import { FC } from "react";
import { ID } from "../../../../../../../resources/helpers";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as ReduxStorefront from "../../../../../../../app/modules/product/storefront/StorefrontSlice";

type Props = {
  name: string;
  id: ID;
};

const StorefrontNameCell: FC<Props> = ({ name, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Link
      to={`/storefront/update-storefront/${id}`}
      onClick={() => {
        if (!id) return
        dispatch(ReduxStorefront.setStorefrontId(id.toString()));
      }}
      className="text-gray-600 mb-1"
      data-testid={id}
    >
      {name}
    </Link>
  )
};

export { StorefrontNameCell };