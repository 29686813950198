import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../core/PageData';
import { useTranslation } from "react-i18next";
import { SettingNavigation } from "./components/SettingNavigation";
import { AsideMenuProfile } from '../../../aside/AsideMenuProfile';

const MobileNavigation = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("HeaderBar.Button.Profile"),
      path: '/profile/waba-account/list',
      isSeparator: false,
      isActive: false,
    }
    ,
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  console.log("Campaign Tools Page");
  
  return (
    <div>
      <SettingNavigation />
    </div>
  );
}

export default MobileNavigation;