import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../core/PageData'
import { CampaignWrapper } from './campaign-list/CampaignList'
import { useTranslation } from "react-i18next";

const CampaignPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Campaign.Title.Campaign"),
      path: '/campaign/list',
      isSeparator: false,
      isActive: false,
    }
    ,
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  console.log("Campaign Tools Page");
  
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{t("Campaign.Title.Campaign")}</PageTitle>
              <CampaignWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/campaign/list' />} />
    </Routes>
  )
}

export default CampaignPage
