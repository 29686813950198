// import InitialState, { UpdateChatListAction } from "./ChatRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "../../../../db";
import InitialState, {
  AlertModal,
  InteractiveButton,
  InteractiveMessage,
  UpdateCampaignAction,
} from "./TemplateRedux";

const initialState: InitialState = {
  clientID: "",
  accountsID: [],
  templateCompaniesID: [],
  templateID: "",
  templateLanguageID: "",
  templateBodyParams: [],
  templateHeaderParams: [],
  newTemplate: {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    templateName: "",
    category: "",
    isActive: true,
    //have sub collection template
    languages: [],
  },
  templateName: "",
  templateChannel: "",
  templateCategory: "",
  templateEmptyCategoryAlert: false,
  templateBodyMessage: "",
  templateEmptyBodyMessageAlert: false,
  templateEmptyCustomTextAlert: [],
  templatePreviewBody: "",
  templateBodyMessageLength: 0,
  generatedTemplate: undefined,
  generatedLanguage: undefined,
  templateNameLength: 0,
  templateNameAlert: false,
  templateNameCharacterAlert: false,
  templateNameEmptyAlert: false,
  templateSenderEmptyAlert: false,
  templateWAAccessToken: "",
  templateWABussinessID: "",
  templateFooter: "",
  templateHeader: "",
  responseTemplateID: "",
  templateState: "create",
  templatePreviewHeaderText: "",
  templateHeaderFile: false,
  templateHeaderURL: "",
  templateFileType: "",
  templateBinaryFile: undefined,
  templateUploadFileSize: 0,
  templateUploadFileType: "",
  templateUploadFileName: "",
  campaignTargetPreviewData: [],
  isloading: false,
  templateButton: [],
  companiesAccounts: [],
  templateQuickReplies: [],
  alertHeader: { show: false, optional: true, body: "" },
  alertFooter: { show: false, optional: true, body: "" },
  alertCTA: { show: false, optional: true, body: "" },
  selectedInteractiveButton: InteractiveButton.None,
  selectedInteractiveMessage: InteractiveMessage.None,
  onStepHeaderOrFooter: "header",
  alertEmptyInteractiveButtonCTA: false,
  alertDuplicateParamName: false,
  alertDuplicateParamNameInBody: false,
  alertEmptyTextContentHeaderParam: false,
  alertNonCheckOTPTermConditions: false,
  alertNonCheckPackageNameTermConditions: false,
  alertNonCheckAppSignatureHashTermConditions: false,
  addSecurityRecommendation: false,
  code_expiration_minutes: 0,
  otpType: "",
  failover: {
    message: "",
    sendWhenUndelivered: false,
    undeliveredTime: 0,
    undeliveredTimeUnit: "",
    bodyParam: []
  },
  myRef: undefined,
};

export const CampaignSlice = createSlice({
  name: UpdateCampaignAction,
  initialState: initialState,
  reducers: {
    setClientID: (state, action: PayloadAction<string>) => {
      state.clientID = action.payload;
      console.log("Set template client id " + action.payload);
    },

    setTemplateUploadFileSize: (state, action: PayloadAction<number>) => {
      state.templateUploadFileSize = action.payload;
      console.log("Set templateUploadFileSize " + action.payload);
    },

    setTemplateUploadFileType: (state, action: PayloadAction<string>) => {
      state.templateUploadFileType = action.payload;
      console.log("Set templateUploadFileType " + action.payload);
    },

    setTemplateUploadFileName: (state, action: PayloadAction<string>) => {
      state.templateUploadFileName = action.payload;
      console.log("Set templateUploadFileName " + action.payload);
    },

    setTemplateBinaryFile: (state, action: PayloadAction<any>) => {
      state.templateBinaryFile = action.payload;
      console.log("Set Template Binary File " + action.payload);
    },

    setTemplateFooter: (state, action: PayloadAction<string>) => {
      state.templateFooter = action.payload;
      console.log("Set template footer " + action.payload);
    },

    setTemplatePreviewHeaderText: (state, action: PayloadAction<string>) => {
      state.templatePreviewHeaderText = action.payload;
      console.log("Set template previewHeaderText " + action.payload);
    },

    setTemplateHeaderURL: (state, action: PayloadAction<string>) => {
      state.templateHeaderURL = action.payload;
      console.log("Set template headerURL " + action.payload);
    },

    setTemplateFileType: (state, action: PayloadAction<string>) => {
      state.templateFileType = action.payload;
      console.log("Set template fileType " + action.payload);
    },

    setTemplateHeaderFile: (state, action: PayloadAction<boolean>) => {
      state.templateHeaderFile = action.payload;
      console.log("Set template headerFile " + action.payload);
    },

    setTemplateHeader: (state, action: PayloadAction<any>) => {
      state.templateHeader = action.payload;
      console.log("Set template header " + action.payload);
    },

    setResponseTemplateID: (state, action: PayloadAction<any>) => {
      state.responseTemplateID = action.payload;
      console.log("Set response template id " + action.payload);
    },

    setTemplateWAAccessToken: (state, action: PayloadAction<any>) => {
      state.templateWAAccessToken = action.payload;
      console.log("Set templateWAAccessToken " + action.payload);
    },

    setTemplateWABussinessID: (state, action: PayloadAction<any>) => {
      state.templateWABussinessID = action.payload;
      console.log("Set templateWABussinessID " + action.payload);
    },

    setTemplateID: (state, action: PayloadAction<any>) => {
      state.templateID = action.payload;
      console.log("Set template template id " + action.payload);
    },

    setTemplateLanguageID: (state, action: PayloadAction<any>) => {
      state.templateLanguageID = action.payload;
      console.log("Set template template with language id " + action.payload);
    },

    setTemplateBodyParams: (state, action: PayloadAction<any[]>) => {
      // Mengonversi objek Firestore menjadi array independen
      const bodyParamsArray = Object.values(action.payload);
    
      // Melakukan pengurutan pada array
      const sortedBodyParams = bodyParamsArray.sort((a: any, b: any) =>
        a?.paramId > b?.paramId ? 1 : -1
      );
    
      // Menetapkan hasil pengurutan ke dalam state
      state.templateBodyParams = sortedBodyParams;
    
      console.log("Set redux template body parameters " + JSON.stringify(sortedBodyParams));
    },

    setTemplateHeaderParams: (state, action: PayloadAction<any>) => {
      state.templateHeaderParams = action.payload;
      console.log(
        "Set template Header parameters " + JSON.stringify(action.payload)
      );
    },

    setCampaignTargetPreviewData: (state, action: PayloadAction<any>) => {
      state.campaignTargetPreviewData = action.payload;
      console.log(
        "Set campaign target preview data " + JSON.stringify(action.payload)
      );
    },

    setTemplateCategory: (state, action: PayloadAction<string>) => {
      state.templateCategory = action.payload;
      console.log(
        "Redux template - Set Template Category " +
          JSON.stringify(action.payload)
      );
    },

    setTemplateEmptyCategoryAlert: (state, action: PayloadAction<boolean>) => {
      state.templateEmptyCategoryAlert = action.payload;
    },

    setTemplateName: (state, action: PayloadAction<string>) => {
      state.templateName = action.payload;
      state.templateNameLength = action.payload.length;
      console.log(
        "Redux template - Set Template Name " + JSON.stringify(action.payload)
      );
    },

    setTemplateChannel: (state, action: PayloadAction<string>) => {
      state.templateChannel = action.payload;
      console.log(
        "Redux template - Set Template Channel " +
          JSON.stringify(action.payload)
      );
    },

    setTemplateBodyMessage: (state, action: PayloadAction<string>) => {
      state.templateBodyMessage = action.payload;
      if (action.payload) {
        state.templateBodyMessageLength = action.payload.length;
      }
      console.log(
        "Redux template - Set Template Body " + JSON.stringify(action.payload)
      );
    },

    setTemplateEmptyBodyMessageAlert: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.templateEmptyBodyMessageAlert = action.payload;
    },

    setTemplateEmptyCustomtextMessageAlert: (
      state,
      action: PayloadAction<[]>
    ) => {
      state.templateEmptyCustomTextAlert = action.payload;
    },

    setTemplateBodyMessageLength: (state, action: PayloadAction<number>) => {
      state.templateBodyMessageLength = action.payload;
      console.log(
        "Redux template - Set Template Body length " +
          JSON.stringify(action.payload)
      );
    },

    setTemplatePreviewBody: (state, action: PayloadAction<string>) => {
      state.templatePreviewBody = action.payload;
      console.log(
        "Redux template - Set Template preview Body " +
          JSON.stringify(action.payload)
      );
    },

    setGeneratedTemplate: (state, action: PayloadAction<any>) => {
      state.generatedTemplate = action.payload;
      // console.log("Redux template - Set generatedTemplate "+JSON.stringify(action.payload));
    },

    setGeneratedLanguage: (state, action: PayloadAction<any>) => {
      state.generatedLanguage = action.payload;
      // console.log("Redux template - Set generatedTemplate "+JSON.stringify(action.payload));
    },

    setTemplateNameAlert: (state, action: PayloadAction<boolean>) => {
      state.templateNameAlert = action.payload;
      console.log("Redux template - Set templateNameAlert ");
    },

    setTemplateNameIncludeCharacterAlert: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.templateNameCharacterAlert = action.payload;
      console.log("Redux template - Set templateNameCharacterAlert ");
    },

    setTemplateNameEmptyAlert: (state, action: PayloadAction<boolean>) => {
      state.templateNameEmptyAlert = action.payload;
      console.log("Redux template - Set templateNameEmptyAlert ");
    },

    setTemplateSenderEmptyAlert: (state, action: PayloadAction<boolean>) => {
      state.templateSenderEmptyAlert = action.payload;
      console.log("Redux template - Set templateNameEmptyAlert ");
    },

    setTemplateState: (state, action: PayloadAction<string>) => {
      state.templateState = action.payload;
      console.log("Redux template - Set templateState " + action.payload);
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isloading = action.payload;
      console.log("Redux template - Set isloading ");
    },

    setTemplateButton: (state, action: PayloadAction<any>) => {
      state.templateButton = action.payload;
      console.log(
        "Set template call to action button " + JSON.stringify(action.payload)
      );
    },

    setAccountsID: (state, action: PayloadAction<any>) => {
      state.accountsID = action.payload;
      console.log("Set template accounts id " + action.payload);
    },

    setTemplateCompaniesID: (state, action: PayloadAction<any>) => {
      state.templateCompaniesID = action.payload;
      console.log("Set template companies id " + action.payload);
    },

    setCompaniesAccounts: (state, action: PayloadAction<any>) => {
      state.companiesAccounts = action.payload;
      console.log("Set companies account " + action.payload);
    },

    setTemplateQuickReplies: (state, action: PayloadAction<string[]>) => {
      state.templateQuickReplies = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },

    setAlertHeader: (state, action: PayloadAction<AlertModal>) => {
      state.alertHeader = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },
    setAlertFooter: (state, action: PayloadAction<AlertModal>) => {
      state.alertFooter = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },
    setAlertCTA: (state, action: PayloadAction<AlertModal>) => {
      state.alertCTA = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },
    setSelectedInteractiveButton: (state, action: PayloadAction<string>) => {
      state.selectedInteractiveButton = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },
    setSelectedInteractiveMessage: (state, action: PayloadAction<string>) => {
      state.selectedInteractiveMessage = action.payload;
      console.log("Set quick reply texts " + action.payload);
    },
    setOnStepHeaderOrFooter: (state, action: PayloadAction<string>) => {
      state.onStepHeaderOrFooter = action.payload;
      console.log("Set on step header or footer " + action.payload);
    },
    setAlertEmptyInteractiveButtonCTA: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertEmptyInteractiveButtonCTA = action.payload;
      console.log("Set alert empty interactive button cta " + action.payload);
    },
    setAlertDuplicateParamName: (state, action: PayloadAction<boolean>) => {
      state.alertDuplicateParamName = action.payload;
      console.log("Set alert duplicate param name " + action.payload);
    },
    setAlertDuplicateParamNameInBody: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertDuplicateParamNameInBody = action.payload;
      console.log("Set alert duplicate param name in body " + action.payload);
    },
    setAlertEmptyTextContentHeaderParam: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertEmptyTextContentHeaderParam = action.payload;
      console.log("Set alert empty text content in header " + action.payload);
    },

    setTemplateEmptyOTPTermCondition: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertNonCheckOTPTermConditions = action.payload;
    },

    setTemplateEmptyPackageNameTermCondition: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertNonCheckPackageNameTermConditions = action.payload;
    },

    setTemplateEmptyAppSignatureHashTermCondition: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.alertNonCheckAppSignatureHashTermConditions = action.payload;
    },

    setMyRef: (
      state,
      action: PayloadAction<any>
    ) => {
      state.myRef = action.payload;
    },

    cleanReduxTemplate: (state, action: PayloadAction<boolean>) => {
      state.templateCategory = "";
      state.templateID = "";
      state.templateLanguageID = "";
      state.templateBodyParams = [];
      state.templateHeaderParams = [];
      state.newTemplate = {
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        templateName: "",
        category: "",
        isActive: true,
        //have sub collection template
        languages: [],
      };
      state.templateName = "";
      state.templateChannel = "";
      state.templateCategory = "";
      state.templateBodyMessage = "";
      state.templateEmptyBodyMessageAlert = false;
      state.templatePreviewBody = "";
      state.templateBodyMessageLength = 0;
      state.generatedTemplate = undefined;
      state.generatedLanguage = undefined;
      state.templateNameLength = 0;
      state.templateNameAlert = false;
      state.templateNameCharacterAlert = false;
      state.templateNameEmptyAlert = false;
      state.templateSenderEmptyAlert = false;
      state.templateWAAccessToken = "";
      state.templateWABussinessID = "";
      state.templateFooter = "";
      state.templateHeader = "";
      state.responseTemplateID = "";
      state.templateState = "create";
      state.templatePreviewHeaderText = "";
      state.templateHeaderFile = false;
      state.templateHeaderURL = "";
      state.templateFileType = "";
      state.templateState = "";
      state.templateUploadFileSize = 0;
      state.templateUploadFileName = "";
      state.templateUploadFileType = "";
      state.templateBinaryFile = undefined;
      state.isloading = false;
      state.campaignTargetPreviewData = [];
      state.templateButton = [];
      state.accountsID = [];
      state.templateCompaniesID = [];
      state.companiesAccounts = [];
      state.clientID = "";
      state.templateEmptyCategoryAlert = false;
      state.templateQuickReplies = [];
      state.alertHeader = { show: false, optional: true, body: "" };
      state.alertFooter = { show: false, optional: true, body: "" };
      state.alertCTA = { show: false, optional: true, body: "" };
      state.selectedInteractiveButton = InteractiveButton.None;
      state.onStepHeaderOrFooter = "header";
      state.alertEmptyInteractiveButtonCTA = false;
      state.alertDuplicateParamName = false;
      state.alertDuplicateParamNameInBody = false;
      state.alertEmptyTextContentHeaderParam = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTemplateID,
  setTemplateLanguageID,
  setTemplateBodyParams,
  cleanReduxTemplate,
  setTemplateHeaderParams,
  setTemplateChannel,
  setTemplateName,
  setTemplateCategory,
  setTemplateBodyMessage,
  setTemplateBodyMessageLength,
  setTemplatePreviewBody,
  setTemplateEmptyBodyMessageAlert,
  setTemplateEmptyOTPTermCondition,
  setTemplateEmptyPackageNameTermCondition,
  setTemplateEmptyAppSignatureHashTermCondition,
  setTemplateEmptyCustomtextMessageAlert,
  setGeneratedTemplate,
  setGeneratedLanguage,
  setTemplateNameAlert,
  setTemplateNameIncludeCharacterAlert,
  setTemplateNameEmptyAlert,
  setTemplateSenderEmptyAlert,
  setTemplateWAAccessToken,
  setTemplateWABussinessID,
  setTemplateFooter,
  setTemplateHeader,
  setResponseTemplateID,
  setTemplateState,
  setTemplatePreviewHeaderText,
  setTemplateFileType,
  setTemplateHeaderFile,
  setTemplateHeaderURL,
  setTemplateBinaryFile,
  setTemplateUploadFileSize,
  setTemplateUploadFileType,
  setTemplateUploadFileName,
  setCampaignTargetPreviewData,
  setLoading,
  setTemplateButton,
  setAccountsID,
  setTemplateCompaniesID,
  setCompaniesAccounts,
  setClientID,
  setTemplateEmptyCategoryAlert,
  setTemplateQuickReplies,
  setAlertHeader,
  setAlertFooter,
  setAlertCTA,
  setSelectedInteractiveButton,
  setSelectedInteractiveMessage,
  setOnStepHeaderOrFooter,
  setAlertEmptyInteractiveButtonCTA,
  setAlertDuplicateParamName,
  setAlertDuplicateParamNameInBody,
  setAlertEmptyTextContentHeaderParam,
  setMyRef,
} = CampaignSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignSlice.reducer;
