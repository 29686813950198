import { useListView } from "../../../../../core/list/ListViewProvider";
import { CampaignToolbar } from "./CampaignToolbar";
import { CampaignSearchComponent } from "./CampaignSearchComponent";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup/redux/store";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const CampaignDetailHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selected } = useListView();
  const nav = useNavigate();
  const { t } = useTranslation();
  const campaignID = useSelector((state: RootState) => state.Campaign.selectedCampaignID); 
  
  return (
    <div className="card-header border-0">
      <div className="card-toolbar">
        <h3 className="m-0">{t("DLR.Title.CampaignDetails")}</h3>
      </div>
      <div className={clsx(`card-toolbar`, isTabletOrMobile ? "w-100" : "")}>
        <CampaignSearchComponent />
        <CampaignToolbar />
      </div>
    </div>
  );
};

export { CampaignDetailHeader };
