import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import CheckboxGroups, {
  MenuItem,
} from "../../../../../components/checkbox-groups/CheckboxGroups";
import * as ApiSettingSlice from "../../../../../modules/api-setting/redux/ApiSettingSlice";
import { ApiSettingState } from "../../../../../modules/api-setting/redux/ApiSettingRedux";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as ServerSetting from "../../../../../../api/server/api-setting";
import { ORDER_STATUS_LIST, OrderStatus7Days } from "src/app/models/Order";

const STATUS_LABEL_MAP = {
  // [OrderStatus7Days.All]: "OrderStatus7Days.All",
  [OrderStatus7Days.Unpaid]: "OrderStatus7Days.Unpaid",
  [OrderStatus7Days.NewOrder]: "OrderStatus7Days.NewOrder",
  [OrderStatus7Days.Processing]: "OrderStatus7Days.Processing",
  [OrderStatus7Days.ReadyToShip]: "OrderStatus7Days.ReadyToShip",
  [OrderStatus7Days.Shipping]: "OrderStatus7Days.Shipping",
  [OrderStatus7Days.Completed]: "OrderStatus7Days.Completed",
  [OrderStatus7Days.Cancelled]: "OrderStatus7Days.Cancelled",
  [OrderStatus7Days.Return]: "OrderStatus7Days.Return",
};

const PushMechanism = () => {
  return (
    <div data-testid="Api-push-edit-page">
      <ChatPushItem />

      <OrderPushItem />

      <ProductPushItem />
    </div>
  );
};

export default PushMechanism;

function ChatPushItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const apiSetting: ApiSettingState = useSelector(
    (state: any) => state.ApiSetting
  );
  const isEditMode = apiSetting.isEditMode;

  const chatPushSetting = apiSetting.push.chat;

  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [urlCallback, setUrlCallback] = useState(chatPushSetting.url ?? "");
  const [isExpand, setIsExpand] = useState(false);

  const currentStateCallbackUrl = chatPushSetting.url;
  useEffect(() => {
    setUrlCallback(currentStateCallbackUrl);
  }, [currentStateCallbackUrl]);

  const handleVerifyCallback = async () => {
    setIsLoadingVerify(true);

    const isValid = await isValidUrlCallback(urlCallback);

    dispatch(
      ApiSettingSlice.setApiSettingPush({
        ...apiSetting.push,
        chat: {
          ...chatPushSetting,
          isActive: true,
          isUrlVerified: isValid,
          url: urlCallback,
        },
      })
    );

    setIsLoadingVerify(false);
  };

  const handleUrlCallbackChange = (value: string) => {
    if (value !== currentStateCallbackUrl) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          chat: {
            ...chatPushSetting,
            isUrlVerified: false,
          },
        })
      );
    }
    setUrlCallback(value);

    // Set state to edited
    triggerEditStateChange();
  };

  const handleCheckboxChange = (
    key: keyof ApiSettingState["push"]["chat"],
    value: boolean
  ) => {
    if (key === "isActive" && !value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          chat: {
            isActive: false,
            isDeliveryReport: false,
            isRecieveMessage: false,
            isUrlVerified: false,
            url: "",
          },
        })
      );

      setUrlCallback("");
    } else if (key !== "isActive" && !!value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          chat: {
            ...chatPushSetting,
            isActive: true,
            [key]: value,
          },
        })
      );
    } else {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          chat: {
            ...chatPushSetting,
            [key]: value,
          },
        })
      );
    }

    if (key === "isActive" && !!value) {
      setIsExpand(true);
    }

    // Set state to edited
    triggerEditStateChange();
  };

  const triggerEditStateChange = () => {
    dispatch(ApiSettingSlice.setStateEdited(true));
  };

  return (
    <Accordion as="div" className="py-4" activeKey={isExpand ? "0" : undefined}>
      <Accordion.Item as="div" eventKey="0" className="shadow-sm">
        <div className="px-9 py-2 d-flex align-items-center gap-4">
          <div className="form-check d-flex align-items-center gap-4">
            <input
              id="chat-check"
              className="form-check-input"
              style={{ zIndex: 40 }}
              type="checkbox"
              checked={!!chatPushSetting.isActive}
              disabled={!isEditMode}
              onChange={(e) =>
                handleCheckboxChange("isActive", e.target.checked)
              }
            />
            <label
              htmlFor="chat-check"
              className="form-check-label fs-3 fw-bolder"
            >
              {t("API.Setting.Push.Chat")}
            </label>
          </div>
          {chatPushSetting.isUrlVerified === true && urlCallback && (
            <div className="d-flex align-items-center gap-4">
              <h5 className="text-muted">({urlCallback})</h5>
            </div>
          )}

          <Accordion.Button
            onClick={() => setIsExpand((prev) => !prev)}
            style={{ backgroundColor: "transparent" }}
          ></Accordion.Button>
        </div>
        <Accordion.Body as="div" className="px-10">
          <p>{t("API.Setting.Push.Callback.URL")}</p>
          <div className="row">
            <div className="col-8">
              <Input
                type="text"
                className="form-control w-100"
                placeholder="https://examples.com"
                name="url_cb"
                readOnly={!isEditMode}
                value={urlCallback}
                onChange={(e) => handleUrlCallbackChange(e.target.value)}
              />
            </div>
            <div className="col">
              <Button
                data-testid="api-push-verifycallback"
                className="btn btn-primary"
                id="verify_url"
                type="button"
                disabled={!isEditMode}
                isLoading={isLoadingVerify}
                onClick={handleVerifyCallback}
              >
                {t("API.Setting.Push.Callback.URL.Verify")}
              </Button>
            </div>
          </div>

          {chatPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === currentStateCallbackUrl && (
              <span className="flex text-success my-3">
                {t("API.Setting.Push.Callback.URL.Message.Success")}
              </span>
            )}
          {!chatPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === currentStateCallbackUrl && (
              <span className="flex text-danger my-3">
                {t("API.Setting.Push.Callback.URL.Message.Invalid")}
              </span>
            )}

          <div className="py-6">
            <div className="form-check py-2">
              <input
                id="delivery-report-check"
                className="form-check-input"
                type="checkbox"
                checked={!!chatPushSetting.isDeliveryReport}
                disabled={!isEditMode}
                onChange={(e) =>
                  handleCheckboxChange("isDeliveryReport", e.target.checked)
                }
              />
              <label
                htmlFor="delivery-report-check"
                className="form-check-label"
              >
                {t("API.Setting.Push.Delivery.Report")}
              </label>
            </div>
            <div className="form-check py-2">
              <input
                id="receive-message-check"
                className="form-check-input"
                type="checkbox"
                checked={!!chatPushSetting.isRecieveMessage}
                disabled={!isEditMode}
                onChange={(e) =>
                  handleCheckboxChange("isRecieveMessage", e.target.checked)
                }
              />
              <label
                htmlFor="receive-message-check"
                className="form-check-label"
              >
                {t("API.Setting.Push.Received.Message")}
              </label>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function OrderPushItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const apiSetting: ApiSettingState = useSelector(
    (state: any) => state.ApiSetting
  );
  const isEditMode = apiSetting.isEditMode;

  const orderPushSetting = apiSetting.push.order;

  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [urlCallback, setUrlCallback] = useState(orderPushSetting.url ?? "");
  const [isExpand, setIsExpand] = useState(false);

  const selectedStatuses = orderPushSetting.selectedStatuses;

  const handleCheckboxStatusChange = (checked: boolean, status: string) => {
    if (checked) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            ...orderPushSetting,
            selectedStatuses: [...selectedStatuses, status],
          },
        })
      );
    } else {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            ...orderPushSetting,
            selectedStatuses: selectedStatuses.filter(
              (item) => item !== status
            ),
          },
        })
      );
    }

    triggerEditStateChange();
  };

  const currentStateCallbackUrl = orderPushSetting.url;
  useEffect(() => {
    setUrlCallback(currentStateCallbackUrl);
  }, [currentStateCallbackUrl]);

  const handleVerifyCallback = async () => {
    setIsLoadingVerify(true);

    const isValid = await isValidUrlCallback(urlCallback);

    dispatch(
      ApiSettingSlice.setApiSettingPush({
        ...apiSetting.push,
        order: {
          ...orderPushSetting,
          isActive: true,
          isUrlVerified: isValid,
          url: urlCallback,
        },
      })
    );

    setIsLoadingVerify(false);
  };

  const handleUrlCallbackChange = (value: string) => {
    if (value !== currentStateCallbackUrl) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            ...orderPushSetting,
            isUrlVerified: false,
          },
        })
      );
    }

    setUrlCallback(value);

    // Set state to edited
    triggerEditStateChange();
  };

  const handleCheckboxChange = (
    key: keyof ApiSettingState["push"]["order"],
    value: boolean
  ) => {
    if (key === "isActive" && !value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            isActive: false,
            isUrlVerified: false,
            url: "",
            selectedStatuses: [],
          },
        })
      );

      setUrlCallback("");
    } else if (key !== "isActive" && !!value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            ...orderPushSetting,
            isActive: true,
            [key]: value,
          },
        })
      );
    } else {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          order: {
            ...orderPushSetting,
            [key]: value,
          },
        })
      );
    }

    if (key === "isActive" && !!value) {
      setIsExpand(true);
    }

    // Set state to edited
    triggerEditStateChange();
  };

  const triggerEditStateChange = () => {
    dispatch(ApiSettingSlice.setStateEdited(true));
  };

  console.log("selectedStatuses", selectedStatuses);

  return (
    <Accordion as="div" className="py-4" activeKey={isExpand ? "0" : undefined}>
      <Accordion.Item as="div" eventKey="0" className="shadow-sm">
        <div className="px-9 py-2 d-flex align-items-center gap-4">
          <div className="form-check d-flex align-items-center gap-4">
            <input
              id="order-check"
              className="form-check-input"
              type="checkbox"
              checked={!!orderPushSetting.isActive}
              disabled={!isEditMode}
              onChange={(e) =>
                handleCheckboxChange("isActive", e.target.checked)
              }
            />
            <label
              htmlFor="order-check"
              className="form-check-label fs-3 fw-bolder"
            >
              {t("Menu.Order")}
            </label>
          </div>
          {orderPushSetting.isUrlVerified === true && urlCallback && (
            <div className="d-flex align-items-center gap-4">
              <h5 className="text-muted">({urlCallback})</h5>
            </div>
          )}
          <Accordion.Button
            onClick={() => setIsExpand((prev) => !prev)}
            style={{ backgroundColor: "transparent" }}
          ></Accordion.Button>
        </div>
        <Accordion.Body as="div" className="px-10">
          <p>{t("API.Setting.Push.Callback.URL")}</p>
          <div className="row">
            <div className="col-8">
              <Input
                type="text"
                className="form-control w-100"
                placeholder="https://examples.com"
                name="url_cb"
                readOnly={!isEditMode}
                value={urlCallback}
                onChange={(e) => handleUrlCallbackChange(e.target.value)}
              />
            </div>
            <div className="col">
              <Button
                data-testid="api-push-verifycallback"
                className="btn btn-primary"
                id="verify_url"
                type="button"
                disabled={!isEditMode}
                isLoading={isLoadingVerify}
                onClick={handleVerifyCallback}
              >
                {t("API.Setting.Push.Callback.URL.Verify")}
              </Button>
            </div>
          </div>

          {orderPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === currentStateCallbackUrl && (
              <span className="flex text-success my-3">
                {t("API.Setting.Push.Callback.URL.Message.Success")}
              </span>
            )}
          {!orderPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === currentStateCallbackUrl && (
              <span className="flex text-danger my-3">
                {t("API.Setting.Push.Callback.URL.Message.Invalid")}
              </span>
            )}

          <div className="py-6 row w-75">
            {ORDER_STATUS_LIST.map((status) => (
              <div className="col-4 form-check py-2" key={status}>
                <input
                  id={`delivery-report-check-${status}`}
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedStatuses.includes(status)}
                  disabled={!isEditMode}
                  onChange={(e) =>
                    handleCheckboxStatusChange(e.target.checked, status)
                  }
                />
                <label
                  htmlFor={`delivery-report-check-${status}`}
                  className="form-check-label d-flex align-items-center gap-2"
                >
                  <span className="text-capitalize">
                    {t(STATUS_LABEL_MAP[status])}
                  </span>
                  {/* <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip {...props} id={status} className="fs-5">
                        Some explaination!!!
                      </Tooltip>
                    )}
                  >
                    <i className={`bi fs-5 bi-info-circle`}></i>
                  </OverlayTrigger> */}
                </label>
              </div>
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function ProductPushItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const apiSetting: ApiSettingState = useSelector(
    (state: any) => state.ApiSetting
  );
  const isEditMode = apiSetting.isEditMode;

  const productPushSetting = apiSetting.push.product;

  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [urlCallback, setUrlCallback] = useState(productPushSetting.url ?? "");
  const [isExpand, setIsExpand] = useState(false);

  const currentStateCallbackUrl = productPushSetting.url;
  useEffect(() => {
    setUrlCallback(currentStateCallbackUrl);
  }, [currentStateCallbackUrl]);

  const handleVerifyCallback = async () => {
    setIsLoadingVerify(true);

    const isValid = await isValidUrlCallback(urlCallback);

    dispatch(
      ApiSettingSlice.setApiSettingPush({
        ...apiSetting.push,
        product: {
          ...productPushSetting,
          isActive: true,
          isUrlVerified: isValid,
          url: urlCallback,
        },
      })
    );

    setIsLoadingVerify(false);
  };

  const handleUrlCallbackChange = (value: string) => {
    if (value !== currentStateCallbackUrl) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          product: {
            ...productPushSetting,
            isUrlVerified: false,
          },
        })
      );
    }

    setUrlCallback(value);

    // Set state to edited
    triggerEditStateChange();
  };

  const handleCheckboxChange = (
    key: keyof ApiSettingState["push"]["product"],
    value: boolean
  ) => {
    if (key === "isActive" && !value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          product: {
            isActive: false,
            isUrlVerified: false,
            url: "",
          },
        })
      );

      setUrlCallback("");
    } else if (key !== "isActive" && !!value) {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          product: {
            ...productPushSetting,
            isActive: true,
            [key]: value,
          },
        })
      );
    } else {
      dispatch(
        ApiSettingSlice.setApiSettingPush({
          ...apiSetting.push,
          product: {
            ...productPushSetting,
            [key]: value,
          },
        })
      );
    }

    if (key === "isActive" && !!value) {
      setIsExpand(true);
    }

    // Set state to edited
    triggerEditStateChange();
  };

  const triggerEditStateChange = () => {
    dispatch(ApiSettingSlice.setStateEdited(true));
  };

  return (
    <Accordion as="div" className="py-4" activeKey={isExpand ? "0" : undefined}>
      <Accordion.Item as="div" eventKey="0" className="shadow-sm">
        <div className="px-9 py-2 d-flex align-items-center gap-4">
          <div className="form-check d-flex align-items-center gap-4">
            <input
              id="product-check"
              className="form-check-input"
              type="checkbox"
              checked={!!productPushSetting.isActive}
              disabled={!isEditMode}
              onChange={(e) =>
                handleCheckboxChange("isActive", e.target.checked)
              }
            />
            <label
              htmlFor="product-check"
              className="form-check-label fs-3 fw-bolder"
            >
              {t("StorefrontList.Column.Product")}
            </label>
          </div>
          {productPushSetting.isUrlVerified === true && urlCallback && (
            <div className="d-flex align-items-center gap-4">
              <h5 className="text-muted">({urlCallback})</h5>
            </div>
          )}
          <Accordion.Button
            onClick={() => setIsExpand((prev) => !prev)}
            style={{ backgroundColor: "transparent" }}
          ></Accordion.Button>
        </div>
        <Accordion.Body as="div" className="px-10">
          <p>{t("API.Setting.Push.Callback.URL")}</p>
          <div className="row">
            <div className="col-8">
              <Input
                type="text"
                className="form-control w-100"
                placeholder="https://examples.com"
                name="url_cb"
                readOnly={!isEditMode}
                value={urlCallback}
                onChange={(e) => handleUrlCallbackChange(e.target.value)}
              />
            </div>
            <div className="col">
              <Button
                data-testid="api-push-verifycallback"
                className="btn btn-primary"
                id="verify_url"
                type="button"
                disabled={!isEditMode}
                isLoading={isLoadingVerify}
                onClick={handleVerifyCallback}
              >
                {t("API.Setting.Push.Callback.URL.Verify")}
              </Button>
            </div>
          </div>

          {productPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === productPushSetting.url && (
              <span className="flex text-success my-3">
                {t("API.Setting.Push.Callback.URL.Message.Success")}
              </span>
            )}
          {!productPushSetting.isUrlVerified &&
            isEditMode &&
            urlCallback === productPushSetting.url && (
              <span className="flex text-danger my-3">
                {t("API.Setting.Push.Callback.URL.Message.Invalid")}
              </span>
            )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

async function isValidUrlCallback(url: string) {
  if (!url) return false;
  try {
    const verifyUrl = await ServerSetting.verifyCallbackUrlSetting(url);

    return verifyUrl?.status === 200 && !!verifyUrl?.data?.is_valid;
  } catch (error: any) {
    console.log("Error verify", error);

    return false;
  }
}
