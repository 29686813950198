import { KTCard } from "../../../../resources/helpers/components/KTCard";
import Customer from "../../../models/Customer";
import Pagination from "./components/footer/Pagination";
import { TargetListHeader } from "./components/header/TargetListHeader";
import { TableProvider } from "./context/TableContext";
import { TargetTable } from "./table/TargetTable";

// const TargetList = () => {
//   // const { itemIdForUpdate } = useListView();
//   return (
//     <>

//       {/* {itemIdForUpdate !== undefined && <TargetEditModal />} */}
//     </>
//   );
// };

const TargetListWrapper = ({ onModalClose }: { onModalClose?: () => void }) => (
  // <QueryRequestProvider>
  //   <QueryResponseProvider>
  //     <ListViewProvider>
  //       <TargetList />
  //     </ListViewProvider>
  //   </QueryResponseProvider>
  // </QueryRequestProvider>
  <TableProvider<Customer> collection="customers">
    <KTCard>
      <TargetListHeader onModalClose={onModalClose} />
      <TargetTable />
      <Pagination />
    </KTCard>
  </TableProvider>
);

export { TargetListWrapper };
