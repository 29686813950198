import { useMediaQuery } from "react-responsive";
import Carousel from "../../../../../modules/product/carousel/carousel";
import CarouselItem from "../../../../../modules/product/carousel/carouselItem";

const StorefrontCarouselImage = ({ id, title, dataImage }: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const carouselItemData = dataImage.map((slideshow: any, i: number) => {
    return (
      <CarouselItem
        id={slideshow.name}
        index={i}
        isActive={slideshow.name === "Shopee" ? true : false}
        title={title.replace("<<marketplace>>", slideshow.name)}
        slideshowDataDesktop={slideshow.data.desktop}
        slideshowDataMobile={slideshow.data.mobile}
      ></CarouselItem>
    );
  });

  return (
    <Carousel
      id={id}
      children={carouselItemData}
      style={{ maxHeight: isTabletOrMobile ? "auto" : "250px" }}
    ></Carousel>
  );
};

export default StorefrontCarouselImage;
