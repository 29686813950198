import React from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { AsideMenuItem } from "./AsideMenuItem";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { AsideMenuButton } from "./AsideMenuButton";

const checkIsInsertOrEditMode = (pathname: string) => {
  console.log("Last index of create:", pathname.lastIndexOf("/create") > 0, " and edit:", pathname.lastIndexOf("/edit") > 0);
  return pathname.lastIndexOf("/create") > 0 
    || pathname.lastIndexOf("/edit") > 0
    || pathname.includes("/create") 
    || pathname.includes("/storefront/create-storefront/")
    || pathname.includes("/storefront/update-storefront/")
    || pathname.includes("/product/create-product/")
    || pathname.includes("/product/update/")
}

const AsideMenuSetting: React.FC = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const settingsClickHandler = () => {
    
    // check if current page is insert or edit mode
    // read from last index of location.pathname
    const isInsertOrEditMode = checkIsInsertOrEditMode(location.pathname);
    console.log("Setting Aside Menu clicked!", { location , isInsertOrEditMode });

    //display none for menu main component if not in insert or edit mode
    if (!isInsertOrEditMode) {
      const asideMenuMain = document.getElementById("aside-menu-main");
      asideMenuMain?.classList.add("d-none");

      //remove display none for menu profile component
      const asideMenuProfile = document.getElementById("aside-menu-setting");
      asideMenuProfile?.classList.remove("d-none");

      if (isTabletOrMobile) {
        nav("/setting/");
      } else { //Punya desktop
        nav("/setting/waba-account/list");
      }
    }


  };

  return (
    <AsideMenuItem
      to="/setting"
      duotoneIcon="/media/icons/duotune/coding/cod001.svg"
      title={t("HeaderBar.Button.Settings")}
      onClick={() => settingsClickHandler()}
      id="AM_Settings"
    />
  );
};

export default AsideMenuSetting;
