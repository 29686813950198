import format from "date-fns/format";
import firebase from "firebase/compat/app";

export function dateOrTimestampToString(
  date?: Date | firebase.firestore.Timestamp | unknown,
  formatStyle: string = "d MMM yyyy, h:mm aaa"
) {
  if (!date) {
    return undefined;
  }

  if (date instanceof firebase.firestore.Timestamp) {
    return format(date.toDate(), formatStyle);
  }

  if (date instanceof Date) {
    return format(date, formatStyle);
  }

  return undefined;
}
