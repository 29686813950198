import { FC } from "react";
import { useTranslation } from "react-i18next";

const AlertModalDialog: FC<{
  bodyMessage: string;
  id: string;
  onSubmit: () => void;
  optional: boolean;
}> = ({ id, bodyMessage, onSubmit, optional }) => {
  const { t } = useTranslation();
  // console.log("AlertModal BroadCast");
  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title fs-2">Warning!</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p className="fs-4">{bodyMessage}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("Campaign.Button.Back")}
            </button>
            {optional ? (
              <button
                onClick={onSubmit}
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                {t("ForgotPassword.Button.Submit")}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModalDialog;
