import {Campaign, CampaignMessage} from "../../../../../models/Campaign"


const sliceData = (data: CampaignMessage[],startIndex: number, endIndex: number) => {
  let newDataCampaign: CampaignMessage [] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const datacampaign = data[index];
    newDataCampaign.push(datacampaign); 
  }
  return newDataCampaign;
}

export const campaignMessageSorting = (data : CampaignMessage[], 
  sort: string | undefined, 
  limit: number | undefined, 
  action: string | undefined , 
  orderBy: string | undefined,
  searchBy: string | undefined, 
  curretPage: number | undefined) => {
  // console.log("Masuk Campaign sorting >>>>> ");

  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort : " + sort);
  console.log("order : " + orderBy);
  console.log("search : " + searchBy);
  console.log("current page : " + curretPage);

  if(curretPage === undefined)curretPage = 1;
  if(limit === undefined)limit = 10;
  if(orderBy === undefined) orderBy = "desc";
  if(searchBy === undefined) searchBy = ""; 
  if(sort === undefined || sort === 'deleted') sort = "notifiedAt";

  let dataCampaigns:any [] = [];

  if(data.length == 0){
    return dataCampaigns;
  }

  for (let index = 0; index < data.length; index++) {
    dataCampaigns.push(data[index]); 
  }
  
  //Search Data
  if(searchBy && searchBy !=null && searchBy!="" && dataCampaigns.length>0){
    if(searchBy.startsWith("62")){
      dataCampaigns = dataCampaigns.filter((data) => data.phoneNumber?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
    }else{
      dataCampaigns = dataCampaigns.filter((data) => data.customerName?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
    }
  }

  console.log(`Data campaign2 : ${JSON.stringify(data)}`);
  
  //Order Data
  if(dataCampaigns.length>0){
    if (sort === "customerName") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
        a.customerName!.toLowerCase() > b.customerName!.toLowerCase() ? 1 : -1
      );
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>
          a.customerName!.toLowerCase() > b.customerName!.toLowerCase() ? -1 : 1
        );
      }
    }

    if (sort === "phoneNumber") {
      dataCampaigns = dataCampaigns?.sort((a, b) => {
        if (a.phoneNumber === undefined || a.phoneNumber === "") return 1;
        if (b.phoneNumber === undefined || b.phoneNumber === "") return -1;
        return a.phoneNumber?.toString().toLowerCase() > b.phoneNumber?.toString().toLowerCase() ? 1 : -1;
      });

      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) => {
          if (a.phoneNumber === undefined || a.phoneNumber === "") return -1;
          if (b.phoneNumber === undefined || b.phoneNumber === "") return 1;
          return a.phoneNumber?.toString().toLowerCase() > b.phoneNumber?.toString().toLowerCase() ? -1 : 1;
        });
      }
    }
    
    if (sort === "updatedAt") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
      {
        if (!a.updatedAt || a.updatedAt === undefined) return 1;
        if (!b.updatedAt || b.updatedAt === undefined) return -1;
        return a.updatedAt > b.updatedAt
          ? 1
          : -1
      });
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>{
          if (!a.updatedAt || a.updatedAt === undefined) return -1;
          if (!b.updatedAt || b.updatedAt === undefined) return 1;
          return a.updatedAt > b.updatedAt
            ? -1
            : 1
      });
      }
    }

    if (sort === "message") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
      {
        if (!a.message || a.message === undefined) return 1;
        if (!b.message || b.message === undefined) return -1;
        return a.message.toLowerCase() > b.message.toLowerCase()
          ? 1
          : -1
      });
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>{
          if (!a.message || a.message === undefined) return -1;
          if (!b.message || b.message === undefined) return 1;
          return a.message.toLowerCase() > b.message.toLowerCase()
            ? -1
            : 1
      });
      }
    }

    if (sort === "status") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
      {
        if (!a.status || a.status === undefined) return 1;
        if (!b.status || b.status === undefined) return -1;
        return a.status.toLowerCase() > b.status.toLowerCase()
          ? 1
          : -1
      });
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>{
          if (!a.status || a.status === undefined) return -1;
          if (!b.status || b.status === undefined) return 1;
          return a.status.toLowerCase() > b.status.toLowerCase()
            ? -1
            : 1
      });
      }
    }
  }

  if(curretPage == 1){
    let endIndex = curretPage * limit;
    dataCampaigns = Array.from(dataCampaigns).slice(0,limit);
  }else{
    let endIndex = curretPage * limit;
    let startIndex = (endIndex - limit); 
    if(startIndex > dataCampaigns.length){
      return [];
    }else{
      dataCampaigns = Array.from(dataCampaigns).slice(startIndex,endIndex);
    }
  }
  return dataCampaigns;
}