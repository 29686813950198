import db from ".";
import * as lc from '../app/modules/localstorage/index'

export const getChannel = async () => {
    const channelList = await db
    .collection("list")
    .doc("channel")
    .get()
    .then((snapshot) => {
        
        const channelList = Object.getOwnPropertyNames(snapshot.data());
        
        return (channelList);     
    })
    // console.log("Channels: "+channelList);

    lc.setItemLC(lc.LCName.ChannelList, channelList);
}

