import { Outlet } from "react-router-dom";
import { toAbsoluteUrl } from "../../resources/helpers/AssetHelpers";
import { useTheme } from "../../setup/theme/useTheme";
import { useMediaQuery } from "react-responsive";

export const RegisterLayout = () => {
  const currentTheme = useTheme();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div 
      className={ !isMobileView ? "container w-full h-full d-flex p-6" : ""}
      style={{
        backgroundColor: "#EDEFF4",
        flex: 1,
      }}>
      {isMobileView && (
        <div
            className="col h-100 d-flex flex-column align-items-center justify-content-center p-6"
            style={{
              backgroundColor: "#ffffff",
              flex: 1,
              maxHeight: '100%',
              overflow: "hidden",
            }}
          >
        <Outlet />
        </div>
      )}
      {!isMobileView && (
        <div
          className="w-full h-full d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: "#ffffff",
            flex: 1,
            borderRadius: "15px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "#ECFDFF",
              flex: 1,
              width: '50%',
              maxHeight: '100%'
            }}
            className="h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <img
              alt="Logo"
              src={toAbsoluteUrl(currentTheme.urlLogo)}
              style={{
                  height: '15vw'
              }}
              draggable="false"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl(currentTheme.urlLogoText)}
              className="mt-5"
              style={{
                  height: '5vw'
              }}
              draggable="false"
            />
          </div>
          <div
            className="col h-100 d-flex flex-column align-items-center justify-content-center"
            style={{
              backgroundColor: "#ffffff",
              flex: 1,
              width: '50%',
              maxHeight: '100%',
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "70%",
                maxHeight: "95%",
                overflow: 'scroll',
              //   backgroundColor: 'red'
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
