/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  PaginationState,
  QUERIES,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import { CampaignMessage } from "../../../../../models/Campaign";
import * as CampaignMessageRedux from "../../../../../modules/campaign/message/CampaignMessageSlice";
import { useQueryRequest } from "./QueryRequestProvider";
import { getCampaignMessages } from "./_requests";
import { campaignMessageSorting } from "./_sort";

import {Response} from '../../../../../../resources/helpers';


export type CampaignsQueryResponse = Response<Array<CampaignMessage>>

const QueryResponseContext =
  createResponseContext<CampaignMessage>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { state, updateState } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  let CampaignQueryResponse: CampaignsQueryResponse;
  // Campaign Data
  const campaignID = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaignID
  );
  const campaign = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  // Campaign Message
  let listCampaignMessage: any = [];
  listCampaignMessage = useSelector(
    (state: RootState) => state.CampaignMessage.listCampaignMessage
  );

  const selectedRepTime = useSelector(
    (state: RootState) => state.CampaignReport.selectedRepTime
  );

  useEffect(() => {
    // console.log("Masuk use effect update query Campaign >>>>>");
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  // useEffect(() => {
  //   console.log("Masuk use effect selected campaign >>>>>");

  // }, [campaignID])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [QUERIES.CAMPAIGN_REPORT_DETAIL, { ...state, campaignID, selectedRepTime }],
    async () => {
      let data: any = [];

      //Loading start at Campaign report list from Redux
      //Loading end at Campaign message list from DB
      // Get List Message
      if (campaignID) {
        data = await getCampaignMessages(
          state.sort,
          state.order,
          state.search,
          state.action,
          state.lastId,
          state.items_per_page,
          campaignID
          // campaign.client
        );
        if (data && data.length > 0) {
          if (selectedRepTime) {
            data = data.filter((message: any) => {
              return message?.isRepTime === selectedRepTime;
            });
          }
          console.log("CampMsgL after", data?.length);

          console.log(
            `Campaign message list from DB : ${JSON.stringify(data)}`
          );
          dispatch(CampaignMessageRedux.setListCampaignMessage(data));
        }
      }

      console.log("CampMsgL before", data?.length);

      let resultData = campaignMessageSorting(
        data,
        state.sort,
        state.items_per_page,
        state.action,
        state.order,
        state.search,
        state.page
      );

      CampaignQueryResponse = {
        data: resultData,
      };

      return Promise.resolve(CampaignQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
