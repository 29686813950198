import { TFunction } from "i18next";
import TemplateLanguage from "../app/models/Language";
export const KeyValues = {
  "customers.name": "Customer's Name",
  "customers.firstName": "Customer's First Name",
  "customers.lastName": "Customer's Last Name",
  "campaigns.campaignName": "Campaign's Name",
  "template.otp": "OTP Code",
  CustomizeText: "Customize text/URL",
};
//Campaign.Input.CustomizeText

export const formatTemplate = (templateLanguage: TemplateLanguage) => {
  let body = templateLanguage.body!.replaceAll(`\n`, `\\n`);
  templateLanguage.bodyParam?.forEach((param) => {
    const key = param.paramId;
    const value = param.textContent ?? "";
    // const regex = new RegExp(`{{${key}}}`, "g");
    body = body.replace(`{{${key}}}`, value);
    console.log(
      `formattedTemplate body: ${templateLanguage.body} ${key} ${value} ${body}`
    );
  });

  let headerText = "";
  if (templateLanguage.header) {
    const header = templateLanguage.header;
    headerText = header.text ? header.text.replaceAll(`\n`, `\\n`) : "";
    header.headerParam?.forEach((param) => {
      const key = param.paramId;
      const value = param.textContent ?? "";
      const regex = `{{${key}}}`;
      headerText = headerText.replace(regex, value);
      console.log(
        `formattedTemplate header: ${templateLanguage.header} ${key} ${value} ${regex} ${headerText}`
      );
    });
  }

  let buttons: string[] | undefined = undefined;
  if (templateLanguage.buttons) {
    buttons = templateLanguage.buttons.map((button) => button.text);
  }
  return { body, header: headerText, footer: templateLanguage.footer, buttons };
};

export const getTemplateButtonText = (
  template: TemplateLanguage,
  t: TFunction
) => {
  let tempBody = "";

  if (template) {
    if (template.body) {
      tempBody = template.body.replaceAll("\n", " ");
      // tempBody = template.body;
    }

    if (template.bodyParamCount) {
      template.bodyParam?.forEach((param, idx) => {
        const replaceIdx: string = `{{${idx + 1}}}`;

        const changedParam = { ...param };

        if (
          Object.keys(KeyValues).includes(changedParam.paramKey!) &&
          changedParam.paramKey !== `customText${idx + 1}`
        ) {
          tempBody = tempBody.replace(
            replaceIdx,
            t("Campaign.Input." + changedParam.paramKey)
          );
        } else {
          if (
            changedParam.paramKey === `customText${idx + 1}` &&
            (!changedParam.textContent || changedParam.textContent === "")
          ) {
            tempBody = tempBody.replace(
              replaceIdx,
              t("Campaign.Input.CustomizeText")
            );
          } else {
            // console.log("Ini custom check 2 >> "+changedParam.textContent);
            const newLine = changedParam.textContent?.includes("\n") ? " " : "";
            const newTextContent = changedParam.textContent?.replaceAll(
              "\n",
              " "
            );
            tempBody = tempBody.replace(
              replaceIdx,
              `${newLine} ${newTextContent}`
            );
          }
        }
      });
    }
  }

  return tempBody;
};

export const getData = (inKey: string, obj: Object) => {
  //TODO
  return undefined;

  /*
  let newObject: any = {}
  Object.entries(KeyValues).map(([key, value]) => {
    console.log("Masuk Map Object : " +key+" - "+inKey+" - "+value);
    
    if(key === inKey){
      console.log("Value: "+JSON.stringify(value));
      
      newObject = value;
      return newObject;
    }
  })
  return newObject
  */
};

export const CapitalizedLetter = (letter: string) => {
  return letter.charAt(0).toUpperCase() + letter.slice(1);
};