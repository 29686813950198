import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { KTSVG, useDebounce } from "../../../../../../../resources/helpers";
import { useQueryRequest } from "../../core/QueryRequestProvider";

const StorefrontListSearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { state, updateState } = useQueryRequest();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const { t } = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        search: debouncedSearchTerm,
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
    }
  }, [debouncedSearchTerm]);

  return (
    <div className={clsx("card-title flex-grow-1", { "w-100": isTabletOrMobile })}>
      <div className={clsx("d-flex align-items-center w-100 position-relative my-1", { "w-100": isTabletOrMobile } )}>
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className={clsx("form-control form-control-solid ps-14", {
            "w-100": isTabletOrMobile,
          })}
          placeholder={t("StorefrontList.Input.Search")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-testid="search-storefront"
        />
      </div>
    </div>
  );
};

export { StorefrontListSearchComponent };
