import db from "../db";
import firebase from "firebase/compat/app";
import { converter2 } from "./converter";
import Customer from "../app/models/Customer";

let firstVisible: firebase.firestore.QueryDocumentSnapshot;
let lastVisible: firebase.firestore.QueryDocumentSnapshot;
// let field = "firstName";


export const fetchCustomers = (
  search: string,
  limit: number,
  client: firebase.firestore.DocumentReference
) =>
  db
    .collection("customers")
    .where("client", "==", client)
    .where("isActive", "==", true)
    .orderBy("firstNameInsensitive")
    .startAt(search.toLowerCase())
    .endAt(search.toLowerCase() + "\uf8ff")
    // .orderBy("firstName", 'asc')
    // .startAt("firstName", ">=", search)
    // .endAt("firstName", "<", search + "\uf8ff")
    .limit(limit)
    .get()
    .then((snapshot) => {
      const customers = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      const checkVisible = snapshot.docs[snapshot.docs.length - 1];
      if (checkVisible !== undefined) {
        lastVisible = snapshot.docs[snapshot.docs.length - 1];
      }

      console.log("Contact - fetchCustomer", customers)
      return customers;
    });

    export const fetchCustomersByPhoneNumber = (
      search: string,
      limit: number,
      client: firebase.firestore.DocumentReference
    ) =>
      db
        .collection("customers")
        .where("client", "==", client)
        .where("isActive", "==", true)
        .orderBy("phoneNumber")
        .startAt(search.toLowerCase())
        .endAt(search.toLowerCase() + "\uf8ff")
        // .orderBy("firstName", 'asc')
        // .startAt("firstName", ">=", search)
        // .endAt("firstName", "<", search + "\uf8ff")
        .limit(limit)
        .get()
        .then((snapshot) => {
          const customers = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
          const checkVisible = snapshot.docs[snapshot.docs.length - 1];
          if (checkVisible !== undefined) {
            lastVisible = snapshot.docs[snapshot.docs.length - 1];
          }
          return customers;
        });

export const deleteCustomer = (id: string) =>
  db
    .collection("customers")
    .doc(id)
    .update({ isActive: false, updatedAt: new Date() });

//New Query Get All Data Customer by Companies
// export const fetchCustomersByCompanies = (
//   companies: Array<firebase.firestore.DocumentReference>
//   ) =>

export const fetchCustomersByClient = (
  client: firebase.firestore.DocumentReference
  ) =>
  db
    .collection("customers")
    .where("client", "==", client)
    .where("isActive", "==", true)
    // .orderBy("lastInteractionAt","desc")
    .withConverter(converter2<Customer>())
    .get()
    .then((snapshot) => {
      console.log("Contact snap", snapshot)
      const contactlist = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      console.log(`data contact list : `, contactlist);
      return contactlist;
    })
    .catch((error) => {
      console.log("Erorr fetchCustomersByCompanies : " + error);
      return [] as Customer[]
    });

export const fetchCustomersNext = (
  search: string,
  limit: number,
  client: firebase.firestore.DocumentReference
) =>
  db
    .collection("customers")
    .where("client", "==", client)
    .where("isActive", "==", true)
    .orderBy("firstNameInsensitive")
    .startAt(search.toLowerCase())
    .endAt(search.toLowerCase() + "\uf8ff")
    .startAfter(lastVisible)
    .limit(limit)
    .get()
    .then((snapshot) => {
      const customers = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      const checkVisible = snapshot.docs[snapshot.docs.length - 1];
      if (checkVisible !== undefined) {
        lastVisible = snapshot.docs[snapshot.docs.length - 1];
        firstVisible = snapshot.docs[0];
      }
      return customers;
    });

export const get = async (customerId: string) => {
  if (!customerId) return undefined;
  
  return await db
    .collection("customers")
    .withConverter(converter2<Customer>())
    .doc(customerId)
    .get()
    .then((snapshot) => ({ id: snapshot.id, ...snapshot.data() } as Customer))
    .catch((err) => {
      console.log("Error getting documents (getCustomerByID)", err);
      return undefined;
    });
}

export const getCustomerByPhoneNumber = (phoneNumber: string) => {

  return db
    .collection("customers")
    .where("isActive","==",true)
    .where("phoneNumber","==",phoneNumber)
    .withConverter(converter2<Customer>())
    .get()
    .then(snapshot => {
      const customer = snapshot.docs.map(doc => {
        return ({...doc.data(), id: doc.id})      
      })
      return customer;
    }
    );
}

export const createCustomer = async (contact: any, callback: any) => {
  return db
    .collection("customers")
    .add(contact)
    .then((docRef) => {
      console.log("New customer : " + docRef.id+" - ");
      callback(docRef.id);
      return Promise.resolve(docRef.id);
    })
    .catch((err) => {
      callback(null);
      console.log("Error create customer : ", err);
      return Promise.reject(err);
    });
};

export const updateCustomer = (contact: any) => {
  return db
    .collection("customers")
    .doc(contact.id)
    .update(contact)
    .then((doc) => {
      console.log("Updated: " + doc);
      return contact.id;
    })
    .catch((err) => {
      console.log("Error update customer : ", err);
      return undefined;
    });
};

//multi client
export const getCustomerByPhoneNoAndClient = async (
  phoneNumber: string,
  client: firebase.firestore.DocumentReference
) =>
  await db
    .collection("customers")
    .where("client", "==", client)
    .where("phoneNumber", "==", phoneNumber)
    .where("isActive", "==", true)
    .get()
    .then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        console.log("Document Exist");
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log("Erorr getCustomerByPhoneNoAndClient : ", err);
    });


//multi client
export const getCustomerByEmailAndClient = async (
  email: string,
  client: firebase.firestore.DocumentReference
) =>
  await db
    .collection("customers")
    .where("client", "==", client)
    .where("email", "==", email)
    .where("isActive", "==", true)
    .get()
    .then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        console.log("Document Exist");
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log("Erorr getCustomerByPhoneNoAndClient: ", err);
    });

export const insertCustomerFromCSV = async (contact:any[], companyId : string) => {
  const batch = db.batch()
  // const companyRef = createRef("company",companyId) 
  
    for (let index = 0; index < contact.length; index++) {
      const element = contact[index];
      const custRef = db.collection('customers').doc();
      batch.set(custRef,element)
    }
  
  await batch.commit()
  .then((result) => {
    console.log("Success Insert batch customers : " + result);
    return true;
  })
  .catch((err) => {
    console.log("Error Inser batch customers : ", err);
    return false;
  });
}

export const getCustomerDataByPhoneNoAndClient = async (
  phoneNumber: string,
  client: firebase.firestore.DocumentReference
) =>
  await db
  .collection("customers")
  .where("client", "==", client)
  .where("phoneNumber", "==", phoneNumber)
  .where("isActive", "==", true)
  .get()
  .then((snapshot) => {
    const customers = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return customers;
  })
  .catch((err) => {
    console.log("Error getCustomersByCompanyID : ", err);
    return [];
  });

export const getByID = async (customerId: string) => {
  if (!customerId) return undefined;
  
  return await db
    .collection("customers")
    .doc(customerId)
    .get()
    .then((snapshot) => ({ id: snapshot.id, ...snapshot.data() }))
    .catch((err) => {
      console.log("Error getting documents (getCustomerByID)", err);
      return undefined;
    });
}