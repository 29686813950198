import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody, KTSVG } from "../../../../../../resources/helpers";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../../setup/redux/store";
import { dataSorterAndDeleteBySameClient } from "../../../../../../util/ArraySnDSpecifiedField";
import { launchEmbeddedSignUp } from "../../../../../../util/facebook/action";
import CardItem from "../../../../../components/table/list/cardList/CardItem";
import * as lc from "../../../../../modules/localstorage/index";
import { WABAAccountListPagination } from "../components/pagination/WABAAccountListPagination";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import WABAAccount from "../../../../../models/WABAccount"
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { WABAAccountsColumns } from "./columns/_columns";
import { Table } from "../../../../../../types/ui";

export const WABAAccountViewDesktop = () => {
  const { t } = useTranslation();
  const wabaAccounts = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => {
  //   const accountWithSpan = wabaAccounts.map((item) => {
  //     if (item.client && item.client.id) {
  //       return { ...item, clientId: item.client.id };
  //     } else {
  //       return { ...item, clientId: "noclient" };
  //     }
  //   })
  //   console.log(`useMemo accounts ${accountWithSpan.length}`)

  //   return getAccountWithSpan(accountWithSpan);
    
  // }, [wabaAccounts]);

  
  const data = useMemo(() => wabaAccounts, [wabaAccounts]);
  const columns = useMemo(() => WABAAccountsColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const currentClient = lc.getItemLC(lc.LCName.Client);
  const clientid = currentClient?.id;
  const WABAList = useSelector(
    (state: RootState) => state.WABAAccountsList.listWABAAccount
  );

  const renderEmptyWaba = () => {
    return (
      <tr>
        <td colSpan={7}>
          <div className="d-flex flex-column text-center w-100 align-content-center justify-content-center">
            {WABAList.length > 0 && t("Contacts.Info.Empty")}
            {/* if null show button */}
            {WABAList.length < 1 && (
              <>
                <div className="fs-1 fw-bolder mt-15">
                  {t("WABAAccountList.EmptyList.Header")}
                </div>
                <div>{t("WABAAccountList.EmptyList.Subheader")}</div>
                <div className="mt-10">
                  <Link
                    data-testid="btnAddWABAAccount"
                    className="btn btn-primary"
                    to="/setting/waba-account/create/sign-up"
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-2"
                    />
                    {t("WABAAccountList.Button")}
                  </Link>
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map(column => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                console.log(row, "index ",i)
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              renderEmptyWaba()
            )}
          </tbody>
        </table>
      </div>
      <WABAAccountListPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  );
};

export const WABAAccountViewMobile = () => {
  const { t } = useTranslation();
  const wabaAccounts = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => wabaAccounts, [wabaAccounts]);
  const currentClient = lc.getItemLC(lc.LCName.Client);
  const clientid = currentClient?.id;
  const WABAList = useSelector(
    (state: RootState) => state.WABAAccountsList.listWABAAccount
  );

  console.log("wabaAccounts : ", wabaAccounts);
  console.log("data wabaAccounts: ", data);

  const accountsSortByClient = data.sort((a, b) =>
    a.client && b.client && a.client.id > b.client.id ? 1 : -1
  );
  const groupedAccounts = groupAccountByClient(accountsSortByClient);

  return (
    <>
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7">
        {WABAList.length > 0 && data.length < 1 && t("Contacts.Info.Empty")}
        {WABAList.length < 1 && (
          <div className="d-flex flex-column text-center w-100 align-content-center justify-content-center">
            {/* {t("Contacts.Info.Empty")} */}
            <div className="fs-1 fw-bolder mt-15">
              {t("WABAAccountList.EmptyList.Header")}
            </div>
            <div>{t("WABAAccountList.EmptyList.Subheader")}</div>
            <div className="mt-10">
              <Link
                data-testid="btnAddWABAAccount"
                className="btn btn-primary"
                to="/setting/waba-account/create/sign-up"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t("WABAAccountList.Button")}
              </Link>
            </div>
          </div>
        )}
        {Object.keys(groupedAccounts).map((key) => (
          <div key={key} className="p-4 bg-primary bg-opacity-25 rounded">
            <h1
              className="fs-1 mb-2 pb-2"
              style={{ borderBottom: "1px solid rgba(0, 156, 247, 0.2)" }}
            >
              {key}
            </h1>

            {groupedAccounts[key].map((waba, index) => (
              <div className="py-2" key={waba.id?.toString()}>
                <CardItem
                  id={waba.id}
                  isWABAList={true}
                  wabaData={waba}
                  index={index}
                />
              </div>
            ))}
          </div>
        ))}

        <WABAAccountListPagination />
      </div>
      {isLoading && <Loading />}
    </>
  );
};

const WABAAccountsTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? (
    <WABAAccountViewMobile />
  ) : (
    <WABAAccountViewDesktop />
  );
};



function groupAccountByClient(list: WABAAccount[]) {
  const map: Map<string, WABAAccount[]> = new Map();
  list.forEach((item) => {
    if (!item.client) {
      const collection = map.get("noclient");
      if (!collection) {
        map.set("noclient", [item]);
      } else {
        collection.push(item);
      }
    } else {
      const collection = map.get(item.client.id);
      if (!collection) {
        map.set(item.client.id, [item]);
      } else {
        collection.push(item);
      }
    }
  });

  const resultObject = Object.fromEntries(map);
  console.log("resultObject", resultObject);
  console.log("initialList", list);

  return resultObject;
}

function getAccountWithSpan(list: WABAAccount[]) : (WABAAccount & Table)[] {
  const resultObject = groupAccountByClient(list);

  let resultWithSpan: (WABAAccount & Table)[] = 
  Object.keys(resultObject).map(key => {
    const firstObject: WABAAccount & Table= {
      ...resultObject[key][0],
      span: resultObject[key].length,
    };
    return firstObject
  })
  /*
  Object.keys(resultObject).forEach((key) => {
    const firstObject: WABAAccount & Table= {
      ...resultObject[key][0],
      span: resultObject[key].length,
    };
    resultObject[key][0] = firstObject;
    resultWithSpan.push(...resultObject[key]);
  });
  */
  console.log("resultWithSpan", resultWithSpan);

  return resultWithSpan;
}

export { WABAAccountsTable };
