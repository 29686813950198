export interface Theme {
  name: string;
  loginSignInName: string;
  urlLogoImageWithText: string;
  urlLogoText: string;
  urlLogoImage: string;
  urlLogo: string;
}

export const themes: { [key: string]: Theme } = {
  sevendays: {
    name: "7days",
    loginSignInName: "SevenDays",
    urlLogoImageWithText: "/media/logos/logo-sevendays-with-text.svg",
    // urlLogoImage: "/media/logos/icon-header-wb.png",
    urlLogoImage: "/media/logos/icon-header-wb-sm.png",
    urlLogo: "/media/logos/icon-logo-7days.svg",
    urlLogoText: "/media/logos/logo-sevendays-only-text.png",
  },
  smile: {
    name: "Smile Interactive",
    loginSignInName: "Smile Interactive",
    urlLogoImageWithText: "/media/smile/login-logo-with-text.png",
    urlLogoImage: "/media/smile/login-logo-with-text.png",
    urlLogo: "/media/smile/login-logo.png",
    urlLogoText: "/media/smile/login-logo-with-text.png",
  },
};
