import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../resources/helpers";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../../setup/redux/store";
import { Campaign } from "../../../../../models/Campaign";
import * as lc from "../../../../../modules/localstorage";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { dateToString } from "./columns/CampaignCreatedAtCell";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { campaignsColumns } from "./columns/_columns";

const CampaignsReportDesktop = () => {
  const { t } = useTranslation();
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  const listData = useSelector(
    (state: RootState) => state.CampaignReport.listCampaignReport
  );
  let campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => campaigns, [campaigns]);
  const [data, setData] = useState(campaigns);
  const columns = useMemo(() => campaignsColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data: listData,
    });
  const [refresh, setRefresh] = useState(false);
  const [failOver, setFailOver] = useState<any[]>([]);

  // useEffect(()=>{
  //   console.log(`on change data cause ReduxCampaignReport`);
  //   const newCampaign = campaigns.find((c)=>c.id === campaignData.id);
  //   console.log(`campaigns : ${JSON.stringify(newCampaign?.campaignMessagesCount)}`)
  //   const updatedCampaigns = campaigns.map((c)=>{
  //     if(c.id === campaignData.id){
  //       return campaignData
  //     }else{
  //       return c;
  //     }
  //   })
  //   setData(updatedCampaigns);
  //   setRefresh(true);
  // },[campaignData.campaignMessagesCount, campaigns]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    setFailOver(lc.getItemLC(lc.LCName.Failover));

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);

  return (
    <>
      {isLoading && <Loading />}
      {refresh && <Loading />}
      {!refresh && (
        <KTCardBody className="py-0 h-auto overflow-hidden">
          {failOver && <div>New Failover appeared!</div>}
          <div
            className="table-responsive"
            style={{
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              {...getTableProps()}
            >
              <thead className="position-sticky top-0 bg-white">
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
                  {headers.map((column: ColumnInstance<Campaign>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<Campaign>, i) => {
                    prepareRow(row);
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("Contacts.Info.Empty")}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <CampaignPagination /> */}
        </KTCardBody>
      )}
    </>
  );
};

type ColumnProps = {
  columnName?: string;
  dataColumn?: any;
};

const ColumnReport: FC<ColumnProps> = ({ columnName, dataColumn }) => {
  return (
    <div className="d-flex flex-row mx-7">
      <div className="w-50 text-gray-700 mb-1">{columnName}</div>
      <div className="w-50 text-gray-700 mb-1 fw-bolder">{dataColumn}</div>
    </div>
  );
};

const CampaignsReportMobile = () => {
  const { t } = useTranslation();
  const campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => campaigns, [campaigns]);
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  const listData = useSelector(
    (state: RootState) => state.CampaignReport.listCampaignReport
  );
  const [data, setData] = useState(listData);
  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   console.log(`on change data cause ReduxCampaignReport`);
  //   const newCampaign = campaigns.find((c) => c.id === campaignData.id);
  //   console.log(
  //     `campaigns : ${JSON.stringify(newCampaign?.campaignMessagesCount)}`
  //   );
  //   const updatedCampaigns = campaigns.map((c) => {
  //     if (c.id === campaignData.id) {
  //       return campaignData;
  //     } else {
  //       return c;
  //     }
  //   });
  //   setData(updatedCampaigns);
  //   setRefresh(true);
  // }, [campaignData.campaignMessagesCount, campaigns]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);

  return (
    <>
      {isLoading && <Loading />}
      {refresh && <Loading />}
      {!refresh && (
        <div className="bg-primary bg-opacity-15 mb-5 mx-5 pb-5 rounded-bottom">
          {data.map((campaign: any) => {
            return (
              <>
                <ColumnReport
                  columnName={t("DLR.Column.SubmittedAt")}
                  dataColumn={
                    campaign.createdAt ? dateToString(campaign.createdAt) : ""
                  }
                />
                <ColumnReport
                  columnName={t("DLR.Column.Published")}
                  dataColumn={
                    campaign.campaignMessagesCount?.countCreated
                      ? campaign.campaignMessagesCount?.countCreated
                      : 0
                  }
                />
                <ColumnReport
                  columnName={t("DLR.Column.Sent")}
                  dataColumn={
                    campaign.campaignMessagesCount?.countSent
                      ? campaign.campaignMessagesCount?.countSent
                      : 0
                  }
                />
                <ColumnReport
                  columnName={t("DLR.Column.Delivered")}
                  dataColumn={
                    campaign.campaignMessagesCount?.countDelivered
                      ? campaign.campaignMessagesCount?.countDelivered
                      : 0
                  }
                />
                <ColumnReport
                  columnName={t("DLR.Column.Read")}
                  dataColumn={
                    campaign.campaignMessagesCount?.countRead
                      ? campaign.campaignMessagesCount?.countRead
                      : 0
                  }
                />
                <ColumnReport
                  columnName={t("DLR.Column.Failed")}
                  dataColumn={
                    campaign.campaignMessagesCount?.countFailed
                      ? campaign.campaignMessagesCount?.countFailed
                      : 0
                  }
                />
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

const CampaignsReport = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? (
    <CampaignsReportMobile />
  ) : (
    <CampaignsReportDesktop />
  );
};

export { CampaignsReport };
