import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import InitialState, { AddMemberAction, Member } from "./AddMemberRedux";
import { Timestamp } from "../../../../db";
import User from "../../../models/User";
import * as registerUserData from "./RegisterRedux";

const initialState: InitialState = {
  userId: "",
  submittedAt: undefined,
  members: [{ id: 0 }],
  responseMessage: "",
};

export const AddMemberSlice = createSlice({
  name: AddMemberAction,
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setSubmittedAt: (state, action: PayloadAction<any>) => {
      state.submittedAt = action.payload;
    },
    setMembers: (state, action: PayloadAction<Member[]>) => {
      state.members = action.payload;
    },
    addMembers: (state, action: PayloadAction<any>) => {
      let tempArray = state.members as any;
      if (!tempArray.some((el: any) => el.id === action.payload.id)) {
        tempArray.push(action.payload);
      } else {
        tempArray.map((item: any) => {
          if (item.id === action.payload.id) {
            item.email = action.payload.email;
            item.role = action.payload.role;
          }
        });
      }
      state.members = tempArray;
    },
    deleteMembers: (state, action: PayloadAction<any>) => {
      let newData = state.members.filter((data) => data.id !== action.payload);
      state.members = newData;
    },
    reverseDeleteMembers: (state, action: PayloadAction<any>) => {
      console.log(action.payload, "payload")
      if(action.payload.length > 0){
        let newData = state.members.filter(data => action.payload.includes(data.id));
        state.members = newData;
      }
    },
    setResponseMessage: (state, action: PayloadAction<string>) => {
      state.responseMessage = action.payload;
    },
    cleanReduxAddMembers: (state, action: PayloadAction<boolean>) => {
      state.members = [{ id: 0 }];
      state.submittedAt = undefined;
      state.userId = "";
      state.responseMessage = "";
    },
  },
});

export const {
  setUser,
  setSubmittedAt,
  setMembers,
  addMembers,
  deleteMembers,
  reverseDeleteMembers,
  cleanReduxAddMembers,
  setResponseMessage,
} = AddMemberSlice.actions;

export default AddMemberSlice.reducer;
