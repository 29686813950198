import format from "date-fns/format";
import parse from "date-fns/parse";
import { Column } from "react-table";
import { Campaign } from "../../../../../../models/Campaign";
import { CampaignCreatedAtCell } from "./CampaignCreatedAtCell";
import { CampaignCustomHeader } from "./CampaignCustomHeader";
import { CampaignInfoCell } from "./CampaignInfoCell";
import { CampaignMessagesCountCell } from "./CampaignMessagesCountCell";
import { CampaignReportActionCell } from "./CampaignReportActionCell";
import { CampaignStringCell } from "./CampaignStringCell";

const repeatColumns: ReadonlyArray<Column<Campaign>> = [
  // {
  //   Header: (props) => (
  //     <CampaignCustomHeader
  //       tableProps={props}
  //       title="Campaign.Input.CampaignName"
  //       className="min-w-125px"
  //       data-testid="campaignName"
  //     />
  //   ),
  //   id: "campaignName",
  //   Cell: ({ ...props }) => (
  //     <CampaignInfoCell campaign={props.data[props.row.index]} />
  //   ),
  // },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.SubmittedAt"
        className="min-w-125px"
        data-testid="dateSent"
      />
    ),
    id: "dateSent",
    Cell: ({ ...props }) => {
      const dateSent = props.data[props.row.index].dateSent;

      return (
        <CampaignStringCell
          id="dateSent"
          content={
            dateSent
              ? format(parse(dateSent, "yyyy-MM-dd", new Date()), "d MMM yyyy")
              : ""
          }
        />
      );
    },
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Published"
        className="min-w-125px"
      />
    ),
    id: "published",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        id="messagecount"
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countCreated
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Sent"
        className="min-w-125px"
        data-testid="count-sent"
      />
    ),
    id: "sent",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        id="count-sent"
        messagesCount={
          props.data[props.row.index].campaignRepeatCount?.countSent
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Delivered"
        className="min-w-125px"
        data-testid="count-delivered"
      />
    ),
    id: "delivered",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        id="count-delivered"
        messagesCount={
          props.data[props.row.index].campaignRepeatCount?.countDelivered
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Read"
        className="min-w-125px"
        data-testid="count-read"
      />
    ),
    id: "read",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        id="count-read"
        messagesCount={
          props.data[props.row.index].campaignRepeatCount?.countRead
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Failed"
        className="min-w-125px"
        data-testid="count-failed"
      />
    ),
    id: "undelivered",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        id="count-failed"
        messagesCount={
          props.data[props.row.index].campaignRepeatCount?.countFailed
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Actions"
        className="min-w-125px"
        data-testid="report-action"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <CampaignReportActionCell
        id="report-action"
        campaign={props.data[props.row.index]}
      />
    ),
  },
];

export { repeatColumns };
