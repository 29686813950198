import React from "react";
import clsx from "clsx";
import "../css/borderdashed.css";
import { findByText } from "@testing-library/react";

interface ButtonProps {
  /**
   * base color of the button with hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbs?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * light style color of the button with hover, defined with $theme-light-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnlg?:
    | "primary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark"
    | "light-primary"
    | "light-success"
    | "light-info"
    | "light-warning"
    | "light-danger"
    | "light-dark";
  /**
   * background style color of the button without hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbg?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * className to be added to the button
   */
  cName?: string;
  /**
   * className to be added to the button
   */
  biIcon?: string;
  /**
   * props for the button
   */
  isMobile?: boolean;
  onChangeFn?: any;
  isHidden?: boolean;
  isVideoButton?: boolean;
  isMultipleUpload?: boolean;
  idRadioButton?: string;
  checked?: boolean;
  isInvalidUpload?: boolean;
  refId?: string;
  accept?: string;
  noMargin?: boolean;
  fixWidth?: boolean;
  /**
   * props for the button
   */
  [propName: string]: any;
}

/**
 * Primary UI component for user interaction
 */
function ButtonUploadMedia({
  btnbs,
  btnlg,
  btnbg,
  cName,
  biIcon,
  isMobile,
  onChangeFn,
  isHidden,
  isVideoButton,
  isMultipleUpload,
  idRadioButton,
  checked,
  isInvalidUpload,
  refId,
  accept,
  noMargin = false,
  fixWidth = false,
  ...props
}: ButtonProps) {
  function styleSelect(): string {
    if (btnbs) {
      return `btn btn-${btnbs}`;
    }
    if (btnlg) {
      return `btn btn-lg btn-${btnlg}`;
    }
    if (btnbg) {
      return `btn btn-bg-${btnbg}`;
    }
    return `btn`;
  }
  return (
    <div>
      <label
        className={clsx(
          isVideoButton && !isMobile ? noMargin ? "m-0":  "ms-5" : "",
          "d-flex cursor-pointer", {
            "d-none" : isHidden,
            "button-dashed-mobile" : isMobile,
            "button-dashed-desktop w-175px" : !isMobile && fixWidth,
            "button-dashed-desktop" : !isMobile && !fixWidth
          }
        )}
        style={{
          borderColor: isInvalidUpload ? "red" : "#787889",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-testid={"MediaUploadLabel-" + props.datatestid}
      >
        {/* <i className="bi bi-card-image display-1"></i> */}
        <i
          className={clsx(cName)}
          style={{ color: "#787889" }}
          data-testid={"MediaUploadIcon-" + props.datatestid}
        ></i>
        <input
          accept={accept}
          ref={refId}
          id="chat-media"
          onChange={onChangeFn}
          type="file"
          name="attachment"
          multiple={isVideoButton ? false : true}
          style={{ display: "none" }}
          data-testid={"MediaUploadInput-" + props.datatestid}
        />
        {props.children}
      </label>
    </div>
  );
}

export default ButtonUploadMedia;
