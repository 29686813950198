import clsx from "clsx";
import { FC } from "react";
import { CellColoumnsProp } from "./_columns";

const CustomCell: FC<CellColoumnsProp> = ({ cell, value, cName }) => {
  return (
    <td {...cell.getCellProps()} className={clsx(cName)}>
      {value}
    </td>
  );
};

export default CustomCell;
