import { useMediaQuery } from "react-responsive";
import Select from "../../../../../styles/components/Select";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const HeaderComponent: React.FC = () => {
  const conversationTypes = [{ label: "Conversation Analyitcs", value: "*" }];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  return (
    <Fragment>
      {isTabletOrMobile && (
        <nav
          className="navbar navbar-expand-lg navbar-light bg-white py-3 my-3 mx-auto"
          data-testid="HeaderComponent"
        >
          <div className="container row">
            <div className="col-6">
              <span className="p-0 m-0 display-6">
                {t("HeaderBar.Button.Dashboard")}
              </span>
            </div>
            <div className="col-6">
              <Select
                data-testid="inputConversationType"
                name="type"
                options={conversationTypes}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={"Conversation Analytics"}
                onChange={(event: any) => {
                  console.log(event);
                }}
                value={conversationTypes.filter(
                  (option) => option.value === conversationTypes[0].value
                )}
              />
            </div>
          </div>
        </nav>
      )}
    </Fragment>
  );
};

export default HeaderComponent;
