export const badgeColorMap: { [key: string]: string } = {
  success: "success",
  failed: "danger",
  timeout: "danger",
};

export const statusMap: { [key: string]: string } = {
  success: "Common.Modal.Column.Contents.Status.Success",
  failed: "Common.Modal.Column.Contents.Status.Failed",
  timeout: "Common.Modal.Column.Contents.Status.Timeout",
};

export const statusLevelMap: { [key: string]: number } = {
  success: 1,
  failed: 3,
  timeout: 2,
};
