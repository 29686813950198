import {ListViewProvider, useListView} from '../../core/list/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TemplateHeader} from './components/header/TemplateHeader'
import {KTCard} from '../../../../resources/helpers/components/KTCard';
import {TemplateTable} from './table/TemplateTable';

const TemplateList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <TemplateHeader />
        <TemplateTable />
        {/* <CIQsTable /> */}
      </KTCard>
    </>
  )
}

const TemplateWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TemplateList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TemplateWrapper}
