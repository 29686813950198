import { useListView } from "../../core/ListViewProvider";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import "../../../../../../styles/css/dragdropfile.scss";
import { checkFile, formatSize } from "../../core/CampaignTargetUtil";
import * as XLSX from "xlsx";
import {
  phoneValidator,
  emailValidator,
  letterValidator,
} from "../../../../../modules/validators/InputValidator";

import { format } from "date-fns";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import React from "react";

const TargetListToolbar = () => {
  const attachmentCSVRef = useRef<HTMLInputElement>(null);
  const [company, setCompany] = useState("");
  const { refetch } = useQueryResponse();
  const { state, updateState } = useQueryRequest();
  const [file, setFile] = useState<File>();
  const [data, setData] = useState<any>([]);
  const [errMessage, setErrMessage] = useState<string[]>([]);
  const [fileSize, setFileSize] = useState<string>("");
  const [maxSize, setMaxSize] = useState<string | number | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [inputFileName, setInputFileName] = useState<string>("");
  const [inputcontentType, setInputContentType] = useState<string>("");
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const { setItemIdForUpdate } = useListView();
  const openAddUserModal = () => {
    setItemIdForUpdate(null);
  };
  const selectCompany = document.getElementById("import-customer-company");

  const removeFile = () => {
    setFile(undefined);
    setFileSize("");
    setInputFileName("");
    setInputContentType("");
    setProgress(0);
    setEmptyData();
    setErrMessage([]);
    setCompany("");
    selectCompany?.setAttribute("value", "");
  };

  const cancel = (withRefresh?: boolean) => {
    // console.log("Masuk cancel >>>");
    if (withRefresh) {
      removeFile();
      refetch();
    }
  };

  const handleChangeCompany = function (event: any) {
    let value = event.target.value;
    // console.log("Change value company : " + value);
    setCompany(value);
  };

  const downloadCSV = (data: any) => {
    var str =
      "First Name,Last Name,Email,Phone Number,Created,Updated,Status,Error Message" +
      "\r\n";
    for (var i = 0; i < data.length; i++) {
      var line = "";
      for (var index in data[i]) {
        if (line != "") line += ",";
        line += data[i][index];
      }
      str += line + "\r\n";
    }

    // console.log("Download file csv >>>> " + str);
    const url = window.URL.createObjectURL(new Blob([str]));
    const link = document.createElement("a");
    link.href = url;
    let reportName =
      "Report-ImportCustomers_" +
      format(new Date(), "d-MMM-yyyy[HHmmss]") +
      ".csv";
    // console.log("Report name : " + reportName);
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // handle drag events
  // const handleDrag = function (e: any) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e.type === "dragenter" || e.type === "dragover") {
  //     setDragActive(true);
  //   } else if (e.type === "dragleave") {
  //     setDragActive(false);
  //   }
  // };

  // triggers when file is dropped
  // const handleDrop = async function (e: any) {
  //   // setFile(undefined);
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDragActive(false);

  //   if (e.dataTransfer.files && e.dataTransfer.files[0]) {
  //     setFile(e.dataTransfer.files[0]);
  //   }
  // };

  // triggers when file is selected with click
  const handleChange = async function (e: any) {
    // setFile(undefined);
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      setFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    // if(inputRef && inputRef.current){
    //   inputRef.current.click();
    // }
  };

  const importCSVFailed = () => {
    removeFile();
    const successFriendAddAlertNotif = document.getElementById(
      "failedProcessAlertNotif"
    ) as HTMLDivElement;
    successFriendAddAlertNotif.innerHTML = t("IC.Alert.Failed").replace(
      `<<name>>`,
      ""
    );
    const buttonSuccessAlert = document.getElementById("triggerFailedProcess");
    if (buttonSuccessAlert) {
      buttonSuccessAlert.click();
    }
  };

  useEffect(() => {
    if (file) {
      const size: number = file.size;
      setInputFileName(file.name);
      setFileSize(formatSize(size));
      setInputContentType(file.type);
      //Check File Type
      const tempArrFileType: string = file.type;
      const arryFileType = tempArrFileType.split("/");
      // console.log("File Type : " + tempArrFileType);
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        //return error
        setErrMessage((prevNotifications) => [
          "HC.Error.InvalidFiletype",
          ...prevNotifications,
        ]);
        setInputFileName(t("HC.Error.InvalidFiletype"));
        setProgress(100);
        return;
      } else {
        // const [result, error] = checkFile2("image","jpg",5);
        const [result, error, maxSize] = checkFile(
          arryFileType[0],
          arryFileType[1],
          size
        );
        console.log("Error : " + error);
        if (error !== undefined) {
          if (maxSize !== 0) {
            setErrMessage((prevNotifications) => [
              "HC.File." + error,
              ...prevNotifications,
            ]);
            setMaxSize(maxSize);
            setInputFileName(
              t(["HC.File." + error]).replace("<<size>>", maxSize + " MB")
            );
            setProgress(100);
          } else {
            setErrMessage((prevNotifications) => [
              "HC.Error." + error,
              ...prevNotifications,
            ]);
            setInputFileName(t(["HC.Error." + error]));
            setProgress(100);
          }
          return;
        }
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (evt) => {
        if (evt !== null && evt.target !== null) {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_csv(ws, {});
          // console.log("Ini Adalah datanya: " + data);
          const dataSplit: string[] = data.split("\n");
          validateCSV(dataSplit);
        }
      };

      reader.onprogress = (evt) => {
        // console.log("Masuk on progress >>");
        if (evt.lengthComputable) {
          // console.log("File lenght computable >>");
          const percent = Math.round((evt.loaded / evt.total) * 100);
          // console.log("progress now >> " + percent);
          setProgress(percent);
        }
      };
    } else {
      setEmptyData();
      setIsSuccess(false);
    }
  }, [file]);

  const setEmptyData = () => {
    let updatedMap: any[] = [];
    for (let index = 0; index < 50; index++) {
      let item = {
        firstName: " ",
        lastName: " ",
        email: " ",
        phoneNumber: " ",
      };
      updatedMap.push(item);
    }
    setData(updatedMap);
  };

  const validateCSV = (data: string[]) => {
    // console.log("Masuk Validate Data");
    let resultData: any[] = [];
    if (data.length <= 0) {
      setErrMessage((prevNotifications) => [
        "IC.Error.InvalidFormat",
        ...prevNotifications,
      ]);
      return;
    } else {
      const dataHeader: string[] = data[0].split(",");
      //check header is firstName, lastName, lastName, and phoneNumber

      const mandatoryColumn = ["First Name", "Phone Number"];
      const error = [];

      //check mandatory column
      for (let index = 0; index < mandatoryColumn.length; index++) {
        const manColumn = dataHeader.find(
          (header) => mandatoryColumn[index] === header
        );
        if (manColumn === undefined) {
          error.push(mandatoryColumn[index]);
        }
      }

      if (error.length > 0) {
        let errColumn = "";
        for (let index = 0; index < error.length; index++) {
          const element = error[index];
          if (index > 0) {
            errColumn += "," + element;
          } else {
            errColumn += element;
          }
        }
        setErrMessage((prevNotifications) => [
          "IC.Error.InvalidFileContent",
          ...prevNotifications,
        ]);
        return;
      }

      //Check & save Index of Header
      const column = ["First Name", "Last Name", "Phone Number", "Email"];
      let columnIndex: any[] = [];
      for (let j = column.length; j >= 0; j--) {
        const columnName = column[j];
        for (let index = 0; index < dataHeader.length; index++) {
          const headerColumn = dataHeader[index];
          if (headerColumn === columnName) {
            columnIndex.push({ index: index, columnName: columnName });
          }
        }
      }

      //Read Data & Create Data Customer Model
      let arrPhoneNumber: string[] = [];
      const arrError: string[] = [];
      const dateTime = new Date();

      if (data.length <= 1) {
        setErrMessage((prevNotifications) => [
          "IC.Error.InvalidFileContent",
          ...prevNotifications,
        ]);
        return;
      }

      for (let i = 1; i < data.length; i++) {
        const element: string[] = data[i].split(",");
        let dataCust = {
          firstName: "",
          lastName: "",
          email: "",
          isActive: true,
          phoneNumber: "",
          createdAt: dateTime,
          updatedAt: dateTime,
          imported: true,
          company: undefined,
        };
        for (let j = 0; j < element.length; j++) {
          for (let k = 0; k < columnIndex.length; k++) {
            const col = columnIndex[k];
            // console.log("JSONnya --->> " + JSON.stringify(col));
            if (col.index === j) {
              if (col.columnName === "First Name") {
                // firstName Validator
                if (!letterValidator(element[j].replace(/ /g, ""))) {
                  // setErrMessage("IC.Error.InvalidFirstName");
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidFirstName"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidFirstName");
                  }
                } else {
                  dataCust.firstName = element[j];
                }
              }
              if (col.columnName === "Last Name") {
                // lastName Validator
                if (
                  element[j] &&
                  element[j] !== "" &&
                  !letterValidator(element[j].replace(/ /g, ""))
                ) {
                  // setErrMessage("IC.Error.InvalidLastName");
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidLastName"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidLastName");
                  }
                } else {
                  dataCust.lastName = element[j];
                }
              }
              if (col.columnName === "Email") {
                // email Validator
                if (
                  element[j] &&
                  element[j] !== "" &&
                  !emailValidator(element[j].replace(/ /g, ""))
                ) {
                  // setErrMessage("Login.Error.WrongEmail");
                  const errorCheck = arrError.find(
                    (error) => error === "Login.Error.WrongEmail"
                  );
                  if (!errorCheck) {
                    arrError.push("Login.Error.WrongEmail");
                  }
                } else {
                  dataCust.email = element[j];
                }
              }
              if (col.columnName === "Phone Number") {
                let phoneNumber = element[j].replace(/ /g, "");
                if (phoneNumber !== "" && phoneNumber!.startsWith("0")) {
                  phoneNumber = "62" + phoneNumber!.substring(1);
                }
                if (!phoneValidator(phoneNumber)) {
                  // setErrMessage("IC.Error.InvalidPhoneNumber");
                  const errorCheck = arrError.find(
                    (error) => error === "IC.Error.InvalidPhoneNumber"
                  );
                  if (!errorCheck) {
                    arrError.push("IC.Error.InvalidPhoneNumber");
                  }
                } else {
                  if (arrPhoneNumber.includes(phoneNumber)) {
                    // setErrMessage("IC.Error.DuplicatePhoneNumber");
                    const errorCheck = arrError.find(
                      (error) => error === "IC.Error.DuplicatePhoneNumber"
                    );
                    if (!errorCheck) {
                      arrError.push("IC.Error.DuplicatePhoneNumber");
                    }
                  } else {
                    arrPhoneNumber.push(phoneNumber);
                    dataCust.phoneNumber = phoneNumber;
                  }
                }
              }
            }
          }
        }
        //Save Data to List Data Customer
        if (arrError.length <= 0) {
          resultData.push(dataCust);
        } else {
          // let errormsg = "";
          // for (let index = 0; index < arrError.length; index++) {
          //   const element = t(arrError[index]);
          //   if (index === arrError.length) {
          //     errormsg += element;
          //   } else {
          //     errormsg += element + "";
          //   }
          // }
          setErrMessage(arrError);
          return;
        }
      }

      // console.log("Ini adalah set DATA : " + JSON.stringify(resultData));
      setData(resultData);
      setIsSuccess(true);
    }
  };

  return (
    <div
      className="d-flex justify-content-end"
      data-kt-user-table-toolbar="base"
    >
      {/* <TargetsListFilter /> */}
      {/* Filter */}
      {/* <button type="button" className="btn btn-light-primary me-3 text-dark">
        <span className="bi bi-funnel-fill" />
        {t("Common.Filter")}
      </button> */}
      {/* Add */}
      {/* <button
        type="button"
        className="btn btn-primary"
        data-bs-dismiss="modal"
        aria-label="Close"
        // onClick={openAddUserModal}
      >
        Add
      </button> */}
    </div>
  );
};

export { TargetListToolbar };
