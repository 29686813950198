import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../../core";
import MemberList from "./member-list/MemberList";

const MemberListPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("CD.Info.Settings"),
      path: "/setting/user-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
       {t("UserManagement.Title")}
      </PageTitle>
      <MemberList />
    </>
  );
};

export default MemberListPage;
