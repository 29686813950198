import {sendUploadFile} from "./connection"
export const UploadFile = async(fileurl: string, accountId: string, filetype: string, filename: string) => {
  const JSON_UPLOAD_FILE = `{
    "url" : "${fileurl}",
    "accountId" : "${accountId}",
    "fileType" : "${filetype}",
    "fileName" : "${filename}"
  }`;
  try {
    return await sendUploadFile(JSON_UPLOAD_FILE);
  } catch (err) {
    console.log("Error Request server : "+err);
    return {error: {
      message: `Service Unavailable`,
      code: `503`,}
    };
  }
}