import InitialState, { TemplateListAction } from "./TemplateListRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Template from "../../../models/Template";

const initialState: InitialState = {
  listTemplate: [],
  countTemplate: 0,
  currentPageTemplate: 0,
  maxPageTemplate: 0,
  arrayTemplatePagination: [],
};

export const TemplateSlice = createSlice({
  name: TemplateListAction,
  initialState: initialState,
  reducers: {
    setListTemplate: (state, action: PayloadAction<Template[]>) => {
      state.listTemplate = action.payload;
      state.countTemplate = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayTemplatePagination = arr;
      } else {
        state.arrayTemplatePagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayTemplatePagination: (state, action: PayloadAction<string[]>) => {
      state.arrayTemplatePagination = action.payload;
    },

    setCountTemplate: (state, action: PayloadAction<number>) => {
      state.countTemplate = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageTemplate = action.payload;
    },

    setMaxPageTemplate: (state, action: PayloadAction<number>) => {
      state.maxPageTemplate = action.payload;
    },

    updateListTemplate: (state, action: PayloadAction<Template>) => {
      let newListTemplate: Template[] = [];
      const newTemplate = action.payload;
      newListTemplate.push(newTemplate);

      for (let index = 0; index < state.listTemplate.length; index++) {
        if (state.listTemplate[index].id === newTemplate.id) {
          console.log(
            "Template ID from Notif: " + state.listTemplate[index].id
          );
        } else {
          newListTemplate.push(state.listTemplate[index]);
        }
      }
      state.listTemplate = newListTemplate;
      state.countTemplate = newListTemplate.length;
      if (newListTemplate.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(newListTemplate.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayTemplatePagination = arr;
      } else {
        state.arrayTemplatePagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(newListTemplate.length / 10) + "",
        ];
      }
    },

    deleteItemListTemplate: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listTemplate.filter(
        (data) => data.id !== action.payload
      );
      state.listTemplate = newDataCollab;
      state.countTemplate = newDataCollab.length;
    },
    cleanReduxTemplate: (state, action: PayloadAction<boolean>) => {
      state.listTemplate = [];
      state.countTemplate = 0;
      state.currentPageTemplate = 0;
      state.maxPageTemplate = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListTemplate,
  deleteItemListTemplate,
  updateListTemplate,
  setCurrentPage,
  setArrayTemplatePagination,
  setMaxPageTemplate,
  setCountTemplate,
  cleanReduxTemplate,
} = TemplateSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default TemplateSlice.reducer;
