import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import Language from "./Language";

interface Template {
  id?: string;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  company?: DocumentReference;
  account?: DocumentReference;
  companies?: DocumentReference[];
  accounts?: DocumentReference[];
  templateName?: string;
  category?: string;
  isActive?: boolean;
  //have sub collection template
  languages?: templateLanguages[];
  languagesModel?: Language[];
  status?: string;
  companyName?: string;
  companyAvatar?: string;
  priority?: number;
  interactiveType?: string;
  isTemplateRegistrationConfirmation?: boolean;
  client?: DocumentReference
  lastSyncAt?: firebase.firestore.Timestamp;
}

export interface templateLanguages {
  templateStatus: string;
  templateLanguage: string;
  account?: DocumentReference;
}

export const templateState = {
  update: "update",
  created: "created",
  campaigncreated: "campaigncreated",
};

export default Template;
