import { Campaign as CampaignModel } from "../../../models/Campaign";

interface InitialState {
  listCampaign: CampaignModel[];
  countCampaign: number;
  currentPageCampaign: number;
  maxPageCampaign: number;
  arrayCampaignPagination: string[];

}
const CampaignListAction: string = "CampaignListAction";

export type unsubFN = () => void;

export default InitialState;
export { CampaignListAction };
