import clsx from "clsx";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import useFirestoreData, { FirestoreQueryState } from "../../../../../../hooks/useFirestoreData";
import { KTCardBody } from "../../../../../../resources/helpers/components/KTCardBody";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { Order, OrderStatus7Days } from "../../../../../models/Order";
import { isSameStatusValue } from "../../OrderPage";
import Pagination from "../components/Pagination";
import { useFirestoreTable } from "../context/TableContext";
import orderColumns from "./columns/_columns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup/redux/store";
import { setResponseExportOrder } from "../../../../../components/modal/ModalExportOrder/redux/ModalExportOrderSlice";

const statusWithAction: string[] = [OrderStatus7Days.NewOrder];
const statusProcessing: string[] = [OrderStatus7Days.Processing];
const statusWithLimit: string[] = [
  OrderStatus7Days.NewOrder,
  OrderStatus7Days.Processing,
  OrderStatus7Days.ReadyToShip,
];

export default function OrderTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalExportState = useSelector((state: RootState) => state.ModalExportOrder);
  const columns = useMemo(() => orderColumns, []);
  const tableRef = useRef<HTMLDivElement>(null);

  const {
    data: orderData,
    isLoading,
    isFetching,
    tableState,
    responseExportOrder,
    checkifAvailableToProcessOrder,
    refreshData,
    setSelectedItems,
  } = useFirestoreTable();
  const shipmentDeadlines = useSelector((state: RootState) => state.Shipments.shipmentDeadlines);
  const statusFilter = tableState.filters?.find((fil) => fil.field === "exportStatus")?.value;

  const displayColumnID = columns
    .filter((col) => {
      if (
        !(
          col.id === "action" ||
          col.id === "selection" ||
          col.id === "shippingLimit" ||
          col.id === "orderPriorityNumber"
        )
      ) {
        return true;
      }
      if (
        (col.id === "action" || col.id === "selection" || col.id === "orderPriorityNumber") &&
        statusFilter &&
        (isSameStatusValue(statusFilter, statusWithAction) ||
          isSameStatusValue(statusFilter, statusProcessing))
      ) {
        return true;
      }
      if (
        col.id === "shippingLimit" &&
        statusFilter &&
        isSameStatusValue(statusFilter, statusWithLimit)
      ) {
        return true;
      }

      return false;
    })
    .map((col) => col.id);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: orderData?.items || [],
  });

  useEffect(() => {
    if (responseExportOrder) {
      console.log("responseExportOrder", { responseExportOrder });
      dispatch(setResponseExportOrder(responseExportOrder));
    }
  }, [responseExportOrder, dispatch]);

  // Refresh data if responseExportOrder is undefined
  // it means that the modal is closed
  useEffect(() => {
    if (!modalExportState.responseExportOrder) {
      refreshData();
      setSelectedItems([]);
    }
  }, [modalExportState.responseExportOrder]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo({ top: 0 });
    }
  }, [tableState]);


  return (
    <>
      {/* <div className="card-body"> */}
      {(isLoading || isFetching) && <Loading />}
      <div
        ref={tableRef}
        className="table-responsive px-2"
        style={{
          height: "95%",
          overflowY: "scroll",
        }}
        id="order-list-table"
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead className="position-sticky top-0 bg-white" style={{ zIndex: 200 }}>
            <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Order>, headerIndex) => {
                if (displayColumnID.includes(column.id)) {
                  return column.render("Header", {
                    ...column.getHeaderProps(),
                    key: headerIndex,
                  });
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold">
            {rows.length > 0
              ? rows.map((row: Row<Order>, rowIndex) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  rowProps.key = rowIndex;
                  const isStatusDifferent = row.original.status !== row.original.exportStatus;
                  const exportStatus = row.original.exportStatus;
                  const data = row.original;
                  const isAvailableToProcess =
                    exportStatus !== OrderStatus7Days.NewOrder ||
                    checkifAvailableToProcessOrder(shipmentDeadlines, data);
                  return (
                    <tr
                      {...rowProps}
                      // className={clsx({
                      //   "text-secondary": !isAvailableToProcess,
                      // })}
                      // className={clsx({
                      //   "bg-warning bg-opacity-10": isStatusDifferent,
                      // })}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        if (displayColumnID.includes(cell.column.id)) {
                          return cell.render("Cell", {
                            ...cell.getCellProps(),
                            key: cellIndex,
                            isAvailableToProcess,
                          });
                        }
                        return null;
                      })}
                    </tr>
                  );
                })
              : !(isLoading || isFetching) && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("OrderList.NoData")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      {/* </div> */}
    </>
  );
}
