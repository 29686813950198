import Language from "../../app/models/Language";
import Template from "../../app/models/Template";
import {
  sendBlastMessage,
  sendCreateTemplate,
  sendMessage,
  sendUpdateTemplate,
} from "./connection";

interface ISendRequestBlastMessage { 
  responseCode : string; 
  responseJson: {
    resultCode: string;
    message: string;
  };
}

export const sendRequestMessage = async (
  type: string,
  company: string,
  from: string,
  to: string,
  templateName: string,
  templateLanguage: string,
  bodyParameters: Array<string>,
  callback: any
) => {
  console.log("sendRequestMessage");
  const component = () => {
    let jsonComponent = ``;
    //if any parameter found
    if (bodyParameters.length > 0) {
      jsonComponent = `
      , "components" : [
        <<component>>
      ]
      `;
    }
    //TODO: Add more components for header

    //
    if (bodyParameters.length > 0) {
      jsonComponent = jsonComponent.replace(
        "<<component>>",
        `{
        "type" : "body",
        "parameters" : [
          ${bodyParameters.map((parameter) => `"${parameter}"`).join(",")}
        ]
      }, <<component>>`
      );
    }
    jsonComponent = jsonComponent.replace(", <<component>>", "");
    return jsonComponent;
  };

  const jsonMessage = `
  {
    "type" : "${type}",
    "${type}" : {
      "company" : "${company}",
      "from" : "${from}",
      "to" : "${to}",
      "type" : "template",
      "template" : {
        "name" : "${templateName}",
        "language" : "${templateLanguage}"
        ${component()}
      }
    }
  }`;

  // console.log(jsonMessage);

  return await sendMessage(
    jsonMessage,
    (responseCode: string, responseJson: JSON) => {
      callback(responseCode, responseJson);
    }
  );
};

export const sendRequestBlastMessage = async (
  uid: string,
  companyID: string,
  accountID: string,
  campaignID: string,
  language: string,
  // bodyParameters: Array<string>,
  // callback: any
) : Promise<ISendRequestBlastMessage>  => {
  console.log("sendRequest Blast Message");

  const jsonMessage = {
    uid: uid,
    company: companyID,
    account: accountID,
    campaign: campaignID,
    language: language,
  };

  let responseCode: string = '';
  let responseJson: any;

  await sendBlastMessage(
    jsonMessage,
    (code: string, data: JSON) => {
      responseCode = code;
      responseJson = data;
    }
  );

  return {responseCode, responseJson} as ISendRequestBlastMessage;
};

export const sendRequestCreateTemplate = async (
  headerFormat: string | undefined,
  language: Language,
  template: Template,
  whatsappBusinessID: string,
  whatsappAccessToken: string,
  isUpdateStage: boolean,
  templateID: string,
  callback: any
) => {
  console.log(language, "data create template SCT")

  // let isUpdateStage = true;

  let templateHeaderText = ``;

  if (
    language.header?.text &&
    language.header.text !== "" &&
    language.header.text !== "undefined"
  ) {
    templateHeaderText = `,{"type" : "HEADER",
      "format" : "${headerFormat}",
      "text" : "${language.header?.text}"
      <<example>>
    }`;
  }

  let templateHeaderMedia = ``;

  if (
    headerFormat &&
    headerFormat !== "" &&
    headerFormat !== "undefined" &&
    language.responseUploadMediaID &&
    language.responseUploadMediaID !== "" &&
    language.responseUploadMediaID !== "undefined"
  ) {
    templateHeaderMedia = `,{
      "type" : "HEADER",
      "format" : "${headerFormat.toUpperCase()}", 
      "example":{"header_handle":["${language.responseUploadMediaID}"]}
    }`;
  }

  const templateFooter = `{
    "type" : "FOOTER",
    "text" : "${language.footer}"
  }`;

  const templateFooterExpiration = `{
    "type" : "FOOTER",
    "code_expiration_minutes" : ${language.code_expiration_minutes}
  }`

  const templateCTA = `{
    "type" : "BUTTONS",
    "buttons" : [<<button>>]
  }`;

  const templateButtons = `{
    "type" : "<<type>>",
    <<otpType>>
    <<text>>
    <<url>>
    <<package_name>>
    <<signature_hash>>
    <<zero_tap_terms_accepted>>
    <<example>>
    <<phonenumber>>
    
  }`;

  let templateBody = `{
    "type" : "BODY",
    "text" : "${language.body?.replaceAll("\n", "\\n")}"
    <<example>>
  }`;

  if (language.addSecurityRecommendation)
    templateBody = `{
      "type" : "BODY",
      "add_security_recommendation" : true
  }`
  let jsonRequestCreateMessage = `
  {
    "name" : "${template.templateName}",
    "language" : "${language.language}",
    "category" : "${template.category?.toUpperCase()}",
    "components" : [${templateBody}<<templateheader>><<templatefooter>><<templateCTA>>]
  }`;

  let jsonRequestUpdateMessage = `
  {
    "components" : [${templateBody}<<templateheader>><<templatefooter>><<templateCTA>>]
  }`;


  let jsonMessage = "";
  if (isUpdateStage) {
    jsonMessage = jsonRequestUpdateMessage;
  } else {
    jsonMessage = jsonRequestCreateMessage;
  }

  /* Check Footer */
  if (language.code_expiration_minutes) 
    jsonMessage = jsonMessage.replace(
      "<<templatefooter>>",
      "," + templateFooterExpiration
    );

  if (language.footer && language.footer !== "") 
    jsonMessage = jsonMessage.replace(
      "<<templatefooter>>",
      "," + templateFooter
    );
  
  
  jsonMessage = jsonMessage.replace("<<templatefooter>>", "");
  

    
  //Process CTA
  if (language.buttons && language.buttons.length > 0) {
    let buttons = "";
    for (let index = 0; index < language.buttons.length; index++) {
      const element = language.buttons[index];
      let tempButton = templateButtons;
      tempButton = tempButton
        .replace("<<type>>", element.type);

      tempButton = tempButton
        .replace("<<otpType>>", element.otpType? `"otp_type" : "${element.otpType}",` : "");
        
      tempButton = tempButton
        .replace("<<text>>", element.text? `"text" : "${element.text}"` : "");
      
      const package_name = element.packageName? `"package_name":"${element.packageName}",` : ""
      tempButton = tempButton
        .replace("<<package_name>>", package_name);
      
      const signature_hash = element.signaturehash? `"signature_hash":"${element.signaturehash}",` : ""
      tempButton = tempButton
        .replace("<<signature_hash>>", signature_hash);

      const zero_tap_terms_accepted = element.termsAccepted? `"zero_tap_terms_accepted":${element.termsAccepted},` : ""
      tempButton = tempButton
        .replace("<<zero_tap_terms_accepted>>", zero_tap_terms_accepted);
      
      if (element.url && element.url !== "" && element.urlType === "DYNAMIC" && element.urlDynamic) {
        const dynamicURLExample = element.dynamicValue ? element.urlDynamic?.replace("{{1}}", element.dynamicValue) : "url-example";
        tempButton = tempButton.replace("<<url>>", `,"url":"${element.urlDynamic}"`);
        tempButton = tempButton.replace(
          "<<example>>",
          `,"example":["${dynamicURLExample}"]`
        );
      }else if (element.url && element.url !== "") {
        tempButton = tempButton.replace("<<url>>", `,"url":"${element.url}"`);
        tempButton = tempButton.replace("<<example>>", "");
      } else {
        tempButton = tempButton.replace("<<url>>", "");
        tempButton = tempButton.replace("<<example>>", "");
      }

      if (element.phoneNumber && element.phoneNumber !== "") {
        tempButton = tempButton.replace(
          "<<phonenumber>>",
          `,"phone_number":"${element.phoneNumber}"`
        );
      } else {
        tempButton = tempButton.replace("<<phonenumber>>", "");
      }

      if (index === language.buttons.length - 1) {
        buttons += tempButton;
      } else {
        buttons += tempButton + ",";
      }
    }
    jsonMessage = jsonMessage.replace(
      "<<templateCTA>>",
      "," + templateCTA.replace("<<button>>", buttons)
    );
  } else {
    jsonMessage = jsonMessage.replace("<<templateCTA>>", "");
  }
  console.log("Check Button : " + jsonMessage);

  if (language.bodyParam && language.bodyParam?.length > 0) {
    //Generate Example
    let arrBodyParam: string = "";
    for (let index = 1; index <= language.bodyParam.length; index++) {
      if (index == language.bodyParam.length) {
        arrBodyParam += `"body-example-${index}"`;
      } else {
        arrBodyParam += `"body-example-${index}",`;
      }
    }
    jsonMessage = jsonMessage.replace(
      "<<example>>",
      `,"example" : {
      "body_text": [[${arrBodyParam}]]
    }`
    );
  } else {
    jsonMessage = jsonMessage.replace("<<example>>", "");
  }

  {
    /* Check header */
  }
  if (headerFormat && headerFormat !== "") {
    if (headerFormat === "text") {
      let headerParameters = language.header?.headerParam;
      if (headerParameters && headerParameters.length > 0) {
        //Generate Example
        let arrHeaderParam: string = "";
        for (let index = 1; index <= headerParameters.length; index++) {
          if (index == headerParameters.length) {
            arrHeaderParam += `"header-example-${index}"`;
          } else {
            arrHeaderParam += `"header-example-${index}",`;
          }
        }
        templateHeaderText = templateHeaderText.replace(
          "<<example>>",
          `,"example" : {
          "header_text": [${arrHeaderParam}]
        }`
        );
      } else {
        templateHeaderText = templateHeaderText.replace("<<example>>", "");
      }
      jsonMessage = jsonMessage.replace(
        "<<templateheader>>",
        templateHeaderText
      );
    } else {
      jsonMessage = jsonMessage.replace(
        "<<templateheader>>",
        templateHeaderMedia
      );
    }
  } else {
    jsonMessage = jsonMessage.replace("<<templateheader>>", "");
  }

  console.log("komen sendRequestCreateTemplate - JSON Message :" + jsonMessage);
  console.log("komen sendRequestCreateTemplate - whatsappAccessToken :" + whatsappAccessToken);


  
  if (isUpdateStage) {
    console.log("MASUK IF ISUPDATESTAGE komen");
    
    return await sendUpdateTemplate(
      jsonMessage,
      whatsappBusinessID,
      whatsappAccessToken,
      templateID,
      (
        responseStatus: boolean,
        responseJson: JSON,
        errorMessage: string,
        errorJSON: JSON
      ) => {
        callback(responseStatus, responseJson, errorMessage, errorJSON);
      }
    );
  } else {
    console.log("MASUK ELSE ISUPDATESTAGE komen");
    return await sendCreateTemplate(
      jsonMessage,
      whatsappBusinessID,
      whatsappAccessToken,
      (
        responseTemplateID: string,
        responseJson: JSON,
        errorMessage: string,
        errorJSON: JSON
      ) => {
        console.log(whatsappBusinessID, "waba jis")
        console.log(responseJson, "resjson jis")
        console.log(errorMessage, "errmess jis")
        callback(responseTemplateID, responseJson, errorMessage, errorJSON);
      }
    );
  }
  
};
