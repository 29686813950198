import React, { useState, createContext, ReactNode, useContext, useEffect } from "react";
import { themes } from "../setup/theme/themes";

interface GlobalContextType {
  theme: string;
  themeSwitchHandler: (themeType: string) => void;
}

const initialContext: GlobalContextType = {
  theme: "",
  themeSwitchHandler: () => {},
};

export const GlobalContext = createContext<GlobalContextType>(initialContext);

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}: GlobalContextProviderProps) => {
  const [currentTheme, setCurrentTheme] = useState<string>("");

  const themeSwitchHandler = (themeType: string) => {
    setCurrentTheme(themeType);
  };

  function updateDocumentTitle(newTitle: string) {
    document.title = newTitle;
  }

  useEffect(() => {
    // Read the theme value from an environment variable (e.g., REACT_APP_THEME)
    const themeFromEnv = process.env.REACT_APP_THEME;

    //get url and domain
    const currentURL = window.location.href;
    console.log(`currentURL : ${currentURL}`);
    const domain = window.location.hostname

    // Extract the theme from the subdomain
    const themeFromDomain = domain.split(".")[0];
    console.log(`domain ${domain}`);
    console.log(`themeFromDomain ${themeFromDomain}`);

    console.log(`theme env config : ${themeFromEnv}`)
    if (themeFromEnv && themeFromEnv !== undefined && themeFromEnv !== "") {
      console.log(`set theme from env : ${themeFromEnv}`)
      setCurrentTheme(themeFromEnv);
      const faviconLink = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (faviconLink && themes[themeFromEnv] && themes[themeFromEnv].urlLogo) {
        faviconLink.href = themes[themeFromEnv].urlLogo; 
      }
      if(themes[themeFromEnv] && themes[themeFromEnv].name){
        updateDocumentTitle(themes[themeFromEnv].name);
      }
    }else if(currentURL.toLowerCase().includes('smile-interactive.com')){
      console.log(`set theme smile cause smile-interactive.com`)
      setCurrentTheme('smile');
      const themeName = 'smile';
      const faviconLink = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      if (faviconLink && themes[themeName] && themes[themeName].urlLogo) {
        faviconLink.href = themes[themeName].urlLogo; 
      }
      if(themes[themeName] && themes[themeName].name){
        updateDocumentTitle(themes[themeName].name);
      }
    }else if(currentURL.toLowerCase().includes('7daysdesk.com')){
      console.log(`set theme sevendays cause 7daysdesk.com`)
      setCurrentTheme('sevendays');
    }else{
      console.log(`set theme sevendays cause default`)
      setCurrentTheme('sevendays');
    }
  }, []);


  return (
    <GlobalContext.Provider
      value={{
        theme: currentTheme,
        themeSwitchHandler: themeSwitchHandler,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
