import clsx from "clsx";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/color.css";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/toggle.scss";
import { validateURL } from "../../../../../util/validation";
import {
  BodyParamLanguage,
  HeaderParamLanguage,
} from "../../../../models/Language";
import AlertFailedProcess from "../../../../modules/alert/AlertFailedProcess";
import {
  AlertModal,
  InteractiveButton,
} from "../../../../modules/template/redux/TemplateRedux";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import PreviewMessage from "../../../core/preview/PreviewMessage";
import QRComponent from "../component/QuickReply";
import CTAComponent from "../component/CallToAction";
// Import teka lawasane

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  generateLanguage: (
    key: string,
    value: any,
    paramcount: number,
    filename: string,
    bodyParams: BodyParamLanguage[] | undefined,
    headerParams: HeaderParamLanguage[] | undefined
  ) => void;
  template: any;
  language: any;
  bodyMessage: any;
  previewBody: any;
}

const TemplateCallToAction: FC<TemplateProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generateLanguage } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const templateRedux = useSelector((state: RootState) => state.Template);
  let templateCtaButton = useSelector(
    (state: RootState) => state.Template.templateButton
  );
  const templateQuickReplies = useSelector(
    (state: RootState) => state.Template.templateQuickReplies
  );
  const selectedButton = useSelector(
    (state: RootState) => state.Template.selectedInteractiveButton
  );
  const templateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  const selectedInteractiveMessage = useSelector(
    (state: RootState) => state.Template.selectedInteractiveMessage
  );
  const setSelectedButton = (value: string) => {
    dispatch(ReduxTemplate.setSelectedInteractiveButton(value));
  };
  const setQuickRepliesButton = (value: string[]) => {
    dispatch(ReduxTemplate.setTemplateQuickReplies(value));
  };
  const setFooter = (value: string) => {
    dispatch(ReduxTemplate.setTemplateFooter(value));
  };
  const [titleButtonPhoneNumber, setTitleButtonPhoneNumber] =
    useState<string>("");
  const [titleButtonVisitWebsite, setTitleButtonVisitWebsite] =
    useState<string>("");
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [validationState, setValidationState] = useState({
    isPhoneCheck: false,
    isURLCheck: false,
    isTitleIdentical: false,
    titlePhoneValid: false,
    valuePhoneValid: false,
    titleUrlValid: false,
    valueUrlValid: false,
  });
  const [isCBPhoneNumber, setIsCBPhoneNumber] = useState<boolean>(false);
  const [isCBVisitWebsite, setIsCBVisitWebsite] = useState<boolean>(false);

  const [selectButton, setSelectButton] = useState<any>();
  const [arraySelectButton, setArraySelectButton] = useState<any>([]);

  const [errorLimitButton, setErrorLimitButton] = useState<string>("");

  const [listSelectedBtn, setListSelectedBtn] = useState<any[]>([]);
  const [btnTxt, setBtnTxt] = useState<string>("Hentikan Promosi");
  const [ftrTxt, setFtrTxt] = useState<string>(
    "Tidak tertarik? Ketuk hentikan promosi"
  );
  const [termMkt, setTermMkt] = useState<boolean>(false);
  const [urlWebsite, setUrlWebsite] = useState<string>("www.example.com");
  const [urlType, setUrlType] = useState<string>("STATIC");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [offerCode, setOfferCode] = useState<string>("Xl4sJv");
  const [formType, setFormType] = useState<string>("REGISTER");

  const [isTitleIdentical, setIsTitleIdentical] = useState<boolean>(false);
  const [disabledAddBtn, setDisabledAddBtn] = useState<boolean>(false);
  const [ctaExist, setCtaExist] = useState<boolean>(false);
  const [qrExist, setQrExist] = useState<boolean>(false);

  console.log(arraySelectButton, "sel but yux");

  const defaultCTAAlert: AlertModal = {
    show: false,
    optional: true,
    body: t("Template.Info.CTAAlert"),
  };

  const listInteractiveOption = [
    {
      label: t("IB.Input.QuickReply"),
      options: [
        {
          value: "MARKETING",
          label: "Marketing opt-output (1 Button Maksimum)",
          group: "QUICK_REPLY",
          isDisabled:
            templateCategory !== "marketing" ||
            arraySelectButton[0]?.value === "COMPLETE_FORM"
              ? true
              : false,
          buttonText: t("IB.Input.Marketing.Button.Text"),
          footerText: t("IB.Input.Marketing.Footer.Text"),
          marketingTerm: false,
        },
        {
          value: "CUSTOM",
          label: "Custom",
          group: "QUICK_REPLY",
          isDisabled:
            arraySelectButton[0]?.value === "COMPLETE_FORM" ? true : false,
          buttonText: "Tambahkan Teks",
        },
      ],
    },
    {
      label: t("IB.Input.CTA"),
      options: [
        {
          value: "VISIT_WEB",
          label: "Visit Web (2 Buttons Maksimum)",
          group: "CALL_TO_ACTION",
          isDisabled:
            arraySelectButton[0]?.value === "COMPLETE_FORM" ? true : false,
          buttonText: "Tambahkan Teks",
          urlType: "STATIC",
          urlWebsite: "www.example.com",
        },
        {
          value: "CALL_PHONE",
          label: "Call Phone Number (1 Button Maksimum)",
          group: "CALL_TO_ACTION",
          isDisabled:
            arraySelectButton[0]?.value === "COMPLETE_FORM" ? true : false,
          buttonText: "Tambahkan Teks",
          phoneNumber: "",
        },
        {
          value: "COPY_OFFER",
          label: "Copy Offer Code (1 Button Maksimum)",
          group: "CALL_TO_ACTION",
          isDisabled:
            arraySelectButton[0]?.value === "COMPLETE_FORM" ? true : false,
          buttonText: "Tambahkan Teks",
          offerCode: "",
        },
        {
          value: "COMPLETE_FORM",
          label: "Complete Form (1 Button Maksimum)",
          group: "CALL_TO_ACTION",
          isDisabled: true,
          buttonText: "Tambahkan Teks",
        },
      ],
    },
  ];

  let limits = {
    VISIT_WEB: 2,
    COPY_OFFER: 1,
    COMPLETE_FORM: 1,
    CALL_PHONE: 1,
    MARKETING: 1,
    CUSTOM: 10, // Batas array penuh
  } as any;

  const formatGroupLabel = (data: any) => (
    <div
      style={{
        fontWeight: "bold",
        fontSize: "12px",
        borderTop: data?.label === t("IB.Input.CTA") ? "1px solid #ccc" : "",
        paddingTop: data?.label === t("IB.Input.CTA") ? "10px" : "0px",
        marginTop: data?.label === t("IB.Input.CTA") ? "-10px" : "0px",
        marginRight: "0px",
        paddingRight: "0px",
        marginLeft: "-10px",
        paddingLeft: "0px",
        width: "150%",
      }}
    >
      <p className="ps-2 p-0 m-0 text-black">{data.label}</p>
    </div>
  );

  const customStyles = {
    option: (provided: any, state: any) => {
      console.log(state, "ini state");
      return {
        ...provided,
        color:
          state.data.value === selectedButton
            ? "white"
            : state.isDisabled
            ? "#6c757d"
            : "#1e2022",
        padding: ".3rem 3rem .3rem .5rem",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        backgroundColor:
          state.isSelected && state.data.value === selectedButton
            ? "#0275d8"
            : state.isFocused
            ? "#f0f0f0"
            : "white",
      };
    },
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    control: (provided: any) => ({
      ...provided,
      height: "43px",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#0275d8",
    }),
  };

  // const isTitleIdentical =
  //   titleButtonVisitWebsite !== "" &&
  //   titleButtonPhoneNumber !== "" &&
  //   titleButtonVisitWebsite === titleButtonPhoneNumber;

  useEffect(() => {
    if (isTitleIdentical) {
      setValidationState((prev) => ({
        ...prev,
        isTitleIdentical: true,
      }));
    } else {
      setValidationState((prev) => ({
        ...prev,
        isTitleIdentical: false,
      }));
    }
  }, [isTitleIdentical]);

  useEffect(() => {
    if (validationState.isTitleIdentical) {
      dispatch(
        ReduxTemplate.setAlertCTA({
          show: true,
          optional: false,
          body: t("IB.Error.BTSame"),
        })
      );
      return;
    }
  }, []);

  useEffect(() => {
    if (
      templateCtaButton.length !== arraySelectButton.length &&
      templateCtaButton.length === 0
    ) {
      setArraySelectButton([]);
    }
  }, [templateCtaButton]);

  // useEffect(() => {
  //   if (templateCtaButton.length > 0) {
  //     const pnObject = templateCtaButton.find(
  //       (object) => object.type === "PHONE_NUMBER"
  //     );
  //     const urlObject = templateCtaButton.find(
  //       (object) => object.type === "URL"
  //     );
  //     if (pnObject || urlObject) {
  //       setSelectedButton(InteractiveButton.CallToAction);
  //       if (pnObject) {
  //         console.log("Ini masuk ke pnObject tidak undefined");
  //         setIsCBPhoneNumber(true);
  //         setPhoneNumber(pnObject.phoneNumber!);
  //         setTitleButtonPhoneNumber(pnObject.text);
  //       }
  //       if (urlObject) {
  //         console.log(`set url object `, urlObject);
  //         setIsCBVisitWebsite(true);
  //         setUrlWebsite(urlObject.url!);
  //         setTitleButtonVisitWebsite(urlObject.text);
  //       }
  //     }
  //   }

  //   console.log("masuk di selected template id ada useffect");
  // }, [templateCtaButton]);

  // useEffect(() => {
  //   if (
  //     selectedButton === InteractiveButton.CallToAction &&
  //     !templateCtaButton.length
  //   ) {
  //     console.log("templatectabtn", templateCtaButton);
  //     dispatch(ReduxTemplate.setAlertCTA({ ...defaultCTAAlert, show: true }));
  //     return;
  //   }

  //   if (
  //     selectedButton === InteractiveButton.QuickReply &&
  //     !templateQuickReplies.length
  //   ) {
  //     dispatch(ReduxTemplate.setAlertCTA({ ...defaultCTAAlert, show: true }));
  //     return;
  //   }

  //   if (validationState.isTitleIdentical) {
  //     dispatch(
  //       ReduxTemplate.setAlertCTA({
  //         show: true,
  //         optional: false,
  //         body: t("IB.Error.BTSame"),
  //       })
  //     );
  //     return;
  //   }

  //   dispatch(ReduxTemplate.setAlertCTA(defaultCTAAlert));
  //   return;
  // }, [
  //   selectedButton,
  //   templateCtaButton,
  //   templateQuickReplies,
  //   validationState,
  // ]);

  const onChangeSelectButtonHandler = (value: string) => {
    const buttonSelected = value;
    setSelectedButton(buttonSelected);

    if (buttonSelected === InteractiveButton.None) {
      generateLanguage("remove-buttons", "", 0, "", undefined, []);
    }
  };

  const handleCallPhoneNumberInfoChanged = (title: string, value: string) => {
    setTitleButtonPhoneNumber(title);

    const filteredButtons = templateCtaButton.filter(
      (button) => button.type !== "PHONE_NUMBER"
    );

    if (!title || !value || value.length <= 4) {
      dispatch(ReduxTemplate.setTemplateButton(filteredButtons));
      if (filteredButtons.length) {
        generateLanguage("buttons", filteredButtons, 0, "", undefined, []);
        return;
      }
      generateLanguage("remove-buttons", "", 0, "", undefined, []);
      return;
    }

    const newButtons = [
      ...filteredButtons,
      {
        type: "PHONE_NUMBER",
        text: title,
        phoneNumber: value,
        index: 0,
      },
    ];
    dispatch(ReduxTemplate.setTemplateButton(newButtons));
    generateLanguage("buttons", newButtons, 0, "", undefined, []);
    setValidationState((prev) => ({
      ...prev,
      titlePhoneValid: true,
      valuePhoneValid: true,
    }));
  };

  const handleVisitWebInfoChanged = (
    title: string,
    value: string,
    type: string,
    urlDynamic: string,
    dynamicValue: string
  ) => {
    setTitleButtonVisitWebsite(title);
    setUrlWebsite(value);

    const filteredButtons = templateCtaButton.filter(
      (button) => button.type !== "URL"
    );

    if (!title || !value || !validateURL(value)) {
      dispatch(ReduxTemplate.setTemplateButton(filteredButtons));
      if (filteredButtons.length) {
        generateLanguage("buttons", filteredButtons, 0, "", undefined, []);
        return;
      }
      generateLanguage("remove-buttons", "", 0, "", undefined, []);
      return;
    }

    const newButtons = [
      ...filteredButtons,
      {
        type: "URL",
        text: title,
        url: value,
        index: 1,
        urlType: type,
        urlDynamic,
        dynamicValue,
      },
    ];
    console.log(`newButtons : `, newButtons);
    dispatch(ReduxTemplate.setTemplateButton(newButtons));
    generateLanguage("buttons", newButtons, 0, "", undefined, []);
    setValidationState((prev) => ({
      ...prev,
      titleUrlValid: true,
      valueUrlValid: true,
    }));
  };

  const handleQuickReply = (
    index: number,
    button_text: string,
    footer_text: string,
    term_marketing: boolean,
    type: string
  ) => {
    const isDuplicate = arraySelectButton?.some(
      (item: any, i: number) =>
        item?.buttonText === button_text &&
        arraySelectButton?.indexOf(item) !== index
    );
    setIsTitleIdentical(isDuplicate);

    const targetIndex = arraySelectButton?.findIndex(
      (item: { value: string }) =>
        item?.value === String(type).toUpperCase() &&
        arraySelectButton?.indexOf(item) === index
    );
    if (type === "Marketing") {
      if (targetIndex !== -1) {
        const updatedArray = arraySelectButton?.map((item: any, i: any) => {
          if (i === targetIndex) {
            return {
              ...item,
              buttonText: button_text,
              footerText: footer_text,
              marketingTerm: term_marketing,
            };
          }
          return item;
        });
        setArraySelectButton(updatedArray);
      }
    }
    if (type === "Custom") {
      if (targetIndex !== -1) {
        const updatedArray = arraySelectButton?.map((item: any, i: any) => {
          if (i === targetIndex) {
            return {
              ...item,
              buttonText: button_text,
            };
          }
          return item;
        });
        setArraySelectButton(updatedArray);
      }
    }
  };

  const handleCallToaction = (
    index: number,
    button_text: string,
    footer_text: string,
    url_website: string,
    url_type: string,
    phone_number: string,
    offer_code: string,
    form_type: string,
    type: string
  ) => {
    const isDuplicate = arraySelectButton?.some(
      (item: any, i: number) =>
        item?.buttonText === button_text &&
        arraySelectButton?.indexOf(item) !== index
    );
    setIsTitleIdentical(isDuplicate);

    const targetIndex = arraySelectButton?.findIndex(
      (item: { value: string }) =>
        item?.value === String(type).toUpperCase() &&
        arraySelectButton?.indexOf(item) === index
    );
    if (type === "Visit_Web") {
      if (targetIndex !== -1) {
        const updatedArray = arraySelectButton?.map((item: any, i: any) => {
          if (i === targetIndex) {
            return {
              ...item,
              buttonText: button_text,
              urlType: url_type,
              urlWebsite: url_website,
            };
          }
          return item;
        });
        setArraySelectButton(updatedArray);
      }
    }
    if (type === "Call_Phone") {
      if (targetIndex !== -1) {
        const updatedArray = arraySelectButton?.map((item: any, i: any) => {
          if (i === targetIndex) {
            return {
              ...item,
              buttonText: button_text,
              phoneNumber: phone_number,
            };
          }
          return item;
        });
        setArraySelectButton(updatedArray);
      }
    }
    if (type === "Copy_Offer") {
      if (targetIndex !== -1) {
        const updatedArray = arraySelectButton?.map((item: any, i: any) => {
          if (i === targetIndex) {
            return {
              ...item,
              buttonText: button_text,
              offerCode: offer_code,
            };
          }
          return item;
        });
        setArraySelectButton(updatedArray);
      }
    }
    if (type === "Complete_Form") {
    }
  };
  //end of handle cta

  const handleDeleteItem = (idx: number) => {
    console.log(arraySelectButton, "check awal roam");
    setArraySelectButton(
      arraySelectButton?.filter((item: any, index: number) => index !== idx)
    );
    console.log(arraySelectButton, "check akhir roam");
    // Mmeunculkan ulang tampilan input-input
    if (arraySelectButton.length) {
      arraySelectButton.map((item: any) => {
        if (item.group === "QUICK_REPLY") {
          setQrExist(true);
        }
        if (item.group === "CALL_TO_ACTION") {
          setCtaExist(true);
        }
      });
    } else {
      setQrExist(false);
      setCtaExist(false);
    }
    setDisabledAddBtn(false);
  };

  const handleQuickReplyChange = (value: string[]) => {
    const dataQuickReplyLang = value.map((text, index) => ({
      type: "QUICK_REPLY",
      text: text,
      index: index,
    }));
    generateLanguage("buttons", dataQuickReplyLang, 0, "", undefined, []);
  };

  const updateTemplateCta = (
    toUpdate: Array<{ type: string; key: string; value: string }>
  ) => {
    let dataCta = [...templateCtaButton];
    if (dataCta && dataCta.length <= 0) {
      console.log(`no initial data`);
      return;
    }
    toUpdate.forEach((newData) => {
      const type = newData.type;
      const key = newData.key;
      const value = newData.value;
      console.log(
        `Before update ${type} k${key} v:${value} templateButton:${JSON.stringify(
          dataCta
        )} `
      );
      for (let i = 0; i < dataCta.length; i++) {
        let tempJson = { ...dataCta[i] };

        if (tempJson.type === type) {
          if (key === "phoneNumber") {
            tempJson.phoneNumber = value;
          } else if (key === "text") {
            tempJson.text = value;
          } else if (key === "index") {
            tempJson.index = i;
          } else if (key === "url") {
            tempJson.url = value;
          }
        }
        dataCta[i] = tempJson;
      }

      console.log(
        `Aftere update ${type} k${key} v:${value} templateButton:${JSON.stringify(
          dataCta
        )} `
      );
    });
    // data from redux

    dispatch(ReduxTemplate.setTemplateButton(dataCta));
    generateLanguage("buttons", dataCta, 0, "", undefined, []);
  };

  // sementara
  // useEffect(() => {
  //   if (selectedButton) {
  //     console.log(`masuk to set selected button`);
  //     const selected =
  //       selectedButton === "CALL_TO_ACTION"
  //         ? listInteractiveOption[1]
  //         : selectedButton === "QUICK_REPLY"
  //         ? listInteractiveOption[2]
  //         : listInteractiveOption[0];
  //     setSelectedListButton(selected);
  //     console.log(`CHECK IsCBVisitWebsite `, isCBVisitWebsite);
  //   }
  // }, [selectedButton]);

  useEffect(() => {
    setIsRefresh(true);
  }, [selectedButton]);

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        setIsRefresh(false);
      }, 300);
    }
  }, [isRefresh]);

  useEffect(() => {
    let find_qr = arraySelectButton.find(
      (item: any) => item.group === "QUICK_REPLY"
    );
    let find_cta = arraySelectButton.find(
      (item: any) => item.group === "CALL_TO_ACTION"
    );
    let find_qr_marketing = arraySelectButton.find(
      (item: any) => item.group === "QUICK_REPLY" && item.value === "MARKETING"
    );
    let find_qr_custom = arraySelectButton.find(
      (item: any) => item.group === "QUICK_REPLY" && item.value === "CUSTOM"
    );
    if (find_qr === undefined) {
      setQrExist(false);
    } else {
      setQrExist(true);
    }
    if (find_cta === undefined) {
      setCtaExist(false);
    } else {
      setCtaExist(true);
    }
  }, [arraySelectButton]);

  const checkDuplicateButtonText = () => {
    const buttonTexts = arraySelectButton?.map((item: any) => item?.buttonText);
    const uniqueButtonTexts = new Set(buttonTexts);
    if (buttonTexts?.length !== uniqueButtonTexts.size) {
      setIsTitleIdentical(true);
    } else {
      setIsTitleIdentical(false);
    }
  };

  useEffect(() => {
    checkDuplicateButtonText();
    let data_button = arraySelectButton?.map((item: any, index: number) => {
      if (item?.group === "QUICK_REPLY") {
        return {
          type: item.group,
          text: item.buttonText || "",
          index: index,
        };
      }
      if (item?.value === "VISIT_WEB") {
        if (item?.urlType === "DYNAMIC") {
          let value = item?.urlWebsite;
          let urlObject = new URL(`https://${value}`);
          return {
            type: "URL",
            text: item?.buttonText || "",
            index: index,
            url: urlObject?.href,
            urlType: item?.urlType,
            urlDynamic: urlObject.origin + urlObject.pathname + "?id={{1}}",
            dynamicValue: urlObject.searchParams.get("id"),
          };
        }
        if (item?.urlType === "STATIC") {
          return {
            type: "URL",
            text: item?.buttonText || "",
            index: index,
            url: item?.urlWebsite,
            urlType: item?.urlType,
            urlDynamic: "",
            dynamicValue: "",
          };
        }
      }
      if (item?.value === "CALL_PHONE") {
        return {
          type: "PHONE_NUMBER",
          text: item?.buttonText || "",
          phoneNumber: item?.phoneNumber,
          index: index,
        };
      }
      if (item?.value === "COPY_OFFER") {
        return {
          type: "OTP",
          otpType: "COPY_CODE",
          text: item?.buttonText || "",
          index: index,
          code: item?.offerCode,
        };
      }
    });
    const quickReplyCount = arraySelectButton.filter(
      (item: any) => item?.group === "QUICK_REPLY"
    ).length;
    const callToActionCount = arraySelectButton.filter(
      (item: any) => item?.group === "CALL_TO_ACTION"
    ).length;
    if (quickReplyCount > 0 && callToActionCount === 0) {
      setSelectedButton("QUICK_REPLY");
    } else if (callToActionCount > 0 && quickReplyCount === 0) {
      setSelectedButton("CALL_TO_ACTION");
    } else {
      setSelectedButton("MULTIPLE");
    }
    const quickReplyItem = arraySelectButton
      .filter((item: any) => item?.group === "QUICK_REPLY")
      .map((item: any) => item?.buttonText);
    if (quickReplyItem) {
      setQuickRepliesButton(quickReplyItem);
    }
    generateLanguage("buttons", data_button, 0, "", undefined, []);
    console.log(data_button, "check dag stp");
    dispatch(ReduxTemplate.setTemplateButton(data_button));
    const marketingItem = arraySelectButton.find(
      (item: any) => item.value === "MARKETING"
    );
    if (marketingItem) {
      console.log("dijalankan wop")
      const footer_marketing = marketingItem?.footerText;
      generateLanguage("footer", footer_marketing, 0, "", undefined, []);
      dispatch(ReduxTemplate.setTemplateFooter(footer_marketing));
      setFooter(footer_marketing);
      if (marketingItem?.marketingTerm === false) {
        setDisabledAddBtn(true);
      } else {
        setDisabledAddBtn(false);
      }
    }
    // else {
    //   generateLanguage("footer", "", 0, "", undefined, []);
    //   dispatch(ReduxTemplate.setTemplateFooter(""));
    //   setFooter("");
    // }
  }, [arraySelectButton]);

  return (
    <>
      <button
        id="triggerFailed"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed_process"
        hidden
      ></button>
      <AlertFailedProcess processName="Template Call To Action Configuration" />
      <div
        className={clsx("tab-pane fade show")}
        id="kt_tab_pane_5"
        role="tabpanel"
        data-testid="templateCallToAction"
      >
        <div className="d-flex">
          {/* 1 */}
          <div className="col-12">
            <div className="step-sub-title pb-0 mb-0 fs-2">
              {t("Campaign.Title.InteractiveButton")}
            </div>
            <div
              className={clsx(
                "mt-0 pt-2 mb-5 d-flex flex-row justify-content-between",
                {
                  "flex-column": isTabletOrMobile,
                }
              )}
            >
              {/* Start for Footer Form */}
              <div
                id="interactiveButtonForm"
                className={clsx({
                  col: isTabletOrMobile,
                  "col-7 m-0 p-0": !isTabletOrMobile,
                })}
              >
                {/* Interactive Button */}
                <div className="mb-5 mt-0 pt-0">
                  {t("Template.Info.InteractiveButtons")}
                </div>
                <div>
                  <div>
                    <div className="d-flex flex-row align-items-center justify-content-between col-gap-2">
                      <div className="col-lg-9 col-md-9 col-sm-9 pr-2">
                        <Select
                          name="add-message-param"
                          value={selectButton}
                          defaultValue={selectButton}
                          styles={customStyles}
                          options={listInteractiveOption}
                          formatGroupLabel={formatGroupLabel}
                          className="basic-select col-md-12 col-sm-12"
                          classNamePrefix="select"
                          placeholder={t("IB.Input.SelectBT")}
                          onChange={(newVal: any) => {
                            if (newVal) {
                              setSelectButton(newVal);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "enter" || e.keyCode === 13) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          // isDisabled={templateCategory === "interactive"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3">
                        <button
                          className="btn btn-primary"
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            minHeight: "38px",
                          }}
                          onClick={() => {
                            let count = arraySelectButton?.length || 0;
                            const countValues = arraySelectButton.reduce(
                              (acc: any, curr: any) => {
                                acc[curr.value] = (acc[curr.value] || 0) + 1;
                                return acc;
                              },
                              {}
                            );
                            if (count >= limits.CUSTOM) {
                              setErrorLimitButton(
                                `You've reached the maximum button limit`
                              );
                            } else if (
                              (countValues[selectButton?.value] || 0) <
                                limits[selectButton?.value] ||
                              (selectButton?.value === "CUSTOM" &&
                                count < limits.CUSTOM)
                            ) {
                              setArraySelectButton([
                                ...arraySelectButton,
                                selectButton,
                              ]);
                              setErrorLimitButton("");
                            } else {
                              setErrorLimitButton(
                                `You've reached the maximum limit, ${selectButton?.label}`
                              );
                            }
                          }}
                          disabled={disabledAddBtn}
                        >
                          + {t("Template.Interactivebutton.Add")}
                        </button>
                      </div>
                    </div>
                  </div>

                  {errorLimitButton !== "" && (
                    <p className="text-danger">{errorLimitButton}</p>
                  )}

                  {qrExist && (
                    <div
                      style={{
                        width: "103%",
                        backgroundColor: "#EDEFF4",
                        borderRadius: "7px",
                        marginTop: "25px",
                      }}
                    >
                      <p
                        style={{
                          padding: "0px",
                          fontWeight: "bold",
                        }}
                        className="py-2 m-0 ps-5"
                      >
                        Quick Reply
                      </p>
                      <div className="p-3 m-0 pt-0 mt-0 pb-1 mb-0">
                        {arraySelectButton.map((item: any, index: number) => {
                          console.log(item, "test item rux");
                          if (item.group === "QUICK_REPLY") {
                            if (item.value === "MARKETING") {
                              return (
                                <QRComponent
                                  idx={index}
                                  mode="Marketing"
                                  handleInputChange={handleQuickReply}
                                  content={{
                                    index: index,
                                    button_text: item?.buttonText,
                                    footer_text: item?.footerText,
                                    term_marketing: item?.marketingTerm,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                            if (item.value === "CUSTOM") {
                              return (
                                <QRComponent
                                  idx={index}
                                  mode="Custom"
                                  handleInputChange={handleQuickReply}
                                  content={{
                                    index: index,
                                    button_text: item?.buttonText,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  )}

                  {ctaExist && (
                    <div
                      style={{
                        width: "103%",
                        backgroundColor: "#EDEFF4",
                        borderRadius: "7px",
                        marginTop: "25px",
                      }}
                    >
                      <p
                        style={{
                          padding: "0px",
                          fontWeight: "bold",
                        }}
                        className="py-2 m-0 ps-5"
                      >
                        Call to Action
                      </p>
                      <div className="p-4 m-0 pt-0">
                        {arraySelectButton.map((item: any, index: number) => {
                          if (item.group === "CALL_TO_ACTION") {
                            if (item.value === "VISIT_WEB") {
                              return (
                                <CTAComponent
                                  idx={index}
                                  mode="Visit_Web"
                                  handleInputChange={handleCallToaction}
                                  content={{
                                    index: index,
                                    button_text: item.buttonText,
                                    footer_text: item.footerText,
                                    url_website: item.urlWebsite,
                                    phone_number: item.phoneNumber,
                                    offer_code: item.offerCode,
                                    form_type: item.formType,
                                    url_type: item.urlType,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                            if (item.value === "CALL_PHONE") {
                              return (
                                <CTAComponent
                                  idx={index}
                                  mode="Call_Phone"
                                  handleInputChange={handleCallToaction}
                                  content={{
                                    index: index,
                                    button_text: item.buttonText,
                                    footer_text: item.footerText,
                                    url_website: item.urlWebsite,
                                    phone_number: item.phoneNumber,
                                    offer_code: item.offerCode,
                                    form_type: item.formType,
                                    url_type: item.urlType,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                            if (item.value === "COPY_OFFER") {
                              return (
                                <CTAComponent
                                  idx={index}
                                  mode="Copy_Offer"
                                  handleInputChange={handleCallToaction}
                                  content={{
                                    index: index,
                                    button_text: item.buttonText,
                                    footer_text: item.footerText,
                                    url_website: item.urlWebsite,
                                    phone_number: item.phoneNumber,
                                    offer_code: item.offerCode,
                                    form_type: item.formType,
                                    url_type: item.urlType,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                            if (item.value === "COMPLETE_FORM") {
                              return (
                                <CTAComponent
                                  idx={index}
                                  mode="Complete_Form"
                                  handleInputChange={handleCallToaction}
                                  content={{
                                    index: index,
                                    button_text: item.buttonText,
                                    footer_text: item.footerText,
                                    url_website: item.urlWebsite,
                                    phone_number: item.phoneNumber,
                                    offer_code: item.offerCode,
                                    form_type: item.formType,
                                    url_type: item.urlType,
                                  }}
                                  validationState={validationState}
                                  handleDeleteItem={handleDeleteItem}
                                />
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* End of Footer Form */}
              <PreviewMessage />
            </div>
            {/* Select Body Parameter */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateCallToAction;
