import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../resources/helpers";
import Loading from "../../../../../../resources/helpers/components/Loading";
import CardItem from "../../../../../components/table/list/cardList/CardItem";
import { ProductListPagination } from "../components/pagination/ProductListPagination";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import Product from "../../../../../models/Product"
import { CustomHeaderColumn } from "./columns/CustomHeaderColumns";
import { CustomRow } from "./columns/CustomRow";
import { productColumns } from "./columns/_columns";

export const ProductViewDesktop = () => {
  const { t } = useTranslation();
  const products = useQueryResponseData();

  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => products, [products]);
  const columns = useMemo(() => productColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  console.log("PRODLIST Table Products", useQueryResponseData());

  return (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Product>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Product>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {t("Contacts.Info.Empty")}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ProductListPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  );
};

const ProductViewMobile = () => {
  const products = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => products, [products]);

  console.log("products : ", products);

  return (
    <>
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7">
        {data.map((product) => (
          <Fragment key={product.id?.toString()}>
            <CardItem
              id={product.id}
              name={product.name}
              numOfVariant={product.variantLength ? product.variantLength : 0}
              marketplaces={product.marketplaceCategory}
              lastStep={product.lastStep}
              isProductList={true}
              isStorefrontList={false}
              imgURL={product.coverImg ? product.coverImg : ""}
              status={product.status}
            />
          </Fragment>
        ))}
      </div>
      <ProductListPagination />
      {isLoading && <Loading />}
    </>
  );
};

const ProductTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? <ProductViewMobile /> : <ProductViewDesktop />;
};

export { ProductTable };
