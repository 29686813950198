import { Manager } from "socket.io-client";
import { getClientRef } from "../../app/modules/localstorage";

const clientRef = getClientRef();
const clientId = clientRef?.id ?? "";

const manager = new Manager(process.env.REACT_APP_SERVER_URL, {
  transports: ["websocket"],
  autoConnect: true,
  // reconnectionAttempts: 10,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
  query: {
    clientId: clientId,
  },
});

console.log("SOCKET INITED");
const socket = manager.socket(`/`);

const SocketConnection = () => {
  return socket;
};

export default SocketConnection;
