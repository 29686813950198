import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../resources/helpers";
import Loading from "../../../../../resources/helpers/components/Loading";
import { dateOrTimestampToString } from "../../../../../util/dateFormater";
import CardItem from "../../../../components/table/list/cardList/CardItem";
import Customer from "../../../../models/Customer";
import { ContactsListPagination } from "../../../contact-management/contact-list/components/pagination/ContactListPagination";
import { useFirestoreTable } from "../context/TableContext";
import { CustomHeaderColumn } from "../table/columns/CustomHeaderColumn";
import { CustomRow } from "../table/columns/CustomRow";
import { targetColumns } from "./columns/_columns";

export const TargetTableViewDesktop = () => {
  const { t } = useTranslation();
  // const Target = useQueryResponseData();
  // const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => Target, [Target]);
  const { data: dataCustomer, isLoading, isFetching } = useFirestoreTable();
  const columns = useMemo(() => targetColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data: dataCustomer?.items || [],
    });

  return (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          // height: "90%",
          height: "30vw",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Customer>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Customer>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {t("Contacts.Info.Empty")}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(isLoading || isFetching) && <Loading />}
    </KTCardBody>
  );
};

export const TargetTableViewMobile = () => {
  const { t } = useTranslation();
  // const Target = useQueryResponseData();
  // const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => Target, [Target]);
  const { data: dataCustomer, isLoading, isFetching } = useFirestoreTable();

  const data = dataCustomer?.items || [];

  return (
    <div
      style={{
        height: "100%",
        overflow: "scroll",
        minHeight: "400px",
        maxHeight: "500px",
      }}
    >
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7">
        {data.length === 0 && (
          <div className="d-flex text-center w-100 align-content-center justify-content-center">
            {t("Contacts.Info.Empty")}
          </div>
        )}
        {data.map((customer, index) => (
          <Fragment key={customer.id?.toString()}>
            <CardItem
              id={customer.id}
              key={customer.id}
              name={customer.firstName}
              isTargetList={true}
              index={index}
              targetData={{
                ...customer,
                createdAt: dateOrTimestampToString(customer.createdAt) || "-",
                lastInteractionAt:
                  dateOrTimestampToString(customer.lastInteractionAt) || "-",
              }}
            />
          </Fragment>
        ))}
      </div>
      {(isLoading || isFetching) && <Loading />}
    </div>
  );
};

const TargetTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return isTabletOrMobile ? (
    <TargetTableViewMobile />
  ) : (
    <TargetTableViewDesktop />
  );
};

export { TargetTable };
