import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { checkIsActive, KTSVG, toAbsoluteUrl } from "../../../resources/helpers";
import { useLocation } from "react-router-dom";
import "../../../styles/css/color.css";
import SVG from "react-inlinesvg";

type Props = {
  subMenu?: string;
  title: string;
  icon?: string;
  fontIcon?: string; //font-awesome icon
  duotoneIcon?: string; //duo-tone icon
  bootstrapIcon?: string; //bootstrap icon
  bbcount?: number;
  bbcolor?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  id?: string;
  hasBullet?: boolean;
  onClick?: () => void;
};

const AsideMenuButton: React.FC<Props> = ({
  children,
  subMenu,
  title,
  duotoneIcon,
  bootstrapIcon,
  bbcount = 0,
  bbcolor,
  id,
  hasBullet = false,
  onClick = () => {
    return;
  },
}) => {
  const location = useLocation();
  const isActive = checkIsActive(location.pathname, "");
  const isSubActive = checkIsActive(location.pathname, subMenu ? subMenu : "");
  let colorselect: string = "";
  if (isActive || isSubActive) {
    colorselect = "cl-graywhite";
  }

  return (
    <div
      className={clsx("menu-item", colorselect)}
      onClick={() => {
        onClick();
      }}
      id={id}
    >
      <button
        className={` bg-transparent border-0 ${clsx("menu-link without-sub", {
          active: isActive || isSubActive,
        })}`}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {duotoneIcon && (
          <div className="h-30px w-30px p-1 d-flex align-items-center justify-content-center">
            <span className="menu-icon justify-content-center p-0 m-0">
              <SVG
                src={toAbsoluteUrl(duotoneIcon)}
                className={clsx(bootstrapIcon, "fs-1 p-0 m-0")}
              />
            </span>
            {bbcount > 0 && (
              <span
                className={clsx(
                  `symbol-badge badge badge-circle bg-${bbcolor}`,
                  "menu-bubble"
                )}
                style={{ top: "5px", marginLeft: "15px" }}
              >
                {bbcount}
              </span>
            )}
          </div>
        )}
        {bootstrapIcon && (
          <div className="symbol symbol-50px">
            <span className="menu-icon justify-content-end">
              <i className={clsx(bootstrapIcon, "fs-1")} />
            </span>
            {bbcount > 0 && (
              <span
                className={clsx(
                  `symbol-badge badge badge-circle bg-${bbcolor}`,
                  "menu-bubble"
                )}
                style={{ top: "5px", marginLeft: "15px" }}
              >
                {bbcount}
              </span>
            )}
          </div>
        )}
        <span className="menu-title" style={{ marginLeft: "10px", fontSize: "0.95rem" }}>
          {title}
        </span>
        {bbcount > 0 && (
          // <span className="menu-title menu-badge justify-content-end" style={{paddingRight:38}}>
          <span className="menu-title menu-badge justify-content-end">
            <span
              className={clsx(
                "badge badge-circle fw-bolder fs-7",
                `bg-${bbcolor}`
              )}
            >
              {bbcount}
            </span>
          </span>
        )}
      </button>
      {children}
    </div>
  );
};

export { AsideMenuButton };
