import { DocumentReference } from "@firebase/firestore-types";
import FileSaver from "file-saver";
import firebase from "firebase/compat/app";
import { subsDRMessage } from "../api/firebase/message";
import * as server from "../api/server/message";
import * as order from "../api/server/order";
import Account from "../app/models/Account";
import { Collaboration, Room, CIQ } from "../app/models/Chat";
import Customer from "../app/models/Customer";
import Message, { MessageStatus } from "../app/models/Message";
import User, { UserCompanies } from "../app/models/User";

import * as userAct from "./user";
import * as lc from "../app/modules/localstorage";
import db, { Timestamp } from "../db";
import { createRef } from "../db/connection";
import * as CollaborationService from "../db/serviceCollaborations";
import * as CustomerService from "../db/serviceCustomer";
import * as RoomService from "../db/serviceRoom";
import * as UserService from "../db/serviceUser";
import * as AccountService from "../db/serviceAccount";
import * as collaboration from "../db/serviceCollaborations";
import * as ChatMessage from "../db/serviceMessage";
import * as message from "../db/serviceMessage";
import * as servUser from "../db/serviceUser";
import { Order } from "../app/models/Order";

export const sortCIQs = (
  ciqs: CIQ[],
  sortBy: "asc" | "desc" | "delete" = "asc"
) => {
  if (sortBy === "delete") {
    return ciqs.slice(); // Return a copy of the original array to avoid modifying it.
  }

  return ciqs.slice().sort((a, b) => {
    const getLastInteractionTime = (collab: Collaboration) =>
      collab.lastInteractionAt?.seconds || 0;

    const lastInteractionA = getLastInteractionTime(a);
    const lastInteractionB = getLastInteractionTime(b);

    return (lastInteractionB - lastInteractionA) * (sortBy === "asc" ? 1 : -1);
  });
};

export const sortCollaboration = (
  collabs: Collaboration[],
  sortBy: "asc" | "desc" | "delete" = "asc"
) => {
  if (sortBy === "delete") {
    return collabs.slice(); // Return a copy of the original array to avoid modifying it.
  }

  return collabs.slice().sort((a, b) => {
    const getLastInteractionTime = (collab: Collaboration) =>
      collab.lastInteractionAt?.seconds || 0;

    const lastInteractionA = getLastInteractionTime(a);
    const lastInteractionB = getLastInteractionTime(b);

    return (lastInteractionB - lastInteractionA) * (sortBy === "asc" ? 1 : -1);
  });
};

export const fetchRoomByCollaborationId = async (collaborationId: string) => {
  const rooms = await RoomService.getRoomByCollaborationId(collaborationId);
  if (!rooms) {
    console.log(
      "fetchRoomByCollaborationId result => rooms is undefined",
      collaborationId
    );
    return;
  }

  // Get Room Account
  const roomsAccount = await Promise.all(
    rooms.map(async (room) => {
      if (room.account) {
        const account = await AccountService.getAccountByID(room.account.id);
        if (account) {
          room.accountModel = account;
          // room.accountsModel = account;
        }

        if (room.toUser) {
          const toUserData = (await Promise.all(
            room.toUser
              .map(async (z: any) => {
                const user = await UserService.getCachedUserByID(z.id);
                console.log(
                  "fetchRoomByCollaborationId result => user",
                  user,
                  z.id
                );
                if (user) {
                  return user;
                }
                return;
              })
              .filter((x) => x)
          )) as User[];
          console.log(
            "fetchRoomByCollaborationId result => toUserData",
            toUserData,
            room.toUser
          );
          room.toUserModel = toUserData;
          // room.toUserData = toUserData;
        }
      }
      return room;
    })
  );

  console.log(
    "fetchRoomByCollaborationId result => roomsAccount",
    roomsAccount
  );

  return roomsAccount;
};

export const fetchCollaborationByID = async (collaborationId: string) => {
  console.log("==> Masuk fetchCollaborationByID CIQNIH", collaborationId);
  const useruid = lc.getItemLC(lc.LCName.UserID);
  console.log("CIQNIH useruid", useruid);

  // Get Collaboration Data
  const collaboration = await CollaborationService.getByID(collaborationId);
  console.log("CIQNIH collaboration", collaboration);

  // Get Customer Data
  if (collaboration.customer) {
    const customer = await CustomerService.getByID(collaboration.customer.id);
    collaboration.customerModel = customer;
  }

  // Get Unread message from realtime database
  const unreadMessages = await ChatMessage.unreadMessages(collaborationId);

  // Get User Data
  const user = await UserService.getCachedUserByID(useruid);
  if (!user) {
    return;
  }

  // Get Room Data
  const room = await fetchRoomByCollaborationId(collaborationId);
  if (!room) {
    console.log("room is undefined");
    return;
  }
  collaboration.roomsModel = room;
  collaboration.unreadMessages = unreadMessages ?? [];
  collaboration.userModel = user;
  console.log("CIQNIH collaboration bawah", collaboration);
  return collaboration;
};

export const fetchCIQByID = async (collabId: string) => {
  console.log("MASUK FETCHCIQBYID CHAT.TSX");

  return await createRef("collaborations", collabId)
    .get()
    .then(async (collab) => {
      let resultCollab = collab.data() as Collaboration;

      resultCollab.id = collab.id;
      let customer = undefined;
      if (resultCollab.customer) {
        customer = await resultCollab.customer!.get();
      }

      if (customer !== undefined) {
        resultCollab.customerModel = customer.data() as Customer;
        resultCollab.customerModel.id = customer.id;
        resultCollab.phoneNumber = resultCollab.customerModel.phoneNumber;
      }
      return resultCollab;
    });
};

export const updateCollaborationCustomer = async (
  collaborationId: string,
  customerId: string
) => {
  await db
    .collection("collaborations")
    .doc(collaborationId)
    .update({
      customer: createRef("customers", customerId),
      updatedAt: new Date(),
    });
};

export const fetchCollaborations = (uid: string, clientid: string) => {
  return collaboration
    .fetchCollaborationsByUser(uid, clientid)
    .then(async (collaborations) => {
      const newCollabs = await Promise.all(
        collaborations.map(async (collaboration: any) => {
          const refUser = createRef("users", uid);
          const user = await refUser.get();
          const message = await ChatMessage.unreadMessages(collaboration.id);
          collaboration.unreadMessages = message;

          if (collaboration.customer) {
            const customer = await collaboration.customer!.get();
            if (customer) {
              let customerData = customer.data() as Customer;
              if (customer.id && customerData) {
                console.log(`set customerData.id : ${customer?.id}`);
                customerData.id = customer?.id;
              }
              collaboration.customerModel = customerData;
            }
          }

          if (user) {
            let userData = user.data() as User;
            if (user.id) {
              userData.id = user.id;
            }
            collaboration.userModel = userData;
          }

          if (collaboration?.toUser?.length !== 0) {
            let temp = [] as any;
            await Promise.all(
              collaboration?.toUser?.map(async (item: any) => {
                const user = await servUser.getCachedUserByID(item.id);
                if (user) {
                  temp?.push(user);
                }
              })
            );
            collaboration.toUserData = temp;
          }

          if (collaboration.company) {
            const accessibleCompanies = userAct.getAccessibleCompany();

            if (accessibleCompanies && Array.isArray(accessibleCompanies)) {
              const companyAccess = accessibleCompanies.find(
                (company) => company.id === collaboration.company?.id
              );

              if (companyAccess) {
                collaboration.companyModel = companyAccess.companyModel;
              }
            } else {
              // Handle the case when accessibleCompanies is null or not an array
              console.error(
                "Accessible companies data is not valid:",
                accessibleCompanies
              );
            }
          }

          return collaboration;
        })
      );
      console.log(newCollabs, "newcoll olx");
      return newCollabs;
    });
};

// export const fetchCollaborations = (uid: string, company: string) => {
export const fetchCollaborationsByAllCompany = (
  companies: Array<firebase.firestore.DocumentReference>,
  useruid: string
) => {
  return collaboration
    .fetchCollaborationsByAllCompanyUser(companies)
    .then(async (collaborations) => {
      const newCollabs = await Promise.all(
        collaborations.map(async (collaboration: any) => {
          console.log(collaboration, "collaba newlid");
          const refUser = createRef("users", useruid);
          const user = await refUser.get();
          const message = await ChatMessage.unreadMessages(collaboration.id);
          collaboration.unreadMessages = message;

          if (collaboration.customer) {
            const customer = await collaboration.customer!.get();
            if (customer) {
              let customerData = customer.data() as Customer;
              if (customer.id && customerData) {
                customerData.id = customer.id;
              }
              collaboration.customerModel = customerData;
            }
          }
          if (user) {
            let userData = user.data() as User;
            if (user.id && userData) {
              userData.id = user.id;
            }
            collaboration.userModel = userData;
          }
          if (collaboration.company && collaboration.company.id) {
            const data = userAct.getAccessibleCompany();
            const companyAccess = data?.find(
              (company) => company.id === collaboration.company.id
            );

            if (companyAccess)
              collaboration.companyModel = companyAccess.companyModel;
          }

          let temp: any[] = [];

          const querySnapshot = await db
            .collection("collaborations")
            .doc(collaboration.id)
            .collection("rooms")
            .get();

          const promises = querySnapshot.docs.map(async (doc: any) => {
            if (doc.exists) {
              const accountRef = doc.data()?.account?.id;
              let accountsModel = null as any;

              if (accountRef) {
                const accountDoc = await db
                  .collection("account")
                  .doc(accountRef)
                  .get();
                if (accountDoc.exists) {
                  accountsModel = accountDoc.data();
                }
              }
              return {
                ...doc.data(),
                id: doc.id,
                accountsModel: accountsModel,
              };
            }
            return undefined;
          });
          if (!promises) return undefined;

          temp = await Promise.all(promises);

          if (!temp) return undefined;

          await Promise.all(
            temp?.map(async (y: any) => {
              let temp2 = [] as any;
              if (y && y?.toUser) {
                await Promise.all(
                  y.toUser?.map(async (z: any) => {
                    const user = await UserService.getCachedUserByID(z.id);
                    if (user) {
                      temp2.push({ ...user });
                    }
                  })
                );
              }
              y.toUserData = temp2;
            })
          );

          collaboration.roomsModel = temp;
          return collaboration;
        })
      );
      console.log(newCollabs, "newCOllfetchCompanybyall olx newlid");
      return newCollabs;
    });
};

export const fetchMessageCollaboration = async (
  listMessages: Message[],
  collaborationid: string,
  roomsid: string,
  lastMessage: any,
  selectedChat: string,
  callback: any
) => {
  lastMessage = undefined;
  if (listMessages && listMessages.length > 0 && listMessages != null) {
    Promise.all(listMessages);
    if (
      listMessages[0].collaborationModel !== undefined &&
      listMessages[0].collaborationModel?.id === selectedChat
    ) {
      return await message
        .fetchMessage(
          collaborationid,
          roomsid,
          lastMessage,
          (lastVisible: any) => {
            callback(lastVisible);
          }
        )
        .then(async (messages) => {
          if (!messages) return;

          const newMessage = await Promise.all(
            messages.map(async (messages) => {
              const collaboration = await messages.collaboration?.get();
              if (collaboration !== undefined) {
                messages.collaborationModel =
                  collaboration.data() as Collaboration;
                messages.collaborationModel.id = collaboration.id;
              }
              return messages;
            })
          );
          const reversedMessages = newMessage.reverse();

          lc.setItemLC(lc.LCName.SelectedChat, reversedMessages);

          return reversedMessages;
        });
    }
  }

  return await message
    .fetchMessage(collaborationid, roomsid, lastMessage, (lastVisible: any) => {
      callback(lastVisible);
    })
    .then(async (messages) => {
      if (!messages) return;

      const newMessage = await Promise.all(
        messages.map(async (messages) => {
          // const customer = await messages.customer!.get();
          // if (customer !== undefined) {
          //   messages.customerModel = customer.data() as Customer;
          //   messages.customerModel.id = customer.id;
          //   console.log("customer di chattsx: "+JSON.stringify(messages.customerModel));
          // }
          // let customer = undefined;
          // if (messages.customer && messages.customer?.id) {
          //   customer = await servCustomer.getCustomerByID(messages.customer?.id);
          // }
          const collaboration = await messages.collaboration?.get();
          if (collaboration !== undefined) {
            messages.collaborationModel = collaboration.data() as Collaboration;
            messages.collaborationModel.id = collaboration.id;
          }

          // const user = await messages.user?.get();
          // if (user !== undefined) {
          //   messages.userModel = user.data() as User;
          // }
          return messages;
        })
      );
      const reversedMessages = newMessage.reverse();

      // lc.setItemLC(lc.LCName.Messages + collaborationid, newMessage);
      lc.setItemLC(lc.LCName.SelectedChat, reversedMessages);
      // return newMessage;

      return reversedMessages;
    });
};

export const fetchCollaborationPrevMessage = (
  collaborationid: string,
  lastMessage: any,
  callback: any,
  roomid?: string
) => {
  const newMessage: Message[] = [];
  return message
    .fetchMessagePrev(
      collaborationid,
      lastMessage,
      (lastVisible: any) => {
        callback(lastVisible);
      },
      roomid
    )
    .then(async (messages) => {
      console.log(messages, "messages bux");
      if (messages) {
        const prevMessage = await Promise.all(
          messages.map(async (messages) => {
            return messages;
          })
        );
        const reversedPrevMessages = prevMessage.reverse();
        return reversedPrevMessages;
      } else {
        return newMessage;
      }
    });
};

export const createCollaborationMessage = async (
  newMessage: Message,
  companyID: string,
  selectedChat: string,
  account: Account | undefined,
  customer: Customer | undefined,
  callback: any,
  isRetry: boolean
) => {
  //Send Request To Server Side
  let Message: Message;
  if (!newMessage) return;

  /***
   * Convert Object To Updatable
   */
  Message = JSON.parse(JSON.stringify(newMessage));
  Message.collaboration = newMessage.collaboration;
  Message.room = newMessage.room;
  Message.user = newMessage.user;
  let arrUser = newMessage.user?.map((user) => {
    return user as firebase.firestore.DocumentReference;
  });
  Message.client = newMessage.client as firebase.firestore.DocumentReference;
  if (newMessage.customer)
    Message.customer =
      newMessage.customer as firebase.firestore.DocumentReference;
  Message.createdAt = newMessage.createdAt;
  Message.updatedAt = newMessage.updatedAt;
  if (Message.replyMessageID)
    Message.replyMessageID =
      newMessage.replyMessageID as firebase.firestore.DocumentReference;

  /***
   * End Convert Object To Updatable
   */
  let textContent: string = JSON.stringify(Message.textContent).replaceAll(
    `"`,
    ``
  );
  //message success created
  const onMessageCreated = async (response: string | void) => {
    if (response && response !== "") {
      Message.id = response;
      callback(Message, null);
    }
  };

  if (Message.channel === "whatsapp") {
    if (!account || !Message.customerPhoneNumber) {
      //Create Firebase Message
      Message.status = MessageStatus.failed;
      if (!account) {
        Message.resultMessage = "No account found.";
      } else {
        Message.resultMessage = "No data account was found.";
      }

      if (!isRetry) {
        message.createMessage(Message).then(onMessageCreated);
      } else {
        message.retryMessage(Message).then(onMessageCreated);
      }
      return;
    }

    await server
      .sendRequestMessage(
        Message,
        companyID,
        account.whatsappNumber,
        Message.customerPhoneNumber,
        textContent,
        (responseCode: string, responseJson: JSON) => {
          Message.responseCode = responseCode ?? "500";
          if (responseJson) {
            let newResponseJson = JSON.parse(JSON.stringify(responseJson));
            if (+responseCode !== 200 && +responseCode !== 202) {
              Message.status = MessageStatus.failed;
            } else {
              Message.status = MessageStatus.submitted;
            }
            Message.resultCode = newResponseJson.resultCode ?? "";
            Message.resultMessage = newResponseJson.message ?? "";
            Message.resultMessageId = newResponseJson.messageID ?? "";
            Message.errorCode = newResponseJson.errorCode ?? "";
            if (
              newResponseJson.conversationID ||
              newResponseJson.conversationCategory ||
              newResponseJson.cost
            ) {
              Message.conversation = newResponseJson.conversationID
                ? createRef("conversations", newResponseJson.conversationID)
                : undefined;
              Message.conversation_category =
                newResponseJson.conversationCategory ?? "";
              Message.oldCost = newResponseJson.cost ?? 0;
            }
            if (newResponseJson.error)
              Message.responseJson = JSON.stringify(newResponseJson);
            if (newResponseJson.whatsapp)
              Message.responseJson = newResponseJson.whatsapp ?? "";
          } else {
            Message.status = MessageStatus.failed;
            Message.resultMessage =
              "No response or reponsecode from server side. bozzz";
          }
          if (!isRetry) {
            message.createMessage(Message).then(async (response) => {
              if (response && response !== "") {
                Message.id = response;
                servUser.createNotificationMessageAnotherUser(
                  Message,
                  companyID
                );
                callback(Message, null);
              }
            });
          } else {
            message.retryMessage(Message).then(onMessageCreated);
          }
        }
      )
      .then(async (response) => {})
      .catch(function (error) {
        console.log("Error : " + error);
        Message.status = MessageStatus.failed;
        Message.resultMessage = error.message;
        !isRetry && message.createMessage(Message).then(onMessageCreated);
        isRetry && message.retryMessage(Message).then(onMessageCreated);
      });
  } else {
    if (!account || !Message?.collaboration?.id || !Message?.room?.id) {
      //Create Firebase Message
      Message.status = MessageStatus.failed;
      if (!account) {
        Message.resultMessage = "No account found.";
      } else {
        Message.resultMessage = "No data account was found.";
      }
      return;
    }

    const room = await RoomService.findRoomById(
      Message?.collaboration?.id,
      Message?.room?.id
    );
    const account_id = room?.account?.id ?? "";

    await server
      .sendRequestMarketplaceMessage(
        Message,
        companyID,
        account_id,
        Message.collaboration.id,
        Message.room.id,
        textContent,
        (responseCode: string, responseJson: JSON) => {
          Message.status = MessageStatus.submitted;
          Message.id = `pending-${Date.now()}`;
          if (!isRetry) {
            callback(Message, null);
          }
        }
      )
      .then(async (response) => {})
      .catch(function (error) {
        Message.status = MessageStatus.failed;
        Message.resultMessage = error.message;
      });
  }
};

export const clearUnreadMessages = async (collaborationId: string) => {
  return await message.updateUnreadMessages(collaborationId);
};

export const clearUnreadMessagesAllChat = async (collaborationId: string) => {
  return await message.updateUnreadMessagesAllChat(collaborationId);
};

export const clearUserUnreadMessages = async (collaborationId: string) => {
  return await message.updateUserUnreadMessages(collaborationId);
};

export const updateMessageRead = async (newMessage: Message) => {
  console.log(`Update Message Read >> ${JSON.stringify(newMessage.id)}`);
  return await message.updateMessageasRead(newMessage);
};

export const requestMarkAsReadMessage = async (jsonRequest: any) => {
  console.log(
    `JSON Request from chat to server: ${JSON.stringify(jsonRequest)}`
  );
  server.sendRequestMarkAsReadMessage(jsonRequest);
};

export const saveMessageMedia = (
  mediaUrl: string | undefined,
  filename: string | undefined
) => {
  if (mediaUrl && filename) {
    FileSaver.saveAs(mediaUrl, filename);
  } else {
    alert("Invalid URL Please Retry Download");
  }
};

export const updateLastInteraction = async (
  collaborationid: string,
  newMessage: Message,
  roomId?: string
) => {
  const refUser = createRef("users", lc.getItemLC("UID"));
  let collab = await createRef("collaborations", collaborationid)
    .get()
    .then(async (collab) => {
      let respCollab = collab.data() as Collaboration;
      respCollab.id = collab.id;
      return respCollab;
    });

  let arrUserUser = collab.toUser;
  if (collab.toUser && collab.toUser?.length > 0) {
    if (
      !arrUserUser?.find((user) => {
        return user.id == lc.getItemLC("UID");
      })
    ) {
      arrUserUser?.push(refUser);
    }
  }

  let lastMessage = newMessage.textContent;
  if (
    (newMessage.textContent === "" || newMessage.textContent === undefined) &&
    newMessage.filename !== undefined
  ) {
    lastMessage = newMessage.filename;
  }
  await db.collection("collaborations").doc(collaborationid).update({
    lastInteractionChannel: newMessage.channel,
    lastInteractionAt: newMessage.createdAt,
    lastInteractionType: newMessage.messageType,
    lastInteractionMessage: lastMessage,
    toUser: arrUserUser,
    updatedAt: new Date(),
  });

  if (roomId) {
    await db
      .collection("collaborations")
      .doc(collaborationid)
      .collection("rooms")
      .doc(roomId)
      .update({
        toUser: arrUserUser,
        updatedAt: new Date(),
        lastCustomerInteractionAt: newMessage.createdAt,
        profileName: collab.profileName,
      });
  }
  return collab;
};

export const updateLastInteractionUserChat = async (
  collaborationid: string,
  newMessage: Message
  // userId: string\
) => {
  let collab = await createRef("collaborations", collaborationid)
    .get()
    .then(async (collab) => {
      let respCollab = collab.data() as Collaboration;
      respCollab.id = collab.id;
      return respCollab;
    });

  let lastMessage = newMessage.textContent;
  if (
    (newMessage.textContent === "" || newMessage.textContent === undefined) &&
    newMessage.filename !== undefined
  ) {
    lastMessage = newMessage.filename;
  }

  await db.collection("collaborations").doc(collaborationid).update({
    lastInteractionChannel: newMessage.channel,
    lastInteractionAt: newMessage.createdAt,
    lastInteractionType: newMessage.messageType,
    lastInteractionMessage: lastMessage,
    updatedAt: new Date(),
  });
  return collab;
};

export const fetchFriendCollaborations = (uid: string) => {
  return collaboration
    .fetchFriendCollaborationsByUser(uid)
    .then(async (collaborations) => {
      const newCollabs = await Promise.all(
        collaborations.map(async (collaboration) => {
          //only 1 data with other user
          let user;
          if (
            collaboration.joinedUsers &&
            collaboration.joinedUsers.length > 0
          ) {
            for (let i = 0; i < collaboration.joinedUsers.length; i++) {
              if ((await collaboration.joinedUsers[i].get()).id !== uid) {
                user = await collaboration.joinedUsers[i].get();
              }
            }
          }
          // const company = await collaboration.company?.get();
          const message = await ChatMessage.userUnreadMessages(
            collaboration.id
          );
          collaboration.unreadMessages = message;

          // console.log("user ===>" + (user?.data() as User).name);
          if (user !== undefined) {
            let userData = user.data() as User;
            if (user.id !== undefined) {
              userData.id = user.id;
            }
            // user_companies model of friend
            const listFriendCompany =
              (await servUser.getListUserCompanyByID(user.id)) ?? [];

            listFriendCompany.forEach(async (company) => {
              if (userData.user_companies === undefined) {
                userData.user_companies = [];
              }
              userData.user_companies.push(company);
            });

            // user friend model
            collaboration.userModel = userData;
          }
          return collaboration;
        })
      );
      return newCollabs;
    });
};

export const createFriendCollaborationMessage = async (
  newMessage: Message,
  collaborationId: string,
  toUserId: string,
  callback: any
) => {
  let Message: Message;

  if (newMessage !== undefined) {
    /***
     * Convert Object To Updatable
     */
    Message = newMessage;
    /***
     * End Convert Object To Updatable
     */

    message.createMessage(Message).then(async (response) => {
      if (response && response !== "") {
        Message.id = response;
        callback(Message, null);
      }
    });

    message.increaseUnreadMessage(collaborationId, toUserId);
  }
};

export const fetchUserCollaborationByID = async (collabId: string) => {
  console.log("==> masuk fetchUserCollaborationByID balezzz", collabId);

  return collaboration
    .getByID(collabId)
    .then(async (collab) => {
      console.log(collab, "collab balezzz");
      const userUnreadmessage = await ChatMessage.userUnreadMessages(collab.id);
      console.log(userUnreadmessage, "userUnreadMessage balezzz");

      collab.unreadMessages = userUnreadmessage;

      const activeUserId = lc.getItemLC("UID");

      //only 1 data with other user

      if (collab.joinedUsers) {
        const userDoc = await collab.joinedUsers
          .find((user) => user.id !== activeUserId)
          ?.get();
        if (userDoc) {
          const userData = {
            ...userDoc.data(),
            id: userDoc.id,
          } as User;

          // user_companies model of friend
          const listFriendCompany =
            (await servUser.getListUserCompanyByID(userData.id!)) ?? [];
          userData.user_companies = [...listFriendCompany];
          // user friend model
          collab.userModel = userData;
        }
      }

      return collab;
    })
    .catch((error) => {
      console.log(`error`);
      return undefined;
    });
};

export const createMessageInitiateChatMarketplace = async (
  marketplace: string,
  orderId: string
) => {
  console.log("==> Masuk createMessageInitiateChatMarketplace INCHA");
  console.log(marketplace, "Marketplace INCHA");
  console.log(orderId, "orderId INCHA");
  const userId = lc.getUserId();
  console.log(userId, "userId INCHA");

  const initiatedChatResult = await order.sendRequestInitiateChatMarketplace(
    marketplace,
    orderId,
    userId
  );
  console.log(initiatedChatResult, "initiatedChatResult INCHA");
  return initiatedChatResult.data;
};
