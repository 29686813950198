import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../../modules/modal/modalConfirmationWithStateHandler";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";
import VariantListDesktop from "./variant-list/VariantListDesktop";
import VariantListMobile from "./variant-list/VariantListMobile";

interface VariantOption {
  isSummary?: boolean;
  onClickUpload?: boolean;
}

const ProductVariantsList = ({ isSummary = false, onClickUpload = false }) => {
  console.log("onClickUpload in variant list : ", onClickUpload);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  const rd_isFromSummary = useSelector(
    (state: RootState) => state.Product.isFromSummary
  );

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );
  const rd_Variants = useSelector((state: RootState) => state.Product.variants);
  const rd_VariantData = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );

  const rd_TempVariants = useSelector(
    (state: RootState) => state.Product.tempVariants
  );

  const rd_TempVariantMatrix: any = useSelector(
    (state: RootState) => state.Product.tempVariantsMatrix
  );

  const rd_TempVariantPhotos: any = useSelector(
    (state: RootState) => state.Product.tempProductVariantPhotos
  );

  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );

  const reduxSelectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );

  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );

  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const URI = rd_productID ? "/product/update" : "/product/create-product";

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: `${URI}/basic-information`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: `${URI}/media-upload`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: `${URI}/package-weight`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: `${URI}/shipments`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: `${URI}/shipments`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.VariantsCategory"),
      path: `${URI}/variants-category`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const UsersBreadcrumbsForSummary: Array<PageLink> = [
    {
      title: t("AddNewProduct.Title.Summary"),
      path: `${URI}/summary`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const [isClickNext, setIsClickNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHasError, setIsHasError] = useState(true);
  const [isModalFromSummary, setModalFromSummary] = useState(false);
  const dispatch = useDispatch();

  let variantFields: any = {};
  rd_Variants?.forEach((item: any, index: number) => {
    variantFields[`variant${index + 1}`] = item.name;
  });

  const nav = useNavigate();

  // eslint-disable-line
  const isDataValid = (variantsMatrix: any) => {
    for (const variant of variantsMatrix) {
      if (!variant.sku || typeof variant.sku !== "string") {
        return false;
      }
      if (!variant.weight || variant.weight === 0) {
        return false;
      }
    }
    const optList: any = rd_Variants[0]?.optionList;
    if (rd_VariantPhotos.length < optList.length) {
      return false;
    }
    return true;
  };

  const handleNext = () => {
    console.log("CLICKED NEXT");
    setIsClickNext(true);

    const dataValid = isDataValid(rd_VariantMatrix);
    console.log("DATA VALID", dataValid);
    if (dataValid) {
      if (rd_isFromSummary) {
        nav(`${URI}/summary`);
      } else if (!isSummary) {
        nav(`${URI}/summary`);
      } else {
        nav(`${URI}/summary`);
      }
    }
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleSavedChanges = async () => {
    setIsLoading(true);
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onVariantsList;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        // updateDecrementNumberOfProductStorefront =
        await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        // updateIncrementNumberOfProductStorefront =
        await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        // updateIncrementNumberOfProductStorefront =
        await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
    }

    console.log("STEP -> 1", rd_ProductPhotos);
    /***
     * Media Page - Process Save Photos
     */
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      console.log("STEP -> 1.0");
      for await (const [i, photos] of rd_ProductPhotos.entries()) {
        const element = photos;
        console.log("STEP -> 1.1 Element", element, "new Prod", newProduct.id);
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("STEP -> 1.2 Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === i ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    console.log("STEP -> PHOTO SAVED");

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
    }

    console.log("STEP -> Vide Saved");

    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }

    console.log("STEP -> Shipment Saved");

    // save company data
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      // create companies
      let newCompanies: any = [];
      console.log("companies : ", companies);
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const companyRef = createRef("company", company.company);
        newCompanies.push({ ...company, company: companyRef });
      }
      const dataCompanies = await actions.createDataCompany(
        productID,
        newCompanies
      );

      // create marketplace collection

      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        console.log("STEP -> company", company);
        console.log("STEP -> marketplace", marketplace);

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );

        const account: any =
          findAccount && findAccount[0] && findAccount[0].id
            ? findAccount[0].id
            : "";
        const accountRef = createRef("account", account ? account : "");

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {
                
                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );

        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );
        if (marketplaceId && marketplaceId !== "") {
          resultMarketplaceId.push(marketplaceId);
        } else {
          isSuccess = false;
        }
      }
    }

    console.log("STEP BEFORE PHOTO VALIDATION");
    // if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];

        console.log("VARPHOTO Elem", element);
        let elem = {
          ...element,
          name: element.name || `variant-photo-${index}`,
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }

    console.log("VAR, STEP VAR CAT", rd_Variants);
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        let variantId: any;
        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          variantId = actions.createDataVariant(productID, dataVariant);
        }

        if (variantId && variantId !== "") {
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let productVariantId: any;
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (attribute.inputValues && (attribute.inputValues[0] || attribute.inputValues[1])) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if(attribute.inputValues[0] || attribute.inputValues[1]){
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId ? attribute.attributeId.toString() : "",
            categoryAttributeModel
          );
        }
      }
    }
  };

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onVariantsList));
    if (!isSummary) {
      if (
        !reduxNewProduct ||
        !reduxNewProduct.brand ||
        !reduxNewProduct.name ||
        reduxNewProduct.name === "" ||
        !reduxNewProduct.storeFront ||
        !reduxNewProduct.description ||
        reduxNewProduct.description === ""
      ) {
        if (rd_productID) {
          return;
        }
        nav(`${URI}/basic-information`);
      } else if (!rd_SelectedCompanies || !rd_marketplaces) {
        nav(`${URI}/variants-category`);
      }
    }
  }, []);

  useEffect(() => {
    console.log("onClickUpload variant list : ", onClickUpload);
    console.log("isSummary  variant list : ", isSummary);
    if (onClickUpload && isSummary) handleNext();
  }, [onClickUpload, isSummary]);

  useEffect(() => {
    let newProduct = { ...reduxNewProduct };
    if (
      reduxSelectedBrandData &&
      reduxSelectedBrandData !== null &&
      reduxSelectedBrandData.value &&
      reduxSelectedBrandData.value !== null
    ) {
      const brandRef = createRef("brands", reduxSelectedBrandData.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [reduxSelectedBrandData]);

  const handleUnsavedChangesFromSummary = () => {
    dispatch(ReduxProduct.setVariants(rd_TempVariants));
    dispatch(ReduxProduct.setVariantMatrix(rd_TempVariantMatrix));
    dispatch(ReduxProduct.setProductVariantPhotos(rd_TempVariantPhotos));
    setModalFromSummary(false);
    nav(`${URI}/summary`);
  };

  const handleSavedChangesFromSummary = () => {
    setModalFromSummary(false);
    handleNext();
    // nav(`${URI}/summary`);
  };

  return (
    <>
      {!isSummary && (
        <PageTitle
          breadcrumbs={
            rd_isFromSummary ? UsersBreadcrumbsForSummary : UsersBreadcrumbs
          }
        >
          {t("AddNewProduct.Title.VariantList")}
        </PageTitle>
      )}

      {!isSummary && (
        <ModalConfirmationMoveToOtherPage
          isDirty={true}
          path={`${URI}`}
          onSave={() =>
            handleSavedChanges().then(() => {
              setIsLoading(false);
              dispatch(ReduxProductList.cleanReduxProductList(true));
              dispatch(ReduxProduct.cleanReduxProduct(true));
            })
          }
          onUnsave={() => handleUnsavedChanges()}
          isEmpty={false}
        />
      )}
      {rd_isFromSummary && (
        <ModalConfirmationWithStateHandler
          id="ModalCompanyAndMarketplaceFromSummary"
          isModalOpen={isModalFromSummary}
          title={t("Alert.Info")}
          body={t("Alert.UnsavedChanges")}
          saveText={t("Alert.Button.SaveChanges")}
          unSaveText={t("Alert.Button.LeaveWithoutSave")}
          cancelText={t("Alert.Button.Cancel")}
          setIsModalOpen={setModalFromSummary}
          onClose={() => setModalFromSummary(false)}
          onUnsave={() => handleUnsavedChangesFromSummary()}
          onSave={() => handleSavedChangesFromSummary()}
        />
      )}
      <div
        className={clsx(
          "d-flex flex-column flex-lg-column",
          isSummary ? "" : "h-100"
        )}
      >
        {!isTabletOrMobile &&
          !isSummary &&
          ProductHeaderTitle("AddNewProduct.Title.VariantList")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: !isSummary ? "92.5%" : "" }}
        >
          <div
            className={clsx(
              `overflow-hidden card h-100 ${
                isTabletOrMobile ? "" : isSummary ? "" : "p-7"
              }`
            )}
          >
            {isTabletOrMobile && !isSummary && <ProductMobileIcon />}
            <div
              style={{
                height: `${
                  !isSummary ? (isTabletOrMobile ? "100%" : "90%") : ""
                }`,
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile ? (isSummary ? "p-0 mb-5" : "p-5") : ""
                }`
              )}
            >
              {isSummary && (
                <div className="step-sub-title mt-3 fs-4 mb-3 mt-5">
                  {t("AddNewProduct.Title.VariantList")}
                  <span className="text-danger">*</span>
                </div>
              )}
              <div
                // ref={titleRef}
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-auto "
                )}
              >
                {!isSummary && isTabletOrMobile && (
                  <div className="d-flex flex-column mt-5">
                    {ProductStep(7, "AddNewProduct.Title.VariantList")}
                  </div>
                )}
                {!isSummary && (
                  <div className="fs-1 fw-bolder mt-5 mb-5">
                    {reduxNewProduct.name}
                  </div>
                )}

                {isTabletOrMobile ? (
                  <VariantListMobile
                    onClickNext={isClickNext}
                    setIsHasError={setIsHasError}
                  />
                ) : (
                  <VariantListDesktop
                    variantData={rd_VariantData}
                    variantFields={variantFields}
                    onClickNext={isClickNext}
                    setIsHasError={setIsHasError}
                    // clickCounter={clickCounter}
                  />
                )}
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx(
                  `d-flex bg-white mt-auto justify-content-between ${
                    isTabletOrMobile ? "mb-5" : ""
                  }`
                )}
              >
                <Link to={`${URI}/variants-category`}>
                  <Button
                    btnbs="primary"
                    cName={isTabletOrMobile ? "mx-5" : ""}
                    data-testid="btnBack"
                  >
                    {`${t("Storefront.Button.Back")}`}
                  </Button>
                </Link>
                <div className="d-flex">
                  {/* <Link to={rd_isFromSummary ? `${URI}/summary" : "/product/list"}>
                  <Button data-testid="btnCancel" btnbs="primary" type="button">
                    {t("AddNewProduct.Button.BPI.Cancel")}
                  </Button>
                </Link> */}
                  {!rd_isFromSummary && (
                    <Link to="/product/list">
                      <Button
                        data-testid="btnCancel"
                        btnbs="primary"
                        type="button"
                      >
                        {t("AddNewProduct.Button.BPI.Cancel")}
                      </Button>
                    </Link>
                  )}
                  {rd_isFromSummary && (
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                      disabled={
                        rd_VariantMatrix.length === 0 && rd_isFromSummary
                      }
                      onClick={() => setModalFromSummary(true)}
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  )}
                  <Button
                    id="btnNext"
                    data-testid="btnNext"
                    type="button"
                    btnbs="primary"
                    cName="mx-5"
                    onClick={() => handleNext()}
                  >
                    {/* {`${t("AddNewProduct.Button.BPI.Next")}`} */}
                    {!rd_isFromSummary
                      ? `${t("AddNewProduct.Button.BPI.Next")}`
                      : `${t("Storefront.Button.Confirm")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};

export default ProductVariantsList;
