import { Link, useLocation, useNavigate } from "react-router-dom";
// import ReactRouterPrompt from "react-router-prompt";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { createDataProduct } from "../../../../../../actions/product";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import "../../../../../../styles/css/select.css";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWihtButtonSavedUnsavedChanges from "../../../../../modules/modal/modalConfirmationWithButtonSavedUnsavedChanges";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import {
  formatSize,
  productMediaCheckFile,
  productMediaUpload,
  uploadFile,
} from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";

interface WeightOption {
  isSummary?: boolean;
  onClickUpload?: boolean;
}

const WeightProduct: React.FC<WeightOption> = ({
  isSummary = false,
  onClickUpload = false,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/create-product",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const nav = useNavigate();
  const dispatch = useDispatch();

  const [isValidProductWeight, setIsValidProductWeight] =
    useState<boolean>(true);
  const [isValidProductLength, setIsValidProductLength] =
    useState<boolean>(true);
  const [isValidProductWidth, setIsValidProductWidth] = useState<boolean>(true);
  const [isValidProductHeight, setIsValidProductHeight] =
    useState<boolean>(true);
  const [isValidProductDimension, setIsValidProductDimension] =
    useState<boolean>(true);

  const [isValidProductWeightRange, setIsValidProductWeightRange] =
    useState<boolean>(true);

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const reduxProductWeight = useSelector(
    (state: RootState) => state.Product.productWeight
  );
  const reduxProductDimensionLength = useSelector(
    (state: RootState) => state.Product.productDimensionLength
  );
  const reduxProductDimensionWidth = useSelector(
    (state: RootState) => state.Product.productDimensionWidth
  );
  const reduxProductDimensionHeight = useSelector(
    (state: RootState) => state.Product.productDimensionHeight
  );

  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );
  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const [isLoading, setIsLoading] = useState(false);
  useState<boolean>(false);

  const onChangeProductWeight = async (event: any) => {
    setIsValidProductWeight(true);
    let ProductWeightValue = event.target.value.replace(/^0+/, "");
    if (ProductWeightValue == 0 || ProductWeightValue.toString().length === 0) {
      setIsValidProductWeight(false);
    }

    setIsValidProductWeightRange(true);
    if (ProductWeightValue > 300000 || ProductWeightValue <= 0) {
      setIsValidProductWeightRange(false);
    }

    let newProduct = { ...reduxNewProduct };
    newProduct.weight = ProductWeightValue;
    dispatch(ReduxProduct.setProductWeight(ProductWeightValue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const onChangeProductLength = async (event: any) => {
    setIsValidProductLength(true);
    let ProductLengthValue = event.target.value.replace(/^0+/, "");
    if (
      ProductLengthValue <= 0 ||
      ProductLengthValue === "" ||
      ProductLengthValue > 300
    ) {
      setIsValidProductLength(false);
    }

    let newProduct = { ...reduxNewProduct };
    newProduct.length = ProductLengthValue;
    dispatch(ReduxProduct.setProductDimensionLength(ProductLengthValue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const onChangeProductWidth = async (event: any) => {
    setIsValidProductWidth(true);
    let ProductWidthValue = event.target.value.replace(/^0+/, "");
    if (
      ProductWidthValue <= 0 ||
      ProductWidthValue === "" ||
      ProductWidthValue > 300
    ) {
      setIsValidProductWidth(false);
    }

    let newProduct = { ...reduxNewProduct };
    newProduct.width = ProductWidthValue;
    dispatch(ReduxProduct.setProductDimensionWidth(ProductWidthValue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const onChangeProductHeight = async (event: any) => {
    setIsValidProductHeight(true);
    let ProductHeightValue = event.target.value.replace(/^0+/, "");
    if (
      ProductHeightValue <= 0 ||
      ProductHeightValue === "" ||
      ProductHeightValue > 300
    ) {
      setIsValidProductHeight(false);
    }

    let newProduct = { ...reduxNewProduct };
    newProduct.height = ProductHeightValue;
    dispatch(ReduxProduct.setProductDimensionHeight(ProductHeightValue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onPackageWeight));
    if (!isSummary) {
      if (
        !reduxNewProduct ||
        !reduxNewProduct.brand ||
        !reduxNewProduct.name ||
        reduxNewProduct.name === "" ||
        !reduxNewProduct.storeFront ||
        !reduxNewProduct.description ||
        reduxNewProduct.description === ""
      ) {
        if (rd_productID !== "") {
          return;
        }
        nav("/product/create-product/basic-information");
      } else if (!rd_ProductPhotos || rd_ProductPhotos.length === 0) {
        nav("/product/create-product/media-upload");
      }
    }
  }, []);

  useEffect(() => {
    if (onClickUpload && isSummary) handleNext();
  }, [onClickUpload, isSummary]);

  useEffect(() => {
    if (
      reduxProductDimensionWidth > 0 ||
      reduxProductDimensionLength > 0 ||
      reduxProductDimensionHeight > 0
    ) {
      setIsValidProductDimension(true);
    }
  }, [
    reduxProductDimensionLength,
    reduxProductDimensionWidth,
    reduxProductDimensionHeight,
  ]);

  const validateData = async () => {
    //check is product name not null
    let isValid = true;

    if (
      reduxProductDimensionLength == 0 ||
      reduxProductDimensionHeight == 0 ||
      reduxProductDimensionWidth == 0
    ) {
      setIsValidProductDimension(false);
      isValid = false;
    }

    if (
      !isValidProductDimension ||
      !isValidProductLength ||
      !isValidProductWidth ||
      !isValidProductHeight
    ) {
      // setIsValidProductDimension(false)
      isValid = false;
    }

    if (reduxProductWeight <= 0 || !isValidProductWeightRange) {
      setIsValidProductWeight(false);
      isValid = false;
    }

    if (reduxProductWeight <= 0 || reduxProductWeight > 300000) {
      setIsValidProductWeight(false);
      isValid = false;
    }

    if (reduxProductDimensionLength <= 0 || reduxProductDimensionLength > 300) {
      setIsValidProductLength(false);
      isValid = false;
    }

    if (reduxProductDimensionWidth <= 0 || reduxProductDimensionWidth > 300) {
      setIsValidProductWidth(false);
      isValid = false;
    }
    if (reduxProductDimensionHeight <= 0 || reduxProductDimensionHeight > 300) {
      setIsValidProductHeight(false);
      isValid = false;
    }

    if (!isValidProductDimension) {
      isValid = false;
    }

    if (!isValid) {
      return isValid;
    }

    return isValid;
  };

  const handleNext = async () => {
    if (
      !isValidProductHeight ||
      !isValidProductLength ||
      !isValidProductWidth
    ) {
      setIsValidProductDimension(false);
    } else {
      setIsValidProductDimension(true);
    }

    let valid = await validateData();

    if (valid) {
      if (!isSummary) {
        nav("/product/create-product/shipments");
      }
    } else {
      return;
    }
  };

  const handleSavedChanges = async () => {
    setIsLoading(true);
    console.log(
      "reduxNewProduct isDangerous : ",
      reduxNewProduct.isDangerousProduct
    );
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    let variantCategoriesData: any[] = [];
    // Save Data
    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onPackageWeight;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        const updateDecrementNumberOfProductStorefront =
          await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
    }
    /***
     * Media Page - Process Save Photos
     */
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      for (let index = 0; index < rd_ProductPhotos.length; index++) {
        const element = rd_ProductPhotos[index];
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === index ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
      // }
    }
    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }
    // save company data
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      // create companies
      let newCompanies: any = [];
      console.log("companies : ", companies);
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const companyRef = createRef("company", company.company);
        newCompanies.push({ ...company, company: companyRef });
      }
      const dataCompanies = await actions.createDataCompany(
        productID,
        newCompanies
      );

      // create marketplace collection
      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );
        const account: any =
          findAccount && findAccount[0] && findAccount[0].id
            ? findAccount[0].id
            : "";
        const accountRef = createRef("account", account ? account : "");

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {
                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );

        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );
        if (marketplaceId && marketplaceId !== "") {
          resultMarketplaceId.push(marketplaceId);
        } else {
          isSuccess = false;
        }
      }
    }

    //if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];
        let elem = {
          ...element,
          name: element.name || "variant-photo",
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        let variantId: any;
        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          variantId = actions.createDataVariant(productID, dataVariant);
        }

        if (variantId && variantId !== "") {
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let productVariantId: any;
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (attribute.inputValues && (attribute.inputValues[0] || attribute.inputValues[1])) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if(attribute.inputValues[0] || attribute.inputValues[1]){
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId ? attribute.attributeId.toString() : "",
            categoryAttributeModel
          );
        }
      }
    }
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
    nav("/product/list");
  };

  return (
    <>
      {!isSummary && (
        <PageTitle breadcrumbs={UsersBreadcrumbs}>{`${t(
          "AddNewProduct.Title.PackageWeight"
        )}`}</PageTitle>
      )}
      {!isSummary && (
        <ModalConfirmationWihtButtonSavedUnsavedChanges
          id="handleCancelButton"
          title={`${t("Alert.Info")}`}
          body={t("Alert.UnsavedChanges")}
          savedOption={`${
            reduxProductWeight > 0 ||
            reduxProductDimensionHeight > 0 ||
            reduxProductDimensionLength > 0 ||
            reduxProductDimensionWidth > 0 ||
            (reduxNewProduct &&
              (!reduxNewProduct.id || reduxNewProduct.id === ""))
              ? t("Alert.Button.SaveChanges")
              : ""
          }`}
          unsavedOption={`${t("Alert.Button.LeaveWithoutSave")}`}
          cancelOption={`${t("Alert.Button.Cancel")}`}
          onSavedChanges={() =>
            handleSavedChanges().then(() => {
              setIsLoading(false);
              dispatch(ReduxProductList.cleanReduxProductList(true));
              dispatch(ReduxProduct.cleanReduxProduct(true));
              nav("product/list");
            })
          }
          onUnsavedChanges={() => handleUnsavedChanges()}
        />
      )}

      {!isSummary && (
        <ModalConfirmationMoveToOtherPage
          isDirty={
            reduxProductWeight > 0 ||
            reduxProductDimensionHeight > 0 ||
            reduxProductDimensionLength > 0 ||
            reduxProductDimensionWidth > 0 ||
            (reduxNewProduct &&
              (!reduxNewProduct.id || reduxNewProduct.id === ""))
          }
          path="/product/create-product/"
          onSave={() =>
            handleSavedChanges().then(() => {
              setIsLoading(false);
              dispatch(ReduxProductList.cleanReduxProductList(true));
              dispatch(ReduxProduct.cleanReduxProduct(true));
            })
          }
          onUnsave={() => handleUnsavedChanges()}
          isEmpty={false}
        />
      )}

      {/* { !isTabletOrMobile &&  
        <div className="card mb-1 mb-xl-2 p-3 d-flex flex-row space">
          <div>
            <div className="h2">
              <h2 className="w-100">
                <span
                  className="bi bi-window-plus fs-2x"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                ></span>
                {t("AddNewProduct.Title.PackageWeight")}
              </h2>
            </div>
          </div>
        </div>
      } */}

      <div
        className={clsx(
          "d-flex flex-column flex-lg-column",
          isSummary ? "" : "h-100"
        )}
      >
        {!isTabletOrMobile &&
          !isSummary &&
          ProductHeaderTitle("AddNewProduct.Title.PackageWeight")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: "92.5%" }}
        >
          <div
            // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
            className={clsx(
              `overflow-hidden card h-100 ${
                isTabletOrMobile ? "" : isSummary ? "" : "p-7"
              }`
            )}
          >
            {isTabletOrMobile && !isSummary && <ProductMobileIcon />}
            <div
              style={{
                height: "90%",
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile && !isSummary ? "p-5" : "p-5"
                }`
              )}
            >
              <div
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-hidden "
                )}
              >
                <div className="d-flex flex-column">
                  {!isSummary && isTabletOrMobile && (
                    <div className="d-flex flex-column mt-5">
                      {ProductStep(3, "AddNewProduct.Title.PackageWeight")}
                    </div>
                  )}

                  {/* Input Weight */}
                  <div className="step-sub-title mb-2 fs-4">
                    <label
                      id="product-weight"
                      className="form-check-label"
                      data-testid="ProductWeight_TitlePackageWeightInput"
                    >
                      {t("AddNewProduct.Info.Weight")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : isSummary ? "w-75" : "w-50"
                      }`
                    )}
                  >
                    <div
                      className="w-100"
                      style={{ width: isTabletOrMobile ? "100%" : "120%" }}
                    >
                      <div className="d-flex align-items-center form-control border-2 px-0 py-0">
                        <input
                          type="number"
                          min="1"
                          max="999"
                          id="bpi-input-product-name"
                          name="bpi-input-product-name"
                          className="form-control border-0"
                          onChange={onChangeProductWeight}
                          value={reduxProductWeight}
                          data-testid="ProductWeight_PackageWeightInput"
                        />
                        <p
                          className="border-0 bg-white my-0"
                          data-testid="ProductWeight_PackageWeightInputUnit"
                          style={{
                            paddingRight: "10px",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          gram
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        data-testid="storefrontTitleErrValid"
                      >
                        {!isValidProductWeightRange && (
                          <div
                            id="weight-error-range"
                            style={{ color: "red" }}
                            role="alert-input-ProductWeight"
                            data-testid="ProductWeight_ErrInvalidWeight"
                          >
                            {t("AddNewProduct.Alert.Weight.RangeofWeight")}
                          </div>
                        )}
                      </div>
                      <div
                        className="d-flex"
                        data-testid="storefrontTitleErrValid"
                      >
                        {!isValidProductWeight && (
                          <div
                            id="weight-error-weight"
                            style={{ color: "red" }}
                            role="alert-input-ProductWeight"
                            data-testid="ProductWeight_ErrWeightRequired"
                          >
                            {t("AddNewProduct.Alert.Weight")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Input Dimension */}
                <div className="step-sub-title mt-3 fs-4">
                  <label
                    className="form-check-label"
                    data-testid="ProductWeight_TitleDimension"
                  >
                    {t("AddNewProduct.Info.Dimension")}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                {/* Dimension Wrapper */}
                <div
                  className={clsx(
                    "align-items-start pt-5",
                    isTabletOrMobile
                      ? "w-100"
                      : isSummary
                      ? "d-flex w-75 gap-4"
                      : "row"
                  )}
                  data-testid="dimension-wrapper"
                >
                  {/* Length */}
                  <div className="col">
                    <div className="step-sub-title mb-2 fs-4">
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "500" }}
                        data-testid="ProductWeight_TitleLenght"
                      >
                        {t("AddNewProduct.Info.Length")}
                        <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div
                      className={clsx(
                        `"d-flex flex-row mb-2 ${
                          isTabletOrMobile ? "w-100" : "w-50"
                        }`
                      )}
                    >
                      <div className="w-100">
                        <div
                          className="d-flex align-items-center form-control border-2 px-0 py-0"
                          style={{ width: isTabletOrMobile ? "100%" : "200%" }}
                        >
                          <input
                            type="number"
                            id="bpi-input-product-name"
                            name="bpi-input-product-name"
                            className="form-control border-0"
                            onChange={onChangeProductLength}
                            value={reduxProductDimensionLength}
                            data-testid="ProductWeight_LenghtInput"
                          />
                          <p
                            className="border-0 bg-white my-0"
                            style={{
                              paddingRight: "10px",
                              color: "gray",
                              textAlign: "center",
                            }}
                            data-testid="ProductWeight_TitleLenghtUnit"
                          >
                            cm
                          </p>
                        </div>
                        <div className="d-flex mb-3">
                          {!isValidProductDimension && (
                            <div
                              style={{ color: "red" }}
                              role="alert-input-ProductLength"
                              data-testid="ProductWeight_ErrDimensionRequired"
                            >
                              {t("AddNewProduct.Alert.Dimension")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      data-testid="storefrontTitleErrValid"
                    >
                      {!isValidProductLength && (
                        <div
                          style={{ color: "red" }}
                          role="alert-input-ProductLength"
                          data-testid="ProductWeight_ErrInvalidLength"
                        >
                          {t("AddNewProduct.Alert.Dimension.RangeofLength")}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End Length */}
                  {/* Width */}
                  <div className="col">
                    <div className="step-sub-title mb-2 fs-4">
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "500" }}
                        data-testid="ProductWeight_TitleWidth"
                      >
                        {t("AddNewProduct.Info.Width")}
                        <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div
                      className={clsx(
                        `"d-flex flex-row mb-2 ${
                          isTabletOrMobile ? "w-100" : "w-50"
                        }`
                      )}
                    >
                      <div className="w-100">
                        <div
                          className="d-flex align-items-center form-control border-2 px-0 py-0"
                          style={{ width: isTabletOrMobile ? "100%" : "200%" }}
                        >
                          <input
                            type="number"
                            id="bpi-input-product-name"
                            name="bpi-input-product-name"
                            className="form-control border-0"
                            data-testid="ProductWeight_WidthInput"
                            onChange={onChangeProductWidth}
                            value={reduxProductDimensionWidth}
                          />
                          <p
                            className="border-0 bg-white my-0"
                            style={{
                              paddingRight: "10px",
                              color: "gray",
                              textAlign: "center",
                            }}
                            data-testid="ProductWeight_TitleWidthUnit"
                          >
                            cm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      data-testid="storefrontTitleErrValid"
                    >
                      {!isValidProductWidth && (
                        <div
                          id="error-weight-width"
                          style={{ color: "red" }}
                          role="alert-input-ProductWeight"
                          data-testid="ProductWeight_ErrInvalidWidth"
                        >
                          {t("AddNewProduct.Alert.Dimension.RangeofWidth")}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End Width */}
                  {/* Height */}
                  <div className="col">
                    <div className="step-sub-title mb-2 fs-4">
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "500" }}
                        data-testid="ProductWeight_TitleHeight"
                      >
                        {t("AddNewProduct.Info.Height")}
                        <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div
                      className={clsx(
                        `"d-flex flex-row mb-2 ${
                          isTabletOrMobile ? "w-100" : "w-50"
                        }`
                      )}
                    >
                      <div className="w-100">
                        <div
                          className="d-flex align-items-center form-control border-2 px-0 py-0"
                          style={{ width: isTabletOrMobile ? "100%" : "200%" }}
                        >
                          <input
                            type="number"
                            id="bpi-input-product-name"
                            name="bpi-input-product-name"
                            className="form-control border-0"
                            onChange={onChangeProductHeight}
                            value={reduxProductDimensionHeight}
                            data-testid="ProductWeight_HeightInput"
                          />
                          <p
                            className="border-0 bg-white my-0"
                            style={{
                              paddingRight: "10px",
                              color: "gray",
                              textAlign: "center",
                            }}
                            data-testid="ProductWeight_TitleHeightUnit"
                          >
                            cm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      data-testid="storefrontTitleErrValid"
                    >
                      {!isValidProductHeight && (
                        <div
                          id="error-weight-height"
                          style={{ color: "red" }}
                          role="alert-input-ProductWeight"
                          data-testid="ProductWeight_ErrInvalidHeight"
                        >
                          {t("AddNewProduct.Alert.Dimension.RangeofHeight")}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End Height */}
                </div>
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx(
                  `d-flex space justify-content-between mt-auto w-100 ${
                    isTabletOrMobile ? "mb-5" : ""
                  }`
                )}
              >
                <Link to="/product/create-product/media-upload">
                  <Button
                    btnbs="primary"
                    cName={isTabletOrMobile ? "mx-5" : ""}
                    data-testid="btnBack"
                  >
                    {`${t("Storefront.Button.Back")}`}
                  </Button>
                </Link>
                <div className="d-flex">
                  <Link to="/product/list">
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  </Link>
                  <Button
                    data-testid="btnNext"
                    type="button"
                    btnbs="primary"
                    cName="mx-5"
                    onClick={() => handleNext()}
                  >
                    {`${t("AddNewProduct.Button.BPI.Next")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Button
        id="btnValidateDataWeight"
        data-testid="btnValidateDataWeight"
        type="button"
        btnbs="primary"
        cName="mx-5 "
        style={{
          visibility: "hidden",
          pointerEvents: "none",
          height: "0px",
          padding: "0.1px",
        }}
        onClick={() => validateData()}
      >
        {`${t("AddNewProduct.Button.BPI.Next")}`}
      </Button>
      {isLoading && <Loading />}
    </>
  );
};
export default WeightProduct;
