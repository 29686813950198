import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { useFirestoreTable } from "../../context/TableContext";

function getArrayPages(
  maxPages: number,
  page: number,
  isMobile: boolean = false
) {
  const maxDisplayPages = isMobile ? 4 : 6;
  const halfDisplay = maxDisplayPages / 2;

  if (maxPages > maxDisplayPages) {
    if (page <= halfDisplay) {
      return [
        ...Array.from({ length: halfDisplay }, (_, i) => i + 1),
        "...",
        maxPages,
      ];
    }
    if (page > maxPages - halfDisplay) {
      return [
        1,
        "...",
        ...Array.from(
          { length: halfDisplay },
          (_, i) => maxPages - (halfDisplay - i) + 1
        ),
      ];
    }

    if (isMobile) {
      return [1, "...", page, "...", maxPages];
    }

    return [1, "...", page - 1, page, page + 1, "...", maxPages];
  }

  const array: string | number[] = [];
  for (let i = 1; i <= maxPages; i++) {
    array.push(i);
  }
  return array;
}

const MemberPagination: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  const { data, memberTableState, updateTableState, updatePage } =
    useFirestoreTable();
  console.log("MemberListPaginations", data, memberTableState);
  const allCount = data?.allCount || 0;
  const page = memberTableState.currentPage;

  const maxPages = Math.ceil(allCount / memberTableState.limit);

  const arrayPages = getArrayPages(maxPages, page, isTabletOrMobile);
  console.log("MemberListPaginations", maxPages, arrayPages);
  return (
    <div
      className={clsx("pt-2 p-4", {
        row: !isTabletOrMobile,
        "pb-6 d-flex align-items-center justify-content-between":
          isTabletOrMobile,
      })}
      data-testid="member-pagination"
    >
      <div
        className={clsx({
          "col-4 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start":
            !isTabletOrMobile,
        })}
      >
        <div
          v-if="enableItemsPerPageDropdown"
          className="dataTables_length flex-left"
          id="kt_customers_table_length"
          data-testid="pagination"
        >
          <label>
            <select
              name="kt_customers_table_length"
              className="form-select form-select-sm form-select-solid"
              value={memberTableState.limit}
              onChange={(e) => {
                const selectedValue = parseInt(e.target.value);
                updateTableState({ limit: selectedValue });
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
              <option value={70}>70</option>
              <option value={80}>80</option>
              <option value={90}>90</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
      </div>
      <div
        className={clsx({
          "col-8 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end":
            !isTabletOrMobile,
        })}
      >
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {/* Button Previous */}
            <li className={`page-item ${page > 1 ? "" : "disabled"}`}>
              <button
                type="button"
                className="page-link "
                onClick={() => {
                  if (page > 1) {
                    updatePage(page - 1);
                  }
                }}
              >
                {isTabletOrMobile ? (
                  <span>«</span>
                ) : (
                  <span>« {t("Marketplace.Pages.Previous")}</span>
                )}
              </button>
            </li>

            {arrayPages.map((i, index) => {
              return (
                <li
                  key={`${i}_${index}`}
                  className={clsx("page-item", { active: i === page })}
                >
                  <button
                    type="button"
                    className={clsx(
                      "page-link",
                      { "me-3": !isTabletOrMobile },
                      { disabled: typeof i !== "number" }
                    )}
                    disabled={typeof i !== "number"}
                    onClick={() => {
                      if (typeof i === "number" && i !== page) {
                        updatePage(i);
                      }
                    }}
                  >
                    {i}
                  </button>
                </li>
              );
            })}

            <li className={`page-item ${page < maxPages ? "" : "disabled"}`}>
              <button
                type="button"
                className="page-link "
                onClick={() => {
                  if (page < maxPages) {
                    updatePage(page + 1);
                  }
                }}
              >
                {isTabletOrMobile ? (
                  <span>»</span>
                ) : (
                  <span>{t("Marketplace.Pages.Next")} »</span>
                )}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MemberPagination;
