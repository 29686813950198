import { useTranslation } from "react-i18next";
import User, { UserStatus } from "../../../../../../../models/User";
import moment from "moment";
import { useFirestoreTable } from "../../context/TableContext";
import ActionCell from "../table/columns/actionCell";

const stringToTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const MemberCard: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: userData,
    isLoading,
    isFetching,
    memberTableState,
    selectedItems,
    setSelectedItems,
  } = useFirestoreTable();

  const userList = userData?.items || [];

  return (
    <>
      <div className="d-flex px-2 pb-3" data-testid="order-card">
        {/* <input
            className="form-check-input my-auto"
            type="checkbox"
            data-kt-check={isAllSelected}
            data-kt-check-target="#kt_table_users .form-check-input"
            checked={isAllSelected}
            onChange={() => onSelectAll()}
          />
          <label className="form-check-label px-3" style={{ fontSize: "18px", fontWeight: "600" }}>
            {t("Storefront.Button.SelectMarketplace.SelectAll")}
          </label> */}
      </div>

      {userList.map((user: User) => (
        <Card key={user.id} user={user} />
      ))}
    </>
  );
};

function Card({ user }: { user: User }) {
  const { t } = useTranslation();

  return (
    <div
      className="p-4 mb-3 fw-bold card bg-secondary"
      style={{
        // backgroundColor: "#C6E7FD",
        fontSize: "14px",
        color: "#6C7288",
      }}
    >
      <div className="d-flex justify-content-between align-items-end border-bottom p-0 m-0">
        <div className="d-flex my-auto">
          <span className="my-auto fw-bolder">
            {stringToTitleCase(user.email ?? "")}
          </span>
        </div>

        <div className=" ml-auto" style={{ fontSize: "10px" }}>
          {/* <ShippingLimitLabel shippingTimestamp={user.shipByDate!} /> */}
          {/* <p>test</p> */}
          <div className="ml-auto" style={{ fontSize: "10px" }}>
            <ActionCell data={user} />
          </div>
        </div>
      </div>

      {user.registeredAt && (
        <div className="pt-2 d-flex justify-content-between">
          <span>{t("Common.Column.Email")}</span>
          <span>{user.email ?? ""}</span>
        </div>
      )}

      {user.registeredAt && (
        <div className="pt-2 d-flex justify-content-between">
          <span>{t("Common.Column.PhoneNumber")}</span>
          <span>{user?.phoneNumber ?? "-"}</span>
        </div>
      )}

      <div className="pt-2  d-flex justify-content-between">
        <span>{t("UserManagement.Table.Header.AccessType")}</span>
        <span>
          {user.role
            ? user.role.charAt(0).toUpperCase() + user.role.slice(1)
            : "User"}
        </span>
      </div>

      {user.registeredAt && (
        <div className="pt-2 d-flex justify-content-between">
          <span>{t("Common.Modal.Column.Header.Status")}</span>
          <span
            className="p-2 rounded"
            style={{
              backgroundColor: user.state === "online" ? "#E8FFF3" : "#FFE8E8",
              color: user.state === "online" ? "#50CD89" : "#CD5050",
            }}
          >
            {user?.state}
          </span>
        </div>
      )}
      {user.status === UserStatus.invited && (
        <div className="pt-2 d-flex justify-content-between gap-2">
          <span>{t("UserManagement.Table.Header.InvitationExpired")}</span>
          <span>
            {moment(user.inviteExpiredAt?.toDate()).format("DD MMMM YYYY, HH:mm ")}
          </span>
        </div>
      )}
    </div>
  );
}

export default MemberCard;
