import moment from "moment";
import "moment/locale/id";
import { useTranslation } from "react-i18next";
// import timeAgo from './ChatTimeV2';

export default function ChatTime(dateconvert: number) {
  const { i18n, t } = useTranslation();

  function getLanguage() {
    return i18n.language;
  }

  return <div>{timeAgo(new Date(dateconvert * 1000), getLanguage())}</div>;
  // moment.locale(getLanguage());

  // function toDateTime(secs:number) {
  //   var t = new Date(1970, 0, 1); // Epoch
  //   t.setSeconds(secs);
  //   return t;
  // }

  // Version 1
  // return (
  //   <div>
  //     {moment(new Date(dateconvert*1000)).fromNow()}
  //   </div>
  // )
}

const MONTH_NAMES_EN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTH_NAMES_ID = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const MONTH_NUMBER_ID = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const DAYS_NUMBER_ID = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

function getFormattedDate(
  date: Date,
  language: string,
  preformattedDate = false,
  hideYear = false
) {
  const day = date.getDate();
  //  < 10 ? "0" + date.getDate() : "" + date.getDate();
  const month_long = language === "id" ? MONTH_NAMES_ID[date.getMonth()] : MONTH_NAMES_EN[date.getMonth()];
  const month = date.getMonth() + 1;
    // date.getMonth() + 1 < 10
    //   ? "0" + (date.getMonth() + 1)
    //   : "" + (date.getMonth() + 1);
  const year = date.getFullYear();
  const hours =
    date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes();

  // if (minutes < 10) {
  //   // Adding leading zero to minutes
  //   minutes = 0;
  // }

  if (preformattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return ` ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10 Januari - 10:20 / January 10th at 10:20
    // return `${ day }/${ month } ${ hours }:${ minutes }`;
    // return language === "id" ? `${day} ${month_long} - ${hours}:${minutes}` : `${month_long} ${day}th at ${hours}:${minutes}`;
    return language === "id" ? `${day} ${month_long}, ${hours}:${minutes}` : `${day} ${month_long}, ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day}/${month}/${year} ${hours}:${minutes}`; //${ hours }:${ minutes }`;
}

// --- Main function
function timeAgo(dateParam: Date, language: string) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const todayDate = new Date();
  const today = todayDate.getTime();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date.getTime()) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const isToday = todayDate.toDateString() === date.toDateString();
  const isYesterday = yesterday.getDate() === date.getDate();
  const isThisYear = todayDate.getFullYear() === date.getFullYear();
  const isThisWeek = Math.abs(today - date.getTime()) <= 6 * DAY_IN_MS; 

  // if (seconds < 5) {
  //   if(language  === "id") return "Baru saja";
  //   else return "now"; //Now | Baru saja
  // else if (seconds < 60) {
  //   if(language  === "id") return seconds+" detik yg lalu";
  //   else return seconds+" seconds ago"; //seconds ago | detik yg lalu
  // } else if (seconds < 90) {
  //   if(language  === "id") return "sekitar 1 menit yang lalu";
  //   else return "about a minute ago"; //about a minute ago | sekitar satu menit yang lalu
  // } else if (minutes < 60) {
  //   if(language  === "id") return minutes+" menit yg lalu";
  //   else return minutes+" minutes ago"; //minutes ago | menit yg lalu
  // }
  // else if (minutes === 60 || hours > 1) {
  //   if(language  === "id") return hours+" jam yg lalu";
  //   else return hours+" hours ago"; //hours ago | jam yg lalu
  // } else if (hours < 24) {
  //   if(language  === "id") return hours+" jam yg lalu";
  //   else return hours+" hours ago"; //hours ago | jam yg lalu
  // } else if (isToday) {
  //   if(language  === "id") return 'Hari ini '+getFormattedDate(date,language, true, false); // Today at 10:20 | Hari ini 10:20
  //   else return 'Today '+getFormattedDate(date,language, true, false); // Today at 10:20 | Hari ini 10:20
  // }
  if (hours < 24 && !isYesterday) {
    if (language === "id") return "Hari ini, " + getFormattedDate(date, language, true, false);
    // 10/January at 10:20 | 10/January 10:20
    else return "Today, " + getFormattedDate(date, language, true, false); // 10/January at 10:20 | 10/January 10:20
  } else if (isYesterday) {
    if (language === "id")
      return "Kemarin, "+ getFormattedDate(date, language, true, false); // + getFormattedDate(date, language, true, false)
    // Yesterday at 10:20 | Kemarin 10:20
    else return "Yesterday, "+ getFormattedDate(date, language, true, false); // + getFormattedDate(date, language, true, false); // Yesterday at 10:20 | Kemarin 10:20
  } else if (isThisWeek) {
    if (language === "id") return moment(date).format('dddd, HH:mm');
    // 10/January at 10:20 | 10/January 10:20
    else return moment(date).format('dddd, HH:mm'); // 10/January at 10:20 | 10/January 10:20
  } else if (isThisYear) {
    if (language === "id") return getFormattedDate(date, language, false, true);
    // 10/January at 10:20 | 10/January 10:20
    else return getFormattedDate(date, language, false, true); // 10/January at 10:20 | 10/January 10:20
  } else {
    if (language === "id") return getFormattedDate(date, language);
    // 10/January at 10:20 | 10/January 10:20
    else return getFormattedDate(date, language); // 10/January at 10:20 | 10/January 10:20
  }
}

export function ChatTime_Old(dateconvert: number) {
  const { i18n } = useTranslation();
  // const dateconvert:number = datecon.getTime();
  function getLanguage() {
    return i18n.language;
  }
  moment.locale(getLanguage());
  return moment(dateconvert).fromNow();
}
