import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import * as WABA from "../../../../../../modules/waba-accounts/redux/WABAAccountSlice";

const WABAAccountsListGrouping = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selected, clearSelected } = useListView();
  const queryClient = useQueryClient();
  const { query } = useQueryResponse();

  const [isSelectedHasContact, setIsSelectedHasContact] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { state, updateState } = useQueryRequest();

  return (
    <>
      <div
        className={clsx(
          "d-flex justify-content-end align-items-center",
          isTabletOrMobile ? "w-100" : ""
        )}
      >
        {!isTabletOrMobile &&  (
          <>
            {/* <div className="fw-bolder me-5">
              {selected.length > 0 ? (
                <span className="me-2">
                  {selected.length} {t("Contacts.Info.Selected")}
                </span>
              ) : null}
            </div> */}
            <button
              type="button"
              data-testid="refresh-waba-list"
              className="btn btn-light-primary me-2 w-100"
              onClick={() => {
                dispatch(WABA.cleanReduxWABAAccount(true));
              }}
            >
              {" "}
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </>
        )}
      </div>
    </>
  );
};

export { WABAAccountsListGrouping };