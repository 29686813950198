import React, { useState } from "react";

type Props = {
  onChange?: (days: number[]) => void;
  selected: number[];
  disabled: boolean;
};

const CampaignDaySelect = ({ onChange, selected, disabled }: Props) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(
    selected ? selected : []
  );

  const handleDayClick = (day: number) => {
    let newSelected: number[] = [];

    if (selectedDays.includes(day)) {
      newSelected = selectedDays.filter((selectedDay) => selectedDay !== day);
    } else {
      newSelected = [...selectedDays, day];
    }

    if (newSelected.length !== 0) {
      setSelectedDays(newSelected);

      if (onChange !== undefined) {
        onChange(newSelected);
      }
    }

    console.log("Selected Days:", newSelected);
  };

  const styles = `
      .calendar-container {
        background-color: #F5F8FA;
        padding: 1rem;
        border-radius: 4px;
      }
      .calendar-buttons {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        max-width: 350px;
        margin: 0 auto;
        border-radius: 6px;
        overflow: hidden
      }

      .border-right{
        border-right: 1px solid #EFF2F5 !important;
      }
      .border-bottom{
        border-bottom: 1px solid #EFF2F5 !important;
      }
  
      .day-button {
        background-color: #ffffff;
        border: none;
        outline: none;
        padding: 6px;
        transition: background-color 0.2s;
      }
      .day-button:enabled {
        cursor: pointer;
      }
  
      .day-button.selected:enabled, .day-button.selected:hover:enabled {
        background-color: #007bff;
        color: white;
      }
      .day-button.selected:disabled {
        background-color: #c9deff;
        color:white;
      }
      .day-button:hover:enabled {
        background-color: #0095E8;
        color: white;
      }
    `;

  return (
    <div className="calendar-container">
      <style>{styles}</style>
      <div className="calendar-buttons">
        {[...Array(35)].map((_, index) => {
          const day = index + 1;
          const isSelected = selectedDays.includes(day);

          return (
            <button
              key={day}
              data-testid={`button-date-${day}`}
              className={`day-button ${isSelected ? "selected" : ""} ${
                day % 7 === 0 ? "" : "border-right"
              } ${day <= 28 ? "border-bottom" : ""}`}
              onClick={() => {
                if (day <= 31) {
                  handleDayClick(day);
                }
              }}
              disabled={day > 31 || disabled}
            >
              {day <= 31 ? day : ""}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignDaySelect;
