import React from "react";
import clsx from "clsx";
import "../css/uploadedimage.css";

interface ButtonProps {
  /**
   * base color of the button with hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbs?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * light style color of the button with hover, defined with $theme-light-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnlg?:
    | "primary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark"
    | "light-primary"
    | "light-success"
    | "light-info"
    | "light-warning"
    | "light-danger"
    | "light-dark";
  /**
   * background style color of the button without hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbg?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * className to be added to the button
   */
  cName?: string;
  /**
   * className to be added to the button
   */
  biIcon?: string;
  /**
   * props for the button
   */
  isMobile?: boolean;
  onClickFn?: any;
  isHidden?: boolean;
  isVideoButton?: boolean;
  checked?: boolean;
  onChecked?:any;
  imgSrc?: string;
  isHasCover?: boolean;
  fixWidth?: boolean;
  /**
   *
   * props for the button
   */
  [propName: string]: any;
}

/**
 * Primary UI component for user interaction
 */
function UploadImageThumbnail({
  btnbs,
  btnlg,
  btnbg,
  cName,
  biIcon,
  isMobile,
  onClickFn,
  isHidden,
  isVideoButton,
  checked,
  imgSrc,
  onChecked,
  isHasCover,
  fixWidth = false,
  ...props
}: ButtonProps) {
  return (
    <div className="">
      <div
        className={clsx("", 
          {
            "d-none" : isHidden,
            "uploaded-image-mobile" : isMobile,
            "uploaded-image-desktop" : !isMobile,
            "w-175px" : !isMobile && fixWidth,
            "ms-7" : isVideoButton && !isMobile
          }
        )}

        style={{
          backgroundImage: `url('${imgSrc}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        data-testid = {"MediaUpload"+props.datatestid}
      >
        {props.children}
      </div>
      <div className={clsx(
            "button-delete semi-transparent-button border-top-zero",
            {
              "button-div-height-mobile" : isMobile,
              "button-div-height-desktop" : !isMobile,
              "w-175px" : !isMobile && fixWidth,
              "justify-content-center" : !isHasCover
            }
          )}
          style={{justifyContent:"center"}}
        >
        {isHasCover && (<>
          {/* Radio Button */}
          <input
            type="radio"
            checked={checked}
            className="radio-inbutton-image"
            name="radio"
            data-testid = {"MediaUploadSelected-"+props.datatestid}
            onChange={onChecked}
          />
          
          {/* Text Cover */}
          {!isMobile && <span className="cover-text" 
            data-testid = {"MediaUploadCover-"+props.datatestid}>COVER</span>}
          </>
        )}

          {/* Delete Button */}
          <i
            className={clsx(`bi bi-trash3`,
              isHasCover ? "icon-right" : "")}
            style={{ cursor: "pointer", color: `${!isHasCover ? "white" : ""}` }}
            data-testid = {"MediaUploadDelete-"+props.datatestid}
            onClick={onClickFn}
          ></i>
        </div>
    </div>
  );
}

export default UploadImageThumbnail;
