import { useTranslation } from "react-i18next";

function AlertSuccessProcess() {
  const { t } = useTranslation();
  console.log("AlertModal Contact List Success");
  return (
    <div className="modal fade" tabIndex={-1} id="modal_success_process">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body row align-items-center justify-content-center">
            <i className="bi bi-check-circle fs-5x text-success text-center"></i>
            <div>
              <h6 id="successProcessAlertNotif" className="text-center py-2">
                Alert
              </h6>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary btn-sm fs-5 text py-1"
                data-bs-dismiss="modal"
              >
                {t("BS.Button.Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertSuccessProcess;
