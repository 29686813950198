import Template from "../../../../models/Template";

const sliceData = (data: Template[], startIndex: number, endIndex: number) => {
  let newDataTemplate: Template[] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const datatemplate = data[index];
    newDataTemplate.push(datatemplate);
  }
  return newDataTemplate;
};

export const templateSorting = (
  data: Template[],
  sort: string | undefined,
  limit: number | undefined,
  action: string | undefined,
  orderBy: string | undefined,
  searchBy: string | undefined,
  curretPage: number | undefined,
  callback: any
) => {
  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort : " + sort);
  console.log("order : " + orderBy);
  console.log("search : " + searchBy);
  console.log("current page : " + curretPage);
  
  console.log(sort, "sortingg rvx");

  if (curretPage === undefined) curretPage = 1;
  if (limit === undefined) limit = 10;
  if (orderBy === undefined) orderBy = "desc";
  if (searchBy === undefined) searchBy = "";
  if (sort === undefined || sort === "deleted") sort = "lastUpdatedAt";

  let datatemplates: Template[] = [];

  if (data.length == 0) {
    return datatemplates;
  }

  for (let index = 0; index < data.length; index++) {
    datatemplates.push(data[index]);
  }

  //Search Data
  if (
    searchBy &&
    searchBy != null &&
    searchBy != "" &&
    datatemplates.length > 0
  ) {
    datatemplates = datatemplates.filter((data) =>
      data.templateName
        ?.toLowerCase()
        .match(new RegExp(searchBy!.toLowerCase(), "g"))
    );
  }

  //Order Data
  if (datatemplates.length > 0) {
    if (sort === "templateName") {
      datatemplates = datatemplates?.sort((a, b) => {
        if (a.templateName === undefined || a.templateName === "") return 1;
        if (b.templateName === undefined || b.templateName === "") return -1;
        return a.templateName!.toString().toLowerCase() >
          b.templateName?.toString().toLowerCase()
          ? 1
          : -1;
      });
      if (orderBy === "desc") {
        datatemplates = datatemplates?.sort((a, b) => {
          if (a.templateName === undefined || a.templateName === "") return -1;
          if (b.templateName === undefined || b.templateName === "") return 1;
          return a.templateName!.toString().toLowerCase() >
            b.templateName?.toString().toLowerCase()
            ? -1
            : 1;
        });
      }
    }

    if (sort === "templateCategory") {
      datatemplates = datatemplates?.sort((a, b) => {
        if (a.category === undefined || a.category === "") return 1;
        if (b.category === undefined || b.category === "") return -1;
        return a.category.toString().toLowerCase() >
          b.category.toString().toLowerCase()
          ? 1
          : -1;
      });

      if (orderBy === "desc") {
        datatemplates = datatemplates?.sort((a, b) => {
          if (a.category === undefined || a.category === "") return -1;
          if (b.category === undefined || b.category === "") return 1;
          return a.category?.toString().toLowerCase() >
            b.category?.toString().toLowerCase()
            ? -1
            : 1;
        });
      }
    }

    if (sort === "lastUpdatedAt") {
      datatemplates = datatemplates?.sort((a, b) => {
        if (!a.updatedAt || a.updatedAt === undefined) return 1;
        if (!b.updatedAt || b.updatedAt === undefined) return -1;
        // return new Date(a.updatedAt) > new Date(b.updatedAt)
        return a.updatedAt > b.updatedAt ? 1 : -1;
      });
      if (orderBy === "desc") {
        datatemplates = datatemplates?.sort((a, b) => {
          if (!a.updatedAt || a.updatedAt === undefined) return -1;
          if (!b.updatedAt || b.updatedAt === undefined) return 1;
          // return new Date(a.updatedAt) > new Date(b.updatedAt)
          return a.updatedAt > b.updatedAt ? -1 : 1;
        });
      }
    }

    if (sort === "companyName") {
      datatemplates = datatemplates?.sort((a, b) => {
        if (!a.companyName || a.companyName === undefined) return 1;
        if (!b.companyName || b.companyName === undefined) return -1;
        return a.companyName.toLowerCase() > b.companyName.toLowerCase()
          ? 1
          : -1;
      });
      if (orderBy === "desc") {
        datatemplates = datatemplates?.sort((a, b) => {
          if (!a.companyName || a.companyName === undefined) return -1;
          if (!b.companyName || b.companyName === undefined) return 1;
          return a.companyName.toLowerCase() > b.companyName.toLowerCase()
            ? -1
            : 1;
        });
      }
    }
  }
  callback(datatemplates.length);
  if (curretPage == 1) {
    let endIndex = curretPage * limit;
    datatemplates = Array.from(datatemplates).slice(0, limit);
  } else {
    let endIndex = curretPage * limit;
    let startIndex = endIndex - limit;
    if (startIndex > datatemplates.length) {
      return [];
    } else {
      datatemplates = Array.from(datatemplates).slice(startIndex, endIndex);
    }
  }
  // console.log("Hasil Data : "+JSON.stringify(dataCollabs));
  return datatemplates;
};
