import React, { InputHTMLAttributes } from "react";
type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  debounce?: boolean;
  debounceDelay?: number;
};

// Button component
const Input: React.FC<InputProps> = ({ children, ...props }) => {
  return (
    <input
      {...props}
      onChange={(e) => {
        if (props.debounce) {
          debounce(() => props.onChange?.(e), props.debounceDelay ?? props.debounceDelay ?? 500);
          return;
        }
        props.onChange?.(e);
      }}
    >
      {children}
    </input>
  );
};

export default Input;

let debounceTimeout: NodeJS.Timeout | null;
function debounce(callback: () => void, delay: number) {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(callback, delay);
}
