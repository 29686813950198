import clsx from "clsx";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import * as ServStorefront from "../../../../../../db/serviceStorefront";
import { KTSVG } from "../../../../../../resources/helpers";
import { toAbsoluteUrl } from "../../../../../../resources/helpers/AssetHelpers";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import { ListMarketplace } from "../../../../../../util/Marketplace";
import Storefront, {
  MarketplaceCategoryList,
  storefrontStatus,
  storefrontStep,
} from "../../../../../models/Storefront";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../../modules/modal/modalConfirmationWithStateHandler";
import * as ReduxStorefront from "../../../../../modules/product/storefront/StorefrontSlice";
import { PageLink, PageTitle } from "../../../../core";
import MarketplaceCategoryModalDesktop from "../../../../../modules/modal/storefront-category/MarketplaceModalDesktop";
import MarketplaceCategoryModalMobile from "../../../../../modules/modal/storefront-category/MarketplaceModalMobile";
import StorefrontMobileIcon from "./StorefrontMobileIcon";

interface TabProps {
  id: string;
  text: string;
  activeTab: string;
  setActiveTab: any;
  disabled?: boolean;
  checkedState?: any;
  setCheckedState?: any;
}

interface TabContentProps {
  id: string;
  activeTab: string;
}

const Tab: FC<TabProps> = ({ id, text, setActiveTab, activeTab, disabled }) => {
  const listMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );
  const dispatch = useDispatch();
  const currentMarketplace: any = listMarketplace.find(
    (m: any) => m.name === id.toLowerCase()
  );
  const isCurrentTabActive = activeTab === id.toLowerCase();

  const handleTabClicked = () => {
    console.log("id to active : ", id);
    setActiveTab(id.toLowerCase());
  };

  const toggleIsCheck = (marketplace: any) => {
    let res = { ...marketplace, isChecked: !marketplace.isChecked };
    return res;
  };

  const handleChange = (id: string) => {
    // check if marketplace is in the redux list
    const isCurrentMarketplaceInList = listMarketplace.some(
      (m: any) => m.name === id
    );
    // add to redux if marketplace is not in the list & set checked state to: true
    if (!isCurrentMarketplaceInList) {
      const data = { name: id, isChecked: true, category: [] };
      dispatch(
        ReduxStorefront.setStorefrontListMarketplace([...listMarketplace, data])
      );
    }
    // if marketplace is in redux list
    else {
      // get current marketplace to toggle
      const currentMarketplace = listMarketplace.find(
        (m: any) => m.name === id
      );
      const data = toggleIsCheck(currentMarketplace);

      const currentListMarketplace: any = [...listMarketplace];

      const categoryIndexToReplace = currentListMarketplace.findIndex(
        (m: any) => m.name === id
      );
      if (categoryIndexToReplace !== -1) {
        currentListMarketplace[categoryIndexToReplace] = data;
      } else {
        currentListMarketplace.push(data);
      }
      dispatch(
        ReduxStorefront.setStorefrontListMarketplace(currentListMarketplace)
      );
    }
  };

  return (
    <div className="mx-1" key={text}>
      <div
        className={clsx(
          `nav-link d-flex align-items-center gap-2 px-2 rounded-top`,
          {
            "pointer-none": disabled,
            "bg-primary text-white": isCurrentTabActive,
            "bg-gray-500 text-white": !isCurrentTabActive,
          }
        )}
      >
        <input
          data-testid={"checkbox-" + text}
          type="checkbox"
          disabled={disabled}
          checked={
            (currentMarketplace && currentMarketplace.isChecked) || false
          }
          onChange={() => handleChange(id)}
          style={{ accentColor: "white" }}
        />
        <div
          className="d-flex align-items-center gap-2 cursor-pointer"
          onClick={handleTabClicked}
        >
          <img
            className="h-20px"
            src={toAbsoluteUrl(
              `/media/logos/marketplace/${id.toLowerCase()}.png`
            )}
            alt={text}
          />
          <span
            className={clsx({
              "pointer-none text-gray-300": disabled,
              "cursor-pointer": !disabled,
            })}
          >
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

const TabContent: FC<TabContentProps> = ({ id, activeTab, children }) => {
  return (
    <>
      {activeTab === id.toLowerCase() ? (
        <div key={id} id={id} role="tabpanel">
          {children}
        </div>
      ) : null}
    </>
  );
};

const StorefrontSelectMarketplace = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("shopee");
  const listMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Info.Storefront"),
      path: "/storefront/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Info.BreadCrumb.AddStorefront"),
      path: "/storefront/create-storefront/storefront-name",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const handleScrollNext = () => {
    let tab = document.getElementById("tab-marketplace");

    tab!.scrollBy({
      left: 120,
      behavior: "smooth",
    });
  };

  const handleScrollBack = () => {
    let tab = document.getElementById("tab-marketplace");

    tab!.scrollBy({
      left: -120,
      behavior: "smooth",
    });
  };

  const nav = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleNext = async () => {
    setIsSubmitted(true);
    // check if selected marketplace but not select category
    //loop through list marketplace in redux
    let validCategories: any;

    listMarketplace.map((m: any) => {
      // get checked value state & length of categories
      let isCheckValid = m.isChecked;
      let isCategoriesValid = m.category.length > 0;
      console.log(
        "isvalid " + isCheckValid + ", category valid " + isCategoriesValid
      );
      if (isCheckValid && isCategoriesValid) {
        validCategories = {
          ...validCategories,
          [m.name]: true,
        };
      } else if (isCheckValid && !isCategoriesValid)
        validCategories = {
          ...validCategories,
          [m.name]: false,
        };
    });

    if (validCategories !== undefined) {
      const isAllValid = Object.values(validCategories).every(
        (value) => value === true
      );
      if (isAllValid) {
        nav("/storefront/create-storefront/summary");
      } else {
        // if marketplace is checked, but not selecting a category yet.
        const marketplaceNotValid = Object.keys(validCategories).filter(
          function (key) {
            return validCategories[key] === false;
          }
        )[0];
        setActiveTab(marketplaceNotValid.toLowerCase());
      }
    } else {
      setIsModalOpen(true);
    }
    dispatch(ReduxStorefront.setStorefrontListMarketplace(listMarketplace));
  };

  const dispatch = useDispatch();
  const reduxStorefrontName = useSelector(
    (state: RootState) => state.Storefront.storefrontName
  );
  const reduxStorefrontAndSelectedMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  const handleUnsavedChanges = () => {
    dispatch(ReduxStorefront.cleanReduxStorefront);
  };

  const createModelStorefront = (user: string, storefrontName: string) => {
    const client = lc.getItemLC(lc.LCName.Client);
    const clientRef = createRef("clients", client.id);
    const productCategoriesData: Storefront = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      status: storefrontStatus.drafted,
      lastStep: storefrontStep.onSelectMarketplace,
      client: clientRef
    };
    return productCategoriesData;
  };

  const createModelStorefrontForUpdate = (
    user: string,
    storefrontName: string
  ) => {
    const storefrontData: Storefront = {
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      updatedBy: createRef("users", user),
      status: storefrontStatus.drafted,
      lastStep: storefrontStep.onSelectMarketplace,
    };
    return storefrontData;
  };

  const createModelMarketplaceCategory = (
    user: string,
    categoriesData: any,
    isChecked: boolean,
    type: string
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      isChecked,
      type: type,
    };
    return marketplaceCategoriesData;
  };

  const createModelMarketplaceCategoryForUpdate = (
    user: string,
    categoriesData: any,
    isChecked: boolean,
    type: string
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      updatedAt: Timestamp.now(),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      isChecked,
      type: type,
    };
    return marketplaceCategoriesData;
  };

  const handleSavedChanges = async () => {
    let user = lc.getItemLC("UID");
    let modelStorefront: any;
    if (!reduxStorefrontId) {
      console.log("STL perform save on select marketplace");
      modelStorefront = createModelStorefront(user, reduxStorefrontName.trim());
      await ServStorefront.createStorefront(modelStorefront)
        .then(async (id) => {
          console.log(
            `success create product categories ${reduxStorefrontName}}`
          );
          console.log("id", id);
          if (id) {
            //looping for selected marketplace
            for (
              let index = 0;
              index < reduxStorefrontAndSelectedMarketplace.length;
              index++
            ) {
              let data: any = undefined;
              let market: any = reduxStorefrontAndSelectedMarketplace[index];
              let type = market.name.toLowerCase();
              let isChecked = market.isChecked;
              let selectedCategory = market?.category;
              for (let i = 0; i < selectedCategory.length; i++) {
                let arrayCategories = selectedCategory[i];
                data = { ...arrayCategories };
              }
              let arrayData = [];
              arrayData.push({ ...data });
              let marketplaceData = createModelMarketplaceCategory(
                user,
                arrayData,
                isChecked,
                type
              );

              if (marketplaceData) {
                //create sub collection
                await ServStorefront.createSelectedMarketplaceProductCategory(
                  id,
                  marketplaceData
                )
                  .then(() => {
                    console.log(
                      `success create subcollection product categories ${reduxStorefrontName}`
                    );
                  })
                  .catch((error) => {
                    console.log("error add subcolection " + error);
                  });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("STL Perform update");
      modelStorefront = createModelStorefrontForUpdate(
        user,
        reduxStorefrontName.trim()
      );
      await ServStorefront.updateStorefront(
        reduxStorefrontId,
        modelStorefront
      ).then(async () => {
        for (
          let index = 0;
          index < reduxStorefrontAndSelectedMarketplace.length;
          index++
        ) {
          let data: any = undefined;
          let market: any = reduxStorefrontAndSelectedMarketplace[index];
          let type = market.name.toLowerCase();
          let isChecked = market.isChecked;
          let selectedCategory = market?.category;
          for (let i = 0; i < selectedCategory.length; i++) {
            let arrayCategories = selectedCategory[i];
            data = { ...arrayCategories };
          }
          let arrayData = [];
          arrayData.push({ ...data });
          let marketplaceData = createModelMarketplaceCategoryForUpdate(
            user,
            arrayData,
            isChecked,
            type
          );

          if (marketplaceData) {
            console.log("STL marketplace data", marketplaceData);
            //get subcollection by type == Shopee
            const getSubCollectionId =
              await ServStorefront.getStorefrontMarketplaceCategoryById(
                reduxStorefrontId
              );
            console.log("getSubCollectionId : ", getSubCollectionId);
            const findByType = getSubCollectionId.find((marketplace: any) => {
              return marketplace.type == type;
            });
            await ServStorefront.updateSelectedMarketplaceProductCategory(
              reduxStorefrontId,
              findByType.id,
              marketplaceData
            )
              .then(() => {
                console.log(
                  `success create subcollection product categories ${reduxStorefrontName}`
                );
              })
              .catch((error) => {
                console.log("error add subcolection " + error);
              });
          }
        }
      });
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event: any) => {
    setSelectAll((prev) => !prev);

    const activeMarketplaces: any = ListMarketplace.filter(
      (marketplace) => marketplace.isDisabled === false
    ).map((filtered) => ({
      name: filtered.name.toLowerCase(),
      isChecked: !selectAll,
      category: [],
    }));

    const listMarketplaces = [...reduxStorefrontAndSelectedMarketplace];
    // merge two array
    const merged = activeMarketplaces.map(
      (obj: any) =>
        listMarketplaces.find(
          (o: any) => o.name.toLowerCase() === obj.name.toLowerCase()
        ) || obj
    );

    const toggled = merged.map((m: any) => ({
      ...m,
      isChecked: !selectAll,
    }));

    // merge both array
    dispatch(ReduxStorefront.setStorefrontListMarketplace(toggled));
  };

  const activeMarketplaces: any = ListMarketplace.filter(
    (marketplace) => marketplace.isDisabled === false
  ).map((filtered) => ({
    name: filtered.name,
    isChecked: false,
    category: [],
  }));

  const listMarketplaces = [...reduxStorefrontAndSelectedMarketplace];
  // merge two array
  const merged = activeMarketplaces.map(
    (obj: any) => listMarketplaces.find((o: any) => o.name === obj.name) || obj
  );

  const isAllSelected = merged.every((value: any) => value.isChecked === true);

  useEffect(() => {
    if (isAllSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [isAllSelected]);

  const reduxStorefrontId = useSelector(
    (state: RootState) => state.Storefront.storefrontID
  );

  const storefrontList = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );

  function convertArray(array: any) {
    return [Object.values(array[0])];
  }

  useEffect(() => {
    if (reduxStorefrontId) {
      const getStorefront: any = storefrontList.find(
        (storefront) => storefront.id === reduxStorefrontId
      );
      console.log(
        "getStorefront.list marketplace : ",
        getStorefront.marketplaceCategory
      );

      let data: any = [];
      getStorefront?.marketplaceCategory?.map((marketplace: any) => {
        console.log("STL conv before", marketplace.categories);
        const category = convertArray(marketplace.categories);
        console.log("STL conv after", category);
        let objectMarketplace = {
          name: marketplace.type,
          isChecked: marketplace.isChecked,
          category: category,
        };
        data.push(objectMarketplace);
      });
      console.log("converted data ", data);
      dispatch(ReduxStorefront.setStorefrontName(getStorefront?.name));
      dispatch(ReduxStorefront.setStorefrontListMarketplace(data));
    }
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("Storefront.Info.BreadCrumb.SelectMarketplace")}
      </PageTitle>

      <ModalConfirmationWithStateHandler
        isModalOpen={isModalOpen}
        onConfirm={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        data-testid="modalNotSelectingMarketplace"
        id="handleNotSelectingMarketplace"
        body={t("Storefront.Alert.SelectMarketplace")}
        confirmText={`${t("Storefront.Button.Back")}`}
        title={t("Storefront.Alert.Warning")}
      />

      <ModalConfirmationMoveToOtherPage
        isDirty={reduxStorefrontName.length > 0}
        path="/storefront/create-storefront"
        onSave={() => handleSavedChanges()}
        onUnsave={() => handleUnsavedChanges()}
      />

      <div className="d-flex flex-column flex-lg-column h-100">
        <div className="overflow-hidden card mb-1 mb-xl-2 h-100">
          {isTabletOrMobile && <StorefrontMobileIcon />}
          <div
            style={{ height: "90%" }}
            className={clsx(
              `d-flex flex-column ${
                isTabletOrMobile ? "p-10" : "px-7 pt-7 pb-0"
              }`
            )}
          >
            <div className="h1">{t("Storefront.Name.SelectMarketplace")}</div>
            {/* tabs */}
            {!isTabletOrMobile && (
              <div className="d-flex align-items-center mb-5 mt-10 gap-4">
                <div
                  className={clsx(`d-flex align-items-center gap-2 p-2 rounded-top mb-1
                ${selectAll === true ? "bg-primary" : "bg-transparent"}
                `)}
                >
                  <input
                    type="checkbox"
                    id="selectAll"
                    name="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    style={{
                      accentColor: "white",
                    }}
                  />
                  <span
                    className={clsx(
                      `${selectAll === true ? "text-white" : "text-dark"}`
                    )}
                  >
                    {t("Storefront.Button.SelectMarketplace.SelectAll")}
                  </span>
                </div>
                <div className="cursor-pointer" onClick={handleScrollBack}>
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr022.svg"
                    className={clsx("svg-icon-dark")}
                  />
                </div>
                <div
                  id="tab-marketplace"
                  style={{
                    width: `${isTabletOrMobile ? "400px" : "600px"}`,
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  {ListMarketplace.map((marketplace) => (
                    <Fragment key={marketplace.name}>
                      <Tab
                        id={marketplace.name.toLowerCase()}
                        text={marketplace.name}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        disabled={marketplace.isDisabled}
                      />
                    </Fragment>
                  ))}
                </div>
                <div className="cursor-pointer" onClick={handleScrollNext}>
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr023.svg"
                    className="svg-icon svg-icon-dark"
                  />
                </div>
              </div>
            )}
            {isTabletOrMobile && (
              <div className="d-flex flex-column align-items-center mb-5 mt-10 gap-4">
                <div className="d-flex align-items-center gap-2 p-2 rounded-top bg-primary w-75">
                  <input
                    type="checkbox"
                    id="selectAll"
                    name="selectAll"
                    onChange={handleSelectAll}
                    checked={selectAll}
                    style={{
                      accentColor: "white",
                    }}
                  />
                  <span
                    className={clsx(
                      `${selectAll === true ? "text-white" : "text-dark"}`
                    )}
                  >
                    {t("Storefront.Button.SelectMarketplace.SelectAll")}
                  </span>
                </div>
                <div className="d-flex flex-row w-100">
                  <div className="cursor-pointer" onClick={handleScrollBack}>
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr022.svg"
                      className={clsx("svg-icon-dark")}
                    />
                  </div>
                  <div
                    id="tab-marketplace"
                    style={{
                      width: "400px",
                      display: "flex",
                      overflowX: "auto",
                    }}
                  >
                    {ListMarketplace.map((marketplace) => (
                      <Fragment key={marketplace.name}>
                        <Tab
                          id={marketplace.name.toLowerCase()}
                          text=""
                          activeTab={activeTab}
                          disabled={marketplace.isDisabled}
                          setActiveTab={setActiveTab}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <div className="cursor-pointer" onClick={handleScrollNext}>
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr023.svg"
                      className="svg-icon svg-icon-dark"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="tab-content" id="myTabContent">
              {ListMarketplace.map((marketplace) => (
                <TabContent
                  id={marketplace.name}
                  activeTab={activeTab}
                  key={marketplace.name}
                >
                  <div className="mt-5">
                    {!isTabletOrMobile && (
                      <MarketplaceCategoryModalDesktop
                        title={`${marketplace.name}`}
                        step="selectMarketplace"
                        isSubmitted={isSubmitted}
                        id={`${marketplace.name.toLowerCase()}`}
                      />
                    )}
                    {isTabletOrMobile && (
                      <MarketplaceCategoryModalMobile
                        title={`${marketplace.name}`}
                        step="selectMarketplace"
                        isSubmitted={isSubmitted}
                        id={`${marketplace.name.toLowerCase()}`}
                      />
                    )}
                  </div>
                </TabContent>
              ))}
            </div>
          </div>
          <div
            className={clsx(
              `d-flex space justify-content-between w-100 ${
                isTabletOrMobile ? "mb-5" : "pt-0 px-7 pb-0"
              }`
            )}
          >
            <Link to="/storefront/create-storefront/storefront-name">
              <Button btnbs="primary" cName="mx-5">
                {`${t("Storefront.Button.Back")}`}
              </Button>
            </Link>
            <div className="d-flex">
              <Link to="/storefront/list">
                <Button data-testid="btnCancel" btnbs="primary" type="button">
                  {t("Alert.Button.Cancel")}
                </Button>
              </Link>
              <Button
                data-testid="btnNext"
                type="button"
                btnbs="primary"
                cName="mx-5"
                onClick={() => handleNext()}
              >
                {`${t("Storefront.Button.Next")}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorefrontSelectMarketplace;
