import clsx from "clsx";
import { FC } from "react";
import { Column, Row } from "react-table";
import WABAAccount from '../../../../../../models/WABAccount'
import { Table } from "../../../../../../../types/ui";
type Props = {
  row: Row<WABAAccount & Table>;
};

const CustomRow: FC<Props> = ({ row }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (
          (cell.column as Column<WABAAccount>).id === "client" &&
          row.original.span
        ) {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({
                "text-end min-w-100px": cell.column.id === "actions",
              })}
              rowSpan={row.original.span}
            >
              {cell.render("Cell")}
            </td>
          );
        } else if (
          (cell.column as Column<WABAAccount>).id === "client" &&
          !row.original.span
        ) {
          return null;
        }
        // else if (
        //   (cell.column as Column<WABAAccount>).id === 'wabaAccount' &&
        //   typeof row.original.client === 'undefined'
        // ) {
        //   return null; // Skip rendering the cell
        // }

        return (
          <td
            {...cell.getCellProps()}
            className={clsx({
              "text-end min-w-100px": cell.column.id === "actions",
            })}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
