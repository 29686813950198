import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import * as storefrontAct from "../../../../../../actions/storefront";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import Company from "../../../../../models/Company";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../../modules/modal/modalConfirmationWithStateHandler";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";
import CompanyAndMarketplaceSelection from "./company-and-marketplace/CompanyAndMarketplaceSelection";
import {
  ShopeeProductCategoryAttribute,
  ShopeeProductCategoryAttributeList,
} from "../../../../../models/ShopeeProductCategoryAttribute";
import { categoryAttributes } from "../../../../../modules/product/redux/ProductRedux";
import { getAttributesByMarketplaceAndCategoryId } from "../../../../../../actions/marketplaceproductcategory";
import * as ReduxMarketplaceProductCategory from "../../../../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";

interface SelectCompanyAndMarketplaceProps {
  isSummary?: boolean;
  onClickUpload?: boolean;
}

const SelectCompanyAndMarketplace: React.FC<
  SelectCompanyAndMarketplaceProps
> = ({ isSummary = false, onClickUpload = false }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );

  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );

  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );

  const rd_isFromSummary = useSelector(
    (state: RootState) => state.Product.isFromSummary
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const rd_isGenerateCategoryAttributes = useSelector(
    (state: RootState) => state.Product.isGenerateCategoryAttributes
  );

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: "/product/create-product/basic-information",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: "/product/create-product/media-upload",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: "/product/create-product/package-weight",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: "/product/create-product/shipments",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsForSummary: Array<PageLink> = [
    {
      title: t("AddNewProduct.Title.Summary"),
      path: "/product/create-product/summary",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  console.log("PAGE, company marketplace", rd_marketplaces);
  let companyList: Company[] = lc.getItemLC("CompanyList");

  const [isValidCompanies, setIsValidCompanies] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const nav = useNavigate();
  const titleRef: any = useRef(null);

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const [oldDataCompanies, setOldDataCompanies] = useState<any>([]);

  const [oldDataMarketplaces, setOldDataMarketplaces] = useState<any>([]);

  const [isModalFromSummary, setModalFromSummary] = useState(false);

  const dispatch = useDispatch();

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );

  const isAllCompanyNotActive = (company: any[]) => {
    if (company?.length === 0) {
      return true;
    }
    return company.every((company) => company.isActive === false);
  };

  const isCompanyActiveAndHaveAtleastOneMarketplaceSelected = (
    companies: any[],
    marketplaces: any[]
  ) => {
    let hasActiveMarketplace = false;
    companies.forEach((company) => {
      if (!company.isActive) return;

      const activeIndex: number = marketplaces
        .filter((mp) => mp.company === company.company)
        .findIndex((mp) => mp.isActive === true);
      if (activeIndex >= 0) hasActiveMarketplace = true;
    });
    console.log(
      `check validation active marketplace : ${hasActiveMarketplace}`
    );
    return hasActiveMarketplace;
  };

  const marketplaceValidation = () => {
    let validation: any = [];
    for (let index = 0; index < rd_marketplaces.length; index++) {
      const marketplace = rd_marketplaces[index];
      const findCompany = rd_SelectedCompanies.find(
        (company) => company.company === marketplace.company
      );

      if (
        findCompany.isActive &&
        marketplace &&
        marketplace.isActive === true &&
        marketplace.company === findCompany.company &&
        (marketplace.category === undefined ||
          marketplace.category?.length <= 0)
      ) {
        validation = [...validation, false];
        const errorMarketplace: any =
          document.getElementsByClassName("alert-marketplace");
        errorMarketplace[0]?.scrollIntoView({ behavior: "smooth" });
      } else {
        validation = [...validation, true];
      }
    }
    console.log("validation : ", validation);
    if (validation.includes(false)) {
      return;
    }
  };

  const getProductCategoryAttributes = async () => {
    let isHasAttribute: boolean = false;
    //shopee
    const findShopee = rd_marketplaces.filter((market) => {
      const isActiveCompany = rd_SelectedCompanies.find((company) => {
        if (company.company === market.company && company.isActive === true)
          return company;
      });
      console.log(`isActiveCompany : ${JSON.stringify(isActiveCompany)}`);
      if (
        isActiveCompany &&
        market.marketplace.toLowerCase() === "shopee" &&
        market.isActive === true
      )
        return market;
    });
    console.log(`findShopee : ${findShopee.length}`);
    if (!findShopee || findShopee.length < 1) {
      dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(false));
      return isHasAttribute;
    }
    let findLastCategoryShopee: string[] = [];
    for (let index = 0; index < findShopee.length; index++) {
      const shopee = findShopee[index];
      console.log(`shopee: ${shopee}`);
      const category = shopee.category[0];
      console.log(`category : ${JSON.stringify(category)}`);
      const data = Object.values(category);
      console.log(`data : ${data}`);
      const findLastCategory: any = data.find(
        (cat: any) => cat.hasChildren === false
      );
      console.log(`findLastCategory : ${JSON.stringify(findLastCategory)}`);
      if (findLastCategory)
        findLastCategoryShopee = [
          ...findLastCategoryShopee,
          findLastCategory.id,
        ];
    }
    let categoryAttributesList: ShopeeProductCategoryAttributeList[] = [];
    //get from redux shopee
    for (let index = 0; index < findLastCategoryShopee.length; index++) {
      const lastCategory = findLastCategoryShopee[index];
      const findCategory = reduxShopeeProductCategoryData.find((cat: any) => {
        if (cat.id === lastCategory) {
          console.log(`lastCategory : ${lastCategory}`);
          return cat;
        }
      });
      //get attribute from redux or get from firebase
      let categoryAttributes: ShopeeProductCategoryAttributeList[] = []
      //jika belum pernah di get, maka get from firebase
      if (!findCategory || !findCategory.attributesList || (findCategory && findCategory.isHasAttributes === "")) {
        categoryAttributes = await getAttributesByMarketplaceAndCategoryId("shopee", lastCategory);
        console.log(`getAttributes from fb : ${JSON.stringify(categoryAttributes)}`);
        console.log(`categoryAttributes length : ${categoryAttributes?.length}`)
        //update to redux shopee, set isHasAttributes and save attributesList
        const reduxMarketplaceProductCategory = [...reduxShopeeProductCategoryData];
        const newReduxMarketplaceProductCategory = reduxMarketplaceProductCategory.map((category)=>{
          if(category.categoryId?.toString() === lastCategory && (!categoryAttributes || (categoryAttributes && categoryAttributes.length < 1))){
            console.log(`set isHasAttributes false`)
            return {...category, isHasAttributes: "false"}
          }else if(category.categoryId?.toString() === lastCategory && (categoryAttributes && categoryAttributes.length > 0)){
            console.log(`set isHasAttributes true`)
            return {...category, isHasAttributes: "true", attributesList: categoryAttributes}
          }
          else{
            return category
          }
        })
        dispatch(ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(newReduxMarketplaceProductCategory));
      }
      if(findCategory && findCategory.isHasAttributes === "true" && findCategory.attributesList && findCategory.attributesList.length > 0){
        console.log(`get attributes from redux`)
        categoryAttributes = findCategory?.attributesList;
      }
      //jika tidak punya attributes, isHasAttributes === "false"
      if((findCategory && findCategory.isHasAttributes === "false") || !categoryAttributes || categoryAttributes.length < 1){
        console.log(`last category not have attribute`)
        dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
        dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
        return isHasAttribute;
      }
      console.log(`categoryAttributes : ${JSON.stringify(categoryAttributes)}`);
      //find in categoryAttributesList, if find add categoryId
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute: any = categoryAttributes[index];
        console.log(`attribute : ${JSON.stringify(attribute)}`);
        const findAttribute = categoryAttributesList.find(
          (attr: ShopeeProductCategoryAttributeList) =>
            attr.attributeId === attribute.attributeId
        );
        if (!findAttribute) {
          const newAttribute: ShopeeProductCategoryAttributeList = {
            ...attribute,
            categoryId: [lastCategory],
          };
          categoryAttributesList.push(newAttribute);
        } else {
          //add category
          let newCategoryId: any;
          if(findAttribute.categoryId) newCategoryId = [...findAttribute.categoryId, lastCategory];
          categoryAttributesList.map(
            (attr: ShopeeProductCategoryAttributeList) => {
              if (attr.attributeId === attribute.attributeId) {
                return { ...attr, categoryId: newCategoryId };
              }
            }
          );
        }
      }
    }
    if (categoryAttributesList.length < 1) {
      dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
      dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
      return isHasAttribute;
    }
    categoryAttributesList = categoryAttributesList.sort((a: any, b: any) => {
      if (a.isMandatory && !b.isMandatory) {
        return -1;
      } else if (!a.isMandatory && b.isMandatory) {
        return 1;
      } else {
        return 0;
      }
    });
    const attributes: categoryAttributes = { shopee: categoryAttributesList };
    console.log(`attributes : ${JSON.stringify(attributes)}`);
    let isFounded = categoryAttributesList.every((v) =>
      rd_categoryAttributes.shopee.includes(v)
    );
    console.log(
      `rd_categoryAttributes.shopee === categoryAttributesList : ${isFounded}`
    );
    if (!isFounded) {
      dispatch(ReduxProduct.setCategoryAttributes(attributes));
    }
    dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
    return true;
  };

  const handleNext = async () => {
    console.log("masuk ke handle next");
    setIsTouched(true);
    setLoading(true);

    if (rd_SelectedCompanies.length <= 0) {
      setIsValidCompanies(false);
    }
    console.log("masuk ke handle next 1");
    if (isAllCompanyNotActive(rd_SelectedCompanies)) {
      setIsValidCompanies(false);
      setLoading(false);
      return;
    } else {
      setIsValidCompanies(true);
    }
    console.log("masuk ke handle next 2");
    if (
      !isCompanyActiveAndHaveAtleastOneMarketplaceSelected(
        rd_SelectedCompanies,
        rd_marketplaces
      )
    ) {
      setTimeout(() => {
        console.log("masuk ke handle next 4 error");
        const errorMessage: any =
          document.getElementsByClassName("alert-marketplace");
        errorMessage[0]?.scrollIntoView({ behavior: "smooth" });
        setLoading(false);
      }, 200);
      return;
    }
    console.log("masuk ke handle next 3");
    if (rd_SelectedCompanies.length > 0 && rd_marketplaces.length > 0) {
      marketplaceValidation();
    }
    setTimeout(() => {
      const errorMessage: any =
        document.getElementsByClassName("alert-marketplace");
      errorMessage[0]?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    setLoading(false);
  };

  useEffect(() => {
    console.log(`isclick next : ${isTouched}`);
    if (isTouched) {
      if (rd_isFromSummary) {
        nav("/product/create-product/summary");
      } else if (!isSummary) {
        //check if not valid companies
        const isHasActiveMarketplace =
          isCompanyActiveAndHaveAtleastOneMarketplaceSelected(
            rd_SelectedCompanies,
            rd_marketplaces
          );
        console.log(`isHasActiveMarketplace : ${isHasActiveMarketplace}`);
        if (!isHasActiveMarketplace) {
          setLoading(false);
          return;
        }
        console.log(
          `rd_isGenerateCategoryAttributes before : ${rd_isGenerateCategoryAttributes}`
        );
        let hasAttributes = false;
        const generatedAttribute = async () => {
          if (!rd_isGenerateCategoryAttributes) {
            console.log(`start get category attributes`);
            hasAttributes = await getProductCategoryAttributes();
            console.log(`end get category attributes`);
            console.log(`hasAttributes : ${hasAttributes}`);
            if (hasAttributes) {
              console.log("masuk category attributes");
              nav("/product/create-product/category-attributes");
            } else {
              console.log("masuk variant category");
              nav("/product/create-product/variants-category");
            }
          } else {
            if (
              rd_isGenerateCategoryAttributes &&
              rd_categoryAttributes &&
              rd_categoryAttributes.shopee &&
              rd_categoryAttributes.shopee.length > 0
            ) {
              console.log("masuk category attributes");
              nav("/product/create-product/category-attributes");
            } else {
              console.log("masuk variant category");
              nav("/product/create-product/variants-category");
            }
          }
        };
        generatedAttribute();
      } else {
        return;
      }
      setLoading(false);
    }
  }, [isTouched]);

  useEffect(() => {
    if (isTouched) {
      setIsTouched(false);
    }
  }, [rd_SelectedCompanies, rd_marketplaces]);

  console.log(`isTouched: ${isTouched}`);

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleSavedChanges = async () => {
    setLoading(true);
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onSelectCompanyAndMarketplace;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        // updateDecrementNumberOfProductStorefront =
        await actions
          .decrementNumberOfProductStorefront(tempStorefrontId)
          .then(() => {
            console.log(
              "update decrementNumberOfProductStorefront for storefront id : ",
              tempStorefrontId
            );
          });
        // updateIncrementNumberOfProductStorefront =
        await actions
          .incrementNumberOfProductStorefront(storefrontId)
          .then(() => {
            console.log(
              "update incrementNumberOfProductStorefront for storefront id : ",
              storefrontId
            );
          });
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        // updateIncrementNumberOfProductStorefront
        await actions
          .incrementNumberOfProductStorefront(storefrontId)
          .then(() => {
            console.log(
              "update incrementNumberOfProductStorefront for storefront id : ",
              storefrontId
            );
          });
      }
    }

    /***
     * Media Page - Process Save Photos
     */
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      for (let index = 0; index < rd_ProductPhotos.length; index++) {
        const element = rd_ProductPhotos[index];
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === index ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
      // }
    }

    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }

    // save company data
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      // create companies
      let newCompanies: any = [];
      console.log("companyAndMarketplace companies : ", companies);
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const companyRef = createRef("company", company.company);
        newCompanies.push({ ...company, company: companyRef });
      }
      const dataCompanies = await actions.createDataCompany(
        productID,
        newCompanies
      );

      console.log(
        "companyAndMarketplace companies 2 : ",
        JSON.stringify(dataCompanies)
      );
      console.log(
        "companyAndMarketplace marketplaces : ",
        JSON.stringify(marketplaces)
      );

      // create marketplace collection
      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );
        const account: any =
          findAccount && findAccount[0] && findAccount[0].id
            ? findAccount[0].id
            : "";
        const accountRef = createRef("account", account);

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {
                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );

        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );
        if (marketplaceId && marketplaceId !== "") {
          resultMarketplaceId.push(marketplaceId);
        } else {
          isSuccess = false;
        }
      }
    }

    //if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];
        let elem = {
          ...element,
          name: element.name || "variant-photo",
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        let variantId: any;
        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          variantId = actions.createDataVariant(productID, dataVariant);
        }

        if (variantId && variantId !== "") {
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (attribute.inputValues && (attribute.inputValues[0] || attribute.inputValues[1])) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if(attribute.inputValues[0] || attribute.inputValues[1]){
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId ? attribute.attributeId.toString() : "",
            categoryAttributeModel
          );
        }
      }
    }
  };

  useEffect(() => {
    if (isTouched && isAllCompanyNotActive(rd_SelectedCompanies)) {
      setIsValidCompanies(false);
      const errorCompany: any =
        document.getElementsByClassName("alert-company");
      errorCompany[0]?.scrollIntoView();
    } else {
      setIsValidCompanies(true);
    }
  }, [rd_SelectedCompanies]); // eslint-disable-line

  const rd_MarketplaceCategoryList = useSelector(
    (state: RootState) => state.Product.marketplaceCategoryList
  );

  const [isLoading, setLoading] = useState(false);

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
  };

  useEffect(() => {
    dispatch(
      ReduxProduct.setLastStep(productStep.onSelectCompanyAndMarketplace)
    );

    if (!isSummary) {
      if (
        !reduxNewProduct ||
        !reduxNewProduct.brand ||
        !reduxNewProduct.name ||
        reduxNewProduct.name === "" ||
        !reduxNewProduct.storeFront ||
        !reduxNewProduct.description ||
        reduxNewProduct.description === ""
      ) {
        if (rd_productID !== "") {
          return;
        }
        nav("/product/create-product/basic-information");
      } else if (!rd_Shipments) {
        nav("/product/create-product/shipments");
      }
    }
    console.log("rd_isFromSummary : ", rd_isFromSummary);
    if (rd_isFromSummary) {
      setOldDataCompanies(rd_SelectedCompanies);
      setOldDataMarketplaces(rd_marketplaces);
    }
    console.log(
      "isi dari data old dari summary: ",
      oldDataCompanies,
      oldDataMarketplaces
    );
    if (rd_MarketplaceCategoryList.length <= 0) {
      getMarketplaceCategoryFromStorefront(reduxNewProduct?.storeFront);
    }
  }, []);

  useEffect(() => {
    console.log("onClickUpload company : ", onClickUpload);
    console.log("isSummary company : ", isSummary);
    if (onClickUpload && isSummary) handleNext();
  }, [onClickUpload, isSummary]);

  const handleSavedChangesFromSummary = () => {
    nav("/product/create-product/summary");
  };

  const handleUnsavedChangesFromSummary = () => {
    dispatch(ReduxProduct.setCompanies(oldDataCompanies));
    dispatch(ReduxProduct.setMarketplaces(oldDataMarketplaces));
    setModalFromSummary(false);
    nav("/product/create-product/summary");
  };

  const getMarketplaceCategoryFromStorefront = (storefront: any) => {
    console.log("storefront : ", reduxNewProduct?.storeFront?.id);
    const { getStoreFrontMarketplaceCategory: getMarketplaceCategory } =
      storefrontAct;
    getMarketplaceCategory(storefront?.id, (marketplaceCategory: any[]) => {
      console.log("Result Get List marketplaceCategory");
      if (marketplaceCategory && marketplaceCategory.length > 0) {
        console.log("marketplaceCategory : ", marketplaceCategory);
        let data: any = [];
        for (let index = 0; index < marketplaceCategory.length; index++) {
          const marketplaceCategoryData = marketplaceCategory[index];
          // data = [
          //   ...data,
          //   {
          //     marketplace: marketplaceCategoryData.type,
          //     categories: marketplaceCategoryData.categories,
          //   },
          // ];
          data.push({
            marketplace: marketplaceCategoryData.type,
            categories: marketplaceCategoryData.categories,
          });
        }
        console.log("data to push ", data);
        dispatch(ReduxProduct.setMarketplacesCategoryList(data));
      }
    });
  };

  return (
    <>
      {/* {!isSummary && <PageTitle breadcrumbs={UsersBreadcrumbs}>
        {t("AddNewProduct.Title.SelectCompany&Marketplace")}
      </PageTitle>} */}
      {(rd_isFromSummary || !isSummary) && (
        <PageTitle
          breadcrumbs={
            rd_isFromSummary ? UsersBreadcrumbsForSummary : UsersBreadcrumbs
          }
        >{`${t("AddNewProduct.Title.SelectCompany&Marketplace")}`}</PageTitle>
      )}

      {!isSummary && (
        <ModalConfirmationMoveToOtherPage
          isDirty={true}
          path="/product/create-product"
          onSave={() =>
            handleSavedChanges().then(() => {
              setLoading(false);
              dispatch(ReduxProductList.cleanReduxProductList(true));
              dispatch(ReduxProduct.cleanReduxProduct(true));
            })
          }
          onUnsave={() => handleUnsavedChanges()}
          isEmpty={false}
        />
      )}

      {rd_isFromSummary && (
        <ModalConfirmationWithStateHandler
          id="ModalCompanyAndMarketplaceFromSummary"
          isModalOpen={isModalFromSummary}
          title={t("Alert.Info")}
          body={t("Alert.UnsavedChanges")}
          saveText={t("Alert.Button.SaveChanges")}
          unSaveText={t("Alert.Button.LeaveWithoutSave")}
          cancelText={t("Alert.Button.Cancel")}
          setIsModalOpen={setModalFromSummary}
          onClose={() => setModalFromSummary(false)}
          onUnsave={() => handleUnsavedChangesFromSummary()}
          onSave={() => handleSavedChangesFromSummary()}
        />
      )}

      <div
        className={clsx("d-flex flex-column flex-lg-column", {
          "h-100": !isSummary,
        })}
      >
        {!isTabletOrMobile &&
          !isSummary &&
          ProductHeaderTitle("AddNewProduct.Title.SelectCompany&Marketplace")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: `${!isSummary ? "92.5%" : ""}` }}
        >
          <div
            className={clsx(`overflow-hidden card h-100
            ${isTabletOrMobile ? "" : isSummary ? "" : "p-7"}`)}
          >
            {isTabletOrMobile && !isSummary && <ProductMobileIcon />}
            <div
              style={{
                height: `${
                  !isSummary ? (isTabletOrMobile ? "100%" : "90%") : ""
                }`,
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile && !isSummary ? "p-5" : "p-5"
                }`
              )}
            >
              {isSummary && (
                <div className="step-sub-title mt-3 fs-4 mb-3">
                  {t("AddNewProduct.Info.Summary.AddCompany&Marketplace")}
                  <span className="text-danger">*</span>
                </div>
              )}
              <div
                ref={titleRef}
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-auto "
                )}
              >
                {!isSummary && isTabletOrMobile && (
                  <div className="d-flex flex-column mt-5">
                    {ProductStep(
                      5,
                      "AddNewProduct.Title.SelectCompany&Marketplace"
                    )}
                  </div>
                )}
                {companyList &&
                  companyList?.map((company) => {
                    return (
                      <CompanyAndMarketplaceSelection
                        company={company}
                        onClickNext={isTouched}
                        isSummary={isSummary}
                        key={`company-${company.id}`}
                      />
                    );
                  })}

                {/* Marketplace Error */}
                <div
                  className="mt-2 mb-2"
                  style={{ height: isTabletOrMobile ? "auto" : "18px" }}
                  data-testid="alert-company"
                >
                  {!isValidCompanies && (
                    <span className="text-danger">
                      {t("AddNewProduct.Alert.SelectCompany")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx("d-flex bg-white mt-auto", {
                  "mb-5": isTabletOrMobile,
                  "justify-content-end": rd_isFromSummary,
                  "justify-content-between": !rd_isFromSummary,
                })}
              >
                {!rd_isFromSummary && (
                  <Link to="/product/create-product/shipments">
                    <Button
                      btnbs="primary"
                      cName={isTabletOrMobile ? "mx-5" : ""}
                      data-testid="btnBack"
                    >
                      {`${t("Storefront.Button.Back")}`}
                    </Button>
                  </Link>
                )}

                <div className="d-flex">
                  {!rd_isFromSummary && (
                    <Link to="/product/list">
                      <Button
                        data-testid="btnCancel"
                        btnbs="primary"
                        type="button"
                      >
                        {t("AddNewProduct.Button.BPI.Cancel")}
                      </Button>
                    </Link>
                  )}
                  {rd_isFromSummary && (
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                      onClick={() => setModalFromSummary(true)}
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  )}
                  <Button
                    id="btnNext"
                    data-testid="btnNext-onSelectCompanyAndMarketplace"
                    type="button"
                    btnbs="primary"
                    cName="mx-5"
                    onClick={() => handleNext()}
                  >
                    {/* {`${t("AddNewProduct.Button.BPI.Next")}`} */}
                    {!rd_isFromSummary
                      ? `${t("AddNewProduct.Button.BPI.Next")}`
                      : `${t("Storefront.Button.Confirm")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
export default SelectCompanyAndMarketplace;
