import WABAAccount from "../../../models/WABAccount";

interface InitialState {
  listWABAAccount: WABAAccount[];
  countWABAAccount: number;
  currentPageWABAAccount: number;
  maxPageWABAAccount: number;
  arrayWABAAccountPagination: string[];
  WABAAccountData: WABAAccount;
  navCount: number;
}
const WABAAccountListAction: string = "WABAAccountListAction";

export type unsubFN = () => void;

export default InitialState;
export { WABAAccountListAction };