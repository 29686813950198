import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../setup/theme/useTheme";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../styles/components/TextInput";
import Button from "../../../../styles/components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as lc from "../../../../app/modules/localstorage";
import * as api from "../../../../api";
import axios from "axios";
import phoneCodes from "../../../../util/phoneCode.json";
import Select from "react-select";
import * as apiRegistration from "../../../../api/server/registration";
import * as RegisterRedux from "../redux/RegisterSlice";
import db from "../../../../db";
import { toast } from "react-toastify";
import { FormControl, InputGroup } from "react-bootstrap";

const registerSchema = Yup.object().shape({
  fullname: Yup.string().required("Login.Error.EmptyFullname"),
  email: Yup.string()
    .required("Register.Error.EmptyEmail")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Register.Error.FormatEmail"
    ),
  phonenumber: Yup.string()
    // .required("Register.Error.EmptyPhone")
    .matches(/^\d{7,15}$/, "Register.Error.FormatPhone"),
  password: Yup.string()
    .min(8, "PasswordEntry.Error.Under8Char")
    .required("PasswordEntry.Error.NewPasswordEmpty")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "PasswordEntry.Error.ExcludeNumber"
    ),
  confirmpassword: Yup.string().when("password", {
    is: (password: any) => password && password.length > 0,
    then: Yup.string()
      .required("Register.Error.EmptyConfirmpassword")
      .oneOf([Yup.ref("password"), null], "Register.Error.Confirmpassword"),
    otherwise: Yup.string().required("Register.Error.EmptyConfirmpassword"),
  }),
});

const initialValues = {
  fullname: "",
  email: "",
  phonenumber: "",
  password: "",
  confirmpassword: "",
  reffcode: "",
};

export const Registration = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validReffCode, setValidReffCode] = useState(false);
  const [reffCode, setReffCode] = useState("");
  const [errorReffCode, setErrorReffCode] = useState("");
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [phoneCodesData, setPhoneCodesData] = useState([]);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<any>({
    value: "+62",
    emoji: "🇮🇩",
    label: `🇮🇩 +62 (Indonesia)`,
  });

  useEffect(() => {
    setPhoneCodesData(phoneCodes as any);
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleconfirmPassword = () => {
    setConfirmPasswordShown(!confirmpasswordShown);
  };
  const currentTheme = useTheme();

  const customStyles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedPhoneCode?.label
            ? "white"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedPhoneCode?.label
            ? "#0275d8" // Background color on hover or when option is selected
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
        width: "300px !important",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
      width: "300px !important",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
      fontSize: "15px",
      width: "95px !important",
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      border: "none",
      outline: "none",
      height: "41px",
      width: "120px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let phoneCode = selectedPhoneCode?.value?.replace("+", "");
      let phonumber =
        values.phonenumber.charAt(0) === "0"
          ? values.phonenumber.slice(1)
          : values.phonenumber.startsWith(phoneCode)
          ? values.phonenumber.slice(phoneCode?.length)
          : values.phonenumber;
          console.log(phonumber, 'test pho')
      setTimeout(async () => {
        await apiRegistration.userValidation(
          Number(phoneCode + phonumber),
          values.email,
          values.reffcode,
          values.fullname,
          values.password,
          selectedPhoneCode,
          (responseCode: number, response: any) => {
            console.log(response);
            if (!responseCode || response === undefined) {
              setLoading(false);
              return;
            }
            if (!response.success) {
              // if (response.message) {
              //   alert(response.message);
              // }
              if (
                response?.errorField.find((field: string) => field === "email")
              ) {
                setValidEmail(false);
                setLoading(false);
              }
              if (
                response?.errorField.find(
                  (field: string) => field === "phonenumber"
                )
              ) {
                setValidPhone(false);
                setLoading(false);
              }
              return;
            }
            setValidEmail(true);
            setValidPhone(true);
            setLoading(false);
            setLoading(false);
            dispatch(RegisterRedux.setUserFullname(values.fullname));
            dispatch(RegisterRedux.setUserEmail(values.email));
            dispatch(
              RegisterRedux.setUserPhoneNumber(Number(phoneCode + phonumber))
            );
            dispatch(RegisterRedux.setUserPassword(values.password));
            dispatch(RegisterRedux.setUserRole("admin"));
            dispatch(RegisterRedux.setCountryCode(selectedPhoneCode));
            if (validReffCode && reffCode !== "") {
              nav("/auth");
              toast("Registration Success", {
                type: "success",
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              console.log(`To Verif email`);
              dispatch(RegisterRedux.setUserID(response?.uid));
              dispatch(
                RegisterRedux.setVerifyExpired(response?.verifyExpiredAt)
              );
              nav("/auth/email-verification");
            }
          }
        );
      }, 1000);
    },
  });

  useEffect(() => {
    const queryReffcode = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      let refferalCode = urlParams.get("referralCode");
      if (refferalCode) {
        formik.setFieldValue("reffcode", refferalCode);
        formik.setFieldTouched("reffcode");
        await handleValidateReffcode(refferalCode);
      }
    };
    queryReffcode();
  }, []);

  const formatOptions = () => {
    if (!phoneCodesData) return [];
    return phoneCodesData.map((code: any) => ({
      value: code?.value,
      emoji: code?.emoji,
      label: `${code?.emoji} ${code?.value} ${
        code?.value === selectedPhoneCode.value ? "" : `(${code?.label})`
      }`, // Combine label and emoji
    }));
  };

  const handleValidateReffcode = async (val: any) => {
    setReffCode(val);
    setErrorReffCode("");
    await apiRegistration.validationRefferal(
      val,
      (responseCode: number, response: any) => {
        console.log(responseCode, "ini respCode verf reff");
        console.log(response, "ini resp verf reff");
        if (response && responseCode === 200) {
          formik.setFieldValue("email", response?.email);
          setDisabledEmail(true);
          setValidReffCode(true);
        } else {
          formik.setFieldValue("email", "");
          setDisabledEmail(false);
          setValidReffCode(false);
          if (typeof response?.error === "string") {
            setErrorReffCode(response?.error);
          } else if (response) {
            setErrorReffCode("Error System Referral Code");
          } else {
            setErrorReffCode("");
          }
        }
      }
    );
  };

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      id="kt_register_form"
      data-testid="form-register"
    >
      {/* begin::Heading */}
      <div className="text-center mt-2 mb-3">
        <h3 className="text-dark mb-2" data-testid="form-register-title">
          {t("Register.Createanaccount")}
        </h3>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-3" data-testid="form-register-input-fullname">
        <label className="form-label fs-6 fw-bolder text-dark required">
          {t("Register.Fullname")}
        </label>
        <TextInput
          data-testid="fullname"
          placeholder=""
          {...formik.getFieldProps("fullname")}
          formcontrol={"solid"}
          name="fullname"
          autoComplete="off"
          id="register-fullname"
          maxLength={100}
        />
        {formik.touched.fullname && formik.errors.fullname ? (
          <div
            data-testid="fullnameerror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">{t(`${formik.errors.fullname}`)}</span>
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div
        className="fv-row mb-3 position-relative"
        data-testid="form-register-input-email"
      >
        <label className="form-label fs-6 fw-bolder text-dark required">
          {t("ForgotPassword.Input.Email")}
        </label>
        <TextInput
          data-testid="email"
          placeholder=""
          {...formik.getFieldProps("email")}
          autoComplete="off"
          id="login-email"
          type="email"
          maxLength={100}
          onInput={(e: any) => {
            e.preventDefault();
            setValidEmail(true);
          }}
          name="email"
          formcontrol={disabledEmail === true ? "transparent" : "solid"}
          disabled={disabledEmail}
        />
        {validReffCode === true && (
          <i
            className="bi bi-patch-check-fill text-success"
            style={{
              position: "absolute",
              right: 15,
              top: "50%",
            }}
          ></i>
        )}
        {validEmail === false && (
          <div
            data-testid="emailerror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">
              {t(`Register.Error.Alert.Email.AlreadyRegistered`)}
            </span>
          </div>
        )}
        {formik.touched.email && formik.errors.email ? (
          <div
            data-testid="emailerror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">{t(`${formik.errors.email}`)}</span>
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div
        className="fv-row mb-3"
        data-testid="form-register-input-phonenumber"
      >
        <label className="form-label fs-6 fw-bolder text-dark">
          {t("Register.PhoneNumber")}
        </label>
        {/* <div className="d-flex flex-row">
          <Select
            value={selectedPhoneCode}
            styles={customStyles}
            options={formatOptions()}
            getOptionLabel={(option) =>
              option.value === selectedPhoneCode.value
                ? `${option?.emoji} ${option?.value}`
                : option.label
            }
            className="basic-select"
            classNamePrefix="select"
            onChange={(selectedOption: any) => {
              setSelectedPhoneCode(selectedOption);
            }}
          />
            <TextInput
              data-testid="phonenumber"
              placeholder=""
              {...formik.getFieldProps("phonenumber")}
              formcontrol={"solid"}
              name="phonenumber"
              autoComplete="off"
              id="register-phonenumber"
              style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
              onInput={(e: any) => {
                e.preventDefault();
                setValidPhone(true);
              }}
              onKeyPress={(e: any) => {
                const keyCode = e.keyCode || e.which;
                const keyValue = String.fromCharCode(keyCode);
                const isDigit = /^\d+$/.test(keyValue);
                if (!isDigit) {
                  e.preventDefault();
                }
              }}
              maxLength={15}
              type="tel"
            />
        </div> */}
        <InputGroup style={{ width: "100%" }}>
          <Select
            value={selectedPhoneCode}
            styles={customStyles}
            options={formatOptions()}
            getOptionLabel={(option) =>
              option.value === selectedPhoneCode.value
                ? `${option?.emoji} ${option?.value}`
                : option.label
            }
            onChange={(selectedOption: any) => {
              setSelectedPhoneCode(selectedOption);
            }}
            className="basic-select"
            classNamePrefix="select"
            aria-label="Phone Code"
          />
          <TextInput
            data-testid="phonenumber"
            placeholder=""
            {...formik.getFieldProps("phonenumber")}
            formcontrol={"solid"}
            name="phonenumber"
            autoComplete="off"
            id="register-phonenumber"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            onInput={(e: any) => {
              e.preventDefault();
              setValidPhone(true);
            }}
            onKeyPress={(e: any) => {
              const keyCode = e.keyCode || e.which;
              const keyValue = String.fromCharCode(keyCode);
              const isDigit = /^\d+$/.test(keyValue);
              if (!isDigit) {
                e.preventDefault();
              }
            }}
            maxLength={15}
            type="tel"
          />
        </InputGroup>
        {validPhone === false && (
          <div
            data-testid="phonenumbererror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">
              {t(`Register.Error.Alert.PhoneNumber.AlreadyRegistered`)}
            </span>
          </div>
        )}
        {formik.touched.phonenumber && formik.errors.phonenumber ? (
          <div
            data-testid="phonenumbererror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">{t(`${formik.errors.phonenumber}`)}</span>
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3" data-testid="form-register-input-password">
        <label className="form-label fs-6 fw-bolder text-dark required">
          {t("Login.Input.Password")}
        </label>
        <div className="input-group">
          <TextInput
            data-testid="password"
            type={passwordShown ? "text" : "password"}
            autoComplete="off"
            {...formik.getFieldProps("password")}
            formcontrol={"solid"}
            id="login-password"
            maxLength={30}
          />
          <i
            onClick={togglePassword}
            className={`input-group-text border-0 bi ${
              passwordShown ? "bi-eye" : "bi-eye-slash"
            }`}
          ></i>
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div
            data-testid="passworderror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <div>
              <span role="alert">{t(`${formik.errors.password}`)}</span>
            </div>
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div
        className="fv-row mb-3"
        data-testid="form-register-input-confirmpassword"
      >
        <label className="form-label fs-6 fw-bolder text-dark required">
          {t("Register.ConfirmPassword")}
        </label>
        <div className="input-group">
          <TextInput
            data-testid="confirmpassword"
            type={confirmpasswordShown ? "text" : "password"}
            autoComplete="off"
            {...formik.getFieldProps("confirmpassword")}
            formcontrol={"solid"}
            id="login-confirmpassword"
            maxLength={30}
            onPaste={(e: Event) => {
              e.preventDefault();
              return false;
            }}
          />
          <i
            onClick={toggleconfirmPassword}
            className={`input-group-text border-0 bi ${
              confirmpasswordShown ? "bi-eye" : "bi-eye-slash"
            }`}
          ></i>
        </div>
        {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
          <div
            data-testid="confirmpassworderror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <div>
              <span role="alert">{t(`${formik.errors.confirmpassword}`)}</span>
            </div>
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group  */}
      <div
        className="fv-row mb-3 position-relative"
        data-testid="form-register-input-reffcode"
      >
        <label className="form-label fs-6 fw-bolder text-dark">
          {/* {t("Register.Fullname")} */}
          Referral Code
        </label>
        <TextInput
          data-testid="reffcode"
          placeholder=""
          {...formik.getFieldProps("reffcode")}
          name="reffcode"
          autoComplete="off"
          id="register-reffcode"
          maxLength={100}
          onInput={(e: any) => {
            handleValidateReffcode(e.target.value);
          }}
          formcontrol={disabledEmail === true ? "transparent" : "solid"}
          disabled={disabledEmail}
        />
        {validReffCode === true && (
          <i
            className="bi bi-patch-check-fill text-success"
            style={{
              position: "absolute",
              right: 15,
              top: "50%",
            }}
          ></i>
        )}
        {validReffCode === false && reffCode !== "" && (
          <div
            data-testid="reffcodeerror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">{errorReffCode}</span>
          </div>
        )}
        {formik.touched.reffcode && formik.errors.reffcode ? (
          <div
            data-testid="reffcodeerror"
            className="fv-plugins-message-container text-danger h-10px"
          >
            <span role="alert">test</span>
            {/* <span role="alert">{t(`${formik.errors.reffcode}`)}</span> */}
          </div>
        ) : (
          <div className="h-10px"></div>
        )}
      </div>
      {/* end::Form group  */}

      {/* begin::Action */}
      <div className="text-center">
        <Button
          data-testid="button-register"
          id="register-button"
          btnlg="primary"
          type="submit"
          disabled={errorReffCode !== "" && reffCode !== "" ? true : false}
          cName="w-100 mb-5"
        >
          {!loading && (
            <span className="indicator-label">
              {reffCode !== "" && validReffCode
                ? t("Common.Button.Submit")
                : t("Contacts.Pages.Next")}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {t("Login.Button.Loading")}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </div>
      {/* end::Action */}
    </form>
  );
};