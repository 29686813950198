import InitialState, { UpdateRegisterAction } from "./RegisterRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
  userfullname: "",
  email: "",
  password: "",
  phoneNumber: 0,
  clientName: "",
  clientLogoURL: "",
  clientCompanyName: "",
  clientWebsite: "",
  clientBillingType: "",
  clientProvince: "",
  clientCity: "",
  clientDistrict: "",
  clientStreet: "",
  clientZipcode: "",
  userrole: "",
  countryCode: "",
  uid: "",
  verifyExpiredAt: "",
};

export const RegisterSlice = createSlice({
  name: UpdateRegisterAction,
  initialState: initialState,
  reducers: {
    setUserFullname: (state, action: PayloadAction<string>) => {
      state.userfullname = action.payload;
      console.log(action.payload);
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      console.log(action.payload);
    },
    setUserPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      console.log(action.payload);
    },
    setUserPhoneNumber: (state, action: PayloadAction<number>) => {
      state.phoneNumber = action.payload;
      console.log(action.payload);
    },
    setClientName: (state, action: PayloadAction<string>) => {
      state.clientName = action.payload;
      console.log(action.payload);
    },
    setClientLogo: (state, action: PayloadAction<string>) => {
      state.clientLogoURL = action.payload;
      console.log(action.payload);
    },
    setClientCompanyName: (state, action: PayloadAction<string>) => {
      state.clientCompanyName = action.payload;
      console.log(action.payload);
    },
    setClientWebsite: (state, action: PayloadAction<string>) => {
      state.clientWebsite = action.payload;
      console.log(action.payload);
    },
    setClientBillingType: (state, action: PayloadAction<string>) => {
      state.clientBillingType = action.payload;
      console.log(action.payload);
    },
    setClientProvince: (state, action: PayloadAction<string>) => {
      state.clientProvince = action.payload;
      console.log(action.payload);
    },
    setClientCity: (state, action: PayloadAction<string>) => {
      state.clientCity = action.payload;
      console.log(action.payload);
    },
    setCLientDistrict: (state, action: PayloadAction<string>) => {
      state.clientDistrict = action.payload;
      console.log(action.payload);
    },
    setClientStreet: (state, action: PayloadAction<string>) => {
      state.clientStreet = action.payload;
      console.log(action.payload);
    },
    setClientZipcode: (state, action: PayloadAction<string>) => {
      state.clientZipcode = action.payload;
      console.log(action.payload);
    },
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userrole = action.payload;
      console.log(action.payload);
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
      console.log(action.payload);
    },
    setUserID: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
      console.log(action.payload);
    },
    setVerifyExpired: (state, action: PayloadAction<string>) => {
      state.verifyExpiredAt = action.payload;
      console.log(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserFullname,
  setCLientDistrict,
  setClientBillingType,
  setClientCity,
  setClientCompanyName,
  setClientLogo,
  setClientName,
  setClientProvince,
  setClientStreet,
  setClientWebsite,
  setClientZipcode,
  setUserEmail,
  setUserPassword,
  setUserPhoneNumber,
  setUserRole,
  setCountryCode,
  setUserID,
  setVerifyExpired
} = RegisterSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default RegisterSlice.reducer;
