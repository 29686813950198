import { useContext } from "react";
import { themes, Theme } from "./themes"; // Import your theme configurations
import { GlobalContext } from "../../context/globalContext";

export function useTheme(): Theme {
  const { theme } = useContext(GlobalContext);

  // Get the current theme's data
  const currentTheme = themes[theme] || themes.default; // Default if not found

  return currentTheme;
}
