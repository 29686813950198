import { useTranslation } from "react-i18next";
import { TableProvider } from "../context/TableContext";
import { useMediaQuery } from "react-responsive";
import Account from "../../../../../../models/Account";
import { KTCard } from "../../../../../../../resources/helpers";
import MarketplaceFormEdit from "./marketplaceEdit/MarketplaceFormEdit";
import MarketplaceShopEditFooter from "./marketplaceEdit/MarketplaceShopEditFooter";

const MarketplaceShopEdit = () => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <TableProvider<Account> collection="account">
      <div className={"flex"} style={{ height: "100%", overflowY: "auto" }}>

      <KTCard>
        {/* {isMobileView ? <MarketplaceCardEdit /> :  */}
        <MarketplaceFormEdit />
        {/* } */}
        <MarketplaceShopEditFooter />
      </KTCard>
      </div>
    </TableProvider>
  );
};

export default MarketplaceShopEdit;
