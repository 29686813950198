import axios from "axios";
import * as bcrypt from "bcryptjs";

export const userValidation = async (
  phonenumber: number,
  email: string,
  referralCode: string,
  fullname: string,
  password: string,
  countryCode: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/validateUser";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios
      .post(
        url,
        {
          phonenumber,
          email,
          referralCode,
          fullname,
          password,
          countryCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const responseJson = response.data;
        const responseCode = response.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error?.response?.status, error?.response?.data);
      });
  } catch (error) {
    console.log(error);
  }
};

export const validationRefferal = async (
  refferalCode: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/validateReferral";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios
      .post(
        url,
        {
          refferalCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const responseJson = response.data;
        const responseCode = response.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error?.response?.status, error?.response?.data);
      });
  } catch (error) {
    console.log(error);
  }
};

export const clientInfoValidation = async (
  clientName: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/validateClientInfo";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios
      .post(
        url,
        {
          clientName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const responseJson = response.data;
        const responseCode = response.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error.response.status, error.response.data);
      });
  } catch (error) {
    console.log(error);
  }
};

export const registration = async (
  clientName: string,
  companyName: string,
  website: string,
  billingType: string,
  province: string,
  city: string,
  district: string,
  street: string,
  zipcode: string,
  logoURL: string,
  uid: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/register";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  const jsonReq = {
    clientName,
    companyName,
    website,
    billingType,
    province,
    city,
    district,
    street,
    zipcode,
    logoURL,
    uid,
  };
  console.log(jsonReq);
  try {
    await axios
      .post(url, jsonReq, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, "testing res");
        const responseJson = response?.data;
        const responseCode = response?.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error?.response?.status, error?.response?.data);
      });
  } catch (error) {
    console.log(error);
  }
};

export const resendEmail = async (
  email: string,
  uid: string,
  fullname: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/resendVerifyEmail";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios
      .post(
        url,
        {
          email,
          uid,
          fullname
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const responseJson = response.data;
        const responseCode = response.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error.response.status, error.response.data);
      });
  } catch (error) {
    console.log(error);
  }
};
