import format from "date-fns/format";
import parse from "date-fns/parse";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../resources/helpers";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../../setup/redux/store";
import { Campaign } from "../../../../../models/Campaign";
import * as CampaignReportRedux from "../../../../../modules/campaign/report/CampaignReportSlice";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { dateToString } from "./columns/CampaignCreatedAtCell";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { campaignsColumns } from "./columns/_columns";
import { repeatColumns } from "./columns/_repeatColumns";

const CampaignsReportDesktop = () => {
  const { t } = useTranslation();
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  const listData = useSelector(
    (state: RootState) => state.CampaignReport.listCampaignReport
  );
  let campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => campaigns, [campaigns]);
  const [data, setData] = useState(campaigns);
  const columns = useMemo(() => repeatColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data: listData,
    });
  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   if (!campaignData || !campaigns) {
  //     return;
  //   }
  //   console.log(`on change data cause ReduxCampaignReport`);
  //   const newCampaign = campaigns.find((c) => c.id === campaignData.id);
  //   console.log(
  //     `campaigns : ${JSON.stringify(newCampaign?.campaignMessagesCount)}`
  //   );

  //   if (campaignData?.campaignMessagesCount?.repetitions) {
  //     const repeatCount = campaignData.campaignMessagesCount.repetitions;
  //     const listReport = Object.keys(repeatCount).map((date) => {
  //       return {
  //         ...campaignData,
  //         campaignRepeatCount: repeatCount[date],
  //         dateSent: date,
  //       };
  //     });

  //     setData(listReport);
  //     setRefresh(true);
  //   } else {
  //     setData([campaignData]);
  //     setRefresh(true);
  //   }
  // }, [
  //   campaignData.campaignMessagesCount,
  //   campaignData.campaignMessagesCount?.repetitions,
  //   campaigns,
  // ]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);

  console.log("DelRep row", rows);
  console.log("DelRep campaignData", campaignData);

  return (
    <>
      {isLoading && <Loading />}
      {refresh && <Loading />}
      {!refresh && (
        <KTCardBody className="py-0 h-auto overflow-hidden">
          <div
            className="table-responsive"
            style={{
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              {...getTableProps()}
            >
              <thead className="position-sticky top-0 bg-white">
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
                  {headers.map((column: ColumnInstance<Campaign>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<Campaign>, i) => {
                    prepareRow(row);
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("Contacts.Info.Empty")}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <CampaignPagination /> */}
          {/* {isLoading && <Loading />} */}
        </KTCardBody>
      )}
    </>
  );
};

type ColumnProps = {
  columnName?: string;
  dataColumn?: any;
};

const ColumnReport: FC<ColumnProps> = ({ columnName, dataColumn }) => {
  return (
    <div className="d-flex flex-row">
      <div style={{ flexGrow: 1 }} className="text-gray-700">
        {columnName}
      </div>
      <div className="text-gray-700 fw-bolder">{dataColumn}</div>
    </div>
  );
};

const CampaignsReportMobile = () => {
  const { t } = useTranslation();
  const campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => campaigns, [campaigns]);
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  const listData = useSelector(
    (state: RootState) => state.CampaignReport.listCampaignReport
  );
  const [data, setData] = useState(listData);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!campaignData || !campaigns) {
  //     return;
  //   }
  //   console.log(`on change data cause ReduxCampaignReport`);
  //   const newCampaign = campaigns.find((c) => c.id === campaignData.id);
  //   console.log(
  //     `campaigns : ${JSON.stringify(newCampaign?.campaignMessagesCount)}`
  //   );

  //   if (campaignData?.campaignMessagesCount?.repetitions) {
  //     const repeatCount = campaignData.campaignMessagesCount.repetitions;
  //     const listReport = Object.keys(repeatCount).map((date) => {
  //       return {
  //         ...campaignData,
  //         campaignRepeatCount: repeatCount[date],
  //         dateSent: date,
  //       };
  //     });

  //     setData(listReport);
  //     setRefresh(true);
  //   } else {
  //     setData([]);
  //     setRefresh(true);
  //   }
  // }, [campaignData.campaignMessagesCount, campaigns]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);

  return (
    <>
      {isLoading && <Loading />}
      {refresh && <Loading />}
      {!refresh &&
        data.map((campaign: any) => {
          return (
            <div
              onClick={() => {
                if (campaign.dateSent) {
                  dispatch(
                    CampaignReportRedux.setSelectedRepTime(campaign.dateSent)
                  );
                }
              }}
              className="bg-primary bg-opacity-15 mb-3 mx-5 py-3 px-5 rounded"
            >
              <div className="pb-1 mb-2 border-bottom">
                <ColumnReport
                  columnName={t("DLR.Column.SubmittedAt")}
                  dataColumn={
                    campaign.dateSent
                      ? format(
                          parse(campaign.dateSent, "yyyy-MM-dd", new Date()),
                          "d MMM yyyy"
                        )
                      : ""
                  }
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
                className="col-gap-9"
              >
                <ColumnReport
                  columnName={t("DLR.Column.Published")}
                  dataColumn={campaign.campaignMessagesCount?.countCreated || 0}
                />
                <ColumnReport
                  columnName={t("DLR.Column.Sent")}
                  dataColumn={campaign.campaignRepeatCount?.countSent || 0}
                />
                <ColumnReport
                  columnName={t("DLR.Column.Delivered")}
                  dataColumn={campaign.campaignRepeatCount?.countDelivered || 0}
                />
                <ColumnReport
                  columnName={t("DLR.Column.Read")}
                  dataColumn={campaign.campaignRepeatCount?.countRead || 0}
                />
                <ColumnReport
                  columnName={t("DLR.Column.Failed")}
                  dataColumn={campaign.campaignRepeatCount?.countFailed || 0}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

const CampaignsRepetitionInfoTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? (
    <CampaignsReportMobile />
  ) : (
    <CampaignsReportDesktop />
  );
};

export { CampaignsRepetitionInfoTable };
