import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import AuthInit from './modules/auth/redux/AuthInit'
import { 
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
} from '../resources/assets/ts/components'
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import NotificationPermissionPrompt from './layout/notifications/permission-prompt';

const App = () => {
  const { t } = useTranslation();
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium/i)){
      browserName = "chrome";
    }else if(userAgent.match(/firefox/i)){
      browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else if(userAgent.match(/edg/i)){
      browserName = "edge";
    } else if(userAgent.match(/crios/i)){
      browserName = "chromeios";
    } else if(userAgent.match(/fxios/i)){
      browserName = "firefoxios";
    }else{
      browserName="No browser detection";
    }
  
   console.log("You are in browser: ", browserName);  
  return (
    <>
    {/* // <Router location={''} navigator={}> */}
      {browserName !== "safari" && browserName !== "chromeios" && browserName !== "firefoxios" && <NotificationPermissionPrompt />}
      {/* {<NotificationPermissionPrompt />} */}
      <Suspense fallback={<div>{t("Login.Button.Loading")}</div>}>
        <AuthInit >
          <Outlet />
          <MasterInit />
        </AuthInit>
      </Suspense>
    {/* // </Router> */}
    </>
  )
}

const MasterInit = () => {
  const isFirstRun = useRef(true)
  const pluginsInitialization = () => {
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
    }, 500)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
  }, [])
  return <></>
}

export {App, MasterInit}
