import axios from "axios";
import * as bcrypt from "bcryptjs";

export const getEmailFromPhone = async (phoneNumber: string) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/findByPhoneNumber";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    //using axios
    const response = await axios.post(
      url,
      { phoneNumber: phoneNumber },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${token}`,
        },
      }
    );
    //convert response to json
    const responseJson = await response.data;
    //return email
    return responseJson.email;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const requestSendEmailResetPassword = async (userName: string, userEmail: string, tokenReset: string) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/email/resetPassword";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    //using axios
    const response = await axios.post(
      url,
      { 
        name : userName,
        emailTo: userEmail,
        token: tokenReset
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            `Bearer ${token}`,
        },
      }
    );
    //convert response to json
    const responseJson = await response.data;
    const responseStatus = await response.status;
    return responseStatus;
  } catch (error) {
    console.log(error);
    return 500;
  }
};


export const resetPassword = async (userID: string, newPassword: string, callback: any) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/resetPassword";

  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios.post(url, 
      { 
        userID : userID,
        password: newPassword
      }, 
      {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          `Bearer ${token}`,
      },
    }).then((response) => {
      console.log(response)
      const responseJson = response.data;
      const responseCode = response.status;
      callback(responseCode, responseJson);
    }
    ).catch((error) => {
      callback(error.response.status, error.response.data);
    }
    );
  } catch (error) {
    console.log(error);
  }
};
