import clsx from "clsx";
import "../css/borderdashed.css"

interface ButtonProps {
  /**
   * base color of the button with hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbs?: 'white' | 'primary' | 'light' | 'secondary' | 'success' | 'info' | 'warning' |  'danger' | 'dark';
  /**
   * light style color of the button with hover, defined with $theme-light-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnlg?: 'primary' | 'success' | 'info' | 'warning' |  'danger' | 'dark' | 'light-primary' | 'light-success' | 'light-info' | 'light-warning' | 'light-danger' | 'light-dark';
  /**
   * background style color of the button without hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbg?: 'white' | 'primary' | 'light' | 'secondary' | 'success' | 'info' | 'warning' |  'danger' | 'dark';
  /**
   * className to be added to the button
   */
  cName?: string;
  /**
   * className to be added to the button
   */
  biIcon?: string;
  /**
   * props for the button
   */
  isMobile?: boolean;
  onChangeFn?: any;
  isHidden?: boolean;
  isVideoButton?: boolean;
  isMultipleUpload?: boolean;
  idRadioButton?:string;
  checked?: boolean;
  isInvalidUpload?: boolean;
  refId?: string;
  accept?: string;
  /**
   * props for the button
   */
  [propName: string]: any;
  
}

/**
 * Primary UI component for user interaction
 */
function ButtonUploadMedia({ 
  btnbs,
  btnlg,
  btnbg,
  cName,
  biIcon,
  isMobile,
  onChangeFn,
  isHidden,
  isVideoButton,
  isMultipleUpload,
  idRadioButton,
  checked,
  isInvalidUpload,
  refId,
  accept,
  ...props
}: ButtonProps) {
  return (
    <div>
      <label
        className={clsx(isMobile ? "button-dashed-mobile" : "button-dashed-desktop", isHidden ? "d-none" : "", isVideoButton && !isMobile ? "ms-7" : "")}
        style={{ borderColor: isInvalidUpload ? "red" : "black" }}
        data-testid="button-upload"
      >
        <i className={clsx(cName)} style={{ position: "absolute",top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black"}}></i>
        <input
          accept={accept}
          ref={refId}
          id="chat-media"
          onChange={onChangeFn}
          type="file"
          name="attachment"
          multiple={isVideoButton ? false : true}
          style={{ display: "none" }}
        />
        {isMultipleUpload && <input type="radio" checked={checked} className="radio-inbutton" name="radio" id={idRadioButton} data-testid="radio-button-upload" />}
        {props.children}
      </label>
    </div>
  );
}

export default ButtonUploadMedia;
