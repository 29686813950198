import { FC, useEffect, useState } from "react";
import { CIQsColumnType } from "../../ciq-list/table/columns/_columns";
import { toAbsoluteUrl } from "src/resources/helpers";
import {
  DocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import db from "src/db";

type Props = {
  CIQ: CIQsColumnType;
};

const CIQInfoCell: FC<Props> = ({ CIQ }) => {
    const [customerData, setCustomerData] = useState<any>(null);
  
    useEffect(() => {
      let isMounted = true;
  
      const getData = async () => {
        if (CIQ?.parent_document?.customer !== null) {
          try {
            const dataCustomer = await db
              .collection('customers')
              .doc(CIQ?.parent_document?.id)
              .get();
            if (isMounted) {
              setCustomerData(dataCustomer.data());
            }
          } catch (error) {
            console.error("Error fetching customer data: ", error);
          }
        }
      };
  
      getData();
      return () => {
        isMounted = false;
      };
    }, [CIQ]);
  
    return (
      <div className="d-flex align-items-center w-auto flex-row">
        <div className="symbol symbol-circle symbol-30px mx-3 bg-light-primary">
          {customerData?.avatar ? (
            <div className="symbol-label">
              <img
                src={`${customerData?.avatar}`}
                alt={customerData?.firstName}
                className="w-100"
                style={{ borderRadius: '50%' }}
              />
            </div>
          ) : (
            <div className="symbol-label">
              <img
                src={toAbsoluteUrl('/media/icons/avatar/def-avatar.png')}
                alt={customerData?.firstName}
                className="w-100"
                style={{ borderRadius: '50%' }}
              />
            </div>
          )}
        </div>
        <div className="d-flex flex-column text-start w-auto">
          {!customerData
            ? CIQ?.parent_document?.profileName ?? CIQ?.parent_document?.phoneNumber
            : ''}
          {customerData?.firstName} {customerData?.lastName ? customerData?.lastName : ''}
        </div>
      </div>
    );
  };
  

export { CIQInfoCell };
