import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FirestoreFilterValue } from "../../../../hooks/useFirestoreData";
import { PageLink, PageTitle } from "../../core/PageData";
import * as ShipmentService from "../../../../db/serviceShipment";
import OrderList from "./order-list/OrderList";
import { useDispatch } from "react-redux";
import { setShipmentDeadlines } from "../../../modules/product/shipment/redux/ShipmentsSlice";

type Props = {};

const OrderPage: React.FC = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // update redux shipment with max ship time
  useQuery("shipmentWithMaxShipTime", () => ShipmentService.getShipmentMaxShipTime(), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (data) => {
      console.log("shipmentWithMaxShipTime", data);
      // update redux
      const shipmentWithMaxShipTime = data?.map((shipment) => {
        return {
          id: shipment.id,
          logisticName: shipment.logisticsChannelName,
          logisticSearchKey: shipment.logisticsChannelNameInsensitive,
          marketplaceName: shipment.marketplaceName,
          maxShipTime: shipment.maxShipTime,
          minShipTime: shipment.minShipTime,
        };
      });
      dispatch(setShipmentDeadlines(shipmentWithMaxShipTime));
    },
  });

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Menu.Order"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  console.log("Order Page");

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs} contentTitle={t("OrderList.Title")}>
        {t("OrderList.Title")}
      </PageTitle>
      <OrderList />
    </>
  );
};

export function isSameStatusValue(value: FirestoreFilterValue | undefined, compare: string | string[]) {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value) && !Array.isArray(compare)) {
    return value === compare;
  }
  if (Array.isArray(value) && Array.isArray(compare)) {
    return value.every((item) => compare.includes(item));
  }
  if (Array.isArray(value) && !Array.isArray(compare)) {
    return value.includes(compare);
  }
  if (!Array.isArray(value) && Array.isArray(compare)) {
    if (typeof value !== "string") {
      return false;
    }
    return compare.includes(value);
  }
  return false;
}

export default OrderPage;
