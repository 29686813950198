import { Column } from "react-table";
import { Campaign } from "../../../../../../models/Campaign";
import { CampaignInfoCell } from "./CampaignInfoCell";
import { CampaignMessagesCountCell } from "./CampaignMessagesCountCell";
import { CampaignCustomHeader } from "./CampaignCustomHeader";
import { CampaignCreatedAtCell } from "./CampaignCreatedAtCell";

const campaignsColumns: ReadonlyArray<Column<Campaign>> = [
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Campaign.Input.CampaignName"
        className="min-w-125px"
        data-testid="campaignName"
      />
    ),
    id: "campaignName",
    Cell: ({ ...props }) => (
      <CampaignInfoCell campaign={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.SubmittedAt"
        className="min-w-125px"
        data-testid="createdAt"
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <CampaignCreatedAtCell
        createdAt={props.data[props.row.index].createdAt}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Published"
        className="min-w-125px"
        data-testid="messagecount"
      />
    ),
    id: "published",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countCreated}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Sent"
        className="min-w-125px"
        data-testid="count-sent"
      />
    ),
    id: "sent",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countSent}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Delivered"
        className="min-w-125px"
        data-testid="count-delivered"
      />
    ),
    id: "delivered",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countDelivered}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Read"
        className="min-w-125px"
        data-testid="count-read"
      />
    ),
    id: "read",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countRead}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="DLR.Column.Failed"
        className="min-w-125px"
        data-testid="count-failed"
      />
    ),
    id: "undelivered",
    Cell: ({ ...props }) => (
      <CampaignMessagesCountCell
        messagesCount={
          props.data[props.row.index].campaignMessagesCount?.countFailed}
      />
    ),
  },
];

export { campaignsColumns };
