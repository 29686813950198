import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { KTSVG } from "../../../../resources/helpers";

type CarouselProps = {
  id: string;
  children: string | HTMLElement;
  [propName: string]: any;
};

const Carousel: React.FC<CarouselProps> = ({
  id,
  children,
  ...props
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div className="d-flex flex-column" id={id}>
      <div className="d-flex justify-content-center mw-100">
        <div
          className={clsx(
            `d-flex justify-content-center py-5 px-5  ${
              isTabletOrMobile ? "mw-100 mh-100" : "mw-50 mh-75"
            }`
          )}
          style={{ backgroundColor: "#EBF7FF" }}
        >
          <div id="carousel" className="carousel slide" data-bs-ride="carousel">
            <div
              className="carousel-inner mw-100"
              {...props}
              // style={{ maxHeight: isTabletOrMobile ? "auto" : "250px"}}
            >
              {children}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              style={{ left: isTabletOrMobile ? "0px" : "-100px" }}
              data-bs-target="#carousel"
              data-bs-slide="prev"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr074.svg"
                className="svg-icon-dark svg-icon-2hx"
              />
            </button>
            <button
              className="carousel-control-next"
              style={{ right: isTabletOrMobile ? "0px" : "-100px" }}
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr071.svg"
                className="svg-icon-dark svg-icon-2hx"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;