import React, {FC, useRef, useEffect, useState} from "react"
import * as action from "../../../actions/playground";
import db from "../../../db"

const SearchFirebase: FC = () => {

  const collectionName = useRef<HTMLInputElement>(null)
  const fieldName = useRef<HTMLInputElement>(null)
  const operator = useRef<HTMLInputElement>(null)
  const value = useRef<HTMLInputElement>(null)
  const valueCollection = useRef<HTMLInputElement>(null)
  const [results, setResults] = useState("")

  useEffect(()=> {

  }, [])

  return (
    <>
    <div>
        Collection Name <input ref={collectionName} type="text"/>
    </div>
    <div>
        Field Name <input ref={fieldName} type="text"/> <br/></div>
    <div>Operator <input ref={operator} type="text"/> <br/></div>
    <div>Value Collection <input ref={valueCollection} type="text"/> <br/>
    <div>Value <input ref={value} type="text"/>
     <br/>
     for multiple input, separate by ","
     </div>
    
    ex: 
    </div>
    <div></div>
    
    <div><button onClick={() => {
      if (!collectionName.current 
        || !fieldName.current 
        || !operator.current 
        || !valueCollection.current 
        || !value.current)
        return; 
      
      action.searchCollections(
        collectionName.current.value, 
        [{
          name: fieldName.current.value , 
          op: operator.current.value, 
          value: value.current.value.split(","),
          collectionRef: valueCollection.current.value
        }],
        (searchResult => {
          console.debug(`filter onfinished`)
          setResults(JSON.stringify(searchResult))
        })
      )

    }}> Search </button>

    <div> 
      Results 
      {`${results}`}
    </div>
    </div>
    </>
  )
}

export default SearchFirebase;