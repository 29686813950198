import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { UserRoles } from "../../../../../models/User";
import TextInput from "../../../../../../styles/components/TextInput";
import { initiateCardMember } from "./AddMember";
import { useMediaQuery } from "react-responsive";
import * as AddMemberRedux from "../../../../../modules/auth/redux/AddMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup/redux/store";
import { Member } from "../../../../../modules/auth/redux/AddMemberRedux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const CardMember: FC<initiateCardMember> = ({ id, onClickDelete }) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const idMember = id;
  let errorMessage: string = useSelector(
    (state: RootState) => state.AddMember.responseMessage
  );
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [emailInviting, setEmailInviting] = useState<String>("");
  const [rolesData, setRolesData] = useState<String[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>({
    value: "user",
    label: "user",
  });
  const dispatch = useDispatch();

  const listRole = () => {
    if (!rolesData) return [];
    if (rolesData) {
      return rolesData.map((role: any) => ({
        value: role,
        label: role,
      }));
    }
  };

  useEffect(() => {
    const roles: string[] = [UserRoles.admin, UserRoles.user];
    setRolesData(roles);
  }, []);

  const customStyles = {
    option: (provided: any, state: any) => {
      console.log(state, "state test")
      return {
        ...provided,
        color:
          state.data?.label === selectedRole?.label
            ? "white"
            : "#1e2022",
        backgroundColor:
          state.data?.label === selectedRole?.label
            ? "#0275d8" // Background color on hover or when option is selected
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      outline: "none",
      border: "none",
    }),
  };

  const AddMembersRd = useSelector(
    (state: RootState) => state.AddMember.members
  );

  useEffect(() => {
    if (AddMembersRd.length) {
      console.log(AddMembersRd, "test adnm");
      AddMembersRd?.map((item: any) => {
        if (item.id === idMember) {
          setEmailInviting(item?.email ?? "");
          setSelectedRole({
            label: item?.role ?? "user",
            value: item.role ?? "user",
          });
        }
      });
    }
  }, [AddMembersRd]);

  const addMemberSchema = Yup.object().shape({
    email: Yup.string()
      .required("Register.Error.EmptyEmail")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Register.Error.FormatEmail"
      ),
    role: Yup.string().required("Register.Error.EmptyEmail"),
  });
  const initialValues = {
    email: "",
    role: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addMemberSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {},
  });

  return (
    <div
      className={
        isTabletOrMobile
          ? "d-flex flex-column h-auto pb-0 pt-10 border border-line rounded rounded-xl overflow-hidden"
          : "d-flex flex-column h-auto p-10 border border-line"
      }
      data-testid="cardmember"
    >
      <div
        className={
          isTabletOrMobile
            ? "h-auto w-100 pb-0 mb-0 d-flex flex-column align-items-start justify-content-between gap-5 p-0"
            : "h-auto w-100 d-flex flex-row align-items-start justify-content-between gap-5 p-0"
        }
      >
        <div className={isTabletOrMobile ? "w-100 px-8" : "w-50"}>
          <p className="w-75">{t("ForgotPassword.Input.Email")}</p>
          <TextInput
            data-testid={"email-"+id}
            // placeholder="Isi email ..."
            formcontrol={"solid"}
            name="email"
            autoComplete="off"
            id={"email-"+id}
            type="email"
            maxLength={100}
            value={emailInviting}
            onBlur={async (e: any) => {
              await formik.handleBlur(e);
            }}
            // {...formik.getFieldProps("email")}
            onInput={async (e: any) => {
              e.preventDefault();
              setEmailInviting(e?.target?.value);
              await formik.handleChange(e);
              await formik.setFieldValue("email", e?.target?.value);
              await formik.validateForm();
              let obj = {
                id,
                email: e?.target?.value,
                role: selectedRole.label,
              };
              dispatch(AddMemberRedux.addMembers(obj));
              dispatch(AddMemberRedux.setResponseMessage(""));
            }}
          />
          {formik.touched.email && formik.errors.email && (
            <div
              data-testid="emailerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.email}`)}</span>
            </div>
          )}
          {errorMessage !== "" && (
            <div
              data-testid="emailerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{errorMessage}</span>
            </div>
          )}
        </div>

        <div data-testid={`${"role-select-" + id}`} className={isTabletOrMobile ? "w-100 px-8" : "w-50"}>
          <p className="w-75">{t("UserManagement.Table.Header.AccessType")}</p>
          <Select
            id={`${"role-" + id}`}
            defaultValue={rolesData[1]}
            options={listRole()}
            styles={customStyles}
            className="basic-select"
            classNamePrefix="select"
            value={selectedRole}
            onChange={async (selectedOption: any) => {
              setSelectedRole(selectedOption);
              let obj = {
                id,
                email: emailInviting,
                role: selectedOption.label,
              };
              dispatch(AddMemberRedux.addMembers(obj));
              await formik.handleChange(selectedOption.label);
              await formik.setFieldValue("role", selectedOption.label);
              await formik.validateForm();
            }}
          />
          {formik.touched.role && formik.errors.role && (
            <div
              data-testid="roleerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.role}`)}</span>
            </div>
          )}
        </div>
        {isTabletOrMobile ? (
          <div
            className="w-100 mb-0 mt-3 p-3 d-flex flex-row align-items-center justify-content-center gap-2 cursor-pointer"
            style={{
              backgroundColor: "#FFE8E8",
              color: "#CD5050",
            }}
            data-testid={"button-delete-"+id}
            onClick={() => {
              console.log(`click delete`);
              if (onClickDelete) onClickDelete();
            }}
          >
            <p className="p-0 m-0">{t("Contacts.Menu.Delete")}</p>
            <span className="bi bi-trash m-0 p-0 fs-3 cursor-pointer" />
          </div>
        ) : (
          <div
            className={
              (formik.touched.email && formik.errors.email) ||
              (formik.touched.role && formik.errors.role) ||
              errorMessage !== ""
                ? "m-0 p-0 cursor-pointer align-self-center pt-2"
                : "m-0 p-0 cursor-pointer align-self-end pb-2"
            }
            data-testid="button-delete"
          >
            <span
              className={clsx("bi bi-trash m-0 p-0 fs-2 cursor-pointer", isMobileView ? "text-danger" : "")}
              onClick={() => {
                console.log(`click delete`);
                if (onClickDelete) onClickDelete();
              }}
              data-testid={"button-delete-"+id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardMember;
