import { FC } from "react";
import { useTranslation } from "react-i18next";
import Message from "../../../../models/Message";
import ChatMessageInfo from "./ChatMessageInfo";

interface MessageProps {
  userMessage: Message;
  onClickReply?: () => void;
  destination?: string;
  onClickShowMsgInfo?: () => void;
}

const ChatMessageMenu: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const {userMessage, onClickReply, destination, onClickShowMsgInfo} = props;

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto'
      data-kt-menu='true'
      data-testid="message-menu"
      onMouseLeave={() => {
        console.log("onMouseLeave MsgInfo");
        document.getElementById(`message_menu_${userMessage.id}`)?.click()
      }}
      style={{ background: "#D9D9D9" }}
    >
      {/* <div className="menu-title message-menu-title d-flex flex-row">
        {destination === "out" && 
          <div className="w-100 ps-2 pe-1 text-left mb-2" 
            onClick={() => {
                //remove show menu
                document.getElementById(`message_menu_${userMessage.id}`)?.click();
                onClickShowMsgInfo && onClickShowMsgInfo();
            }}
            style={{cursor: "pointer"}}
          >
          {t("MI.Title.MessageInfo")}
          </div>
        }
      </div> */}
      {destination === "out" && <ChatMessageInfo userMessage={userMessage} />}
      {destination === "out" && <hr></hr>}
      <div className="menu-title message-menu-title d-flex flex-column">
        {/* <div
          className="bi bi-x-lg cursor-pointer"
          data-kt-menu-dismiss="true"
        ></div> */}
        <div className="w-100 ps-2 pe-1" 
            data-testid={`reply-button-${userMessage.id}`}
            onClick={() => {
                //remove show menu
                document.getElementById(`message_menu_${userMessage.id}`)?.click();
                onClickReply && onClickReply();
            }}
            style={{cursor: "pointer", textAlign: "left"}}
        >
          {t("HandledCustomer.Reply")}
        </div>
      </div>
    </div>
  );
};

export default ChatMessageMenu;
