import React, { useEffect, useState } from "react";
import * as action from "../../../actions/playground";
import Button from "../../../styles/components/Button";
import db from "../../../db";
import { Process } from "../../models/Process";
import { number } from "yup";
import { firestore } from "firebase-admin";
import { collection, deleteField, getDocs, updateDoc } from "firebase/firestore";

const ProcessTab: React.FC = () => {
  const [syncCount, setSyncCount] = useState<number>(0);
  const [expCount, setExpCount] = useState<number>(0);

  const [added, setAdded] = useState<number>(0);
  const [updated, setUpdated] = useState<number>(0);
  const [unrecognize, setUnrecognize] = useState<firebase.default.firestore.QueryDocumentSnapshot<firebase.default.firestore.DocumentData>[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const displayMessage = () => {

    const messageElement = document.getElementById("message");
    if (messageElement) {
      const unrecognizeIds = unrecognize?.map((doc) => doc.id) || [];

      const unrecognizeMessage =
        unrecognizeIds.length > 0
          ? `<li>Unrecognized document ids(${unrecognizeIds.length}): ${unrecognizeIds.join(", ")}</li>`
          : "<li>No unrecognized documents</li>";
      messageElement.innerHTML = `
        <p>Done edit old record with message below:</p>
        <ul>
          ${unrecognizeMessage}
          <li>Syncronize Order: ${syncCount}</li>
          <li>Export Order: ${expCount}</li>
          <br>
          <li>Added Field: ${added}</li>
          <li>Updated Field: ${updated}</li>
        </ul>
      `;
    }
  };

  const fetchDataFromProcessCollection = async () => {
    try {
      const snapshot = await db.collection("processes").get();
      return snapshot.docs;
    } catch (error) {
      console.error("Error fetching data from processes collection:", error);
      throw error;
    }
  };
  const updatedb = async (docId: string, obj: Process)  => {
    try {
      await db.collection("processes").doc(docId).update(obj);
    } catch (error) {
      console.error(
        `Error add Name Field for document ID ${docId}:`,
        error
      );
    }
  }


  const addFieldToDB = async (docId: string, nama: string, fieldName: boolean, fieldType: boolean) => {
    try {
      const updateObj = fieldName ? (fieldType ? { name: nama, type: typeSet[nama] } : { name: nama }) : { type: typeSet[nama] };
      await db.collection("processes").doc(docId).update(updateObj);
      // addToDB(undefined, docId, nama);
    } catch (error) {
      console.error(
        `Error add Name Field for document ID ${docId}:`,
        error
      );
    }
  }

  const addToDB = async (toAdd?: any[], docId?: string, name?: string) => {
    var updatePromises = toAdd && toAdd.map((proc) =>
      updateTypeField(proc.id, typeSet[proc.data().name])
    );

    if (docId && name) {
      updatePromises = [updateTypeField(docId, typeSet[name])];
    }

    if (updatePromises) await Promise.all(updatePromises);
  };

  const updateNameField = async (docId: string, nama: string) => {
    try {
      await db.collection("processes").doc(docId).update({ name: nama });
      console.log(`Updated document ID: ${docId}`);
    } catch (error) {
      console.error(
        `Error updating Type Field for document ID ${docId}:`,
        error
      );
    }
  };

  const updateTypeField = async (docId: string, type: string) => {
    try {
      await db.collection("processes").doc(docId).update({ type });
      console.log(`Updated document ID: ${docId}`);
    } catch (error) {
      console.error(
        `Error updating Type Field for document ID ${docId}:`,
        error
      );
    }
  };
  
  const typeSet: { [name: string]: string } = {
    "Syncronize Order": "syncOrder",
    "Export Order": "exportOrder",
  };

  const editOldRecordFieldCollectionProcesses = async () => {
    try {
      var syncedCount: number = 0;
      var exportedCount: number = 0;
      var addedCount: number = 0;
      var updatedCount: number = 0;
      var unrecognized: any[] = [];
      
        const processDocs = await fetchDataFromProcessCollection();
        console.log(`PROCESSDOCS: `, processDocs);

        processDocs.forEach((doc) => {
          const data = doc.data() as Process

          if (data.syncLog && data.account) {
            if (!data.name || !data.type) { console.log("Sync Log tanpa name atau type");
            addedCount++;
            setAdded(addedCount); }
            else if (typeSet[data.name] !== data.type) { console.log("Sync Log nama dan tipe tidak bersesuaian");
            updatedCount++;
            setUpdated(updatedCount); }

            data.type = "syncOrder"
            data.name = "Syncronize Order"
            updatedb(doc.id, data);
            syncedCount++;
            setSyncCount(syncedCount);
            return ;
          }

          if (data.orders && data.orderIds)
          {
            if (!data.name || !data.type) { console.log("Export Log tanpa name atau type");
                addedCount++;
                setAdded(addedCount); }
            else if (typeSet[data.name] !== data.type) { console.log("Export Log nama dan tipe tidak bersesuaian");
            updatedCount++;
            setUpdated(updatedCount); }
            
            data.type = "exportOrder"
            data.name = "Export Order"
            updatedb (doc.id, data);
            exportedCount++;
            setExpCount(exportedCount);
            return ;
          }

          // if (!data.orders && !data.orderIds && !data.name && !data.type) {
          unrecognized.push(doc);
          setUnrecognize(unrecognized);

            // return;
            // }
        });
          // console.log(`UNRECOGNIZE: `, unrecognize.map((undef) => {
            //   return undef.id;
            // }));
        unrecognize.map((undef) => {
          console.log(`UNRECOGNIZED DOCUMENT: ${undef.id}`);
        });
        console.log(`SYNC: `, syncCount);
        console.log(`EXPORT: `, expCount);

        console.log(
          `UPDATE FIELD "type" = "exportOrder" IF THAT DOESN'T EXIST COMPLETED`
        );
      
      setShowMessage(true);
    } catch (error) {
      console.log(`error Edit Old Record Field Collection Processes `, error);
    }
  };

  const removeNameAndType = async () => {
    try {
      const docRef = await getDocs(collection(db, 'processes'));
      const updates = docRef.docs.map(doc => {
        const docRef = doc.ref;
        return updateDoc(docRef, {
          name: deleteField(),
          type: deleteField()
        })
      })

      await Promise.all(updates)
  
      console.log(`Deleted field with result: ${updates}`);
      document.getElementById("message")?.append("All name and type field has been deleted!");
    } catch (error) {
      console.error(`Error deleting field :`, error);
    }
  }

  const removeType = async () => {
    try {
      const docRef = await getDocs(collection(db, 'processes'));
      const updates = docRef.docs.map(doc => {
        const docRef = doc.ref;
        return updateDoc(docRef, {
          type: deleteField()
        })
      })

      await Promise.all(updates)
  
      console.log(`Deleted field with result: ${updates}`);
      document.getElementById("message")?.append("All type field has been deleted!");
    } catch (error) {
      console.error(`Error deleting field :`, error);
    }
  }

  const removeName = async () => {
    try {
      const docRef = await getDocs(collection(db, 'processes'));
      const updates = docRef.docs.map(doc => {
        const docRef = doc.ref;
        return updateDoc(docRef, {
          name: deleteField()
        })
      })

      await Promise.all(updates)
  
      console.log(`Deleted field with result: ${updates}`);
      document.getElementById("message")?.append("All type field has been deleted!");
    } catch (error) {
      console.error(`Error deleting field :`, error);
    }
  }

  const removeNameNoSync = async () => {
    try {
      const docRef = await db.collection("processes").where("name", "!=", "Syncronize Order").get();
      docRef.docs.map((ref) => {
        console.log("Deleting field Name of Document", ref.id);
        return db.collection("processes").doc(ref.id).update({
          name: deleteField()
        });
      });
      document.getElementById("message")?.append("All type field has been deleted!");
    } catch (error) {
      console.error(`Error deleting field :`, error);
    }
  }

  useEffect(() => {
    if (showMessage) {
      displayMessage();
    }
  }, [added, updated, syncCount, expCount, unrecognize, showMessage]);

  return (
    <div>
      <div>
        <Button
          className="btn btn-lg btn-primary fw-bolder mx-3 my-3"
          onClick={() => editOldRecordFieldCollectionProcesses()}
        >
          Edit Old Record Field Collection Processes
        </Button>
        <Button
          className="btn btn-lg btn-primary fw-bolder mx-3 my-3"
          onClick={() => removeNameAndType()}
        >
          Remove Name and Type
        </Button>
        <Button
          className="btn btn-lg btn-primary fw-bolder mx-3 my-3"
          onClick={() => removeType()}
        >
          Remove Type
        </Button>
        <Button
          className="btn btn-lg btn-primary fw-bolder mx-3 my-3"
          onClick={() => removeName()}
        >
          Remove Name
        </Button>
        <Button
          className="btn btn-lg btn-primary fw-bolder mx-3 my-3"
          onClick={() => removeNameNoSync()}
        >
          Remove Name other than "Syncronize"
        </Button>
        <div className="hidden" id="message">
        </div>
      </div>
    </div>
  );
};

export default ProcessTab;
