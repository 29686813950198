import React, { FC, PropsWithChildren } from "react";
import { HeaderProps } from "react-table";
import Customer from "../../../../../models/Customer";
import { useFirestoreTable } from "../../context/TableContext";
import { useListView } from "../../core/ListViewProvider";

type Props = {
  tableProps: PropsWithChildren<HeaderProps<Customer>>;
};

const TargetSelectionHeader: FC<Props> = ({ tableProps }) => {
  // const {isAllSelected, onSelectAll} = useListView()
  const { selectedItems, setSelectedItems } = useFirestoreTable();
  const data = tableProps.data;

  const isAllSelected =
    selectedItems.length > 0 &&
    data.every(
      (customer) =>
        customer.id &&
        selectedItems.some((cust) => cust.id && cust.id === customer.id)
    );

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data.map((item) => item));
    }
  };

  return (
    <th {...tableProps.column.getHeaderProps()} className="w-10px pe-2">
      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
        <input
          data-testid="checkbox-select-all"
          className="form-check-input"
          type="checkbox"
          data-kt-check={isAllSelected}
          data-kt-check-target="#kt_table_users .form-check-input"
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  );
};

export { TargetSelectionHeader };
