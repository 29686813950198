import clsx from "clsx";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Process } from "../../../../../../models/Process";
import UserDownloadModal from "../../components/modal/UserDownloadModal";

type DownloadCellProps = {
  processes: Process;
};

export default function DownloadCell({ processes }: DownloadCellProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const showModal = (data: Process) => {
    setModalOpen(true);
  };

  return (
    <>
      <UserDownloadModal
        processID={processes.id}
        isModalOpen={modalOpen}
        setIsModalOpen={() => setModalOpen(false)}
      />
      <div
        onClick={() => showModal(processes)}
        className="d-flex align-items-center justify-content-center gap-1 cursor-pointer"
      >
        <span>
          {processes.downloadCount ?? 0} {t("ExportOrderProcess.DownloadTimes")}
        </span>
        <span>
          <i className="bi bi-chevron-down"></i>
        </span>
      </div>
    </>
  );
}
