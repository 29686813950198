/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {format} from 'date-fns';
import { Timestamp } from "@firebase/firestore-types";

type Props = {
  value?: Date | Timestamp
  className? : string
}

const TemplateLastSync: FC<Props> = ({ value, className }) => {
  let valueStr: string = " - ";
  if (value){ 
    const date = JSON.stringify(value).toString().indexOf("seconds")>-1 ? 
    new Date((value as Timestamp).seconds! * 1000) : 
    value as Date
    valueStr = format(date, "d MMM yyyy, h:mm aaa" );
  } 
  return (
    // <div className='text-gray-650 mb-1'>{valueStr}</div>
    <div className={className??""}>{valueStr}</div>
  )
};

export default TemplateLastSync;
