import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import socket from "../../../../../../setup/socket/socket";
import { Process } from "../../../../../models/Process";
import { useFirestoreTable } from "../context/TableContext";
import DownloadCell from "../table/columns/DownloadCell";
import ReportCell from "../table/columns/ReportCell";
import StatusCell from "../table/columns/StatusCell";

import { reqReadyToShipOrder } from "../../../../../../api/server/order";
import * as ProcessesService from "../../../../../../db/serviceProcesses";
import * as locStg from "../../../../../modules/localstorage";

const stringToTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const ProcessCard: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: orderData,
    isLoading,
    isFetching,
    tableState,
    selectedItems,
    setSelectedItems,
  } = useFirestoreTable();

  const orderList = orderData?.items || [];

  const isAllSelected =
    selectedItems.length > 0 &&
    orderList
      .filter(
        (item) => !(!item.id || !item.fileURL || item.status !== "finished")
      )
      .every((item) => selectedItems.includes(item.id));

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        orderList
          .filter(
            (item) => !(!item.id || !item.fileURL || item.status !== "finished")
          )
          .map((item) => item.id)
      );
    }
  };

  return (
    <>
      <div className="d-flex px-2 pb-3" data-testid="order-card">
        <input
          className="form-check-input my-auto"
          type="checkbox"
          data-kt-check={isAllSelected}
          data-kt-check-target="#kt_table_users .form-check-input"
          checked={isAllSelected}
          onChange={() => onSelectAll()}
        />
        <label
          className="form-check-label px-3"
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {t("Storefront.Button.SelectMarketplace.SelectAll")}
        </label>
      </div>

      {orderList.map((item) => (
        <Card key={item.id} process={item} />
      ))}
    </>
  );
};

function Card({ process }: { process: Process }) {
  const { t } = useTranslation();

  const { selectedItems, setSelectedItems } = useFirestoreTable();

  const isSelected =
    process.id !== undefined && selectedItems.includes(process.id);

  const onSelect = () => {
    setSelectedItems((prev) => {
      if (!process.id || !process.fileURL || process.status !== "finished") {
        return prev;
      }
      if (prev.includes(process.id)) {
        return prev.filter((key) => key !== process.id);
      }
      return [...prev, process.id];
    });
  };

  return (
    <div
      className="p-2 mb-2 fw-bold card"
      style={{
        backgroundColor: "#C6E7FD",
        fontSize: "14px",
        color: "#6C7288",
      }}
    >
      <div className="d-flex justify-content-between align-items-end border-bottom pb-1">
        <div className="d-flex my-auto">
          <div className="my-auto">
            <input
              className="form-check-input"
              type="checkbox"
              data-kt-check={isSelected}
              data-kt-check-target="#kt_table_users .form-check-input"
              checked={isSelected}
              disabled={
                !process.id || !process.fileURL || process.status !== "finished"
              }
              onChange={() => {
                onSelect();
              }}
            />
          </div>
          <span
            className="px-2 text-dark fw-bolder"
            style={{ fontSize: "1.25rem" }}
          >
            {process.id}
          </span>
        </div>

        <div className="ml-auto" style={{ fontSize: "10px" }}>
          <ReportCell
            status={process.status}
            orders={process.orders}
            id={process.id}
          />
        </div>
      </div>

      <div className="pt-2 d-flex justify-content-between">
        <span>{t("OrderList.OrderTime")}</span>
        <span>
          {moment(process.createdAt?.toDate()).format("HH:mm DD//MM/YYYY")}
        </span>
      </div>

      <div className="d-flex justify-content-between">
        <span>{t("ExportOrderProcess.TotalOrder")}</span>
        <span>{process.orders?.length ?? 0}</span>
      </div>

      <div className="d-flex justify-content-between">
        <span>{t("ExportOrderProcess.DownloadCount")}</span>
        {/* <span>
          {process.downloadCount} {t("ExportOrderProcess.DownloadTimes")}
        </span> */}
        <DownloadCell processes={process} />
      </div>
      <ActionSection
        fileURL={process.fileURL}
        status={process.status}
        processID={process.id}
        orderID={process.orderIds}
      />
    </div>
  );
}

type ActionSectionProps = {
  fileURL?: string;
  status: string;
  processID: string;
  orderID: string[];
};
function ActionSection({
  fileURL,
  status,
  processID,
  orderID,
}: ActionSectionProps) {
  const { refreshData } = useFirestoreTable();
  const { t } = useTranslation();
  const [isLoadingRetry, setIsLoadingRetry] = useState(false);

  const handleRetry = async () => {
    const userID = locStg.getItemLC(locStg.LCName.UserID);
    setIsLoadingRetry(true);
    await reqReadyToShipOrder(orderID, userID, processID);
    await refreshData();
    setIsLoadingRetry(false);
  };

  if (status === "waiting") {
    return (
      <div className="d-flex align-items-center gap-2 justify-content-center px-4 fs-7">
        <span className="spinner-border spinner-border-sm"></span>
        <span>{t("Common.Pages.Loading")}</span>
      </div>
    );
  }

  if (status === "finished" && !fileURL) {
    return (
      <div className="d-flex justify-content-between text-center pt-5">
        <button
          type="button"
          className="w-100 btn btn-light-primary btn-sm"
          disabled={isLoadingRetry}
          onClick={() => handleRetry()}
        >
          {isLoadingRetry
            ? t("ExportOrderProcess.Button.Loading")
            : t("ExportOrderProcess.Button.Retry")}
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-between text-center pt-5">
      <button
        disabled={!fileURL}
        type="button"
        className="btn btn-primary btn-sm w-100"
        style={{ fontSize: "14px" }}
        onClick={async () => {
          const userID = locStg.getItemLC(locStg.LCName.UserID);
          if (!processID || !orderID || !userID) {
            return undefined;
          }
          const link = document.createElement("a");
          link.href = fileURL || "";
          link.download = "";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          await ProcessesService.incrementDownloadByIDAndAccID(
            processID,
            userID
          );
          refreshData();
        }}
      >
        {t("ExportOrderProcess.Button.Download")}
      </button>
    </div>
  );
}

export default ProcessCard;
