/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from "../../../../../resources/helpers";
import { getCIQs } from "./_requests";
import { getCIQr } from "./_requests";
import { CIQsColumnType as CIQ } from "../table/columns/_columns";
import { Collaboration } from "../../../../models/Chat";
// import { Room as CIQ } from '../../../../models/Chat'
import { useQueryRequest } from "./QueryRequestProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../setup/redux/store";
import * as CIQRedux from "../../../../modules/ciq/redux/CIQSlice";
import { ciqSorting } from "./_sort";
import { Response } from "../../../../../resources/helpers";

export type CIQsQueryResponse = Response<CIQ[]>;

const QueryResponseContext = createResponseContext<CIQ>(initialQueryResponse);

const QueryResponseProvider: FC = ({ children }) => {
  const { state, updateState } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listCIQdata: any = [];
  listCIQdata = useSelector((state: RootState) => state.CIQ.listCIQ); //list CIQ

  console.log("atasnya ciq wxx", listCIQdata);
  console.log(query, "lets query CIQ >>>>>");
  console.log(updatedQuery, "lets updaquery CIQ >>>>>");
  let CIQQueryResponse: CIQsQueryResponse;

  useEffect(() => {
    console.log("Masuk use effect update query CIQ >>>>>");
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    console.log("Masuk use effect update list CIQ >>>>");
    setCounter(listCIQdata.length);
  }, [listCIQdata]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${counter}`,
    async () => {
      let data: any = [];
      // dispatch(CIQRedux.cleanReduxCIQ(true));

      // cek redux
      if (!listCIQdata || listCIQdata.length <= 0) {
        console.log("MASUK CEK LISTSCIQDATA REDUX A");
        data = await getCIQs(
          state.sort,
          // "lastInteractionAt",
          // "asc",
          state.order,
          state.search,
          state.action,
          state.lastId,
          state.items_per_page
        );
        if (data && data.length > 0) {
          dispatch(CIQRedux.setListCIQ(data));
        }
        // setCIQData(listCIQdata);
      } else {
        console.log("MASUK CEK LISTSCIQDATA REDUX B");
        data = listCIQdata;
      }

      // let dats = await getCIQs(
      //   // state.sort,
      //   "lastInteractionAt",
      //   "asc",
      //   // state.order,
      //   state.search,
      //   state.action,
      //   state.lastId,
      //   state.items_per_page
      // );

      let resultData = ciqSorting(
        data,
        // "lastInteractionAt",
        state.sort,
        state.items_per_page,
        state.action,
        // "asc",
        state.order,
        state.search,
        state.page,
        (lengthData: number) => {
          if (state.search && state.search !== "") {
            console.log("MASUK RESULTDATA CIQSORTING");
            dispatch(CIQRedux.setCountCIQ(lengthData));
          }
        }
      );

      if (state.search && state.search !== "") {
      } else {
        dispatch(CIQRedux.setCountCIQ(data.length));
      }
      CIQQueryResponse = {
        data: resultData,
      };

      // return getCIQs(state.sort , state.order, state.search, state.action, state.lastId, state.items_per_page )
      return Promise.resolve(CIQQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>{children}</QueryResponseContext.Provider>;
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponsePagination, useQueryResponseLoading };
