import Product from "../../../../../models/Product"

const sliceData = (
  data: Product[],
  startIndex: number,
  endIndex: number
) => {
  let newDataProduct: Product[] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const dataproduct = data[index];
    newDataProduct.push(dataproduct);
  }
  return newDataProduct;
};

export const productSorting = (data : Product[], 
  sort: string | undefined, 
  limit: number | undefined, 
  action: string | undefined , 
  orderBy: string | undefined,
  searchBy: string | undefined, 
  currentPage: number | undefined,
  callback: any) => {
  console.log("Masuk Product product sorting >>>>> ");

  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort prod: " + sort);
  console.log("order : " + orderBy);
  console.log("search : " + searchBy);
  console.log("current page : " + currentPage);

  if(currentPage === undefined)currentPage = 1;
  if(limit === undefined)limit = 10;
  if(orderBy === undefined) orderBy = "asc";
  if(searchBy === undefined) searchBy = ""; 
  if(sort === undefined || sort === 'deleted') sort = "createdAt";

  let dataProduct: Product[] = [];

  if(data.length == 0){
    return dataProduct;
  }

  for (let index = 0; index < data.length; index++) {
    dataProduct.push(data[index]); 
  }

  //Search Data
  if(searchBy && searchBy !=null && searchBy!="" && dataProduct.length>0){
    dataProduct = dataProduct.filter((data) => data.name?.toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
  }

  //sorting
  if(dataProduct.length>0){
    if (sort === "name") {
      dataProduct = dataProduct?.sort((a, b) =>
        a.name!.toLowerCase() > b.name!.toLowerCase() ? 1 : -1
      );
      if (orderBy === "desc") {
        dataProduct = dataProduct?.sort((a, b) =>
          a.name!.toLowerCase() > b.name!.toLowerCase() ? -1 : 1
        );
      }
    }
    if (sort === "variant") {
      dataProduct = dataProduct?.sort((a, b) =>
        a.variantLength! > b.variantLength! ? 1 : -1
      );
      if (orderBy === "desc") {
        dataProduct = dataProduct?.sort((a, b) =>
           a.variantLength! > b.variantLength! ? -1 : 1
        );
      }
    }
    if (sort === "status") {
      dataProduct = dataProduct?.sort((a, b) =>
      {
        if (!a.status || a.status === undefined) return 1;
        if (!b.status || b.status === undefined) return -1;
        return a.status.toLowerCase() > b.status.toLowerCase()? 1 : -1
      });
      if (orderBy === "desc") {
        dataProduct = dataProduct?.sort((a, b) =>{
          if (!a.status || a.status === undefined) return -1;
          if (!b.status || b.status === undefined) return 1;
          return a.status.toLowerCase() > b.status.toLowerCase() ? -1 : 1
      });
      }
    }
  }

  callback(dataProduct.length);

  if(currentPage == 1){
    let endIndex = currentPage * limit;
    dataProduct = Array.from(dataProduct).slice(0,limit);
  }else{
    let endIndex = currentPage * limit;
    let startIndex = (endIndex - limit);
    console.log("Hasil slice data 2 : "+startIndex+" - "+endIndex);
    if(startIndex > dataProduct.length){
      return [];
    }else{
      dataProduct = Array.from(dataProduct).slice(startIndex,endIndex);
    }
  }
  console.log("Hasil Length Data : "+dataProduct.length);
  // console.log("Hasil Data : "+JSON.stringify(dataContact));
  console.log("Hasil Current Page : "+currentPage);
  return dataProduct;
}