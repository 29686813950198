import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { whatsappNumbers } from "../../../../../../models/WABAccount";
import ModalConfirmation from "../../../../../../modules/modal/modalConfirmation";

type Props = {
  id?: string;
  phoneNumbers?: whatsappNumbers[];
};

const WABAAccountRegisteredPhoneNumberCell: FC<Props> = ({
  id,
  phoneNumbers,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <div
        className={clsx(
          `text-gray-700 mb-1`,
          isTabletOrMobile ? "text-start fs-2 fw-bold" : "text-center"
        )}
        // role="button"
        // data-bs-toggle="modal"
        // data-bs-target={`#showphonenumber-${id}`}
      >
        {phoneNumbers && phoneNumbers.length > 0 && phoneNumbers.map((data) => {
          return (
            <div key={data?.id} className="d-flex justify-content-center">
              {data?.phoneNumber}
            </div>
          );
        })}
        {/* {phoneNumbers?.length} Phone Number */}
      </div>
      <ModalConfirmation
        id={`showphonenumber-${id}`}
        title={"Phone Number"}
        body={
          <>
            {phoneNumbers?.map((phoneNumberData) => {
              return (
                <div key={phoneNumberData?.id}>
                  {phoneNumberData?.phoneNumber}
                </div>
              );
            })}
          </>
        }
        confirmOption={""}
      />
    </>
  );
};

export { WABAAccountRegisteredPhoneNumberCell };
