/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  KTSVG,
  initialQueryState,
  useDebounce,
} from "../../../../../../resources/helpers";
import { useFirestoreTable } from "../../context/TableContext";
import { useQueryRequest } from "../../core/QueryRequestProvider";

const TargetListSearchComponent = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  const { updateTableState } = useFirestoreTable();

  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateTableState({
          searchKey: {
            field: "phoneNumber",
            value: debouncedSearchTerm,
          },
        });
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );
  // console.log("Current state: ", state);

  return (
    <div className={clsx("card-title", isTabletOrMobile ? "w-100" : "")}>
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative my-1 w-100">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          data-testid="search-target"
          type="text"
          data-kt-user-table-filter="search"
          className={clsx(
            "form-control form-control-solid ps-14",
            isTabletOrMobile ? "w-100" : "w-250px"
          )}
          placeholder={t("Contacts.Button.SearchUser")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

export { TargetListSearchComponent };
