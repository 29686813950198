/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import clsx from "clsx";
import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { collection, getCountFromServer, query } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Select, { components } from "react-select";
import * as action from "../../actions/playground";
import { incrementNumberOfProductStorefront } from "../../actions/product";
import * as companiesQuery from "../../api/firebase/companies";
import db, { newDB } from "../../db";
import { createRef } from "../../db/connection";
import { converter, converter2 } from "../../db/converter";
import {firebaseConfig} from "../../db";
import { getAllProductList } from "../../db/serviceProduct";
import {
  getListByClient,
  getListWABAccountByClient,
} from "../../db/serviceWABA";
import Loading from "../../resources/helpers/components/Loading";
import Button from "../../styles/components/Button";
import "../../styles/css/requestnotification.css";
import "../../styles/css/select.css";
import { launchEmbeddedSignUp } from "../../util/facebook/action";
import Client from "../models/Client";
import { Shipments } from "../models/Product";
import Template from "../models/Template";
import { User } from "../models/User";
import * as lc from "../modules/localstorage";

import { useQuery } from "react-query";
import SearchFirebase from "./playgrounds/searchFirebase";
import UserTab from "./playgrounds/user";
import { Process } from "../models/Process";
import ProcessTab from "./playgrounds/process";

const getShipmentsByProductID = async (productID: string) => {
  return await db
    .collection("products")
    .doc(productID)
    .collection("shipments")
    .withConverter(converter2<Shipments>())
    .get()
    .then((snaps) => {
      const shipmentList = snaps.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      if (shipmentList.length > 0) {
        return shipmentList;
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      console.log(
        "Error (Server) - getAllShipment in Product Error: " +
          JSON.stringify(error)
      );
      return undefined;
    });
};

export const fetchBrands = (search: string) =>
  db
    .collection("brands")
    .where("isActive", "==", true)
    .orderBy("brandNameInsensitive")
    .orderBy("createdAt")
    .startAt(search.toLowerCase())
    .endAt(search.toLowerCase() + "\uf8ff")
    .limit(20)
    // .orderBy("firstName", 'asc')
    // .startAt("firstName", ">=", search)
    // .endAt("firstName", "<", search + "\uf8ff")
    .get()
    .then((snapshot) => {
      const brands = snapshot.docs.map((doc) => {
        console.log("Brand Name", doc.data().brandNameInsensitive);
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      return brands;
    });

const axiosPost = async () => {
  console.log("axios post");
  const config = {
    headers: {
      Authorization:
        "Bearer $2a$10$SoOcDYU6M6tg7oUe00UVQeCgji/yfRpvYfRqU4H9kIKY1.SEC0c5a",
    },
  };
  await axios
    .post("http://localhost:3001/user/findByPhoneNumber", {
      phoneNumber: "0811303100",
    })
    .then((response) => console.log(response))
    .catch((error) => console.log("axios catch " + error));
  console.log("axios finished");
};

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const getTargetFileFromDB = async (url: string) => {
  console.log(`URL download : ` + url);

  const fileData = await axios({
    method: "get",
    url: url,
    responseType: "document",
  }).then((response) => {
    console.log(`response axios download
    1 >> ${JSON.stringify(response.data)}
    2 >> ${response.status}
    `);
    return response;
  });

  console.log(`response axios download 2
  1 >> ${JSON.stringify(fileData)}
  `);

  return new File([fileData.data], "test.csv");
};

const DeviceTest: FC = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 900px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return (
    <>
      <h3>Device Test!</h3>
      {isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
      {isBigScreen && <p>You have a huge screen</p>}
      {isTabletOrMobile && <p>You are a tablet or mobile phone</p>}
      {/* <p>Your are in {isPortrait ? "portrait" : "landscape"} orientation</p> */}
      {/* {isRetina && <p>You are retina</p>} */}
    </>
  );
};

//handleAddShopee
const { Timestamp } = firebase.firestore;

const Playground: FC = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<any>(null);
  //axiosPost();
  // fetchDataTesting()
  const [haveNotif, setHaveNotif] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [deletedIds, setDeletedIds] = useState<JSX.Element[]>([]);
  const [result, setResult] = useState<JSX.Element>();
  const [input, setInput] = useState<any>();
  const [currentStep, setCurrentStep] = useState(1);
  const [url, setUrl] = useState("");
  const [dataYT, setDataYT] = useState("");
  const [isYTInvalid, setIsYTInvalid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //firebase testing

  const youtubeCheckLink = () => {
    fetch(
      "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=xzNT4JywW0A&format=json"
    )
      .then((response) => response.json())
      .then((json) => setDataYT(json))
      .catch((error) => {
        setIsYTInvalid(true);
        console.log("403/404 Video");
      });
  };

  const requestPermission = () => {
    console.log("Requesting Permission.....");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setHaveNotif(true);
        console.log("Notificaition Permission Granted");
        const firebase = initializeApp(firebaseConfig);
        const msg = getMessaging(firebase);
        getToken(msg, {
          vapidKey:
            "BD1-qOyuBx7dDTKJTHGK7uZzoDMMVtlglOoJsAkovqcxlsfk62wVSklQa5mA5pZ61gNgr4wbCyDEnh62IZK34_0",
        }).then((currentToken) => {
          if (currentToken) {
            console.log("currentToken: ", currentToken);
          } else {
            console.log("cannot get token");
          }
        });
      } else {
        console.log("Do not have permission");
      }
    });
  };
  //end of firebase cm testing

  const deleteMessage = async () => {
    console.log("onlick run");
    action.deleteUnused(count).then((ids) => {
      console.log("finished delete from firebase");
      // const divEle:HTMLDivElement = document.getElementById("result")!;
      const elements = ids.map((id) => <div>{id}</div>);
      setDeletedIds(elements);
    });
  };

  const deleteCollaboration = async () => {
    console.log("onlick run " + count);
    await action.deleteCollaborations(count).then((ids) => {
      console.log("finished delete from firebase");
      // const divEle:HTMLDivElement = document.getElementById("result")!;
      const elements = ids.map((id) => <div>{id}</div>);
      setDeletedIds(elements);
    });
  };

  const deleteErrorMessage = async () => {
    console.log("onlick error run");
    let i = 1;
    action.deleteErrorMessage(count).then((data) => {
      console.log("finished delete from firebase");
      // const divEle:HTMLDivElement = document.getElementById("result")!;
      const elements = data.map((datum) => <div key={i++}>{datum}</div>);
      setDeletedIds(elements);
    });
  };

  const data = [0, 1321, 41512, 2123];

  const find = () => {
    console.log(input);
    const isFound = data.find((value, index, obj) => {
      if (value === input) {
        setResult(<div>found</div>);
        console.log(true);
        return obj;
      }
      console.log(false);
      return undefined;
    });
  };

  const onBackClicked = () => {
    console.log("back clicked " + currentStep);

    if (currentStep === 1) {
      console.log("First Step Page");
      //
    } else {
      setCurrentStep(currentStep - 1);
      document.getElementById(`step_${currentStep}`)?.click();
    }
    console.log("Prev Step: " + currentStep);
  };

  const onContinueClicked = () => {
    console.log("continue clicked, current: " + currentStep);

    if (currentStep === 4) {
      console.log("Last Step Page");
      //
    } else {
      setCurrentStep(currentStep + 1);
      document.getElementById(`step_${currentStep}`)?.click();
    }
    console.log("Next Step: " + currentStep);
  };

  const downloadCSV = async () => {
    const file = await getTargetFileFromDB(
      "https://firebasestorage.googleapis.com/v0/b/days-stagging-5b2b7.appspot.com/o/campaigns%2FtKP63WDpdn8EKMbQsXvK%2Ftarget%2FCampaignTarget-Check-Send-2_23-Sep-2022%5B162036%5D.csv?alt=media&token=a4541888-bcc8-441d-8549-46a34cae72f8"
    );
  };

  useEffect(() => {
    document.getElementById(`step_${currentStep}`)?.click();
  }, [currentStep]);

  const deleteCampaigns = () => {
    alert("function delete campaigns called");
  };

  const [files, setFiles] = useState("");
  const insertShopeeProductCategoryHandler = async (e: any) => {
    const fileReader: any = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e: any) => {
      console.log("e.target.result", e.target.result);
      setFiles(e.target.result);
    };
  };

  const updateCollaborationsToUsertoArraysToUser = () => {
    //get All Data Collaborations
    db.collection("collaborations")
      .get()
      .then((snaps) => {
        console.log("snaps", snaps.docs);

        snaps.docs.forEach((snap) => {
          // ({data: snap.data(), id: snap.id});
          if (snap.data().toUser && !Array.isArray(snap.data().toUser)) {
            db.collection("collaborations")
              .doc(snap.id)
              .update({
                toUser: [snap.data().toUser],
              });
          }
        });
      });

    //update All Collaboration
  };

  const delete50Collab = () => {
    //get All Data Collaborations
    db.collection("collaborations")
      .get()
      .then((snaps) => {
        console.log("snaps", snaps.docs);
        snaps.docs.forEach((snap) => {
          // ({data: snap.data(), id: snap.id});
          if (snap.data().toUser && !Array.isArray(snap.data().toUser)) {
            db.collection("collaborations")
              .doc(snap.id)
              .update({
                toUser: [snap.data().toUser],
              });
          }
        });
      });

    //update All Collaboration
  };

  let [isShopeeProductCategoryExist, setIsShopeeProductCategoryExist] =
    useState<boolean>(false);
  let [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  const handleAddShopee = async (data: any) => {
    setIsSubmitClicked(true);
    let converted: any = JSON.parse(data);
    // check if shopee exist
    const doesDocExist = (docId: string) => {
      return db
        .collection("marketplaceProductCategory")
        .doc(docId)
        .get()
        .then((doc) => {
          return doc.exists;
        });
    };
    //Call the function to check if doc exists:
    let isShopeeExist = await doesDocExist("Shopee");

    if (isShopeeExist) {
      console.log("DATA IS ALREADY EXIST");
      return;
    } else {
      console.log("PERFORM ADD DATA");
      //if not exist, insert
      const categoryList = converted.response.category_list;
      db.collection("marketplaceProductCategory")
        .doc("shopee")
        .set({
          name: "shopee",
          isActive: true,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        })
        .then((docRef: any) => {
          categoryList.forEach((category: any) => {
            let categoryId = category.category_id;
            db.collection("marketplaceProductCategory")
              .doc("shopee")
              .collection("categoryList")
              .doc(categoryId.toString())
              .set(
                {
                  categoryId: category.category_id.toString(),
                  isActive: true,
                  parentCategoryId: category.parent_category_id.toString(),
                  categoryName: {
                    id: category.display_category_name,
                    en: category.original_category_name,
                  },
                  hasChildren: category.has_children,
                  createdAt: Timestamp.now(),
                  updatedAt: Timestamp.now(),
                },
                { merge: true }
              );
          });
        });
    }
  };

  console.log("EXIST STATE", isShopeeProductCategoryExist);
  useEffect(() => {
    db.collection("marketplaceProductCategory")
      .doc("shopee")
      .get()
      .then((doc) => {
        setIsShopeeProductCategoryExist(doc.exists);
      });
  }, [isShopeeProductCategoryExist]);

  const handleToLowerCaseShopee = async () => {
    setIsSubmitClicked(true);
    const getShopeeData = await db
      .collection("marketplaceProductCategory")
      .doc("Shopee")
      .get()
      .then((doc) => {
        console.log("doc : ", doc.data());
        return doc.data();
      });
    const getShopeeCategoryData = await db
      .collection("marketplaceProductCategory")
      .doc("Shopee")
      .collection("categoryList")
      .get()
      .then((snapshot) => {
        const categories = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        return categories;
      });
    if (getShopeeData) {
      console.log("getShopeeData : ", getShopeeData);
      const updateShopee = await db
        .collection("marketplaceProductCategory")
        .doc("shopee")
        .set({
          name: "shopee",
          isActive: true,
          createdAt: getShopeeData.createdAt,
          updatedAt: getShopeeData.updatedAt,
        })
        .then((docRef: any) => {
          console.log("docRef : ", docRef);
          if (getShopeeCategoryData) {
            console.log("getShopeeCategoryData : ", getShopeeCategoryData);
            getShopeeCategoryData.map(async (category: any) => {
              console.log("category : ", category);
              let categoryId = category.id;
              console.log("categoryId : ", categoryId);
              const shopeeCategoryList = await db
                .collection("marketplaceProductCategory")
                .doc("shopee")
                .collection("categoryList")
                .doc(categoryId)
                .set(category);
              console.log("shopeeCategoryList : ", shopeeCategoryList);
            });
          }
        });
      setIsSubmitClicked(false);
    }
  };

  const shipmentDummyArray = [
    {
      name: "JNE",
      displayOrder: 1,
      options: ["Regular", "Instant", "Same Day", "OKE", "JNE Trucking", "YES"],
    },
    {
      name: "AnterAja",
      displayOrder: 2,
      options: ["Regular", "Economy", "Instant", "SameDay", "Next day"],
    },
    {
      name: "Shopee Xpress",
      displayOrder: 3,
      options: [
        "Standart",
        "Ekonomi",
        "Agen Shopee",
        "Instant",
        "Hemat",
        "Point",
      ],
    },
    {
      name: "SiCepat",
      displayOrder: 4,
      options: ["Regular", "Halu", "Gokil", "BEST", "Dropship"],
    },
    {
      name: "J&T",
      displayOrder: 5,
      options: ["Regular", "Economy", "Cargo", "Jemari"],
    },
    {
      name: "Gosend",
      displayOrder: 6,
      options: ["Instant Courier", "SameDay", "Instant Car"],
    },
    {
      name: "Wahana",
      displayOrder: 7,
      options: ["Regular", "Service Normal"],
    },
    {
      name: "GrabExpress",
      displayOrder: 8,
      options: ["Instant", "SameDay"],
    },
    {
      name: "Lion Parcel",
      displayOrder: 9,

      options: ["Regular", "ONEPACK"],
    },
    {
      name: "PAXEL",
      displayOrder: 10,

      options: ["Regular", "MP"],
    },
    {
      name: "ID Express",
      displayOrder: 11,

      options: ["Regular"],
    },
    {
      name: "POS",
      displayOrder: 12,

      options: ["Regular"],
    },
    {
      name: "Ninja",
      displayOrder: 13,

      options: ["Regular"],
    },

    {
      name: "Kurir Rekomendasi",
      displayOrder: 14,

      options: ["Regular"],
    },
    {
      name: "Alfatrex",
      displayOrder: 15,

      options: ["Ambil di Alfamart"],
    },
    {
      name: "TIKI",
      displayOrder: 16,

      options: ["Regular"],
    },
    {
      name: "Indah Logistik",
      displayOrder: 17,

      options: ["Regular"],
    },
    {
      name: "Bluebird Kirim",
      displayOrder: 18,

      options: ["Bluebird Kirim"],
    },
    {
      name: "Rocket Delivery",
      displayOrder: 19,

      options: ["Rocket Delivery"],
    },
  ];

  const clearCollection = (path: string) => {
    const ref = db.collection(path);
    ref.onSnapshot((snapshot) => {
      snapshot.docs.forEach((doc) => {
        ref.doc(doc.id).delete();
      });
    });
  };

  const shipmentsHandler = async () => {
    setIsLoading(true);
    for await (const dummy of shipmentDummyArray) {
      const parent = dummy;
      const childrenList = parent.options;
      //get from firebase if exit not insert
      let getShipment: any = await db
        .collection("shipments")
        .where("name", "==", parent.name)
        .where("isParent", "==", true)
        .get()
        .then((snaps) => {
          const shipment = snaps.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          return shipment;
        })
        .catch((error) => {
          console.log(
            "error create shipment : ",
            parent.name,
            ", error : ",
            error
          );
        });
      console.log("check is exist shipment parent : ", getShipment);
      let shipmentsIdRef: any;
      if (getShipment.length <= 0) {
        console.log("create shipment parent : ", parent.name);
        await db
          .collection("shipments")
          .add({
            name: parent.name,
            isActive: true,
            displayOrder: parent.displayOrder,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            isParent: true,
            shipmentNameInsensitive: parent.name
              .replaceAll(" ", "")
              .toLowerCase(),
          })
          .then((ref) => {
            shipmentsIdRef = ref.id;
          })
          .catch((error) => {
            console.log(
              "error create shipement : ",
              parent.name,
              " error :",
              error
            );
          });
      } else {
        //update add nameInsensitive
        await db
          .collection("shipments")
          .doc(getShipment[0].id)
          .set(
            {
              name: parent.name,
              isActive: true,
              displayOrder: parent.displayOrder,
              updatedAt: Timestamp.now(),
              isParent: true,
              shipmentNameInsensitive: parent.name
                .replaceAll(" ", "")
                .toLowerCase(),
            },
            { merge: true }
          )
          .then((ref) => {
            console.log("success update : ", parent.name);
          })
          .catch((error) => {
            console.log(
              "error update shipments : ",
              getShipment[0].id,
              ", error",
              error
            );
          });
        //delete for shopee express
        await db
          .collection("shipments")
          .where("name", "==", "Shopee Express")
          .where("isParent", "==", true)
          .get()
          .then((snaps) => {
            snaps.docs.forEach((doc) => {
              console.log(`data shipment shopee express : ${doc.id}`);
              doc.ref.delete();
            });
          })
          .catch((error) => {
            console.log(
              "error delete shipment shopee express: ",
              ", error : ",
              error
            );
          });
        //delete grab express children and name not include grabexpress
        await db
          .collection("shipments")
          .where("parentName", "==", "GrabExpress")
          .where("isParent", "==", false)
          .get()
          .then((snaps) => {
            const batch = db.batch();
            snaps.forEach((doc: any) => {
              if (!doc.data().name.toLowerCase().includes("grabexpress")) {
                batch.delete(doc.ref);
              }
            });
            return batch.commit();
          })
          .catch((error) => {
            console.log("error delete grabexpress: ", ", error : ", error);
          });
      }
      console.log("shipmentsIdRef : ", shipmentsIdRef);
    }
    setIsLoading(false);
  };

  const updateNumberOfProductsStorefront = async () => {
    let clientId = "";

    const localClient = lc.getItemLC(lc.LCName.Client);
    if (localClient) {
      clientId = localClient.id;
    }
    const getProducts = await getAllProductList(clientId);
    for (let index = 0; index < getProducts.length; index++) {
      const product = getProducts[index];
      if (product.storeFront && product.storeFront.id) {
        const storefrontID = product.storeFront.id;
        console.log("product.storeFront : ", product.storeFront.id);
        const updateIncrement = await incrementNumberOfProductStorefront(
          storefrontID
        );
      }
    }
  };

  const updateStorefrontStructure = async () => {
    await db
      .collection("storefront")
      .orderBy("marketplacesRegistered")
      .get()
      .then((storefrontSnap) => {
        storefrontSnap.docs.forEach(async (storefrontDoc) => {
          await storefrontDoc.ref
            .collection("marketplaceCategory")
            .get()
            .then((snap) => {
              snap.docs.forEach(async (doc) => {
                if (
                  !doc.data().marketplaces ||
                  doc.data().marketplaces.length <= 0
                ) {
                  return;
                }
                const newData = {
                  account:
                    doc.data().account ??
                    createRef("account", doc.data().marketplaces[0].account.id),
                  marketplaceShopCategoryId:
                    doc.data().marketplaceShopCategoryId ??
                    doc.data().marketplaces[0].marketplaceCategoryID,
                };
                await doc.ref.set(newData, { merge: true });
                console.log(
                  "Succes Update => ",
                  storefrontDoc.data().name,
                  storefrontDoc.id
                );
              });
            });
        });
      });
  };

  const setStorefrontForTest = async (multiAcc: boolean = false) => {
    const dataRegistered = [
      {
        account: createRef("account", "8BRbjmZrANrlX2v5jtGu"),
        shopee: true,
      },
    ];

    if (multiAcc) {
      dataRegistered.push({
        account: createRef("account", "8ztpNj21DA2tkz5xMH1t"),
        shopee: true,
      });
    }

    await db.collection("storefront").doc("pf4gWZ9xlXJqvL4N6Bua").set(
      {
        marketplacesRegistered: dataRegistered,
        status: "requested",
      },
      { merge: true }
    );
    // await db
    //   .collection("storefront")
    //   .doc("pf4gWZ9xlXJqvL4N6Bua")
    //   .collection("marketplaceCategory")
    //   .get()
    //   .then((snap) => {
    //     if (snap.docs.length > 0) {
    //       snap.docs[0].ref.set(
    //         {
    //           account: createRef("account", "8BRbjmZrANrlX2v5jtGu"),
    //           marketplaceCategoryName: "Etalase Hall",
    //           marketplaceCurrentCountItem: 0,
    //           marketplaces: [
    //             {
    //               account: createRef("account", "8BRbjmZrANrlX2v5jtGu"),
    //               marketplaceCategoryID: 6666,
    //             },
    //           ],
    //           marketplaceShopCategoryId: 6666,
    //           status: "requested",
    //         },
    //         { merge: true }
    //       );
    //     }
    //   });

    if (multiAcc) {
      await db
        .collection("storefront")
        .doc("pf4gWZ9xlXJqvL4N6Bua")
        .collection("marketplaceCategory")
        .add({
          account: createRef("account", "8ztpNj21DA2tkz5xMH1t"),
          createdAt: new Date(),
          createdBy: createRef("users", "riEPC29GSsbdrmYRJrzY3ccX9Or2"),
          isChecked: true,
          marketplaceCategoryName: "CatTest123",
          marketplaceCurrentCountItem: 0,
          marketplaces: [
            {
              account: createRef("account", "8ztpNj21DA2tkz5xMH1t"),
              marketplaceCategoryID: 7777,
            },
          ],
          marketplaceShopCategoryId: 7777,
          type: "shopee",
          updatedAt: new Date(),
          updatedBy: createRef("users", "riEPC29GSsbdrmYRJrzY3ccX9Or2"),
        })
        .then((doc) => {
          console.log("New MarketCategoryID", doc.id);
          return doc.id;
        })
        .catch((error: any) => {
          console.error("Error adding document: ", error);
        });
    }

    console.log("Success set storefront");

    // Mrtpx41JJ4XBeokvcQDK
  };

  // const getMarketplaceCategiryById = async () => {
  //   await db
  //     .collectionGroup("marketplaceCategory")
  //     .where("marketplaceShopCategoryId", "==", 3471)
  //     .get()
  //     .then(async (snap) => {
  //       const marketplaceName = snap.docs[0].data().marketplaceCategoryName;
  //       const marketplaceId = snap.docs[0].data().marketplaceShopCategoryId;
  //       const parent: any = snap.docs[0].ref.parent.parent
  //         ? await snap.docs[0].ref.parent.parent.get().then((doc) => {
  //             return {
  //               id: doc.id,
  //               ...doc.data(),
  //             };
  //           })
  //         : undefined;
  //       console.log("Name", marketplaceName);
  //       console.log("ID", marketplaceId);
  //       console.log("parentID", parent?.id);
  //       console.log("parent Name", parent?.name);
  //       snap.docs[0].ref.set(
  //         {
  //           marketplaceCurrentCountItem: firebase.firestore.FieldValue.delete(),
  //         },
  //         { merge: true }
  //       );
  //     });
  // };

  // add multi client on collection customers
  const updateCustomersCollectionMultiClient = async () => {
    setIsLoading(true);
    //get all customers
    //get client by company
    //update customer, delete company and add client by company
    const getCustomers = await db
      .collection("customers")
      .get()
      .then((snaps) => {
        const customer = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return customer;
      })
      .catch((error) => {
        console.log("error get customers collection : ", error);
      });
    const getClientByCompany = await db
      .collection("company")
      .get()
      .then((snaps) => {
        const company = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return company;
      })
      .catch((error) => {
        console.log("error get company collection : ", error);
      });
    if (
      getCustomers &&
      getCustomers.length > 0 &&
      getClientByCompany &&
      getClientByCompany.length > 0
    ) {
      console.log("getCustomers : ", getCustomers[0]);
      getCustomers.forEach(async (customer: any) => {
        //wrong updated, clear field id in customer collection firebase
        const deletedId = await db
          .collection("customers")
          .doc(customer.id!)
          .set(
            {
              id: firebase.firestore.FieldValue.delete(),
            },
            { merge: true }
          )
          .then((ref) => {
            console.log("success delete id : ", customer.id);
          })
          .catch((error) => {
            console.log("error delete id : ", error);
          });

        const getDataCompany: any = getClientByCompany.find(
          (company: any) => company?.id === customer?.company?.id
        );
        console.log("findClientByCompany : ", getDataCompany);
        if (getDataCompany && getDataCompany.client.id) {
          const clientRef = createRef("clients", getDataCompany?.client?.id);
          const companyRef = createRef("company", customer?.company?.id);
          let companies = [];
          if (companyRef) companies.push(companyRef);
          const newCustomerData = {
            // ...customer,
            client: clientRef,
            companies: companies,
            company: firebase.firestore.FieldValue.delete(),
            updatedAt: Timestamp.now(),
          };
          console.log("newCustomerData : ", newCustomerData);
          updateCustomersCollection(newCustomerData, customer.id!);
        }
      });
    }
    setIsLoading(false);
  };

  //update template collection add client
  const updateUsersCollectionMultiClient = async () => {
    //get all templates
    //get client by company
    //update template, delete company and add client by company
    /*
    const users = await db
      .collection("users")
      .withConverter(converter<User>())
      .get()
      .then((snaps)=>{
        const users = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return users;
      }).catch((error)=>{
        console.log("error get users collection : ", error);
        return []
      });
    
    users.forEach((user: User)=>{
      pg.updateUserData(user.id)
    })*/

    action.updateUserData();

    // const companies = await companiesQuery.getAll().then((companies) => {
    //   console.log(`companies : ${JSON.stringify(companies)}`);
    //   return companies
    // });
    // console.log(`getCompanies : ${JSON.stringify(companies)}`);

    /*
    if(users && users.length > 0 && getClientByCompany && getClientByCompany.length > 0){
      console.log("getTemplates : ", getTemplates[0]);
      getTemplates.forEach((template: any)=>{
        const getDataCompany: any = getClientByCompany.find(
          (company: any) => company.id === template?.company?.id
        );
        console.log("findClientByCompany : ", getDataCompany);
        if(getDataCompany && getDataCompany.client.id) {
          const clientRef = createRef("clients", getDataCompany.client.id)
          const companyRef = createRef("company", template.company.id);
          let companies = [];
          if(companyRef) companies.push(companyRef);
          const accountref = createRef("accounts", template.account.id);
          let accounts = [];
          if(accountref) accounts.push(accountref);
          const newTemplateData = {
            ...template,
            client: clientRef,
            clientData: {

            }
          };
          console.log("newTemplateData : ", newTemplateData);
          updateTemplatesCollection(newTemplateData);
        }
      })
    }
    */
  };

  const updateCustomersCollection = async (customerData: any, id: string) => {
    console.log("update customer with new data : ", customerData);
    await db
      .collection("customers")
      .doc(id)
      .set(customerData, { merge: true })
      .then(async (ref) => {
        console.log("success update customers collection");
      })
      .catch((error) => {
        console.log("error update customers add multi client : ", error);
      });
  };

  // add multi client on collection campaign
  const updateCampaignCollectionMultiClient = async () => {
    setIsLoading(true);
    //get all campaigns
    //get client by company
    //update campaign, delete company and add client by company
    const getCampaigns = await db
      .collection("campaigns")
      .get()
      .then((snaps) => {
        const campaign = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return campaign;
      })
      .catch((error) => {
        console.log("error get campaign collection : ", error);
      });
    const getClientByCompany = await db
      .collection("company")
      .get()
      .then((snaps) => {
        const company = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return company;
      })
      .catch((error) => {
        console.log("error get company collection : ", error);
      });
    if (
      getCampaigns &&
      getCampaigns.length > 0 &&
      getClientByCompany &&
      getClientByCompany.length > 0
    ) {
      console.log("getCampaigns : ", getCampaigns[0]);
      getCampaigns.forEach((campaign: any) => {
        const getDataCompany: any = getClientByCompany.find(
          (company: any) => company.id === campaign?.company?.id
        );
        console.log("findClientByCompany : ", getDataCompany);
        if (getDataCompany && getDataCompany.client.id) {
          const clientRef = createRef("clients", getDataCompany?.client?.id);
          const companyRef = createRef("company", campaign?.company?.id);
          let companies = [];
          if (companyRef) companies.push(companyRef);
          const newCampaignData = {
            id: firebase.firestore.FieldValue.delete(),
            client: clientRef,
          };
          console.log("newCampaignData : ", newCampaignData, campaign.id!);
          updateCampaignsCollection(newCampaignData, campaign.id!);
        }
      });
    }
    setIsLoading(false);
  };

  const updateCampaignsCollection = async (campaignData: any, id: any) => {
    console.log("update campaign with new data : ", campaignData);
    await db
      .collection("campaigns")
      .doc(id)
      .set(campaignData, { merge: true })
      .then(async (ref) => {
        console.log("success update campaign collection");
      })
      .catch((error) => {
        console.log("error update campaign add multi client : ", error);
      });
  };

  //update template collection add client
  const updateTemplateCollectionMultiClient = async () => {
    setIsLoading(true);
    //get all templates
    //get client by company
    //update template, delete company and add client by company
    const getTemplates = await db
      .collection("templates")
      .get()
      .then(async (snaps) => {
        const template = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return template;
      })
      .catch((error) => {
        console.log("error get template collection : ", error);
      });
    const getClientByCompany = await db
      .collection("company")
      .get()
      .then((snaps) => {
        const company = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return company;
      })
      .catch((error) => {
        console.log("error get company collection : ", error);
      });
    if (
      getTemplates &&
      getTemplates.length > 0 &&
      getClientByCompany &&
      getClientByCompany.length > 0
    ) {
      getTemplates.forEach(async (template: any) => {
        console.log("template for update : ", template?.id);
        const companyData = template?.company;
        if (companyData && companyData.id) {
          const getDataCompany: any = getClientByCompany.find(
            (company: any) => company?.id === companyData?.id
          );
          console.log("findClientByCompany : ", getDataCompany);
          if (
            getDataCompany &&
            getDataCompany.client &&
            getDataCompany.client.id
          ) {
            const clientRef = createRef("clients", getDataCompany?.client?.id);
            const companyRef = createRef("company", template?.company?.id);
            let companies = [];
            if (companyRef) companies.push(companyRef);
            const accountref = createRef("accounts", template?.account?.id);
            let accounts = [];
            if (accountref) accounts.push(accountref);

            const newTemplateData = {
              //detele field id firebase
              id: firebase.firestore.FieldValue.delete(),
              client: clientRef,
              accounts: accounts,
              companies: companies,
            };
            console.log("newTemplateData : ", newTemplateData);
            await updateTemplatesCollection(newTemplateData, template.id!);
          }
        }
      });
    }
    setIsLoading(false);
  };

  const updateTemplatesCollection = async (templateData: any, id: any) => {
    console.log("update template with new data : ", templateData);
    await db
      .collection("templates")
      .doc(id)
      .set(templateData, { merge: true })
      .then(async (ref) => {
        console.log("success update template collection");
      })
      .catch((error) => {
        console.log("error update template add multi client : ", error);
      });
  };

  //update collaborations collection add multi client
  const updateMessagesCollectionMultiClient = async () => {
    setIsLoading(true);
    const getCollaborations = await db
      .collection("messages")
      .get()
      .then(async (snaps: any) => {
        snaps.docs.forEach(async (snap: any) => {
          if (snap.data().company && !snap.data.client) {
            const companyID: string = snap.data().company.id;
            //get company data
            const getCompanyData: any = await getCompanyByID(companyID);
            console.log(`client in company : ${getCompanyData?.client?.id}`);
            if (getCompanyData && getCompanyData.client) {
              const clientRef = createRef(
                "clients",
                getCompanyData?.client?.id
              );
              db.collection("messages")
                .doc(snap.id)
                .update({
                  client: clientRef,
                })
                .then(() => {
                  console.log(`success add client on message : ${snap.id}`);
                })
                .catch((error) => {
                  console.log(`error add client on message : ${snap.id}`);
                });
            }
          }
        });
      })
      .catch((error) => {
        console.log("error get messages collection : ", error);
      });
    setIsLoading(false);
  };

  //update collaborations collection add multi client
  const updateCollaborationsCollectionMultiClient = async () => {
    setIsLoading(true);
    const getCollaborations = await db
      .collection("collaborations")
      .get()
      .then(async (snaps: any) => {
        snaps.docs.forEach(async (snap: any) => {
          if (snap.data().company && !snap.data.client) {
            const companyID: string = snap.data().company.id;
            //get company data
            const getCompanyData: any = await getCompanyByID(companyID);
            console.log(`client in company : ${getCompanyData?.client?.id}`);
            if (getCompanyData && getCompanyData.client) {
              const clientRef = createRef(
                "clients",
                getCompanyData?.client?.id
              );
              db.collection("collaborations")
                .doc(snap.id)
                .update({
                  client: clientRef,
                })
                .then(() => {
                  console.log(
                    `success add client on collaboration : ${snap.id}`
                  );
                })
                .catch((error) => {
                  console.log(`error add client on collaboration : ${snap.id}`);
                });
            }
          }
        });
      })
      .catch((error) => {
        console.log("error get collaborations collection : ", error);
      });
    setIsLoading(false);
  };

  const getCompanyByID = async (companyId: any) => {
    return await db
      .collection("company")
      .doc(companyId)
      .get()
      .then((snaps: any) => {
        console.log(`snaps : ${snaps.data()}`);
        return snaps.data();
      })
      .catch((error) => {
        console.log("error get company data : ", error);
      });
  };

  const CustomMenu = () => (
    <div className="customReactSelectMenu">
      <button
        className="btn btn-info btn-sm btn-block"
        onClick={() => console.log("HEY SUSSSSCESS")}
      >
        Add New
      </button>
    </div>
  );

  const customMenu = (props: any) => {
    return (
      <components.Menu {...props}>
        <div>
          {props.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props.children}</div>
          )}
          <button
            className={"change-data"}
            onClick={props.selectProps.changeOptionsData}
          >
            Change data
          </button>
        </div>
      </components.Menu>
    );
  };
  const Menu = (props: any) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div>
            {props.selectProps.fetchingData ? (
              <span className="fetching">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className={"change-data"}
              onClick={props.selectProps.changeOptionsData}
            >
              Change data
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const getShipmentData = async () => {
    const shipmentsData = await getShipmentsByProductID("vbJWJbTC8yKQa7XYKP3x");
    let shipmentListID: any[] = [];
    if (shipmentsData && shipmentsData.length > 0) {
      for (const shipmentData of shipmentsData) {
        console.log("masuk looping");
        let typeShipment = shipmentData.types!;
        // console.log("isi dari: "+ JSON.stringify(typeShipment) + "length nya: ", typeShipment.length)
        for (const tshipment of typeShipment) {
          console.log("isi dari data per types: ", tshipment);
          const typeShipmentID = tshipment.shipmentID?.id;
          shipmentListID.push(typeShipmentID);
          console.log("isi dari typeshipment id: ", typeShipmentID);
        }
      }
      console.log("isi dari shipmentListID: ", shipmentListID);
    } else {
      //TODO error log
    }
  };
  const sessionInfoListener = (event: any) => {
    if (event.origin !== "https://www.facebook.com") return;

    console.log(`sessionInfoListener ${JSON.stringify(event)}`);

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          const { phoneID, wabaID } = data.data;
          console.log(`data get from on finish: `, phoneID, wabaID);
        }
        //...
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log("Non JSON Response", event.data);
    }
  };

  const updateTemplateCategory = async () => {
    const getTemplateByCategory = await db
      .collection("templates")
      .where("category", "==", "transactional")
      .get()
      .then(async (snaps) => {
        const template = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return template;
      })
      .catch((error) => {
        console.log("error get template collection : ", error);
      });
    if (getTemplateByCategory && getTemplateByCategory.length > 0) {
      for (let index = 0; index < getTemplateByCategory.length; index++) {
        const template = getTemplateByCategory[index];
        console.log(`template `, template);
        const newTemplateData = { ...template, category: "utility" };
        const { id, ...newTemplateDataWithoutId } = newTemplateData;
        console.log(`newTemplateDataWithoutId`, newTemplateDataWithoutId);
        await updateTemplatesCollection(newTemplateDataWithoutId, template.id);
      }
    }
  };

  const getOrderByTimeRange = async (
    from: Date,
    to: Date,
    accountId: string,
    status?: string[]
  ) => {
    console.log("getOrderByTimeRange", from, to, accountId);
    let query = db
      .collection("orders")
      .where("account", "==", createRef("account", accountId))
      .where("marketplaceOrderCreatedAt", ">=", from)
      .where("marketplaceOrderCreatedAt", "<=", to);

    if (status) {
      query = query.where("status", "in", status);
    }

    return await query
      .get()
      .then((snap) => {
        if (snap.docs.length > 0) {
          const dataList = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log(dataList);
          return dataList;
        } else {
          console.log("getOrderByTimeRange No such document!");
          return undefined;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const updateOrderMarketplaceCreatedAt = async () => {
    return await db
      .collection("orders")
      .get()
      .then((snap) => {
        if (snap.docs.length > 0) {
          snap.docs.forEach(async (doc) => {
            const dataOrder = doc.data();
            if (dataOrder.marketpalceOrderCreatedAt) {
              await doc.ref.set(
                {
                  marketplaceOrderCreatedAt:
                    dataOrder.marketpalceOrderCreatedAt,
                  marketpalceOrderCreatedAt:
                    firebase.firestore.FieldValue.delete(),
                },
                { merge: true }
              );
            }

            console.log(doc.data());
          });
        }
      });
  };

  const updateTemplateCategoryOTP = async () => {
    setIsLoading(true);
    const getTemplateByCategory: any = await db
      .collection("templates")
      .where("category", "==", "otp")
      .get()
      .then(async (snaps) => {
        const template = snaps.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return template;
      })
      .catch((error) => {
        console.log("error get template collection : ", error);
      });
    if (getTemplateByCategory && getTemplateByCategory.length > 0) {
      for (let index = 0; index < getTemplateByCategory.length; index++) {
        const template = getTemplateByCategory[index];
        console.log(`template `, template);
        const newTemplateData = { ...template, category: "authentication" };
        const { id, ...newTemplateDataWithoutId } = newTemplateData;
        console.log(`newTemplateDataWithoutId`, newTemplateDataWithoutId);
        await updateTemplatesCollection(newTemplateDataWithoutId, template.id);
      }
    }
    setIsLoading(false);
  };

  const getProductBySkuOrParentSkuOrVariantSku = async (sku: string) => {
    try {
      const withParentSku = db
        .collection("products")
        .where("parentSKU", "==", sku)
        .get();
      const withSku = db.collection("products").where("sku", "==", sku).get();
      const withVariantSku = db
        .collectionGroup("productvariants")
        .where("sku", "==", sku)
        .get();

      const [parentSkulSnap, skuSnap, variantSkuSnap] = await Promise.all([
        withParentSku,
        withSku,
        withVariantSku,
      ]);
      const parentData = parentSkulSnap.docs.length
        ? parentSkulSnap.docs.map((doc) => ({ ...doc.data() }))
        : [];
      const skuData = skuSnap.docs.length
        ? skuSnap.docs.map((doc) => ({ ...doc.data() }))
        : [];
      const variantData = variantSkuSnap.docs.length
        ? variantSkuSnap.docs.map((doc) => ({ ...doc.data() }))
        : [];

      return [...parentData, ...skuData, ...variantData];
    } catch (error: any) {
      console.log(error.toString ? error.toString() : error);
    }
  };

  // window.addEventListener("message", sessionInfoListener);

  return (
    <>
      {/* <!-- partial:index.partial.html --> */}
      {false && (
        <div
          id="onesignal-popover-container"
          className="onesignal-popover-container onesignal-reset slide-down"
        >
          <div
            id="onesignal-popover-dialog"
            className="onesignal-popover-dialog"
          >
            <div id="normal-popover">
              <div className="popover-body">
                <div className="popover-body-icon">
                  <img
                    alt="notification icon"
                    className=""
                    src="https://s3-us-west-1.amazonaws.com/sfc-wordpress2/wp-content/uploads/2017/12/01190813/press-stamp-231x230.png"
                  />
                </div>
                <div className="popover-body-message">
                  We'd like to show you notifications for the latest news and
                  updates.
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="popover-footer">
                <button
                  id="onesignal-popover-allow-button"
                  className="align-right primary popover-button"
                  onClick={() => requestPermission()}
                >
                  Allow
                </button>
                <button
                  id="onesignal-popover-cancel-button"
                  className="align-right secondary popover-button"
                >
                  No Thanks
                </button>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- partial --> */}

      {/* Create Campaign Step */}
      <div className="card mb-1 mb-xl-2 p-3">
        <div>
          {/* Step Tab */}
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
                id="step_1"
              >
                Setup Campaign
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
                id="step_2"
              >
                Search Firebase
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
                id="step_3"
              >
                Target
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
                id="step_4"
              >
                User
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
                id="step_5"
              >
                Setup Marketplace Product Category
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_6"
                id="step_6"
              >
                Setup Product Shipping
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_7"
                id="step_7"
              >
                Setup Storefront List & Search Brand
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_8"
                id="step_8"
              >
                Setup Multi Client
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_9"
                id="step_9"
              >
                FB EMBEDDED SIGNUP
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_10"
                id="step_10"
              >
                Update Storefront Structure
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_11"
                id="step_11"
              >
                Update Transactional to Utility
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_12"
                id="step_12"
              >
                Update OTP to Authentication
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_13"
                id="step_13"
              >
                Orders
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_14"
                id="step_14"
              >
                Product
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_15"
                id="step_15"
              >
                Process
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_16"
                id="step_16"
              >
                Realtime DB
              </a>
            </li>
          </ul>
          {/* Step Details */}
          <div className="tab-content" id="myTabContent">
            {/* Step 1 */}
            <div className="tab-pane fade" id="kt_tab_pane_1" role="tabpanel">
              Step 1
              <div
                className="fw-bold fs-3 text-gray-400 mb-15"
                data-testid="faq-page"
              >
                <h2>Playground Page - {process.env.NODE_ENV} </h2>
                <DeviceTest />

                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Launch demo modal
                </button>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Modal title
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">...</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="button" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  name="input"
                  type="input"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setInput(e.target.value);
                  }}
                  value={input}
                />
                <div>
                  <Button
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={find}
                  >
                    Find
                  </Button>
                </div>
                <div>
                  <input
                    name="removeNData"
                    type="input"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCount(parseInt(e.target.value));
                    }}
                  />
                  <Button
                    id="btnError"
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={deleteMessage}
                  >
                    Delete Unused Message
                  </Button>

                  <Button
                    id="btnError"
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={deleteErrorMessage}
                  >
                    Delete Error Message
                  </Button>
                </div>

                <div>
                  <Button
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={deleteCampaigns}
                  >
                    Delete 200 Campaign
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={updateCollaborationsToUsertoArraysToUser}
                  >
                    Update User collaboration to Array User
                  </Button>
                </div>
                <div>
                  <input
                    name="removeCollabs"
                    type="input"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCount(parseInt(e.target.value));
                    }}
                  />
                  <Button
                    id="btnDeleteCollabs"
                    className="btn btn-lg btn-primary fw-bolder"
                    onClick={deleteCollaboration}
                  >
                    Delete Collaborations
                  </Button>
                </div>
              </div>
              <div id="result">
                {deletedIds}
                {result}
              </div>
              <div>
                <div className="App">
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    components={{ Menu: Menu }}
                  />
                </div>
              </div>
              {/* <div style={{position: "relative"}}>
        <UploadImageThumbnail />
      </div> */}
              <div style={{ position: "relative" }}>
                <button
                  id="onesignal-popover-allow-button"
                  className="align-right primary popover-button"
                  onClick={() => youtubeCheckLink()}
                >
                  GET YT VALIDATION
                </button>
                <button
                  id="onesignal-popover-allow-button"
                  className="align-right primary popover-button"
                  onClick={() => getShipmentData()}
                >
                  GET SHIPMENT DATA
                </button>
                <p>HAHAHHA{JSON.stringify(dataYT)}</p>
                <p>{isYTInvalid && "ERROR"}</p>
              </div>
            </div>
            {/* Step 2 */}
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <SearchFirebase />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <div className="d-flex space mb-5 mt-10">
                <input
                  value={url}
                  type="text"
                  onChange={(e) => e.target.value}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={downloadCSV}
                >
                  Download
                </button>
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
              <UserTab />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
              <div>
                <h1>Shopee</h1>
                <input
                  type="file"
                  accept="application/json"
                  onChange={insertShopeeProductCategoryHandler}
                />
                <button
                  className={clsx("btn btn-primary mp-3", { disabled: !files })}
                  onClick={() => (files ? handleAddShopee(files) : "")}
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  className={clsx("btn btn-primary mp-3")}
                  onClick={() => handleToLowerCaseShopee()}
                >
                  Update Shopee To shopee
                </button>
              </div>
              <div>
                {isShopeeProductCategoryExist && isSubmitClicked && (
                  <p>Shopee Product Category is Already Exist</p>
                )}
                {/* {
                  !isShopeeProductCategoryExist && (
                    <p>Shopee Product Category Successfully Added</p>
                    )
                } */}
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => shipmentsHandler()}
              >
                Click Here to create shipments collection
              </Button>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_7" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => updateNumberOfProductsStorefront()}
              >
                Click Here to update number of products storefront
              </Button>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => updateCustomersCollectionMultiClient()}
              >
                Add Client on Collection Customer
              </Button>
              <br />
              <Button
                className="btn btn-lg btn-primary fw-bolder mt-10"
                onClick={() => updateCampaignCollectionMultiClient()}
              >
                Add Client on Collection Campaigns
              </Button>
              <br />
              <Button
                className="btn btn-lg btn-primary fw-bolder mt-10"
                onClick={() => updateTemplateCollectionMultiClient()}
              >
                Add Client on Collection Templates
              </Button>
              <br />
              <Button
                className="btn btn-lg btn-primary fw-bolder mt-10"
                onClick={() => updateMessagesCollectionMultiClient()}
              >
                Add Client on Collection Message
              </Button>
              <br />
              <Button
                className="btn btn-lg btn-primary fw-bolder mt-10"
                onClick={() => updateCollaborationsCollectionMultiClient()}
              >
                Add Client on Collection Collaboration
              </Button>

              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => updateUsersCollectionMultiClient()}
              >
                Add Client on Collection Users
              </Button>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => launchEmbeddedSignUp("amx")}
              >
                FB LOGIN!
              </Button>
              <br />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_10" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder me-2"
                onClick={() => updateStorefrontStructure()}
              >
                Update Structure
              </Button>
              <Button
                className="btn btn-lg btn-primary fw-bolder me-2"
                onClick={() => setStorefrontForTest()}
              >
                setStorefrontForTest
              </Button>
              <Button
                className="btn btn-lg btn-primary fw-bolder me-2"
                onClick={() => setStorefrontForTest(true)}
              >
                setStorefrontForTestMultiAcc
              </Button>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_11" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => updateTemplateCategory()}
              >
                Update Template Category Transactional to Utility
              </Button>
              <br />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_12" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={() => updateTemplateCategoryOTP()}
              >
                Update Template Category OTP to Authentication
              </Button>
              <br />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_13" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder me-4"
                onClick={async () => {
                  await getOrderByTimeRange(
                    new Date("2022-09-01"),
                    new Date(),
                    "8BRbjmZrANrlX2v5jtGu"
                  );
                }}
              >
                Get Order Time Range Without Status
              </Button>
              <Button
                className="btn btn-lg btn-primary fw-bolder me-4"
                onClick={async () => {
                  await getOrderByTimeRange(
                    new Date("2022-09-01"),
                    new Date(),
                    "8BRbjmZrANrlX2v5jtGu",
                    ["COMPLETED", "PROCESSED"]
                  );
                }}
              >
                Get Order Time Range With Status
              </Button>
              <Button
                className="btn btn-lg btn-primary fw-bolder me-4"
                onClick={async () => {
                  const q = query(collection(newDB, "orders"));
                  const countOrder = (await getCountFromServer(q)).data().count;

                  console.log("Count Order", countOrder);
                }}
              >
                Try Count
              </Button>
              <Button
                className="btn btn-lg btn-primary fw-bolder me-4"
                onClick={async () => {
                  await updateOrderMarketplaceCreatedAt();
                }}
              >
                Update Order marketplaceCreatedAt
              </Button>
              <div className="mt-4">
                <input
                  className="form-input"
                  type="text"
                  id="searchBrandInput"
                />
                <Button
                  className="btn btn-lg btn-primary fw-bolder"
                  onClick={() => {
                    const input = document.getElementById(
                      "searchBrandInput"
                    ) as HTMLInputElement;
                    const value = input.value;
                    fetchBrands(value);
                  }}
                  type="button"
                >
                  Search Brand
                </Button>
              </div>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_14" role="tabpanel">
              <Button
                className="btn btn-lg btn-primary fw-bolder"
                onClick={async () => {
                  const data = await getProductBySkuOrParentSkuOrVariantSku(
                    "12345811"
                  );
                  console.log(data);
                }}
              >
                Get Product or Variant SKU
              </Button>
              <br />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_15" role="tabpanel">
                <ProcessTab/>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_16" role="tabpanel">
              <RealtimeDBAccount />
            </div>
          </div>
        </div>
        <div className="d-flex space mb-5 mt-10">
          <button
            type="button"
            className="btn btn-primary"
            onClick={onBackClicked}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onContinueClicked}
          >
            Continue
          </button>
        </div>
      </div>
      <div></div>
      {isLoading && <Loading />}
    </>
  );
};

const realtimeDB = firebase.database();
const updateCount = async (accountID: string) => {
  const existingData = await realtimeDB
    .ref("account/" + accountID)
    .get()
    .then((snap) => (snap.exists() ? snap.val() : undefined));
  if (existingData && existingData.count) {
    realtimeDB
      .ref("account/" + accountID)
      .set({ count: existingData.count + 1 });
  } else {
    realtimeDB.ref("account/" + accountID).set({ count: 1 });
  }
};

const updateCount2 = async (accountID: string) => {
  const existingData = await realtimeDB
    .ref("account/" + accountID)
    .get()
    .then((snap) => (snap.exists() ? snap.val() : undefined));
  if (existingData && existingData.count) {
    realtimeDB.ref("account/" + accountID + "/count").transaction((data) => {
      return data + 1;
    });
  } else {
    realtimeDB.ref("account/" + accountID + "/count").set(1);
  }
};

const updateBalance = async (accountID: string, amount: number) => {
  const existingData = await realtimeDB
    .ref("account/" + accountID)
    .get()
    .then((snap) => (snap.exists() ? snap.val() : undefined));
  if (existingData && existingData.balance) {
    realtimeDB.ref("account/" + accountID + "/balance").transaction((data) => {
      return data + amount;
    });
  } else {
    realtimeDB.ref("account/" + accountID + "/balance").set(amount);
  }
};

const addArrayObject = (accountID: string, data?: any) => {
  data = "oakwokawokaowk";
  realtimeDB
    .ref("account/" + accountID + "/note")
    .transaction((currentData) => {
      currentData = currentData || [];
      currentData.push(data);
      return currentData;
    });
};

function RealtimeDBAccount() {
  const clientID = lc.getItemLC(lc.LCName.Client).id;
  const { data: accounts } = useQuery(["waba-account", clientID], () =>
    getListByClient(createRef("clients", clientID))
  );
  const [selectedAccount, setSelectedAccount] = useState<string>("");

  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const [balanceInput, setBalanceInput] = useState<string>("");
  const [lastNotes, setLastNotes] = useState<string[]>([]);

  // console.log(setBalanceInput);
  const handleAddCount = async (accountID: string) => {
    if (isLoading) return;

    setIsLoading(true);
    await updateCount(accountID);
    setIsLoading(false);
  };

  const handleAddCount2 = async (accountID: string) => {
    if (isLoading) return;

    setIsLoading(true);
    await updateCount2(accountID);
    setIsLoading(false);
  };

  const handleTopUp = async (accountID: string, topUpAmount: any) => {
    console.log(topUpAmount);
    if (isLoading) return;
    setIsLoading(true);
    await updateBalance(accountID, topUpAmount);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedAccount || selectedAccount === "") {
      setCount(0);
      setBalance(0);
      setBalanceInput("");
      return;
    }
    const handleDataChange = (snap: firebase.database.DataSnapshot) => {
      setIsLoading(true);
      const data = snap.val();
      if (data && data.count) {
        setCount(data.count);
      } else {
        setCount(0);
      }
      if (data && data.balance) {
        setBalance(data.balance);
      } else {
        setBalance(0);
      }
      if (data && data.note) {
        setLastNotes(data.note);
      } else {
        setLastNotes(["NO NOTES YET"]);
      }
      setIsLoading(false);
    };

    realtimeDB.ref("account/" + selectedAccount).on("value", handleDataChange);
    return () => {
      realtimeDB
        .ref("account/" + selectedAccount)
        .off("value", handleDataChange);
    };
  }, [selectedAccount]);

  if (!accounts) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex align-items-start" style={{ gap: "1rem" }}>
      <div>
        <select
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          className="form-select"
          name="account"
          id="account"
        >
          <option value="">Select Account</option>
          {accounts.map((account) => (
            <option key={account.id} value={account.id}>
              {account.businessName ?? account.id}
            </option>
          ))}
        </select>
        <p className="mt-3 px-4 fs-1">
          Count:{" "}
          {isLoading ? (
            <span className="fs-6">Processing...</span>
          ) : (
            <span className="fw-bolder">{count}</span>
          )}
        </p>
      </div>
      <div>
        <button
          onClick={async () => await handleAddCount(selectedAccount)}
          type="button"
          disabled={selectedAccount === "" || isLoading}
          className="btn btn-lg btn-primary"
        >
          Add Count
        </button>
        <button
          onClick={async () => await handleAddCount2(selectedAccount)}
          type="button"
          disabled={selectedAccount === "" || isLoading}
          className="btn btn-lg btn-primary"
        >
          Add Count 2
        </button>
      </div>
      <div>
        <p className="mt-3 px-4 fs-1">
          Balance:{" "}
          {isLoading ? (
            <span className="fs-6">Processing...</span>
          ) : (
            <span className="fw-bolder">{balance}</span>
          )}
        </p>
        <div className="mt-3 px-4">
          <input
            type="number"
            value={balanceInput}
            onChange={(e) => setBalanceInput(e.target.value)}
            className="form-control"
            placeholder="Enter balance amount"
          />
          <button
            onClick={async () =>
              await handleTopUp(selectedAccount, parseFloat(balanceInput))
            }
            type="button"
            disabled={
              selectedAccount === "" || balanceInput === "" || isLoading
            }
            className="btn btn-primary mt-2"
          >
            Update Balance
          </button>
        </div>
      </div>
      <div>
        <div className="mt-3 px-4">
          <p>Last 3 Notes:</p>
          {lastNotes.slice(-3).map((note, index) => (
            <div key={index}>{`${index}.  ${note}`}</div>
          ))}
          <button
            onClick={() => addArrayObject(selectedAccount)}
            type="button"
            disabled={selectedAccount === "" || isLoading}
            className="btn btn-primary mt-2"
          >
            Update Note
          </button>
        </div>
      </div>
    </div>
  );
}

export { Playground };
