//custom ReactSelect
//https://stackoverflow.com/questions/66597232/properly-use-nooptionsmessage-in-react-select-to-change-the-no-options-text
//TODO replace all react-select with custom
import ReactSelect, { 
  OptionsOrGroups, 
  ActionMeta,
  Options,
  components } from "react-select"; 


type Option = {
  value: string;
  label: string;
}

const CustomSelect = (props: any) => {

  if (!props.allowSelectAll) return <ReactSelect {...props} />

  let allOption: Option ;
  if (props.allOption)
    allOption = props.allOption
  else 
    allOption = {value: "ALL", label: ""}

  const MultiValue = (props: any) => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === allOption.value) {
      labelToBeDisplayed = "All is selected";
    }
    return (
      <components.MultiValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  };
  
  
  const onChange = (selected: readonly Option[], event: ActionMeta<Option>) => {
    if (!selected || selected.length === 0) return props.onChange(selected);

      if (selected[selected.length - 1].value === props.allOption.value) {
        return props.onChange([allOption, ...props.options]);
      }
      let result: Option[] = [];
      if (selected.length === props.options.length) {
        if (selected.includes(allOption)) {
          result = selected.filter(
            option => option.value !== allOption.value
          );
        } else if (event.action === "select-option") {
          result = [allOption, ...props.options];
        }
        return props.onChange(result);
      }
  }

  return <ReactSelect 
    {...props}
    style = {props.style}
    options = {[allOption, ...props.option]}
    onChange={onChange}
    components = {{MultiValue}}
  />
}

export default CustomSelect