import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Customer from "../../models/Customer"
import * as customerServ from "../../../db/serviceCustomer"
import { RootState } from "../../../setup/redux/store";
import { PageLink, PageTitle } from "../../layout/core";
import CountryList from "../../lists/CountryList";
import GenderList from "../../lists/GenderList";
import MaritalStatus from "../../lists/MaritalStatusList";
import * as ReduxContact from "../contact/redux/ContactSlice";
import { Loading } from "../util/Loading";
import { ContactHeader } from "./ContactHeader";
import { Overview } from "./components/Overview";
import { Settings } from "./components/settings/Settings";

type Props = {
  id: string;
  name: string;
  phoneNumber?: string;
};

const ContactDetailPage: React.FC = () => {
  const location = useLocation();
  const data = location.state as Props;
  console.log("Data : ", data);
  console.log(`account page`);
  const { t } = useTranslation();
  // const [contactData, setContactData] = useState({});
  let dataContact: Customer;
  const [title, setTitle] = useState(data?.name);
  //get redux customerData
  const rd_customerData = useSelector(
    (state: RootState) => state.Contact.contactData
  );

  const [isRefresh, setIsRefresh] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("CHECK => in contact detail : ", data.id);
    getCustomerDetail();
    setIsRefresh(true);
  }, []);

  useEffect(() => {
    //set timeout
    setTimeout(() => {
      setIsRefresh(false);
    }, 300);
  }, [isRefresh === true]);

  const getCustomerDetail = async () => {
    if (data.id) {
      await customerServ.get(data?.id).then((doc) => {
        // console.log("get customer by id " + JSON.stringify(doc));
        let dataContact = doc as Customer;
        console.log("SelectedContact ax", dataContact);
        setTitle(
          dataContact.firstName! +
            " " +
            (dataContact.lastName ? dataContact.lastName : "")
        );
        if (dataContact.maritalStatus !== "") {
          const maritalStatus = generateSelectedMaritalStatus(
            dataContact.maritalStatus!
          );
          dataContact.selectedMaritalStatus = maritalStatus;
        }
        if (dataContact.gender !== "") {
          const gender = generateSelectedGender(dataContact.gender!);
          dataContact.selectedGender = gender;
        }
        if (dataContact.country !== "") {
          const country = generateSelectedCountry(dataContact.country!);
          dataContact.selectedCountry = country;
        }
        // social media
        if(dataContact.facebook?.length !== 0 || dataContact.facebook !== undefined){
          const newFacebook = generateSelectedSocialMedia(dataContact.facebook!);
          dataContact.selectedFacebook = newFacebook;
        }
        if(dataContact.instagram?.length !== 0 || dataContact.instagram !== undefined){
          const newInstagram = generateSelectedSocialMedia(dataContact.instagram!);
          dataContact.selectedInstagram = newInstagram;
        }
        if(dataContact.tiktok?.length !== 0 || dataContact.tiktok !== undefined){
          const newTiktok = generateSelectedSocialMedia(dataContact.tiktok!);
          dataContact.selectedTiktok = newTiktok;
        }
        if(dataContact.shopee?.length !== 0 || dataContact.shopee !== undefined){
          const newShopee = generateSelectedSocialMedia(dataContact.shopee!);
          dataContact.selectedShopee = newShopee;
        }
        if(dataContact.tokopedia?.length !== 0 || dataContact.tokopedia !== undefined){
          const newTokopedia = generateSelectedSocialMedia(dataContact.tokopedia!);
          dataContact.selectedTokopedia = newTokopedia;
        }
        if(dataContact.lazada?.length !== 0 || dataContact.lazada !== undefined){
          const newLazada = generateSelectedSocialMedia(dataContact.lazada!);
          dataContact.selectedLazada = newLazada;
        }
        if(dataContact.bukalapak?.length !== 0 || dataContact.bukalapak !== undefined){
          const newBukalapak = generateSelectedSocialMedia(dataContact.bukalapak!);
          dataContact.selectedBukalapak = newBukalapak;
        }
        if(dataContact.blibli?.length !== 0 || dataContact.blibli !== undefined){
          const newBlibli = generateSelectedSocialMedia(dataContact.blibli!);
          dataContact.selectedBlibli = newBlibli;
        }
        // end social media
        if (data.phoneNumber) {
          dataContact.phoneNumber = data.phoneNumber;
        }
        // setContactData(dataContact);
        dispatch(ReduxContact.setContactData(dataContact));
        // console.log("Test : " + JSON.stringify(contactData));
      });
    }
  };

  useEffect(() => {
    console.log("on change customer data : ", rd_customerData.id === data.id);
    if (rd_customerData.id === data.id) {
      console.log("customer found with id : ", rd_customerData.id);
      // setContactData(rd_customerData);
      setTitle(
        rd_customerData.firstName! +
          " " +
          (rd_customerData.lastName ? rd_customerData.lastName : "")
      );
    }
  }, [rd_customerData]);

  const generateSelectedMaritalStatus = (maritalStatus: string) => {
    const findMaritalStatus = MaritalStatus.find((item) => {
      if (item.value.toLowerCase() === maritalStatus?.toLowerCase()) {
        return item;
      }
      return undefined;
    });
    console.log("check data findMaritalStatus : ", findMaritalStatus);
    if (findMaritalStatus) {
      return {
        value: findMaritalStatus.value,
        label: t(findMaritalStatus.label),
      };
    }
  };

  const generateSelectedGender = (gender: string) => {
    const findGender = GenderList.find((item) => {
      if (item.value.toLowerCase() === gender?.toLowerCase()) {
        return item;
      }
      return undefined;
    });
    console.log("check data findGender : ", findGender);
    if (findGender) {
      return {
        value: findGender.value,
        label: t(findGender.label),
      };
    }
  };

  const generateSelectedSocialMedia = (data: any[]) => {
    if (Array.isArray(data)) {
      const selectedValueForLabel = data.map((value) => ({ value, label: value }));
      console.log("check data selectedFacebook: ", selectedValueForLabel);
      return selectedValueForLabel;
    } else {
      console.error("Data is not an array:", data);
      return [];
    }
  };
  

  const generateSelectedCountry = (country: string) => {
    const findCountry = CountryList.find((item) => {
      if (item.value.toLowerCase() === country?.toLowerCase()) {
        return item;
      }
      return undefined;
    });
    console.log("check data findGender : ", findCountry);
    if (findCountry) {
      return {
        value: findCountry.value,
        label: t(findCountry.label),
      };
    }
  };

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: t("SideBar.MenuItem.Contacts"),
      path: "contact/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    //  <>
    //   <PageTitle breadcrumbs={accountBreadCrumbs}>{data.name}</PageTitle>
    //   <AccountHeader customer={contactData}/>
    //   {currentActivity ==='overview' ? <Overview customer={contactData} /> : <></>}
    //   {currentActivity ==='settings' ? <Settings customer={contactData} /> : <></>}
    // </>
    <Routes>
      <Route
        element={
          <>
            <ContactHeader customer={rd_customerData} />
            <Outlet />
          </>
        }
      >
        <Route
          path="overview/*"
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
              <Overview customer={rd_customerData} />
            </>
          }
        />
        <Route
          path="settings/*"
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
              {!isRefresh && <Settings customer={rd_customerData} />}
              {isRefresh && <Loading />}
            </>
          }
        />
        <Route index element={<Navigate to="contact/list" />} />
      </Route>
    </Routes>
  );
};

export default ContactDetailPage;
