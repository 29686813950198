import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useFirestoreTable } from "../context/TableContext";
import { isSameStatusValue } from "../../../../../order/process-order/ProcessPage";

interface IOptions {
  value: string | string[];
  label: string;
}

const MemberStatusFilter: React.FC = () => {
  const { t } = useTranslation();
  const isLaptopView = useMediaQuery({ query: "(max-width: 1440px)" });
  const statusOptions: IOptions[] = [
    { value: "invited", label: t("UserManagement.Tab.Invited") },
    { value: "registered", label: t("UserManagement.Tab.Registered") },
  ];

  const { memberTableState, updateTableState } = useFirestoreTable();

  const selectedStatus =
    memberTableState.filters?.find((fil) => fil.field === "status")?.value ||
    "registered";

  const updateStatusFilter = (value: string | string[]) => {
    const filtered =
      memberTableState.filters?.filter((fil) => fil.field !== "status") || [];

    updateTableState({
      filters: [
        ...filtered,
        {
          field: "status",
          value: value,
        },
      ],
    });
  };

  return (
    <div
      id="tab-status"
      className="btn-group btn-group-custom d-flex align-items-center justify-content-between flex-nowrap w-100"
      role="group"
      style={
        isLaptopView
          ? {
              maxWidth: "1176px",
              overflowX: "auto",
            }
          : {
              maxWidth: "100%",
            }
      }
      data-testid="order-status-filter"
    >
      {statusOptions.map((status, index) => {
        console.log(status, "status");
        return (
          <button
            key={index}
            type="button"
            className={`btn btn-white text-nowrap`}
            onClick={() => updateStatusFilter(status.value)}
            data-testid={"button_" + String(status.label).toLowerCase()}
          >
            <span
              className={clsx("fw-bolder", {
                "text-primary border-3 border-bottom border-primary py-3":
                  isSameStatusValue(selectedStatus, status.value),
              })}
            >
              {status.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default MemberStatusFilter;
