import React, { ButtonHTMLAttributes, useCallback, useEffect, useState } from "react";
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean };

// Button component
const Button: React.FC<ButtonProps> = ({ children, isLoading, ...props }) => {
  const [isLoadingState, setLoadingState] = useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    return () => {
      setLoadingState(false);
    };
  }, []);

  return (
    <button
      {...props}
      ref={buttonRef}
      onClick={async (e) => {
        if (buttonRef.current === null) return;
        buttonRef.current.disabled = true;
        try {
          await Promise.resolve(props.onClick?.(e));
        } finally {
          setTimeout(() => {
            if (buttonRef.current === null) return;
            buttonRef.current.disabled = false;
          }, 500);
        }
      }}
    >
      {!isLoading && !isLoadingState && children}
      {(isLoading || isLoadingState) && <span className="spinner-border spinner-border-sm"></span>}
    </button>
  );
};

export default Button;
