import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { boolean } from "yup";
import ModalConfirmationWithStateHandler from "./modalConfirmationWithStateHandler";

interface ModalProps {
  id: string;
  isModalOpen: boolean;
  isDirty?: boolean;

  title?: string;
  body?: string | HTMLElement;

  confirmText?: string;
  onConfirm?: any;

  cancelText?: string;
  onCancel?: any;
}

const ModalConfirmationChangeVariants: FC<ModalProps> = (props) => {
  const { t } = useTranslation();

  const {
    id,
    isModalOpen,
    title = t("Alert.Info"),
    body = t("AddNewProduct.Modal.VariantCategory.EditorDelete"),
    confirmText = t("AddNewProduct.Modal.VariantCategory.Button.Yes,I'mSure"),
    onConfirm,
    cancelText = t("Alert.Button.Cancel"),
    onCancel,
  } = props;

  return (
    <ModalConfirmationWithStateHandler
      id={id}
      size="sm"
      title={title}
      body={body}
      isModalOpen={isModalOpen}
      confirmText={confirmText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      onClose={onCancel}
    />
  );
};

export default ModalConfirmationChangeVariants;
