import { DocumentData, QueryDocumentSnapshot, getDoc } from "firebase/firestore";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import {
  reqDownloadCSVExportedOrders,
  reqReadyToShipOrder,
} from "../../../../../../api/server/order";
import { getAccountByID } from "../../../../../../db/serviceAccount";
import useFirestoreData, { FirestoreQueryState } from "../../../../../../hooks/useFirestoreData";
import { Account } from "../../../../../models/Account";
import { Order, OrderStatus7Days } from "../../../../../models/Order";
import * as lc from "../../../../../modules/localstorage/index";
import moment from "moment";
import { ShipmentDeadlines } from "../../../../../modules/product/shipment/redux/ShipmentsRedux";
import { createRef } from "../../../../../../db";

type TableContextValue = {
  tableState: FirestoreQueryState<Order>;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  updateTableState: (data: Partial<FirestoreQueryState<Order>>) => void;
  updatePage: (newPage: number) => void;
  refreshData: () => void;
  setReadyToShipOrder: (items: string[]) => void;
  setExportedOrder: (items: string[]) => void;
  resetResponseRTS: () => void;
  checkifAvailableToProcessOrder: (shipmentDeadlines: ShipmentDeadlines[], data: Order) => boolean;
  loadingRTS: boolean;
  responseRTS: ResponseRTS | undefined;
  responseExportOrder: ResponseRTS | undefined;
  isLoading: boolean;
  isFetching: boolean;
  data?: {
    allCount: number;
    items: Order[];
  };
};

type ResponseRTS = {
  message: string;
  processId: string;
  data: {
    accountName: string;
    count: number;
  }[];
};

export const defaultTableState: FirestoreQueryState<Order> = {
  clientID: undefined,
  collection: "",
  sorting: {
    field: "marketplaceOrderCreatedAt",
    order: "asc",
  },
  limit: 10,
  filters: [
    {
      field: "exportStatus",
      value: [OrderStatus7Days.NewOrder],
    },
    // {
    //   field: "marketplaceLogisticChannelParent",
    //   value: "LEX"
    // },
    // {
    //   field: "orderPriorityNumber",
    //   value: "30"
    // }
  ],
  currentPage: 1,
  searchKey: {
    field: "ordersn",
    value: "",
    caseSensitive: true,
  },
  priorityOrder: true,
};
export const defaultValue: TableContextValue = {
  tableState: defaultTableState,
  selectedItems: [],
  setSelectedItems: () => {},
  updateTableState: () => {},
  updatePage: () => {},
  refreshData: () => {},
  setReadyToShipOrder: () => {},
  setExportedOrder: () => {},
  resetResponseRTS: () => {},
  checkifAvailableToProcessOrder: () => true,
  loadingRTS: false,
  responseRTS: undefined,
  responseExportOrder: undefined,
  isLoading: true,
  isFetching: true,
  data: undefined,
};

export const TableContext = createContext<TableContextValue>(defaultValue);
export function useFirestoreTable() {
  return useContext(TableContext);
}

type TableProviderProps<T> = PropsWithChildren<{
  collection: string;
}>;

async function mapOrderAccount(
  docs: QueryDocumentSnapshot<Order, DocumentData>[]
): Promise<Order[]> {
  return await Promise.all(
    docs.map(async (doc) => {
      const accRef = doc.data().account;
      if (!accRef) {
        return { id: doc.id, ...doc.data() };
      }

      const accountData = await getAccountByID(accRef.id);
      if (!accountData) {
        return { id: doc.id, ...doc.data() };
      }
      // const accountData = { id: accDoc.id, ...accDoc.data() } as Account;
      return { id: doc.id, ...doc.data(), accountData: accountData as Account };
    })
  );
}

export function TableProvider<T>({ children, collection }: TableProviderProps<T>) {
  const clientID = lc.getItemLC(lc.LCName.Client).id;
  const userID = lc.getItemLC(lc.LCName.UserID);

  const [tableState, setTableState] = useState<FirestoreQueryState<Order>>({
    ...defaultTableState,
    collection: collection,
    clientID: clientID,
  });

  useEffect(() => {
    const storedState = localStorage.getItem("tableStateShiporderlist");
    if (storedState) {
      setTableState(JSON.parse(storedState));
    }
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("tableStateShiporderlist", JSON.stringify(tableState));
  }, [tableState]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("tableStateShiporderlist");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { data, isLoading, isFetching, refetch } = useFirestoreData<Order>(
    {
      ...tableState,
      searchKey: tableState.searchKey
        ? {
            ...tableState.searchKey,
            value: tableState.searchKey.value.toUpperCase(),
          }
        : undefined,
    },
    mapOrderAccount
  );

  console.log("SelectedItems", selectedItems);

  console.log("INII TableState", tableState);

  const [loadingRTS, setLoadingRTS] = useState(false);
  const [responseRTS, setResponseRTS] = useState<ResponseRTS | undefined>(undefined);
  const [responseExportOrder, setResponseExportOrder] = useState<ResponseRTS | undefined>(
    undefined
  );

  const updateTableState = (data: Partial<FirestoreQueryState<Order>>) => {
    let newState = data;

    if (!data.currentPage) {
      newState.currentPage = 1;
    }

    return setTableState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
    setSelectedItems([]);
  };

  const refreshData = () => {
    refetch();
    setTableState((prev) => ({
      ...prev,
      sorting: defaultTableState.sorting,
      currentPage: defaultTableState.currentPage,
      priorityOrder: defaultTableState.priorityOrder,
    }));
  };

  const setReadyToShipOrder = async (items: string[]) => {
    setLoadingRTS(true);
    const res = await reqReadyToShipOrder(items, userID);
    console.log(res);
    setResponseRTS(res);
    setLoadingRTS(false);
  };

  const setExportedOrder = async (items: string[]) => {
    const response = await reqDownloadCSVExportedOrders(items, userID);
    console.log({ response });
    setResponseExportOrder(response);
  };

  const resetResponseRTS = () => {
    setResponseRTS(undefined);
    setLoadingRTS(false);
  };

  const checkifAvailableToProcessOrder = (shipmentDeadlines: ShipmentDeadlines[], data: Order) => {
    const currentTime = moment().format("HHmmss");
    const logisticData = shipmentDeadlines.find(
      (logistic) =>
        logistic.logisticSearchKey ===
          data.marketplaceLogisticChannelName?.replaceAll(" ", "").toLowerCase() &&
        logistic.marketplaceName === data.marketplaceType
    );
    const logisticMaxShipTime = moment(
      logisticData?.maxShipTime?.toDate() ?? moment().endOf("day")
    ).format("HHmmss");
    const logisticMinShipTime = moment(
      logisticData?.minShipTime?.toDate() ?? moment().startOf("day")
    ).format("HHmmss");
    const accountData = data.accountData;
    const accountMaxShipTime = moment(
      accountData?.maxShipTime?.toDate() ?? moment().endOf("day")
    ).format("HHmmss");
    const accountMinShipTime = moment(
      accountData?.minShipTime?.toDate() ?? moment().startOf("day")
    ).format("HHmmss");
    const checkifAvailableToProcessOrder = logisticData
      ? parseInt(currentTime) >= parseInt(logisticMinShipTime) &&
        parseInt(currentTime) < parseInt(logisticMaxShipTime)
      : parseInt(currentTime) >= parseInt(accountMinShipTime) &&
        parseInt(currentTime) < parseInt(accountMaxShipTime);
    return checkifAvailableToProcessOrder;
  };

  console.log("Items", data?.items);

  const value = {
    tableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    setReadyToShipOrder,
    setExportedOrder,
    resetResponseRTS,
    loadingRTS,
    responseRTS,
    responseExportOrder,
    updatePage,
    checkifAvailableToProcessOrder,
    isLoading,
    isFetching,
    refreshData,
    data,
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}
