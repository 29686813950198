import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as lc from "../../../../../app/modules/localstorage/index";
import * as servAccount from "../../../../../db/serviceAccount";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/margin.scss";
import {
  MapMediaSupport,
  MediaType,
} from "../../../../../util/TemplateMediaSupport";
import CardItem from "../../../../components/table/list/cardList/CardItem";
import { Account } from "../../../../models/Account";
import { templateState } from "../../../../models/Template";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { UserCompanies } from "../../../../models/User";

interface ChannelProps {
  onChanges: (params: { key: string; value: any }[]) => void;
}

const TemplateChannel: FC<ChannelProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [hasSender, setHasSender] = useState<boolean>(false);
  const [hasTemplateName, setHasTemplateName] = useState<boolean>(false);
  const [validTemplateName, setValidTemplateName] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //temporary account and templateCompany
  const templateCompanies = useSelector(
    (state: RootState) => state.Template.templateCompaniesID
  );
  const clientID = useSelector((state: RootState) => state.Template.clientID);
  const accountsID = useSelector(
    (state: RootState) => state.Template.accountsID
  );
  const rdTemplateNameAlert = useSelector(
    (state: RootState) => state.Template.templateNameAlert
  );
  const rdTemplateNameEmptyAlert = useSelector(
    (state: RootState) => state.Template.templateNameEmptyAlert
  );
  const rdTemplateSenderEmptyAlert = useSelector(
    (state: RootState) => state.Template.templateSenderEmptyAlert
  );
  const [templateNameAlert, setTemplateNameAlert] = useState<boolean>(true);
  const templateName = useSelector(
    (state: RootState) => state.Template.templateName
  );
  const lengthTemplateName = useSelector(
    (state: RootState) => state.Template.templateNameLength
  );
  const reduxTemplateState = useSelector(
    (state: RootState) => state.Template.templateState
  );
  const campaignTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  const companiesAccounts = useSelector(
    (state: RootState) => state.Template.companiesAccounts as Account[]
  );
  const [userCompanies, setUserCompanies] = useState<UserCompanies[]>([]);

  useEffect(() => {
    if (campaignTemplateFinish) {
      document
        .getElementById("inputTemplateName")
        ?.setAttribute("readonly", "readonly");
      setHasTemplateName(true);
    }
  }, [campaignTemplateFinish]);

  useEffect(() => {
    setTemplateNameAlert(rdTemplateNameAlert);
  }, [rdTemplateNameAlert]);

  useEffect(() => {
    if (rdTemplateSenderEmptyAlert) {
      const divEmptyTemplateSender: HTMLSpanElement | null =
        document.getElementById("emptyTemplateSender");
      if (accountsID.length > 0 && divEmptyTemplateSender) {
        divEmptyTemplateSender.hidden = true;
      } else if (accountsID.length <= 0 && divEmptyTemplateSender) {
        divEmptyTemplateSender.hidden = false;
      }
    }
    if (accountsID.length > 0) setHasSender(true);
    else setHasSender(false);
  }, [accountsID]);

  useEffect(() => {
    if (rdTemplateSenderEmptyAlert && accountsID.length <= 0) {
      const divEmptyTemplateSender: HTMLSpanElement | null =
        document.getElementById("emptyTemplateSender");
      if (divEmptyTemplateSender !== null) {
        divEmptyTemplateSender.hidden = false;
      }
    }
  }, [rdTemplateSenderEmptyAlert]);

  useEffect(() => {
    if (!validTemplateName) {
      dispatch(ReduxTemplate.setTemplateNameIncludeCharacterAlert(true));
    } else {
      dispatch(ReduxTemplate.setTemplateNameIncludeCharacterAlert(false));
    }
  }, [validTemplateName]);

  useEffect(() => {
    const userCompanies = lc.getItemLC(lc.LCName.CompanyList);
    const client = lc.getItemLC(lc.LCName.Client);
    // console.log("Get Data Account userCompanies length : ", userCompanies?.length);
    // console.log("Get Data Account Companies Account : " + JSON.stringify(companiesAccounts));
    if (
      userCompanies.length > 0 &&
      (!companiesAccounts || companiesAccounts.length === 0)
    ) {
      setUserCompanies(userCompanies);
      setIsLoading(true);
      // console.log("Get Data Account on effect");
      servAccount
        .getAccessibleAccount(client.id, userCompanies)
        .then((accounts) => {
          console.log(accounts, "testing accounts yts");
          // console.log(`Get Data Account on effect result ${JSON.stringify(accounts)} `);
          if (!accounts) {
            dispatch(ReduxTemplate.setCompaniesAccounts([]));
            setIsLoading(false);
            return;
          }

          // console.log(`Get Data Account on effect before sorted`);

          const sortedAccount = accounts?.sort((a: any, b: any) => {
            if (!a.type || a.type === undefined) return -1;
            if (!b.type || b.type === undefined) return 1;
            return a?.type?.toLowerCase() > b?.type?.toLowerCase() ? -1 : 1;
          });

          console.log(sortedAccount, "sor acc yts");

          setHasSender(false);
          // console.log(`Get Data Account on effect after sorted`);

          if (sortedAccount && sortedAccount.length > 0) {
            dispatch(ReduxTemplate.setCompaniesAccounts(sortedAccount));
          }

          // console.log(`Get Data Account accounts count ${sortedAccount.length}`);
          setIsLoading(false);
        })
        .catch((error) => {
          dispatch(ReduxTemplate.setCompaniesAccounts([]));
          setIsLoading(false);
        });
    }

    if (templateName && templateName !== "" && accountsID.length > 0) {
      setHasSender(true);
      setHasTemplateName(true);
    } else {
      setHasTemplateName(false);
    }
    if (accountsID.length > 0) {
      setHasSender(true);
      //for accountsID
      for (let index = 0; index < accountsID.length; index++) {
        const account = accountsID[index];
        const checkbox = document.getElementById(`checkbox-${account}`);
        const checkedValue = checkbox?.getAttribute("checked");
        // console.log(
        //   `Use effect AccountsID Checkbox for ${account} is ${checkedValue}`
        // );
        if (
          checkbox &&
          (checkedValue === undefined ||
            checkedValue === null ||
            checkedValue === "false")
        ) {
          checkbox?.setAttribute("checked", "true");
        }
      }
      document.getElementById("nextStep")?.classList.remove("disabled");
    } else {
      setHasSender(false);
    }
    if (clientID === "") {
      const client = lc.getItemLC(lc.LCName.Client);
      dispatch(ReduxTemplate.setClientID(client?.id));
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      dispatch(ReduxTemplate.setLoading(true));
    } else {
      dispatch(ReduxTemplate.setLoading(false));
    }
  }, [isLoading]);

  const capitalizeFirst = (text: string) => {
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
  };

  const onChangeTemplateName = async (event: any) => {
    let templateName = event.target.value;
    props.onChanges([
      { key: "accountsId", value: accountsID },
      { key: "companiesId", value: templateCompanies },
      { key: "templateName", value: templateName },
    ]);
    dispatch(
      ReduxTemplate.setTemplateName(
        (templateName = templateName.replace(/\s+/g, "_"))
      )
    );
    if (!campaignTemplateFinish) {
      setValidTemplateName(templateName.match(/^[a-z0-9_]*$/));
      setHasTemplateName(templateName && templateName !== "");

      dispatch(ReduxTemplate.setTemplateNameAlert(false));
    } else {
      setHasTemplateName(true);
      dispatch(ReduxTemplate.setTemplateNameAlert(false));
    }
    if (templateName !== "") {
      dispatch(ReduxTemplate.setTemplateNameEmptyAlert(false));
    } else {
      dispatch(ReduxTemplate.setTemplateNameEmptyAlert(true));
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event: any) => {
    setSelectAll((prev) => !prev);
    let selectedAccount: string[] = [];
    let selectedCompanies: string[] = [];
    if (event.target.checked === true) {
      companiesAccounts.map((account) => {
        selectedAccount.push(account.id);
        selectedCompanies.push(account.company.id);
      });
    }
    console.log(companiesAccounts, "compana");
    dispatch(ReduxTemplate.setAccountsID(selectedAccount));
    dispatch(ReduxTemplate.setTemplateCompaniesID(selectedCompanies));
    props.onChanges([
      { key: "accountsId", value: selectedAccount },
      { key: "companiesId", value: selectedCompanies },
      { key: "templateName", value: templateName },
    ]);
  };

  const handleOnChangeSelectedAccount = (
    accountId: string,
    companyId: string
  ) => {
    const checkbox = document.getElementById(`checkbox-${accountId}`);
    const checkedValue = checkbox?.getAttribute("checked");
    // console.log(`Checkbox for ${accountId} is ${checkedValue}`);
    let selectedAccounts: string[] = [...accountsID];
    let selectedCompanies: string[] = [...templateCompanies];
    const findCompanyById = selectedCompanies.find(
      (company) => company === companyId
    );
    if (
      checkbox &&
      (checkedValue === undefined ||
        checkedValue === null ||
        checkedValue === "false")
    ) {
      checkbox?.setAttribute("checked", "true");
      selectedAccounts.push(accountId);
      if (!findCompanyById) selectedCompanies.push(companyId);
    } else {
      setSelectAll(false);
      checkbox?.removeAttribute("checked");
      selectedAccounts = selectedAccounts.filter(
        (account) => account !== accountId
      );
      if (findCompanyById) {
        selectedCompanies = selectedCompanies.filter(
          (company) => company !== companyId
        );
      }
    }
    if (selectedAccounts.length === companiesAccounts.length)
      setSelectAll(true);
    // console.log("selectedAccounts ", selectedAccounts);
    dispatch(ReduxTemplate.setAccountsID(selectedAccounts));
    dispatch(ReduxTemplate.setTemplateCompaniesID(selectedCompanies));
    //temporary set accountID and templateCompanyID
    //find in selectedAccounts type whatsapp
    props.onChanges([
      { key: "accountsId", value: selectedAccounts },
      { key: "companiesId", value: selectedCompanies },
      { key: "templateName", value: templateName },
    ]);
  };

  return (
    <>
      <div
        className={clsx("tab-pane fade show")}
        id="kt_tab_pane_0"
        role="tabpanel"
        data-testid="tab_panel_channel"
      >
        <div className="mb-10" id="div-templateName">
          <div
            className="step-sub-title fs-2"
            data-testid="template_name_sub_title"
          >
            {t("Campaign.Title.TemplateName")}
            {hasTemplateName && (
              <span
                id="validTemplateName"
                className="bi bi-check-circle-fill text-success ps-3"
              ></span>
            )}
          </div>
          <div className="mb-2 mt-2" data-testid="template_name_info">
            {t("Campaign.Info.TemplateName")}
          </div>

          {isTabletOrMobile ? (
            <div className="d-flex align-items-end form-control border-2 px-0 py-0">
              <textarea
                data-testid="input_template_name"
                id="input_template_name"
                name="input_template_name"
                placeholder={t("Campaign.Title.TemplateName")}
                maxLength={512}
                className="form-control form-control-solid border-0 bg-white"
                onChange={onChangeTemplateName}
                value={templateName}
                style={{
                  height: "100%",
                  resize: "none",
                }}
                rows={5}
              />
              <p
                className="border-0 bg-white my-0"
                style={{
                  paddingRight: "5px",
                  color: "gray",
                  textAlign: "center",
                  paddingTop: "100px",
                }}
              >
                {lengthTemplateName}/512
              </p>
            </div>
          ) : (
            <>
              <div className="d-flex flex-row border w-50">
                <input
                  className="form-control form-control-solid border-0 bg-white"
                  data-testid="input_template_name"
                  placeholder={t("Campaign.Title.TemplateName")}
                  id="inputTemplateName"
                  onChange={onChangeTemplateName}
                  maxLength={512}
                  value={templateName}
                />
                <p
                  className="d-flex align-items-center border-0 bg-white my-0"
                  style={{
                    paddingRight: "5px",
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  {lengthTemplateName}/512
                </p>
              </div>
              {/* <div data-testid="input_template_name_length">
              {t("Common.Char")} : {lengthTemplateName}/512
            </div> */}
            </>
          )}
          {!validTemplateName && (
            <div
              data-testid="errorspecialchar"
              style={{ color: "red" }}
              className="text-danger"
            >
              {t("Template.Error.SpecialChar")}
            </div>
          )}
          {templateNameAlert && (
            <span
              id="existTemplateName"
              data-testid="existTemplateName"
              className="text-danger"
              style={{ color: "red" }}
            >
              {t("Template.Info.ExistingTemplateName")}
            </span>
          )}
          {rdTemplateNameEmptyAlert && (
            <span
              id="emptyTemplateName"
              data-testid="emptyTemplateName"
              className="text-danger"
              style={{ color: "red" }}
            >
              {t("CreateTemplate.TN&SS.Alert.InputTemplateName")}
            </span>
          )}
        </div>

        {reduxTemplateState !== templateState.campaigncreated && (
          <div className="mb-10">
            <div className="step-sub-title fs-2" data-testid="sender_sub_title">
              {t("Campaign.Title.Sender")}
              {hasSender && (
                <span
                  id="filledSender"
                  className="bi bi-check-circle-fill text-success ps-3"
                ></span>
              )}
              {!hasSender && (
                <span
                  id="filledSender"
                  className="bi bi-check-circle-fill text-success ps-3"
                  style={{ display: "none" }}
                ></span>
              )}
            </div>
            <div className="mb-1 mt-1" id="div-templateSender">
              {t("Campaign.Info.Sender")}
            </div>
            <span
              id="emptyTemplateSender"
              data-testid="emptyTemplateSender"
              className="text-danger"
              style={{ color: "red" }}
            >
              {t("CreateTemplate.TN&SS.Alert.SenderSelection")}
            </span>
            {!isTabletOrMobile && (
              <table className="table table-row-dashed gy-7 gs-7">
                <thead>
                  <tr className="fw-bold fs-6 text-gray-800">
                    <th>
                      <input
                        data-testid={`checkbox-SelectAll`}
                        type="checkbox"
                        className="form-check-input"
                        id="selectAll"
                        name="selectAll"
                        onChange={handleSelectAll}
                        checked={selectAll}
                      />
                      <span className="ms-5">
                        {t("CreateTemplate.TN&SS.Column.Header.Channel")}
                      </span>
                    </th>
                    <th>{t("CreateTemplate.TN&SS.Column.Header.Company")}</th>
                    <th>{t("CreateTemplate.TN&SS.Column.Header.Account")}</th>
                    <th>
                      {t("CreateTemplate.TN&SS.Column.Header.MediaSupport")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companiesAccounts.length > 0 &&
                    companiesAccounts.map((element, index) => {
                      const findMediaSupport: any[] = MapMediaSupport.get(
                        element?.type?.toLowerCase()
                      );

                      const mediaTypes = MediaType;
                      // console.log("findMediaSupport : ", findMediaSupport);
                      return (
                        <tr
                          key={index + 1}
                          className={clsx({
                            "bg-light-primary": (index + 1) % 2 !== 0,
                          })}
                        >
                          <td>
                            <input
                              data-testid={`checkbox-${index + 1}`}
                              type="checkbox"
                              className="form-check-input"
                              id={`checkbox-${element.id}`}
                              name={`checkbox-${element.id}`}
                              checked={
                                accountsID &&
                                accountsID.find(
                                  (account) => account === element.id
                                )
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleOnChangeSelectedAccount(
                                  element.id,
                                  element.companyModel!.id
                                )
                              }
                            />
                            <span className="ms-5">{`${capitalizeFirst(
                              element.type
                            )}`}</span>
                          </td>
                          <td data-testid={`companyName-${index + 1}`}>
                            {element.companyModel?.companyName || ""}
                          </td>
                          <td data-testid={`Acccount-${index + 1}`}>
                            {element.type === "whatsapp"
                              ? element.whatsappNumber
                              : ""}
                          </td>
                          <td data-testid={`MediaSupport-${index + 1}`}>
                            <div className="d-flex flex-row gap-2">
                              {mediaTypes.map((media: string, i) => {
                                // console.log(`mediaType ${media}`);
                                return (
                                  <img
                                    className="mh-30px"
                                    src={toAbsoluteUrl(
                                      `/media/icons/mediasupport/${media}_${
                                        findMediaSupport?.indexOf(
                                          media?.toLowerCase()
                                        ) !== -1
                                          ? "Active.png"
                                          : "Inactive.png"
                                      }`
                                    )}
                                    key={i}
                                    alt={`media-support-${media?.toLowerCase()}`}
                                    data-testid={`media-support-${media?.toLowerCase()}`}
                                  />
                                );
                              })}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            {isTabletOrMobile && companiesAccounts.length > 0 && (
              <div>
                <div className="d-flex flex-row gap-5 p-4">
                  <input
                    data-testid={`checkbox-SelectAll`}
                    type="checkbox"
                    className="form-check-input"
                    id="selectAll"
                    name="selectAll"
                    onChange={handleSelectAll}
                    checked={selectAll}
                  />
                  <div>Select All</div>
                </div>
                {companiesAccounts.map((element, index) => {
                  const findMediaSupport: string[] = MapMediaSupport.get(
                    element?.type?.toLowerCase()
                  );
                  const findCompany: any =
                    userCompanies &&
                    userCompanies?.find(
                      (access) => access?.company?.id === element?.company?.id
                    );
                  return (
                    <div className="mt-5">
                      <CardItem
                        id={`account-selection-${index + 1}`}
                        name={`${capitalizeFirst(element.type)}`}
                        isAccountSelection={true}
                        checked={
                          accountsID &&
                          accountsID.find((account) => account === element.id)
                            ? true
                            : false
                        }
                        phoneNumber={element.whatsappNumber}
                        CompanyName={findCompany?.companyName || ""}
                        AccountName={element.name}
                        mediaSupport={findMediaSupport}
                        onChange={() =>
                          handleOnChangeSelectedAccount(
                            element.id,
                            findCompany?.id
                          )
                        }
                        AccountId={element.id}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateChannel;
