import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../setup/theme/useTheme";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../../styles/components/TextInput";
import Button from "../../../../styles/components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import province from "../../../../util/regions/provinsi.json";
import city from "../../../../util/regions/kota.json";
import district from "../../../../util/regions/kecamatan.json";
import Select from "react-select";
import * as RegisterRedux from "../redux/RegisterSlice";
import { RootState } from "../../../../setup/redux/store";
import { useNavigate } from "react-router-dom";
import * as apiRegistration from "../../../../api/server/registration";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { KTCard } from "../../../../resources/helpers";
import i18next from "../../../../setup/translations/i18next";

const caddressSchema = Yup.object().shape({
  province: Yup.number()
    .notOneOf([0], "Register.Error.Alert.Province.Required")
    .required("Register.Error.Alert.Province.Required"),
  city: Yup.number()
    .notOneOf([0], "Register.Error.Alert.City.Required")
    .required("Register.Error.Alert.City.Required"),
  district: Yup.number()
    .notOneOf([0], "Register.Error.Alert.District.Required")
    .required("Register.Error.Alert.District.Required"),
  street: Yup.string().required("Register.Error.Alert.Street.Required"),
  zip: Yup.string()
    .required("Register.Error.Alert.ZipCode.Required")
    .matches(/^\d{5}$/, "Register.Error.FormatZipcode"),
});

const initialValues = {
  province: 0,
  city: 0,
  district: 0,
  street: "",
  zip: "",
};

interface province {
  id: number;
  name: string;
}

interface city {
  id: number;
  province_id: number;
  name: string;
}

interface district {
  id: number;
  regency_id: number;
  name: string;
}

export const ClientAddress = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [responseErr, setResponseErr] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const dispatch = useDispatch();
  const currentTheme = useTheme();
  const registerState = useSelector((state: RootState) => state.Registration);

  useEffect(() => {
    setProvinceData(province as any);
    setCityData(city as any);
    setDistrictData(district as any);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: caddressSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      console.log(values, "values");
      dispatch(
        RegisterRedux.setClientProvince(selectedProvince?.label.toLowerCase())
      );
      dispatch(RegisterRedux.setClientCity(selectedCity?.label.toLowerCase()));
      dispatch(
        RegisterRedux.setCLientDistrict(selectedDistrict?.label.toLowerCase())
      );
      dispatch(RegisterRedux.setClientStreet(values.street));
      dispatch(RegisterRedux.setClientZipcode(values.zip));
      setTimeout(async () => {
        console.log("Start Registration");
        await apiRegistration.registration(
          registerState?.clientName,
          registerState?.clientCompanyName,
          registerState?.clientWebsite,
          registerState?.clientBillingType,
          selectedProvince?.label.toLowerCase(),
          selectedCity?.label.toLowerCase(),
          selectedDistrict?.label.toLowerCase(),
          values?.street,
          values?.zip,
          registerState?.clientLogoURL,
          registerState?.uid ? registerState?.uid : "",
          (responseCode: number, response: any) => {
            console.log(JSON.stringify(response), "test");
            console.log(registerState, "test");
            if (!responseCode || response === undefined) {
              setLoading(false);
              setIsOpen(true);
              setResponseErr(t("Register.Modal.ServerDown"));
              return;
            }
            if (!response.success) {
              if (response.message) {
                setIsOpen(true);
                setResponseErr(response.message);
              }
              return;
            }
            setLoading(false);
            nav("/auth");
            toast("Registration Success", {
              type: "success",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        );
      }, 1000);
    },
  });

  const customStylesProvince = {
    option: (provided: any, state: any) => {
      console.log(state, "isi state test");
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? state.data?.disable === true
              ? "gray"
              : "white"
            : state.data?.disable === true
            ? "gray"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? "#0275d8"
            : state.data?.disable === true
            ? "lightgrey"
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      border: "none",
      outline: "none",
    }),
  };

  const customStylesCity = {
    option: (provided: any, state: any) => {
      console.log(state, "isi state test");
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? state.data?.disable === true
              ? "gray"
              : "white"
            : state.data?.disable === true
            ? "gray"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? "#0275d8"
            : state.data?.disable === true
            ? "lightgrey"
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      border: "none",
      outline: "none",
    }),
  };

  const customStylesDistrict = {
    option: (provided: any, state: any) => {
      console.log(state, "isi state test");
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? state.data?.disable === true
              ? "gray"
              : "white"
            : state.data?.disable === true
            ? "gray"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedProvince?.label
            ? "#0275d8"
            : state.data?.disable === true
            ? "lightgrey"
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      border: "none",
      outline: "none",
    }),
  };

  const formatOptionsProvince = () => {
    if (!provinceData) return [];

    return provinceData.map((code: any) => ({
      value: code?.id,
      label: code?.name,
    }));
  };

  const formatOptionsCity = () => {
    if (!cityData) return [];

    if (selectedProvince) {
      return cityData
        .filter((city: city) => city.province_id === selectedProvince.value)
        .map((code: any) => ({
          value: code?.id,
          label: code?.name,
        }));
    }
  };

  const formatOptionsDistrict = () => {
    if (!districtData) return [];

    if (selectedCity) {
      return districtData
        .filter(
          (district: district) => district.regency_id === selectedCity.value
        )
        .map((code: any) => ({
          value: code?.id,
          label: code?.name,
        }));
    }
  };

  const autoResize = () => {
    const textarea = document.getElementById("client-street");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  const closeModal = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        data-testid="caddress-form"
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="text-center mb-10" data-testid="caddress-form-title">
          <h1 className="text-dark mb-3">
            {t("Register.ClientAddress.Title")}
          </h1>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger" data-testid="errorLogin">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div
          className="fv-row mb-10"
          data-testid="caddress-form-input-province"
        >
          <label className="form-label fs-6 fw-bolder text-dark required">
            {t("CD.Input.Province")}
          </label>
          <Select
            id="province"
            value={selectedProvince}
            styles={customStylesProvince}
            options={formatOptionsProvince()}
            className="basic-select"
            classNamePrefix="select"
            onChange={async (selectedOption: any) => {
              setSelectedProvince(selectedOption);
              setSelectedCity(null);
              setSelectedDistrict(null);
              await formik.setFieldValue("province", selectedOption?.value);
              await formik.setFieldValue("city", 0);
              await formik.setFieldTouched("city");
              await formik.setFieldValue("district", 0);
              await formik.setFieldTouched("district");
              await formik.validateForm();
            }}
            placeholder={t("Register.ClientAddress.AddressSelection").replace(
              "<field>",
              i18next.language === "id" ? "Provinsi" : "Province"
            )}
          />
          {formik.touched.province && formik.errors.province && (
            <div
              data-testid="provinceerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.province}`)}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10" data-testid="caddress-form-input-city">
          <label className="form-label fs-6 fw-bolder text-dark required">
            {t("CD.Input.City")}
          </label>
          <Select
            id="city"
            value={selectedCity}
            styles={customStylesCity}
            options={formatOptionsCity()}
            className="basic-select"
            classNamePrefix="select"
            onChange={async (selectedOption: any) => {
              setSelectedCity(selectedOption);
              setSelectedDistrict(null);
              await formik.setFieldValue("city", selectedOption?.value);
              await formik.setFieldValue("district", 0);
              await formik.setFieldTouched("district");
              await formik.validateForm();
            }}
            placeholder={t("Register.ClientAddress.AddressSelection").replace(
              "<field>",
              i18next.language === "id" ? "Kota" : "City"
            )}
          />
          {formik.touched.city && formik.errors.city && (
            <div
              data-testid="cityerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.city}`)}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div
          className="fv-row mb-10"
          data-testid="caddress-form-input-district"
        >
          <label className="form-label fs-6 fw-bolder text-dark required">
            {t("Contact.EditContact.Address.District")}
          </label>
          <Select
            id="district"
            value={selectedDistrict}
            styles={customStylesDistrict}
            options={formatOptionsDistrict()}
            className="basic-select"
            classNamePrefix="select"
            onChange={(selectedOption: any) => {
              setSelectedDistrict(selectedOption);
              formik.setFieldValue("district", selectedOption?.value);
            }}
            placeholder={t("Register.ClientAddress.AddressSelection").replace(
              "<field>",
              i18next.language === "id" ? "Kecamatan" : "District"
            )}
          />
          {formik.touched.district && formik.errors.district && (
            <div
              data-testid="districterror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.district}`)}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10" data-testid="caddress-form-input-street">
          <label className="form-label fs-6 fw-bolder text-dark required">
            {t("Contact.EditContact.Address.StreetName")}
          </label>
          <textarea
            data-testid="street"
            id="client-street"
            className="form-control form-control-solid "
            maxLength={100}
            onInput={autoResize}
            {...formik.getFieldProps("street")}
          />
          {formik.touched.street && formik.errors.street && (
            <div
              data-testid="streeterror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.street}`)}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10" data-testid="caddress-form-input-zip">
          <label className="form-label fs-6 fw-bolder text-dark required">
            {t("CD.Input.ZipCode")}
          </label>
          <TextInput
            data-testid="zipcode"
            placeholder=""
            {...formik.getFieldProps("zip")}
            formcontrol={"solid"}
            name="zip"
            autoComplete="off"
            id="caddress-zip"
            maxLength={5}
          />
          {formik.touched.zip && formik.errors.zip && (
            <div
              data-testid="zipcodeerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t(`${formik.errors.zip}`)}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="text-center">
          <Button
            data-testid="button-caddress"
            id="login-button"
            btnlg="primary"
            type="submit"
            // disabled={formik.isSubmitting || !formik.isValid}
            cName="w-100 mb-5"
          >
            {!loading && (
              <span className="indicator-label">
                {t("Common.Button.Submit")}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {t("Login.Button.Loading")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </div>
        {/* end::Action */}
      </form>
      <Modal
        animation
        centered
        show={isOpen}
        onHide={async () => {
          closeModal();
        }}
        scrollable={false}
      >
        <Modal.Header closeButton className="bg-white">
          <Modal.Title data-testid="modal-title">
            {t("AddNewProduct.Modal.Error.Retry.Header")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <p className="mb-3 fs-5">{responseErr}</p>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button
            type="button"
            className="btn btn-primary w-25 text-center"
            onClick={async () => {
              closeModal();
            }}
          >
            {t("Marketplace.Modal.HowToAuth.Footer.Button")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
