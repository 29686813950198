import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../../../components/tabs/Tabs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import LandingPage from "./landing/LandingPage";
import { useQuery } from "react-query";
import * as ApiSettingService from "../../../../../db/serviceApiSetting";
import * as ApiSettingSlice from "../../../../modules/api-setting/redux/ApiSettingSlice";
import ApiOverview from "./api-overview/APIOverview";
import PushMechanism from "./push-mechanism/PushMechanism";
import SMSFailover from "./sms-failover/SMSFailover";
import ModalConfirmation from "../../../../components/modal/ModalConfirm";
import Button from "../../../../components/button/Button";
import isPublicIP from "../../../../../util/publicIPChecker";
import { useMediaQuery } from "react-responsive";
import { Timestamp } from "../../../../../db";
import * as ClientAction from "../../../../../actions/client";
import { ApiSettingState } from "../../../../modules/api-setting/redux/ApiSettingRedux";
import {
  isListHaveDuplicates,
  isValidPhoneNumber,
} from "../../../../../util/phoneNumber";

const IS_SANDBOX =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "stagging-sandbox";

const APISetting: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiSetting: ApiSettingState = useSelector(
    (state: any) => state.ApiSetting
  );
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const { isLoading, error, refetch } = useQuery(
    ["apiSetting", apiSetting.access.secretKey],
    () => ClientAction.getApiSetting(),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (!data) {
          setUserHasApiSetting(false);
          return;
        }

        if (!data.access && !data.push && !data.sms) {
          setUserHasApiSetting(false);
          return;
        }

        console.log("user has api setting", data);
        if (data.access) {
          dispatch(ApiSettingSlice.setApiSettingAccess(data.access));
          dispatch(
            ApiSettingSlice.setApiSettingWhiteList(
              data.access.ipwhitelist ?? []
            )
          );
        }

        if (data.push) {
          dispatch(
            ApiSettingSlice.setApiSettingPush({
              chat: {
                url: data.push.chat?.url ?? "",
                isActive: !!data.push.chat,
                isUrlVerified: !!data.push.chat?.url,
                isDeliveryReport: data.push.chat?.isDeliveryReport ?? false,
                isRecieveMessage: data.push.chat?.isRecieveMessage ?? false,
              },
              order: {
                url: data.push.order?.url ?? "",
                isActive: !!data.push.order,
                isUrlVerified: !!data.push.order?.url,
                selectedStatuses: data.push.order?.selectedStatuses ?? [],
              },
              product: {
                url: data.push.product?.url ?? "",
                isActive: !!data.push.product,
                isUrlVerified: !!data.push.product?.url,
              },
            })
          );
        }

        if (data.sms) {
          dispatch(ApiSettingSlice.setApiSettingSms(data.sms));
        }

        if (IS_SANDBOX) {
          dispatch(
            ApiSettingSlice.setApiSettingPhoneNumberWhiteList(
              data.access?.phoneNumberWhitelist ?? []
            )
          );
        } else {
          dispatch(
            ApiSettingSlice.setApiSettingPhoneNumberWhiteList(
              data.sandboxAccess?.phoneNumberWhitelist ?? []
            )
          );
        }

        if (data.sandboxAccess) {
          dispatch(
            ApiSettingSlice.setApiSandboxSettingAcces({
              accessToken: data.sandboxAccess.accessToken,
              secretKey: data.sandboxAccess.secretKey,
            })
          );
        }
        setUserHasApiSetting(true);
      },
    }
  );

  const [tabList] = useState([
    {
      title: t("API.Setting.Tab.AppOverview"),
      component: () => <ApiOverview />,
    },
    {
      title: t("API.Setting.Tab.PushMechanism"),
      component: () => <PushMechanism />,
    },
    {
      title: t("API.Setting.Tab.SMS"),
      component: () => <SMSFailover />,
    },
  ]);
  const [userHasApiSetting, setUserHasApiSetting] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isButtonSaveEnabled, setIsButtonSaveEnabled] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [actionType, setIsActionType] = useState<"cancel" | "save">("cancel");

  const isAllCallbackValid = Object.values(apiSetting.push).every(
    (item) => !item.isActive || (!!item.url && item.isUrlVerified)
  );

  const handleEditButtonClick = () => {
    dispatch(ApiSettingSlice.setEditMode(true));
    setIsEditMode(true);
  };
  const handleCancelButtonClick = () => {
    if (!apiSetting.isStateEdited) {
      closeEditMode();
      return;
    }
    setIsActionType("cancel");
    setIsModalOpen(true);
  };
  const handleSaveButtonClick = async () => {
    if (!apiSetting.isStateEdited) {
      toast(t("API.Setting.Toast.NoDataUpdated"), {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsActionType("save");
    setIsModalOpen(true);
  };

  const closeEditMode = () => {
    // Disable edit mode
    dispatch(ApiSettingSlice.setEditMode(false));
    dispatch(ApiSettingSlice.setStateEdited(false));

    // Refetch data
    refetch();

    // Close modal
    setIsModalOpen(false);

    // Close edit mode
    setIsEditMode(false);

    // Reset button save
    // dispatch(ApiSettingSlice.setVerifyCallbackUrlErrorStatus(false));
    dispatch(ApiSettingSlice.setVerifyMaskingErrorStatus(false));
    setIsButtonSaveEnabled(true);

    console.log("Close Edit Mode");
  };

  const saveApiSetting = async () => {
    // Verify Input
    if (apiSetting.isVerifyMaskingError && isAllCallbackValid) {
      console.log("Verify Save Api Error");
      toast("Please submit a valid data", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Update Redux Access Data to DB
    const ipwhitelist = apiSetting.ipwhitelist.filter(
      (ip: string) => ip !== "" && isPublicIP(ip)
    );
    const phoneNumberWhitelist = apiSetting.phoneNumberWhitelist.filter(
      (phone: string) => phone !== "" && isValidPhoneNumber(phone)
    );
    const accessData = {
      ...apiSetting.access,
      // phoneNumberWhitelist: phoneNumberWhitelist,
      ipwhitelist: ipwhitelist,
      updatedAt: Timestamp.now(),
    };
    // const pushData = { ...apiSetting.push, updatedAt: Timestamp.now() };
    const smsData = { ...apiSetting.sms, updatedAt: Timestamp.now() };
    console.log("accessData", accessData);
    // console.log("pushData", pushData);
    console.log("smsData", smsData);
    await ApiSettingService.updateAccess(accessData);
    // await ApiSettingService.updatePush(pushData);
    await ApiSettingService.updateSms(smsData);

    const pushResult = await ClientAction.updateClientPushSetting(
      apiSetting.push
    );
    if (!pushResult || pushResult.data.error) {
      toast(pushResult?.data.error ?? "Failed Update Push Setting", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsModalOpen(false);

      return;
    }

    const whitelistPhoneResult =
      await ClientAction.updateClientWhitelistPhoneNumbers(
        phoneNumberWhitelist,
        apiSetting.access.accessToken
      );
    if (!whitelistPhoneResult || whitelistPhoneResult.status !== 200) {
      toast(
        whitelistPhoneResult?.data.message ??
          "Failed Update Whitelist Phone Numbers",
        {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      dispatch(
        ApiSettingSlice.setApiSettingPhoneNumberWhiteListError(
          whitelistPhoneResult?.data
        )
      );

      setIsModalOpen(false);

      return;
    }

    dispatch(ApiSettingSlice.setApiSettingPhoneNumberWhiteListError(undefined));

    closeEditMode();

    toast(t("API.Setting.Toast.DataUpdated"), {
      type: "success",
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const cancelApiSetting = () => {
    refetch();
    closeEditMode();
  };

  // useEffect(() => {
  //   if (!isLoading && !error && !data) {
  //     console.log("user doesnt has api setting");
  //     setUserHasApiSetting(false);
  //   }

  //   if (!isLoading && !error && data) {
  //     console.log("user has api setting", data);
  //     dispatch(ApiSettingSlice.setApiSettingAccess(data.access));
  //     dispatch(
  //       ApiSettingSlice.setApiSettingPush({
  //         chat: {
  //           url: data.push.chat?.url ?? "",
  //           isActive: !!data.push.chat,
  //           isUrlVerified: !!data.push.chat?.url,
  //           isDeliveryReport: data.push.chat?.isDeliveryReport ?? false,
  //           isRecieveMessage: data.push.chat?.isRecieveMessage ?? false,
  //         },
  //         order: {
  //           url: data.push.order?.url ?? "",
  //           isActive: !!data.push.order,
  //           isUrlVerified: !!data.push.order?.url,
  //         },
  //         product: {
  //           url: data.push.product?.url ?? "",
  //           isActive: !!data.push.product,
  //           isUrlVerified: !!data.push.product?.url,
  //         },
  //       })
  //     );
  //     dispatch(ApiSettingSlice.setApiSettingSms(data.sms));
  //     dispatch(
  //       ApiSettingSlice.setApiSettingWhiteList(data.access.ipwhitelist ?? [])
  //     );
  //     dispatch(
  //       ApiSettingSlice.setApiSettingPhoneNumberWhiteList(
  //         data.access.phoneNumberWhitelist ?? []
  //       )
  //     );

  //     if (data.sandboxAccess) {
  //       dispatch(
  //         ApiSettingSlice.setApiSandboxSettingAcces({
  //           accessToken: data.sandboxAccess.accessToken,
  //           secretKey: data.sandboxAccess.secretKey,
  //         })
  //       );
  //     }
  //     setUserHasApiSetting(true);
  //   }
  // }, [JSON.stringify(data)]);

  useEffect(() => {
    if (
      apiSetting.ipwhitelist &&
      apiSetting.ipwhitelist.every((ip: string) => isPublicIP(ip)) &&
      apiSetting.phoneNumberWhitelist.every((phone: string) =>
        isValidPhoneNumber(phone)
      ) &&
      !isListHaveDuplicates(apiSetting.phoneNumberWhitelist) &&
      !apiSetting.isVerifyMaskingError &&
      isAllCallbackValid
    ) {
      setIsButtonSaveEnabled(true);
    } else {
      setIsButtonSaveEnabled(false);
    }
  }, [apiSetting, isAllCallbackValid]);

  if (isLoading) {
    return <div data-testid="Loading">Loading...</div>;
  }

  if (error) {
    return <div data-testid="Error">Error</div>;
  }

  if (!userHasApiSetting) {
    return (
      <div data-testid="LandingPage">
        <LandingPage />
      </div>
    );
  }

  const ToolButtons: React.FC<{ editMode: boolean }> = ({ editMode }) => {
    if (editMode) {
      // const callbackUrlChanged: boolean = !apiSetting.push.chat.isUrlVerified || !apiSetting.push.order.isUrlVerified || !apiSetting.push.product.isUrlVerified
      return (
        <div className="flex">
          <Button
            data-testid="saveButton"
            className="btn btn-primary btn-sm float-end ms-3"
            onClick={() => handleSaveButtonClick()}
            disabled={!isButtonSaveEnabled}
          >
            {t("Alert.Back.Button.Save")}
          </Button>
          <Button
            className="btn btn-secondary btn-sm float-end"
            onClick={() => handleCancelButtonClick()}
          >
            {t("ForgotPassword.Button.Cancel")}
          </Button>
        </div>
      );
    }

    return (
      <Button
        className="btn btn-primary btn-sm float-end "
        onClick={() => handleEditButtonClick()}
      >
        {t("Storefront.Column.Button.Edit")}
      </Button>
    );
  };

  return (
    <div
      className="flex bg-white p-5 h-100 poppins-regular"
      style={{ height: "100%", overflowY: "auto", fontFamily: "Poppins" }}
    >
      {!isMobileView && (
        <div>
          <ToolButtons editMode={isEditMode} />
        </div>
      )}

      <div className="pt-5">
        <Tabs tabList={tabList} />
      </div>

      {isMobileView && (
        <div className="fixed-bottom h-20 border pt-3 pe-5">
          <ToolButtons editMode={isEditMode} />
        </div>
      )}

      {/* Modal Confirmation */}
      <ModalConfirmation
        onConfirm={() =>
          actionType === "cancel" ? cancelApiSetting() : saveApiSetting()
        }
        onCancel={() => {
          console.log("cancel");
          setIsModalOpen(false);
        }}
        onHide={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        type={actionType}
        confirmOption={t("API.Setting.Modal.Button.Confirm")}
        cancelOption={t("API.Setting.Modal.Button.Cancel")}
        title={
          actionType === "cancel"
            ? t("API.Setting.Modal.Title.Cancel")
            : t("API.Setting.Modal.Title.Save")
        }
        message={
          actionType === "cancel"
            ? t("API.Setting.Modal.Message.Cancel")
            : t("API.Setting.Modal.Message.Save")
        }
      />
    </div>
  );
};

export { APISetting };
