import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  const styles = {
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "calc(50% - 2rem)",
    left: "calc(50% - 4rem)",
  };

  // const bgStyles = {
  //   position: "fixed",
  //   display: 'none',
  //   width: '100%',
  //   height: '100%',
  //   top: '0',
  //   left: '0',
  //   right: '0',
  //   bottom: '0',
  //   backgroundColor: 'rgba(0,0,0,0.5)',
  //   zIndex: '999',
  //   cursor: "pointer"
  // }

  return (
    <div
      tabIndex={-100}
      style={{
        position: "fixed",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "block",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "99999",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          ...styles,
          position: "absolute",
          textAlign: "center",
          rowGap: "5px",
        }}
      >
        <span className="indicator-progress" style={{ display: "block" }}>
          <span className="spinner-border spinner-border-md align-middle m-5"></span>
        </span>
        {t("Common.Pages.Loading")}...
      </div>
    </div>
  );
};

export { Loading };
