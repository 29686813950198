/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../styles/components/Button";
import Customer from "../../../models/Customer";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/redux/store";
import * as ReduxContact from "../../../modules/contact/redux/ContactSlice";
import {getCountryName} from "../../../lists/CountryList";
import Badge from "react-bootstrap/esm/Badge";

const Label = ({ children, className }: any, props: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <label
      {...props}
      // className={clsx(`col-sm-4 text-dark`, { "py-2": isTabletOrMobile })}
      className={clsx(`text-dark ${className}`, {
        "py-2 mt-2": isTabletOrMobile,
        "mt-3": !isTabletOrMobile,
      })}
    >
      {children}
    </label>
  );
};

interface MultiOption {
  label: string;
  value: string;
  date: number;
}

// const Field = (label: string, content: string | undefined) => {
interface FieldProps {
  label: string;
  content: string | Date | undefined;
  className?: string;
  gap?: string;
}

interface FieldPropsArray {
  label: string;
  content: any[] | undefined;
  className?: string;
  gap?: string;
}
const Field = ({ label, content, className, gap }: FieldProps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  console.log(`
    isTabletOrMobile : ${isTabletOrMobile} 
    label : ${label} 
    content : ${content}`);
  return (
    <div className={clsx("row", gap)}>
      <Label className={clsx(className, "col-4")}>{label}</Label>
      <div
        className={clsx(className, { 
          "py-2 mt-2": isTabletOrMobile,  
          "col-6 mt-3": !className
        })} >
        <span
          className={clsx("fs-6", {
            "font-weight-bold text-black": isTabletOrMobile,
            "text-muted": !isTabletOrMobile,
          })}
        >
          {content && content}
          {!content && "-"}
        </span>
      </div>
    </div>
  )
}

const FieldArray = ({ label, content, className, gap }: FieldPropsArray) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  console.log(`
    isTabletOrMobile: ${isTabletOrMobile} 
    label: ${label} 
    content: ${content}`);
    
  return (
    <div className={clsx("row", gap)}>
      <Label className={clsx(className, "col-4")}>{label}</Label>
      <div className={clsx(className, { 
        "py-2 mt-2": isTabletOrMobile,  
        "col-6 mt-3": !className
      })}>
        {content && content.length > 0 ? (
          content.map((option, index) => (
            <span
              key={index}
              className={clsx("fs-6 p-1 text-black", {
                "font-weight-bold": isTabletOrMobile,
                "text-muted": !isTabletOrMobile,
              })}
            >
              <Badge bg="secondary" className={clsx("text-black text-truncate ")} style={{ maxWidth: '124px' }} >
                {option}
              </Badge>
            </span>
          ))
        ) : (
          <span className="text-muted">-</span>
        )}
      </div>
    </div>
  );
};




export const Overview = ({ customer }: { customer: Customer }) => {
  // const customerData: Contact = customer;
  const { t } = useTranslation();
  const nav = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const rd_customerData = useSelector(
    (state: RootState) => state.Contact.contactData
  );
  const rd_navcount = useSelector((state: RootState) => state.Contact.navCount);
  const handleBack = () => {
    // if(rd_isFromContactList){
    //   console.log("rd_isFromContactList  is true");
    //   //if url in setting page is from contact list page
    //   nav("/contact/list");
    // }else{
    //   nav(-1);
    // }
    console.log(`navigate handle click in overview ${rd_navcount}`);
    const navcount = rd_navcount;
    dispatch(ReduxContact.setNavCount(1));
    nav(navcount * -1);
  };

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">

        {/* Detail Header */}
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t("CD.Title.ProfileDetail")}</h3>
          </div>

          {!isTabletOrMobile && (
            <Link
              to={`/contact/contact-detail/settings/${customer.id}`}
              state={{
                id: customer.id,
                name:
                  customer.firstName +
                  (customer.lastName ? " " + customer.lastName : ""),
              }}
              className={
                "btn btn-primary align-self-center justify-content-end "
              }
            >
              {t("CD.Button.EditProfile")}
            </Link>
          )}
          {/* <button className="btn btn-secondary align-self-center disabled">
            Save Changes
          </button> */}
        </div>

        <div className={clsx("card-body container d-flex", {
          "flex-column" : isTabletOrMobile
        })}>
            <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>
            <div className="col">
              <Field label={t("CD.Input.FirstName")} content={rd_customerData.firstName} className="col mt-3" gap={isTabletOrMobile ? "" : "gap-15"} />
              <Field label={t("CD.Input.LastName")} content={rd_customerData.lastName} className="col mt-3" gap={isTabletOrMobile ? "" : "gap-15"}/>
            </div>
          </div>
          <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>
            <Field 
              label={t("CD.Input.Birthdate")} 
              content={rd_customerData.birthdate? customer.birthdate : undefined} className="col mt-3"/>
            <Field label={t("CD.Input.MaritalStatus")} className="col mt-3" content={
                !rd_customerData.maritalStatus ? rd_customerData.maritalStatus : 
                rd_customerData.maritalStatus==="single" ? t("CD.Option.Single") : 
                t("CD.Option.Married")
              } />
            
          </div>
          <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>
            <Field 
            label={t("CD.Input.Gender")} 
            content={!rd_customerData.gender ? rd_customerData.gender : 
              rd_customerData.gender==="male" ? t("CD.Option.Male") : 
              rd_customerData.gender==="female" ? t("CD.Option.Female") : ""
            }
            className="col mt-3"
            />
          </div>
        </div>

        {/* Address Header */}
        <div className="card-header">
          <div className="card-title m-0 py-9 pb-3">
            <h4 className="fw-bolder m-0">{t("CD.Input.Address")}</h4>
          </div>
        </div>

        {/* { left right} */}
        <div className={clsx("card-body container d-flex", {
          "flex-column mb-20 pb-10" : isTabletOrMobile,
          "mb-5" : !isTabletOrMobile
        })}>
        
            <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>
                
                  {/* Street Name */}
                  <Field label={t("Contact.EditContact.Address.StreetName")} 
                      content={rd_customerData.address} className="col mt-3"/>

                  {/* District */}
                  <Field 
                    label={t("Contact.EditContact.Address.District")} 
                    content={`${rd_customerData.district},`} className="col mt-3"/>
              
                  {/* City */}
                  <Field label={t("CD.Input.City")} content={rd_customerData.city} className="col mt-3"/>

            </div>

            <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>

              {/* Province */}
              <Field 
                label={t("Contact.EditContact.Address.Province")} 
                content={rd_customerData.province} className="col mt-3"/>


                {/* Country */}
              <Field 
              label={t("CD.Input.Country")} 
              content={rd_customerData.country && getCountryName(rd_customerData.country)} className="col mt-3" />

              {/* zip code */}
              <Field label={t("CD.Input.ZipCode")} content={rd_customerData.zipcode} className="col mt-3"  />
              
            </div>
         
        </div>

        {/* SocialMedia Header */}
        <div className="card-header">
          <div className="card-title m-0 py-9 pb-3">
            <h4 className="fw-bolder m-0">{t("CD.Input.SocialMedia")}</h4>
          </div>
        </div>

        {/* {Social Media left right} */}
        <div className={clsx("card-body container d-flex", {
          "flex-column mb-20 pb-10" : isTabletOrMobile,
          "mb-5" : !isTabletOrMobile
        })}>
        
            <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>
                
                  {/* Facebook */}
                  <FieldArray label={t("Contact.EditContact.SocialMedia.Facebook")} 
                      content={rd_customerData.facebook} className="col mt-3"/>

                  {/* Instagram */}
                  <FieldArray label={t("Contact.EditContact.SocialMedia.Instagram")} 
                      content={rd_customerData.instagram} className="col mt-3"/>
                      
                  {/* Tiktok */}
                  <FieldArray label={t("Contact.EditContact.SocialMedia.Tiktok")} 
                      content={rd_customerData.tiktok} className="col mt-3"/>

                  {/* Shopee */}
                  <FieldArray label={t("Contact.EditContact.SocialMedia.Shopee")} 
                      content={rd_customerData.shopee} className="col mt-3"/>
     
            </div>

            <div className={clsx(isTabletOrMobile ? "d-flex flex-column" : "row row-col-2 w-100")}>

              {/* Tokopedia*/}
              <FieldArray label={t("Contact.EditContact.SocialMedia.Tokopedia")} 
                      content={rd_customerData.tokopedia} className="col mt-3"/>

              {/* Lazada */}
              <FieldArray label={t("Contact.EditContact.SocialMedia.Lazada")} 
                  content={rd_customerData.lazada} className="col mt-3"/>
                  
              {/* Bukalapak */}
              <FieldArray label={t("Contact.EditContact.SocialMedia.Bukalapak")} 
                  content={rd_customerData.bukalapak} className="col mt-3"/>

              {/* Blibli */}
              <FieldArray label={t("Contact.EditContact.SocialMedia.Blibli")} 
                  content={rd_customerData.blibli} className="col mt-3"/>
           
            </div>
         
        </div>
      
      </div>

      {!isTabletOrMobile && (
        <div className="position-sticky d-flex align-items-center bottom-0 h-70px bg-white rounded z-50">
          <Button btnbs="primary" cName="mx-5" onClick={() => handleBack()}>
            {t("Storefront.Button.Back")}
          </Button>
        </div>
      )}

      {isTabletOrMobile && (
        <div
          className="p-5 w-100 shadow-sm bg-white position-fixed text-end"
          style={{ bottom: "90px", right: 0, zIndex: "998" }}
        >
          <Link
            to={`/contact/contact-detail/settings/${customer.id}`}
            state={{
              id: customer.id,
              name:
                customer.firstName +
                (customer.lastName ? " " + customer.lastName : ""),
            }}
            className={"btn btn-primary align-self-center justify-content-end "}
          >
            {t("CD.Button.EditProfile")}
          </Link>
        </div>
      )}
    </>
  );
};
