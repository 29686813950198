import {useListView} from '../../../../core/list/ListViewProvider'
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ReduxCampaign from '../../../../../modules/campaign/redux/CampaignSlice'
import * as ReduxCampaignTarget from '../../../../../modules/campaign/target/redux/CampaignTargetSlice'
import * as ReduxTemplate from '../../../../../modules/template/redux/TemplateSlice'

const CampaignToolbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const {setItemIdForUpdate} = useListView();

  const onCreateHandler = () => {
    dispatch(ReduxCampaign.cleanReduxCampaign(true));
    dispatch(ReduxCampaignTarget.cleanReduxCampaignTarget(true));
    dispatch(ReduxCampaignTarget.setColumns([]));
    dispatch(ReduxTemplate.cleanReduxTemplate(true));
    dispatch(ReduxCampaign.setCampaignTemplateFinish(false));
  };

  return (
    <div
      className={clsx(
        `d-flex justify-content-end`,
        isTabletOrMobile ? "flex-column gap-5 w-100" : ""
      )}
      data-kt-user-table-toolbar="base"
    >
      <Link className="btn btn-primary" to="/campaign/create" onClick={onCreateHandler}>
        <span>
          <i className="bi bi-plus-lg fs-4 me-2"></i>{t("Campaign.Button.CreateCampaign")}
        </span>
      </Link>
      {/* <CampaignFilter /> */}

      {/* begin::Filter */}
      <button type="button" className="btn btn-light-primary me-3" disabled>
        <span className="bi bi-funnel-fill" />
        {t("Common.Filter")}
      </button>
      {/* end::Filter */}

      {/* begin::Handle Selected */}
      {/* <button
        type="button"
        className="btn btn-primary"
        // onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {t("CIQ.Button.Handle")}
      </button> */}
      {/* end::Handle */}

      {/* {selected.length > 0 ? 
      <button
        type='button'
        className='btn btn-primary'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {t("CIQ.Button.DeleteSelected")}
      </button> : <button
        type='button'
        className='btn btn-danger' disabled
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {t("CIQ.Button.HandleSelected")}
      </button>} */}
    </div>
  );
}

export {CampaignToolbar}
