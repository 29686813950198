import Customer from "../../../models/Customer";

interface InitialState {
  listContact: Customer[];
  countContact: number;
  currentPageContact: number;
  maxPageContact: number;
  arrayContactPagination: string[];
  contactData: Customer;
  navCount: number;
}
const ContactListAction: string = "ContactListAction";

export type unsubFN = () => void;

export default InitialState;
export { ContactListAction };
