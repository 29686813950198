import React, { FC, useMemo } from "react";
import { ID } from "../../../../../../resources/helpers";
import Customer from "../../../../../models/Customer";
import { useFirestoreTable } from "../../context/TableContext";
import { useListView } from "../../core/ListViewProvider";

type Props = {
  customer: Customer;
};

const TargetSelectionCell: FC<Props> = ({ customer }) => {
  // const {selected, onSelect} = useListView()
  // const isSelected = useMemo(() => selected.includes(id), [id, selected])
  const { selectedItems, setSelectedItems } = useFirestoreTable();

  const isSelected = //Centang" item" yang sudah dipilih sebelumnya saat muat modal
    customer.id &&
    selectedItems.some((cust) => cust.id && cust.id === customer.id)
      ? true
      : false;

  const onSelect = () => {
    setSelectedItems((prev) => {
      if (!customer.id) {
        return prev;
      }
      if (isSelected) { 
        console.log("deselected: " + prev.filter((cust) => cust.id !== customer.id).map((c) => {return c.phoneNumber;}));
        return prev.filter((cust) => cust.id !== customer.id); //Kembalikan item" tersisa yang dicentang
      }
      console.log("selected: " + prev.map((c) => {return c.phoneNumber;}));
      return [...prev, customer];
    });
  };
  return (
    <div className="form-check form-check-sm form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        data-kt-check={isSelected}
        data-kt-check-target="#kt_table_users .form-check-input"
        checked={isSelected}
        onChange={() => onSelect()}
      />
    </div>
  );
};

export { TargetSelectionCell };
