// All Style
const convertTextStyle = (textMessage: string) => {
  const messageBold: string = textBold(textMessage);
  const messageItalic: string = textItalic(messageBold);
  const messageStrike: string = textStrikeThrough(messageItalic);
  const styledMessage: string = textMonospace(messageStrike);
  return styledMessage;
};

// Bold
const textBold = (text: string) => {
  let replaceBold = true;
  let boldmark: number = text.lastIndexOf("*");

  if (boldmark <= 0) {
    replaceBold = false;
  }
  let countMark = 0;

  while (replaceBold) {
    boldmark = text.lastIndexOf("*");
    countMark += 1;
    let indexBold: number = text.indexOf("*");

    if (indexBold < boldmark) {
      if (indexBold !== -1) {
        if (countMark % 2 === 1) {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "<strong>" + boldtemp2;
        } else {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "</strong>" + boldtemp2;
        }
      } else {
        replaceBold = false;
      }
    } else {
      if (indexBold !== -1) {
        if (countMark % 2 === 0) {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "</strong>" + boldtemp2;
        }
      }
      replaceBold = false;
    }
  }
  return text;
};

// Italic
const textItalic = (text: string) => {
  let replaceItalic = true;
  let italicmark: number = text.lastIndexOf("_");

  if (italicmark <= 0) {
    replaceItalic = false;
  }
  // let startAt = 0;
  let countMark = 0;

  while (replaceItalic) {
    italicmark = text.lastIndexOf("_");
    countMark += 1;
    let indexItalic: number = text.indexOf("_");

    if (indexItalic < italicmark) {
      if (indexItalic !== -1) {
        if (countMark % 2 === 1) {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "<em>" + italictemp2;
        } else {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "</em>" + italictemp2;
        }
      } else {
        replaceItalic = false;
      }
    } else {
      if (indexItalic !== -1) {
        if (countMark % 2 === 0) {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "</em>" + italictemp2;
        }
      }
      replaceItalic = false;
    }
  }
  return text;
};

// Strikethrough
const textStrikeThrough = (text: string) => {
  let replaceStrike = true;
  let strikemark: number = text.lastIndexOf("~");

  if (strikemark <= 0) {
    replaceStrike = false;
  }
  let countMark = 0;

  while (replaceStrike) {
    strikemark = text.lastIndexOf("~");
    countMark += 1;
    let indexStrike: number = text.indexOf("~");

    if (indexStrike < strikemark) {
      if (indexStrike !== -1) {
        if (countMark % 2 === 1) {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "<s>" + striketemp2;
        } else {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "</s>" + striketemp2;
        }
      } else {
        replaceStrike = false;
      }
    } else {
      if (indexStrike !== -1) {
        if (countMark % 2 === 0) {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "</s>" + striketemp2;
        }
      }
      replaceStrike = false;
    }
  }
  return text;
};

// Monospace
const textMonospace = (text: string) => {
  let replaceMono = true;
  let monomark: number = text.lastIndexOf("```");

  if (monomark <= 0) {
    replaceMono = false;
  }
  let countMark = 0;

  while (replaceMono) {
    monomark = text.lastIndexOf("```");
    countMark += 1;
    let indexMono: number = text.indexOf("```");

    if (indexMono < monomark) {
      if (indexMono !== -1) {
        if (countMark % 2 === 1) {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "<tt>" + monotemp2;
        } else {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "</tt>" + monotemp2;
        }
      } else {
        replaceMono = false;
      }
    } else {
      if (indexMono !== -1) {
        if (countMark % 2 === 0) {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "</tt>" + monotemp2;
        }
      }
      replaceMono = false;
    }
  }
  return text;
};


export { convertTextStyle }