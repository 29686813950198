import Modal from "../Modal";

type ModalConfirmSaveProps = {
  onHide: () => void;
  onCancel: () => void;
  onConfirm: (responseSubmit?: any) => void;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen: boolean;
  title?: string;
  message?: string;
  type: "save" | "cancel";
};
const ModalConfirmation: React.FC<ModalConfirmSaveProps> = (props) => {
  return (
    <Modal
      id="ModalConfirmation"
      title={props.title ?? "Modal Confirmation"}
      onConfirm={(data) => props.onConfirm(data)}
      onHide={props.onHide}
      onCancel={props.onCancel}
      confirmOption={props.confirmOption ?? "Confirm"}
      cancelOption={props.cancelOption ?? "Cancel"}
      isModalOpen={props.isModalOpen}
      centered={true}
      size="lg"
      closeBtn={false}
    >
      {props.message && <span>{props.message}</span>}
      {!props.message && props.type === "save" && (
        <span>Do you want to save the changes?</span>
      )}
      {!props.message && props.type === "cancel" && (
        <span>
          Are you sure you want to cancel? Any unsaved changes will be lost.
        </span>
      )}
    </Modal>
  );
};

export default ModalConfirmation;
