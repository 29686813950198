import clsx from "clsx";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { Campaign } from "../../../../../../models/Campaign";
import * as CampaignReportRedux from "../../../../../../modules/campaign/report/CampaignReportSlice";

type Props = {
  campaign: Campaign;
  id?: string;
};

const CampaignReportActionCell: FC<Props> = ({ campaign, id }) => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex align-items-center">
      <button
        data-testid={id || "report-action"}
        type="button"
        className="btn btn-light btn-active-light-primary btn-sm"
        onClick={() => {
          if (campaign.dateSent) {
            dispatch(CampaignReportRedux.setSelectedRepTime(campaign.dateSent));
          }
        }}
      >
        Detail
      </button>
    </div>
  );
};

export { CampaignReportActionCell };
