import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../db";

/**
 * @deleteFile
 * @return errorString
 */
export const deleteFile = async (firebaseUrl: string, id?: string) => {
  console.log(`file ${firebaseUrl}`);
  var fileRef = storage.refFromURL(firebaseUrl);
  // Delete the file
  return fileRef
    .delete()
    .then(() => {
      // File deleted successfully
      console.log(`file ${id} url ${firebaseUrl} removed`);
      return undefined;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.error(`Error file remove ${id} url ${firebaseUrl}`);
      return error;
    });
};

export const uploadFile = async (
  file: File,
  path: string
): Promise<string | undefined> => {
  if (!file) return undefined;

  try {
    const storageRef = ref(storage, path);

    await uploadBytes(storageRef, file);

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);

    return String(error);
  }
};

export async function uploadFileToStorage(
  path: string,
  file: any,
  metadata: any
) {
  try {
    const snap = await storage.ref(path).put(file, metadata);

    const mediaUrl = await snap.ref.getDownloadURL();

    return {
      metaData: snap.metadata,
      mediaUrl,
    };
  } catch (error) {
    console.log("Error uploadTaskPromise", error);
    return undefined;
  }
}
