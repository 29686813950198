import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as ModalExportOrderRedux from "./ModalExportOrderRedux";

export const ModalExportOrderSlice = createSlice({
  name: "ModalExportOrderRedux",
  initialState: ModalExportOrderRedux.initialState,
  reducers: {
    setIsModalOpen(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
    },
    setResponseExportOrder(state, action: PayloadAction<ModalExportOrderRedux.ResponseRTS | undefined>) {
      state.responseExportOrder = action.payload;
    },
  },
});

export const { setIsModalOpen, setResponseExportOrder } = ModalExportOrderSlice.actions;
export default ModalExportOrderSlice.reducer;
