/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { FC } from "react";
import { KTSVG } from "../../../resources/helpers";
import { useLayout } from "../core";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import "../../../styles/css/toolbar.scss";
import * as lc from '../../../app/modules/localstorage'
import { useTranslation } from "react-i18next";


const Toolbar1: FC = () => {
  const { classes } = useLayout();
  const { t } = useTranslation();

  // const selectedCompany = () => {
  //   const CID = lc.getItemLC("CID");
    
  //   // 
  //   //if CID not null, return company name //Bryan
  //   const companyList = lc.getItemLC(lc.LCName.CompanyList)
  //   //if CID null, return "Select Company"
  //   if (!CID || companyList.length <= 0) {
  //     return (
  //       <a className="btn btn-secondary">
  //         {/* image company */}
  //         <i className="far fa-building fs-1"></i>
  //         {/* company name */}
  //         <span className="text-dark">{t("Common.Input.CompanySelect")}</span>
  //         <i className="bi bi-triangle-fill rotate180"></i>
  //       </a>
  //     );
  //   }
  //   if (companyList.length > 0) {
  //     const activeCompany : CompanyData = companyList.find((company: { id: string; companyName: string; avatar: string}) => company.id === CID)
  //     return (
  //       <a className="btn btn-secondary">
  //         {/* image company */}
  //         {activeCompany?.avatar ? (
  //           <img id={`avatarCompany_${activeCompany.id}`} src={activeCompany.avatar} alt="company" className="h-20px"/>
  //         ) : (
  //           <i className="far fa-building fs-1"></i>
  //         )}
  //         {/* company name */}
  //         <span className="text-dark">{activeCompany.companyName}</span>
  //         <i className="bi bi-triangle-fill rotate180"></i>
  //       </a>
  //     );
  //   }
  // }

  return (
    <div className="py-2 m-0" id="kt_toolbar">
      {/* begin::Container */}
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(" "),
          "container-fluid d-flex flex-stack pb-0 mb-0 ps-4"
        )}
      >
        <DefaultTitle />
      </div>
    </div>
  );
};

export { Toolbar1 };
