import { FC , useEffect, useState} from "react";
import { connect, ConnectedProps, useDispatch , useSelector} from "react-redux";
import {RootState} from "../../../../setup/redux/store";
import * as Chat from "../../../../actions/chat";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import * as lc from '../../../modules/localstorage/index'
import FriendChatItem from "./FriendChatItem";

const mapState = (state: RootState) => ({ chat: state.Chat })
const connector = connect(mapState, chat.ChatSlice.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const FriendChatList: FC<PropsFromRedux> = (props) => {
  let collablist = useSelector((state: RootState) => state.Chat.userChatList); //list user chat Collaboration
  const dispatch = useDispatch();
  const chatOpenHandler = (id: string) => {
    dispatch(chat.setSelectedUserChat(id));
    dispatch(chat.updateSortUserChatList());
    
  }

  useEffect(() => {
    const currentUser = lc.getItemLC(lc.LCName.User);
    if(!currentUser) return;
    const user = {
      company: lc.getItemLC(lc.LCName.CompanyID),
      uid: currentUser.uid,
    }

    //TODO check user chat list
    if (!collablist || collablist.length <= 0) {
      Chat
      .fetchFriendCollaborations(user.uid)
      .then((collabs) => {
        collablist = collabs;
        //TODO set user chat list
        dispatch(chat.setUserChatList(collabs))
      })
    }
  },[]);
  
  return (
    <div id="kt_chat_contacts_body" style={{overflow:"hidden", marginBottom:"10px"}}>
            <div
            style={{
              width: "100%",
              height:"100%",
              maxHeight:"100%",
              border: "1px",
              overflowY: "scroll",
            }}
            >
            {
             collablist.map((chatListItem) => {
                return <FriendChatItem item={chatListItem} key={chatListItem.id} onOpenChat={chatOpenHandler}/>
              })
            }
      </div>
    </div>
  );
}


export default connector(FriendChatList);