import React, { FC, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KTSVG } from "../../../../resources/helpers";

import * as ReduxStorefront from "../../product/storefront/StorefrontSlice";
import * as ReduxMarketplaceProductCategory from "../../product/marketplaceProductCategory/marketplaceProductCategorySlice";
import ModalSelectProductCategory from "../modalSelectProductCategory";
import * as servStorefront from "../../../../db/serviceStorefront";
import { RootState } from "../../../../setup/redux/store";
import { getMarketplaceProductCategory } from "../../../../actions/storefront";

interface MarketplaceCategoryProps {
  title: string;
  step: string;
  isSubmitted?: any;
  id: string;
  onConfirmCategory?: (categories: any[]) => void;
}

function hasItemWithKeyAndValue(arr: [], key: string, value: any) {
  return arr.some((item) => {
    return item[key] === value;
  });
}

const findCategoryIndex = (arrays: [], key: string, value: any) => {
  let current = arrays.findIndex((items: any) => {
    return hasItemWithKeyAndValue(items, key, value);
  });
  return current;
};

const MarketplaceCategoryModalMobile: React.FC<MarketplaceCategoryProps> = ({
  title,
  step,
  isSubmitted,
  id,
  onConfirmCategory,
}) => {
  const { t, i18n } = useTranslation();

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const listMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );
  const marketplaceName = title.split(" ").pop();

  const isCurrentMarketplaceHasSelectedCategory: any = listMarketplace.some(
    (marketplace: any) => marketplace.name?.toLowerCase() === marketplaceName?.toLowerCase()
  );
  const currentMarketplaceSelected: any = listMarketplace.find(
    (marketplace: any) => marketplace.name?.toLowerCase() === marketplaceName?.toLowerCase()
  );
  const currentCategory = currentMarketplaceSelected?.category;
  console.log("MOBILE ", currentCategory)

  const dispatch = useDispatch();

  const isChecked = currentMarketplaceSelected?.isChecked || false;

  // const [categories, setCategories] = useState<any[]>(); //data from firebase
  const [arrayData, setArrayData] = useState<any>([[]]); //data in modal
  const [arrayParentList, setArrayParentList] = useState<any>([]); //parent list
  const [arrayChildren, setArrayChildren] = useState<any>([]); //children yg di gonta ganti
  const [selectedCategories, setSelectedCategories] = useState<any>([]); //yg di select

  useEffect(() => {
    if (marketplaceName?.toLowerCase() === "shopee" && reduxShopeeProductCategoryData) {
      if (!reduxStorefrontId && step !== "summary" && reduxShopeeProductCategoryData && reduxShopeeProductCategoryData.length > 0) {
        let children: any = reduxShopeeProductCategoryData?.filter((category: any) => {
          return category.parentCategoryId === "0";
        });
        let newArray = [...arrayChildren];
        newArray[0] = children;
        setArrayChildren(newArray);
      }else if(reduxStorefrontId && step !== "summary" && reduxShopeeProductCategoryData && reduxShopeeProductCategoryData.length > 0){

      }
    }
  }, [reduxShopeeProductCategoryData]);

  const handleParentSelectedCategory = (selectedCategory: any) => {
    setIsValidSelectedCategory(false);
    if (selectedCategory.parentCategoryId === 0) {
      let children: any = reduxShopeeProductCategoryData?.filter((category) => {
        return category.parentCategoryId === 0;
      });
      let newArray = [...arrayChildren];
      newArray[0] = children;
      setArrayChildren(newArray);
      setArrayParentList([]);
      const categoryToReplace = selectedCategories.slice(0, 1);
      setSelectedCategories(categoryToReplace);
      setIsValidSelectedCategory(false);
      return;
    }
    const currentIndex = arrayParentList.findIndex((items: any) => {
      return items.categoryId === selectedCategory.categoryId;
    });
    const newArrayParentList = arrayParentList.slice(0, currentIndex);
    setArrayParentList(newArrayParentList);
    let children: any = reduxShopeeProductCategoryData?.filter((category) => {
      return category.parentCategoryId === selectedCategory.parentCategoryId;
    });
    const newArrayChildren = arrayData.slice(0, arrayChildren.length);
    children = [...newArrayChildren, children];
    setArrayChildren(children);
    const getFinalCategoryIndex = selectedCategories.findIndex(
      (items: any) => {
        return items.id === selectedCategory.categoryId;
      }
    );
    const categoryToReplace = selectedCategories.slice(
      0,
      getFinalCategoryIndex + 1
    );
    setSelectedCategories(categoryToReplace);
    if (selectedCategory.hasChildren === true) {
      setIsValidSelectedCategory(false);
    }
  };

  const handleSelectedCategory = (selectedCategory: any) => {
    if (selectedCategory.parentCategoryId === 0) {
      setSelectedCategories([]);
      setIsValidSelectedCategory(false);
    }

    let parent: any = reduxShopeeProductCategoryData?.filter((category) => {
      return (
        category.categoryId === selectedCategory.categoryId &&
        category.hasChildren === true
      );
    });
    let parentlist;
    if (parent.length > 0) {
      parentlist = [...arrayParentList, ...parent];
      setArrayParentList(parentlist);
    }
    const currentIndex = findCategoryIndex(
      arrayData,
      "categoryId",
      selectedCategory.categoryId
    );

    const newArrayCategories = arrayData.slice(0, currentIndex + 1);

    let children: any = reduxShopeeProductCategoryData?.filter((category) => {
      return category.parentCategoryId === selectedCategory.categoryId;
    });
    let dataPush;
    if (children.length > 0) {
      dataPush = [...newArrayCategories, children];
      setArrayChildren(dataPush);
      return ;
    } 
  
    let category: any = reduxShopeeProductCategoryData?.filter((category) => {
      return category.categoryId === selectedCategory.categoryId;
    });
    dataPush = [...arrayParentList, category[0]];
    for (let index = 0; index < dataPush.length; index++) {
      const currentPiece = {
        id: dataPush[index].categoryId,
        level: index,
        hasChildren: dataPush[index].hasChildren,
        name : {
          id: dataPush[index].categoryName.id,
          en: dataPush[index].categoryName.en,
        }
      };
      dataPush.splice(index, 1, currentPiece);
    }
    setIsDropdownShow(false);
    setIsValidSelectedCategory(true);
    //set to redux
    if (dataPush && dataPush.length > 0) {
      const categoryToAdd = dataPush;
      const data: any = {
        name: marketplaceName?.toLowerCase(),
        isChecked,
        category: [],
      };
      data.category.push(categoryToAdd);
      const currentListMarketplace: any = [...listMarketplace];
      const categoryIndexToReplace = currentListMarketplace.findIndex(
        (marketplace: any) => marketplace.name?.toLowerCase() === marketplaceName?.toLowerCase()
      );
      if (categoryIndexToReplace !== -1) {
        currentListMarketplace[categoryIndexToReplace] = data;
      } else {
        currentListMarketplace.push(data);
      }
      console.log("categoryToAdd : ", categoryToAdd);
      setSelectedCategories(categoryToAdd);
      dispatch(ReduxStorefront.setStorefrontListMarketplace(currentListMarketplace));

      console.log(`handle on confirm ${JSON.stringify(currentListMarketplace)}`)
      onConfirmCategory && onConfirmCategory(categoryToAdd);
    }
    
  };
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [isValidSelectedCategory, setIsValidSelectedCategory] = useState(true);

  const currentCategories:any = listMarketplace.find((cat:any) => cat.name === id)
  const reduxStorefrontId = useSelector((state: RootState) => state.Storefront.storefrontID);

  useEffect(() => {
    if(id?.toLowerCase() === "shopee"){
      if(!reduxShopeeProductCategoryData || reduxShopeeProductCategoryData.length <= 0){
        getMarketplaceProductCategory(id?.toLowerCase() ).then(async (response) => {
          if(response){
            dispatch(ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(response))
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    if (currentCategories && currentCategories.category.length > 0) {
      let listCategories = [...currentCategories.category];
      let newFinal: any = [];
      let tempParentList: any = [];
      listCategories.map((list: any) => {
        list.map((cat: any, i: number) => {
          let children: any = reduxShopeeProductCategoryData?.find(
            (category: any) => category.categoryId === cat.id
          );
          const currentPiece = {
            id: cat.id,
            level: cat.level,
            hasChildren: cat.hasChildren,
            name: cat.name,
          };

          newFinal[i] = currentPiece;
          setSelectedCategories(newFinal);

          if (children.hasChildren) {
            tempParentList[i] = children;
            setArrayParentList(tempParentList);
          } else {
            let tempChildren: any = reduxShopeeProductCategoryData?.filter(
              (category: any) => {
                return category.parentCategoryId === children.parentCategoryId;
              }
            );
            let newArray = [...arrayChildren];
            newArray[0] = tempChildren;
            setArrayChildren(newArray);
          }
        });
      });
    } 
  }, [currentCategory]); // eslint-disable-line

  const checkedWithNoCategory: any = listMarketplace.find(
    (marketplace: any) => marketplace.isChecked === true && marketplace.category.length === 0
  );

  const isAllUnchecked = listMarketplace.every(
    (marketplace: any) => marketplace.isChecked === false
  );

  return (
    <>
      <div
        className={clsx(
          `d-flex flex-column ${step === "summary" ? "w-100" : ""}`
        )}
      >
        <div
          id="marketplaceCategory"
          className={clsx(`border rounded-top d-flex btn-group`, {
            "pointer-none": !isChecked,
            "w-100": step === "summary",
            "border-gray-900": isChecked,
            "border-gray-300": !isChecked,
            "rounded-bottom": !isDropdownShow,
          })}
        >
          <button
            className={clsx(
              "d-flex flex-row justify-content-between btn w-100 text-gray-500 align-items-center px-4",
              {
                "text-gray-900": isChecked,
              }
            )}
            id="btnselectedcategory"
            style={{
              alignItems: "left",
            }}
            onClick={() => {
              if (isValidSelectedCategory === true) {
                setIsDropdownShow(!isDropdownShow);
              }
            }}
          >
            {selectedCategories.length <= 0 && (
              <span>
                {t("Storefront.Input.SelectMarketPlace").replace(
                  "<<marketplace>>",
                  title
                )}
              </span>
            )}
            {selectedCategories.length > 0 && (
              <span>
                {selectedCategories?.map((category: any) => {
                  console.log(
                    "check selected categories : " +
                      JSON.stringify(selectedCategories)
                  );
                  return (
                    <>
                      {i18n.language === "id"
                        ? category.name.id
                        : category.name.en}{" "}
                      {category.hasChildren ? " > " : ""}
                    </>
                  );
                })}
              </span>
            )}
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className={clsx("svg-icon", { "svg-icon-dark": isChecked })}
            />
          </button>
        </div>
        {isChecked && isDropdownShow && (
          <div
            className="d-flex flex-column w-100 border border-top-0 border-dark rounded-bottom px-3"
            style={{
              maxHeight: "40vw",
              overflowY: "hidden",
              backgroundColor: "#F0F0F0",
            }}
          >
            <div className="d-flex flex-column p-2">
              {arrayParentList &&
                arrayParentList.length > 0 &&
                arrayParentList.map((category: any, i: any) => {
                  return (
                    <>
                      {category.hasChildren && (
                        <div
                          className="d-flex flex-row justify-content-between mb-2 px-1 fw-bolder"
                          role="button"
                          onClick={() => handleParentSelectedCategory(category)}
                          key={category.categoryId}
                        >
                          {i18n.language === "id"
                            ? category.categoryName.id
                            : category.categoryName.en}
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr072.svg"
                            className={clsx("svg-icon", {
                              "svg-icon-dark": isChecked,
                            })}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              <div
                style={{
                  maxHeight: "40vw",
                  overflowY: "scroll",
                  backgroundColor: "#F0F0F0",
                }}
              >
                {arrayChildren &&
                  arrayChildren?.map((category: any, i: any) => {
                    return (
                      <>
                        {category &&
                          category?.map((cat: any, i: number) => {
                            const isCategoryActive = selectedCategories.some(
                              (u: any) => {
                                return u.id === cat.categoryId;
                              }
                            );
                            return (
                              <div
                                className={clsx(
                                  `d-flex flex-row justify-content-between mb-2 px-1`,
                                  {
                                    "bg-primary text-white rounded":
                                      isCategoryActive,
                                  }
                                )}
                                role="button"
                                onClick={() => handleSelectedCategory(cat)}
                                key={cat.categoryId}
                              >
                                {i18n.language === "id"
                                  ? cat.categoryName.id
                                  : cat.categoryName.en}
                                {cat.hasChildren && (
                                  <KTSVG
                                    path="/media/icons/duotune/arrows/arr071.svg"
                                    className={clsx("svg-icon", {
                                      "svg-icon-dark": isChecked,
                                      "svg-icon-white": isCategoryActive,
                                    })}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
      {step === "summary" && id === title && isSubmitted && isAllUnchecked && (
        // checkedWithNoCategory &&
        <p className="text-danger">{t("Storefront.Alert.SelectMarketplace")}</p>
      )}

      {step === "summary" &&
        id === title &&
        // isSubmitted &&
        checkedWithNoCategory &&
        id === checkedWithNoCategory.name && (
          <p className="text-danger p-2">
            {t("Storefront.Alert.SelectMarketplace")}
          </p>
        )}
    </>
  );
};

export default MarketplaceCategoryModalMobile;