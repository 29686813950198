import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
// import { KTSVG } from "../../../../../resources/helpers";
// import { KTCard } from "../../../../../resources/helpers/components/KTCard";
// import { Order } from "../../../../models/Order";
import { Process } from "../../../../models/Process";
// import OrderCard from "./card/OrderCard";
import Pagination from "./components/Pagination";
// import SelectFilter from "./components/SelectFilter";
// import StatusFilter from "./components/StatusFilter";
// import TableHeader from "./components/TableHeader";
import ProcessCard from "./card/ProcessCard";
import TableHeader from "./components/TableHeader";
import { TableProvider } from "./context/TableContext";
import ProcessTable from "./table/ProcessTable";
// import OrderModal from "./modal/OrderModal";
// import OrderTable from "./table/ProcessTable";

const ProcessList = () => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <TableProvider<Process> collection="processes">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* <div className="card py-2">
            <StatusFilter />
          </div>
          <div className="card py-2 my-2 bg-transparent">
            <SelectFilter />
          </div> */}
          <div
            style={{
              height: isMobileView ? "100%" : "100%",
              overflow: "hidden",
            }}
            className="card p-3"
          >
            <TableHeader />

            {isMobileView && <ProcessCard />}
            {!isMobileView && <ProcessTable />}

            <Pagination />
          </div>
        </div>

        {/* <OrderModal /> */}
      </TableProvider>
    </>
  );
};

export default ProcessList;
