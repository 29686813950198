import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle, PageLink } from "../layout/core/PageData";
import ChatWrapper from "../layout/chat-handle/components/ChatWrapper";
import ChatFeature from "../layout/chat-feature";
import { useMediaQuery } from "react-responsive";

const HandleChat: FC = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const usersBreadcrumbs: Array<PageLink> = [
    // {
    //   title: t('SideBar.MenuItem.HC'),
    //   path: '/handled-customer',
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: '',
    //   path: '',
    //   isSeparator: true,
    //   isActive: false,
    // },
  ]

  return (
    <div
      className="d-flex flex-column w-100 h-100 row-gap-3 overflow-scroll">
      <PageTitle breadcrumbs={usersBreadcrumbs}>{t('SideBar.MenuItem.HC')}</PageTitle>
      <ChatWrapper />
      {/* <ChatFeature /> */}
    </div>
  );
};

export { HandleChat };