import {
  ListViewProvider,
  useListView,
} from "../campaign-info/core/ListViewProvider";
// './core/list/ListViewProvider'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../setup/redux/store";
import * as CampaignReportRedux from "../../../../modules/campaign/report/CampaignReportSlice";
import { CampaignDetailHeader } from "./components/header/CampaignDetailHeader";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { CampaignDetails } from "./table/CampaignsReportDetailTable";

const CampaignReportDetailList = () => {
  const { itemIdForUpdate } = useListView();
  const dispatch = useDispatch();

  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );

  const selectedRepTime = useSelector(
    (state: RootState) => state.CampaignReport.selectedRepTime
  );

  useEffect(() => {
    return () => {
      dispatch(CampaignReportRedux.setSelectedRepTime(undefined));
    };
  }, []);

  console.log("DelRep Data Camp Rep", campaignData?.scheduleConfig);
  return (
    <>
      {(!campaignData?.campaignMessagesCount?.repetitions ||
        (campaignData?.campaignMessagesCount?.repetitions &&
          selectedRepTime)) && (
        <div className="card mb-2">
          <CampaignDetailHeader />
          <CampaignDetails />
        </div>
      )}
    </>
  );
};

const CampaignReportDetailWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CampaignReportDetailList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { CampaignReportDetailWrapper };
