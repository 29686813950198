import axios, { AxiosResponse } from "axios";
import { createRef } from "../../../../../../db/connection";
import { getAllProductList, getProductById, getProductMarketplaceCategoryById } from "../../../../../../db/serviceProduct";
import { ID } from "../../../../../../resources/helpers";
import * as lc from "../../../../../modules/localstorage";
import Product, { ProductQueryResponse } from "../../../../../models/Product"

import firebase from "firebase/compat/app";
import { deleteProductData } from "../../../../../../actions/product";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

const getProducts = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<Product>> => {

  let productQueryResponse: ProductQueryResponse;
  console.log("item : " + limit);
  console.log("action : " + action);

  let sortBy: string = "name";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }
  console.log("order =====>>" + orderBy);

  //TODO proses get data
  let dataProducts: any = [];
  let dataProduct = undefined;
  // let marketplaceCategory: any;
  let clientId = "";

  const localClient = lc.getItemLC(lc.LCName.Client)
  if(localClient){
    clientId = localClient.id
  }

  console.log("client ID",clientId)
  
  dataProducts = await getAllProductList(clientId) ;

  console.log("PRODLIST dataProduct", dataProducts)

  // if(dataProduct && dataProduct.length>0){
  //   for (var i = 0; i < dataProduct.length ; i++) {
  //     let data = dataProduct[i].data;
  //     data.id = dataProduct[i].id;

  //     if(data.id){
  //       marketplaceCategory = await getProductMarketplaceCategoryById(data.id);
  //       if(marketplaceCategory){
  //         data.marketplaceCategory = marketplaceCategory;
  //       }
  //     }
  //     console.log("data.marketplaceCategory : ", data.marketplaceCategory);

  //     if(!data.numberOfProducts){
  //       data.numberOfProducts = 0;
  //     }

  //     dataProducts.push(data as Product);
  //   }
  // }

  return Promise.resolve(dataProducts);
};

const deleteProduct = (productId: ID): Promise<void> => {
  if (productId === undefined || productId === null) {
  } else {
    deleteProductData(productId.toString());
  }

  return Promise.resolve();
};

const getProductMarketplaceDataById = (productId: ID): Promise<void> => {
  if (productId === undefined || productId === null) {
  } else {
    getProductMarketplaceCategoryById(productId.toString());
  }
  return Promise.resolve();
};

const deleteSelectedProducts = (productsId: Array<ID>): Promise<void> => {
  const requests = productsId.map((id) => {
    if (id === undefined || id === null) {
    } else {
      console.log("Delete id : " + id);
      deleteProductData(id.toString());
    }
  });

  return axios.all(requests).then(() => {});
};

export {
  getProducts,
  getProductMarketplaceDataById,
  deleteProduct,
  deleteSelectedProducts,
};
