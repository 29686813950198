import React, { FC } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Message from "../../../../models/Message"

const containerStyle = {
  width: '265px',
  height: '265px'   
};

interface ChatLocationProps {
    item: Message;
    width?: any;
    height?: any;
  }

export const MessageLocation: FC<ChatLocationProps> = (props) => {
    const {item, width, height} = props;
    let mapStyle = {
      height: '0px',
      width: '0px'
    };
    // const mapURL = "maps.google.com/maps?q=-7.310778617858887%2C112.66867065429688&z=17&hl=en"
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBY-st3GrutMRi2M18YKmTG1KdGwvZNNns"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
  
    let center = {
        lat: 0,
        lng: 0
    };

  if(item.location?.latitude !== undefined && item.location?.longitude !== undefined){
    center.lat = item.location?.latitude;
    center.lng = item.location?.longitude;
  }

  if (width !== "" && height !== "") {
    //change mapStyle
    mapStyle = {
      width: width,
      height: height,
    };
  }

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={mapStyle.width && mapStyle.height ? mapStyle : containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={{ lat: center.lat, lng: center.lng }} />
      </GoogleMap>
  ) : <></>
}
