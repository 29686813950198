import { format } from "date-fns";
import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
import { getCustomerDataByPhoneNoAndClient } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import * as campaignServices from "../../../../../../db/serviceCampaign";
import { CampaignMessage } from "../../../../../models/Campaign";
import Customer from "../../../../../models/Customer";
import * as CampaignMessageRedux from "../../../../../modules/campaign/message/CampaignMessageSlice";
import * as lc from "../../../../../modules/localstorage/index";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

const getCampaignMessages = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number,
  campaignId: string
  // company: firebase.firestore.DocumentReference
): Promise<Array<CampaignMessage>> => {
  let sortBy: string = "id";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }

  let campaignMessages;
  let newCampaignMessages: CampaignMessage[] = [];

  let campaignReportDetails: any = [];

  campaignMessages = await campaignServices.fetchMessagesByCampaignID(
    campaignId
  );

  if (campaignMessages) {
    // // Fetch Customer Data
    const withDetails = await Promise.all(
      campaignMessages.map(async (message) => {
        let data = message.data;
        let reportDetailData;

        // ["customerName","phoneNumber","updatedAt","message","status"]
        reportDetailData = {
          phoneNumber: data.phoneNumber,
          message: data.message,
          status: data.status,
          isRepTime: data.isRepTime,
          type: data.type ?? 'Whatsapp'
        };

        // Insert ID
        if (message.id) {
          data.id = message.id;
        }
        // console.log(
        //   "getCustomerDataByPhoneNoAndCompany logger: ",
        //   data.phoneNumber,
        //   company.id
        // );
        // Get Customer Data
        const client = lc.getItemLC(lc.LCName.Client);
        const clientRef = createRef("clients", client?.id);
        const customers = await getCustomerDataByPhoneNoAndClient(
          data.phoneNumber,
          clientRef
        );
        let customerData = customers.find((customer) => {
          return customer.data.phoneNumber === data.phoneNumber;
        });
        console.log("customers logger: ", customers);
        if (customerData) {
          // console.log(`Customer Data: ${JSON.stringify(customerData.data)}`)
          data.customerModel = customerData.data as Customer;
          reportDetailData = {
            ...reportDetailData,
            // customerModel: data.customerModel,
            customerName: `${data.customerModel.firstName} ${
              data.customerModel.lastName ? data.customerModel.lastName : ""
            }`,
          };
          console.log("customerName: ", reportDetailData);
        }

        reportDetailData = {
          ...reportDetailData,
          updatedAt: data.updatedAt,
        };

        newCampaignMessages.push(data as CampaignMessage);
        // campaignReportDetails.push(reportDetailData);
        return reportDetailData;
      })
    );
    campaignReportDetails = withDetails;
  }
  return Promise.resolve(campaignReportDetails);
};

export { getCampaignMessages };
