import {
  DocumentData,
  QueryDocumentSnapshot,
  getDoc,
} from "firebase/firestore";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import User from "../../../../../../models/User";
import useFirestoreData, {
  FirestoreQueryState,
} from "../../../../../../../hooks/useFirestoreData";
import * as lc from "../../../../../../modules/localstorage/index";
import SocketConnection from "../../../../../../../setup/socket/socket";
import { id } from "date-fns/locale";

type MemberTableContextValue = {
  memberTableState: FirestoreQueryState<User>;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  updateTableState: (data: Partial<FirestoreQueryState<User>>) => void;
  updatePage: (newPage: number) => void;
  refreshData: () => void;
  isLoading: boolean;
  isFetching: boolean;
  data?: {
    allCount: number;
    items: User[];
  };
};

export const defaultMemberTableState: FirestoreQueryState<User> = {
  clientID: undefined,
  collection: "users",
  sorting: {
    field: "name",
    order: "asc",
  },
  limit: 10,
  filters: [
    {
      field: "status",
      value: ["invited"],
    },
  ],
  currentPage: 1,
  searchKey: {
    field: "email",
    value: "",
  },
};
export const defaultValue: MemberTableContextValue = {
  memberTableState: defaultMemberTableState,
  selectedItems: [],
  setSelectedItems: () => {},
  updateTableState: () => {},
  updatePage: () => {},
  refreshData: () => {},
  isLoading: true,
  isFetching: true,
  data: undefined,
};

export const TableContext =
  createContext<MemberTableContextValue>(defaultValue);
export function useFirestoreTable() {
  return useContext(TableContext);
}

type TableProviderProps<T> = PropsWithChildren<{
  collection: string;
}>;

async function mapUserData(
  docs: QueryDocumentSnapshot<User, DocumentData>[]
): Promise<User[]> {
  return (await Promise.all(
    docs.map(async (doc) => {
      console.log(doc.data(), "coba ini chiy");
      if (lc.getUserId() && doc.id !== lc.getUserId()) {
        return {
          ...doc.data(),
          id: doc.id,
        } as User;
      }
    })
  )).filter((user) => user !== undefined) as User[];
}

export function TableProvider<T>({
  children,
  collection,
}: TableProviderProps<T>) {
  const clientID = lc.getItemLC(lc.LCName.Client)?.id;
  const userID = lc.getItemLC(lc.LCName.UserID);
  console.log(`Client ID ${clientID}`);
  const [memberTableState, setTableState] = useState<FirestoreQueryState<User>>(
    {
      ...defaultMemberTableState,
      collection: collection,
      clientID: clientID,
      refetchInterval: 1000 * 60,
    }
  );

  useEffect(() => {
    const storedState = localStorage.getItem("tableStateMemberlist"); 
    if (storedState) {
      setTableState(JSON.parse(storedState));
    }
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("tableStateMemberlist", JSON.stringify(memberTableState)); 
  }, [memberTableState]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("tableStateMemberlist");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { data, isLoading, isFetching, refetch } = useFirestoreData<User>(
    memberTableState,
    mapUserData
  );

  console.log("user data chiy", data);

  const updateTableState = (data: Partial<FirestoreQueryState<User>>) => {
    let newState = data;

    if (!data.currentPage) {
      newState.currentPage = 1;
    }

    return setTableState((prev: any) => ({
      ...prev,
      ...newState,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
    setSelectedItems([]);
  };

  const refreshData = async () => {
    await refetch();
    setTableState((prev: any) => ({
      ...prev,
      sorting: defaultMemberTableState.sorting,
      currentPage: defaultMemberTableState.currentPage,
    }));
  };

  console.log("Items", data?.items);

  useEffect(() => {
    const socket = SocketConnection();
    const onDataChange = () => refreshData();
    socket.on(`Account-data-update`, onDataChange);

    return () => {
      socket.off(`Account-data-update`, onDataChange);
    };
  }, []);
  console.log("Items context chiy", data?.items);

  const value = {
    memberTableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    updatePage,
    isLoading,
    isFetching,
    refreshData,
    data,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}
