import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { KTCard } from "../../../../../../../resources/helpers";

type SyncResultModalProp = {
  isResultOpen?: boolean;
  isResultClose?: () => void;
  syncRes?: {
    response?: Array<{
      totalNewOrder: number;
      totalUpdatedOrder: number;
    }>;
  };
  syncDate?: Array<string>;
};

export const SyncResultModal: FC<SyncResultModalProp> = ({
  isResultOpen,
  isResultClose,
  syncRes,
  syncDate,
}) => {
  const { t } = useTranslation();

  const [syncedData, setSyncedData] = useState<any>();
  const [syncDateTime, setSyncDateTime] = useState<Array<string>>([]);
  const [totalUpdatedOrder, setTotalUpdatedOrder] = useState<number>(0);

  console.log("SYNCDATE :" + syncDate);
  useEffect(() => {
    console.log("MASUK USEFEK SYNCRESULTMODAL");
    if (syncDate && syncDate.length > 1) {
      setSyncDateTime(syncDate);
    }
    
    if (syncRes && syncRes.response) {
      const responseObject: Array<any> = syncRes.response;
      setSyncedData(responseObject);

      const totalUpdated = responseObject.reduce(
        (acc, resp) => acc + resp.totalUpdatedOrder,
        0
      );
      setTotalUpdatedOrder(totalUpdated);
    }
  }, [syncRes, syncDate]);

  const startDateTime = syncDateTime[0] ? syncDateTime[0].split(" ") : ["", ""];
  const endDateTime = syncDateTime[1] ? syncDateTime[1].split(" ") : ["", ""];

  return (
    <>
      <Modal
        animation
        centered
        show={isResultOpen}
        size="lg"
        scrollable={false}
        onHide={() => isResultClose && isResultClose()}
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title data-testid="modal-title">
            {t("OrderList.Sync.Synced")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <KTCard className="bg-white p-5">
            <div className="row">
              <div className="col-md-4">
                <h4>{t("OrderList.Sync.FirstDate")}</h4>
                <div className="fw-bolder text-dark">
                  {startDateTime[0] + " "}
                </div>
                <div className="fw-bold text-muted">{startDateTime[1]}</div>
              </div>
              <div className="col-md-4">
                <h4>{t("OrderList.Sync.EndDate")}</h4>
                <div className="fw-bolder text-dark">
                  {endDateTime[0] + " "}
                </div>
                <div className="fw-bold text-muted">{endDateTime[1]}</div>
              </div>
              <div className="col-md-4">
                <h4>{t("OrderList.Sync.TotalSync")}</h4>
                {syncedData && (
                  <div>
                    <div>{totalUpdatedOrder}</div>
                  </div>
                )}
              </div>
            </div>
          </KTCard>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <button
            className="btn btn-primary w-25"
            onClick={() => isResultClose && isResultClose()}
          >
            {t("OrderList.Sync.Button")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SyncResultModal;
