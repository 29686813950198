import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import { Timestamp } from "../../../../../db";
import { createRef } from "../../../../../db/connection";
import { useDebounce } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/color.css";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/toggle.scss";
import { KeyValues as KeyValue } from "../../../../../util/Formatter";
import {
  MapMediaSupport,
  MediaType,
} from "../../../../../util/TemplateMediaSupport";
import {
  BodyParamLanguage,
  HeaderParamLanguage,
} from "../../../../models/Language";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import { AlertModal } from "../../../../modules/template/redux/TemplateRedux";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import {
  checkFile,
  formatSize,
} from "../../../chat/components/chat-util/ChatUtil";
import PreviewMessage from "../../../core/preview/PreviewMessage";

let KeyValues: any = KeyValue;

const getSupportedMedia = (
  selectedAccountsId: string[],
  companiesAccounts: any
) => {
  console.log("SAI selected account id: ", selectedAccountsId);
  console.log("SAI companies acc:", companiesAccounts);
  const selectedAccounts = selectedAccountsId.map((id) => {
    const account = companiesAccounts.find((account: any) => account.id === id);
    const suportedMedia: string[] = MapMediaSupport.get(
      account?.type?.toLowerCase()
    );
    return { ...account, suportedMedia };
  });

  console.log("SAI selected account: ", selectedAccounts);

  const allAccountMedia: string[][] = selectedAccounts.reduce(
    (acc: string[][], curr) => [...acc, curr.suportedMedia],
    []
  );

  console.log("SAI all account media", allAccountMedia);

  const selectedMediaSupport =
    allAccountMedia && Array.isArray(allAccountMedia)
      ? allAccountMedia.reduce((acc: string[], curr: string[]) => {
          console.log("SAI Masuk masalah", acc, curr);
          if (!Array.isArray(acc) || !Array.isArray(curr)) {
            return acc; // or handle the error as needed
          }
          if (!acc.length) {
            return curr;
          }
          return acc.filter((item) => curr.includes(item));
        }, [])
      : [];

  return selectedMediaSupport;
};

const getSelectedChannel = (
  selectedAccountsId: string[],
  companiesAccounts: any
) => {
  const selectedChannelAccounts = selectedAccountsId.map((id) => {
    const account = companiesAccounts?.find(
      (account: any) => account.id === id
    );
    let channel = account?.type;
    return channel?.charAt(0)?.toUpperCase() + channel?.slice(1);
  });

  return [...new Set(selectedChannelAccounts)];
};

const getUnsupportedMedia = (supportedMedia: string[]) => {
  const mediaAvailable = MediaType.map((media) => media.toLowerCase());
  return mediaAvailable.filter((media) => !supportedMedia?.includes(media));
};

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  template: any;
  language: any;
  bodyMessage: any;
  previewBody: any;
  currentStep: number | undefined;
  generateLanguage: (
    key: string,
    value: any,
    paramcount: number,
    filename: string,
    bodyParams: BodyParamLanguage[] | undefined,
    headerParams: HeaderParamLanguage[] | undefined
  ) => void;
}

type MessageParameter = {
  item: string;
  label: string;
};

const TemplateFooterComponent: FC<TemplateProps> = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { generateLanguage, currentStep } = props;

  const defaultHeaderAlert: AlertModal = {
    show: false,
    optional: true,
    body: t("Template.Info.HeaderAlert"),
  };
  const defaultFooterAlert: AlertModal = {
    show: false,
    optional: true,
    body: t("Template.Info.FooterAlert"),
  };

  // const [bodyMessage, setbodyMessage] = useState<string>("");
  const reduxFooter = useSelector(
    (state: RootState) => state.Template.templateFooter
  );
  console.log(reduxFooter, "statex");
  const reduxHeader = useSelector(
    (state: RootState) => state.Template.templateHeader
  );
  const reduxTemplateHeaderParams = useSelector(
    (state: RootState) => state.Template.templateHeaderParams
  );
  const reduxTemplateFileType = useSelector(
    (state: RootState) => state.Template.templateFileType
  );
  const reduxOnStepHeaderOrFooter = useSelector(
    (state: RootState) => state.Template.onStepHeaderOrFooter
  );
  const reduxTemplateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  const reduxEmptyTextContentHeaderParam = useSelector(
    (state: RootState) => state.Template.alertEmptyTextContentHeaderParam
  );

  const customTextTitleRef = useRef<HTMLInputElement>(null);
  const [customTextTitleFocus, setCustomTextTitleFocus] =
    useState<boolean>(false);

  // useEffect(() => {delete KeyValues['campaigns.campaignName']}, [reduxTemplateCategory])
  useEffect(() => {
    if (reduxTemplateCategory === "interactive") {
      const { "campaigns.campaignName": deletedProp, ...newObj } = KeyValues;
      // newObj is a new object that doesn't contain "campaigns.campaignName"
      KeyValues = newObj;
    } else if (reduxTemplateCategory !== "interactive") {
      KeyValues = KeyValue;
    }
  }, [reduxTemplateCategory]);

  const [templateFooter, setTemplateFooter] = useState<string>("");
  const [bodyParams, setBodyParams] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const option: any = KeyValues;

  const [lengthTextHeader, setLengthTextHeader] = useState<number>(0);
  const [inputFileExtention, setInputFileExtention] = useState<string>("");
  const [isAttachmentHeader, setIsAttachmentHeader] = useState<boolean>(false);
  const [isTextHeader, setIsTextHeader] = useState<boolean>(false);

  const [file, setFile] = useState<File>();
  const [inputFileName, setInputFileName] = useState<string>("");
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [selectedParameter, setSelectedParameter] =
    useState<MessageParameter>();
  const [headerText, setHeaderText] = useState<string>("");
  const [isHasHeaderParam, setHasHeaderParam] = useState(false);
  const [headerURL, setHeaderURL] = useState<string>("");
  const [headerType, setHeaderType] = useState<string>("");
  const [alertEmptyParamName, setAlertEmptyParamName] =
    useState<boolean>(false);
  const reduxLoading = useSelector(
    (state: RootState) => state.Template.isloading
  );
  const companiesAccounts = useSelector(
    (state: RootState) => state.Template.companiesAccounts
  );
  const selectedAccountsId = useSelector(
    (state: RootState) => state.Template.accountsID
  );
  // const reduxShowAlertHeader = useSelector(
  //   (state: RootState) => state.Template.showAlertHeader
  // )

  type HeaderParam = {
    value: string;
    label: string;
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [selectedHeadParam, setSelectedHeadParam] = useState<HeaderParam>();
  const [showFooter, setShowFooter] = useState(false);
  const [customTextFocus, setCustomTextFocus] = useState(false);
  let [inputParamName, setInputParamName] = useState<string>("");

  type TemplateHeader = {
    id: string;
    title: string;
    description: string;
    icon?: string;
    isInputFile?: boolean;
    disabled: boolean;
    onTemplateChanges?: () => void;
  };

  useEffect(() => {
    console.log(`headerInput is `, headerText);
  }, [headerText]);

  //Get Uploaded File
  const setPreviewFile = async (event: any) => {
    if (event.target.files[0].type === "application/pdf") {
      dispatch(ReduxTemplate.setTemplateHeaderFile(true));
    }

    if (event.target.files[0]) {
      dispatch(ReduxTemplate.setTemplatePreviewHeaderText(""));
      setHasHeaderParam(false);
      setHeaderText("");
      setIsAttachmentHeader(true);
      setFile(event.target.files[0]);

      if (attachmentRef.current) {
        if (attachmentRef.current.value) {
          attachmentRef.current.value = "";
        }
      }
    }
  };

  useEffect(() => {
    if (
      headerType &&
      headerType === "header-text" &&
      !headerText &&
      !showFooter
    ) {
      dispatch(
        ReduxTemplate.setAlertHeader({ ...defaultHeaderAlert, show: true })
      );
      dispatch(ReduxTemplate.setAlertFooter(defaultFooterAlert));
      return;
    }

    if (
      reduxTemplateHeaderParams?.length &&
      reduxTemplateHeaderParams[0].paramType === "customText" &&
      !reduxTemplateHeaderParams[0].textContent
    ) {
      dispatch(
        ReduxTemplate.setAlertHeader({
          show: true,
          optional: false,
          body: t("CreateTemplate.TemplateContent.Alert.EmptyCustomTextorURL"),
        })
      );
      dispatch(ReduxTemplate.setAlertFooter(defaultFooterAlert));
      return;
    }

    if (headerType && headerType !== "header-text" && !file && !showFooter) {
      dispatch(
        ReduxTemplate.setAlertHeader({ ...defaultHeaderAlert, show: true })
      );
      dispatch(ReduxTemplate.setAlertFooter(defaultFooterAlert));
      return;
    }

    if (showFooter && !reduxFooter) {
      dispatch(
        ReduxTemplate.setAlertFooter({ ...defaultFooterAlert, show: true })
      );
      dispatch(ReduxTemplate.setAlertHeader(defaultHeaderAlert));
      return;
    }

    dispatch(ReduxTemplate.setAlertHeader(defaultHeaderAlert));
    dispatch(ReduxTemplate.setAlertFooter(defaultFooterAlert));
    // dispatch(ReduxTemplate.setOnStepHeaderOrFooter("header"))
    return;
  }, [
    headerType,
    headerText,
    file,
    showFooter,
    reduxFooter,
    reduxTemplateHeaderParams,
  ]);

  useEffect(() => {
    if (currentStep) {
      setShowFooter(false);
    }
  }, [currentStep]);

  useEffect(() => {
    console.log("selected Head Param", selectedHeadParam);
  }, [selectedHeadParam]);

  const replaceMessageForPreview = (
    preview: string,
    param: HeaderParamLanguage,
    paramName: string
  ): string => {
    let newPreview = preview;
    console.log("param in replaceMessageForPreview ", param, paramName);
    if (param.paramType === "customText") {
      console.log(`check param : ${JSON.stringify(param)}`);
      console.log(`param.paramName in replaceMessageForPreview : ${paramName}`);
      const newCustomText =
        param.textContent && param.textContent !== ""
          ? param.textContent
          : paramName !== ""
          ? paramName
          : t("Campaign.Input.CustomizeText");
      const textToReplace = param.paramName
        ? param.paramName
        : t("Campaign.Input.CustomizeText");
      newPreview = newPreview.replace(
        "{{" + textToReplace + "}}",
        `<div data-testid="preview-body-parameter" class="bg-primary d-flex align-items-center py-2 px-2 text-break rounded fs-7 lh-1 text-white">
          <i class="bi bi-server pe-2 text-white"></i>
          <span class="text-start d-block" style="width: 90%;">${newCustomText}</span>
        </div>`
      );
    } else {
      newPreview = newPreview.replaceAll(
        "{{" + t("Campaign.Input." + param.paramType) + "}}",
        `<span data-testid="preview-body-parameter" class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
          "Campaign.Input." + param.paramType
        )}</span>`
      );
    }

    return newPreview;
  };

  //Set Header Type and Media Type
  useEffect(() => {
    const mediaType = reduxHeader.mediaType;
    const type = reduxHeader.type;
    const inHeaderURL = reduxHeader.headerURL;
    const fileName = reduxHeader.fileName;
    let text = reduxHeader.text;
    let headerParam = reduxHeader.headerParam;
    console.log("Check header file name: " + inHeaderURL);

    console.log("Check header param in text type: ", headerParam);

    if (mediaType && mediaType !== "") {
      setHeaderType(`header-${mediaType}`);
      setInputFileName(fileName);
      dispatch(ReduxTemplate.setTemplateHeaderURL(inHeaderURL));
      setHeaderURL(inHeaderURL);
      dispatch(ReduxTemplate.setTemplateFileType(mediaType));
      setIsAttachmentHeader(true);
    } else if (type === "text") {
      setHeaderType("header-text");
      setIsTextHeader(true);
      if (headerParam) {
        console.log(`headerParam in text type: ${JSON.stringify(headerParam)}`);
        dispatch(ReduxTemplate.setTemplateHeaderParams(headerParam));
        setHasHeaderParam(true);
        for (let index = 0; index < headerParam.length; index++) {
          const translate =
            headerParam[index].paramName && headerParam[index].paramName !== ""
              ? headerParam[index].paramName
              : t(
                  "Campaign.Input." +
                    (headerParam[index].paramType === "customText"
                      ? "CustomizeText"
                      : headerParam[index].paramKey)
                );
          text = text.replace(
            "{{" + headerParam[index].paramId + "}}",
            "{{" + translate + "}}"
          );
          const newPreviewHeaderText = replaceMessageForPreview(
            text,
            headerParam[index],
            headerParam[index].paramName ? headerParam[index].paramName : ""
          );
          dispatch(
            ReduxTemplate.setTemplatePreviewHeaderText(newPreviewHeaderText)
          );
        }
        console.log("isi text sekarang adalah: " + text);
        setHeaderText(text);
      }
      // else {
      //   setHeaderText(text);
      //   dispatch(ReduxTemplate.setTemplatePreviewHeaderText(text));
      // }
    }
  }, [reduxHeader]);

  // Get Attachment
  useEffect(() => {
    if (file) {
      //Set File Attribut
      const size: number = file.size;
      console.log("File Size : " + file.size);
      dispatch(ReduxTemplate.setTemplateUploadFileSize(size));
      dispatch(ReduxTemplate.setTemplateHeaderURL(URL.createObjectURL(file)));
      setHeaderURL(URL.createObjectURL(file));
      if (file.name.length > 25) {
        setInputFileName(file.name.substring(0, 25) + "...");
      } else {
        setInputFileName(file.name);
      }
      dispatch(ReduxTemplate.setTemplateUploadFileName(file.name));
      dispatch(ReduxTemplate.setTemplateUploadFileType(file.type));
      generateLanguage(
        "headerURL",
        URL.createObjectURL(file),
        0,
        file.name,
        undefined,
        []
      );
      //Check File Type
      const tempArrFileType: string = file.type;
      const arryFileType = tempArrFileType.split("/");
      console.log("File Type : " + tempArrFileType);
      if (
        arryFileType === undefined ||
        arryFileType.length < 1 ||
        arryFileType[0] === undefined
      ) {
        // error Failed Upload
        setInputFileName(t("HC.Error.InvalidFiletype"));
        setFile(undefined);
        const failedTextAlert = document.getElementById(
          "failedProcessAlertNotif"
        ) as HTMLDivElement;
        failedTextAlert.innerHTML = t("HC.Error.InvalidFiletype");
        const buttonFailedAlert = document.getElementById("triggerFailed");
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
        return;
      } else {
        // const [result, error] = checkFile2("image","jpg",5);
        const [result, error, maxSize] = checkFile(
          arryFileType[0],
          arryFileType[1],
          size
        );
        if (error !== undefined) {
          if (maxSize !== 0) {
            let textAlert = t("HC.File." + error).replace(
              "<<size>>",
              maxSize + " MB"
            );
            removeFile();
            const failedTextAlert = document.getElementById(
              "failedProcessAlertNotif"
            ) as HTMLDivElement;
            failedTextAlert.innerHTML = textAlert;
            const buttonFailedAlert = document.getElementById("triggerFailed");
            if (buttonFailedAlert) {
              buttonFailedAlert.click();
            }
          } else {
            removeFile();
            const failedTextAlert = document.getElementById(
              "failedProcessAlertNotif"
            ) as HTMLDivElement;
            failedTextAlert.innerHTML = t("HC.Error." + error);
            const buttonFailedAlert = document.getElementById("triggerFailed");
            if (buttonFailedAlert) {
              buttonFailedAlert.click();
            }
          }
          return;
        }
        if (result) {
          console.log(
            `Hasil Template File Type : ${result.toString()} - ${reduxTemplateFileType}`
          );
          if (result === reduxTemplateFileType) {
            dispatch(ReduxTemplate.setTemplateFileType(result.toString()));
          } else {
            removeFile();
            const failedTextAlert = document.getElementById(
              "failedProcessAlertNotif"
            ) as HTMLDivElement;
            failedTextAlert.innerHTML = t("HC.Error.InvalidFiletype");
            const buttonFailedAlert = document.getElementById("triggerFailed");
            if (buttonFailedAlert) {
              buttonFailedAlert.click();
            }
            return;
          }
        }
      }
      //Update DataTemplate
      // companyID: string, inputcontentType:string, inputFileName:string,file:File, messageType: string
      updateOnChangeData("file", file);
    }
  }, [file]);

  const removeFile = () => {
    if (reduxTemplateFileType === "document") {
      dispatch(ReduxTemplate.setTemplateUploadFileSize(0));
      dispatch(ReduxTemplate.setTemplateUploadFileName(""));
    }

    dispatch(ReduxTemplate.setTemplateHeaderURL(""));
    dispatch(ReduxTemplate.setTemplateHeaderFile(false));
    dispatch(ReduxCampaign.setFileURL(""));

    setFile(undefined);
    setInputFileName("");
    setHeaderURL("");
  };

  //function add text or param from text input header

  // TODO
  const onChangeCustomInputTextURL = async (
    value: string,
    paramName: string
  ) => {
    const oldHeaderParam = reduxTemplateHeaderParams;
    if (oldHeaderParam[0]) {
      console.log("onChangeCustomInputTextURL oldParam", oldHeaderParam[0]);
      let newHeaderParam = [];
      if (oldHeaderParam[0].paramType === "customText") {
        const header = {
          paramId: reduxTemplateHeaderParams[0].paramId,
          paramKey: reduxTemplateHeaderParams[0].paramKey,
          paramType: reduxTemplateHeaderParams[0].paramType,
          paramValue: reduxTemplateHeaderParams[0].paramValue,
          paramName: reduxTemplateHeaderParams[0].paramName
            ? reduxTemplateHeaderParams[0].paramName
            : paramName
            ? paramName
            : "",
          textContent: value,
        };

        let newPreview = replaceMessageForPreview(
          headerText,
          header,
          header.paramName
        );
        dispatch(ReduxTemplate.setTemplatePreviewHeaderText(newPreview));

        newHeaderParam.push(header);
        dispatch(ReduxTemplate.setTemplateHeaderParams(newHeaderParam));
      }

      // if (value !== "") {
      //   newPreview = newPreview.replace(
      //     `{{${t("Campaign.Input.CustomizeText")}}}`,
      //     `<span class="badge badge-primary fw-bolder fs-7 m-1">
      //       <i class="bi bi-server pe-2 text-white"></i>
      //       ${value}
      //       </span>`
      //   );
      // } else {
      //   newPreview = newPreview.replace(
      //     `{{${t("Campaign.Input.CustomizeText")}}}`,
      //     `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
      //       "Campaign.Input.CustomizeText"
      //     )}</span>`
      //   );
      // }

      let headerparams = headerText
        .split("{{")
        .filter((val) => val.includes("}}"))
        .map((val) => val.substring(0, val.indexOf("}}")));
      //Generate Language
      let templateLanguange = headerText;
      for (let index = 0; index < headerparams.length; index++) {
        const element = headerparams[index];
        templateLanguange = templateLanguange.replace(element, index + 1 + "");
      }
      generateLanguage(
        "headerText",
        templateLanguange,
        newHeaderParam.length,
        "",
        undefined,
        newHeaderParam
      );
    }
  };

  const processDataHeader = async (
    newHeaderText: string,
    headerParamName: string
  ) => {
    //Process Save To Redux
    let headerparams = newHeaderText
      .split("{{")
      .filter((val) => val.includes("}}"))
      .map((val) => val.substring(0, val.indexOf("}}")));
    let arrHeaderParam: any[] = [];
    let newPreviewHeaderText = newHeaderText;

    if (headerparams.length) {
      const headerParam = headerparams[0];
      const keyParam = Object.keys(option).find((value) => {
        return t("Campaign.Input." + value) === headerParam;
      });

      let text = "";
      let paramName = "";
      if (
        reduxTemplateHeaderParams[0] &&
        reduxTemplateHeaderParams[0].textContent
      ) {
        text = reduxTemplateHeaderParams[0].textContent;
      }
      if (
        reduxTemplateHeaderParams[0] &&
        reduxTemplateHeaderParams[0].paramName
      ) {
        paramName = reduxTemplateHeaderParams[0].paramName;
      }
      console.log("keyparam", keyParam);

      const header = {
        paramId: 1,
        paramKey: `${
          !keyParam || keyParam === "CustomizeText"
            ? "customText" + 1
            : keyParam
        }`,
        paramType: `${
          !keyParam || keyParam === "CustomizeText" ? "customText" : keyParam
        }`,
        paramValue: `${
          !keyParam ? t("Campaign.Input.CustomizeText") : headerParam
        }`,
        textContent: text,
        paramName:
          (!keyParam || keyParam === "CustomizeText") && paramName
            ? paramName
            : headerParamName
            ? headerParamName
            : headerParam,
      };

      arrHeaderParam.push(header);

      newPreviewHeaderText = replaceMessageForPreview(
        newPreviewHeaderText,
        header,
        header.paramName
      );
      dispatch(ReduxTemplate.setTemplateHeaderParams([header]));

      setHasHeaderParam(true);
      // for (let index = 0; index < headerparams.length; index++) {
      //   const element = headerparams[index];
      //   const keyParam = Object.keys(option).find((value, index, array) => {
      //     return t("Campaign.Input." + array[index]) === element;
      //   });

      //   let newParam = reduxTemplateHeaderParams[0];
      //   let text = "";
      //   if (newParam && newParam.textContent !== "") {
      //     text = newParam.textContent;
      //   }

      //   if (keyParam && keyParam !== "") {
      //     setHasHeaderParam(true);
      //     const header = {
      //       paramId: index + 1,
      //       paramKey: `${
      //         keyParam === "CustomizeText"
      //           ? "customText" + (index + 1)
      //           : keyParam
      //       }`,
      //       paramType: `${
      //         keyParam === "CustomizeText" ? "customText" : keyParam
      //       }`,
      //       paramValue: element,
      //       textContent: text,
      //     };
      //     // text = replaceMessageForPreview(text, header);
      //     arrHeaderParam.push(header);
      //     console.log("Header param : " + JSON.stringify(arrHeaderParam));
      //     dispatch(ReduxTemplate.setTemplateHeaderParams(arrHeaderParam));
      //   }
      //   //TODO

      //   if (element === `${t("Campaign.Input.CustomizeText")}`) {
      //     if (text !== "") {
      //       newPreviewHeaderText = newPreviewHeaderText.replace(
      //         `{{${t("Campaign.Input.CustomizeText")}}}`,
      //         text
      //       );
      //     } else {
      //       newPreviewHeaderText = newPreviewHeaderText.replace(
      //         `{{${t("Campaign.Input.CustomizeText")}}}`,
      //         `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
      //           "Campaign.Input.CustomizeText"
      //         )}</span>`
      //       );
      //     }
      //   } else {
      //     newPreviewHeaderText = newPreviewHeaderText.replaceAll(
      //       `{{${t("Campaign.Input." + keyParam)}}}`,
      //       `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>
      //     ${t("Campaign.Input." + keyParam)}</span>`
      //     );
      //   }
      // }

      console.log("new preview header", newPreviewHeaderText);
      //Save To Redux
      dispatch(
        ReduxTemplate.setTemplatePreviewHeaderText(newPreviewHeaderText)
      );
      // generatePreviewBodyMessage(bodyMessage);
    } else {
      if (reduxTemplateHeaderParams.length > 0) {
        dispatch(ReduxTemplate.setTemplateHeaderParams([]));
      }
      setHasHeaderParam(false);
    }
    //Generate Language
    let templateLanguange = newHeaderText;
    headerparams.forEach((element, index) => {
      templateLanguange = templateLanguange.replace(element, index + 1 + "");
    });
    console.log(`before generate data language`);
    generateLanguage(
      "headerText",
      templateLanguange,
      headerparams.length,
      "",
      undefined,
      arrHeaderParam
    );
  };

  //function add header from modal
  const onAddHeaderParam = async (
    newParam?: string,
    inputParamName?: string
  ) => {
    // const e = document.getElementById(
    //   "add-header-param"
    // ) as HTMLSelectElement | null;
    console.log(`check inputParamName in onAddHeaderParam ${inputParamName}`);
    if (newParam) {
      const headerParameter = inputParamName ? inputParamName : newParam;
      let newHeaderText = headerText + "{{" + headerParameter + "}}";
      setHeaderText(newHeaderText);
      console.log(`newHeaderText : ${newHeaderText}`);
      dispatch(ReduxTemplate.setTemplateHeader(newHeaderText));

      let newPreviewHeaderText = newHeaderText;
      console.log("prev head new", newPreviewHeaderText);
      Object.keys(option)
        .map((value, index, array) => {
          return array[index];
        })
        .forEach((key) => {
          newPreviewHeaderText = newPreviewHeaderText.replace(
            t("Campaign.Input.CustomizeText"),
            inputParamName ? inputParamName : ""
          );
          newPreviewHeaderText = replaceMessageForPreview(
            newPreviewHeaderText,
            { paramId: 1, paramType: key, paramName: inputParamName },
            inputParamName ? inputParamName : ""
          );
        });

      // //change headertext
      // setHeaderText(inputParamName.replace(t("Campaign.Input.CustomizeText"), replacedText + "added text"))
      // console.log('replaced text header custom param',t("Campaign.Input.CustomizeText"),replacedText)

      //Validate Message
      setHasHeaderParam(
        newPreviewHeaderText.includes("{{") ||
          newPreviewHeaderText.includes("}}")
      );

      dispatch(
        ReduxTemplate.setTemplatePreviewHeaderText(newPreviewHeaderText)
      );

      //Process Save To Redux
      processDataHeader(newHeaderText, inputParamName ? inputParamName : "");
    }
  };

  const onChangeTextFooter = async (value: string) => {
    const textFooter = value;
    generateLanguage("footer", textFooter, 0, "", undefined, []);
    dispatch(ReduxTemplate.setTemplateFooter(textFooter));
  };

  const updateOnChangeData = (key: string, value: any) => {
    if (key === "file") {
      if (file) {
        props.onChanges([
          { key: "templateId", value: selectedTemplateId },
          { key: "bodyParam", value: bodyParams },
          { key: "file", value: file },
          { key: "inputcontentType", value: file.type },
          { key: "inputFileName", value: file.name },
          { key: "templateId", value: selectedTemplateId },
          { key: "bodyParam", value: bodyParams },
          { key, value },
        ]);
      }
    } else {
      console.log({ key, value }, "masuk inih");
      props.onChanges([
        { key: "templateId", value: selectedTemplateId },
        { key: "bodyParam", value: bodyParams },
        { key, value },
      ]);
    }
  };

  // const onShowFooterForm = () => {
  //   document
  //     .getElementById("headerForm")
  //     ?.setAttribute("style", "display:none");
  //   document.getElementById("footerForm")?.removeAttribute("style");
  //   document
  //     .getElementById("headerTitle")
  //     ?.setAttribute("style", "display:none");
  //   document.getElementById("footerTitle")?.removeAttribute("style");
  // };

  const processChangeHeaderText = async (
    headerText: string,
    headerParamName: string
  ) => {
    let newHeaderText: string = headerText;
    setLengthTextHeader(headerText.toString().length);

    setHeaderText(newHeaderText);
    dispatch(ReduxTemplate.setTemplateHeader(newHeaderText));
    let newPreviewHeaderText = newHeaderText;
    Object.keys(option)
      .map((value, index, array) => {
        return array[index];
      })
      .forEach((key) => {
        console.log(`check key : ${key} in processChangeHeaderText`);
        newPreviewHeaderText = newPreviewHeaderText.replaceAll(
          `{{${t(`Campaign.Input.${key}`)}}}`,
          `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
            "Campaign.Input." + key
          )}</span>`
        );
      });

    //Validate Message
    setHasHeaderParam(
      newPreviewHeaderText.includes("{{") || newPreviewHeaderText.includes("}}")
    );

    dispatch(ReduxTemplate.setTemplatePreviewHeaderText(newPreviewHeaderText));
    //Process Save To Redux
    processDataHeader(newHeaderText, inputParamName ? inputParamName : "");
  };

  const TempHeaderInput: FC<{ headerInput: string; headerCustom?: string }> = ({
    headerInput,
    headerCustom,
  }) => {
    // const [inputText, setInputText] = useState(headerText)

    // const debounceCustomText = useDebounce(inputRef.current, 500);

    const [inputText, setInputText] = useState<string>(
      headerInput ? headerInput : ""
    );
    const [customText, setCustomText] = useState<string>(
      headerCustom ? headerCustom : ""
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const customTextRef = useRef<HTMLInputElement>(null);

    const debounceInputText = useDebounce(inputText, 500);
    const debounceCustomText = useDebounce(customText, 500);

    useEffect(() => {
      if (debounceInputText !== headerInput)
        processChangeHeaderText(debounceInputText, inputParamName);
    }, [debounceInputText]);

    useEffect(() => {
      if (debounceCustomText !== headerCustom)
        onChangeCustomInputTextURL(debounceCustomText, inputParamName);
    }, [debounceCustomText]);

    useEffect(() => {
      if (isTextHeader) {
        if (inputRef.current && !customTextFocus && !customTextTitleFocus) {
          inputRef.current.focus();
        } else if (
          customTextRef.current &&
          customTextFocus &&
          !customTextTitleFocus
        ) {
          customTextRef.current.focus();
        }
      }
    }, []);

    return (
      <>
        <div className={clsx(isAttachmentHeader ? "" : "display-none")}>
          <div className="d-flex flex-row justify-content-end py-2">
            {headerURL === "" && headerType !== "" && !reduxLoading && (
              <label className="btn btn-primary">
                {t("Campaign.Button.Choose")}
                <input
                  data-testid="campaign-media-input"
                  ref={attachmentRef}
                  id="campaign-media"
                  type="file"
                  name="campaign-media"
                  multiple={false}
                  onChange={setPreviewFile}
                  style={{ display: "none" }}
                  accept={inputFileExtention}
                />
              </label>
            )}
            {
              //show when filename already inputted
              inputFileName && (
                <div className="p-4 rounded-1 align-middle bg-gray-100">
                  {/* Add check if there is uploaded image */}
                  <label
                    data-testid="campaign-media-file"
                    className="text-gray-600"
                  >
                    {inputFileName}
                  </label>
                  <label
                    className={clsx(
                      "btn btn-sm btn-icon-danger btn-text-danger p-0",
                      reduxLoading === true ? "disabled" : ""
                    )}
                    onClick={removeFile}
                  >
                    <i className="bi bi-x-lg"></i>
                  </label>
                </div>
              )
            }
          </div>
        </div>

        <div className={clsx("mb-5", "display-none")}>
          {/* <div className={clsx("mb-5")}> */}
          <div className="step-sub-title">
            {t("Campaign.Title.Header")}
            {file && (
              <span className="bi bi-check-circle-fill text-success ps-3"></span>
            )}
          </div>
          <div className="d-flex flex-row align-items-center">
            <label
              className="btn btn-icon-gray-900 btn-text-gray-900 p-0"
              // type="button"
              data-bs-toggle="tooltip"
              data-kt-image-input-action="change"
              title={t("HC.Info.Upload")}
            >
              <i className="bi bi-card-image fs-5tx"></i>
              <span
                className="badge badge-square upload-badge bi bi-plus-circle-fill text-gray-500 fs-1"
                style={{ top: "30px" }}
              />
              <input
                id="chat-media"
                onChange={setPreviewFile}
                type="file"
                name="attachment"
                multiple={false}
                style={{ display: "none" }}
                accept={inputFileExtention}
              />
            </label>
            {/* Uploaded Header */}
            {file && (
              <div>
                <label className="text-gray-600">{inputFileName}</label>
                <label className="btn btn-sm btn-icon-danger btn-text-danger p-0">
                  <div
                    className="btn bi bi-x-lg text-danger"
                    onClick={removeFile}
                  ></div>
                </label>
              </div>
            )}
          </div>
        </div>

        {/* Text Header */}
        {headerType === "header-text" && (
          <div className={clsx(isTextHeader ? "py-2" : "display-none")}>
            <div className={clsx(isTextHeader ? "" : "display-none")}>
              <div className="mb-5">{t("Template.Info.TextHeader")}</div>
            </div>

            <div
              className={
                isTextHeader ? "d-flex flex-row border mt-4" : "display-none"
              }
            >
              <input
                ref={inputRef}
                className="form-control form-control-solid border-0 bg-white"
                type="text"
                placeholder={t("Campaign.Input.ContentField")}
                id="inputTextHeader"
                data-testid="header-text-input"
                onChange={(e) => {
                  setCustomTextFocus(false);
                  setInputText(e.target.value);
                }}
                // onChange={(e) => setInputText(e.target.value)}
                value={inputText}
                maxLength={60}
              />
              <button
                className="border-0 align-middle bg-white "
                style={{ color: "gray" }}
              >
                {lengthTextHeader}/60
              </button>
            </div>
            {/* TODO add custom data in Header and when header type is Text */}
            {reduxTemplateHeaderParams &&
              reduxTemplateHeaderParams.length > 0 &&
              isHasHeaderParam && (
                <>
                  <div className="d-flex flex-row mt-5 mb-5">
                    <input
                      data-testid="header-text-prev"
                      className="w-50"
                      type="text"
                      disabled
                      value={
                        reduxTemplateHeaderParams[0].paramType ===
                          "customText" && reduxTemplateHeaderParams[0].paramName
                          ? reduxTemplateHeaderParams[0].paramName
                          : reduxTemplateHeaderParams[0].paramValue
                      }
                    />
                    {reduxTemplateHeaderParams[0].paramType &&
                      reduxTemplateHeaderParams[0].paramType ===
                        "customText" && (
                        <input
                          ref={customTextRef}
                          className="form-control form-control-solid bg-white w-50"
                          placeholder={`${t("Campaign.Input.CustomizeText")}`}
                          id="input-body-param"
                          onChange={(e) => {
                            setCustomTextFocus(true);
                            setCustomText(e.target.value);
                            if (e.target.value !== "") {
                              dispatch(
                                ReduxTemplate.setAlertEmptyTextContentHeaderParam(
                                  false
                                )
                              );
                            }
                          }}
                          value={customText}
                        ></input>
                      )}
                  </div>
                  {reduxEmptyTextContentHeaderParam &&
                    reduxTemplateHeaderParams[0].paramType === "customText" &&
                    reduxTemplateHeaderParams[0].textContent === "" && (
                      <div className="text-danger">
                        {t(
                          "CreateTemplate.TemplateContent.Alert.EmptyCustomTextorURL"
                        )}
                      </div>
                    )}
                </>
              )}
            <div className={clsx(!isHasHeaderParam ? "" : "display-none")}>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary m-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addDataHeaderModal"
                  onClick={() => {
                    setCustomTextTitleFocus(true);
                  }}
                >
                  + {t("Campaign.Button.AddData")}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const TempHeader: FC<TemplateHeader> = (props) => {
    const { id, title, description, icon, disabled } = props;
    const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png
    const fileExtention: string = "application/pdf"; //pdf
    const videoExtention: string = "video/mp4"; //mp4

    // const mediaType = reduxHeader.mediaType;
    // const type = reduxHeader.type;

    const onClickHandler = (id: string) => {
      console.log(`id ${id} clicked`);

      setIsAttachmentHeader(true);

      if (id === headerType) {
        //Remove Header
        setHeaderType("");
        //remove-header
        generateLanguage("remove-header", "", 0, "", undefined, []);
        dispatch(ReduxTemplate.setTemplateHeaderParams([]));
        removeFile();
        setHeaderURL("");
        console.log("Masuk redux header");
        return;
      }

      if (id === "header-text") {
        setInputFileExtention("");
        setHeaderType("header-text");
        setIsTextHeader(true);
        setIsAttachmentHeader(false);
        dispatch(ReduxTemplate.setTemplateHeaderFile(false));

        removeFile();
        generateLanguage("headerType", "text", 0, "", undefined, []);
        return;
      }

      removeFile();
      setHasHeaderParam(false);
      setHeaderText("");
      dispatch(ReduxTemplate.setTemplatePreviewHeaderText(""));

      if (id === "header-image") {
        generateLanguage("headerMediaType", "image", 0, "", undefined, []);
        setInputFileExtention(imageExtention);
        dispatch(ReduxTemplate.setTemplateHeaderFile(false));
        dispatch(ReduxTemplate.setTemplateFileType("image"));
        setHeaderType("header-image");
        setIsTextHeader(false);
      } else if (id === "header-video") {
        generateLanguage("headerMediaType", "video", 0, "", undefined, []);
        setInputFileExtention(videoExtention);
        dispatch(ReduxTemplate.setTemplateHeaderFile(false));
        dispatch(ReduxTemplate.setTemplateFileType("video"));
        setHeaderType("header-video");
        setIsTextHeader(false);
      } else if (id === "header-document") {
        generateLanguage("headerMediaType", "document", 0, "", undefined, []);
        setInputFileExtention(fileExtention);
        dispatch(ReduxTemplate.setTemplateHeaderFile(true));
        dispatch(ReduxTemplate.setTemplateFileType("document"));
        setHeaderType("header-document");
        setIsTextHeader(false);
      } else {
        setInputFileExtention("");
      }
    };

    return (
      <div className="py-3">
        <button
          type="button"
          id={id}
          data-testid={"tempHeader-" + id}
          className={clsx(
            "btn btn-light btn-active-primary d-flex align-items-center gap-4 border",
            headerType === id ? "active" : "",
            reduxLoading === true ? "disabled" : ""
          )}
          disabled={disabled}
          style={{ borderStyle: "dotted", width: "100%" }}
          onClick={() => onClickHandler(id)}
        >
          <div className="bg-secondary px-4 bg-opacity-50">
            <span className={icon}></span>
          </div>
          <div className="text-start">
            <div className="step-sub-title">{title}</div>
            <div className="desc">{description}</div>
          </div>
        </button>

        {headerType === id ? (
          <div>
            <TempHeaderInput
              headerInput={headerText}
              headerCustom={
                reduxTemplateHeaderParams.length
                  ? reduxTemplateHeaderParams[0].textContent
                  : ""
              }
            />

            {isTabletOrMobile ? <PreviewMessage /> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const listParamOption: HeaderParam[] = Object.keys(option).map(
    (value, index, array) => {
      return {
        value: option[value],
        label: t("Campaign.Input." + array[index]),
      };
    }
  );

  // --------------------------------------- UI ---------------------------------------  //

  const supportedMedia = getSupportedMedia(
    selectedAccountsId,
    companiesAccounts
  );
  const unsupportedMedia = getUnsupportedMedia(supportedMedia);
  const selectedChannel = getSelectedChannel(
    selectedAccountsId,
    companiesAccounts
  );

  useEffect(() => {
    if (showFooter) {
      console.log(`set on step footer`);
      dispatch(ReduxTemplate.setOnStepHeaderOrFooter("footer"));
    }
  }, [showFooter]);

  useEffect(() => {
    if (reduxOnStepHeaderOrFooter === "footer") setShowFooter(true);
    else if (reduxOnStepHeaderOrFooter === "header") setShowFooter(false);
  }, [reduxOnStepHeaderOrFooter]);

  return (
    <>
      <button
        id="triggerFailed"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed_process"
        hidden
        // onClick={() => {
        //   nav("/template/list");
        // }}
      ></button>
      {/* <AlertFailedProcess /> */}
      <div
        className={clsx("tab-pane fade show")}
        // className="tab-pane fade"
        id="kt_tab_pane_4"
        // id="kt_tab_pane_3"
        role="tabpanel"
        data-testid="templateHeaderAndFooter"
      >
        {/* Start Modal */}
        <div
          className="modal fade"
          id="addDataHeaderModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("Campaign.Button.AddData")}
                </h5>
                <button
                  type="button"
                  className={clsx(
                    "btn btn-icon btn-sm btn-active-light-primary ms-2",
                    reduxLoading === true ? "disabled" : ""
                  )}
                  id="close-addData"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCustomTextTitleFocus(false);
                  }}
                >
                  <span
                    className="svg-icon svg-icon svg-icon-2x"
                    data-bs-dismiss="modal"
                  >
                    <i className="bi bi-x-lg fs-2" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  {/* <select
                    className="form-select form-control form-control-solid mb-3 mb-lg-0"
                    id="add-header-param"
                    defaultValue={0}
                  >
                    <option value={0}>{t("Campaign.Button.AddData")}...</option>
                    {Object.keys(option).map((value, index, array) => {
                      return (
                        // <option value={option[value]} key={value}>
                        <option value={array[index]} key={value}>
                          {t(`Campaign.Input.${array[index]}`)}
                        </option>
                      );
                    })}
                  </select> */}
                  <Select
                    name="add-message-param"
                    defaultValue={{
                      value: "",
                      label: t("Campaign.Input.AddData") + "...",
                    }}
                    options={listParamOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t("Campaign.Input.AddData") + "..."}
                    onChange={(newVal) => {
                      if (newVal) {
                        setSelectedHeadParam(newVal);
                        if (newVal.value === "Customize text/URL") {
                          //add focus to input text field name
                          setTimeout(() => {
                            const inputParamName =
                              document.getElementById("inputParamName");
                            // inputParamName?.focus();
                          }, 100);
                        }
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "enter" || e.keyCode === 13) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                  />
                  {selectedHeadParam &&
                    selectedHeadParam.value === "Customize text/URL" && (
                      <div>
                        <div className="mt-5 w-100">
                          {t("Campaign.IM.Body.Modal.CustomText.TextFieldName")}
                          <span className="text-danger">*</span>
                        </div>
                        <div className="flex-grow-1 d-flex flex-row border border-secondary rounded">
                          <input
                            ref={customTextTitleRef}
                            className="form-control form-control-solid border-0 bg-white"
                            type="text"
                            id="inputParamName"
                            placeholder={t("Campaign.IM.Body.Modal.CustomText")}
                            onChange={(e) => {
                              const paramName: string = e.target.value;
                              console.log(`paramName : ${paramName}`);
                              setInputParamName(paramName);
                              if (paramName !== "") {
                                setAlertEmptyParamName(false);
                              }
                            }}
                            maxLength={30}
                          />
                          <button
                            className="border-0 align-middle bg-transparent"
                            style={{ color: "gray" }}
                          >
                            {inputParamName.length}/30
                          </button>
                        </div>
                        {alertEmptyParamName && (
                          <span className="text-danger">
                            {t(
                              "Campaign.TemplateCategory.IM.Body.CustomTextModal.ErrorAlert"
                            )}
                          </span>
                        )}
                      </div>
                    )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (
                      selectedHeadParam &&
                      selectedHeadParam?.value === "Customize text/URL" &&
                      inputParamName === ""
                    ) {
                      setAlertEmptyParamName(true);
                      return;
                    }
                    if (selectedHeadParam?.value) {
                      onAddHeaderParam(
                        selectedHeadParam.label,
                        selectedHeadParam.value === "Customize text/URL"
                          ? inputParamName
                          : ""
                      );
                    }
                    setCustomTextTitleFocus(false);
                  }}
                  data-bs-dismiss={
                    selectedHeadParam &&
                    selectedHeadParam?.value === "Customize text/URL" &&
                    inputParamName === ""
                      ? "#"
                      : "modal"
                  }
                >
                  {t("Campaign.Button.AddData")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}

        {/* New Template */}
        <div className="d-flex">
          {/* 1 */}
          <div className="col-12">
            {/* Select Template */}
            <div className="mb-5">
              <div
                id="footerTitle"
                data-testid="footerTitle"
                className={clsx("step-sub-title mb-4 fs-2")}
              >
                {t("Template.Title.TemplateFooter")}
              </div>
            </div>

            {/* Select Header Parameter */}
            <div className={clsx("mb-5 d-flex flex-column flex-md-row")}>
              {/* Start for Footer Form */}
              <div id="footerForm" className={clsx("col-md-6")}>
                {/* <button
                  id="addHeaderAndFooter"
                  className={clsx("bi bi-plus-lg btn btn-primary m-1")}
                  onClick={() => setShowFooter(false)}
                >
                  {t("Template.Button.AddHeader")}
                </button> */}

                <div className={clsx("mb-5 mt-5")}>
                  <div className="mb-5">
                    {t("Template.Info.TemplateFooter")}
                    {/* {hasCompleteParams && (
                      <span className="bi bi-check-circle-fill text-success ps-3"></span>
                    )} */}
                  </div>
                </div>

                {/* End bodyParams Explain Card */}

                <TemplateFooter
                  footerText={reduxFooter}
                  onChangeTextFooter={onChangeTextFooter}
                />

                <div>
                  <div className="mb-5">{t("Template.Info.ToHeader")}</div>
                  <button
                    id="addCallToAction"
                    className={clsx(
                      "bi bi-plus-lg btn btn-primary m-1",
                      reduxLoading === true ? "disabled" : ""
                    )}
                    onClick={() => updateOnChangeData("addCallToAction", "6")}
                  >
                    {t("Template.Button.AddInteractiveButtons")}
                  </button>
                </div>
              </div>
              {/* End of The col 6 of Header Form */}

              <div className="col-1"></div>
              {isTabletOrMobile && !showFooter ? "" : <PreviewMessage />}
            </div>
            {/* Select Body Parameter */}
          </div>
        </div>
      </div>
    </>
  );
};

type TemplateFooterProps = {
  footerText: string;
  onChangeTextFooter: (value: string) => void;
};

const TemplateFooter: FC<TemplateFooterProps> = ({
  footerText,
  onChangeTextFooter,
}) => {
  const { t } = useTranslation();

  const [inputText, setInputText] = useState<string>(footerText);
  const debounceFooterText = useDebounce(inputText, 500);

  useEffect(() => {
    if (
      footerText !== inputText &&
      (footerText === "" || footerText !== debounceFooterText)
    ) {
      setInputText(footerText);
    }
  }, [footerText]);

  useEffect(() => {
    if (debounceFooterText !== footerText) {
      onChangeTextFooter(debounceFooterText);
    }
  }, [debounceFooterText, footerText, onChangeTextFooter]);

  return (
    <div className="mb-10">
      <div className="d-flex flex-row border ">
        <input
          className="form-control form-control-solid border-0 bg-white"
          type="text"
          placeholder={t("Campaign.Input.ContentField")}
          id="inputTemplateName"
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          maxLength={60}
        />
        <button
          className="border-0 align-middle bg-white "
          style={{ color: "gray" }}
        >
          {inputText.length}/60
        </button>
      </div>
    </div>
  );
};

export default TemplateFooterComponent;
