import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import useFirestoreData, {
  FirestoreQueryState,
} from "../../../../../hooks/useFirestoreData";
import { RootState } from "../../../../../setup/redux/store";
import Customer from "../../../../models/Customer";

type TableContextValue = {
  tableState: FirestoreQueryState<Customer>;
  selectedItems: Customer[];
  setSelectedItems: Dispatch<SetStateAction<Customer[]>>;
  updateTableState: (data: Partial<FirestoreQueryState<Customer>>) => void;
  updatePage: (newPage: number) => void;
  refreshData: () => void;
  isLoading: boolean;
  isFetching: boolean;
  data?: {
    allCount: number;
    items: Customer[];
  };
};

export const defaultTableState: FirestoreQueryState<Customer> = {
  clientID: undefined,
  collection: "",
  sorting: {
    field: "firstName",
    order: "asc",
  },
  limit: 10,
  filters: [
    {
      field: "isActive",
      value: true,
    },
  ],
  currentPage: 1,
  searchKey: undefined,
  priorityOrder: false,
};
export const defaultValue: TableContextValue = {
  tableState: defaultTableState,
  selectedItems: [],
  setSelectedItems: () => {},
  updateTableState: () => {},
  updatePage: () => {},
  refreshData: () => {},
  isLoading: true,
  isFetching: true,
  data: undefined,
};

export const TableContext = createContext<TableContextValue>(defaultValue);
export function useFirestoreTable() {
  return useContext(TableContext);
}

type TableProviderProps<T> = PropsWithChildren<{
  collection: string;
}>;

export function TableProvider<T>({
  children,
  collection,
}: TableProviderProps<T>) {
  let targetClientID: string = useSelector(
    (state: RootState) => state.Campaign.clientID
  );

  const selectedTargetRdx: Customer[] = useSelector(
    (state: RootState) => state.CampaignTarget.selectedTarget
  );
  // console.log("selectedTargetRdx", selectedTargetRdx);
  // const selectedFromRdx: string[] = selectedTargetRdx.reduce(
  //   (acc: string[], curr) => (curr.id ? [...acc, curr.id] : acc),
  //   []
  // );

  const [tableState, setTableState] = useState<FirestoreQueryState<Customer>>({
    ...defaultTableState,
    collection: collection,
    clientID: targetClientID,
  });

  useEffect(() => {
    const storedState = localStorage.getItem("tableStateCatargetlist"); 
    if (storedState) {
      setTableState(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("tableStateCatargetlist");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("tableStateCatargetlist", JSON.stringify(tableState)); 
  }, [tableState]);

  const [selectedItems, setSelectedItems] =
    useState<Customer[]>(selectedTargetRdx);

  const { data, isLoading, isFetching, refetch } =
    useFirestoreData<Customer>(tableState);

  const updateTableState = (data: Partial<FirestoreQueryState<Customer>>) => {
    let newState = data;

    if (!data.currentPage) {
      newState.currentPage = 1;
    }

    return setTableState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
  };

  const refreshData = () => {
    refetch();
    setTableState((prev) => ({
      ...prev,
      sorting: defaultTableState.sorting,
      currentPage: defaultTableState.currentPage,
    }));
  };

  console.log("Items", data?.items);

  const value = {
    tableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    updatePage,
    isLoading,
    isFetching,
    refreshData,
    data,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}
