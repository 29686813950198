import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../resources/helpers";
import Customer, { ContactsQueryResponse } from "../../../../models/Customer";
import { fetchCustomers, deleteCustomer } from "../../../../../actions";
//import { Contact } from '../../../../pages/Contact'
import firebase from "firebase/compat/app";
import {
  fetchCustomersByClient,
} from "../../../../../db";
import { format } from "date-fns";
import { getItemLC } from "../../../../modules/localstorage";
import { createRef } from "../../../../../db/connection";
import * as lc from "../../../../modules/localstorage/index";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

const deleteSelectedContacts = (contactIds: Array<ID>): Promise<void> => {
  // const requests = contactIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  const requests = contactIds.map((id) => {
    if (id === undefined || id === null) {
    } else {
      console.log("Delete id : " + id);
      deleteCustomer(id.toString());
    }
  });

  return axios.all(requests).then(() => {});
};

const getTargetByClientID = async (
  clientID: string | undefined
): Promise<Array<Customer>> => {
  let ContactsQueryResponse: ContactsQueryResponse;
  console.log("getTargetByClient: " + clientID);

  if (!clientID || clientID === "") {
    console.log("Client not found return array null []");
    return [];
  }
  const client = createRef("clients", clientID);

  //TODO proses get data
  let dataContactsResult = await fetchCustomersByClient(client)
  console.log(`dataContacts : `, dataContactsResult);

  if (dataContactsResult.length > 0) {
    console.log("Target data exist...");
  }

  return Promise.resolve(dataContactsResult);
};

export {
  deleteSelectedContacts,
  getTargetByClientID
};
