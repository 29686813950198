/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { KTSVG, useDebounce } from "../../../../../../../resources/helpers";
import { useQueryRequest } from "../../core/QueryRequestProvider";

const WABAAccountsListSearchComponent = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { state, updateState } = useQueryRequest();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  // Effect for API call
  useEffect(
    () => {
      console.log(`debounce searchTerm: ${searchTerm}`);
      let search = "";
      if (debouncedSearchTerm) {
        search = debouncedSearchTerm;
      }
      updateState({
        search: search,
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  return (
    <div className={clsx("card-title", isTabletOrMobile ? "w-100" : "w-100")}>
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative my-1 w-100">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          data-testid="search-waba"
          type="text"
          data-kt-user-table-filter="search"
          className={clsx(
            "form-control form-control-solid ps-14",
            isTabletOrMobile ? "w-100" : "w-100"
          )}
          placeholder={t("WABAAccountList.SearchBar")}
          value={searchTerm}
          onChange={async (e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

export { WABAAccountsListSearchComponent };
