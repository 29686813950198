import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import MarketplaceList from "./MarketplaceList";
import { PageLink, PageTitle } from "../../../core";
import { useTranslation } from "react-i18next";
import MarketplaceShopEdit from "./marketplace-list/components/MarketplaceShopEdit";

//
const MarketplaceEditPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Marketplace.Menu.Marketplace"),
      path: "/setting/marketplace/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Marketplace.List"),
      path: "/setting/marketplace/edit",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{t("Marketplace.Button.Edit")}</PageTitle>
      <MarketplaceShopEdit />
    </>
  );
};

export default MarketplaceEditPage;
