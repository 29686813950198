import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { templateStatus } from "../../../../../models/Language";
import { useTranslation } from "react-i18next";
import Language from "../../../../../models/Language";
import { getAccountByID } from "../../../../../../db/serviceAccount";
import { Modal } from "react-bootstrap";
import { getTemplateLanguages } from "../../../../../../db/serviceTemplate";

type Props = {
  languages?: any;
};

const TemplateStatusCell: FC<Props> = ({ languages }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    async function processData() {
      let dataLanguage = [];

      for (const item of languages || []) {
        let acc = await getAccountByID(item?.account?.id);
        let bussinesName = acc?.name || acc?.type || acc?.searchKey;

        dataLanguage.push({
          templateLanguage: item.templateLanguage,
          templateStatus: item.templateStatus,
          bussinesAccount: bussinesName,
        });
      }

      setData(dataLanguage);
    }
    processData();
  }, [languages]);
  // if (dataLanguage !== undefined && dataLanguage.length > 0) {
  //   let html: any = ``;
  //   for (let index = 0; index < languages.length; index++) {
  //     const element = languages[index];
  //     if (
  //       element.templateLanguage !== undefined &&
  //       element.templateLanguage !== "" &&
  //       element.templateStatus !== undefined &&
  //       element.templateStatus !== ""
  //     ) {
  //       let templateLanguage = t(
  //         "Global.Languages." + element.templateLanguage
  //       );
  //       if (
  //         element.templateStatus !== templateStatus.approved &&
  //         element.templateStatus !== templateStatus.rejected &&
  //         element.templateStatus !== templateStatus.disable_update
  //       ) {
  //         let hoverTooltip = t("Campaign.Info.Templatestatus")
  //           .replaceAll(
  //             "<<Languange>>",
  //             `${
  //               element.templateLanguage === "id" && i18n.language === "id"
  //                 ? "Bahasa " +
  //                   t("Global.Languages." + element.templateLanguage)
  //                 : t("Global.Languages." + element.templateLanguage)
  //             }`
  //           )
  //           .replaceAll(
  //             "<<Status>>",
  //             t("Campaign.Info." + element.templateStatus)
  //           );
  //         html += `<div class="badge badge-light-warning fs-8 fw-bolder my-2 data-bs-toggle="tooltip" rel=tooltip data-bs-placement="right" title="${hoverTooltip}" data-bs-html="true" >${templateLanguage}</div>`;
  //       } else if (element.templateStatus === templateStatus.approved) {
  //         let hoverTooltip = t("Campaign.Info.Templatestatus")
  //           .replaceAll(
  //             "<<Languange>>",
  //             `${
  //               element.templateLanguage === "id" && i18n.language === "id"
  //                 ? "Bahasa " +
  //                   t("Global.Languages." + element.templateLanguage)
  //                 : t("Global.Languages." + element.templateLanguage)
  //             }`
  //           )
  //           .replaceAll(
  //             "<<Status>>",
  //             t("Campaign.Info." + element.templateStatus)
  //           );
  //         html += `<div class="badge badge-light-success fs-8 fw-bolder my-2 data-bs-toggle="tooltip" data-bs-x="right" title="${hoverTooltip}" data-bs-html="true">${templateLanguage} </div> `;
  //       } else if (
  //         element.templateStatus === templateStatus.rejected ||
  //         element.templateStatus === templateStatus.disable_update
  //       ) {
  //         let hoverTooltip = t("Campaign.Info.Templatestatus")
  //           .replaceAll(
  //             "<<Languange>>",
  //             `${
  //               element.templateLanguage === "id" && i18n.language === "id"
  //                 ? "Bahasa " +
  //                   t("Global.Languages." + element.templateLanguage)
  //                 : t("Global.Languages." + element.templateLanguage)
  //             }`
  //           )
  //           .replaceAll(
  //             "<<Status>>",
  //             t("Campaign.Info." + element.templateStatus)
  //           );
  //         if (element.reason && element.reason !== "") {
  //           hoverTooltip = hoverTooltip + " [" + element.reason + "]";
  //         }
  //         html += `<div class="badge badge-light-danger fs-8 fw-bolder my-2 data-bs-toggle="tooltip" data-bs-placement="right" title="${hoverTooltip}" data-bs-html="true">${templateLanguage} </div> `;
  //       } else {
  //         <></>;
  //       }
  //     }
  //   }
  //   return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  //   // return <></>
  // } else {
  //   return <></>;
  // }
  console.log(data.length, data, "datalang part");

  let bg_approved = "#e8fff3";
  let bg_pending = "#fff8dd";
  let bg_reject = "#ffe8e8";

  let color_approved = "#50cd89";
  let color_pending = "#ffcc19";
  let color_reject = "#cd5050";

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {data?.length < 2 && (
        <div
          style={{
            // padding: "10px",
            backgroundColor:
              data[0]?.templateStatus === "APPROVED"
                ? bg_approved
                : data[0]?.templateStatus === "PENDING"
                ? bg_pending
                : data[0]?.templateStatus === "REJECTED"
                ? bg_reject
                : "white",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              padding: "0px",
              margin: data[0]?.bussinesAccount !== undefined ? "4px" : "0px",
              color:
                data[0]?.templateStatus === "APPROVED"
                  ? color_approved
                  : data[0]?.templateStatus === "PENDING"
                  ? color_pending
                  : color_reject,
            }}
          >
            {data[0]?.bussinesAccount !== undefined &&
              `${
                data[0]?.templateLanguage === "id"
                  ? "Indonesia"
                  : data[0]?.templateLanguage === "en"
                  ? "English"
                  : ""
              }_${data[0]?.bussinesAccount}`}
          </p>
        </div>
      )}
      {data?.length > 1 && (
        <div
          style={{
            padding: "0px",
            margin: "0px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            columnGap: "5px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        >
          <p
            style={{
              padding: "8px",
              margin: "0px",
              width: "fit-content",
              borderRadius: "8px",
              textAlign: "center",
              backgroundColor: bg_approved,
              color: color_approved,
            }}
          >
            {
              data.filter((item: any) => item.templateStatus === "APPROVED")
                .length
            }
          </p>
          <p
            style={{
              padding: "8px",
              margin: "0px",
              width: "fit-content",
              borderRadius: "8px",
              textAlign: "center",
              backgroundColor: bg_pending,
              color: color_pending,
            }}
          >
            {
              data.filter((item: any) => item.templateStatus === "PENDING")
                .length
            }
          </p>
          <p
            style={{
              padding: "8px",
              margin: "0px",
              width: "fit-content",
              borderRadius: "8px",
              textAlign: "center",
              backgroundColor: bg_reject,
              color: color_reject,
            }}
          >
            {
              data.filter((item: any) => item.templateStatus === "REJECTED")
                .length
            }
          </p>
        </div>
      )}

      <Modal
        animation
        centered
        show={isModalOpen}
        onHide={() => closeModal && closeModal()}
        // size="sm"
        scrollable={true}
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Template</h5>
          <div>
            <button
              type="button"
              className="btn-close"
              id="close-addDataFailoverModal"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            ></button>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="w-[100%] d-flex flex-row align-items-center justify-content-between">
            <p className="w-[33%] fw-bolder">Language</p>
            <p className="w-[33%] fw-bolder">Account</p>
            <p className="w-[33%] fw-bolder">Status</p>
          </div>
          {data?.map((x: any, index: any) => {
            return (
              <div
                key={index}
                className="w-[100%] d-flex flex-row align-items-center justify-content-between mb-3"
              >
                <p className="w-[33%] p-0 m-0 align-self-center">
                  {x?.templateLanguage === "id"
                    ? "Indonesia"
                    : x?.templateLanguage === "en"
                    ? "English"
                    : ""}
                </p>
                <p className="w-[33%] p-0 m-0 align-self-center">
                  {x?.bussinesAccount}
                </p>
                <div
                  style={{
                    backgroundColor:
                      x.templateStatus === "APPROVED"
                        ? bg_approved
                        : x.templateStatus === "PENDING"
                        ? bg_pending
                        : x.templateStatus === "REJECTED"
                        ? bg_reject
                        : "white",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "center",
                    width: "fit-content",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      padding: "0px",
                      margin: "4px",
                      color:
                        x.templateStatus === "APPROVED"
                          ? color_approved
                          : x.templateStatus === "PENDING"
                          ? color_pending
                          : color_reject,
                      textTransform: "lowercase",
                    }}
                  >
                    {x?.templateStatus}
                  </p>
                </div>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export { TemplateStatusCell };
