import React from "react"
import db from "../../db"
import {createRef } from "../../db/connection"
import * as companyServ from "../../db/serviceCompany"
import firebase from "firebase/compat/app"
import { InviteUsers } from "../../resources/partials"
import * as lc from "../../app/modules/localstorage"
import { UserCompanies } from "../../app/models/User"
import { getClientByID } from "./clients"

export const initCompany = (companyCode:string, companyName:string) => {
  const companyRef = db.collection('company').doc(companyCode)
  const companyObj = {
    address: "Jalan Lidah Raya Harapan Barat Blok B No 5, Surabaya",
    companyName,
    isActive: true,
    currency: "IDR"
  }
  return companyRef.set(companyObj, { merge: true })
  .then(_ => ({companyObj: companyObj, companyRef}))
}

export const initAccount = (companyObj:Object, companyRef:firebase.firestore.DocumentReference) => { 
  return db.collection("account").add({
    company: companyRef,
    isActive: true,
    type: "whatsapp",
    access_token: "EABNLT11HfZBEBAAGDJlJTyb27gg7MzDmHjZCH0RuVv1QwxkbkXbELqZBFLw0LZA3LqfkiQi4ZB3yg7EQA7a1rxNoZAtZBiI253ZAEZCJ7Ek3iHVASKCEfjDzZB1t9lcicWZBAuWQU1lDcQ6naMeZAjhZACYhmpOOcs8rLaW61oggNN1aIZBq5XuuJtg5BJ",
    whatsappNumber: "6281216285566",
    whatsappNumber_ID: "109324118456283"
  }).then(_ => ({companyRef, companyObj}))
}


export const initUser = async (uid:string, userEmail:string, companyRef:firebase.firestore.DocumentReference) => {
  const client = lc.getItemLC(lc.LCName.Client)
  const doc = db.collection("users").doc(uid)
  const clientData = await getClientByID(client.id)
  if (!clientData) return console.error('client data not found');
  return doc.set({
      client: createRef("clients", client.id),
      email: userEmail,
      isActive: true,
      name: userEmail,
      friendListCount:0,
      address: "Jl Lidah Raya Harapan Barat Blok B no 5, kec. Lidah Wetan, kel. Lakarsantri, Surabaya, Jawa Timur, 60213",
      clientData: {
        avatar: clientData.avatar,
        clientName: clientData.clientName
      }

    }, { merge: true }).then(_ => {
      return initUserCompanies(doc)

    }).catch(error => console.log(`error msg: ${error}`))
}

export const initUserCompanies = (userDoc:firebase.firestore.DocumentReference) => {

  const clientRef = lc.getClientRef()!

  let userCompany = {
    addedAt: firebase.firestore.Timestamp.now(),
    company: createRef("company", "*"),
    id: "*",
    isActive: true,
    companyName: "All",
    client: clientRef
  }

  userDoc.collection('user_companies').doc('*')
    .set(userCompany,{merge: true})
    .then(() => console.log('success create * company access'))

  companyServ.getByClient().then(companies => {
    companies.forEach(company => {
      
      console.log(`create user company ${userDoc.id} company ${company.companyName}`)
      const userCompany = {
        addedAt: firebase.firestore.Timestamp.now(),
        company: createRef("company", company.id),
        isActive: true,
        companyName: company.companyName,
        id: company.id,
        client: company.client
      }

      userDoc.collection('user_companies').doc(company.id)
      .set(userCompany,{merge: true})
      .then(() => console.log('success'))
    })
  })

  return 
}

export const initFirestore = async (company:string, uid:string, userEmail:string) => {

  return initCompany("Labstore", "Labstore")
    .then(({companyObj, companyRef}) => {
      console.log("param Company doc successfully written!");
      return initAccount(companyObj, companyRef)
    })
    .then(({companyObj, companyRef}) => {
      console.log("Account doc successfully written!");
      return initUser(uid, userEmail, companyRef)
    })
    .then(_ => {
      console.log("user successfully created!");
      
      return Promise.resolve()
    }) 
    .catch((error) => {
        console.error("Error writing document: ", error);
    });

}

export const deleteTestMessage = (count:number) => {

  return db.collection("messages")
    .where("textContent","<=", "Ini pesan : "+ '\uf8ff')
    .limit(count)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length===0)
        return [];
      
      const deletedIds = Promise.all(snapshot.docs.map(async doc => {
        const id = doc.id
        const data = doc.data()
        await db.collection("messages").doc(id).delete()
          .then(() => console.log(`deleted data ${id} with ${JSON.stringify(data)}`))
          .catch((error:string) => {
            console.error("Error removing document: ", error);
        });

        return id
        
      }))

      return deletedIds


    })
    
}


export const deleteCollaborations = async (count:number) => {
  console.log("Delete Collaborations ...");
  return await db.collection("collaborations")
    .orderBy("createdAt", "desc")
    .limit(count)
    .get()
    .then(snapshot => {
      console.log("Result Query Collabs : "+snapshot.docs.length);
      if (snapshot.docs.length===0)
        return [];
      const deletedIds = Promise.all(snapshot.docs.map(async doc => {
        const id = doc.id
        const data = doc.data()
        await db.collection("collaborations").doc(id).delete()
          .then(() => console.log(`deleted data ${id} with ${JSON.stringify(data)}`))
          .catch((error:string) => {
            console.error("Error removing document: ", error);
        });

        return id
        
      }))

      return deletedIds
    })
}

export const deleteErrorMessage = (count:number) => {

  return db.collection("messages")
    // .where("textContent","<=", "Ini pesan : "+ '\uf8ff')
    //.where("customer.converter","==", null)
    .orderBy("customer.converter")
    .limit(count)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length===0)
        return [];
        
      const deletedIds = Promise.all(snapshot.docs.map(async doc => {
        const id = doc.id
        const data = doc.data()
        
        await db.collection("messages").doc(id).delete()
          .then(() => console.log(`deleted data ${id} with ${JSON.stringify(data)}`))
          .catch((error:string) => {
            console.error("Error removing document: ", error);
        });
        
        //console.log(`id: ${id} data: ${data}`)
        return id //JSON.stringify(data)
        
      }))
      

      return deletedIds


    })
    
}



export const updateUser = async (uid?:string) => {
  if (!uid) {
    console.log("missing parameter uid")
    return undefined;
  }

  const doc = db.collection("users").doc(uid)
  return doc.set({
      client: createRef("clients", "Labstore"),
      clientData: {
        clientName: "Labstore", 
        avatar: ""
      },
    }, { merge: true }).then(_ => {

    }).catch(error => console.log(`error msg: ${error}`))
}


export const updateTemplates = async () => {
  db.collection("templates")
    .where("account","in",["/accounts/abcbasd"])
}