/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryRequest } from "./QueryRequestProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from "../../../../../../resources/helpers";
import Product, { ProductQueryResponse } from "../../../../../models/Product"
import { RootState } from "../../../../../../setup/redux/store";
import { getProducts } from "./_requests";
import * as ProductListRedux from "../../../../../modules/product/productList/ProductListSlice";
import { productSorting } from "./_sort";

const QueryResponseContext = createResponseContext<Product>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listProductData: any = [];
  listProductData = useSelector(
    (state: RootState) => state.ProductList.listProduct
  ); //list CIQ
  let ProductQueryResponse: ProductQueryResponse;

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    console.log("Masuk use effect update list Product >>>>");
    setCounter(listProductData.length);
  }, [listProductData]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${counter}`,
    async () => {
      let data: any = [];

      //cek redux
      if (!listProductData || listProductData.length <= 0) {
        data = await getProducts(
          state.sort,
          state.order,
          state.search,
          state.action,
          state.lastId,
          state.items_per_page
        );
        if (data && data.length > 0) {
          dispatch(ProductListRedux.setListProduct(data));
        }
      } else {
        data = listProductData;
      }
      let resultData = productSorting(
        data,
        state.sort,
        state.items_per_page,
        state.action,
        state.order,
        state.search,
        state.page,
        (lengthData: number) => {
          if (state.search && state.search !== "") {
            dispatch(ProductListRedux.setCountProduct(lengthData));
          }
        }
      );
      if (state.search && state.search !== "") {
      } else {
        dispatch(
          ProductListRedux.setCountProduct(listProductData.length)
        );
      }

      ProductQueryResponse = {
        data: resultData,
      };
      return Promise.resolve(ProductQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  // console.log("response storefront "+JSON.stringify(response));

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
