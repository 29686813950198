import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { QUERIES } from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import Customer from "../../../../../models/Customer";
import * as ReduxCampaign from "../../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxCampaignTarget from "../../../../../modules/campaign/target/redux/CampaignTargetSlice";
import { useFirestoreTable } from "../../context/TableContext";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";

const TargetListGrouping = ({
  onSubmitTarget,
}: {
  onSubmitTarget: () => void;
}) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();

  const { data, selectedItems, setSelectedItems } = useFirestoreTable();

  const onSelectAll = () => {
    if (data?.items) {
      setSelectedItems(data?.items);
    }
  };

  const isAllSelected =
    selectedItems.length > 0 &&
    data?.items &&
    data.items.every(
      (customer) =>
        customer.id &&
        selectedItems.some((cust) => cust.id && cust.id === customer.id)
    );

  return (
    <div
      className={clsx(
        "d-flex justify-content-end align-items-center",
        isTabletOrMobile ? "flex-column w-100" : "me-3"
      )}
    >
      {!isTabletOrMobile && (
        <div
          data-testid="selected-count"
          className={clsx(
            "fw-bolder me-5",
            isTabletOrMobile ? "w-100 text-center mb-5" : ""
          )}
        >
          {selectedItems.length > 0 ? (
            <span className="me-2">
              {selectedItems.length} {t("Contacts.Info.Selected")}
            </span>
          ) : null}
        </div>
      )}

      {/* <button
        type="button"
        className={clsx(
          "btn btn-light-primary text-dark",
          isTabletOrMobile ? "w-100" : " me-3"
        )}
      >
        <span className="bi bi-funnel-fill" />
        {t("Common.Filter")}
      </button> */}

      {selectedItems.length > 0 ? (
        <button
          id="btn-add-to-target"
          data-testid="btn-add-to-target"
          type="button"
          data-bs-dismiss="modal"
          className={clsx(
            "btn btn-primary",
            isTabletOrMobile ? "w-100 mt-5" : ""
          )}
          onClick={() => {
            // dispatch(ReduxCampaign.setIsLoading(true))
            // setTimeout(() => {
            //   addToTargetSelectedItems.mutateAsync();
            // }, 100)
            onSubmitTarget();
          }}
        >
          {t("Common.Button.Add")}
        </button>
      ) : (
        <button
          data-testid="btn-add-to-target"
          type="button"
          className={clsx(
            "btn btn-primary",
            isTabletOrMobile ? "w-100 mt-5" : ""
          )}
          data-dismiss="modal"
          aria-label="Close"
          disabled
          // onClick={async () => await deleteSelectedItems.mutateAsync()}
        >
          {t("Common.Button.Add")}
        </button>
      )}
      {isTabletOrMobile && (
        <>
          <div className="btn btn-primary w-100 mt-5">
            <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 justify-content-center">
              <input
                data-testid="checkbox-select-all"
                type="checkbox"
                data-kt-check={isAllSelected}
                data-kt-check-target="#kt_table_users .form-check-input"
                checked={isAllSelected}
                onChange={onSelectAll}
                style={{
                  accentColor: "white",
                }}
              />
              <span>{t("Storefront.Button.SelectMarketplace.SelectAll")}</span>
            </div>
          </div>
          <div className="w-100 mt-5">
            <div
              data-testid="selected-count"
              className={clsx(
                "fw-bolder me-5",
                isTabletOrMobile ? "w-100" : ""
              )}
            >
              {selectedItems.length > 0 ? (
                <span className="me-2">
                  {selectedItems.length} {t("Contacts.Info.Selected")}
                </span>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { TargetListGrouping };
