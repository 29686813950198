import React, { useRef, useEffect } from "react";
import { AsideMenuMain } from "./AsideMenuMain";
import { AsideMenuProfile } from "./AsideMenuProfile";
import {
  DrawerComponent,
  ScrollComponent,
  ToggleComponent,
} from "../../../resources/assets/ts/components";
import { Link } from "react-router-dom";
import { useTheme } from "src/setup/theme/useTheme";
import { KTSVG, toAbsoluteUrl } from "src/resources/helpers";

const AsideMenu: React.FC = () => {
  const currentTheme = useTheme();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  /**
   * use effect for stay the side bar in profile item while the component rendered is profile thing
   */
  useEffect(() => {
    const path = window.location.pathname;
    const parts = path.split("/");
    if (parts[1] === "setting") {
      setTimeout(() => {
        const asideMenuMain = document.getElementById("aside-menu-main");
        asideMenuMain?.classList.add("d-none");

        //remove display none for menu profile component
        const asideMenuProfile = document.getElementById("aside-menu-setting");
        asideMenuProfile?.classList.remove("d-none");
      }, 100);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backtoMainClickHandler = () => {
    console.log("masuk di profileClickHandler");
    const asideMenuMain = document.getElementById("aside-menu-main");
    asideMenuMain?.classList.remove("d-none");

    //remove display none for menu profile component
    const asideMenuProfile = document.getElementById("aside-menu-setting");
    asideMenuProfile?.classList.add("d-none");
  };

  return (
    <div
      // id='kt_aside_menu_wrapper'
      id="kt_app_sidebar"
      ref={scrollRef}
      className="d-flex"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="100%"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-wrappers="#kt_aside_menu"
      data-kt-scroll-offset="0"
      style={{ width: "100%", height: "100%" }}
    >
      <div
        id="#kt_aside_menu"
        data-kt-menu="true"
        className="bg-white menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
      >
        <AsideMenuMain />
        <AsideMenuProfile />
      </div>
    </div>
  );
};

export { AsideMenu };
