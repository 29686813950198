import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Order, OrderStatus7Days } from "../../../../../../models/Order";
import { useFirestoreTable } from "../../context/TableContext";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isSameStatusValue } from "../../../OrderPage";
import { RootState } from "../../../../../../../setup/redux/store";
import { CellColoumnsProp } from "./_columns";
import { setIsModalOpen, setResponseExportOrder } from "../../../../../../components/modal/ModalExportOrder/redux/ModalExportOrderSlice";
import { useEffect, useState } from "react";
import { createMessageInitiateChatMarketplace } from "../../../../../../../actions/chat";
import Message from "../../../../../../models/Message";
import * as Chat from "../../../../../../../actions/chat";
import * as chat from "../../../../../../../app/modules/chat/redux/ChatSlice";
import * as LC from "../../../../../../modules/localstorage";
import * as CIQ from "../../../../../../../app/modules/ciq/redux/CIQSlice";
import { createNotificationAnotherUser } from "../../../../../../../db/serviceUser";
import { Collaboration } from "../../../../../../models/Chat";
type ActionCellProps = CellColoumnsProp;

const ActionCell: React.FC<ActionCellProps> = ({ row, cell, isAvailableToProcess }) => {
  const { setReadyToShipOrder, setExportedOrder, tableState } = useFirestoreTable();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = row.original;
  const shopId = data.accountData?.lazada_sellerID ?? data.accountData?.shopee_shopID ?? data.accountData?.tokopedia_shopID;
  const orderKey = data.id ?? `${data.marketplaceType}_${shopId}_${data.ordersn}`;
  const statusWithAction: string[] = [OrderStatus7Days.NewOrder];
  const statusProcessing: string[] = [OrderStatus7Days.Processing];
  const statusFilter = tableState.filters?.find((fil) => fil.field === "exportStatus")?.value;
  const marketplaceType = data.marketplaceType ?? "";

  const buttonExportOrderHandler = async () => {
    dispatch(setIsModalOpen(true));
    setExportedOrder([orderKey]);
  };

  const buttonInitiateChatHandler = async (order: Order) => {
    console.log("==> MULAI CLICK INCHA");
    const marketplaceType = data.marketplaceType ?? "";
    const orderId = data.id ?? "";

    if (marketplaceType === "tokopedia" || marketplaceType === "lazada") {
      const initiatedChat = await createMessageInitiateChatMarketplace(marketplaceType, orderId);
      if (initiatedChat) {
        const collaborationId = initiatedChat.collaborationId;
        const roomId = initiatedChat.roomId;
        const collab = await Chat.fetchCollaborationByID(collaborationId);
        if (!collab) {
          return
        }
        // dispatch(CIQ.deleteItemListCIQ(roomId));
        dispatch(chat.updateChatList(collab));
        dispatch(chat.setSelectedChat(collab.id));
        // console.log(roomId, "roomId sebelum setSelectedRoom INCHA");
        // console.log(selectedRoom, "sebelum setSelectedRoom INCHA");
        dispatch(chat.setSelectedRoom(roomId));
        dispatch(CIQ.deleteItemListCIQ(roomId));
        navigate(`/handled-customer`);

        console.log("IOFI SET ORDER INITIATE MESSAGE");
        dispatch(chat.setOrderInitiateMessage(order));
        // console.log(order, "order INCHA");
        // console.log(orderInitiateMessage, "setOrderInitiateMessage INCHA");
        if (collab.company && collab.company?.id && collab.customer) {
          //Process Create Notif For Another User
          createNotificationAnotherUser(collab);
        }

        // If handle CIQ failed
        else {
          dispatch(chat.updateAllChatChatList(collab));
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log(collabs, "collabs bawah INCHA");
  //   console.log(orderState, "orderState bawah INCHA");
  //   if (initiatedChat && orderState && collabs) {
  //     dispatch(chat.updateChatList(collabs));
  //     dispatch(chat.setSelectedChat(collabs.id));
  //     dispatch(chat.setSelectedRoom(initiatedChat.roomId));
  //     dispatch(chat.setOrderInitiateMessage(orderState));
  //   }
  //   console.log(orderInitiateMessage, "setOrderInitiateMessage bawah INCHA");
  //   console.log(selectedRoom, "setselectedRoom bawah INCHA");
  // }, [initiatedChat, orderState, collabs]);

  return (
    <td {...cell.getCellProps()} className="text-center">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" data-testid="dropdownToggleAction">
          {t("Marketplace.Table.Header.Action")}
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-light text-center">
          {/* In Tab New Order */}
          {isSameStatusValue(statusFilter, statusWithAction) && (
            <div>
              <div className="dropdown-item btn btn-active-light btn-sm">
                <button disabled={!isAvailableToProcess} type="button" className="btn btn-sm" onClick={() => setReadyToShipOrder([orderKey])}>
                  {t("OrderList.Button.ArrangeShipment")}
                </button>
              </div>
              <div className="dropdown-item btn btn-active-light btn-sm">
                {/* <button type="button" className="btn btn-sm" onClick={buttonInitiateChatHandler}>
                  Go To Chat
                </button> */}
                {marketplaceType === "tokopedia" || marketplaceType === "lazada" ? (
                  <button className="btn btn-sm" data-testid="goToChat" onClick={() => buttonInitiateChatHandler(data)}>
                    {t("OrderList.Button.GoToChat")}
                  </button>
                ) : (
                  <button className="btn btn-sm text-secondary" aria-disabled>
                    {t("OrderList.Button.GoToChat")}
                  </button>
                )}
              </div>
            </div>
          )}

          {/* In Tab Processing */}
          {isSameStatusValue(statusFilter, statusProcessing) && (
            <div className="dropdown-item btn btn-active-light btn-sm">
              <button type="button" className="btn btn-sm" onClick={() => buttonExportOrderHandler()}>
                {t("OrderList.Button.ExportOrder")}
              </button>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </td>
  );
};

export default ActionCell;
