import { Timestamp } from "firebase-admin/firestore";
import * as registerUserData from "./RegisterRedux";
import { UserRoles } from "../../../models/User";

interface InitialState {
  userId: string;
  submittedAt: Timestamp | undefined; 
  members: Member[] | [];
  responseMessage: string;
}

export interface Member {
  id: number;
  email?: string;
  role?: UserRoles;
}

const AddMemberAction: string = "AddMember";

export default InitialState;
export { AddMemberAction };
