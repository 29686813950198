import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { Response } from "../../resources/helpers";

interface Storefront {
  id? : string;
  name?: string;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  createdBy?: DocumentReference;
  updatedBy?: DocumentReference;
  isActive?: boolean;
  status?: string;
  lastStep?: string;
  marketplaceRegistered?: any; //Update By Server Side After Request to Marketpalce
  client?: DocumentReference;
  numberOfProducts?: number;
  marketplaceCategory?: [];

  //donot save to db
  initials?: {
    label: string;
    state: string;
  };
}
export default Storefront

export type StorefrontQueryResponse = Response<Storefront[]>;
// export type Storefront = {
//   
// };

export interface MarketplaceCategoryList {
  id? : string;
  categories?: any;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  createdBy?: DocumentReference;
  updatedBy?: DocumentReference;
  isChecked?: boolean;
  marketplaceCategoryID?: number; //Update By Server Side After Request to Marketpalce
  marketplaceCurrentCountItem?: number; //Update By Server Side After Request to Marketpalce
  marketplaceCurrentProductID?: string[]; //Update By Server Side After Request to Marketpalce
  marketplaceCategoryName?: string; //Update By Server Side After Request to Marketpalce
  type?: string;
}

export interface SelectedCategory {
    categoryId?: string;
    level?: number;
    hasChildren?: boolean;
    name?: string;
}

export const storefrontStatus = {
  drafted: "drafted",
  created: "created",
  requested: "requested",
  deleted: "deleted"
};

export const storefrontStep = {
  onStorefrontName: "storefrontName",
  onSelectMarketplace: "selectMarketplace",
  onSummary: "summary"
};

export type ProductCategoriesAction = {
  create: "create";
  update: "update";
  delete: "delete";
};