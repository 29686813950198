import clsx from "clsx";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toAbsoluteUrl } from "../../../../resources/helpers";
import { RootState } from "../../../../setup/redux/store";
import * as ReduxProduct from "../redux/ProductSlice";
import ModalConfirmation from "../../modal/modalConfirmation";

interface ShipmentCardProps {
  shipment: any;
  id: string;
  onClickNext: boolean;
  // setIsValid: any;
}

const ShipmentCard: FC<ShipmentCardProps> = ({ shipment, id, onClickNext }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );
  const shipmentsList = useSelector(
    (state: RootState) => state.Shipments.listShipments
  );
  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isValidShipment, setIsValidShipment] = useState<boolean>(true);

  const imgName = shipment.name.replaceAll(" ", "").toLowerCase();
  const src = toAbsoluteUrl(`/media/logos/shipping/${imgName}.png`);

  const handleOnChangeActiveShipment = (id: string, name: string) => {
    console.log("id : ", id);
    console.log("isactive : ", isActive);
    const findShipments = rd_Shipments?.find(
      (shipment: any) => shipment.id === id
    );
    console.log("findShipmentsFromRedux : ", findShipments);
    let shipmentsData;
    const data = {
      id: id,
      isActive: !isActive,
      name: name,
      types: findShipments?.types !== undefined ? findShipments.types : [],
    };
    if (!findShipments) {
      //jika belum ada, ditambahkan ke redux
      shipmentsData = [...rd_Shipments, data];
    } else {
      //jika sudah ada, ganti data dengan data baru
      shipmentsData = rd_Shipments?.filter(
        (shipment: any) => shipment.id !== id
      );
      shipmentsData.push(data);
    }
    dispatch(ReduxProduct.setShipments(shipmentsData));
    setIsActive((prev) => !prev);
  };

  const handleOnChangeShipmentType = (
    parentId: string,
    typeId: string,
    typeName: string
  ) => {
    const shipmentTypeCheckbox = document.getElementById(typeId);
    const checkedValue = shipmentTypeCheckbox?.getAttribute("checked");
    if (checkedValue === null || checkedValue === "false") {
      shipmentTypeCheckbox?.setAttribute("checked", "true");
    } else {
      shipmentTypeCheckbox?.removeAttribute("checked");
    }
    console.log("parentId id : ", parentId);
    //find in parent
    let findShipments = rd_Shipments?.find(
      (shipment: any) => shipment.id === parentId
    );
    //add type to shipment
    let typeData = findShipments.types;
    const typeShipmentToPush = {
      id: typeId,
      name: typeName,
    };
    //kalau belum ada di add to type nya
    if (!typeData) {
      typeData = [typeShipmentToPush];
      console.log("typeData to push : ", typeData);
    } else {
      const findShipmentType = typeData?.find(
        (shipment: any) => shipment.id === typeId
      );
      console.log("findType : ", findShipmentType);
      if (!findShipmentType) {
        typeData = [...typeData, typeShipmentToPush];
      } else {
        //kalau sudah ada/ di uncheck maka di delete from type nya
        typeData = typeData?.filter((shipment: any) => shipment.id !== typeId);
      }
    }
    const dataTemp = {
      id: findShipments.id,
      isActive: isActive,
      name: findShipments.name,
      types: typeData,
    };
    let shipmentsData = rd_Shipments?.filter(
      (shipment: any) => shipment.id !== id
    );
    shipmentsData.push(dataTemp);
    dispatch(ReduxProduct.setShipments(shipmentsData));
  };

  const shipmentValidation = (id: string) => {
    console.debug("shipment.id on click next : ", id);
    if (rd_Shipments.length > 0) {
      const getShipment: any = shipmentsList?.find(
        (shipment: any) => shipment.id === id
      );
      console.debug("GETSHIPMENT, ", getShipment);
      const shipment = rd_Shipments.find((shipment: any) => shipment.id === id);
      if (
        shipment &&
        shipment.isActive &&
        getShipment.children.length > 0 &&
        shipment.types.length <= 0
      ) {
        setIsValidShipment(false);
      } else {
        setIsValidShipment(true);
      }
    }
  };

  useEffect(() => {
    if (rd_Shipments?.length > 0) {
      const findShipments = rd_Shipments.find(
        (shipment: any) => shipment.id === id
      );
      if (findShipments) {
        console.log(`findShipments.isActive : ${findShipments.isActive}`);
        setIsActive(findShipments.isActive);
        console.log("findShipments.types : ", findShipments.types);
        for (let index = 0; index < findShipments.types.length; index++) {
          const element = findShipments.types[index];
          const shipmentTypeCheckbox: any = document.getElementById(element.id);
          if(shipmentTypeCheckbox) shipmentTypeCheckbox?.setAttribute("checked", true);
        }
        if (findShipments.isActive === true) {
          shipmentValidation(shipment.id);
        }
      }
    }
  }, [rd_Shipments]);

  useEffect(() => {
    if (isActive) {
      shipmentValidation(shipment.id);
    }
  }, []);

  useEffect(()=>{
    if(rd_marketplaces && rd_marketplaces.length > 0){
      const findMarketplace = rd_marketplaces?.find((market) => {
        return (shipment?.name?.toLowerCase().includes(market.marketplace.toLowerCase()))
          
      })
      if(findMarketplace && findMarketplace.isActive) setIsDisabled(true);
      else setIsDisabled(false);
    }
  },[rd_marketplaces])

  console.log("onClickNext : ", onClickNext);

  return (
    <div
      className={clsx(`col-sm-12 col-lg-6`, {
        "error-shipmenttype": onClickNext && !isValidShipment && isActive,
        "pt-6": isTabletOrMobile,
        "mb-6": !isTabletOrMobile,
      })}
      key={id}
      data-testid={id}
    >
      <div
        className={clsx(
          "bg-primary d-flex flex-column h-100 rounded p-4 w-100",
          {
            "bg-opacity-20": isActive,
            "bg-opacity-20 opacity-50": !isActive,
          }
        )}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="rounded me-4">
              <img
                src={src}
                height="35px"
                className="rounded "
                alt={shipment.name + ".png"}
              />
            </div>
            <h2 className="m-0 fw-bolder">{shipment.name}</h2>
          </div>
          <div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                data-testid={`switch-shipment-${shipment.name.toLowerCase()}`}
                id="flexSwitchCheckDefault"
                checked={isActive}
                onChange={() => {
                  //jika disable (mandatory jika shopee)
                  if(isDisabled && isActive){
                    return;
                  }
                  handleOnChangeActiveShipment(shipment.id, shipment.name);
                }}
                // disabled={isDisabled}
                data-bs-toggle={isDisabled && isActive ? "modal" : ""}
                data-bs-target={isDisabled && isActive ? `#${shipment.id}` : ""}
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          {shipment?.children?.map((child: any) => (
            <Fragment key={child.id.toString()}>
              <div className="col-6" key={child.id}>
                <div className="d-flex align-items-center gap-4 mt-4">
                  <div className="form-check form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={child.id}
                      disabled={isActive === false}
                      onClick={() =>
                        handleOnChangeShipmentType(id, child.id, child.name)
                      }
                    />
                    <label htmlFor={child.id} className="form-check-label">
                      {child.name}
                    </label>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}

          {/* Error Message */}
          <div
            className="mt-2 mb-2"
            style={{ height: "20px" }}
            data-testid="alert-shippertype"
          >
            {onClickNext && !isValidShipment && isActive && (
              <span
                id="shipping-error-selection-type"
                className="mt-2 text-danger"
              >
                {t("AddNewProduct.Alert.ShippingType")}
              </span>
            )}
          </div>
        </div>
      </div>
      {isDisabled && 
        <ModalConfirmation 
          id={shipment.id} 
          title={t("StorefrontList.Alert.Delete.Storefront.Title")} 
          body={t("AddNewProduct.Modal.Shipment.ShopeeAlert")} 
          confirmOption={t("BS.Button.Close")}
        />
      }
    </div>
  );
};

export default ShipmentCard;
