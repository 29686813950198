import { Collaboration as HandledMessageListItem } from "../../../models/Chat";
import Message from "../../../models/Message";

import firebase from "firebase/compat/app";
import { Order } from "../../../models/Order";

interface InitialState {
  //Chat
  chatList: HandledMessageListItem[];
  selectedCollaboration: HandledMessageListItem | undefined;
  listMessage: Message[];
  selectedChat: string;
  selectedRoom: string;
  countTotalUnreadMessages: number;
  lastVisibleMessage: any;
  countSelectedUnreadMessage: number;
  replyToMessage: any;
  replyToMessageAudioDuration: string;
  userLastInteraction?: firebase.firestore.Timestamp;

  //Chat User
  userListMessage: Message[];
  userChatList: HandledMessageListItem[];
  countTotalUserUnreadMessages: number;
  selectedUserChat: string;
  countSelectedUserUnreadMessage: number;

  // All Chat User
  allChatChatList: HandledMessageListItem[];
  allChatSelectedCollaboration: HandledMessageListItem | undefined;
  allChatListMessage: Message[];
  allChatSelectedChat: string;
  allChatSelectedRoom: string;
  allChatCountTotalUnreadMessages: number;
  allChatCountSelectedUnreadMessage: number;
  allChatLastVisibleMessage: any;

  chatState: string;
  chatInput: any;
  orderInitiateMessage: Order | undefined;
}
const UpdateChatListAction: string = "UpdateChatList";

export type unsubFN = () => void;

export default InitialState;
export { UpdateChatListAction };
