import { format } from "date-fns";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  createRef,
  getCustomerByPhoneNumber,
  getCustomerDataByPhoneNoAndClient,
} from "../../../../../../../db";
import {
  countCampaignMessagesByCampaignId,
  fetchMessagesByCampaignID,
} from "../../../../../../../db/serviceCampaign";
import { QUERIES } from "../../../../../../../resources/helpers";
import { RootState } from "../../../../../../../setup/redux/store";
import Campaign, { CampaignMessage } from "../../../../../../models/Campaign";
import Customer from "../../../../../../models/Customer";
import * as ReduxCampaignMessage from "../../../../../../modules/campaign/message/CampaignMessageSlice";
import * as ReduxCampaignReport from "../../../../../../modules/campaign/report/CampaignReportSlice";
import * as lc from "../../../../../../modules/localstorage/index";
import CampaignMobileIcon from "../../../../campaign-create/component/CampaignMobileIcon";
import { useQueryResponse } from "../../core/QueryResponseProvider";

const CampaignReportHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  // const { selected } = useListView();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );

  const queryClient = useQueryClient();

  const onBackHandler = () => {
    dispatch(ReduxCampaignReport.cleanReduxCampaignReport(true));
    dispatch(ReduxCampaignReport.cleanReduxCampaignReportData(true));
    dispatch(ReduxCampaignMessage.cleanReduxCampaignMessage(true));

    nav("/campaign/list");
  };

  const refreshData = async () => {
    queryClient.invalidateQueries(QUERIES.CAMPAIGN_REPORT_INFO);
    queryClient.invalidateQueries(QUERIES.CAMPAIGN_REPORT_DETAIL);
    // console.log("refresh report data");
    // let messagesCount = await countCampaignMessagesByCampaignId(
    //   campaignData.id!
    // );
    // if (messagesCount) {
    //   console.log(`messagesCount : ${JSON.stringify(messagesCount)}`);
    //   const newCampaignData: Campaign = {
    //     ...campaignData,
    //     campaignMessagesCount: messagesCount,
    //   };
    //   dispatch(ReduxCampaignReport.setSelectedCampaign(newCampaignData));
    // }
    // // Campaign Messages
    // let newCampaignMessages: CampaignMessage[] = [];
    // let campaignReportDetails: any = [];
    // const campaignMessages = await fetchMessagesByCampaignID(campaignData.id!);
    // if (campaignMessages) {
    //   // // Fetch Customer Data
    //   for (var i = 0; i < campaignMessages.length; i++) {
    //     let data = campaignMessages[i].data;
    //     let reportDetailData;
    //     // ["customerName","phoneNumber","updatedAt","message","status"]
    //     reportDetailData = {
    //       phoneNumber: data.phoneNumber,
    //       message: data.message,
    //       status: data.status,
    //     };
    //     // Insert ID
    //     if (campaignMessages[i].id) {
    //       data.id = campaignMessages[i].id;
    //     }
    //     console.log(
    //       "getCustomerDataByPhoneNoAndCompany logger: ",
    //       data.phoneNumber,
    //       campaignData.company?.id
    //     );
    //     // Get Customer Data
    //     const client = lc.getItemLC(lc.LCName.Client);
    //     // const clientRef = createRef("clients", campaignData.client?.id);
    //     const clientRef = createRef("clients", campaignData.client?.id!);
    //     const customers = await getCustomerDataByPhoneNoAndClient(
    //       data.phoneNumber,
    //       clientRef
    //     );
    //     let customerData = customers.find((customer) => {
    //       return customer.data.phoneNumber === data.phoneNumber;
    //     });
    //     console.log("customers logger: ", customers);
    //     if (customerData) {
    //       // console.log(`Customer Data: ${JSON.stringify(customerData.data)}`)
    //       data.customerModel = customerData.data as Customer;
    //       reportDetailData = {
    //         ...reportDetailData,
    //         // customerModel: data.customerModel,
    //         customerName: `${data.customerModel.firstName} ${
    //           data.customerModel.lastName ? data.customerModel.lastName : ""
    //         }`,
    //       };
    //       console.log("customerName: ", reportDetailData);
    //     }
    //     // Updated At
    //     if (data.updatedAt !== undefined) {
    //       data.updatedAt = format(
    //         new Date(data.updatedAt?.seconds! * 1000),
    //         "d MMM yyyy, h:mm aaa"
    //       );
    //     }
    //     reportDetailData = {
    //       ...reportDetailData,
    //       updatedAt: data.updatedAt,
    //     };
    //     newCampaignMessages.push(data as CampaignMessage);
    //     campaignReportDetails.push(reportDetailData);
    //   }
    // }
    // if (campaignReportDetails.length > 0) {
    //   console.log("update list campaign message");
    //   dispatch(ReduxCampaignMessage.setCurrentPageMessage(10));
    //   dispatch(
    //     ReduxCampaignMessage.setListCampaignMessage(campaignReportDetails)
    //   );
    // }
  };

  return (
    <>
      {isTabletOrMobile && (
        <>
          <CampaignMobileIcon />
          <div className="p-5">
            <h3 className="mx-7">{campaignData?.campaignName}</h3>
          </div>
        </>
      )}
      {!isTabletOrMobile && (
        <div className="card-header border-0 ps-0">
          <div className="card-toolbar m-0">
            <button
              className="btn btn-white bi bi-arrow-left"
              onClick={onBackHandler}
            />
            <h3 className="m-0">{campaignData?.campaignName}</h3>
          </div>
          <div className="card-toolbar m-0">
            <button
              type="button"
              className="btn btn-light-primary me-2"
              onClick={() => refreshData()}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export { CampaignReportHeader };
