import { getListAttributesListByMarketplaceAndCategoryId } from "../db/serviceMarketplaceProductCategory";

export function getAttributesByMarketplaceAndCategoryId(
  marketplaceName: string,
  categoryId: string
): Promise<any> {
  const getData = getListAttributesListByMarketplaceAndCategoryId(marketplaceName, categoryId)
    .then((initialCategory) => {
      return initialCategory;
    });
  return getData;
}