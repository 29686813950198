import { Timestamp } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as ProcessService from "../../../../../../../db/serviceProcesses";
import SocketConnection from "../../../../../../../setup/socket/socket";
import Modal from "../../../../../../components/Modal";
import { Process, ProcessOrder } from "../../../../../../models/Process";
import { getClientRef } from "../../../../../../modules/localstorage";
import { useFirestoreTable } from "../../context/TableContext";

import moment from "moment";
import { useQuery } from "react-query";
import orderColumns from "../../../../shiporder-export/order-list/table/columns/_columns";

interface IProcessSocketEvent {
  status: string;
  process: string;
}

const PROCESS_STATUS = {
  FINISH: "finished",
};

interface IUserDownloadModal {
  processID: string;
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}

const UserDownloadModal: React.FC<IUserDownloadModal> = ({ isModalOpen, setIsModalOpen, processID }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(["process-download-data", processID], () => ProcessService.getUsersDownloadDataByProcessID(processID), {
    enabled: isModalOpen && !!processID,
  });

  const items = data ?? [];

  return (
    <Modal
      id="UserDownloadModal"
      title={t("ExportOrderProcess.Download.Modal.Title")}
      confirmOption="OK"
      isModalOpen={isModalOpen}
      onConfirm={() => setIsModalOpen()}
      centered={true}
    >
      {isLoading ? (
        <div className="text-center fs-6">
          <span className="spinner-border"></span>
          <p>{t("Common.Modal.Process.Loading")}</p>
        </div>
      ) : (
        <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead className="position-sticky top-0 bg-white">
            <tr>
              {/* {headers.map((header, i) => { */}
              <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">
                {t("ExportOrderProcess.Download.Modal.Header.UserName")}
              </th>
              <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">
                {t("ExportOrderProcess.Download.Modal.Header.Time")}
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              items.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{item.userData?.name}</td>
                    <td className="text-center">{item.downloadAt ? moment(item.downloadAt.toDate()).format("DD/MM/YYYY HH:mm") : t("Common.NoRecord")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2} className="text-center">
                  {t("Common.NoRecord")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </Modal>
  );
};

export default UserDownloadModal;
