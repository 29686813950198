import { FC, PropsWithChildren, useState } from "react";
import { CellProps } from "react-table";
import { ToastContainer, toast } from "react-toastify";
import { Order } from "../../../../../../models/Order";
import EditPriorityModal from "../../components/modal/EditPriorityModal";
import * as OrderService from "../../../../../../../db/serviceOrder";
import { useFirestoreTable } from "../../context/TableContext";
import { useTranslation } from "react-i18next";

type PriorityCellProps = {
  oldOrder: Order;
  isAvailableToProcess: boolean;
};

const PriorityCell = ({ oldOrder, isAvailableToProcess }: PriorityCellProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { refreshData } = useFirestoreTable();
  const closeModal = () => {
    setShowModal(false);
  };

  const SubmitChangedData = async (newPrior: number) => {
    closeModal();
    if (oldOrder.id) {
      setIsLoading(true);
      await OrderService.updatePriorityNumber(oldOrder.id, newPrior);
      toast(t("OrderList.Toast.PriorityUpdated"), {
        type: "success",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
      console.log("Updated Priority for", oldOrder.id, ">>", newPrior);
      refreshData();
    }
  };

  // const data = row.original;
  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center cursor-pointer ${isAvailableToProcess ? "text-dark" : ""}`}
        onClick={() => setShowModal(true)}
        data-testid="edit-priority-column"
      >
        <p className="me-2 p-0 m-0" style={{ color: "#777" }}>{oldOrder.orderPriorityNumber}</p>
        {isLoading ? (
          <span className="spinner-border spinner-border-sm" />
        ) : (
          <span>
            <i className="bi bi-pencil-square"></i>
          </span>
        )}
      </div>
      {showModal && <EditPriorityModal data={oldOrder.orderPriorityNumber} isOpen={showModal} onClose={closeModal} submitData={SubmitChangedData} />}
    </>
  );
};

export default PriorityCell;
