import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { reqReadyToShipOrder, reqDownloadCSVExportedOrders } from "../../../../../../../api/server/order";
import * as ProcessesService from "../../../../../../../db/serviceProcesses";
import * as lc from "../../../../../../modules/localstorage/index";
import { useFirestoreTable } from "../../context/TableContext";

type ActionCellProps = {
  status: string;
  processID: string;
  orderIDs: string[];
  fileURL?: string;
  type: "rtsOrder" | "exportOrder";
};

export default function ActionCell({ type, fileURL, status, processID, orderIDs }: ActionCellProps) {
  const { refreshData } = useFirestoreTable();
  const { t } = useTranslation();
  const [isLoadingRetry, setIsLoadingRetry] = useState(false);

  const handleRetry = async () => {
    const userID = lc.getItemLC(lc.LCName.UserID);
    setIsLoadingRetry(true);
    if (type === "rtsOrder") {
      await reqReadyToShipOrder(orderIDs, userID, processID);
    } else if (type === "exportOrder") {
      await reqDownloadCSVExportedOrders(orderIDs, userID, processID);
    }
    await refreshData();
    setIsLoadingRetry(false);
  };

  if (status === "waiting") {
    return (
      <div className="d-flex align-items-center gap-2 justify-content-center px-4 fs-7">
        <span className="spinner-border spinner-border-sm"></span>
        <span>{t("ExportOrderProcess.Status.Processing")}</span>
      </div>
    );
  }

  if (status === "failed") {
    return (
      <button disabled={isLoadingRetry} onClick={() => handleRetry()} type="button" className="w-100 btn btn-light-primary btn-sm">
        {isLoadingRetry ? t("ExportOrderProcess.Button.Loading") : t("ExportOrderProcess.Button.Retry")}
      </button>
    );
  }

  if (status === "finished" && type === "rtsOrder") {
    return (
      <button disabled type="button" className="w-100 btn btn-secondary btn-sm">
        {t("ExportOrderProcess.Status.Finished")}
      </button>
    );
  }
  return (
    <button
      type="button"
      className="w-100 btn btn-primary btn-sm"
      onClick={async () => {
        const userID = lc.getItemLC(lc.LCName.UserID);
        if (!processID || !userID) {
          return undefined;
        }

        const link = document.createElement("a");
        link.href = fileURL || "";
        link.download = "";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        await ProcessesService.incrementDownloadByIDAndAccID(processID, userID);
        refreshData();
      }}
    >
      {t("ExportOrderProcess.Button.Download")}
    </button>
  );
}
