import clsx from "clsx";
import { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { RootState } from "../../../../../../../setup/redux/store";
import Marketplace, {
  ListMarketplace,
} from "../../../../../../../util/Marketplace";
import Company from "../../../../../../models/Company";
import Account from "../../../../../../models/Account";
import { fetchCompanyMarketplaceAccounts } from "../../../../../../../actions/account";
import MarketplaceCategoryModalDesktop from "../../../../../../modules/modal/marketplace-modal/MarketplaceModalDesktop";
import MarketplaceCategoryModalMobile from "../../../../../../modules/modal/marketplace-modal/MarketplaceModalMobile";
import * as ReduxProduct from "../../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../../modules/util/Loading";
import { CapitalizedLetter } from "../../../../../../../util/Formatter";

interface CompanyAndMarketplaceSelectionProps {
  company?: any;
  onClickNext?: boolean;
  isSummary?: boolean;
  isDisabled?: boolean;
}

function convertArray(array: any) {
  if (array && array.length !== 0) {
    return [Object.values(array[0])];
  }
  return [{}];
}

const CompanyAndMarketplaceSelection: FC<
  CompanyAndMarketplaceSelectionProps
> = ({ company, onClickNext, isSummary, isDisabled }) => {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const [isShowMarketplace, setIsShowMarketplace] = useState(false);
  const [isAllSelectedMarketplaceValid, setIsAllSelectedMarketplaceValid] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );
  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const shipmentsList = useSelector(
    (state: RootState) => state.Shipments.listShipments
  );

  const [isActiveCompany, setIsActiveCompany] = useState(false);
  const [marketplacesData, setMarketplacesData] = useState<any>(null);
  const [activeAccounts, setActiveAccounts] = useState<Account[]>([]);
  const listMarketplace = useRef<Marketplace[]>([]);
  useEffect(() => {
    const activeMarketplaces = ListMarketplace.filter(
      (marketplace) => !marketplace.isDisabled
    ).map((marketplace) => marketplace.name);

    const fetch = async () => {
      const activeAccounts = await fetchCompanyMarketplaceAccounts(
        company.id,
        activeMarketplaces.map((marketplace) => marketplace.toLowerCase())
      );
      setActiveAccounts(activeAccounts);
    };
    fetch();
  }, []);

  useEffect(() => {
    //set listMarketplace from activeAccounts
    listMarketplace.current = activeAccounts.map((account) => {
      return {
        name: account.type,
        isDisabled: false,
      };
    });
  }, [activeAccounts]);

  const rd_MarketplaceCategoryList = useSelector(
    (state: RootState) => state.Product.marketplaceCategoryList
  );

  const handleOnChange = (company: Company, marketplace: string) => {
    const checkbox = document.getElementById(
      `checkbox-${
        marketplace.toLowerCase() + "-" + company.companyName.toLowerCase()
      }`
    );
    const checkedValue = checkbox?.getAttribute("checked");

    console.log(`Checkbox for ${marketplace} is ${checkedValue}`);

    let isActive = false;
    if (checkbox && (checkedValue === null || checkedValue === "false")) {
      checkbox?.setAttribute("checked", "true");
      isActive = true;
    } else {
      checkbox?.removeAttribute("checked");
    }

    const selectedCategory = handleSelectedMarketplaceCategory(
      company,
      marketplace
    );
    console.log("findSelectedCategory : ", selectedCategory);

    let updatedMarketplaces;
    if(!rd_marketplaces || rd_marketplaces.length < 1){
      updatedMarketplaces = [
        {
          company: company.id,
          marketplace,
          isActive: true,
          category: selectedCategory,
        },
      ];
    }else{
      updatedMarketplaces = rd_marketplaces.map((market)=>{
        console.log(`Check company id in marketplace ${market.company} and in company ${company.id.toLowerCase()}`);
        if(market.company.toLowerCase() === company.id.toLowerCase() &&
          market.marketplace === marketplace){
            return {...market, isActive}
        }else{
          return market;
        }
      })
    }
    dispatch(ReduxProduct.setMarketplaces(updatedMarketplaces));

    //handle if marketplace shopee, then shopee xpress must be active
    //find if marketplace.marketplaceName is Shopee then add shopee shipment to be mandatory
    let findShipmentByMarketplace = rd_Shipments.find((shipment) =>
      shipment.name.toLowerCase().includes(marketplace.toLowerCase())
    );
    console.log("findShipmentsFromRedux : ", findShipmentByMarketplace);
    //if not found find in list
    let shipmentsData;
    if (!findShipmentByMarketplace) {
      findShipmentByMarketplace = shipmentsList?.find((shipment: any) =>
        shipment.name.toLowerCase().includes(marketplace.toLowerCase())
      );
      const data = {
        id: findShipmentByMarketplace?.id,
        isActive: true,
        name: findShipmentByMarketplace?.name,
        types:
          findShipmentByMarketplace?.types !== undefined
            ? findShipmentByMarketplace.types
            : [],
      };
      //jika belum ada, ditambahkan ke redux
      shipmentsData = [...rd_Shipments, data];
      dispatch(ReduxProduct.setShipments(shipmentsData));
    } else {
      //jika sudah ada, ganti data dengan data baru
      shipmentsData = rd_Shipments?.filter(
        (shipment: any) => shipment.id !== findShipmentByMarketplace.id
      );
      console.log(`check isActive : ${isActive}`);
      const data = {
        id: findShipmentByMarketplace?.id,
        isActive: isActive,
        name: findShipmentByMarketplace?.name,
        types:
          findShipmentByMarketplace?.types !== undefined
            ? findShipmentByMarketplace.types
            : [],
      };
      shipmentsData.push(data);
      dispatch(ReduxProduct.setShipments(shipmentsData));
    }
  };

  const handleCompany = (companyData: Company) => {
    const data = {
      company: companyData.id,
      isActive: !isShowMarketplace,
    };
    const companyCheckbox = document.getElementById(companyData.id);
    const checkedValue = companyCheckbox?.getAttribute("checked");
    console.log("companyCheckbox checked  value : ", companyCheckbox);
    if (checkedValue === null || checkedValue === "false") {
      companyCheckbox?.setAttribute("checked", "true");
    } else {
      companyCheckbox?.removeAttribute("checked");
    }
    const findCompany = rd_SelectedCompanies?.find(
      (company: any) => company.company === companyData.id
    );
    let companiesData;
    if (!findCompany) {
      companiesData = [...rd_SelectedCompanies, data];
    } else {
      companiesData = rd_SelectedCompanies.filter(
        (company) => company.company !== companyData.id
      );
      companiesData.push(data);
    }
    setIsShowMarketplace((prev) => !prev);
    dispatch(ReduxProduct.setCompanies(companiesData));
  };

  const findMarketplace = () => {
    const selectedCompany: any =
      rd_SelectedCompanies &&
      rd_SelectedCompanies?.find(
        (companyData: any) => companyData.company === company.id
      );

    const companyCheckbox: any = document.getElementById(selectedCompany?.id);

    // set initial checked status of company
    if (selectedCompany && selectedCompany?.isActive) {
      companyCheckbox?.setAttribute("checked", true);
      setIsShowMarketplace(true);
    } else {
      companyCheckbox?.removeAttribute("checked");
      setIsShowMarketplace(false);
    }

    if (selectedCompany) {
      const selectedMarketplace = rd_marketplaces?.filter(
        (m) => m.company === selectedCompany?.company
      );
      console.log("selectedMarketplace : ", selectedMarketplace);
      // set initial checked status of marketplace
      selectedMarketplace &&
        selectedMarketplace.length > 0 &&
        selectedMarketplace?.forEach((marketplace: any) => {
          let marketplaceTypeCheckbox: any = document.getElementById(
            `checkbox-${marketplace.marketplace.toLowerCase()}-${company.companyName.toLowerCase()}`
          );

          if (marketplace?.isActive) {
            marketplaceTypeCheckbox?.setAttribute("checked", true);
          } else {
            marketplaceTypeCheckbox?.removeAttribute("checked");
          }
        });

      console.log(`selectedMarketplace length : ${selectedMarketplace.length}`);
      const isAllNotActive =
        selectedMarketplace &&  selectedMarketplace.length > 0 &&
        selectedMarketplace.every((sm: any) => sm.isActive === false);
      console.log(`isAllNotActive : ${isAllNotActive}`);
      setIsAllSelectedMarketplaceValid(isAllNotActive);
    }
  };

  const handleSelectedMarketplaceCategory = (
    company: any,
    marketplaceName: any
  ) => {
    let selectedCategory;
    if (rd_marketplaces && rd_marketplaces.length > 0) {
      console.log("enter product marketplace");
      rd_marketplaces.find((marketplace) => {
        if (
          marketplace.marketplace.toLowerCase() === marketplaceName &&
          marketplace.company === company.id &&
          marketplace.category !== undefined
        ) {
          selectedCategory = marketplace.category;
        } else {
          console.log("enter marketplace category list from redux");
          const findFromRedux: any = rd_MarketplaceCategoryList.find(
            (market: any) =>
              market.marketplace.toLowerCase() === marketplaceName.toLowerCase()
          );
          selectedCategory = findFromRedux?.categories;
        }
      });
    } else {
      console.log("enter marketplace category list default");
      console.log("rd_MarketplaceCategoryList :", rd_MarketplaceCategoryList);
      const findFromDefault: any = rd_MarketplaceCategoryList.find((market) => {
        console.log("market.categories : ", market);
        return (
          market.marketplace.toLowerCase() === marketplaceName.toLowerCase()
        );
      });
      if (findFromDefault) selectedCategory = findFromDefault?.categories;
    }
    console.log("selectedCategory : ", selectedCategory);
    return selectedCategory;
  };

  useEffect(() => {
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      findMarketplace();
    }
  }, [onClickNext]);

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const getMarketplaceProductCategoryData = (marketplaceName: string) => {
    let arrayData: any = [[]];
    if (
      marketplaceName.toLowerCase() === "shopee" &&
      reduxShopeeProductCategoryData
    ) {
      let parent: any = reduxShopeeProductCategoryData?.filter(
        (category: any) => {
          return category.parentCategoryId === 0;
        }
      );

      let newArray = [...arrayData];
      newArray[0] = parent;

      arrayData = newArray;
    }
    return arrayData;
  };

  const findCompanyIsActive = (selectedCompany: any) => {
    console.log("selected company id : ", selectedCompany);
    const findCompany: any =
      rd_SelectedCompanies &&
      rd_SelectedCompanies.find((market) => {
        console.log("check market : ", market);
        if (market.company === selectedCompany && market.isActive === true)
          return market;
        return false;
      });
    if (findCompany) setIsActiveCompany(true);
  };

  useEffect(() => {
    console.log("useffect selected storefront in company selection");
    if (isSummary) {
      findCompanyIsActive(company.id);
      const marketplaces =
        rd_marketplaces &&
        rd_marketplaces.filter((market: any) => market.isActive === true);
      console.log("find marketplaces : ", marketplaces);
      if (!marketplacesData || marketplacesData.length <= 0)
        setMarketplacesData(marketplaces);
    }
  }, []);

  useEffect(() => {
    setIsRefresh(true);
    setIsLoading(true);
  }, [isShowMarketplace]);

  useEffect(() => {
    setTimeout(() => {
      setIsRefresh(false);
      setIsLoading(false);
    }, 500);
  }, [isRefresh === true]);

  console.log(`CHECK -> onClickNext ${onClickNext} \nisShowMarketplace ${isShowMarketplace} \nisAllSelectedMarketplaceValid ${isAllSelectedMarketplaceValid}`)

  return (
    <div key={`selection-${company?.id}`}>
      {(!isSummary || (isSummary && isActiveCompany)) && (
        <div className="form-check form-check-custom form-check-solid mt-5">
          <input
            data-testid={`company-${company?.companyName}`}
            id={company?.id}
            className="form-check-input"
            type="checkbox"
            checked={isShowMarketplace}
            onChange={() => handleCompany(company)}
          />
          <label
            htmlFor={company?.id}
            className="form-check-label fw-bolder fs-2"
          >
            {company?.companyName}
          </label>
        </div>
      )}
      <div
        className="mt-2 mb-2"
        style={{ height: isTabletOrMobile ? "auto" : "10px" }}
        data-testid="alert-marketplace"
      >
        {/* {onClickNext && !isSelectedMarketplace && isShowMarketplace && ( */}
        {onClickNext && isShowMarketplace && isAllSelectedMarketplaceValid && (
          <span className="text-danger alert-marketplace">
            {t("AddNewProduct.Alert.SelectMarketplace")}
          </span>
        )}
      </div>
      {!isRefresh && 
        isShowMarketplace &&
        listMarketplace &&
        listMarketplace.current.map(({ name, isDisabled }: any) => {
          console.log("isi dari listMarketplace: ", marketplacesData);
          let findMarketplace: unknown = undefined;
          if (isSummary && marketplacesData) {
            findMarketplace = marketplacesData.find((market: any) => {
              return (
                market.marketplace.toLowerCase() === name.toLowerCase() &&
                market.company === company.id &&
                market.isActive === true
              );
            });
          }
          console.debug("findMarketplace to show  : ", findMarketplace);

          if (isSummary && !findMarketplace) {
            return null;
          }

          // if (!isSummary || (isSummary && findMarketplace)) {
          return (
            <div
              className={clsx("d-flex w-100 p-2 align-items-start", {
                "flex-column": isTabletOrMobile,
                "flex-row": !isTabletOrMobile,
              })}
              key={name}
            >
              <div
                className={clsx(
                  "mt-4 d-flex flex-row align-items-center gap-2 cursor-pointer form-check form-check-custom form-check-solid",
                  {
                    "w-100 mb-2": isTabletOrMobile,
                    "w-25 ms-10": !isTabletOrMobile,
                  }
                )}
              >
                <input
                  id={`checkbox-${`${name.toLowerCase()}-${company.companyName.toLowerCase()}`}`}
                  className="form-check-input"
                  data-testid={`checkbox-${name}`}
                  type="checkbox"
                  key={name}
                  disabled={isDisabled}
                  onChange={() => handleOnChange(company, name.toLowerCase())}
                  checked={
                    (rd_marketplaces &&
                      rd_marketplaces.find((market: any) => {
                        return (
                          market.marketplace === name.toLowerCase() &&
                          market.isActive === true &&
                          market.company === company.id
                        );
                      })) ||
                    false
                  }
                />
                <img
                  className="h-20px"
                  src={toAbsoluteUrl(
                    `/media/logos/marketplace/${name.toLowerCase()}.png`
                  )}
                  alt={`${name}.png`}
                />
                <span className="fs-3 fw-bold d-block">{CapitalizedLetter(name)}</span>
              </div>
              <div
                className={clsx("d-flex flex-column ", {
                  "w-100": isTabletOrMobile,
                  "w-75": !isTabletOrMobile,
                })}
              >
                {!isTabletOrMobile && (
                  <MarketplaceCategoryModalDesktop
                    title={name}
                    step={"companyandmarketplace"}
                    isSubmitted={onClickNext}
                    id={name}
                    isChecked={
                      rd_marketplaces &&
                      rd_marketplaces.find(
                        (marketplace) =>
                          marketplace.marketplace === name.toLowerCase() &&
                          marketplace.isActive === true &&
                          marketplace.company === company.id
                      )
                    }
                    arrayData={getMarketplaceProductCategoryData(
                      name.toLowerCase()
                    )}
                    currentMarketplaceSelected={convertArray(
                      handleSelectedMarketplaceCategory(
                        company,
                        name.toLowerCase()
                      ) || [{}]
                    )}
                    isCurrentMarketplaceHasSelectedCategory={true}
                    reduxMarketplace={
                      name.toLowerCase() === "shopee"
                        ? reduxShopeeProductCategoryData
                        : ""
                    }
                    company={company ? company : ""}
                    marketplace={
                      rd_marketplaces &&
                      rd_marketplaces.find(
                        (marketplace) =>
                          marketplace.marketplace === name.toLowerCase() &&
                          marketplace.company === company.id
                      )
                    }
                  />
                )}
                {isTabletOrMobile && (
                  <MarketplaceCategoryModalMobile
                    title={name}
                    step={"companyandmarketplace"}
                    isSubmitted={onClickNext}
                    isChecked={
                      rd_marketplaces &&
                      rd_marketplaces.find(
                        (marketplace) =>
                          marketplace.marketplace.toLowerCase() ===
                            name.toLowerCase() &&
                          marketplace.isActive === true &&
                          marketplace.company === company.id
                      )
                    }
                    company={company ? company : ""}
                    marketplaceName={name}
                    currentMarketplaceSelected={convertArray(
                      handleSelectedMarketplaceCategory(
                        company,
                        name.toLowerCase()
                      ) !== undefined
                        ? handleSelectedMarketplaceCategory(
                            company,
                            name.toLowerCase()
                          )
                        : [{}]
                    )}
                    arrayData={getMarketplaceProductCategoryData(
                      name.toLowerCase()
                    )}
                    reduxMarketplace={
                      name.toLowerCase() === "shopee"
                        ? reduxShopeeProductCategoryData
                        : ""
                    }
                    id={name}
                    listMarketplace={rd_marketplaces}
                    marketplace={
                      rd_marketplaces &&
                      rd_marketplaces.find(
                        (marketplace) =>
                          marketplace.marketplace === name.toLowerCase() &&
                          marketplace.company === company.id
                      )
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      {isLoading && <Loading />}
    </div>
  );
};

export default CompanyAndMarketplaceSelection;
