import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PageLink, PageTitle } from "../layout/core/PageData";
import  ContactDetailPage from "../modules/accounts/ContactDetailPage"

type Props = {
  id: string;
  name: string;
};

const ContactDetail: FC = () => {
  const location = useLocation();
  const data = location.state as Props
  const { t } = useTranslation();
  
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t('SideBar.MenuItem.Contacts'),
      path: '/contact/list',
      isSeparator: false,
      isActive: false,
    }
    ,
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{data?.name}</PageTitle>
      <div className="h-100" data-testid="contactDetailPage" style={{overflowY:"scroll"}}> 
        < ContactDetailPage />
        {/* <Overview /> */}
      </div>
    </>
  );
};

export { ContactDetail };
