import {ListViewProvider, useListView} from './core/ListViewProvider';
import {QueryRequestProvider} from './core/QueryRequestProvider';
import {QueryResponseProvider} from './core/QueryResponseProvider';
import {WABAAccountsTable} from './table/WABAAccountsTable';
import {KTCard} from '../../../../../resources/helpers/components/KTCard';
import {WABAAccountsListHeader} from './components/header/WABAAccountListHeader';

const WABAAccountsList = () => {
  return (
    <>
      <KTCard>
        <WABAAccountsListHeader />
        <WABAAccountsTable />
      </KTCard>
    </>
  )
}

const WABAAccountsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <WABAAccountsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WABAAccountsListWrapper}