import clsx from "clsx";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { toAbsoluteUrl } from "../../../../resources/helpers";

type CarouselProps = {
  index: number;
  isActive: boolean;
  id: string;
  title: string;
  slideshowDataDesktop: string;
  slideshowDataMobile: string
};

const CarouselItem: React.FC<CarouselProps> = ({
  index,
  isActive,
  id,
  title,
  slideshowDataDesktop,
  slideshowDataMobile,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <Fragment key={id.toString()}>
      <div
        className={clsx("carousel-item", {
          active: isActive,
          height: "100px",
        })}
        data-bs-interval="2000"
        key={id}
      >
        <div className="d-flex justify-content-center">
          <h5>{title}</h5>
        </div>
        <img
          src={toAbsoluteUrl(
            isTabletOrMobile ? slideshowDataMobile : slideshowDataDesktop
          )}
          className="d-flex mw-100"
          alt={`item-${index}`}
        />
      </div>
    </Fragment>
  );
};

export default CarouselItem;
