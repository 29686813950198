import {useListView} from "../../../../../core/list/ListViewProvider"
import { useTranslation } from "react-i18next";
import { campaignDetailsColumns } from "../../../campaign-report-details/table/columns/_columns"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../setup/redux/store';
import { format } from 'date-fns';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

const CampaignToolbar = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const selectedCampaign = useSelector((state: RootState) => state.CampaignReport.selectedCampaign)
  const campaignMessages = useSelector((state: RootState) => state.CampaignMessage.listCampaignMessage)

  const onDownloadReportHandler = () => {
    // Process CSV
    // Get Column
    const tableHeader = campaignDetailsColumns;
    let reportDetailHeader:any = [];
    tableHeader.forEach(header => {
      reportDetailHeader.push(header.id);
    })
    console.log(`Header File ${JSON.stringify(reportDetailHeader)}`);

    // Process Data
    let messageList: any = campaignMessages;
    let newMessageList: any = [];

    console.log(`Message Report Table Data (as Object) : ${JSON.stringify(newMessageList)}`);
    
    // to CSV format (comma separator)
    var targetFileHeader = reportDetailHeader.toString() + "\r\n";
    var dataCSV = targetFileHeader;

    for (var i = 0; i < messageList.length; i++) {
      var line = "";
      for (var index in reportDetailHeader) {
        console.log(index)
        if (line !== "") line += ",";
        line += `"${messageList[i][reportDetailHeader[index]]}"`;
      }
      // for (var index in messageList[i]) {
      //   if (line !== "") line += ",";
      //   line += `"${messageList[i][index]}"`;
      // }
      dataCSV += line + "\r\n";
    }

    // Create CSV and Download
    const url = window.URL.createObjectURL(new Blob([dataCSV]));
    const link = document.createElement("a");
    link.href = url;
    let reportName =
      "CampaignReport-" +
      selectedCampaign.campaignName +
      format(new Date(), "d-MMM-yyyy[HHmmss]") +
      ".csv";
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className={clsx('d-flex justify-content-end', isTabletOrMobile ? "flex-column gap-3 w-100" : "")} data-kt-user-table-toolbar='base'>
      {/* begin::Filter */}
      <button type='button' className={clsx('btn btn-light-primary me-3')} disabled>
        <span className='bi bi-funnel-fill' />
        {t("Common.Filter")}
      </button>
      {/* end::Filter */}
      {/* begin::Download Report */}
      <button data-testid="btn-downloadreport" type='button' className='btn btn-primary me-3' onClick={onDownloadReportHandler}>
        <span className='bi bi-download me-2' />
        Download Report
      </button>
      {/* end::Download Report */}
    </div>
  )
}

export {CampaignToolbar}
