import React from "react";
import { useMediaQuery } from "react-responsive";

const SelectionCell = ({ isSelected, isCheckable, onSelect }: any) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <td className={!isMobileView ? "px-2 py-0 my-0" : "pe-2 p-0 -ps-2 m-0 -ms-2"}>
      {!isMobileView ? (
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            data-kt-check={isSelected}
            checked={isSelected}
            disabled={!isCheckable}
            onChange={onSelect}
          />
        </div>
      ) : (
        <div className="form-check form-check-sm form-check-custom">
          <input
            className="form-check-input cursor-pointer border-2 border-primary"
            type="checkbox"
            data-kt-check={isSelected}
            checked={isSelected}
            disabled={!isCheckable}
            onChange={onSelect}
          />
        </div>
      )}
    </td>
  );
};

export default SelectionCell;
