import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as ApiSettingRedux from "./ApiSettingRedux";

export const ApiSettingSlice = createSlice({
  name: ApiSettingRedux.name,
  initialState: ApiSettingRedux.initialState,
  reducers: {
    setApiSettingAccess: (
      state,
      action: PayloadAction<ApiSettingRedux.ApiSettingAccessState>
    ) => {
      state.access = { ...state.access, ...action.payload };
    },
    setApiSettingPush: (
      state,
      action: PayloadAction<ApiSettingRedux.ApiSettingPushState>
    ) => {
      state.push = { ...state.push, ...action.payload };
    },
    setApiSettingSms: (
      state,
      action: PayloadAction<ApiSettingRedux.ApiSettingSmsState>
    ) => {
      state.sms = { ...state.sms, ...action.payload };
    },
    setApiSettingWhiteList: (state, action: PayloadAction<string[]>) => {
      state.ipwhitelist = action.payload;
    },
    setApiSettingPhoneNumberWhiteList: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.phoneNumberWhitelist = action.payload;
    },
    setApiSettingPhoneNumberWhiteListError: (
      state,
      action: PayloadAction<
        ApiSettingRedux.ApiSettingState["phoneNumberWhitelistError"]
      >
    ) => {
      state.phoneNumberWhitelistError = action.payload;
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
      console.log("Set Edit Mode " + action.payload);
    },
    // setVerifyCallbackUrlErrorStatus: (state, action: PayloadAction<boolean>) => {
    //   state.isVerifyCallbackError = action.payload;
    // },
    setVerifyMaskingErrorStatus: (state, action: PayloadAction<boolean>) => {
      state.isVerifyMaskingError = action.payload;
    },
    setStateEdited: (state, action: PayloadAction<boolean>) => {
      state.isStateEdited = action.payload;
    },
    setApiSandboxSettingAcces: (
      state,
      action: PayloadAction<ApiSettingRedux.ApiSandboxSettingAccessState>
    ) => {
      state.accessSandbox = { ...state.accessSandbox, ...action.payload };
    },
  },
});

export const {
  setApiSettingAccess,
  setEditMode,
  setApiSettingWhiteList,
  setApiSettingPush,
  setApiSettingSms,
  // setVerifyCallbackUrlErrorStatus,
  setVerifyMaskingErrorStatus,
  setStateEdited,
  setApiSandboxSettingAcces,
  setApiSettingPhoneNumberWhiteList,
  setApiSettingPhoneNumberWhiteListError,
} = ApiSettingSlice.actions;
export default ApiSettingSlice.reducer;
