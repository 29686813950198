import { connect } from "formik";
import * as pg from "../api/firebase/playground";
import auth from "../auth/firebase";
import db from "../db";
import { createRef } from "../db/connection";

export const deleteUnused = (count: number) => pg.deleteTestMessage(count);

export const deleteErrorMessage = (count: number) =>
  pg.deleteErrorMessage(count);
export const deleteCollaborations = (count: number) =>
  pg.deleteCollaborations(count);

export const initFirestore = (uid: string, email: string, company: string) =>
  pg.initFirestore(company, uid, email);

export const initUser = (uid: string, email: string, company: string) => {
  const companyRef = db.collection("company").doc(company);
  return pg.initUser(uid, email, companyRef);
};
export const createPrimaryData = (email: string, password: string) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      const uid = user!.uid;
      // ...
      console.log(`uid ${uid} ; json user: ${JSON.stringify(user)}`);

      return pg.initFirestore(uid, email, "Labstore");
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ..
      console.log(`error code ${errorCode} errorMsg ${errorMessage}`);
    });

export const createUserData = (
  email: string,
  password: string,
  uidInput?: string
) => {
  if (uidInput) {
    return pg.initUser(uidInput, email, createRef("company", "*"));
  }

  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      const uid = user!.uid;
      // ...
      console.log(`uid ${uid} ; json user: ${JSON.stringify(user)}`);

      const ref = createRef("company", "*");
      return pg.initUser(uid, email, ref);
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ..
      console.log(`error code ${errorCode} errorMsg ${errorMessage}`);

      return undefined;
    });
};

/**
 * update user data with new field.
 * if uid undefined, get all users and update them
 * @param uid
 * @returns
 */
export const updateUserData = (uid: string | undefined = undefined) => {
  if (uid) return pg.updateUser(uid);

  // get all users
  return db
    .collection("users")
    .get()
    .then((snapshot) => {
      if (snapshot.docs.length === 0) return [];

      const updatedIds = Promise.all(
        snapshot.docs.map(async (doc) => {
          const id = doc.id;
          const data = doc.data();
          await pg
            .updateUser(id)
            .then(() =>
              console.log(`updated data ${id} with ${JSON.stringify(data)}`)
            )
            .catch((error: string) => {
              console.error("Error updating document: ", error);
            });

          return id;
        })
      );

      return updatedIds;
    });
};

export interface FieldFilter {
  name: string;
  op: string; // "<", "<=", "==", ">", ">=", //operator
  collectionRef?: string;
  value: string[];
}

export interface CollectionResult {
  collection: string;
  id: string;
}

export const searchCollections = (
  name: string,
  filters: FieldFilter[],
  onFinish: (results: CollectionResult[]) => void
) => {
  const doc = db.collection(name);
  let query: any = doc;
  filters.forEach((filter) => {
    console.log(`filter ${JSON.stringify(filter)}`);
    let searchFor: any;
    if (filter.collectionRef)
      searchFor = filter.value.map(ref => createRef(filter.collectionRef, ref));
    else 
      searchFor = `${filter.value[0]}\uf8ff`;
    console.log(`filter searchFor ${JSON.stringify(searchFor)}`);
    query = query.where(filter.name, filter.op, searchFor);
  });

  query
    .get()
    .then((snapshot: any) => {
      if (snapshot.docs.length === 0) {
        console.debug(`filter not found`);
        onFinish([]);
        return;
      }

      const results = Promise.all(
        snapshot.docs.map(async (doc: any) => {
          const id = doc.id;
          const data = doc.data();
          console.debug(`collection ${name} found id ${id}`);
          return { collection: name, id: id } as CollectionResult;
        })
      ).then((results) => onFinish(results));
    })
    .catch(() => {
      console.debug(`filter catch error`);
      return;
    });
};