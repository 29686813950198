import i18n, { t } from "i18next";
import { getImageSize } from "react-image-size";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx-js-style";
import { JSON2SheetOpts } from "xlsx-js-style";
import { excelStyle } from "../app/models/Product";
import {
  productMediaUpload as MEDIA_TYPE,
  formatSize,
  productMediaCheckFile,
} from "../app/modules/util/Utils";
import { IProductMediaUpload } from "../styles/components/ButtonUploadMediaRefV3";
import CryptoJS from "crypto-js";

export type Options = {
  maxSize?: number;
  fileType?: string[];
};

/**
 * checkFileType
 * @return
 */
export const checkFileType = (fileType: string, allowedFileType: string[]) => {
  //Check File Type
  // const arryFileType = fileType.split("/");
  // console.log("File Type : " + tempArrFileType);

  if (allowedFileType.length < 0) return undefined;

  // Check File Extension
  // if (
  //   arryFileType || arryFileType.length < 1 || !arryFileType[0]
  // ) {

  //   if ()

  //   return `${i18n.t("HC.Error.InvalidFiletype")}`

  // }

  return undefined;
};

export const validateFile = (file: File, options?: Options) => {
  if (!options) return;

  //validate file
  const size: number = file.size;
  if (options.maxSize && size > options.maxSize) {
    return i18n.t("HC.File.MaxSize", { size: `${options.maxSize}KB` });
  }

  if (options.fileType) {
    const error = checkFileType(file.type, options.fileType);
    if (error) {
      return error;
    }
  }
};

export const read_csv_as_json = async <T>(
  file: File,
  onReadFinished?: (header: string[], data: T[], description: T[], ws?: XLSX.WorkSheet) => void,
  onReadError?: (errorMessage?: string) => void
) => {
  const buffer = await file.arrayBuffer();

  try {
    const wb = XLSX.read(buffer, { type: "array" });
    const ws = wb.Sheets[wb.SheetNames[0]];
    const wsExample = wb.Sheets[wb.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(ws) as T[];
    const jsonDataForHeader = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    }) as string[][];

    // const csvData = XLSX.utils.sheet_to_csv(ws, {
    //   FS: ",",
    //   RS: "\n",
    //   forceQuotes: true,
    // });

    if (jsonData.length <= 4) {
      onReadError && onReadError(t("ImportProduct.Error.XLSX.EmptyFile"));
      return {
        error: t("ImportProduct.Error.XLSX.EmptyFile"),
      };
    }

    const header: string[] = jsonDataForHeader[0];

    // for (let index = 0; index < header.length; index++) {
    //   const item = header[index];

    //   if (
    //     (jsonDataForHeader[1][index] !==
    //       t(`Import.productDescription1.${item}`) ||
    //       jsonDataForHeader[2][index] !==
    //         t(`Import.productDescription2.${item}`)) &&
    //     item !== "log" &&
    //     item !== "serverLog"
    //   ) {
    //     console.log(
    //       `data cek 1 ${jsonDataForHeader[1][index]} !== ${t(
    //         `Import.productDescription1.${item}`
    //       )}`
    //     );
    //     console.log(
    //       `data cek 2 ${jsonDataForHeader[2][index]} !== ${t(
    //         `Import.productDescription2.${item}`
    //       )}`
    //     );

    //     onReadError && onReadError(t("ImportProduct.ErrorAlert.Redownload"));
    //     return {
    //       error: t("ImportProduct.ErrorAlert.Redownload"),
    //     };
    //   }
    // }

    // if (
    //   jsonDataForHeader[4][0] !==
    //   "JANGAN MENGHAPUS YANG DIATAS, ISI DATA DIBAWAH"
    // ) {
    //   onReadError && onReadError(t("ImportProduct.ErrorAlert.Redownload"));
    //   return;
    // }

    const description: T[] = jsonData.slice(0, 4);

    onReadFinished && onReadFinished(header, jsonData.slice(4), description, wsExample);

    return {
      result: {
        header,
        data: jsonData.slice(4),
        description,
        worksheet: wsExample,
      },
    };
  } catch (error) {
    console.error(error);
    onReadError && onReadError(t("ImportProduct.Error.XLSX.ErrorFileReading"));
    return {
      error: t("ImportProduct.Error.XLSX.ErrorFileReading"),
    };
  }
};

export const isURL = (url: string) => {
  const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return pattern.test(url);
};

export const generateMedia = async (imageUrl: string) => {
  // const mediaType ="image";
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const URLObj = URL.createObjectURL(blob);

  const size: number = blob.size;
  const tempArrFileType: string = blob.type;
  const arryFileType = tempArrFileType.split(`/`);
  const fileName = uuidv4();

  let newFile: IProductMediaUpload = {
    url: URLObj,
    size: formatSize(size),
    type: tempArrFileType,
    extension: arryFileType[1],
    name: fileName,
  };

  return newFile;
};

export const validateImage = async (imageUrl: string) => {
  let errorMedia;

  try {
    const mediaType = "image";
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const URLObj = URL.createObjectURL(blob);

    const size: number = blob.size;
    const tempArrFileType: string = blob.type;
    const arryFileType = tempArrFileType.split(`/`);
    const fileName = "myimage.jpg";

    console.log(`BLOB : ${URLObj}`);

    if (arryFileType === undefined || arryFileType.length < 1 || arryFileType[0] === undefined) {
      //return error
      console.log("Media - Error File Invalid Format");
      errorMedia = t("ImportProduct.Error.XLSX.InvalidFormatFile");

      console.log(`Error :  ${errorMedia}`);
      return errorMedia;
    }

    let photosWidth: number = 0;
    let photosHeight: number = 0;

    console.log(`. w ${mediaType} ${MEDIA_TYPE.image} `);
    if (mediaType === MEDIA_TYPE.image) {
      try {
        console.log(".5 w");
        const { width, height } = await getImageSize(URLObj);
        photosWidth = width;
        photosHeight = height;

        console.log(`w ${width} ${height}`);
      } catch (error) {
        // Handle the error here
        errorMedia = t("ImportProduct.Error.XLSX.InvalidMediaURL");

        // console.log(`Error :  ${errorMedia}`)
        return errorMedia; // or throw error;
      }
    }

    const options = { maxfilesize: 2 };
    const [result, error, maxSize] = productMediaCheckFile(
      arryFileType[0],
      arryFileType[1],
      size,
      photosHeight,
      photosWidth,
      options
    );

    console.log(`after productMediaCheckFile`);
    if (error !== undefined) {
      if (maxSize !== 0) {
        console.log("Media - Error Process Validate Maxsize: " + maxSize);
        if (result === MEDIA_TYPE.image) {
          // setInvalidPhotoFileSize(true);
          errorMedia = t("ImportProduct.Error.XLSX.InvalidFileSize");

          console.log(`Error :  ${errorMedia}`);
          return errorMedia;
        }
        return;
      } else {
        console.log("Media -  Process Validate File : " + error);
        if (result === MEDIA_TYPE.image) {
          // setInvalidPhotoFileFormat(true);
          errorMedia = t("ImportProduct.Error.XLSX.InvalidImageResolution");

          // console.log(`Error :  ${errorMedia}`)
          return errorMedia;
        }
      }
      errorMedia = "Invalid URL Image";
      return errorMedia;
    }
    console.log(`after error undifiend`);
    if (!result || result === "") {
      console.log("Media - Error File Invalid Format");
      if (result !== MEDIA_TYPE.image) {
        // setInvalidPhotoFileFormat(true);
        errorMedia = t("ImportProduct.Error.XLSX.InvalidFormatFile");

        console.log(`Error :  ${errorMedia}`);
        return errorMedia;
      }
      return;
    }
    console.log(`after result null`);
    let newFile: IProductMediaUpload = {
      url: URLObj,
      size: formatSize(size),
      type: tempArrFileType,
      extension: arryFileType[1],
      name: fileName,
    };
    console.log(`after add newFile`);
    if (result === MEDIA_TYPE.image) {
      // dispatch(ReduxProduct.addProductPhotos(newFile));
      // setMedia(newFile);
      // onFinishFn(newFile);
      console.log(`after add newFile if`);
      console.log(`new File ${newFile}`);

      return undefined;
      // saveFileToRef(index, newFile);
      // console.log(`url dari REF ${arrInputRefImage[index].current.url}`);
      // console.log(`berhasil ${JSON.stringify(newFile)}`)
    } else {
      console.log("Media - Error File Invalid Format");
      if (result === MEDIA_TYPE.image) {
        errorMedia = t("ImportProduct.Error.XLSX.InvalidFormatFile");
        console.log(`after add newFile else`);
        console.log(`Error :  ${errorMedia}`);
        return errorMedia;
        // onFinishFn(undefined, {isValidPhotoFileFormat: true})
        // setInvalidPhotoFileFormat(true);
      }
    }
  } catch (error) {
    console.log(error);
    return t("ImportProduct.Error.XLSX.InvalidMediaURL");
  }
};

export const write_json_to_csv = <T>(data: T[], opts?: JSON2SheetOpts): string | undefined => {
  const ws = XLSX.utils.json_to_sheet<T>(data, opts);
  const csvData = XLSX.utils.sheet_to_csv(ws);

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  return url;
};

export const write_json_to_sheet = <T>(
  data: T[],
  description: T[],
  opts?: JSON2SheetOpts,
  worksheet?: XLSX.WorkSheet
): string | undefined => {
  const combination = [...description, ...data];
  console.log(`combination ${JSON.stringify(combination)}`);
  const ws = XLSX.utils.json_to_sheet<T>(combination, opts);

  setStyleAndColumnWidths(ws);
  mergeRow4(ws);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, t("ImportProduct.XLSX.Template"));

  if (worksheet) {
    setStyleAndColumnWidths(worksheet);
    mergeRow4(worksheet);
    XLSX.utils.book_append_sheet(workbook, worksheet, t("ImportProduct.XLSX.ExampleUpload"));
  }

  const excelBuffer: ArrayBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = URL.createObjectURL(blob);
  return url;
};

const setColumnWidths = (ws: XLSX.WorkSheet, columnWidths: number[]): void => {
  ws["!cols"] = columnWidths.map((width) => ({ width }));
  ws["!rows"] = ws["!rows"] || [];
  ws["!rows"][0] = { hpx: 60 };
};

const setStyleAndColumnWidths = (ws: XLSX.WorkSheet): void => {
  const range = XLSX.utils.decode_range(ws["!ref"] || "");
  const columnWidths: number[] = [];

  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0;

    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
      console.log(`ini data refcell ${cellRef}`);
      const cell = ws[cellRef];

      if (cell) {
        cell.s =
          row === 0 || row === 4
            ? excelStyle.styleHeader
            : row === 1 || row === 3
            ? excelStyle.styleDescription
            : row === 2
            ? excelStyle.styleOptional
            : excelStyle.styleContent;

        if (cell.c) {
          cell.c.hidden = true;
        }
      }

      if (cell && cell.v && typeof cell.v === "string") {
        maxLength = Math.max(maxLength, cell.v.length);
      }
    }

    columnWidths[col] = Math.min(20, Math.max(10, maxLength + 2));
  }

  setColumnWidths(ws, columnWidths);
};

const mergeRow4 = (ws: XLSX.WorkSheet): void => {
  const range = XLSX.utils.decode_range(ws["!ref"] || "");
  const headerLength = range.e.c - range.s.c + 1;
  ws["!merges"] = [{ s: { r: 4, c: 0 }, e: { r: 4, c: headerLength - 1 } }];
};

export const getColumnWidths = (ws: XLSX.WorkSheet): number[] => {
  const columnWidths: number[] = [];
  const range = XLSX.utils.decode_range(ws["!ref"] || "");

  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0;

    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
      console.log(`ini data refcell ${cellRef}`);
      const cell = ws[cellRef];

      if (cell && cell.v && typeof cell.v === "string") {
        maxLength = Math.max(maxLength, cell.v.length);
      }
    }

    columnWidths[col] = Math.min(20, Math.max(10, maxLength + 2));
  }

  return columnWidths;
};

export async function calculateFileSHA256(file: File | Blob) {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(reader.result));
        const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
        return resolve(hash);
      } else {
        return reject(undefined);
      }
    };
    reader.onerror = () => {
      return reject(undefined);
    };
    reader.readAsArrayBuffer(file);
  });
}
