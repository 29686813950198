/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { Campaign } from "../../../../../../models/Campaign";

type Props = { content?: string; id?: string };

const CampaignStringCell: FC<Props> = ({ content, id }) => {
  return (
    <div
      data-testid={id || "string-cell"}
      className="d-flex align-items-center"
    >
      <div className="d-flex flex-column">{content || ""}</div>
    </div>
  );
};

export { CampaignStringCell };
