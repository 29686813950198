import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendRequestWABASignUp } from "../../../../../../../api/server/wabaSignUp";
import { RootState } from "../../../../../../../setup/redux/store";
import * as lc from "../../../../../../modules/localstorage/index";
import ModalConfirmationMoveToOtherPage from "../../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import * as ReduxWABASignup from "../../../../../../modules/waba-accounts/redux/data/WABAAccountSignUpSlice";
import { PageLink, PageTitle } from "../../../../../core";

const WABASignUp: FC = () => {
  const dispatch = useDispatch();
  const currentClient = lc.getItemLC(lc.LCName.Client);
  const clientid = currentClient?.id;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const lastStep = useSelector(
    (state: RootState) => state.WABAAccountSignUp.lastStep
  );

  const reduxDataWABA = useSelector(
    (state: RootState) => state.WABAAccountSignUp.dataWABA
  );

  const [dataEmbeddedSignUp, setDataEmbeddedSignUp] = useState<any>(undefined);
  const [isRequestEmbeddedSignUp, setIsRequestEmbeddedSignUp] = useState<boolean>(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)

  useEffect(() => {
    dispatch(ReduxWABASignup.cleanReduxWABAAccountSignUp(true));
  }, []);

  useEffect(() => {
    const sessionInfoListener = (event: any) => {
      console.log("sessionInfoListener", event)
      if (!event.origin.includes("facebook")) return;
      try {
        const data = JSON.parse(event.data);
        console.log(`data from onfinish`, data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // if user finishes the embedded sign up flow
          if (data.event === "FINISH") {
            const dataWABA = data.data;
            console.log("dataWABA", dataWABA);
            dispatch(ReduxWABASignup.setDataWABA(dataWABA));
          }
          // if user cancels the embedded sign up flow
          else {
            const { current_step } = data.data;
            console.log("current_step", current_step);
            dispatch(ReduxWABASignup.setLastStep(current_step));
            console.log("data.data", data.data);
          }
        }
      } catch {
        // Don’t parse info that’s not a JSON
        console.log(
          "Non JSON Response from Finish Message on Typescript",
          event.data
        );
      }
    };
    window.addEventListener("message", sessionInfoListener);
    return () => {
      window.removeEventListener("message", sessionInfoListener);
    }
  }, [])
  

  const launchEmbeddedSignUp = (client: string) => {
    // dispatch(ReduxWABASignup.setLastStep(""));
    // Launch Facebook login
    FB.login(
      function (response) {
        if (response) {
          if (
            response.authResponse !== null &&
            response.status === "connected"
          ) {
            console.log(`reduxDataWABA `, JSON.stringify(reduxDataWABA));
            setDataEmbeddedSignUp(response);
          } else {
            setIsPopupOpen(false);
          }
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "whatsapp_business_management, whatsapp_business_messaging, business_management",
        extras: {
          feature: "whatsapp_embedded_signup",
          version: 2,
          sessionInfoVersion: 2,
        },
      }
    );
  };

  useEffect(() => {
    console.log(`dataEmbeddedSignUp : `, dataEmbeddedSignUp);
    console.log(`reduxDataWABA : `, reduxDataWABA);
    if (Object.keys(reduxDataWABA).length > 0 && dataEmbeddedSignUp && isRequestEmbeddedSignUp) {
      if (isLoadingRequest) {
        return
      }
      console.log(`send request embedded signup`);
      setIsLoadingRequest(true)
      sendRequestWABASignUp(
        { ...dataEmbeddedSignUp, dataWABA: reduxDataWABA },
        clientid,
        (code, res) => {
          console.log("reqResponse", res, "code", code);
          setIsLoadingRequest(false)
          if (code === 200) {
            navigate("/setting/waba-account/create/verify");
            setIsError(false);
            setIsPopupOpen(false);
          } else {
            setIsError(true);
            setIsPopupOpen(false);
          }
        }
      );
    }
  }, [dataEmbeddedSignUp, reduxDataWABA, isRequestEmbeddedSignUp]);

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("HeaderBar.Button.Settings"),
      path: "",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <ModalConfirmationMoveToOtherPage
        path="/setting/waba-account/create/sign-up"
        secondPath="/setting/waba-account/create/verify"
        onUnsave={() =>
          // dispatch(ReduxWABASignup.cleanReduxWABAAccountSignUp(true))
          undefined
        }

        // onSave={() =>
        //   handleSavedChanges().then(() => {
        //     setIsLoading(false);
        //     dispatch(ReduxProductList.cleanReduxProductList(true));
        //     dispatch(ReduxProduct.cleanReduxProduct(true));
        //   })
        // }
        // onUnsave={() => handleUnsavedChanges()}
      />

      {isPopupOpen && (
        <div
          className="h-100 d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            backgroundColor: "rgba(0,0,0,0.50)",
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          <div
            className="col-8 mx-auto text-center fs-2"
            data-testid="gray-overlay"
          >
            {t("WABAAccountRegister.EmbeddedSignUp.ExplanationGreyOverlay")}
          </div>
        </div>
      )}

      <PageTitle breadcrumbs={UsersBreadcrumbs}>
        {t("WABAAccountList.Button")}
      </PageTitle>

      <div className="d-flex flex-column h-100">
        <div className="card mb-1 mb-xl-2 py-4 px-7 d-flex flex-row space">
          <h2 className="w-100 m-0 fw-semibold h1">Embedded Sign Up</h2>
        </div>

        <div className="h-100 overflow-auto card p-7">
          <div className="flex-lg-column-fluid tab-content h-100 overflow-auto">
            <div className="fs-4 text-gray-800" data-testid="explain-paraghraf">
              <p>{t(`WABAAccountRegister.EmbeddedSignUp.Explanation1`)}</p>
              <ul>
                <li>
                  {t(`WABAAccountRegister.EmbeddedSignUp.Explanation2_1`)}
                </li>
                <li>
                  {t(`WABAAccountRegister.EmbeddedSignUp.Explanation2_2`)}
                </li>
                <li>
                  {t(`WABAAccountRegister.EmbeddedSignUp.Explanation2_3`)}
                </li>
              </ul>
              <p>{t(`WABAAccountRegister.EmbeddedSignUp.Explanation3`)}</p>
            </div>
            <div className="d-grid col-4 mx-auto py-7">
              <button
                type="button"
                data-testid="btnAddWABAAccount"
                className="btn btn-primary btn-lg w-100"
                onClick={() => {
                  setIsPopupOpen(true);
                  dispatch(ReduxWABASignup.setLastStep(""));
                  launchEmbeddedSignUp(clientid);
                  setIsRequestEmbeddedSignUp(true);
                }}
              >
                {t("WABAAccountRegister.EmbeddedSignUp.Register")}
              </button>
            </div>
            {isError ? (
              <div className="text-center">
                <h3 className="text-bold">
                  {t(`WABAAccount.Register.ServerDownNotification.Header`)}
                </h3>
                <h6 style={{ color: "gray" }}>
                  {t(`WABAAccount.Register.ServerDownNotification.Subheader1`)}
                  <br />
                  {t(`WABAAccount.Register.ServerDownNotification.Subheader2`)}
                </h6>
              </div>
            ) : null}
            {lastStep ? (
              <div className="text-center">
                <h3 className="text-bold">
                  {t(`WABAAccount.Register.PopUpDialogNotification.Header`)}
                </h3>
                <h6 style={{ color: "gray" }}>
                  <p>
                    {t(
                      `WABAAccount.Register.PopUpDialogNotification.Subheader`
                    )}
                    :
                  </p>

                  <p data-testid="last-step">
                    {t(`WABAAccountPage.SignUp.${lastStep}`)}
                  </p>
                </h6>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default WABASignUp;
