import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ID, KTSVG } from "../../../../../../../resources/helpers";
import ModalConfirmation from "../../../../../../modules/modal/modalConfirmation";

type Props = {
  id: ID;
  indexId: number;
  name?: string;
  messageContent?: any;
};

type ActionMenu = {
  label: string;
  type: "link" | "button";
  state?: { [key: string]: any };
  to?: string;
  onClick?: () => void;
  bstoogleId?: string;
};

const print = (action: ActionMenu, key: string) => {
  console.log(`action: ${action.bstoogleId} - key: ${key}`);
  if (action.type === "link") {
    return (
      <Link
        to={`${action.to}`}
        state={action.state}
        className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
        key={key}
      >
        {action.label}
      </Link>
    );
  } else {
    return (
      <button
        className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
        onClick={action.onClick ? action.onClick : () => {}}
        key={key}
        data-bs-toggle={action?.bstoogleId ? "modal" : ""}
        data-bs-target={action?.bstoogleId}
      >
        {action.label}
      </button>
    );
  }
};

const CampaignMessageActionsCell: FC<Props> = ({ id, indexId, name, messageContent }) => {
  const { t } = useTranslation();
  const actions: ActionMenu[] = [
    {
      label: t("DLR.Column.MOMessage"),
      type: "button",
      state: { id, name },
      bstoogleId: "#modalMessageContent",
    },
  ];
  //split messageContent by //n to be array and replace //n with ""
  const messageContentView = messageContent?.split(/\\n/g).map((item:any) => item.replace(/\\n/g, ""));
  console.log(`messageContentView : ${messageContentView.length}`);

  return (
    <>
      <div className="dropdown">
        <button
          data-testid="dropdownMenuAction"
          type="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="btn btn-icon"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen053.svg"
            className="svg-icon-1 m-0"
          />
        </button>
        <div className="dropdown-menu">
          <h3 className="dropdown-header">{t("Common.Actions")}</h3>
          {actions.map((action, index) => print(action, `${index}`))}
        </div>
        <ModalConfirmation
          id="modalMessageContent"
          title="Message Content"
          //set body value messageContentView and add br if you want to show new line
          body={
            <div>
             {messageContentView?.map((item:any, index:number) => {
                return <div key={index}>{item}<br/></div>
             })}
            </div>
          }
          confirmOption={""}
        />
      </div>
    </>
  );
};
export { CampaignMessageActionsCell };
