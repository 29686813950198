import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../../../../../src/app/models/Customer";
import InitialState, { CampaginTargetListAction } from "./CampaignTargetRedux";
// import Campaign from "../../../models/Campaign"
// import { Timestamp } from "../../../../db";
// import * as lc from "../../localstorage/index"

const initialState: InitialState = {
  targetList: [],
  targetCount: 0,
  selectedTarget: [],
  selectedTargetCount: 0,
  previewData: [],
  previewDataCount: 0,
  targetData: "",
  columns: [],
};

export const CampaignTargetSlice = createSlice({
  name: CampaginTargetListAction,
  initialState: initialState,
  reducers: {
    setTargetList: (state, action: PayloadAction<Customer[]>) => {
      state.targetList = action.payload;
      state.targetCount = action.payload.length;
    },

    setTargetCount: (state, action: PayloadAction<number>) => {
      state.targetCount = action.payload;
    },

    setSelectedTarget: (state, action: PayloadAction<Customer[]>) => {
      console.log(`Set selected Target ${action.payload}`);
      state.selectedTarget = action.payload;
      state.selectedTargetCount = action.payload.length;
    },

    setSelectedTargetCount: (state, action: PayloadAction<number>) => {
      console.log(`Set selected Target Count ${action.payload}`);

      state.selectedTargetCount = action.payload;
    },

    setPreviewData: (state, action: PayloadAction<any[]>) => {
      state.previewData = action.payload;
      state.previewDataCount = action.payload.length;
    },

    setColumns: (state, action: PayloadAction<any[]>) => {
      state.columns = action.payload;
    },

    setTargetData: (state, action: PayloadAction<string>) => {
      state.targetData = action.payload;
    },

    setPreviewDataCount: (state, action: PayloadAction<number>) => {
      state.previewDataCount = action.payload;
    },

    updateTargetList: (state, action: PayloadAction<Customer>) => {
      let newTargetList: any[] = [];
      const newContact = action.payload;
      newTargetList.push(newContact);

      for (let index = 0; index < state.targetList.length; index++) {
        if (state.targetList[index].id === newContact.id) {
        } else {
          newTargetList.push(state.targetList[index]);
        }
      }
      state.targetList = newTargetList;
      state.targetCount = newTargetList.length;
    },

    updateSelectedTarget: (state, action: PayloadAction<Customer>) => {
      let newTargetList: any[] = [];
      const newContact = action.payload;
      newTargetList.push(newContact);

      for (let index = 0; index < state.selectedTarget.length; index++) {
        if (state.selectedTarget[index].id === newContact.id) {
        } else {
          newTargetList.push(state.selectedTarget[index]);
        }
      }
      state.selectedTarget = newTargetList;
      state.selectedTargetCount = newTargetList.length;
    },

    deleteItemTargetList: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newData = state.targetList.filter(
        (data) => data.id !== action.payload
      );
      state.targetList = newData;
      state.targetCount = newData.length;
    },

    cleanReduxCampaignTarget: (state, action: PayloadAction<boolean>) => {
      state.targetList = [];
      state.targetCount = 0;
      state.selectedTarget = [];
      state.selectedTargetCount = 0;
      state.previewData = [];
      state.previewDataCount = 0;
      state.targetData = "";
      // state.columns = [];
    },
    cleanReduxSelectedCampaignTarget: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.selectedTarget = [];
      state.selectedTargetCount = 0;
      state.previewData = [];
      state.previewDataCount = 0;
      state.targetData = "";
      // state.columns = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTargetList,
  setTargetCount,
  setSelectedTarget,
  setSelectedTargetCount,
  setPreviewData,
  setPreviewDataCount,
  setTargetData,
  updateTargetList,
  updateSelectedTarget,
  deleteItemTargetList,
  cleanReduxCampaignTarget,
  cleanReduxSelectedCampaignTarget,
  setColumns,
} = CampaignTargetSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignTargetSlice.reducer;
