import { useMutation, useQueryClient } from "react-query";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
// import {handledCollabs} from '../../core/_requests'
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { requestGetMarketplaceStorefront } from "../../../../../../../api/server/storefront";
import { getAccessibleAccount } from "../../../../../../../db/serviceAccount";
import Loading from "../../../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../../../setup/redux/store";
import * as lc from "../../../../../../modules/localstorage/index";
import ModalDeleteSelected from "../../../../../../modules/modal/modalDeleteSelected";
import * as StorefrontList from "../../../../../../modules/product/storefront/storefrontList/StorefrontListSlice";
import { deleteSelectedStorefronts } from "../../core/_requests";

const StorefrontListGrouping = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected, clearSelected } = useListView();
  const queryClient = useQueryClient();
  const { query } = useQueryResponse();
  const { state, updateState } = useQueryRequest();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });
  let listStorefrontData: any = [];
  listStorefrontData = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );
  const [isValidSelected, setIsValidSelected] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const deleteSelectedItems = useMutation(
    () => deleteSelectedStorefronts(selected),
    {
      onSuccess: () => {
        for (let index = 0; index < selected.length; index++) {
          dispatch(
            StorefrontList.deleteItemListStorefront(selected[index]!.toString())
          );
        }
        updateState({
          sort: state.sort === "delete" ? "asc" : "delete",
          items_per_page: state.items_per_page,
          page: 1,
          action: "noAction",
        });
        clearSelected();
      },
    }
  );

  function checkNumberOfProducts(numberOfProducts: number) {
    return numberOfProducts <= 0;
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSelectedHasProduct, setIsSelectedHasProduct] = useState(false);

  const checkSelectedItems = () => {
    let numberOfProducts: any = [];
    for (let index = 0; index < selected.length; index++) {
      const getStorefrontData = listStorefrontData.find((storefront: any) => {
        return storefront.id === selected[index];
      });
      numberOfProducts = [
        ...numberOfProducts,
        getStorefrontData.numberOfProducts,
      ];
    }
    const isValid = numberOfProducts.every(checkNumberOfProducts);

    if (isValid) {
      setIsSelectedHasProduct(false);
    } else {
      setIsSelectedHasProduct(true);
    }

    setIsDeleteModalOpen(true);

    // const buttonDeleted = document.getElementById("btn-deleted");
    // console.log("button : ", buttonDeleted);
    // let modalToShow = "#confirmDeleteSelected"
    // if(!isValid){
    //   modalToShow = "#errorDeleteSelected"
    // }
    // buttonDeleted?.setAttribute("data-bs-target", modalToShow)
    // setIsValidSelected(isValid);
  };

  const getMarketplaceStrorefront = async () => {
    setIsLoading(true);
    const client = lc.getItemLC(lc.LCName.Client);
    const user = lc.getItemLC(lc.LCName.User);
    const companyAccess = lc.getItemLC(lc.LCName.CompanyList)
    const accountList = await getAccessibleAccount(client.id, companyAccess, "shopee");
    if (!user || !accountList || accountList.length <= 0) {
      return;
    }
    const responseList = await Promise.all(
      accountList.map((account) =>
        requestGetMarketplaceStorefront(account.id, user.uid)
      )
    );
    console.log("responseListStore", responseList);
    dispatch(StorefrontList.cleanReduxStorefrontList(true));

    setIsLoading(false);
  };

  useEffect(() => {
    console.log("isValidSelected : ", isValidSelected);
  }, [isValidSelected]);

  return (
    <>
      <div
        className={clsx(
          `d-flex justify-content-end align-items-center me-3 ${
            isTabletOrMobile ? "w-100" : ""
          }`
        )}
      >
        <div className="fw-bolder me-5">
          {!isTabletOrMobile && selected.length > 0 ? (
            <span className="me-2">
              {selected.length} {t("Storefront.Info.Selected")}
            </span>
          ) : null}
        </div>

        {!isTabletOrMobile && (
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={() => {
              getMarketplaceStrorefront();
            }}
          >
            Sync
          </button>
        )}

        {!isTabletOrMobile && (
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={() => {
              dispatch(StorefrontList.cleanReduxStorefrontList(true));
            }}
          >
            <i className="bi bi-arrow-clockwise"></i>
          </button>
        )}

        {!isTabletOrMobile && (
          <>
            {selected.length > 0 ? (
              <button
                data-testid="delete-selected"
                name="btn-deleted"
                id="btn-deleted"
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  checkSelectedItems();
                }}
              >
                {t("StorefrontList.Button.DeleteSelected")}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger"
                disabled
                onClick={async () => await deleteSelectedItems.mutateAsync()}
              >
                {t("StorefrontList.Button.DeleteSelected")}
              </button>
            )}
          </>
        )}
      </div>

      {isLoading && <Loading />}

      <ModalDeleteSelected
        id="confirmDeleteSelected"
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={
          isSelectedHasProduct
            ? t("StorefrontList.Alert.Delete.Storefront.Title")
            : t("Warning")
        }
        body={
          isSelectedHasProduct
            ? t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontHasProduct"
              )
            : t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontDoesn'tHasProduct"
              )
        }
        confirmText={
          isSelectedHasProduct
            ? undefined
            : t("Storefront.Column.Button.Delete")
        }
        cancelText={
          isSelectedHasProduct
            ? t("Storefront.Button.Back")
            : t("Alert.Button.Cancel")
        }
        onConfirm={async () => {
          await deleteSelectedItems.mutateAsync();
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export { StorefrontListGrouping };
