import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { useListView } from "../../core/ListViewProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup/redux/store";
import { useState } from "react";
import { useMutation } from "react-query";
import { deleteSelectedStorefronts } from "../../core/_requests";
import * as StorefrontList from "../../../../../../modules/product/storefront/storefrontList/StorefrontListSlice";
import { useQueryRequest } from "../../core/QueryRequestProvider";

const StorefrontListToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selected, clearSelected } = useListView();
  const [isSelectedHasProduct, setIsSelectedHasProduct] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { state, updateState } = useQueryRequest();
  let listStorefrontData: any = [];
  listStorefrontData = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );

  const checkSelectedItems = () => {
    let numberOfProducts: any = [];
    for (let index = 0; index < selected.length; index++) {
      const getStorefrontData = listStorefrontData.find((storefront: any) => {
        return storefront.id === selected[index];
      });
      numberOfProducts = [
        ...numberOfProducts,
        getStorefrontData.numberOfProducts,
      ];
    }
    const isValid = numberOfProducts.every(checkNumberOfProducts);

    if (isValid) {
      setIsSelectedHasProduct(false);
    } else {
      setIsSelectedHasProduct(true);
    }

    setIsDeleteModalOpen(true);
  };

  function checkNumberOfProducts(numberOfProducts: number) {
    return numberOfProducts <= 0;
  }

  const deleteSelectedItems = useMutation(
    () => deleteSelectedStorefronts(selected),
    {
      onSuccess: () => {
        for (let index = 0; index < selected.length; index++) {
          dispatch(
            StorefrontList.deleteItemListStorefront(selected[index]!.toString())
          );
        }
        updateState({
          sort: state.sort === "delete" ? "asc" : "delete",
          items_per_page: state.items_per_page,
          page: 1,
          action: "noAction",
        });
        clearSelected();
      },
    }
  );

  return (
    <>
      <div
        className={clsx(
          `d-flex justify-content-end align-items-center me-3 ${
            isTabletOrMobile ? "w-100" : ""
          }`
        )}
      >
        <Link
          className={clsx(`btn btn-primary ${isTabletOrMobile ? "w-100" : ""}`)}
          to="/storefront/create-storefront/storefront-name"
        >
          <span>
            <i className="bi bi-plus-lg fs-4 me-2"></i>
            {t("Storefront.Button.AddNewStorefront")}
          </span>
        </Link>
      </div>
      {isTabletOrMobile && (
        <div
          className={clsx(
            `d-flex justify-content-end align-items-center me-3 mt-5 ${
              isTabletOrMobile ? "w-100" : ""
            }`
          )}
        >
          {selected.length > 0 ? (
            <button
              data-testid="delete-selected"
              name="btn-deleted"
              id="btn-deleted"
              type="button"
              className="btn btn-danger w-100"
              onClick={() => {
                checkSelectedItems();
              }}
            >
              {t("StorefrontList.Button.DeleteSelected")}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger w-100"
              disabled
              onClick={async () => await deleteSelectedItems.mutateAsync()}
            >
              {t("StorefrontList.Button.DeleteSelected")}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export { StorefrontListToolbar };
