interface GenderType {
  value: string,
  label: string
}

const GenderList: GenderType[] = [
  {
    value: "male",
    label: "CD.Option.Male",
  },
  {
    value: "female",
    label: "CD.Option.Female",
  },
];

export default GenderList;