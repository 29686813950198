import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { AsideMenuItem } from "../../../../aside/AsideMenuItem";
import { AsideMenuItemWithSub } from "../../../../aside/AsideMenuItemWithSub";

export function SettingNavigation() {
  const { t } = useTranslation();
  const backtoMainClickHandler = () => {
    console.log("masuk di profileClickHandler");
    const asideMenuMain = document.getElementById("aside-menu-main");
    asideMenuMain?.classList.remove("d-none");

    //remove display none for menu profile component
    const asideMenuProfile = document.getElementById("aside-menu-setting");
    asideMenuProfile?.classList.add("d-none");
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const nav = useNavigate();

  useEffect(() => {
    if (isTabletOrMobile) console.log(`masuk ke mobile UI`);
    else nav("/setting/waba-account/list");
  }, []);

  const [isClickWABA, setIsClickWABA] = useState<boolean>(false);
  const [isClickMarketplace, setIsClickMarketplace] = useState<boolean>(false);

  return (
    <div
      id="aside-menu-setting"
      className="d-flex flex-column justify-content-between h-100"
    >
      <br />
      <div>
        <h2>{t("HeaderBar.Button.Settings")}</h2>
      </div>
      <div>
        <br />
        <AsideMenuItem
          to="#"
          title={t("WABAAccount.Menu.WhatsAppBusines")}
          onClick={() => {
            setIsClickWABA(!isClickWABA);
          }}
          bbcolor="dark"
        />
        {isClickWABA && (
          <div>
            <AsideMenuItem
              to="/setting/waba-account/create/sign-up"
              title={t("WABAAccount.Submenu.AddWhatsAppBusinesAccount")}
              id="WABA_ADD"
              hasBullet={true}
              bbcolor="dark"
            />
            <AsideMenuItem
              to="/setting/waba-account/list"
              title={t("WABAAccount.Submenu.WhatsAppBusinesAccountList")}
              id="WABA_LIST"
              hasBullet={true}
              bbcolor="dark"
            />
            <AsideMenuItem
              to="#"
              title={t("WABAAccount.Submenu.Billing")}
              id="WABA_BILLING"
              hasBullet={true}
              bbcolor="dark"
            />
          </div>
        )}
        <AsideMenuItem
          to="#"
          title={t("Marketplace.Menu.Marketplace")}
          id="MARKETPLACE"
          onClick={() => {
            setIsClickMarketplace(!isClickMarketplace);
          }}
          bbcolor="dark"
        />
        {isClickMarketplace && (
          <div>
            <AsideMenuItem
              to="/setting/marketplace/marketplace-list"
              title={t("Marketplace.List")}
              id="MARKETPLACE_LIST"
              hasBullet={true}
              bbcolor="dark"
            />
            <AsideMenuItem
              to="#"
              title={t("Marketplace.Shipment")}
              id="MARKETPLACE_SHIPMENT"
              hasBullet={true}
              bbcolor="dark"
            />
          </div>
        )}
        <AsideMenuItem
          to="/setting/api"
          title={t("API.Setting.Menu")}
          id="API_SETTING"
          bbcolor="dark"
        />
        <AsideMenuItem
          to="#"
          title={t("Campaign.Column.TemplateLanguage")}
          id="LANG"
          bbcolor="dark"
        />
        <AsideMenuItem
          to="#"
          // title={t("Campaign.Column.TemplateLanguage")}
          title="Insight"
          id="INSIGHT"
          bbcolor="dark"
        />
      </div>
    </div>
  );
}
