import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../../resources/helpers";

const StorefrontMobileIcon = () => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-row menu menu-column menu-state-title-primary 
                menu-state-icon-primary menu-state-bullet-primary py-3
                border-gray-300 border-bottom align-items-center"
    >
      <Link to="/storefront/list" className="symbol p-0 mx-0">
        <KTSVG
          path="/media/icons/duotune/arrows/arr074.svg"
          className="svg-icon-primary svg-icon-2hx"
        />
      </Link>
      <div className="menu-item here show menu-accordion">
        <span className="menu-link px-0">
          <div className="symbol symbol-50px">
            <span className="menu-icon justify-content-end">
              <i className="fas fa-box fs-1" />
            </span>
          </div>
          <span className="menu-title">{`${t(
            "Storefront.Info.Storefront"
          )}`}</span>
        </span>
      </div>
    </div>
  );
};
export default StorefrontMobileIcon;
