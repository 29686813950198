import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { KTSVG } from "../../../../../resources/helpers";
import Loading from "../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../setup/redux/store";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxTarget from "../../../../modules/campaign/target/redux/CampaignTargetSlice";
import { TargetListWrapper } from "../../campaign-target/TargetList";

const TargetModal: FC<{ isOpen?: boolean; onClose?: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const targetCount = useSelector(
    (state: RootState) => state.CampaignTarget.selectedTargetCount
  );
  const isLoading = useSelector((state: RootState) => state.Campaign.isLoading);

  useEffect(() => {
    console.log("Target Modal");
  }, [targetCount]);

  return (
    <>
      {/* <div className="modal fade" tabIndex={-1} id={`select_campaign_target`}>
        <div
          className="modal-dialog modal-dialog-centered mw-auto"
          // role="document"
          style={{ maxWidth: "800px", zIndex: "99" }}
        >
         
        </div>
      </div> */}
      <Modal
        animation
        centered
        show={isOpen}
        onHide={() => onClose && onClose()}
        size="lg"
        scrollable={false}
      >
        <Modal.Header closeButton>
          <Modal.Title data-testid="modal-title">
            {t("Campaign.Button.Addtarget")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <TargetListWrapper onModalClose={onClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export { TargetModal };
