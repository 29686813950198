import { sendWABAVerifyPhoneNumber } from "./connection";

export const sendRequestWABAVerify = async (
  client: string,
  data: {
    phoneNumbers: string[];
    wabaID: string;
    wabaPhoneNumberID: string;
    // currentStep: string;
  }
) => {
  const response: {
    code: number;
    data: any;
  } = {
    code: 400,
    data: undefined,
  };

  await sendWABAVerifyPhoneNumber({ ...data, client }, (code, data) => {
    if (code) {
      response.code = code;
    }
    if (data) {
      response.data = data;
    }
  });

  return response;
};
