import clsx from "clsx";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive";
import { KTSVG, toAbsoluteUrl } from "../../../../resources/helpers";
import Customer from "../../../models/Customer";
import { getContacts } from "../../contact-management/contact-list/core/_requests";

const ContactListModal: FC<{ phoneNumber: string | undefined; onClose: () => void }> = ({
  onClose,
  phoneNumber,
}) => {
  const [selected, setSelected] = useState<Customer | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <>
      <div
        data-testid="modalAddContact"
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollabled mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <ModalHeader onClose={onClose} data-bs-dismiss="modal" />
            {/* begin::Modal body */}
            <ContactList selected={selected} setSelected={setSelected} />
            {/* end::Modal body */}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => onClose()}
              >
                {t("Alert.Back.Button.Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={selected === undefined}
                onClick={() => {
                  if (!selected) {
                    return;
                  }
                  navigate(`/contact/contact-detail/settings/${selected.id}`, {
                    state: {
                      id: selected.id,
                      name: `${selected.firstName!} ${selected.lastName ?? ""}`,
                      phoneNumber: phoneNumber,
                    },
                  });
                }}
              >
                Select
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const ContactList: FC<{
  selected: Customer | undefined;
  setSelected: (contact: Customer | undefined) => void;
}> = ({ selected, setSelected }) => {
  const { t } = useTranslation()
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { data: contacts, isLoading } = useQuery(
    "noPhoneContacts",
    async () => {
      const data = await getContacts(
        "firstNameInsensitive",
        "desc",
        "",
        undefined,
        undefined,
        20
      );
      return data.filter(
        (contact) => !contact.phoneNumber || contact.phoneNumber === undefined
      );
    }
  );

  const [search, setSearch] = useState("");

  if (isLoading) {
    return <div>{t("Common.Pages.Loading")}</div>;
  }

  if ((!isLoading && !contacts) || contacts.length <= 0) {
    return <div className="py-4 fs-3 text-center">{t("Contacts.Info.Empty")}</div>
  }

  if (contacts) {
    const filteredContacts = contacts.filter((contact) => {
      if (search && contact.firstName) {
        return contact.firstName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      }
      return true;
    });

    console.log("DataContactList", filteredContacts);

    // if (filteredContacts.length <= 0) {
    //   return <div className="py-4">{t("Contacts.Info.Empty")}</div>;
    // }

    return (
      <div className="modal-body">
        <form className="w-100 position-relative mb-7" autoComplete="off">
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
          />
          <input
            id="search-chat"
            type="text"
            className="form-control form-control-solid px-15"
            name="search"
            placeholder="Search Contact"
            style={{ background: "#EEEEEE", borderColor: "#EEEEEE" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </form>
        <div className="d-flex flex-column">
          {filteredContacts.length <= 0 && (
            <div className="py-4 fs-3 text-center">{t("Contacts.Info.Empty")}</div>
          )}
          {filteredContacts.length > 0 && filteredContacts.map((contact) => {
            const displayName = getDisplayName(contact, isTabletOrMobile)

            return (
              <div
                onClick={() => {
                  if (selected && selected.id === contact.id) {
                    return setSelected(undefined);
                  }
                  return setSelected(contact);
                }}
                key={contact.id}
                className={clsx(
                  "d-flex align-items-center border-bottom border-2 p-3",
                  {
                    "bg-primary": selected && selected.id === contact.id,
                    "bg-hover-primary" : !(selected && selected.id === contact.id)
                  } 
                )}
              >
                {/* begin:: Avatar */}
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  {contact.avatar ? (
                    <div className="symbol-label">
                      <img
                        src={`${contact.avatar}`}
                        alt={contact.firstName}
                        className="w-100"
                      />
                    </div>
                  ) : (
                    <div className="symbol-label">
                      <img
                        src={toAbsoluteUrl("/media/icons/avatar/def-avatar.png")}
                        alt={contact.firstName}
                        className="w-100"
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span
                    // to={"/contact/contact-detail/settings/" + contact.id}
                    // state={{
                    //   id: contact.id,
                    //   name: `${contact.firstName!} ${contact.lastName ?? ""}`,
                    // }}
                    className="text-gray-800 fs-3"
                  >
                    {displayName}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  return null;
};

const ModalHeader: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      {/* <h2 className="fw-bolder">{t("Contacts.Button.AddUser")}</h2> */}
      <h2 className="fw-bolder">Select Contact</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          onClose();
        }}
        style={{ cursor: "pointer" }}
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr061.svg"
          className="svg-icon-1"
        />
      </div>
      {/* end::Close */}
    </div>
  );
};

function getDisplayName(contact: Customer, isTabletOrMobile: boolean){
  let fullName: string = "";
  const maxLength: number = isTabletOrMobile ? 15 : 50;

  if (
    (!contact.firstName ||
      contact?.firstName === "") &&
    contact?.phoneNumber
  ) {
    fullName = contact.phoneNumber;
  } else if (contact.firstName) {
    if (
      contact?.lastName &&
      contact?.lastName !== ""
    ) {
      fullName = `${contact.firstName!} ${
        contact.lastName
      }`;
    } else {
      fullName = contact.firstName!;
    }
  }

  return `
  ${fullName.slice(0, maxLength)}${fullName.length > maxLength ? "..." : ""}
  `
}

export default ContactListModal;
