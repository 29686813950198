import clsx from "clsx";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import ButtonUploadMediaRefV3, {IProductMediaUpload, IError} from "../../../styles/components/ButtonUploadMediaRefV3";
import Company from "../../models/Company";
import * as lc from "../../modules/localstorage";
import { ListMarketplace } from "../../../util/Marketplace";
import { useMediaQuery } from "react-responsive";
import MarketplaceCategoryModalDesktop from "./storefront-category/MarketplaceModalDesktop";
import MarketplaceCategoryModalMobile from "./storefront-category/MarketplaceModalMobile"
import { useDispatch, useSelector } from "react-redux";
import * as ReduxStorefront from "../../modules/product/storefront/StorefrontSlice";
import { RootState } from "../../../setup/redux/store";
import { productMediaUpload, uploadFile } from "../util/Utils";
import Loading from "../../../resources/helpers/components/Loading";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as actions from "../../../actions/actions";
import * as actAccount from "../../../actions/account"
import { createRef } from "../../../db/connection";
import { submitBrand } from "../../../api/server/brands";
import ModalRetryConfirmation from "./modalRetryConfirmation";
import { setLoading } from "../template/redux/TemplateSlice";

type ModalAddBrandProductProps = {
  id: string;
  title?: string;
  subtitle?: string;
  body?: string | JSX.Element;
  image?: string;
  onCancel?: () => void;
  onConfirm?: (responseSubmit: any) => void;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen?: boolean;
};

const ModalAddBrandProduct: React.FC<ModalAddBrandProductProps> = ({
  id,
  title,
  subtitle,
  body,
  image,
  onCancel,
  cancelOption,
  onConfirm,
  confirmOption,
  isModalOpen = false,
  ...props
}) => {
  
  const dispatch = useDispatch();
  
  const listMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  const [isLoading, setIsLoading] = useState(false);
  const [arrayOfImages, setArrayOfImages] = useState<IProductMediaUpload[]>([]);
  const [arrayOfCategories, setArrayOfCategories] = useState<any[]>([]);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [stateRetryBrand, setStateRetryBrand] = useState<any>(null);
  
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; 
  const arrInputRefImage: any[] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  React.useEffect(() => {

    let findMarketplace = listMarketplace.find(
      (m: any) => m.name === "Shopee"
    );

    if (!findMarketplace) {
      const data = { name: "Shopee", isChecked: true, category: [] };
      console.log(`useEffect set marketplace ${JSON.stringify(data)}}`)

      dispatch(
        ReduxStorefront.setStorefrontListMarketplace([...listMarketplace, data])
      );
      return ;
    }    
  }, [])

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);
  
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  // make use ref
  const [numButtons, setNumButtons] = useState(3);

  const handleAddButton = () => {
    setNumButtons((prevNumButtons) => prevNumButtons + 1);
  };

  const renderButtons = () => {
    const buttons = [];
    for (let i = 1; i < numButtons; i++) {
      console.log(`render button ${i}`)
      buttons.push(
        <div
          className="col"
          key={`div_up_image-${i}`}
          style={{ marginBottom: arrayOfImages.length > 0 ? (isTabletOrMobile ? "7px" : "33px") : "5px" }}
        >
          <ButtonUploadMediaRefV3
            idRadioButton={`up_image-${i}`}
            isMultipleUpload={true}
            isMobile={isTabletOrMobile}
            cName={
              "bi bi-card-image " +
              (isTabletOrMobile ? "display-2" : "display-3")
            }
            refId={arrInputRefImage[i]}
            datatestid={`BtnUploadImage-${i}`}
            style={{ color: "black" }}
            onFinishFn={(photo?: IProductMediaUpload, errors?: IError) => {
              setIsLoading(true);
              // console.log error
              if (errors) {
                console.log(`error add Foto ${JSON.stringify(errors)}`)
                if (errors?.isValidPhotoFileFormat) {
                  // Display error message when the photo file format is invalid
                  // console.log('Invalid photo file format');
                    // formik.setFieldError('arrayOfImages', 'Invalid photo file format');
                    setInvalidPhotoFileFormat(errors.isValidPhotoFileFormat);
                    setIsLoading(false);
                    return;
                  }
                  if (errors?.isValidPhotoFileSize) {
                    // Display error message when the photo file size is invalid
                    setInvalidPhotoFileFormat(errors.isValidPhotoFileSize);
                    console.log('Invalid photo file size');
                    // formik.setFieldError('arrayOfImages', 'Invalid photo file size');
                  setIsLoading(false);
                  return;
                }
              }
              if(photo){
                setArrayOfImages((prevArray: IProductMediaUpload[]) => {
                  const updatedArray = [...prevArray];
                  if (photo) {
                    updatedArray.push(photo);
                  }
                  formik.setFieldValue("arrayOfImages", updatedArray);
                  return updatedArray;
                });
              }else formik.setFieldTouched("arrayOfImages", true);
              
              setIsLoading(false);
            }}
            onRemoveFn={(index?:number ,  errors?: IError , url?: string) => {
              setIsLoading(true);
              console.log(`removeProductPhotos url addBrand ${url}`);
              if (url) {
                setArrayOfImages((prevArray: IProductMediaUpload[]) => {
                  const updatedArray = prevArray.filter((item) => item.url !== url);
                  formik.setFieldValue("arrayOfImages", updatedArray);
                  formik.setFieldTouched("arrayOfImages", true);
                  return updatedArray;
                });
              }
              setIsLoading(false);
            }}
            accept={imageExtention}
            isVideoButton={false}
            mediaType={productMediaUpload.image}
            options={{maxfilesize:2}}
          />
          {/* <ErrorMessage name="arrayOfImages" component="div" className="error-message" /> */}
        </div>
        

      );
    }
    return buttons;
  };

  const handleShowRetryModal = (stateErrorRetry:any) => {
    setStateRetryBrand(stateErrorRetry);
    setShowRetryModal(true);
  };

  // Function to close the Retry Modal
  const handleCloseRetryModal = () => {
    setShowRetryModal(false);
  };

  // Function to retry
  const handleRetry = async (stateErrorRetry:any) => {
    setShowRetryModal(false);
    // Call the retry logic here
    await handleSaveRetry(stateErrorRetry);
  };

  const handleSaveRetry = async (retryBrand:any) => {
    setLoading(true);
    console.log("handelSave Retry")
  }
  

  const handleSave = async (values:any) => {
    setIsLoading(true);

    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let isSuccess = true;

    // Retrieve the brand name input value
    const brandName = values.brandName;
    console.log(`ini brand name handle save ${brandName}`)
    // Retrieve the checkbox value
    const isAgreed = values.agreementAddBrand;
    console.log(`ini checkbox handle save ${isAgreed}`)

    console.log("array of images On Submit : " + JSON.stringify(arrayOfImages));

    const uploadToFirestorage = async (
        file: any,
        filetype: string,
        fileName: string,
        path: string
      ) => {
        const inputcontentType = filetype;
        const inputFileName = fileName;
        return await uploadFile(file, inputcontentType, inputFileName, path);
    };
    

      const newBrand = await actions.createBrand(brandName,arrayOfCategories);

      console.log(`data newbrandId ${newBrand} / ${JSON.stringify(newBrand)}`);

      if (!newBrand || !newBrand.id) {
        setIsLoading(false);
        let stateErrorRetry = {
          BrandName : brandName,
          ErrorRes : "Error Create Brand",
        }
        handleShowRetryModal(stateErrorRetry);
        console.log("error create brand");
        return;
      }

      //delete old photos firestorage and firebase
      //actions.deletePhotos(brandId) 
      const deleteOldMedia = await actions.deleteMedia(newBrand.id,"photos");
      // if deleteoldmedia false then return
      if(!deleteOldMedia){
        setIsLoading(false);
        let stateErrorRetry = {
          BrandName : brandName,
          ErrorRes : "Error Delete Old Media",
        }
        handleShowRetryModal(stateErrorRetry);
        console.log("error delete old media")
        return;
      }

      //upload new photos
      for (const image of arrayOfImages) {
        const result_FS_URL = await uploadToFirestorage(
          image.url,
          image.type,
          image.name, 
          "brands/photos/" + newBrand.id
        );
        console.log("Result URL Photos: " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
          setIsLoading(false);
          let stateErrorRetry = {
            BrandName : brandName,
            ErrorRes : "Error Upload Media to FireStore",
          }
          handleShowRetryModal(stateErrorRetry);
          console.log("error upload media to FireStore")
          return;
        }
          //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          image.url,
          image.name,
          image.extension,
          image.type,
          image.size,
          result_FS_URL,
          false,
          "",
          refUser
        );
        const mediaID = await actions.createBrandDataMedia(
          newBrand.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
          setIsLoading(false);
          let stateErrorRetry = {
            BrandName : brandName,
            ErrorRes : "Error Create New Media",
          }
          handleShowRetryModal(stateErrorRetry);
          console.log("error to create new media")
          return;
        }
      }

      // get account shopee
      const shopeeAccounts = await actAccount.fetchByChannel("shopee");
      if (!shopeeAccounts || shopeeAccounts.length <= 0) {
        // error account not found
        setLoading(false);
        let stateErrorRetry = {
          BrandName : brandName,
          ErrorRes : "Error Account Not Found",
        }
        handleShowRetryModal(stateErrorRetry);
        console.log("error account not found")
        return ;
      }

      console.log(shopeeAccounts, "shopee accounts isxo")

      // const response = await submitBrand(newBrand.id, shopeeAccounts[0].id);
      const response = await submitBrand(newBrand.id, "W0ICkChtv7OVrjkRFUVB");
      
      console.log(`submit brand response ${JSON.stringify(response)}`);

      if (!response || response.status !== 200) {
        setIsLoading(false);
        let stateErrorRetry = {
          BrandName : brandName,
          ErrorRes : "Error Submit Brand",
        }
        handleShowRetryModal(stateErrorRetry);
        console.log(`error submit brand ${JSON.stringify(response.data.error)}`);
        return ;
      }

      let responseSubmit = {
        isSuccess: isSuccess,
        BrandName: brandName,
        BrandID: newBrand.id,
        PhotosURL: resultFirestorageURL,
        PhotosMediaID: resultPhotosMediaID,
      }

      console.log("responseSubmit : " + JSON.stringify(responseSubmit));
  
    
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    onConfirm && onConfirm(newBrand);
  }

  const validationSchema = Yup.object().shape({
    agreementAddBrand: Yup.bool().oneOf([true], t(`AddNewProduct.Modal.Info.AgreementAddaNewBrand.Alert`)),
    brandName: Yup.string()
      .required(t('AddNewProduct.Modal.Input.BrandName.Alert')),
    arrayOfImages: Yup.array().min(1, t("AddNewProduct.Modal.Info.SampleProductImage.Alert")),
    arrayOfCategories: Yup.array().min(1, t("AddNewProduct.Modal.Input.Category.Alert")),
  });

  const formik = useFormik({
    initialValues: {
      agreementAddBrand: false,
      brandName: '',
      arrayOfImages: [],
      arrayOfCategories: [],
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });
  
  
  const [isInvalidPhotoFileFormat, setInvalidPhotoFileFormat] = useState<boolean>(false);
  const [isInvalidPhotoFileSize, setInvalidPhotoFileSize] =  useState<boolean>(false);
  const [isValidPhoto, setValidPhoto] = useState<boolean>(true);
  const [isMaximumPhotos, setMaximumPhotos] = useState<boolean>(false);
  
  useEffect(() => {
    
    setInvalidPhotoFileFormat(false);
    setInvalidPhotoFileSize(false);
    console.log("array of images: ", arrayOfImages);
  }, [arrayOfImages]);
  
  return (
    
    <div
      {...props}
      hidden={!isModalOpen}
      className="h-100 w-100 d-flex align-items-center justify-content-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99998,
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      }}

      key={`${id}`}
    >
      {/* <ModalRetryConfirmation show={showRetryModal} onClose={handleCloseRetryModal} onRetry={handleRetry} /> */}
      <ModalRetryConfirmation id={"showRetryModal"} isModalOpen={showRetryModal} stateRetry={stateRetryBrand}
        onCancel={() => {
            handleCloseRetryModal();
          }}
          onConfirm={() => {
            handleRetry(stateRetryBrand);
          }}/>
      
      {isLoading && <Loading />}

      <div id={id} className="h-100 w-100">
        <div
          className="modal-dialog modal-dialog-centered overflow-auto"
          style={{
            // minWidth: isTabletOrMobile ? "" : "500px", // Adjust the width as needed
            maxWidth: isTabletOrMobile ? "100vw" : "50vw" , // Limit the maximum width to 90% of the viewport
          }}
        >
          <div className="modal-content">

            <form
                id="kt_modal_add_brand_form"
                className="form"
                onSubmit={formik.handleSubmit}
            >
            
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <div className="ms-6 d-flex flex-column justify-content-between">
                    <h3 className="modal-title align-items-start">{title} </h3>
                  </div>
                </div>
              </div>

              <div className={`modal-body ${clsx("",{
                  "mh-400px overflow-scroll" : isTabletOrMobile,
                  "mh-500px overflow-scroll" : !isTabletOrMobile
                })}`}>
                   {/* <button onClick={handleShowRetryModal}>Open Retry Modal</button> */}
                 
                  
                  {/* Brand Name */}
                  <label className="required fw-bold fs-6 mb-2">{t("AddNewProduct.Modal.Info.BrandName")}</label>
                  <div className="d-flex align-items-center form-control border-2 px-2 py-2">
                    <input
                      className = "d-flex align-items-center form-control border-0 px-0 py-0"
                      data-testid="input-brand-name"
                      maxLength={63}
                      placeholder={t("AddNewProduct.Modal.Input.BrandName")}
                      type="text"
                      id="brandName"
                      {...formik.getFieldProps('brandName')}
                    />
                    <p className="border-0 bg-white my-0" style={{ paddingRight: '10px', color: 'gray', textAlign: 'center' }}>
                      {formik.values.brandName.length}/63
                    </p>
                  </div>
                  {formik.touched.brandName && formik.errors.brandName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.brandName}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-row mb-7"></div>
                  
                  {/* <Categories></Categories> */}
                  <label className="required fw-bold fs-6 mb-2">{t("AddNewProduct.Modal.Info.Category")}</label>
                  <div
                    className="overflow-scroll "
                    style={{ maxHeight: `${isTabletOrMobile ? "45vh" : "29vh"}` }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ overflowX: "hidden" }}
                    >
                      {ListMarketplace?.filter((item: any) => item.name === "Shopee")
                        .map(({ name, isDisabled }: any) => {
                        return (
                            <div
                              className={clsx(`d-flex flex-column`, 
                                {
                                  "w-100" : isTabletOrMobile,
                                  "w-50" : !isTabletOrMobile
                                }
                              )}
                              key={name?.toLowerCase()}
                            >
                              {!isTabletOrMobile && (
                                <MarketplaceCategoryModalDesktop
                                  title={name?.toLowerCase()}
                                  step="add-brand"
                                  isSubmitted={false}
                                  id={name?.toLowerCase()}
                                  onConfirmCategory={(data: any) => {
                                    console.log(
                                      "data category selected : " + JSON.stringify(data)
                                    );
                                    setArrayOfCategories(data);
                                    formik.setFieldValue("arrayOfCategories", data);
                          
                                  }}
                                />
                              )}
                              {isTabletOrMobile && (
                                <MarketplaceCategoryModalMobile
                                  title={name?.toLowerCase()}
                                  step=""
                                  isSubmitted={false}
                                  id={name?.toLowerCase()}
                                  onConfirmCategory={(data: any) => {
                                    console.log(
                                      "data category selected : " + JSON.stringify(data)
                                    );
                                    setArrayOfCategories(data);
                          
                                  }}
                                />
                              )}
                            </div>
                        );
                      })}
                    </div>
                    {formik.errors.arrayOfCategories && (
                          <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.arrayOfCategories}</span>
                          </div>
                        </div>
                        )} 
                  </div>
                  <div className="fv-row mb-7"></div>


                  {/* FOTO BRAND */}
                  <div className="step-sub-title mb-2 fs-4">
                    <label
                      htmlFor="arrayOfImages"
                      id="section-photo"
                      className="required fw-bold fs-6 mb-2"
                      data-testid="MediaUploadTitlePhoto"
                    >
                      {t("AddNewProduct.Modal.Info.SampleProductImage")}
                    </label>
                  </div>
                
                  <div
                    style={{
                      width: isTabletOrMobile ? "100%" : "100%",
                      maxHeight: "20%"
                    }}
                  >
                    <div
                      className={clsx("row",isTabletOrMobile ? "row-cols-3" : "row-cols-5")}
                      style={{
                        marginRight: !isTabletOrMobile ? "7px" : "",
                      }}
                    >
                        {renderButtons()}
                        {numButtons <= 10 && (
                          
                        <div className="col">
                          <button className ="button-dashed-desktop d-flex cursor-pointer"
                            style={{
                              background: "#E1E3E3",
                              borderColor: "rgb(120, 120, 137)",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={handleAddButton}
                          >
                            + {t("AddNewProduct.Modal.Info.SampleProductImage.Addmore")}
                          </button>
                        </div>
                        )}  
                        
                      {/* ))} */}
                      
                    </div>
                    {formik.errors.arrayOfImages && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.arrayOfImages}</span>
                          </div>
                        </div>
                        )}  
                    <div className="pt-2" data-testid="MediaUploadErrorPhotos">
                      {isInvalidPhotoFileFormat && (
                        <div
                          id="media-invalid-photo-format"
                          style={{ color: "#F1416C" }}
                          // role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosInvalidFormat"
                        >
                          {t("AddNewProduct.Alert.Photo.Invalidformatfile")}
                        </div>
                      )}
                      {isInvalidPhotoFileSize && (
                        <div
                          id="media-invalid-photo-format"
                          style={{ color: "#F1416C" }}
                          // role="alert-select-storefront"
                          data-testid="MediaUploadErrorPhotosInvalidFormat"
                        >
                          {t("AddNewProduct.Modal.Info.ImageSize")}
                        </div>
                      )}
                      </div>
                        
                          <div>
                      <div
                        className="step-sub-title mt-3 mb-2 fs-6"
                        style={{
                          paddingTop: "5px",
                        }}
                      >
                        <label
                          className="form-check-label"
                          data-testid="MediaUploadPhotoRequirements"
                        >
                          {t("AddNewProduct.SubTitle.PhotoFileRequirements")}
                        </label>
                      </div>
                      <ul>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFormatPhoto"
                        >
                          {t(
                            "AddNewProduct.Modal.Info.ImageFormat"
                          )}
                        </li>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFormatRatio"
                        >
                          {t(
                            "AddNewProduct.SubTitle.PhotoFileRequirements.Ratio"
                          )}{" "}
                          1:1
                        </li>
                        <li
                          className="text-gray-800 fw-normal "
                          data-testid="MediaUploadPhotoRequirementsFileSize"
                        >
                          {t(
                            "AddNewProduct.Modal.Info.ImageSize"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* end:: End Group */}

                  {/* Checkbox */}
                  <div className="fv-row mb-10 mt-5">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="agreement-addbrand"
                        {...formik.getFieldProps('agreementAddBrand')}
                      />
                      <label className="form-check" htmlFor="agreement-addbrand">
                       {t("AddNewProduct.Modal.Info.AgreementAddaNewBrand")}
                      </label>
                    </div>
                    {formik.touched.agreementAddBrand && formik.errors.agreementAddBrand && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.agreementAddBrand}</span>
                        </div>
                      </div>
                    )}
                  </div>                     
              </div>

              <div className="modal-footer justify-content-right">
                {cancelOption ? (
                  <button type="button" className="btn btn-light-primary mx-2" onClick={onCancel}>
                    {cancelOption}
                  </button>
                ) : null}
                {confirmOption ? (
                  <button
                    type="submit"
                    className="btn btn-primary mx-2"
                    disabled={formik.isSubmitting}
                  >
                    {confirmOption}
                  </button>
                ) : null}
              </div>
            </form>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddBrandProduct;
