export type ApiSettingAccessState = {
  secretKey: string;
  accessToken: string;
};

export type ApiSandboxSettingAccessState = {
  secretKey: string;
  accessToken: string;
};

export type ApiSettingPushState = {
  chat: {
    url: string;
    isActive: boolean;
    isUrlVerified: boolean;
    isDeliveryReport: boolean;
    isRecieveMessage: boolean;
  };
  order: {
    url: string;
    isActive: boolean;
    isUrlVerified: boolean;
    selectedStatuses: string[];
  };
  product: {
    url: string;
    isActive: boolean;
    isUrlVerified: boolean;
  };
};

export type ApiSettingSmsState = {
  failoverSenderMode: "LN" | "MS";
  maskingSMS?: string;
};

export type ApiSettingState = {
  initiatedSetting: boolean;
  isEditMode: boolean;
  // isVerifyCallbackError: boolean;
  isVerifyMaskingError: boolean;
  isStateEdited: boolean;
  access: ApiSettingAccessState;
  accessSandbox: ApiSandboxSettingAccessState;
  push: ApiSettingPushState;
  sms: {
    failoverSenderMode: string;
    maskingSMS: string;
  };
  ipwhitelist: string[];
  phoneNumberWhitelist: string[];
  phoneNumberWhitelistError?: {
    phoneNumbers: string[];
    message: string;
  };
};

export const name = "ApiSettingRedux";
export const initialState: ApiSettingState = {
  isEditMode: false,
  initiatedSetting: true,
  // isVerifyCallbackError: false,
  isVerifyMaskingError: false,
  isStateEdited: false,
  access: {
    secretKey: "",
    accessToken: "",
  },
  accessSandbox: {
    secretKey: "",
    accessToken: "",
  },
  push: {
    chat: {
      url: "",
      isActive: false,
      isUrlVerified: false,
      isDeliveryReport: false,
      isRecieveMessage: false,
    },
    order: {
      url: "",
      isActive: false,
      isUrlVerified: false,
      selectedStatuses: [],
    },
    product: {
      url: "",
      isActive: false,
      isUrlVerified: false,
    },
  },
  sms: {
    failoverSenderMode: "LN",
    maskingSMS: "",
  },
  ipwhitelist: [] as string[],
  phoneNumberWhitelist: [] as string[],
};
