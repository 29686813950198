import InitialState, { ContactListAction } from "./ContactRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../../models/Customer";

const initialState: InitialState = {
  listContact: [],
  countContact: 0,
  currentPageContact: 0,
  maxPageContact: 0,
  arrayContactPagination: [],
  contactData: {},
  navCount: 1,
};

export const ContactSlice = createSlice({
  name: ContactListAction,
  initialState: initialState,
  reducers: {
    setListContact: (state, action: PayloadAction<Customer[]>) => {
      state.listContact = action.payload;
      state.countContact = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayContactPagination = arr;
      } else {
        state.arrayContactPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayContactPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayContactPagination = action.payload;
    },

    setCountContact: (state, action: PayloadAction<number>) => {
      state.countContact = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageContact = action.payload;
    },

    setMaxPageContact: (state, action: PayloadAction<number>) => {
      state.maxPageContact = action.payload;
    },

    updateListContact: (state, action: PayloadAction<Customer>) => {
      let newListContact: Customer[] = [];
      const newContact = action.payload;
      newListContact.push(newContact);

      for (let index = 0; index < state.listContact.length; index++) {
        if (state.listContact[index].id === newContact.id) {
          console.log("Collab ID from Notif: " + state.listContact[index].id);
        } else {
          newListContact.push(state.listContact[index]);
        }
      }

      console.log("NEW LIST CONTACT", newListContact);
      // state.listContact = newListContact;
      state.countContact = newListContact.length;
    },

    deleteItemListContact: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listContact.filter(
        (data) => data.id !== action.payload
      );
      state.listContact = newDataCollab;
      state.countContact = newDataCollab.length;
    },

    setContactData: (state, action: PayloadAction<Customer>) => {
      state.contactData = action.payload;
    },

    setNavCount: (state, action: PayloadAction<number>) => {
      state.navCount = action.payload;
    },

    cleanReduxContact: (state, action: PayloadAction<boolean>) => {
      state.listContact = [];
      state.countContact = 0;
      state.currentPageContact = 0;
      state.maxPageContact = 0;
      state.contactData = {};
      state.navCount = 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListContact,
  deleteItemListContact,
  updateListContact,
  setCurrentPage,
  setArrayContactPagination,
  setMaxPageContact,
  setCountContact,
  cleanReduxContact,
  setContactData,
  setNavCount
} = ContactSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default ContactSlice.reducer;
