import { useTranslation } from "react-i18next";
import * as ProcessService from "../../../../../../../db/serviceProcesses";
import Modal from "../../../../../../components/Modal";
import { Process, ProcessOrder } from "../../../../../../models/Process";

import moment from "moment";
import { useQuery } from "react-query";
import { badgeColorMap, statusLevelMap, statusMap } from "../../../../../../../util/processOrder";

interface IReportModal {
  processID: string;
  isModalOpen: boolean;
  setIsModalOpen: () => void;
  selectedData?: string[];
}

const ReportModal: React.FC<IReportModal> = ({ processID, isModalOpen, setIsModalOpen, selectedData }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(["process-orders-data", processID], () => ProcessService.getProcessOrderDataByProcessID(processID), {
    enabled: isModalOpen && !!processID,
  });

  const items = data ?? [];
  const onlySelected = selectedData && selectedData.length > 0 ? items.filter((item) => selectedData.includes(item.orderRef.id)) : items;

  return (
    <Modal
      id="ReportModal"
      title={t("ExportOrderProcess.Report.Modal.Title")}
      confirmOption="OK"
      isModalOpen={isModalOpen}
      onConfirm={() => setIsModalOpen()}
      centered={true}
      size="lg"
    >
      {isLoading ? (
        <div className="text-center fs-6">
          <span className="spinner-border"></span>
          <p>{t("Common.Modal.Process.Loading")}</p>
        </div>
      ) : (
        <ProcessReportTable data={items as ProcessOrder[]} />
      )}
    </Modal>
  );
};

export const ProcessReportTable: React.FC<{ data: ProcessOrder[] }> = ({ data }) => {
  const { t } = useTranslation();

  const sortedData = data.sort((a, b) => (statusLevelMap[b.statusRts] || 0) - (statusLevelMap[a.statusRts] || 0));

  return (
    <div
      className="table-responsive px-2"
      style={{
        maxHeight: "60vh",
        overflowY: "scroll",
      }}
    >
      <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
        <thead className="position-sticky top-0 bg-white">
          <tr>
            <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">
              {t("Common.Modal.Column.Header.Marketplace")}
            </th>
            <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">{t("OrderList.OrderNumber")}</th>
            <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">
              {t("Common.Modal.Column.Header.Status")}
            </th>
            <th className="text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white text-center">{t("OrderList.OrderTime")}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((proc, i) => {
            return (
              <tr key={i}>
                <td className="text-center">{proc.accountData?.searchKey ?? proc.accountData?.businessName ?? proc.marketplaceType}</td>
                <td className="text-center">{proc.ordersn}</td>
                <td className="text-center">
                  <span className={`fs-5 badge bg-${badgeColorMap[proc.statusRts] || "primary"} d-block`}>
                    {statusMap[proc.statusRts] ? t(statusMap[proc.statusRts]) : proc.statusRts}
                  </span>
                </td>
                <td className="text-center">
                  {proc.orderData?.marketplaceOrderCreatedAt
                    ? moment(proc.orderData.marketplaceOrderCreatedAt.toDate()).format("DD/MM/YYYY HH:mm")
                    : t("Common.NoRecord")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReportModal;
