/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import WABAAccount from '../../../../../../models/WABAccount'

type Props = {
  wabaAccount: WABAAccount;
  name?: string;
};

const WABAAccountInfoCell: FC<Props> = ({ wabaAccount, name }) => {
  return (
  <div className={clsx(
    `text-gray-700 mb-1`,
    "text-center" 
  )}>
    {/* begin:: Avatar */}
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
      <Link
        to="#"
        //  to={
        //   "/contact/contact-detail/overview/" + wabaAccount.id
        // }
        // state={{
        //   id: contact.id,
        //   name: contact.firstName! + (contact.lastName? " " + contact.lastName : ''),
        // }}
        
      >
      </Link>
    </div>
    <div className="d-flex flex-column">
      <Link
        to="#"
        // to={
        //   "/contact/contact-detail/overview/" + wabaAccount.id
        // }
        // state={{
        //   id: wabaAccount.id,
        // }}
        className="text-gray-800 text-hover-primary mb-1"
      >
        {name}
      </Link>
      
    </div>
  </div>
)};

export { WABAAccountInfoCell };