import db from ".";
import MarketplaceProductCategory from "../app/models/MarketplaceProductCategory";
import { ShopeeProductCategoryAttribute } from "../app/models/ShopeeProductCategoryAttribute";
import Storefront from "../app/models/Storefront";
import * as ls from "../app/modules/localstorage";
import { createRef } from "./connection";
import { converter2 } from "./converter";

export const getMarketplaceProductCategoryByMarketplace = async (
  marketplace: string
) => {
  return await db
    .collection("marketplaceProductCategory")
    .doc(marketplace)
    .get()
    .then((snap) => {
      if (snap.exists) {
        return { id: snap.id, ...snap.data() };
      }
      return undefined;
    })
    .catch((err) => {
      console.log("Error getMarketplaceProductCategoryByMarketplace", err);
      return undefined;
    });
};

export const getListProductCategoryByMarketplace = async (
  marketplace: string
) => {
  return await db
    .collection("marketplaceProductCategory")
    .doc(marketplace)
    .collection("categoryList")
    .withConverter(converter2<MarketplaceProductCategory>())
    .get()
    .then((snapshot) => {
      const categories = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return categories;
    });
};
export const getMarketplaceProductCategoryById = async (
  marketplace: string,
  id: string
) => {
  return await db
    .collection("marketplaceProductCategory")
    .doc(marketplace)
    .collection("categoryList")
    .doc(id)
    .withConverter(converter2<MarketplaceProductCategory>())
    .get()
    .then((snapshot) => {
      console.log("snap getMarketplaceProductCategoryById", snapshot);
      return snapshot.data();
    })
    .catch((error) => {
      console.log("Error getMarketplaceProductCategoryById", error);
      return undefined;
    });
};

export const getAllChildParentCategoryById = async (
  marketplace: string,
  id: string
) => {
  const accumulatedData: MarketplaceProductCategory[] = [];

  const getRecursiveData = async (marketplace: string, id: string) => {
    const data = await getMarketplaceProductCategoryById(marketplace, id);
    if (data) {
      accumulatedData.push(data);
      const parentId = data.parentCategoryId;
      if (parentId) {
        await getRecursiveData(marketplace, parentId.toString());
      }
    }
  };

  await getRecursiveData(marketplace, id);

  return accumulatedData;
};

export const createStorefront = async (storefrontData: any) => {
  let storefrontId;
  await db
    .collection("storefront")
    .add(storefrontData)
    .then((ref) => {
      storefrontId = ref.id;
      return ref.id;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  return storefrontId;
};

export const updateStorefront = async (id: string, storefrontData: any) => {
  let storefrontId = id;
  await db
    .collection("storefront")
    .doc(id)
    .set(
      {
        ...storefrontData,
      },
      {
        merge: true,
      }
    )
    .then(() => {
      console.log("Success update storefront", id);
    })
    .catch((error) => {
      console.error("Error update document: ", error);
    });
  return storefrontId;
};

export const createSelectedMarketplaceProductCategory = async (
  productCategoriesId: string,
  marketplaceData: any
) => {
  await db
    .collection("storefront")
    .doc(productCategoriesId)
    .collection("marketplaceCategory")
    .add(marketplaceData)
    .then((ref: any) => {
      productCategoriesId = ref.id;
      return ref.id;
    })
    .catch((error: any) => {
      console.error("Error adding document: ", error);
    });
};

export const updateSelectedMarketplaceProductCategory = async (
  storefrontId: string,
  marketplaceId: string,
  marketplaceData: any
) => {
  await db
    .collection("storefront")
    .doc(storefrontId)
    .collection("marketplaceCategory")
    .doc(marketplaceId)
    .set(marketplaceData, { merge: true })
    .then(() => {
      console.log("Success update marketplaceCategory", marketplaceId);
    })
    .catch((error: any) => {
      console.error("Error update document : ", error);
    });
};

export const getStorefrontByName = async (
  categoriesName: string,
  clientId: string
) => {
  return await db
    .collection("storefront")
    .where("isActive", "==", true)
    .where("name", "==", categoriesName)
    .where("client", "==", createRef("clients", clientId))
    .withConverter(converter2<Storefront>())
    .limit(1)
    .get()
    .then((snaps) => {
      const storefront = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return storefront;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export const getStorefrontById = async (id: string) => {
  return await db
    .collection("storefront")
    .doc(id)
    .get()
    .then((ref) => {
      return { id: ref.id, data: ref.data() };
    })
    .catch((err) => {
      console.error("Error fetching storefont id", err);
    });
};

export const handleIsNameExist = async (name: string, id: any) => {
  // Filter the collection by the name field
  const snapshot = await db
    .collection("storefront")
    .where("name", "==", name)
    .get();

  // Check the size of the returned QuerySnapshot object
  if (snapshot.size > 0) {
    // Get the first document from the QuerySnapshot object
    const doc = snapshot.docs[0];
    // Check if the document has a status of "draft" and if the ID matches the ID of the document you are trying to edit
    if (doc.data().status === "drafted" && doc.id === id) {
      // Update the name in the collection
      db.collection("storefront").doc(id).update({ name });
      return true;
    } else {
      // Display an error message or prompt the user to enter a different name
      console.log(`Error: Name "${name}" already exists in the collection.`);
      return false;
    }
  }
};

export const getStorefrontMarketplaceCategoryById = async (id: string) => {
  return await db
    .collection("storefront")
    .doc(id)
    .collection("marketplaceCategory")
    .get()
    .then((snaps: any) => {
      const storefronts = snaps.docs.map((doc: any) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log("storefronts marketplace : ", storefronts);
      return storefronts;
    })
    .catch((err) => {
      console.error(
        "Error fething storefront marketplace category collection",
        err
      );
    });
};

// utils
export const arrayToTree = (array: any[]) => {
  let map: any = {},
    node,
    roots: any = [],
    i;

  for (i = 0; i < array.length; i += 1) {
    map[array[i].categoryId] = i; // initialize the map
    array[i].children = []; // initialize the children
  }

  for (i = 0; i < array.length; i += 1) {
    node = array[i];
    if (node.parentCategoryId !== 0) {
      array[map[node.parentCategoryId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getAllStorefront = async (useFor: "list" | "table") => {
  const client = ls.getItemLC(ls.LCName.Client);
  if (!client) return;
  console.log(`getAllStorefrontList client: ${client.id}`);

  let query = db
    .collection("storefront")
    .where("isActive", "==", true)
    .where("client", "==", createRef("clients", client.id));

  if (useFor === "list") {
    query = query
      .where("status", "!=", "drafted")
      .orderBy("status", "asc")
      .orderBy("name", "asc");
  } else {
    query = query.orderBy("createdAt", "desc");
  }

  return await query
    .get()
    .then((snaps) => {
      const storefrontList = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return storefrontList;
    })
    .catch((error) => {
      //TODO
      console.log(`Error storefront getAll ${error}`);
    });
};

export const deleteStorefrontById = async (id: string) => {
  await db
    .collection("storefront")
    .doc(id)
    .update({ isActive: false, updatedAt: new Date() });
};
