import InitialState, { CustomerInQueueAction } from "./CIQRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Collaboration as CIQModel } from "../../../models/Chat";
import { Timestamp } from "firebase/firestore";

const initialState: InitialState = {
  listCIQ: [],
  countCIQ: 0,
  currentPageCIQ: 0,
  maxPageCIQ: 0,
  arrayCiqPagination: [],
};

export const CIQSlice = createSlice({
  name: CustomerInQueueAction,
  initialState: initialState,
  reducers: {
    setListCIQ: (state, action: PayloadAction<CIQModel[]>) => {
      state.listCIQ = action.payload;
      state.countCIQ = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayCiqPagination = arr;
        console.log(arr, "ARR SETLISTCIQ DI CIQSLICE.TSX");
      } else {
        state.arrayCiqPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayCiqPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayCiqPagination = action.payload;
    },

    setCountCIQ: (state, action: PayloadAction<number>) => {
      state.countCIQ = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageCIQ = action.payload;
    },

    setMaxPageCIQ: (state, action: PayloadAction<number>) => {
      state.maxPageCIQ = action.payload;
    },

    updateListCIQ: (state, action: PayloadAction<CIQModel>) => {
      console.log(action.payload, "payload update pow");
      console.log("MASUK UPDATELISTCIQ DI CIQSLICE.TSX");
      let newListCIQ: CIQModel[] = [];
      const newCollab = action.payload;
      newListCIQ.push(newCollab);
      console.log(newListCIQ, "NEWLISTCIQ");

      for (let index = 0; index < state.listCIQ.length; index++) {
        if (state.listCIQ[index].id === newCollab.id) {
          console.log("Collab ID from Notif: " + state.listCIQ[index].id);
        } else {
          newListCIQ.push(state.listCIQ[index]);
          console.log(state.listCIQ.length, "STATE.LISTCIQ.length");
          console.log(newListCIQ, "data baru ciq gro");
        }
      }

      let filteredListCIQ = newListCIQ.filter((room) => {
        console.log(room, "ini room1 kbr");
        return (
          room?.status === "queue" ||
          (room?.status === "handled" &&
            room?.toUserModel?.every((userData: any) => {
              console.log(userData?.last_changed, "last online kbr");
              console.log(room?.lastCustomerInteractionAt, "last cust kbr");
              return (
                userData?.state !== undefined &&
                userData?.state !== "online" &&
                room?.lastCustomerInteractionAt && room.lastCustomerInteractionAt > userData?.last_changed
              );
            }))
        );
      });

      console.log(filteredListCIQ, "testing exd");

      const objToDate = (obj: any) => {
        let result = new Date(0);
        result.setSeconds(obj?.seconds);
        result.setMilliseconds(obj?.nanoseconds / 1000000);
        return result;
      };

      let newListSorted = filteredListCIQ.sort((a, b) => {
        // const dateA: any = a.lastCustomerInteractionAt ? a.lastCustomerInteractionAt.seconds : null;
        // const dateB: any = b.lastCustomerInteractionAt ? b.lastCustomerInteractionAt.seconds : null;

        // if (dateA === null) return 1;
        // if (dateB === null) return -1;

        // return dateA - dateB;
        let bd: any = objToDate(b?.lastCustomerInteractionAt);
        let ad: any = objToDate(a?.lastCustomerInteractionAt);
        return ad - bd;
      });

      state.listCIQ = newListSorted;
      state.countCIQ = filteredListCIQ.length;
      if (filteredListCIQ.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(filteredListCIQ.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayCiqPagination = arr;
      } else {
        state.arrayCiqPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(filteredListCIQ.length / 10) + "",
        ];
      }
    },

    deleteItemListCIQ: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      console.log(action.payload, "IOFI payload delete pow" , state.listCIQ);
      let newDataCollab = state.listCIQ.filter(
        (data) => data.id !== action.payload
      );
      state.listCIQ = newDataCollab;
      state.countCIQ = newDataCollab.length;
    },

    cleanReduxCIQ: (state, action: PayloadAction<boolean>) => {
      state.listCIQ = [];
      state.countCIQ = 0;
      state.currentPageCIQ = 0;
      state.maxPageCIQ = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListCIQ,
  deleteItemListCIQ,
  updateListCIQ,
  setCurrentPage,
  setArrayCiqPagination,
  setMaxPageCIQ,
  setCountCIQ,
  cleanReduxCIQ,
} = CIQSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default CIQSlice.reducer;
