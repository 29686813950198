import React, { useState } from "react";

type Props = {
  onChange?: (month: number[]) => void;
  selected?: number[];
  disabled?: boolean;
};

const CampaignMonthSelect = ({ onChange, selected, disabled }: Props) => {
  const [selectedMonths, setSelectedMonths] = useState<number[]>(
    selected ? selected : []
  );

  const handleMonthClick = (month: number) => {
    let newSelected: number[] = [];

    if (selectedMonths.includes(month)) {
      newSelected = selectedMonths.filter(
        (selectedMonth) => selectedMonth !== month
      );
    } else {
      newSelected = [...selectedMonths, month];
    }
    if (newSelected.length !== 0) {
      setSelectedMonths(newSelected);

      if (onChange !== undefined) {
        onChange(newSelected);
      }
    }

    console.log("Selected Months:", newSelected);
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="calendar-container">
      <style>{style}</style>
      <div className="calendar-buttons">
        {monthNames.map((monthName, index) => {
          const monthNumber = index;
          const isSelected = selectedMonths.includes(monthNumber);

          return (
            <button
              key={monthNumber}
              data-testid={`button-month-${monthName}`}
              className={`month-button ${isSelected ? "selected" : ""} ${
                monthNumber < 9 ? "border-bottom" : ""
              } ${monthNumber % 4 === 0 ? "" : "border-right"}`}
              onClick={() => handleMonthClick(monthNumber)}
              disabled={disabled}
            >
              {monthName}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignMonthSelect;

const style = `
  .calendar-container {
    background-color: #F5F8FA;
    padding: 1rem;
    border-radius: 4px;
  }
  .calendar-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 400px;
    margin: 0 auto;
    border-radius: 6px;
    overflow: hidden
  }

  .border-right{
    border-right: 1px solid #EFF2F5 !important;
  }
  .border-bottom{
    border-bottom: 1px solid #EFF2F5 !important;
  }

  .month-button {
    background-color: #ffffff;
    border: none;
    outline: none;
    padding: 10px;
    transition: background-color 0.2s;
  }
  .month-button:enabled {
    cursor: pointer;
  }

  .month-button.selected:enabled, .month-button.selected:hover:enabled {
    background-color: #007bff;
    color: white;
  }
  .month-button.selected:disabled {
    background-color: #c9deff;
    color:white;
  }
  .month-button:hover:enabled {
    background-color: #0095E8;
    color: white;
  }
`;
