import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { RootState } from "../../../../setup/redux/store";
import {
  ListViewProvider,
  useListView,
} from "../../core/list/ListViewProvider";
import { CampaignReportHeader } from "./campaign-info/components/header/CampaignReportHeader";
import { QueryRequestProvider } from "./campaign-info/core/QueryRequestProvider";
import { QueryResponseProvider } from "./campaign-info/core/QueryResponseProvider";
import { CampaignsRepetitionInfoTable } from "./campaign-info/table/CampaignsRepetitionInfoTable";
import { CampaignsReport as CampaignInfoTable } from "./campaign-info/table/CampaignsReportTable";

const CampaignReport = () => {
  const { itemIdForUpdate } = useListView();
  const campaignData = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaign
  );
  console.log("DelRep Data Camp Rep", campaignData);

  return (
    <>
      <div className="card mb-2">
        <CampaignReportHeader />

        {campaignData?.campaignMessagesCount?.repetitions ? (
          <CampaignsRepetitionInfoTable />
        ) : (
          <CampaignInfoTable />
        )}
      </div>
    </>
  );
};

const CampaignReportWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CampaignReport />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { CampaignReportWrapper };
