import clsx from "clsx";
import { FC, useState } from "react";
import { Row } from "react-table";
import Template, { templateLanguages } from "../../../../../models/Template";
import PreviewMessage from "../../../../core/preview/PreviewMessage";
import ModalWarning from "../../../../../modules/modal/modalWarning";
import AlertSuccessAddFriend from "../../../../contact-management/user-friendlist/components/header/AlertSuccessAddFriend";
import SyncModal from "../../../../order/shiporder-export/order-list/components/modal/SyncModal";
import PreviewTemplateModal from "../../components/modal/PreviewTemplateModal";

type Props = {
  row: Row<Template>;
};

const CustomRow: FC<Props> = ({ row }) => {
  return (
    <>
      <tr
        {...row.getRowProps()}
        className="p-0 m-0"
        style={{
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        }}
      >
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({
                "p-0 m-0 text-end min-w-100px": cell.column.id === "actions",
              })}
              style={{
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
              }}
            >
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export { CustomRow };
