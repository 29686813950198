import {FC, useState, createContext, useContext, useEffect} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
} from '../../../../../resources/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  // Load state from localStorage or use initial state
  useEffect(() => {
    const storedState = localStorage.getItem("queryStateCatargeList");
    if (storedState) {
      setState(JSON.parse(storedState));
    }
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("queryStateCatargeList", JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("queryStateCatargeList");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
