import clsx from "clsx";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import {
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationChangeVariants from "../../../../../modules/modal/modalConfirmationChangeVariants";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../../modules/modal/modalConfirmationWithStateHandler";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";
import VariantCard from "./variant/VariantCard";
import VariantsCarouselImage from "./variant/VariantsCaraousel";
import { ShopeeProductCategoryAttributeList } from "../../../../../models/ShopeeProductCategoryAttribute";

let pathImg = "/media/images/variants";
const SlideshowImages = [
  {
    name: "Shopee",
    data: {
      mobile: `${pathImg}/variant-shopee.png`,
      desktop: `${pathImg}/variant-shopee.png`,
    },
  },
  {
    name: "Tokopedia",
    data: {
      mobile: `${pathImg}/variant-tokopedia.png`,
      desktop: `${pathImg}/variant-tokopedia.png`,
    },
  },
  {
    name: "Lazada",
    data: {
      mobile: `${pathImg}/variant-lazada.png`,
      desktop: `${pathImg}/variant-lazada.png`,
    },
  },
  {
    name: "Blibli",
    data: {
      mobile: `${pathImg}/variant-blibli.png`,
      desktop: `${pathImg}/variant-blibli.png`,
    },
  },
];

export interface TypeVariantOption {
  value: string;
  label: string;
}

const ProductVariantsCategory = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const typeVariants: TypeVariantOption[] = [
    {
      value: `${t("AddNewProduct.Modal.Variant.Color")}`,
      label: `${t("AddNewProduct.Modal.Variant.Color")}`,
    },
    {
      value: `${t("AddNewProduct.Modal.Variant.Size")}`,
      label: `${t("AddNewProduct.Modal.Variant.Size")}`,
    },
  ];

  const rd_isFromSummary = useSelector(
    (state: RootState) => state.Product.isFromSummary
  );

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );

  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );

  const rd_TempVariants = useSelector(
    (state: RootState) => state.Product.tempVariants
  );

  const rd_TempVariantMatrix: any = useSelector(
    (state: RootState) => state.Product.tempVariantsMatrix
  );

  const rd_TempVariantPhotos: any = useSelector(
    (state: RootState) => state.Product.tempProductVariantPhotos
  );

  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );
  const reduxSelectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );

  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const URI = rd_productID ? "/product/update" : "/product/create-product";

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: `${URI}/basic-information`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: `${URI}/media-upload`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: `${URI}/package-weight`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: `${URI}/shipments`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: `${URI}/shipments`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsWithSpesification: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: `${URI}/basic-information`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: `${URI}/media-upload`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: `${URI}/package-weight`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Shipment"),
      path: `${URI}/shipments`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.SelectCompany&Marketplace"),
      path: `${URI}/select-company-and-marketplace`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Specification"),
      path: `${URI}/category-attributes`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsForSummary: Array<PageLink> = [
    {
      title: t("AddNewProduct.Title.Summary"),
      path: `${URI}/summary`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const dispatch = useDispatch();
  const nav = useNavigate();
  const titleRef: any = useRef(null);
  const [isProductHaveVariant, setIsProductHaveVariant] = useState("");
  const [isValidProductVariant, setIsValidProductVariant] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [touchCounter, setTouchCounter] = useState(0);
  const [isModalFromSummary, setModalFromSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);

  const onProductVariantChange = (e: any) => {
    const variantProductvalue = e.target.value;
    const variantProduct = variantProductvalue === "true" ? true : false;
    setIsProductHaveVariant(variantProductvalue);
    setIsValidProductVariant(true);
    let newProduct = { ...reduxNewProduct };
    newProduct.isProductHaveVariants = variantProduct;
    dispatch(ReduxProduct.setIsProductHaveVariants(variantProductvalue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
    //check from redux if not exist set index 0
    if (rd_Variants.length <= 0)
      dispatch(ReduxProduct.setVariants([{ index: 0 }]));
  };

  const validationVariant = () => {
    let validation: any = [];
    for (let index = 0; index < rd_Variants.length; index++) {
      const variant = rd_Variants[index];
      console.log("variant : ", variant);
      if (
        variant.name &&
        variant.name !== "" &&
        variant.optionList &&
        variant.optionList.length > 0
      ) {
        validation = [...validation, true];
      } else {
        validation = [...validation, false];
      }
    }
    console.log("validation variant : ", validation);
    if (validation.includes(false)) {
      return;
    }
    //generate
    nav(`${URI}/variants-list`);
  };

  const generateVariantsMatrix = (variants: any) => {
    const variantData = [];
    if (variants.length === 1) {
      variants[0].optionList?.forEach((opt: any, index: any) => {
        const findMatrix =
          rd_VariantMatrix &&
          rd_VariantMatrix?.find(
            (matrix: any) => matrix.variant1 === opt.option
          );
        console.log("find index : ", findMatrix);
        variantData.push({
          tierIndex: [index],
          variant1: opt.option,
          sku: findMatrix && findMatrix.sku ? findMatrix.sku : "",
          weight: findMatrix && findMatrix.weight ? findMatrix.weight : 0,
          isActive:
            findMatrix && findMatrix.isActive ? findMatrix.isActive : true,
          isMainVariant:
            findMatrix && findMatrix.isMainVariant
              ? findMatrix.isMainVariant
              : index === 0
              ? true
              : false,
        });
      });
    } else if (variants.length > 1) {
      const variant1Options = variants[0]?.optionList?.map(
        (o: any) => o.option
      );
      const variant2Options = variants[1]?.optionList?.map(
        (o: any) => o.option
      );
      for (let i = 0; i < variant1Options.length; i++) {
        for (let j = 0; j < variant2Options.length; j++) {
          const tierIndex = [i, j];
          const findMatrix =
            rd_VariantMatrix &&
            rd_VariantMatrix?.find(
              (matrix: any) =>
                matrix.variant1 === variant1Options[i] &&
                matrix.variant2 === variant2Options[j]
            );
          console.log("find index : ", findMatrix);
          variantData.push({
            tierIndex: [i, j],
            variant1: variant1Options[i],
            variant2: variant2Options[j],
            sku: findMatrix && findMatrix.sku ? findMatrix.sku : "",
            weight: findMatrix && findMatrix.weight ? findMatrix.weight : 0,
            isActive:
              findMatrix &&
              (findMatrix.isActive === true || findMatrix.isActive === false)
                ? findMatrix.isActive
                : true,
            isMainVariant:
              findMatrix &&
              (findMatrix.isMainVariant === true ||
                findMatrix.isMainVariant === false)
                ? findMatrix.isMainVariant
                : i === 0 && j === 0
                ? true
                : false,
          });
        }
      }
    }
    return variantData;
  };

  const handleNext = () => {
    setIsTouched(true);
    setTouchCounter((prev) => prev + 1);
    let validationVarianProduct: boolean = true;
    if (!isProductHaveVariant) {
      if (typeof window !== "undefined") {
        // mobile
        window.scrollTo({ top: 0, behavior: "smooth" });
        // desktop
        titleRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsValidProductVariant(false);
      validationVarianProduct = false;
      return;
    } else {
      // Scroll Variant Error
      // adjustChildScrollPosition();
    }
    if (isProductHaveVariant === "true") {
      if (rd_Variants.length <= 0) {
        return;
      } else {
        validationVariant();
        const matrixVariant = generateVariantsMatrix(rd_Variants);
        dispatch(ReduxProduct.setVariantMatrix(matrixVariant));
      }
    } else {
      nav(`${URI}/summary`);
    }
  };

  const handleAddVariant = () => {
    if (rd_Variants.length === 2) return;
    dispatch(ReduxProduct.setVariants([...rd_Variants, { index: 1 }]));
  };

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onVariantsCategory));
    if (
      !reduxNewProduct ||
      !reduxNewProduct.brand ||
      !reduxNewProduct.name ||
      reduxNewProduct.name === "" ||
      !reduxNewProduct.storeFront ||
      !reduxNewProduct.description ||
      reduxNewProduct.description === ""
    ) {
      if (rd_productID !== "") {
        return;
      }
      nav(`${URI}/basic-information`);
    } else if (!rd_SelectedCompanies || !rd_marketplaces) {
      nav(`${URI}/select-company-and-marketplace`);
    }

    if (rd_isProductHaveVariants) {
      const producthavevariant =
        rd_isProductHaveVariants === "true" ? "true" : "false";
      setIsProductHaveVariant(producthavevariant);
      setIsValidProductVariant(true);
    }
    if (rd_isFromSummary) {
      dispatch(ReduxProduct.setTempVariants(rd_Variants));
      dispatch(ReduxProduct.setTempVariantsMatrix(rd_VariantMatrix));
      dispatch(ReduxProduct.setTempProductVariantPhotos(rd_VariantPhotos));
    }
  }, []);

  useEffect(() => {
    console.log("RDV, is Prod have vars", rd_isProductHaveVariants);
    if (rd_isProductHaveVariants !== "") {
      const producthavevariant =
        rd_isProductHaveVariants === "true" ? "true" : "false";
      setIsProductHaveVariant(producthavevariant);
      setIsValidProductVariant(true);
    }
  }, [rd_isProductHaveVariants]);

  useEffect(() => {
    let newProduct = { ...reduxNewProduct };
    if (
      reduxSelectedBrandData &&
      reduxSelectedBrandData !== null &&
      reduxSelectedBrandData.value &&
      reduxSelectedBrandData.value !== null
    ) {
      const brandRef = createRef("brands", reduxSelectedBrandData.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [reduxSelectedBrandData]);

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleSavedChanges = async () => {
    setIsLoading(true);
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    let variantCategoriesData: any[] = [];

    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onVariantsCategory;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        const updateDecrementNumberOfProductStorefront =
          await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
    }
    /***
     * Media Page - Process Save Photos
     */

    console.log("STEP MEDIA BEGINS");
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      for (let index = 0; index < rd_ProductPhotos.length; index++) {
        const element = rd_ProductPhotos[index];
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === index ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    console.log("STEP VIDEO BEGINS");

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
      // }
    }

    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }

    // save company data
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      // create companies
      let newCompanies: any = [];
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const companyRef = createRef("company", company.company);
        newCompanies.push({ ...company, company: companyRef });
      }
      console.log("companies : ", newCompanies);
      const dataCompanies = await actions.createDataCompany(
        productID,
        newCompanies
      );

      // create marketplace collection
      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );
        const account: any =
          findAccount && findAccount[0] && findAccount[0].id
            ? findAccount[0].id
            : "";
        const accountRef = createRef("account", account ? account : "");

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {

                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );

        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );
        if (marketplaceId && marketplaceId !== "") {
          resultMarketplaceId.push(marketplaceId);
        } else {
          isSuccess = false;
        }
      }
    }

    //if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];
        let elem = {
          ...element,
          name: element.name || "variant-photo",
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        let variantId: any;
        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          variantId = actions.createDataVariant(productID, dataVariant);
        }

        if (variantId && variantId !== "") {
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let productVariantId: any;
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (attribute.inputValues && (attribute.inputValues[0] || attribute.inputValues[1])) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if(attribute.inputValues[0] || attribute.inputValues[1]){
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId ? attribute.attributeId.toString() : "",
            categoryAttributeModel
          );
        }
      }
    }
  };

  const handleUnsavedChangesFromSummary = () => {
    dispatch(ReduxProduct.setVariants(rd_TempVariants));
    dispatch(ReduxProduct.setVariantMatrix(rd_TempVariantMatrix));
    dispatch(ReduxProduct.setProductVariantPhotos(rd_TempVariantPhotos));
    setModalFromSummary(false);
    nav(`${URI}/summary`);
  };

  const handleSavedChangesFromSummary = () => {
    const matrixVariant = generateVariantsMatrix(rd_Variants);
    dispatch(ReduxProduct.setVariantMatrix(matrixVariant));
    nav(`${URI}/summary`);
  };

  return (
    <>
      <PageTitle
        breadcrumbs={
          rd_isFromSummary
            ? UsersBreadcrumbsForSummary
            : !rd_categoryAttributes ||
              (rd_categoryAttributes.shopee &&
                rd_categoryAttributes.shopee.length < 1)
            ? UsersBreadcrumbs
            : UsersBreadcrumbsWithSpesification
        }
      >
        {t("AddNewProduct.Title.VariantsCategory")}
      </PageTitle>
      {!rd_isFromSummary && (
        <ModalConfirmationMoveToOtherPage
          isDirty={true}
          path={`${URI}`}
          onSave={() =>
            handleSavedChanges().then(() => {
              setIsLoading(false);
              dispatch(ReduxProductList.cleanReduxProductList(true));
              dispatch(ReduxProduct.cleanReduxProduct(true));
            })
          }
          onUnsave={() => handleUnsavedChanges()}
          isEmpty={false}
        />
      )}
      {rd_isFromSummary && (
        <ModalConfirmationWithStateHandler
          id="ModalCompanyAndMarketplaceFromSummary"
          isModalOpen={isModalFromSummary}
          title={t("Alert.Info")}
          body={t("Alert.UnsavedChanges")}
          saveText={
            rd_VariantMatrix.length > 0 ? t("Alert.Button.SaveChanges") : ""
          }
          unSaveText={t("Alert.Button.LeaveWithoutSave")}
          cancelText={t("Alert.Button.Cancel")}
          setIsModalOpen={setModalFromSummary}
          onClose={() => setModalFromSummary(false)}
          onUnsave={() => handleUnsavedChangesFromSummary()}
          onSave={() => handleSavedChangesFromSummary()}
        />
      )}

      <div className="d-flex flex-column flex-lg-column h-100">
        {!isTabletOrMobile &&
          ProductHeaderTitle("AddNewProduct.Title.VariantsCategory")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: "92.5%" }}
        >
          <div
            className={clsx(
              `overflow-hidden card h-100 ${isTabletOrMobile ? "" : "p-7"}`
            )}
          >
            {isTabletOrMobile && <ProductMobileIcon />}
            <div
              style={{
                height: `${isTabletOrMobile ? "100%" : "90%"}`,
              }}
              className={clsx(
                `d-flex flex-column ${isTabletOrMobile ? "p-5" : "p-5"}`
              )}
            >
              <div
                ref={titleRef}
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-auto "
                )}
              >
                {isTabletOrMobile && (
                  <div className="d-flex flex-column mt-5">
                    {ProductStep(
                      rd_categoryAttributes &&
                        rd_categoryAttributes.shopee &&
                        rd_categoryAttributes.shopee.length > 0
                        ? 7
                        : 6,
                      "AddNewProduct.Title.VariantsCategory"
                    )}
                  </div>
                )}

                {/* title */}
                {/* <div className="fw-bolder fs-2 mb-4">
                  {t("AddNewProduct.Info.ProductVariants")}
                </div> */}
                {/* caraousel */}
                {/* <VariantsCarouselImage
                  id="variantscaraousel"
                  dataImage={SlideshowImages}
                  title={`${t("AddNewProduct.Carousel.Info.PreviewVariant")}`}
                /> */}
                {/* input yes or no variant product */}
                <div className="d-flex flex-column">
                  <div className="fw-bolder fs-2">
                    {t(
                      "AddNewProduct.Info.ProductVariants.Doesthisproducthavevariants?"
                    )}
                    <span className="text-danger">*</span>
                  </div>
                  <div
                    className={clsx(
                      `d-flex flex-row justify-content-between mt-3 ${
                        isTabletOrMobile ? "w-50" : "w-25"
                      }`
                    )}
                  >
                    <div className="d-flex flex-row gap-2 form-check form-check-custom form-check-solid">
                      <input
                        id={`checkbox-producthavevariant`}
                        className="form-check-input"
                        data-testid={`checkbox-producthavevariant`}
                        type="checkbox"
                        key="producthavevariant"
                        value="true"
                        checked={
                          isProductHaveVariant !== "" &&
                          isProductHaveVariant === "true"
                        }
                        onChange={onProductVariantChange}
                      />
                      <span className="fs-3 fw-bold d-block">
                        {t("AddNewProduct.Info.ProductVariants.Yes")}
                      </span>
                    </div>
                    <div className="d-flex flex-row gap-2 form-check form-check-custom form-check-solid">
                      <input
                        id={`checkbox-productnothavevariant`}
                        className="form-check-input"
                        data-testid={`checkbox-productnothavevariant`}
                        type="checkbox"
                        key="productnothavevariant"
                        value="false"
                        checked={
                          isProductHaveVariant !== "" &&
                          isProductHaveVariant === "false"
                        }
                        onChange={onProductVariantChange}
                      />
                      <span className="fs-3 fw-bold d-block">
                        {t("AddNewProduct.Info.ProductVariants.No")}
                      </span>
                    </div>
                  </div>
                  {!isValidProductVariant && isTouched ? (
                    <div
                      className={clsx("mt-2")}
                      style={{ height: isTabletOrMobile ? "auto" : "18px" }}
                      data-testid="alert-dangerousgoods"
                    >
                      <span className="text-danger">
                        {t("AddNewProduct.Alert.ChooseAnswer")}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="mt-2"
                      style={{ height: isTabletOrMobile ? "auto" : "18px" }}
                    >
                      <span className="text-warning">
                        <i
                          className="bi bi-exclamation-triangle text-warning"
                          data-testid="MediaUploadWarningUploadVideo"
                        ></i>
                        {t("AddNewProduct.Warning.MaxCombinationVariant")}
                      </span>
                    </div>
                  )}

                  {/* Card */}
                  {isValidProductVariant && isProductHaveVariant === "true" ? (
                    rd_Variants?.length <= 0 ? (
                      <>
                        <VariantCard
                          variantNo={1}
                          variantType={typeVariants}
                          onClickNext={isTouched}
                          clickNextCounter={touchCounter}
                        />
                      </>
                    ) : (
                      <>
                        {rd_Variants &&
                          rd_Variants.map((v: any, i: number) => (
                            <Fragment key={i.toString()}>
                              <VariantCard
                                variantNo={v.index}
                                variantType={typeVariants}
                                onClickNext={isTouched}
                                clickNextCounter={touchCounter}
                              />
                            </Fragment>
                          ))}
                      </>
                    )
                  ) : null}
                  {isValidProductVariant &&
                    isProductHaveVariant === "true" &&
                    rd_Variants.length < 2 && (
                      <div className="d-flex mt-5">
                        <Button
                          btnbs="primary"
                          // cName={isTabletOrMobile ? "mx-5" : "mt-3"}
                          data-testid="btnAddVariant"
                          onClick={() => handleAddVariant()}
                        >
                          + {`${t("AddNewProduct.Button.AddVariant")}`}
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div
              className={clsx(
                `d-flex bg-white mt-auto  ${isTabletOrMobile ? "mb-5" : ""}`,
                rd_isFromSummary
                  ? "justify-content-end"
                  : "justify-content-between"
              )}
            >
              {!rd_isFromSummary &&
                rd_categoryAttributes &&
                rd_categoryAttributes.shopee &&
                rd_categoryAttributes.shopee.length > 0 && (
                  // <Link to={`${URI}/select-company-and-marketplace`}>
                  <Link to={`${URI}/category-attributes`}>
                    <Button
                      btnbs="primary"
                      cName={isTabletOrMobile ? "mx-5" : ""}
                      data-testid="btnBack"
                    >
                      {`${t("Storefront.Button.Back")}`}
                    </Button>
                  </Link>
                )}
              {!rd_isFromSummary &&
                (!rd_categoryAttributes ||
                  !rd_categoryAttributes.shopee ||
                  rd_categoryAttributes.shopee.length < 1) && (
                  <Link to={`${URI}/select-company-and-marketplace`}>
                    <Button
                      btnbs="primary"
                      cName={isTabletOrMobile ? "mx-5" : ""}
                      data-testid="btnBack"
                    >
                      {`${t("Storefront.Button.Back")}`}
                    </Button>
                  </Link>
                )}
              <div className="d-flex">
                {/* <Link to={rd_isFromSummary ? `${URI}/summary" : "/product/list"}>
                  <Button data-testid="btnCancel" btnbs="primary" type="button" disabled={rd_VariantMatrix.length === 0 && rd_isFromSummary}>
                    {t("AddNewProduct.Button.BPI.Cancel")}
                  </Button>
                </Link> */}
                {!rd_isFromSummary && (
                  <Link to="/product/list">
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  </Link>
                )}
                {rd_isFromSummary && (
                  <Button
                    data-testid="btnCancel"
                    btnbs="primary"
                    type="button"
                    // disabled={rd_VariantMatrix.length === 0 && rd_isFromSummary}
                    onClick={() => setModalFromSummary(true)}
                  >
                    {t("AddNewProduct.Button.BPI.Cancel")}
                  </Button>
                )}
                <Button
                  id="btnNext"
                  data-testid="btnNext"
                  type="button"
                  btnbs="primary"
                  cName="mx-5"
                  onClick={() => handleNext()}
                >
                  {`${t("AddNewProduct.Button.BPI.Next")}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};

export default ProductVariantsCategory;
