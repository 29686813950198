import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { KTSVG } from "../../../../resources/helpers";
import clsx from "clsx";
import * as ReduxMarketplaceProductCategory from "../../product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../setup/redux/store";
import { useMediaQuery } from "react-responsive";
import * as ReduxProduct from "../../../modules/product/redux/ProductSlice";
import { getMarketplaceProductCategory} from "../../../../actions/storefront";
import { productStep } from "../../../models/Product";
import { ShopeeProductCategoryAttributeList } from "../../../models/ShopeeProductCategoryAttribute";
import { categoryAttributes } from "../../product/redux/ProductRedux";
import { CapitalizedLetter } from "../../../../util/Formatter";

interface MarketplaceCategoryProps {
  title: string;
  step: string;
  isSubmitted?: any;
  reduxMarketplace: any;
  isChecked: boolean;
  currentMarketplaceSelected: any;
  arrayData: any;
  id: string;
  marketplaceName: string;
  company?: any;
  listMarketplace?: any;
  marketplace?: any,
}

function hasItemWithKeyAndValue(arr: [], key: string, value: any) {
  return arr.some((item) => {
    return item[key] === value;
  });
}

const findCategoryIndex = (arrays: [], key: string, value: any) => {
  let current = arrays.findIndex((items: any) => {
    return hasItemWithKeyAndValue(items, key, value);
  });
  return current;
};

function convertArray(array: any) {
  if (array !== undefined || array !== null || array.length !== 0) {
    return [Object.values(array[0])];
  }
}

const MarketplaceCategoryModalMobile: React.FC<MarketplaceCategoryProps> = ({
  id,
  title,
  step,
  isSubmitted,
  isChecked,
  currentMarketplaceSelected,
  arrayData,
  marketplaceName,
  reduxMarketplace,
  listMarketplace,
  company,
  marketplace,
}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [currentCategory, setCurrentCategory] = useState<any>(
    currentMarketplaceSelected
  );
  const [arrayParentList, setArrayParentList] = useState<any>([]); //parent list
  const [arrayChildren, setArrayChildren] = useState<any>([]); //children yg di gonta ganti
  const [selectedCategories, setSelectedCategories] = useState<any>(currentCategory[0]) //yg di select
  
  const rd_selectedStorefront = useSelector((state: RootState) => state.Product.selectedStoreFrontData);
  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );
  console.log("rd marketplace", reduxMarketplace)

  useEffect(() => {
    if (reduxMarketplace) {
      if (
        step === "companyandmarketplace" &&
        reduxMarketplace &&
        reduxMarketplace.length > 0
      ) {
        let children: any = reduxMarketplace?.filter((category: any) => {
          return category.parentCategoryId === 0;
        });
        let newArray = [...arrayChildren];
        newArray[0] = children;
        console.log("children : ", newArray)
        setArrayChildren(newArray);
      }
    }
  }, [reduxMarketplace]);

  const handleParentSelectedCategory = (selectedCategory: any) => {
    console.log("CMS, you click parent");
    setIsValidSelectedCategory(false);
    if (selectedCategory.parentCategoryId === 0) {
      let children: any = reduxMarketplace?.filter((category: any) => {
        return category.parentCategoryId === 0;
      });
      let newArray = [...arrayChildren];
      newArray[0] = children;
      console.log("newArray : ", newArray);
      setArrayChildren(newArray);
      setArrayParentList([]);
      const categoryToReplace = selectedCategories.slice(0, 1);
      setSelectedCategories(categoryToReplace);
      setIsValidSelectedCategory(false);
      return;
    }
    const currentIndex = arrayParentList.findIndex((items: any) => {
      return items.categoryId === selectedCategory.categoryId;
    });
    const newArrayParentList = arrayParentList.slice(0, currentIndex);
    setArrayParentList(newArrayParentList);
    let children: any = reduxMarketplace?.filter((category: any) => {
      return category.parentCategoryId === selectedCategory.parentCategoryId;
    });
    const newArrayChildren = arrayData.slice(0, arrayChildren.length);
    console.log("newArrayChildren : ", newArrayChildren);
    newArrayChildren[0] = children;
    // children = [...newArrayChildren, children];
    console.log("change children : ", children)
    setArrayChildren(newArrayChildren);
    const getFinalCategoryIndex = selectedCategories.findIndex((items: any) => {
      return items.id === selectedCategory.categoryId;
    });
    const categoryToReplace = selectedCategories.slice(
      0,
      getFinalCategoryIndex + 1
    );
    setSelectedCategories(categoryToReplace);
    if (selectedCategory.hasChildren === true) {
      setIsValidSelectedCategory(false);
    }
  };

  const handleSelectedCategory = (selectedCategory: any) => {
    if (selectedCategory.parentCategoryId === 0) {
      setSelectedCategories([]);
      setIsValidSelectedCategory(false);
    }

    let parent: any = reduxMarketplace?.filter((category: any) => {
      return (
        category.categoryId === selectedCategory.categoryId &&
        category.hasChildren === true
      );
    });
    let parentlist;
    if (parent.length > 0) {
      parentlist = [...arrayParentList, ...parent];
      setArrayParentList(parentlist);
    }
    const currentIndex = findCategoryIndex(
      arrayData,
      "categoryId",
      selectedCategory.categoryId
    );


    let children: any = reduxMarketplace?.filter((category: any) => {
      return category.parentCategoryId === selectedCategory.categoryId;
    });
    let dataPush;
    if (children.length > 0) {
      let newArray = [...arrayChildren];
      newArray[0] = children;
      console.log("newArray : ", newArray);
      setArrayChildren(newArray);
    } else {
      let category: any = reduxMarketplace?.filter((category: any) => {
        return category.categoryId === selectedCategory.categoryId;
      });
      dataPush = [...arrayParentList, category[0]];
      for (let index = 0; index < dataPush.length; index++) {
        const currentPiece = {
          id: dataPush[index].categoryId,
          level: index,
          hasChildren: dataPush[index].hasChildren,
          name: {
            id: dataPush[index].categoryName.id,
            en: dataPush[index].categoryName.en,
          },
        };
        dataPush.splice(index, 1, currentPiece);
      }
      setIsDropdownShow(false);
      setIsValidSelectedCategory(true);
      //set to redux
      if (dataPush && dataPush.length > 0) {
        const categoryToAdd = dataPush;
        console.log("category to add : ", categoryToAdd);
        const marketplaceUpdate = { ...marketplace, category: [] };
        const data: any = {
          company: marketplace.company ? marketplace.company : "",
          marketplace: marketplaceName.toLowerCase(),
          isActive: marketplace.isActive ? marketplace.isActive : false,
          category: [],
        };
        data.category.push(categoryToAdd);
        marketplaceUpdate.category.push(categoryToAdd);
        let tempMarketplace: any;
        const getMarketplace: any = rd_marketplaces.filter(
          (market: any) => market !== marketplace
        );
        console.log("getMarketplace : ", getMarketplace);
        tempMarketplace = [...getMarketplace, marketplaceUpdate];
        console.log("redux marketplace baru : ", tempMarketplace);
        setSelectedCategories(categoryToAdd);
        dispatch(ReduxProduct.setMarketplaces(tempMarketplace));
        dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(false));
      }
    }
  };

  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [isValidSelectedCategory, setIsValidSelectedCategory] = useState(true);

  useEffect(() => {
    if (id?.toLowerCase() === "shopee") {
      if (!reduxMarketplace || reduxMarketplace.length <= 0) {
        getMarketplaceProductCategory(id.toLowerCase()).then((response) => {        
          if (response) {
            dispatch(
              ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
                response
              )
            );
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      marketplace &&
      marketplace.category &&
      marketplace.category?.length > 0
    ) {
      let listCategories: any = [...marketplace.category];
      listCategories = convertArray(listCategories);
      console.log("listCategories in use effect: ", listCategories);
      let newFinal: any = [];
      let tempParentList: any = [];
      listCategories.map((list: any) => {
        console.log("list : ", list);
        list.map((cat: any, i: number) => {
          console.log("cat : ", cat);
          let children: any = reduxMarketplace?.find(
            (category: any) => category.categoryId === cat.id.toString()
          );
          console.log("children : ", children);
          const currentPiece = {
            id: cat.id,
            level: cat.level,
            hasChildren: cat.hasChildren,
            name: cat.name,
          };

          newFinal[i] = currentPiece;
          console.log("new final : ", newFinal);
          setSelectedCategories(newFinal);

          if (children?.hasChildren) {
            tempParentList[i] = children;
            setArrayParentList(tempParentList);
          } else {
            let tempChildren: any = reduxMarketplace?.filter(
              (category: any) => {
                return category.parentCategoryId === children?.parentCategoryId;
              }
            );
            let newArray = [];
            newArray[0] = tempChildren;
            console.log("new array children : ", newArray);
            setArrayChildren(newArray);
          }

          return cat;
        });

        return list;
      });
    }
    }, [marketplace]);
  // }, [setSelectedCategories]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <>
      <div
        className={clsx(
          `d-flex flex-column`,{
            "w-100" : step === "summary" || step === "companyandmarketplace"
          } 
        )}
      >
        <div
          id={"marketplaceCategory" + marketplaceName + company}
          className={clsx(`border rounded-top d-flex btn-group`, {
            "pointer-none": !isChecked,
            "w-100": step === "summary",
            "border-gray-900": isChecked,
            "border-gray-300": !isChecked,
            "rounded-bottom": !isDropdownShow,
          })}
        >
          <button
            className={clsx(
              "d-flex flex-row justify-content-between btn w-100 text-gray-500 align-items-center px-4",
              {
                "text-gray-900": isChecked,
              }
            )}
            id="btnselectedcategory"
            style={{
              alignItems: "left",
            }}
            onClick={() => {
              if (isValidSelectedCategory === true) {
                setIsDropdownShow(!isDropdownShow);
              }
            }}
          >
            {selectedCategories?.length <= 0 && (
              <span>
                {t("Storefront.Input.SelectMarketPlace").replace(
                  "<<marketplace>>",
                  CapitalizedLetter(title)
                )}
              </span>
            )}
            {selectedCategories?.length > 0 && (
              <span>
                {selectedCategories?.map((category: any) => {
                  return (
                    <>
                      {i18n.language === "id"
                        ? category.name.id
                        : category.name.en}{" "}
                      {category.hasChildren ? " > " : ""}
                    </>
                  );
                })}
              </span>
            )}
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className={clsx("svg-icon", { "svg-icon-dark": isChecked })}
            />
          </button>
        </div>
        {isChecked && isDropdownShow && (
          <div
            className="d-flex flex-column w-100 border border-top-0 border-dark rounded-bottom px-3"
            style={{
              maxHeight: "40vw",
              overflowY: "hidden",
              backgroundColor: "#F0F0F0",
            }}
          >
            <div className="d-flex flex-column p-2">
              {arrayParentList &&
                arrayParentList.length > 0 &&
                arrayParentList.map((category: any, i: any) => {
                  return (
                    <>
                      {category?.hasChildren && (
                        <div
                          className="d-flex flex-row justify-content-between mb-2 px-1 fw-bolder"
                          role="button"
                          onClick={() => handleParentSelectedCategory(category)}
                          key={category.categoryId}
                        >
                          {i18n.language === "id"
                            ? category.categoryName.id
                            : category.categoryName.en}
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr072.svg"
                            className={clsx("svg-icon", {
                              "svg-icon-dark": isChecked,
                            })}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              <div
                style={{
                  maxHeight: "40vw",
                  overflowY: "scroll",
                  backgroundColor: "#F0F0F0",
                }}
              >
                {arrayChildren &&
                  arrayChildren?.map((category: any, i: any) => {
                    return (
                      <>
                        {category &&
                          category?.map((cat: any, i: number) => {
                            const isCategoryActive = selectedCategories.some(
                              (u: any) => {
                                return u.id.toString() === cat.categoryId;
                              }
                            );
                            return (
                              <div
                                className={clsx(
                                  `d-flex flex-row justify-content-between mb-2 px-1`,
                                  {
                                    "bg-primary text-white rounded":
                                      isCategoryActive,
                                  }
                                )}
                                role="button"
                                onClick={() => handleSelectedCategory(cat)}
                                key={cat.categoryId}
                              >
                                {i18n.language === "id"
                                  ? cat.categoryName.id
                                  : cat.categoryName.en}
                                {cat.hasChildren && (
                                  <KTSVG
                                    path="/media/icons/duotune/arrows/arr071.svg"
                                    className={clsx("svg-icon", {
                                      "svg-icon-dark": isChecked,
                                      "svg-icon-white": isCategoryActive,
                                    })}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <div
          className={clsx("mt-2 mb-2")}
          style={{
            height: isTabletOrMobile ? "auto" : "18px",
            minHeight: "8px",
          }}
          data-testid="alert-company"
        >
          {step === "companyandmarketplace" &&
            isSubmitted &&
            id?.toLowerCase() === title?.toLowerCase() &&
            selectedCategories.length <= 0 &&
            isChecked && (
              <span className="text-danger p-2 alert-marketplace">
                {t("Storefront.Alert.SelectMarketplace")}
              </span>
            )}
        </div>
      </div>
      {/* {step === "summary" && id === title && isSubmitted && isAllUnchecked && (
        // checkedWithNoCategory &&
        <p className="text-danger">{t("Storefront.Alert.SelectMarketplace")}</p>
      )}

      {step === "summary" &&
        id === title &&
        // isSubmitted &&
        checkedWithNoCategory &&
        id === checkedWithNoCategory.name && (
          <p className="text-danger p-2">
            {t("Storefront.Alert.SelectMarketplace")}
          </p>
        )} */}
    </>
  );
};

export default MarketplaceCategoryModalMobile;
