import { Modal, Offcanvas } from "react-bootstrap";
import { KTCard } from "../../../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { FC, useEffect } from "react";
import db, { createRef } from "../../../../../../../db";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
const ModalAuthGuide: FC<{
  isOpen?: boolean;
  onClose?: () => void;
  marketplaceName?: string;
}> = ({ isOpen, onClose, marketplaceName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const closeUpdate = async () => {
    onClose && onClose();
  };


  useEffect(() => {
    const EventHandler = (event: any) => {
      console.log(event?.data, "test bypass");
      if (event.data === "ServerClosed") {
        window.location.reload();
        // navigate("/settings/marketplace");
      }
    };
    window.addEventListener("message", EventHandler);
    return () => {
      window.removeEventListener("message", EventHandler);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <>
          {isTabletOrMobile ? (
            <Offcanvas
              show={isOpen}
              onHide={async () => {
                closeUpdate();
              }}
              placement="bottom"
              style={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
              className="h-auto pb-20"
            >
              <Offcanvas.Header closeButton className="border-bottom ms-5 me-5">
                <Offcanvas.Title data-testid="fs-2 pt-2">
                  {t("Marketplace.Modal.HowToAuth.Title")}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="h-auto">
                <KTCard>
                  <div className="pt-5 pb-20 pe-5">
                    <h5 className="ps-4 mb-5">
                      {t("Marketplace.Modal.HowToAuth.Body.Follow")}
                    </h5>
                    <ol className="fs-6">
                      <li>
                        {t("Marketplace.Modal.HowToAuth.Body.One.One")}
                        {marketplaceName?.replace("_", " ")}
                        {t("Marketplace.Modal.HowToAuth.Body.One.Two")}
                      </li>
                      <li>{t("Marketplace.Modal.HowToAuth.Body.Two")}</li>
                      <li>
                        {t("Marketplace.Modal.HowToAuth.Body.Three")}
                        {marketplaceName?.replace("_", " ")}
                      </li>
                      <li>{t("Marketplace.Modal.HowToAuth.Body.Four")}</li>
                    </ol>
                  </div>
                </KTCard>
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <>
              <Modal
                animation
                centered
                show={isOpen}
                // onHide={() => onClose && onClose()}
                onHide={async () => {
                  closeUpdate();
                }}
                className="md"
                scrollable={false}
              >
                <Modal.Header closeButton className="bg-light">
                  <Modal.Title data-testid="modal-title">
                    {t("Marketplace.Modal.HowToAuth.Title")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                  <KTCard className="bg-white pt-10 pb-10 ps-5 pe-5">
                    <div>
                      <h5 className="ps-4 mb-5">
                        {t("Marketplace.Modal.HowToAuth.Body.Follow")}
                      </h5>
                      <ol>
                        <li>
                          {t("Marketplace.Modal.HowToAuth.Body.One.One")}
                          {marketplaceName?.replace("_", " ")}
                          {t("Marketplace.Modal.HowToAuth.Body.One.Two")}
                        </li>
                        <li>{t("Marketplace.Modal.HowToAuth.Body.Two")}</li>
                        <li>
                          {t("Marketplace.Modal.HowToAuth.Body.Three")}
                          {marketplaceName?.replace("_", " ")}
                        </li>
                        <li>{t("Marketplace.Modal.HowToAuth.Body.Four")}</li>
                      </ol>
                    </div>
                  </KTCard>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                  <button
                    type="button"
                    className="btn btn-primary w-25 text-center"
                    onClick={async () => {
                      closeUpdate();
                    }}
                  >
                    {t("Marketplace.Modal.HowToAuth.Footer.Button")}
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ModalAuthGuide;
