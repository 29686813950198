import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, { UpdateSyncAction } from "./SyncRedux";
import * as lc from "../localstorage/index";
import { Account as SyncedAccount } from "../../models/Account";
import { infoSyncOrder } from "../notify";


const initialState: InitialState = {
    clientID: "",
    AccountList: [],
    StartTime: new Date(),
    EndTime: new Date(),
    IsSync: false,
    SyncRes: null
}

const SyncSlice = createSlice({
    name: UpdateSyncAction,
    initialState: initialState,
    reducers: {
        runSync: (state, action: PayloadAction<string[]>) => {
            // state.IsSync = true;
            state.AccountList.push(...action.payload);
        },
        finishSync: (state, action: PayloadAction<any>) => {
            state.AccountList = [];
            // infoSyncOrder('Sync finished successfully!', true, action.payload);
        }
    }
});

export const {
    runSync,
    finishSync
} = SyncSlice.actions

export default SyncSlice.reducer;