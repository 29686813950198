import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProcessOrder } from "../../../../../../models/Process";
import ReportModal from "../../components/modal/ReportModal";

type ReportCellProps = {
  orders?: ProcessOrder[];
  status?: string;
  id: string;
};

const statusColorMap: { [status: string]: string } = {
  success: "success",
  process: "warning",
  failed: "danger",
};

// const statusIcon: { [status: string]: string } = {
//   success: "bi bi-clipboard-check-fill",
//   process: "bi bi-hourglass-split",
//   failed: "bi bi-x-square-fill",
// };

const statusLevel: { [status: string]: number } = {
  success: 30,
  process: 20,
  failed: 10,
};

export default function ReportCell({ orders, id }: ReportCellProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  // const [modalReportOpen, setModalReportOpen] = useState(false);
  // const onModalClose = setModalReportOpen(false);

  if (!orders) {
    return (
      <div className="text-center">
        {t("ExportOrderProcess.Report.NotFound")}
      </div>
    );
  }

  const groupedOrders = groupByStatus(orders);

  const sortedKeys = Object.keys(groupedOrders).sort(
    (a, b) => (statusLevel[b] || 0) - (statusLevel[a] || 0)
  );

  const showModal = (data: ProcessOrder[]) => {
    setModalOpen(true);
    setSelectedData(data.map((item) => item.orderRef.id));
  };

  return (
    <>
      <ReportModal
        isModalOpen={modalOpen}
        setIsModalOpen={() => setModalOpen(false)}
        processID={id}
        selectedData={selectedData}
      />
      <div
        onClick={() => {
          showModal(orders);
        }}
        className="w-full d-flex gap-1 align-items-center justify-content-center flex-wrap cursor-pointer"
      >
        {sortedKeys.map((status) => {
          const list = groupedOrders[status];
          const color = statusColorMap[status] ?? "secondary";
          // const icon = statusIcon[status] ?? "";
          return (
            <div
              key={status}
              className={`d-flex gap-1 align-items-center justify-content-between alert-${color} py-1 px-2 rounded fs-7`}
            >
              <span className={`badge bg-${color} py-1 px-2`}>
                {list.length}
              </span>
              {/* <span className="text-capitalize fw-bold">{status}</span> */}
              <span>
                {/* <i className={`${icon} text-${color}`}></i> */}
                {status.slice(0, 4).toLocaleUpperCase()}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

function groupByStatus(orders: ProcessOrder[]) {
  const orderStatusMap: {
    [status: string]: ProcessOrder[];
  } = {};

  orders.forEach((order) => {
    const statusRts =
      order.statusRts !== "timeout" ? order.statusRts : "failed";
    if (orderStatusMap[statusRts]) {
      orderStatusMap[statusRts] = [...orderStatusMap[statusRts], order];
      return;
    }
    orderStatusMap[statusRts] = [order];
    return;
  });

  return orderStatusMap;
}
