import clsx from "clsx";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import { validateURL, hasSubQuery } from "../../../../../util/validation";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { button } from "../../../../models/Language";
import { useMediaQuery } from "react-responsive";

interface Props {
  idx: number;
  mode: "Marketing" | "Custom";
  validationState?: any;
  content: {
    index: number;
    button_text: string;
    footer_text?: string;
    term_marketing?: boolean;
  };
  handleInputChange: (
    index: number,
    button_text: string,
    footer_text: string,
    term_marketing: boolean,
    type: string
  ) => void;
  handleDeleteItem: (id: number) => void;
}

const QRComponent: FC<Props> = ({
  idx,
  mode,
  content,
  handleInputChange,
  validationState,
  handleDeleteItem,
}) => {
  const templateCtaButton = useSelector(
    (state: RootState) => state.Template.templateButton
  );

  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const index = content.index;
  const formTitle = `${t(`IB.Title.${mode}`)}`;
  const formId = `${mode}Form`;
  const [btnText, setBtnText] = useState<string>(content.button_text);
  const [footerText, setFooterText] = useState<string>(
    content?.footer_text || ""
  );
  const [cTermMarketing, setCTermMarketing] = useState<boolean>(false);
  const { isTitleIdentical } = validationState;
  const dispatch = useDispatch();
  const debounceBtnTxt = useDebounce(btnText, 500);
  const debounceFtrTxt = useDebounce(footerText, 500);

  const isMarketing = () => mode === "Marketing";
  const isCustom = () => mode === "Custom";

  return (
    <>
      <div
        className="mb-4"
        style={{
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div
          id={`${formId}`}
          className="W-100 p-5 px-3 mx-0 pt-0 pb-0 mb-0 mt-0 d-flex align-items-center justify-content-center"
        >
          {isMarketing() && (
            // <div className="d-flex flex-column">
            <div>
              <div className={(isTabletOrMobile ? "flex-column" : "flex-row row-gap-2") + " w-100 d-flex flex-1 align-items-start justify-content-between m-0 px-2 py-4"}>
                <div
                  className={clsx(
                    isTabletOrMobile
                      ? "col-12"
                      : isMarketing()
                      ? "col-3"
                      : "col-md-4"
                  )}
                >
                  <label style={{ fontWeight: 600 }}>Type</label>

                  <input
                    value={"Marketing opt-output"}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="btnText"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                    }}
                    disabled={true}
                  />
                </div>

                <div
                  className={clsx(
                    isTabletOrMobile
                      ? "col-12"
                      : isMarketing()
                      ? "col-3"
                      : "col-md-4"
                  )}
                >
                  <label style={{ fontWeight: 600 }}>
                    {t("Template.Button.Text")}
                    <span className="text-danger">*</span> ⓘ
                  </label>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      defaultValue={btnText}
                      maxLength={25}
                      type="text"
                      className="form-control"
                      id="buttonTitle"
                      data-testid="btnText"
                      style={{
                        borderColor: "#979797",
                        borderWidth: "0.3px",
                        borderRadius: "5px",
                        paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                      }}
                      onChange={(e) => {
                        const val = e.target.value as string;
                        if (val.length > 25) return;

                        setBtnText(e.target.value);
                        handleInputChange(
                          index,
                          e.target.value,
                          footerText,
                          cTermMarketing,
                          mode
                        );
                      }}
                      disabled={true}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        fontSize: "0.875em", // Sesuaikan ukuran teks
                        color: "#979797", // Sesuaikan warna teks
                      }}
                    >
                      {btnText.length}/25
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {isTitleIdentical && (
                      <label className="text-danger">
                        {t("IB.Error.BTSame")}
                      </label>
                    )}

                    {btnText.length === 0 && (
                      <label
                        id={`${mode}TitleRequired`}
                        className={clsx("text-danger")}
                      >
                        {t("IB.Error.BTextEmpty")}
                      </label>
                    )}
                  </div>
                </div>

                <div
                  className={clsx(
                    isTabletOrMobile
                      ? "col-12"
                      : isMarketing()
                      ? "col-5"
                      : "col-md-4"
                  )}
                >
                  <label style={{ fontWeight: 600 }}>
                    {t("Template.Footer.Text")}
                    <span className="text-danger">*</span> ⓘ
                  </label>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      defaultValue={footerText}
                      maxLength={50}
                      type="text"
                      className="form-control"
                      id="buttonTitle"
                      data-testid={
                        isMarketing() ? "button-title-url" : "button-title-pn"
                      }
                      style={{
                        borderColor: "#979797",
                        borderWidth: "0.3px",
                        borderRadius: "5px",
                        paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                      }}
                      onChange={(e) => {
                        const val = e.target.value as string;
                        if (val.length > 50) return;

                        setFooterText(e.target.value);
                        handleInputChange(
                          index,
                          btnText,
                          e.target.value,
                          cTermMarketing,
                          mode
                        );
                      }}
                      disabled={true}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        fontSize: "0.875em", // Sesuaikan ukuran teks
                        color: "#979797", // Sesuaikan warna teks
                      }}
                    >
                      {footerText.length}/50
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {footerText.length === 0 && (
                      <label
                        id={`${mode}TitleRequired`}
                        className={clsx("text-danger")}
                      >
                        {t("IB.Error.BFooterEmpty")}
                      </label>
                    )}
                  </div>
                </div>

                {!isTabletOrMobile && (
                  <div className="col-1 p-0 m-0 d-flex align-self-center justify-content-center h-100 w-auto">
                    <p
                      className="p-0 m-0 w-auto"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <i
                        className="bi bi-x text-black fs-1 fw-bolder p-0 m-0 cursor-pointer"
                        onClick={() => {
                          console.log("Cancel", mode, idx);
                          handleDeleteItem(Number(idx));
                        }}
                      ></i>
                    </p>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex flex-row align-items-start justify-content-between px-2 col-gap-1">
                <input
                  className="form-check-input mt-1 cursor-pointer col-1"
                  data-testid={`switch-${mode}`}
                  type="checkbox"
                  role="switch"
                  onClick={(e: any) => {
                    setCTermMarketing(Boolean(e?.target?.checked));
                    handleInputChange(
                      index,
                      btnText,
                      footerText,
                      Boolean(e?.target?.checked),
                      mode
                    );
                  }}
                  style={{
                    appearance: "none", // Menghilangkan gaya default browser
                    width: "15px",
                    height: "15px",
                    borderRadius: "3px",
                    borderColor: "black",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    display: "inline-block",
                    backgroundColor: cTermMarketing ? "black" : "white", // Sesuaikan dengan warna yang diinginkan
                    cursor: "pointer",
                    position: "relative",
                  }}
                  defaultChecked={cTermMarketing}
                />
                <p className="col-12">
                  I understand that it's Testing WABA's responsibility to stop
                  sending marketing messages to customers who opt out.
                </p>
              </div>
              {isTabletOrMobile &&
              <div className="col-12 py-3">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    console.log("Cancel", mode, idx);
                    handleDeleteItem(Number(idx));
                  }}
                >
                  Discard
                </button>
              </div>}
            </div>
          )}
          {isCustom() && (
            <div className={(isTabletOrMobile ? "flex-column" : "flex-row") + " w-100 d-flex flex-1 align-items-start justify-content-between m-0 px-2 py-4 row-gap-2"}>
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCustom()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>Type</label>

                <input
                  value={"Custom"}
                  maxLength={25}
                  type="text"
                  className="form-control"
                  id="buttonTitle"
                  data-testid="btnText"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                  disabled={true}
                />
              </div>

              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCustom()
                    ? "col-8"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t("Template.Button.Text")}
                  <span className="text-danger">*</span>
                </label>

                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={btnText}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="btnText"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 25) return;

                      setBtnText(e.target.value);
                      handleInputChange(
                        index,
                        e.target.value,
                        footerText,
                        cTermMarketing,
                        mode
                      );
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {btnText.length}/25
                  </span>
                </div>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {isTitleIdentical && (
                    <label className="text-danger">
                      {t("IB.Error.BTSame")}
                    </label>
                  )}

                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}
                </div>
              </div>

              {isTabletOrMobile ? (
                <div className="col-12 py-3">
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => {
                      console.log("Cancel", mode, idx);
                      handleDeleteItem(Number(idx));
                    }}
                  >
                    Discard
                  </button>
                </div>
              ) : (
                <div className="col-1 p-0 m-0 d-flex align-self-center justify-content-center h-100 w-auto">
                  <p
                    className="p-0 m-0 w-auto"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className="bi bi-x text-black fs-1 fw-bolder p-0 m-0 cursor-pointer"
                      onClick={() => {
                        console.log("Cancel", mode, idx);
                        handleDeleteItem(Number(idx));
                      }}
                    ></i>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QRComponent;
