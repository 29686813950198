import { useContext, createContext, Dispatch, SetStateAction, useState, PropsWithChildren, useEffect } from "react";
import useFirestoreData, { FirestoreQueryState } from "../../../../../../../hooks/useFirestoreData";
import Account from "../../../../../../models/Account";
import { DocumentData } from "firebase/firestore";
import { QueryDocumentSnapshot } from "firebase/firestore";
import User from "../../../../../../models/User";
import { createRef, getUserByID } from "../../../../../../../db";
import * as lc from "../../../ ../../../../../modules/localstorage/index";
import SocketConnection from "../../../../../../../setup/socket/socket";
import { createDocRef } from "../../../../../../../hooks/useFirestoreData";
import { DocumentReference } from "firebase-admin/firestore";
// import * as AccountService from "../../../../../../../db/serviceAccount";

type MarketplaceTableContextValue = {
  marketplaceTableState: FirestoreQueryState<Account>;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  updateTableState: (data: Partial<FirestoreQueryState<Account>>) => void;
  updatePage: (newPage: number) => void;
  refreshData: () => Promise<void>;
  isLoading: boolean;
  isFetching: boolean;
  data?: {
    allCount: number;
    items: Account[];
  };
  accountFormData?: Partial<Account>;
  setAccountFormData: Dispatch<SetStateAction<Partial<Account>>>;
};

export const defaultMarketplaceTableState: FirestoreQueryState<Account> = {
  // clientID: client.id ? client.id : undefined ,
  clientID: undefined,
  collection: "account",
  sorting: {
    field: "type",
    order: "asc",
  } || {
      field: "name",
      order: "asc",
      compare: (a: any, b: any) => {
        const lowerA = a.toLowerCase();
        const lowerB = b.toLowerCase();

        if (lowerA < lowerB) {
          return -1;
        }
        if (lowerA > lowerB) {
          return 1;
        }
        // If the strings are equal, compare the original strings to maintain case order
        return a.localeCompare(b);
      },
    } || {
      field: "isActive",
      order: "asc",
    },
  limit: 10,
  filters: [
    // {
    //   field:"client",
    //   value: createDocRef("clients", client.id),
    // },
    {
      field: "type",
      value: ["shopee", "lazada", "tokopedia", "bukalapak", "blibli"],
    },
  ],
  currentPage: 1,
  searchKey: {
    field: "name",
    value: "",
  },
  priorityOrder: false,
};
export const defaultValue: MarketplaceTableContextValue = {
  marketplaceTableState: defaultMarketplaceTableState,
  selectedItems: [],
  setSelectedItems: () => {},
  updateTableState: () => {},
  updatePage: () => {},
  refreshData: () => new Promise((resolve) => resolve()),
  isLoading: true,
  isFetching: true,
  data: undefined,
  accountFormData: undefined,
  setAccountFormData: () => {},
};

export const TableContext = createContext<MarketplaceTableContextValue>(defaultValue);
export function useFirestoreTable() {
  return useContext(TableContext);
}

type TableProviderProps<T> = PropsWithChildren<{
  collection: string;
}>;

async function mapAccountData(docs: QueryDocumentSnapshot<Account, DocumentData>[]): Promise<Account[]> {
  return await Promise.all(
    docs.map(async (doc) => {
      console.log(doc.data(), "mplc chiy")
      const userID = doc.data().id;
      const userData = !userID ? undefined : await getUserByID(userID);
      return {
        ...doc.data(),
        userData: (userData as User) ?? undefined,
        id: doc.id,
      };
    })
  );
}

export function TableProvider<T>({ children, collection }: TableProviderProps<T>) {
  const clientID = lc.getItemLC(lc.LCName.Client)?.id;

  const [marketplaceTableState, setTableState] = useState<FirestoreQueryState<Account>>({
    ...defaultMarketplaceTableState,
    collection: collection,
    clientID: clientID,
  });

  useEffect(() => {
    const storedState = localStorage.getItem("tableStateMarketplacelist"); 
    if (storedState) {
      setTableState(JSON.parse(storedState));
    }
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("tableStateMarketplacelist", JSON.stringify(marketplaceTableState)); 
  }, [marketplaceTableState]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("tableStateMarketplacelist");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [accountFormData, setAccountFormData] = useState<Partial<Account>>({});
  const { data, isLoading, isFetching, refetch } = useFirestoreData<Account>(marketplaceTableState, mapAccountData);

  console.log("marketplace data", data);

  const updateTableState = (newdata: Partial<FirestoreQueryState<Account>>) => {
    let newState = newdata;

    if (!newdata.currentPage) {
      newState.currentPage = 1;
    }

    return setTableState((prev) => ({
      ...prev,
      ...newdata,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
    setSelectedItems([]);
  };

  const refreshData = async () => {
    await refetch();
    // setTableState((prev) => ({
    //   ...prev,
    //   sorting: defaultMarketplaceTableState.sorting,
    //   currentPage: defaultMarketplaceTableState.currentPage,
    //   priorityOrder: defaultMarketplaceTableState.priorityOrder,
    // }));
  };

  useEffect(() => {
    const socket = SocketConnection();
    const onDataChange = () => refreshData();
    socket.on(`Account-data-update`, onDataChange);

    return () => {
      socket.off(`Account-data-update`, onDataChange);
    };
  }, []);

  console.log("Items context chiy", data?.items);

  const value = {
    marketplaceTableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    updatePage,
    isLoading,
    isFetching,
    refreshData,
    data,
    accountFormData,
    setAccountFormData,
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}
