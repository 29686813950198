import axios from "axios";
import * as LC from "../../app/modules/localstorage/index";
import { ApiSettingAccess, ApiSettingPush, ApiSettingSms } from "../../app/models/ApiSetting";

const IS_SANDBOX =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "stagging-sandbox";

const BASE_URL = IS_SANDBOX
  ? `${process.env.REACT_APP_SERVER_URL}`
  : `${process.env.REACT_APP_SERVER_SANDBOX_URL}`;

export async function initializeSandbox() {
  const clientLC = LC.getItemLC(LC.LCName.Client);
  const uid = LC.getItemLC(LC.LCName.UserID);

  const url = `${BASE_URL}/sandbox/initialize`;

  try {
    const response = await axios.post<{
      message: string;
      error?: string;
      data?: {
        access_token: string;
        client_id: string;
        secret_key: string;
        ipwhitelist: string[];
      };
    }>(
      url,
      {
        client_id: clientLC?.id,
        client_name: clientLC?.clientName,
        uid: uid,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("initializeSandbox response :", response);
    return {
      status: response.status,
      response: response.data,
    };
  } catch (err) {
    console.log("Error Request server : " + err);
    return undefined;
  }
}

export async function getSandboxApiSetting() {
  const clientLC = LC.getItemLC(LC.LCName.Client);

  const sandboxClientID = `SANDBOX_${clientLC?.id}`;

  const url = `${BASE_URL}/setting/${sandboxClientID}/api`;

  try {
    const res = await axios.get<{
      message: string;
      error?: string;
      data?: {
        access?: ApiSettingAccess;
        push?: ApiSettingPush;
        sms?: ApiSettingSms;
      };
    }>(url, {
      headers: {
        "ngrok-skip-browser-warning": 1,
      },
    });
    console.log("getSandboxApiSetting response :", res);

    if (res.data.data) {
      return res.data.data;
    }

    return undefined;
  } catch (err) {
    console.log("Error Request server : " + err);
    return undefined;
  }
}

export const updateWhitelistPhoneNumbers = async (
  data: { phoneNumbers: string[] },
  accessToken: string
) => {
  const clientId = LC.getClientRef()?.id ?? "";
  const sandboxClientID = `SANDBOX_${clientId}`;
  const url = `${BASE_URL}/setting/${sandboxClientID}/whitelist_phone_number`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log("updateWhitelistPhoneNumbers response :", response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err: any) {
    console.log("updateWhitelistPhoneNumbers Error", err);
    if (!!err?.response) {
      return {
        status: err.response.status,
        data: err.response.data,
      };
    }
    return undefined;
  }
};
