import axios from "axios";
import * as bcrypt from "bcryptjs";
import db, { Timestamp } from "../../db";
import { sendMessage } from "./connection";
import Message from "../../app/models/Message";
import * as logger from "../../util/SDayslogger";

export const submitBrand = async (brandId: string, account: string ) => {
  const request = {
    brand: brandId,
    account: account,
  }
  
  const url : string = process.env.REACT_APP_SERVER_URL! + '/7days/brand/management/ShopBrand/add';
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    return await axios
    .post(url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //todo process response
        console.log(`Response Code Submit Brand : ${response.status} | ${ JSON.stringify(response.data)}`);
        return response;

      })
      .catch((error) => {
        //return error callback
        logger.SDayslogWithoutRouting(
          `Error response from server: ${error.response.status}, ${ JSON.stringify(error.response.data)}`, 
          logger.SDLOGGER_WARNING
        )
        return error.response;
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    console.log(`503, Service Unavailable`);
    return err;
  }
  
}

export const sendRequestMarkAsReadMessage = async (jsonRequest: any) => {
  const url : string = process.env.REACT_APP_SERVER_URL! + '/messages/markasread';
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);
  try {
    await axios
    .post(url, jsonRequest, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseJson = response.data;
        const responseCode = response.status;
        console.log(`Response Code Mark As Read Message : ${responseCode}`);
        console.log(`Response JSON Mark As Read Message : ${JSON.stringify(responseJson)}`);
      })
      .catch((error) => {
        //return error callback
        console.log(`Error response from server: ${error.response.status}, ${error.response.data}`);
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    console.log(`503, Service Unavailable`);
  }
};
