import { FC, useState } from "react";
import { Order } from "../../../../../../models/Order";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { KTCard } from "../../../../../../../resources/helpers";
import { toast } from "react-toastify";
import { textBold } from "../../../../../chat/components/chat-util/ChatUtil";

type EditPriorityModalProps = {
  data?: number;
  isOpen: boolean;
  onClose: () => void;
  submitData: (newData: number) => void;
};

const EditPriorityModal: FC<EditPriorityModalProps> = ({ data, isOpen, onClose, submitData }) => {
  const { t } = useTranslation();
  const [priorNum, setPriorNum] = useState<number>(0);
  const maxPrior = 100;
  const minPrior = 20;

  const handlePriorNumChange = (e: any) => {
    const value = Math.ceil(e.target.value);
    e.target.value = value;
    if (value < 0) return (e.target.value = 0);
    if (value > maxPrior) return (e.target.value = maxPrior);
    setPriorNum(value);
  };

  const changePriority = () => {
    if (priorNum < minPrior)
      toast(t("OrderList.Toast.CannotSetPriorityUnderMinimum"), {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else submitData(priorNum);
  };

  return (
    <>
      {isOpen && (
        <Modal animation centered show={isOpen} onHide={onClose}>
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">{t("OrderList.Modal.EditPriority.Title")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <KTCard className="fs-4 p-4">
              <form>
                <div className="form-group row">
                  <div className="col-sm-4">
                    <label className="col-form-label">{t("OrderList.Modal.EditPriority.Label")}</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      max={maxPrior}
                      min={minPrior}
                      step={1}
                      autoFocus
                      id="priorityNumber"
                      className={"form-control"}
                      defaultValue={data ? data : 0}
                      onChange={handlePriorNumChange}
                      onKeyDown={(e) => {
                        // prevent non-numeric input but allow backspace and delete and direction keys
                        if (
                          !/[0-9]/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "ArrowUp" &&
                          e.key !== "ArrowDown"
                        ) {
                          e.preventDefault();
                          return;
                        }
                      }}
                    />
                    <small style={{fontSize: "13px"}}>{t("OrderList.Modal.EditPriority.Input.Desc")}</small>
                  </div>
                </div>
              </form>
            </KTCard>
          </Modal.Body>

          <Modal.Footer className="justify-content-end">
            <button type="submit" className="btn btn-primary mx-2 w-25" onClick={changePriority}>
              {t("OrderList.Modal.EditPriority.Button.Confirm")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EditPriorityModal;
