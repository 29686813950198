import { FC } from "react";

type Props = {
  numberOfProducts: number;
};

const StorefrontNumberOfProductCell: FC<Props> = ({ numberOfProducts }) => {
  return <div className="text-gray-650 mb-1 ps-6">{numberOfProducts}</div>;
};

export { StorefrontNumberOfProductCell };