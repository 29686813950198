import clsx from "clsx";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column, HeaderProps } from "react-table";
import { FirestoreQueryState } from "../../../../../../../hooks/useFirestoreData";
import { Process } from "../../../../../../models/Process";
import { defaultTableState, useFirestoreTable } from "../../context/TableContext";
import ActionCell from "./ActionCell";
import DateTimeCell from "./DateTimeCell";
import DownloadCell from "./DownloadCell";
import ReportCell from "./ReportCell";
import { default as ShippingLimitCell, default as StatusCell } from "./StatusCell";

export type HeaderColumnsProp = PropsWithChildren<HeaderProps<Process>> & {
  field: keyof Process | "action";
};
export type CellColumnsProp = PropsWithChildren<CellProps<Process>> & {
  value: string;
};

const orderColumns: ReadonlyArray<Column<Process>> = [
  {
    Header: (props: HeaderColumnsProp) => <SelectionHeader key={props.column.id} {...props} />,
    id: "selection",
    Cell: (props: CellColumnsProp) => <SelectionCell {...props} />,
  },

  // ID
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.ProcessID" field="id" />;
    },
    id: "id",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} style={{ wordBreak: "break-all" }} className="text-center text-dark">
          {data.id}
        </td>
      );
    },
  },

  // Created At
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.DateCreated" field="createdAt" />;
    },
    id: "createdAt",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center">
          <DateTimeCell timestamp={data.createdAt} />
        </td>
      );
    },
  },

  // Created By
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.CreatedBy" field="action" />;
    },
    id: "createdBy",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      const { t } = useTranslation();

      return (
        <td {...props.cell.getCellProps()} className="text-center text-dark">
          {data.userData?.name ?? t("Common.NoData")}
        </td>
      );
    },
  },

  // Total Order
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.TotalOrder" field="action" />;
    },
    id: "totalOrder",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center text-dark">
          {data?.orders?.length}
        </td>
      );
    },
  },

  // Report
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.Report" field="action" />;
    },
    id: "report",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center">
          <ReportCell orders={data.orders} status={data.status} id={data.id} />
        </td>
      );
    },
  },

  // Download Count
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title="ExportOrderProcess.DownloadCount" field="downloadCount" />;
    },
    id: "downloadCount",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center text-dark">
          {/* {data.downloadCount ?? 0} {t("ExportOrderProcess.DownloadTimes")}
          <span className="ml-3">
            <i className="bi bi-chevron-down"></i>
          </span> */}
          <DownloadCell processes={data} />
        </td>
      );
    },
  },

  // Action
  {
    Header: (props: HeaderColumnsProp) => {
      return <CustomHeader {...props} title={"Common.Actions"} field="action" />;
    },
    id: "action",
    Cell: (props: CellColumnsProp) => {
      const data = props.row.original;
      const orders = data.orders || [];
      const status = data.status === "waiting" ? "waiting" : orders.some((order) => order.statusRts === "success") ? "finished" : "failed";
      const type = data.name === "RTS Order" ? "rtsOrder" : "exportOrder";
      const fileURL = data.fileURL;
      return (
        <td {...props.cell.getCellProps()} className="text-center">
          <ActionCell type={type} fileURL={fileURL} status={status} processID={data.id} orderIDs={data.orderIds} />
        </td>
      );
    },
  },
];

type CustomHeaderProps = HeaderColumnsProp & {
  className?: string;
  title?: string;
};

const CustomHeader: FC<CustomHeaderProps> = ({ className, title, field, column }) => {
  const { t } = useTranslation();
  const { tableState, updateTableState } = useFirestoreTable();

  const isSelectedSort = tableState.sorting.field === field ? tableState.sorting : undefined;

  const onClickHandler = () => {
    if (!field || field === "action") {
      return;
    }

    let newSort = defaultTableState.sorting;
    console.log("on klik handler", { field, isSelectedSort, tableState });
    if (!isSelectedSort || (isSelectedSort && isSelectedSort.order !== "asc")) {
      newSort = {
        field: field,
        order: "asc",
      };
    }
    if (isSelectedSort && isSelectedSort.order !== "desc") {
      newSort = {
        field: field,
        order: "desc",
      };
    }

    updateTableState({
      sorting: newSort,
    });
  };

  return (
    <th
      {...column.getHeaderProps()}
      key={column.id}
      className={clsx(className, tableState.sorting.field === field && `table-sort-${isSelectedSort ? isSelectedSort.order : ""}`)}
      style={{ cursor: "pointer", width: "14%" }}
      onClick={onClickHandler}
    >
      {t(title!)}
    </th>
  );
};

const SelectionHeader: FC<HeaderColumnsProp> = ({ column, data }) => {
  const { selectedItems, setSelectedItems } = useFirestoreTable();

  const isAllSelected =
    selectedItems.length > 0 &&
    data.filter((item) => !(!item.id || !item.fileURL || item.status !== "finished")).every((item) => selectedItems.includes(item.id));

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data.filter((item) => !(!item.id || !item.fileURL || item.status !== "finished")).map((item) => item.id));
    }
  };
  return (
    <th {...column.getHeaderProps()} className="px-2" style={{ width: "2%" }}>
      <div className="form-check form-check-sm form-check-custom form-check-solid">
        <input
          className="form-check-input"
          type="checkbox"
          data-kt-check={isAllSelected}
          data-kt-check-target="#kt_table_users .form-check-input"
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  );
};

const SelectionCell: FC<CellColumnsProp> = ({ cell, row }) => {
  const { selectedItems, setSelectedItems } = useFirestoreTable();

  const data = row.original;
  const itemKey = data.id;

  const isSelected = data.id !== undefined && selectedItems.includes(itemKey);

  const onSelect = () => {
    setSelectedItems((prev) => {
      if (!data.id || !data.fileURL || data.status !== "finished") {
        return prev;
      }
      if (prev.includes(itemKey)) {
        return prev.filter((key) => key !== itemKey);
      }
      return [...prev, itemKey];
    });
  };

  return (
    <td {...cell.getCellProps()} className="px-2">
      <div className="form-check form-check-sm form-check-custom form-check-solid">
        <input
          className="form-check-input"
          type="checkbox"
          data-kt-check={isSelected}
          data-kt-check-target="#kt_table_users .form-check-input"
          checked={isSelected}
          disabled={!data.id || !data.fileURL || data.status !== "finished"}
          onChange={() => onSelect()}
        />
      </div>
    </td>
  );
};

export default orderColumns;
