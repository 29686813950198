import clsx from "clsx";
import firebase from "firebase/compat/app";
import moment from "moment";
import { FC, Fragment, PropsWithChildren } from "react";

type DateTimeCellProp = {
  timestamp?: firebase.firestore.Timestamp;
};

const OrderTimeCell: FC<DateTimeCellProp> = ({ timestamp }) => {
  const value =
    timestamp && timestamp instanceof firebase.firestore.Timestamp
      ? timestamp.toDate()
      : "";

  const date = moment(value).format("DD/MM/YYYY");
  const time = moment(value).format("HH:mm");

  return (
    <Fragment>
      <div className="fw-bolder text-dark">{date}</div>
      <div className="fw-bold">{time}</div>
    </Fragment>
  );
};

export default OrderTimeCell;
