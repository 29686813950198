import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../../../core";
import CardMember from "./CardMember";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { UserRoles } from "../../../../../models/User";
import * as AddMemberRedux from "../../../../../modules/auth/redux/AddMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup/redux/store";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import * as ApiMember from "../../../../../../api/server/member";
import * as lc from "../../../../../modules/localstorage/index";

export interface initiateCardMember {
  id: number;
  email?: string;
  role?: UserRoles;
  onClickDelete?: () => void;
}

const AddMember = () => {
  const nav = useNavigate();
  const memberState = useSelector((state: RootState) => state.AddMember);
  const { t } = useTranslation();
  console.log(memberState, "memberstate");
  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("CD.Info.Settings"),
      path: "/setting",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("UserManagement.Title"),
      path: "/setting/user-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const AddMembersRd = useSelector(
    (state: RootState) => state.AddMember.members
  );
  const errorMessage = useSelector(
    (state: RootState) => state.AddMember.responseMessage
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [sentInvitation, setSentInvitation] = useState(0);
  const [failedInvitation, setFailedInvitation] = useState(0);
  const [addNewMember, setAddNewMember] =
    useState<initiateCardMember[]>(AddMembersRd);

  const deleteCardMember = (index: number) => {
    console.log(addNewMember, index, "test");
    // setAddNewMember(prevItems => prevItems.filter((member) => member.index !== index))
    const updatedMembers = AddMembersRd.filter((member) => member.id !== index);

    // Map through the filtered array to update the index values
    let updatedMembersWithIndex = updatedMembers.map((member, index) => {
      return { ...member, id: index };
    });

    if (updatedMembersWithIndex.length <= 0) {
      updatedMembersWithIndex = [{ id: 0 }];
    }

    // Set the updated array with adjusted index values
    setAddNewMember(updatedMembersWithIndex);
    dispatch(AddMemberRedux.setMembers(updatedMembersWithIndex));
  };

  useEffect(() => {
    if (AddMembersRd.length <= 0) {
      setAddNewMember([{ id: 0 }]);
    }
  }, []);

  const closeModal = async () => {
    dispatch(AddMemberRedux.cleanReduxAddMembers(true));
    nav("/setting/user-management");
  };

  const handleCreateAccess = async () => {
    setErrorResponse("");
    setSentInvitation(0);
    setFailedInvitation(0);
    setLoading(true);
    let client = lc.getItemLC(lc.LCName.Client);
    let user = lc.getItemLC(lc.LCName.UserData);
    const memberToRequest = AddMembersRd.map((member) => {
      if (member?.email !== "" && member?.role) return member;
    });
    await ApiMember.generateRefferalCode(
      memberToRequest,
      client?.id,
      user?.id,
      (responseCode: number, response: any) => {
        console.log(response, "test response");
        let timeoutId = setTimeout(() => {
          setIsOpenInfo(true);
        }, 5000);
        if (!responseCode || response === undefined) {
          setLoading(false);
          setIsOpenInfo(false);
          clearTimeout(timeoutId); // Hapus timeout jika tidak ada respons
          return;
        }
        if (!response.success) {
          // menghapus data email user lain yg sudah terdaftar dan menyisakan yg masih error
          if (response?.error_ids?.length > 0) {
            setIsOpen(true);
            setFailedInvitation(response?.error_ids?.length);
            let data_id = response?.error_ids;
            dispatch(AddMemberRedux.reverseDeleteMembers(data_id));
            if (response.message) {
              setErrorResponse(response.message);
              dispatch(AddMemberRedux.setResponseMessage(response.message));
            }
          }
          setLoading(false);
          if (response?.success_ids?.length > 0) {
            setIsOpen(true);
            setSentInvitation(response?.success_ids?.length);
          }
          clearTimeout(timeoutId); // Hapus timeout jika ada respons
          return;
        }
        if (response?.success_ids?.length > 0) {
          setIsOpen(true);
          setSentInvitation(response?.success_ids?.length);
        }
        setIsOpen(true);
        setLoading(false);
        clearTimeout(timeoutId);
      }
    );
  };

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("UserManagement.Button.AddMember")}
      </PageTitle>
      <div className="card h-100" data-testid="form-addmember">
        <div className="card mb-1 mb-xl-2 py-4 px-7 d-flex flex-row space">
          <h1 data-testid="form-title">
            {t("UserManagement.Button.AddMember")}
          </h1>
        </div>
        <div className="container row mx-auto h-100 d-flex overflow-scroll mb-0 pb-0">
          <div className="d-flex flex-column w-100 gap-5">
            {AddMembersRd?.map((item) => {
              return (
                <CardMember
                  key={item.id}
                  id={item.id}
                  onClickDelete={() => deleteCardMember(item.id)}
                />
              );
            })}
            <button
              data-testid="button-addmember"
              onClick={() => {
                const largestIndex = addNewMember.reduce((maxId, item) => {
                  return item.id > maxId ? item.id : maxId;
                }, 0);
                setAddNewMember((prevItems) =>
                  prevItems.concat({ id: largestIndex + 1 })
                );
                dispatch(AddMemberRedux.addMembers({ id: largestIndex + 1 }));
              }}
              disabled={
                errorMessage !== "" ||
                AddMembersRd?.some(
                  (item) =>
                    !item.email ||
                    !item.role ||
                    item.email === "" ||
                    !String(item.email).match(
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                    )
                ) === true
              }
              className={
                isTabletOrMobile
                  ? "btn btn-primary mh-50px w-auto mt-5"
                  : "btn btn-primary mh-50px w-25 mt-5"
              }
            >
              {t("UserManagement.Button.AddMember")}
            </button>
          </div>
        </div>
        <div
          className={clsx(
            `d-flex space`,
            isTabletOrMobile ? "p-10" : "p-10 mt-0"
          )}
          style={{ justifyContent: "space-between" }}
        >
          <button
            id="prevStep"
            data-testid="prevStep"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              //clear redux
              dispatch(AddMemberRedux.cleanReduxAddMembers(true));
              nav("/setting/user-management");
            }}
          >
            {t("Campaign.Button.Back")}
          </button>
          <button
            id="nextStep"
            data-testid="nextStep"
            type="button"
            className="btn btn-primary"
            disabled={
              AddMembersRd?.some(
                (item) =>
                  !item.email ||
                  !item.role ||
                  item.email === "" ||
                  !String(item.email).match(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                  )
              ) === true
            }
            onClick={() => {
              handleCreateAccess();
            }}
          >
            {!loading && (
              <span className="indicator-label">{t("CD.Button.Submit")}</span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {t("Login.Button.Loading")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>

      <Modal
        animation
        centered
        show={isOpen}
        onHide={async () => {
          closeModal();
        }}
        scrollable={false}
        data-testid="modal-success"
      >
        <Modal.Header closeButton className="bg-white">
          <Modal.Title data-testid="modal-title">
            {t("UserManagement.Invitation.Success")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <p className="mb-3 fs-5">
            {sentInvitation} {t("UserManagement.Invitation.Registered")}
            <br />
            {failedInvitation > 0
              ? `${failedInvitation} ${t(
                  "UserManagement.Invitation.FailedRegistered"
                )}`
              : ""}
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button
            type="button"
            className="btn btn-primary w-25 text-center"
            onClick={async () => {
              if (failedInvitation < 1) {
                closeModal();
              } else {
                setIsOpen(false);
              }
            }}
          >
            {t("OrderList.Sync.Button")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        data-testid="modal-info"
        animation
        centered
        show={isOpenInfo}
        scrollable={false}
      >
        <Modal.Header closeButton className="bg-white">
          <Modal.Title data-testid="modal-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <p className="mb-3 fs-5">{t("UserManagement.Modal.Information")}</p>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button
            type="button"
            className="btn btn-primary w-25 text-center"
            onClick={async () => {
              setIsOpenInfo(false);
            }}
          >
            {t("AddNewProduct.Modal.Error.Retry.Cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMember;
