import OrderModal from "../order/new-orderlist/modal/OrderModal";
import ModalExportOrder from "../order/new-orderlist/modal/ModalExportOrder";
import "./style/styless.scss";
import { ListProps } from "./models/Models";
import { TableProvider } from "./utils/TableProvider";
import ListView from "./components/list";

export default function ListComponent<T>({
  collection,
  collectionGroup,
  tabs,
  mapper,
  columns: columnsData,
  selection,
  defaultFilters: filters,
  defaultSorting: sorting,
  useFilter,
  searching,
  extra,
  action,
}: ListProps<T>) {
  return (
    <TableProvider<T>
      collection={collection}
      mapper={mapper}
      collectionGroup={collectionGroup}
      tabs={tabs}
      columns={columnsData}
      selection={selection}
      filters={filters}
      sorting={sorting}
      searching={searching}
      extra={extra}
      useFilter={useFilter}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ListView<T>
          collection={collection}
          collectionGroup={collectionGroup}
          tabs={tabs}
          mapper={mapper}
          columns={columnsData}
          selection={selection}
          defaultFilters={filters}
          defaultSorting={sorting}
          useFilter={useFilter}
          searching={searching}
          extra={extra}
          action={action}
        />
      </div>
      <OrderModal />
      <ModalExportOrder />
    </TableProvider>
  );
}
