import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup/redux/store";
import { useTranslation} from "react-i18next";
import { KTSVG } from "../../../resources/helpers";
import ReactSelect from "react-select/async";
import { components } from "react-select";
import MarketplaceProductCategory from "../../models/MarketplaceProductCategory";
import { Item1 } from "../../../resources/partials/content/activity/Item1";
import { t } from "i18next";

type ModalProps = {
  id: string;
  title: string;
  body: string | JSX.Element;
  displayCategory: [];
  image?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onOther?: () => void;
  otherOption?: string;
  confirmOption: string;
  cancelOption?: string;
  isOther?: boolean;
  searchTerm?: string;
  onChangeSearch?: (value: string) => void;
};

const ModalSelectProductCategory: React.FC<ModalProps> = ({
  id,
  title,
  body,
  displayCategory,
  image,
  onCancel,
  onConfirm,
  onOther,
  otherOption,
  confirmOption,
  cancelOption,
  isOther,
  searchTerm,
  onChangeSearch
}) => {
  const lastCategoriesSelected: any = displayCategory.slice().pop();
  const isLastCategoriesHasChildren = lastCategoriesSelected?.hasChildren;
  const {i18n } = useTranslation();
  const [refreshCategory, setRefreshCategory] = useState<boolean>(true);
  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const [selectedCategory, setSelectedCategory] = useState()

  useEffect(() => {
    setTimeout(() => {
      setRefreshCategory(false);
      console.log("masuk refresh category");
    }, 2000);
  }, [refreshCategory === true]);
  
  //function for filter data to search by name

  const selectedByFindCategory = (treeCategory: MarketplaceProductCategory) => {
   
    setTimeout(() => {
      document.getElementById(`${treeCategory.categoryId}`)?.click()
      document.getElementById(`${treeCategory.categoryId}`)?.focus()
    }, 100)
    if (!treeCategory.children) {
      return
    }
    selectedByFindCategory(treeCategory.children)
    // while(treeCategory.children) { 
    //   setTimeout(() => {
    //     document.getElementById(arrayList[i])?.click()
    //   }, 100)
    // }
  }


  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        // style={{maxWidth: "fit-content"}}
      >
        <div className="modal-content" style={{ backgroundColor: "#f2f2f2" }}>
          <div >
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            {/* <div className="w-25 px-6">
              <input value={searchTerm} onChange={(e) => {
                if (!onChangeSearch) {
                  return
                }
                onChangeSearch(e.target.value)
              }} className="form-control" placeholder="Nama Kategori"  />
              <button className="form-control" onClick={() => selectedByFindCategory(["100001","100018","100129","102084"])}> Button </button>
            </div> */}
            <div className="w-75 px-6">
              <ReactSelect 
                id="search-category"
                name="search-category"
                value={null}
                loadOptions={(input, callback) => {
                  const locale = i18n.language
                  const options: any = filterArrayWithParent(reduxShopeeProductCategoryData, input, locale)
                  callback(options)
                }}
                onChange={(item: any) => {
                  // setSelectedCategory(item)
                  console.log(JSON.stringify(item))
                  if (!item) {
                    return
                  }

                  selectedByFindCategory(item.value)
                }}
                placeholder={t('AddNewProduct.SearchBar.SearchCategory')}
                components={{
                  Option:(props) => {
                    console.log("selectcategoryprops",props.children)
                    return (
                      <components.Option {...props}>
                        <span dangerouslySetInnerHTML={{
                          __html: props.label.replaceAll(
                            ">",
                            "<i class='bi bi-chevron-right'></i>"
                          )
                        }}></span>
                      </components.Option>
                    );
                  }
                }}
              />
            </div>

          </div>
          {/* Add refresher on a component for laoding data */}
          {!refreshCategory && (
            <div className="modal-body">
              <div className="p-5 bg-white rounded">{body}</div>
            </div>
          )}
          {/* <div className="modal-body">
            <div className="p-5 bg-white rounded">{body}</div>
          </div> */}

          <div className="modal-footer d-flex justify-content-between">
            <div style={{ fontWeight: "bold" }}>
              {displayCategory?.map((category: any, index: number) => (
                <React.Fragment key={`display-cat-${index}`}>
                  <span>
                    {i18n.language === "id"
                      ? category.name.id
                      : category.name.en}
                  </span>
                  {displayCategory.length - 1 !== category.level && (
                    <span className="mx-3" style={{ fontFamily: "monospace" }}>
                      {">"}
                    </span>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div style={{ gap: "" }}>
              <button
                type="button"
                className="btn btn-light mx-5"
                data-bs-dismiss="modal"
                onClick={onOther ? onOther : onCancel}
              >
                {cancelOption}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                disabled={isLastCategoriesHasChildren}
                onClick={onConfirm ? onConfirm : onCancel}
              >
                {confirmOption}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSelectProductCategory;

function buildTree(array: MarketplaceProductCategory[]) {
  const map: {
    [key: string]: MarketplaceProductCategory
  } = {};
  let tree = undefined;

  // Create a mapping of categoryId to objects
  array.forEach((item) => {
    map[item.categoryId!] = { ...item, children: undefined };
  });

  array.forEach(item => {
    const parent = map[item.parentCategoryId!];
    if (parent) {
      parent.children = map[item.categoryId!];
    } else {
      tree = map[item.categoryId!]
    }
  });

  return tree;
}

function getLines (trees: MarketplaceProductCategory[], locale: string){
  const lines: {
    label: string
    value: MarketplaceProductCategory
  }[] = []

  const findLine: any = (tree: MarketplaceProductCategory, currLine: string) => {
    const language = locale === "id" ? tree.categoryName?.id : tree.categoryName?.en
    let line = currLine + language
    if(!tree.children){
      return line
    }
    return findLine(tree.children, `${line} > `)

  }

  trees.forEach((tree) => {
    const line = findLine(tree, "")
    lines.push({
      label: line,
      value: tree
    })
  })

  return lines.sort((a, b) => {
    const elementsA = a.label.split(">")
    const elementsB = b.label.split(">")
    if (elementsA.length < elementsB.length) {
      return 1
    }
    if (elementsA.length < elementsB.length) {
      return -1
    }
    if (elementsA.length > elementsB.length) {
      return -1
    }
    return 0

  })
}

function filterArrayWithParent(
  array: MarketplaceProductCategory[], 
  name: string,
  locale: string
) {
  const arrayTree: MarketplaceProductCategory[] = [];
  
  const findAllParent: any = (
    category: MarketplaceProductCategory,
    currArray: MarketplaceProductCategory[] = []
  ) => {
    const allParent = [...currArray]
    
    allParent.push(category)
    
    if(category.parentCategoryId === 0){
      return buildTree(allParent)
    }
    const parent = array.find((item) => item.categoryId === category.parentCategoryId)
    if(!parent){
      return buildTree(allParent)
    }
    return findAllParent(parent, allParent)
  }

  
  const matchedItems = array.filter((item) => {
    const language = locale === "id" ? item.categoryName?.id : item.categoryName?.en
    return !item.hasChildren && language && language.toLowerCase().includes(name.toLowerCase())
  })
//   console.log(matchedItems);
  
  matchedItems.forEach((category, index) => {
    const treeCategory = findAllParent(category)
    arrayTree.push(treeCategory)

  })

  return getLines(arrayTree, locale)
}