import Storefront, { MarketplaceCategoryList } from "../app/models/Storefront";
import {
  deleteStorefrontById,
  getAllStorefront,
  getStorefrontMarketplaceCategoryById,
  getListProductCategoryByMarketplace
} from "../db/serviceStorefront";

export const getStoreFrontList = async (
  callback: (
    storefrontOption: { label: string; value: string }[],
    storefronts: Storefront[]
  ) => void
) => {
  const listStoreFront = await getAllStorefront("list");
  console.log("listStorefront", listStoreFront);
  if (listStoreFront && listStoreFront.length > 0) {
    let storefronts: any[] = [];
    let resultStoreFront: Storefront[] = listStoreFront.map(
      (storefrontResult) => {
        storefronts.push({
          label: storefrontResult.data.name,
          value: storefrontResult.id,
        });
        return storefrontResult as unknown as Storefront;
      }
    );
    callback(storefronts, resultStoreFront);
  } else {
    callback([], []);
  }
};

export const getStoreFrontMarketplaceCategory = async (
  id: string,
  callback: any
) => {
  const listMarketplaceCategory = await getStorefrontMarketplaceCategoryById(
    id
  );
  if (listMarketplaceCategory && listMarketplaceCategory.length > 0) {
    let storefronts: any[] = [];
    let resultMarketplaceCategory: any[] = [];
    listMarketplaceCategory.map((marketplaceCategoryResult: any) => {
      resultMarketplaceCategory.push(
        marketplaceCategoryResult as unknown as MarketplaceCategoryList
      );
      storefronts.push(marketplaceCategoryResult);
    });
    callback(storefronts, resultMarketplaceCategory);
  } else {
    callback([], []);
  }
};

export function getMarketplaceProductCategory(
  marketplaceName: string
): Promise<any> {
  const getData = getListProductCategoryByMarketplace(marketplaceName)
    .then((initialCategory) => {
      return initialCategory;
    });
  return getData;
}

export const deleteStorefrontData = (id: string) => deleteStorefrontById(id);
