import db, { Timestamp } from ".";
import {
  ApiSettingAccess,
  ApiSettingPush,
  ApiSettingSms,
} from "../app/models/ApiSetting";
import { converter2 } from "./converter";
import * as lc from "../app/modules/localstorage/index";
import moment from "moment";

export const getAccess = async (): Promise<ApiSettingAccess | undefined> => {
  const clientId = lc.getClientId();
  if (clientId) {
    try {
      const apiAccess = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("access")
        .withConverter(converter2<ApiSettingAccess>())
        .get();
      if (apiAccess.exists) {
        return apiAccess.data();
      } else {
        console.log("Service API Setting Error : Client Not Found");
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  console.log("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const getPush = async (): Promise<ApiSettingPush | undefined> => {
  const clientId = lc.getClientId();
  if (clientId) {
    try {
      const apiAccess = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("push")
        .withConverter(converter2<ApiSettingPush>())
        .get();
      if (apiAccess.exists) {
        return apiAccess.data();
      } else {
        console.log("Service API Setting Error : Client Not Found");
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  console.log("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const getSms = async (): Promise<ApiSettingSms | undefined> => {
  const clientId = lc.getClientId();
  if (clientId) {
    try {
      const apiAccess = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("sms")
        .withConverter(converter2<ApiSettingSms>())
        .get();
      if (apiAccess.exists) {
        return apiAccess.data();
      } else {
        console.log("Service API Setting Error : Client Not Found");
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  console.log("Service API Setting Error : Client ID Not Found");
  return undefined;
};
export const createAccess = async () => {
  const clientId = lc.getClientId();
  const userId = lc.getUserId();
  const timestamp = moment().unix();

  if (clientId && userId) {
    const combinedString = `${clientId}#${userId}#${timestamp}`;
    const secretKey = btoa(combinedString);
    const access = {
      secretKey,
      clientId,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
    try {
      const apiAccess = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("access")
        .set(access);
      console.log("Service API Create Access", apiAccess);
      return access;
    } catch (error) {
      console.error("Service API Create Access", error);
      return undefined;
    }
  }
  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const updateAccess = async (accessData: Partial<ApiSettingAccess>) => {
  const clientId = lc.getClientId();
  if (clientId) {
    const access = {
      ...accessData,
      updatedAt: Timestamp.now(),
    };
    try {
      await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("access")
        .set(access);
      return access;
    } catch (error) {
      console.error("Service API Update Access", error);
      return undefined;
    }
  }

  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const createPush = async () => {
  const clientId = lc.getClientId();

  if (clientId) {
    const push = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
    try {
      const apiPush = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("push")
        .set(push);
      console.log("Service API Create Push", apiPush);
      return push;
    } catch (error) {
      console.error("Service API Create Push", error);
      return undefined;
    }
  }
  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const updatePush = async (pushData: Partial<ApiSettingAccess>) => {
  const clientId = lc.getClientId();
  if (clientId) {
    const push = {
      ...pushData,
      updatedAt: Timestamp.now(),
    };
    try {
      await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("push")
        .set(push);
      return push;
    } catch (error) {
      console.error("Service API Update Access", error);
      return undefined;
    }
  }

  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const createSms = async () => {
  const clientId = lc.getClientId();

  if (clientId) {
    const sms = {
      failoverSenderMode: "LN",
      maskingSms: "",
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
    try {
      const apiSms = await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("sms")
        .set(sms);
      console.log("Service API Create SMS", apiSms);
      return sms;
    } catch (error) {
      console.error("Service API Create SMS", error);
      return undefined;
    }
  }
  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};

export const updateSms = async (smsData: Partial<ApiSettingAccess>) => {
  const clientId = lc.getClientId();
  if (clientId) {
    const sms = {
      ...smsData,
      updatedAt: Timestamp.now(),
    };
    try {
      await db
        .collection("clients")
        .doc(clientId)
        .collection("api")
        .doc("sms")
        .set(sms);
      return sms;
    } catch (error) {
      console.error("Service API Update Access", error);
      return undefined;
    }
  }

  console.error("Service API Setting Error : Client ID Not Found");
  return undefined;
};
