import  Template  from "../../../models/Template";

interface InitialState {
  listTemplate: Template[];
  countTemplate: number;
  currentPageTemplate: number;
  maxPageTemplate: number;
  arrayTemplatePagination: string[];
}
const TemplateListAction: string = "TemplateListAction";

export type unsubFN = () => void;

export default InitialState;
export { TemplateListAction };
