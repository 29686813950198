import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import newMessageModel from "../../../models/Message"

import ChatTimeFromFirebase from "./chat-message/ChatTime";
import "../../../../styles/css/color.css";
import { saveMessageMedia } from "../../../../actions/chat";
import { useTranslation } from "react-i18next";
import { ChatFileView } from "./chat-modal/ChatFileView";
import { MessageLocation } from "./chat-message/ChatLocation";
import { useMediaQuery } from "react-responsive";
import { convertTextStyle, avatarUrl} from "./chat-util/ChatUtil";
import "../../../../styles/css/dropdown.scss";
import {MenuComponent} from '../../../../resources/assets/ts/components'
import ChatFailedMenu from "./chat-menu/ChatFailedMenu";
import ChatMessageInfo from "./chat-menu/ChatMessageInfo";
import * as lc from "../../../modules/localstorage/index";

interface MessageProps {
  message: newMessageModel;
  isDrawer?: boolean;
  index: number;
  messages: newMessageModel[];
  collaborationId: string;
}

const FriendChatMessage: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const { message, index, isDrawer, messages , collaborationId} = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [previousMedia, setPreviousMedia] = useState<string>("");
  const [nextMedia, setNextMedia] = useState<string>("");
  const [currentMedia, setCurrentMedia] = useState<string>(
    `kt_modal_${message.messageType}_${message.id}`
  );
  let listMediaUrl: string[] = [];
  let friendAvatar = document.getElementById("selectedUserChatAvatar") as HTMLImageElement;
  let userAvatar = document.getElementById("active_user_avatar") as HTMLImageElement;
  if (isTabletOrMobile) {
    userAvatar = document.getElementById("avatar") as HTMLImageElement;
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [])

  useEffect(() => {
    messages.forEach((obj) => {
      if (
        obj.messageType !== undefined &&
        (obj.messageType === "video" || obj.messageType === "image")
      ) {
        listMediaUrl.push(`kt_modal_${obj.messageType}_${obj.id}`);
      }
    });

    for (let index = 0; index < listMediaUrl.length; index++) {
      if (listMediaUrl[index] === currentMedia) {
        setNextMedia(listMediaUrl[(index + 1)]);
        if(index>0){
          setPreviousMedia(listMediaUrl[(index - 1)]); 
        }
      }
    }
  }, []);

  const templateAttr = {};

  let mediaFileName = message.filename;
  if (mediaFileName && mediaFileName?.length > 20) {
    mediaFileName = mediaFileName?.substring(0, 20) + "...";
  }

  let msgtype = "out";
  const activeUserId = lc.getItemLC("UID");
  // console.log(message)

  if (message.toUser?.id === activeUserId ) {
    msgtype = "in";
  }

  let bgChat = "cl-";
  let txChat = "black";
  if (msgtype !== "in") {
    bgChat = "cl-7days";
  } else {
    bgChat += "7days-in";
    // sabunzone, tokopedia, whatsapp  : hitam
    if (
      message.channel.toString().toLowerCase() !== "sabunzone" &&
      message.channel.toString().toLowerCase() !== "tokopedia" &&
      message.channel.toString().toLowerCase() !== "whatsapp"
    ) {
      txChat = "white";
    }
  }

  const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${
    msgtype === "in" ? "start" : "end"
  } mb-3`;

  const contentType = `justify-content-${msgtype === "in" ? "start" : "end"}`;

  const saveMedia = () => {
    if (message.mediaUrl) {
      if (message.filename !== undefined && message.filename !== "") {
        return saveMessageMedia(message.mediaUrl, message.filename);
      } else {
        const filename = t("HC.File.Untitled");
        return saveMessageMedia(message.mediaUrl, message.filename);
      }
    } else {
      return alert(t("HC.Error.FailedUpload"));
    }
  };

  // const deleteMessageHandler = () => {
  //   console.log("Delete clicked");
  //   document.getElementById(`message_retry_menu_${message.id}`)?.click();

  //   // Set Message isActive to False
  //   // MessageAction.updateIsActiveMessage(messageId);
  // }

  // Menu Open and Close Handler
  // Close Menu
  const closeMenuHandler = (menuId: string) => {
    document.getElementById(menuId)?.classList.add("hide-child");
    document.getElementById(menuId)?.click();
  }

  return (
    <div
      key={`message${index}`}
      className={clsx("d-flex", contentClass)}
      {...templateAttr}
    >
      {/* Inbound Avatar */}
      {msgtype === "in" && (
        <div
          className={clsx(
            "symbol symbol-circle",
            isTabletOrMobile ? "symbol-20px" : "symbol-35px"
          )}
          style={{ padding: isTabletOrMobile ? "2px" : "5px" }}
        >
          <img
            alt="Pic"
            // src={avatarUrl(message.userModel)}
            src={friendAvatar.src}
          />
        </div>
      )}

      {/* Chat Message */}
      <div 
        className="d-flex flex-column"
        style={{ position: "relative"}}
        onMouseEnter={() => document.getElementById(`message_menu_${message.id}`)?.classList.remove("hide-child")}
        onMouseLeave={() => closeMenuHandler(`message_menu_${message.id}`)}
      >
        {/* Message Menu (Info) */}
        {msgtype !== "in" && (
          <div className="hide-child" id={`message_menu_${message.id}`} style={{zIndex:9999}}>
            <div id={`message_menu_toggle_${message.id}`}
              className="message-menu fas fa-chevron-circle-down cursor-pointer"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="top-end"
              data-kt-menu-flip="bottom"
              style={{color: "white"}}
            ></div>
            <ChatMessageInfo userMessage={message} />
          </div>
        )
        }

        {/* Message Bubble */}
        <div id={`message_${message.id}`} className={clsx("d-flex flex-row", `${contentType}`)}>

          {/* Retry Message Menu if Status: "failed", undefined, ""
          {(msgtype !== "in" && (message.status === "failed" || message.status === "" || message.status === undefined)) && (
            <ChatFailedMenu 
              failedMessage={message} 
              failedCustomer= {customer}
              // failedCustomer= {message.customerModel}
              failedUser= {user}
              failedCollaborationId= {collaborationId}
            />
            // <ChatFailedMenu failedMessage={message} onDeleteMessage={deleteMessageHandler}/>
          )} */}

          {/* Text Message based on Message Type */}
          {/* Message Type: Unsupported */}
          {message.messageType === "unsupported" && (
            <div
              style={{ color: txChat }}
              className={clsx(
                "p-5 rounded",
                `${bgChat}`,
                " fw-bold mw-lg-300px"
              )}
            >
              <div>
                <span
                  className="bi bi-clock-fill"
                  style={{ color: txChat, paddingRight: "5px" }}
                />
                <span style={{ fontStyle: "italic" }}>
                  {t("HC.Error.Unsupportedmsg")}
                </span>
              </div>
            </div>
          )}

          {/* Message Type: Text */}
          {message.messageType === "text" && (
            <div
              style={{ color: txChat }}
              dangerouslySetInnerHTML={{
                __html: convertTextStyle(message.textContent).replaceAll("\n","<br/>"),
              }}
              className={clsx(
                "p-5 rounded fw-bold ",
                `${bgChat}`,
                isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
              )}
            ></div>
          )}

          {/* Message Type: Document */}
          {message.messageType === "document" && (
            <div
              className={clsx(
                "p-2 rounded",
                `${bgChat}`,
                "d-flex flex-column",
                isTabletOrMobile ? "mw-300px" : "mw-lg-300px"
              )}
            >
              <div
                className={clsx(
                  "p-2 rounded",
                  `${bgChat}`,
                  "d-flex flex-row align-items-center bg-opacity-25"
                )}
                title={message.filename}
              >
                <div
                  className="bi bi-file-earmark-text fs-3x"
                  style={{ color: txChat }}
                ></div>
                <div
                  className="container-sm"
                  style={{ paddingLeft: "5px", color: txChat }}
                >
                  {mediaFileName}
                </div>
                <div
                  className="d-flex flex-column justify-content-end"
                  style={{ width: "70px" }}
                >
                  <div
                    className="btn bi bi-download fs-3"
                    onClick={saveMedia}
                    style={{
                      color: txChat,
                      paddingTop: "5px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingBottom: "0px",
                    }}
                  ></div>
                  <div
                    className="text-center"
                    style={{ fontSize: "10px", color: txChat }}
                  >
                    {message.filesize}
                  </div>
                </div>
              </div>
              {message.textContent && message.textContent !== "" && (
                <div
                  className={clsx(
                    "p-3 rounded",
                    `${bgChat}`,
                    "fluid align-items-center"
                  )}
                  style={{ color: txChat }}
                  dangerouslySetInnerHTML={{
                    __html: convertTextStyle(message.textContent).replaceAll("\n","<br/>"),
                  }}
                ></div>
              )}
            </div>
          )}

          {/* Message Type: Image */}
          {(message.messageType === "image" ||
            message.messageType === "video") && (
            //bubble chat
            <div
              className={clsx("p-2 rounded"
              , `${bgChat}`
              )}
            >
              {message.messageType === "image" && (
                <div
                  className={clsx(
                    "image-input bg-opacity-25"
                    , "d-flex flex-column"
                    ,isTabletOrMobile ? "w-200px" : "mw-300px"
                  )}
                  // className="image-input bg-opacity-25 mw-lg-300px"
                  data-kt-image-input="true"
                  data-bs-toggle="modal"
                  data-bs-target={`#kt_modal_${message.messageType}_${message.id}`}
                  // onClick={openDialog}
                >
                  {/* begin::Preview existing avatar */}
                  <div
                    className={clsx(
                      "wrapper"
                      ,isTabletOrMobile ? "mw-200px" : "mw-300px"
                    )}
                    // className="image-input-wrapper mw-300px"
                    style={{
                      backgroundImage: `url('${message.mediaUrl}')`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  {/* end::Preview existing avatar */}
                </div>
              )}
              {message.messageType === "video" && (
                // <div className="card overlay overlay-block">
                //   <div className="card-body p-0">
                //     <div className="overlay-wrapper"
                //       data-kt-image-input="true"
                //       data-bs-toggle="modal"
                //       data-bs-target={`#kt_modal_${message.messageType}_${message.id}`}
                //       >
                //       <img className={clsx("card-rounded", isTabletOrMobile ? "w-200px" : "w-300px")} src={message.mediaUrl} />
                //     </div>
                //     <div className="overlay-layer card-rounded bg-dark bg-opacity-10">
                //       <img src={toAbsoluteUrl('/media/svg/misc/video-play.svg')} className="h-60px" alt=""/>
                //     </div>
                //   </div>
                // </div>

                <div
                  className={clsx(
                    "image-input",
                    isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
                  )}
                  // className="image-input mw-lg-300px"
                  data-kt-image-input="true"
                  data-bs-toggle="modal"
                  data-bs-target={`#kt_modal_${message.messageType}_${message.id}`}
                >
                  {/* begin::Preview existing video */}
                  <div
                    className="btn bi bi-play-circle fs-5x w-300px position-absolute top-50 start-50 translate-middle"
                    style={{ color: "white"}}
                    // style={{ color: "white", zIndex: 999 }}
                  ></div>
                  <video
                    className={clsx(isTabletOrMobile ? "w-200px" : "w-300px")}
                    // className="w-300px"
                    src={message.mediaUrl}
                    style={{ borderRadius: "8px" }}
                  ></video>
                  {/* end::Preview existing video */}
                </div>
              )}
              {/***
               * Caption
               */}
              {message.textContent && message.textContent !== "" && (
                <div
                  className={clsx(
                    "p-3 rounded",
                    `${bgChat}`,
                    "fluid align-items-center",
                    isTabletOrMobile ? "mw-200px" : "mw-300px"
                    // "mw-300px"
                  )}
                  style={{ color: txChat }}
                  dangerouslySetInnerHTML={{
                    __html: convertTextStyle(message.textContent).replaceAll("\n","<br/>"),
                  }}
                ></div>
              )}
              {/***
               * End Of Caption
               */}

              {message.mediaUrl !== undefined &&
                message.id !== undefined &&
                message.filename !== undefined && (
                  <ChatFileView
                    messageType={message.messageType}
                    mediaURL={message.mediaUrl}
                    messageId={message.id}
                    mediaName={message.filename}
                    previousMedia={previousMedia}
                    nextMedia={nextMedia}
                    currentMedia={currentMedia}
                  />
                )}
            </div>
            //End Of Bubble chat
          )}

          {/* Message Type: Audio */}
          {message.messageType === "audio" && (
            <audio
              className={clsx(
                `audio-${bgChat}`,
                message.channel !== "7days" ? "audio-white" : ""
              )}
              // className={`audio-${bgChat} audio-white `}
              src={message.mediaUrl}
              controls
            ></audio>
          )}

          {/* Message Type: Location */}
          {message.messageType === "location" && (
            <div
              style={{ color: txChat }}
              className={clsx(
                "p-5 rounded fw-bold",
                `${bgChat}`,
                isTabletOrMobile ? "w-200px" : "w-300px"
                // "  w-300px"
              )}
            >
              {/* Map */}
              <a
                href={`https://maps.google.com/maps?q=${message.location?.latitude},${message.location?.longitude}&z=17`}
              >
                <div className="w-300px">
                  <MessageLocation item={message} />
                </div>
              </a>

              {/* Caption : Location Name + Address*/}
              <div
                className={clsx(
                  "rounded",
                  `${bgChat}`,
                  "fluid align-items-center"
                )}
                style={{ color: txChat, paddingTop: "5px" }}
              >
                {message.locationName && (
                  <div>
                    <span
                      className="bi bi-geo-alt-fill"
                      style={{ paddingRight: "5px" }}
                    />
                    {message.locationName}
                  </div>
                )}
                {message.locationAddress && (
                  <div>{message.locationAddress}</div>
                )}
              </div>
            </div>
          )}

          {/* Message Type: Template */}
          {message.messageType === "template" && (
            <div
              style={{ color: txChat }}
              className={clsx(
                "p-5 rounded fw-bold",
                `${bgChat}`,
                isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
                // " fw-bold mw-lg-400px"
              )}
              dangerouslySetInnerHTML={{
                __html: convertTextStyle(message.textContent).replaceAll("\n","<br/>"),
              }}
            >
              {/* TODO for header */}
              {/* TODO for footer */}
              {/* TODO for button */}
            </div>
          )}

        </div>

        {/* Message Timestamp and Status >> Status Message: submitted,sent,delivered, read , failed (search for retry message) */}
        <div
          className={clsx(
            `${msgtype === "in" ? "start" : "ms-3"}`,
            `d-flex justify-content-${msgtype === "in" ? "start" : "end"}`
          )}
        >
          <span className="d-flex flex-row text-muted fs-7 mb-1">
            {msgtype !== "in" &&
              message.status === "submitted" && (
                <i
                  className={clsx("bi bi-clock", isTabletOrMobile ? "fs-1" : "fs-3")}
                ></i>
            )}
            {msgtype !== "in" &&
              message.status === "sent" && (
                <i
                  className={clsx("bi bi-check2", isTabletOrMobile ? "fs-1" : "fs-3")}
                ></i>
            )}
            {msgtype !== "in" &&
              message.status === "delivered" && (
                <i
                  className={clsx("bi bi-check2-all", isTabletOrMobile ? "fs-1" : "fs-3")}
                ></i>
              )}
            {msgtype !== "in" &&
              (message.status === "read" && 
                <i
                  className={clsx("bi bi-check2-all text-primary", isTabletOrMobile ? "fs-1" : "fs-3")}
                ></i>
              )}
            {ChatTimeFromFirebase(message.createdAt.seconds)}
          </span>
        </div>
      </div>

      {/* Outbound Avatar */}
      {msgtype !== "in" && (
        <div
          // className="symbol  symbol-35px symbol-circle"
          className={clsx(
            "symbol symbol-circle",
            isTabletOrMobile ? "symbol-20px" : "symbol-35px"
          )}
          style={{ padding: isTabletOrMobile ? "2px" : "5px" }}
        >
          <img
            alt="Pic"
            src={userAvatar.src}
          />
        </div>
      )}

    </div>
  );
};

export default FriendChatMessage;