import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as lc from "../../../../modules/localstorage/index";
import { useFirestoreTable } from "../../utils/useFirestoreTable";

type useTextFilter<T> = {
  type: "text";
  label: string;
  field: string;
  value: any;
};

type useNumberFilter<T> = {
  type: "number";
  label: string;
  field: string;
  value: any;
};

type useOptionFilter<T> = {
  type: "option";
  label: string;
  field: string;
  options: { label: string; value: any }[];
  value: any;
};

type useDateRangeFilter<T> = {
  type: "date-range";
  label: string;
  field: string;
  value: any;
};

type useBooleanFilter<T> = {
  type: "boolean";
  label: string;
  field: string;
  value: any;
};

type useGreaterFilter<T> = {
  type: "greater";
  label: string;
  field: string;
  value: any;
};

type useLessFilter<T> = {
  type: "less";
  label: string;
  field: string;
  value: any;
};

type useGreaterThanFilter<T> = {
  type: "greater-than";
  label: string;
  field: string;
  value: any;
};

type useLessThanFilter<T> = {
  type: "less-than";
  label: string;
  field: string;
  value: any;
};

type useExceptionFilter<T> = {
  type: "exception";
  label: string;
  field: string;
  value: any;
};

type useFilter<T> =
  | useTextFilter<T>
  | useNumberFilter<T>
  | useOptionFilter<T>
  | useDateRangeFilter<T>
  | useBooleanFilter<T>
  | useGreaterFilter<T>
  | useLessFilter<T>
  | useGreaterThanFilter<T>
  | useLessThanFilter<T>
  | useExceptionFilter<T>;

type FilterModalProps<T> = {
  show: boolean;
  onHide: (filters: useFilter<T>[]) => void;
  filters: useFilter<T>[];
  resetFilters: (filters: useFilter<T>[]) => void;
  applyFilters: (filters: useFilter<T>[]) => void;
};

function FilterModal<T>({
  show,
  onHide,
  filters,
  resetFilters,
  applyFilters,
}: FilterModalProps<T>) {
  const { t } = useTranslation();
  const [localFilters, setLocalFilters] = useState<useFilter<T>[]>(filters);
  const {
    data: itemsData = { items: [] },
    isLoading,
    isFetching,
    TableState,
    refreshData,
    selectedItems,
    setSelectedItems,
    updateTableState,
  } = useFirestoreTable();

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterChange = (
    index: number,
    key: keyof useFilter<T>,
    value: any
  ) => {
    const updatedFilters = [...localFilters];
    updatedFilters[index] = { ...updatedFilters[index], [key]: value };
    setLocalFilters(updatedFilters);
  };

  const handleApplyFilters = () => {
    applyFilters(localFilters);
  };

  const handleResetFilters = () => {
    resetFilters(
      localFilters.map((item) => {
        if (item.type === "option") {
          return {
            ...item,
            value: "option-title",
          };
        } else {
          return {
            ...item,
            value: undefined,
          };
        }
      })
    );
    setLocalFilters(
      localFilters.map((item) => {
        if (item.type === "option") {
          return {
            ...item,
            value: "option-title",
          };
        } else {
          return {
            ...item,
            value: undefined,
          };
        }
      })
    );
    onHide(localFilters);
  };

  console.log(localFilters, "check locaf");

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide(localFilters);
        setLocalFilters(TableState.useFilter as any);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Common.Filter")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column row-gap-2 mt-0 pt-5">
        {localFilters.map((filter, index) => (
          <Form.Group key={index}>
            <Form.Label className="fw-bolder mb-0 pb-1">
              {filter.label}
            </Form.Label>
            {filter.type === "text" && (
              <Form.Control
                type="text"
                value={filter?.value !== undefined ? filter?.value : ""}
                onChange={(e: any) =>
                  handleFilterChange(index, "value", e.target.value)
                }
              />
            )}
            {filter.type === "number" && (
              <Form.Control
                type="number"
                value={filter?.value !== undefined ? filter?.value : 0}
                onChange={(e) =>
                  handleFilterChange(index, "value", Number(e.target.value))
                }
              />
            )}
            {filter.type === "option" && (
              <Form.Control
                as="select"
                value={filter?.value !== undefined ? filter?.value : ""}
                onChange={(e) => {
                  handleFilterChange(index, "value", e.target.value);
                }}
              >
                {(filter as useOptionFilter<T>).options.map(
                  (option, optIndex) => (
                    <option key={optIndex} value={option.value}>
                      {option.label}
                    </option>
                  )
                )}
              </Form.Control>
            )}
            {filter.type === "date-range" && (
              <>
                <Form.Control
                  type="date"
                  value={
                    filter?.value !== undefined
                      ? (filter?.value as [string, string])[0]
                      : ""
                  }
                  onChange={(e) =>
                    handleFilterChange(index, "value", [
                      e.target.value,
                      (filter?.value as [string, string])[1],
                    ])
                  }
                />
                <Form.Control
                  type="date"
                  value={
                    filter?.value !== undefined
                      ? (filter?.value as [string, string])[1]
                      : ""
                  }
                  onChange={(e) =>
                    handleFilterChange(index, "value", [
                      (filter?.value as [string, string])[0],
                      e.target.value,
                    ])
                  }
                />
              </>
            )}
            {filter.type === "boolean" && (
              <Form.Control
                as="select"
                value={
                  filter.value !== undefined && (String(filter.value) as any)
                }
                onChange={(e) =>
                  handleFilterChange(index, "value", e.target.value)
                }
              >
                <option value="true_boolean">True</option>
                <option value="false_boolean">False</option>
              </Form.Control>
            )}
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleResetFilters}>
          {t("Common.ResetFilter")}
        </Button>
        <Button variant="primary" onClick={handleApplyFilters}>
          {t("Common.ApplyFilter")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterModal;
