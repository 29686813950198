import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import WABAAcount from "../../../models/WABAccount"
import InitialState, { WABAAccountListAction } from "./WABAAccountRedux";

const initialState: InitialState = {
  listWABAAccount: [],
  countWABAAccount: 0,
  currentPageWABAAccount: 0,
  maxPageWABAAccount: 0,
  arrayWABAAccountPagination: [],
  WABAAccountData: {},
  navCount: 1,
};

export const WABAAccountSlice = createSlice({
  name: WABAAccountListAction,
  initialState: initialState,
  reducers: {
    setListWABAAccount: (state, action: PayloadAction<WABAAcount[]>) => {
      state.listWABAAccount = action.payload;
      state.countWABAAccount = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayWABAAccountPagination = arr;
      } else {
        state.arrayWABAAccountPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayWABAAccountPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayWABAAccountPagination = action.payload;
    },

    setCountWABAAccount: (state, action: PayloadAction<number>) => {
      state.countWABAAccount = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageWABAAccount = action.payload;
    },

    setMaxPageWABAAccount: (state, action: PayloadAction<number>) => {
      state.maxPageWABAAccount = action.payload;
    },

    updateListWABAAccount: (state, action: PayloadAction<WABAAcount>) => {
      let newListContact: WABAAcount[] = [];
      const newWABAAccount = action.payload;
      newListContact.push(newWABAAccount);

      for (let index = 0; index < state.listWABAAccount.length; index++) {
        if (state.listWABAAccount[index].id === newWABAAccount.id) {
          console.log(
            "Collab ID from Notif: " + state.listWABAAccount[index].id
          );
        } else {
          newListContact.push(state.listWABAAccount[index]);
        }
      }

      console.log("NEW LIST CONTACT", newListContact);
      state.countWABAAccount = newListContact.length;
    },

    deleteItemListWABAAccount: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listWABAAccount.filter(
        (data) => data.id !== action.payload
      );
      state.listWABAAccount = newDataCollab;
      state.countWABAAccount = newDataCollab.length;
    },

    setWABAAccountData: (state, action: PayloadAction<WABAAcount>) => {
      state.WABAAccountData = action.payload;
    },

    setNavCount: (state, action: PayloadAction<number>) => {
      state.navCount = action.payload;
    },

    cleanReduxWABAAccount: (state, action: PayloadAction<boolean>) => {
      state.listWABAAccount = [];
      state.countWABAAccount = 0;
      state.currentPageWABAAccount = 0;
      state.maxPageWABAAccount = 0;
      state.WABAAccountData = {};
      state.navCount = 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListWABAAccount,
  setArrayWABAAccountPagination,
  setMaxPageWABAAccount,
  setCountWABAAccount,
  setWABAAccountData,
  updateListWABAAccount,
  deleteItemListWABAAccount,
  setCurrentPage,
  cleanReduxWABAAccount,
  setNavCount,
} = WABAAccountSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default WABAAccountSlice.reducer;
