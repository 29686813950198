interface BR_COLOR {
  MARKETING: string,
  UTILITY: string,
  AUTHENTICATION: string,
  SERVICE: string,
}

interface BG_COLOR {
  MARKETING: string,
  UTILITY: string,
  AUTHENTICATION: string,
  SERVICE: string,
}

const BR_COLOR: BR_COLOR = {
  MARKETING : 'rgba(83, 242, 27, 1)',
  UTILITY : 'rgba(233, 176, 27, 1)',
  AUTHENTICATION : 'rgb(218, 8, 6)',
  SERVICE : 'rgba(24, 159, 251, 1)'
}

const BG_COLOR: BG_COLOR = {
  MARKETING : 'rgba(83, 242, 27, 1)',
  UTILITY : 'rgba(233, 176, 27, 1)',
  AUTHENTICATION : 'rgb(218, 8, 6)',
  SERVICE : 'rgba(24, 159, 251, 1)',
}  

export {
  BR_COLOR,
  BG_COLOR,
}