import { Fragment, useEffect, useState } from "react";
import styles from "./Summary.module.css";
import { useTranslation } from "react-i18next";

interface SummaryDetail {
  authentication: number;
  marketing: string | number;
  service: string | number;
  utility: string | number;
}

interface ISummary {
  name: string;
  total: string;
  detail: SummaryDetail;
}

interface IProps {
  selectedCategories: string[];
  data: any;
}

const baseTemplateSummaryData = [
  {
    name: "Total Summary Category",
    total: "",
    detail: {
      authentication: 0,
      marketing: 0,
      service: 0,
      utility: 0,
    },
  },
  {
    name: "Total Cost",
    total: "",
    detail: {
      authentication: 0,
      marketing: 0,
      service: 0,
      utility: 0,
    },
  },
];

const SummaryComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [summaries, setSummaries] = useState<ISummary[]>(
    baseTemplateSummaryData
  );

  // Side Effect For Handler When Selected Categories Is Changed
  useEffect(() => {
    const categories = [...props.selectedCategories];
    setSelectedCategories(categories);
  }, [props.selectedCategories]);

  useEffect(() => {
    const baseTemplateSummaryData = [
      {
        name: t("Home.Summary.TotalSummaryCategory"),
        total: "",
        detail: {
          authentication: 0,
          marketing: 0,
          service: 0,
          utility: 0,
        },
      },
      {
        name: t("Home.Summary.TotalCost"),
        total: "",
        detail: {
          authentication: 0,
          marketing: 0,
          service: 0,
          utility: 0,
        },
      },
    ];

    if (props.data && props.data.length > 0) {
      // Calculate and Create Summary For Analytic Data
      const summaryData = baseTemplateSummaryData;
      for (const data of props.data) {
        const conversationCategory: string = data.conversationCategory;
        const totalConversation = data.total_conv ?? data.waba_total_conv;
        const totalCost = data.total_cost ?? data.waba_total_cost;

        if (conversationCategory === "authentication") {
          summaryData[0].detail.authentication += totalConversation;
          summaryData[1].detail.authentication += totalCost;
        } else if (conversationCategory === "marketing") {
          summaryData[0].detail.marketing += totalConversation;
          summaryData[1].detail.marketing += totalCost;
        } else if (conversationCategory === "service") {
          summaryData[0].detail.service += totalConversation;
          summaryData[1].detail.service += totalCost;
        } else if (conversationCategory === "utility") {
          summaryData[0].detail.utility += totalConversation;
          summaryData[1].detail.utility += totalCost;
        }
      }
      summaryData[0].total = `${(
        summaryData[0].detail.authentication +
        summaryData[0].detail.marketing +
        summaryData[0].detail.service +
        summaryData[0].detail.utility
      ).toLocaleString()}`;
      summaryData[1].total = `IDR ${(
        summaryData[1].detail.authentication +
        summaryData[1].detail.marketing +
        summaryData[1].detail.service +
        summaryData[1].detail.utility
      ).toLocaleString()}`;

      setSummaries(summaryData);
    } else {
      setSummaries(baseTemplateSummaryData);
    }
  }, [props.data]);

  return (
    <Fragment>
      <div
        className="container pt-lg-5 px-5 row mx-auto "
      >
        {summaries.map((summary, index) => (
          <div key={index} className="col-12 col-md-6 py-3 py-lg-0">
            <div className={`${styles["card-summary"]}`}>
              <div className={styles["summary"]}>
                <span>{summary.total}</span>
              </div>
              <div className={`${styles["summary-label"]}`}>
                <span>{summary.name}</span>
              </div>
              <div className="row">
                {selectedCategories.map((category) => (
                  <div
                    key={category}
                    className={`${styles["summary-detail"]} col-12 col-md-6`}
                  >
                    {category === "authentication" && (
                      <>
                        <span className="mr-auto">
                          {t("Home.ConversationCategories.Authenticating")}
                        </span>
                        <span className="ml-auto">
                          {index === 0
                            ? summary.detail.authentication
                            : `IDR ${summary.detail.authentication.toLocaleString()}`}
                        </span>
                      </>
                    )}
                    {category === "marketing" && (
                      <>
                        <span className="mr-auto">
                          {t("Home.ConversationCategories.Marketing")}
                        </span>
                        <span className="ml-auto">
                          {index === 0
                            ? summary.detail.marketing
                            : `IDR ${summary.detail.marketing.toLocaleString()}`}
                        </span>
                      </>
                    )}
                    {category === "service" && (
                      <>
                        <span className="mr-auto">
                          {t("Home.ConversationCategories.Service")}
                        </span>
                        <span className="ml-auto">
                          {index === 0
                            ? summary.detail.service
                            : `IDR ${summary.detail.service.toLocaleString()}`}
                        </span>
                      </>
                    )}
                    {category === "utility" && (
                      <>
                        <span className="mr-auto">
                          {t("Home.ConversationCategories.Utility")}
                        </span>
                        <span className="ml-auto">
                          {index === 0
                            ? summary.detail.utility
                            : `IDR ${summary.detail.utility.toLocaleString()}`}
                        </span>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default SummaryComponent;
