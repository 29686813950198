import clsx from "clsx";
import { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../../../setup/redux/store";
import ModalDeleteSelected from "../../../../../../modules/modal/modalDeleteSelected";
import * as productList from "../../../../../../modules/product/productList/ProductListSlice";
import ModalImportProduct from "../../../product-import/ModalImportProduct";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteSelectedProducts } from "../../core/_requests";

const ProductGrouping = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selected, clearSelected } = useListView();
  const [isSelectedHasProduct, setIsSelectedHasProduct] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const { state, updateState } = useQueryRequest();
  const { refetch } = useQueryResponse();
  const [isValidSelected, setIsValidSelected] = useState(true);
  let listProductData: any = [];
  listProductData = useSelector(
    (state: RootState) => state.ProductList.listProduct
  );

  const checkSelectedItems = () => {
    let numberOfProducts: any = [];
    for (let index = 0; index < selected.length; index++) {
      const geProductData = listProductData.find((product: any) => {
        return product.id === selected[index];
      });
      numberOfProducts = [...numberOfProducts, geProductData.numberOfProducts];
    }
    const isValid = numberOfProducts.every(checkNumberOfProducts);

    if (isValid) {
      setIsSelectedHasProduct(false);
    } else {
      setIsSelectedHasProduct(true);
    }

    setIsDeleteModalOpen(true);
  };

  function checkNumberOfProducts(numberOfProducts: number) {
    return numberOfProducts <= 0;
  }

  const deleteSelectedItems = useMutation(
    () => deleteSelectedProducts(selected),
    {
      onSuccess: () => {
        /***
         * TODO Request Delete To Server Side
         */
        for (let index = 0; index < selected.length; index++) {
          dispatch(
            productList.deleteItemListProduct(selected[index]!.toString())
          );
        }
        updateState({
          sort: state.sort === "delete" ? "asc" : "delete",
          items_per_page: state.items_per_page,
          page: 1,
          action: "noAction",
        });
        clearSelected();
      },
    }
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const renderConfirmationModal = (id: string, show: boolean = false) => {
    return (
      <BootstrapModal centered id={id ?? "confimationModal"} show={show}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>
            {t("ImportProduct.Title")}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="fs-5 fw-bolder text-center">
            <i
              className="bi bi-check-lg text-primary"
              style={{
                fontSize: "4rem",
              }}
            ></i>
            <p>{t("IC.Alert.Success")}</p>
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <button
            className="btn btn-primary mx-2"
            data-bs-dismiss="modal"
            onClick={() => setShowConfirmModal(false)}
          >
            {t("BS.Button.Close")}
          </button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  return (
    <>
      <div
        className={clsx(
          `d-flex justify-content-end align-items-center me-3 ${
            isTabletOrMobile ? "w-100" : ""
          }`
        )}
      >
        <div className="fw-bolder me-5">
          {!isTabletOrMobile && selected.length > 0 ? (
            <span className="me-2">
              {selected.length} {t("Storefront.Info.Selected")}
            </span>
          ) : null}
        </div>
        {!isTabletOrMobile && (
          <button
            type="button"
            data-testid="refresh-product-list"
            className="btn btn-light-primary me-2"
            onClick={() => {
              dispatch(productList.cleanReduxProductList(true));
              refetch();
            }}
          >
            <i className="bi bi-arrow-clockwise"></i>
          </button>
        )}
        {!isTabletOrMobile && (
          <button
            type="button"
            data-testid="import-product-list"
            className="btn btn-light-primary me-2"
            onClick={() => {
              setIsImportModalOpen(true);
            }}
          >
            {t("AddNewProduct.Menu.Title.ImportProduct")}
          </button>
        )}

        {!isTabletOrMobile && (
          <>
            {selected.length > 0 ? (
              <button
                data-testid="delete-selected"
                name="btn-deleted"
                id="btn-deleted"
                type="button"
                className="btn btn-danger"
                disabled
                onClick={() => {
                  checkSelectedItems();
                }}
              >
                {t("StorefrontList.Button.DeleteSelected")}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger"
                disabled
                onClick={
                  async () => {
                    console.log("Deleted");
                  } //delete selected
                }
              >
                {t("StorefrontList.Button.DeleteSelected")}
              </button>
            )}
          </>
        )}
      </div>

      {isImportModalOpen && (
        <ModalImportProduct
          id={"importProductCsv"}
          isModalOpen={isImportModalOpen}
          title={t("ImportProduct.Title")}
          // subtitle={"tes modal subtitle"}
          cancelOption={t("AddNewProduct.Button.BPI.Cancel")}
          confirmOption={t("Common.Button.Submit")}
          onConfirm={() => {
            console.log("import csv modal success");
            setIsImportModalOpen(false);
            setShowConfirmModal(true);
            dispatch(productList.cleanReduxProductList(true));
            refetch();
          }}
          onCancel={() => setIsImportModalOpen(false)}
        />
      )}

      {renderConfirmationModal(
        "confirmationModal",
        !isImportModalOpen && showConfirmModal
      )}

      <ModalDeleteSelected
        id="confirmDeleteSelected"
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={
          isSelectedHasProduct
            ? t("StorefrontList.Alert.Delete.Storefront.Title")
            : t("Warning")
        }
        body={
          isSelectedHasProduct
            ? t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontHasProduct"
              )
            : t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontDoesn'tHasProduct"
              )
        }
        confirmText={
          isSelectedHasProduct
            ? undefined
            : t("Storefront.Column.Button.Delete")
        }
        cancelText={
          isSelectedHasProduct
            ? t("Storefront.Button.Back")
            : t("Alert.Button.Cancel")
        }
        onConfirm={async () => {
          await deleteSelectedItems.mutateAsync();
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export { ProductGrouping };
