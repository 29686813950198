import moment from "moment";
import * as AccountService from "../../../../../../../../db/serviceAccount";
import { useParams } from "react-router-dom";
import Input from "../../../../../../../components/input/Input";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { useFirestoreTable } from "../../context/TableContext";
import Account from "../../../../../../../models/Account";
import { useTranslation } from "react-i18next";
type MarketplaceFormEditType = {};
const MarketplaceFormEdit: React.FC<MarketplaceFormEditType> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { accountFormData: data, setAccountFormData } = useFirestoreTable();

  useEffect(() => {
    const initAccountFormData = async (id: string) => {
      const account = (await AccountService.getAccountByID(id)) as Account;
      console.log(account);
      setAccountFormData(account);
    };
    if (params.id) {
      initAccountFormData(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    console.log("RISU", { data });
  }, [data]);

  return (
    <div className="container-fluid ms-10 mt-10 overflow-scroll">
      <div className="mb-10">
        <h1>{t("Marketplace.Edit.Title")}</h1>
      </div>
      <form>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.ShopName")}</label>
          <div className="w-100">
            <Input
              type="text"
              className="form-control w-50"
              value={data?.name ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, name: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.Username")}</label>
          <div className="w-100">
            <Input
              type="text"
              className="form-control w-50"
              value={data?.searchKey ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, searchKey: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.ShopURL")}</label>
          <div className="w-100">
            <Input
              type="url"
              className="form-control w-50"
              value={data?.marketplace_url ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, marketplace_url: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.Date")}</label>
          <div className="w-100">
            <Input
              type="date"
              className="form-control w-50"
              value={moment(data?.createdAt?.toMillis()).format("YYYY-MM-DD")}
              // onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.District")}</label>
          <div className="w-100">
            <Input
              // debounce={true}
              // debounceDelay={1000}
              type="text"
              className="form-control w-50"
              value={data?.district ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, district: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.City")}</label>
          <div className="w-100">
            <Input
              type="text"
              className="form-control w-50"
              value={data?.city ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, city: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.Province")}</label>
          <div className="w-100">
            <Input
              type="text"
              className="form-control w-50"
              value={data?.province ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, province: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
        <div className="mb-5 d-flex">
          <label className="form-label w-25 fs-5 align-items-center">{t("Marketplace.Edit.Warehouse")}</label>
          <div className="w-100">
            <Input
              type="text"
              className="form-control w-50"
              value={data?.warehouse ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountFormData({ ...data, warehouse: e.currentTarget?.value ?? "" })}
              required
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MarketplaceFormEdit;
