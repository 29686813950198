import { Campaign, CampaignMessage } from "../../../models/Campaign";

interface InitialState {
  // Campaign Report
  listCampaignReport: Campaign[];
  countCampaignReport: number;
  currentPageCampaignReport: number;
  maxPageCampaignReport: number;
  arrayCampaignReportPagination: string[];
  selectedCampaign: Campaign;
  selectedCampaignID: string;
  selectedRepTime?: string;
}
const CampaignReportAction: string = "CampaignReportAction";

export type unsubFN = () => void;

export default InitialState;
export { CampaignReportAction };
