import { DocumentReference } from "firebase/firestore";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { FirestoreFilterValue } from "../../../../hooks/useFirestoreData";
import { PageLink, PageTitle } from "../../core/PageData";
import ProcessList from "./process-list/ProcessList";

type Props = {};

const ProcessPage = (props: Props) => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Menu.Order"),
      path: "order",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle
        breadcrumbs={usersBreadcrumbs}
        contentTitle={t("ExportOrderProcess.Title")}
      >
        {t("ExportOrderProcess.Title")}
      </PageTitle>
      <ProcessList />
    </>
  );
};

export function isSameStatusValue(
  value: FirestoreFilterValue | undefined,
  compare: string | string[]
) {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value) && !Array.isArray(compare)) {
    return value === compare;
  }
  if (Array.isArray(value) && Array.isArray(compare)) {
    return value.every((item) => compare.includes(item));
  }
  if (Array.isArray(value) && !Array.isArray(compare)) {
    return value.includes(compare);
  }
  if (!Array.isArray(value) && Array.isArray(compare)) {
    if (typeof value !== "string") {
      return false;
    }
    return compare.includes(value);
  }
  return false;
}

export default ProcessPage;
