export function excerpt(s:string = "", l:number = 80) {
    const res = (s.match(new RegExp('.{' + l + '}\\S*')) || [s])[0]
    return `${res}... `
}

export function excerptFirstAndLast(string: string = "filename") {
    const strlen = string.length
    let res
    if (strlen > 20) {
        const first15 = string.substring(0, 14)
        const last5 =  string.substring(strlen - 4)
        res = `${first15} ...${last5}`
    } else {
        res = string
    }
    return res
} 