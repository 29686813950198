import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "src/setup/redux/store";
import { PageLink, PageTitle } from "../../core";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as ReduxTemplate from "../../../modules/template/redux/TemplateSlice";
import * as ReduxCampaign from "../../../modules/campaign/redux/CampaignSlice";
import { FC, useEffect, useState } from "react";
import Template, {
  templateState as ImportedTemplateState,
  templateLanguages,
} from "src/app/models/Template";
import * as servTemplate from "../../../../db/serviceTemplate";
import TemplateLanguage, {
  BodyParamLanguage,
  HeaderParamLanguage,
  templateStatus,
} from "../../../models/Language";
import { Timestamp } from "firebase/firestore";
import { InteractiveMessage } from "src/app/modules/template/redux/TemplateRedux";
import { createRef } from "../../../../db/connection";
import { UserCompanies } from "src/app/models/User";
import { LCName, getItemLC } from "src/app/modules/localstorage";
import { UploadFile as UploadFileToWaba } from "../../../../api/server/media";
import { updateOrCreateWhatsappTemplate } from "src/actions/template";
import * as ReduxTemplateList from "../../../modules/template/list/TemplateListSlice";
import "../../../../styles/css/campaign.scss";
import * as Log from "../../../../util/SDayslogger";
import { getAccountByID } from "src/db/serviceAccount";
import Account from "src/app/models/Account";
import { storage } from "src/db";
import { Loading } from "src/app/modules/util/Loading";
import ModalConfirmationMoveToOtherPage from "src/app/modules/modal/modalConfirmationMoveToOtherPage";
import AlertFailedProcess from "src/app/modules/alert/AlertFailedProcess";
import ModalConfirmation from "../../../modules/modal/modalConfirmation";
import clsx from "clsx";
import { AlertModal } from "../../../modules/template/redux/TemplateRedux";
import AlertModalDialog from "../../../modules/alert/AlertModalDialog";
import TemplateMobileIcon from "../template-create/component-template/TemplateMobileIcon";
import TemplateChannel from "./layout/TemplateChannel";
import TemplateCategory from "./layout/TemplateCategory";
import TemplateHeaderComponent from "./layout/TemplateHeader";
import TemplateProcess from "./layout/TemplateProcess";
import TemplateFooterComponent from "./layout/TemplateFooter";
import TemplateCodeDelivery from "./layout/TemplateCodeDelivery";
import TemplateCallToAction from "./layout/TemplateCallToAction";
import TemplateSubmission from "./layout/TemplateSubmission";
import { Modal } from "react-bootstrap";
import Language from "../../../models/Language";

interface TemplateData {
  [prop: string]: [value: any];
}

const TemplateUpdateCreate = () => {
  const { i18n, t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [breadcrumb, setBreadcrumb] = useState<any>([]);
  const [dataTemplate, setDataTemplate] = useState<TemplateData>({});
  const [showReassureModal, setShowReassureModal] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState("");
  const [isButtonNextDisabled, setIsButtonNextDisabled] = useState<any>();
  const {
    templateName,
    clientID,
    accountsID,
    companiesAccounts,
    templateID,
    templateNameCharacterAlert,
    templateBodyMessage,
    templateCategory,
    selectedInteractiveMessage,
    generatedTemplate,
    generatedLanguage,
    responseTemplateID,
    templateLanguageID,
    templateState,
    templateBodyParams,
    templateEmptyCustomTextAlert,
    alertNonCheckOTPTermConditions,
    alertNonCheckPackageNameTermConditions,
    alertNonCheckAppSignatureHashTermConditions,
    templateHeaderParams,
    templateUploadFileType,
    templateUploadFileName,
    templateUploadFileSize,
    templateCompaniesID,
    isloading,
    alertHeader,
    alertFooter,
    alertCTA,
    onStepHeaderOrFooter,
    alertDuplicateParamNameInBody,
    templateButton,
    templateFooter,
    templateHeader
  } = useSelector((state: RootState) => state.Template);

  const {
    campaignTemplateFinish,
    accountID: CampaignAccountID,
    clientID: CampaignClientID,
  } = useSelector((state: RootState) => state.Campaign);

  const { previewData } = useSelector(
    (state: RootState) => state.CampaignTarget
  );

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Campaign.Title.Template"),
      path: "/template/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const stateTemplate = useSelector((state: RootState) => state.Template)
  console.log(stateTemplate, 'deq')

  const ResetTemplate = async () => {
    console.log(
      stateTemplate,
      "before template state wop"
    );
    dispatch(ReduxTemplate.setTemplateHeader(""));
    dispatch(ReduxTemplate.setTemplateHeaderParams([]));
    dispatch(ReduxTemplate.setTemplateFooter(""));
    dispatch(ReduxTemplate.setTemplateBodyMessageLength(0));
    dispatch(ReduxTemplate.setTemplateBodyMessage(""));
    dispatch(ReduxTemplate.setTemplatePreviewBody(""));
    dispatch(ReduxTemplate.setTemplatePreviewHeaderText(""));
    dispatch(ReduxTemplate.setSelectedInteractiveButton(""));
    dispatch(ReduxTemplate.setTemplateButton([]));
    dispatch(ReduxTemplate.setTemplateBodyParams([]));
    dispatch(ReduxTemplate.setTemplateQuickReplies([]));
    dispatch(ReduxTemplate.setGeneratedTemplate(undefined));
    dispatch(ReduxTemplate.setOnStepHeaderOrFooter(""));
    dispatch(
      ReduxTemplate.setGeneratedLanguage({
        language: "en",
        updatedAt: Timestamp.now(),
        isActive: true,
        buttons: [],
        status: "PENDING",
      })
    );
    document.getElementById("nextStep")?.classList.remove("disabled");

    console.log(
      stateTemplate,
      "after template state wop"
    );
  };

  const onPrevNextClicked = async (direction: "prev" | "next" | "AddOn") => {
    let nextStep = 0;
    const removeDisabledClass = (stepId: any) => {
      document.getElementById(stepId)?.classList.remove("disabled");
    };

    switch (direction) {
      case "prev":
        switch (currentStep) {
          case 0:
            dispatch(ReduxTemplate.setTemplateNameAlert(false));
            dispatch(ReduxTemplate.setTemplateNameEmptyAlert(false));
            dispatch(ReduxTemplate.setTemplateSenderEmptyAlert(false));
            removeDisabledClass(`nextStep`);
            if (templateState === ImportedTemplateState.campaigncreated) {
              dispatch(ReduxCampaign.setBackFromTemplate(true));
              nav("/campaign/create");
              return;
            }
            nextStep = 0;
            break;
          case 1:
            nextStep = -1;
            break;
          case 2:
            nextStep = -1;
            ResetTemplate();
            break;
          case 3:
            nextStep = -1;
            dispatch(ReduxTemplate.setOnStepHeaderOrFooter("header"));
            break;
          case 4:
            nextStep = -1;
            break;
          case 5:
            removeDisabledClass(`nextStep`);
            nextStep = templateCategory === "authentication" ? -2 : -1;
            dispatch(ReduxTemplate.setOnStepHeaderOrFooter("footer"));
            break;
          case 6:
            nextStep = -2;
            break;
          default:
            nextStep = 0;
            break;
        }
        if (campaignTemplateFinish) {
          removeDisabledClass(`nextStep`);
        }
        break;

      case "next":
        nextStep = 1;

        switch (currentStep) {
          case 0:
            removeDisabledClass(`nextStep`);
            if (!campaignTemplateFinish) {
              let isError: boolean[] = [];

              if (!templateName) {
                dispatch(ReduxTemplate.setTemplateNameEmptyAlert(true));
                setTimeout(() => {
                  document
                    .getElementById("div-templateName")
                    ?.scrollIntoView({ behavior: "smooth" });
                }, 500);
                isError = [...isError, true];
              } else if (templateState === ImportedTemplateState.created) {
                const sameTemplateName = await servTemplate
                  .fecthTemplateByNameAndClient(templateName, clientID)
                  .then((template) => template);

                if (sameTemplateName && sameTemplateName?.length > 0) {
                  dispatch(ReduxTemplate.setTemplateNameAlert(true));
                  document
                    .getElementById("existTemplateName")
                    ?.classList.remove("d-none");
                  isError = [...isError, true];
                }

                if (templateNameCharacterAlert) {
                  setTimeout(() => {
                    document
                      .getElementById("div-templateName")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }, 500);
                  isError = [...isError, true];
                }
              }

              if (accountsID.length <= 0) {
                dispatch(ReduxTemplate.setTemplateSenderEmptyAlert(true));
                document.getElementById("emptyTemplateSender")!.hidden = false; //periksa
                if (isError.length < 1) {
                  setTimeout(() => {
                    document
                      .getElementById("div-templateSender")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }, 500);
                }
                isError = [...isError, true];
              }

              if (isError.length > 0) return;
            }
            break;

          case 1:
            if (!templateCategory) {
              dispatch(ReduxTemplate.setTemplateEmptyCategoryAlert(true));
              return;
            } else if (
              templateCategory === "interactive" &&
              selectedInteractiveMessage === ""
            ) {
              document
                .getElementById("danger-interactive")
                ?.classList.remove("d-none");
              return;
            } else {
              dispatch(ReduxTemplate.setTemplateEmptyCategoryAlert(false));
            }
            dispatch(ReduxTemplate.setOnStepHeaderOrFooter("header"));
            break;

          case 2:
            break;

          case 3:
            dispatch(ReduxTemplate.setTemplateNameAlert(false));
            removeDisabledClass(`nextStep`);
            dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(false));

            if (templateBodyMessage === "") {
              //(Padha) Periksa
              dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(true));
              return;
            }

            let emptyCustomText;
            if (templateBodyParams.length > 0) {
              //Periksa
              emptyCustomText = checkEmptyCustomText(); //Fungsine gaonok
            }

            let templateBodyWithoutParam = templateBodyMessage.replace(
              //(Padha) Periksa
              /{{[^}}]*}}/g,
              ""
            );
            if (templateBodyWithoutParam === "") {
              dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(true));
            }
            dispatch(ReduxTemplate.setOnStepHeaderOrFooter("footer"));

            if (emptyCustomText?.includes(true)) return;
            break;

          case 4:
            nextStep = 2;
            break;

          case 5:
            if (templateCategory !== "authentication") {
              if (campaignTemplateFinish) {
                nav("/campaign/create");
                return;
              }
            }
            break;

          case 6:
            nextStep = -1;
            break;

          default:
            break;
        }

        if (campaignTemplateFinish) {
          removeDisabledClass(`nextStep`);
        }
        break;

      default:
        removeDisabledClass(`nextStep`);
        nextStep = 1;
        break;
    }

    document.getElementById(`prevStep`)?.classList.add("disabled");
    await sleep(200);
    document.getElementById(`step_${currentStep}`)?.classList.add("disabled");
    document.getElementById(`prevStep`)?.classList.remove("disabled");
    setCurrentStep(currentStep + nextStep);
  };

  const modalClose = () => {
    setShowReassureModal(false);
  };

  const checkEmptyCustomText = () => {
    //check if in reduxTemplateBodyParams include paramtype customText and textContent is empty
    let customBodyParams: any = [];
    templateBodyParams.forEach((param: any) => {
      if (param.paramType === "customText" && param.textContent === "") {
        customBodyParams.push(true);
      }
      if (param.paramType === "customText" && param.textContent !== "") {
        customBodyParams.push(false);
      }
    });
    if (customBodyParams.length > 0) {
      dispatch(
        ReduxTemplate.setTemplateEmptyCustomtextMessageAlert(customBodyParams)
      );
    }
    return customBodyParams;
  };

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const onCampaignChanges = (params: { key: string; value: any }[]) => {
    let data: TemplateData = { ...dataTemplate };
    params.forEach((param) => {
      if (
        (param.key === "addHeaderAndFooter" && param.value === "4") ||
        (param.key === "addCallToAction" && param.value === "6")
      ) {
        onPrevNextClicked("AddOn");
      } else {
        data = { ...data, [param.key]: param.value };
      }
    });
    setDataTemplate(data);
  };

  const mapDataToObject = async (
    dataCampaign: TemplateData,
    callback: (templateObj: Template, languageObj: TemplateLanguage) => void
  ) => {
    let templateObj: Template = {};
    let languageObj: TemplateLanguage = {};
    templateObj = generatedTemplate;
    languageObj = generatedLanguage;

    if (
      languageObj &&
      languageObj.header &&
      languageObj.header.type &&
      languageObj.header.type === "text" &&
      (!languageObj.header.text ||
        (languageObj.header.text && languageObj.header.text === ""))
    ) {
      languageObj = Object.fromEntries(
        Object.entries(languageObj).filter(([key]) => !key.includes("header"))
      );
    }

    if (
      languageObj &&
      languageObj.header &&
      languageObj.header.type &&
      languageObj.header.type === "media" &&
      (!languageObj.header.headerURL ||
        (languageObj.header.headerURL && languageObj.header.headerURL === ""))
    ) {
      languageObj = Object.fromEntries(
        Object.entries(languageObj).filter(([key]) => !key.includes("header"))
      );
    }
    if (templateState === ImportedTemplateState.created) {
      languageObj = {
        ...languageObj,
        createdAt: new Date(),
      };
      templateObj = {
        ...templateObj,
        createdAt: Timestamp.now(),
        priority: 0,
      };
    }
    if (templateCategory === "interactive" && selectedInteractiveMessage) {
      templateObj = {
        ...templateObj,
        interactiveType:
          selectedInteractiveMessage === InteractiveMessage.List
            ? "list"
            : "button",
      };
    }

    if (languageObj && languageObj.buttons && languageObj.buttons.length > 0) {
      const findStaticButton = languageObj.buttons.find(
        (btn) => btn.type === "URL" && btn.urlType === "STATIC"
      );
      if (findStaticButton) {
        const newButtons = languageObj.buttons.map((btn) => {
          if (btn.type === "URL" && btn.urlType === "STATIC") {
            return { ...btn, dynamicValue: "", urlDynamic: "" };
          } else {
            return btn;
          }
        });
        languageObj = {
          ...languageObj,
          buttons: newButtons,
        };
      }
    }

    callback(templateObj, languageObj);
  };

  const generateDataLanguage = (
    key: string,
    value: any,
    paramcount: number,
    filename: string,
    bodyParams: BodyParamLanguage[] | undefined,
    headerParams: HeaderParamLanguage[] | undefined
  ) => {
    console.log(generatedLanguage, "ini dijalankan 2 bruu")
    let languageTemplate = { ...generatedLanguage };
    if (
      generatedLanguage &&
      generatedLanguage !== "" &&
      generatedLanguage !== null
    ) {
      if (generatedLanguage["header"]) {
        languageTemplate["header"] = generatedLanguage["header"];
      }
      if (generatedLanguage["buttons"]) {
        languageTemplate["buttons"] = generatedLanguage["buttons"];
      }
      if (generatedLanguage["footer"]) {
        languageTemplate["footer"] = generatedLanguage["footer"];
      }
      if (generatedLanguage["addSecurityRecommendation"]) {
        languageTemplate["addSecurityRecommendation"] =
          generatedLanguage["addSecurityRecommendation"];
      }
      if (generatedLanguage["code_expiration_minutes"]) {
        languageTemplate["code_expiration_minutes"] =
          generatedLanguage["code_expiration_minutes"];
      }
    }

    if (!languageTemplate.language) {
      if (templateLanguageID && templateLanguageID !== "") {
        languageTemplate.language = templateLanguageID;
      } else {
        languageTemplate.language = i18n.language;
      }
    }
    if (
      templateState === ImportedTemplateState.created ||
      templateState === ImportedTemplateState.campaigncreated
    ) {
      if (!languageTemplate.createdAt) {
        languageTemplate.createdAt = Timestamp.now();
      }
    }

    if (!languageTemplate.updatedAt) {
      languageTemplate.updatedAt = Timestamp.now();
    }
    if (!languageTemplate.isActive) {
      languageTemplate.isActive = true;
    }
    if (bodyParams) {
      if (bodyParams.length > 0) {
        languageTemplate.bodyParam = bodyParams;
      } else {
        languageTemplate = Object.fromEntries(
          Object.entries(languageTemplate).filter(
            ([key]) => !key.includes("bodyParam")
          )
        );
      }
    }

    if (headerParams && headerParams.length > 0) {
      languageTemplate.headerParam = headerParams;
    }
    if (key === "body") {
      languageTemplate.body = value;
      languageTemplate.bodyParamCount = paramcount;
    } else if (key === "headerText") {
      if (!languageTemplate.header) {
        languageTemplate.header = { text: value };
      } else {
        let header = { ...languageTemplate.header };
        header.text = value;
        languageTemplate.header = header;
      }
      if (headerParams && headerParams.length > 0) {
        if (!languageTemplate.header) {
          languageTemplate.header = {
            paramCount: paramcount,
            headerParam: headerParams,
          };
        } else {
          let header = { ...languageTemplate.header };
          header.paramCount = paramcount;
          header.headerParam = headerParams;
          languageTemplate.header = header;
        }
      } else {
        let header = { ...languageTemplate.header };
        if (header && header.paramCount && header.headerParam) {
          header.paramCount = 0;
          header.headerParam = [];
          languageTemplate.header = header;
        }
      }
    } else if (key === "headerType") {
      if (!languageTemplate.header) {
        languageTemplate.header = { type: value };
      } else {
        if (languageTemplate.header && languageTemplate.header !== value) {
          languageTemplate.header = { type: value };
        } else {
          let header = { ...languageTemplate.header };
          header.type = value;
          languageTemplate.header = header;
        }
      }
    } else if (key === "headerURL") {
      if (!languageTemplate.header) {
        languageTemplate.header = { headerURL: value };
      } else {
        let header = { ...languageTemplate.header };
        header.headerURL = value;
        languageTemplate.header = header;
      }
      let header = { ...languageTemplate.header };
      header.filename = filename;
      languageTemplate.header = header;
    } else if (key === "headerMediaType") {
      languageTemplate.header = { type: "media" };
      if (!languageTemplate.header) {
        languageTemplate.header = { mediaType: value };
      } else {
        let header = { ...languageTemplate.header };
        header.mediaType = value;
        languageTemplate.header = header;
      }
    } else if (key === "footer") {
      languageTemplate.footer = value;
      if (value === "") {
        languageTemplate = Object.fromEntries(
          Object.entries(languageTemplate).filter(
            ([key]) => !key.includes("footer")
          )
        );
      }
    } else if (key === "buttons") {
      languageTemplate.buttons = value;
    } else if (key === "remove-header") {
      if (value === "") {
        languageTemplate = Object.fromEntries(
          Object.entries(languageTemplate).filter(
            ([key]) => !key.includes("header")
          )
        );
      }
    } else if (key === "remove-buttons") {
      if (value === "") {
        languageTemplate = Object.fromEntries(
          Object.entries(languageTemplate).filter(
            ([key]) => !key.includes("buttons")
          )
        );
      }
    } else if (key === "addSecurityRecommendation") {
      languageTemplate.addSecurityRecommendation = value;
    } else if (key === "code_expiration_minutes") {
      languageTemplate.code_expiration_minutes = value;
    }

    if (!languageTemplate.status) {
      languageTemplate.status =
        templateCategory === "interactive"
          ? templateStatus.approved
          : templateStatus.pending;
    }
    console.log(languageTemplate, "check lang cuq")
    dispatch(ReduxTemplate.setGeneratedLanguage(languageTemplate));
    onCampaignChanges([{ key: "languages", value: languageTemplate }]);
    if (
      templateCompaniesID &&
      templateCompaniesID.length > 0 &&
      accountsID.length > 0
    ) {
      generateDataTemplate(
        "languages",
        languageTemplate,
        templateCompaniesID,
        accountsID
      );
    }

    return languageTemplate;
  };

  const generateDataTemplate = (
    key: string,
    value: any,
    companies?: string[],
    accountsid?: string[]
  ) => {
    console.log("ini dijalankan cuq")
    let template = { ...generatedTemplate };
    if (
      templateState === ImportedTemplateState.created ||
      templateState === ImportedTemplateState.campaigncreated
    ) {
      if (!template.createdAt) {
        template.createdAt = Timestamp.now();
      }
    }
    if (!template.updatedAt) {
      template.updatedAt = Timestamp.now();
    }
    if (!template.isActive) {
      template.isActive = true;
    }

    if (!template.companies) {
      const selectedCompaniesRef = companies?.map((company) => {
        return createRef("company", company);
      });
      template.companies = selectedCompaniesRef;
    }

    if (!template.accounts) {
      const selectedAccountRef = accountsid?.map((account) => {
        return createRef("account", account);
      });
      template.accounts = selectedAccountRef;
    }

    if (!template.client) {
      const clientRef = createRef("clients", clientID);
      template.client = clientRef;
    }

    template.templateName = templateName;
    template.category = templateCategory;
    onCampaignChanges([{ key: "template", value: template }]);
    dispatch(ReduxTemplate.setGeneratedTemplate(template));
  };

  const onSaveTemplate = async () => {
    let isRouteToList = true;
    if (templateState === ImportedTemplateState.campaigncreated) {
      isRouteToList = false;
    }
    let templateObj: Template = {};
    let languageObj: TemplateLanguage = {};
    await mapDataToObject(dataTemplate, (template, language) => {
      templateObj = template;
      languageObj = language;
    });

    if (!campaignTemplateFinish) {
      let newObjTemplate = { ...templateObj };
      let newLanguageObj = { ...languageObj } as any;
      let arrBodyParam = newLanguageObj?.failover?.bodyParam;

      if (arrBodyParam !== undefined) {
        newLanguageObj.failover = { ...newLanguageObj.failover };
        for (let index = 0; index < arrBodyParam.length; index++) {
          const element = arrBodyParam[index];
          newLanguageObj.failover.message =
            newLanguageObj.failover.message.replace(
              new RegExp(`{{${element.paramName || element.paramValue}}}`, "g"),
              `{{${index + 1}}}`
            );
        }
      }

      if (
        templateState === ImportedTemplateState.created ||
        templateState === ImportedTemplateState.campaigncreated
      ) {
        let dataTemplateLanguages: templateLanguages[] = [];
        if (
          newObjTemplate &&
          newObjTemplate.accounts &&
          newObjTemplate.accounts.length > 0
        ) {
          for (let i = 0; i < newObjTemplate.accounts.length; i++) {
            const accountRef = newObjTemplate.accounts[i];
            const dataLanguage: templateLanguages = {
              templateLanguage: i18n.language,
              templateStatus:
                templateCategory === "interactive"
                  ? templateStatus.approved
                  : templateStatus.pending,
              account: accountRef,
            };
            dataTemplateLanguages.push(dataLanguage);
          }
        }
        newObjTemplate.languages = dataTemplateLanguages;
      }

      newObjTemplate.updatedAt = Timestamp.now();
      newLanguageObj.updatedAt = new Date();
      const arrCompany: UserCompanies[] = getItemLC(LCName.CompanyList);

      if (
        dataTemplate &&
        dataTemplate.file &&
        dataTemplate.inputcontentType &&
        dataTemplate.inputFileName &&
        languageObj.header &&
        languageObj.header.mediaType
      ) {
        for (let i = 0; i < templateCompaniesID.length; i++) {
          const companyID = templateCompaniesID[i];
          const fileURL = await uploadFile(
            companyID,
            dataTemplate.inputcontentType.toString(),
            dataTemplate.inputFileName.toString(),
            dataTemplate.file,
            languageObj.header?.mediaType?.toString()
          );

          if (fileURL && fileURL !== "") {
            if (newLanguageObj && newLanguageObj.header) {
              let header = { ...newLanguageObj.header };
              header.headerURL = fileURL;
              newLanguageObj.header = header;
              dispatch(ReduxCampaign.setFileURL(fileURL));
              let saveLanguageObj = { ...newLanguageObj };
              dispatch(ReduxTemplate.setGeneratedLanguage(saveLanguageObj));
            }
            if (
              templateUploadFileType &&
              templateUploadFileType !== "" &&
              templateUploadFileName &&
              templateUploadFileName !== "" &&
              fileURL &&
              fileURL !== "" &&
              accountsID &&
              accountsID.length > 0
            ) {
              if (templateCategory !== "interactive") {
                for (let i = 0; i < accountsID.length; i++) {
                  const accountID = accountsID[i];
                  const account = companiesAccounts.find(
                    (account) => account.id === accountID
                  );
                  if (account && account.type === "whatsapp") {
                    const reponseUpload: any = await UploadFileToWaba(
                      fileURL,
                      accountID,
                      templateUploadFileType,
                      templateUploadFileName
                    );
                    if (
                      reponseUpload &&
                      reponseUpload.data &&
                      reponseUpload.data.h
                    ) {
                      let id = reponseUpload.data.h;
                      let ids: any[] = id.split("\n");
                      newLanguageObj.responseUploadMediaID = ids[0];
                      newLanguageObj.responseJSONUploadMedia =
                        JSON.stringify(reponseUpload);
                    } else {
                      const failedTextAlert = document.getElementById(
                        "failedProcessAlertNotif"
                      ) as HTMLDivElement;
                      failedTextAlert.innerHTML = t("HC.Error.FailedUpload");
                      const buttonFailedAlert = document.getElementById(
                        "triggerFailedProcess"
                      );
                      if (buttonFailedAlert) {
                        buttonFailedAlert.click();
                      }
                      isRouteToList = false;
                      return;
                    }
                  }
                }
              }
            }
          } else {
            const failedTextAlert = document.getElementById(
              "failedProcessAlertNotif"
            ) as HTMLDivElement;
            failedTextAlert.innerHTML = t("HC.Error.FailedUpload");
            const buttonFailedAlert = document.getElementById(
              "triggerFailedProcess"
            );
            if (buttonFailedAlert) {
              buttonFailedAlert.click();
            }
            isRouteToList = false;
            return;
          }
        }
      }

      if (templateState === ImportedTemplateState.update) {
        let countTodaysUpdates = 0;
        let countMonthlyUpdate = 0;

        if (languageObj.countTodaysUpdates) {
          countTodaysUpdates = languageObj.countTodaysUpdates;
        }

        if (
          !newLanguageObj.responseTemplateID ||
          newLanguageObj.responseTemplateID === ""
        ) {
          newLanguageObj.responseTemplateID = responseTemplateID;
        }

        if (languageObj.countMonthlyUpdate) {
          countMonthlyUpdate = languageObj.countMonthlyUpdate;
        }
        let processedUpdate: boolean = false;
        if (
          newLanguageObj.responseTemplateID &&
          newLanguageObj.responseTemplateID !== ""
        ) {
          const langTemplateStatus =
            templateObj.languages && templateObj.languages[0]?.templateStatus;

          if (
            templateObj.languages &&
            templateObj.languages[0]?.templateStatus === templateStatus.rejected
          ) {
            processedUpdate = true;
            newLanguageObj.countTodaysUpdates = countTodaysUpdates + 1;
            newLanguageObj.countMonthlyUpdate = countMonthlyUpdate + 1;
            newLanguageObj.status = templateStatus.rejected;
          } else {
            const failedTextAlert = document.getElementById(
              "failedProcessAlertNotif"
            ) as HTMLDivElement;
            failedTextAlert.innerHTML = t("ET.Error.EditFailed");
            const buttonFailedAlert = document.getElementById(
              "triggerFailedProcess"
            );
            if (buttonFailedAlert) {
              buttonFailedAlert.click();
            }
          }
        } else {
          processedUpdate = false;
          newLanguageObj.countTodaysUpdates = countTodaysUpdates + 1;
          newLanguageObj.countMonthlyUpdate = countMonthlyUpdate + 1;
          newObjTemplate.updatedAt = Timestamp.now();
          newLanguageObj.updatedAt = new Date();
          if (templateCategory !== "interactive") {
            let getTemplateAccountsData = undefined;
            if (newObjTemplate.id && newLanguageObj.language) {
              getTemplateAccountsData = await servTemplate.getTemplateAccounts(
                newObjTemplate.id.toString(),
                newLanguageObj.language!
              );
            }
            for (let i = 0; i < accountsID.length; i++) {
              const accountID = accountsID[i];
              const dataAccount = companiesAccounts.find(
                (item: any) => item.id === accountID && item.type === "whatsapp"
              );
              if (dataAccount) {
                await updateOrCreateWhatsappTemplate(
                  newObjTemplate,
                  newLanguageObj,
                  dataAccount.whatsappBusinessAccount_ID,
                  dataAccount.access_token,
                  false,
                  templateID,
                  accountID,
                  getTemplateAccountsData && getTemplateAccountsData.length > 0
                    ? getTemplateAccountsData
                    : undefined,
                  async (resultTemplate: Template) => {
                    if (resultTemplate) {
                      dispatch(
                        ReduxTemplateList.updateListTemplate(
                          resultTemplate as Template
                        )
                      );
                    }
                  }
                ).catch((e) => {
                  Log.SDayslogger(
                    nav,
                    "Error Update Template (updateOrCreateWhatsappTemplate): " +
                    e,
                    Log.SDLOGGER_ERROR,
                    false,
                    true
                  );
                  const failedTextAlert = document.getElementById(
                    "failedProcessAlertNotif"
                  ) as HTMLDivElement;
                  failedTextAlert.innerHTML = t("ET.Error.DefaultEditFailed");
                  const buttonFailedAlert = document.getElementById(
                    "triggerFailedProcess"
                  );
                  if (buttonFailedAlert) {
                    buttonFailedAlert.click();
                  }
                });
              }
            }
          }
        }
        if (processedUpdate && templateCategory !== "interactive") {
          let getTemplateAccountsData = undefined;
          if (newObjTemplate.id && newLanguageObj.language) {
            getTemplateAccountsData = await servTemplate.getTemplateAccounts(
              newObjTemplate.id.toString(),
              newLanguageObj.language!
            );
          }
          for (let i = 0; i < accountsID.length; i++) {
            const accountID = accountsID[i];
            const dataAccount = companiesAccounts.find(
              (item: any) => item.id === accountID
            );

            if (dataAccount) {
              await updateOrCreateWhatsappTemplate(
                newObjTemplate,
                newLanguageObj,
                dataAccount.whatsappBusinessAccount_ID,
                dataAccount.access_token,
                true,
                templateID,
                accountID,
                getTemplateAccountsData && getTemplateAccountsData.length > 0
                  ? getTemplateAccountsData
                  : undefined,
                (resultTemplate: Template) => {
                  if (resultTemplate) {
                    dispatch(
                      ReduxTemplateList.updateListTemplate(
                        resultTemplate as Template
                      )
                    );
                  }
                }
              ).catch((e) => {
                Log.SDayslogger(
                  nav,
                  "Error Update Template (updateOrCreateWhatsappTemplate): " +
                  e,
                  Log.SDLOGGER_ERROR,
                  false,
                  true
                );
                const failedTextAlert = document.getElementById(
                  "failedProcessAlertNotif"
                ) as HTMLDivElement;
                failedTextAlert.innerHTML = t("ET.Error.DefaultEditFailed");
                const buttonFailedAlert = document.getElementById(
                  "triggerFailedProcess"
                );
                if (buttonFailedAlert) {
                  buttonFailedAlert.click();
                }
              });
            }
          }
        }
      } else {
        if (templateState === "campaigncreated") {
          let accountRef = createRef("account", CampaignAccountID);
          let clientsRef = createRef("clients", CampaignClientID);
          let accountsRef = accountsID?.map((item) => {
            return createRef("account", item);
          });
          let companiesRef = companiesAccounts?.map((item) => {
            return createRef("company", item.id);
          });
          newObjTemplate["languages"] = [
            {
              templateStatus: generatedLanguage.status,
              templateLanguage: generatedLanguage.language,
              account: CampaignAccountID !== "" ? accountRef : accountsRef[0],
            },
          ];
          newObjTemplate["isActive"] = true;
          newObjTemplate["priority"] = 0;
          newObjTemplate["category"] = templateCategory;
          if (!newObjTemplate.accounts) {
            newObjTemplate["accounts"] =
              CampaignAccountID !== "" ? [accountRef] : accountsRef;
          }
          newObjTemplate["createdAt"] = Timestamp.now();
          newObjTemplate["updatedAt"] = Timestamp.now();
          newObjTemplate["templateName"] = templateName;
          newObjTemplate["companies"] = companiesRef || [];
          newObjTemplate["client"] = clientsRef;
        }

        await servTemplate
          .createTemplate(newObjTemplate)
          .then(async (templateId: any) => {
            if (templateId) {
              let arrayAccountId = newObjTemplate?.languages?.map((item) => {
                return item?.account?.id;
              });
              let dataWabaId = [] as any;
              if (arrayAccountId && arrayAccountId?.length > 0) {
                await Promise.all(
                  arrayAccountId?.map(async (x) => {
                    if (x) {
                      let res = await getAccountByID(x);
                      dataWabaId.push(res?.whatsappBusinessAccount_ID);
                    }
                  })
                );
              }

              dataWabaId?.map(async (item: any) => {
                await servTemplate
                  .addCampaignLanguage(
                    templateId,
                    `${i18n.language}_${item}`,
                    newLanguageObj
                  )
                  .then(async (languageId) => {
                    dispatch(ReduxTemplate.setTemplateLanguageID(languageId));
                    dispatch(ReduxTemplate.setTemplateID(templateId));
                    if (
                      templateState === ImportedTemplateState.campaigncreated
                    ) {
                      dispatch(ReduxCampaign.setTemplateID(templateId));
                      dispatch(ReduxCampaign.setTemplateLanguageID(languageId));
                      dispatch(
                        ReduxCampaign.setTemplateBodyParams(templateBodyParams)
                      );
                    }
                  });
              });

              const newTemplate: any = await servTemplate.get(templateId);
              let savedTemplate = (await newTemplate) as any;
              if (templateState === "campaigncreated") {
                const accountRef = createRef("account", CampaignAccountID);
                const accountsRef = accountsID.map(async (item) => {
                  return createRef("account", item);
                });
                const clientsRef = createRef("clients", CampaignClientID);
                const companiesRef = companiesAccounts?.map(async (item) => {
                  return createRef("company", item.id);
                });
                savedTemplate["languages"] = [
                  {
                    templateStatus: generatedLanguage?.status,
                    templateLanguage: generatedLanguage?.language,
                    accounts: accountRef,
                  },
                ];
                savedTemplate["isActive"] = true;
                savedTemplate["priority"] = 0;
                savedTemplate["category"] = templateCategory;
                savedTemplate["accounts"] =
                  CampaignAccountID !== "" ? [accountRef] : accountsRef;
                savedTemplate["createdAt"] = Timestamp.now();
                savedTemplate["updatedAt"] = Timestamp.now();
                savedTemplate["templateName"] = templateName;
                savedTemplate["client"] = clientsRef;
                savedTemplate["companies"] = companiesRef || [];
                savedTemplate["company"] = companiesRef || [];
              }
              if (savedTemplate) {
                if (savedTemplate.company) {
                  let company = arrCompany?.find(
                    (cmp) => cmp.id === savedTemplate.company.id
                  );
                  if (company) {
                    savedTemplate.companyName = company.companyName;
                  }
                }
                let wabaAccounts: any = [];
                if (savedTemplate?.accounts?.length > 0) {
                  savedTemplate.accounts.forEach((acc: any) => {
                    let account = companiesAccounts.find(
                      (cmp: Account) => cmp.id === acc.id
                    );
                    if (account && account.type === "whatsapp") {
                      wabaAccounts.push(account);
                    }
                  });
                }
                dispatch(ReduxTemplate.setGeneratedTemplate(savedTemplate));
                if (
                  wabaAccounts.length > 0 &&
                  templateCategory !== "interactive"
                ) {
                  const getTemplateAccountsData =
                    await servTemplate.getTemplateAccounts(
                      savedTemplate.id,
                      newLanguageObj.language!
                    );
                  wabaAccounts.forEach(async (wabaAcc: any) => {
                    const templateWABussinessID =
                      wabaAcc.whatsappBusinessAccount_ID;
                    const templateWAAccessToken = wabaAcc.access_token;

                    await updateOrCreateWhatsappTemplate(
                      savedTemplate,
                      newLanguageObj,
                      templateWABussinessID,
                      templateWAAccessToken,
                      false,
                      savedTemplate.id,
                      wabaAcc.id,
                      getTemplateAccountsData &&
                        getTemplateAccountsData.length > 0
                        ? getTemplateAccountsData
                        : undefined,
                      (resultTemplate: Template) => {
                        if (resultTemplate) {
                          dispatch(
                            ReduxTemplateList.updateListTemplate(
                              resultTemplate as Template
                            )
                          );
                        } else {
                          //Error
                        }
                      }
                    );
                  });
                }
              }
            }
          });
      }

      // Move to Template List Page
      if (isRouteToList) {
        dispatch(ReduxTemplate.cleanReduxTemplate(true));
        nav("/template/list");
      } else if (
        templateState === ImportedTemplateState.campaigncreated ||
        campaignTemplateFinish
      ) {
        dispatch(ReduxCampaign.setCampaignTemplateFinish(true));
        nav("/campaign/create");
      }
    } else {
      nav("/campaign/create");
    }
  };
  //end

  useEffect(() => {
    document
      .getElementById(`step_${currentStep}`)
      ?.classList.remove("disabled");
    document.getElementById(`step_${currentStep}`)?.click();
    document.getElementById(`step_${currentStep}`)?.classList.add("disabled");

    let newBreadcrumb = [...usersBreadcrumbs];

    switch (+currentStep) {
      case 0:
        if (templateState === ImportedTemplateState.campaigncreated) {
          setPageTitle(t("Campaign.Column.TemplateName"));
        } else {
          setPageTitle(t("CreateTemplate.TN&SS.Title"));
          if (templateName && templateName !== "") {
            const divExistTemplateName =
              document.getElementById("existTemplateName");
            const isHide = divExistTemplateName?.hasAttribute("style");
            if (isHide) {
              divExistTemplateName?.classList.add("d-none");
            }
          } else {
            const divEmptyTemplateName =
              document.getElementById("emptyTemplateName");
            if (divEmptyTemplateName) divEmptyTemplateName.hidden = true;
          }

          const divEmptyTemplateSender = document.getElementById(
            "emptyTemplateSender"
          );
          if (!accountsID || accountsID.length < 1) {
            if (divEmptyTemplateSender) divEmptyTemplateSender.hidden = true;
          }
        }
        break;

      case 1:
        setPageTitle(t("CreateTemplate.TemplateCategory.Header"));
        newBreadcrumb.push(
          {
            title: t("CreateTemplate.TN&SS.Title"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => {
              document
                .getElementById("emptyTemplateSender")
                ?.setAttribute("hidden", "true");
              document
                .getElementById("emptyTemplateName")
                ?.setAttribute("hidden", "true");
              setCurrentStep(0);
            },
          },
          { title: "", path: "", isSeparator: true, isActive: false }
        );
        break;

      case 2:
        setPageTitle(t("CreateTemplate.TemplateContent.Header"));
        newBreadcrumb.push(
          {
            title: t("CreateTemplate.TN&SS.Title"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => {
              document
                .getElementById("emptyTemplateSender")
                ?.setAttribute("hidden", "true");
              document
                .getElementById("emptyTemplateName")
                ?.setAttribute("hidden", "true");
              setCurrentStep(0);
            },
          },
          { title: "", path: "", isSeparator: true, isActive: false },
          {
            title: t("CreateTemplate.TemplateCategory.Header"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => setCurrentStep(1),
          },
          { title: "", path: "", isSeparator: true, isActive: false }
        );
        break;

      case 3:
        newBreadcrumb.push(
          {
            title: t("CreateTemplate.TN&SS.Title"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => {
              document
                .getElementById("emptyTemplateSender")
                ?.setAttribute("hidden", "true");
              document
                .getElementById("emptyTemplateName")
                ?.setAttribute("hidden", "true");
              setCurrentStep(0);
            },
          },
          { title: "", path: "", isSeparator: true, isActive: false },
          {
            title: t("CreateTemplate.TemplateCategory.Header"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => setCurrentStep(1),
          },
          { title: "", path: "", isSeparator: true, isActive: false },
          {
            title: t("Template.Title.TemplateHeader"),
            path: "",
            isSeparator: false,
            isActive: false,
            onClick: () => setCurrentStep(2),
          },
          { title: "", path: "", isSeparator: true, isActive: false }
        );
        break;

      case 4:
        if (templateCategory !== "authentication") {
          newBreadcrumb.push(
            {
              title: t("CreateTemplate.TN&SS.Title"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => {
                document
                  .getElementById("emptyTemplateSender")
                  ?.setAttribute("hidden", "true");
                document
                  .getElementById("emptyTemplateName")
                  ?.setAttribute("hidden", "true");
                setCurrentStep(0);
              },
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateCategory.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(1),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateHeader"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(2),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateContent.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(3),
            },
            { title: "", path: "", isSeparator: true, isActive: false }
          );
        } else {
          newBreadcrumb.push(
            {
              title: t("CreateTemplate.TN&SS.Title"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => {
                document
                  .getElementById("emptyTemplateSender")
                  ?.setAttribute("hidden", "true");
                document
                  .getElementById("emptyTemplateName")
                  ?.setAttribute("hidden", "true");
                setCurrentStep(0);
              },
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateCategory.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(1),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateHeader"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(2),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.CodeDelivery"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(3),
            },
            { title: "", path: "", isSeparator: true, isActive: false }
          );
        }
        break;

      case 5:
        if (templateCategory !== "authentication") {
          newBreadcrumb.push(
            {
              title: t("CreateTemplate.TN&SS.Title"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => {
                document
                  .getElementById("emptyTemplateSender")
                  ?.setAttribute("hidden", "true");
                document
                  .getElementById("emptyTemplateName")
                  ?.setAttribute("hidden", "true");
                setCurrentStep(0);
              },
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateCategory.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(1),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateHeader"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(2),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateContent.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(3),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateFooter"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(4),
            },
            { title: "", path: "", isSeparator: true, isActive: false }
          );
        } else {
          newBreadcrumb.push(
            {
              title: t("CreateTemplate.TN&SS.Title"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => {
                document
                  .getElementById("emptyTemplateSender")
                  ?.setAttribute("hidden", "true");
                document
                  .getElementById("emptyTemplateName")
                  ?.setAttribute("hidden", "true");
                setCurrentStep(0);
              },
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateCategory.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(1),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateHeader"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(2),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.CodeDelivery"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(3),
            },
            { title: "", path: "", isSeparator: true, isActive: false }
          );
        }
        break;

      default:
        break;
    }

    setBreadcrumb(newBreadcrumb);
  }, [currentStep]);

  const confirmCancel = () => {
    nav("/template/list");
    dispatch(ReduxTemplate.cleanReduxTemplate(true));
  };

  const uploadFile = async (
    companyID: string,
    inputcontentType: string,
    inputFileName: string,
    file: any,
    headerType: string
  ) => {
    let fileURL = "";
    if (file) {
      const metadata = {
        contentType: inputcontentType,
        fileName: inputFileName,
      };
      const uuid = uuidv4();
      const task = storage
        .ref(companyID + "/templates/headers/" + headerType + "/" + uuid)
        .child(inputFileName)
        .put(file, metadata);
      await task
        .then(async (snapshot) => {
          return storage
            .ref(companyID + "/templates/headers/" + headerType + "/" + uuid)
            .child(inputFileName)
            .getDownloadURL()
            .then((url) => {
              fileURL = url;
            });
        })
        .catch((error) => {
          Log.SDayslogger(
            nav,
            "Error Edit Template - Maximmum Edit Per Days: " + error,
            Log.SDLOGGER_ERROR,
            false,
            true
          );
          const failedTextAlert = document.getElementById(
            "failedProcessAlertNotif"
          ) as HTMLDivElement;
          failedTextAlert.innerHTML = t("HC.Error.FailedUpload");
          const buttonFailedAlert = document.getElementById(
            "triggerFailedProcess"
          );
          if (buttonFailedAlert) {
            buttonFailedAlert.click();
          }
        });
    }
    return fileURL;
  };

  const reassureModal = () => {
    setShowReassureModal(true);
  };

  const submitTemplate = async () => {
    console.log(generatedLanguage, "genlang upx");
    console.log(generatedTemplate, "gentemp upx");
    modalClose();
    dispatch(ReduxTemplate.setLoading(true));
    document.getElementById(`prevStep`)?.classList.add("disabled");
    const button = document.querySelector("button");
    button?.classList.add("disabled");
    await onSaveTemplate();
    document.getElementById(`prevStep`)?.classList.remove("disabled");
    dispatch(ReduxTemplate.setLoading(false));
  };

  const handleSubmit = () => {
    console.log("masuk ini step");
    let emptyCustomText;
    if (templateBodyMessage === "") {
      dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(true));
    }
    if (templateBodyParams.length > 0) {
      emptyCustomText = checkEmptyCustomText();
    }
    let templateBodyWithoutParam = templateBodyMessage;
    if (templateBodyWithoutParam !== "") {
      templateBodyWithoutParam = templateBodyWithoutParam.replace(
        /{{[^}}]*}}/g,
        ""
      );
      if (templateBodyWithoutParam === "") {
        dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(true));
      }
    }
    const isHasEmptyCustomText: boolean =
      emptyCustomText &&
      emptyCustomText?.length > 0 &&
      emptyCustomText.includes(true);

    if (
      templateBodyMessage !== "" &&
      templateBodyWithoutParam !== "" &&
      (!emptyCustomText ||
        emptyCustomText?.length <= 0 ||
        !isHasEmptyCustomText)
    ) {
      if (!campaignTemplateFinish) {
        if (currentStep === 5) {
          let newBreadcrumb: Array<PageLink> = usersBreadcrumbs;
          newBreadcrumb.push(
            {
              title: t("CreateTemplate.TN&SS.Title"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => {
                document
                  .getElementById("emptyTemplateSender")
                  ?.setAttribute("hidden", "true");
                document
                  .getElementById("emptyTemplateName")
                  ?.setAttribute("hidden", "true");
                setCurrentStep(0);
              },
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateCategory.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(1),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateHeader"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(2),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("CreateTemplate.TemplateContent.Header"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(3),
            },
            { title: "", path: "", isSeparator: true, isActive: false },
            {
              title: t("Template.Title.TemplateFooter"),
              path: "",
              isSeparator: false,
              isActive: false,
              onClick: () => setCurrentStep(4),
            },
            { title: "", path: "", isSeparator: true, isActive: false }
          );
          setBreadcrumb(newBreadcrumb);
        } else {
          setBreadcrumb(usersBreadcrumbs);
        }
        onPrevNextClicked("next");
      } else {
        onPrevNextClicked("next");
      }
    }
  };

  useEffect(() => {
    console.log(generatedLanguage, "genlang")
    if (templateButton && templateButton.length > 0) {
      let isFounded =
        generatedLanguage &&
        generatedLanguage.buttons &&
        generatedLanguage.buttons.every((btn: any) =>
          templateButton.includes(btn)
        );
      if (!isFounded) {
        generateDataLanguage("buttons", templateButton, 0, "", undefined, []);
      }
    }
    if (templateFooter !== "") {
      dispatch(ReduxTemplate.setTemplateFooter(templateFooter))
    }
    if (templateHeader) {
      dispatch(ReduxTemplate.setTemplateHeader(templateHeader))
    }
  }, [templateButton, templateFooter, generatedLanguage]);

  const isUpdate = templateID;
  const URI = isUpdate ? "/template/update" : "/template/create";

  const renderPageTitle = () => {
    let title = "";
    let breadcrumbs = usersBreadcrumbs;

    switch (currentStep) {
      case 0:
        title = t("CreateTemplate.TN&SS.Title");
        break;
      case 1:
        title = t("CreateTemplate.TemplateCategory.Header");
        breadcrumbs = breadcrumb;
        break;
      case 2:
        title =
          templateCategory !== "authentication"
            ? t("Template.Title.TemplateHeader")
            : t("Template.CodeDelivery");
        breadcrumbs = breadcrumb;
        break;
      case 3:
        title = t("CreateTemplate.TemplateContent.Header");
        breadcrumbs = breadcrumb;
        break;
      case 4:
        title =
          templateCategory === "authentication"
            ? t("Template.Title.Submit")
            : t("Template.Title.TemplateFooter");
        breadcrumbs = breadcrumb;
        break;
      case 5:
        title = t("Campaign.Title.InteractiveButton");
        breadcrumbs = breadcrumb;
        break;
      case 6:
        title = t("Template.Title.Submit");
        breadcrumbs = breadcrumb;
        break;
      default:
        break;
    }

    if (templateState === ImportedTemplateState.campaigncreated) {
      title = t("Campaign.Button.CreateCampaign");
    }

    return <PageTitle breadcrumbs={breadcrumbs}>{title}</PageTitle>;
  };

  const NextButton = ({
    currentStep,
    isloading,
    generatedLanguage,
    isButtonNextDisabled,
    reassureModal,
    handleSubmit,
    alertCTA,
    templateHeaderParams,
    alertDuplicateParamNameInBody,
    templateCategory,
    onStepHeaderOrFooter,
    alertNonCheckOTPTermConditions,
    alertNonCheckPackageNameTermConditions,
    alertNonCheckAppSignatureHashTermConditions,
    onPrevNextClicked,
  }: any) => {
    const dispatch = useDispatch();
    console.log(currentStep, "check currentstep stp");
    console.log(
      alertNonCheckOTPTermConditions,
      alertNonCheckPackageNameTermConditions,
      alertNonCheckAppSignatureHashTermConditions,
      "check alert stp"
    );

    const handleNextClick = () => {
      if (
        templateHeaderParams &&
        templateHeaderParams.length > 0 &&
        templateHeaderParams[0].paramType === "customText" &&
        templateHeaderParams[0].textContent === ""
      ) {
        dispatch(ReduxTemplate.setAlertEmptyTextContentHeaderParam(true));
        return;
      }

      if (currentStep === 3 && alertDuplicateParamNameInBody) {
        return;
      }

      if (
        (alertNonCheckOTPTermConditions ||
          alertNonCheckPackageNameTermConditions ||
          alertNonCheckAppSignatureHashTermConditions) &&
        templateCategory === "authentication" &&
        currentStep === 4
      ) {
        return;
      } else {
        onPrevNextClicked("next");
      }
    };

    const renderButton = () => {
      switch (currentStep) {
        case 6:
          return (
            <button
              id="nextStep"
              data-testid="nextStep"
              type="button"
              className="btn btn-primary"
              onClick={reassureModal}
              disabled={
                isloading ||
                (generatedLanguage?.failover && isButtonNextDisabled)
              }
            >
              {!isloading && t("ForgotPassword.Button.Submit")}
              {isloading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {t("Login.Button.Loading")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          );
        case 5:
          return (
            <SubmitButtonCTA
              handleSubmit={handleSubmit}
              reduxLoading={isloading}
              reduxAlertCTA={alertCTA}
            />
          );
        default:
          return (
            <button
              id="nextStep"
              data-testid="nextStep"
              type="button"
              className="btn btn-primary"
              onClick={handleNextClick}
            >
              {t("Campaign.Button.Continue")}
            </button>
          );
      }
    };

    return renderButton();
  };


  useEffect(() => {
    if (
      templateState &&
      templateID !== "" &&
      templateState === "update"
    ) {
      if (templateID && templateID !== "") {
        servTemplate.get(templateID).then((templateObject) => {
          if (templateObject) {
            dispatch(
              ReduxTemplate.setTemplateCategory(templateObject.category!)
            );
            const companiesID = templateObject.companies?.map(
              (item) => item.id
            );
            const accountsID = templateObject.accounts?.map((item) => item.id);
            dispatch(ReduxTemplate.setTemplateCompaniesID(companiesID));
            dispatch(ReduxTemplate.setAccountsID(accountsID));
            dispatch(
              ReduxTemplate.setTemplateName(templateObject.templateName!)
            );
            console.log(templateObject, "template object deq")
            // dispatch(ReduxTemplate.setGeneratedTemplate(templateObject));
            // dispatch(ReduxTemplate.setGeneratedLanguage(templateObject.languages));
          }
        });

        //Process Data Language
        servTemplate
          .getTemplateLanguages(templateID)
          .then((listLanguage) => {
            if (listLanguage && listLanguage.length > 0) {
              const newLanguage = listLanguage.map((language) => {
                return language as Language;
              });
              const templateWithLanguage = listLanguage[0];

              console.log(templateWithLanguage, newLanguage, "checkk bruu")

              if (
                templateWithLanguage.status &&
                (templateWithLanguage.status === templateStatus.approved ||
                  templateWithLanguage.status === templateStatus.rejected ||
                  templateWithLanguage.status === templateStatus.paused ||
                  templateWithLanguage.status === templateStatus.pending)
              ) {
                console.log("Process To Update");
              } else {
                const failedTextAlert = document.getElementById(
                  "failedProcessAlertNotif"
                ) as HTMLDivElement;
                failedTextAlert.innerHTML = t("ET.Error.TemplateStatus");
                const buttonFailedAlert = document.getElementById(
                  "triggerFailedProcess"
                );
                if (buttonFailedAlert) {
                  buttonFailedAlert.click();
                }
              }

              let languageId = templateWithLanguage?.id;
              let bodyParam = templateWithLanguage?.bodyParam;
              let body = templateWithLanguage?.body!;
              let header = templateWithLanguage?.header;
              let footer = templateWithLanguage?.footer;
              let buttons = templateWithLanguage?.buttons;
              let responseTemplateID = templateWithLanguage?.responseTemplateID;

              if (templateWithLanguage === undefined || body === undefined) {
                const failedTextAlert = document.getElementById(
                  "failedProcessAlertNotif"
                ) as HTMLDivElement;
                //Perlu Pesan Failed, sudah melebihi batas update template harian (1x update)
                failedTextAlert.innerHTML = t("UFL.Alert.AddUser").replace(
                  `<<name>>`,
                  "abc"
                );
                const buttonFailedAlert = document.getElementById(
                  "triggerFailedProcess"
                );
                if (buttonFailedAlert) {
                  buttonFailedAlert.click();
                  // nav("/template/list");
                }
              }
              if (responseTemplateID) {
                dispatch(
                  ReduxTemplate.setResponseTemplateID(responseTemplateID)
                );
              }
              if (body) {
                dispatch(ReduxTemplate.setTemplateBodyMessage(body));
              }
              if (buttons) {
                dispatch(ReduxTemplate.setTemplateButton(buttons));
              }
              if (header) {
                let mediaType = header.mediaType;
                let type = header.type;
                let inHeaderURL = "";
                if (header.headerURL) {
                  inHeaderURL = header.headerURL;
                }
                let text = header.text;
                let headerParam = header.headerParam;
                if (mediaType) {
                  dispatch(ReduxTemplate.setTemplateHeaderURL(inHeaderURL));
                  dispatch(ReduxTemplate.setTemplateFileType(mediaType));
                } else if (type === "text") {
                  let previewHeaderText = text;
                  if (headerParam) {
                    dispatch(
                      ReduxTemplate.setTemplateHeaderParams(headerParam)
                    );
                    for (let index = 0; index < headerParam.length; index++) {
                      let newHeaderText: BodyParamLanguage = headerParam[index];
                      const paramId = newHeaderText.paramId;
                      const paramType = newHeaderText.paramType;
                      const paramKey = newHeaderText.paramKey!;
                      const paramTextContent = newHeaderText.textContent;
                      const paramName = newHeaderText.paramName
                        ? newHeaderText.paramName
                        : "";
                      const translate =
                        paramName !== ""
                          ? paramName
                          : t(
                            "Campaign.Input." +
                            (paramType === "customText"
                              ? "CustomizeText"
                              : paramKey)
                          );
                      text = text.replaceAll(
                        "{{" + paramId + "}}",
                        "{{" + translate + "}}"
                      );
                      let newPreviewHeaderText = text;
                      if (
                        paramType === "customText" &&
                        paramTextContent &&
                        paramTextContent !== ""
                      ) {
                        newPreviewHeaderText = newPreviewHeaderText.replaceAll(
                          `{{${translate}}}`,
                          paramTextContent
                        );
                      } else {
                        newPreviewHeaderText = newPreviewHeaderText.replaceAll(
                          "{{" + translate + "}}",
                          `<span className="badge badge-primary fw-bolder fs-7 m-1"><i className="bi bi-server pe-2 text-white"></i>${paramName && paramName !== ""
                            ? paramName
                            : t(
                              "Campaign.Input." +
                              (paramType === "customText"
                                ? "CustomizeText"
                                : newHeaderText.paramKey!)
                            )
                          }</span>`
                        );
                      }
                      previewHeaderText = newPreviewHeaderText;
                    }
                  }
                  dispatch(
                    ReduxTemplate.setTemplatePreviewHeaderText(
                      previewHeaderText
                    )
                  );
                }
                dispatch(ReduxTemplate.setTemplateHeader(header));
              }
              if (footer) {
                dispatch(ReduxTemplate.setTemplateFooter(footer));
              }

              let newBodyMessage = body;
              let newPreviewBody = newBodyMessage;
              if (bodyParam && bodyParam.length > 0) {
                dispatch(ReduxTemplate.setTemplateBodyParams(bodyParam));
                for (let index = 0; index < bodyParam.length; index++) {
                  let bodyParamI = bodyParam[index];
                  const translate =
                    bodyParamI.paramName && bodyParamI.paramName !== ""
                      ? bodyParamI.paramName
                      : t(
                        "Campaign.Input." +
                        (bodyParamI.paramType === "customText"
                          ? "CustomizeText"
                          : bodyParamI.paramKey)
                      );
                  newBodyMessage = newBodyMessage.replaceAll(
                    "{{" + bodyParamI.paramId + "}}",
                    "{{" + translate + "}}"
                  );
                  newPreviewBody = newPreviewBody.replace(
                    bodyParamI.paramId.toString(),
                    translate!
                  );
                }
                dispatch(
                  ReduxTemplate.setTemplatePreviewBody(
                    (newPreviewBody)
                  )
                );
                dispatch(ReduxTemplate.setTemplateBodyParams(bodyParam));
                dispatch(ReduxTemplate.setTemplateBodyMessage(newBodyMessage));
              }


              dispatch(ReduxTemplate.setTemplateID(templateID));
              dispatch(ReduxTemplate.setTemplateLanguageID(languageId));
              dispatch(ReduxTemplate.setGeneratedLanguage(newLanguage[0]));
            } else {
              const failedTextAlert = document.getElementById(
                "failedProcessAlertNotif"
              ) as HTMLDivElement;
              failedTextAlert.innerHTML = "update gagal silahkan coba kembali";
              const buttonFailedAlert = document.getElementById(
                "triggerFailedProcess"
              );
              if (buttonFailedAlert) {
                buttonFailedAlert.click();
              }
            }
          });
      }
    }
  }, [])

  console.log(stateTemplate, "state template bruu")

  return (
    <>
      <ModalConfirmationMoveToOtherPage
        path={URI}
        secondPath={currentStep === 6 || currentStep === 0 ? `/template` : URI}
        paths={[currentStep === 6 ? `campaign` : URI]}
        onSave={() => console.log("save")}
        onUnsave={() => console.log("unsave")}
      />
      {isloading && <Loading />}
      <button
        id="triggerFailedProcess"
        data-bs-toggle="modal"
        data-bs-target="#modal_failed_process"
        hidden
      ></button>
      <AlertFailedProcess processName="Template Configuration" />
      {renderPageTitle()}
      <div className="d-flex flex-column flex-lg-column h-100 w-100">
        <ModalConfirmation
          id="cancelCreateModal"
          body={t("Template.Button.DiscardChanges")}
          title={t("CD.Button.Cancel")}
          onConfirm={confirmCancel}
          confirmOption={t("BS.Button.Close")}
          cancelOption={t("CD.Button.Cancel")}
        />
        <div
          className={clsx(
            `overflow-hidden card mb-1 mb-xl-2 h-100`,
            isTabletOrMobile ? "" : "p-7"
          )}
        >
          {isTabletOrMobile && <TemplateMobileIcon />}

          {/* TEMPLATE BODY PAGE  */}
          <div
            style={{ height: "100%", overflowY: "scroll" }}
            className={clsx(isTabletOrMobile ? "p-10" : "")}
          >
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 d-none">
              {Array.from({ length: currentStep + 1 }).map((_, index) => {
                const stepId = `step_${index}`;
                const tabPaneId = `kt_tab_pane_${index}`;
                const disabledClass = index > currentStep ? "disabled" : "";

                return (
                  <li className="nav-item" key={index}>
                    <a
                      className={`nav-link ${disabledClass}`}
                      data-bs-toggle="tab"
                      href={`#${tabPaneId}`}
                      id={stepId}
                    ></a>
                  </li>
                );
              })}
            </ul>
            <div className="flex-lg-column-fluid tab-content" id="myTabContent">
              {/* STEP 0 */}
              <TemplateChannel onChanges={onCampaignChanges} />

              {/* STEP 1 */}
              <TemplateCategory onChanges={onCampaignChanges} />

              {/* STEP 2 */}
              <TemplateHeaderComponent
                bodyMessage={dataTemplate.bodyMessage}
                previewBody={dataTemplate.previewBody}
                template={dataTemplate.template}
                language={dataTemplate.language}
                onChanges={onCampaignChanges}
                generateLanguage={generateDataLanguage}
                currentStep={currentStep}
              />

              {/* STEP 3 */}
              <TemplateProcess
                onChanges={onCampaignChanges}
                generateLanguage={generateDataLanguage}
                generateTemplate={generateDataTemplate}
              />

              {/* STEP 4 */}
              {templateCategory !== "authentication" ? (
                <TemplateFooterComponent
                  bodyMessage={dataTemplate.bodyMessage}
                  previewBody={dataTemplate.previewBody}
                  template={dataTemplate.template}
                  language={dataTemplate.language}
                  onChanges={onCampaignChanges}
                  generateLanguage={generateDataLanguage}
                  currentStep={currentStep}
                />
              ) : (
                <TemplateCodeDelivery
                  onChanges={onCampaignChanges}
                  generateLanguage={generateDataLanguage}
                  generateTemplate={generateDataTemplate}
                />
              )}

              {/* STEP 5 */}
              <TemplateCallToAction
                bodyMessage={dataTemplate.bodyMessage}
                previewBody={dataTemplate.previewBody}
                template={dataTemplate.template}
                language={dataTemplate.language}
                onChanges={onCampaignChanges}
                generateLanguage={generateDataLanguage}
              />

              {/* Step 6*/}
              <TemplateSubmission
                onChanges={onCampaignChanges}
                validateInputHandler={(isValid: any) => {
                  setIsButtonNextDisabled(!isValid);
                }}
              />
            </div>
          </div>

          {/* BUTTON  */}
          <div
            className={clsx(
              `d-flex space`,
              isTabletOrMobile ? "p-10" : "mt-10"
            )}
            style={{ justifyContent: "space-between" }}
          >
            <button
              id="prevStep"
              data-testid="prevStep"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                onPrevNextClicked("prev");
              }}
              data-bs-toggle={
                (templateState === ImportedTemplateState.update &&
                  (currentStep === 2 || currentStep === 0)) ||
                  (templateState === ImportedTemplateState.created &&
                    currentStep === 0)
                  ? "modal"
                  : ""
              }
              data-bs-target={
                (templateState === ImportedTemplateState.update &&
                  (currentStep === 2 || currentStep === 0)) ||
                  (templateState === ImportedTemplateState.created &&
                    currentStep === 0)
                  ? "#cancelCreateModal"
                  : ""
              }
            >
              {t("Campaign.Button.Back")}
            </button>
            <NextButton
              currentStep={currentStep}
              isloading={isloading}
              generatedLanguage={generatedLanguage}
              isButtonNextDisabled={isButtonNextDisabled}
              reassureModal={reassureModal}
              handleSubmit={handleSubmit}
              alertCTA={alertCTA}
              templateHeaderParams={templateHeaderParams}
              alertDuplicateParamNameInBody={alertDuplicateParamNameInBody}
              templateCategory={templateCategory}
              onStepHeaderOrFooter={onStepHeaderOrFooter}
              alertNonCheckOTPTermConditions={alertNonCheckOTPTermConditions}
              alertNonCheckPackageNameTermConditions={
                alertNonCheckPackageNameTermConditions
              }
              alertNonCheckAppSignatureHashTermConditions={
                alertNonCheckAppSignatureHashTermConditions
              }
              onPrevNextClicked={onPrevNextClicked}
            />
          </div>
        </div>
      </div>

      {showReassureModal === true && (
        <Modal
          animation
          centered
          show={showReassureModal === true}
          size="sm"
          scrollable={false}
          onHide={() => modalClose && modalClose()}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Campaign.Modal.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="fs-4">
              <p>{t("Campaign.Modal.Body")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <button
              type="button"
              className="btn btn-outline-primary w-15 text-center"
              style={{
                border: "0.5px solid #189ffb",
              }}
              onClick={() => modalClose && modalClose()}
            >
              {t("Campaign.Modal.Cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary w-30"
              onClick={async () => await submitTemplate()}
            >
              {t("Campaign.Modal.Yes")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const SubmitButtonCTA: FC<{
  reduxAlertCTA: AlertModal;
  reduxLoading: boolean;
  handleSubmit: () => void;
}> = ({ reduxAlertCTA, reduxLoading, handleSubmit }) => {
  console.log(reduxAlertCTA, "check rdta");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const templateQuickReplyTexts = useSelector(
    (state: RootState) => state.Template.templateQuickReplies
  );
  const templateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  return (
    <>
      {(templateCategory === "interactive" ||
        templateCategory === "authentication") && (
          <button
            id="nextStep"
            data-testid="nextStep"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={reduxLoading}
          >
            {!reduxLoading && t("ForgotPassword.Button.Submit")}
            {reduxLoading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {t("Login.Button.Loading")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        )}
      {templateCategory !== "interactive" &&
        templateCategory !== "authentication" && (
          <>
            <button
              id="nextStep"
              data-testid="nextStep"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (reduxAlertCTA.show) {
                  return;
                }
                handleSubmit();
              }}
              disabled={reduxLoading}
              data-bs-target={reduxAlertCTA.show ? "#alertModalCTA" : ""}
              data-bs-toggle={reduxAlertCTA.show ? "modal" : ""}
            >
              {!reduxLoading && t("ForgotPassword.Button.Submit")}
              {reduxLoading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {t("Login.Button.Loading")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>

            <AlertModalDialog
              id="alertModalCTA"
              bodyMessage={reduxAlertCTA.body}
              onSubmit={() => handleSubmit()}
              optional={reduxAlertCTA.optional}
            />
          </>
        )}
    </>
  );
};

export default TemplateUpdateCreate;
