import { button, failover } from "../../../models/Language";
import Template from "../../../models/Template";

enum InteractiveButton {
  None = "",
  CallToAction = "CALL_TO_ACTION",
  QuickReply = "QUICK_REPLY",
  OTP = "OTP",
  Multiple = "MULTIPLE"
}
enum InteractiveMessage {
  None = "",
  List = "LIST_MESSAGE",
  Button = "BUTTON_REPLY",
}

export interface AlertModal {
  show: boolean;
  optional: boolean;
  body: string;
}

interface InitialState {
  clientID: string;
  accountsID: string[];
  templateCompaniesID: string[];
  templateID: string;
  newTemplate: Template;
  templateLanguageID: string;
  templateBodyParams: any[];
  templateHeaderParams: any;
  templateName: string;
  templateChannel: string;
  templateCategory: string;
  templateEmptyCategoryAlert: boolean;
  templateBodyMessage: string;
  templateEmptyBodyMessageAlert: boolean;
  templateEmptyCustomTextAlert: boolean[];
  templatePreviewBody: string;
  generatedTemplate: any;
  generatedLanguage: any;
  templateBodyMessageLength: number;
  templateNameLength: number;
  templateNameAlert: boolean;
  templateNameCharacterAlert: boolean;
  templateNameEmptyAlert: boolean;
  templateSenderEmptyAlert: boolean;
  templateWAAccessToken: string;
  templateWABussinessID: string;
  templateFooter: string;
  templateHeader: any;
  templateButton: button[];
  responseTemplateID: any;
  templateState: string;
  templatePreviewHeaderText: string;
  templateHeaderFile: boolean;
  templateHeaderURL: string;
  templateFileType: string;
  templateBinaryFile: any;
  templateUploadFileSize: number;
  templateUploadFileType: string;
  templateUploadFileName: string;
  campaignTargetPreviewData: any[];
  isloading: boolean;
  companiesAccounts: any[];
  templateQuickReplies: string[];
  alertHeader: AlertModal;
  alertFooter: AlertModal;
  alertCTA: AlertModal;
  selectedInteractiveButton: string;
  selectedInteractiveMessage: string;
  onStepHeaderOrFooter: string;
  alertEmptyInteractiveButtonCTA: boolean;
  alertDuplicateParamName: boolean;
  alertDuplicateParamNameInBody: boolean;
  alertEmptyTextContentHeaderParam: boolean;
  alertNonCheckOTPTermConditions: boolean;
  alertNonCheckPackageNameTermConditions: boolean;
  alertNonCheckAppSignatureHashTermConditions: boolean;
  addSecurityRecommendation: boolean;
  code_expiration_minutes: number;
  otpType: string;
  failover: failover;
  myRef: any;
}

const UpdateCampaignAction: string = "UpdateTemplate";

// export type unsubFN = () => void;

export default InitialState;

export { InteractiveButton, InteractiveMessage, UpdateCampaignAction };
