import { FC , useEffect } from "react";
import { MenuComponent } from "../../../../../resources/assets/ts/components";
import Message from "../../../../models/Message";

import { formatDate} from "../chat-util/ChatUtil";
import { useTranslation } from "react-i18next";

interface MessageProps {
    userMessage: Message;
  }

const ChatMessageInfo: FC<MessageProps> = (props) => {
    const { t } = useTranslation();
    const message = props.userMessage;

    // useEffect(() => {
    //   setTimeout(() => {
    //     MenuComponent.reinitialization()
    //   }, 50)
    // }, [])

    return (
            <div
              id={`message_info_menu_${message.id}`}
              data-kt-menu='true'
              className="mb-2"
              // onMouseLeave={() => document.getElementById(`message_menu_${message.id}`)?.click()}
              // style={{inset: `${document.getElementById(`message_${message.id}`)?.clientHeight}px 0px auto auto`}}
            >
              <div className="menu-title message-menu-title d-flex flex-row">
                <div className="bi bi-x-lg cursor-pointer" data-kt-menu-dismiss="true"></div>
                <div className="w-100 ps-2 pe-1">{t("MI.Title.MessageInfo")}</div>
              </div>
              <div className="menu-item message-menu-item d-flex flex-row">
                  <div className="bi bi-check2-all text-primary pe-1 fs-3"></div>
                  <div className="pe-1">{t("MI.Info.Read")}</div>
                {message.readAt && <div className="message-menu-item-timestamp">{formatDate(message.readAt,"en")}</div>}
                {!message.readAt && <div className="message-menu-item-timestamp-null">-</div>}
              </div>
              <div className="menu-item message-menu-item d-flex flex-row">
                  <div className="bi bi-check2-all pe-1 fs-3"></div>
                  <div className="pe-1">{t("MI.Info.Delivered")}</div>
                {message.deliveredAt && <div className="message-menu-item-timestamp">{formatDate(message.deliveredAt,"en")}</div>}
                {!message.deliveredAt && <div className="message-menu-item-timestamp-null">-</div>}
              </div>
              {/* <div className="menu-item message-menu-item d-flex flex-row">
                  <div className="bi bi-check2 pe-1"></div>
                  <div className="pe-1">Submit</div>
                {message.submittedAt && <div className="message-menu-item-timestamp">{formatDate(message.submittedAt,"en")}</div>}
                {!message.submittedAt && <div className="message-menu-item-timestamp-null">-</div>}
              </div> */}
            </div>
    )
}

export default ChatMessageInfo;