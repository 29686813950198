import clsx from "clsx";
import firebase from "firebase/compat/app";
import moment from "moment";
import { FC, Fragment, PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";

type DateTimeCellProp = {
  timestamp?: firebase.firestore.Timestamp;
};

const MarketplaceDateCell: FC<DateTimeCellProp> = ({ timestamp }) => {
  const value =
    timestamp && timestamp instanceof firebase.firestore.Timestamp
      ? timestamp.toDate()
      : "";

  const date = moment(value).format("DD/MM/YYYY");
  const time = moment(value).format("HH:mm");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <>
      {!isTabletOrMobile ? (
        <Fragment>
          {time !== "Invalid date" && (<div className="fw-bolder text-dark">{time}</div>)}
          <div className="fw-bold text-gray-600">{date}</div>
        </Fragment>
      ) : (
        <Fragment>
          {time !== "Invalid date" && (<div className="fw-bolder text-dark">{date}, {time}</div>)}
        </Fragment>
      )}
    </>
  );
};

export default MarketplaceDateCell;
