import { useTranslation } from "react-i18next";

export const ProductHeaderTitle = (title: string) => {
  const { t } = useTranslation();
  return (
    <div className="card mb-1 mb-xl-2 p-3 d-flex flex-row space">
      <div>
        <div className="h2">
          <h2 className="w-100">
            <span
              className="bi bi-window-plus fs-2x"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            ></span>
            {t(title)}
          </h2>
        </div>
      </div>
    </div>
  );
};
