import {Column} from 'react-table'
import {CampaignCustomHeader} from './CampaignMessageCustomHeader'
import {CampaignMessage} from '../../../../../../models/Campaign'
import {CampaignMessageLastUpdatedAtCell} from './CampaignMessageLastUpdatedAtCell'
import {CampaignMessageCell} from './CampaignMessageCell'
import {CampaignCustomerInfoCell} from './CampaignMessageCustomerInfoCell'

const campaignDetailsColumns: ReadonlyArray<Column<CampaignMessage>> = [
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="Common.Column.Name" className='min-w-125px' data-testid="customerName"/>
    ),
    id: 'customerName',
    Cell: ({...props}) => <CampaignCustomerInfoCell customerInfo={props.data[props.row.index].customerName}/>,
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="Common.Column.PhoneNumber" className='min-w-125px' data-testid="phoneNumber"/>
    ),
    id: 'phoneNumber',
    Cell: ({...props}) => <CampaignCustomerInfoCell customerInfo={props.data[props.row.index].phoneNumber}/>,
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="Campaign.Column.LastUpdated" className='min-w-125px' data-testid="updatedAt"/>
    ),
    id: 'updatedAt',
    Cell: ({...props}) => <CampaignMessageLastUpdatedAtCell lastUpdatedAt={props.data[props.row.index].updatedAt} />,
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="DLR.Column.MOMessage" className='min-w-125px' data-testid="message"/>
    ),
    id: 'message',
    Cell: ({...props}) => <CampaignMessageCell message={props.data[props.row.index].message}/>,
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="DLR.Column.Type" className='min-w-125px' data-testid="type"/>
    ),
    id: 'type',
    Cell: ({...props}) => (<div className="text-gray-650 mb-1">{props.data[props.row.index].type ?? "Whatsapp"}</div>),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title="DLR.Column.Status" className='min-w-125px' data-testid="status"/>
    ),
    id: 'status',
    Cell: ({...props}) => <CampaignMessageCell message={props.data[props.row.index].status.toUpperCase()}/>,
  },
]



export {campaignDetailsColumns}
