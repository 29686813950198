import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactRouterPrompt from "react-router-prompt";
import ModalConfirmationWithStateHandler from "./modalConfirmationWithStateHandler";

interface ModalProps {
  path: string;
  secondPath?: string;
  paths?: string[];
  isDirty?: boolean;

  title?: string;
  body?: string | HTMLElement;

  saveText?: string;
  unSaveText?: string;
  cancelText?: string;

  onSave?: any;
  onUnsave?: any;

  isEmpty?: boolean;
}

const ModalConfirmationMoveToOtherPage: FC<ModalProps> = (props) => {
  const { t } = useTranslation();

  const {
    path,
    secondPath,
    paths,
    isDirty = false,
    isEmpty = false,
    title = t("Alert.Info"),
    body = t("Alert.UnsavedChanges"),
    saveText = t("Alert.Button.SaveChanges"),
    unSaveText = isEmpty ? t("Common.Yes") : t("Alert.Button.LeaveWithoutSave"),
    cancelText = t("Alert.Button.Cancel"),
    onSave,
    onUnsave,
  } = props;

  return (
    <ReactRouterPrompt
      when={(nextLocation: any, _action: any) => {
        const pathname = nextLocation.pathname;
        console.log(`modalconfirmation pathname ${pathname} `);
        console.log(`path ${path}`);
        console.log(`secondPath ${secondPath}`);

        const inStepPath = pathname.includes(path);
        const inSecondPath = pathname.includes(secondPath);
        const inPathsArray = paths
          ? paths.filter((path) => pathname.includes(path)).length > 0
          : false;

        console.log(`ModalConfirmationMoveToOtherPage pathname ${pathname}`);
        console.log(`ModalConfirmationMoveToOtherPage path ${path}`);
        console.log(
          `ModalConfirmationMoveToOtherPage secondPath ${secondPath}`
        );
        console.log(
          `ModalConfirmationMoveToOtherPage inStepPath ${inStepPath}`
        );
        console.log(
          `ModalConfirmationMoveToOtherPage inSecondPath ${inSecondPath}`
        );
        console.log(
          `ModalConfirmationMoveToOtherPage inPathsArray ${inPathsArray}`
        );
        console.log(inStepPath, inSecondPath, "__ini currentStep pathh");

        // if next link is still in create storefront step don't show modal
        if (inStepPath || inSecondPath || inPathsArray) {
          return false;
        }

        return true;
      }}
    >
      {({ isActive, onConfirm, onCancel }: any) =>
        isActive && (
          <ModalConfirmationWithStateHandler
            isModalOpen={isActive}
            id="modalLeaveToOtherPage"
            data-testid="modalLeaveToOtherPage"
            title={title}
            body={body}
            saveText={isDirty ? saveText : undefined}
            unSaveText={unSaveText}
            cancelText={cancelText}
            onSave={() => {
              onSave().then(() => {
                onConfirm();
              });
            }}
            onUnsave={() => {
              onUnsave();
              onConfirm();
            }}
            onConfirm={onConfirm}
            onClose={onCancel}
          />
        )
      }
    </ReactRouterPrompt>
  );
};

export default ModalConfirmationMoveToOtherPage;
