import {
  DocumentData,
  QueryDocumentSnapshot,
  collection as dbCollection,
  getDoc,
  onSnapshot,
  query,
} from "firebase/firestore";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { reqReadyToShipOrder } from "../../../../../../api/server/order";
import { getAccountByID } from "../../../../../../db/serviceAccount";
import useFirestoreData, {
  FirestoreQueryState,
} from "../../../../../../hooks/useFirestoreData";
import { Account } from "../../../../../models/Account";
// import { Order, OrderStatus7Days } from "../../../../../models/Order";
import { getUserByID, newDB } from "../../../../../../db";
import SocketConnection from "../../../../../../setup/socket/socket";
import { Process } from "../../../../../models/Process";
import User from "../../../../../models/User";
import * as lc from "../../../../../modules/localstorage/index";

type TableContextValue = {
  tableState: FirestoreQueryState<Process>;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  updateTableState: (data: Partial<FirestoreQueryState<Process>>) => void;
  updatePage: (newPage: number) => void;
  refreshData: () => Promise<void>;
  isLoading: boolean;
  isFetching: boolean;
  data?: {
    allCount: number;
    items: Process[];
  };
};

// type ResponseRTS = {
//   message: string;
//   processId: string;
//   data: {
//     accountName: string;
//     count: number;
//   }[];
// };

export const defaultTableState: FirestoreQueryState<Process> = {
  clientID: undefined,
  collection: "",
  sorting: {
    field: "createdAt",
    order: "desc",
  },
  limit: 10,
  filters: [
    {
      field: "type",
      value: "exportOrder",
    },
  ],
  currentPage: 1,
  searchKey: {
    field: "id",
    value: "",
  },
  priorityOrder: false,
};
export const defaultValue: TableContextValue = {
  tableState: defaultTableState,
  selectedItems: [],
  setSelectedItems: () => {},
  updateTableState: () => {},
  updatePage: () => {},
  refreshData: () => new Promise((resolve) => resolve()),
  isLoading: true,
  isFetching: true,
  data: undefined,
};

export const TableContext = createContext<TableContextValue>(defaultValue);
export function useFirestoreTable() {
  return useContext(TableContext);
}

type TableProviderProps<T> = PropsWithChildren<{
  collection: string;
}>;

async function mapProcessData(
  docs: QueryDocumentSnapshot<Process, DocumentData>[]
): Promise<Process[]> {
  return await Promise.all(
    docs.map(async (doc) => {
      const userID = doc.data().createdBy?.id;
      const userData = !userID ? undefined : await getUserByID(userID);
      return {
        ...doc.data(),
        userData: (userData as User) ?? undefined,
        id: doc.id,
      };
    })
  );
}

export function TableProvider<T>({
  children,
  collection,
}: TableProviderProps<T>) {
  const clientID = lc.getItemLC(lc.LCName.Client).id;

  const [tableState, setTableState] = useState<FirestoreQueryState<Process>>({
    ...defaultTableState,
    collection: collection,
    clientID: clientID,
    refetchInterval: 1000 * 60,
  });

  useEffect(() => {
    const storedState = localStorage.getItem("tableStateProcesslist"); 
    if (storedState) {
      setTableState(JSON.parse(storedState));
    }
  }, []);

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("tableStateProcesslist", JSON.stringify(tableState)); 
  }, [tableState]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("tableStateProcesslist");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { data, isLoading, isFetching, refetch } = useFirestoreData<Process>(
    tableState,
    mapProcessData
  );

  console.log("TableState", tableState);

  const updateTableState = (data: Partial<FirestoreQueryState<Process>>) => {
    let newState = data;

    if (!data.currentPage) {
      newState.currentPage = 1;
    }

    return setTableState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const updatePage = (newPage: number) => {
    updateTableState({
      currentPage: newPage,
    });
    setSelectedItems([]);
  };

  const refreshData = async () => {
    await refetch();
    // setTableState((prev) => ({
    //   ...prev,
    //   sorting: defaultTableState.sorting,
    //   currentPage: defaultTableState.currentPage,
    //   priorityOrder: defaultTableState.priorityOrder,
    // }));
  };

  useEffect(() => {
    console.log("Masuk useEffect Export Order Process");
    const socket = SocketConnection();
    const onDataChange = () => refreshData();
    socket.on(`process-data-update`, onDataChange);

    return () => {
      socket.off(`process-data-update`, onDataChange);
    };
  }, []);

  console.log("Items", data?.items);

  const value = {
    tableState,
    selectedItems,
    setSelectedItems,
    updateTableState,
    updatePage,
    isLoading,
    isFetching,
    refreshData,
    data,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}
