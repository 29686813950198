import { LCName, getItemLC, setItemLC } from "../../localstorage";
import Notification from "../../../models/Notification";

export function removeFromNotificationsByCollabID(collabID: string) {
  let arrNotif: Array<Notification> = [];
  if (getItemLC(LCName.Notification)) {
    arrNotif = getItemLC(LCName.Notification);
  }

  if (arrNotif.length > 0) {
    arrNotif = arrNotif.filter((notif) => notif.collaborationID !== collabID);
    arrNotif = arrNotif.sort((a, b) =>
      new Date(a.createdAt.seconds * 1000) >
      new Date(b.createdAt.seconds * 1000)
        ? -1
        : 1
    );
  }
  setItemLC(LCName.Notification, arrNotif);
}
