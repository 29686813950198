import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import Brand from "./Brand";
import Shipment from "./Shipment";
import { Response } from "../../resources/helpers";

interface Product {
  id?: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
  sku?: string;
  parentSKU?: string;
  name?: string;
  nameInsensitive?: string;
  printText?: string;
  description?: string;
  isActive?: boolean;
  status?: string;
  storeFront?: firebase.firestore.DocumentReference;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  uom?: string;
  brand?: firebase.firestore.DocumentReference;
  marketplaceProductCategory?: any;
  marketplaceURL?: any;
  varian?: any;
  maxOrder?: number;
  minOrder?: number;
  typesOfGoods?: string;
  isDangerousProduct?: boolean;
  dangerousType?: string;
  expressShipper?: boolean;
  instantShipper?: boolean;
  stdPrice?: number;
  companies?: Companies[];
  marketplaces?: Marketplace[];
  isProductHaveVariants?: boolean;
  variants?: Variant[];
  productvariants?: ProductVariants[];
  lastStep?: string;
  client?: firebase.firestore.DocumentReference;
  coverImg?: string;
  variantLength?: number;
  marketplaceCategory?: [];
  brandModel?: Brand;
}

export default Product;

// export type Product = {
//   initials?: {
//     label: string;
//     state: string;
//   };
// };

export type ProductQueryResponse = Response<Product[]>;

export const productStep = {
  onBasicProductInformation: "BasicProductInformation",
  onMedia: "media",
  onPackageWeight: "packageWeight",
  onShipment: "shipment",
  onSelectCompanyAndMarketplace: "selectCompanyAndMarketplace",
  onSpecification: "specification",
  onVariantsCategory: "variantsCategory",
  onVariantsList: "variantsList",
  onSummary: "summary",
};

export interface Marketplace {
  company?: firebase.firestore.DocumentReference;
  marketplace?: string;
  isActive?: boolean;
  category?: any[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
  account?: firebase.firestore.DocumentReference;
  attributeList?: attributeList[] | undefined;
}

export interface attributeList {
  attributeId?: number;
  attributeValueList?: attributeValueList[];
}

export interface attributeValueList {
  valueId?: number;
  originalValueName?: string;
  valueUnit?: string;
}

export interface Companies {
  company?: firebase.firestore.DocumentReference;
  isActive: boolean;
}

export const productStatus = {
  drafted: "drafted",
  created: "created",
  requested: "requested",
  pending: "pending",
  imported: "imported",
  synced: "synced",
};

export const excelStyle = {
  styleHeader: {
    alignment: {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    },
    fill: {
      fgColor: {
        rgb: "2896c9",
      },
    },
    font: {
      name: "Arial",
      sz: 10,
      bold: true,
      color: {
        rgb: "FFFFFF",
      },
    },
    border: {
      bottom: {
        style: "medium",
        color: {
          rgb: "000000",
        },
      },
    },
  },
  styleOptional: {
    alignment: {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    },
    fill: {
      fgColor: {
        rgb: "b8c9de",
      },
    },
    font: {
      name: "Arial",
      sz: 10,
      bold: true,
      color: {
        rgb: "000000",
      },
    },
    border: {
      bottom: {
        style: "medium",
        color: {
          rgb: "000000",
        },
      },
    },
  },
  styleDescription: {
    alignment: {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    },
    fill: {
      fgColor: {
        rgb: "b8c9de",
      },
    },
    font: {
      name: "Arial",
      sz: 10,
      color: {
        rgb: "000000",
      },
    },
    border: {
      bottom: {
        style: "medium",
        color: {
          rgb: "000000",
        },
      },
    },
  },
  styleContent: {
    alignment: {
      wrapText: true,
      vertical: "center",
      horizontal: "center",
    },
    font: {
      name: "Arial",
      sz: 10,
    },
  },
};

export interface Media {
  id?: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
  isActive?: boolean;
  extension?: string;
  fileName?: string;
  fileSize?: string;
  fileType?: string;
  fileURL?: string;
  isCover?: boolean;
  youtbeURL?: string;
  marketplaceURl?: any;
}

export interface Shipments {
  shipmentID?: firebase.firestore.DocumentReference;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
  isActive?: boolean;
  name?: string;
  types?: ShipmentsType[];
}

export interface ShipmentsType {
  shipmentID?: firebase.firestore.DocumentReference;
  name?: string;
}

export interface Variant {
  marketplace?: string;
  image?: string;
  index?: number;
  optionList?: VariantOption[];
  name?: string;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
}

export interface VariantOption {
  option?: string;
  image?: any;
}

export interface ProductVariants {
  tierIndex?: number[];
  sku?: string;
  weight?: number;
  isActive?: boolean;
  isMainVariant?: boolean;
  stock?: number;
  price?: firebase.firestore.DocumentReference;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
}

export type ProductCategoriesAction = {
  create: "create";
  update: "update";
  delete: "delete";
};

export interface categoryAttribute {
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  createdBy?: firebase.firestore.DocumentReference;
  updatedBy?: firebase.firestore.DocumentReference;
  attributeId?: string;
  inputValues?: any[];
  categoryId?: string[];
  marketplace?: string;
}

export interface ImportProduct {
  parentSKU: string;
  productName: string;
  productDescription: string;
  imageURL_1?: string;
  imageURL_2?: string;
  imageURL_3?: string;
  imageURL_4?: string;
  imageURL_5?: string;
  imageURL_6?: string;
  imageURL_7?: string;
  imageURL_8?: string;
  imageURL_9?: string;
  imageURL_10?: string;
  isDangerousGoods?: boolean;
  packageWeight: number;
  packageLength?: number;
  packageWidth?: number;
  packageHeight?: number;
  hasVariant?: boolean;
  log?: string;
  serverLog?: string;
  variantSKU?: string;
  variantName1?: string;
  variantOption1?: string;
  variantImage1?: string;
  variantName2?: string;
  variantOption2?: string;
  expeditionName?: string;
  expeditions?: (Shipment | undefined)[];
  variantData?: {
    variants?: Variant[];
    productVariants?: ProductVariants[];
  };
  variantCode?: string;
  brandName?: string;
  brand?: firebase.firestore.DocumentReference;
  categoryId: string;
}

export interface ImportProductTemplate {
  parentSKU: string;
  productName: string;
  productDescription: string;
  imageURL_1?: string;
  imageURL_2?: string;
  imageURL_3?: string;
  imageURL_4?: string;
  imageURL_5?: string;
  imageURL_6?: string;
  imageURL_7?: string;
  imageURL_8?: string;
  imageURL_9?: string;
  imageURL_10?: string;
  isDangerousGoods?: boolean;
  packageWeight: number;
  packageLength?: number;
  packageWidth?: number;
  packageHeight?: number;
  hasVariant?: boolean;
  brandName?: string;
  category?: string;
}
