import InitialState, { CampaignMessageListAction } from "./CampaignMessageRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign, CampaignMessage } from "../../../models/Campaign";
import { Timestamp } from "../../../../db";


const initialState: InitialState = {
  listCampaignMessage: [],
  countCampaignMessage: 0,
  currentPageCampaignMessage: 0,
  maxPageCampaignMessage: 0,
  arrayCampaignMessagePagination: [],
  listReportData: []
};


export const CampaignSlice = createSlice({
  name: CampaignMessageListAction,
  initialState: initialState,
  reducers: {
    setListCampaignMessage: (state, action: PayloadAction<CampaignMessage[]>) => {
      state.listCampaignMessage = action.payload;
      state.countCampaignMessage = action.payload.length;
      if(action.payload.length/10 <= 6){
        let arr:string[] = [];
        for (let index = 0; index < Math.round(action.payload.length/10); index++) {
          arr.push((index+1)+"");
        }
        state.arrayCampaignMessagePagination = arr;
      }else{
        state.arrayCampaignMessagePagination = ["1","2","3","4","...",Math.round(action.payload.length/10)+""];
      }
    },

    setArrayCampaignMessagePagination: (state, action: PayloadAction<string[]>) => {
      state.arrayCampaignMessagePagination = action.payload;
    },

    setCountCampaignMessage: (state, action: PayloadAction<number>) => {
      state.countCampaignMessage = action.payload;
    },

    setCurrentPageMessage: (state, action: PayloadAction<number>) => {
      state.currentPageCampaignMessage = action.payload;
    },

    setMaxPageCampaignMessage: (state, action: PayloadAction<number>) => {
      state.maxPageCampaignMessage = action.payload;
    },

    updateListCampaignMessage: (state, action: PayloadAction<CampaignMessage>) => {
      let newListCampaignMessage:CampaignMessage[] =[];
      const newCampaign = action.payload;
      newListCampaignMessage.push(newCampaign);
      
      for (let index = 0; index < state.listCampaignMessage.length; index++) {
        if(state.listCampaignMessage[index].id === newCampaign.id){
          // console.log("Collab ID from Notif: "+state.listCampaignMessage[index].id);
        }else{
          newListCampaignMessage.push(state.listCampaignMessage[index]);
        }
      }
      state.listCampaignMessage = newListCampaignMessage;
      state.countCampaignMessage = newListCampaignMessage.length;
    },

    deleteItemListCampaignMessage: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listCampaignMessage.filter((data) => data.id !== action.payload);
      state.listCampaignMessage = newDataCollab;
      state.countCampaignMessage = newDataCollab.length;
    },
    
    cleanReduxCampaignMessage: (state,action: PayloadAction<boolean>) => {
        state.listCampaignMessage = [];
        state.countCampaignMessage = 0;
        state.currentPageCampaignMessage = 0;
        state.maxPageCampaignMessage = 0;
      },

    setListReportData: (state, action: PayloadAction<any[]>) => {
      state.listReportData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListCampaignMessage,
  deleteItemListCampaignMessage,
  updateListCampaignMessage,
  setCurrentPageMessage,
  setArrayCampaignMessagePagination,
  setMaxPageCampaignMessage,
  setCountCampaignMessage,
  setListReportData,
  cleanReduxCampaignMessage
} = CampaignSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignSlice.reducer;