import { Fragment } from "react";
import { useSelector } from "react-redux";
import ApiOverviewPage from "./ApiOverviewPage";
import ApiOverviewEditPage from "./ApiOverviewEditPage";
const APIOverview = () => {
  const apiSetting = useSelector((state: any) => state.ApiSetting);
  const isEditMode = apiSetting.isEditMode;

  if (isEditMode) {
    return (
      <div data-testid="Api-overview-edit-page">
        <ApiOverviewEditPage />
      </div>
    );
  }
  return (
    <div data-testid="Api-overview-page">
      <ApiOverviewPage />
    </div>
  );
};

export default APIOverview;
