import { t } from "i18next";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../../../setup/redux/store";
import ModalConfirmationMoveToOtherPage from "../../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import { PageLink, PageTitle } from "../../../../../core";

const WABASummary: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: "Setting",
      path: "",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const navigate = useNavigate();

  const dataWABA = useSelector(
    (state: RootState) => state.WABAAccountSignUp.dataWABA
  );

  useEffect(() => {
    if (
      !Object.keys(dataWABA).length ||
      !dataWABA?.waba_id ||
      !dataWABA?.phone_number_id
    ) {
      console.log("dataWABA", dataWABA);
      // return navigate("/setting/waba-account/list");
      window.location.replace("/setting/waba-account/list#");
    }
  }, [dataWABA, navigate]);

  if (
    !Object.keys(dataWABA).length ||
    !dataWABA?.waba_id ||
    !dataWABA?.phone_number_id
  ) {
    return null;
  }

  return (
    <>
      <ModalConfirmationMoveToOtherPage
        path="/setting/waba-account/create/summary"
        secondPath="/setting/waba-account/list"
        paths={["/setting/waba-account/list#", "/setting/waba-account/verify"]}
        onUnsave={() => undefined}

        // onSave={() =>
        //   handleSavedChanges().then(() => {
        //     setIsLoading(false);
        //     dispatch(ReduxProductList.cleanReduxProductList(true));
        //     dispatch(ReduxProduct.cleanReduxProduct(true));
        //   })
        // }
        // onUnsave={() => handleUnsavedChanges()}
      />
      <PageTitle breadcrumbs={UsersBreadcrumbs}>
        {t("WABAAccountList.Button")}
      </PageTitle>

      <div className="d-flex flex-column h-100 pb-5">
        <div className="card mb-1 mb-xl-2 py-4 px-7 d-flex flex-row space">
          <h2 className="w-100 m-0 fw-semibold h1">
            {t("WABAAccount.VerifyingAccount.Title")}
          </h2>
        </div>

        <div
          className="d-flex flex-column card py-7"
          style={{ height: !isTabletOrMobile ? "90%" : "auto" }}
        >
          <div style={{ flexGrow: 1 }} className="overflow-auto px-7">
            <div
              className="fs-4 text-gray-800 mb-8"
              data-testid="explain-paraghraf-summary"
            >
              <p>
                {t("WABAAccount.VerifyingAccount.VerifyingProcess.Explanation")}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center pt-5 px-7">
            <button
              onClick={() => navigate("/setting/waba-account/list")}
              className="btn btn-primary"
            >
              {t("Common.Modal.Column.Button.Done")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WABASummary;
