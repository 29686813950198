interface InitialState {
  accountID: string;
  storefrontID: string;
  storefrontName: string;
  storefrontNameLength: number;
  listMarketplace: [];
  isloading: boolean;
  lastStep: string;
  status: string;
}

const UpdateStorefront: string = "UpdateStorefront";

export default InitialState;

export { UpdateStorefront };
