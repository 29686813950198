import clsx from "clsx";
import { toAbsoluteUrl } from "../../../resources/helpers";
import { HeaderUserMenu } from "../header/header-menus/HeaderUserMenu";
import * as lc from "../../modules/localstorage/index";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

type UserItem = {
  isSidebarMinimized: boolean;
};

const UserItem = ({ isSidebarMinimized }: UserItem) => {
  const [isShouldDisplayBalance, setIsShouldDisplayBalance] =
    useState<boolean>(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const toolbarButtonMarginClass = "ms-1 ms-lg-3",
    toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

  const client = lc.getItemLC(lc.LCName.Client);
  const user = lc.getItemLC(lc.LCName.UserData);

  let userName = user && user.name ? user.name : "7days User";
  if (userName.length > 20) {
    userName = userName.substring(0, 17) + "...";
  }

  let clientName =
    client && client.clientName ? client.clientName : "Unnamed Client";
  if (clientName.length > 20) {
    clientName = clientName.substring(0, 17) + "...";
  }

  useEffect(() => {
    setIsShouldDisplayBalance(isSidebarMinimized);
  }, [isSidebarMinimized]);

  return (
    <div key={"aside-user-item"}>
      {isShouldDisplayBalance ? (
        <div
          data-testid="AsideUserItemAvatar"
          className={clsx(
            "d-flex cursor-pointer symbol align-items-center justify-content-center pb-2 mt-0 pt-0",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img
            id="active_user_avatar"
            data-testid="AsideUserItemAvatarImage"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "0.3px solid lightgray",
            }}
            src={toAbsoluteUrl(
              user && user?.avatar !== ""
                ? user?.avatar
                : "/media/icons/avatar/user-icon.png"
            )}
            alt="7daysUser"
            className="mh-35px mw-35px shadow"
          />
        </div>
      ) : (
        <div
          className="py-3 mx-4 my-4 m-0 menu-item text-wrap bg-light"
          style={{
            borderRadius: "500px",
          }}
        >
          <div
            className={clsx(
              "d-flex flex-row align-items-center",
              toolbarButtonMarginClass
            )}
            id="kt_header_user_menu_toggle"
          >
            {/* begin::Toggle */}
            <div
              // to={'/profile'}
              data-testid="AsideUserItemAvatar"
              className={clsx(
                "d-flex cursor-pointer symbol gap-3",
                toolbarUserAvatarHeightClass
              )}
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
              // onMouseOver={handleMouseOver}
              // onMouseOut={handleMouseOut}
            >
              <img
                id="active_user_avatar"
                data-testid="AsideUserItemAvatarImage"
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                src={toAbsoluteUrl(
                  user && user?.avatar !== ""
                    ? user?.avatar
                    : "/media/icons/avatar/user-icon.png"
                )}
                alt="7daysUser"
                className="mh-40px mw-40px"
              />
              <div className="d-flex flex-column menu-title">
                <span data-testid="AsideUserItemUsername" className="fw-bolder">
                  {userName}
                </span>
                <span data-testid="AsideUserItemUserClient">{clientName}</span>
              </div>
            </div>
            {/* <HeaderUserMenu /> */}
            {/* end::Toggle */}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserItem;
