import { useQueryClient, useMutation } from "react-query";
import { QUERIES } from "../../../../../../resources/helpers";
import { useListView } from "../../../../core/list/ListViewProvider";
import { useQueryResponse } from "../../../../core/list/QueryResponseProvider";
import { useQueryRequest } from "../../../../core/list/QueryRequestProvider";
// import {handledCollabs} from '../../core/_requests'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Template from "../../../../../../app/modules/template/list/TemplateListSlice";
import * as ReduxTemplate from "../../../../../modules/template/redux/TemplateSlice";
import * as ReduxCampaign from "../../../../../modules/campaign/redux/CampaignSlice";
import { templateState } from "../../../../../models/Template";
import { Link } from "react-router-dom";
// import * as lc from '../../../modules/localstorage/index'

const TemplateGrouping = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected, clearSelected } = useListView();
  const queryClient = useQueryClient();
  const { query } = useQueryResponse();
  const { state, updateState } = useQueryRequest();

  // const callback  = (responseId: string[]) => {
  //   if(responseId && responseId.length > 0 ){
  //     for (let index = 0; index < responseId.length; index++) {
  //       const id = responseId[index];

  //       if(id && id!=null){
  //         Chat.fetchCollaborationByID(id.toString())
  //           .then((collab) => {
  //             if (collab) {
  //               // console.log("response fetchCollaborationByID" + JSON.stringify(collab));
  //               dispatch(chat.updateChatList(collab));
  //             }
  //           });
  //       }

  //     }
  //   }
  // }

  // const updateSelectedItems = useMutation(() => handledCollabs(selected,t("CIQ.Error.DoubleHandled"),
  // (id:string[]) => {
  //   //Callback message send
  //   callback(id);
  // }
  // ), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     // queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     updateState({sort:(state.sort === 'delete' ? 'asc' : 'delete' ), items_per_page: state.items_per_page, page: 1, action: "noAction"})

  //     clearSelected()
  //     // return updateState({sort:(state.sort === 'delete' ? 'asc' : 'delete' ), items_per_page: state.items_per_page, page: 1, action: "noAction"})
  //   },
  // })

  return (
    <div className="d-flex justify-content-end align-items-center me-3">
      {/* <div className='fw-bolder me-5'>
        {selected.length > 0 ? <span className='me-2'>{selected.length} {t("CIQ.Info.Selected")}</span> : null}
      </div> */}

      {/* begin::Filter */}
      <button
        type="button"
        className="btn btn-light-primary me-2"
        onClick={() => {
          dispatch(Template.cleanReduxTemplate(true));
        }}
      >
        {" "}
        <i className="bi bi-arrow-clockwise"></i>
      </button>
      <button type="button" className="btn btn-light-primary me-3" disabled>
        <span className="bi bi-funnel-fill" />
        {t("Common.Filter")}
      </button>
      {/* end::Filter */}

      {/* begin:: Create Template */}
      {/* <Link
        className="btn btn-primary"
        to="/template/create"
        onClick={() => {
          dispatch(ReduxTemplate.cleanReduxTemplate(true));
          dispatch(ReduxCampaign.cleanReduxCampaign(true));
          dispatch(ReduxTemplate.setTemplateID(""));
          dispatch(ReduxTemplate.setTemplateState(templateState.created));
          dispatch(ReduxCampaign.setCampaignTemplateFinish(false));
        }}
      >
        <span>
          <i className="bi bi-plus-lg fs-4 me-2"></i>
          {t("Campaign.Info.CreateTemplate")}
        </span>
      </Link> */}

      <Link
        className="btn btn-primary"
        to="/template/create"
        onClick={() => {
          dispatch(ReduxTemplate.cleanReduxTemplate(true));
          dispatch(ReduxCampaign.cleanReduxCampaign(true));
          dispatch(ReduxTemplate.setTemplateID(""));
          dispatch(ReduxTemplate.setTemplateState(templateState.created));
          dispatch(ReduxCampaign.setCampaignTemplateFinish(false));
        }}
      >
        <span>
          <i className="bi bi-plus-lg fs-4 me-2"></i>
          {t("Campaign.Info.CreateTemplate")}
        </span>
      </Link>
    </div>
  );
};

export { TemplateGrouping };
