import clsx from "clsx";
import Picker from "emoji-picker-react";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import * as servAccount from "../../../../../db/serviceAccount";
import * as servTemplate from "../../../../../db/serviceTemplate";
import { useDebounce } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/color.css";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/toggle.scss";
import { KeyValues } from "../../../../../util/Formatter";
import { convertTextStyle } from "../../../../../util/TextFormatter";
import Language, {
  BodyParamLanguage,
  BodyParamLanguage as HeaderParamLanguage,
  templateStatus,
} from "../../../../models/Language";
import { templateState } from "../../../../models/Template";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import PreviewMessage from "../../../core/preview/PreviewMessage";
import { kMaxLength } from "buffer";
import async from "react-select/dist/declarations/src/async/index";
//import from local storage

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  generateLanguage: (
    key: string,
    value: any,
    paramcount: number,
    filename: string,
    bodyParams: BodyParamLanguage[] | undefined,
    headerParams: HeaderParamLanguage[] | undefined
  ) => void;
  generateTemplate: (
    key: string,
    value: any,
    companies?: string[],
    accountsid?: string[]
  ) => void;
}

type MessageParameter = {
  item: string;
  label: string;
};

const TemplateProcess: FC<TemplateProps> = ({
  onChanges,
  generateLanguage,
  generateTemplate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exp, setExp] = useState<number>(5);
  const [secrec, setSecrec] = useState<boolean>(false);
  const [expcode, setExpcode] = useState<boolean>(false);
  const test_recux = useSelector((state: RootState) => state.Template);
  const reduxTemplateBodyParams = useSelector(
    (state: RootState) => state.Template.templateBodyParams
  );
  const reduxTemplateLanguage = useSelector(
    (state: RootState) => state.Template.generatedLanguage
  );
  const reduxTemplateID = useSelector(
    (state: RootState) => state.Template.templateID
  );
  const reduxBodyMessage = useSelector(
    (state: RootState) => state.Template.templateBodyMessage
  );
  const reduxBodyMessageLength = useSelector(
    (state: RootState) => state.Template.templateBodyMessageLength
  );
  const reduxTemplateState = useSelector(
    (state: RootState) => state.Template.templateState
  );
  const reduxHeaderURL = useSelector(
    (state: RootState) => state.Template.templateHeaderURL
  );
  const headerParams = useSelector(
    (state: RootState) => state.Template.templateHeaderParams
  );
  const reduxEmptyBodyMessageAlert = useSelector(
    (state: RootState) => state.Template.templateEmptyBodyMessageAlert
  );
  const reduxEmptyCustomTextlert = useSelector(
    (state: RootState) => state.Template.templateEmptyCustomTextAlert
  );
  const templateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  const duplicateParamName = useSelector(
    (state: RootState) => state.Template.alertDuplicateParamName
  );
  const alertDuplicateParamNameInBody = useSelector(
    (state: RootState) => state.Template.alertDuplicateParamNameInBody
  );

  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [showWarningBodyMessage, setShowWarningBodyMessage] =
    useState<boolean>(false);
  const [addOnButton, setAddOnButton] = useState<boolean>(false);
  let option: any = KeyValues;
  const [lengthBodyMessage, setLengthBodyMessage] = useState<number>(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEmojiPickerBodyParam, setShowEmojiPickerBodyParam] =
    useState(false);
  const [activeEmojiPickerBodyParam, setActiveEmojiPickerBodyParam] =
    useState(0);
  const myRef = useRef<HTMLTextAreaElement>(null);
  const [, setCustoParamId] = useState<string>("");
  const [, setHeaderText] = useState<string>("");
  const [previewHeaderText, setPreviewHeaderText] = useState<string>("");
  const [, setTemplateFooter] = useState<string>("");
  const [, setPreviewFooterText] = useState<string>("");
  const [headerURL, setHeaderURL] = useState<string>("");
  const [, setLengthFooter] = useState<number>(0);
  const [, setIsAttachmentHeader] = useState<boolean>(false);
  const [, setFileType] = useState<string>("");
  const [, setIsTextHeader] = useState<boolean>(false);
  const [, setSelectedTemplateHeader] = useState<string>("");
  const campaignTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  const reduxLoading = useSelector(
    (state: RootState) => state.Template.isloading
  );
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [listParameter, setListParameter] = useState<MessageParameter[]>([]);
  const [selectedParameter, setSelectedParameter] =
    useState<MessageParameter>();
  const [inputParamName, setInputParamName] = useState<string>("");
  const [alertEmptyParamName, setAlertEmptyParamName] =
    useState<boolean>(false);

  const customStyles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "white"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "#0275d8" // Background color on hover or when option is selected
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
      color: "#1e2022", // Color of the selected value
    }),
  };

  useEffect(() => {
    if (templateCategory === "authentication") {
      let temp_data = Object.assign(
        {},
        reduxTemplateLanguage,
        secrec === true ? { addSecurityRecommendation: secrec } : {},
        expcode === true ? { code_expiration_minutes: exp } : {}
      );
      dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
    }
  }, [secrec, exp]);

  useEffect(() => {
    if (templateCategory === "interactive") {
      const NewlistDataParameter = listParameter.filter(
        (item) => item.item !== "Campaign's Name"
      );
      console.log(
        `new list data parameter: ${JSON.stringify(NewlistDataParameter)}`
      );
      setListParameter(NewlistDataParameter);
    } else {
      const listDataParameter: MessageParameter[] = Object.keys(option).map(
        (value, index, array) => {
          return {
            item: option[value],
            label: t("Campaign.Input." + array[index]),
          };
        }
      );
      setListParameter(listDataParameter);
      if (templateCategory === "authentication" && secrec === false) {
        onChangeBodyOTP(
          `{{${t("Campaign.Input.template.otp")}}} ${t(
            "Campaign.Input.template.otp.text"
          )}`
        );
      } else {
        onChangeBodyOTP("");
      }
    }
  }, [templateCategory]);

  useEffect(() => {
    if (campaignTemplateFinish) {
      document
        .getElementById("bodyMessage")
        ?.setAttribute("readonly", "readonly");
      // document.getElementById("addHeaderAndFooter")?.classList.add("disabled");
      // document.getElementById("addBodyParams")?.classList.add("disabled");
      setBodyMessage(reduxBodyMessage);
      setLengthBodyMessage(reduxBodyMessageLength);
      setAddOnButton(false);
    }
  }, [campaignTemplateFinish]);

  useEffect(() => {
    console.log(
      "CHECK USE EFFECT UNTUK TEMPLATE UPDATE  ==================================================="
    );
    //set setListParameter
    if (listParameter.length <= 0) {
      const listDataParameter: MessageParameter[] = Object.keys(option).map(
        (value, index, array) => {
          return {
            item: option[value],
            label: t("Campaign.Input." + array[index]),
          };
        }
      );
      if (listDataParameter) {
        setListParameter(listDataParameter);
      }
    }

    // let url = document.location.href;
    // const parsed = (url: any) => {
    //   let urlParsed = new URL(url);
    //   return urlParsed.pathname.substring(17);
    // };Cu

    // const templateId = parsed(url);
    console.log("Temlate ID yang akan di update adalah: " + reduxTemplateID);
    myRef.current?.focus();

    if (
      reduxTemplateState &&
      reduxTemplateID !== "" &&
      reduxTemplateState === templateState.update
    ) {
      if (reduxTemplateID && reduxTemplateID !== "") {
        // dispatch(ReduxTemplate.cleanReduxTemplate(true));
        //Process Data Template
        // const templateObj =
        servTemplate.get(reduxTemplateID).then((templateObject) => {
          if (templateObject) {
            dispatch(
              ReduxTemplate.setTemplateCategory(templateObject.category!)
            );
            const companiesID = templateObject.companies?.map(
              (item) => item.id
            );
            const accountsID = templateObject.accounts?.map((item) => item.id);
            dispatch(ReduxTemplate.setTemplateCompaniesID(companiesID));
            dispatch(ReduxTemplate.setAccountsID(accountsID));
            // dispatch(
            //   ReduxTemplate.setTemplateCompanyID(templateObject.company?.id)
            // );
            dispatch(
              ReduxTemplate.setTemplateName(templateObject.templateName!)
            );
            // dispatch(ReduxTemplate.setAccountID(templateObject.account?.id));
            dispatch(ReduxTemplate.setGeneratedTemplate(templateObject));
            // dispatch(ReduxTemplate.setGeneratedLanguage(templateObject.languages));
            // const accountID = templateObject.account?.id;
            // console.log("ISI DARI TEMPLATE OBJECT ", templateObject);
            // if (accountID) {
            //   console.log("Check data service account: " + accountID);
            //   const dataAccount = servAccount
            //     .getAccountByID(accountID)
            //     .then((account) => {
            //       console.log(
            //         "Check data temlate untuk account: " +
            //           JSON.stringify(account)
            //       );
            //       if (account) {
            //         dispatch(
            //           ReduxTemplate.setTemplateWABussinessID(
            //             account.whatsappBusinessAccount_ID
            //           )
            //         );
            //         dispatch(
            //           ReduxTemplate.setTemplateWAAccessToken(
            //             account.access_token
            //           )
            //         );
            //       }
            //     });
            // }
          }
        });

        //Process Data Language
        // const languageTemplate =
        servTemplate
          .getTemplateLanguages(reduxTemplateID)
          .then((listLanguage) => {
            console.log(
              "Result Get Template By ID : " + JSON.stringify(listLanguage)
            );
            if (listLanguage && listLanguage.length > 0) {
              //todo
              // dispatch(ReduxTemplate.setGeneratedLanguage(languageTemplate));
              const newLanguage = listLanguage.map((language) => {
                return language as Language;
              });
              console.log(
                "------------------------>>>> Check Data Template : " +
                  JSON.stringify(newLanguage)
              );

              // Choose Template with current Language
              const templateWithLanguage = listLanguage[0];

              if (
                templateWithLanguage.status &&
                (templateWithLanguage.status === templateStatus.approved ||
                  templateWithLanguage.status === templateStatus.rejected ||
                  templateWithLanguage.status === templateStatus.paused ||
                  templateWithLanguage.status === templateStatus.pending)
              ) {
                // Remove Pending
                console.log("Process To Update");
              } else {
                console.log(
                  "Edit Template - Error : template status invalid (" +
                    templateWithLanguage.status +
                    ")"
                );
                const failedTextAlert = document.getElementById(
                  "failedProcessAlertNotif"
                ) as HTMLDivElement;
                failedTextAlert.innerHTML = t("ET.Error.TemplateStatus");
                const buttonFailedAlert = document.getElementById(
                  "triggerFailedProcess"
                );
                if (buttonFailedAlert) {
                  buttonFailedAlert.click();
                }
              }

              let languageId = templateWithLanguage?.id;
              let bodyParam = templateWithLanguage?.bodyParam;
              let body = templateWithLanguage?.body!;
              let header = templateWithLanguage?.header;
              let footer = templateWithLanguage?.footer;
              let buttons = templateWithLanguage?.buttons;
              let responseTemplateID = templateWithLanguage?.responseTemplateID;
              dispatch(ReduxTemplate.setTemplateID(reduxTemplateID));
              dispatch(ReduxTemplate.setTemplateLanguageID(languageId));
              console.log(
                `Data Template : 
                  1. Body Params : ${bodyParam}
                  2.Body : ${body}
                  3.Header : ${header}
                  4. Footer : ${footer}
                  5. Response Template ID: ${responseTemplateID}
                  6. Buttons: ${JSON.stringify(buttons)}`
              );

              if (templateWithLanguage === undefined || body === undefined) {
                const failedTextAlert = document.getElementById(
                  "failedProcessAlertNotif"
                ) as HTMLDivElement;
                //Perlu Pesan Failed, sudah melebihi batas update template harian (1x update)
                failedTextAlert.innerHTML = t("UFL.Alert.AddUser").replace(
                  `<<name>>`,
                  "abc"
                );
                const buttonFailedAlert = document.getElementById(
                  "triggerFailedProcess"
                );
                if (buttonFailedAlert) {
                  buttonFailedAlert.click();
                  // nav("/template/list");
                }
              }

              console.log(
                "Ini tempate test pilihan text1: " + JSON.stringify(header)
              );
              // dispatch(ReduxTemplate.setGeneratedLanguage(templateWithLanguage));

              console.log(
                "Check Data Sebelumbnya awal : " + JSON.stringify(newLanguage)
              );
              if (responseTemplateID) {
                dispatch(
                  ReduxTemplate.setResponseTemplateID(responseTemplateID)
                );
              }

              if (body) {
                dispatch(ReduxTemplate.setTemplateBodyMessage(body));
              }

              if (footer) {
                setTemplateFooter(footer);
                setLengthFooter(footer.length);
                let newFooterText = footer;
                setPreviewFooterText(newFooterText);
                dispatch(ReduxTemplate.setTemplateFooter(footer));
              }

              if (buttons) {
                dispatch(ReduxTemplate.setTemplateButton(buttons));
              }

              if (header) {
                console.log("Header type stored");
                let mediaType = header.mediaType;
                let type = header.type;
                let inHeaderURL = "";
                if (header.headerURL) {
                  inHeaderURL = header.headerURL;
                }
                let text = header.text;
                let headerParam = header.headerParam;
                console.log("Check header file name: " + inHeaderURL);
                console.log(
                  "ini isi dari preview text header: " + previewHeaderText
                );
                if (mediaType) {
                  setSelectedTemplateHeader(`header-${mediaType}`);
                  setHeaderURL(inHeaderURL!.toString());
                  dispatch(ReduxTemplate.setTemplateHeaderURL(inHeaderURL));
                  dispatch(ReduxTemplate.setTemplateFileType(mediaType));
                  setFileType(mediaType);
                  setIsAttachmentHeader(true);
                  console.log("ini adalah isi header URL: " + headerURL);
                  console.log(
                    "ini adalah isi reduxHeaderURL: " + reduxHeaderURL
                  );
                } else if (type === "text") {
                  console.log(`template process current text ${text}`);
                  setSelectedTemplateHeader("header-text");
                  setIsTextHeader(true);
                  let previewHeaderText = text;
                  if (headerParam) {
                    dispatch(
                      ReduxTemplate.setTemplateHeaderParams(headerParam)
                    );
                    console.log(
                      "Check header param in text type: " +
                        JSON.stringify(headerParam)
                    );
                    /** TODO revisit on edit */
                    for (let index = 0; index < headerParam.length; index++) {
                      let newHeaderText: BodyParamLanguage = headerParam[index];
                      const paramId = newHeaderText.paramId;
                      const paramType = newHeaderText.paramType;
                      const paramKey = newHeaderText.paramKey!;
                      const paramTextContent = newHeaderText.textContent;
                      const paramName = newHeaderText.paramName
                        ? newHeaderText.paramName
                        : "";
                      const translate =
                        paramName !== ""
                          ? paramName
                          : t(
                              "Campaign.Input." +
                                (paramType === "customText"
                                  ? "CustomizeText"
                                  : paramKey)
                            );
                      text = text.replaceAll(
                        "{{" + paramId + "}}",
                        "{{" + translate + "}}"
                      );
                      console.log(`text :  ${text}`);
                      let newPreviewHeaderText = text;
                      if (
                        paramType === "customText" &&
                        paramTextContent &&
                        paramTextContent !== ""
                      ) {
                        newPreviewHeaderText = newPreviewHeaderText.replaceAll(
                          `{{${translate}}}`,
                          paramTextContent
                        );
                      } else {
                        newPreviewHeaderText = newPreviewHeaderText.replaceAll(
                          "{{" + translate + "}}",
                          `<span className="badge badge-primary fw-bolder fs-7 m-1"><i className="bi bi-server pe-2 text-white"></i>${
                            paramName && paramName !== ""
                              ? paramName
                              : t(
                                  "Campaign.Input." +
                                    (paramType === "customText"
                                      ? "CustomizeText"
                                      : newHeaderText.paramKey!)
                                )
                          }</span>`
                        );
                      }
                      console.log(
                        `new preview header text: ${newPreviewHeaderText}`
                      );
                      previewHeaderText = newPreviewHeaderText;
                    }
                  }
                  console.log(
                    `isi text sekarang ${text} dan preview ${previewHeaderText}`
                  );
                  setHeaderText(text);
                  dispatch(
                    ReduxTemplate.setTemplatePreviewHeaderText(
                      previewHeaderText
                    )
                  );
                  setPreviewHeaderText(convertTextStyle(previewHeaderText));
                }
                dispatch(ReduxTemplate.setTemplateHeader(header));
              }

              let newBodyMessage = body;
              let newPreviewBody = newBodyMessage;
              if (bodyParam && bodyParam.length > 0) {
                dispatch(ReduxTemplate.setTemplateBodyParams(bodyParam));
                for (let index = 0; index < bodyParam.length; index++) {
                  let bodyParamI = bodyParam[index];
                  const translate =
                    bodyParamI.paramName && bodyParamI.paramName !== ""
                      ? bodyParamI.paramName
                      : t(
                          "Campaign.Input." +
                            (bodyParamI.paramType === "customText"
                              ? "CustomizeText"
                              : bodyParamI.paramKey)
                        );
                  newBodyMessage = newBodyMessage.replaceAll(
                    "{{" + bodyParamI.paramId + "}}",
                    "{{" + translate + "}}"
                  );
                  console.log(
                    `${bodyParamI.paramId.toString()} to be replace : ${translate}`
                  );
                  console.log(`newPreviewBody before : ${newPreviewBody}`);
                  newPreviewBody = newPreviewBody.replace(
                    bodyParamI.paramId.toString(),
                    translate!
                  );
                  console.log(`newPreviewBody after : ${newPreviewBody}`);
                  newPreviewBody = replaceMessageForPreview(
                    newPreviewBody,
                    bodyParamI
                  );
                  console.log(`newPreviewBody : ${newPreviewBody}`);
                }

                console.log("Load preview >>> " + newPreviewBody);
                dispatch(
                  ReduxTemplate.setTemplatePreviewBody(
                    convertTextStyle(newPreviewBody)
                  )
                );

                console.log(
                  "Check Template Body Params : " + JSON.stringify(bodyParam)
                );
                console.log(
                  "Check Template Body Message : " +
                    JSON.stringify(newBodyMessage)
                );
                console.log("Body Param 6 : " + bodyParam);
                // dispatch(ReduxTemplate.setTemplateBodyParams(bodyParam));
                // dispatch(ReduxTemplate.setTemplateBodyMessage(bodyMessage));
                if (newBodyMessage && newBodyMessage !== "") {
                  console.log("ini masuk untuk true kan addonbutton");
                  // checkBodyParamEmptyCustomText();
                  setAddOnButton(!campaignTemplateFinish);
                  setBodyMessage(newBodyMessage);
                  generatePreviewBodyMessage(
                    undefined,
                    newBodyMessage,
                    templateWithLanguage,
                    bodyParam
                  );
                  console.log("End Of Call Function");
                } else {
                  setAddOnButton(false);
                }
              } else {
                if (newBodyMessage && newBodyMessage !== "") {
                  setAddOnButton(!campaignTemplateFinish);
                  setBodyMessage(newBodyMessage);
                  generatePreviewBodyMessage(
                    undefined,
                    newBodyMessage,
                    templateWithLanguage
                  );
                } else {
                  setAddOnButton(false);
                }
              }
            } else {
              //alert Message exceeds the limit of the number of updates
              const failedTextAlert = document.getElementById(
                "failedProcessAlertNotif"
              ) as HTMLDivElement;
              //Perlu Pesan Failed, sudah melebihi batas update template harian (1x update)
              failedTextAlert.innerHTML = "update gagal silahkan coba kembali";
              const buttonFailedAlert = document.getElementById(
                "triggerFailedProcess"
              );
              if (buttonFailedAlert) {
                buttonFailedAlert.click();
              }
            }
          });
      }
    } else if (
      reduxTemplateState &&
      reduxTemplateID !== "" &&
      reduxTemplateState === templateState.campaigncreated
    ) {
      if (reduxBodyMessage && reduxBodyMessage !== "") {
        setBodyMessage(reduxBodyMessage);
      }
    }
    if (campaignTemplateFinish) {
      // document.getElementById("addHeaderAndFooter")?.classList.add("disabled");
    }
    // myRef.current?.focus();
  }, []);

  const onChangeBodyMessage = async (event: any) => {
    let newBodyMessage: string = event.target.value;
    let checkBodyMessage = newBodyMessage.trim();
    //Limitation for no special character function
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const isHasSpecialCharInput = format.test(newBodyMessage);
    console.log(
      "bodyMessage is include special character?: " + isHasSpecialCharInput
    );
    if (isHasSpecialCharInput === false || checkBodyMessage === "") {
      if (newBodyMessage.length >= 50) {
        const failedTextAlert = document.getElementById(
          "failedProcessAlertNotif"
        ) as HTMLDivElement;
        if (failedTextAlert)
          failedTextAlert.innerHTML = t("HC.Error.InvalidFiletype");
        const buttonFailedAlert = document.getElementById("triggerFailed");
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
        return;
      }
    }
    setBodyMessage(newBodyMessage);
    if (newBodyMessage && newBodyMessage !== "") {
      dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(false));
      if (campaignTemplateFinish) {
        setAddOnButton(false);
      } else {
        setAddOnButton(true);
      }
    } else {
      setAddOnButton(false);
    }
    if (campaignTemplateFinish) {
      // document.getElementById("addHeaderAndFooter")?.classList.add("disabled");
    }
    // console.log("--->>> 1. "+newBodyMessage.replaceAll("\n","\\n"));
    // await dispatch(ReduxTemplate.setTemplateBodyMessage(newBodyMessage));
    await generatePreviewBodyMessage("", newBodyMessage, "");
  };

  const onChangeBodyOTP = async (event: any) => {
    let newBodyMessage: string = event;
    let checkBodyMessage = newBodyMessage.trim();
    //Limitation for no special character function
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const isHasSpecialCharInput = format.test(newBodyMessage);
    console.log(
      "bodyMessage is include special character?: " + isHasSpecialCharInput
    );
    if (isHasSpecialCharInput === false || checkBodyMessage === "") {
      if (newBodyMessage.length >= 50) {
        const failedTextAlert = document.getElementById(
          "failedProcessAlertNotif"
        ) as HTMLDivElement;
        if (failedTextAlert)
          failedTextAlert.innerHTML = t("HC.Error.InvalidFiletype");
        const buttonFailedAlert = document.getElementById("triggerFailed");
        if (buttonFailedAlert) {
          buttonFailedAlert.click();
        }
        return;
      }
    }
    setBodyMessage(newBodyMessage);
    if (newBodyMessage && newBodyMessage !== "") {
      dispatch(ReduxTemplate.setTemplateEmptyBodyMessageAlert(false));
      if (campaignTemplateFinish) {
        setAddOnButton(false);
      } else {
        setAddOnButton(true);
      }
    } else {
      setAddOnButton(false);
    }
    if (campaignTemplateFinish) {
      // document.getElementById("addHeaderAndFooter")?.classList.add("disabled");
    }
    // console.log("--->>> 1. "+newBodyMessage.replaceAll("\n","\\n"));
    // await dispatch(ReduxTemplate.setTemplateBodyMessage(newBodyMessage));
    await generatePreviewBodyMessage("", newBodyMessage, "");
  };

  const onAddMessageParam = async () => {
    const e: any = selectedParameter;

    if (e !== null && Object.keys(e).length !== 0) {
      const paramValue = e.value;
      const paramText = e.label;

      let textVal = myRef.current;
      console.log(textVal, "textVal");

      if (paramValue !== "0") {
        //Input at typer standing
        let cursorStart = textVal?.selectionStart;
        let cursorEnd = textVal?.selectionEnd;
        if (cursorStart !== undefined && cursorEnd !== undefined) {
          let selectedText = bodyMessage.substring(cursorStart, cursorEnd);
          let selectedStart = bodyMessage.substring(0, cursorStart);
          let selectedEnd = bodyMessage.substring(
            cursorEnd,
            bodyMessage.length
          );
          let resultText =
            selectedStart +
            "" +
            selectedText +
            "{{" +
            paramText +
            "}}" +
            selectedEnd;
          // let resultTextBody = convertTextStyle(resultText);
          // setBodyMessage(resultText);
          // await dispatch(ReduxTemplate.setTemplateBodyMessage(resultText));
          await generatePreviewBodyMessage(inputParamName, resultText, "");
          setInputParamName("");
        }
        // Origin code
        // let newBodyMessage = bodyMessage+"{{"+paramText+"}}";
        // setBodyMessage(newBodyMessage);
        // generatePreviewBodyMessage(newBodyMessage);
      }
    }
  };

  const replaceMessageForPreview = (
    previewBody: string,
    bodyParam: BodyParamLanguage
  ): string => {
    let newPreviewBody = previewBody;
    console.log(`CHECK bodyParam.paramType ${bodyParam.paramType}`);
    if (bodyParam.paramType === "customText") {
      const newCustomText =
        bodyParam.textContent && bodyParam.textContent !== ""
          ? bodyParam.textContent
          : bodyParam.paramName
          ? bodyParam.paramName
          : t("");
      const textToReplace = bodyParam.paramName
        ? bodyParam.paramName
        : t("Campaign.Input.CustomizeText");
      newPreviewBody = newPreviewBody.replace(
        "{{" + textToReplace + "}}",
        `<div data-testid="preview-body-parameter" class="bg-primary d-flex align-items-center py-2 px-2 text-break rounded fs-7 fw-bolder lh-1 text-white">
          <i class="bi bi-server pe-2 text-white"></i>
          <span class="text-start d-block" style="width: 90%;">${newCustomText}</span>
        </div>`
      );
    } else {
      newPreviewBody = newPreviewBody.replaceAll(
        "{{" + t("Campaign.Input." + bodyParam.paramType) + "}}",
        `<span data-testid="preview-body-parameter" class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
          "Campaign.Input." + bodyParam.paramType
        )}</span>`
      );
    }

    return newPreviewBody;
  };

  const onCustomParamChanges = async (
    text: string,
    paramNo: number,
    paramName: string
  ) => {
    console.log("Inserted value: " + text);
    console.log("Inserted value paramno: " + paramNo);
    console.log("Inserted value paramName: " + paramName);

    const filteredBodyParams: BodyParamLanguage[] =
      reduxTemplateBodyParams.filter((bodyParam) => {
        return bodyParam.paramId !== paramNo;
      });
    console.log(
      `Check Hasil Filter Body Param : ${JSON.stringify(filteredBodyParams)}`
    );

    let tempParams = [...filteredBodyParams];
    console.log(tempParams, "tempParams before tmps");

    // let customTextValue = text.replaceAll("\n","<br/>");
    // let customTextValue = text.replaceAll("\n",`\n`);
    let customTextValue = text;

    let customParam = {
      paramId: paramNo,
      paramKey: "customText" + paramNo, //"customText",
      paramValue: `${t("Campaign.Input.CustomizeText")}`,
      paramType: "customText",
      textContent: customTextValue ? customTextValue : "",
      paramName: paramName,
    };
    console.log(customParam, "customParam tmps");
    console.log(paramName, "paramName tmps");

    tempParams.push(customParam);
    tempParams = tempParams.sort((a, b) => (a.paramId > b.paramId ? 1 : -1));

    let newPreviewBody = reduxBodyMessage;
    console.log(reduxBodyMessage, "reduxbodymessage before tmps");

    for (let index = 0; index < tempParams.length; index++) {
      const bodyParam = tempParams[index];
      if (bodyParam.paramName) {
        newPreviewBody = newPreviewBody.replace(
          t("Campaign.Input.CustomizeText"),
          bodyParam.paramName
        );
      }
      console.log(bodyParam, "bodyPaeam tmps");
      // dispatch(ReduxTemplate.setTemplateBodyMessage(newPreviewBody))
      console.log(reduxBodyMessage, "reduxbodymessage after tmps");
      console.log(newPreviewBody, "newPreviewBodyMessage before replace tmps");

      newPreviewBody = replaceMessageForPreview(newPreviewBody, bodyParam);
    }
    console.log(newPreviewBody, "newPreviewBodyMessage after replace tmps");

    newPreviewBody = newPreviewBody.replaceAll("\n", "<br/>");
    dispatch(
      ReduxTemplate.setTemplatePreviewBody(convertTextStyle(newPreviewBody))
    );
    console.log("CHECK tempParams >>>>>", tempParams);
    dispatch(ReduxTemplate.setTemplateBodyParams(tempParams));
    console.log(tempParams, "tempsparams after tmps");

    //Generate Language
    let bodyParam = reduxBodyMessage
      .split("{{")
      .filter((val) => val.includes("}}"))
      .map((val) => val.substring(0, val.indexOf("}}")));
    let templateLanguange = reduxBodyMessage;
    for (let index = 0; index < bodyParam.length; index++) {
      const element = bodyParam[index];
      templateLanguange = templateLanguange.replace(
        new RegExp(`{{${element}}}`, "g"),
        `{{${index + 1}}}`
      );
    }
    console.log(`tmps templateLanguange : `, templateLanguange);
    updateOnChangeData("bodyMessage", reduxBodyMessage);
    updateOnChangeData("previewBody", newPreviewBody);
    console.log("masuk check body param empty custom disini");
    checkBodyParamEmptyCustomText(tempParams, headerParams);
    generateLanguage(
      "body",
      templateLanguange,
      tempParams.length,
      "",
      tempParams,
      undefined
    );
    console.log(headerParams, "headerParams tmps");
  };

  type Param = {
    text: string;
    paramNo: number;
    paramName: string;
  };
  const [parameter, setParameter] = useState<Param>();
  const debounceParam = useDebounce(parameter, 500);
  useEffect(() => {
    if (debounceParam)
      onCustomParamChanges(
        debounceParam.text,
        debounceParam.paramNo,
        debounceParam.paramName
      );
  }, [debounceParam]);

  const generatePreviewBodyMessage = async (
    paramName: string | undefined,
    newBodyMessage: string,
    generatedLanguage: any,
    bodyParams: Array<any> | undefined = undefined
  ) => {
    console.log(`Generate Preview Body Message : ${newBodyMessage}`);
    let newPreviewBody = newBodyMessage;
    //Process Save To Redux Body Params
    let arrBodyParam: any[] = [];
    let bodyParam = newBodyMessage
      .split("{{")
      .filter((val) => val.includes("}}"))
      .map((val) => val.substring(0, val.indexOf("}}")));
    if (bodyParam.length > 0) {
      for (let index = 0; index < bodyParam.length; index++) {
        const element = bodyParam[index];
        const keyParam = Object.keys(option).find((value, index, array) => {
          return t("Campaign.Input." + array[index]) === element;
        });

        let tempBodyParams = bodyParams ? bodyParams : reduxTemplateBodyParams;
        let newParam = tempBodyParams.find((param) => {
          return param.paramId === index + 1;
        });
        let paramNameValue = "";
        if (keyParam && keyParam !== "") {
          let text = "";
          let type = `${
            keyParam === "CustomizeText" ? "customText" : keyParam
          }`;
          console.log("ini type > " + type);
          if (newParam && newParam.paramType && newParam.paramType === type) {
            console.log(
              "ini masuk ubah text content >>>" + newParam.textContent
            );
            if (newParam.textContent) {
              text = newParam.textContent;
            }
            if (newParam.paramName) {
              console.log(`masuk ambil dari redux`);
              paramNameValue = newParam.paramName;
            }
          }
          console.log("ini text > " + text);

          let header = {
            paramId: index + 1,
            paramKey: `${
              keyParam === "CustomizeText"
                ? "customText" + (index + 1)
                : keyParam
            }`,
            paramType: `${
              keyParam === "CustomizeText" ? "customText" : keyParam
            }`,
            paramValue: element,
            textContent: text,
            paramName:
              keyParam === "CustomizeText"
                ? newParam
                  ? paramNameValue
                  : paramName
                : "",
            // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
          };

          // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
          console.log("New param added object >>> " + JSON.stringify(header));
          arrBodyParam.push(header);
        } else {
          console.log(`masuk else`);
          console.log(`element >>> ${element}`);
          console.log(`newParam >>> ${JSON.stringify(newParam)}`);
          if (element !== "") {
            //find if duplicate
            // const findParamByParamName = checkParamName(element)
            // console.log(`findParamByParamName : ${findParamByParamName}`)
            // if(findParamByParamName && findParamByParamName.paramId !== index+1){
            //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
            // }else{
            //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
            let text: string =
              newParam && newParam.textContent ? newParam.textContent : "";
            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: text,
              paramName: element,
            };
            arrBodyParam.push(header);
            // }
          }
        }
      }
      //Save To Redux
      console.log(
        "Temporary Template Body params : " + JSON.stringify(arrBodyParam)
      );
      console.log("Body Param 9 : ");
      dispatch(ReduxTemplate.setTemplateBodyParams(arrBodyParam));
      console.log(
        "Redux Template Body params : " + JSON.stringify(arrBodyParam)
      );
    }

    for (let index = 0; index < arrBodyParam.length; index++) {
      const bodyParam = arrBodyParam[index];
      if (bodyParam.paramName) {
        console.log(`new preview body before : ${newPreviewBody}`);
        newPreviewBody = newPreviewBody.replace(
          t("Campaign.Input.CustomizeText"),
          bodyParam.paramName
        );
        console.log(`new preview body after : ${newPreviewBody}`);
      }
      newPreviewBody = replaceMessageForPreview(newPreviewBody, bodyParam);
    }

    //Validate Message
    if (newPreviewBody.includes("{{") || newPreviewBody.includes("}}")) {
      // setShowWarningBodyMessage(true);
    } else if (
      !newBodyMessage.includes("{{") &&
      !newBodyMessage.includes("}}")
    ) {
      dispatch(ReduxTemplate.setTemplateBodyParams([]));
      console.log("Tidak ada body params");
      setShowWarningBodyMessage(false);
    } else {
      setShowWarningBodyMessage(false);
    }
    newPreviewBody = newPreviewBody.replaceAll("\n", "<br/>");
    // newPreviewBody = newPreviewBody.replaceAll("\\n", "<br/>");
    console.log(`newBodyMessage before : ${newBodyMessage}`);
    if (paramName) {
      //replace {{Custom text/URL}}
      newBodyMessage = newBodyMessage.replace(
        t("Campaign.Input.CustomizeText"),
        paramName
      );
      console.log(`newBodyMessage after : ${newBodyMessage}`);
    }
    setBodyMessage(newBodyMessage);
    dispatch(
      ReduxTemplate.setTemplatePreviewBody(convertTextStyle(newPreviewBody))
    );
    dispatch(ReduxTemplate.setTemplateBodyMessage(newBodyMessage));
    setLengthBodyMessage(newBodyMessage.toString().length);
    dispatch(
      ReduxTemplate.setTemplateBodyMessageLength(
        newBodyMessage.toString().length
      )
    );

    //Generate Language
    let templateLanguange = newBodyMessage;
    for (let index = 0; index < bodyParam.length; index++) {
      const element = bodyParam[index];
      templateLanguange = templateLanguange.replace(
        new RegExp(`{{${element}}}`, "g"),
        `{{${index + 1}}}`
      );
    }
    updateOnChangeData("bodyMessage", newBodyMessage);
    updateOnChangeData("previewBody", newPreviewBody);
    console.log("masuk check body param empty custom disini");
    generateLanguage(
      "body",
      templateLanguange,
      bodyParam.length,
      "",
      arrBodyParam,
      undefined
    );
    checkBodyParamEmptyCustomText(arrBodyParam, headerParams);
  };

  const onStyleChangeClick = async (
    event: any,
    style: "strike" | "monospace" | "italic" | "bold" | "emoji",
    emojiObj: any = undefined
  ) => {
    console.log(`event ${event.type}`);
    let startSign: string, endSign: string;
    switch (style) {
      case "strike":
        startSign = "~";
        endSign = startSign;
        break;
      case "monospace":
        startSign = "```";
        endSign = startSign;
        break;
      case "italic":
        startSign = "_";
        endSign = startSign;
        break;
      case "bold":
        startSign = "*";
        endSign = startSign;
        break;
      case "emoji":
        startSign = "";
        endSign = emojiObj.emoji;
        break;
      default:
        startSign = "*";
        endSign = startSign;
    }
    let textVal = myRef.current;
    let cursorStart = textVal?.selectionStart;
    let cursorEnd = textVal?.selectionEnd;
    if (cursorStart !== undefined && cursorEnd !== undefined) {
      let selectedText = bodyMessage.substring(cursorStart, cursorEnd);
      let selectedStart = bodyMessage.substring(0, cursorStart);
      let selectedEnd = bodyMessage.substring(cursorEnd, bodyMessage.length);
      let resultText =
        selectedStart + startSign + selectedText + endSign + selectedEnd;

      dispatch(ReduxTemplate.setTemplateBodyMessage(resultText));
      generatePreviewBodyMessage("", resultText, "");
    }
  };

  const updateOnChangeData = (key: string, value: any) => {
    onChanges([{ key, value }]);
  };

  const onCustomStyle = (
    style: "bold" | "italic" | "emoji",
    textAreaId: string,
    emojiObject: any = undefined,
    paramName: string
  ) => {
    console.log(`Style ${style} selected`);
    let startSign, endSign;
    switch (style) {
      case "bold":
        startSign = "*";
        endSign = startSign;
        break;
      case "italic":
        startSign = "_";
        endSign = startSign;
        break;
      case "emoji":
        startSign = "";
        endSign = emojiObject.emoji;
        break;
    }

    let currentTextArea = document.getElementById(
      textAreaId
    ) as HTMLTextAreaElement;
    let tempText = "";
    if (currentTextArea.value) {
      tempText = currentTextArea.value;
    }

    // Selection
    let selectStart = currentTextArea.selectionStart;
    let selectEnd = currentTextArea.selectionEnd;

    if (selectStart !== undefined && selectEnd !== undefined) {
      let selectedText = tempText.substring(selectStart, selectEnd);
      let selectedStart = tempText.substring(0, selectStart);
      let selectedEnd = tempText.substring(selectEnd, tempText.length);
      let resultText =
        selectedStart + startSign + selectedText + endSign + selectedEnd;

      currentTextArea.value = resultText;
      onCustomParamChanges(
        resultText,
        +textAreaId.charAt(textAreaId.length - 1),
        paramName ? paramName : ""
      );
    }
  };

  const checkBodyParamEmptyCustomText = (
    bodyParams: Array<any>,
    headerParam: Array<any>
  ) => {
    console.log(
      "masuk function check empty custom text param >>> " +
        `body param >> ${JSON.stringify(bodyParams)} ` +
        `header param >> ${JSON.stringify(headerParam)}`
    );

    if (
      bodyParams &&
      bodyParams.length > 0 &&
      reduxEmptyCustomTextlert.length > 0
    ) {
      const templateBodyParams = bodyParams;
      let customBodyParams: any = [];
      templateBodyParams.map((param) => {
        if (param.paramType === "customText" && param.textContent === "") {
          customBodyParams.push(true);
        }
        if (param.paramType === "customText" && param.textContent !== "") {
          customBodyParams.push(false);
        }
      });
      console.log("checkEmpty : ", customBodyParams);
      //looping for checkEmpty
      if (customBodyParams.length > 0) {
        dispatch(
          ReduxTemplate.setTemplateEmptyCustomtextMessageAlert(customBodyParams)
        );
      }
    }
  };

  const checkParamName = (paramName: string) => {
    if (reduxTemplateBodyParams.length > 0) {
      const findParamByParamName = reduxTemplateBodyParams.find((param) => {
        return param.paramName === paramName;
      });
      return findParamByParamName;
    }
  };

  const checkEmptyCustomText = () => {
    const templateBodyParams = reduxTemplateBodyParams;
    let customBodyParams: any = [];
    templateBodyParams.forEach((param: any) => {
      if (param.paramType === "customText" && param.textContent === "") {
        customBodyParams.push(true);
      }
      if (param.paramType === "customText" && param.textContent !== "") {
        customBodyParams.push(false);
      }
    });
    console.log("checkEmpty : ", customBodyParams);
    if (customBodyParams.length > 0) {
      dispatch(
        ReduxTemplate.setTemplateEmptyCustomtextMessageAlert(customBodyParams)
      );
    }
    return customBodyParams;
  };

  return (
    <>
      <div
        data-testid="template-process-tab"
        className={clsx("tab-pane fade show")}
        id="kt_tab_pane_3"
        role="tabpanel"
      >
        {/* Start Modal */}
        <div
          className="modal fade"
          id="addDataModal"
          // tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-testid="template-process-addDataModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("Campaign.Button.AddData")}
                </h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  id="close-addData"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    className="svg-icon svg-icon svg-icon-2x"
                    data-bs-dismiss="modal"
                  >
                    <i className="bi bi-x-lg fs-2"></i>
                  </span>
                </div>
              </div>
              <div className="modal-body">
                <Select
                  name="add-message-param"
                  styles={customStyles}
                  defaultValue={selectedParameter}
                  options={listParameter.length > 0 ? listParameter : []}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={t("Campaign.Input.AddData") + "..."}
                  onChange={(e) => {
                    const selectedParameterData: any = e;
                    console.log("selectedParameter :", selectedParameterData);
                    setSelectedParameter(selectedParameterData);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter" || e.keyCode === 13) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
                {selectedParameter &&
                  selectedParameter.item === "Customize text/URL" && (
                    <div>
                      <div className="mt-5 w-100">
                        {t("Campaign.IM.Body.Modal.CustomText.TextFieldName")}
                        <span className="text-danger">*</span>
                      </div>
                      <div className="flex-grow-1 d-flex flex-row border border-secondary rounded">
                        <input
                          className="form-control form-control-solid border-0 bg-white"
                          type="text"
                          placeholder={t("Campaign.IM.Body.Modal.CustomText")}
                          value={inputParamName}
                          onChange={(e) => {
                            const paramName: string = e.target.value;
                            console.log(`paramName : ${paramName}`);
                            setInputParamName(paramName);
                            if (paramName !== "") {
                              setAlertEmptyParamName(false);
                            }
                            //check if has same paramName
                            // const checkParam = checkParamName(paramName)
                            // if(checkParam){
                            //   dispatch(ReduxTemplate.setAlertDuplicateParamName(true));
                            //   return;
                            // }
                            // if(duplicateParamName){
                            //   dispatch(ReduxTemplate.setAlertDuplicateParamName(false))
                            // }
                          }}
                          maxLength={30}
                        />
                        <button
                          className="border-0 align-middle bg-transparent"
                          style={{ color: "gray" }}
                        >
                          {inputParamName.length}/30
                        </button>
                      </div>
                      {alertEmptyParamName && (
                        <span id="empty-paramName" className="text-danger">
                          {t(
                            "Campaign.TemplateCategory.IM.Body.CustomTextModal.ErrorAlert"
                          )}
                        </span>
                      )}
                      {duplicateParamName && (
                        <span id="duplicate-paramName" className="text-danger">
                          {t(
                            "Campaign.TemplateCategory.IM.Body.ExistFieldName.ErrorAlert"
                          )}
                        </span>
                      )}
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={clsx(
                    "btn btn-primary",
                    reduxLoading === true ? "disabled" : ""
                  )}
                  onClick={() => {
                    if (
                      selectedParameter &&
                      selectedParameter.item === "Customize text/URL"
                    ) {
                      if (inputParamName === "") {
                        setAlertEmptyParamName(true);
                        dispatch(
                          ReduxTemplate.setAlertDuplicateParamName(false)
                        );
                        return;
                      }
                      if (duplicateParamName) return;
                    }
                    onAddMessageParam();
                    setTimeout(() => {
                      myRef.current?.setSelectionRange(-1, -1);
                      myRef.current?.focus();
                    }, 500);
                  }}
                  data-bs-dismiss={
                    selectedParameter &&
                    selectedParameter.item === "Customize text/URL" &&
                    (inputParamName === "" ||
                      (inputParamName !== "" && duplicateParamName))
                      ? "#"
                      : "modal"
                  }
                  data-testid="button-modal-submit"
                  disabled={!selectedParameter}
                >
                  {t("Campaign.Button.AddData")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}

        {/* New Template */}
        <div className="d-flex">
          {/* 1 */}
          <div className="col-12">
            {/* Select Template */}
            <div className="mb-2">
              <div className="step-sub-title mb-2 fs-2">
                {t("Template.Title.TemplateContent")}

                <span
                  id="filledBody"
                  className="bi bi-check-circle-fill text-success ps-3"
                  style={reduxBodyMessage === "" ? { display: "none" } : {}}
                ></span>
              </div>
            </div>

            {/* Select Header Parameter */}
            <div
              className={clsx(
                "mb-1 d-flex",
                isTabletOrMobile ? "flex-column" : ""
              )}
            >
              <div className={clsx(isTabletOrMobile ? "col-12" : "col-6")}>
                <div className={clsx("mb-5 mt-5")}>
                  <div className="mb-5">
                    {templateCategory !== "authentication"
                      ? t("Template.Info.TemplateContent")
                      : t("Template.Info.TemplateOTP")}
                  </div>
                  {templateCategory !== "authentication" ? (
                    <>
                      <div className="d-flex flex-row border">
                        <textarea
                          ref={myRef}
                          className="border-0 form-control"
                          name=""
                          id="bodyMessage"
                          rows={12}
                          cols={5}
                          placeholder={t("Campaign.Input.ContentField")}
                          onChange={onChangeBodyMessage}
                          maxLength={1024}
                          value={reduxBodyMessage}
                          data-testid="body-message"
                          style={{ resize: "none" }}
                        >
                          {bodyMessage}
                        </textarea>
                        <p
                          className="border-0 align-middle bg-white p-2"
                          style={{ color: "gray" }}
                        >
                          {lengthBodyMessage}/1024
                        </p>
                      </div>
                      {reduxEmptyBodyMessageAlert && (
                        <div className="text-danger">
                          {t(
                            "CreateTemplate.TemplateContent.Alert.EmptyTemplateContent"
                          )}
                        </div>
                      )}
                      {!reduxEmptyBodyMessageAlert &&
                        alertDuplicateParamNameInBody && (
                          <div className="text-danger">
                            {t(
                              "Campaign.TemplateCategory.IM.Body.DuplicateParameters.ErrorAlert"
                            )}
                          </div>
                        )}
                      {showEmojiPicker && (
                        <Picker
                          pickerStyle={{ width: "100%" }}
                          onEmojiClick={(e, emojiObject) =>
                            onStyleChangeClick(e, "emoji", emojiObject)
                          }
                        />
                      )}
                      <div className="d-flex justify-content-end">
                        <i
                          id=""
                          className="btn bi bi-emoji-smile p-3 fs-1"
                          onClick={() => setShowEmojiPicker((val) => !val)}
                          data-testid="tooltip-emoji"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Common.Emoji")}
                        ></i>
                        <i
                          className="btn bi bi-type-bold p-3 fs-1"
                          onClick={(e) => onStyleChangeClick(e, "bold")}
                          data-testid="tooltip-bold-transform"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Common.Bold")}
                        ></i>
                        <i
                          className="btn bi bi-type-italic p-3 fs-1"
                          onClick={(e) => onStyleChangeClick(e, "italic")}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Common.Italic")}
                        ></i>
                        <i
                          className="btn bi bi-type-strikethrough p-3 fs-1"
                          onClick={(e) => onStyleChangeClick(e, "strike")}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Common.Strikethrough")}
                        ></i>
                        <i
                          className="btn bi bi-code-slash p-3 fs-1"
                          onClick={(e) => onStyleChangeClick(e, "monospace")}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Common.Monospace")}
                        ></i>
                        <button
                          // className="bi bi-plus-lg btn btn-primary m-2"
                          className={clsx(
                            reduxLoading ? "disabled" : "",
                            "bi bi-plus-lg btn btn-primary m-2",
                            isTabletOrMobile ? "fs-8" : ""
                          )}
                          data-bs-toggle="modal"
                          id="addBodyParams"
                          data-bs-target="#addDataModal"
                          data-testid="button-add-data-body-message"
                        >
                          {t("Campaign.Button.AddData")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div data-testid="additional-content-otp">
                      <div className="form-check d-flex flex-row align-self-start align-items-center justify-content-start gap-x-3">
                        <input
                          data-testid="check-securityrecom"
                          className="form-check-input"
                          type="checkbox"
                          checked={secrec}
                          id="option1"
                          onChange={async (e) => {
                            setSecrec(e.currentTarget.checked);
                            if (e.currentTarget.checked === true) {
                              await onChangeBodyOTP(
                                `{{${t("Campaign.Input.template.otp")}}} ${t(
                                  "Campaign.Input.template.otp.text"
                                )} ${t("Template.Info.OTPSEC")}`
                              );
                            } else {
                              await onChangeBodyOTP(
                                `{{${t("Campaign.Input.template.otp")}}} ${t(
                                  "Campaign.Input.template.otp.text"
                                )}`
                              );
                            }
                          }}
                        />
                        <label
                          className="fs-4 fw-bolder p-0 my-0 mx-3"
                          htmlFor="option1"
                        >
                          {t("Template.Info.SecRec")}
                        </label>
                      </div>
                      <p
                        style={{
                          paddingLeft: "35px",
                        }}
                      >
                        {t("Template.Info.SecRecInfo")}
                      </p>
                      <div className="pt-3 form-check d-flex flex-row align-self-start align-items-center justify-content-start gap-x-3">
                        <input
                          className="form-check-input"
                          data-testid="check-expcode"
                          type="checkbox"
                          checked={expcode}
                          id="option2"
                          onChange={async (e) => {
                            setExpcode(e.currentTarget.checked);
                            if (e.currentTarget.checked === true) {
                              generateLanguage(
                                "footer",
                                `${t("Template.info.expfooter")} ${exp} ${t(
                                  "Template.Minute"
                                )}`,
                                0,
                                "",
                                undefined,
                                []
                              );
                              dispatch(
                                ReduxTemplate.setTemplateFooter(
                                  `${t("Template.info.expfooter")} ${exp} ${t(
                                    "Template.Minute"
                                  )}`
                                )
                              );
                            } else {
                              generateLanguage(
                                "footer",
                                "",
                                0,
                                "",
                                undefined,
                                []
                              );
                              dispatch(ReduxTemplate.setTemplateFooter(""));
                            }
                          }}
                        />
                        <label
                          className="fs-4 fw-bolder p-0 my-0 mx-3"
                          htmlFor="option2"
                        >
                          {t("Template.Info.Exp")}
                        </label>
                      </div>
                      <p
                        style={{
                          paddingLeft: "35px",
                        }}
                      >
                        {t("Template.Info.ExpInfo")}
                      </p>
                      <p className="mt-5 fs-4">{t("Template.ExpIn")}</p>
                      <div className="dropdown w-auto mt-2">
                        <button
                          className="w-auto dropdown-toggle px-5 py-2 d-flex flex-row align-items-center justify-conten-between"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            border:
                              expcode === false
                                ? "1px solid #cacaca"
                                : "1px solid black",
                            fontSize: "12px",
                            outline: "none",
                          }}
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          disabled={expcode === false ? true : false}
                        >
                          {exp} {t("Template.Minute")}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button
                              data-testid="dropdown-exptime1"
                              className="dropdown-item"
                              onClick={() => {
                                setExp(1);
                                dispatch(
                                  ReduxTemplate.setTemplateFooter(
                                    `${t("Template.info.expfooter")} 1 ${t(
                                      "Template.Minute"
                                    )}`
                                  )
                                );
                                generateLanguage(
                                  "footer",
                                  `${t("Template.info.expfooter")} 1 ${t(
                                    "Template.Minute"
                                  )}`,
                                  0,
                                  "",
                                  undefined,
                                  []
                                );
                              }}
                            >
                              1 {t("Template.Minute")}
                            </button>
                          </li>
                          <li>
                            <button
                              data-testid="dropdown-exptime2"
                              className="dropdown-item"
                              onClick={() => {
                                setExp(2);
                                dispatch(
                                  ReduxTemplate.setTemplateFooter(
                                    `${t("Template.info.expfooter")} 2 ${t(
                                      "Template.Minute"
                                    )}`
                                  )
                                );
                                generateLanguage(
                                  "footer",
                                  `${t("Template.info.expfooter")} 2 ${t(
                                    "Template.Minute"
                                  )}`,
                                  0,
                                  "",
                                  undefined,
                                  []
                                );
                              }}
                            >
                              2 {t("Template.Minute")}
                            </button>
                          </li>
                          <li>
                            <button
                              data-testid="dropdown-exptime3"
                              className="dropdown-item"
                              onClick={() => {
                                setExp(3);
                                dispatch(
                                  ReduxTemplate.setTemplateFooter(
                                    `${t("Template.info.expfooter")} 3 ${t(
                                      "Template.Minute"
                                    )}`
                                  )
                                );
                                generateLanguage(
                                  "footer",
                                  `${t("Template.info.expfooter")} 3 ${t(
                                    "Template.Minute"
                                  )}`,
                                  0,
                                  "",
                                  undefined,
                                  []
                                );
                              }}
                            >
                              3 {t("Template.Minute")}
                            </button>
                          </li>
                          <li>
                            <button
                              data-testid="dropdown-exptime4"
                              className="dropdown-item"
                              onClick={() => {
                                setExp(4);
                                dispatch(
                                  ReduxTemplate.setTemplateFooter(
                                    `${t("Template.info.expfooter")} 4 ${t(
                                      "Template.Minute"
                                    )}`
                                  )
                                );
                                generateLanguage(
                                  "footer",
                                  `${t("Template.info.expfooter")} 4 ${t(
                                    "Template.Minute"
                                  )}`,
                                  0,
                                  "",
                                  undefined,
                                  []
                                );
                              }}
                            >
                              4 {t("Template.Minute")}
                            </button>
                          </li>
                          <li>
                            <button
                              data-testid="dropdown-exptime5"
                              className="dropdown-item"
                              onClick={() => {
                                setExp(5);
                                dispatch(
                                  ReduxTemplate.setTemplateFooter(
                                    `${t("Template.info.expfooter")} 5 ${t(
                                      "Template.Minute"
                                    )}`
                                  )
                                );
                                generateLanguage(
                                  "footer",
                                  `${t("Template.info.expfooter")} 5 ${t(
                                    "Template.Minute"
                                  )}`,
                                  0,

                                  "",
                                  undefined,
                                  []
                                );
                              }}
                            >
                              5 {t("Template.Minute")}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                {/* bodyParams Warning */}
                <div
                  className={clsx(showWarningBodyMessage ? "" : "display-none")}
                >
                  <div className="card border-warning mb-3">
                    <div className="card-body text-warning">
                      <h5 className="card-title">
                        {t("Campaign.Alert.InvalidData")}
                      </h5>
                      <p className="card-text">
                        <i className="bi bi-exclamation-triangle text-warning"></i>
                        {t("Campaign.Alert.WrongParam")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* get template body param */}
                {templateCategory !== "authentication" &&
                  reduxTemplateBodyParams &&
                  reduxTemplateBodyParams.length > 0 && (
                    <div>
                      {reduxTemplateBodyParams.map((bodyParam, i) => {
                        return (
                          <div
                            className="d-flex flex-row m-1"
                            key={`bodyParamDiv_${i + 1}`}
                          >
                            <span className="badge badge-circle fw-bolder fs-7 badge-primary mt-3">
                              {i + 1}
                            </span>
                            <div className="d-flex flex-column w-100 m-3">
                              <input
                                type="text"
                                disabled
                                value={
                                  bodyParam.paramType === "customText" &&
                                  bodyParam.paramName
                                    ? bodyParam.paramName
                                    : bodyParam.paramValue
                                }
                              />
                              {bodyParam.paramType &&
                                bodyParam.paramType === "customText" && (
                                  <div
                                    className={clsx("mt-3 mb-3")}
                                    id={`customParam${i + 1}`}
                                  >
                                    <textarea
                                      className="form-control w-100 mt-3"
                                      placeholder={t(
                                        "Campaign.Input.CustomizeText"
                                      )}
                                      rows={3}
                                      id={`textAreaTemplateBodyParam${i + 1}`}
                                      onClick={(e) => {
                                        console.log(
                                          "ini value dari id: " +
                                            e.currentTarget.id
                                        );
                                        setCustoParamId(e.currentTarget.id);
                                      }}
                                      onChange={(e) => {
                                        setParameter({
                                          text: e.target.value,
                                          paramNo: +e.target.id.charAt(
                                            e.target.id.length - 1
                                          ),
                                          paramName: bodyParam.paramName
                                            ? bodyParam.paramName
                                            : "",
                                        });
                                        console.log(
                                          parameter,
                                          "parameter tmps"
                                        );
                                      }}
                                      // onBlur={({ target }) => target.focus()}
                                      defaultValue={bodyParam.textContent}
                                    ></textarea>
                                    {reduxEmptyCustomTextlert &&
                                      reduxEmptyCustomTextlert.map(
                                        (alert, index) => {
                                          if (index === i && alert === true) {
                                            return (
                                              <div
                                                className="text-danger"
                                                data-testid="errorEmptyCustomtext"
                                              >
                                                {t(
                                                  "CreateTemplate.TemplateContent.Alert.EmptyCustomTextorURL"
                                                )}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    {showEmojiPickerBodyParam &&
                                      activeEmojiPickerBodyParam === i + 1 && (
                                        <Picker
                                          pickerStyle={{ width: "100%" }}
                                          onEmojiClick={(e, emojiObject) =>
                                            onCustomStyle(
                                              "emoji",
                                              `textAreaTemplateBodyParam${
                                                i + 1
                                              }`,
                                              emojiObject,
                                              bodyParam.paramName
                                                ? bodyParam.paramName
                                                : ""
                                            )
                                          }
                                          key={i + 1}
                                          data-key={i + 1}
                                        />
                                      )}

                                    <div className="d-flex justify-content-end">
                                      {/* Emoji */}
                                      <i
                                        id={`emoji_${i + 1}`}
                                        className="btn bi bi-emoji-smile p-3 fs-1"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t("Common.Emoji")}
                                        onClick={() => {
                                          // onCustomStyle("emoji", `textAreaTemplateBodyParam${i + 1}`);
                                          // // setActiveTextArea(
                                          // //   `textAreaTemplateBodyParam${i + 1}`
                                          // // );
                                          // // setShowEmojiPicker(true)}}
                                          setActiveEmojiPickerBodyParam(i + 1);
                                          setShowEmojiPickerBodyParam(
                                            (val) => !val
                                          );
                                        }}
                                      ></i>
                                      <i
                                        id={`customBold${i + 1}`}
                                        className="btn bi bi-type-bold p-3 fs-1"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t("Common.Bold")}
                                        onClick={() =>
                                          onCustomStyle(
                                            "bold",
                                            `textAreaTemplateBodyParam${i + 1}`,
                                            undefined,
                                            bodyParam.paramName
                                              ? bodyParam.paramName
                                              : ""
                                          )
                                        }
                                      ></i>
                                      <i
                                        id={`customItalic${i + 1}`}
                                        className="btn bi bi-type-italic p-3 fs-1"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t("Common.Italic")}
                                        onClick={
                                          () =>
                                            onCustomStyle(
                                              "italic",
                                              `textAreaTemplateBodyParam${
                                                i + 1
                                              }`,
                                              undefined,
                                              bodyParam.paramName
                                                ? bodyParam.paramName
                                                : ""
                                            )
                                          // setActiveItalicArea(
                                          //   `textAreaTemplateBodyParam${i + 1}`
                                          // )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                {addOnButton && templateCategory !== "authentication" && (
                  <div>
                    {/* <div className="mb-5">{t("Template.Info.ToHeader")}</div> */}
                    {/* <button
                      id="addHeaderAndFooter"
                      className={clsx(
                        "bi bi-plus-lg btn btn-primary m-1",
                        reduxLoading === true ? "disabled" : ""
                      )}
                      onClick={() => {
                        //handle is has error or empty custom text
                        if (reduxTemplateBodyParams.length > 0) {
                          const emptyCustomText = checkEmptyCustomText();
                          console.log(`emptyCustomText: ${emptyCustomText}`);
                          const isHasEmptyCustomText: boolean =
                            emptyCustomText &&
                            emptyCustomText?.length > 0 &&
                            emptyCustomText.includes(true);
                          console.log(
                            "isHasEmptyCustomText >> ",
                            isHasEmptyCustomText
                          );
                          if (isHasEmptyCustomText) return;
                        }
                        updateOnChangeData("addHeaderAndFooter", "4");
                      }}
                    >
                      {t("Template.Button.AddHeader")}
                    </button> */}
                  </div>
                )}
                {/* bodyParams Explain Card */}
              </div>

              <div className="col-1"></div>
              <PreviewMessage />
            </div>
            {/* Select Body Parameter */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateProcess;
