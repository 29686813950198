import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const SMSFailoverPage = () => {
  const { t } = useTranslation();

  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const apiSetting = useSelector((state: any) => state.ApiSetting);
  const [failoverSenderMode, setFailoverSenderMode] = useState<string>("");
  const [failoverSenderType, setFailoverSenderType] = useState<string>("");
  useEffect(() => {
    const senderMode = apiSetting.sms.failoverSenderMode;
    const senderType = senderMode === "LN" ? "API.Setting.SMS.Long.Number" : "API.Setting.SMS.Mask.Number";
    setFailoverSenderMode(senderMode);
    setFailoverSenderType(senderType);
    console.log("failoverSenderMode", apiSetting.sms.failoverSenderMode);
  }, [apiSetting.sms.failoverSenderMode]);
  return (
    <>
      <div className="row mt-2">
        <div className={isMobileView ? "col-6" : "col-2"}>
          <p className="mt-3">{t("API.Setting.SMS.Type")}</p>
        </div>
        <div className="col pt-3">{t(failoverSenderType)}</div>
      </div>
      {failoverSenderMode == "MS" && (
        <div className="row">
          <div className={isMobileView ? "col-6" : "col-2"}>
            <p>{t("API.Setting.SMS.ID.Sender")}:</p>
          </div>
          <div className="col" data-testid="masking-sms-value">
            {apiSetting.sms.maskingSMS}
          </div>
        </div>
      )}
    </>
  );
};

export default SMSFailoverPage;
