import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { KTSVG } from "../../../../resources/helpers/components/KTSVG";
import { useDispatch, useSelector } from "react-redux";
import FriendChatList from "./FriendChatList";
import FriendChatInner from "./FriendChatInner";
import { RootState } from "../../../../setup/redux/store";
import { toAbsoluteUrl } from "../../../../resources/helpers";
import { Link } from "react-router-dom";
import { User } from "../../../models/User";
import { useMediaQuery } from "react-responsive";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import * as chatUtil from "./chat-util/ChatUtil";
import * as lc from "../../../modules/localstorage/index"
import * as serviceUser from "../../../../db/serviceUser"

const FriendChatWrapper: FC = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [friend, setFriend] = useState<User>();
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const activeUserId = lc.getItemLC("UID");

  const selectedChat = useSelector(
    (state: RootState) => state.Chat.selectedUserChat
  );
  const collabbls = useSelector((state: RootState) => state.Chat.userChatList);
  const colabs = collabbls.find((obj) => {
    return obj.id === selectedChat;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (colabs) {
      setFriend(colabs.userModel);
    }
    if (colabs) {
      const friendId = colabs.userModel?.id;
      //check is friend id already on active user friendlist
      if(friendId){
        serviceUser.checkFriendByID(activeUserId, friendId).then((users) => {
          if(users && users.length > 0) {
            setIsFriend(true);
          } else {
            setIsFriend(false);
          }
        })
      }
    }
  }, [colabs]);
  
  const backToChatList = () => {
    dispatch(chat.setSelectedUserChat(""));
  }
  
  const addFriend = () => {
    if (!isFriend) {
      return <button className="btn btn-primary" style={{paddingLeft: "10px",paddingRight: "10px"}} disabled>
        <span>
          <i className="bi bi-plus-lg fs-4 me-2"></i>
          {t("UC.Button.AddFriend")}
        </span>
      </button>
    }
  }

  // Mobile View
  if (isTabletOrMobile) {
    if (selectedChat === null || selectedChat === "") {
      return (
        <div className="d-flex flex-column flex-lg-row h-100">
          <div
            className="flex-column flex-lg-row mb-10 mb-lg-0"
            // className="flex-column flex-lg-row-auto mb-10 mb-lg-0"
            style={{
              backgroundColor: "#F5F7F8",
              border: "1px solid #CDCDDE",
              borderRadius: "8.125px",
              // height: "100%"
              // width: "30%"
            }}
          >
            <div
              className="card card-flush"
              style={{ height: "100%", backgroundColor: "white" }}
            >
              {/* Chat Search */}
              <div className="card-header pt-7" id="kt_chat_contacts_header">
                <form className="w-100 position-relative" autoComplete="off">
                  <KTSVG
                    path="/media/icons/duotune/general/gen021.svg"
                    className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
                  />
                  <input
                    type="text"
                    className="form-control form-control-solid px-15"
                    name="search"
                    placeholder={t("HC.Input.Search")}
                    style={{ background: "#FFFFFF", borderColor: "black" }}
                  />
                </form>
              </div>

              {/* Handled Messages List */}
              <FriendChatList />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column flex-lg-row h-100">
          <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
            {/* <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10" style={{width: "60%"}}> */}
            <div
              className="card col-lg-12 h-100"
              id="kt_chat_messenger"
              style={{
                backgroundColor: "#F5F7F8",
                border: "1px solid #CDCDDE",
                borderRadius: "8.125px",
              }}
            >
              {/* Header Inner */}
              <div
                className="card-header"
                id="kt_chat_messenger_header"
                style={{
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  paddingLeft: isTabletOrMobile ? "10px" : "",
                  paddingRight: isTabletOrMobile ? "20px" : "",
                }}
              >
                {colabs !== undefined && (
                  <div
                    className="card-title"
                    style={{ paddingBottom: "5px", paddingTop: "5px" }}
                  >
                    {isTabletOrMobile && (
                      <div
                        className="btn btn-icon bi bi-arrow-left-circle m-0"
                        style={{
                          padding: "0px",
                        }}
                        onClick={backToChatList}
                      ></div>
                    )}
                    <span
                      className="symbol symbol-30px symbol-circle"
                      style={{ paddingRight: "10px" }}
                    >
                      <img id="selectedUserChatAvatar" alt={colabs.userModel?.name} src={chatUtil.avatarUrl(colabs.userModel)}/>
                    </span>
                    {colabs !== undefined &&
                        friend !== undefined &&
                        friend.id !== undefined && (
                      <div className="d-flex justify-content-center flex-column me-3">
                        <span
                          className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1"
                        >
                          {chatUtil.userName(colabs?.userModel,40)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {colabs !== undefined &&
                  friend !== undefined &&
                  friend.id !== undefined && (
                    <div
                      className="card-toolbar"
                      style={{ paddingBottom: "5px", paddingTop: "5px" }}
                    >
                      <div className="me-n3">
                        {addFriend()}
                      </div>
                    </div>
                  )}
              </div>
              {/* Body Inner */}
              {colabs !== undefined && (
                <FriendChatInner friend={colabs?.userModel} />
              )}
            </div>
          </div>
        </div>
      );
    }
    // return <ChatList2 />
    //Check selected chat null ?
    // not null return chat inner
    // chat inner itu ada hader, body, footer
    // null return chat list
  }
  // =================================== Dekstop View ===================================  //
  else {
    return (
      <div className="d-flex flex-column flex-lg-row h-100">
        {/* <div className="d-flex flex-column flex-lg-row" style={{marginTop: "20px",marginBottom: "20px"}}> */}
        {/*** Chat List Sebelah Kiri */}
        <div
          className="flex-column flex-lg-row mb-10 mb-lg-0"
          // className="flex-column flex-lg-row-auto mb-10 mb-lg-0"
          style={{
            backgroundColor: "#F5F7F8",
            border: "1px solid #CDCDDE",
            borderRadius: "8.125px",
            // height: "100%"
            // width: "30%"
          }}
        >
          <div
            className="card card-flush"
            style={{ height: "100%", backgroundColor: "white" }}
          >
            {/* Chat Search */}
            <div className="card-header pt-7" id="kt_chat_contacts_header">
              <form className="w-100 position-relative" autoComplete="off">
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
                />
                <input
                  type="text"
                  className="form-control form-control-solid px-15"
                  name="search"
                  placeholder={t("HC.Input.Search")}
                  style={{ background: "#FFFFFF", borderColor: "black" }}
                />
              </form>
            </div>

            {/* Handled Messages List */}
            <FriendChatList />
          </div>
        </div>

        {/***
         * Chat List Sebelah Kanan
         */}

        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          {/* <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10" style={{width: "60%"}}> */}
          <div
            className="card col-lg-12 h-100"
            id="kt_chat_messenger"
            style={{
              backgroundColor: "white",
              border: "1px solid #CDCDDE",
              borderRadius: "8.125px",
            }}
          >
            {/* Header Inner */}
            <div
              className="card-header"
              id="kt_chat_messenger_header"
              style={{
                paddingBottom: "5px",
                paddingTop: "5px",
                marginBottom: "0px",
                marginTop: "0px",
              }}
            >
              {colabs !== undefined && (
                <div
                  className="card-title"
                  style={{ paddingBottom: "5px", paddingTop: "5px" }}
                >
                  <span
                    className="symbol symbol-30px symbol-circle"
                    style={{ paddingRight: "10px" }}
                  >
                   <img id="selectedUserChatAvatar" alt={colabs.userModel?.name} src={chatUtil.avatarUrl(colabs.userModel)}/>
                  </span>
                  {colabs !== undefined &&
                        friend !== undefined &&
                        friend.id !== undefined && (
                    <div className="d-flex justify-content-center flex-column me-3">
                      <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">
                          {chatUtil.userName(colabs?.userModel,40)}
                        </span>
                    </div>
                  )}
                </div>
              )}
              {colabs !== undefined &&
                friend !== undefined &&
                friend.id !== undefined && (
                  <div
                    className="card-toolbar"
                    style={{ paddingBottom: "5px", paddingTop: "5px" }}
                  >
                    <div className="me-n3">
                      {addFriend()}
                    </div>
                  </div>
                )}
            </div>
            {/* Body Inner */}
            {colabs !== undefined && (
              <FriendChatInner />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default FriendChatWrapper;
