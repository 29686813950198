import React, { FC, useState } from "react";
import { ActionMeta, MultiValue , PropsValue } from "react-select";
import CreatableReactSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

interface CreateableSelectProps {
  value?: OptionType[];
  name?: string;
  placeholder?: string;
  onDataUpdate?: (selected: any[]) => void;
  testId?: string;
  [propName: string]: any;
}

interface OptionType {
  value: string;
  label: string;
  date?: number;
}

const CreateableSelect: FC<CreateableSelectProps & React.HTMLAttributes<HTMLElement>> = ({
  value = [],
  placeholder,
  onDataUpdate,
  testId,
  name,
  ...props
}) => {
  
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<OptionType[]>(value);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " " || event.code === "Space" ||  event.key === "Space"      ) {
      event.preventDefault();
      setInputValue((prevInputValue) => prevInputValue + " ");
    }else if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
  
      const trimmedValue = inputValue.trim();
      if (trimmedValue === "") {
        return;
      }
  
      const isDuplicate = selectedValues.some(
        (option) => option.value.toLowerCase() === trimmedValue.toLowerCase()
      );
  
      if (isDuplicate) {
        setErrorMessage(t("Contact.Alert.DuplicateValue"));
        setTimeout(() => {
          setErrorMessage("");
        }, 5000); // Set a timeout of 2 seconds to clear the error message
        return;
      }
  
      const existingOption = selectedValues.find(
        (option) => option.value === trimmedValue
      );
  
      if (existingOption) {
        setInputValue("");
        return;
      }
  
      const updatedSelectedValue = [
        ...selectedValues,
        { value: trimmedValue, label: trimmedValue },
      ];
  
      setSelectedValues(updatedSelectedValue);
      setErrorMessage("");
  
      if (onDataUpdate) {
        onDataUpdate(updatedSelectedValue);
      }
  
      setInputValue("");
    };
  };
  
  
  const handleCreateOption = (inputValue: string) => {
    const isDuplicate = selectedValues.some(
      (option) => option.label === inputValue
    );
  
    if (isDuplicate) {
      setErrorMessage(t("Contact.Alert.DuplicateValue"));
      return;
    }
  
    const updatedSelectedValue = [
      ...selectedValues,
      { value: inputValue, label: inputValue },
    ];
    setSelectedValues(updatedSelectedValue);
    setErrorMessage("");
  
    if (onDataUpdate) {
      onDataUpdate(updatedSelectedValue);
    }
  
    setInputValue("");
  };
  
  



  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
      // Clear the input value when an option is selected or created
      setInputValue('');
    }
    setInputValue('');
  
    setSelectedValues(newValue as OptionType[]);
  
    if (onDataUpdate) {
      onDataUpdate(newValue as OptionType[]);
    }
  };
  


  const handleInputChange = (newValue: string) => {
    setInputValue(newValue.trim()); // Trim the input value and update the state
  
    // Clear the input value if it's empty
    if (newValue.trim() === "") {
      setInputValue("");
    }
    
  };

  return (
    <div data-testid={testId} className="createable-select-container">
      <style>
        {`
          .createable-select-container{
            max-width: 800px; /* Set the maximum width for the input */
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
      </style>
      <CreatableReactSelect
        {...props}
        isMulti
        menuIsOpen={false}
        placeholder={placeholder}
        options={[]}
        value={selectedValues}
        defaultValue={[] as PropsValue<OptionType>} // Set empty array as defaultValue
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        inputValue={inputValue}
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        name={name}        
        isClearable
      />
      {errorMessage && (
        <div className="formik-error-message" style={{ color: "red", fontSize: "1rem", fontWeight: 400 }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CreateableSelect;
