import {FC} from 'react'

type Props = {
  template?: any
}

const TemplateCategoryCell: FC<Props> = ({template}) => (
  // <div className='badge badge-light fw-bolder'>{email}</div>
  <div className='text-gray-650 mb-1 text-center'>{template.category}</div>
)

export {TemplateCategoryCell}
