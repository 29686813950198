import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isPublicIP from "../../../../../../util/publicIPChecker";
import * as ApiSettingApi from "../../../../../../api/server/api-setting";
import * as ApiSettingSlice from "../../../../../modules/api-setting/redux/ApiSettingSlice";
import ModalConfirmation from "../../../../../components/modal/ModalConfirm";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import PhoneInput from "react-phone-input-2";
import {
  isListHaveDuplicates,
  isValidPhoneNumber,
} from "../../../../../../util/phoneNumber";

const whitelistPhoneLimit = process.env.REACT_APP_PHONE_NUMBER_WHITELIST_LIMIT
  ? Number(process.env.REACT_APP_PHONE_NUMBER_WHITELIST_LIMIT)
  : 20;

const ApiOverviewEditPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiSetting = useSelector((state: any) => state.ApiSetting);
  const [accessToken, setAccessToken] = useState<string>(
    apiSetting.access.accessToken
  );
  const [ipAddress, setIpAddress] = useState<string[]>(apiSetting.ipwhitelist);
  const [phoneNumber, setPhoneNumber] = useState<string[] | undefined>(
    apiSetting.phoneNumberWhitelist
  );
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDisplayToken, setIsDisplayToken] = useState<boolean>(true);
  // const [isDisplaySandboxToken, setIsDisplaySandboxToken] =
  //   useState<boolean>(true);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // const textAreaSandboxRef = useRef<HTMLTextAreaElement>(null);

  const phoneNumberWhitelistError:
    | { phoneNumbers?: string[]; message: string }
    | undefined = apiSetting.phoneNumberWhitelistError;

  const handleGenerateAccessToken = async () => {
    // Generate access token
    setLoading(true);
    const secretKey = apiSetting.access.secretKey;
    const response = await ApiSettingApi.generateAccessToken(secretKey);
    const token = response?.data.token;
    if (!token) return;

    // Save access token to state to update view
    setAccessToken(token);
    setLoading(false);

    // Set state to edited
    triggerEditStateChange();

    // Save access token to redux
    dispatch(
      ApiSettingSlice.setApiSettingAccess({
        ...apiSetting.access,
        accessToken: token,
      })
    );

    // Close modal
    setIsModalOpen(false);
  };

  const triggerEditStateChange = () => {
    dispatch(ApiSettingSlice.setStateEdited(true));
  };

  const handleAddIpAdress = () => {
    // Add new blank ip address
    setIpAddress([...ipAddress, ""]);

    // Set state to edited
    triggerEditStateChange();
  };

  const handleAddPhoneNumber = () => {
    if (phoneNumber && phoneNumber.length >= whitelistPhoneLimit) {
      return;
    }

    if (!phoneNumber) {
      return setPhoneNumber(["62"]);
    }
    // Add new blank ip address
    setPhoneNumber([...phoneNumber, "62"]);

    // Set state to edited
    triggerEditStateChange();
  };

  const handleUpdatePhoneNumber = (value: string, index: number) => {
    if (!phoneNumber) {
      return;
    }
    // Update PhoneNumber
    const updatedPhoneNumber = phoneNumber.map((ip, i) =>
      i === index ? value : ip
    );
    setPhoneNumber(updatedPhoneNumber);
    dispatch(
      ApiSettingSlice.setApiSettingPhoneNumberWhiteList(updatedPhoneNumber)
    );

    // Set state to edited
    triggerEditStateChange();
  };

  const handleDeletePhoneNumber = (index: number) => {
    if (!phoneNumber) {
      return;
    }
    // Delete Phone Number
    const deletedPhoneNumber = phoneNumber.filter((_, i) => i !== index);
    setPhoneNumber(deletedPhoneNumber);
    dispatch(
      ApiSettingSlice.setApiSettingPhoneNumberWhiteList(deletedPhoneNumber)
    );
    // Set state to edited

    triggerEditStateChange();
  };

  const handleDeleteIpAddress = (index: number) => {
    // Delete ip address
    const deletedIpAddress = ipAddress.filter((_, i) => i !== index);
    setIpAddress(deletedIpAddress);
    dispatch(ApiSettingSlice.setApiSettingWhiteList(deletedIpAddress));

    // Set state to edited
    triggerEditStateChange();
  };

  const handleUpdateIpAddress = (value: string, index: number) => {
    // Update ip address
    const updatedIpAddress = ipAddress.map((ip, i) =>
      i === index ? value : ip
    );
    setIpAddress(updatedIpAddress);
    dispatch(ApiSettingSlice.setApiSettingWhiteList(updatedIpAddress));

    // Set state to edited
    triggerEditStateChange();
  };

  const handleCopyClick = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
      toast(t("API.Setting.Overview.Access.Token.Copied"), {
        type: "success",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <div className="container mt-10 w-100">
        <div>
          <div className="flex">
            <span className="h3">{t("API.Setting.Overview.Access.Token")}</span>
            {isDisplayToken && (
              <button
                className="btn fa fa-eye viewpass text-muted h3"
                onClick={() => setIsDisplayToken(!isDisplayToken)}
              ></button>
            )}
            {!isDisplayToken && (
              <button
                className="btn fa fa-eye-slash viewpass text-muted h3"
                onClick={() => setIsDisplayToken(!isDisplayToken)}
              ></button>
            )}
          </div>

          <div
            className="d-flex w-100"
            style={{ position: "relative", display: "inline-block" }}
          >
            <textarea
              ref={textAreaRef}
              id="accessTokenHidden"
              className="form-control w-75 bg-secondary"
              placeholder="Access Token"
              readOnly
              // rows={2}
              style={{ resize: "none" }}
              value={
                isDisplayToken ? accessToken : "******************************"
              }
            />
          </div>
          <div className="">
            <Button
              className="btn btn-primary mt-5"
              type="button"
              isLoading={isLoading}
              onClick={() => setIsModalOpen(true)}
            >
              {t("API.Setting.Overview.Access.Token.Generate")}
            </Button>
            <Button
              className="btn mt-5 border border-secondary ms-3"
              onClick={handleCopyClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-copy text-muted"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="mt-15">
          <h3>{t("API.Setting.Overview.IP.Whitelist")}</h3>
          {ipAddress.map((ip: string, index) => (
            <div key={index} className="my-3">
              <div className="flex my-2 input-group w-25">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Please Input IP Address"
                  value={ip}
                  onChange={(e) => handleUpdateIpAddress(e.target.value, index)}
                />

                {ipAddress.length > 1 && (
                  <Button
                    className="btn btn-sm btn-danger ms-2"
                    onClick={() => handleDeleteIpAddress(index)}
                  >
                    x
                  </Button>
                )}
              </div>
              {ip !== "" && !isPublicIP(ip) && (
                <span className="flex text-danger text-xs fw-light">
                  {t("API.Setting.Overview.IP.Invalid")}
                </span>
              )}
            </div>
          ))}
          <Button
            className="btn btn-primary mt-5"
            type="button"
            disabled={ipAddress[ipAddress.length - 1] === ""}
            onClick={handleAddIpAdress}
          >
            + {t("API.Setting.Overview.IP.Adress.Add")}
          </Button>
        </div>

        <div className="mt-15">
          <h3>{t("API.Setting.Overview.Phone.Whitelist")}</h3>
          {phoneNumber?.map((phone, index) => {
            const rest = [
              ...phoneNumber.slice(0, index),
              ...phoneNumber.slice(index + 1),
            ];
            return (
              <div key={index} className="my-3">
                <div className="flex my-2 input-group w-25">
                  <PhoneInput
                    containerClass="form-control p-0 overflow-hidden"
                    inputClass="w-100 border-0"
                    buttonClass="border-0"
                    dropdownStyle={{ borderColor: "black", zIndex: 999 }}
                    country="id"
                    masks={{ id: "... .... .... ...." }}
                    enableSearch={true}
                    countryCodeEditable={false}
                    value={phone}
                    onChange={(phone) => {
                      handleUpdatePhoneNumber(phone, index);
                    }}
                  />

                  {phoneNumber.length > 1 && (
                    <Button
                      className="btn btn-sm btn-danger ms-2"
                      onClick={() => handleDeletePhoneNumber(index)}
                    >
                      x
                    </Button>
                  )}
                </div>
                {phone !== "" && !isValidPhoneNumber(phone) && (
                  <span className="flex text-danger text-xs fw-light">
                    {t("API.Setting.Overview.Phone.Invalid")}
                  </span>
                )}
                {phoneNumber &&
                  phoneNumber.length > 1 &&
                  rest.includes(phone) && (
                    <span className="flex text-danger text-xs fw-light">
                      {t("API.Setting.Overview.Phone.Duplicated")}
                    </span>
                  )}
                {phoneNumberWhitelistError &&
                  phoneNumberWhitelistError.phoneNumbers?.includes(phone) && (
                    <span className="flex text-danger text-xs fw-light">
                      {phoneNumberWhitelistError.message}
                    </span>
                  )}
              </div>
            );
          })}
          <button
            className="btn btn-primary mt-5"
            type="button"
            disabled={
              phoneNumber?.[phoneNumber.length - 1] === "" ||
              (phoneNumber && phoneNumber.length >= whitelistPhoneLimit) ||
              (phoneNumber &&
                phoneNumber.length > 0 &&
                !isValidPhoneNumber(phoneNumber[phoneNumber.length - 1])) ||
              (phoneNumber && isListHaveDuplicates(phoneNumber))
            }
            onClick={handleAddPhoneNumber}
          >
            + {t("API.Setting.Overview.Phone.Number.Add")}
          </button>
        </div>

        <ModalConfirmation
          onConfirm={() => handleGenerateAccessToken()}
          onCancel={() => setIsModalOpen(false)}
          onHide={() => setIsModalOpen(false)}
          isModalOpen={modalOpen}
          type={"save"}
          title={t("API.Setting.Modal.Title.GenerateToken")}
          message={t("API.Setting.Message.GenerateToken")}
        />
      </div>
    </>
  );
};

export default ApiOverviewEditPage;
