import axios from "axios";
import * as bcrypt from "bcryptjs";

export const generateRefferalCode = async (
  members: any[],
  clientId: string,
  userId: string,
  callback: any
) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/createMember";
  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);

  const data = {
    members,
    clientId,
    userId,
    submittedAt: new Date(),
  };
  // const dummy = {
  //   success: true,
  //   error_ids: [],
  //   success_ids: [1, 2],
  //   message: "success registered all",
  // };
  // const dummy_error = {
  //   success: false,
  //   success_ids: [],
  //   error_ids: [1],
  //   message: "error email has been registered",
  // };
  try {
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, "testing res");
        const responseJson = response?.data;
        const responseCode = response?.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error?.response?.status, error?.response?.data);
      });
    // return callback(200, dummy_error);
  } catch (error) {
    console.log(error);
  }
};

export const resendInvitation = async (userId: string, callback: any) => {
  const url = process.env.REACT_APP_SERVER_URL! + "/user/resendInvitation";
  const token = bcrypt.hashSync(process.env.REACT_APP_SERVER_TOKEN!, 10);

  const data = {
    userId,
  };
  try {
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseJson = response?.data;
        const responseCode = response?.status;
        callback(responseCode, responseJson);
      })
      .catch((error) => {
        callback(error?.response?.status, error?.response?.data);
      });
  } catch (error) {
    console.log(error);
  }
};
