import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import TextInput from '../../../../styles/components/TextInput'
import Button from '../../../../styles/components/Button'
import { useTranslation } from 'react-i18next'
import { getUserByEmail } from '../../../../db'
import { sendEmailResetPassword, sendEmailResetPassword2 } from '../redux/AuthCRUD'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const { t } = useTranslation();
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("ForgotPassword.Error.Email"))
      .required(t("ForgotPassword.Error.EmailEntry"))
      // .test('test-validateUserEmail', t("Login.Notif.IncorrectUser"), async function(value) {
      //   const { path, createError } = this;
      //   if(value){
      //     if(await getUserByEmail(value)){
      //       return true
      //     }
      //     else{
      //       return false
      //     }
      //   }
      //   return Promise.resolve(false);
      // }),
  });
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [emailInvalid, setEmailInvalid] = useState<boolean | undefined>(undefined)
  const [emailNotRegistered, setEmailNotRegistered] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        getUserByEmail(values.email).then(async (user: any) => {
          let isSuccess = false; 
          if(!user || !user[0].id){
            setHasErrors(true)
            setEmailInvalid(true)
          }
          if(user && !user?.registeredAt){
            setHasErrors(true);
            setEmailNotRegistered(true);
          }
          // if(user)
          else{
            let data = user[0];
            let sendEmail = await sendEmailResetPassword2(data.name, data.id, values.email); 
            console.log("Send email : "+ sendEmail)
            if (sendEmail) {
              setHasErrors(false)
              setLoading(false)
              isSuccess=true;
            }else{
              console.log("Masuk error . . . " );
              setHasErrors(true)
              setLoading(false)  
            }
          }
          setSubmitting(isSuccess);
        })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">{t("ForgotPassword.Info.Header")}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {t("ForgotPassword.Info.EmailEntry")}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {emailInvalid === true ? t("ForgotPassword.Error.Email") : 
              emailNotRegistered === true ? t("ForgotPassword.Error.EmailNotRegistered") : t("ForgotPassword.Notif.Error")}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">
              {t("ForgotPassword.Notif.URLSent")}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {t("ForgotPassword.Input.Email")}
          </label>
          <TextInput
            data-testid="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            formcontrol={"solid"}
            // isvalid={formik.touched.email && !formik.errors.email}
            name="email"
            autoComplete="off"
            id="fpassword-email"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <Button
            type="submit"
            id="fpassword-submit"
            btnlg="primary"
            cName="fw-bolder me-4"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <span className="indicator-label">
              {t("ForgotPassword.Button.Submit")}
            </span>
            {loading && (
              <span className="indicator-progress">
                {t("Login.Button.Loading")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
          <Link to="/auth/login">
            <Button
              type="button"
              id="fpassword-cancel"
              btnlg="light-primary"
              cName="fw-bolder"
            >
              {t("ForgotPassword.Button.Cancel")}
            </Button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
