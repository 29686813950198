const browserDetector = () => {
                 
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium/i)){
      browserName = "chrome";
    }else if(userAgent.match(/firefox/i)){
      browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else if(userAgent.match(/edg/i)){
      browserName = "edge";
    } else if(userAgent.match(/crios/i)){
      browserName = "chromeios";
    } else if(userAgent.match(/fxios/i)){
      browserName = "firefoxios";
    }else{
      browserName="No browser detection";
    }
  
   console.log("You are in browser: ", browserName);    
   return browserName;     
}

export default browserDetector;