import db from ".";
import {converter2} from "./converter";
import Company from "../app/models/Company";
import * as lc from "../app/modules/localstorage"

export const getCustomerCountByCompanyID = (id: string) =>
  db
    .collection("company")
    .doc(id)
    .get()
    .then((snapshot) => {
      const counter = snapshot.get("customerCount");
      return counter;
    });
// return company;

export const fetchCountCustomers = (CompanyID: string) =>
  db
    .collection("company")
    .doc(CompanyID)
    .get()
    .then((doc) => {
      let count = 0;
      if (!doc.exists) {
        // doc.data() will be undefined in this case
        console.error("No such document!");
      } else {
        const company = doc.data();
        if (company) {
          count = company.customerCount;
        }
      }
      return count;
    });

  export const fetchCountCIQ = (CompanyID: string) =>
  db
    .collection("company")
    .doc(CompanyID)
    .get()
    .then((doc) => {
      let count = 0;
      if (!doc.exists) {
        // doc.data() will be undefined in this case
        console.error("No such document!");
      } else {
        const company = doc.data();
        if (company && company.ciqCount) {
          count = company.ciqCount;
        }
      }
      return count;
    });


  export const getByClient = async () => {
    const clientRef = lc.getClientRef()!
    return db
    .collection("company")
    .withConverter(converter2<Company>())
    .where("client","==", clientRef)
    .get()
    .then(async (snaps) => {
      if (snaps.empty) {
        // doc.data() will be undefined in this case
        console.error("No such document!");
        return [] as Company[]

      } 
      return await Promise.all(snaps.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id
        } as Company
      }))
      
    });

  }  
