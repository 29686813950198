import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { endOfDay, format, startOfDay, startOfMonth } from "date-fns";
import PreviewMessage from "../../../../core/preview/PreviewMessage";
import { getTemplateLanguages } from "../../../../../../db/serviceTemplate";
import { getTemplateById } from "../../core/_requests";
import Language from "../../../../../models/Language";
import MessagePreview from "./MessagePreviewModal";

type PreviewTemplateModal = {
  isOpen?: boolean;
  onClose?: () => void;
  idTemplate?: string;
};

const PreviewTemplateModal: FC<PreviewTemplateModal> = ({
  isOpen,
  onClose,
  idTemplate,
}) => {
  const { t } = useTranslation();
  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const startOfDayTime = startOfDay(today);
  const endOfDayTime = endOfDay(today);
  const formattedStartDate = format(firstDayOfMonth, "yyyy-MM-dd");
  const formattedEndDate = format(today, "yyyy-MM-dd");
  const formattedStartTime = format(startOfDayTime, "HH:mm:ss");
  const formattedEndTime = format(endOfDayTime, "HH:mm:ss");

  const [startDate, setStartDate] = useState(formattedStartDate);
  const [startTime, setStartTime] = useState(formattedStartTime);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const [endTime, setEndTime] = useState(formattedEndTime);
  const [templateId, setTemplateId] = useState("03a9HaRkzMBxWhboDUXJ");
  const [template, setTemplate] = useState<Language>();
  const [templateLanguage, setTemplateLanguage] = useState<Language[]>();
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isStartTimeValid, setIsStartTimeValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);
  const [isEndTimeValid, setIsEndTimeValid] = useState<boolean>(true);
  const [isEndDateLessThanStartDate, setIsEndDateLessThanStartDate] =
    useState<boolean>(true);
  const [isEndTimeLessThanStartTime, setIsEndTimeLessThanStartTime] =
    useState<boolean>(true);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  useEffect(() => {
    getTemplateLanguages(String(idTemplate)).then((listLanguage) => {
      const newLanguage = listLanguage.map((language) => {
        return language as Language;
      });
      setTemplateLanguage(newLanguage);
    });

    templateLanguage?.map((language, idx) => {
      if (idx === 0) {
        setTemplate(language);
        return 0;
      }
    });
  }, [templateLanguage, idTemplate]);

  const handleOnChangeStartDate = (e: any) => {
    setStartDate(e.target.value);
    console.log(`start date value : `, startDate);
  };

  const handleOnChangeStartTime = (e: any) => {
    setStartTime(e.target.value);
    console.log(`start time value : `, startTime);
  };

  const handleOnChangeEndDate = (e: any) => {
    setEndDate(e.target.value);
    console.log(`end date value : `, endDate);
  };

  const handleOnChangeEndTime = (e: any) => {
    setEndTime(e.target.value);
    console.log(`end time value : `, endTime);
  };

  const handleOnCheckBox = (id: string) => {
    setSelectedData((prevSelectedData) =>
      prevSelectedData.includes(id)
        ? prevSelectedData.filter((selectedId) => selectedId !== id)
        : [...prevSelectedData, id]
    );
  };

  return (
    <>
      {isOpen && (
        <Modal
          animation
          centered
          show={isOpen}
          // size="lg"
          scrollable={false}
          onHide={() => onClose && onClose()}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Campaign.Title.Preview")}{" "}
              {t("Template.Title.TemplateContent")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light w-100">
            <MessagePreview template={template} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PreviewTemplateModal;
