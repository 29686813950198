import db from "../db";
import * as conn from "./connection";
import { converter2 } from "./converter";
import Message from "../app/models/Message";
import { BadgeItem } from "../app/models/Chat";
import firebase from "firebase/compat/app";
import * as lc from "../app/modules/localstorage/index";
import { RootState } from "../setup/redux/store";
import { useSelector } from "react-redux";

export const fetchMessage_old = async (collaboration: string) => {
  const collabRef = conn.createRef("collaborations", collaboration);

  return await db
    .collection("messages")
    .where("collaboration", "==", collabRef)
    .where("isActive", "==", true)
    .withConverter(converter2<Message>())
    .orderBy("createdAt")
    .get()
    .then((snapshot) => {
      const message = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return message;
    });
};

// Try Chat Pagination
// // 1. Get first page (10 messages)
export const fetchMessage = async (collaboration: string, rooms: string, lastMessage: any, callback: any) => {
  if (lastMessage !== undefined) {
    console.log("Masuk set lastVisible");
    callback(lastMessage);
    return;
  }

  let limitation = 10;
  if (process.env.REACT_APP_CLIENT_LIMITMESSAGE) {
    limitation = +process.env.REACT_APP_CLIENT_LIMITMESSAGE;
  }

  const collabRef = conn.createRef("collaborations", collaboration);
  const roomRef = db.collection("collaborations").doc(collaboration).collection("rooms").doc(rooms);

  // const roomsQuery = await db.collectionGroup('rooms');
  // roomsQuery.get().then(async (querySnapshot) => {
  //   let rooms = await Promise.all(
  //     querySnapshot.docs.map((doc) => {
  //       console.log(doc.data(), "jalo klx")
  //       return doc.data()
  //     })
  //   )
  //   return rooms
  // });

  // const zko = await db
  //   .collectionGroup("rooms")
  //   .get();
  // let newData: any[] = []
  // zko.forEach((doc) => {
  //   newData.push(doc.data())
  // })

  // console.log(newData, "data colgroup ax")

  let result = await db
    .collection("messages")
    .where("room", "==", roomRef)
    .where("isActive", "==", true)
    .withConverter(converter2<Message>())
    .orderBy("createdAt", "desc")
    .limit(limitation)
    .get()
    .then((snapshot) => {
      const message = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      const checkVisible = snapshot.docs[snapshot.docs.length - 1];
      if (checkVisible !== undefined) {
        // 2. Get the oldest (last visible) messages of Step 1
        callback(checkVisible);
        // console.log("Data Snap : "+checkVisible);
        // console.log("Data Snap : "+JSON.stringify(checkVisible));
        // lastVisible = checkVisible.data() as Message;
        // lastVisible.id = checkVisible.id;
      }
      return message;
    });

  return result;
};

// 3. Get the previous messages
export const fetchMessagePrev = async (collaboration: string, lastMessage: any, callback: any, room?: string) => {
  let limitation = 10;
  if (process.env.REACT_APP_CLIENT_LIMITMESSAGE) {
    limitation = +process.env.REACT_APP_CLIENT_LIMITMESSAGE;
  }
  const collabRef = conn.createRef("collaborations", collaboration);
  const roomRef = db.collection("collaborations").doc(collaboration).collection("rooms").doc(room);
  console.log(collabRef, "checking bux");
  console.log(lastMessage, "lastmess bux");
  return await db
    .collection("messages")
    .where("room", "==", roomRef)
    .where("isActive", "==", true)
    .withConverter(converter2<Message>())
    .orderBy("createdAt", "desc")
    .startAfter(lastMessage)
    .limit(limitation)
    .get()
    .then((snapshot) => {
      const prevMessage = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      const checkVisible = snapshot.docs[snapshot.docs.length - 1];
      if (checkVisible !== undefined) {
        // 2. Get the oldest (last visible) messages of Step 1
        callback(checkVisible);
        // lastVisible = snapshot.docs[snapshot.docs.length - 1];
      }

      // console.log("Last Visible Previous Message: "+JSON.stringify(lastVisible));
      return prevMessage;
    });
  // }
};

export const unreadMessages = (collaborationId: string) => {
  console.log("==> Masuk unreadMessages nnnotifff");

  return firebase
    .database()
    .ref("/collaborations/" + collaborationId + "/")
    .once("value")
    .then((snapshot) => {
      let bi: BadgeItem[] = [];
      snapshot.forEach((node) => {
        if (node.exists()) {
          // console.log(collaborationId, "isi colabid nnnotifff");
          // console.log(node.val(), "snapshot olx nnnotifff");
          bi.push({
            unreadCount: node.val().unreadMessages,
            className: "",
            channel: node.key!,
          });
        }
      });
      return bi;
    });
};

export const createMessage = (Message: any) => {
  console.log("masuk createmessage bozzz", Message);
  return db
    .collection("messages")
    .add(Message)
    .then((docRef) => {
      console.log("bozzz Document written with ID: ", docRef.id);
      return docRef.id;
    })
    .catch((err) => {
      console.error("Error create customer : ", err);
      return "";
    });
};

export const retryMessage = (Message: any) => {
  console.log("masuk retrymessage bozzz");
  return (
    db
      .collection("messages")
      .doc(Message.id)
      .update({
        responseCode: Message.responseCode,
        resultCode: Message.resultCode,
        status: Message.status,
        resultMessage: Message.resultMessage,
        resultMessageId: Message.resultMessageId,
        updatedAt: new Date(),
      })
      // .then((docRef:any) => {
      //   console.log("Document updated with ID: ", docRef.id);
      //   return docRef.id;
      // })
      .catch((err) => {
        console.error("Error retry message : ", err);
        return "";
      })
  );
};

export const updateMessageasRead = (Message: any) => {
  return (
    db
      .collection("messages")
      .doc(Message.id)
      .update({
        isMessageRead: true,
        updatedAt: new Date(),
      })
      // .then((docRef:any) => {
      //   console.log("Document updated with ID: ", docRef.id);
      //   return docRef.id;
      // })
      .catch((err) => {
        console.error("Error retry message : ", err);
        return "";
      })
  );
};

export const clearUnreadMessages = async (collaborationId: string, channel: string) => {
  // const collabRef = firebase.database().ref(`/collaborations/${collaborationId}/${channel}`);
  const collabRef = firebase.database().ref(`/collaborations/${collaborationId}`);
  collabRef.set({
    unreadMessages: 0,
  });
};

export const updateUnreadMessages = async (collaborationId: string) => {
  const collabRef = firebase.database().ref(`/collaborations/${collaborationId}`);
  await collabRef.once("value", async (snapshot) => {
    snapshot.forEach((questionSnapshot) => {
      console.log(questionSnapshot.key, "qsnahp kuy");
      const collabChildRef = firebase.database().ref(`/collaborations/${collaborationId}/${questionSnapshot.key}`);
      collabChildRef.update({
        unreadMessages: 0,
      });
      console.log(collabChildRef, "nanan kuy");
    });
  });
};

export const updateUnreadMessagesAllChat = async (collaborationId: string) => {
  const collabRef = firebase.database().ref(`/collaborations/${collaborationId}`);
  await collabRef.once("value", async (snapshot) => {
    snapshot.forEach((questionSnapshot) => {
      const collabChildRef = firebase.database().ref(`/collaborations/${collaborationId}/${questionSnapshot.key}`);
      collabChildRef.update({
        unreadMessages: 0,
      });
    });
  });
};

// ============================== User Chat ============================== //

export const userUnreadMessages = (collaborationId: string) => {
  const userId = lc.getItemLC("UID");
  // console.log("User Id: "+userId);
  // console.log("Collaboration Id: "+collaborationId);

  return firebase
    .database()
    .ref("/collaborations/" + collaborationId + "/" + userId + "/")
    .once("value")
    .then((snapshot) => {
      let bi: BadgeItem[] = [];
      console.log("Snapshot : " + JSON.stringify(snapshot));
      snapshot.forEach((node) => {
        if (node.exists()) {
          bi.push({
            unreadCount: node.val().unreadMessages,
            className: "",
            channel: node.key!,
          });
        }
      });
      return bi;
    });
};

export const updateUserUnreadMessages = async (collaborationId: string) => {
  console.log("masuk B rxk");
  const userId = lc.getItemLC("UID");
  const collabRef = firebase.database().ref(`/collaborations/${collaborationId}/${userId}/7days/`);
  await collabRef.update({
    unreadMessages: 0,
  });
};

export const increaseUnreadMessage = async (collaborationId: string, toUserId: string) => {
  const collabref = firebase.database().ref(`/collaborations/${collaborationId}/${toUserId}/7days`);
  await collabref.once("value", async (snapshot) => {
    if (!snapshot.val()) {
      await createInitCollaborations(collaborationId, toUserId);
    } else {
      const unreadMessages = snapshot.val().unreadMessages;
      await collabref.update({
        unreadMessages: unreadMessages + 1,
      });
    }
  });
};

const createInitCollaborations = async (collaborationId: string, toUserId: string) => {
  const collabref = firebase.database().ref(`/collaborations/${collaborationId}/${toUserId}/7days`);
  collabref.set({
    unreadMessages: 1,
  });
};

// export const updateUnreadMessages2 = async(collaborationId: string, channel:string ) => {
//   // const collabRef = firebase.database().ref(`/collaborations/${collaborationId}/${channel}`);
//   const collabRef = firebase.database().ref(`/collaborations/${collaborationId}`);
//   await collabRef.once("value", async(snapshot) => {
//     if(!snapshot.val()){
//       await clearUnreadMessages(collaborationId, channel);
//     }else{
//       const unreadMessages = snapshot.val().unreadMessages;
//       await collabRef.update({
//         unreadMessages: 0
//       });
//     }
//   })
