import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../../../resources/helpers/components/KTSVG";
import { RootState } from "../../../../../../../setup/redux/store";
import { launchEmbeddedSignUp } from "../../../../../../../util/facebook/action";
import * as lc from "../../../../../../modules/localstorage/index";
import { useListView } from "../../core/ListViewProvider";
import { WABAAccountsListGrouping } from "./WABAAccountListGrouping";

const WABAAccountsListToolbar = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const { setItemIdForUpdate } = useListView();
  const currentClient = lc.getItemLC(lc.LCName.Client);

  const clientid = currentClient?.id;

  const WABAList = useSelector(
    (state: RootState) => state.WABAAccountsList.listWABAAccount
  );

  return (
    <div
      className={clsx(
        `d-flex justify-content-end`,
        isTabletOrMobile ? "flex-column gap-5 w-100" : ""
      )}
      data-kt-user-table-toolbar="base"
    >
      {/* begin::Add user */}
      {WABAList.length > 0 && (
        <Link
          className={clsx(`btn btn-primary ${isTabletOrMobile ? "w-100" : ""}`)}
          to="/setting/waba-account/create/sign-up"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          />
          {t("WABAAccountList.Button")}
        </Link>
      )}
      {/* end::Add user */}
      {isTabletOrMobile && (
        <div className="d-flex w-100 mt-0">
          <WABAAccountsListGrouping />
        </div>
      )}
    </div>
  );
};

export { WABAAccountsListToolbar };
