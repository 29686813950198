import { FC } from "react";
// import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { PageTitle } from "../layout/core/PageData";

const About: FC = () => {
  // const { t } = useTranslation();
  return (
    <div className="fw-bold fs-3 text-gray-400 mb-15" data-testid="about-page">
      <PageTitle>About</PageTitle>
      <h2>About Page</h2>
    </div>
  );
};

export { About };
