import clsx from "clsx";
import { KTSVG } from "../../../../../../resources/helpers/components/KTSVG";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";

interface IShippingLimitLabelProps {
  shippingTimestamp?: Timestamp;
}

const getShippingLabelName = (shippingLimit: number) => {
  if (shippingLimit === 0) {
    return t("Common.Pending");
  } else if (shippingLimit <= 0) {
    return t("Common.Expired");
  } else {
    return `${shippingLimit} ${t("Common.ShortHour")}`;
  }
};
const ShippingLimitLabel: React.FC<IShippingLimitLabelProps> = ({
  shippingTimestamp,
}: IShippingLimitLabelProps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const shipDate = shippingTimestamp && moment(shippingTimestamp.toDate());
  const nowDate = moment();
  const shippingLimit = shipDate?.diff(nowDate, "hours") || 0;
  const shippingLimitLabel = getShippingLabelName(shippingLimit);

  return (
    <div
      style={{ width: isTabletOrMobile ? "70px" : "100px" }}
      className={
        !isTabletOrMobile
          ? `p-2 rounded-3 text-center fw-bold d-flex justify-content-start
        ${clsx({
          "alert-danger": shippingLimit <= 4 && shippingLimit > 0,
          "alert-success": shippingLimit > 4,
          "alert-warning": shippingLimit < 0,
          "alert-secondary": shippingLimit === 0,
        })}    
        }` // Desktop Display
        : `p-1 rounded-3 text-center fw-bold d-flex justify-content-end m-0
        ${clsx({
          "alert-danger": shippingLimit <= 4 && shippingLimit > 0,
          "alert-success": shippingLimit > 4,
          "alert-warning": shippingLimit < 0,
          "alert-secondary": shippingLimit === 0,
        })}    
        }` // Mobile Display
      }
    >
      <KTSVG
        path="/media/icons/duotune/general/gen013.svg"
        className={`${clsx({
          "svg-icon-danger": shippingLimit <= 4 && shippingLimit > 0,
          "svg-icon-success": shippingLimit > 4,
          "svg-icon-warning": shippingLimit < 0,
          "svg-icon-secondary": shippingLimit === 0,
        })} my-auto`}
      />
      <div className="px-1">
        <span>{shippingLimitLabel}</span>
      </div>
    </div>
  );
};

export default ShippingLimitLabel;
