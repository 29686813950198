import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup/redux/store";
import * as ReduxTemplate from "../../modules/template/redux/TemplateSlice";

interface TemplateProps {
  templateCategory: string;
  onChanges: (params: { key: string; value: any }[]) => void;
  validateInputHandler: (isValid: boolean) => void;
  parentValue: string;
  // onRefChange?: (ref: React.RefObject<HTMLTextAreaElement>) => void;
}

const TextAreaFailover: React.FC<TemplateProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cursorPosition, setCursorPosition] = useState(0);
  const [failoverMessage, setFailoverMessage] = useState<string>("");
  const [lengthBodyMessage, setLengthBodyMessage] = useState<number>(0);
  const [warningOTP, setWarningOTP] = useState<boolean>(false);
  const [warningLimitText, setWarningLimitText] = useState<boolean>(false);
  const [warningZeroText, setWarningZeroText] = useState<boolean>(false);
  const [warningEmoji, setWarningEmoji] = useState<boolean>(false);
  const [warningBadword, setWarningBadword] = useState<boolean>(false);
  const [warningBadwordText, setWarningBadwordText] = useState<string>("");
  const [typing, setTyping] = useState<boolean>(false);
  const myRef = useRef<HTMLTextAreaElement>(null);

  const reduxTemplateFailover = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage
  );
  const reduxTemplateLanguage = useSelector(
    (state: RootState) => state.Template.generatedLanguage
  );
  const reduxCampaignData = useSelector(
    (state: RootState) => state.Campaign.campaignData
  );

  const alertDuplicateParamNameInBody = useSelector(
    (state: RootState) => state.Template.alertDuplicateParamNameInBody
  );

  // useEffect(() => {
  //   props.onRefChange(myRef);
  // }, [props.onRefChange]);

  console.log(failoverMessage, "LENGTHHH FAILOVERMESSAGE");
  // console.log(
  //   reduxTemplateFailover?.failover?.message,
  //   "LENGTHHH TEMPLATEFAILOVER FAILOVER"
  // );
  console.log(reduxTemplateFailover.failover, "LENGTHHH TEMPLATEFAILOVER");
  console.log(reduxCampaignData, "LENGTHHH CAMPAINGNDATA FAILOVER");
  // console.log(reduxCampaignData, "LENGTHHH CAMPAINGNDATA");
  console.log(reduxTemplateLanguage, "LENGTHHH TEMPLATELANGUAGE FAILOVER");
  console.log(props, "LENGTHHH PROPOS");

  useEffect(() => {
    console.log("jalan a");
    setFailoverMessage(reduxTemplateFailover?.failover?.message || "");
  }, [reduxTemplateFailover]);

  useEffect(() => {
    if (window.location.pathname.includes("campaign") && typing === false) {
      console.log("jalan b");
      setFailoverMessage(String(reduxTemplateFailover?.failover?.message));
    }

    // if (window.location.pathname.includes("template")) {
    //   setFailoverMessage(
    //     reduxTemplateLanguage?.failover?.message !== undefined
    //       ? reduxTemplateLanguage.failover.message
    //       : ""
    //   );
    // }
  }, [reduxTemplateFailover, reduxCampaignData, typing]);

  useEffect(() => {
    if (typing === false) {
      if (failoverMessage !== props.parentValue) {
        console.log("jalan c");
        setFailoverMessage(props.parentValue);
      }
    }
  }, [props.parentValue, failoverMessage, typing]);

  // HANDLE FOR DISABLE BUTTON NEXT
  useEffect(() => {
    if (
      !warningEmoji &&
      !warningBadword &&
      warningZeroText &&
      lengthBodyMessage <= 160
    ) {
      props.validateInputHandler(true);
    } else {
      props.validateInputHandler(false);
    }
  }, [warningEmoji, warningBadword, warningZeroText, lengthBodyMessage]);

  console.log(warningZeroText, "warnzerote yiz");
  console.log(lengthBodyMessage, "lengbo yiz");

  useEffect(() => {
    if (lengthBodyMessage >= 160) {
      setWarningLimitText(true);
    } else {
      setWarningLimitText(false);
    }
  }, [warningLimitText, lengthBodyMessage]);

  // const handleFocus = () => {
  //   console.log(myRef, "ctes iox")
  //   if (myRef.current) {
  //     if (props.templateCategory === "authentication") {
  //       const otpIndex = failoverMessage?.indexOf("{{OTP CODE}}");
  //       myRef.current.setSelectionRange(otpIndex, otpIndex);
  //       console.log(myRef, "ctes iox")
  //       dispatch(ReduxTemplate.setMyRef(myRef));
  //     }
  //   }
  // };

  const handleFocus = () => {
    if (myRef.current) {
      if (props.templateCategory === "authentication") {
        const otpIndex = failoverMessage?.indexOf("{{OTP CODE}}");
        setCursorPosition(otpIndex);
        myRef.current.setSelectionRange(otpIndex, otpIndex);
        // dispatch(ReduxTemplate.setMyRef(myRef));
      } else {
        myRef.current.setSelectionRange(cursorPosition, cursorPosition);
        // dispatch(ReduxTemplate.setMyRef(myRef));
      }
    }
  };

  const onchangeMessage = (e: any) => {
    setTyping(true);
    if (
      !e.currentTarget.value.includes("{{OTP CODE}}") &&
      props.templateCategory === "authentication"
    ) {
      e.preventDefault();
      setWarningOTP(true);
      return;
    } else {
      setWarningOTP(false);
    }

    if (e.currentTarget.value.toString().length >= 160) {
      setWarningLimitText(true);
    } else {
      setWarningLimitText(false);
    }

    if (e.currentTarget.value.toString().length > 0) {
      setWarningZeroText(true);
    } else {
      setWarningZeroText(false);
    }

    // PREVENT EMOJI VIA SMS
    const userInput = e.currentTarget.value.toString().toLowerCase();
    if (isEmoji(userInput)) setWarningEmoji(true);
    else setWarningEmoji(false);

    // PREVENT BADWORD
    const badWords = ["LOGIN", "`", "~", "@", "$", "^", "_", "[", "]", "|"];
    const isContainBadword = badWords.filter((word: string) => {
      return userInput.includes(word.toLowerCase());
    });
    if (isContainBadword.length > 0) {
      setWarningBadword(true);
      // setWarningBadwordText(isContainBadword.join(" "));
      setWarningBadwordText(badWords.join(" "));
    } else {
      setWarningBadword(false);
      setWarningBadwordText("");
    }

    setFailoverMessage(e.currentTarget.value);
    // if (reduxTemplateFailover?.failover?.message !== null) {
    //   setFailoverMessage(reduxTemplateFailover?.failover?.message || 0);
    // } else {
    //   setFailoverMessage(e.currentTarget.value);
    // }
    console.log(e.currentTarget.value.toString().length, "length oix");
    setLengthBodyMessage(
      e.currentTarget.value.toString().length
      // //   // ||
      // //   //   reduxTemplateFailover?.failover?.message.length
    );

    props.onChanges(e);
  };
  // console.log(
  //   reduxTemplateFailover?.failover?.message.length,
  //   "LENGTHHH TEMPLATEFAILOVER"
  // );
  // console.log(
  //   reduxTemplateLanguage?.failover?.message.length,
  //   "LENGTHHH TEMPLATELANGUAGE"
  // );
  // console.log(
  //   valueOnchangeMessage?.currentTarget?.value,
  //   "LENGTHHH E CURRENTTARGET"
  // );
  // console.log(
  //   valueOnchangeMessage?.currentTarget?.value.length,
  //   "LENGTHHH E CURRENTTARGET"
  // );

  useEffect(() => {
    // let valueOnchangeMessageUseEffect = valueOnchangeMessage;
    if (
      // valueOnchangeMessageUseEffect?.currentTarget?.value.toString().length - 1 > 0 ||
      // (reduxTemplateLanguage?.failover?.message?.length ?? 0) > 0 ||
      // (reduxTemplateFailover?.failover?.message?.length ?? 0) > 0
      failoverMessage.length > 0
    ) {
      setWarningZeroText(true);
    } else {
      setWarningZeroText(false);
    }
  }, [
    failoverMessage,
    // warningZeroText,
    // valueOnchangeMessage,
    // reduxTemplateLanguage,
    // reduxTemplateFailover,
  ]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setTyping(false);
    }, 1500);

    return () => clearTimeout(typingTimeout);
  }, [failoverMessage]);

  useEffect(() => {
    setLengthBodyMessage(failoverMessage.length);
  }, [failoverMessage]);

  return (
    <>
      <div
        className="d-flex flex-row border mb-2"
        data-testid="failover-textarea-container"
      >
        <textarea
          ref={myRef}
          className="border-0 form-control"
          name=""
          id="bodyMessage"
          rows={5}
          cols={5}
          placeholder={t("Campaign.Input.ContentField")}
          onChange={(e) => onchangeMessage(e)}
          onFocus={handleFocus}
          maxLength={160}
          value={failoverMessage}
          style={{ resize: "none" }}
          data-testid="failover-textarea"
        >
          {failoverMessage}
        </textarea>
        <p
          className="border-0 align-middle bg-white p-2"
          style={{ color: "gray" }}
        >
          {lengthBodyMessage}/160
        </p>
      </div>
      {warningZeroText === false && (
        <p className="text-danger mt-0 pt-0">
          <i className="bi bi-exclamation-triangle text-danger mt-0 pt-0"></i>{" "}
          {t("Failover.OTP.Zero")}
        </p>
      )}
      {alertDuplicateParamNameInBody && (
        <p className="text-danger mt-0 pt-0">
          <i className="bi bi-exclamation-triangle text-danger mt-0 pt-0"></i>{" "}
          {t(
            "Campaign.TemplateCategory.IM.Body.DuplicateParameters.ErrorAlert"
          )}
        </p>
      )}
      {warningOTP === true && (
        <p className="text-warning mb-0 pb-0">
          <i className="bi bi-exclamation-triangle text-warning mb-0 pb-0"></i>{" "}
          {t("Failover.OTP.warning")}
        </p>
      )}
      {warningLimitText === true && (
        <p className="text-danger mt-0 pt-0">
          <i className="bi bi-exclamation-triangle text-danger mt-0 pt-0"></i>{" "}
          {t("Failover.OTP.danger")}
        </p>
      )}
      {warningEmoji && (
        <p className="text-danger mt-0 pt-0" data-testid="warning-emoji">
          <i className="bi bi-exclamation-triangle text-danger mt-0 pt-0"></i>{" "}
          {t("Failover.Emoji.Danger")}
        </p>
      )}
      {warningBadword && (
        <p className="text-danger mt-0 pt-0" data-testid="warning-badword">
          <i className="bi bi-exclamation-triangle text-danger mt-0 pt-0"></i>{" "}
          {t("Failover.BadWord.Danger")}
          <b> : {warningBadwordText}</b>
        </p>
      )}
    </>
  );
};

export default TextAreaFailover;

function isEmoji(str: string) {
  for (var i = 0, n = str.length; i < n; i++) {
    if (str.charCodeAt(i) > 255) {
      return true;
    }
  }
  return false;
}
