
import {Collaboration as CIQModel} from "../../../models/Chat"

interface InitialState {
  listCIQ: CIQModel[];
  countCIQ: number;
  currentPageCIQ: number;
  maxPageCIQ: number;
  arrayCiqPagination: string[];
}
const CustomerInQueueAction: string = "CustomerInQueueAction";

export type unsubFN = () => void;

export default InitialState;
export { CustomerInQueueAction };
