import { FC } from "react";
import { useQuery } from "react-query";
import { isNotEmpty, QUERIES } from "../../../../../resources/helpers";
import { getContactById } from "../core/_requests";
import { useListView } from "../core/ListViewProvider";
import { ContactEditModalForm } from "./ContactEditModalForm";

const ContactEditModalFormWrapper: FC<{
  phoneNumber?: string;
  onClose?: () => void;
  onSave?: (contactId?: string) => void;
}> = ({ phoneNumber, onClose, onSave }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getContactById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
    }
  );

  if (!itemIdForUpdate) {
    return (
      <ContactEditModalForm
        isUserLoading={isLoading}
        onClose={phoneNumber ? onClose : undefined}
        onSave={onSave}
        contact={{ id: undefined, phoneNumber: phoneNumber ?? undefined }}
      />
    );
  }

  if (!isLoading && !error && user) {
    return <ContactEditModalForm isUserLoading={isLoading} contact={user} />;
  }

  return null;
};

export { ContactEditModalFormWrapper };
