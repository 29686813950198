/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { Campaign } from "../../../../../../models/Campaign";

type Props = {
  messagesCount?: number;
  id?: string;
};

const CampaignMessagesCountCell: FC<Props> = ({ messagesCount, id }) => {
  let count = messagesCount;
  return (
    <div
      className="d-flex align-items-center"
      data-testid={id || "messagecount"}
    >
      <div className="d-flex flex-column">{count ? count : 0}</div>
    </div>
  );
};

export { CampaignMessagesCountCell };
