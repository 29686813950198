/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { KTSVG } from "../../../resources/helpers";
import { toAbsoluteUrl } from "../../../resources/helpers/AssetHelpers";
import "../../../styles/css/color.css";
import { AsideMenu } from "./AsideMenu";
import { throttle } from "lodash";
import Draggable from "react-draggable";
import { useMediaQuery } from "react-responsive";

const AsideDefault: FC = () => {
  const { t } = useTranslation();
  // const webMedium = useMediaQuery({ query: "(max-width: 1800px)" });
  // const webSmall = useMediaQuery({ query: "(max-width: 1200px)" });
  // const webExtraSmall = useMediaQuery({ query: "(max-width: 1000px)" });
  // const [isSidebarMinimized, setIsSidebarMinimized] = useState<boolean>(false);
  // const [isShouldDisplayBalance, setIsShouldDisplayBalance] =
  //   useState<boolean>(false);
  // const draggableRef = useRef<HTMLDivElement | null>(null);
  // const divRef = useRef<HTMLDivElement | null>(null);

  // const handleToggle = () => {
  //   console.log("check");
  // };

  // useEffect(() => {
  //   const element = divRef.current;

  //   if (!element) return;

  //   const handleResize = throttle((entries: ResizeObserverEntry[]) => {
  //     for (let entry of entries) {
  //       const componentWidth = entry.contentRect.width;
  //       if (componentWidth < 100 && !isSidebarMinimized) {
  //         setIsSidebarMinimized(true);
  //       } else if (componentWidth >= 100 && isSidebarMinimized) {
  //         setIsSidebarMinimized(false);
  //       }
  //     }
  //   }, 100);

  //   const resizeObserver = new ResizeObserver(handleResize);

  //   resizeObserver.observe(element);

  //   return () => {
  //     resizeObserver.unobserve(element);
  //     handleResize.cancel();
  //   };
  // }, [isSidebarMinimized]);

  // useEffect(() => {
  //   setIsShouldDisplayBalance(isSidebarMinimized);
  //   localStorage.setItem(
  //     "sidebar-minimized",
  //     isSidebarMinimized === true ? "true" : "false"
  //   );
  // }, [isSidebarMinimized]);

  return (
    <div
      id="kt_aside"
      className="aside cl-gray aside-hoverable"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-menu d-flex flex-column-fluid w-100">
        <AsideMenu />
      </div>
    </div>
  );
};

export { AsideDefault };
