/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";
import Customer from '../../../../../models/Customer'

type Props = {
  contact: Customer;
};

const ContactInfoCell: FC<Props> = ({ contact }) => {
  let completeName =  contact.firstName! + (contact.lastName? " " + contact.lastName : '');
  if (completeName.length > 25) {
    completeName = completeName.slice(0, 24).concat("...");
  }
  return (
  <div className="d-flex align-items-center">
    {/* begin:: Avatar */}
    <div className="symbol symbol-circle symbol-30px overflow-hidden me-3">
      <Link
         to={
          "/contact/contact-detail/overview/" + contact.id
        }
        state={{
          id: contact.id,
          name: contact.firstName! + (contact.lastName? " " + contact.lastName : ''),
        }}
        
      >
        {contact.avatar ? (
          <div className="symbol-label">
            <img
              src={`${contact.avatar}`}
              alt={contact.firstName}
              className="w-100"
            />
          </div>
        ) : (
        <div className="symbol-label">
        <img
          src={toAbsoluteUrl("/media/icons/avatar/def-avatar.png")}
          alt={contact.firstName}
          className="w-100"
        />
      </div>
    )
        // (
        //   <div
        //     className={clsx(
        //       "symbol-label fs-3",
        //       `bg-light-${contact.initials?.state}`,
        //       `text-${contact.initials?.state}`
        //     )}
        //   >
        //     {contact.initials?.label}
        //   </div>
        // )
        }
      </Link>
    </div>
    <div className="d-flex flex-column">
      <Link
        to={
          "/contact/contact-detail/overview/" + contact.id
        }
        state={{
          id: contact.id,
          name: contact.firstName! + (contact.lastName? " " + contact.lastName : ''),
        }}
        className="text-gray-800 text-hover-primary mb-1"
      >
        {completeName}
      </Link>
      
      {/* <span>{contact.email}</span> */}
    </div>
  </div>
)};

export { ContactInfoCell };
