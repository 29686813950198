/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateContact } from "../../../../../layout/contact-management/contact-list/core/_requests";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import * as customerServ from "../../../../../../db/serviceCustomer";
import { useDispatch, useSelector } from "react-redux";
import * as ContactRedux from "../../../../contact/redux/ContactSlice";
import Button from "../../../../../../styles/components/Button";
import ModalConfirmationMoveToOtherPage from "../../../../modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../modal/modalConfirmationWithStateHandler";
import { getItemLC, LCName } from "../../../../localstorage";
import { createRef } from "../../../../../../db/connection";
import { useMediaQuery } from "react-responsive";
import { RootState } from "../../../../../../setup/redux/store";
import CountryList from "../../../../../lists/CountryList";
import MaritalStatusList from "../../../../../lists/MaritalStatusList";
import Select from 'react-select'
import GenderList from "../../../../../lists/GenderList";
import MaritalStatus from "../../../../../lists/MaritalStatusList";

import Customer from "../../../../../models/Customer"

import MultiSelect from "editable-creatable-multiselect";

import CreateableSelect from "../../../../../../styles/components/CreateableSelect";
import firebase from "firebase/compat/app";

const isOneOfTheObjectTrue = (obj: any) =>
  Object.values(obj).some((value) => value === true);

const checkPhoneNo = async (value: string, client: any) => {
  let isExistData = await customerServ.getCustomerByPhoneNoAndClient(value, client);
  console.log("Cek with phone result : " + isExistData);
  return isExistData;
};

const Label = ({ children }: any, props: any) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    
    <label {...props} className="w-25">
      {children}
    </label>
  );
};


// const datenow =  firebase.firestore.Timestamp.now();
// console.log(`firebase date ${datenow}`);

const editContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  lastName: Yup.string(),
  birthdate: Yup.date().nullable().max(new Date()),
  address: Yup.string().max(255),
  district: Yup.string().max(100),
  province: Yup.string().max(100),
  city: Yup.string().max(100),
  zipcode: Yup.number().max(99999),
  // social media
  facebook: Yup.array().of(Yup.string()),
  instagram: Yup.array().of(Yup.string()),
  tiktok: Yup.array().of(Yup.string()),
  shopee: Yup.array().of(Yup.string()),
  tokopedia: Yup.array().of(Yup.string()),
  lazada: Yup.array().of(Yup.string()),
  bukalapak: Yup.array().of(Yup.string()),
  blibli: Yup.array().of(Yup.string()),
});

interface Option{
  value: string,
  label: string,
  date?: number,
}
export const ProfileDetailsEdit = ({ customer }: { customer: Customer }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nav: any = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhoneOrEmailEdited, setIsPhoneOrEmailEdited] = useState(false);
  const [isPhoneAlreadyExist, setIsPhoneAlreadyExist] = useState(false);
  const [maritalStatusList, setMaritalStatusList] = useState<any>([]);
  const [genderList, setGenderList] = useState<any>([]);
  
  const [listFacebook, setListFacebook] = useState<Option[]>(customer?.selectedFacebook ? customer?.selectedFacebook : []);
  const [listInstagram, setListInstagram] = useState<Option[]>(customer?.selectedInstagram ? customer?.selectedInstagram : []);
  const [listTiktok, setListTiktok] = useState<Option[]>(customer?.selectedTiktok ? customer?.selectedTiktok : []);
  const [listShopee, setListShopee] = useState<Option[]>(customer?.selectedShopee ? customer?.selectedShopee : []);
  const [listTokopedia, setListTokopedia] = useState<Option[]>(customer?.selectedTokopedia ? customer?.selectedTokopedia : []);
  const [listLazada, setListLazada] = useState<Option[]>(customer?.selectedLazada ? customer?.selectedLazada : []);
  const [listBukalapak, setListBukalapak] = useState<Option[]>(customer?.selectedBukalapak ? customer?.selectedBukalapak : []);
  const [listBlibli, setListBlibli] = useState<Option[]>(customer?.selectedBlibli ? customer?.selectedBlibli : []);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  // redux listContact
  const listContact = useSelector(
    (state: RootState) => state.Contact.listContact
  );

  const rd_navcount = useSelector(
    (state: RootState) => state.Contact.navCount
  );
  console.log(` customer in profile details edit ${JSON.stringify(customer)}`)
  const client = getItemLC(LCName.Client);

  const [contactForEdit] = useState({
    // ...contact,
    id: customer.id,
    avatar: customer.avatar ? customer.avatar : "",
    firstName: customer.firstName,
    lastName: customer.lastName ? customer.lastName : "",
    email: customer.email ? customer.email : "",
    phoneNumber: customer.phoneNumber ? customer.phoneNumber : "",
    gender: customer.gender ? customer.gender : "",
    birthdate: customer.birthdate ? customer.birthdate : "",
    maritalStatus: customer.maritalStatus ? customer.maritalStatus : "",
    address: customer.address ? customer.address : "",
    district: customer.district ? customer.district : "",
    city: customer.city ? customer.city : "",
    province: customer.province ? customer.province : "",
    zipcode: customer.zipcode ? customer.zipcode : "",
    country: customer.country ? customer.country : "",
    //social media
    facebook : customer.facebook? customer.facebook : "", 
    instagram : customer.instagram? customer.instagram : "", 
    tiktok : customer.tiktok? customer.tiktok : "",   
    shopee : customer.shopee? customer.shopee : "", 
    tokopedia : customer.tokopedia? customer.tokopedia : "", 
    lazada : customer.lazada? customer.lazada : "", 
    bukalapak : customer.bukalapak? customer.bukalapak : "", 
    blibli : customer.blibli? customer.blibli :"",
    // follow since
    followFacebook : customer.followFacebook? customer.followFacebook : "", 
    followInstagram : customer.followInstagram? customer.followInstagram : "", 
    followTiktok : customer.followTiktok? customer.followTiktok : "", 
    followShopee : customer.followShopee? customer.followShopee : "", 
    followTokopedia : customer.followTokopedia? customer.followTokopedia : "", 
    followLazada : customer.followLazada? customer.followLazada : "", 
    followBukalapak : customer.followBukalapak? customer.followBukalapak : "", 
    followBlibli : customer.followBlibli? customer.followBlibli :"",

    firstNameInsensitive: "",
    updatedAt: new Date(),
  });

  const handleBack = () => {
    
    console.log(`navigate handle click in profile details edit ${rd_navcount}`)
    const navcount = rd_navcount;
    dispatch(ContactRedux.setNavCount(1));
    nav(navcount * -1);
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    await handleSave(formik.values);
  };

  const generateSelectedMaritalStatus = (maritalStatus: string) => {
    const findMaritalStatus = MaritalStatus.find((item) => {
      if (item.value.toLowerCase() === maritalStatus?.toLowerCase()) {
        return item;
      }
    });
    console.log("check data findMaritalStatus : ", findMaritalStatus);
    if (findMaritalStatus) {
      return {
        value: findMaritalStatus.value,
        label: t(findMaritalStatus.label),
      };
    }
  };

  const generateSelectedGender = (gender: string) => {
    const findGender = GenderList.find((item) => {
      if (item.value.toLowerCase() === gender?.toLowerCase()) {
        return item;
      }
    });
    console.log("check data findGender : ", findGender);
    if (findGender) {
      return {
        value: findGender.value,
        label: t(findGender.label),
      };
    }
  };

  const generateSelectedSocialMedia = (data: any[]) => {
    const selectedValueForLabel = data.map((value) => ({ "value": value, "label": value }));
    console.log("check data selectedFacebook : ", selectedValueForLabel);
    if (selectedValueForLabel) {
      return selectedValueForLabel;
    }
  };


  const generateSelectedCountry = (country: string) => {
    const findCountry = CountryList.find((item) => {
      if (item.value.toLowerCase() === country?.toLowerCase()) {
        return item;
      }
    });
    console.log("check data findGender : ", findCountry);
    if (findCountry) {
      return {
        value: findCountry.value,
        label: t(findCountry.label),
      };
    }
  };

  const handleSave = async (values: any) => {
    try {
      setIsModalOpen(false);
      const fnameInsensitive = values.firstName!.toLowerCase();
      values.firstNameInsensitive = fnameInsensitive;
      values.updatedAt = new Date();

      // { values.facebook?.length !== 0 ? values.followFacebook =  firebase.firestore.Timestamp.now() : values.followFacebook = ""};
      // { values.instagram?.length !== 0 ? values.followInstagram =  firebase.firestore.Timestamp.now() : values.followInstagram = ""};
      // { values.tiktok?.length !== 0 ? values.followTiktok =  firebase.firestore.Timestamp.now() : values.followTiktok = ""};
      // { values.shopee?.length !== 0 ? values.followShopee =  firebase.firestore.Timestamp.now() : values.followShopee = ""};
      // { values.tokopedia?.length !== 0 ? values.followTokopedia =  firebase.firestore.Timestamp.now() : values.followTokopedia = ""};
      // { values.lazada?.length !== 0 ? values.followLazada =  firebase.firestore.Timestamp.now() : values.followLazada = ""};
      // { values.bukalapak?.length !== 0 ? values.followBukalapak =  firebase.firestore.Timestamp.now() : values.followBukalapak = ""};
      // { values.blibli?.length !== 0 ? values.followBlibli =  firebase.firestore.Timestamp.now() : values.followBlibli = ""};

      if(values.facebook?.length !== 0 ){
        console.log(`value follow Facebook ${values.followFacebook}`)
        if(values.followFacebook === undefined || values.followFacebook === "" ){
          values.followFacebook =  firebase.firestore.Timestamp.now();
        }
      }else {values.followFacebook = null}
      if(values.instagram?.length !== 0 ){
        if(values.followInstagram === undefined || values.followInstagram === "" ){
          values.followInstagram =  firebase.firestore.Timestamp.now();
        }
      }else {values.followInstagram = null}
      if(values.tiktok?.length !== 0 ){
        if(values.followTiktok === undefined || values.followTiktok === "" ){
          values.followTiktok =  firebase.firestore.Timestamp.now();
        }
      }else {values.followTiktok = null}
      if(values.shopee?.length !== 0 ){
        if(values.followShopee === undefined || values.followShopee === "" ){
          values.followShopee =  firebase.firestore.Timestamp.now();
        }
      }else {values.followShopee = null}
      if(values.tokopedia?.length !== 0 ){
        if(values.followTokopedia === undefined || values.followTokopedia === "" ){
          values.followTokopedia =  firebase.firestore.Timestamp.now();
        }
      }else {values.followTokopedia = null}
      if(values.lazada?.length !== 0 ){
        if(values.followLazada === undefined || values.followLazada === "" ){
          values.followLazada =  firebase.firestore.Timestamp.now();
        }
      }else {values.followLazada = null}
      if(values.bukalapak?.length !== 0 ){
        if(values.followBukalapak === undefined || values.followBukalapak === "" ){
          values.followBukalapak =  firebase.firestore.Timestamp.now();
        }
      }else {values.followBukalapak = null}
      if(values.blibli?.length !== 0 ){
        if(values.followBlibli === undefined || values.followBlibli === "" ){
          values.followBlibli =  firebase.firestore.Timestamp.now();
        }
      }else {values.followBlibli = null}

      // reformat phone number
      if (values.phoneNumber !== "" && values.phoneNumber!.startsWith("0")) {
        values.phoneNumber = "62" + values.phoneNumber!.substring(1);
      }

      //reformat gender, marital status, country
      console.log("values : ", values);

      console.log("VALUE PHONE NUMBER", values);

      const contactId = await updateContact(values);
      if (contactId) {
        let getUpdateContact = await customerServ.get(
          contactId.toString()
        );
        if (getUpdateContact) {
          console.log("UPTD, getUpdateContact", getUpdateContact);
          //get from list and update from redux rd_customerData
          const newContactList: any = listContact.map((item) => {
            if (item.id === getUpdateContact!.id) {
              return getUpdateContact;
            } else {
              return item;
            }
          });
          //new contact data cop from getUpdateContact
          let newContactData = getUpdateContact;
          if(getUpdateContact.maritalStatus !== ""){
            const newMaritalStatus = generateSelectedMaritalStatus(getUpdateContact.maritalStatus!);
            newContactData.selectedMaritalStatus = newMaritalStatus;
          }
          if(getUpdateContact.gender !== ""){
            const newGender = generateSelectedGender(getUpdateContact.gender!);
            newContactData.selectedGender = newGender;
          }
          // social media
          if(getUpdateContact.facebook?.length !== 0 ){
            const newFacebook = generateSelectedSocialMedia(getUpdateContact.facebook!);
            newContactData.selectedFacebook = newFacebook;
            console.log(`value follow Facebook ${getUpdateContact.followFacebook}`)
          }
          if(getUpdateContact.instagram?.length !== 0 ){
            const newInstagram = generateSelectedSocialMedia(getUpdateContact.instagram!);
            newContactData.selectedInstagram = newInstagram;
          }
          if(getUpdateContact.tiktok?.length !== 0 ){
            const newTiktok = generateSelectedSocialMedia(getUpdateContact.tiktok!);
            newContactData.selectedTiktok = newTiktok;
            
            console.log(`value tiktok sebelum apa kah masuk ${values.followBukalapak}`)
          }
          if(getUpdateContact.shopee?.length !== 0 ){
            const newShopee = generateSelectedSocialMedia(getUpdateContact.shopee!);
            newContactData.selectedShopee = newShopee;
          }
          if(getUpdateContact.tokopedia?.length !== 0 ){
            const newTokopedia = generateSelectedSocialMedia(getUpdateContact.tokopedia!);
            newContactData.selectedTokopedia = newTokopedia;
            console.log(`value follow Tokopedia ${values.followTokopedia}`)
          }
          if(getUpdateContact.lazada?.length !== 0 ){
            const newLazada = generateSelectedSocialMedia(getUpdateContact.lazada!);
            newContactData.selectedLazada = newLazada;            
            console.log(`value follow Facebook ${getUpdateContact.followLazada}`)
          }
          if(getUpdateContact.bukalapak?.length !== 0 ){
            const newBukalapak = generateSelectedSocialMedia(getUpdateContact.bukalapak!);
            newContactData.selectedBukalapak = newBukalapak;
            console.log(`bukalapak firstime buka lapak apa kah masuk ${values.followBukalapak}`)
          }
          if(getUpdateContact.blibli?.length !== 0 ){
            const newBlibli = generateSelectedSocialMedia(getUpdateContact.blibli!);
            newContactData.selectedBlibli = newBlibli;
            console.log(`value follow Blibli ${getUpdateContact.followLazada}`)
          }
          // end social media
          if(getUpdateContact.country !== ""){
            const newCountry = generateSelectedCountry(getUpdateContact.country!);
            newContactData.selectedCountry = newCountry;
          }
          console.log("newContactList : ", newContactList);
          dispatch(ContactRedux.updateListContact(newContactList));
          dispatch(ContactRedux.setContactData(newContactData));
        }
        formik.setSubmitting(true);
        nav(-1, {
          state: {
            id: customer.id,
            name:
              customer.firstName +
              (customer.lastName ? " " + customer.lastName : ""),
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setIsModalOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: contactForEdit,
    validationSchema: editContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const elementEmail = document.getElementById("input-email");
        const elementPhone = document.getElementById("input-phone");

        let valueEmail = elementEmail?.getAttribute("value") || "";
        let valuePhone = elementPhone?.getAttribute("value") || "";

        const errorEmail = document.getElementById("error-email");
        const errorPhone = document.getElementById("error-phone");

        // if there is error in email or phone, don't save
        if (errorEmail !== null || errorPhone !== null) {
          return;
        }

        /**
         * if element input for email not exist,
         * it means user has already have the email and phone
         * so get the value from redux customer
         */
        if (elementEmail === null) {
          values.email = customer.email ? customer.email : "";
        } else {
          // otherwise get from input value
          values.email = valueEmail;
        }

        if (elementPhone === null) {
          values.phoneNumber = customer.phoneNumber ? customer.phoneNumber : "";
        } else {
          values.phoneNumber = valuePhone.length > 8 ? valuePhone : "";
        }

        // if phone number already exist
        const errorDuplicatePhoneElement =
          document.getElementById("error-phone-exist");

        if (valuePhone) {
          const clientRef = createRef("clients", client?.id);
          if (valuePhone !== "" && valuePhone.startsWith("0")) {
            valuePhone = "62" + valuePhone.substring(1);
          }
          if (await checkPhoneNo(valuePhone!, clientRef)) {
            errorDuplicatePhoneElement?.classList.remove("d-none");
            return;
          } else {
            errorDuplicatePhoneElement?.classList.add("d-none");
            setIsPhoneAlreadyExist(false);
          }
        }

        // if email or phone is updated show modal first

        if (
          (valuePhone && valuePhone?.length > 8) ||
          (valueEmail && valueEmail?.length > 0)
        ) {
          setIsPhoneOrEmailEdited(true);
          setIsModalOpen(true);
        } else {
          await handleSave(values);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  });

  const isFormEdited = isOneOfTheObjectTrue(formik.touched);

  useEffect(()=>{
    if(maritalStatusList.length === 0){
      //copy from MaritalStatusList in variabel list
      const list = MaritalStatusList.map((item) => {
        return {
          ...item,
          label: t(item.label),
        };
      });
      //log list
      console.log("check list marital status : ",list);
      setMaritalStatusList(list);
      console.log("customer marital status : ", customer.maritalStatus);
    }
    if(genderList.length === 0){
      const genderList = GenderList.map((item)=>{
        return{
          ...item,
          label: t(item.label)
        }
      });
      setGenderList(genderList);
      console.log("genderList : ", genderList);
    }
    if(genderList.length !== 0){
      const genderList = GenderList.map((item)=>{
        return{
          ...item,
          label: t(item.label)
        }
      });
      setGenderList(genderList);
      console.log("genderList : ", genderList);
    }
    
  },[])

  return (
    <>
      {!formik.isSubmitting && isFormEdited && isPhoneOrEmailEdited && (
        <ModalConfirmationMoveToOtherPage
          path="/contact/contact-detail/settings"
          onUnsave={() => handleBack()}
        />
      )}

      <ModalConfirmationWithStateHandler
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id="update-email-and-password"
        title={t("Storefront.Alert.Warning")}
        body={t(
          "Contact.AddCustomer.EditContact.Modal.AddPhoneNumber&EmailAddress"
        )}
        saveText={t("AddNewProduct.Modal.VariantCategory.Button.Yes,I'mSure")}
        unSaveText={t("CD.Button.Cancel")}
        onUnsave={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        onSave={() => handleConfirm()}
      />

      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className="card mb-5 mb-xl-10"
          id="kt_profile_details_view"
          style={{ overflowY: "hidden", width: "100%", height: "100%" }}
        >
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t("CD.Title.ProfileDetail")}</h3>
            </div>

            {!isTabletOrMobile && (
              <div className="justify-content-end align-self-center">
                <button
                  data-testid="btnCancel"
                  type="reset"
                  onClick={() => handleBack()}
                  data-kt-users-modal-action="button"
                  className="btn btn-danger"
                  // disabled={formik.isSubmitting || isUserLoading}
                >
                  {t("CD.Button.Cancel")}
                </button>
                <button
                  data-testid="btnSave"
                  type="submit"
                  data-kt-users-modal-action="submit"
                  className="btn btn-primary ms-lg-5"
                >
                  {t("CD.Button.SaveChanges")}
                </button>
              </div>
            )}
          </div>

          <div className={clsx("card-body")}>
            <div
              className={clsx(
                "d-flex w-100",
                isTabletOrMobile ? "flex-column gap-3" : "flex-row gap-10"
              )}
            >
              <div
                className={clsx(
                  "d-flex",
                  isTabletOrMobile ? "flex-column w-100 gap-5" : "flex-row w-75 gap-3"
                )}
              >
                {/* nama depan
                  nama belakang
                  birthdate
                */}
                <div className={clsx("d-flex w-100 flex-column gap-5")}>
                  <div
                    className={clsx(
                      "d-flex flex-row align-items-center",
                      isTabletOrMobile ? "w-100" : "gap-10"
                    )}
                  >
                    <Label>
                      {t("CD.Input.FirstName")}
                      <span className="text-danger">*</span>
                    </Label>
                    <div className={clsx(isTabletOrMobile ? "w-75" : "w-50")}>
                      <input
                        type="text"
                        data-testid="inputFirstName"
                        // 
                        placeholder={t("CD.Input.FirstName")}
                        {...formik.getFieldProps("firstName")}
                        name="firstName"
                        className={clsx(
                          "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.firstName &&
                              formik.errors.firstName,
                          },
                          {
                            "is-valid":
                              formik.touched.firstName &&
                              !formik.errors.firstName,
                          }
                        )}
                        autoComplete="off"
                        disabled={formik.isSubmitting}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div
                          className="fv-plugins-message-container"
                          data-testid="alert-fname"
                        >
                          <div className="fv-help-block">
                            <span role="alert">{t("CD.Error.FirstName")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "d-flex flex-row align-items-center",
                      isTabletOrMobile ? "w-100" : "gap-10"
                    )}
                  >
                    <Label>{t("CD.Input.LastName")}</Label>
                    <div className={clsx(isTabletOrMobile ? "w-75" : "w-50")}>
                      <input
                        type="text"
                        data-testid="inputLastName"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder={t("CD.Input.LastName")}
                        {...formik.getFieldProps("lastName")}
                        name="lastName"
                      />
                    </div>
                  </div>
                </div>
                <div className={clsx("d-flex w-100 flex-column gap-5")}>
                  <div
                    className={clsx(
                      "d-flex flex-row align-items-center",
                      isTabletOrMobile ? "w-100" : "gap-5"
                    )}
                  >
                    <Label>{t("CD.Input.Birthdate")}</Label>
                    <div className={clsx(isTabletOrMobile ? "w-75" : "w-50")}>
                      <input
                        data-testid="inputBirthdate"
                        type="date"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        {...formik.getFieldProps("birthdate")}
                        name="birthdate"
                      />
                      {formik.touched.birthdate && formik.errors.birthdate && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{t("CD.Error.Birthdate")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "d-flex flex-row align-items-center",
                      isTabletOrMobile ? "w-100 mb-5" : "gap-5"
                    )}
                  >
                    <Label>{t("CD.Input.MaritalStatus")}</Label>
                    <div className={clsx(isTabletOrMobile ? "w-75" : "w-50")}>
                      <Select
                        data-testid="inputMaritalStatus"
                        defaultValue={customer?.selectedMaritalStatus}
                        name="maritalStatus"
                        options={maritalStatusList ? maritalStatusList : []}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={t("CD.PH.MarietalStatus")}
                        onChange={(e) => {
                          const maritalStatus: any = e;
                          console.log(
                            "selected marital status :",
                            maritalStatus
                          );
                          formik.setFieldValue(
                            "maritalStatus",
                            maritalStatus.value
                          );
                        }}
                        onKeyDown={(e) => {
                          console.log("e :", e);
                          if (e.key === "enter" || e.keyCode === 13) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "d-flex flex-column",
                  isTabletOrMobile ? "w-100" : "w-25"
                )}
              >
                {/* gender
                 */}
                <div className="d-flex flex-row align-items-center">
                  <Label>{t("CD.Input.Gender")}</Label>
                  <div className={clsx(isTabletOrMobile ? "w-75" : "")}>
                    <Select
                      data-testid="inputGender"
                      defaultValue={customer?.selectedGender}
                      name="gender"
                      options={genderList ? genderList : []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={t("CD.PH.Gender")}
                      onChange={(e) => {
                        const gender: any = e;
                        console.log("selected gender :", gender);
                        formik.setFieldValue("gender", gender.value);
                      }}
                      onKeyDown={(e) => {
                        console.log("e :", e);
                        if (e.key === "enter" || e.keyCode === 13) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h4 className="fw-bolder m-0">{t("CD.Input.Address")}</h4>
            </div>
          </div>
          <div
            className={clsx("card-body", isTabletOrMobile ? "mb-20" : "mb-10")}
          >
            <div
              className={clsx(
                "d-flex w-100",
                isTabletOrMobile ? "flex-column gap-3" : "flex-row gap-10"
              )}
            >
              <div
                className={clsx(
                  "d-flex flex-column gap-3",
                  isTabletOrMobile ? "w-100" : "w-50"
                )}
              >
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.Address.StreetName")}</Label>
                  <div className="w-75">
                    <input
                      type="text"
                      data-testid="inputStreetName"
                      placeholder={t(
                        "Contact.EditContact.Address.Field.StreetName"
                      )}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("address")}
                      name="address"
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max255Characters")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.Address.District")}</Label>
                  <div className="w-75">
                    <input
                      data-testid="inputDistrict"
                      type="text"
                      placeholder={t(
                        "Contact.EditContact.Address.Field.District"
                      )}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("district")}
                      name="district"
                    />
                    {formik.touched.district && formik.errors.district && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max100Characters")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("CD.Input.City")}</Label>
                  <div className="w-75">
                    <input
                      type="text"
                      data-testid="inputCity"
                      placeholder={t("Contact.EditContact.Address.Field.City")}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("city")}
                      name="city"
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max100Characters")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "d-flex flex-column gap-3",
                  isTabletOrMobile ? "w-100" : "w-50"
                )}
              >
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.Address.Province")}</Label>
                  <div className="w-75">
                    <input
                      type="text"
                      data-testid="inputProvince"
                      placeholder={t(
                        "Contact.EditContact.Address.Field.Province"
                      )}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("province")}
                      name="province"
                    />
                    {formik.touched.province && formik.errors.province && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max100Characters")}
                      </div>
                    )}
                  </div>
                </div>
                <div className={clsx("d-flex flex-row w-100 align-items-center", isTabletOrMobile ? "mb-2" : "")}>
                  <Label>{t("CD.Input.Country")}</Label>
                  <div className="w-75">
                    <Select
                      data-testid="inputCountry"
                      defaultValue={customer.selectedCountry}
                      name="country"
                      options={CountryList ? CountryList : []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={t("CD.PH.Country")}
                      onChange={(e) => {
                        const country: any = e;
                        console.log("selected country :", country);
                        formik.setFieldValue("country", country.value);
                      }}
                      onKeyDown={(e) => {
                        console.log("e :", e);
                        if (e.key === "enter" || e.keyCode === 13) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      menuPlacement="top"
                    />
                  </div>
                </div>
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("CD.Input.ZipCode")}</Label>
                  <div className="w-75">
                    <input
                      type="text"
                      data-testid="inputZipCode"
                      placeholder={t(
                        "Contact.EditContact.Address.Field.ZipCode"
                      )}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      {...formik.getFieldProps("zipcode")}
                      name="zipcode"
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          formik.setFieldValue("zipcode", e.target.value);
                        }
                      }}
                      // maxLength={5}
                      // max={99999}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max5Characters")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* social media */}
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h4 className="fw-bolder m-0">{t("CD.Input.SocialMedia")}</h4>
            </div>
          </div>
          <div
            className={clsx("card-body", isTabletOrMobile ? "mb-20" : "mb-10")}
          >
             <div
              className={clsx("d-flex w-100", {
                  "flex-column gap-3": isTabletOrMobile,
                  "flex-row gap-10": !isTabletOrMobile
                }
              )}
            >
              <div
                className={clsx("d-flex flex-column gap-3", {
                    "w-100": isTabletOrMobile,
                    "w-50": !isTabletOrMobile
                  }
                )}
              > 
              {/* facebook */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Facebook")}</Label>
                  <div className="w-75">
                     <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listFacebook}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Facebook"
                      )}
                      testId="inputFacebook" 
                      onDataUpdate={(selected: any[]) => {
                        setListFacebook(selected);
                        console.log(`onDataUpdate List Facebook ${JSON.stringify(listFacebook)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "facebook",
                          values
                        );
                        // console.log(`onDataUpdate tutupfacebookList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("facebook",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("facebook"))}`)
                      }}
                      // {...formik.getFieldProps("facebook")}
                      name="facebook"
                    />
                  </div>
                </div>
               
              {/* Instagram */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Instagram")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listInstagram}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Instagram"
                      )}
                      testId="inputInstagram" 
                      onDataUpdate={(selected: any[]) => {
                        setListInstagram(selected);
                        console.log(`onDataUpdate List instagram ${JSON.stringify(listInstagram)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "instagram",
                          values
                        );
                        // console.log(`onDataUpdate tutupinstagramList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("instagram",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("instagram"))}`)
                      }}
                      // {...formik.getFieldProps("instagram")}
                      name="instagram"
                    />
                </div>
                </div>
              {/* Tiktok */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Tiktok")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listTiktok}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Tiktok"
                      )}
                      testId="inputTiktok" 
                      onDataUpdate={(selected: any[]) => {
                        setListTiktok(selected);
                        console.log(`onDataUpdate List tiktok ${JSON.stringify(listTiktok)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "tiktok",
                          values
                        );
                        // console.log(`onDataUpdate tutupinstagramList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("instagram",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("instagram"))}`)
                      }}
                      // {...formik.getFieldProps("instagram")}
                      name="tiktok"
                    />
                    {/* <input
                      type="text"
                      data-testid="inputTiktok"
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Tiktok"
                      )}
                      
                      {...formik.getFieldProps("tiktok")}
                      name="tiktok"
                    />
                    {formik.touched.tiktok && formik.errors.tiktok && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max255Characters")}
                      </div>
                    )} */}
                  </div>
                </div>
              {/* Shopee */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Shopee")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listShopee}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Shopee"
                      )}
                      testId="inputShopee" 
                      onDataUpdate={(selected: any[]) => {
                        setListShopee(selected);
                        console.log(`onDataUpdate List shopee ${JSON.stringify(listShopee)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "shopee",
                          values
                        );
                        // console.log(`onDataUpdate tutupshopeeList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("shopee",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("shopee"))}`)
                      }}
                      // {...formik.getFieldProps("shopee")}
                      name="shopee"
                    />
                    {/* <input
                      type="text"
                      data-testid="inputShopee"
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Shopee"
                      )}
                      
                      {...formik.getFieldProps("shopee")}
                      name="shopee"
                    />
                    {formik.touched.shopee && formik.errors.shopee && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max255Characters")}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              
              {/* right side */}
              <div
                className={clsx(
                  "d-flex flex-column gap-3",
                  isTabletOrMobile ? "w-100" : "w-50"
                )}
              >
                {/* Tokopedia */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Tokopedia")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listTokopedia}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Tokopedia"
                      )}
                      data-testid="inputTokopedia" 
                      onDataUpdate={(selected: any[]) => {
                        setListTokopedia(selected);
                        console.log(`onDataUpdate List Tokopedia ${JSON.stringify(listTokopedia)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "tokopedia",
                          values
                        );
                        // console.log(`onDataUpdate tutupshopeeList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("shopee",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("shopee"))}`)
                      }}
                      // {...formik.getFieldProps("shopee")}
                      name="tokopedia"
                    />
                    {/* <input
                      type="text"
                      data-testid="inputTokopedia"
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Tokopedia"
                      )}
                      
                      {...formik.getFieldProps("tokopedia")}
                      name="tokopedia"
                    />
                    {formik.touched.tokopedia && formik.errors.tokopedia && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max255Characters")}
                      </div>
                    )} */}
                  </div>
                </div>
                {/* Lazada */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Lazada")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listLazada}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Lazada"
                      )}
                      testId="inputLazada" 
                      onDataUpdate={(selected: any[]) => {
                        setListLazada(selected);
                        console.log(`onDataUpdate List Lazada ${JSON.stringify(listLazada)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "lazada",
                          values
                        );
                        // console.log(`onDataUpdate tutupshopeeList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("shopee",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("shopee"))}`)
                      }}
                      // {...formik.getFieldProps("shopee")}
                      name="lazada"
                    />
                  </div>
                </div>
                {/* Bukalapak */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Bukalapak")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listBukalapak}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Bukalapak"
                      )}
                      testId="inputBukalapak" 
                      onDataUpdate={(selected: any[]) => {
                        setListBukalapak(selected);
                        console.log(`onDataUpdate List Bukalapak ${JSON.stringify(listBukalapak)}`)
                        console.log(`onDataUpdate ${JSON.stringify(selected)}`)
                        const values = selected.map((option)=> `${option.value}`)
                        console.log(`onDataUpdate mapValue ${JSON.stringify(values)}`)
                        formik.setFieldValue(
                          "bukalapak",
                          values
                        );
                        // console.log(`onDataUpdate tutupshopeeList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("shopee",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("shopee"))}`)
                      }}
                      // {...formik.getFieldProps("shopee")}
                      name="bukalapak"
                    />
                    
                  </div>
                </div>
                {/* Blibli */}
                <div className="d-flex flex-row w-100 align-items-center">
                  <Label>{t("Contact.EditContact.SocialMedia.Blibli")}</Label>
                  <div className="w-75">
                  <CreateableSelect 
                      
                      
                      // value={customer?.selectedFacebook ? customer?.selectedFacebook : []}
                      value={listBlibli}
                      placeholder={t(
                        "Contact.EditContact.SocialMedia.PH.Blibli"
                      )}
                      testId="inputBlibli" 
                      onDataUpdate={(selected: any[]) => {
                        const values = selected.map((option) => option.value);

                        // Check for duplicates in the selected values
                        const duplicates = values.filter(
                          (value, index) => values.indexOf(value) !== index
                        );
                    
                        if (duplicates.length > 0) {
                          // Set an error for the field if there are duplicates
                          formik.setFieldError("blibli", "Duplicate values are not allowed");
                        } else {
                          // Clear the error if there are no duplicates
                          formik.setFieldError("blibli", undefined);
                        }
                    
                        setListBlibli(selected);
                        formik.setFieldValue("blibli", values);
                        // console.log(`onDataUpdate tutupshopeeList${JSON.stringify(selected)}`)
                        // console.log(`onDataUpdate formik set fields prop ${JSON.stringify(formik.setFieldValue("shopee",selected))}`)
                        // console.log(`onDataUpdate formik get fields prop ${JSON.stringify(formik.getFieldProps("shopee"))}`)
                      }}
                      // {...formik.getFieldProps("shopee")}
                      name="blibli"
                    />
                    {/* {formik.touched.blibli && formik.errors.blibli && (
                      <div className="text-danger mt-1">
                        {t("Contact.Edit.Alert.Max255Characters")}
                      </div>
                    )}  */}
                  </div>
                </div>
              </div>
              </div>
              </div>
        </div>
      </form>

      {!isTabletOrMobile && (
        <div className="position-sticky d-flex align-items-center bottom-0 h-70px bg-white rounded z-50">
          <Button btnbs="primary" cName="mx-5" onClick={() => handleBack()}>
            {t("Storefront.Button.Back")}
          </Button>
        </div>
      )}

      {isTabletOrMobile && (
        <div
          className="p-5 w-100 shadow-sm bg-white position-fixed text-end"
          style={{ bottom: "90px", right: 0, zIndex: "998" }}
        >
          <div className="justify-content-end align-self-center">
            <button
              data-testid="btnCancel"
              type="reset"
              onClick={() => handleBack()}
              data-kt-users-modal-action="button"
              className="btn btn-danger me-5"
              // disabled={formik.isSubmitting || isUserLoading}
            >
              {t("CD.Button.Cancel")}
            </button>
            <button
              form="kt_modal_add_user_form"
              data-testid="btnSave"
              type="submit"
              data-kt-users-modal-action="submit"
              className="btn btn-primary ms-lg-5"
            >
              {t("CD.Button.SaveChanges")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
