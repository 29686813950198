import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CellColoumnsProp } from "./_columns";
import { useEffect, useState } from "react";
import User from "../../../../../../../../models/User";
import { useMediaQuery } from "react-responsive";
import {
  deleteUserByID,
  deleteUserFriendListByID,
} from "../../../../../../../../../db/serviceUser";
import { useFirestoreTable } from "../../../context/TableContext";
import { resendInvitation } from "../../../../../../../../../api/server/member";
import { toast } from "react-toastify";
type ActionCellProps = {
  data: User;
};

const ActionCell: React.FC<ActionCellProps> = ({ data }) => {
  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [isReassureDeleteOpen, setIsReassureDeleteOpen] = useState(false);
  const onReassureDeleteClose = () => setIsReassureDeleteOpen(false);
  const { refreshData, isFetching, updateTableState, isLoading } =
    useFirestoreTable();

  const deleteAction = async () => {
    await deleteUserByID(String(data.id));
    refreshData();
    setDropdownVisible(false);
    onReassureDeleteClose();
    toast("Delete Invitation Success", {
      type: "success",
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const reassureDeleteOpen = () => {
    setIsReassureDeleteOpen(true);
  };

  const handleEditResend = async () => {
    setDropdownVisible(false)
    if (data.status === "invited") {
      // resend invitation
      await resendInvitation(
        String(data.id),
        (responseCode: number, response: any) => {
          console.log(response, "test response");
          console.log(responseCode, "test responseCode");
          if (!responseCode || response === undefined) {
            toast("Resend Invitation Error", {
              type: "error",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
          if (!response.success) {
            toast(response.message, {
              type: "error",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
          toast("Resend Invitation Success", {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    } else {
      // edit data
      console.log("edit data");
    }
  };
  return (
    <>
      <Dropdown
        id={data.id}
        show={dropdownVisible}
        onClick={(e) => {
          e.preventDefault();
        }}
        onToggle={(isOpen) => {
          setDropdownVisible(isOpen);
        }}
      >
        {isTabletOrMobile ? (
          <Dropdown.Toggle
            variant="transparent"
            id="dropdown-toggle"
            className="no-caret pe-0 me-0"
            bsPrefix="bi bi-three-dots-vertical fs-1 pe-3"
            // as={CustomToggle}
          >
            {/* <i className="bi bi-three-dots-vertical fs-1 pe-3"></i> */}
          </Dropdown.Toggle>
        ) : (
          <>
            <Dropdown.Toggle variant="secondary" id="dropdown-toggle">
              {t("Marketplace.Table.Header.Action")}
            </Dropdown.Toggle>
          </>
        )}

        <Dropdown.Menu className="bg-light text-center">
          <Link
            className="dropdown-item btn btn-black btn-sm"
            to="#"
            onClick={() => {
              handleEditResend();
            }}
            data-testid="editButton"
          >
            {data.status === "invited"
              ? t("Retry.Info.Resend")
              : t("Marketplace.Button.Edit")}
          </Link>
          <Link
            className="dropdown-item btn btn-black btn-sm"
            to="#"
            onClick={() => {
              if (data.status === "invited") {
                reassureDeleteOpen();
              }
            }}
            data-testid="deleteButton"
          >
            {t("Marketplace.Action.Delete")}
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      {isReassureDeleteOpen === true && (
        <Modal
          animation
          centered
          show={isReassureDeleteOpen === true}
          data-testid="modalReassureDeleteMarketplaceAction"
          // size="sm"
          scrollable={false}
          onHide={() => onReassureDeleteClose && onReassureDeleteClose()}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Marketplace.Modal.ReassureDelete.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="fs-4">
              <p>{t("Marketplace.Modal.ReassureDelete.Body")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <button
              type="button"
              data-testid="modalCancelButton"
              className="btn btn-outline-primary w-15 text-center"
              style={{
                border: "0.5px solid #189ffb",
              }}
              onClick={() => onReassureDeleteClose && onReassureDeleteClose()}
            >
              {t("Marketplace.Modal.ReassureDelete.Cancel")}
            </button>
            <button
              type="button"
              data-testid="modalDeleteButton"
              className="btn btn-primary w-30"
              onClick={() => {
                deleteAction();
              }}
            >
              {t("Marketplace.Modal.ReassureDelete.Yes")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ActionCell;
