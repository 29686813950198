import clsx from "clsx";
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import { validateURL, hasSubQuery } from "../../../../../util/validation";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { button } from "../../../../models/Language";
import { useMediaQuery } from "react-responsive";

interface Props {
  idx: number;
  mode: "Visit_Web" | "Call_Phone" | "Complete_Form" | "Copy_Offer";
  validationState?: any;
  content: {
    index: number;
    button_text: string;
    footer_text: string;
    url_website: string;
    phone_number: string;
    offer_code: string;
    form_type: string;
    url_type: string;
  };
  handleInputChange: (
    index: number,
    button_text: string,
    footer_text: string,
    url_website: string,
    url_type: string,
    phone_number: string,
    offer_code: string,
    form_type: string,
    type: string
  ) => void;
  handleDeleteItem: (id: number) => void;
}

const CTAComponent: FC<Props> = ({
  idx,
  mode,
  content,
  handleInputChange,
  validationState,
  handleDeleteItem,
}) => {
  const templateCtaButton = useSelector(
    (state: RootState) => state.Template.templateButton
  );
  const index = content.index;
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const formTitle = `${t(`IB.Title.${mode}`)}`;
  const formId = `${mode}Form`;
  const [btnText, setBtnText] = useState<string>(content.button_text);
  const [footerText, setFooterText] = useState<string>(content.footer_text);
  const [offerCode, setOfferCode] = useState<string>(content.offer_code);
  const [urlWeb, seturlWeb] = useState<string>(content.url_website);
  const [urlType, seturlType] = useState<string>(content.url_type);
  const [formType, setFormType] = useState<string>(content.form_type);
  const [phoneNumber, setPhoneNumber] = useState<string>(content.phone_number);
  const { isTitleIdentical } = validationState;
  const dispatch = useDispatch();

  console.log(urlType, "test url");

  const isVisitWeb = () => mode === "Visit_Web";
  const isCallPhone = () => mode === "Call_Phone";
  const isCompleteForm = () => mode === "Complete_Form";
  const isCopyOffer = () => mode === "Copy_Offer";

  return (
    <>
      <div
        className="mb-4"
        style={{
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          width: "100%",
        }}
      >
        <div
          id={`${formId}`}
          className="W-100 p-5 px-3 mx-0 pt-0 pb-0 mb-0 mt-0 d-flex align-items-center justify-content-center"
        >
          {isVisitWeb() && (
            <div
              className={
                (isTabletOrMobile
                  ? "d-flex flex-row flex-wrap flex-1 w-100 align-items-center justify-content-between row-gap-1"
                  : "flex-row") +
                " w-100 d-flex flex-1 align-items-start justify-content-between m-0 px-2 py-4"
              }
            >
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isVisitWeb()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>Type of Action</label>
                <input
                  disabled={true}
                  type="text"
                  maxLength={25}
                  defaultValue={formTitle}
                  id="buttonTitle"
                  className="form-control"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                />
              </div>

              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isVisitWeb()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t("Template.Button.Text")}
                  <span className="text-danger">*</span>
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={btnText}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="btnText"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 25) return;

                      setBtnText(e.target.value);
                      handleInputChange(
                        index,
                        e.target.value,
                        footerText,
                        urlWeb,
                        urlType,
                        "",
                        "",
                        "",
                        mode
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {btnText.length}/25
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {isTitleIdentical && (
                    <label className="text-danger">
                      {t("IB.Error.BTSame")}
                    </label>
                  )}

                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={
                  isTabletOrMobile ? "d-flex flex-column col-4" : "col-2"
                }
              >
                <label style={{ fontWeight: 600 }}>
                  {t("IB.Title.URLType")}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  value={{
                    value:
                      urlType === "STATIC"
                        ? "1"
                        : urlType === "DYNAMIC"
                        ? "2"
                        : "",
                    label:
                      urlType === "STATIC"
                        ? "Static"
                        : urlType === "DYNAMIC"
                        ? "Dynamic"
                        : "Select URL Type",
                  }}
                  options={[
                    { value: "1", label: "Static" },
                    { value: "2", label: "Dynamic" },
                  ]}
                  id="callToActionButton"
                  data-testid={"input-url-type"}
                  classNamePrefix="select"
                  placeholder={t("IB.Input.SelectBT")}
                  onChange={(newVal: any) => {
                    if (newVal) {
                      let urlTypes =
                        newVal?.value === "1" ? "STATIC" : "DYNAMIC";

                      seturlType(urlTypes);
                      handleInputChange(
                        index,
                        btnText,
                        footerText,
                        urlWeb,
                        urlTypes,
                        "",
                        "",
                        "",
                        mode
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter" || e.keyCode === 13) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                />
              </div>
              <div
                className={
                  isTabletOrMobile ? "d-flex flex-column col-7" : "col-3"
                }
              >
                <label style={{ fontWeight: 600 }}>
                  {t("IB.Title.URLWebsite")}
                  <span className="text-danger">*</span>
                </label>

                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={urlWeb}
                    maxLength={2000}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="urlWeb"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "55px", // Tambahkan padding untuk ruang di dalam input
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 2000) return;

                      seturlWeb(e.target.value);
                      handleInputChange(
                        index,
                        btnText,
                        footerText,
                        e.target.value,
                        urlType,
                        "",
                        "",
                        "",
                        mode
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {btnText.length}/2000
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}
                </div>
              </div>

              {isTabletOrMobile ? (
                <div className="col-12 py-3">
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => {
                      console.log("Cancel", mode, idx);
                      handleDeleteItem(Number(idx));
                    }}
                  >
                    Discard
                  </button>
                </div>
              ) : (
                <div className="col-1 p-0 m-0 d-flex align-self-center justify-content-center h-100 w-auto">
                  <p
                    className="p-0 m-0 w-auto"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className="bi bi-x text-black fs-1 fw-bolder p-0 m-0 cursor-pointer"
                      onClick={() => {
                        console.log("Cancel", mode, idx);
                        handleDeleteItem(Number(idx));
                      }}
                    ></i>
                  </p>
                </div>
              )}
            </div>
          )}
          {isCallPhone() && (
            <div
              className={
                (isTabletOrMobile ? "flex-column" : "flex-row") +
                " w-100 d-flex flex-1 align-items-start justify-content-between m-0 px-2 py-4"
              }
            >
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCallPhone()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>Type of Action</label>
                <input
                  disabled={true}
                  type="text"
                  maxLength={25}
                  defaultValue={formTitle}
                  id="buttonTitle"
                  className="form-control"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCallPhone()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t("Template.Button.Text")}
                  <span className="text-danger">*</span>
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={btnText}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="btnText"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "50px",
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 25) return;

                      setBtnText(e.target.value);
                      handleInputChange(
                        index,
                        e.target.value,
                        "",
                        "",
                        "",
                        phoneNumber,
                        "",
                        "",
                        mode
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {btnText.length}/25
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {isTitleIdentical && (
                    <label className="text-danger">
                      {t("IB.Error.BTSame")}
                    </label>
                  )}

                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCallPhone()
                    ? "col-5"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t(`IB.Title.PhoneNumber`)}
                  <span className="text-danger">*</span>
                </label>
                <PhoneInput
                  data-testid="input-phone"
                  containerClass="form-control d-flex"
                  containerStyle={{
                    padding: "3px 3px 3px 0px",
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                  inputStyle={{
                    borderWidth: "0px",
                    width: "auto",
                    overflow: "scroll",
                  }}
                  dropdownStyle={{ borderColor: "black" }}
                  country="id"
                  masks={{ id: "... .... .... ...." }}
                  enableSearch={true}
                  value={phoneNumber}
                  onChange={(phone) => {
                    setPhoneNumber(phone);
                    handleInputChange(
                      index,
                      btnText,
                      "",
                      "",
                      "",
                      phone,
                      "",
                      "",
                      mode
                    );
                  }}
                  countryCodeEditable={false}
                />
                <div className="d-flex">
                  {phoneNumber?.length === 0 && (
                    <label id={`PhoneNumberRequired`} className="text-danger">
                      {t("IB.Error.PNEmpty")}
                    </label>
                  )}
                  <label
                    id="urlWebsiteValidURLRequired"
                    className={clsx("text-danger", {
                      "d-none":
                        phoneNumber?.length === 0 || phoneNumber?.length > 4,
                    })}
                  >
                    {t("IB.Error.PNInvalid")}
                  </label>
                </div>
              </div>
              {isTabletOrMobile ? (
                <div className="col-12 py-3">
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => {
                      console.log("Cancel", mode, idx);
                      handleDeleteItem(Number(idx));
                    }}
                  >
                    Discard
                  </button>
                </div>
              ) : (
                <div className="col-1 p-0 m-0 d-flex align-self-center justify-content-center h-100 w-auto">
                  <p
                    className="p-0 m-0 w-auto"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className="bi bi-x text-black fs-1 fw-bolder p-0 m-0 cursor-pointer"
                      onClick={() => {
                        console.log("Cancel", mode, idx);
                        handleDeleteItem(Number(idx));
                      }}
                    ></i>
                  </p>
                </div>
              )}
            </div>
          )}
          {isCopyOffer() && (
            <div
              className={
                (isTabletOrMobile ? "flex-column" : "flex-row") +
                " w-100 d-flex flex-1 align-items-start justify-content-between m-0 px-2 py-4"
              }
            >
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCopyOffer()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>Type of Action</label>
                <input
                  disabled={true}
                  type="text"
                  maxLength={25}
                  defaultValue={formTitle}
                  id="buttonTitle"
                  className="form-control"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCopyOffer()
                    ? "col-3"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t("Template.Button.Text")}
                  <span className="text-danger">*</span>
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={btnText}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid="btnText"
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 25) return;

                      setBtnText(e.target.value);
                      handleInputChange(
                        index,
                        e.target.value,
                        "",
                        "",
                        "",
                        "",
                        offerCode,
                        "",
                        mode
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {btnText.length}/25
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {isTitleIdentical && (
                    <label className="text-danger">
                      {t("IB.Error.BTSame")}
                    </label>
                  )}

                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={clsx(
                  isTabletOrMobile
                    ? "col-12"
                    : isCopyOffer()
                    ? "col-5"
                    : "col-md-4"
                )}
              >
                <label style={{ fontWeight: 600 }}>
                  {t("IB.Title.OfferCode")}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    defaultValue={offerCode}
                    maxLength={25}
                    type="text"
                    className="form-control"
                    id="buttonTitle"
                    data-testid={
                      isCopyOffer() ? "button-title-url" : "button-title-pn"
                    }
                    style={{
                      borderColor: "#979797",
                      borderWidth: "0.3px",
                      borderRadius: "5px",
                      paddingRight: "50px", // Tambahkan padding untuk ruang di dalam input
                    }}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      if (val.length > 25) return;

                      setOfferCode(e.target.value);
                      handleInputChange(
                        index,
                        btnText,
                        "",
                        "",
                        "",
                        "",
                        val,
                        "",
                        mode
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      fontSize: "0.875em", // Sesuaikan ukuran teks
                      color: "#979797", // Sesuaikan warna teks
                    }}
                  >
                    {offerCode ? offerCode?.length : 0}/25
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {!offerCode ||
                    (offerCode?.length === 0 && (
                      <label
                        id={`${mode}TitleRequired`}
                        className={clsx("text-danger")}
                      >
                        {t("IB.Error.BFooterEmpty")}
                      </label>
                    ))}
                </div>
              </div>
              {isTabletOrMobile ? (
                <div className="col-12 py-3">
                  <button
                    className="btn btn-danger w-100"
                    onClick={() => {
                      console.log("Cancel", mode, idx);
                      handleDeleteItem(Number(idx));
                    }}
                  >
                    Discard
                  </button>
                </div>
              ) : (
                <div className="col-1 p-0 m-0 d-flex align-self-center justify-content-center h-100 w-auto">
                  <p
                    className="p-0 m-0 w-auto"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className="bi bi-x text-black fs-1 fw-bolder p-0 m-0 cursor-pointer"
                      onClick={() => {
                        console.log("Cancel", mode, idx);
                        handleDeleteItem(Number(idx));
                      }}
                    ></i>
                  </p>
                </div>
              )}
            </div>
          )}
          {isCompleteForm() && (
            <div className="row g-3 mt-2">
              <div className="col-md-5">
                <label style={{ fontWeight: 600 }}>
                  {t("Template.Button.Text")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  defaultValue={btnText}
                  maxLength={25}
                  type="text"
                  className="form-control"
                  id="buttonTitle"
                  data-testid="btnText"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => {
                    const val = e.target.value as string;
                    if (val.length > 25) return;

                    setBtnText(e.target.value);
                    handleInputChange(
                      index,
                      e.target.value,
                      "",
                      "",
                      "",
                      "",
                      "",
                      formType,
                      mode
                    );
                  }}
                />
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {isTitleIdentical && (
                    <label className="text-danger">
                      {t("IB.Error.BTSame")}
                    </label>
                  )}

                  {btnText.length === 0 && (
                    <label
                      id={`${mode}TitleRequired`}
                      className={clsx("text-danger")}
                    >
                      {t("IB.Error.BTextEmpty")}
                    </label>
                  )}

                  <div
                    style={{
                      justifySelf: "end",
                      marginLeft: "auto",
                    }}
                  >
                    {btnText.length}/25
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <label style={{ fontWeight: 600 }}>
                  Type of Form
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="callToActionButton"
                  data-testid={"input-url-type"}
                  className="form-control basic-single form-select"
                  aria-label="Default select example"
                  style={{
                    borderColor: "#979797",
                    borderWidth: "0.3px",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => {
                    let formTypes;
                    switch (e.target.value) {
                      case "1":
                        formTypes = "REGISTER";
                        break;
                      case "2":
                        formTypes = "COMPLETE_QUIZ";
                        break;
                      case "3":
                        formTypes = "COMPLETE_SIGN";
                        break;
                      case "4":
                        formTypes = "UPDATE";
                        break;
                      case "5":
                        formTypes = "CUSTOM";
                        break;
                    }
                    setFormType(String(formTypes));
                    handleInputChange(
                      index,
                      btnText,
                      "",
                      "",
                      "",
                      "",
                      "",
                      String(formTypes),
                      mode
                    );
                  }}
                  value={
                    formType === "REGISTER"
                      ? "1"
                      : formType === "COMPLETE_QUIZ"
                      ? "2"
                      : formType === "COMPLETE_SIGN"
                      ? "3"
                      : formType === "UPDATE"
                      ? "4"
                      : formType === "CUSTOM"
                      ? "5"
                      : ""
                  }
                >
                  <option value="1">Register for an event</option>
                  <option value="2">Complete our quiz</option>
                  <option value="3">Complete sign up</option>
                  <option value="4">Update preferences</option>
                  <option value="5">Custom form</option>
                </select>
              </div>
              <button
                className="btn btn-primary col-md-2 align-self-center"
                style={{ height: "40px", padding: 0 }}
                onClick={() => {
                  alert("Feature under construction ...");
                }}
              >
                Create
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CTAComponent;
