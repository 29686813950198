import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as ApiSettingSlice from "../../../../../modules/api-setting/redux/ApiSettingSlice";
import axios from "axios";
import Button from "../../../../../components/button/Button";

const SMSFailoverEditPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiSetting = useSelector((state: any) => state.ApiSetting);
  const [failoverSenderMode, setFailoverSenderMode] = useState<"LN" | "MS">("LN");
  const [maskingSMS, setMaskingSMS] = useState<string>("");
  const [isMaskingValid, setIsMaskingValid] = useState<boolean | null>(null);
  const handleFailoverSenderModeChange = (label: "LN" | "MS") => {
    setFailoverSenderMode(label);
    dispatch(
      ApiSettingSlice.setApiSettingSms({
        failoverSenderMode: label,
      })
    );

    // Set state to edited
    triggerEditStateChange();
  };

  const handleMaskingSMSIDChange = (maskingSMSValue: string) => {
    setMaskingSMS(maskingSMSValue);

    // Set state to edited
    triggerEditStateChange();
  };

  // const handleRegisterMaskingIDSender = async (maskingSMS: string) => {
    const handleRegisterMaskingIDSender = async () => {
    try {
      const accessToken = apiSetting.access.accessToken;
      const serverBaseUrl = process.env.REACT_APP_SERVER_URL;
      const verifyUrl = await axios.post(
        `${serverBaseUrl}/v1/sms/masking`,
        { maskingSMS: maskingSMS },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (verifyUrl.status === 200) {
        dispatch(ApiSettingSlice.setVerifyMaskingErrorStatus(false));
        setIsMaskingValid(true);
      } else if (verifyUrl.status === 202) {
        dispatch(ApiSettingSlice.setVerifyMaskingErrorStatus(false));
        setIsMaskingValid(true);
      } else {
        dispatch(ApiSettingSlice.setVerifyMaskingErrorStatus(true));
        setIsMaskingValid(false);
      }
    } catch (error) {
      dispatch(ApiSettingSlice.setVerifyMaskingErrorStatus(true));
      setIsMaskingValid(false);
    }
    dispatch(
      ApiSettingSlice.setApiSettingSms({
        failoverSenderMode: failoverSenderMode,
        maskingSMS: maskingSMS,
      })
    );
  };

  const triggerEditStateChange = () => {
    dispatch(ApiSettingSlice.setStateEdited(true));
  };

  useEffect(() => {
    setFailoverSenderMode(apiSetting.sms.failoverSenderMode);
    setMaskingSMS(apiSetting.sms.maskingSMS);
  }, [apiSetting.sms]);

  return (
    <>
      <div className="row mt-2">
        <p className="mt-3">{t("API.Setting.SMS.What.Type")}</p>
      </div>
      <div className="row">
        <form action="#" method="post">
          <SelectionCell
            value="LN"
            label={t("API.Setting.SMS.Long.Type")}
            checked={failoverSenderMode === "LN"}
            onChange={() => handleFailoverSenderModeChange("LN")}
          />
          <SelectionCell
            value="MS"
            label={t("API.Setting.SMS.Mask.Type")}
            checked={failoverSenderMode === "MS"}
            onChange={() => handleFailoverSenderModeChange("MS")}
          />

          {failoverSenderMode === "MS" && (
            <div>
              <div className="mt-5 flex">
                <p>{t("API.Setting.SMS.ID.Sender")}</p>
                <input
                  type="text"
                  className="form-control w-50"
                  placeholder={t("API.Setting.SMS.ID.Sender")}
                  name="idSender"
                  value={maskingSMS}
                  onChange={(e) => handleMaskingSMSIDChange(e.target.value)}
                />
                {isMaskingValid && <span className="flex text-success my-3">{t("API.Setting.SMS.Mask.Message.Success")}</span>}
                {isMaskingValid === false && <span className="flex text-danger my-3">{t("API.Setting.SMS.Mask.Message.Failed")}</span>}
              </div>
              <Button
                className="btn btn-primary mt-5"
                id="register_masking"
                type="button"
                onClick={() => handleRegisterMaskingIDSender()}
                data-testid="register-masking-button"
              >
                {t("API.Setting.SMS.Register.Mask")}
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

const SelectionCell: FC<{ value: string; label: string; checked: boolean; onChange?: () => void }> = ({ value, label, checked, onChange }) => {
  return (
    <div className="form-check mt-3">
      <input
        type="radio"
        className="form-check-input"
        name="smsType"
        checked={checked}
        value={value}
        onChange={onChange}
        id={value}
        data-testid={`${value}-radio`}
      />
      <label className="form-check-label radio" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

export default SMSFailoverEditPage;
