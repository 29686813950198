import { Campaign, CampaignData } from "../../../models/Campaign";
import Template from "../../../models/Template";
import Account from "../../../models/Account";
import Language, { button } from "../../../models/Language";

export interface InitialState {
  campaignData: CampaignData;
  clientID: string;
  accountID: string;
  campaignTemplateType: string;
  campaignChannel: string;
  newCampaignName: string | undefined;
  templateID: string;
  newTemplate: Template;
  templateLanguageID: string;
  templateBodyParams: any[];
  templateHeaderParams: any;
  selectedCampaign: Campaign;
  selectedCampaignID: string;
  newCampaignID: string;
  campaignTemplateFinish: boolean;
  campaignState: string;
  selectedAccount: any;
  selectedTemplateLanguage: Language;
  backFromTemplate: boolean;
  fileURL: string;
  selectedCampaignTemplateID: string;
  templateList: Template[];
  isLoading: boolean;
  templateButtons: button[];
  isCampaignDynamicURLValid: boolean;
  // schedule:
}

const UpdateCampaignAction: string = "UpdateCampaign";

// export type unsubFN = () => void;

export default InitialState;

export { UpdateCampaignAction };
