import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ID, toAbsoluteUrl } from "../../../../../../../resources/helpers";
import ModalViewStatusMarketplace from "../../../../../../modules/modal/modalViewStatusMarketplace";
import { useQueryResponseData } from "../../core/QueryResponseProvider";
import * as lc from "../../../../../../../app/modules/localstorage/index";
import { getAccountByID } from "../../../../../../../db/serviceAccount";
import Account from "../../../../../../models/Account";
import { sendRequestStorefront } from "../../../../../../../api/server/storefront";

type Props = {
  id?: ID;
  status?: string;
};

const getAccountData = async (id: any) => {
  //getAccountByID return as object
  const data = await getAccountByID(id);
  console.log(`data in getAccountData : `, data);
  return data;
};

const ViewMarketplace = ({ data, companyAndAccounts }: any) => {
  console.log(`data in ViewMarketplace : `, data);
  console.log("data marketplaceCategory : ", data?.marketplaceCategory);
  const { t } = useTranslation();
  const [requesting, setRequesting] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [marketplaceChecked, setMarketplaceChecked] = useState<any[]>([]);

  const handleRetry = (
    storefrontID: string,
    accountID: string,
    userID: string
  ) => {
    sendRequestStorefront(storefrontID, accountID, userID);
    setRequesting(true);
  };

  const handleRetrySelected = (selectedItem: any[]) => {
    console.log(`seletedItem : `, selectedItem)
    selectedItem.map((request) => {
      sendRequestStorefront(request.storefront, request.account, request.user);
    });
    setRequesting(true);
  };

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setCheckedCount(checkedCount + 1);
    } else {
      setCheckedCount(checkedCount - 1);
      setMarketplaceChecked(marketplaceChecked.slice(0, -2));
    }
  };

  const isMarketplaceEnabled = (key: string, marketplace: any) => {
    return marketplace[key];
  };

  useEffect(() => {
    console.log("use effect modal status");
    setTimeout(() => setRequesting(false), 3000);
  }, [requesting === true]);

  return (
    <>
      {companyAndAccounts?.map((item: any) => {
        return (
          <div className="w-100 border border-line p-3" key={item.company.companyName}>
            <div className="d-flex flex-row w-100 p-0 align-items-center justify-content-between">
              <div className="px-5 h3">{item.company.companyName}</div>
              <div>
                <button
                  className="btn btn-light btn-active-light-primary btn-sm"
                  disabled={marketplaceChecked.length < 1}
                  onClick={() => {
                    handleRetrySelected(marketplaceChecked);
                  }}
                >
                  <span className="px-1">{`${t(
                    "Common.Modal.Button.RetrySelected"
                  )}`}</span>
                  <span className="bi bi-arrow-clockwise"></span>
                </button>
              </div>
            </div>
            <table className="w-100">
              <tr className="w-100 text-center">
                <th className="w-25">{`${t(
                  "Common.Modal.Column.Header.Marketplace"
                )}`}</th>
                <th className="w-25">{`${t(
                  "Common.Modal.Column.Header.Status"
                )}`}</th>
                <th className="w-25">{`${t(
                  "Common.Modal.Column.Header.Information"
                )}`}</th>
                <th className="w-25">{`${t(
                  "Common.Modal.Column.Header.Action"
                )}`}</th>
              </tr>
              <tbody>
              {item.account.map((acc: any) => {
                //find account in data marketplacesRegistered
                console.log(
                  "marketplacesRegistered : ",
                  data.marketplacesRegistered
                );
                console.log("acc.id : ", acc.id);
                const findAccount = data.marketplacesRegistered?.find(
                  (data: any) => data.account.id === acc.id
                );
                console.log("findAccount : ", findAccount);
                let findMarketplace: any;
                if (findAccount) {
                  //find by key in object and return key with value
                  findMarketplace = Object.keys(findAccount).find((key) => {
                    return key === acc.type;
                  });
                  console.log("findKey : ", findMarketplace);
                }
                return (
                  <tr key={acc.id}>
                    <td className="d-flex gap-2 align-items-center cursor-pointer">
                      <input
                        key={`checkbox-${acc.id}`}
                        type="checkbox"
                        disabled={
                          findAccount?.[findMarketplace] === true ? true : false
                        }
                        checked={isMarketplaceEnabled(
                          acc?.id,
                          marketplaceChecked
                        )}
                        onChange={(e: any) => {
                          let newDataSetter: any = {};
                          newDataSetter = {
                            storefront: data.id,
                            account: acc.id,
                            user: data.createdBy.id,
                          };
                          marketplaceChecked.push(newDataSetter);
                          handleCheckboxChange(e);
                        }}
                      />
                      <img
                        className="h-20px"
                        src={toAbsoluteUrl(
                          `/media/logos/marketplace/shopee.png`
                        )}
                        alt={"shopee.png"}
                      />
                      <span className="d-block">
                        {/* acc.type and style uppercase in first letter */}
                        {acc.type.charAt(0).toUpperCase() + acc.type.slice(1)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span
                        className={clsx(
                          `badge ${
                            findAccount?.[findMarketplace] === true
                              ? "badge-light-success"
                              : "badge-light-danger"
                          }`
                        )}
                      >
                        {`${
                          findAccount?.[findMarketplace] === true
                            ? t("Common.Modal.Column.Contents.Status.Success")
                            : t("Common.Modal.Column.Contents.Status.Failed")
                        }`}
                      </span>
                    </td>
                    <td className="text-center">-</td>
                    <td className="text-center py-0">
                      <button
                        className="btn btn-light btn-active-light-primary btn-sm"
                        role="button"
                        disabled={
                          findAccount?.[findMarketplace] === true ? true : false
                        }
                        onClick={() => {
                          //get user id from localstorage
                          const user = lc.getItemLC(lc.LCName.User);
                          handleRetry(data?.id, acc?.id, user?.uid);
                        }}
                      >
                        <span className="px-1">{`${t(
                          "Common.Modal.Column.Button.Retry"
                        )}`}</span>
                        {requesting && <i className="fas fa-sync fa-spin"></i>}
                        {!requesting && (
                          <span className="bi bi-arrow-clockwise"></span>
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};

const StorefrontStatusCell: FC<Props> = ({ id, status }) => {
  const { t } = useTranslation();
  const storefronts = useQueryResponseData();
  const [data, setData] = useState<any>();
  // const [statusStorefront, setStatusStorefront] = useState<string>("");
  const [companyAndAccounts, setCompanyAndAccounts] = useState<any>([]);
  let isShowMarketplace: boolean = false;
  if (status !== "created" && status !== "drafted") {
    isShowMarketplace = true;
    //find success and failed
    status = status?.replace("Common.Modal.Column.Contents.Status.Success", t("Common.Modal.Column.Contents.Status.Success"));
    status = status?.replace("Common.Modal.Column.Contents.Status.Failed", t("Common.Modal.Column.Contents.Status.Failed")); 
    console.log("status : ", status);
  }
  console.log("isShowMarketplace : ", isShowMarketplace);

  const getDataStorefront = () => {
    const getData = storefronts.find((storefront: any) => {
      return storefront.id === id;
    });
    return getData;
  };

  const getCompanyByAccount = async (data: any) => {
    if (
      data &&
      data.marketplacesRegistered &&
      data.marketplacesRegistered.length > 0
    ) {
      console.log(
        "data.marketplacesRegistered : ",
        data.marketplacesRegistered
      );
      const getDataAccount: Account[] = data.marketplacesRegistered?.map(
        async (marketplace: any) => {
          console.log(`marketplace : `, marketplace.account.id);
          const getAccount: any = await getAccountData(
            marketplace?.account?.id
          );
          return getAccount;
        }
      );
      console.log(`getDataAccount : `, getDataAccount);
      //convert getDatAccount to object
      const dataAccount = await Promise.all(getDataAccount);
      //grup account by company and save as object {company: companyData, account: [accountData]]}
      const groupAccountByCompany = dataAccount?.reduce(
        (acc: any, account: any) => {
          const company = acc.find(
            (item: any) => item.company.id === account.company.id
          );
          if (company) {
            company.account.push(account);
          } else {
            //find company data in localstorage
            const companiesData: any = lc
              .getItemLC(lc.LCName.CompanyList)
              ?.find((item: any) => item.id === account.company.id);
            acc.push({
              company: companiesData,
              account: [account],
            });
          }
          return acc;
        },
        []
      );
      console.log(`groupAccountByCompany : `, groupAccountByCompany);
      return groupAccountByCompany;
    }
  };

  return (
    <>
      <div
        className="text-gray-650 mb-1"
        style={{ textTransform: "capitalize" }}
        data-bs-toggle={isShowMarketplace ? "modal" : ""}
        data-bs-target={isShowMarketplace ? "#modalViewStatus-" + id : "#"}
        onClick={async () => {
          if (isShowMarketplace) {
            const getData = getDataStorefront();
            if (getData) {
              setData(getData);
              const getCompanyAndAccountList = await getCompanyByAccount(
                getData
              );
              setCompanyAndAccounts(getCompanyAndAccountList);
              console.log(
                `getCompanyAndAccountList : ${getCompanyAndAccountList}}`
              );
            }
            console.log("hasil get data ", getData);
          }
        }}
      >
        {status}
      </div>
      {isShowMarketplace && (
        <ModalViewStatusMarketplace
          id={"modalViewStatus-" + id}
          title={`${t("StorefrontList.Title.Modal")}`}
          body={
            <ViewMarketplace
              key={id}
              data={data}
              companyAndAccounts={companyAndAccounts}
            />
          }
          confirmOption={`${t("Alert.Back.Button.Save")}`}
          cancelOption={`${t("Alert.Button.Cancel")}`}
        />
      )}
    </>
  );
};

export { StorefrontStatusCell };
