import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SMSFailoverEditPage from "./SMSFailoverEditPage";
import SMSFailoverPage from "./SMSFailoverPage";

const SMSFailover = () => {
  const { t } = useTranslation();
  const apiSetting = useSelector((state: any) => state.ApiSetting);
  const isEditMode = apiSetting.isEditMode;

  return (
    <div className="container mt-10">
      <div className="row">
        <b>{t("API.Setting.Tab.SMS")}</b>
      </div>

      {isEditMode && (
        <div data-testid="sms-failover-edit-page">
          <SMSFailoverEditPage />
        </div>
      )}

      {!isEditMode && (
        <div data-testid="sms-failover-page">
          <SMSFailoverPage />
        </div>
      )}
    </div>
  );
};

export default SMSFailover;
