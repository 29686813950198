import { Fragment, useEffect } from "react";
import Select from "../../../../../styles/components/Select";

interface PaginationProps {
  defaultActivePage: number;
  pageSize: number;
  totalData: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  defaultActivePage,
  pageSize,
  totalData,
  onPageChange,
  onPageSizeChange,
}) => {
  const pageSizeOptions = [10, 20, 50, 100].map((pageSize) => ({
    label: pageSize.toString(),
    value: pageSize,
  }));
  const totalPages = Math.ceil(totalData / pageSize);
  const pages = [...Array(totalPages).keys()].map((_, i) => i + 1);
  const moveToPage = (page: number) => () => onPageChange(page);
  const setPageSize = (pageSize: number) => onPageSizeChange(pageSize);

  return (
    <div
      className="p-5 d-flex justify-content-between"
    >
      <div
        v-if="enableItemsPerPageDropdown"
        className="dataTables_length flex-left"
        id="kt_customers_table_length"
        data-testid="pagination"
      >
        <label>
          <select
            name="kt_customers_table_length"
            className="form-select form-select-sm form-select-solid"
            defaultValue={pageSize}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setPageSize(Number(selectedValue));
            }}
          >
            {pageSizeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <ul className="pagination ml-auto">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={moveToPage(1)}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {defaultActivePage - 3 > 0 && pages.length > 5 && (
          <li className={`page-item`}>
            <button className="page-link">...</button>
          </li>
        )}

        {pages.map((page) => (
          <Fragment key={page}>
            {page <
              defaultActivePage +
                (defaultActivePage < 3 ? 6 - defaultActivePage : 3) &&
              page >
                defaultActivePage -
                  (totalPages - defaultActivePage < 2
                    ? 5 - totalPages + defaultActivePage
                    : 3) && (
                <li
                  className={`page-item ${
                    page === defaultActivePage && "active"
                  }`}
                >
                  <button className="page-link" onClick={moveToPage(page)}>
                    {page}
                  </button>
                </li>
              )}
          </Fragment>
        ))}
        {defaultActivePage + 3 <= pages.length && pages.length > 5 && (
          <li className={`page-item`}>
            <button className="page-link">...</button>
          </li>
        )}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={moveToPage(pages.length)}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default PaginationComponent;
