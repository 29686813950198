import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useListView } from "../../../../../core/list/ListViewProvider";
import { ProductGrouping } from "./ProductGrouping";
import { ProductSearchComponent } from "./ProductSearchComponent";

const ProductHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selected } = useListView();
  const { t } = useTranslation();
  if (isTabletOrMobile) {
    return (
      <>
        <div className="menu menu-column menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary pt-6">
          <div className="menu-item here show menu-accordion">
            <span className="menu-link px-5">
              <div className="symbol symbol-50px">
                <span className="menu-icon justify-content-end">
                  <i className="fas fa-box fs-1" />
                </span>
              </div>
              <span className="menu-title">{`${t("Storefront.Column.Product")}`}</span>
            </span>
          </div>
        </div>
        <div className="border-0 pt-6 px-5 d-flex flex-column">
          <ProductSearchComponent /> 
          {/* begin::Card toolbar */}
          <div className="card-toolbar w-100 pb-7 mt-3">
            <ProductGrouping />
            {/* {selected.length > 0 ? <CampaignGrouping /> : <CampaignToolbar />} : */}
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
      </>
    );
  } else {
    return (
      <div className="card-header align-items-center border-0 pt-6 my-2">
        <ProductSearchComponent />
        {/* begin::Card toolbar */}
        <div className="card-toolbar">
          <ProductGrouping />
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    );
  }
};

export { ProductHeader };
