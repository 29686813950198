import { useTranslation } from "react-i18next";
import { useFirestoreTable } from "../context/TableContext";
import Account from "../../../../../../models/Account";
import MarketplaceStatusCell from "../components/table/columns/StatusCell";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import MarketplaceDateCell from "../components/table/columns/DateCell";
import MarketplaceActionCell from "../components/table/columns/ActionCell";
import MarketplaceValueCell from "../components/table/columns/SetupProgress";
import MarketplaceAppType from "../components/table/columns/AppType";

const MarketplaceCard: React.FC = () => {
  const { t } = useTranslation();

  const {
    data: orderData,
    isLoading,
    isFetching,
    marketplaceTableState,
  } = useFirestoreTable();

  const marketplaceList = orderData?.items || []

  return (
    <>
      {marketplaceList.map((item) => (
        <Card key={item.id} account={item} />
      ))}
    </>
  )
}

function Card({ account }: { account: Account }) {
  const { t } = useTranslation();

  let step = 0;
  let data = account;
  let marketplaceName = data.type.toLowerCase();
  let prop_check = data.type === 'lazada' ? `${marketplaceName}_sellerID` : `${marketplaceName}_shopID`
  if (data.hasOwnProperty(prop_check)) {
    step = 1;
  }
  if ((data.hasOwnProperty(prop_check) && data.isActive === true) || (data.hasOwnProperty(prop_check) && data.connectedAt)) {
    step = 2;
  }
  if (data.hasOwnProperty(prop_check) && data.isActive === true && data.connectedAt) {
    step = 3;
  }

  return (
    <div
      className="p-2 mb-2 fw-bold card mx-2"
      style={{
        backgroundColor: "#C6E7FD",
        fontSize: "14px",
        color: "#6C7288",
      }}
      data-testid="card-marketplace"
    >
      <div className="d-flex justify-content-between align-items-end border-bottom pb-1">
        <div className="d-flex my-auto">
          <span
            className="text-dark fw-bolder"
            style={{ fontSize: "1.25rem" }}
          >
            {account.name}
          </span>
        </div>

        <div className="ml-auto" style={{ fontSize: "10px" }}>
          <MarketplaceActionCell data={account} />
        </div>
      </div>


      <div className="pt-2 d-flex justify-content-between">
        <span>{t("Marketplace.Menu.Marketplace")}</span>
        <span>
          {/* {moment(process.createdAt?.toDate()).format("HH:mm DD//MM/YYYY")} */}
          {account.type}
        </span>
      </div>

      <div className="pt-2 d-flex justify-content-between">
        <span>{t("Marketplace.Table.Header.Date")}</span>
        <span>
          {/* {moment(process.createdAt?.toDate()).format("HH:mm DD//MM/YYYY")} */}
          <MarketplaceDateCell timestamp={account.connectedAt} />
        </span>
      </div>

      <div className="pt-2 d-flex justify-content-between">
        <span>{t("Marketplace.Table.Header.AppType")}</span>
        <span>
          {/* <MarketplaceStatusCell status={account.isActive} data={account} /> */}
          <MarketplaceAppType type={account.type} credentials={account.credentials} data={account} />
        </span>
      </div>

      <div className="pt-2justify-content-between">
        <span>
          <MarketplaceValueCell value={step} data={account} />
        </span>
      </div>

    </div>
  );
}

export default MarketplaceCard;