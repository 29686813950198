import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/toggle.scss";
import "../../../../../styles/css/color.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { RootState } from "../../../../../setup/redux/store";
import { useMediaQuery } from "react-responsive";
import TextAreaFailover from "../../../../components/textarea/TextAreaFailover";
import { KeyValues } from "../../../../../util/Formatter";
import Select from "react-select";
import Language, {
  BodyParamLanguage,
  BodyParamLanguage as HeaderParamLanguage,
  templateStatus,
} from "../../../../models/Language";
import { useDebounce } from "../../../../../resources/helpers";

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  validateInputHandler: (isValid: boolean) => void;
}

type MessageParameter = {
  item: string;
  label: string;
};

const TemplateSubmission: FC<TemplateProps> = (props) => {
  type Param = {
    text: string;
    paramNo: number;
    paramName: string;
  };
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  let ourRef = useRef<HTMLTextAreaElement>(null);
  const { i18n, t } = useTranslation();
  const [failoverToggle, setFailoverToggle] = useState<boolean>(false);
  const [failoverMessage, setFailoverMessage] = useState<string>("");
  const [lengthBodyMessage, setLengthBodyMessage] = useState<number>(0);
  const [sendWhenUndelivered, setSendWhenUndelivered] =
    useState<boolean>(false);
  const [undeliveredTime, setUndeliveredTime] = useState<number>(1);
  const [undeliveredUnit, setUndeliveredUnit] = useState<string>("Hour");
  const [warningOTP, setWarningOTP] = useState<boolean>(false);
  const [warningLimitText, setWarningLimitText] = useState<boolean>(false);
  const [previewMessage, setPreviewMessage] = useState<string>("");
  const [failoverBodyParam, setFailoverBodyParam] = useState<any[] | []>([]);
  const [parameter, setParameter] = useState<Param>();
  // const [optionParameter, setOptionParameter] = useState<any>({
  //   "campaigns.campaignName": "Campaign's Name",
  //   CustomizeText: "Customize text/URL",
  // });
  const [, setCustoParamId] = useState<string>("");
  const [listParameter, setListParameter] = useState<MessageParameter[]>([]);
  const [selectedParameter, setSelectedParameter] =
    useState<MessageParameter>();
  const [inputParamName, setInputParamName] = useState<string>("");
  const [alertEmptyParamName, setAlertEmptyParamName] =
    useState<boolean>(false);
  const [addOnButton, setAddOnButton] = useState<boolean>(false);
  const [showWarningBodyMessage, setShowWarningBodyMessage] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const reduxTemplateLanguage = useSelector(
    (state: RootState) => state.Template.generatedLanguage
  );
  const reduxTemplate = useSelector((state: RootState) => state.Template);
  const templateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );
  const reduxEmptyCustomTextlert = useSelector(
    (state: RootState) => state.Template.templateEmptyCustomTextAlert
  );
  const reduxLoading = useSelector(
    (state: RootState) => state.Campaign.isLoading
  );
  const duplicateParamName = useSelector(
    (state: RootState) => state.Template.alertDuplicateParamName
  );
  const campaignTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );

  console.log(reduxTemplateLanguage, "REDUXTEMPLATELANGUAGE TPSM opick");

  let option: any =
    templateCategory !== "authentication"
      ? ({
          "campaigns.campaignName": "Campaign's Name",
          CustomizeText: "Customize text/URL",
        } as any)
      : ({
          "template.otp": "OTP Code",
          "campaigns.campaignName": "Campaign's Name",
          CustomizeText: "Customize text/URL",
        } as any);

  const customStyles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "white"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "#0275d8" // Background color on hover or when option is selected
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
      color: "#1e2022", // Color of the selected value
    }),
  };

  console.log(failoverMessage, "failov opu");

  const debounceParam = useDebounce(parameter, 500);
  console.log(debounceParam, "debparam oux");
  // useEffect(() => {
  //   console.log(`debounce value ${JSON.stringify(debounceParam)}`);
  //   if (debounceParam)
  //     onCustomParamChanges(
  //       debounceParam.text,
  //       debounceParam.paramNo,
  //       debounceParam.paramName
  //     );
  // }, [debounceParam]);

  // useEffect(() => {
  //   if (listParameter.length <= 0) {
  //     const listDataParameter: MessageParameter[] = Object.keys(option).map(
  //       (value, index, array) => {
  //         return {
  //           item: option[value],
  //           label: t("Campaign.Input." + array[index]),
  //         };
  //       }
  //     );
  //     if (listDataParameter) {
  //       setListParameter(listDataParameter);
  //     }
  //   }

  //   ourRef.current?.focus();
  // }, []);

  useEffect(() => {
    if (reduxTemplateLanguage?.failover) {
      console.log(reduxTemplateLanguage?.failover, "rtf uka kio");
      if (
        reduxTemplateLanguage?.failover?.message !== null &&
        reduxTemplateLanguage?.failover?.message !== undefined
      ) {
        setFailoverToggle(true);
        let arrBodyParam: any[] = [];
        let bodyParam = reduxTemplateLanguage.failover.message
          .split("{{")
          .filter((val: any) => val.includes("}}"))
          .map((val: any) => val.substring(0, val.indexOf("}}")));
        if (bodyParam.length > 0) {
          console.log(bodyParam, "BODYPARAM KIO PFM");

          for (let index = 0; index < bodyParam.length; index++) {
            const element = bodyParam[index];
            console.log(bodyParam[index], "bodyParam[index] kio pfm");

            let opsi = option;
            reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
              const { paramKey, paramValue, paramName } = item;
              opsi = {
                ...opsi,
                [paramName !== "" ? paramName : paramKey]: paramValue,
              };
            });
            console.log(opsi, "ini opsi kio");
            console.log(reduxTemplateLanguage, "ini rtl vox");
            console.log(reduxTemplate, "ini rt vox");
            const keyParam = Object.keys(opsi).find((value, index, array) => {
              console.log(array[index], element, "apa ini coba kio");
              return t("Campaign.Input." + array[index]) === element;
            });
            console.log(keyParam, "KEYPARAM kio");

            let tempBodyParams = bodyParam
              ? bodyParam
              : reduxTemplateLanguage?.bodyParam;
            let newParam = tempBodyParams?.find((param: any) => {
              return param.paramId === index + 1;
            });
            let paramNameValue = "";
            if (keyParam && keyParam !== "") {
              let text = "";
              let type = `${
                keyParam === "CustomizeText" ? "customText" : keyParam
              }`;
              console.log(" PFM ini type > " + type);
              if (
                newParam &&
                newParam.paramType &&
                newParam.paramType === type
              ) {
                console.log(
                  "PFM ini masuk ubah text content >>>" + newParam.textContent
                );
                if (newParam.textContent) {
                  text = newParam.textContent;
                }
                if (newParam.paramName) {
                  console.log(`masuk ambil dari redux paramName PFM`);
                  paramNameValue = newParam.paramName;
                }
              }
              console.log("PFM ini text > " + text);

              let header = {
                paramId: index + 1,
                paramKey: `${
                  keyParam === "CustomizeText"
                    ? "customText" + (index + 1)
                    : keyParam
                }`,
                paramType: `${
                  keyParam === "CustomizeText" ? "customText" : keyParam
                }`,
                paramValue: element,
                textContent: debounceParam?.text ? debounceParam.text : text,
                paramName:
                  keyParam === "CustomizeText"
                    ? newParam
                      ? paramNameValue
                      : ""
                    : "",
                // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
              };

              // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
              console.log(
                "PFM New param added object >>> " + JSON.stringify(header)
              );
              console.log("insert a");
              arrBodyParam.push(header);
            } else {
              let opsi2 = option;
              reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
                const { paramKey, paramValue, paramName, textContent } = item;
                opsi2 = {
                  ...opsi2,
                  [paramName !== "" ? paramName : paramKey]: textContent,
                };
              });
              let newText = opsi2[element];
              if (element !== "") {
                //find if duplicate
                // const findParamByParamName = checkParamName(element)
                // console.log(`findParamByParamName : ${findParamByParamName}`)
                // if(findParamByParamName && findParamByParamName.paramId !== index+1){
                //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
                // }else{
                //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
                let text: string =
                  newParam && newParam.textContent
                    ? newParam.textContent
                    : newText
                    ? newText
                    : "";

                console.log(debounceParam?.text, "apa isi insert");
                let header = {
                  paramId: index + 1,
                  paramKey: `customText${index + 1}`,
                  paramType: `customText`,
                  paramValue: t("Campaign.Input.CustomizeText"),
                  textContent: debounceParam?.text ? debounceParam.text : text,
                  paramName: element,
                };
                console.log("insert b");
                arrBodyParam.push(header);
                // }
              }
            }
          }
        }
        let templateLanguange = reduxTemplateLanguage?.failover.message;
        console.log(templateLanguange, "templang uka");
        setFailoverMessage(
          templateLanguange !== undefined ? templateLanguange : ""
        );
        setPreviewMessage(reduxTemplateLanguage.failover.message);
        console.log(arrBodyParam, "arro oux");
        setFailoverBodyParam(arrBodyParam);
      }
      if (reduxTemplateLanguage.sendWhenUndelivered) {
        setSendWhenUndelivered(
          reduxTemplateLanguage.failover.sendWhenUndelivered
        );
      }
      if (reduxTemplateLanguage.undeliveredTime) {
        setUndeliveredTime(reduxTemplateLanguage.failover.undeliveredTime);
      }
      if (reduxTemplateLanguage.undeliveredTimeUnit) {
        setUndeliveredUnit(reduxTemplateLanguage.failover.undeliveredTimeUnit);
      }
    }
  }, [reduxTemplateLanguage?.failover, debounceParam?.text]);

  useEffect(() => {
    if (templateCategory === "authentication") {
      setFailoverMessage("{{OTP CODE}}");
      if (failoverToggle === true) {
        let temp_data = Object.assign({}, reduxTemplateLanguage, {
          failover: {
            message: "{{OTP CODE}}",
            bodyParam: [],
            bodyParamCount: 0,
            sendWhenUndelivered: sendWhenUndelivered,
            undeliveredTime: 0,
            undeliveredTimeUnit: "",
          },
        });
        dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
      }
    } else {
      setFailoverMessage("");
      if (failoverToggle === true) {
        let temp_data = Object.assign({}, reduxTemplateLanguage, {
          failover: {
            message: failoverMessage,
            bodyParam: reduxTemplateLanguage?.failover?.bodyParam,
            bodyParamCount: reduxTemplateLanguage?.failover?.bodyParamCount,
            sendWhenUndelivered: sendWhenUndelivered,
            undeliveredTime: 0,
            undeliveredTimeUnit: "",
          },
        });
        dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
      }
    }

    if (templateCategory === "interactive") {
      const NewlistDataParameter = listParameter.filter(
        (item) => item.item !== "Campaign's Name"
      );
      console.log(
        `new list data parameter: ${JSON.stringify(NewlistDataParameter)}`
      );
      setListParameter(NewlistDataParameter);
    } else {
      let opsi = option;
      reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
        const { paramKey, paramName, paramValue } = item;
        opsi = {
          ...opsi,
          [paramName !== "" ? paramName : paramKey]: paramValue,
        };
      });
      const listDataParameter: MessageParameter[] = Object.keys(opsi).map(
        (value, index, array) => {
          console.log(value, index, array, "itempar peq");
          console.log(opsi[value], array[index], "itemopt peq");
          return {
            item: opsi[value],
            label:
              opsi[value] !== "Custom text/URL" &&
              opsi[value] !== "Teks/URL yang disesuaikan"
                ? // &&
                  // opsi[value] !== "CustomizeText"
                  t("Campaign.Input." + array[index])
                : array[index],
          };
        }
      );
      console.log(listDataParameter, "listdapar peq");
      setListParameter(listDataParameter);
    }
  }, [templateCategory]);

  useEffect(() => {
    console.log(
      "CHECK USE EFFECT UNTUK TEMPLATE UPDATE  ==================================================="
    );
    //set setListParameter
    // if (listParameter.length <= 0) {
    let opsi = option;
    reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
      const { paramKey, paramName, paramValue } = item;
      opsi = {
        ...opsi,
        [paramName !== "" ? paramName : paramKey]: paramValue,
      };
    });
    const listDataParameter: MessageParameter[] = Object.keys(opsi).map(
      (value, index, array) => {
        console.log(value, index, array, "itempar peq");
        console.log(opsi[value], "itemopt peq");
        return {
          item: opsi[value],
          label:
            opsi[value] !== "Custom text/URL" &&
            opsi[value] !== "Teks/URL yang disesuaikan"
              ? //  &&
                // opsi[value] !== "CustomizeText"
                t("Campaign.Input." + array[index])
              : array[index],
        };
      }
    );
    if (listDataParameter) {
      setListParameter(listDataParameter);
    }
    // }
    console.log(opsi, "opsi ucva");
    console.log(reduxTemplateLanguage, "rtl ucva");
    console.log(reduxTemplate, "ini rt ucva");
  }, [reduxTemplateLanguage]);

  // const handleChildRefChange = (childRef: React.RefObject<HTMLTextAreaElement>) => {
  //   // Do something with the childRef in the parent component if needed
  //   console.log("Received childRef in parent:", childRef);
  //   // You can store the childRef in the parentRef if needed
  //   // ourRef.current = childRef.current;
  // };

  // const checkEmptyCustomText = () => {
  //   //check if in reduxTemplateBodyParams include paramtype customText and textContent is empty
  //   const templateBodyParams = reduxTemplateLanguage?.failover?.bodyParam;
  //   let customBodyParams: any = [];
  //   templateBodyParams.forEach((param: any) => {
  //     if (param.paramType === "customText" && param.textContent === "") {
  //       customBodyParams.push(true);
  //     }
  //     if (param.paramType === "customText" && param.textContent !== "") {
  //       customBodyParams.push(false);
  //     }
  //   });
  //   console.log("checkEmpty : ", customBodyParams);
  //   if (customBodyParams.length > 0) {
  //     dispatch(
  //       ReduxTemplate.setTemplateEmptyCustomtextMessageAlert(customBodyParams)
  //     );
  //   }
  //   return customBodyParams;
  // };

  // const onCustomParamChanges = async (
  //   text: string,
  //   paramNo: number,
  //   paramName: string
  // ) => {
  //   console.log("Inserted value: " + text);
  //   console.log("Inserted value paramno: " + paramNo);
  //   console.log("Inserted value paramName: " + paramName);

  //   const filteredBodyParams: BodyParamLanguage[] =
  //     reduxTemplateLanguage?.failover?.bodyParam.filter((bodyParam:any) => {
  //       return bodyParam.paramId !== paramNo;
  //     });
  //   console.log(
  //     `Check Hasil Filter Body Param : ${JSON.stringify(filteredBodyParams)}`
  //   );

  //   let tempParams = [...filteredBodyParams];

  //   // let customTextValue = text.replaceAll("\n","<br/>");
  //   // let customTextValue = text.replaceAll("\n",`\n`);
  //   let customTextValue = text;

  //   let customParam = {
  //     paramId: paramNo,
  //     paramKey: "customText" + paramNo, //"customText",
  //     paramValue: `${t("Campaign.Input.CustomizeText")}`,
  //     paramType: "customText",
  //     textContent: customTextValue ? customTextValue : "",
  //     paramName: paramName,
  //   };

  //   tempParams.push(customParam);
  //   tempParams = tempParams.sort((a, b) => (a.paramId > b.paramId ? 1 : -1));

  //   let newPreviewBody = reduxTemplateLanguage?.failover.bodyParam;
  //   console.log(
  //     "Check Body message on custom text chnage : " +
  //       reduxTemplateLanguage?.failover.bodyParam
  //   );

  //   for (let index = 0; index < tempParams.length; index++) {
  //     const bodyParam = tempParams[index];
  //     if (bodyParam.paramName) {
  //       newPreviewBody = newPreviewBody.replace(
  //         t("Campaign.Input.CustomizeText"),
  //         bodyParam.paramName
  //       );
  //     }
  //     newPreviewBody = replaceMessageForPreview(newPreviewBody, bodyParam);
  //   }
  //   newPreviewBody = newPreviewBody.replaceAll("\n", "<br/>");
  //   console.log("CHECK tempParams >>>>>", tempParams);
  //   dispatch(ReduxTemplate.setTemplateBodyParams(tempParams));
  //   console.log("GENERATE DATA LANGUAGE >>>>");

  //   //Generate Language
  //   let bodyParam = reduxTemplateLanguage?.failover.message
  //     .split("{{")
  //     .filter((val: any) => val.includes("}}"))
  //     .map((val: any) => val.substring(0, val.indexOf("}}")));
  //   let templateLanguange = reduxTemplateLanguage?.failover.message;
  //   for (let index = 0; index < bodyParam.length; index++) {
  //     const element = bodyParam[index];
  //     templateLanguange = templateLanguange.replace(
  //       `{{${element}}}`,
  //       `{{${index + 1}}}`
  //     );
  //   }
  //   console.log(`templateLanguange : `, templateLanguange);
  //   updateOnChangeData("bodyMessage", reduxTemplateLanguage?.failover.message);
  //   updateOnChangeData("previewBody", newPreviewBody);
  //   console.log("masuk check body param empty custom disini");
  //   checkBodyParamEmptyCustomText(tempParams);
  //   // generateLanguage(
  //   //   "body",
  //   //   templateLanguange,
  //   //   tempParams.length,
  //   //   "",
  //   //   tempParams,
  //   //   undefined
  //   // );
  // };

  const onChangeMessage = (e: any) => {
    let arrBodyParam: any[] = [];
    // let option: any = KeyValues;
    let bodyParam = e.currentTarget.value
      .split("{{")
      .filter((val: any) => val.includes("}}"))
      .map((val: any) => val.substring(0, val.indexOf("}}")));
    if (bodyParam.length > 0) {
      for (let index = 0; index < bodyParam.length; index++) {
        let element = bodyParam[index];
        let opsi = option;
        reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue } = item;
          opsi = {
            ...opsi,
            [paramKey]: paramValue,
          };
        });
        console.log(opsi, "ops vox4");
        console.log(reduxTemplateLanguage, "rtl vox4");
        console.log(reduxTemplate, "ini rt vox4");
        const keyParam = Object.keys(opsi).find((value, index, array) => {
          return t("Campaign.Input." + array[index]) === element;
        });

        let tempBodyParams = bodyParam
          ? bodyParam
          : reduxTemplateLanguage?.failover.bodyParam;
        let newParam = tempBodyParams.find((param: any) => {
          return param.paramId === index + 1;
        });
        let paramNameValue = "";
        if (keyParam && keyParam !== "") {
          let text = "";
          let type = `${
            keyParam === "CustomizeText" ? "customText" : keyParam
          }`;
          console.log(" PFM ini type > " + type);
          if (newParam && newParam.paramType && newParam.paramType === type) {
            console.log(
              "PFM ini masuk ubah text content >>>" + newParam.textContent
            );
            if (newParam.textContent) {
              text = newParam.textContent;
            }
            if (newParam.paramName) {
              console.log(`masuk ambil dari redux paramName PFM`);
              paramNameValue = newParam.paramName;
            }
          }
          console.log("PFM ini text > " + text);

          let header = {
            paramId: index + 1,
            paramKey: `${
              keyParam === "CustomizeText"
                ? "customText" + (index + 1)
                : keyParam
            }`,
            paramType: `${
              keyParam === "CustomizeText" ? "customText" : keyParam
            }`,
            paramValue: element,
            textContent: debounceParam?.text ? debounceParam.text : text,
            paramName:
              keyParam === "CustomizeText"
                ? newParam
                  ? paramNameValue
                  : ""
                : "",
            // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
          };

          // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
          console.log(
            "PFM New param added object >>> " + JSON.stringify(header)
          );
          arrBodyParam.push(header);
        } else {
          console.log(`masuk else PFM`);
          console.log(`PFM element >>> ${element}`);
          console.log(`PFM newParam >>> ${JSON.stringify(newParam)}`);
          if (element !== "") {
            //find if duplicate
            // const findParamByParamName = checkParamName(element)
            // console.log(`findParamByParamName : ${findParamByParamName}`)
            // if(findParamByParamName && findParamByParamName.paramId !== index+1){
            //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
            // }else{
            //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
            let text: string =
              newParam && newParam.textContent ? newParam.textContent : "";
            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: debounceParam?.text ? debounceParam.text : text,
              paramName: element,
            };
            arrBodyParam.push(header);
            // }
          }
        }
      }
    }

    let templateLanguange = e.currentTarget.value;
    setFailoverMessage(templateLanguange);
    setPreviewMessage(e.currentTarget.value);
    let temp_data = Object.assign({}, reduxTemplateLanguage, {
      failover:
        sendWhenUndelivered === true
          ? {
              message: templateLanguange,
              sendWhenUndelivered: sendWhenUndelivered,
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              undeliveredTime: undeliveredTime,
              undeliveredTimeUnit: undeliveredUnit,
            }
          : {
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              message: templateLanguange,
              sendWhenUndelivered: sendWhenUndelivered,
            },
    });
    dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
  };

  const onChangeValidTextStatus = (isValid: boolean) => {
    if (failoverToggle !== true) {
      console.log("failover pas false FT");
      props.validateInputHandler(!isValid);
    } else {
      console.log("failover pas true FT");
      props.validateInputHandler(isValid);
    }
  };

  const addParameter = () => {
    console.log("MASUK ADDPARAMETER REBUILD OPU OPICK");

    const e: any = selectedParameter;
    console.log(e, "e apa ini opick");
    console.log(inputParamName, "inparam opick");
    let newMessage =
      failoverMessage +
      "{{" +
      (e?.label !== "Custom text/URL" &&
      e?.label !== "Teks/URL yang disesuaikan"
        ? // &&
          // e?.label !== "CustomizeText"
          e?.label
        : inputParamName) +
      "}}";
    let arrBodyParam: any[] = [];
    let bodyParam = newMessage
      .split("{{")
      .filter((val: any) => val.includes("}}"))
      .map((val: any) => val.substring(0, val.indexOf("}}")));
    if (bodyParam.length > 0) {
      for (let index = 0; index < bodyParam.length; index++) {
        const element = bodyParam[index];
        let opsi = option;
        reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue, paramName } = item;
          opsi = {
            ...opsi,
            [paramName !== "" ? paramName : paramKey]: paramValue,
          };
        });
        const keyParam = Object.keys(opsi).find((value, index, array) => {
          return t("Campaign.Input." + array[index]) === element;
        });

        let tempBodyParams = bodyParam
          ? bodyParam
          : reduxTemplateLanguage?.failover.bodyParam;
        let newParam = tempBodyParams?.find((param: any) => {
          return param.paramId === index + 1;
        });
        console.log(newParam, "NEWPARAM OPICK ");

        let paramNameValue = "";
        console.log(keyParam, "keypar opick");
        if (keyParam && keyParam !== "") {
          let text = "";
          let type = `${
            keyParam === "CustomizeText" ? "customText" : keyParam
          }`;
          if (newParam && newParam.paramType && newParam.paramType === type) {
            console.log("masuk 1 opick");
            if (newParam.textContent) {
              text = newParam.textContent;
            }
            if (newParam.paramName) {
              paramNameValue = newParam.paramName;
            }
          } else {
            console.log("masuk 2 opick");
            paramNameValue = inputParamName;
          }

          let header = {
            paramId: index + 1,
            paramKey: `${
              keyParam === "CustomizeText"
                ? "customText" + (index + 1)
                : keyParam
            }`,
            paramType: `${
              keyParam === "CustomizeText" ? "customText" : keyParam
            }`,
            paramValue: element,
            textContent: debounceParam?.text ? debounceParam.text : text,
            paramName:
              keyParam === "CustomizeText"
                ? newParam
                  ? paramNameValue
                  : inputParamName
                : "",
          };
          console.log(header, "HEDAER OPSI 1 OPICK");

          arrBodyParam.push(header);
        } else {
          let opsi2 = option;
          console.log(opsi2, "opsi 2 opick");
          reduxTemplateLanguage?.bodyParam?.forEach((item: any) => {
            const { paramKey, paramValue, paramName, textContent } = item;
            opsi2 = {
              ...opsi2,
              [paramName !== "" ? paramName : paramKey]: textContent,
            };
          });
          let newText = opsi2[element];
          console.log(element, "el opick");
          if (element !== "") {
            let text: string =
              newParam && newParam.textContent
                ? newParam.textContent
                : newText
                ? newText
                : "";
            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: debounceParam?.text ? debounceParam.text : text,
              paramName: element,
            };
            console.log(header, "HEDAER OPSI 1 OPICK");
            arrBodyParam.push(header);
          } else {
            let text: string =
              newParam && newParam.textContent
                ? newParam.textContent
                : newText
                ? newText
                : "";
            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: debounceParam?.text ? debounceParam.text : text,
              paramName: inputParamName,
            };
            arrBodyParam.push(header);
          }
        }
      }
    }
    console.log(failoverMessage, "fm opu");
    console.log(previewMessage, "pm opu");
    console.log(newMessage, "nm opu");
    console.log(arrBodyParam, "arrbod opick");
    let templateLanguange = newMessage;
    for (let index = 0; index < arrBodyParam.length; index++) {
      const element = arrBodyParam[index];
      templateLanguange = templateLanguange.replace(
        new RegExp(`{{${element}}}`, "g"),
        `{{${index + 1}}}`
      );
    }
    setFailoverMessage(newMessage);
    setPreviewMessage(
      previewMessage +
        "{{" +
        (e?.label !== "Custom text/URL" &&
        e?.label !== "Teks/URL yang disesuaikan"
          ? // &&
            // e?.label !== "CustomizeText"
            e?.label
          : inputParamName) +
        "}}"
    );
    setFailoverBodyParam(arrBodyParam);
    let temp_data = Object.assign({}, reduxTemplateLanguage, {
      failover:
        sendWhenUndelivered === true
          ? {
              message: templateLanguange,
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              sendWhenUndelivered: sendWhenUndelivered,
              undeliveredTime: undeliveredTime,
              undeliveredTimeUnit: undeliveredUnit,
            }
          : {
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              message: templateLanguange,
              sendWhenUndelivered: sendWhenUndelivered,
            },
    });
    dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
    console.log(temp_data, "temp data opu");

    // dispatch(ReduxTemplate.setTemplateBodyParams(arrBodyParam));
    console.log("KELUAR ADDPARAMETER REBUILD OPU OPICK");
  };

  // const onAddMessageParam = async () => {
  //   console.log("MASUK ONADDMESSAGEPARAM REBUILD");
  //   // const e = document.getElementById(
  //   //   "add-message-param"
  //   // ) as HTMLSelectElement | null;
  //   const e: any = selectedParameter;

  //   if (e !== null && Object.keys(e).length !== 0) {
  //     // const paramValue = e.selectedOptions.item(0)?.value;
  //     // const paramText = e.selectedOptions.item(0)?.text;
  //     const paramValue = e.item;
  //     const paramText = e.label;

  //     let textVal = ourRef.current;
  //     console.log(textVal, "TEXTVAL REBUILD");
  //     console.log(paramValue, "PARAMVALUE REBUILD");
  //     console.log(paramValue, "PARAMTEXT REBUILD");

  //     if (paramValue !== "0") {
  //       //Input at typer standing
  //       let cursorStart = textVal?.selectionStart;
  //       let cursorEnd = textVal?.selectionEnd;
  //       console.log(cursorStart, "CURSORSTART REBUILD");
  //       console.log(cursorEnd, "CURSOREND REBUILD");

  //       if (cursorStart !== undefined && cursorEnd !== undefined) {
  //         let selectedText = failoverMessage.substring(cursorStart, cursorEnd);
  //         let selectedStart = failoverMessage.substring(0, cursorStart);
  //         let selectedEnd = failoverMessage.substring(
  //           cursorEnd,
  //           failoverMessage.length
  //         );
  //         console.log(selectedText, "SELECTEDTEXT REBUILD");
  //         console.log(selectedStart, "SELECTEDSTART REBUILD");
  //         console.log(selectedEnd, "SELECTEDREND REBUILD");

  //         let resultText =
  //           selectedStart +
  //           "" +
  //           selectedText +
  //           "{{" +
  //           paramText +
  //           "}}" +
  //           selectedEnd;
  //         // let resultTextBody = convertTextStyle(resultText);
  //         // setBodyMessage(resultText);
  //         // await dispatch(ReduxTemplate.setTemplateBodyMessage(resultText));
  //         console.log(inputParamName, "INPUT PARAM NAME REBUILD");
  //         console.log(resultText, "RESULT TEXT REBUILD");

  //         await generatePreviewBodyMessage(inputParamName, resultText, "");
  //         setInputParamName("");
  //       } else {
  //         console.log("GAADA CURSORSTART SAMA CURSOREND");
  //       }
  //       // Origin code
  //       // let newBodyMessage = bodyMessage+"{{"+paramText+"}}";
  //       // setBodyMessage(newBodyMessage);
  //       // generatePreviewBodyMessage(newBodyMessage);
  //     }
  //   }
  //   console.log("--KELUAR ONADDMESSAGEPARAM REBUILD--");
  // };

  // const replaceMessageForPreview = (
  //   previewBody: string,
  //   bodyParam: BodyParamLanguage
  // ): string => {
  //   let newPreviewBody = previewBody;
  //   console.log(`CHECK bodyParam.paramType ${bodyParam.paramType}`);
  //   if (bodyParam.paramType === "customText") {
  //     const newCustomText =
  //       bodyParam.textContent && bodyParam.textContent !== ""
  //         ? bodyParam.textContent
  //         : bodyParam.paramName
  //         ? bodyParam.paramName
  //         : t("");
  //     const textToReplace = bodyParam.paramName
  //       ? bodyParam.paramName
  //       : t("Campaign.Input.CustomizeText");
  //     newPreviewBody = newPreviewBody.replace(
  //       "{{" + textToReplace + "}}",
  //       `<div data-testid="preview-body-parameter" class="bg-primary d-flex align-items-center py-2 px-2 text-break rounded fs-7 fw-bolder lh-1 text-white">
  //         <i class="bi bi-server pe-2 text-white"></i>
  //         <span class="text-start d-block" style="width: 90%;">${newCustomText}</span>
  //       </div>`
  //     );
  //   } else {
  //     newPreviewBody = newPreviewBody.replaceAll(
  //       "{{" + t("Campaign.Input." + bodyParam.paramType) + "}}",
  //       `<span data-testid="preview-body-parameter" class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
  //         "Campaign.Input." + bodyParam.paramType
  //       )}</span>`
  //     );
  //   }

  //   return newPreviewBody;
  // };

  // const checkBodyParamEmptyCustomText = (bodyParams: Array<any>) => {
  //   console.log("MASUK CHECKBODYOPARAMEMPTYCUSTOMTEXT REBUILD");
  //   console.log(
  //     "masuk function check empty custom text param >>> " +
  //       `body param >> ${JSON.stringify(bodyParams)} `
  //   );

  //   if (
  //     bodyParams &&
  //     bodyParams.length > 0 &&
  //     reduxEmptyCustomTextlert.length > 0
  //   ) {
  //     const templateBodyParams = bodyParams;
  //     let customBodyParams: any = [];
  //     templateBodyParams.map((param) => {
  //       if (param.paramType === "customText" && param.textContent === "") {
  //         customBodyParams.push(true);
  //       }
  //       if (param.paramType === "customText" && param.textContent !== "") {
  //         customBodyParams.push(false);
  //       }
  //     });
  //     console.log("checkEmpty : ", customBodyParams);
  //     //looping for checkEmpty
  //     if (customBodyParams.length > 0) {
  //       dispatch(
  //         ReduxTemplate.setTemplateEmptyCustomtextMessageAlert(customBodyParams)
  //       );
  //     }
  //   }
  // };

  // const generatePreviewBodyMessage = async (
  //   paramName: string | undefined,
  //   newBodyMessage: string,
  //   generatedLanguage: any,
  //   bodyParams: Array<any> | undefined = undefined
  // ) => {
  //   console.log(`Generate Preview Body Message : ${newBodyMessage} REBUILD`);
  //   // let newPreviewBody = newBodyMessage;
  //   //Process Save To Redux Body Params
  //   let arrBodyParam: any[] = [];
  //   let bodyParam = newBodyMessage
  //     .split("{{")
  //     .filter((val) => val.includes("}}"))
  //     .map((val) => val.substring(0, val.indexOf("}}")));
  //   if (bodyParam.length > 0) {
  //     for (let index = 0; index < bodyParam.length; index++) {
  //       const element = bodyParam[index];
  //       const keyParam = Object.keys(option).find((value, index, array) => {
  //         return t("Campaign.Input." + array[index]) === element;
  //       });

  //       let tempBodyParams = bodyParams
  //         ? bodyParams
  //         : reduxTemplateLanguage?.failover.bodyParam;
  //       let newParam = tempBodyParams.find((param: any) => {
  //         return param.paramId === index + 1;
  //       });
  //       let paramNameValue = "";
  //       if (keyParam && keyParam !== "") {
  //         let text = "";
  //         let type = `${
  //           keyParam === "CustomizeText" ? "customText" : keyParam
  //         }`;
  //         console.log("ini type > " + type);
  //         if (newParam && newParam.paramType && newParam.paramType === type) {
  //           console.log(
  //             "ini masuk ubah text content >>>" + newParam.textContent
  //           );
  //           if (newParam.textContent) {
  //             text = newParam.textContent;
  //           }
  //           if (newParam.paramName) {
  //             console.log(`masuk ambil dari redux`);
  //             paramNameValue = newParam.paramName;
  //           }
  //         }
  //         console.log("ini text > " + text);

  //         let header = {
  //           paramId: index + 1,
  //           paramKey: `${
  //             keyParam === "CustomizeText"
  //               ? "customText" + (index + 1)
  //               : keyParam
  //           }`,
  //           paramType: `${
  //             keyParam === "CustomizeText" ? "customText" : keyParam
  //           }`,
  //           paramValue: element,
  //           textContent: text,
  //           paramName:
  //             keyParam === "CustomizeText"
  //               ? newParam
  //                 ? paramNameValue
  //                 : paramName
  //               : "",
  //           // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
  //         };

  //         // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
  //         console.log("New param added object >>> " + JSON.stringify(header));
  //         arrBodyParam.push(header);
  //       } else {
  //         console.log(`masuk else`);
  //         console.log(`element >>> ${element}`);
  //         console.log(`newParam >>> ${JSON.stringify(newParam)}`);
  //         if (element !== "") {
  //           //find if duplicate
  //           // const findParamByParamName = checkParamName(element)
  //           // console.log(`findParamByParamName : ${findParamByParamName}`)
  //           // if(findParamByParamName && findParamByParamName.paramId !== index+1){
  //           //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
  //           // }else{
  //           //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
  //           let text: string =
  //             newParam && newParam.textContent ? newParam.textContent : "";
  //           let header = {
  //             paramId: index + 1,
  //             paramKey: `customText${index + 1}`,
  //             paramType: `customText`,
  //             paramValue: t("Campaign.Input.CustomizeText"),
  //             textContent: text,
  //             paramName: element,
  //           };
  //           arrBodyParam.push(header);
  //           // }
  //         }
  //       }
  //     }
  //     //Save To Redux
  //     console.log(
  //       "Temporary Template Body params : " + JSON.stringify(arrBodyParam)
  //     );
  //     console.log("Body Param 9 : ");
  //     dispatch(ReduxTemplate.setTemplateBodyParams(arrBodyParam));
  //     console.log(
  //       "Redux Template Body params : " + JSON.stringify(arrBodyParam)
  //     );
  //   }

  //   // for (let index = 0; index < arrBodyParam.length; index++) {
  //   //   const bodyParam = arrBodyParam[index];
  //   //   if (bodyParam.paramName) {
  //   //     console.log(`new preview body before : ${newPreviewBody}`);
  //   //     newPreviewBody = newPreviewBody.replace(
  //   //       t("Campaign.Input.CustomizeText"),
  //   //       bodyParam.paramName
  //   //     );
  //   //     console.log(`new preview body after : ${newPreviewBody}`);
  //   //   }
  //   //   newPreviewBody = replaceMessageForPreview(newPreviewBody, bodyParam);
  //   // }

  //   //Validate Message
  //   if (newBodyMessage.includes("{{") || newBodyMessage.includes("}}")) {
  //     setShowWarningBodyMessage(true);
  //   } else if (
  //     !newBodyMessage.includes("{{") &&
  //     !newBodyMessage.includes("}}")
  //   ) {
  //     dispatch(ReduxTemplate.setTemplateBodyParams([]));
  //     console.log("Tidak ada body params");
  //     setShowWarningBodyMessage(false);
  //   } else {
  //     dispatch(ReduxTemplate.setTemplateBodyParams([]));
  //     setShowWarningBodyMessage(false);
  //   }
  //   newBodyMessage = newBodyMessage.replaceAll("\n", "<br/>");
  //   // newPreviewBody = newPreviewBody.replaceAll("\\n", "<br/>");
  //   console.log(`newBodyMessage before : ${newBodyMessage}`);
  //   if (paramName) {
  //     //replace {{Custom text/URL}}
  //     newBodyMessage = newBodyMessage.replace(
  //       t("Campaign.Input.CustomizeText"),
  //       paramName
  //     );
  //     console.log(`newBodyMessage after : ${newBodyMessage}`);
  //   }
  //   setFailoverMessage(newBodyMessage);
  //   setLengthBodyMessage(newBodyMessage.toString().length);
  //   dispatch(
  //     ReduxTemplate.setTemplateBodyMessageLength(
  //       newBodyMessage.toString().length
  //     )
  //   );

  //   //Generate Language
  //   let templateLanguange = newBodyMessage;
  //   for (let index = 0; index < bodyParam.length; index++) {
  //     const element = bodyParam[index];
  //     templateLanguange = templateLanguange.replace(
  //       `{{${element}}}`,
  //       `{{${index + 1}}}`
  //     );
  //   }

  //   updateOnChangeData("bodyMessage", newBodyMessage);
  //   // updateOnChangeData("previewBody", newPreviewBody);
  //   console.log("masuk check body param empty custom disini");
  //   // setPreviewMessage(previewMessage + "{{" + e?.label + "}}");
  //   console.log(arrBodyParam, "apa isinya uka");
  //   setFailoverBodyParam(arrBodyParam);
  //   console.log(arrBodyParam, "arrbodyp uka");
  //   let temp_data = Object.assign({}, reduxTemplateLanguage, {
  //     failover:
  //       sendWhenUndelivered === true
  //         ? {
  //             message: newBodyMessage,
  //             bodyParam: arrBodyParam,
  //             bodyParamCount: arrBodyParam.length,
  //             sendWhenUndelivered: sendWhenUndelivered,
  //             undeliveredTime: undeliveredTime,
  //             undeliveredTimeUnit: undeliveredUnit,
  //           }
  //         : {
  //             bodyParam: arrBodyParam,
  //             bodyParamCount: arrBodyParam.length,
  //             message: newBodyMessage,
  //             sendWhenUndelivered: sendWhenUndelivered,
  //           },
  //   });
  //   dispatch(ReduxTemplate.setGeneratedLanguage(temp_data));
  //   checkBodyParamEmptyCustomText(arrBodyParam);
  //   console.log("--KELUAR CHECKBODYOPARAMEMPTYCUSTOMTEXT REBUILD--");
  // };

  // const updateOnChangeData = (key: string, value: any) => {
  //   props.onChanges([{ key, value }]);
  // };

  return (
    <>
      <div
        className={clsx("tab-pane fade")}
        id="kt_tab_pane_6"
        role="tabpanel"
        data-testid="tab-pane-templateSumbission"
      >
        <div className="d-flex row w-100" style={{ position: "relative" }}>
          {/* 1 */}
          <div className="col-12" data-testid="tab-pane-failover">
            {/* Select Template */}
            <div className="mb-5">
              <div
                id="headerTitle"
                data-testid="headerTitle"
                className="step-sub-title mb-4 fs-2"
              >
                {/* Template Submission */}
                {t("Template.Title.Submit")}
              </div>
            </div>
            <div className={clsx("mb-5 mt-5")}>
              <div className="mb-5 fs-5" data-testid="infoSubmit">
                {t("Template.Info.Submit")}
              </div>
              {/* <div className="mb-5" data-testid="confSubmit">
                <h6>{t("Template.Info.ConfSubmit")}</h6>
              </div> */}
              <div
                className={
                  isMobileView
                    ? "p-5 rounded bg-secondary mb-2 col-12"
                    : "p-5 rounded bg-secondary mb-2 col-8"
                }
              >
                <div className="d-flex">
                  <p className="fs-4 fw-bolder">{t("Failover.Title")}</p>
                  <div className="form-check form-switch ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      data-testid="template-failover-toggle"
                      // checked={failoverToggle}
                      onClick={(e) => {
                        setFailoverToggle(e.currentTarget.checked);
                        if (e.currentTarget.checked === true) {
                          let temp_data = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover:
                                sendWhenUndelivered === true
                                  ? {
                                      message: failoverMessage,
                                      bodyParam:
                                        reduxTemplateLanguage?.failover
                                          ?.bodyParam,
                                      bodyParamCount:
                                        reduxTemplateLanguage?.failover
                                          ?.bodyParamCount,
                                      sendWhenUndelivered: sendWhenUndelivered,
                                      undeliveredTime: undeliveredTime,
                                      undeliveredTimeUnit: undeliveredUnit,
                                    }
                                  : {
                                      message: failoverMessage,
                                      bodyParam:
                                        reduxTemplateLanguage?.failover
                                          ?.bodyParam,
                                      bodyParamCount:
                                        reduxTemplateLanguage?.failover
                                          ?.bodyParamCount,
                                      sendWhenUndelivered: sendWhenUndelivered,
                                    },
                            }
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data)
                          );
                        } else {
                          // let temp_data = Object.assign(
                          //   {},
                          //   reduxTemplateLanguage,
                          //   {
                          //     failover: null,
                          //   }
                          // );
                          // dispatch(
                          //   ReduxTemplate.setGeneratedLanguage(temp_data)
                          // );
                          let temp_data = { ...reduxTemplateLanguage };
                          delete temp_data.failover;
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data)
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="fs-5">{t("Failover.Info")}</p>
                </div>
              </div>
            </div>
            {failoverToggle && (
              <div className={isMobileView ? "col-12" : "col-8"}>
                <h5 className="fw-bolder">
                  {t("Failover.Message.Title")}
                  <span className="text-danger">*</span>
                </h5>
                <TextAreaFailover
                  templateCategory={templateCategory}
                  onChanges={onChangeMessage}
                  key="message"
                  validateInputHandler={onChangeValidTextStatus}
                  parentValue={failoverMessage}
                  // onRefChange={handleChildRefChange}
                ></TextAreaFailover>
                <div className="w-full pt-0 mt-0 d-flex flex-row align-items-end justify-content-end">
                  <button
                    // className="bi bi-plus-lg btn btn-primary m-2"
                    className={clsx(
                      reduxLoading ? "disabled" : "",
                      "bi bi-plus-lg btn btn-primary m-2 align-self-end",
                      isTabletOrMobile ? "fs-8" : ""
                    )}
                    data-bs-toggle="modal"
                    id="addBodyParams"
                    data-bs-target="#addDataTemplateModal"
                    data-testid="button-add-data-body-message"
                  >
                    {t("Campaign.Button.AddData")}
                  </button>
                </div>

                {failoverBodyParam && failoverBodyParam.length > 0 && (
                  <div>
                    {failoverBodyParam.map((bodyParam: any, i: any) => {
                      console.log(bodyParam, "isi bodpar uka");
                      return (
                        <div
                          className="d-flex flex-row m-1"
                          key={`bodyParamDiv_${i + 1}`}
                        >
                          <span className="badge badge-circle fw-bolder fs-7 badge-primary mt-3">
                            {i + 1}
                          </span>
                          <div className="d-flex flex-column w-100 m-3">
                            <input
                              type="text"
                              disabled
                              value={
                                bodyParam.paramType === "customText" &&
                                bodyParam.paramName
                                  ? bodyParam.paramName
                                  : bodyParam.paramValue
                                // bodyParam.paramValue
                              }
                            />
                            {bodyParam.paramType &&
                              bodyParam.paramType === "customText" && (
                                <div
                                  className={clsx("mt-3 mb-3")}
                                  id={`customParam${i + 1}`}
                                >
                                  <textarea
                                    className="form-control w-100 mt-3"
                                    placeholder={t(
                                      "Campaign.Input.CustomizeText"
                                    )}
                                    rows={3}
                                    id={`textAreaTemplateBodyParam${i + 1}`}
                                    onClick={(e) => {
                                      console.log(
                                        "PFM ini value dari id: " +
                                          e.currentTarget.id
                                      );
                                      setCustoParamId(e.currentTarget.id);
                                    }}
                                    onChange={(e) => {
                                      // checkEmptyCustomText()
                                      setParameter({
                                        text: e.target.value,
                                        paramNo: +e.target.id.charAt(
                                          e.target.id.length - 1
                                        ),
                                        paramName: bodyParam.paramName
                                          ? bodyParam.paramName
                                          : "",
                                      });
                                      let updatedFailover =
                                        reduxTemplateLanguage?.failover?.bodyParam?.map(
                                          (param: any) => {
                                            if (
                                              param.paramId ===
                                                +e.target.id.charAt(
                                                  e.target.id.length - 1
                                                ) &&
                                              param.paramName ===
                                                bodyParam.paramName
                                            ) {
                                              return {
                                                ...param,
                                                textContent: e.target.value,
                                              };
                                            }
                                            return param;
                                          }
                                        );
                                      const temp_data = Object.assign(
                                        {},
                                        reduxTemplateLanguage,
                                        {
                                          failover: {
                                            ...reduxTemplateLanguage?.failover,
                                            bodyParam: updatedFailover,
                                          },
                                        }
                                      );
                                      console.log(
                                        reduxTemplateLanguage,
                                        "reduxTemplateLanguage before 1430 RCD"
                                      );
                                      console.log(
                                        temp_data,
                                        "temp_data before 1430 RCD"
                                      );
                                      dispatch(
                                        ReduxTemplate.setGeneratedLanguage(
                                          temp_data
                                        )
                                      );
                                      console.log(
                                        reduxTemplateLanguage,
                                        "reduxTemplateLanguage 1430 RCD"
                                      );
                                    }}
                                    // onBlur={({ target }) => target.focus()}
                                    defaultValue={bodyParam.textContent}
                                  ></textarea>
                                  {reduxEmptyCustomTextlert &&
                                    reduxEmptyCustomTextlert.map(
                                      (alert, index) => {
                                        if (index === i && alert === true) {
                                          return (
                                            <div
                                              className="text-danger"
                                              data-testid="errorEmptyCustomtext"
                                            >
                                              {t(
                                                "CreateTemplate.TemplateContent.Alert.EmptyCustomTextorURL"
                                              )}
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  className={clsx(showWarningBodyMessage ? "" : "display-none")}
                >
                  <div className="card border-warning mb-3">
                    <div className="card-body text-warning">
                      <h5 className="card-title">
                        {t("Campaign.Alert.InvalidData")}
                      </h5>
                      <p className="card-text">
                        <i className="bi bi-exclamation-triangle text-warning"></i>
                        {t("Campaign.Alert.WrongParam")}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isMobileView
                      ? "flex-column mt-13 justify-content-between align-items-center"
                      : "d-flex mt-13 flex-row w-full flex-wrap justify-content-between align-items-center"
                  }
                >
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sendWhenUndelivered}
                      onChange={(e) => {
                        setSendWhenUndelivered(e.currentTarget.checked);
                        if (e.currentTarget.checked === true) {
                          let temp_data = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                message: failoverMessage,
                                bodyParam:
                                  reduxTemplateLanguage?.failover?.bodyParam,
                                bodyParamCount:
                                  reduxTemplateLanguage?.failover
                                    ?.bodyParamCount,
                                sendWhenUndelivered: e.currentTarget.checked,
                                undeliveredTime: undeliveredTime,
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data)
                          );
                        } else {
                          let temp_data = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                message: failoverMessage,
                                sendWhenUndelivered: e.currentTarget.checked,
                                bodyParam:
                                  reduxTemplateLanguage?.failover?.bodyParam,
                                bodyParamCount:
                                  reduxTemplateLanguage?.failover
                                    ?.bodyParamCount,
                              },
                            }
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data)
                          );
                        }
                      }}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label fs-5">
                      {t("Failover.TimePeriod.Info")}
                    </label>
                  </div>
                  <div className={isMobileView ? "pt-3" : ""}>
                    <div className="d-flex flex-row input-group">
                      <input
                        type="number"
                        min={undeliveredUnit === "Minute" ? 5 : 1}
                        max={
                          undeliveredUnit === "Day"
                            ? undefined
                            : undeliveredUnit === "Hour"
                            ? 24
                            : 60
                        }
                        value={undeliveredTime}
                        disabled={sendWhenUndelivered === true ? false : true}
                        onChange={(e) => {
                          let value = Number(e.currentTarget.value);
                          if (isNaN(value) || value === 0) {
                            if (undeliveredUnit === "Minute") {
                              value = undeliveredUnit === "Minute" ? 5 : 1;
                            }
                          }
                          if (undeliveredUnit === "Hour" && value >= 24) {
                            value = 24;
                          }
                          if (undeliveredUnit === "Minute" && value >= 60) {
                            value = 60;
                          }
                          setUndeliveredTime(value);
                          let temp_data = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                message: failoverMessage,
                                sendWhenUndelivered: sendWhenUndelivered,
                                bodyParam:
                                  reduxTemplateLanguage?.failover?.bodyParam,
                                bodyParamCount:
                                  reduxTemplateLanguage?.failover?.bodyParam
                                    ?.length,
                                undeliveredTime: value,
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data)
                          );
                        }}
                        className="form-control"
                        style={{
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          borderBottomRightRadius: "0rem",
                          borderTopRightRadius: "0rem",
                          position: "relative",
                          width: "100px",
                        }}
                      />
                      <div className="input-group-append position-relative d-flex">
                        <div className="position-relative d-inline-block">
                          <select
                            className="form-select border position-relative flex-grow-1 w-100"
                            value={undeliveredUnit}
                            disabled={
                              sendWhenUndelivered === true ? false : true
                            }
                            onChange={(e) => {
                              const selectedUnit = e.currentTarget.value;
                              setUndeliveredUnit(selectedUnit);
                              setUndeliveredTime(
                                selectedUnit === "Minute" ? 5 : 1
                              );
                              let temp_data = Object.assign(
                                {},
                                reduxTemplateLanguage,
                                {
                                  failover: {
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                    bodyParam:
                                      reduxTemplateLanguage?.failover
                                        ?.bodyParam,
                                    undeliveredTime:
                                      selectedUnit === "Minute" ? 5 : 1,
                                    undeliveredTimeUnit: selectedUnit,
                                  },
                                }
                              );
                              dispatch(
                                ReduxTemplate.setGeneratedLanguage(temp_data)
                              );
                            }}
                            style={{
                              borderTopLeftRadius: "0rem",
                              borderTopRightRadius: "0.5rem",
                              borderBottomRightRadius: "0.5rem",
                              borderBottomLeftRadius: "0rem",
                            }}
                          >
                            <option
                              label={t("Failover.TimePeriod.Minute")}
                              value="Minute"
                            />
                            <option
                              label={t("Failover.TimePeriod.Hour")}
                              value="Hour"
                            />
                            <option
                              label={t("Failover.TimePeriod.Day")}
                              value="Day"
                            />
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* start modal  */}
      <div
        className="modal fade"
        id="addDataTemplateModal"
        // tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-testid="template-process-addDataModal"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Campaign.Button.AddData")}
              </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                id="close-addData"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span
                  className="svg-icon svg-icon svg-icon-2x"
                  data-bs-dismiss="modal"
                >
                  <i className="bi bi-x-lg fs-2"></i>
                </span>
              </div>
            </div>
            <div className="modal-body" data-testid="modal-body">
              <Select
                name="add-message-param"
                styles={customStyles}
                defaultValue={selectedParameter}
                options={listParameter.length > 0 ? listParameter : []}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={t("Campaign.Input.AddData") + "..."}
                onChange={(e) => {
                  const selectedParameterData: any = e;
                  setSelectedParameter(selectedParameterData);
                }}
                onKeyDown={(e) => {
                  if (e.key === "enter" || e.keyCode === 13) {
                    e.preventDefault();
                    return false;
                  }
                }}
              />
              {selectedParameter &&
                selectedParameter.item === "Customize text/URL" && (
                  <div>
                    <div className="mt-5 w-100">
                      {t("Campaign.IM.Body.Modal.CustomText.TextFieldName")}
                      <span className="text-danger">*</span>
                    </div>
                    <div className="flex-grow-1 d-flex flex-row border border-secondary rounded">
                      <input
                        className="form-control form-control-solid border-0 bg-white"
                        type="text"
                        placeholder={t("Campaign.IM.Body.Modal.CustomText")}
                        value={inputParamName}
                        onChange={(e) => {
                          const paramName: string = e.target.value;
                          console.log(`paramName : ${paramName}`);
                          setInputParamName(paramName);
                          if (paramName !== "") {
                            setAlertEmptyParamName(false);
                          }
                          //check if has same paramName
                          // const checkParam = checkParamName(paramName)
                          // if(checkParam){
                          //   dispatch(ReduxTemplate.setAlertDuplicateParamName(true));
                          //   return;
                          // }
                          // if(duplicateParamName){
                          //   dispatch(ReduxTemplate.setAlertDuplicateParamName(false))
                          // }
                        }}
                        maxLength={30}
                      />
                      <button
                        className="border-0 align-middle bg-transparent"
                        style={{ color: "gray" }}
                      >
                        {inputParamName.length}/30
                      </button>
                    </div>
                    {alertEmptyParamName && (
                      <span id="empty-paramName" className="text-danger">
                        {t(
                          "Campaign.TemplateCategory.IM.Body.CustomTextModal.ErrorAlert"
                        )}
                      </span>
                    )}
                    {duplicateParamName && (
                      <span id="duplicate-paramName" className="text-danger">
                        {t(
                          "Campaign.TemplateCategory.IM.Body.ExistFieldName.ErrorAlert"
                        )}
                      </span>
                    )}
                  </div>
                )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={clsx(
                  "btn btn-primary",
                  reduxLoading === true ? "disabled" : ""
                )}
                onClick={() => {
                  if (
                    selectedParameter &&
                    selectedParameter.item === "Customize text/URL"
                  ) {
                    if (inputParamName === "") {
                      setAlertEmptyParamName(true);
                      dispatch(ReduxTemplate.setAlertDuplicateParamName(false));
                      return;
                    }
                    if (duplicateParamName) return;
                  }
                  // onAddMessageParam();
                  addParameter();
                  setTimeout(() => {
                    ourRef.current?.setSelectionRange(-1, -1);
                    ourRef.current?.focus();
                  }, 500);
                }}
                data-bs-dismiss={
                  selectedParameter &&
                  selectedParameter.item === "Customize text/URL" &&
                  (inputParamName === "" ||
                    (inputParamName !== "" && duplicateParamName))
                    ? "#"
                    : "modal"
                }
                data-testid="button-modal-submit"
                disabled={!selectedParameter}
              >
                {t("Campaign.Button.AddData")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end modal  */}
    </>
  );
};

export default TemplateSubmission;
