import React from "react";
import { useMediaQuery } from "react-responsive";

const SelectionHeader = ({ isAllSelected, onSelectAll }: any) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <th className="px-2 py-0 my-0 align-content-center">
      {!isMobileView ? (
        <div className="py-0 my-0 form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            data-kt-check={isAllSelected}
            checked={isAllSelected}
            onChange={onSelectAll}
          />
        </div>
      ) : (
        <div className="py-0 my-0 form-check form-check-sm form-check-custom">
          <input
            className="form-check-input cursor-pointer border-2 border-primary"
            type="checkbox"
            data-kt-check={isAllSelected}
            checked={isAllSelected}
            onChange={onSelectAll}
          />
        </div>
      )}
    </th>
  );
};

export default SelectionHeader;
