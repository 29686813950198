import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { KTCard } from "../../../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { endOfDay, format, startOfDay, startOfMonth } from "date-fns";
import { useFirestoreTable } from "../../context/TableContext";
import * as AccountService from "../../../../../../../db/serviceAccount";
import * as lc from "../../../../../../../app/modules/localstorage";
import { createRef } from "../../../../../../../db";
import { Order } from "../../../../../../models/Order";
import Account from "../../../../../../models/Account";
import {
  syncOrder,
  syncOrderProps,
} from "../../../../../../../api/server/order";
import SyncResultModal from "./SyncResultModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup/redux/store";
import { runSync, finishSync } from "../../../../../../modules/order/SyncSlice";
import Customer from "../../../../../../models/Customer";
import { Timestamp } from "firebase-admin/firestore";
import Message from "../../../../../../models/Message";
import Notification from "../../../../../../models/Notification";
import { infoSyncOrder } from "../../../../../../modules/notify";
import { newNotification } from "../../../../../../../api/firebase";
// import { onNewDataSync } from "../../../../../../routes/PrivateRoutes";

type SyncModalProp = {
  isOpen?: boolean;
  onClose?: () => void;
  onResultFinished?: () => void;
  onSync?: (prm: boolean) => void;
  syncResult?: (result: any, syncTime: Array<string>) => void;
  // syncDate?: (prm: Array<string>) => void;
};

const SyncModal: FC<SyncModalProp> = ({
  isOpen,
  onClose,
  onResultFinished,
  onSync,
  syncResult,
  // syncDate,
}) => {
  const { t } = useTranslation();

  const [orderDataAll, setOrderData] = useState<Account[]>([]);
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
  const syncRes = lc.getItemLC(lc.LCName.SyncRes);

  useEffect(() => {
    const fetchOrderData = async () => {
      const client = lc.getItemLC(lc.LCName.Client);
      const clientId = client.id;
      const clientIdRef = createRef("clients", clientId);
      try {
        const listOfOrders = await AccountService.getByClient(clientIdRef);
        const storedState = lc.getItemLC("isSync");

        // Check if listOfOrders is not undefined before setting the state
        if (listOfOrders !== undefined) {
            console.log("Order Data: ", listOfOrders);
            setOrderData(listOfOrders);
  
            // Check default value for selectedData
            const defaultSelectedData = listOfOrders.map((order) => order.id);
            setSelectedData(defaultSelectedData);
          }

        if (storedState) {
          // onSync = JSON.parse(storedState);
          console.log("MASUK IF STOREDSTATE");
          const { inProgress } = JSON.parse(storedState);
          if (inProgress) {
            syncHandler();
            console.log("MASUK IF INPROGRESS");
          }
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, []);

  const orderList = orderDataAll;

  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const startOfDayTime = startOfDay(today);
  const endOfDayTime = endOfDay(today);

  const formattedStartDate = format(firstDayOfMonth, "yyyy-MM-dd");
  const formattedEndDate = format(today, "yyyy-MM-dd");
  const formattedStartTime = format(startOfDayTime, "HH:mm:ss");
  const formattedEndTime = format(endOfDayTime, "HH:mm:ss");

  const formattedStartDateToDisplay = format(firstDayOfMonth, "dd/MM/yyyy");
  const formattedEndDateToDisplay = format(today, "dd/MM/yyyy");

  const [startDate, setStartDate] = useState<string>(formattedStartDate);
  const [startTime, setStartTime] = useState<string>(formattedStartTime);

  const [startDateToDisplay, setStartDateToDisplay] = useState<string>(formattedStartDateToDisplay);
  const [endDateToDisplay, setEndDateToDisplay] = useState<string>(formattedEndDateToDisplay);
  
  const [endDate, setEndDate] = useState<string>(formattedEndDate);
  const [endTime, setEndTime] = useState<string>(formattedEndTime);
  
  // For proper value checking
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isStartTimeValid, setIsStartTimeValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);
  const [isEndTimeValid, setIsEndTimeValid] = useState<boolean>(true);
  const [isEndDateLessThanStartDate, setIsEndDateLessThanStartDate] =
    useState<boolean>(true);
  const [isEndTimeLessThanStartTime, setIsEndTimeLessThanStartTime] =
    useState<boolean>(true);

  const [selectedData, setSelectedData] = useState<string[]>([]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const onConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  const dispatch = useDispatch();
  const isSync = useSelector((state: RootState) => state.SyncOrder.IsSync);

  const handleOnChangeStartDate = (e: any) => {
    try {
      console.log(`start date value : `, new Date(e.target.value), " from ", e.target.value);
      setStartDate(e.target.value);
      setStartDateToDisplay(format(new Date(e.target.value), "dd/MM/yyyy"));
    } catch (error) {
      console.log("Error inputting start date:", error);
    }
  };

  const handleOnChangeStartTime = (e: any) => {
    setStartTime(e.target.value);
    console.log(`start time value : `, startTime);
  };

  const handleOnChangeEndDate = (e: any) => {
    try {
      console.log(`end date value : `, new Date(e.target.value), " from ", e.target.value);
      setEndDate(e.target.value);
      setEndDateToDisplay(format(new Date(e.target.value), "dd/MM/yyyy"));
    } catch (error) {
      console.log("Error inputting end date:", error);
    }
  };

  const handleOnChangeEndTime = (e: any) => {
    setEndTime(e.target.value);
    console.log(`end time value : `, endTime);
  };

  const handleOnCheckBox = (id: string) => {
    setSelectedData((prevSelectedData) =>
      prevSelectedData.includes(id)
        ? prevSelectedData.filter((selectedId) => selectedId !== id)
        : [...prevSelectedData, id]
    );
  };

  const syncHandler = async () => {
    const client = lc.getItemLC(lc.LCName.Client);
    const clientId = client.id;

    const from_time = `${startDate} ${startTime} GMT+0700`;
    const to_time = `${endDate} ${endTime} GMT+0700`;
    
    const from_time_t = `${startDateToDisplay} ${startTime} GMT+0700`;
    const to_time_t = `${endDateToDisplay} ${endTime} GMT+0700`;
    
    console.log(
      "From Time :",
      from_time_t,
      "To Time :",
      to_time_t
    );

    const selected_items = selectedData;
    const reqData: syncOrderProps = {
      startTime: from_time,
      endTime: to_time,
      accounts: selected_items,
    };

    setSyncInProgress(true);
    lc.setItemLC("isSyncInProgress", "true");

    onClose && onClose();
    onSync && onSync(true);

    // Store syncDate in local storage
    lc.setItemLC("syncDate", [from_time_t, to_time_t]);

    // Store syncResult in local storage
    lc.setItemLC("syncResult", JSON.stringify(reqData));

    // Get SyncOrder Result from server
    const result = await syncOrder(reqData, clientId);

    if (syncResult && result) {
      console.log("Pass!");
      syncResult(result, [from_time_t, to_time_t]);
    }
    onResultFinished && onResultFinished();

    onSync && onSync(false);
    setSyncInProgress(false);
    lc.removeLC("isSync");
    lc.removeLC("isSyncInProgress");
    dispatch(finishSync(result));
  };

  useEffect(() => {
    const start = `${startDate} ${startTime}`;
    const formattedStart = new Date(start);
    const end = `${endDate} ${endTime}`;
    const formattedEnd = new Date(end);
    console.log("Use Effect Sync Modal untuk mengaktifkan pemeriksaan tanggal mulai dan selesai SyncOrder");

    if (!startDate) setIsStartDateValid(false);
    else setIsStartDateValid(true);
    if (!startTime) setIsStartTimeValid(false);
    else setIsStartTimeValid(true);
    if (!endDate) setIsEndDateValid(false);
    else setIsEndDateValid(true);
    if (!endTime) setIsEndTimeValid(false);
    else setIsEndTimeValid(true);
    if (endDate && endDate < startDate) setIsEndDateLessThanStartDate(false);
    else setIsEndDateLessThanStartDate(true);
    if (formattedEnd < formattedStart) setIsEndTimeLessThanStartTime(false);
    else setIsEndTimeLessThanStartTime(true);
  }, [startDate, startTime, endDate, endTime]);

  console.log("openConfirmModal", openConfirmModal);

  return (
    <>
      {isOpen && (
        <Modal
          animation
          centered
          show={isOpen}
          size="lg"
          scrollable={false}
          onHide={onClose}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("OrderList.Sync")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="bg-light">
            <KTCard className="fs-4 p-4">
              <form>
                {/* Start Date & Start Time */}
                <div className="form-group row mb-3">
                  <div className="col-sm-2">
                    <label className="col-form-label">
                      {t("OrderList.Sync.FirstDate")}
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      data-testid="start-date-input"
                      id="start-date-input"
                      name="start-date-input"
                      maxLength={50}
                      className={`form-control${
                        !isStartDateValid ? " is-invalid" : ""
                      }`}
                      onChange={handleOnChangeStartDate}
                      value={startDate}
                      type="date"
                    />
                    {!isStartDateValid && (
                      <div
                        className="invalid-feedback"
                        data-testid="startDateErrValid"
                        style={{ color: "red" }}
                      >
                        {t("ExportOrder.StartDate.Alert")}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <input
                      data-testid="start-time-input"
                      id="start-time-input"
                      name="start-time-input"
                      maxLength={50}
                      className={`form-control${
                        !isStartTimeValid ? " is-invalid" : ""
                      }`}
                      onChange={handleOnChangeStartTime}
                      value={startTime}
                      type="time"
                      step="1"
                    />
                    {!isStartTimeValid && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback"
                        data-testid="startTimeErrValid"
                      >
                        {t("ExportOrder.StartTime.Alert")}
                      </div>
                    )}
                  </div>
                </div>

                {/* End Date & End Time */}
                <div className="form-group row mb-3">
                  <div className="col-sm-2">
                    <label className="col-form-label">
                      {t("OrderList.Sync.EndDate")}
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <input
                      data-testid="end-date-input"
                      id="end-date-input"
                      name="end-date-input"
                      maxLength={50}
                      className={`form-control${
                        !isEndDateValid || !isEndDateLessThanStartDate ? " is-invalid" : ""
                      }`}
                      onChange={handleOnChangeEndDate}
                      value={endDate}
                      type="date"
                      min={startDate}
                    />
                    {!isEndDateValid && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback"
                        data-testid="endDateErrValid"
                      >
                        {t("ExportOrder.EndDate.Alert1")}
                      </div>
                    )}
                    {!isEndDateLessThanStartDate && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback"
                        data-testid="endDateLessThanStartDate"
                      >
                        {t("ExportOrder.EndDate.Alert2")}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <input
                      data-testid="end-time-input"
                      id="end-time-input"
                      name="end-time-input"
                      maxLength={50}
                      className={`form-control${
                        !isEndTimeValid || !isEndTimeLessThanStartTime ? " is-invalid" : ""
                      }`}
                      onChange={handleOnChangeEndTime}
                      value={endTime}
                      type="time"
                    />
                    {!isEndTimeValid && (
                      <div
                        className="invalid-feedback"
                        data-testid="endTimeErrValid"
                        style={{ color: "red" }}
                      >
                        {t("ExportOrder.EndTime.Alert1")}
                      </div>
                    )}
                    {!isEndTimeLessThanStartTime && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback"
                        data-testid="endDateLessThanEndDate"
                      >
                        {t("ExportOrder.EndTime.Alert2")}
                      </div>
                    )}
                  </div>
                </div>

                <fieldset className="form-group mb-3">
                  <div className="row">
                    <legend className="col-form-label col-sm-2 pt-0">
                      {t("OrderList.Sync.Account")}
                    </legend>
                    <div className="col-sm-8">
                      {orderList.map((item) => (
                        <div key={item.id} className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="item.id"
                            defaultChecked={true}
                            onChange={() => handleOnCheckBox(item.id)}
                          />
                          <label className="form-check-label">
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </fieldset>
              </form>
            </KTCard>
          </Modal.Body>

          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <button className="btn btn-primary w-50" onClick={syncHandler} disabled={
              !isStartDateValid || !isStartTimeValid || !isEndDateValid || !isEndTimeValid || !isEndDateLessThanStartDate || !isEndTimeLessThanStartTime || selectedData.length <= 0 && true
              }>
              {t("OrderList.Sync.StartSync")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SyncModal;
