/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  PaginationState,
  QUERIES,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import Customer, { ContactsQueryResponse } from "../../../../models/Customer";
import * as ReduxCampaignTarget from "../../../../modules/campaign/target/redux/CampaignTargetSlice";
import { useQueryRequest } from "./QueryRequestProvider";
import { getTargetByClientID } from "./_requests";
import { TargetSorting } from "./_sort";

const QueryResponseContext =
  createResponseContext<Customer>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listTarget: any = [];
  listTarget = useSelector(
    (state: RootState) => state.CampaignTarget.targetList
  ); //list target
  let TargetQueryResponse: ContactsQueryResponse;
  // let targetCompanyID:string = useSelector((state: RootState) => state.Campaign.companyID);
  let targetClientID: string = useSelector(
    (state: RootState) => state.Campaign.clientID
  );

  useEffect(() => {
    console.log(`updated query`);
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.USERS_LIST}-campaign-target`, { ...state, targetClientID }],
    async () => {
      console.log("Fetching: " + isFetching);
      console.log(
        "Campaign target data on redux length : " + listTarget.length
      );
      let data: any = [];

      //cek redux target
      if (!listTarget || listTarget.length <= 0) {
        console.log("Fetching: getTargetByCompanyID ");
        //get customer by client
        data = await getTargetByClientID(targetClientID!);
        console.log(
          `data getTargetByCompanyID:`,
          data?.length,
          "List Redux",
          listTarget?.length
        );
        if (data && data.length > 0) {
          dispatch(ReduxCampaignTarget.setTargetList(data));
        }
      } else {
        data = listTarget;
      }

      let resultData = TargetSorting(
        data,
        state.sort,
        state.items_per_page,
        state.action,
        state.order,
        state.search,
        state.page
      );

      TargetQueryResponse = {
        data: resultData,
      };

      // return getCIQs(state.sort , state.order, state.search, state.action, state.lastId, state.items_per_page )
      return Promise.resolve(TargetQueryResponse);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  //console.log("response "+JSON.stringify(response));

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
