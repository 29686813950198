import { Fragment, useEffect, useState } from "react";
import styles from "./Table.module.css";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

interface IProps {
  selectedCategories: string[];
  data: any[];
}

const TableComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [summaryDetails, setSummmaryDetails] = useState<any[]>([]);

  // Side Effect For Handler When Selected Categories Is Changed
  useEffect(() => {
    const categories = [...props.selectedCategories];
    setSelectedCategories(categories);
  }, [props.selectedCategories]);

  useEffect(() => {
    if (props.data) {
      const summaries = [...props.data];
      setSummmaryDetails(summaries);
    }
  }, [props.data]);

  return (
    <Fragment>
      <div className="container">
        {isTabletOrMobile && (
          <div className="container row mx-auto py-3">
            {summaryDetails.map((summary, index) => (
              <div key={index} className="col-12 col-md-6 py-3 py-lg-0">
                <div className={`${styles["card-summary"]} my-1`}>
                  <div className={styles["summary"]}>
                    <span className="mr-auto">{summary.label}</span>
                    <span className="ml-auto">{summary.total}</span>
                  </div>

                  <div className="row pt-3">
                    {selectedCategories.map((category) => (
                      <div
                        key={category}
                        className={`${styles["summary-detail"]} col-12 col-md-6 px-4`}
                      >
                        {category === "authentication" && (
                          <>
                            <span className="mr-auto">
                              {t("Home.ConversationCategories.Authenticating")}
                            </span>
                            <span className="ml-auto text-dark">
                              {summary.authentication}
                            </span>
                          </>
                        )}
                        {category === "marketing" && (
                          <>
                            <span className="mr-auto">
                              {t("Home.ConversationCategories.Marketing")}
                            </span>
                            <span className="ml-auto text-dark">
                              {summary.marketing}
                            </span>
                          </>
                        )}
                        {category === "utility" && (
                          <>
                            <span className="mr-auto">
                              {t("Home.ConversationCategories.Service")}
                            </span>
                            <span className="ml-auto text-dark">
                              {summary.service}
                            </span>
                          </>
                        )}
                        {category === "service" && (
                          <>
                            <span className="mr-auto">
                              {t("Home.ConversationCategories.Utility")}
                            </span>
                            <span className="ml-auto text-dark">
                              {summary.utility}
                            </span>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!isTabletOrMobile && (
          <table className={styles["detail-summary-table"]}>
            <thead>
              <tr>
                <th>{t("Common.Date")}</th>
                {selectedCategories.map((category) => (
                  <th key={category}>
                    {category === "authentication" &&
                      t("Home.ConversationCategories.Authenticating")}
                    {category === "marketing" &&
                      t("Home.ConversationCategories.Marketing")}
                    {category === "utility" &&
                      t("Home.ConversationCategories.Utility")}
                    {category === "service" &&
                      t("Home.ConversationCategories.Service")}
                  </th>
                ))}
                <th> Total</th>
              </tr>
            </thead>
            <tbody>
              {(!summaryDetails.length || summaryDetails.length === 0) && (
                <tr>
                  <td
                    colSpan={selectedCategories.length + 2}
                    className="text-center"
                  >
                    {t("Common.NoData")}
                  </td>
                </tr>
              )}

              {summaryDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.label}</td>
                  {selectedCategories.map((category) => (
                    <td key={category}>
                      {category === "authentication" && detail.authentication}
                      {category === "marketing" && detail.marketing}
                      {category === "utility" && detail.utility}
                      {category === "service" && detail.service}
                    </td>
                  ))}
                  <td>{detail.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Fragment>
  );
};

export default TableComponent;
