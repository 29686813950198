/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React from "react";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";
import { Campaign } from "../../../../../models/Campaign";

type Props = {
  campaign: Campaign;
};

const CampaignCompanyCell: FC<Props> = ({ campaign }) => (
  <div className='text-gray-650 mb-1'>{campaign.companyName}</div>
);

export { CampaignCompanyCell };
