import firebase from "firebase/compat/app";
import db from ".";
import Brand, { MarketplacesBrand } from "../app/models/Brand";
import { Media } from "../app/models/Product";
import { converter2 } from "./converter";

export const getAllBrands = async () => {
  return await db
    .collection("brands")
    .where("isActive", "==", true)
    .orderBy("brandName", "asc")
    .get()
    .then((snaps) => {
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getAllBrands : " + error);
    });
};

export const searchBrands = async (keyword: string) => {
  return await db
    .collection("brands")
    .withConverter(converter2<Brand>())
    .where("isActive", "==", true)
    // .where("brandName", "==", keyword)
    .orderBy("brandNameInsensitive")
    .startAt(keyword.toLowerCase())
    .endAt(keyword.toLowerCase() + "\uf8ff")
    .get()
    .then((snaps) => {
      console.log("brandSnap", snaps);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr searchBrand : " + error);
    });
};

export const getBrandCategory = async (
  brandID: string,
  marketplace: string
) => {
  return await db
    .collection("brands")
    .doc(brandID)
    .collection("marketplaceBrands")
    .withConverter(converter2<MarketplacesBrand>())
    .where("marketplaceName", "==", marketplace)
    .get()
    .then((snaps) => {
      console.log("brandCategorySnaps", snaps);
      const brandCategoryList = snaps.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as MarketplacesBrand)
      );

      return brandCategoryList;
    })
    .catch((error) => {
      //
      console.log("Erorr searchBrand : " + error);
      return undefined;
    });
};

export const createBrandCategory = async (
  brandID: string,
  category: any,
  categoryID?: string
) => {
  if (categoryID) {
    return await db
      .collection("brands")
      .doc(brandID)
      .collection("marketplaceBrands")
      .doc(categoryID)
      .set(category)
      .then(() => {
        return categoryID;
      })
      .catch((error: any) => {
        console.error("Error updating document Category: ", error);
        return "";
      });
  } else {
    return await db
      .collection("brands")
      .doc(brandID)
      .collection("marketplaceBrands")
      .add(category)
      .then((ref: any) => {
        let refID = "";
        if (ref && ref.id) {
          refID = ref.id;
        }
        return refID;
      })
      .catch((error: any) => {
        console.error("Error adding document Category: ", error);
        return "";
      });
  }
};

// export const getBrandPaginate = async (limit: number, after?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
//   const query = db
//   .collection("brands")
//   .where("isActive", "==", true)
//   .withConverter(converter2<Brand>())
//   .orderBy("brandName", "asc")

//   if (after) {
//     query.startAfter(after)
//   }
//   query.limit(limit);
//   return await query.get()
//     .then((snaps) => {
//       const brands = snaps.docs.map((doc) => ({
//         id: doc.id,
//         data: doc.data(),
//         doc: doc
//       }));
//       return brands;
//     })
//     .catch((error) => {
//       //TODO
//       console.log("Erorr getAllBrands : " + error);
//     });

// }

export const getBrandByName = async (brandName: string) => {
  console.log("getProductByName " + brandName);
  return await db
    .collection("brands")
    .where("isActive", "==", true)
    .where("brandName", "==", brandName)
    .withConverter(converter2<Brand>())
    .limit(1)
    .get()
    .then((snaps) => {
      const product = snaps.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log("Data : " + product.length);
      return product;
    })
    .catch((error) => {
      console.error("Error getProduct By Name : ", error);
    });
};

export const getBrandByNameInsensitive = async (brandName: string) => {
  return await db
    .collection("brands")
    .where("isActive", "==", true)
    .where("brandNameInsensitive", "==", brandName.toLocaleLowerCase())
    .withConverter(converter2<Brand>())
    .limit(1)
    .get()
    .then((snaps) => {
      if (snaps.docs.length > 0) {
        return snaps.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      }
      return [];
    })
    .catch((error) => {
      console.error(
        "Error getBrandByNameInsensitive : ",
        error.toString ? error.toString() : JSON.stringify(error)
      );
      return [];
    });
};

export const getBrandById = async (brandID: string) =>
  await db
    .collection("brands")
    .doc(brandID)
    .withConverter(converter2<Brand>())
    .get()
    .then((doc) => {
      console.log(`template found`);
      return {
        ...doc.data(),
        id: doc.id,
      };
    })
    .catch((error: any) => {
      console.log("Error getting document Brand By ID:", error);
      return undefined;
    });

export const createBrand = async (brandData: any) => {
  const brandid = await db
    .collection("brands")
    .add(brandData)
    .then((ref) => {
      console.log(`Added document Brands with ID: ${ref.id}`);
      // brandid = ref.id;
      return ref.id;
    })
    .catch((error) => {
      console.error("Error adding document Brands: ", error);
      return undefined;
    });
  return brandid;
};

export const getMedia = async (brandID: string) => {
  return await db
    .collection("brands")
    .doc(brandID)
    .collection("photos")
    .withConverter(converter2<Media>())
    .get()
    .then((snaps) => {
      console.log("getMediaSnaps", snaps);
      const media = snaps.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Media)
      );

      return media;
    })
    .catch((error) => {
      //
      console.log("Error getMedia : " + error);
      return undefined;
    });
};

// delete media deleteMedia without mediaID param
export const deleteMedia = async (
  brandID: string,
  mediaType: string,
  mediaID: string
) => {
  return await db
    .collection("brands")
    .doc(brandID)
    .collection(mediaType)
    .doc(mediaID)
    .delete()
    .then(() => {
      return mediaID;
    })
    .catch((error: any) => {
      console.error("Error updating document Media: ", error);
      return "";
    });
};

export const createMedia = async (
  brandID: string,
  mediaType: string,
  media: any
) => {
  return await db
    .collection("brands")
    .doc(brandID)
    .collection(mediaType)
    .add(media)
    .then((ref: any) => {
      let refID = "";
      if (ref && ref.id) {
        refID = ref.id;
      }
      return refID;
    })
    .catch((error: any) => {
      console.error("Error adding document Media: ", error);
      return "";
    });
};
