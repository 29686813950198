import React from "react";
import { KTSVG } from "../../../resources/helpers";
import { useMediaQuery } from "react-responsive";

type ModalProps = {
  id: string;
  title: string;
  body: string | HTMLElement;
  image?: string;
  onUnsavedChanges?: () => void;
  onSavedChanges?: () => void;
  onCancel?: () => void;
  onOther?: () => void;
  otherOption?: string;
  unsavedOption?: string;
  savedOption?: string;
  cancelOption?: string;
  isOther?: boolean;
};

const ModalConfirmationWihtButtonSavedUnsavedChanges: React.FC<ModalProps> = ({
  id,
  title,
  body,
  onUnsavedChanges,
  onSavedChanges,
  onCancel,
  isOther,
  onOther,
  otherOption,
  unsavedOption,
  savedOption,
  cancelOption,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ minWidth: isTabletOrMobile ? "" : "700px" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" data-test-id="ModalConfirmation-Title">{title}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-test-id="ModalConfirmation-ButtonClose"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {/* <p>{body}</p> */}
            {body}
          </div>
          {isTabletOrMobile && (
            <>
              <div className="modal-footer d-flex flex-column">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  data-test-id="ModalConfirmation-ButtonCancel"
                  onClick={onOther ? onOther : onCancel}
                >
                  {cancelOption}
                </button>
                <button
                  type="button"
                  className="btn btn-light-primary w-100"
                  data-bs-dismiss="modal"
                  data-test-id="ModalConfirmation-ButtonUnsavedChanges"
                  onClick={onUnsavedChanges ? onUnsavedChanges : onCancel}
                >
                  {unsavedOption}
                </button>
                {savedOption !== "" && (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    data-test-id="ModalConfirmation-ButtonSavedChanges"
                    onClick={onSavedChanges ? onSavedChanges : onCancel}
                  >
                    {savedOption}
                  </button>
                )}
              </div>
            </>
          )}
          {!isTabletOrMobile && (
            <>
              <div className="modal-footer justify-content-between">
                <div className="justify-content-left">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    data-test-id="ModalConfirmation-ButtonCancel"
                    onClick={onOther ? onOther : onCancel}
                  >
                    <span>{cancelOption}</span>
                  </button>
                </div>
                <div className="justify-content-right">
                  <button
                    type="button"
                    className="btn btn-light-primary mx-2"
                    data-bs-dismiss="modal"
                    data-test-id="ModalConfirmation-ButtonUnsavedChanges"
                    onClick={onUnsavedChanges ? onUnsavedChanges : onCancel}
                  >
                    {unsavedOption}
                  </button>
                  {savedOption !== "" && (
                    <button
                      type="button"
                      className="btn btn-primary mx-2"
                      data-bs-dismiss="modal"
                      data-test-id="ModalConfirmation-ButtonSavedChanges"
                      onClick={onSavedChanges ? onSavedChanges : onCancel}
                    >
                      {savedOption}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmationWihtButtonSavedUnsavedChanges;
