import React from "react";
import { KTSVG } from "../../../resources/helpers";
import { useMediaQuery } from "react-responsive";

type ModalProps = {
  id: string;
  title: string;
  body: string | JSX.Element;
};

const ModalDangerousProductInformation: React.FC<ModalProps> = ({
  id,
  title,
  body,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{minWidth: `${isTabletOrMobile ? "": "fit-content"}`, backgroundColor: "#F2F3F7"}}>
          <div className="modal-header pb-0" style={{borderBottom: "none"}}>
            <h5 className="modal-title">{title}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body" style={{ maxHeight: "700px", minWidth: `${isTabletOrMobile ? "" : "700px"}`}}>
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDangerousProductInformation;