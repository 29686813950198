import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MarketplaceColumn from "./columns/_columns";
import { useFirestoreTable } from "../../context/TableContext";
import { Loading } from "../../../../../../../modules/util/Loading";
import { ColumnInstance, Row, useTable } from "react-table";
import Account from "../../../../../../../models/Account";
import * as AccountService from "../../../../../../../../db/serviceAccount";
import * as lc from '../../../../../../../../app/modules/localstorage';
import { createRef } from "../../../../../../../../db";
import { r } from "msw/lib/glossary-dc3fd077";
function MarketplaceTable() {
  const { t } = useTranslation();
  const [accountData , setAccountData] = useState<Account[]>([]);
  const columns = useMemo(() => MarketplaceColumn, [MarketplaceColumn]);
 
  // // Get Account Data From Database using Service
  useEffect(() => {
    const fetchAccountData = async() => {
      const client = lc.getItemLC(lc.LCName.Client);
      const clientId = client.id;
      const clientIdRef = createRef("clients", clientId);
      const accountData =  await AccountService.getByClient(clientIdRef);
      console.log('accountData: ' , accountData);
      setAccountData(accountData);
    }

    fetchAccountData();
  }, []);

  const {
    data: orderData,
    isLoading,
    isFetching,
    marketplaceTableState,
  } = useFirestoreTable();

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
  useTable({
    columns,
    data : orderData?.items || []
  });
  // console.log({headers, rows});
  return (
    <>
      {/* {(isLoading || isFetching) && <Loading />} */}
      <div
        className="table-responsive px-2"
        style={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 10 }}
          >
            <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Account>, headerIndex) => {
                return column.render("Header", {
                  ...column.getHeaderProps(),
                  key: headerIndex,
                });
              })}
            </tr>
          </thead>
          <tbody className=" fw-bold">
            {rows.length > 0
              ? rows.map((row: Row<Account>, rowIndex) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  rowProps.key = rowIndex;
                  return (
                    <tr {...rowProps}>
                      {row.cells.map((cell, cellIndex) => {
                        return cell.render("Cell", {
                          ...cell.getCellProps(),
                          key: cellIndex,
                        });
                      })}
                    </tr>
                  );
                })
              : !(isLoading || isFetching) && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("OrderList.NoData")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MarketplaceTable;


function getAccountWithSpan(arg0: any): any {
  throw new Error("Function not implemented.");
}

