import clsx from "clsx";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
// import orderColumns from "./columns/_columns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../setup/redux/store";
import { useFirestoreTable } from "../../context/TableContext";
import Loading from "../../../../../../../../resources/helpers/components/Loading";
import User from "../../../../../../../models/User";
import * as memberService from "../../../../../../../../db/serviceUser";
import * as lc from "../../../../../../../../app/modules/localstorage";
import { createRef } from "../../../../../../../../db";
import MemberColumns from "./columns/_columns";

export default function MemberTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [memberData , setMemberData] = useState<User[]>([]);
  const columns = useMemo(() => MemberColumns, []);

  useEffect(() => {
    const fetchmemberData = async () => {
      const client = lc.getItemLC(lc.LCName.Client);
      const clientId = client.id;
      const clientIdRef = createRef("clients", clientId);
      // const memberData =  await memberService.getUserByFilter(clientId, "status", "invited");
      // console.log(memberData, "test data member 1");
      // setMemberData(memberData as any);
    };

    fetchmemberData();
  }, []);

  const {
    data: memberData,
    isLoading,
    isFetching,
    memberTableState,
    refreshData,
    setSelectedItems,
  } = useFirestoreTable();

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data: memberData?.items || [],
    });

  return (
    <>
      {/* <div className="card-body"> */}
      {(isLoading || isFetching) && <Loading />}
      <div
        className="table-responsive px-2"
        style={{
          height: "95%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="w-100 position-sticky top-0 bg-white"
            style={{ zIndex: 200 }}
          >
            <tr
              className="w-100 text-center text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white"
              data-testid="column"
            >
              {headers.map((column: ColumnInstance<User>, headerIndex) => {
                return (
                  ((rows[0]?.original?.status === "invited" &&
                    column?.id !== "phoneNumber" &&
                    column?.id !== "name" &&
                    column?.id !== "status") ||
                    (rows[0]?.original?.status === "registered" &&
                      column?.id !== "inviteExpiredAt")) &&
                  column.render("Header", {
                    ...column.getHeaderProps(),
                    key: headerIndex,
                  })
                );
              })}

              {/* {headers.map((column: ColumnInstance<User>, headerIndex) => {
                return column.render("Header", {
                  ...column.getHeaderProps(),
                  key: headerIndex,
                });
              })} */}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold">
            {rows.length > 0
              ? rows.map((row: Row<User>, rowIndex) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  rowProps.key = rowIndex;
                  return (
                    <tr {...rowProps}>
                      {row.cells.map((cell, cellIndex) => {
                        const data = row.original;
                        const column = columns[cellIndex];
                        if (
                          data.status === "invited" &&
                          (column.id === "email" ||
                            column.id === "role" ||
                            column.id === "inviteExpiredAt" ||
                            column.id === "action")
                        ) {
                          return cell.render("Cell", {
                            ...cell.getCellProps(),
                            key: cellIndex,
                          });
                        } else if (
                          data.status === "registered" &&
                          column.id !== "inviteExpiredAt"
                        ) {
                          return cell.render("Cell", {
                            ...cell.getCellProps(),
                            key: cellIndex,
                          });
                        } else {
                          return null;
                        }
                      })}
                    </tr>
                  );
                })
              : !(isLoading || isFetching) && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("OrderList.NoData")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      {/* </div> */}
    </>
  );
}
