import { createNewProduct } from "./connection";

export const sendRequestProduct = async (
  accountID: string,
  productID: string,
  userID: string,
//   callback: any
) => {
  console.log("masuk di function sendRequestProduct to marketplace", accountID, productID, userID)
  const jsonAddProduct = `
  {
    "account" : "${accountID}",
    "product" : "${productID}",
    "user" : "${userID}"
  }`;

  console.log("jsonAddProduct : ", jsonAddProduct);

  await createNewProduct(jsonAddProduct
    // , (rsError:string, rsMessage:string, rsJson:JSON) => {
    // callback(rsError, rsMessage, rsJson);
//   }
  )
};
