import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../modules/auth/pages/Login";
import { AuthLayout } from "../layout/AuthLayout";
import { ForgotPassword } from "../modules/auth/pages/ForgotPassword";
import { ResetPasswordLayout } from "../layout/ResetPasswordLayout";
import { ResetPassword } from "../modules/auth/pages/ResetPassword";
import { ResetPasswordSuccess } from "../modules/auth/pages/ResetPasswordSuccess";
import { Registration } from "../modules/auth/pages/Register";
import { RegisterLayout } from "../layout/RegisterLayout";
import { EmailVerification } from "../modules/auth/pages/EmailVerify";
import { ClientInfo } from "../modules/auth/pages/ClientInfo";
import { ClientAddress } from "../modules/auth/pages/ClientAddress";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route index element={<Login />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path="registration" element={<Registration />} />
        <Route path="client-info" element={<ClientInfo />} />
        <Route path="client-address" element={<ClientAddress />} />
      </Route>
      <Route path="reset-password-success" element={<ResetPasswordSuccess />} />
      <Route path="email-verification" element={<EmailVerification />} />

      <Route element={<ResetPasswordLayout />}>
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
};

export { AuthRoutes };
