import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import db from ".";
import Company from "../app/models/Company";
import User, { UserCompanies } from "../app/models/User";
import * as lc from "../app/modules/localstorage/index";
import { createRef } from "./connection";
import { converter, converter2 } from "./converter";
import Notification from "../app/models/Notification";
import { Timestamp } from "firebase-admin/firestore";
import { queryClient } from "../../src";

let firstVisibleFriendList: firebase.firestore.QueryDocumentSnapshot;
let lastVisibleFriendList: firebase.firestore.QueryDocumentSnapshot;

export const getCachedUserByID = async (id: string) => {
  const cachedUser = queryClient.getQueryData(["user", id]);
  if (cachedUser) {
    return cachedUser as User;
  }
  const user = await getUserByID(id);
  if (user) {
    queryClient.setQueryData(["user", id], user);
    return user;
  }
  return;
};

export const getUserByID = async (id: string) => {
  return await db
    .collection("users")
    .doc(id)
    .withConverter(converter2<User>())
    .get()
    .then((snapshot) => {
      console.log("Fetched User data : ");
      if (snapshot.exists) return { id: snapshot.id, ...snapshot.data() } as User;
    })
    .catch((err) => {
      console.log("Error getting user (getUserByID)", err);
    });
};

export const checkUserByEmail = async (email: string) =>
  await db
    .collection("users")
    .where("email", "==", email)
    .get()
    .then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        console.log("Document Exist (checkUserByEmail) : " + email);
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log("Erorr check user (checkUserByEmail) : ", err);
    });

export const getUserByEmail = async (email: string) => {
  console.log("get User ID by Email");

  if (!email) return null;

  return await db
    .collection("users")
    .where("email", "==", email)
    .where("isActive", "==", true)
    .get()
    .then((snaps) => {
      console.log(snaps.docs);
      if (snaps.docs && snaps.docs[0]) {
        const data = snaps.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        return data;
      }
    })
    .catch((error) => {
      //TODO
      console.log(error);
    });
};

export const createToken = async (uid: string, type: string) => {
  console.log(`generate random token ${type} and save to realtimedb`);
  const token = uuidv4();
  const tokenRef = firebase.database().ref(`tokens/${uid}`);
  await tokenRef.set({
    token: token,
    type: type,
    isUsed: false,
  });
  return token;
};

export const getToken = async (token: string, callback: any) => {
  console.log(`get token ${token}`);
  const tokenRef = firebase.database().ref(`/tokens`);
  //search token in database
  // where token is equals
  const tokenSnapshot = await tokenRef.orderByChild("token").equalTo(token).once("value");
  const tokenData = tokenSnapshot.val();
  if (tokenData) {
    const tokenId = Object.keys(tokenData)[0];
    const token = tokenData[tokenId];
    if (token) {
      return callback({ ...token, uid: tokenId });
    } else return callback();
  } else return callback();
};

export const updateIsUsedToken = async (uid: string) => {
  console.log(`update is used token to realtimedb`);
  await firebase.database().ref(`tokens/${uid}`).update({ isUsed: true });
};

export const updateUser = (user: User) => {
  const { id, ...userDataWithoutId } = user;
  return db
    .collection("users")
    .doc(id)
    .update(userDataWithoutId)
    .then((doc) => {
      console.log("Updated: " + doc);
    })
    .catch((err) => {
      console.log("Error update user : ", err);
    });
};

export const getListUserCompanyByID = async (userID: string) => {
  console.log("getListUserCompanyByID . . ");

  if (!userID) return undefined;

  return await db
    .collection("users")
    .doc(userID)
    .collection("user_companies")
    .withConverter(converter2<UserCompanies>())
    .where("isActive", "==", true)
    .get()
    .then((snaps) => {
      // // console.log(snaps.docs);
      if (snaps.empty) return [] as UserCompanies[];

      return Promise.all(
        snaps.docs.map((doc) => {
          const companiesAccess = { ...doc.data(), id: doc.id };

          const companyRef = doc.data().company ?? createRef("company", "*");
          return companyRef
            .withConverter(converter2<Company>())
            .get()
            .then((company) => {
              if (!company.exists) return companiesAccess as UserCompanies;

              return {
                ...companiesAccess,
                companyModel: { ...company.data(), id: company.id },
              } as UserCompanies;
            });
        })
      );
    })
    .catch((error) => {
      //TODO
      console.log(`Erorr getListUserCompanyByID : ${error}`);
      Promise.reject(`Erorr getListUserCompanyByID : ${error}`);
      return undefined;
    });
};

export const getUserByUserCompanyRef = async (companyId: string) => {
  if (!companyId) return null;

  const companyRef = createRef("company", companyId);

  return await db
    .collectionGroup("user_companies")
    .where("company", "==", companyRef)
    .where("isActive", "==", true)
    .get()
    .then((snaps: { docs: any[] }) => {
      console.log("snaps", snaps.docs);
      return snaps.docs.map((snap: { data: () => any; id: any; ref: { parent: { parent: any } } }) => ({
        data: snap.data(),
        id: snap.id,
        userID: snap.ref.parent.parent,
      }));
    })
    .catch((error: string) => {
      //TODO
      console.log("Error : " + error);
    });
};

//start of user-friendList query
export const getFriendListCountByUserID = async (id: string) =>
  await db
    .collection("users")
    .doc(id)
    .get()
    .then((snapshot) => {
      const counter = snapshot.get("friendListCount");
      return counter;
    });

export const getListFriendListByUserID = async (userID: string) => {
  console.log("getListFriendListByUserID . . ");

  if (!userID) return null;

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .where("isActive", "==", true)
    .orderBy(firebase.firestore.FieldPath.documentId())
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getListFriendListByUserID : " + error);
    });
};

export const getListFriendListByUserIDWithLimit = async (userID: string, limit: number) => {
  console.log("getListFriendListByUserIDWithLimit . . ");

  if (!userID) return null;

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .where("isActive", "==", true)
    .orderBy(firebase.firestore.FieldPath.documentId())
    // .orderBy(sortBy, orderBy)
    .limit(limit)
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const checkVisible = snaps.docs[snaps.docs.length - 1];
      if (checkVisible !== undefined) {
        lastVisibleFriendList = snaps.docs[snaps.docs.length - 1];
      }
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getListFriendListByUserID : " + error);
    });
};

export const getNextListFriendListByUserIDWithLimit = async (
  userID: string,
  limit: number
  // sortBy: string,
  // orderBy: firebase.firestore.OrderByDirection
) => {
  console.log("getNextListFriendListByUserIDWithLimit . . ");

  if (!userID) return null;

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .where("isActive", "==", true)
    .orderBy(firebase.firestore.FieldPath.documentId())
    // .orderBy(sortBy, orderBy)
    .startAfter(lastVisibleFriendList)
    .limit(limit)
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const checkVisible = snaps.docs[snaps.docs.length - 1];
      if (checkVisible !== undefined) {
        lastVisibleFriendList = snaps.docs[snaps.docs.length - 1];
        firstVisibleFriendList = snaps.docs[0];
      }
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getNextListFriendListByUserIDWithLimit : " + error);
    });
};

export const getPrevListFriendListByUserIDWithLimit = async (
  userID: string,
  limit: number
  // sortBy: string,
  // orderBy: firebase.firestore.OrderByDirection
) => {
  console.log("getListFriendListByUserIDWithLimit . . ");

  if (!userID) return null;

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .where("isActive", "==", true)
    .orderBy(firebase.firestore.FieldPath.documentId())
    // .orderBy(sortBy, orderBy)
    .endBefore(firstVisibleFriendList)
    .limitToLast(limit)
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      const checkVisible = snaps.docs[snaps.docs.length - 1];
      if (checkVisible !== undefined) {
        lastVisibleFriendList = snaps.docs[snaps.docs.length - 1];
        firstVisibleFriendList = snaps.docs[0];
      }
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr getListFriendListByUserID : " + error);
    });
};

export const deleteUserFriendListByID = async (userID: string, friendListID: string) =>
  await db.collection("users").doc(userID).collection("friendList").doc(friendListID).update({ isActive: false, updatedAt: new Date() });

export const deleteUserByID = async (userID: string) => await db.collection("users").doc(userID).delete();

export const getUserByFilter = async (clientId: string, filter: string, value: any) => {
  console.log("getUserByFilter . . ");

  if (!filter) return null;

  return await db
    .collection("users")
    .withConverter(converter2<User>())
    .where("client", "==", createRef("clients", clientId))
    .where(filter, "==", value)
    .where("isActive", "==", true)
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const users = snaps.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return users;
    })
    .catch((error) => {
      //
      console.error("Error getUserByFilter : " + error);
      return undefined;
    });
};

export const addFriendList = async (userID: string, friendID: string) => {
  console.log("addFriendList . . ");

  if (!userID || !friendID) return null;

  const refFriendID = createRef("users", friendID);

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .add({
      user: refFriendID,
      isActive: true,
    })
    .then((doc) => {
      console.log("Added: " + doc);
    })
    .catch((err) => {
      console.log("Error add friendlist : ", err);
    });
};

export const checkFriendByID = async (userID: string, friendID: string) => {
  console.log("checkFriendByID . . ");

  if (!userID || !friendID) return null;

  const refFriendID = createRef("users", friendID);

  return await db
    .collection("users")
    .doc(userID)
    .collection("friendList")
    .where("user", "==", refFriendID)
    .where("isActive", "==", true)
    .get()
    .then((snaps) => {
      // console.log(snaps.docs);
      const friendlist = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return friendlist;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr checkFriendByID : " + error);
    });
};

export const updateUserDefaultCompany = (userID: string, companyID: string) => {
  const companyRef = createRef("company", companyID);
  return db
    .collection("users")
    .doc(userID)
    .update({ company: companyRef, updatedAt: new Date() })
    .then((doc) => {
      console.log("Updated: " + doc);
    })
    .catch((err) => {
      console.log("Error updateUserDefaultCompany : ", err);
    });
};

export const createNotificationAnotherUser = async (collab: any) => {
  console.log("==> masuk createNotificationAnotherUser balezzz");
  const userID = lc.getItemLC(lc.LCName.UserID);
  const getAllUsersSameCompany: any = await getUserByUserCompanyRef(collab.company.id);
  const customerRef = createRef("customers", collab.customer.id);
  const companyRef = createRef("company", collab.company.id);
  const client = lc.getItemLC(lc.LCName.Client);
  const clientRef = createRef("clients", client?.id);
  console.log("balezzz Notif To User : " + getAllUsersSameCompany.length);
  for (let index = 0; index < getAllUsersSameCompany.length; index++) {
    console.log("Created Notification for user : " + getAllUsersSameCompany[index].userID.id);
    if (getAllUsersSameCompany[index].userID.id !== userID) {
      db.collection("users")
        .doc(getAllUsersSameCompany[index].userID.id)
        .collection("notifications")
        .add({
          collaboration: createRef("collaborations", collab.id),
          status: "handled",
          customer: customerRef,
          customerPhoneNumber: collab.phoneNumber,
          createdAt: new Date(),
          channel: "whatsapp",
          type: "collaborations",
          client: clientRef,
          company: companyRef,
          notifiedAt: null,
        });
    }
  }
};

export const createNotificationMessageAnotherUser = async (Message: any, companyId: string) => {
  console.log("bozzz Process createNotificationMessageAnotherUser");
  const userID = lc.getItemLC(lc.LCName.UserID);
  console.log("bozzz createNotificationMessageAnotherUser Company ID : " + companyId);
  const getAllUsersSameCompany: any = await getUserByUserCompanyRef(companyId);

  const customerRef = Message.customer ? createRef("customers", Message.customer.id) : null;
  const companyRef = createRef("company", companyId);
  const client = lc.getItemLC(lc.LCName.Client);
  const clientRef = createRef("clients", client?.id);
  const collaborationRef = createRef("collaborations", Message.collaboration.id);
  const roomRef = createRef("collaborations", `${Message.collaboration.id}/rooms/${Message.room.id}`);
  console.log("bozzz Notif To User : " + getAllUsersSameCompany?.length);
  if (Message.channel === "whatsapp") {
    console.log("MASUK IF WHATSAPP DI SERVICE USER BOZZZ");
    for (let index = 0; index < getAllUsersSameCompany.length; index++) {
      console.log("bozzz Created Notification for user : " + getAllUsersSameCompany[index].userID.id);
      if (getAllUsersSameCompany[index].userID.id !== userID) {
        db.collection("users")
          .doc(getAllUsersSameCompany[index].userID.id)
          .collection("notifications")
          .add({
            messages: createRef("messages", Message.id),
            status: "outbound",
            customer: customerRef,
            customerPhoneNumber: Message.customerPhoneNumber,
            createdAt: new Date(),
            channel: "whatsapp",
            type: "messages",
            company: companyRef,
            client: clientRef,
            notifiedAt: null,
          });
      }
    }
  } else {
    console.log("MASUK ELSE WHATSAPP DI SERVICE USER BOZZZ");
    for (let index = 0; index < getAllUsersSameCompany.length; index++) {
      console.log("bozzz Created Notification for user : " + getAllUsersSameCompany[index].userID.id);
      if (getAllUsersSameCompany[index].userID.id !== userID) {
        db.collection("users")
          .doc(getAllUsersSameCompany[index].userID.id)
          .collection("notifications")
          .add({
            messages: createRef("messages", Message.id),
            status: "outbound",
            customer: customerRef,
            room: roomRef,
            collaboration: collaborationRef,
            createdAt: new Date(),
            channel: Message.channel,
            type: "messages",
            company: companyRef,
            client: clientRef,
            notifiedAt: null,
          });
      }
    }
  }
};

export const deleteAllNotifications = async (userID: string) => {
  try {
    const snapshot = await db.collection("users").doc(userID).collection("notifications").get();

    const batch = db.batch();

    snapshot.docs.forEach((doc) => {
      // if (doc.data().type !== "syncOrder") {
      batch.delete(doc.ref);
      // }
    });

    // lc.removeNotification()

    await batch.commit();

    console.log("All notifications deleted successfully");
  } catch (error) {
    console.error("Error deleting notifications:", error);
    throw error;
  }
};

export const deleteNotificationsByID = async (userID: string, notificationID: string | undefined) => {
  try {
    const snapshot = await db.collection("users").doc(userID).collection("notifications").doc(notificationID);

    await snapshot.delete();

    // lc.removeLC(lc.LCName.Notification)

    console.log(notificationID + "deleted successfully");
  } catch (error) {
    console.error("Error deleting notifications:", error);
    throw error;
  }
};

export type syncResToDBProps = {
  orders: any[];
  totalSyncedOrder: number;
  startTime: Timestamp;
  endTime: Timestamp;
  createdAt: Timestamp;
  read: boolean;
};

export const insertSyncResultNotification = async (userID: string, data: any) => {
  try {
    const clientID = lc.getItemLC(lc.LCName.Client)?.id; // Ensure that clientID is not null or undefined
    if (!clientID) {
      throw new Error("Client ID is missing or invalid.");
    }

    const notificationData = {
      ...data,
      client: createRef("clients", clientID),
      type: "syncOrder",
      notifiedAt: null,
      read: false,
    };

    const notificationRef = await db.collection("users").doc(userID).collection("notifications").add(notificationData);

    return notificationRef.id;
  } catch (error) {
    console.error("Error inserting sync result notification:", error);
    throw error;
  }
};

export const markNotifRead = async (userID: string, notif: Notification) => {
  return await db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .doc(notif.id)
    .update({ read: true })
    .then((doc) => {
      console.log("Updated notif:" + doc);
    })
    .catch((error) => {
      console.log("Error updating notif", error);
    });
};

// export const createNotificationJoinedUserCollab = async (collab: any) => {
//   console.log("masuk createNotificationAnotherUser")
//   const userID = lc.getItemLC(lc.LCName.UserID);
//   const getAllUsersSameCompany:any =  await getUserByUserCompanyRef(collab.company.id);
//   const customerRef = createRef("customers", collab.customer.id);
//   const companyRef = createRef("company", collab.company.id);
//   console.log("Notif To User : "+getAllUsersSameCompany.length);
//   for (let index = 0; index < getAllUsersSameCompany.length; index++) {
//     console.log("Created Notification for user : "+getAllUsersSameCompany[index].userID.id);
//     if(getAllUsersSameCompany[index].userID.id !== userID){
//       db.collection("users").doc(getAllUsersSameCompany[index].userID.id)
//       .collection("notifications").add({
//         collaboration: createRef("collaborations", collab.id),
//         status: "handled",
//         customer: customerRef,
//         createdAt: new Date(),
//         channel: "whatsapp",
//         type: "collabNewJoinedUser",
//         company: companyRef,
//         notifiedAt: null,
//       });
//     }
//   }
// }

export const createUpdate = async (userID: string, data: Partial<User>) => {
  try {
    await db.collection("users").doc(userID).set(data, { merge: true });
    console.log("User updated successfully");
  } catch (error) {
    console.error("Error updating user:", error);
  }
};
