import { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  toAbsoluteUrl,
  getIconChannelUrl,
} from "../../../../resources/helpers";
import { Collaboration as HandledMessageListItem } from "../../../models/Chat";
import ChatTime, { ChatListTime } from "./chat-message/ChatTime";
import { ChatLastMessage } from "./chat-message/ChatLastMessage";
import * as chatUtil from "./chat-util/ChatUtil";
import "../../../../styles/css/margin.scss";
import { RootState } from "../../../../setup/redux/store";

interface ChatItemProps {
  // item: HandledMessageListItem;
  item: any;
  selected: boolean;
}

const ChatItem: FC<ChatItemProps> = ({ item, selected }) => {
  const profileName = getProfileName(item);
  return (
    <div
      className={clsx(
        "d-flex flex-stack p-1 px-2 m-2 rounded bg-active-secondary bg-hover-secondary cl-graywhite",
        selected === true ? "cl-7days" : ""
      )}
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
      id={item.id}
      key={item.id}
      data-testid="chat-item"
    >
      <div className="d-flex align-items-center">
        <div className="symbol symbol-35px symbol-circle p-0 m-0">
          <img
            src={chatUtil.avatarUrl(item.customerModel)}
            alt={item.profileName}
          />
          {item.companyModel &&
            item.companyModel.avatar &&
            item.companyModel.avatar !== "" && (
              <img
                className="badge avatar-badge badge-sm badge-circle bg-success avatar-badge-img fs-7"
                alt={item.userModel?.name}
                src={item.companyModel.avatar}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "0px",
                }}
              />
            )}
          {(!item.companyModel ||
            item.companyModel.avatar === undefined ||
            item.companyModel.avatar === "") && (
              <i
                className="start-75 top-75 far fa-building fs-7 cl-gray"
                style={{
                  position: "absolute",
                  color: "#404255",
                }}
              ></i>
            )}
        </div>

        <div className="ms-3">
          <Link
            to="#"
            className="fs-7 text-gray-700 mb-2"
            style={{
              fontWeight: "600",
            }}
          >
            {profileName}
          </Link>
          <div className="fw-bold text-gray-400">
            <ChatLastMessage
              lastmessage={item.lastInteractionMessage ?? ""}
              lastmessagetype={item.lastInteractionType ?? ""}
              id={item.id}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-end ms-2">
        {item.lastInteractionAt && (
          <p className={item.unreadMessagesCount > 0 ? "text-success fs-7 mb-1 fw-bolder" : "text-muted fs-7 mb-1"}>
            {ChatListTime(item.lastInteractionAt.seconds)}
          </p>
        )}
        {/* <div className="symbol-group symbol-hover">
          {item.unreadMessages?.map(
            (unreadMessages) =>
              unreadMessages.unreadCount > 0 && (
                <span
                  className="symbol symbol-30px"
                  key={unreadMessages.channel}
                >
                  <img
                    className="symbol-label channel-logo"
                    alt={unreadMessages.channel}
                    src={toAbsoluteUrl(
                      getIconChannelUrl(unreadMessages.channel.toLowerCase())
                    )}
                    style={{ backgroundColor: "#FFFFFF", objectFit: "cover" }}
                  />
                  <span className="badge symbol-badge badge-sm badge-circle bg-success start-75">
                    {unreadMessages.unreadCount}
                  </span>
                </span>
              )
          )}
        </div> */}
        {item.LastMessageModel?.status === "submitted" &&
          item.LastMessageModel.destination === "outbound" && (
            <div>
              <i className={clsx("bi bi-clock fs-3")}></i>
            </div>
          )}
        {item.LastMessageModel?.status === "sent" &&
          item.LastMessageModel.destination === "outbound" && (
            <div>
              <i className={clsx("bi bi-check2 fs-3")}></i>
            </div>
          )}
        {item.LastMessageModel?.status === "delivered" &&
          item.LastMessageModel.destination === "outbound" && (
            <div>
              <i className={clsx("bi bi-check2-all fs-3")}></i>
            </div>
          )}
        {item.LastMessageModel?.status === "read" &&
          item.LastMessageModel.destination === "outbound" && (
            <div>
              <i className={clsx("bi bi-check2-all text-primary fs-3")}></i>
            </div>
          )}
        {item.unreadMessagesCount > 0 &&
          <span className="badge symbol-badge badge-sm badge-circle bg-success start-75 m-0">
            {item.unreadMessagesCount}
          </span>
        }
        {/* <div>
          <i className="bi bi-check2-all"></i>
        </div> */}
      </div>
    </div>
  );
};

function getProfileName(item: HandledMessageListItem) {
  let profileName = item.profileName;

  //if customer model empty, show profileName or customerName
  if (!item.customerModel) {
    profileName = item.profileName ?? item.phoneNumber ?? "";
  } else if (!item.customerModel?.firstName) {
    //first name not exists
    profileName = `+${item.customerModel.phoneNumber}`;
  } else if (item.customerModel?.lastName) {
    //first name exists & last name exists
    profileName = `${item.customerModel?.firstName} ${item.customerModel?.lastName}`;
  } else {
    //last name not exists
    profileName = `${item.customerModel?.firstName}`;
  }

  if (profileName.length > 15) {
    profileName = profileName.substring(0, 11) + "...";
  }

  return profileName;
}

export default ChatItem;
