import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../resources/helpers";
import {Campaign} from "../../../../models/Campaign"
import firebase from "firebase/compat/app";
import * as campaignServ from "../../../../../db/serviceCampaign";
import { format } from "date-fns";
import { createRef } from "../../../../../db/connection";
import * as lc from "../../../../modules/localstorage/index";
import Company from "../../../../models/Company";
import { UserCompanies } from "../../../../models/User";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;

// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

/***
 * Get Data Customer In Queue
 */
 const getCampaigns = async(
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<Campaign>> => {
  // let CIQQueryResponse: CIQsQueryResponse;
  console.log("Campaign - item : " + limit);
  console.log("Campaign - action : " + action);
  console.log("Campaign - sort : " + sort);
  console.log("Campaign - order : " + order);
  console.log("Campaign - search : " + search);

  
  const client = lc.getItemLC(lc.LCName.Client)

  let sortBy: string = "id";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchBy = search;
  }

  const companyAccesses:UserCompanies[] = lc.getItemLC(lc.LCName.CompanyList) ?? [];
  if(companyAccesses.length <= 0) return [];
  
  let campaigns = await campaignServ
    .getAccessibleCampaign(client.id, companyAccesses);

  // let client = companyAccesses[0].client;

  campaigns = await Promise.all(campaigns.map(async campaign => {
    if(campaign.company){
      let company = companyAccesses.find((cmp) => cmp.id === campaign.company?.id);
      if(company){
        campaign.companyName = company.companyName;
        campaign.companyAvatar = company.companyModel?.avatar ?? ""
      }
    }



    // Campaign Report Data (from Realtime DB by CampaignId)
    let messagesCount = await campaignServ.countCampaignMessagesByCampaignId(campaign.id!);
    if (messagesCount) {
      campaign.campaignMessagesCount = messagesCount;
    }

    // console.log(`Check Request datefns Campaign ID >> ${dataCampaigns[i].id}`);
    // if (campaign.updatedAt!== undefined) {
    //   campaign.updatedAt = 
    //   format(
    //     new Date(campaign.updatedAt?.seconds! * 1000)
    //     ,"d MMM yyyy, h:mm aaa"
    //   );
    // }
    return campaign
  }))
    
  console.log(`Campaign data count ${campaigns.length}`);
  
  return Promise.resolve(campaigns);
};

/***
 * End Of Get Data Customer In Queue
 */

const getCampaignById = (id: ID): Promise<Campaign | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Campaign>>) => response.data)
    .then((response: Response<Campaign>) => response.data);
};

// const createCIQ = (
//   CIQ: any
// ): Promise<CIQ | undefined | string | void> => {
//   console.log("create CIQ ===>>>" + JSON.stringify(CIQ));

//   return createCustomer(CIQ);

// };

// const updateCIQ = (
//   CIQ: any
// ): Promise<CIQ | undefined | string | void> => {
//   return updateCustomer(CIQ);
//   // return axios
//   //   .post(`${USER_URL}/${CIQ.id}`, CIQ)
//   //   .then((response: AxiosResponse<Response<CIQ>>) => response.data)
//   //   .then((response: Response<CIQ>) => response.data);
// };

const handledCollab = async (CIQId: ID,  messageFailed : string, callback: any): Promise<void> => {
  // if (CIQId === undefined || CIQId === null) {
  //   return Promise.resolve();
  // } else {
  //   const getUser = lc.getItemLC(lc.LCName.User);
  //   let id = null;
  //   try {
  //      id  = await updateCollabStatus(CIQId.toString(), CollabsStatus.handled,getUser.uid,messageFailed)
  //     .then((response) => {
  //       if(response){
  //         callback(CIQId, true);
  //       }else{  
  //         callback(CIQId, false);
  //       }
  //     });
  //     console.log("ini adalah id : "+id);
  //     return Promise.resolve();
  //   } catch (error) {
  //     callback(CIQId, false);
  //     return Promise.reject();
  //   }
  // }
};

export {
  getCampaigns,
  handledCollab,
  getCampaignById,

};