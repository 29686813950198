import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import * as ReduxStorefront from "../../../../../../../app/modules/product/storefront/StorefrontSlice";
import * as StorefrontList from "../../../../../../../app/modules/product/storefront/storefrontList/StorefrontListSlice";
import {
  ID,
  KTSVG,
  toAbsoluteUrl,
} from "../../../../../../../resources/helpers";
import { RootState } from "../../../../../../../setup/redux/store";
import { storefrontStep } from "../../../../../../models/Storefront";
import ModalDeleteSelected from "../../../../../../modules/modal/modalDeleteSelected";
import AlertModal from "../../../../../broadcast/AlertModal";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteStorefront } from "../../core/_requests";

type Props = {
  id: ID;
  indexId?: number;
  numberOfProduct: number;
  lastStep?: string;
};

const StorefrontActionsCell: FC<Props> = ({
  id,
  indexId,
  numberOfProduct,
  lastStep,
}) => {
  const dispatch = useDispatch();
  const { setItemIdForUpdate } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });
  const { state, updateState } = useQueryRequest();
  const openAddUserModal = () => {
    setItemIdForUpdate(null);
  };

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const onSuccessSubmit = () => {
    //create emelent in list message
    console.log("onSuccessSubmit");
    let triggerDummyButton = document.getElementById(
      "triggerAlert"
    ) as HTMLButtonElement;
    triggerDummyButton.click();
  };

  const deleteItem = useMutation(() => deleteStorefront(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      dispatch(StorefrontList.deleteItemListStorefront(id + ""));
      updateState({
        sort: state.sort === "delete" ? "asc" : "delete",
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
    },
    onError(error, variables, context) {
      dispatch(StorefrontList.deleteItemListStorefront(id + ""));
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSelectedHasProduct, setIsSelectedHasProduct] = useState(false);
  let listStorefrontData: any = [];
  listStorefrontData = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );
  const checkSelectedItems = (id: any) => {
    const getStorefrontData = listStorefrontData.find((storefront: any) => {
      return storefront.id === id;
    });
    console.log(
      "getStorefrontData.numberOfProducts : ",
      getStorefrontData.numberOfProducts
    );
    if (
      !getStorefrontData.numberOfProducts &&
      getStorefrontData.numberOfProducts <= 0
    ) {
      setIsSelectedHasProduct(false);
    } else {
      setIsSelectedHasProduct(true);
    }
    setIsDeleteModalOpen(true);
  };

  const nav = useNavigate();
  const handleOnEdit = (id: any) => {
    //set to redux
    dispatch(ReduxStorefront.setStorefrontId(id.toString()));
    //nav
    const navigate: string =
      lastStep === ""
        ? `/storefront/update-storefront/${id.toString()}`
        : lastStep === storefrontStep.onStorefrontName
        ? "/storefront/create-storefront/storefront-name"
        : lastStep === storefrontStep.onSelectMarketplace
        ? "/storefront/create-storefront/select-marketplace"
        : lastStep === storefrontStep.onSummary
        ? "/storefront/create-storefront/summary"
        : "/storefront/create-storefront/storefront-name";
    console.log("navigate : ", navigate);
    console.log("id : ", id.toString());
    nav(navigate);
  };

  return (
    <>
      <div className="text-center">
        <button
          id="triggerAlert"
          data-bs-toggle="modal"
          data-bs-target="#modal_broadcast_alert"
          hidden
        ></button>
        <AlertModal />

        <div className="dropdown">
          {/* eslint-disable-next-line */}
          {!isTabletOrMobile && (
            <a
              className="btn btn-light btn-active-light-primary btn-sm"
              href="#"
              data-testid="dropdownMenuAction"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Common.Actions")}
              <KTSVG
                path="/media/icons/duotune/arrows/arr072.svg"
                className="svg-icon-5 m-0"
              />
            </a>
          )}
          {isTabletOrMobile && (
            <a
              href="#"
              data-testid="dropdownMenuAction"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen053.svg"
                className="svg-icon-1 m-0"
              />
            </a>
          )}

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              {/* eslint-disable-next-line */}
              <a
                data-testid="actionDeleteStorefront"
                className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
                data-kt-users-table-filter="delete_row"
                onClick={() => {
                  checkSelectedItems(id);
                }}
              >
                {t("StorefrontList.Column.Button.Menu.Delete")}
              </a>
            </li>
            <li>
              <button
                onClick={() => handleOnEdit(id)}
                className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
              >
                {t("Storefront.Column.Button.Edit")}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <ModalDeleteSelected
        data-testid="modalConfirmDeleteSelected"
        id="confirmDeleteSelected"
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={
          isSelectedHasProduct
            ? t("StorefrontList.Alert.Delete.Storefront.Title")
            : t("Warning")
        }
        body={
          isSelectedHasProduct
            ? t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontHasProduct"
              )
            : t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontDoesn'tHasProduct"
              )
        }
        confirmText={
          isSelectedHasProduct
            ? undefined
            : t("Storefront.Column.Button.Delete")
        }
        cancelText={
          isSelectedHasProduct
            ? t("Storefront.Button.Back")
            : t("Alert.Button.Cancel")
        }
        onConfirm={async () => {
          await deleteItem.mutateAsync();
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export { StorefrontActionsCell };
function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
