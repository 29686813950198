import React from "react";
import clsx from "clsx";
import "../css/uploadedvideo.css";

interface ButtonProps {
  /**
   * base color of the button with hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbs?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * light style color of the button with hover, defined with $theme-light-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnlg?:
    | "primary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark"
    | "light-primary"
    | "light-success"
    | "light-info"
    | "light-warning"
    | "light-danger"
    | "light-dark";
  /**
   * background style color of the button without hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbg?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  /**
   * className to be added to the button
   */
  cName?: string;
  /**
   * className to be added to the button
   */
  biIcon?: string;
  /**
   * props for the button
   */
  isMobile?: boolean;
  onClickFn?: any;
  handleLoadedMetadata?: any;
  isHidden?: boolean;
  isVideoButton?: boolean;
  vidSrc?: string;
  refId?:any;
  /**
   *
   * props for the button
   */
  [propName: string]: any;
}

/**
 * Primary UI component for user interaction
 */
function UploadVideoThumbnail({
  btnbs,
  btnlg,
  btnbg,
  cName,
  biIcon,
  isMobile,
  onClickFn,
  isHidden,
  isVideoButton,
  vidSrc,
  refId,
  ...props
}: ButtonProps) {
  return (
    <>
    <div style={{ height:"100%", width:"100%", backgroundColor: "rgba(0, 0, 0, 0.35)", borderRadius: "8px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
      <div className="rounded d-flex flex-column" style={{width: "100%", height: "100%"}} data-testid="video-uploaded-wrapper">
        <div
          className="image-input mw-lg-300px"
          data-kt-image-input="true"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_video"
        >
          <div
            className="btn bi bi-play-circle fs-2x w-300px position-absolute top-50 start-50 translate-middle"
            style={{ color: "white" }}
            
          ></div>
          <video
            data-testid={"MediaUploadVideo-"+props.datatestid}
            src={`${vidSrc}`}
            style={{width: "100%", height: "100%", borderRadius: "8px", objectFit: "fill" }}
            onLoadedMetadata={props.handleLoadedMetadata}
          ></video>
        </div>
        
        <div
          className="modal"
          tab-index="-1"
          id="kt_modal_video"
          data-testid="modal-video-preview"
        >
          <div className="modal-dialog">
            <div
              className="modal-content position-absolute bg-dark bg-opacity-75"
            >
              <div
                className="modal-header"
                style={{ padding: "0px", borderBottom: "0px" }}
              >
                <div
                  className="btn btn-icon btn-sm"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    className="svg-icon svg-icon-2x bi bi-arrow-left"
                    style={{ color: "white;" }}
                  ></i>
                </div>
                <div
                  className="btn btn-icon btn-sm"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  
                </div>
              </div>
              <video
                ref={refId}
                id="video-player"
                src={`${vidSrc}`}
                data-testid={"MediaUploadMediaPlayer-"+props.datatestid}
                className="mh-400px  h-lg-auto w-lg-auto" 
                controls
              ></video>
              <div
                className="modal-header justify-content-end"
                style={{ padding: "0px", borderBottom: "0px" }}
              ></div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

    </div>

  <div className="w-100" data-testid="button-delete-wrapper"></div>
  <div className={clsx("hw-100x button-delete-video semi-transparent-button-video border-top-zero-video", isMobile ? "button-div-height-mobile" : "button-div-height-desktop")} data-testid={"MediaUploadButtonDelete-"+props.datatestid}>
  <i className="bi bi-trash3 icon-center" style={{cursor: "pointer"}} onClick={onClickFn} 
                data-testid={"MediaUploadButtonDeleteIcon-"+props.datatestid}></i>
  </div>
  </>
  );
}

export default UploadVideoThumbnail;
