import firebase from "firebase/compat/app";
import db from ".";
import TemplateLanguage, { accountData } from "../../src/app/models/Language";
import Template from "../../src/app/models/Template";
import { createRef } from "./connection";
import { converter2 } from "./converter";
// import Language as Languagev2 from "../app/models/Language";
import Company from "../app/models/Company";
import * as lc from "../app/modules/localstorage/index";
import * as conn from "./connection";
import { UserCompanies } from "../app/models/User";

export const getListTemplateInteractive = async (companyID: string, client: string, language: string, limit?: number) => {
  let query = db
    .collection("templates")
    .where("client", "==", createRef("clients", client))
    .where("companies", "array-contains", createRef("company", companyID))
    .where("isActive", "==", true)
    .where("category", "==", "interactive") //TODO Filter interactive only
    .orderBy("priority")
    .orderBy("createdAt", "desc");

  // if (limit) {
  //   query = query.limit(limit);
  // }

  return await query
    .withConverter(converter2<Template>())
    .get()
    .then(async (snapshot) => {
      const templates: Template[] = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();

          const languageDoc = await doc.ref
            .collection("languages")
            .doc(language)
            .withConverter(converter2<TemplateLanguage>())
            .get()
            .then((doc) => {
              if (doc.exists) return { ...doc.data(), id: doc.id };
              return undefined;
            });

          return {
            ...data,
            id: doc.id,
            languagesModel: languageDoc ? [languageDoc] : [],
          };
        })
      );

      // console.log(
      //   `get language interactive message ${JSON.stringify(templates)}`
      // );
      return templates.filter((template: Template) => template.languagesModel && template.languagesModel.length > 0);
    });
};

export const getByAccount = async (clientId: string, accountId: string) => {
  console.log(`getByAccount ${accountId} client:${clientId}`);
  const query = db
    .collection("templates")
    .withConverter(converter2<Template>())
    .where("client", "==", createRef("clients", clientId))
    .where("accounts", "array-contains", createRef("account", accountId));

  return query
    .get()
    .then((snapshot) => {
      const templates = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as Template;
      });
      console.log(templates, "templates sor");
      return templates;
    })
    .catch((error) => {
      console.error(`query getByAccount ${error}`);
      return [] as Template[];
    });
};

export const getAccessibleTemplate = async (clientId: string, companyAccess: UserCompanies[]) => {
  let query = db
    .collection("templates")
    .withConverter(converter2<Template>())
    .where("isActive", "==", true)
    .where("client", "==", createRef("clients", clientId));

  console.log(`get accessible template `, companyAccess.length);

  const hasAllCompanyAccess = companyAccess.find((accessCompany) => accessCompany.id === "*");
  //if user does not have access to all company, get by company
  if (!hasAllCompanyAccess) {
    companyAccess.forEach((company) => {
      const ref = createRef("company", company.id);
      query = query.where("companies", "array-contains", ref);
    });
  }

  console.log(`get accessible template filter`, query);

  return query
    .get()
    .then((snapshot) => {
      const templates = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as Template;
      });
      return templates;
    })
    .catch((error) => {
      console.error(`query getByAccount ${error}`);
      return [] as Template[];
    });
};

export const fecthTemplateByNameAndClient = async (templateName: string, clientID: string) => {
  const client = createRef("clients", clientID);
  return await db
    .collection("templates")
    .where("client", "==", client)
    .where("isActive", "==", true)
    .where("templateName", "==", templateName)
    .withConverter(converter2<Template>())
    .limit(1)
    .get()
    .then((snaps) => {
      const templateList = snaps.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      console.log("templateList : " + templateList);
      return templateList;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr fetchTemplateByTemplateNameAndClient : " + error);
    });
};

export const getTemplateLanguages = async (templateID: string) => {
  return await db
    .collection("templates")
    .doc(templateID)
    .collection("languages")
    // .where("status","in",["APPROVED", "approved"])
    .withConverter(converter2<TemplateLanguage>())
    .get()
    .then((snapshot) => {
      const languages = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      return Promise.all(languages);
      // return languages;
    })
    .catch((err) => {
      console.log("Error set languages in template : ", err);
      return Promise.reject();
    });
};

export const get = async (templateID: string) =>
  await db
    .collection("templates")
    .doc(templateID)
    .withConverter(converter2<Template>())
    .get()
    .then((snapshot) => ({ id: snapshot.id, ...snapshot.data() }))
    .catch((err) => {
      console.log("Error getting documents (getDataTemplateByID)", err);
    });

export const createTemplate = async (templateData: any) => {
  let templateId;
  console.log(templateData, "Create New Template in Firebase xll");

  await db
    .collection("templates")
    .add(templateData)
    .then((ref) => {
      console.log(templateData, "isi template data rui");
      templateId = ref.id;
      return ref.id;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  return templateId;
};

export const updateTemplate = async (templateData: any, templateID: string) => {
  console.log("Check Data UPDATE :: " + JSON.stringify(templateData));
  let template: Template = Object.fromEntries(Object.entries(templateData as Template).filter(([key]) => !key.includes("id")));

  console.log("Check Data UPDATE 2 :: " + JSON.stringify(template));
  return await db
    .collection("templates")
    .doc(templateID)
    .set({ ...template }, { merge: false })
    // .update(template)
    .then((doc) => {
      console.log("Updated: " + doc);
    })
    .catch((err) => {
      console.log("Error updateTemplate : ", err);
    });
};

export const updateLanguage = async (templateId: string, languageID: string, languageData: any) => {
  console.log("Check Language Data : " + JSON.stringify(languageData));
  let language = languageData as TemplateLanguage;
  language = Object.fromEntries(Object.entries(language).filter(([key]) => !key.includes("id")));

  console.log(language, "language rui");
  console.log(languageID, "languageID rui");
  return await db
    .collection("templates")
    .doc(templateId)
    .collection("languages")
    .doc(languageID)
    .set(language)
    .then((doc) => {
      console.log("Updated: " + doc);
    })
    .catch((err) => {
      console.log("Error updateLanguage : ", err);
    });
};

export const addCampaignLanguage = async (templateId: string, languageID: string, languageData: any) => {
  console.log("running addCampaignLanguage() rpm")
  return await db
    .collection("templates")
    .doc(templateId)
    .collection("languages")
    .doc(languageID)
    // .set({...languageData})
    .set(languageData, { merge: false })
    .then((doc) => {
      console.log("Added: " + doc);
      return languageID;
    })
    .catch((err) => {
      console.log("Error set languages in template : ", err);
    });
};

export const getTemplateAccounts = async (templateID: string, languageID: string) => {
  if (!templateID || !languageID) return;

  return await db
    .collection("templates")
    .doc(templateID)
    .collection("languages")
    .doc(languageID)
    .collection("templateAccounts")
    .withConverter(converter2<accountData>())
    .get()
    .then((snapshot: any) => {
      console.log("komen templateID getTemplateAccounts : ", templateID);
      console.log("komen languageID getTemplateAccounts : ", languageID);
      console.log("masuk sini getTemplateAccounts");
      console.log(`komen snapshot `, snapshot);
      const templateAccounts = snapshot.docs.map((doc: any) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(templateAccounts, "templateAccounts komen");

      return templateAccounts;
    })
    .catch((err) => {
      console.log("Error get languages in template : ", err);
      return Promise.reject();
    });
};

export const updateTemplateAccounts = async (templateID: string, languageID: string, template: any) => {
  if (!templateID || !languageID || !template.id) return;
  const templateAccountID = template.id;
  delete template.id;
  return await db
    .collection("templates")
    .doc(templateID)
    .collection("languages")
    .doc(languageID)
    .collection("templateAccounts")
    .doc(templateAccountID)
    .set({ ...template }, { merge: true })
    .then((snaps) => {
      console.log(`success create or update template accounts`);
    })
    .catch((error: any) => {
      console.log("Error create or update template accounts` : " + error);
    });
};

export const createTemplateAccounts = async (templateID: string, languageID: string, template: any) => {
  if (!templateID || !languageID) return;

  return await db
    .collection("templates")
    .doc(templateID)
    .collection("languages")
    .doc(languageID)
    .collection("templateAccounts")
    .add({
      ...template,
    })
    .then((snaps) => {
      console.log(`success create or create template accounts`);
    })
    .catch((error: any) => {
      console.log("Error create or create template accounts` : " + error);
    });
};

export const deleteTemplatebyId = (id: string) => db.collection("templates").doc(id).update({ isActive: false, updatedAt: new Date() });
