/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { ID, KTSVG } from "../../../../../../../resources/helpers";
import { useQueryRequest } from "../../core/QueryRequestProvider";

type ActionMenu = {
  label: string;
  type: "link" | "button";
  state?: { [key: string]: any };
  to?: string;
  onClick?: () => void;
};

const print = (action: ActionMenu, key: string) => {
  if (action.type === "link") {
    return (
      <Link
        to={`${action.to}`}
        onClick={() => {
          document.getElementById(`toresume-${action.state?.id}`)?.click();
        }}
        state={action.state}
        className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
        key={key}
      >
        {action.label}
      </Link>
    );
  } else {
    return (
      <button
        className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
        onClick={action.onClick}
        key={key}
      >
        {action.label}
      </button>
    );
  }
};

type Props = {
  id: ID;
  indexId: number;
  name?: string;
};

const WABAAccountActionsCell: FC<Props> = ({ id, indexId, name }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { state, updateState } = useQueryRequest();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });

  const actions: ActionMenu[] = [
    {
      label: t("WABAAccountList.Action.Resume"),
      type: "link",
      to: `#`,
      state: { id, name },
    },
  ];

  return (
    <>
      <div className="dropdown">
        {isTabletOrMobile && (
          <button
            data-testid="dropdownMenuAction"
            type="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="btn btn-icon"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen053.svg"
              className="svg-icon-1 m-0"
            />
          </button>
        )}
        {!isTabletOrMobile && (
          <a
            className="btn btn-light btn-active-light-primary btn-sm"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("Common.Actions")}
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className="svg-icon-5 m-0"
            />
          </a>
        )}
        <div className="dropdown-menu">
          <h3 className="dropdown-header">{t("Common.Actions")}</h3>
          {actions.map((action, index) => print(action, `${index}`))}
        </div>
      </div>
    </>
  );
};

export { WABAAccountActionsCell };

function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
