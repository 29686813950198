import { format } from "date-fns";
import firebase from "firebase/compat/app";
import { FC } from "react";

type Props = {
  // lastUpdatedAt?: Long;
  lastUpdatedAt?: firebase.firestore.Timestamp | unknown;
};

// const dateToString = (date: firebase.firestore.Timestamp) => {
//   const newDate = new Date(date.toMillis());
//   const stringDate = format(newDate,"d MMM yyyy, h:mm aaa"
//     );
//   return stringDate;
// }

const CampaignMessageLastUpdatedAtCell: FC<Props> = ({ lastUpdatedAt }) => {
  if (lastUpdatedAt && lastUpdatedAt instanceof firebase.firestore.Timestamp) {
    return (
      <div className="text-gray-650 mb-1">
        {format(lastUpdatedAt.toDate(), "d MMM yyyy, h:mm aaa")}
      </div>
    );
  }
  if (lastUpdatedAt && typeof lastUpdatedAt === "string") {
    return <div className="text-gray-650 mb-1">{lastUpdatedAt}</div>;
  }
  return <div className="text-gray-650 mb-1">{""}</div>;
};

export { CampaignMessageLastUpdatedAtCell };
