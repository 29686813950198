import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  QueryConstraint,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
  collection,
  doc,
  documentId,
  endAt,
  getCountFromServer,
  getDocs,
  getDocsFromServer,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
  and,
  or,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { Maybe } from "yup/lib/types";
import { newDB } from "../db";
// import {getDataFromFirestore} from './getDataFromDB'

export type FirestoreFilterValue =
  | string
  | number
  | boolean
  | DocumentReference
  | string[]
  | null
  | undefined;

export type FirestoreQueryState<T> = {
  collection: string;
  clientID?: string;
  filters?: {
    field: keyof T;
    value: FirestoreFilterValue;
  }[];
  sorting: {
    field: keyof T;
    order: "asc" | "desc";
  };
  limit: number;
  currentPage: number;
  searchKey?: {
    field: keyof T;
    value: string;
    caseSensitive?: boolean;
  };
  priorityOrder?: boolean;
  refetchInterval?: number;
};

export type MapperFn<T> = (docs: QueryDocumentSnapshot<T, DocumentData>[]) => T[] | Promise<T[]>;

export function createDocRef(collection: string, id: string) {
  return doc(newDB, `${collection}/${id}`);
}

export function useFirestoreData<T>(props: FirestoreQueryState<T>, mapper?: MapperFn<T>) {
  const { collection } = props;
  const queryResult = useQuery(
    [`firestore-data-${collection}`, props],
    () => getDataFromDB(props, mapper),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchInterval: props.refetchInterval,
    }
  );
  // const toUncover = queryResult.data?.items;
  // toUncover?.map((item) => {
  //   // console.log(item, "item ikos");
  // });
  // const {data: queryData} = queryResult

  // const data = getFilteredData(props, queryData?.items)

  // return {
  //   ...queryResult,
  //   data: data
  // }
  return queryResult;
}

async function getDataFromDB<T>(state: FirestoreQueryState<T>, mapper?: MapperFn<T>) {
  const {
    clientID,
    collection: collName,
    sorting,
    limit: max,
    filters,
    currentPage,
    searchKey,
    priorityOrder,
  } = state;

  if (!clientID) {
    return { allCount: 0, items: [] };
  }

  const converterData: FirestoreDataConverter<T> = {
    toFirestore: (data) => data || {},
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
      const data = snapshot.data(options);
      return data as T;
    },
  };

  const countConstrains: QueryConstraint[] = [
    where("client", "==", createDocRef("clients", clientID)),
  ];

  if (filters) {
    const idFilter = filters.find((filter) => filter.field === "id");
    if (!!idFilter) {
      countConstrains.push(where(documentId(), "==", idFilter.value));
    } else {
      filters.forEach((filter) => {
        if (Array.isArray(filter.value)) {
          countConstrains.push(where(filter.field.toString(), "in", filter.value));
          return;
        } else {
          countConstrains.push(where(filter.field.toString(), "==", filter.value));
          return;
        }
      });
    }
  }

  if (searchKey && searchKey.value.length > 2) {
    if (searchKey.field === "id") {
      countConstrains.push(orderBy(documentId()));
    } else {
      countConstrains.push(orderBy(searchKey.field.toString(), "asc"));
    }
  }

  if (priorityOrder) {
    countConstrains.push(orderBy("orderPriorityNumber", "desc"));
  }

  if (searchKey?.field !== "id" || searchKey?.value?.length < 2) {
    countConstrains.push(orderBy(sorting.field.toString(), sorting.order));
  }

  if (searchKey && searchKey.value.length > 2) {
    if (searchKey?.field !== "id") {
      if (!!searchKey.caseSensitive) {
        countConstrains.push(
          where(searchKey.field.toString(), ">=", searchKey.value),
          where(searchKey.field.toString(), "<=", searchKey.value + "\uf8ff")
        );
      } else {
        countConstrains.push(
          where(searchKey.field.toString(), ">=", searchKey.value.toUpperCase()),
          where(searchKey.field.toString(), "<=", searchKey.value.toLowerCase() + "\uf8ff")
        );
      }
    } else {
      countConstrains.push(
        where(documentId(), ">=", searchKey.value.toUpperCase()),
        where(documentId(), "<=", searchKey.value.toLowerCase() + "\uf8ff")
      );
    }
  }

  try {
    const counterQuery = query(collection(newDB, collName), ...countConstrains);

    const dataConstrains = [...countConstrains];

    if (currentPage > 1) {
      const tempConstrain = [...countConstrains];

      tempConstrain.push(limit((currentPage - 1) * max));

      const tempDocs = (await getDocs(query(collection(newDB, collName), ...tempConstrain))).docs;

      dataConstrains.push(startAfter(tempDocs[tempDocs.length - 1]));
    }

    dataConstrains.push(limit(max));

    console.log(dataConstrains, "checkcontrainsg tvu")

    const dataQuery = query(
      collection(newDB, collName).withConverter(converterData),
      ...dataConstrains
    );

    const count = (await getCountFromServer(counterQuery)).data().count;
    // const data = await getDataFromFirestore<T>(state, mapper)
    const docs = (await getDocs(dataQuery)).docs;
    console.log(docs, "test 1 daqu");

    console.log(dataQuery, "daqu");

    let data: T[] = [];
    if (mapper) {
      const tempData = await mapper(docs);
      data = [...tempData];
    } else {
      const tempData = docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as T;
      });
      data = [...tempData];
    }

    //  ----- TODO : jgn dihapus, dipindah pakai mapper
    // if (searchKey && searchKey.field && searchKey.value !== "") {
    //   const regex = new RegExp(searchKey.value.toLowerCase(), "i");
    //   data = data?.filter((item) => {
    //     const fieldValue = item[searchKey.field] as unknown;
    //     if (typeof fieldValue === "string") {
    //       return regex.test(fieldValue.toLowerCase());
    //       // String(fieldValue)
    //       //   ?.toLowerCase()
    //       //   .includes(String(searchKey?.value)?.toLowerCase()) === true
    //     }
    //   });
    // }

    // if (sorting) {
    //   data = data?.sort((a: any, b: any) => {
    //     const fieldValueA =
    //       typeof a[sorting.field] === "string"
    //         ? a[sorting.field].toUpperCase()
    //         : a[sorting.field];
    //     const fieldValueB =
    //       typeof b[sorting.field] === "string"
    //         ? b[sorting.field].toUpperCase()
    //         : b[sorting.field];

    //     if (
    //       typeof fieldValueA === "string" &&
    //       typeof fieldValueB === "string"
    //     ) {
    //       return sorting.order === "desc"
    //         ? fieldValueB.localeCompare(fieldValueA)
    //         : fieldValueA.localeCompare(fieldValueB);
    //     } else {
    //       return sorting.order === "desc"
    //         ? fieldValueB - fieldValueA
    //         : fieldValueA - fieldValueB;
    //     }
    //   });
    // }

    // if (collName === "account") {
    //   data = data.map((item: any) => {
    //     let step = 0;
    //     let prop_check =
    //       item.type === "lazada"
    //         ? `${item.type}_sellerID`
    //         : `${item.type}_shopID`;
    //     if (item.hasOwnProperty(prop_check)) {
    //       step = 1;
    //     }
    //     if (
    //       (item.hasOwnProperty(prop_check) && item.isActive === true) ||
    //       (item.hasOwnProperty(prop_check) && item.connectedAt)
    //     ) {
    //       step = 2;
    //     }
    //     if (
    //       item.hasOwnProperty(prop_check) &&
    //       item.isActive === true &&
    //       item.connectedAt
    //     ) {
    //       step = 3;
    //     }
    //     item["status"] = step;
    //     return item;
    //   });
    //   if (sorting.field === "status") {
    //     data = data.sort((a: any, b: any) => {
    //       if (sorting.order === "desc") {
    //         return b.status - a.status;
    //       } else {
    //         return a.status - b.status;
    //       }
    //     });
    //   }
    //   if (sorting.field === "name") {
    //     data = data.sort((a: any, b: any) => {
    //       const nameA = a.name.toUpperCase();
    //       const nameB = b.name.toUpperCase();
    //       if (sorting.order === "desc") {
    //         return nameB.localeCompare(nameA);
    //       } else {
    //         return nameA.localeCompare(nameB);
    //       }
    //     });
    //   }
    // }
    return { allCount: count, items: data };
  } catch (error) {
    console.log(`Error get firestore data table ${collName}`, error);
    return { allCount: 0, items: [] };
  }
}

function getFilteredData<T>(state: FirestoreQueryState<T>, data?: T[]) {
  // if (!data) {
  //   return {items: [], allCount: 0}
  // }
  // const {
  //   sorting,
  //   limit: max,
  //   filters,
  //   currentPage,
  //   searchKey
  // } = state
  // const filtered = filterData<T>(data, filters)
  // const filteredBySearch = filterBySearch<T>(filtered, searchKey)
  // const sorted = sortData<T>(filteredBySearch, sorting)
  // const startIndex = (currentPage - 1) * max
  // const endIndex = startIndex + max;
  // const sliced = sorted.slice(startIndex, endIndex)
  // return {items: sliced, allCount: sorted.length}
}

function sortData<T>(
  data: T[],
  sorting: {
    field: keyof T;
    order: "desc" | "asc";
  }
) {
  const { field, order } = sorting;

  if (order === "asc") {
    return data.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
      if (typeof valueA === "number" && typeof valueB === "number") {
        return valueA - valueB;
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return valueA.toLocaleLowerCase() > valueB.toLocaleLowerCase() ? 1 : -1;
      }

      if (valueA instanceof Timestamp && valueB instanceof Timestamp) {
        return valueA.toDate().getTime() - valueB.toDate().getTime();
      }

      return 0;
    });
  }

  if (order === "desc") {
    return data.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
      if (typeof valueA === "number" && typeof valueB === "number") {
        return valueB - valueA;
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return valueB.toLocaleLowerCase() > valueA.toLocaleLowerCase() ? 1 : -1;
      }

      if (valueA instanceof Timestamp && valueB instanceof Timestamp) {
        return valueB.toDate().getTime() - valueA.toDate().getTime();
      }

      return 0;
    });
  }

  return data;
}

function filterBySearch<T>(
  data: T[],
  search?: {
    field: keyof T;
    value: string;
  }
) {
  if (!search || search.value === "") {
    return data;
  }

  const { field, value } = search;

  return data.filter((item) => {
    const itemValue = item[field];
    console.log(item, "item itval");
    console.log(value, "value itval");
    console.log(itemValue, "itval");
    if (typeof itemValue === "string") {
      return itemValue.includes(value);
    }
    return false;
  });
}

function filterData<T>(
  data: T[],
  filters?: {
    field: keyof T;
    value: string | number | DocumentReference;
  }[]
) {
  if (!filters) {
    return data;
  }
  return data.filter((item) =>
    filters.every((filter) => {
      const { value, field } = filter;
      const itemValue = item[field];
      if (value instanceof DocumentReference && itemValue instanceof DocumentReference) {
        return value.id === itemValue.id;
      }

      if (typeof value === "string" && typeof itemValue === "string") {
        return value === itemValue;
      }
      if (typeof value === "number" && typeof itemValue === "number") {
        return value === itemValue;
      }
    })
  );
}

export default useFirestoreData;
