
import Customer from "../../../../models/Customer";
import { ProfileDetailsEdit } from './cards/ProfileDetailsEdit'

export function Settings({customer} : {customer: Customer}) {
  const customerData: Customer = customer
  console.log("check customer : ", customerData.id);
  
  return (
    <>
    {/* <h1>settings</h1> */}
      {/* <ProfileDetails customer={customerData}/> */}
      <ProfileDetailsEdit customer={customerData}/>
      {/* <SignInMethod />
      <ConnectedAccounts />
      <EmailPreferences />
      <Notifications />
      <DeactivateAccount /> */}
    </>
  )
}
