import React, { useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import {
  checkIsActive,
  KTSVG,
  toAbsoluteUrl,
} from "../../../resources/helpers";
import { useLayout } from "../core";
import i18next from "../../../setup/translations/i18next";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import SVG from "react-inlinesvg";

type Props = {
  to: string;
  title: string;
  icon?: string;
  type?: string;
  bootstrapIcon?: string; //bootstrap icon
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  type,
  bootstrapIcon,
  fontIcon,
  hasBullet,
}) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // const isActive = checkIsActive(pathname, to);
  const [isActive, setIsActive] = useState<boolean>(
    checkIsActive(pathname, to)
  );
  const { config } = useLayout();
  const { aside } = config;
  console.log(isActive, "testing");

  const toggleActive = () => {
    if (isActive === true) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  return (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
      data-kt-menu-trigger="click"
    >
      <span
        className="menu-link"
        onClick={() => {
          isMobileView && toggleActive();
        }}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === "svg" && (
          <div className="h-30px w-30px p-1 d-flex align-items-center justify-content-center">
            <span className="menu-icon justify-content-center p-0 m-0">
              <SVG
                src={toAbsoluteUrl(icon)}
                className={clsx(icon, "text-muted fs-1 p-0 m-0")}
              />
            </span>
          </div>
        )}

        {bootstrapIcon && (
          <div className="h-30px w-30px p-1 d-flex align-items-center justify-content-center">
            <span className="menu-icon justify-content-center p-0 m-0">
              <i className={clsx(bootstrapIcon, "text-muted fs-2 p-0 m-0")} />
            </span>
          </div>
        )}

        {fontIcon && aside.menuIcon === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title" style={{ marginLeft: "10px", fontSize: "0.95rem" }}>
          {!type || type !== "lang"
            ? title
            : i18next.language === "en"
            ? t("Global.Languages.en")
            : t("Global.Languages.id")}
        </span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
