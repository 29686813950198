import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../resources/helpers";
import firebase from "firebase/compat/app";
import * as collabsService from "../../../../../db/serviceCollaborations";
import * as customerServ from "../../../../../db/serviceCustomer";
import * as userServ from "../../../../../db/serviceUser";
import * as roomService from "../../../../../db/serviceRoom";
import { getItemLC, LCName } from "../../../../modules/localstorage";
import * as lc from "../../../../modules/localstorage/index";
import { Collaboration, CollabsStatus, Room } from "../../../../models/Chat";
import { UserCompanies } from "../../../../models/User";
import Customer from "../../../../models/Customer";
import { getUserByID } from "../../../../../db";
import { getQueuedRooms } from "../../../../../db/serviceRoom";
import { removeFromNotificationsByCollabID } from "../../../../modules/notify/Notification/helper";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

/***
 * Get Data Customer In Queue
 */
const getCIQs = async (sort?: string, order?: string, search?: string, action?: string, lastId?: string, limit?: number): Promise<Array<Room>> => {
  // let CIQQueryResponse: CIQsQueryResponse;
  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort : " + sort);
  console.log("order : " + order);
  console.log("search : " + search);

  let sortBy: string = "lastInteractionAt";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "asc") orderBy = "asc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }

  // if (limit === undefined) limit = 10;

  const client = lc.getItemLC(lc.LCName.Client);
  //Create Ref Data Company
  const companyAccesses: UserCompanies[] = lc.getItemLC(lc.LCName.CompanyList) ?? [];
  if (companyAccesses.length <= 0) return [];

  //TODO proses get data
  // let ciqs = (await collabsService.fetchCIQ(client.id, companyAccesses)).filter((room) => {
  //   console.log(room, "ini room1 kbr");
  //   return (
  //     room?.status === "queue" ||
  //     (room?.status === "handled" &&
  //       room?.toUserModel?.every((userData: any) => {
  //         console.log(room, "ini room2 kbr");
  //         // console.log(room.lastCustomerInteractionAt > userData?.last_changed, "boolean kbr");
  //         console.log(userData?.last_changed, "last online kbr");
  //         console.log(room?.lastCustomerInteractionAt, "last cust kbr");
  //         if (room.lastCustomerInteractionAt) {

  //           return userData?.state !== undefined && userData?.state !== "online" && room?.lastCustomerInteractionAt > userData?.last_changed;
  //         }

  //       }))
  //   );
  // });

  // if (ciqs.length <= 0) {
  //   return [] as Room[];
  // }
  // return Promise.resolve(ciqs);
  const queuedRooms = await getQueuedRooms();
  console.log("IOFI queuedRooms", queuedRooms);
  return queuedRooms;
};

/***
 * Get Data from Rooms
 */
const getCIQr = async (sort?: string, order?: string, search?: string, action?: string, lastId?: string, limit?: number): Promise<Array<Room>> => {
  let sortBy: string = "id";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }

  const client = lc.getItemLC(lc.LCName.Client);
  //Create Ref Data Company
  const companyAccesses: UserCompanies[] = getItemLC(LCName.CompanyList);
  console.log("ArrComapny query in length " + companyAccesses.length);
  if (!companyAccesses || companyAccesses.length == 0) return [];

  //TODO proses get data
  const ciqr = await roomService.fetchAccessibleRoom(client.id, companyAccesses);
  console.log(`CIQR data : ${JSON.stringify(ciqr)}`);
  console.log("CIQR data : ", ciqr);

  if (ciqr.length <= 0) {
    console.log("CIQ does not exist...");
    return [] as (Collaboration & Room)[];
  }

  const dataRooms = await Promise.all(
    ciqr.map(async (room) => {
      if (room.collaboration) {
        let collab = await collabsService.getByID(room.collaboration.id);
        room.collaborationModel = collab;
      }
      return room;
    })
  );

  const datanew = await Promise.all(
    dataRooms.map(async (x) => {
      const collaborationData = await Promise.all(
        (x.collaborationModel?.toUserModel ?? []).map(async (a) => {
          return a.state !== "online";
        })
      );

      console.log(collaborationData, x.status, "yhgahhhh");

      return x.status === "queue" || (x.status === "handled" && collaborationData.every(Boolean));
    })
  );

  const filteredRooms = dataRooms.filter((_, index) => datanew[index]);

  return Promise.resolve(filteredRooms);
};

/***
 * End Of Get Data Customer In Queue
 */

const getCIQById = (id: ID): Promise<Collaboration | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Collaboration>>) => response.data)
    .then((response: Response<Collaboration>) => response.data);
};

/* Task Handle Chat Multi User -> update single collabs  */
const handledCollab = async (CIQId: ID, messageFailed: string, callback: any): Promise<void> => {
  console.log("==> masuk HandleCollab balezzz");
  console.log(CIQId, "CIQId balezzz");

  if (CIQId === undefined || CIQId === null) {
    console.log("masuk kalo CIQ ID kosong balezzz");
    return Promise.resolve();
  } else {
    console.log("masuk kalo CIQ ID ada balezzz");
    const getUser = lc.getItemLC(lc.LCName.User);
    let id = null;
    try {
      //Update Collaboration
      id = await collabsService.updateStatus(CIQId.toString(), CollabsStatus.handled, getUser.uid, messageFailed).then((response) => {
        console.log(response, "ini response balezzz");
        if (response) {
          callback(CIQId, true);
        } else {
          callback(CIQId, false);
        }
      });
      console.log(id, "id updateStatus balezzz");
      return Promise.resolve();
    } catch (error) {
      callback(CIQId, false);
      return Promise.reject();
    }
  }
};

/* Task Handle Chat Multi User -> update multi collabs  */
const handledCollabs = (CIQIds: Array<ID>, messageFailed: string, callback: any): Promise<void> => {
  console.log("==> masuk HandledCollabs balezzz");
  const getUser = lc.getItemLC(lc.LCName.User);
  const requests = collabsService.updateListCollabStatus(CIQIds, CollabsStatus.handled, getUser.uid, messageFailed, (responseId: string[]) => {
    //Callback message send
    callback(responseId);
  });
  return requests;
};

/* 
  Handle CIQ FIXED by KEN & aidanz 21 Maret. 
  I don't know how function above works. so we made a new function

  Used to handle CIQ when user click button "Handled"  in table
*/
export const handleCIQ = async (collaborationId: string, roomId: string) => {
  try {
    const userId = lc.getItemLC(lc.LCName.User)?.uid;
    removeFromNotificationsByCollabID(collaborationId);
    await collabsService.setStatusToHandled(collaborationId, userId);
    await roomService.setStatusToHandled(collaborationId, roomId, userId);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const handleCIQS = async (selectedIds: { collaborationIds: string; roomId: string }[]) => {
  for (const selectedId of selectedIds) {
    await handleCIQ(selectedId.collaborationIds, selectedId.roomId);
  }
};

// console.log("==> masuk updateStatys balezzz", id, status, userId, messageFailed);
// const userRef = conn.createRef("users", userId);
// //Check Collaboration
// try {
//   let arrUser = [userRef];
//   let getCollabByid = await fetchUserCollaborationByID(id);
//   console.log(getCollabByid, "getCollabByid balezzz");

//   if (getCollabByid) {
//     getCollabByid?.toUserData?.forEach((item) => {
//       const isExist = arrUser?.some((user) => user?.id === item?.id);
//       if (!isExist && arrUser?.length > 0) {
//         let refId = conn.createRef("users", item.id);
//         arrUser.push(refId);
//       }
//     });
//   }
//   const roomsSnapshot = await db
//     .collection("collaborations")
//     .doc(id)
//     .collection("rooms")
//     // .limit(1)
//     .get();

//   console.log(roomsSnapshot, "roomsSnapshot balezzz");
//   console.log(arrUser, "arruser 1 balezzz");

//   if (!roomsSnapshot.empty) {
//     roomsSnapshot.docs.map(async (x) => {
//       const roomDoc = x;
//       await roomDoc.ref.update({
//         status: status,
//         updatedAt: new Date(),
//         toUser: arrUser,
//       });
//     });
//   } else {
//     return null;
//   }

//   await db.collection("collaborations").doc(id).update({
//     status: status,
//     updatedAt: new Date(),
//     handleAt: new Date(),
//     handledByUser: userRef,
//     toUser: arrUser,
//   });
//   console.log(arrUser, "arrUser 2 balezzz");
//   if (!roomsSnapshot.empty) {
//     roomsSnapshot.docs.map(async (x) => {
//       await db.collection("collaborations").doc(id).collection("rooms").doc(x.id).update({
//         status: status,
//         updatedAt: new Date(),
//         // handleAt: new Date(),
//         // handledByUser: userRef,
//         toUser: arrUser,
//       });
//     });
//   }

//   return id;
// } catch (error) {
//   console.error("Error updating collaboration status:", error);
//   return null;
// }

export { getCIQs, getCIQr, handledCollab, handledCollabs, getCIQById };
