import firebase from "firebase/compat/app";
import "firebase/compat/database";
import db, { createRef, getUserByID } from ".";
import { Account } from "../app/models/Account";
import {
  Process,
  ProcessListAccount,
  ProcessOrder,
} from "../app/models/Process";
import { converter2 } from "./converter";
import { getAccountByID } from "./serviceAccount";
import { getOrderByID } from "./serviceOrder";

export const getByProcessId = async (processId: string) => {
  try {
    console.log("GET DATA FROM PROCESS", processId);
    const procDoc = await db
      .collection("processes")
      .doc(processId)
      .withConverter(converter2<Process>())
      .get();

    const processData = { ...procDoc.data(), id: procDoc.id };
    const orders = processData.orders;
    if (!orders) {
      return processData;
    }

    const ordersWithAccData = await Promise.all(
      orders.map(async (order) => {
        if (!order.account || !order.account.id) {
          return order;
        }
        const accData = await getAccountByID(order.account.id);
        if (!accData) {
          return order;
        }
        return { ...order, accountData: accData };
      })
    );

    return { ...processData, orders: ordersWithAccData };
  } catch (error) {
    console.log(error);
    console.error(`Error Processes Service getByProcessId ${error}`);
  }
};

export const incrementDownloadByIDAndAccID = async (
  processId: string,
  userID: string
) => {
  const usersDownload = {
    user: createRef("users", userID),
    downloadAt: firebase.firestore.Timestamp.now(),
  };
  return await db
    .collection("processes")
    .doc(processId)
    .set(
      {
        downloadCount: firebase.firestore.FieldValue.increment(1),
        usersDownload: firebase.firestore.FieldValue.arrayUnion(usersDownload),
      },
      { merge: true }
    );
};

export const getProcessOrderDataByProcessID = async (id: string) => {
  try {
    const procDoc = await db
      .collection("processes")
      .doc(id)
      .withConverter(converter2<Process>())
      .get();

    if (!procDoc || !procDoc.exists) {
      return undefined;
    }

    const procOrders = procDoc.data()?.orders;
    if (!procOrders) {
      return undefined;
    }

    const ordersData = await Promise.all(
      procOrders.map(async (order) => {
        if (!order.account || !order.account.id) {
          return order;
        }
        const accData = await getAccountByID(order.account.id);
        if (!accData) {
          return order;
        }

        if (!order.orderRef || !order.orderRef.id) {
          return { ...order, accountData: accData };
        }

        const orderData = await getOrderByID(order.orderRef.id);
        if (!orderData) {
          return { ...order, accountData: accData };
        }
        return { ...order, accountData: accData, orderData: orderData };
      })
    );

    return ordersData;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUsersDownloadDataByProcessID = async (id: string) => {
  try {
    const procDoc = await db
      .collection("processes")
      .doc(id)
      .withConverter(converter2<Process>())
      .get();

    if (!procDoc || !procDoc.exists) {
      return undefined;
    }

    const usersDownload = procDoc.data()?.usersDownload;
    if (!usersDownload) {
      return undefined;
    }

    const usersData = await Promise.all(
      usersDownload.map(async (item) => {
        if (!item.user || !item.user.id) {
          return item;
        }
        const userData = await getUserByID(item.user.id);
        if (!userData) {
          return item;
        }

        return { ...item, userData: userData };
      })
    );

    return usersData;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

// export const getFieldNameValue = async (filteredValue: string | string[]) => {
//   var field = [];
//   //collections "Processes" di db tu buat apa vid?
//   const procDoc = await db
//   .collection("processes")
//   .where("name", "!=", filteredValue)
//   .withConverter(converter2<Process>())
//   .get()
//   // .then((snap) => {
//   //   const processes = snap.docs.map((doc) => {
//   //     return {...doc.data(), id: doc.id};
//   //   })
//   //   field = processes;
//   // });

//   if (!procDoc || procDoc.empty) {
//     return undefined;
//   }

//   field = procDoc.docs.map((doc) => {
//     return doc.data()?.name;
//   });

//   return field;
// }

// export const updateProcess = async () => {
//   const procDoc = await db.collection("processes")
//   .where("name", "==", "\"\"")
//   .withConverter(converter2<Process>())
//   .get()

//   if (!procDoc || procDoc.empty) return undefined;

//   procDoc.forEach((doc) => {
//     console.log("Hasil query process tanpa nama:", doc.data()?.id);
//     doc.ref.update({
//       name: "Export Order",
//     });
//   });

//   return procDoc;
// }

// export const deleteSyncProcess = async () => {
//   const procDoc = await db.collection("processes")
//     .where("name", "==", "SyncronizeOrder")
//     // .delete()
//     .get()
//     .then((doc) => {
//       doc.forEach((item) => {
//         item.ref.delete();
//       })
//       // docs.map((item) => {
        
//         console.log("From Service Process Delete SUCCESS!");
//       // });
//     })
//     .catch((error: string) => {
//       console.error(
//         "Error deleted SyncOrder :",
//         error
//       );
//     });
  
//     return procDoc;
// };