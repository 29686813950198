import { FC } from "react";
// import { Storefront } from "../../core/_models";

type Props = {
  numberOfVariant: number;
};

const ProductNumberOfVariant: FC<Props> = ({ numberOfVariant = 0}) => {
  return <div className="text-gray-650 mb-1">{numberOfVariant} Variant</div>;
};

export { ProductNumberOfVariant };