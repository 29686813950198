import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import WABAAccount, { WABAAccountDetail } from "../../../../../../models/WABAccount";
import { WABAAccountActionsCell } from "./WABAAccountActionsCell";
import { WABAAccountCompanyProfileNameCell } from "./WABAAccountCompanyProfileName";
import { WABAAccountCustomHeader } from "./WABAAccountCustomHeader";
import { WABAAccountInfoCell } from "./WABAAccountInfoCell";
import { WABAAccountProgressCell } from "./WABAAccountProgressCell";
import { WABAAccountRegisteredPhoneNumberCell } from "./WABAAccountRegisteredPhoneNumberCell";
import { WABAAccountSelectionCell } from "./WABAAccountSelectionCell";
import { WABAAccountSelectionHeader } from "./WABAAccountSelectionHeader";
import { WABAAccountStatusCell } from "./WABAAccountStatusCell";
import CellText from "../../../../../../components/table/columns/CellString";

const WABAAccountsColumns: ReadonlyArray<Column<WABAAccountDetail>> = [
  // {
  //   Header: (props) => <WABAAccountSelectionHeader tableProps={props} />,
  //   id: "selection",
  //   Cell: ({ ...props }) => (
  //     <WABAAccountSelectionCell id={props.data[props.row.index].id} />
  //   ),
  // },
  // {
  //   Header: (props) => (
  //     <WABAAccountCustomHeader
  //       tableProps={props}
  //       title="WABAAccountList.Column.WABAAccount"
  //       className="text-center"
  //     />
  //   ),
  //   id: "client",
  //   Cell: ({ ...props }) =>
  //     <CellText value={props.data[props.row.index].clientModel?.clientName} />  
  // },
  {
    Header: (props) => (
      <WABAAccountCustomHeader
        tableProps={props}
        title="WABAAccountList.Column.CompanyProfileName"
        className="text-center"
      />
    ),
    id: "businessName",
    Cell: ({ ...props }) => (
      <WABAAccountInfoCell
        wabaAccount={props.data[props.row.index]}
        name={
          props.data[props.row.index].businessName
            ? props.data[props.row.index].businessName
            : ""
        }
      />
    ),
  },
  {
    Header: (props) => (
      <WABAAccountCustomHeader
        tableProps={props}
        title="WABAAccountList.Column.PhoneNumber"
        className="text-center"
      />
    ),
    id: "phoneNumber",
    Cell: ({ ...props }) => (
      <WABAAccountRegisteredPhoneNumberCell
        id={props.data[props.row.index].id}
        phoneNumbers={
          props.data[props.row.index].whatsappNumbers
            ? props.data[props.row.index].whatsappNumbers
            : []
        }
      />
    ),
  },
  // {
  //   Header: (props) => (
  //     <WABAAccountCustomHeader
  //       tableProps={props}
  //       title="WABAAccountList.Column.Status"
  //       className="w-25 text-center"
  //     />
  //   ),
  //   id: "status",
  //   Cell: ({ ...props }) => (
  //     <WABAAccountStatusCell
  //       status={props.data[props.row.index].accountReviewStatus}
  //     />
  //   ),
  // },
  {
    Header: (props) => (
      <WABAAccountCustomHeader
        tableProps={props}
        title="WABAAccountList.Column.SetupProgress"
        className="text-center"
      />
    ),
    id: "progress",
    Cell: ({ ...props }) => (
      <WABAAccountProgressCell
        id={props.data[props.row.index].id}
        progress={props.data[props.row.index].progress}
        lastStep={props.data[props.row.index].lastStep}
        templateVerification={
          props.data[props.row.index].templateVerification
            ? props.data[props.row.index].templateVerification
            : undefined
        }
        errorMessage={props.data[props.row.index].errorMessage || ""}
      />
    ),
  },
  {
    Header: (props) => (
      <WABAAccountCustomHeader
        tableProps={props}
        title="Common.Actions"
        className="text-end ps-2"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <WABAAccountActionsCell
        id={props.data[props.row.index].id}
        indexId={props.row.index}
      />
    ),
  },
];

export { WABAAccountsColumns };
