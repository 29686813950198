import InitialState, { UpdateChatListAction } from "./ChatRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Collaboration as HandledMessageListItem } from "../../../models/Chat";
import Message from "../../../models/Message";
import { BadgeItem } from "../../../models/Chat";

import { Timestamp } from "../../../../db";
import * as chatAct from "../../../../actions/chat";
import { createRef } from "../../../../db/connection";
import * as lc from "../../../modules/localstorage/index";
import { ChatType } from "../../../models/Message";
import { Order } from "../../../models/Order";

const initialState: InitialState = {
  chatList: [],
  selectedCollaboration: {
    lastCustomerInteractionAt: Timestamp.now(),
    id: "",
    fullName: "",
    profileName: "",
    image: "",
    lastMessages: "",
    unreadMessages: [],
    // lastInteractionAt: Timestamp.fromDate(new Date()),
    lastInteractionAt: Timestamp.now(),
    lastInteractionChannel: "",
    lastInteractionType: "",
    lastInteractionMessage: "",
    customer: undefined,
    customerModel: undefined,
    LastMessageModel: undefined,
    phoneNumber: undefined,
    idCollab: "",
    idRoom: "",
  },
  lastVisibleMessage: undefined,
  listMessage: [],
  selectedChat: "",
  selectedRoom: "",
  countTotalUnreadMessages: 0,
  countSelectedUnreadMessage: 0,
  replyToMessage: "",
  replyToMessageAudioDuration: "",

  //User Chat
  userListMessage: [],
  userChatList: [],
  countTotalUserUnreadMessages: 0,
  countSelectedUserUnreadMessage: 0,
  selectedUserChat: "",

  //All Chat
  allChatChatList: [],
  allChatSelectedCollaboration: {
    lastCustomerInteractionAt: Timestamp.now(),
    id: "",
    fullName: "",
    image: "",
    lastMessages: "",
    unreadMessages: [],
    // lastInteractionAt: Timestamp.fromDate(new Date()),
    lastInteractionAt: Timestamp.now(),
    lastInteractionChannel: "",
    lastInteractionType: "",
    lastInteractionMessage: "",
    customer: undefined,
    customerModel: undefined,
    LastMessageModel: undefined,
    lastUserInteractionAt: undefined,
    idCollab: "",
    idRoom: "",
  },
  allChatListMessage: [],
  allChatSelectedChat: "",
  allChatSelectedRoom: "",
  allChatCountTotalUnreadMessages: 0,
  allChatCountSelectedUnreadMessage: 0,
  allChatLastVisibleMessage: undefined,
  chatState: ChatType.mychat,
  chatInput: [],
  orderInitiateMessage: undefined,
};

export const ChatSlice = createSlice({
  name: UpdateChatListAction,
  initialState: initialState,
  reducers: {
    setChatList: (state, action: PayloadAction<HandledMessageListItem[]>) => {
      state.chatList = action.payload;
      let totalUnreadMessages = 0;
      action.payload.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i].unreadCount) {
              console.debug("Check Unread Message list Collaboration REDUX : " + unreadMessages[i].unreadCount + " - " + element.id);
              totalUnreadMessages += unreadMessages[i].unreadCount;
            }
          }
        }
      });

      state.countTotalUnreadMessages = totalUnreadMessages;

      // TO DO: Custom Sort Collaboration
      // Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = action.payload.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        if (Array.isArray(unreadCollab.unreadMessages)) {
          return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
        }
        return false;
      });

      // Read Collabs , lastInteraction Desc
      const readCollabs = action.payload.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        if (Array.isArray(readCollab.unreadMessages)) {
          return readCollab.unreadMessages.findIndex(isRead) < 0;
        }
        return false;
      });

      state.chatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // action.payload.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });
    },

    updateChatList: (state, action: PayloadAction<HandledMessageListItem>) => {
      let newChatList: HandledMessageListItem[] = [];

      // Set Company Model to New Handled CIQ
      const companyList = lc.getItemLC("CompanyList");
      const newCollab = { ...action.payload } as HandledMessageListItem;
      if (newCollab.company) {
        for (let i = 0; i < companyList.length; i++) {
          let collabCompany = companyList[i];
          if (companyList[i].id === newCollab.company.id) {
            newCollab.companyModel = collabCompany;
          }
        }
      }

      newChatList.push(newCollab);

      for (let index = 0; index < state.chatList.length; index++) {
        if (state.chatList[index].id === action.payload.id) {
          // console.log("Collab ID from Notif: "+state.chatList[index].id);
        } else {
          newChatList.push(state.chatList[index]);
          // console.log("Collab ID : "+state.chatList[index].id);
        }
      }

      // Sort New Chat List
      // TO DO : Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = newChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // TO DO : Read Collabs , lastInteraction Desc
      const readCollabs = newChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      newChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // newChatList.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });

      // setChatList(newChatList);
      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;

      newChatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        console.debug("Check Unread Message : ", unreadMessages);
        if (unreadMessages) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
              if (element.id === action.payload.id) {
                unreadSelectedChat += unreadMessages[i].unreadCount;
              }
            }
          }
        }
      });
      state.countSelectedUnreadMessage = unreadSelectedChat;
      state.chatList = newChatList;
      state.countTotalUnreadMessages = totalUnreadMessages;
    },

    updateCollabIncommingMessage: (state, action: PayloadAction<HandledMessageListItem>) => {
      let newChatList: HandledMessageListItem[] = [];

      // Set Company Model to New Handled CIQ
      const companyList = lc.getItemLC("CompanyList");
      const newCollab = { ...action.payload } as HandledMessageListItem;
      if (newCollab.company) {
        for (let i = 0; i < companyList.length; i++) {
          let collabCompany = companyList[i];
          if (companyList[i].id === newCollab.company.id) {
            newCollab.companyModel = collabCompany;
          }
        }
      }

      for (let index = 0; index < state.chatList.length; index++) {
        if (state.chatList[index].id === action.payload.id) {
          newChatList.push(newCollab);
        } else {
          newChatList.push(state.chatList[index]);
          // console.log("Collab ID : "+state.chatList[index].id);
        }
      }

      // Sort New Chat List
      // TO DO : Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = newChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // TO DO : Read Collabs , lastInteraction Desc
      const readCollabs = newChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      newChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // newChatList.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });

      // setChatList(newChatList);
      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;

      newChatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
              if (element.id === action.payload.id) {
                unreadSelectedChat += unreadMessages[i].unreadCount;
              }
            }
          }
        }
      });
      state.countSelectedUnreadMessage = unreadSelectedChat;
      state.chatList = newChatList;
      state.countTotalUnreadMessages = totalUnreadMessages;
    },

    setOrderInitiateMessage: (state, action: PayloadAction<Order | undefined>) => {
      state.orderInitiateMessage = action.payload;
    },

    updateUnreadMessage: (state, action: PayloadAction<string>) => {
      state.chatList.forEach((obj) => {
        if (obj.id === action.payload) {
          const readMessages = obj.unreadMessages;
          if (readMessages) {
            for (let i = 0; i < readMessages.length; i++) {
              readMessages[i].unreadCount = 0;
            }
            obj.unreadMessages = readMessages;
          } else {
            obj.unreadMessages = [];
          }
        }
      });

      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;
      state.chatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages.length; i++) {
            totalUnreadMessages += unreadMessages[i].unreadCount;
            if (element.id === action.payload) {
              unreadSelectedChat += unreadMessages[i].unreadCount;
            }
          }
        }
      });
      state.countSelectedUnreadMessage = unreadSelectedChat;
      state.countTotalUnreadMessages = totalUnreadMessages;
    },

    setListMessages: (state, action: PayloadAction<Message[]>) => {
      state.listMessage = action.payload;
    },

    setUserListMessages: (state, action: PayloadAction<Message[]>) => {
      state.userListMessage = action.payload;
    },

    setSelectedChat: (state, action: PayloadAction<string>) => {
      state.selectedChat = action.payload;
    },

    setChatInput: (state, action: PayloadAction<any>) => {
      state.chatInput = action.payload;
    },

    setSelectedRoom: (state, action: PayloadAction<string>) => {
      state.selectedRoom = action.payload;
    },

    setSelectedCollaboration: (state, action: PayloadAction<HandledMessageListItem | undefined>) => {
      state.selectedCollaboration = action.payload;
    },
    //Sampek sini

    addIncomingMessages: (state, action: PayloadAction<Message>) => {
      if (state.selectedChat === action.payload.collaboration?.id) {
        state.listMessage = [...state.listMessage, action.payload];
      }
    },

    deleteMessage: (state, action: PayloadAction<boolean>) => {
      state.listMessage = [];
      state.selectedChat = "";
      state.countTotalUnreadMessages = 0;
      state.chatList = [];
      state.userChatList = [];
      state.selectedCollaboration = {
        lastCustomerInteractionAt: Timestamp.now(),
        id: "",
        fullName: "",
        image: "",
        lastMessages: "",
        unreadMessages: [],
        // lastInteractionAt: Timestamp.fromDate(new Date()),
        lastInteractionAt: Timestamp.now(),
        lastInteractionChannel: "",
        lastInteractionType: "",
        lastInteractionMessage: "",
        customer: undefined,
        customerModel: undefined,
        LastMessageModel: undefined,
        lastUserInteractionAt: undefined,
        idCollab: "",
        idRoom: "",
      };
    },

    setLastVisibleMessage: (state, action: PayloadAction<any>) => {
      state.lastVisibleMessage = action.payload;
    },

    updateStatusListMessages: (state, action: PayloadAction<Message>) => {
      let tempListMessage: Message[] = state.listMessage.map((item) => {
        let tempMessage: Message;
        /***
         * Convert Object To Updatable
         */
        tempMessage = JSON.parse(JSON.stringify(item));
        tempMessage.collaboration = item.collaboration;
        tempMessage.user = item.user;
        tempMessage.customer = item.customer;
        tempMessage.createdAt = item.createdAt;
        tempMessage.updatedAt = item.updatedAt;
        tempMessage.submittedAt = item.submittedAt;
        tempMessage.deliveredAt = item.deliveredAt;
        tempMessage.readAt = item.readAt;
        tempMessage.failedAt = item.failedAt;
        if (tempMessage.id && tempMessage.id === action.payload.id) {
          tempMessage.status = action.payload.status;
          tempMessage.updatedAt = action.payload.updatedAt;
          tempMessage.submittedAt = action.payload.submittedAt;
          tempMessage.deliveredAt = action.payload.deliveredAt;
          tempMessage.readAt = action.payload.readAt;
        }
        // tempListMessage.push(tempMessage);
        return tempMessage;
      });
      state.listMessage = tempListMessage;
    },

    updateActiveListMessages: (state, action: PayloadAction<Message>) => {
      let activeListMessage: Message[] = state.listMessage.map((item) => {
        let activeMessage: Message;
        activeMessage = JSON.parse(JSON.stringify(item));
        activeMessage.collaboration = item.collaboration;
        activeMessage.user = item.user;
        activeMessage.customer = item.customer;
        activeMessage.createdAt = item.createdAt;
        activeMessage.updatedAt = item.updatedAt;
        if (activeMessage.id && activeMessage.id === action.payload.id) {
          activeMessage.status = action.payload.status;
        }
        return activeMessage;
      });
    },

    // ============================== User Chat Feature ============================== //
    setUserChatList: (state, action: PayloadAction<HandledMessageListItem[]>) => {
      // state.userChatList = action.payload;

      let totalUserUnreadMessages = 0;
      action.payload.forEach((element) => {
        const userUnreadMessages = element.unreadMessages;
        if (userUnreadMessages) {
          for (let i = 0; i < userUnreadMessages.length; i++) {
            totalUserUnreadMessages += userUnreadMessages[i].unreadCount;
          }
        }
      });

      state.countTotalUserUnreadMessages = totalUserUnreadMessages;

      // TO DO: Custom Sort Collaboration
      // Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = action.payload.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // Read Collabs , lastInteraction Desc
      const readCollabs = action.payload.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      state.userChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];
    },

    setSelectedUserChat: (state, action: PayloadAction<string>) => {
      state.selectedUserChat = action.payload;
    },

    updateSortUserChatList: (state) => {
      let newUserChatList: HandledMessageListItem[] = state.userChatList;
      // TO DO: Custom Sort Collaboration
      // Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = newUserChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });
      // Read Collabs , lastInteraction Desc
      const readCollabs = newUserChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      state.userChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];
    },

    // update Chat List if get a new message
    updateUserChatList: (state, action: PayloadAction<HandledMessageListItem>) => {
      let newUserChatList: HandledMessageListItem[] = [];
      newUserChatList.push(action.payload);

      for (let index = 0; index < state.userChatList.length; index++) {
        if (state.userChatList[index].id === action.payload.id) {
          // console.log("Collab ID from Notif: "+state.userChatList[index].id);
        } else {
          newUserChatList.push(state.userChatList[index]);
          // console.log("Collab ID : "+state.userChatList[index].id);
        }
      }

      // setChatList(newChatList);
      let totalUnreadMessages = 0;
      let unreadSelectedUserChat = 0;
      newUserChatList.forEach((element) => {
        const userUnreadMessages = element.unreadMessages;
        if (userUnreadMessages) {
          unreadSelectedUserChat = 0;
          for (let i = 0; i < userUnreadMessages.length; i++) {
            totalUnreadMessages += userUnreadMessages[i].unreadCount;
            if (element.id === action.payload.id) {
              unreadSelectedUserChat += userUnreadMessages[i].unreadCount;
            }
          }
        }
      });
      state.countSelectedUserUnreadMessage = unreadSelectedUserChat;
      // state.userChatList = newUserChatList;
      state.countTotalUserUnreadMessages = totalUnreadMessages;

      // TO DO: Custom Sort Collaboration
      // Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = newUserChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // Read Collabs , lastInteraction Desc
      const readCollabs = newUserChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      const userChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      state.userChatList = userChatList;
    },

    addUserIncomingMessages: (state, action: PayloadAction<Message>) => {
      if (state.selectedUserChat === action.payload.collaboration?.id) {
        state.userListMessage = [...state.userListMessage, action.payload];
      }
    },

    // Update User Unread Message
    updateUserUnreadMessage: (state, action: PayloadAction<string>) => {
      state.userChatList.forEach((obj) => {
        if (obj.id === action.payload) {
          const userReadMessages = obj.unreadMessages;
          if (userReadMessages) {
            for (let i = 0; i < userReadMessages.length; i++) {
              userReadMessages[i].unreadCount = 0;
            }
            obj.unreadMessages = userReadMessages;
          } else {
            obj.unreadMessages = [];
          }
        }
      });

      let totalUserUnreadMessages = 0;
      let userUnreadSelectedChat = 0;
      state.userChatList.forEach((element) => {
        const userUnreadMessages = element.unreadMessages;
        if (userUnreadMessages) {
          userUnreadSelectedChat = 0;
          for (let i = 0; i < userUnreadMessages.length; i++) {
            totalUserUnreadMessages += userUnreadMessages[i].unreadCount;
            if (element.id === action.payload) {
              userUnreadSelectedChat += userUnreadMessages[i].unreadCount;
            }
          }
        }
      });
      state.countSelectedUserUnreadMessage = userUnreadSelectedChat;
      state.countTotalUserUnreadMessages = totalUserUnreadMessages;
    },

    // ============================== All Chat Feature ============================== //
    setAllChatChatList: (state, action: PayloadAction<HandledMessageListItem[]>) => {
      let listAllChatList = action.payload;

      let listAllChatListAfter: HandledMessageListItem[] = [];
      for (let index = 0; index < listAllChatList.length; index++) {
        const collabs = listAllChatList[index];
        const checkToUser = collabs.toUser?.find((user) => {
          return user.id === lc.getItemLC("UID");
        });
        if (!checkToUser) {
          listAllChatListAfter.push(collabs);
        }
      }

      let totalUnreadMessages = 0;
      listAllChatListAfter.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i] && unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
            }
          }
        }
      });

      state.allChatCountTotalUnreadMessages = totalUnreadMessages;

      // TO DO: Custom Sort Collaboration
      // Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = listAllChatListAfter.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // Read Collabs , lastInteraction Desc
      const readCollabs = listAllChatListAfter.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      state.allChatChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // action.payload.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });
    },

    updateAllChatChatList: (state, action: PayloadAction<HandledMessageListItem>) => {
      let newChatList: HandledMessageListItem[] = [];

      // Set Company Model to New Handled CIQ
      const companyList = lc.getItemLC("CompanyList");
      const newCollab = { ...action.payload } as HandledMessageListItem;
      if (newCollab.company && companyList?.length > 0) {
        for (let i = 0; i < companyList?.length; i++) {
          let collabCompany = companyList[i];
          if (companyList[i].id === newCollab?.company.id) {
            newCollab.companyModel = collabCompany;
          }
        }
      }

      newChatList.push(newCollab);
      if (state?.allChatChatList?.length > 0) {
        for (let index = 0; index < state.allChatChatList?.length; index++) {
          if (state.allChatChatList[index].id === action.payload.id) {
            // console.log("Collab ID from Notif: "+state.allChatChatList[index].id);
          } else {
            newChatList.push(state.allChatChatList[index]);
            // console.log("Collab ID : "+state.allChatChatList[index].id);
          }
        }
      }

      let listAllChatList: HandledMessageListItem[] = [];
      if (newChatList?.length > 0) {
        for (let index = 0; index < newChatList?.length; index++) {
          const collabs = newChatList[index];
          const checkToUser = collabs.roomsModel?.find((user) => {
            return user.toUserData?.find((x: any) => {
              return x.id === lc.getItemLC("UID");
            });
          });
          const checkStatus = collabs.roomsModel?.find((x) => {
            return x.status === "queue";
          });
          if (!checkToUser && !checkStatus) {
            listAllChatList.push(collabs);
          }
        }
      }

      // Sort New Chat List
      // TO DO : Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = listAllChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // TO DO : Read Collabs , lastInteraction Desc
      const readCollabs = listAllChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      newChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // newChatList.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });

      // setChatList(newChatList);
      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;
      newChatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages?.length > 0) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages?.length; i++) {
            if (unreadMessages[i] && unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
              if (element.id === action.payload.id) {
                unreadSelectedChat += unreadMessages[i].unreadCount;
              }
            }
          }
        }
      });
      state.allChatCountSelectedUnreadMessage = unreadSelectedChat;
      state.allChatChatList = newChatList;
      state.allChatCountTotalUnreadMessages = totalUnreadMessages;
    },

    updateAllChatCollabIncommingMessage: (state, action: PayloadAction<HandledMessageListItem>) => {
      let newChatList: HandledMessageListItem[] = [];

      // Set Company Model to New Handled CIQ
      const companyList = lc.getItemLC("CompanyList");
      const newCollab = { ...action.payload } as HandledMessageListItem;
      if (newCollab.company) {
        for (let i = 0; i < companyList.length; i++) {
          let collabCompany = companyList[i];
          if (companyList[i].id === newCollab.company.id) {
            newCollab.companyModel = collabCompany;
          }
        }
      }

      const refUser = createRef("users", lc.getItemLC("UID"));

      for (let index = 0; index < state.allChatChatList.length; index++) {
        if (state.allChatChatList[index].id === action.payload.id) {
          newChatList.push(newCollab);
          // console.log("Collab ID from Notif: "+state.allChatChatList[index].id);
        } else {
          newChatList.push(state.allChatChatList[index]);
          // console.log("Collab ID : "+state.allChatChatList[index].id);
        }
      }

      let listAllChatList: HandledMessageListItem[] = [];
      for (let index = 0; index < newChatList.length; index++) {
        const collabs = newChatList[index];
        const checkToUser = collabs.toUser?.find((user) => {
          return user.id === lc.getItemLC("UID");
        });
        if (!checkToUser) {
          listAllChatList.push(collabs);
        }
      }

      // Sort New Chat List
      // TO DO : Unread Collabs , lastInteractionAt Asc
      const unreadCollabs = listAllChatList.filter(function (unreadCollab) {
        const hasUnread = (unread: BadgeItem) => unread.unreadCount > 0;
        return unreadCollab.unreadMessages.findIndex(hasUnread) > -1;
      });

      // TO DO : Read Collabs , lastInteraction Desc
      const readCollabs = listAllChatList.filter(function (readCollab) {
        const isRead = (unread: BadgeItem) => unread.unreadCount > 0;
        return readCollab.unreadMessages.findIndex(isRead) < 0;
      });

      newChatList = [...chatAct.sortCollaboration(unreadCollabs), ...chatAct.sortCollaboration(readCollabs)];

      // // TO DO : Sort Collaborations by Total Unread Messages Desc, lastInteractionAt Asc
      // newChatList.sort(function (a, b) {
      //   const isUnreadFn = (unread: BadgeItem) => unread.unreadCount > 0;
      //   const aHasUnread = a.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   const bHasUnread = b.unreadMessages.findIndex(isUnreadFn)>-1? 1 : 0;
      //   return bHasUnread - aHasUnread || a.lastInteractionAt.nanoseconds - b.lastInteractionAt.nanoseconds
      // });

      // setChatList(newChatList);
      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;
      newChatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i] && unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
              if (element.id === action.payload.id) {
                unreadSelectedChat += unreadMessages[i].unreadCount;
              }
            }
          }
        }
      });
      state.allChatCountSelectedUnreadMessage = unreadSelectedChat;
      state.allChatChatList = newChatList;
      state.allChatCountTotalUnreadMessages = totalUnreadMessages;
    },

    updateAllChatUnreadMessage: (state, action: PayloadAction<string>) => {
      state.allChatChatList.forEach((obj) => {
        if (obj.id === action.payload) {
          const readMessages = obj.unreadMessages;
          if (readMessages) {
            for (let i = 0; i < readMessages.length; i++) {
              readMessages[i].unreadCount = 0;
            }
            obj.unreadMessages = readMessages;
          } else {
            obj.unreadMessages = [];
          }
        }
      });

      let totalUnreadMessages = 0;
      let unreadSelectedChat = 0;
      state.allChatChatList.forEach((element) => {
        const unreadMessages = element.unreadMessages;
        if (unreadMessages) {
          unreadSelectedChat = 0;
          for (let i = 0; i < unreadMessages.length; i++) {
            if (unreadMessages[i] && unreadMessages[i].unreadCount) {
              totalUnreadMessages += unreadMessages[i].unreadCount;
              if (element.id === action.payload) {
                unreadSelectedChat += unreadMessages[i].unreadCount;
              }
            }
          }
        }
      });
      state.allChatCountSelectedUnreadMessage = unreadSelectedChat;
      state.allChatCountTotalUnreadMessages = totalUnreadMessages;
    },

    setAllChatListMessages: (state, action: PayloadAction<Message[]>) => {
      state.allChatListMessage = action.payload;
    },

    setAllChatSelectedChat: (state, action: PayloadAction<string>) => {
      state.allChatSelectedChat = action.payload;
    },

    setAllChatSelectedRoom: (state, action: PayloadAction<string>) => {
      state.allChatSelectedRoom = action.payload;
    },

    setAllChatSelectedCollaboration: (state, action: PayloadAction<HandledMessageListItem | undefined>) => {
      state.allChatSelectedCollaboration = action.payload;
    },

    addAllChatIncomingMessages: (state, action: PayloadAction<Message>) => {
      if (state.allChatSelectedChat === action.payload.collaboration?.id) {
        state.allChatListMessage = [...state.allChatListMessage, action.payload];
      }
    },

    deleteAllChatMessage: (state, action: PayloadAction<boolean>) => {
      // setListMessages([]);
      state.allChatListMessage = [];
      state.allChatSelectedChat = "";
      state.allChatCountTotalUnreadMessages = 0;
      state.allChatChatList = [];
      state.allChatSelectedCollaboration = {
        id: "",
        fullName: "",
        image: "",
        lastMessages: "",
        unreadMessages: [],
        // lastInteractionAt: Timestamp.fromDate(new Date()),
        lastInteractionAt: Timestamp.now(),
        lastInteractionChannel: "",
        lastInteractionType: "",
        lastInteractionMessage: "",
        customer: undefined,
        customerModel: undefined,
        LastMessageModel: undefined,
        lastUserInteractionAt: undefined,
        lastCustomerInteractionAt: Timestamp.now(),
        idCollab: "",
        idRoom: "",
      };
    },

    updateAllChatStatusListMessages: (state, action: PayloadAction<Message>) => {
      let tempListMessage: Message[] = state.allChatListMessage.map((item) => {
        let tempMessage: Message;
        /***
         * Convert Object To Updatable
         */
        tempMessage = JSON.parse(JSON.stringify(item));
        tempMessage.collaboration = item.collaboration;
        tempMessage.user = item.user;
        tempMessage.customer = item.customer;
        tempMessage.createdAt = item.createdAt;
        tempMessage.updatedAt = item.updatedAt;
        tempMessage.submittedAt = item.submittedAt;
        tempMessage.deliveredAt = item.deliveredAt;
        tempMessage.readAt = item.readAt;
        tempMessage.failedAt = item.failedAt;
        if (tempMessage.id && tempMessage.id === action.payload.id) {
          tempMessage.status = action.payload.status;
          tempMessage.updatedAt = action.payload.updatedAt;
          tempMessage.submittedAt = action.payload.submittedAt;
          tempMessage.deliveredAt = action.payload.deliveredAt;
          tempMessage.readAt = action.payload.readAt;
        }
        return tempMessage;
      });
      state.allChatListMessage = tempListMessage;
    },

    updateAllChatActiveListMessages: (state, action: PayloadAction<Message>) => {
      let activeListMessage: Message[] = state.allChatListMessage.map((item) => {
        let activeMessage: Message;
        /***
         * Convert Object To Updatable
         */
        activeMessage = JSON.parse(JSON.stringify(item));
        activeMessage.collaboration = item.collaboration;
        activeMessage.user = item.user;
        activeMessage.customer = item.customer;
        activeMessage.createdAt = item.createdAt;
        activeMessage.updatedAt = item.updatedAt;
        if (activeMessage.id && activeMessage.id === action.payload.id) {
          activeMessage.status = action.payload.status;
        }
        return activeMessage;
      });
    },

    setAllChatLastVisibleMessage: (state, action: PayloadAction<any>) => {
      state.allChatLastVisibleMessage = action.payload;
    },

    setReplyToMessage: (state, action: PayloadAction<any>) => {
      state.replyToMessage = action.payload;
    },

    setReplyToMessageAudioDuration: (state, action: PayloadAction<any>) => {
      state.replyToMessageAudioDuration = action.payload;
    },

    // ============================================================================== //

    setCurrentChatState: (state, action: PayloadAction<string>) => {
      state.chatState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChatList, //
  setListMessages, //
  setChatInput,
  setSelectedChat, //
  setSelectedRoom,
  setSelectedCollaboration,
  updateUnreadMessage, //
  addIncomingMessages, //
  deleteMessage, //
  setLastVisibleMessage,
  updateChatList, //
  updateStatusListMessages,
  updateActiveListMessages,
  setCurrentChatState,
  updateCollabIncommingMessage,
  setOrderInitiateMessage,

  // User Conversation
  setUserListMessages,
  setUserChatList,
  updateUserChatList,
  updateSortUserChatList,
  updateUserUnreadMessage,
  setSelectedUserChat,
  addUserIncomingMessages,

  //All Chat
  setAllChatChatList,
  updateAllChatChatList,
  updateAllChatUnreadMessage,
  setAllChatListMessages,
  setAllChatSelectedChat,
  setAllChatSelectedRoom,
  setAllChatSelectedCollaboration,
  addAllChatIncomingMessages,
  deleteAllChatMessage,
  updateAllChatStatusListMessages,
  updateAllChatActiveListMessages,
  setAllChatLastVisibleMessage,
  updateAllChatCollabIncommingMessage,
  setReplyToMessage,
  setReplyToMessageAudioDuration,
} = ChatSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default ChatSlice.reducer;
