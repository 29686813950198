import InitialState, { CampaignListAction } from "./CampaignListRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign as CampaignModel } from "../../../models/Campaign";

const initialState: InitialState = {
  listCampaign: [],
  countCampaign: 0,
  currentPageCampaign: 0,
  maxPageCampaign: 0,
  arrayCampaignPagination: [],
};

export const CampaignSlice = createSlice({
  name: CampaignListAction,
  initialState: initialState,
  reducers: {
    setListCampaign: (state, action: PayloadAction<CampaignModel[]>) => {
      state.listCampaign = action.payload;
      state.countCampaign = action.payload.length;
      if(action.payload.length/10 <= 6){
        let arr:string[] = [];
        for (let index = 0; index < Math.round(action.payload.length/10); index++) {
          arr.push((index+1)+"");
        }
        state.arrayCampaignPagination = arr;
      }else{
        state.arrayCampaignPagination = ["1","2","3","4","...",Math.round(action.payload.length/10)+""];
      }
    },

    setArrayCampaignPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayCampaignPagination = action.payload;
    },

    setCountCampaign: (state, action: PayloadAction<number>) => {
      state.countCampaign = action.payload;
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPageCampaign = action.payload;
    },

    setMaxPageCampaign: (state, action: PayloadAction<number>) => {
      state.maxPageCampaign = action.payload;
    },

    updateListCampaign: (state, action: PayloadAction<CampaignModel>) => {
      let newListCampaign:CampaignModel[] =[];
      const newCampaign = action.payload;
      newListCampaign.push(newCampaign);
      
      for (let index = 0; index < state.listCampaign.length; index++) {
        if(state.listCampaign[index].id === newCampaign.id){
          console.log("Collab ID from Notif: "+state.listCampaign[index].id);
        }else{
          newListCampaign.push(state.listCampaign[index]);
        }
      }
      state.listCampaign = newListCampaign;
      state.countCampaign = newListCampaign.length;
    },

    deleteItemListCampaign: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listCampaign.filter((data) => data.id !== action.payload);
      state.listCampaign = newDataCollab;
      state.countCampaign = newDataCollab.length;
    },
    
    cleanReduxCampaign: (state,action: PayloadAction<boolean>) => {
        state.listCampaign = [];
        state.countCampaign = 0;
        state.currentPageCampaign = 0;
        state.maxPageCampaign = 0;
      },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListCampaign,
  deleteItemListCampaign,
  updateListCampaign,
  setCurrentPage,
  setArrayCampaignPagination,
  setMaxPageCampaign,
  setCountCampaign,
  cleanReduxCampaign
} = CampaignSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignSlice.reducer;