
import Product from "../../../models/Product"

interface InitialState {
  listProduct: Product[];
  countProduct: number;
  currentPageProduct: number;
  maxPageProduct: number;
  arrayProductPagination: string[];
}
const ProductListAction: string = "ProductListAction";

export type unsubFN = () => void;

export default InitialState;
export { ProductListAction };
