import clsx from "clsx";
import { param } from "jquery";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactSelect from "react-select";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../../../db";
import { Collaboration } from "../../../../models/Chat";
import Language, { BodyParamLanguage } from "../../../../models/Language";
import Template from "../../../../models/Template";
import PreviewMessage from "../../../core/preview/PreviewMessage";
import { checkFile } from "../chat-util/ChatUtil";

const InteractiveTemplate: FC<{
  list: Template[];
  sendMessage: (templateLanguage: Language) => void;
  template?: Template;
  collaboration?: Collaboration;
}> = (props) => {
  const { template: templateProp, list, sendMessage, collaboration } = props;
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [template, setTemplate] = useState<Template | undefined>(templateProp);
  const modalId = templateProp ? templateProp.id : "Other";
  const defaultTemplateOption = templateProp ? { value: templateProp?.id, label: templateProp?.templateName } : undefined;

  const [bodyParameters, setBodyParameters] = useState<BodyParamLanguage[]>([]);
  const [headerParameters, setHeaderParameters] = useState<BodyParamLanguage[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileError, setFileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined } | undefined>(undefined);

  const handleSelectTemplate = (option: any) => {
    console.log(`handle selected template ${JSON.stringify(option)}`);
    const findSelected = list.find((t) => t.id === option.value);
    if (findSelected) {
      setTemplate(findSelected);
    }
  };

  const getCurrentLanguageModel = () => {
    if (!template) {
      return undefined;
    }

    const languageModel = {
      ...template!.languagesModel![0],
      bodyParam: bodyParameters,
    };
    if (template!.languagesModel![0].header) {
      languageModel.header = {
        ...template!.languagesModel![0].header!,
        headerParam: headerParameters,
      };
    }

    if (selectedFile) {
      const fileName = selectedFile.name;
      const previewURL = URL.createObjectURL(selectedFile);
      languageModel.header = {
        ...template!.languagesModel![0].header!,
        filename: fileName,
        headerURL: previewURL,
      };
    }

    return languageModel;
  };

  const uploadFile = async (companyID: string, messageType?: string) => {
    let fileURL = "";
    if (selectedFile && messageType) {
      // setPicture(event.target.files[0])
      const metadata = {
        contentType: selectedFile.type,
        fileName: selectedFile.name,
      };
      const uuid = uuidv4();
      const task = storage
        .ref(companyID + "/" + messageType + "s/chat/sent/" + uuid)
        .child(selectedFile.name)
        .put(selectedFile, metadata);
      await task
        .then(async (snapshot) => {
          return storage
            .ref(companyID + "/" + messageType + "s/chat/sent/" + uuid)
            .child(selectedFile.name)
            .getDownloadURL()
            .then((url) => {
              fileURL = url;
            });
        })
        .catch((error) => {
          console.log("error : " + error.message);
          alert(t("HC.Error.FailedUpload"));
          setFileError(t("HC.Error.FailedUpload"));
          setSelectedFile(undefined);
        });
    }
    return fileURL;
  };

  const handleSend = async () => {
    console.log(`handle send message`);
    setIsLoading(true);

    if (!collaboration || !collaboration.companyModel) {
      return;
    }

    if (fileError) {
      return;
    }

    const languageModel = getCurrentLanguageModel();
    if (!languageModel) {
      return;
    }

    if (selectedFile && languageModel.header) {
      const firebaseMediaURL = await uploadFile(collaboration.companyModel.id, languageModel.header.mediaType);
      if (!firebaseMediaURL || firebaseMediaURL === "") {
        alert(t("HC.Error.FailedUpload"));
        setFileError(t("HC.Error.FailedUpload"));
        setSelectedFile(undefined);
        return;
      }

      languageModel.header.headerURL = firebaseMediaURL;
    }

    sendMessage(languageModel);

    if (modalId === "Other") {
      setTemplate(undefined);
    }

    if (selectedFile) {
      setSelectedFile(undefined);
    }

    resetHeaderParams();
    resetBodyParams();

    setIsLoading(false);
  };

  const resetBodyParams = () => {
    if (!template) {
      setBodyParameters([]);
      return;
    }

    const templateLang: Language = template!.languagesModel![0];
    let params: BodyParamLanguage[] = [];

    if (templateLang.bodyParamCount && templateLang.bodyParamCount > 0 && templateLang.bodyParam) {
      params = templateLang.bodyParam;
    }

    if (collaboration) {
      params = getCustomerName(params, collaboration);
    }

    setBodyParameters(params);
  };

  const resetHeaderParams = () => {
    if (!template) {
      setHeaderParameters([]);
      return;
    }

    const templateLang: Language = template!.languagesModel![0];
    let params: BodyParamLanguage[] = [];

    if (templateLang.header && templateLang.header.headerParam) {
      params = templateLang.header.headerParam;
    }

    if (collaboration) {
      params = getCustomerName(params, collaboration);
    }

    setHeaderParameters(params);
  };

  const onChageHeaderParam = (value: string, parameter?: BodyParamLanguage) => {
    if (value === undefined || !parameter) return;

    if (parameter.paramType !== "customText") {
      setHeaderParameters((prev) =>
        prev.map((param) => {
          if (param.paramKey === parameter.paramKey) {
            return { ...param, textContent: value };
          }
          return param;
        })
      );

      return;
    }

    setHeaderParameters((prev) =>
      prev.map((param) => {
        if (param.paramName === parameter.paramName) {
          return { ...param, textContent: value };
        }
        return param;
      })
    );
  };

  const onChageBodyParam = (value: string, parameter?: BodyParamLanguage) => {
    if (value === undefined || !parameter) return;

    if (parameter.paramType !== "customText") {
      setBodyParameters((prev) =>
        prev.map((param) => {
          if (param.paramKey === parameter.paramKey) {
            return { ...param, textContent: value };
          }
          return param;
        })
      );

      return;
    }

    setBodyParameters((prev) =>
      prev.map((param) => {
        if (param.paramName === parameter.paramName) {
          return { ...param, textContent: value };
        }
        return param;
      })
    );
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
    const fileSize = file.size;
    const fileType = file.type;

    const arryFileType = fileType.split("/");
    if (arryFileType === undefined || arryFileType.length < 1 || arryFileType[0] === undefined) {
      alert(t("HC.Error.InvalidFiletype"));
      setFileError(t("HC.Error.InvalidFiletype"));
      setSelectedFile(undefined);
      return;
    } else {
      const [result, error, maxSize] = checkFile(arryFileType[0], arryFileType[1], fileSize);
      if (error !== undefined) {
        if (maxSize !== 0) {
          alert(t("HC.File." + error).replace("<<size>>", maxSize + " MB"));
          setFileError(t("HC.File." + error).replace("<<size>>", maxSize + " MB"));
          setSelectedFile(undefined);
        } else {
          alert(t("HC.Error." + error));
          setFileError(t("HC.Error." + error));
          setSelectedFile(undefined);
        }
        return;
      }
      // if (result !== undefined) {
      //   setmessageType(result.toString());
      // }
    }
  };

  const handleInputChange = (value: string, uniqueParam: { param: BodyParamLanguage; type: "body" | "header" | "both" }) => {
    const key = uniqueParam.param.paramKey;

    if ((value === undefined || value === "") && key) {
      setErrors((prev) => ({
        ...prev,
        [key]: t("Campaign.TemplateCategory.IM.Body.EmptyParameters.ErrorAlert"),
      }));
    } else if (key) {
      setErrors((prev) => {
        if (prev && prev[key]) {
          delete prev[key];
          return prev;
        }
        return prev;
      });
    }

    if (uniqueParam.type === "both") {
      onChageHeaderParam(value, uniqueParam.param);
      onChageBodyParam(value, uniqueParam.param);
      return;
    }
    if (uniqueParam.type === "header") {
      onChageHeaderParam(value, uniqueParam.param);
      return;
    }
    if (uniqueParam.type === "body") {
      onChageBodyParam(value, uniqueParam.param);
      return;
    }
  };

  useEffect(() => {
    // if (!template) return;
    console.log(`handle useEffect template`);

    resetHeaderParams();
    resetBodyParams();
    setErrors(undefined);
  }, [template, collaboration]);

  const uniqueParameters = getUniqueParameters(bodyParameters, headerParameters);

  const currentLangModel = getCurrentLanguageModel();
  const allowedExtentions = getAllowedExtensions(currentLangModel?.header?.type, currentLangModel?.header?.mediaType);
  // console.log(`currentErrors${modalId}`, errors);

  return (
    <div
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      id={`InteractiveTemplateModal${modalId}`}
      aria-hidden="true"
      key={`interactive-template-modal-${modalId}`}
      data-testid={`interactive-template-modal-${modalId}`}
    >
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("Campaign.TemplateCategory.InteractiveMessage.Header")}</h5>
          </div>
          <div className="modal-body ">
            <div
              className={clsx("d-flex w-100", {
                "flex-column": isTabletOrMobile,
              })}
            >
              <div className={clsx("", { "w-50 me-10": !isTabletOrMobile })}>
                <div className="form-group mb-10">
                  <label htmlFor="template-select" className="mb-2">
                    <b>{t("Campaign.Title.Template")}</b>
                  </label>
                  <ReactSelect
                    id="template-select"
                    options={list.map((t) => ({
                      value: t.id,
                      label: t.templateName,
                    }))}
                    isSearchable={true}
                    value={template ? { value: template.id, label: template.templateName } : { value: undefined, label: "Select..." }}
                    isDisabled={templateProp && true}
                    onChange={handleSelectTemplate}
                  />
                </div>

                {currentLangModel && currentLangModel.header?.type === "media" && !selectedFile && (
                  <div className="form-group">
                    <label htmlFor="template-select" className="mb-2">
                      <b>{t("Template.Info.FileName")}</b>
                    </label>
                    <div className="d-flex flex-fill bg-secondary search-toolbar mw-80 h-auto align-items-start image-input">
                      <div className="flex-grow-1 m-2 align-self-center">{currentLangModel.header.filename ?? ""}</div>
                    </div>
                  </div>
                )}

                {currentLangModel && allowedExtentions && !selectedFile && (
                  <label className="btn btn-sm btn-primary mt-1">
                    {t("Template.Button.Change")}
                    <input
                      // ref={attachmentRef}
                      id="template-media"
                      type="file"
                      name="template-media"
                      multiple={false}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          handleFileChange(event.target.files[0]);
                        }
                      }}
                      style={{ display: "none" }}
                      accept={allowedExtentions}
                    />
                  </label>
                )}

                {currentLangModel && allowedExtentions && selectedFile && (
                  <div className="form-group">
                    <label htmlFor="template-select" className="mb-2">
                      <b>{t("Template.Info.FileName")}</b>
                    </label>
                    <div className="d-flex flex-fill bg-secondary search-toolbar mw-80 h-auto align-items-center image-input">
                      <div className="flex-grow-1 m-2 align-self-center">{selectedFile.name ?? ""}</div>

                      <button type="button" className="btn btn-sm btn-icon-danger btn-text-danger px-3" onClick={() => setSelectedFile(undefined)}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  </div>
                )}

                {uniqueParameters.length > 0 && (
                  <>
                    <div className="mb-10 mt-10">
                      {uniqueParameters.map((uniqueParam) => {
                        const inputError = errors && uniqueParam.param.paramKey && errors[uniqueParam.param.paramKey];
                        return (
                          <div className="form-group mb-5" key={`${uniqueParam.param.paramName || uniqueParam.param.paramKey}`}>
                            <label className="mb-2" htmlFor={uniqueParam.param.paramName || uniqueParam.param.paramKey}>
                              <b>{uniqueParam.param.paramName || uniqueParam.param.paramValue}</b>
                            </label>
                            <input
                              type="text"
                              className={clsx("form-control", {
                                "is-invalid": inputError,
                              })}
                              id={uniqueParam.param.paramName || uniqueParam.param.paramKey}
                              value={uniqueParam.param.textContent ?? ""}
                              onChange={(e) => handleInputChange(e.target.value, uniqueParam)}
                            />

                            {inputError ? <span className="invalid-feedback">{inputError}</span> : null}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
              <div className={clsx("", { "w-50 ms-10": !isTabletOrMobile })} data-testid={`interactive-preview-${modalId}`}>
                <PreviewMessage template={currentLangModel} className="donotremove" />
              </div>
            </div>
            {/* Button group & warning */}
            <div className={clsx("d-flex", { "flex-column": isTabletOrMobile })}>
              <div
                className={clsx("text-warning d-flex flex-fill", {
                  "mb-10": isTabletOrMobile,
                })}
              >
                <i className="bi bi-exclamation-triangle text-warning fa-lg m-2" />
                {t("Contacts.Import.Warning")}
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-secondary me-3 flex-fill"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    if (modalId === "Other") {
                      setTemplate(undefined);
                      resetHeaderParams();
                      resetBodyParams();
                    }
                  }}
                >
                  {t("Alert.Button.Cancel")}
                </button>
                <button
                  className="btn btn-primary ms-3 flex-fill"
                  data-bs-dismiss="modal"
                  onClick={handleSend}
                  disabled={!collaboration || isLoading || (errors && Object.keys(errors).length > 0)}
                >
                  {isLoading ? t("Common.Pages.Loading") : t("HC.Button.SendFrom")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getAllowedExtensions(headerType?: string, mediaType?: string) {
  if (!headerType) {
    return undefined;
  }
  if (mediaType === "image") {
    return "image/png, image/jpg, image/jpeg";
  }
  if (mediaType === "video") {
    return "video/mp4";
  }
  if (mediaType === "document") {
    return "application/pdf";
  }
}

function getUniqueParameters(bodyParameters: BodyParamLanguage[], headerParameters: BodyParamLanguage[]) {
  let inputParameters: Array<{
    param: BodyParamLanguage;
    type: "body" | "header" | "both";
  }> = [];

  bodyParameters.forEach((param) => {
    if (param.paramType !== "customText") {
      const existedParam = inputParameters.find((p) => p.param.paramKey === param.paramKey);

      if (existedParam) return;

      inputParameters.push({ param, type: "body" });
      return;
    }

    const existedParam = inputParameters.find((p) => p.param.paramName === param.paramName);

    if (existedParam) return;

    inputParameters.push({ param, type: "body" });
  });

  headerParameters.forEach((param) => {
    if (param.paramType !== "customText") {
      const existedParam = inputParameters.find((p) => p.param.paramKey === param.paramKey);

      if (existedParam && existedParam.type === "header") return;
      if (existedParam && existedParam.type === "both") return;
      if (existedParam && existedParam.type === "body") {
        const filtered = inputParameters.filter((p) => p.param.paramKey === param.paramKey);
        inputParameters = [...filtered, { ...existedParam, type: "both" }];
        return;
      }

      inputParameters.push({ param, type: "header" });
    }

    const existedParam = inputParameters.find((p) => p.param.paramName === param.paramName);

    if (existedParam && existedParam.type === "header") return;
    if (existedParam && existedParam.type === "both") return;
    if (existedParam && existedParam.type === "body") {
      const filtered = inputParameters.filter((p) => p.param.paramName !== existedParam.param.paramName);
      inputParameters = [...filtered, { ...existedParam, type: "both" }];
      return;
    }

    inputParameters.push({ param, type: "header" });
  });

  return inputParameters;
}

function getCustomerName(params: BodyParamLanguage[], collaboration: Collaboration) {
  return params.map((param) => {
    const firstName = collaboration.customerModel?.firstName || collaboration.phoneNumber || "";
    const lastName = collaboration.customerModel?.lastName || collaboration.phoneNumber || "";
    const fullName = !collaboration.customerModel?.firstName && !collaboration.customerModel?.lastName ? firstName : `${firstName} ${lastName}`;

    if (param.paramKey === "customText") {
      return param;
    }

    if (param.paramKey === "customers.firstName") {
      return {
        ...param,
        textContent: firstName,
      };
    }
    if (param.paramKey === "customers.lastName") {
      return {
        ...param,
        textContent: lastName,
      };
    }
    if (param.paramKey === "customers.name") {
      return {
        ...param,
        textContent: fullName,
      };
    }

    return param;
  });
}

export default InteractiveTemplate;
