import React from "react"
import { requestSendEmailResetPassword } from "../../../../api/server/users";
import { createToken } from "../../../../db"

const sendEmailResetPassword = (userName: string, userId: string, email: string, callback: any) => {
  let token;
  //generate token, save to realtime database
  createToken(userId, "RP").then((res) => {
    token = res;
    console.log(token);

    //request sent email to server side with axios
    requestSendEmailResetPassword(userName,email,token) .then(async (response) => {
      if(response === 200){
        return true
      }else{
        return false
      }
    })
  })
}

const sendEmailResetPassword2 = async (userName: string, userId: string, email: string) => {
  let token;
  let result= false;
  //generate token, save to realtime database
  await createToken(userId, "RP").then(async (res) => {
    token = res;
    console.log(token);
  
    //request sent email to server side with axios
    await requestSendEmailResetPassword(userName,email,token).then(async (response) => {
      let resp = await response;
      console.log("response : " + resp)
      if(resp === 200 ){
        result= true
      }else{
        result=  false
      }
    })
  })
  return Promise.resolve(result);
}

export { sendEmailResetPassword, sendEmailResetPassword2 }