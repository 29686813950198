// import React from 'react';

// type modalRetryConfirmationProps={
//     show: boolean,
//     onClose: () => void,
//     onRetry: () => void,
// }

// const ModalRetryConfirmation: React.FC<modalRetryConfirmationProps> = ({ show, onClose, onRetry }) => {
//   return (
//     <>
//     <div className={`modal ${show ? 'show' : ''}`} tabIndex={-1} role="dialog">
//       <div className="modal-dialog" role="document">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title">Retry Modal</h5>
//             <button type="button" className="close" onClick={onClose} aria-label="Close">
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>
//           <div className="modal-body">
//             <p>Do you want to retry?</p>
//           </div>
//           <div className="modal-footer">
//             <button type="button" className="btn btn-secondary" onClick={onClose}>No</button>
//             <button type="button" className="btn btn-primary" onClick={onRetry}>Yes</button>
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };

// export default ModalRetryConfirmation;

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { boolean } from "yup";
import ModalConfirmationWithStateHandler from "./modalConfirmationWithStateHandler";

interface ModalProps {
  id: string;
  isModalOpen: boolean;
  isDirty?: boolean;

  title?: string;
  body?: any;

  confirmText?: string;
  onConfirm?: any;

  cancelText?: string;
  onCancel?: any;

  stateRetry?: any;
}

const ModalRetryConfirmation: FC<ModalProps> = (props) => {
  const { t } = useTranslation();



  const {
    id,
    isModalOpen,
    title = t("AddNewProduct.Modal.Error.Retry.Header"),
    body = "do you want to retry?",
    confirmText = "Retry",
    onConfirm,
    cancelText = t("AddNewProduct.Modal.Error.Retry.Cancel"),
    onCancel,
    stateRetry,
  } = props;

  let modalBody = body;
  if (stateRetry){
      const tempBody3 = (
        <div style={{textAlign : "justify"}}>
          <p>{t("AddNewProduct.Modal.Error.Retry.BodyFirst")}</p>
      
          {/* <p style={{color : "red"}}>Add Brand {stateRetry.BrandName}, It shows this Error {stateRetry.ErrorRes}.</p> */}
          <p style={{color : "red"}}>{t("AddNewProdcut.Modal.Error.Retry.BodySecond",{ BrandName: stateRetry.BrandName , ErrorRes: stateRetry.ErrorRes })}</p>
      
          <p>{t("AddNewProduct.Modal.Error.Retry.BodyThird")}</p>
        </div>
      );

//   const tempBody = {`asasasas`};
    modalBody = tempBody3;
    } 

  return (

    <ModalConfirmationWithStateHandler
      id={id}
      size="sm"
      title={title}
      body={modalBody}
      isModalOpen={isModalOpen}
    //   confirmText={confirmText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      onClose={onCancel}
      closeOption={true}
      alignFooterRight = {true}
    />
  );
};

export default ModalRetryConfirmation;
