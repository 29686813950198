export const DocumentMediaType: string[] = [
  // "plain",
  // "pdf",
  // "rtf",
  // "vnd.ms-powerpoint",
  // "msword",
  // "vnd.ms-excel",
  // "vnd.openxmlformats-officedocument.wordprocessingml.document",
  // "vnd.openxmlformats-officedocument.presentationml.presentation",
  // "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // "vnd.oasis.opendocument.spreadsheet",
  // "vnd.oasis.opendocument.presentation",
  "comma-separated-values",
  "csv"
];

export const checkFile = (FileType:string|undefined,FileExtensionType:string|undefined, FileSize:number ) => {
  let result: string|undefined = undefined;
  let error = undefined;
  let maxSize = 0;

  // Check File Type
  if(FileType === undefined || FileType === ""){
    error ="InvalidFiletype";
  }else if(FileType.toLocaleLowerCase() === "text"){
    result= "document";
  }else{
    error ="InvalidFiletype";
  }

  // Check File Size
  if (result !== undefined) {
    const sizeInMB = (FileSize / (1024*1024));
    if (result === "document" && sizeInMB >= 5) {
      maxSize = 5;
      error ="MaxSize";
    }
  }

  // Check File Extension
  if (result !== undefined) {
    if (result === "document" && (FileExtensionType !== undefined) && (DocumentMediaType.includes(FileExtensionType) === false)) {
      error="InvalidFiletype"
    }
  }

  return [result,error,maxSize]
};


export const formatSize = (bytes:number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
