import { Timestamp } from "firebase-admin/firestore";
import Message from "../../app/models/Message";
import Customer from "../../app/models/Customer";
import Friend from "../../app/models/Customer";
import { LCName, getItemLC, setItemLC } from "../../app/modules/localstorage";
import Notification from "../../app/models/Notification";

import db, { getUserByID } from "../../db";
import * as lc from "src/app/modules/localstorage/index";
import * as customerServ from "../../db/serviceCustomer";
import { createRef } from "../../db/connection";
import { UserCompanies } from "../../app/models/User";
import { Order } from "../../app/models/Order";
import { syncOrder } from "../server/order";
import store from "../../setup/redux/store";
import { runSync } from "../../app/modules/order/SyncSlice";
import { infoSyncOrder } from "../../app/modules/notify";
import { Timestamp as Tstamp } from "firebase/firestore";
import moment from "moment";

let companyAccesses: UserCompanies[] = (getItemLC(LCName.CompanyList) ??
  []) as UserCompanies[];

const id_client = lc.getItemLC(lc.LCName.Client)?.id;
const clientRef = createRef("clients", id_client);

let defaultLimit = 24;
let limitByEnv = process.env.REACT_APP_HOUR_LIMIT_NOTIFICATION;

const addNotificationLC = (notif: Notification) => {
  //ambil notif yang ada dari local storage
  let arrNotif: Array<Notification> = getItemLC(LCName.Notification) ?? [];

  //cek notif ada atau tidak
  const isNotificationExist = arrNotif.find(
    (existNotif) => existNotif.id === notif.id
  );

  if (!isNotificationExist) {
    //tambah notif baru ke array
    arrNotif.push(notif);

    //sort descending
    arrNotif = arrNotif.sort((a, b) =>
      new Date(a.createdAt.seconds * 1000) >
        new Date(b.createdAt.seconds * 1000)
        ? -1
        : 1
    );

    //mengupdate local storage
    setItemLC(LCName.isHaveNotif, true);
    setItemLC(LCName.Notification, arrNotif);
  }
};

function calculateStartDate(hoursAgo: number): Date {
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setHours(startDate.getHours() - hoursAgo);
  return startDate;
}

const oneHourAgo = Tstamp.fromDate(
  calculateStartDate(Number(limitByEnv ? limitByEnv : defaultLimit))
);
const now = Tstamp.fromDate(new Date());

export const newNotification = (
  notifType:
    | "CIQHandled"
    | "MultiUserMessage"
    | "newMessage"
    | "CIQMessage"
    | "newUserMessage"
    | "SyncOrder",
  doc: any,
  contact?: Customer,
  company?: any,
  newMessage?: Message
  // syncOrder?: any
) => {
  let newNotif: {
    notifType: string;
    id: string;
    state?: string;
    channel?: string;
    companyID?: string;
    companyAvatar?: string;
    companyName?: string;
    createdAt?: Timestamp;
    phoneNumber?: string;
    collaborationID?: string;
    profileName?: string;
    avatar?: string;
    name?: string;
    messageID?: string;
    client?: string;
    accounts?: string[];
    startTime?: string;
    endTime?: string;
    syncRes?: any;
  } = {
    notifType,
    state: "primary",
    id: doc.id,
    channel: notifType !== "SyncOrder" && doc.data().channel,
    companyID: notifType !== "SyncOrder" && doc.data().company.id,
    createdAt: notifType !== "SyncOrder" && doc.data().createdAt,
    phoneNumber: notifType !== "SyncOrder" && doc.data().customerPhoneNumber,
    profileName: notifType !== "SyncOrder" && doc.data().customerProfileName,
    companyAvatar: company?.avatar,
    companyName: company?.companyName,
  };
  const collaboration = notifType !== "SyncOrder" && doc.data().collaboration;
  console.log(doc.data(), "data upix")
  if (collaboration) {
    newNotif = {
      ...newNotif,
      collaborationID: collaboration.id,
    };
  }

  if (collaboration?.phoneNumber) {
    newNotif.phoneNumber = collaboration.phoneNumber;
  }

  if (contact) {
    newNotif = {
      ...newNotif,
      avatar: contact.avatar!,
      name: contact.lastName
        ? contact.firstName! + " " + contact.lastName
        : contact.firstName!,
    };
  }

  if (newMessage) {
    if (newMessage.collaboration) {
      newNotif = {
        ...newNotif,
        messageID: newMessage.id,
        collaborationID: newMessage?.collaboration?.id,
        createdAt: newMessage.createdAt,
        // createdAt: doc.data().createdAt
      };
    } else {
      newNotif = {
        ...newNotif,
        messageID: newMessage.id,
        collaborationID: newMessage?.room?.parent?.parent?.id,
        createdAt: newMessage.createdAt,
        // createdAt: doc.data().createdAt
      };
    }
  }

  if (notifType === "SyncOrder") {
    const startTimeInSeconds: number = doc.data().startTime;
    const endTimeInSeconds: number = doc.data().endTime;

    const startTime = new Date(startTimeInSeconds * 1000).toLocaleString();
    const endTime = new Date(endTimeInSeconds * 1000).toLocaleString();

    newNotif = {
      ...newNotif,
      startTime: startTime,
      endTime: endTime,
      syncRes: doc.data().orders,
    };
  }

  return newNotif as Notification;
};

export const subsToUnhandledCollabs = (
  userID: string,
  companyID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "queue")
    .where("notifiedAt", "==", null)
    .where("type", "==", "collaborations")
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        const batch = db.batch();

        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            batch.update(doc.ref, { notifiedAt: new Date() });
            const dataContact = doc.data().customer as Customer;
            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "CIQMessage",
              doc,
              dataContact,
              company,
              undefined
            );
            addNotificationLC(newNotif);

            const HALF_A_MINUTE = 30 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              HALF_A_MINUTE
            ) {
              onNewData(newNotif, undefined);
            }
          });
          await Promise.all(updates);
          await batch.commit();
        } catch (error) {
          console.error("Error processing snapshot:", error);
        }
      },
      (error) => {
        console.error(`Error observe subsToUnhandledCollabs: ${error}`);
      }
    );
};

export const subsToHanldeCollabsByAnotherUser = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "handled")
    .where("notifiedAt", "==", null)
    .where("type", "==", "collaborations")
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        const batch = db.batch();

        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            batch.update(doc.ref, { notifiedAt: new Date() });
            const dataContact = doc.data().customer as Customer;
            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "CIQHandled",
              doc,
              dataContact,
              company,
              undefined
            );
            addNotificationLC(newNotif);

            const TEN_MINUTES = 10 * 60 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              TEN_MINUTES
            ) {
              onNewData(newNotif, undefined);
            }
          });
          await Promise.all(updates);
          await batch.commit();
        } catch (error) {
          console.error(`Error in subsToHanldeCollabsByAnotherUser: ${error}`);
        }
      },
      (error) => {
        console.error(
          `Error observe subsToHanldeCollabsByAnotherUser: ${error}`
        );
      }
    );
};

export const subsToNotificationMessageCollabByAnotherUser = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "outbound")
    .where("notifiedAt", "==", null)
    .where("type", "==", "messages")
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        const batch = db.batch();

        try {
          const updates = querySnapshot?.docs?.map(async (doc) => {
            batch.update(doc.ref, { notifiedAt: new Date() });
            const dataContact = doc.data()?.customer?.id !== undefined ? await customerServ.get(doc.data().customer?.id) : undefined
            const newMessage = await createRef(
              "messages",
              doc.data().messages?.id
            )
              .get()
              .then((message) => {
                const resultMessage = message.data() as any;
                resultMessage.id = message.id;
                return resultMessage;
              });

            const company = companyAccesses.find(
              (data) => data?.id === doc?.data()?.company?.id
            );
            const newNotif = newNotification(
              "MultiUserMessage",
              doc,
              dataContact,
              company,
              newMessage
            );
            addNotificationLC(newNotif);

            const TEN_MINUTES = 10 * 60 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              TEN_MINUTES
            ) {
              onNewData(newNotif, newMessage);
            }
          });
          await Promise.all(updates);
          await batch.commit();
        } catch (error) {
          console.error(
            `Error in subsToNotificationMessageCollabByAnotherUser: ${error}`
          );
        }
      },
      (error) => {
        console.error(
          `Error observe subsToNotificationMessageCollabByAnotherUser: ${error}`
        );
      }
    );
};

export const subsToNewMessageCollaboration = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "inbound")
    .where("notifiedAt", "==", null)
    .where("type", "==", "messages")
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        const batch = db.batch();

        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            batch.update(doc.ref, { notifiedAt: new Date() });
            const dataContact = await customerServ.get(doc.data().customer?.id);

            let newMessage;
            if (doc.data().messages?.id) {
              newMessage = await createRef("messages", doc.data().message.id)
                .get()
                .then((message) => {
                  const resultMessage = message.data() as any;
                  resultMessage.id = message.id;
                  return resultMessage;
                });
            } else {
              const messageQuerySnapshot = await db
                .collection("messages")
                .where("room", "==", doc.data().room)
                .orderBy("createdAt", "desc")
                .limit(1)
                .get();
              const docId = messageQuerySnapshot.docs[0]?.id;
              newMessage = await createRef("messages", docId)
                .get()
                .then((message) => {
                  const resultMessage = message.data() as any;
                  resultMessage.id = message.id;
                  return resultMessage;
                });
            }

            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "newMessage",
              doc,
              dataContact,
              company,
              newMessage
            );
            addNotificationLC(newNotif);
            onNewData(newNotif, newMessage);
          });
          await Promise.all(updates);
          await batch.commit();
        } catch (error) {
          console.error(`Error in subsToNewMessageCollaboration: ${error}`);
        }
      },
      (error) => {
        console.error(`Error observe subsToNewMessageCollaboration: ${error}`);
      }
    );
};

export const subsToFriendListMessages = (
  userId: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  const userRef = createRef("users", userId);
  return db
    .collection("messages")
    .where("toUser", "==", userRef)
    .where("notifiedAt", "==", null)
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        const batch = db.batch();

        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            batch.update(doc.ref, { notifiedAt: new Date() });

            const userId = doc.data().fromUser.id;
            const user = await getUserByID(userId);
            if (!user) return;

            const dataUser = {
              id: user.id,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            };

            const newMessage = { ...doc.data(), id: doc.id } as any;
            const notif = newNotification(
              "newUserMessage",
              doc,
              dataUser,
              undefined,
              newMessage
            );
            addNotificationLC(notif);
          });

          await Promise.all(updates);
          await batch.commit();
        } catch (error) {
          console.error(`Error in subsToFriendListMessages: ${error}`);
        }
      },
      (error) => {
        console.error(`Error observe subsToFriendListMessages: ${error}`);
      }
    );
};

export const subsToNotificationSyncOrderMarketplace = (
  userId: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userId)
    .collection("notifications")
    .where("type", "==", "syncOrder")
    .where("notifiedAt", "==", null)
    .where("createdAt", ">=", oneHourAgo)
    .orderBy("createdAt", "desc")
    .onSnapshot(async (querySnapshot) => {
      const batch = db.batch();

      try {
        const updates = querySnapshot.docs.map(async (doc) => {
          if (!doc.exists) return;

          batch.update(doc.ref, { notifiedAt: new Date() });

          const syncResult = doc.data().syncRes;
          const newNotif = newNotification(
            "SyncOrder",
            doc,
            undefined,
            null,
            syncResult
          );
          addNotificationLC(newNotif);

          onNewData(newNotif, syncResult);
        });

        await Promise.all(updates);
        await batch.commit();
      } catch (error) {
        console.error(
          `Error in subsToNotificationSyncOrderMarketplace: ${error}`
        );
      }
    });
};

export const listSubsToUnhandledCollabs = (
  userID: string,
  companyID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  console.log(
    Number(newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit),
    "cheo fux"
  );
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "queue")
    .where("type", "==", "collaborations")
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            const dataContact = doc.data().customer as Customer;
            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "CIQMessage",
              doc,
              dataContact,
              company,
              undefined
            );
            const HALF_A_MINUTE = 30 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              HALF_A_MINUTE
            ) {
              onNewData(newNotif, undefined);
            }
          });
          await Promise.all(updates);
        } catch (error) {
          console.error("Error processing snapshot:", error);
        }
      },
      (error) => {
        console.error(`Error observe subsToUnhandledCollabs: ${error}`);
      }
    );
};

export const listSubsToHanldeCollabsByAnotherUser = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "handled")
    .where("type", "==", "collaborations")
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            const dataContact = doc.data().customer as Customer;
            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "CIQHandled",
              doc,
              dataContact,
              company,
              undefined
            );
            const TEN_MINUTES = 10 * 60 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              TEN_MINUTES
            ) {
              onNewData(newNotif, undefined);
            }
          });
          await Promise.all(updates);
        } catch (error) {
          console.error(`Error in subsToHanldeCollabsByAnotherUser: ${error}`);
        }
      },
      (error) => {
        console.error(
          `Error observe subsToHanldeCollabsByAnotherUser: ${error}`
        );
      }
    );
};

export const listSubsToNotificationMessageCollabByAnotherUser = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  console.log(userID, "check userID mess")
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "outbound")
    .where("type", "==", "messages")
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        try {
          const updates = querySnapshot?.docs?.map(async (doc) => {
            const dataContact = doc.data()?.customer?.id !== undefined ? await customerServ.get(doc.data().customer?.id) : undefined
            const newMessage = await createRef(
              "messages",
              doc.data().messages?.id
            )
              .get()
              .then((message) => {
                const resultMessage = message.data() as any;
                resultMessage.id = message.id;
                return resultMessage;
              });

            const company = companyAccesses.find(
              (data) => data?.id === doc?.data().company?.id
            );
            const newNotif = newNotification(
              "MultiUserMessage",
              doc,
              dataContact,
              company,
              newMessage
            );

            const TEN_MINUTES = 10 * 60 * 1000;
            if (
              new Date().getTime() -
              new Date(doc.data().createdAt.seconds * 1000).getTime() <
              TEN_MINUTES
            ) {
              onNewData(newNotif, newMessage);
            }
          });
          await Promise.all(updates);
        } catch (error) {
          console.error(
            `Error in subsToNotificationMessageCollabByAnotherUser: ${error}`
          );
        }
      },
      (error) => {
        console.error(
          `Error observe subsToNotificationMessageCollabByAnotherUser: ${error}`
        );
      }
    );
};

export const listSubsToNewMessageCollaboration = (
  userID: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userID)
    .collection("notifications")
    .where("status", "==", "inbound")
    .where("type", "==", "messages")
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            const dataContact = await customerServ.get(doc.data().customer?.id);

            let newMessage;
            if (doc.data().messages?.id) {
              newMessage = await createRef("messages", doc.data().message.id)
                .get()
                .then((message) => {
                  const resultMessage = message.data() as any;
                  resultMessage.id = message.id;
                  return resultMessage;
                });
            } else {
              const messageQuerySnapshot = await db
                .collection("messages")
                .where("room", "==", doc.data().room)
                .orderBy("createdAt", "desc")
                .limit(1)
                .get();
              const docId = messageQuerySnapshot.docs[0]?.id;
              newMessage = await createRef("messages", docId)
                .get()
                .then((message) => {
                  const resultMessage = message.data() as any;
                  resultMessage.id = message.id;
                  return resultMessage;
                });
            }

            const company = companyAccesses.find(
              (data) => data.id === doc.data().company.id
            );
            const newNotif = newNotification(
              "newMessage",
              doc,
              dataContact,
              company,
              newMessage
            );
            onNewData(newNotif, newMessage);
          });
          await Promise.all(updates);
        } catch (error) {
          console.error(`Error in subsToNewMessageCollaboration: ${error}`);
        }
      },
      (error) => {
        console.error(`Error observe subsToNewMessageCollaboration: ${error}`);
      }
    );
};

export const listSubsToFriendListMessages = (
  userId: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  const userRef = createRef("users", userId);
  return db
    .collection("messages")
    .where("toUser", "==", userRef)
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (querySnapshot) => {
        try {
          const updates = querySnapshot.docs.map(async (doc) => {
            const userId = doc.data().fromUser.id;
            const user = await getUserByID(userId);
            if (!user) return;

            const dataUser = {
              id: user.id,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            };

            const newMessage = { ...doc.data(), id: doc.id } as any;
            const notif = newNotification(
              "newUserMessage",
              doc,
              dataUser,
              undefined,
              newMessage
            );
            onNewData(notif, newMessage);
          });

          await Promise.all(updates);
        } catch (error) {
          console.error(`Error in subsToFriendListMessages: ${error}`);
        }
      },
      (error) => {
        console.error(`Error observe subsToFriendListMessages: ${error}`);
      }
    );
};

export const listSubsToNotificationSyncOrderMarketplace = (
  userId: string,
  onNewData: (notif: Notification, message: Message | undefined) => void,
  newLimit?: number
) => {
  return db
    .collection("users")
    .doc(userId)
    .collection("notifications")
    .where("type", "==", "syncOrder")
    .where(
      "createdAt",
      ">=",
      Tstamp.fromDate(
        calculateStartDate(
          Number(
            newLimit !== 0 ? newLimit : limitByEnv ? limitByEnv : defaultLimit
          )
        )
      )
    )
    .orderBy("createdAt", "desc")
    .onSnapshot(async (querySnapshot) => {
      try {
        const updates = querySnapshot.docs.map(async (doc) => {
          if (!doc.exists) return;
          const syncResult = doc.data().syncRes;
          const newNotif = newNotification(
            "SyncOrder",
            doc,
            undefined,
            null,
            syncResult
          );
          onNewData(newNotif, syncResult);
        });

        await Promise.all(updates);
      } catch (error) {
        console.error(
          `Error in subsToNotificationSyncOrderMarketplace: ${error}`
        );
      }
    });
};
