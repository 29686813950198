import {FC} from 'react'
import firebase from 'firebase/compat/app'
import { format } from 'date-fns';

type Props = {
  createdAt?: firebase.firestore.Timestamp;
  // createdAt?: Long;
}

const CampaignCreatedAtCell: FC<Props> = ({createdAt}) => (
  <div className='text-gray-650 mb-1' data-testid="createdAt">{createdAt ? dateToString(createdAt) : ""}</div>
  // <div className='text-gray-650 mb-1 '>{createdAt ? dateToString(createdAt) : ""}</div>
)

const dateToString = (date: firebase.firestore.Timestamp) => {
  const newDate = new Date(date.toMillis());
  const stringDate = format(newDate,"d MMM yyyy, h:mm aaa"
    );
  return stringDate;
}

export {CampaignCreatedAtCell, dateToString}