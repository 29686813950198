import React from "react"
import {components} from "react-select"
import { useTranslation } from "react-i18next";
import {GroupBase} from "react-select"

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    handleAdd?: () => void;
    itemCreateText? : string;
  }
}

export const AddDataButton = (props: any) => {
  const {handleAdd} = props.selectProps
  const {t} = useTranslation()
  
  return (
      <components.Menu {...props}>
        <div>
          {props.selectProps.fetchingData ? (
            <span className="fetching">{t("Common.Pages.Loading")}...</span>
          ) : (
            <div>
              {props.children}
              
              <button
                data-testid="button-to-storefront"
                className={"change-data"}
                onClick={handleAdd}
                onTouchStart={handleAdd}
              >
                <h6 className="text-primary">
                  {`${props.selectProps.itemCreateText}`}
                </h6>
              </button>
              
            </div>
          )}
          
        </div>
        
      </components.Menu>
  );
};


