import firebase from "firebase/compat/app";
import db from ".";
import { Account } from "../app/models/Account";
import Client from "../app/models/Client";
import Company from "../app/models/Company";
import { UserCompanies } from "../app/models/User";
import * as lc from "../app/modules/localstorage/";
import * as conn from "./connection";
import { createRef } from "./connection";
import { converter2 } from "./converter";

export const getAccountByCompanyAndChannel = async (company: string, channel: string) => {
  const companyRef = conn.createRef("company", company);

  console.log("COMPANY", company);
  // console.log("REF Company", JSON.stringify(companyRef));

  return await db
    .collection("account")
    .withConverter(converter2<Account>())
    .where("company", "==", companyRef)
    .where("type", "==", channel)
    .get()
    .then((snapshot) => {
      const accounts = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log(
        "getAccountByCompanyAndChannel: ",
        accounts.map((a) => a.id)
      );
      return accounts;
    });
};

export const getCompanyAccounts = async (company: string, channels: string[]) => {
  const companyRef = conn.createRef("company", company);

  console.log(`fetchCompanyMarketplaceAccounts ${company} ${channels}`);

  console.log("COMPANY", company);
  console.log("REF Company", JSON.stringify(companyRef));

  return await db
    .collection("account")
    .withConverter(converter2<Account>())
    .where("company", "==", companyRef)
    .where("type", "in", channels)
    // .where("type", "==", channel)
    .get()
    .then((snapshot) => {
      const accounts = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.debug(`getCompanyAccounts: ${accounts}`);
      return accounts;
    });
};

export const getAccountByID = async (accountID: string): Promise<Account | undefined> => {
  try {
    const account = await db
      .collection("account")
      .doc(accountID)
      .withConverter(converter2<Account>())
      .get();

    if (!account.exists) {
      console.log("No such account!");
      return undefined;
    }

    console.log("Account data:", account.data());
    return {...account.data(), id: account.id} as Account;
  } catch (error) {
    console.error("Error getting account by id:", error);
  }
}

export const getAccessibleAccount = async (clientId: string, userCompanies: UserCompanies[], channel?: string) => {
  console.log("Get Data Account");

  console.log("clientId : ", clientId);
  console.log("userCompanies : ", userCompanies);
  if (!clientId || !userCompanies) {
    console.log("No Client or Companies");
    return undefined;
  }

  let query = db.collection("account").withConverter(converter2<Account>()).where("client", "==", createRef("clients", clientId));

  const accessAllCompany = userCompanies.find((accessCompany) => accessCompany.id === "*");
  //if have access to all company, get by client
  if (!accessAllCompany) {
    //createRef("company", company.id)
    //`company/${company.id}`
    const companyAccess = userCompanies.map((company) => createRef("company", company.id));
    console.log(`Get Data Account companyAccess Query ${companyAccess.length}`);
    query = query.where("company", "in", companyAccess);
  }

  if (channel) query = query.where("type", "==", channel);

  console.log(`Get Data Account accessAllCompany ${accessAllCompany}`);

  return query
    .get()
    .then(async (snaps) =>
      Promise.all(
        snaps.docs.map((doc) => {
          console.log(`Get Data Account query ${doc.id} ${doc.data().company?.id}`);
          const account = {
            ...doc.data(),
            id: doc.id,
          };

          const companyRef = doc.data().company ?? createRef("company", "*");
          return companyRef
            .withConverter(converter2<Company>())
            .get()
            .then((company) => {
              if (!company.exists) return account;

              return {
                ...account,
                companyModel: { ...company.data(), id: company.id },
              };
            })
            .catch((err) => {
              console.log(err);
              return account;
            });
        })
      )
    )

    .catch((error) => {
      console.error(`Get Data Account error ${error}`);
      Promise.reject("error get accessible account");
      return undefined;
    });
};

export const getByClient = async (client: firebase.firestore.DocumentReference) => {
  console.log(`masuk getAccountByClient`);

  // console.log(`client: ${JSON.stringify(client)}`);
  return await db
    .collection("account")
    .withConverter(converter2<Account>())
    .where("isActive", "==", true)
    .where("type", "!=", "whatsapp")
    .where("client", "==", client)
    // add filteer where company belongs to user
    .get()
    .then((snapshot) => {
      const accounts = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log(`getAccountByClient accounts : `, accounts);
      return accounts;
    });
};

export const getWAByClient = async (client: firebase.firestore.DocumentReference) => {
  console.log(`masuk getWAByClient`);

  // console.log(`client: ${JSON.stringify(client)}`);
  return await db
    .collection("account")
    .withConverter(converter2<Account>())
    .where("isActive", "==", true)
    .where("type", "==", "whatsapp")
    .where("client", "==", client)
    // add filteer where company belongs to user
    .get()
    .then((snapshot) => {
      const accounts = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log(`getWAByClient accounts : `, accounts);
      return accounts;
    });
};

export const insertIntoAccount = async (data: Partial<Account>) => {
  return await db.collection("account").add(data);
};

export const deleteAccount = async (AccountId: string) => {
  try {
    await db.collection("account").doc(AccountId).delete();

    console.log("Account deleted successfully");
  } catch (error) {
    console.error("Error deleting account:", error);
  }
};

export const disconnectActionAccount = async (AccountId: string) => {
  try {
    await db.collection("account").doc(AccountId).update({
      isActive: false,
    });

    console.log("Account Disconnect successfully");
  } catch (error) {
    console.error("Error Disconnecting account:", error);
  }
};

export const connectActionAccount = async (AccountId: string) => {
  try {
    await db.collection("account").doc(AccountId).update({
      connectedAt: new Date(),
      isActive: true,
    });

    console.log("Account Connect successfully");
  } catch (error) {
    console.error("Error Connecting account:", error);
  }
};
