/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from "react";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";
import Customer from "../../../../../models/Customer"

type Props = {
  contact: Customer;
};

const TargetInfoCell: FC<Props> = ({ contact }) => (
  
  <div className="d-flex align-items-center">
    {/* begin:: Avatar */}
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
      
        {contact.avatar ? (
          <div className="symbol-label">
            <img
              src={`${contact.avatar}`}
              alt={contact.firstName}
              className="w-100"
            />
          </div>
        ) : (
        <div className="symbol-label">
        <img
          src={toAbsoluteUrl("/media/icons/avatar/def-avatar.png")}
          alt={contact.firstName}
          className="w-100"
        />
      </div>
    )
        }
      {/* </Link> */}
    </div>
    <div className="d-flex flex-column">
      {/* <Link
        to={
          "/contact/contact-detail/overview/" +
          contact.firstName! + (contact.lastName? "-" + contact.lastName : '')
        }
        state={{
          id: contact.id,
          name: contact.firstName! + (contact.lastName? " " + contact.lastName : ''),
        }}
        className="text-gray-800 text-hover-primary mb-1"
      > */}
        {contact.firstName} {contact.lastName? contact.lastName : ''}
      {/* </Link> */}
      
      {/* <span>{contact.email}</span> */}
    </div>
  </div>
);

export { TargetInfoCell };
