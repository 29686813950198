import React from 'react'
import { useMediaQuery } from "react-responsive";

const Content: React.FC = ({children}) => {
  // const {classes} = useLayout()
  // const location = useLocation()
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  // useEffect(() => {
  //   DrawerComponent.hideAll()
  // }, [location])

  return (
    <div id='kt_content_container' 
    className="container pt-0" 
    style={{marginRight:"0%", marginLeft:"0%", padding:isTabletOrMobile ? "2px" : "10px"}}>
      {children}
    </div>
  )
}

export {Content}
