import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getAttributesByMarketplaceAndCategoryId } from "../../../../../../../actions/marketplaceproductcategory";
import { getMarketplaceProductCategory } from "../../../../../../../actions/storefront";
import * as ReduxProduct from "../../../../../../../app/modules/product/redux/ProductSlice";
import * as ReduxShipment from "../../../../../../../app/modules/product/shipment/redux/ShipmentsSlice";
import * as StorefrontList from "../../../../../../../app/modules/product/storefront/storefrontList/StorefrontListSlice";
import { getBrandById } from "../../../../../../../db/serviceBrand";
import {
  getAllShipment,
  getMarketplacesByProductID,
  getProductById,
  getProductCategoryAttributesByProductId,
  getProductPhotosByProductId,
  getProductShipmentByProductId,
  getProductVideoByProductId,
  getShipmentChildren,
  getShipmentParent,
  getVariantCategoryByProductId,
  getVariantListByProductId,
  getisHaveVideo,
} from "../../../../../../../db/serviceProduct";
import { getStorefrontById } from "../../../../../../../db/serviceStorefront";
import { ID, KTSVG } from "../../../../../../../resources/helpers";
import { RootState } from "../../../../../../../setup/redux/store";
import Product, {
  categoryAttribute,
  productStep,
} from "../../../../../../models/Product";
import {
  ShopeeProductCategoryAttributeList,
  ShopeeProductCategoryAttributeValue,
} from "../../../../../../models/ShopeeProductCategoryAttribute";
import ModalDeleteSelected from "../../../../../../modules/modal/modalDeleteSelected";
import * as ReduxMarketplaceProductCategory from "../../../../../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { categoryAttributes } from "../../../../../../modules/product/redux/ProductRedux";
import { Loading } from "../../../../../../modules/util/Loading";
import AlertModal from "../../../../../broadcast/AlertModal";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteProduct } from "../../core/_requests";

export type Props = {
  id: ID;
  indexId?: number;
  numberOfVariant?: number;
  lastStep?: string;
  children?: any;
};

function addUniqueValue(list: any, newValue: any) {
  let existingValue = list.find((item: any) => item.value === newValue.value);
  if (!existingValue) {
    list.push(newValue);
  }
  return list;
}

function addIndex(arr: any) {
  return arr.map((item: any, index: number) => ({ ...item, index: index }));
}

const ProductActionsCell: FC<Props> = ({
  id,
  indexId,
  numberOfVariant,
  lastStep,
}): any => {
  const dispatch = useDispatch();
  const { setItemIdForUpdate } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });
  const { state, updateState } = useQueryRequest();
  const openAddUserModal = () => {
    setItemIdForUpdate(null);
  };

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const onSuccessSubmit = () => {
    //create emelent in list message
    console.log("onSuccessSubmit");
    let triggerDummyButton = document.getElementById(
      "triggerAlert"
    ) as HTMLButtonElement;
    triggerDummyButton.click();
  };

  const deleteItem = useMutation(() => deleteProduct(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      dispatch(StorefrontList.deleteItemListStorefront(id + ""));
      updateState({
        sort: state.sort === "delete" ? "asc" : "delete",
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
    },
    onError(error, variables, context) {
      dispatch(StorefrontList.deleteItemListStorefront(id + ""));
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSelectedHasProduct, setIsSelectedHasProduct] = useState(false);
  let listProductData: any = [];
  listProductData = useSelector(
    (state: RootState) => state.ProductList.listProduct
  );
  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const checkSelectedItems = (id: any) => {
    const getStorefrontData = listProductData?.find((storefront: any) => {
      return storefront.id === id;
    });
    console.log(
      "getProductData?.numberOfProducts : ",
      getStorefrontData.numberOfProducts
    );
    if (
      !getStorefrontData.numberOfProducts &&
      getStorefrontData.numberOfProducts <= 0
    ) {
      setIsSelectedHasProduct(false);
    } else {
      setIsSelectedHasProduct(true);
    }
    setIsDeleteModalOpen(true);
  };

  const nav = useNavigate();
  const handleOnEdit = async (id: any) => {
    console.log("product last step", lastStep);
    setIsLoading(true);
    //set to redux
    dispatch(ReduxProduct.setProductID(id.toString()));
    let newProduct = { ...reduxNewProduct };
    newProduct.id = id.toString();
    console.log("product id on new product", newProduct.id);
    const getProduct: any = listProductData?.find(
      (product: any) => product.id === id.toString()
    );
    //initiate product data from firebase
    let dataProductFB: any;
    let ProductData: any;
    const getDataProductFB = getProductById(id.toString()).then(
      (dataProduct) => {
        return dataProduct;
      }
    );
    dataProductFB = await getDataProductFB;
    ProductData = dataProductFB?.data;
    console.log("DATA PROD, FROM FIREBASE", dataProductFB);

    newProduct.id = dataProductFB?.id;

    //set tempStorefront
    console.log("ProductData?.storeFront : ", ProductData?.storeFront);
    if (ProductData?.storeFront) {
      dispatch(
        ReduxProduct.setTempSelectedStorefront(ProductData?.storeFront.id)
      );
    }

    //initiate product shipment data from firebase
    let ProductShipmentData: any;
    const getDataProductShipmentsFB = getProductShipmentByProductId(
      id.toString()
    ).then((dataShipment) => {
      return dataShipment;
    });
    ProductShipmentData = await getDataProductShipmentsFB;
    console.log("DATA PROD SHIPMENT, FROM FIREBASE", ProductShipmentData);
    const shipments: any = await getShipmentParent();
    let array: any = [];
    const copyParent = [...shipments];
    for await (const shipment of copyParent) {
      const data = await getShipmentChildren(shipment.id);
      const shipmentObj = { ...shipment, children: data };
      array.push(shipmentObj);
    }
    console.log("array children : ", array);
    let sortArray: any[] = array.sort((a: any, b: any) =>
      a.children.length! > b.children.length! ? -1 : 1
    );
    // console.log("hasil sorting array : ", sortArray);
    dispatch(ReduxShipment.setShipmentList(sortArray));

    // initiate variant category data from firebase
    let ProductVariantCategoryData: any;
    const getDataVariantCategoryFB = getVariantCategoryByProductId(
      id.toString()
    ).then((dataVariant) => dataVariant);
    ProductVariantCategoryData = await getDataVariantCategoryFB;

    // initiate variant list data from firebase
    let ProductVariantListData: any;
    const getDataVariantListFB = getVariantListByProductId(id.toString()).then(
      (dataVariant) => dataVariant
    );
    ProductVariantListData = await getDataVariantListFB;

    // initiate company & marketplaces data product from firebase
    let ProductMarketplacesData: any;
    const getDataProductMarketplacessFB = getMarketplacesByProductID(
      id.toString()
    ).then((dataMarketplaces) => {
      return dataMarketplaces;
    });
    ProductMarketplacesData = await getDataProductMarketplacessFB;
    console.log(
      "DATA PROD CMPLS (marketplaces), FROM FIREBASE",
      ProductMarketplacesData
    );
    let CompanyDataSetter: any[] = [];
    let MarketplaceDataSetter: any[] = [];

    //Storefront
    let dataStorefront: any;
    let storefrontName: string;
    let storefrontSetter: any;
    //Brand
    let dataBrand: any;
    let brandName: string;
    let brandSetter: any;
    //Shipment
    let dataShipmentSetter: any[] = [];
    // variantCategory
    let variantPhotos: any[] = [];
    let variantCombination: any[] = [];
    // variantList
    let variantListSetter: any[] = [];

    console.log("DATPROD, GET: ", getProduct);
    if (getProduct && getProduct.name) {
      if (!reduxNewProduct.name) {
        console.log("productname that's get: ", getProduct.name);
        dispatch(ReduxProduct.setProductName(getProduct.name));
        dispatch(ReduxProduct.setProductNameLength(getProduct.name.length));
        newProduct.name = getProduct.name;
        newProduct.nameInsensitive = getProduct.name.trim().toLowerCase();
        // dispatch(ReduxProduct.setNewProduct(newProduct));
      } else {
        console.log("data product name is avaible");
      }
    }
    if (getProduct && getProduct.sku) {
      if (!reduxNewProduct.sku) {
        console.log("productsku that's get: ", getProduct.sku);
        dispatch(ReduxProduct.setProductSKU(getProduct.sku));
        dispatch(
          ReduxProduct.setProductSKULength(
            getProduct.sku ? getProduct.sku.length : 0
          )
        );
        newProduct.sku = getProduct.sku;
      } else {
        console.log("data product sku is avaible");
      }
    }
    if (getProduct.storeFront) {
      if (!reduxNewProduct.storeFront) {
        console.log("storefront that's get: ", getProduct.storeFront.id);
        getStorefrontById(getProduct.storeFront.id).then((data) => {
          dataStorefront = data;
          storefrontName = dataStorefront.data.name;
          storefrontSetter = {
            label: storefrontName,
            value: getProduct.storeFront.id,
          };
          console.log("SRF, DATA SET TO REDUX ", storefrontSetter);
          dispatch(ReduxProduct.setSelectedStoreFrontData(storefrontSetter));
        });
      } else {
        console.log("data product storefront is avaible");
      }
    }
    if (getProduct.brand) {
      if (!reduxNewProduct.brand) {
        console.log("brand that's get: ", getProduct.brand.id);
        getBrandById(getProduct.brand.id).then((data) => {
          dataBrand = data;
          console.log("BRN, DATA ", dataBrand);
          brandName = dataBrand.brandName;
          brandSetter = {
            label: brandName,
            value: getProduct.brand.id,
          };
          console.log("BRN, DATA SET TO REDUX ", brandSetter);
          dispatch(ReduxProduct.setSelectedBrandData(brandSetter));
        });
      } else {
        console.log("data product brand is avaible");
      }
    }
    if (getProduct.description) {
      if (!reduxNewProduct.description) {
        console.log("description that's get: ", getProduct.description);
        dispatch(ReduxProduct.setDescriptionProduct(getProduct.description));
        dispatch(
          ReduxProduct.setDescriptionProductLength(
            getProduct.description.length
          )
        );

        newProduct.description = getProduct.description;
        // dispatch(
        //   ReduxProduct.setNewProduct({
        //     ...newProduct,
        //     description: getProduct.description,
        //   })
        // );
      }
    }
    //Image Initialization
    if (getProduct.coverImage !== "") {
      getProductPhotosByProductId(id.toString()).then((photos) => {
        console.log("data product photos in firebase: ", photos);
        const listPhotos = photos.map((photo) => {
          console.log("DATA PERPHOTO OKE", photo);
          let dataPhoto = {
            url: photo.fileURL,
            size: photo.fileSize,
            type: photo.fileType,
            extension: photo.extension,
            name: photo.fileName,
          };
          console.log("DATA PERPHOTO OKE, FOR SETTER", dataPhoto);
          return dataPhoto;
        });
        dispatch(ReduxProduct.setProductPhotos(listPhotos));
      });
    }

    //Video Initialization
    const isHaveVideo = getisHaveVideo(id.toString()).then((ishave) => {
      console.log("IS HAVE VIDEO?", ishave);
      if (ishave > 0) {
        getProductVideoByProductId(id.toString()).then((video: any[]) => {
          console.log("data product video in firebase: ", video);
          if (video.length > 0) {
            video.map((vid: any) => {
              console.log("DATA PERVIDEO OKE", video);
              let dataVideo = {
                url: vid.fileURL,
                size: vid.fileSize,
                type: vid.fileType,
                extension: vid.extension,
                name: vid.fileName,
              };
              console.log("DATA PERPHOTO OKE, FOR SETTER", dataVideo);
              dispatch(ReduxProduct.setProductVideo(dataVideo));
              if (vid.youtbeURL) {
                dispatch(ReduxProduct.setVideoYoutubeURL(vid.youtbeURL));
              }
            });
          }
        });
      } else {
        console.log("there is no video");
      }
    });

    //Package Weight Initialization
    if (ProductData) {
      console.log("DATA, PROD, ProductData", ProductData);

      if (ProductData?.weight) {
        dispatch(ReduxProduct.setProductWeight(ProductData?.weight));
        newProduct.width = ProductData?.weight;
      }

      if (ProductData?.length) {
        dispatch(ReduxProduct.setProductDimensionLength(ProductData?.length));
        newProduct.length = ProductData?.length;
      }

      if (ProductData?.height) {
        dispatch(ReduxProduct.setProductDimensionHeight(ProductData?.height));
        newProduct.height = ProductData?.height;
      }

      if (ProductData?.width) {
        dispatch(ReduxProduct.setProductDimensionWidth(ProductData?.width));
        newProduct.width = ProductData?.width;
      }

      //isDangerousProduct Initialization
      if (ProductData?.isDangerousProduct !== undefined) {
        newProduct.isDangerousProduct = ProductData?.isDangerousProduct;
        dispatch(
          ReduxProduct.setIsDangerousProduct(
            ProductData?.isDangerousProduct.toString()
          )
        );
      }
    } else {
      console.log("DATA, PROD, ProductData, masuk else");
    }

    // Variant Initialization
    if (ProductData) {
      if (ProductData?.isProductHaveVariants !== undefined) {
        console.log("VARBUG ENTER VARIANTS CHANGE");
        const isVariant =
          ProductData?.isProductHaveVariants === true ? "true" : "false";
        dispatch(ReduxProduct.setIsProductHaveVariants(isVariant));
        newProduct.isProductHaveVariants = ProductData?.isProductHaveVariants;
      }
    }

    if (ProductData?.isProductHaveVariants === true) {
      if (ProductVariantCategoryData) {
        // Variant Type
        let defaultVariantType = [
          {
            value: "Warna",
            label: "Warna",
          },
          {
            value: "Ukuran",
            label: "Ukuran",
          },
        ];

        let newVariantType = [...defaultVariantType];
        ProductVariantCategoryData.map((variant: any) => {
          const variantTypeObj = {
            value: variant.name,
            label: variant.name,
          };

          newVariantType = addUniqueValue(newVariantType, variantTypeObj);
        });
        dispatch(ReduxProduct.setVariantsType(newVariantType));

        // Selected Variant
        let selectedVariant: any[] = [];
        ProductVariantCategoryData.sort(
          (a: any, b: any) => a.index - b.index
        ).map((variant: any) => {
          const variantTypeObj = {
            value: variant.name,
            label: variant.name,
          };

          selectedVariant.push(variantTypeObj);
        });

        dispatch(ReduxProduct.setSelectedVariantsType(selectedVariant));

        // Variants Combination
        ProductVariantCategoryData.map((variant: any) => {
          const variantObj = {
            name: variant.name,
            index: variant.index,
            optionList: addIndex(variant.optionList),
          };

          variantCombination.push(variantObj);
        });

        let sorted = [...variantCombination].sort(
          (a: any, b: any) => a.index - b.index
        );

        dispatch(ReduxProduct.setVariants(sorted));
      }

      // Variant List
      console.log("VARLIST DATA", ProductVariantListData);
      if (ProductVariantListData) {
        console.log("VARLIST COMBO", variantCombination);
        ProductVariantListData.map((variant: any, i: number) => {
          if (variant.tierIndex.length === 2) {
            const variantObj = {
              tierIndex: variant.tierIndex,
              variant1: variantCombination.find((v: any) => v.index === 0)
                .optionList[variant.tierIndex[0]]?.option,
              variant2: variantCombination.find((v: any) => v.index === 1)
                .optionList[variant.tierIndex[1]]?.option,
              sku: variant.sku,
              weight: variant.weight,
              isActive: true,
              isMainVariant: variant.isMainVariant,
            };

            variantListSetter.push(variantObj);
          } else if (variant.tierIndex.length === 1) {
            const variantObj = {
              tierIndex: variant.tierIndex,
              variant1: variantCombination.find((v: any) => v.index === 0)
                .optionList[variant.tierIndex[0]].option,
              sku: variant.sku,
              weight: variant.weight,
              isActive: true,
              isMainVariant: variant.isMainVariant,
            };

            variantListSetter.push(variantObj);
          }
        });

        // sort
        variantListSetter.sort((a: any, b: any): any => {
          if (a.tierIndex.length === 1) {
            return a.tierIndex[0] - b.tierIndex[0];
          } else if (a.tierIndex.length === 2) {
            for (let i = 0; i < a.tierIndex.length; i++) {
              if (a.tierIndex[i] < b.tierIndex[i]) {
                return -1;
              } else if (a.tierIndex[i] > b.tierIndex[i]) {
                return 1;
              }
            }
            return 0;
          }
        });

        const variantThatHasPhotos = variantCombination.find(
          (v) => v.index === 0
        )?.optionList;

        if (variantThatHasPhotos?.length > 0) {
          variantPhotos = variantThatHasPhotos.filter(
            (photo: any) => photo.image !== ""
          );
          console.log("VARPhoto", variantPhotos);
          let varPhoto: any[] = variantPhotos.map((photo) => {
            const photoData = {
              index: photo.index,
              url: photo.image,
              option: photo.option,
            };
            return photoData;
            // varPhoto.push(photoData);
          });
          console.log("VARPhoto, oke: ", varPhoto);
          dispatch(ReduxProduct.setProductVariantPhotos(varPhoto));
        }

        dispatch(ReduxProduct.setVariantMatrix(variantListSetter));
      }
    }

    //Shipment Initialization
    if (ProductShipmentData) {
      dataShipmentSetter = ProductShipmentData.map((shipment: any) => {
        let shipmentTypes: any[] = shipment.types.map((type: any) => {
          return { id: type.shipmentID.id, name: type.name };
          // shipmentTypes.push({ id: type.shipmentID.id, name: type.name });
        });
        let shipmentData = {
          id: shipment.shipmentID.id,
          isActive: shipment.isActive,
          name: shipment.name,
          types: shipmentTypes,
        };
        // dataShipmentSetter.push(shipmentData);
        return shipmentData;
      });
      console.log("SHPDATA, SETTER", dataShipmentSetter);
      dispatch(ReduxProduct.setShipments(dataShipmentSetter));
      dispatch(ReduxShipment.setSummaryShipmentList(dataShipmentSetter));
    }

    // Company and Marketplace
    // Company Initialization
    console.log("adding data companies");
    console.log("isi dari ProductData?.companies", ProductData?.companies);
    console.log("masuk ke if companies");
    // Marketplace Initialization
    if (ProductMarketplacesData !== undefined) {
      ProductMarketplacesData.map((marketplace: any) => {
        console.log("data marketplace 1 by 1", marketplace);
        MarketplaceDataSetter.push({
          company: marketplace.company.id,
          marketplace: marketplace.marketplace,
          isActive: marketplace.isActive,
          category: marketplace.categories,
        });
        CompanyDataSetter.push({
          company: marketplace.company.id,
          isActive: marketplace.isActive,
        });
      });
      console.log("isi dari data MarketplaceDataSetter", MarketplaceDataSetter);
      dispatch(ReduxProduct.setMarketplaces(MarketplaceDataSetter));
      console.log("companyDataSetter isinya", CompanyDataSetter);
      dispatch(ReduxProduct.setCompanies(CompanyDataSetter));
    }

    //product specification or attributes
    let ProductCategoryAttributesData: any;
    const getDataProductCategoryAttributesFB =
      getProductCategoryAttributesByProductId(id.toString()).then(
        (dataCategoryAttributes) => {
          return dataCategoryAttributes;
        }
      );
    ProductCategoryAttributesData = await getDataProductCategoryAttributesFB;
    //get all attributes from last category
    if (ProductCategoryAttributesData) {
      //get all attributes
      await getProductCategoryAttributes(
        MarketplaceDataSetter,
        CompanyDataSetter,
        ProductCategoryAttributesData
      );
    }
    dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));

    dispatch(ReduxProduct.setNewProduct(newProduct));

    setIsLoading(false);

    lastStep = !lastStep
      ? ProductData.lastStep
        ? ProductData.lastStep
        : ""
      : lastStep;

    if (lastStep === "") {
      dispatch(ReduxProduct.setIsCreatedProduct(true));
    }
    //nav
    const navigate: string =
      lastStep === ""
        ? `/product/update/${id.toString()}`
        : lastStep === productStep.onBasicProductInformation
        ? "/product/update/basic-information"
        : lastStep === productStep.onMedia
        ? "/product/update/media-upload"
        : lastStep === productStep.onPackageWeight
        ? "/product/update/package-weight"
        : lastStep === productStep.onShipment
        ? "/product/update/shipments"
        : lastStep === productStep.onSelectCompanyAndMarketplace
        ? "/product/update/select-company-and-marketplace"
        : lastStep === productStep.onSpecification ||
          ProductData.lastStep === productStep.onSpecification
        ? "/product/update/category-attributes"
        : lastStep === productStep.onVariantsCategory
        ? "/product/update/variants-category"
        : lastStep === productStep.onVariantsList
        ? "/product/update/variants-list"
        : lastStep === productStep.onSummary
        ? "/product/update/summary"
        : "/product/update/basic-information";
    console.log("navigate : ", navigate);
    console.log("id : ", id.toString());
    nav(navigate);
  };

  const getProductCategoryAttributes = async (
    marketplace: any,
    companies: any,
    data: categoryAttribute[]
  ) => {
    //find if company active
    const findShopee = marketplace.filter((market: any) => {
      const isActiveCompany = companies.find((company: any) => {
        if (company.company === market.company && company.isActive === true)
          return company;
      });
      console.log(`isActiveCompany : ${JSON.stringify(isActiveCompany)}`);
      if (
        isActiveCompany &&
        market.marketplace.toLowerCase() === "shopee" &&
        market.isActive === true
      )
        return market;
    });
    console.log(`findShopee : ${findShopee.length}`);
    if (!findShopee || findShopee.length < 1) return;
    let findLastCategoryShopee: string[] = [];
    for (let index = 0; index < findShopee.length; index++) {
      const shopee = findShopee[index];
      console.log(`shopee: ${shopee}`);
      const category = shopee.category[0];
      console.log(`category : ${JSON.stringify(category)}`);
      const data = Object.values(category);
      console.log(`data : ${data}`);
      const findLastCategory: any = data.find(
        (cat: any) => cat.hasChildren === false
      );
      console.log(`findLastCategory : ${JSON.stringify(findLastCategory)}`);
      if (!findLastCategory) return;
      findLastCategoryShopee = [...findLastCategoryShopee, findLastCategory.id];
    }
    let categoryAttributesList: ShopeeProductCategoryAttributeList[] = [];
    //get from redux shopee
    for (let index = 0; index < findLastCategoryShopee.length; index++) {
      const lastCategory = findLastCategoryShopee[index];
      const findCategory = reduxShopeeProductCategoryData.find((cat: any) => {
        if (cat.id === lastCategory) {
          console.log(`lastCategory : ${lastCategory}`);
          return cat;
        }
      });
      //get attribute from redux or get from firebase
      let categoryAttributes: ShopeeProductCategoryAttributeList[] = [];
      //jika belum pernah di get, maka get from firebase
      if (
        !findCategory ||
        !findCategory.attributesList ||
        (findCategory && findCategory.isHasAttributes === "")
      ) {
        categoryAttributes = await getAttributesByMarketplaceAndCategoryId(
          "shopee",
          lastCategory
        );
        console.log(
          `getAttributes from fb : ${JSON.stringify(categoryAttributes)}`
        );
        console.log(
          `categoryAttributes length : ${categoryAttributes?.length}`
        );
        //update to redux shopee, set isHasAttributes and save attributesList
        const reduxMarketplaceProductCategory = [
          ...reduxShopeeProductCategoryData,
        ];
        const newReduxMarketplaceProductCategory =
          reduxMarketplaceProductCategory.map((category) => {
            if (
              category.categoryId?.toString() === lastCategory &&
              (!categoryAttributes ||
                (categoryAttributes && categoryAttributes.length < 1))
            ) {
              console.log(`set isHasAttributes false`);
              return { ...category, isHasAttributes: "false" };
            } else if (
              category.categoryId?.toString() === lastCategory &&
              categoryAttributes &&
              categoryAttributes.length > 0
            ) {
              console.log(`set isHasAttributes true`);
              return {
                ...category,
                isHasAttributes: "true",
                attributesList: categoryAttributes,
              };
            } else {
              return category;
            }
          });
        dispatch(
          ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
            newReduxMarketplaceProductCategory
          )
        );
      }
      if (
        findCategory &&
        findCategory.isHasAttributes === "true" &&
        findCategory.attributesList &&
        findCategory.attributesList.length > 0
      ) {
        console.log(`get attributes from redux`);
        categoryAttributes = findCategory?.attributesList;
      }
      //jika tidak punya attributes, isHasAttributes === "false"
      if (
        (findCategory && findCategory.isHasAttributes === "false") ||
        !categoryAttributes ||
        categoryAttributes.length < 1
      ) {
        console.log(`last category not have attribute`);
        dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
        dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
        return;
      }
      console.log(`categoryAttributes : ${categoryAttributes}`);
      //find in categoryAttributesList, if find add categoryId
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute: ShopeeProductCategoryAttributeList =
          categoryAttributes[index];
        const findAttribute = categoryAttributesList.find(
          (attr: ShopeeProductCategoryAttributeList) =>
            attr.attributeId === attribute.attributeId
        );
        let newValueList =
          attribute.attributeValues &&
          (attribute.inputType === "COMBO_BOX" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX")
            ? [...attribute.attributeValues]
            : undefined;
        const findInDataFromFB = data.find((attr) => {
          console.log(
            `CHECK CA attr from fb : ${
              attr.attributeId?.toString() === attribute.attributeId.toString()
            }`
          );
          console.log(`CHECK CA attr input : ${attr.inputValues}`);
          if (attr.attributeId?.toString() === attribute.attributeId.toString())
            return attr;
        });
        let formatInputValues: any;
        if (findInDataFromFB && findInDataFromFB.inputValues) {
          formatInputValues = findInDataFromFB.inputValues.map((input) => {
            if (
              typeof input === "object" &&
              (attribute.inputType === "MULTIPLE_SELECT" ||
                attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX")
            ) {
              return Object.values(input);
            } else {
              return input;
            }
          });
          console.log(`formatInputValues : `, formatInputValues);
        }
        console.log(`findInDataFromFB : ${JSON.stringify(findInDataFromFB)}`);
        //add custom value to list
        let newAttributeValues: any[] = [];
        if (newValueList && formatInputValues) {
          newAttributeValues =
            formatInputValues[0] && formatInputValues[0].length > 0
              ? formatInputValues[0].filter(
                  (customVal: any) =>
                    customVal && customVal.value === customVal.label
                )
              : formatInputValues[1]
              ? null
              : formatInputValues.filter(
                  (customVal: any) =>
                    customVal && customVal.value === customVal.label
                );
          console.log(`newAttributeValues : `, newAttributeValues);
        }
        let attributeValueToSet = attribute.attributeValues
          ? [...attribute.attributeValues]
          : [];
        if (newAttributeValues && newAttributeValues.length > 0) {
          for (let index = 0; index < newAttributeValues.length; index++) {
            const attr = newAttributeValues[index];
            console.log(`attr : `, attr);
            const newList: ShopeeProductCategoryAttributeValue = {
              valueId: attr?.label,
              displayValueName: attr?.label,
              originalValueName: attr?.label,
            };
            attributeValueToSet.push(newList);
          }
        }
        console.log(`attributeValueToSet : `, attributeValueToSet);
        if (!findAttribute) {
          const newAttribute: ShopeeProductCategoryAttributeList = {
            ...attribute,
            categoryId:
              findInDataFromFB && findInDataFromFB?.categoryId
                ? findInDataFromFB.categoryId
                : [lastCategory],
            inputValues: formatInputValues ? formatInputValues : null,
            attributeValues:
              attributeValueToSet && attributeValueToSet.length > 0
                ? attributeValueToSet
                : undefined,
          };
          categoryAttributesList.push(newAttribute);
        } else {
          //add category
          let newCategoryId: any;
          if (findAttribute.categoryId)
            newCategoryId = [...findAttribute.categoryId, lastCategory];
          categoryAttributesList.map(
            (attr: ShopeeProductCategoryAttributeList) => {
              if (attr.attributeId === attribute.attributeId) {
                return {
                  ...attr,
                  categoryId: newCategoryId,
                  inputValues: formatInputValues ? formatInputValues : null,
                  attributeValues:
                    attributeValueToSet && attributeValueToSet.length > 0
                      ? attributeValueToSet
                      : undefined,
                };
              }
            }
          );
        }
      }
    }
    if (categoryAttributesList.length > 0) {
      categoryAttributesList = categoryAttributesList.sort((a: any, b: any) => {
        if (a.isMandatory && !b.isMandatory) {
          return -1;
        } else if (!a.isMandatory && b.isMandatory) {
          return 1;
        } else {
          return 0;
        }
      });
      const attributes: categoryAttributes = { shopee: categoryAttributesList };
      console.log(`attributes in summary : ${JSON.stringify(attributes)}`);
      dispatch(ReduxProduct.setCategoryAttributes(attributes));
    } else {
      dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <div className="text-center">
        <button
          id="triggerAlert"
          data-bs-toggle="modal"
          data-bs-target="#modal_broadcast_alert"
          hidden
        ></button>
        <AlertModal />

        <div className="dropdown">
          {/* eslint-disable-next-line */}
          {!isTabletOrMobile && (
            <button
              className="btn btn-light btn-active-light-primary btn-sm"
              data-testid="dropdownMenuAction"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Common.Actions")}
              <KTSVG
                path="/media/icons/duotune/arrows/arr072.svg"
                className="svg-icon-5 m-0"
              />
            </button>
          )}

          {isTabletOrMobile && (
            <button
              data-testid="dropdownMenuAction"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="w-auto mx-0 px-0 bg-transparent border-none outline none"
              style={{
                border: "none",
                outline: "none",
              }}
            >
              <KTSVG
                path="#"
                className="bi bi-three-dots-vertical fs-1 p-0 m-0"
              />
            </button>
          )}

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              {/* eslint-disable-next-line */}
              <a
                data-testid="actionDeleteStorefront"
                className="dropdown-item btn btn-light btn-active-light-primary btn-sm disabled"
                data-kt-users-table-filter="delete_row"
                onClick={() => {
                  // checkSelectedItems(id);
                  return;
                }}
              >
                {t("StorefrontList.Column.Button.Menu.Delete")}
              </a>
            </li>
            <li>
              <button
                id={"editOnProduct-" + id}
                data-testid={"edit-" + id}
                onClick={() => handleOnEdit(id)}
                className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
              >
                {t("Storefront.Column.Button.Edit")}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <ModalDeleteSelected
        data-testid="modalConfirmDeleteSelected"
        id="confirmDeleteSelected"
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={
          isSelectedHasProduct
            ? t("StorefrontList.Alert.Delete.Storefront.Title")
            : t("Warning")
        }
        body={
          isSelectedHasProduct
            ? t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontHasProduct"
              )
            : t(
                "StorefrontList.Alert.Delete.Storefront.Info.StorefrontDoesn'tHasProduct"
              )
        }
        confirmText={
          isSelectedHasProduct
            ? undefined
            : t("Storefront.Column.Button.Delete")
        }
        cancelText={
          isSelectedHasProduct
            ? t("Storefront.Button.Back")
            : t("Alert.Button.Cancel")
        }
        onConfirm={async () => {
          await deleteItem.mutateAsync();
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};

export const useProductEdit = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  let listProductData: any = useSelector(
    (state: RootState) => state.ProductList.listProduct
  );
  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const handleEdit = async (
    id: any,
    lastStep?: string,
    searchParams?: string
  ) => {
    console.log("product last step", lastStep);
    // setIsLoading(true);
    //set to redux
    dispatch(ReduxProduct.setProductID(id.toString()));
    let newProduct = { ...reduxNewProduct };
    newProduct.id = id.toString();
    console.log("product id on new product", newProduct.id);
    const getProduct: any = listProductData?.find(
      (product: any) => product.id === id.toString()
    );
    //initiate product data from firebase
    let dataProductFB: any;
    let ProductData: any;
    const getDataProductFB = getProductById(id.toString()).then(
      (dataProduct) => {
        return dataProduct;
      }
    );
    dataProductFB = await getDataProductFB;
    ProductData = dataProductFB?.data;
    console.log("DATA PROD, FROM FIREBASE", dataProductFB);

    newProduct.id = dataProductFB?.id;

    //set tempStorefront
    console.log("ProductData?.storeFront : ", ProductData?.storeFront);
    if (ProductData?.storeFront) {
      dispatch(
        ReduxProduct.setTempSelectedStorefront(ProductData?.storeFront.id)
      );
    }

    //initiate product shipment data from firebase
    let ProductShipmentData: any;
    const getDataProductShipmentsFB = getProductShipmentByProductId(
      id.toString()
    ).then((dataShipment) => {
      return dataShipment;
    });
    ProductShipmentData = await getDataProductShipmentsFB;
    console.log("DATA PROD SHIPMENT, FROM FIREBASE", ProductShipmentData);
    const shipments: any = await getShipmentParent();
    let array: any = [];
    const copyParent = [...shipments];
    for await (const shipment of copyParent) {
      const data = await getShipmentChildren(shipment.id);
      const shipmentObj = { ...shipment, children: data };
      array.push(shipmentObj);
    }
    console.log("array children : ", array);
    let sortArray: any[] = array.sort((a: any, b: any) =>
      a.children.length! > b.children.length! ? -1 : 1
    );
    // console.log("hasil sorting array : ", sortArray);
    dispatch(ReduxShipment.setShipmentList(sortArray));

    // initiate variant category data from firebase
    let ProductVariantCategoryData: any;
    const getDataVariantCategoryFB = getVariantCategoryByProductId(
      id.toString()
    ).then((dataVariant) => dataVariant);
    ProductVariantCategoryData = await getDataVariantCategoryFB;

    // initiate variant list data from firebase
    let ProductVariantListData: any;
    const getDataVariantListFB = getVariantListByProductId(id.toString()).then(
      (dataVariant) => dataVariant
    );
    ProductVariantListData = await getDataVariantListFB;

    // initiate company & marketplaces data product from firebase
    let ProductMarketplacesData: any;
    const getDataProductMarketplacessFB = getMarketplacesByProductID(
      id.toString()
    ).then((dataMarketplaces) => {
      return dataMarketplaces;
    });
    ProductMarketplacesData = await getDataProductMarketplacessFB;
    console.log(
      "DATA PROD CMPLS (marketplaces), FROM FIREBASE",
      ProductMarketplacesData
    );
    let CompanyDataSetter: any[] = [];
    let MarketplaceDataSetter: any[] = [];

    //Storefront
    let dataStorefront: any;
    let storefrontName: string;
    let storefrontSetter: any;
    //Brand
    let dataBrand: any;
    let brandName: string;
    let brandSetter: any;
    //Shipment
    let dataShipmentSetter: any[] = [];
    // variantCategory
    let variantPhotos: any[] = [];
    let variantCombination: any[] = [];
    // variantList
    let variantListSetter: any[] = [];

    console.log("DATPROD, GET: ", getProduct);
    if (getProduct && getProduct.name) {
      if (!reduxNewProduct.name) {
        console.log("productname that's get: ", getProduct.name);
        dispatch(ReduxProduct.setProductName(getProduct.name));
        dispatch(ReduxProduct.setProductNameLength(getProduct.name.length));
        newProduct.name = getProduct.name;
        newProduct.nameInsensitive = getProduct.name.trim().toLowerCase();
        // dispatch(ReduxProduct.setNewProduct(newProduct));
      } else {
        console.log("data product name is avaible");
      }
    }
    if (getProduct && getProduct.sku) {
      if (!reduxNewProduct.sku) {
        console.log("productsku that's get: ", getProduct.sku);
        dispatch(ReduxProduct.setProductSKU(getProduct.sku));
        dispatch(
          ReduxProduct.setProductSKULength(
            getProduct.sku ? getProduct.sku.length : 0
          )
        );
        newProduct.sku = getProduct.sku;
      } else {
        console.log("data product sku is avaible");
      }
    }
    if (getProduct.storeFront) {
      if (!reduxNewProduct.storeFront) {
        console.log("storefront that's get: ", getProduct.storeFront.id);
        getStorefrontById(getProduct.storeFront.id).then((data) => {
          dataStorefront = data;
          storefrontName = dataStorefront.data.name;
          storefrontSetter = {
            label: storefrontName,
            value: getProduct.storeFront.id,
          };
          console.log("SRF, DATA SET TO REDUX ", storefrontSetter);
          dispatch(ReduxProduct.setSelectedStoreFrontData(storefrontSetter));
        });
      } else {
        console.log("data product storefront is avaible");
      }
    }
    if (getProduct.brand) {
      if (!reduxNewProduct.brand) {
        console.log("brand that's get: ", getProduct.brand.id);
        getBrandById(getProduct.brand.id).then((data) => {
          dataBrand = data;
          console.log("BRN, DATA ", dataBrand);
          brandName = dataBrand.brandName;
          brandSetter = {
            label: brandName,
            value: getProduct.brand.id,
          };
          console.log("BRN, DATA SET TO REDUX ", brandSetter);
          dispatch(ReduxProduct.setSelectedBrandData(brandSetter));
        });
      } else {
        console.log("data product brand is avaible");
      }
    }
    if (getProduct.description) {
      if (!reduxNewProduct.description) {
        console.log("description that's get: ", getProduct.description);
        dispatch(ReduxProduct.setDescriptionProduct(getProduct.description));
        dispatch(
          ReduxProduct.setDescriptionProductLength(
            getProduct.description.length
          )
        );

        newProduct.description = getProduct.description;
        // dispatch(
        //   ReduxProduct.setNewProduct({
        //     ...newProduct,
        //     description: getProduct.description,
        //   })
        // );
      }
    }
    //Image Initialization
    if (getProduct.coverImage !== "") {
      getProductPhotosByProductId(id.toString()).then((photos) => {
        console.log("data product photos in firebase: ", photos);
        const listPhotos = photos.map((photo) => {
          console.log("DATA PERPHOTO OKE", photo);
          let dataPhoto = {
            url: photo.fileURL,
            size: photo.fileSize,
            type: photo.fileType,
            extension: photo.extension,
            name: photo.fileName,
          };
          console.log("DATA PERPHOTO OKE, FOR SETTER", dataPhoto);
          return dataPhoto;
        });
        dispatch(ReduxProduct.setProductPhotos(listPhotos));
      });
    }

    //Video Initialization
    const isHaveVideo = getisHaveVideo(id.toString()).then((ishave) => {
      console.log("IS HAVE VIDEO?", ishave);
      if (ishave > 0) {
        getProductVideoByProductId(id.toString()).then((video: any[]) => {
          console.log("data product video in firebase: ", video);
          if (video.length > 0) {
            video.map((vid: any) => {
              console.log("DATA PERVIDEO OKE", video);
              let dataVideo = {
                url: vid.fileURL,
                size: vid.fileSize,
                type: vid.fileType,
                extension: vid.extension,
                name: vid.fileName,
              };
              console.log("DATA PERPHOTO OKE, FOR SETTER", dataVideo);
              dispatch(ReduxProduct.setProductVideo(dataVideo));
              if (vid.youtbeURL) {
                dispatch(ReduxProduct.setVideoYoutubeURL(vid.youtbeURL));
              }
            });
          }
        });
      } else {
        console.log("there is no video");
      }
    });

    //Package Weight Initialization
    if (ProductData) {
      console.log("DATA, PROD, ProductData", ProductData);

      if (ProductData?.weight) {
        dispatch(ReduxProduct.setProductWeight(ProductData?.weight));
        newProduct.width = ProductData?.weight;
      }

      if (ProductData?.length) {
        dispatch(ReduxProduct.setProductDimensionLength(ProductData?.length));
        newProduct.length = ProductData?.length;
      }

      if (ProductData?.height) {
        dispatch(ReduxProduct.setProductDimensionHeight(ProductData?.height));
        newProduct.height = ProductData?.height;
      }

      if (ProductData?.width) {
        dispatch(ReduxProduct.setProductDimensionWidth(ProductData?.width));
        newProduct.width = ProductData?.width;
      }

      //isDangerousProduct Initialization
      if (ProductData?.isDangerousProduct !== undefined) {
        newProduct.isDangerousProduct = ProductData?.isDangerousProduct;
        dispatch(
          ReduxProduct.setIsDangerousProduct(
            ProductData?.isDangerousProduct.toString()
          )
        );
      }
    } else {
      console.log("DATA, PROD, ProductData, masuk else");
    }

    // Variant Initialization
    if (ProductData) {
      if (ProductData?.isProductHaveVariants !== undefined) {
        console.log("VARBUG ENTER VARIANTS CHANGE");
        const isVariant =
          ProductData?.isProductHaveVariants === true ? "true" : "false";
        dispatch(ReduxProduct.setIsProductHaveVariants(isVariant));
        newProduct.isProductHaveVariants = ProductData?.isProductHaveVariants;
      }
    }

    if (ProductData?.isProductHaveVariants === true) {
      if (ProductVariantCategoryData) {
        // Variant Type
        let defaultVariantType = [
          {
            value: "Warna",
            label: "Warna",
          },
          {
            value: "Ukuran",
            label: "Ukuran",
          },
        ];

        let newVariantType = [...defaultVariantType];
        ProductVariantCategoryData.map((variant: any) => {
          const variantTypeObj = {
            value: variant.name,
            label: variant.name,
          };

          newVariantType = addUniqueValue(newVariantType, variantTypeObj);
        });
        dispatch(ReduxProduct.setVariantsType(newVariantType));

        // Selected Variant
        let selectedVariant: any[] = [];
        ProductVariantCategoryData.sort(
          (a: any, b: any) => a.index - b.index
        ).map((variant: any) => {
          const variantTypeObj = {
            value: variant.name,
            label: variant.name,
          };

          selectedVariant.push(variantTypeObj);
        });

        dispatch(ReduxProduct.setSelectedVariantsType(selectedVariant));

        // Variants Combination
        ProductVariantCategoryData.map((variant: any) => {
          const variantObj = {
            name: variant.name,
            index: variant.index,
            optionList: addIndex(variant.optionList),
          };

          variantCombination.push(variantObj);
        });

        let sorted = [...variantCombination].sort(
          (a: any, b: any) => a.index - b.index
        );

        dispatch(ReduxProduct.setVariants(sorted));
      }

      // Variant List
      console.log("VARLIST DATA", ProductVariantListData);
      if (ProductVariantListData) {
        console.log("VARLIST COMBO", variantCombination);
        ProductVariantListData.map((variant: any, i: number) => {
          if (variant.tierIndex.length === 2) {
            const variantObj = {
              tierIndex: variant.tierIndex,
              variant1: variantCombination.find((v: any) => v.index === 0)
                .optionList[variant.tierIndex[0]]?.option,
              variant2: variantCombination.find((v: any) => v.index === 1)
                .optionList[variant.tierIndex[1]]?.option,
              sku: variant.sku,
              weight: variant.weight,
              isActive: true,
              isMainVariant: variant.isMainVariant,
            };

            variantListSetter.push(variantObj);
          } else if (variant.tierIndex.length === 1) {
            const variantObj = {
              tierIndex: variant.tierIndex,
              variant1: variantCombination.find((v: any) => v.index === 0)
                .optionList[variant.tierIndex[0]].option,
              sku: variant.sku,
              weight: variant.weight,
              isActive: true,
              isMainVariant: variant.isMainVariant,
            };

            variantListSetter.push(variantObj);
          }
        });

        // sort
        variantListSetter.sort((a: any, b: any): any => {
          if (a.tierIndex.length === 1) {
            return a.tierIndex[0] - b.tierIndex[0];
          } else if (a.tierIndex.length === 2) {
            for (let i = 0; i < a.tierIndex.length; i++) {
              if (a.tierIndex[i] < b.tierIndex[i]) {
                return -1;
              } else if (a.tierIndex[i] > b.tierIndex[i]) {
                return 1;
              }
            }
            return 0;
          }
        });

        const variantThatHasPhotos = variantCombination.find(
          (v) => v.index === 0
        )?.optionList;

        if (variantThatHasPhotos?.length > 0) {
          variantPhotos = variantThatHasPhotos.filter(
            (photo: any) => photo.image !== ""
          );
          console.log("VARPhoto", variantPhotos);
          let varPhoto: any[] = variantPhotos.map((photo) => {
            const photoData = {
              index: photo.index,
              url: photo.image,
              option: photo.option,
            };
            return photoData;
            // varPhoto.push(photoData);
          });
          console.log("VARPhoto, oke: ", varPhoto);
          dispatch(ReduxProduct.setProductVariantPhotos(varPhoto));
        }

        dispatch(ReduxProduct.setVariantMatrix(variantListSetter));
      }
    }

    //Shipment Initialization
    if (ProductShipmentData) {
      dataShipmentSetter = ProductShipmentData.map((shipment: any) => {
        let shipmentTypes: any[] = shipment.types.map((type: any) => {
          return { id: type.shipmentID.id, name: type.name };
          // shipmentTypes.push({ id: type.shipmentID.id, name: type.name });
        });
        let shipmentData = {
          id: shipment.shipmentID.id,
          isActive: shipment.isActive,
          name: shipment.name,
          types: shipmentTypes,
        };
        // dataShipmentSetter.push(shipmentData);
        return shipmentData;
      });
      console.log("SHPDATA, SETTER", dataShipmentSetter);
      dispatch(ReduxProduct.setShipments(dataShipmentSetter));
      dispatch(ReduxShipment.setSummaryShipmentList(dataShipmentSetter));
    }

    // Company and Marketplace
    // Company Initialization
    console.log("adding data companies");
    console.log("isi dari ProductData?.companies", ProductData?.companies);
    console.log("masuk ke if companies");
    // Marketplace Initialization
    if (ProductMarketplacesData !== undefined) {
      ProductMarketplacesData.map((marketplace: any) => {
        console.log("data marketplace 1 by 1", marketplace);
        MarketplaceDataSetter.push({
          company: marketplace.company.id,
          marketplace: marketplace.marketplace,
          isActive: marketplace.isActive,
          category: marketplace.categories,
        });
        CompanyDataSetter.push({
          company: marketplace.company.id,
          isActive: marketplace.isActive,
        });
      });
      console.log("isi dari data MarketplaceDataSetter", MarketplaceDataSetter);
      dispatch(ReduxProduct.setMarketplaces(MarketplaceDataSetter));
      console.log("companyDataSetter isinya", CompanyDataSetter);
      dispatch(ReduxProduct.setCompanies(CompanyDataSetter));
    }

    //product specification or attributes
    let ProductCategoryAttributesData: any;
    const getDataProductCategoryAttributesFB =
      getProductCategoryAttributesByProductId(id.toString()).then(
        (dataCategoryAttributes) => {
          return dataCategoryAttributes;
        }
      );
    ProductCategoryAttributesData = await getDataProductCategoryAttributesFB;
    //get all attributes from last category
    if (ProductCategoryAttributesData) {
      //get all attributes
      await getProductCategoryAttributes(
        MarketplaceDataSetter,
        CompanyDataSetter,
        ProductCategoryAttributesData
      );
    }
    dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));

    dispatch(ReduxProduct.setNewProduct(newProduct));

    // setIsLoading(false);

    lastStep = !lastStep
      ? ProductData.lastStep
        ? ProductData.lastStep
        : ""
      : lastStep;

    if (lastStep === "") {
      dispatch(ReduxProduct.setIsCreatedProduct(true));
    }
    //nav
    let navigate: string =
      lastStep === ""
        ? `/product/update/${id.toString()}`
        : lastStep === productStep.onBasicProductInformation
        ? "/product/update/basic-information"
        : lastStep === productStep.onMedia
        ? "/product/update/media-upload"
        : lastStep === productStep.onPackageWeight
        ? "/product/update/package-weight"
        : lastStep === productStep.onShipment
        ? "/product/update/shipments"
        : lastStep === productStep.onSelectCompanyAndMarketplace
        ? "/product/update/select-company-and-marketplace"
        : lastStep === productStep.onSpecification ||
          ProductData.lastStep === productStep.onSpecification
        ? "/product/update/category-attributes"
        : lastStep === productStep.onVariantsCategory
        ? "/product/update/variants-category"
        : lastStep === productStep.onVariantsList
        ? "/product/update/variants-list"
        : lastStep === productStep.onSummary
        ? "/product/update/summary"
        : "/product/update/basic-information";

    if (searchParams) {
      navigate = navigate + searchParams;
    }
    console.log("navigate : ", navigate);
    console.log("id : ", id.toString());
    nav(navigate);
  };

  const getProductCategoryAttributes = async (
    marketplace: any,
    companies: any,
    data: categoryAttribute[]
  ) => {
    //find if company active
    const findShopee = marketplace.filter((market: any) => {
      const isActiveCompany = companies.find((company: any) => {
        if (company.company === market.company && company.isActive === true)
          return company;
      });
      console.log(`isActiveCompany : ${JSON.stringify(isActiveCompany)}`);
      if (
        isActiveCompany &&
        market.marketplace.toLowerCase() === "shopee" &&
        market.isActive === true
      )
        return market;
    });
    console.log(`findShopee : ${findShopee.length}`);
    if (!findShopee || findShopee.length < 1) return;
    let findLastCategoryShopee: string[] = [];
    for (let index = 0; index < findShopee.length; index++) {
      const shopee = findShopee[index];
      console.log(`shopee: ${shopee}`);
      const category = shopee.category[0];
      console.log(`category : ${JSON.stringify(category)}`);
      const data = Object.values(category);
      console.log(`data : ${data}`);
      const findLastCategory: any = data.find(
        (cat: any) => cat.hasChildren === false
      );
      console.log(`findLastCategory : ${JSON.stringify(findLastCategory)}`);
      if (!findLastCategory) return;
      findLastCategoryShopee = [...findLastCategoryShopee, findLastCategory.id];
    }
    let categoryAttributesList: ShopeeProductCategoryAttributeList[] = [];
    //get from redux shopee
    for (let index = 0; index < findLastCategoryShopee.length; index++) {
      const lastCategory = findLastCategoryShopee[index];
      const findCategory = reduxShopeeProductCategoryData.find((cat: any) => {
        if (cat.id === lastCategory) {
          console.log(`lastCategory : ${lastCategory}`);
          return cat;
        }
      });
      //get attribute from redux or get from firebase
      let categoryAttributes: ShopeeProductCategoryAttributeList[] = [];
      //jika belum pernah di get, maka get from firebase
      if (
        !findCategory ||
        !findCategory.attributesList ||
        (findCategory && findCategory.isHasAttributes === "")
      ) {
        categoryAttributes = await getAttributesByMarketplaceAndCategoryId(
          "shopee",
          lastCategory
        );
        console.log(
          `getAttributes from fb : ${JSON.stringify(categoryAttributes)}`
        );
        console.log(
          `categoryAttributes length : ${categoryAttributes?.length}`
        );
        //update to redux shopee, set isHasAttributes and save attributesList
        const reduxMarketplaceProductCategory = [
          ...reduxShopeeProductCategoryData,
        ];
        const newReduxMarketplaceProductCategory =
          reduxMarketplaceProductCategory.map((category) => {
            if (
              category.categoryId?.toString() === lastCategory &&
              (!categoryAttributes ||
                (categoryAttributes && categoryAttributes.length < 1))
            ) {
              console.log(`set isHasAttributes false`);
              return { ...category, isHasAttributes: "false" };
            } else if (
              category.categoryId?.toString() === lastCategory &&
              categoryAttributes &&
              categoryAttributes.length > 0
            ) {
              console.log(`set isHasAttributes true`);
              return {
                ...category,
                isHasAttributes: "true",
                attributesList: categoryAttributes,
              };
            } else {
              return category;
            }
          });
        dispatch(
          ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
            newReduxMarketplaceProductCategory
          )
        );
      }
      if (
        findCategory &&
        findCategory.isHasAttributes === "true" &&
        findCategory.attributesList &&
        findCategory.attributesList.length > 0
      ) {
        console.log(`get attributes from redux`);
        categoryAttributes = findCategory?.attributesList;
      }
      //jika tidak punya attributes, isHasAttributes === "false"
      if (
        (findCategory && findCategory.isHasAttributes === "false") ||
        !categoryAttributes ||
        categoryAttributes.length < 1
      ) {
        console.log(`last category not have attribute`);
        dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
        dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(true));
        return;
      }
      console.log(`categoryAttributes : ${categoryAttributes}`);
      //find in categoryAttributesList, if find add categoryId
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute: ShopeeProductCategoryAttributeList =
          categoryAttributes[index];
        const findAttribute = categoryAttributesList.find(
          (attr: ShopeeProductCategoryAttributeList) =>
            attr.attributeId === attribute.attributeId
        );
        let newValueList =
          attribute.attributeValues &&
          (attribute.inputType === "COMBO_BOX" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX")
            ? [...attribute.attributeValues]
            : undefined;
        const findInDataFromFB = data.find((attr) => {
          console.log(
            `CHECK CA attr from fb : ${
              attr.attributeId?.toString() === attribute.attributeId.toString()
            }`
          );
          console.log(`CHECK CA attr input : ${attr.inputValues}`);
          if (attr.attributeId?.toString() === attribute.attributeId.toString())
            return attr;
        });
        let formatInputValues: any;
        if (findInDataFromFB && findInDataFromFB.inputValues) {
          formatInputValues = findInDataFromFB.inputValues.map((input) => {
            if (
              typeof input === "object" &&
              (attribute.inputType === "MULTIPLE_SELECT" ||
                attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX")
            ) {
              return Object.values(input);
            } else {
              return input;
            }
          });
          console.log(`formatInputValues : `, formatInputValues);
        }
        console.log(`findInDataFromFB : ${JSON.stringify(findInDataFromFB)}`);
        //add custom value to list
        let newAttributeValues: any[] = [];
        if (newValueList && formatInputValues) {
          newAttributeValues =
            formatInputValues[0] && formatInputValues[0].length > 0
              ? formatInputValues[0].filter(
                  (customVal: any) =>
                    customVal && customVal.value === customVal.label
                )
              : formatInputValues[1]
              ? null
              : formatInputValues.filter(
                  (customVal: any) =>
                    customVal && customVal.value === customVal.label
                );
          console.log(`newAttributeValues : `, newAttributeValues);
        }
        let attributeValueToSet = attribute.attributeValues
          ? [...attribute.attributeValues]
          : [];
        if (newAttributeValues && newAttributeValues.length > 0) {
          for (let index = 0; index < newAttributeValues.length; index++) {
            const attr = newAttributeValues[index];
            console.log(`attr : `, attr);
            const newList: ShopeeProductCategoryAttributeValue = {
              valueId: attr?.label,
              displayValueName: attr?.label,
              originalValueName: attr?.label,
            };
            attributeValueToSet.push(newList);
          }
        }
        console.log(`attributeValueToSet : `, attributeValueToSet);
        if (!findAttribute) {
          const newAttribute: ShopeeProductCategoryAttributeList = {
            ...attribute,
            categoryId:
              findInDataFromFB && findInDataFromFB?.categoryId
                ? findInDataFromFB.categoryId
                : [lastCategory],
            inputValues: formatInputValues ? formatInputValues : null,
            attributeValues:
              attributeValueToSet && attributeValueToSet.length > 0
                ? attributeValueToSet
                : undefined,
          };
          categoryAttributesList.push(newAttribute);
        } else {
          //add category
          let newCategoryId: any;
          if (findAttribute.categoryId)
            newCategoryId = [...findAttribute.categoryId, lastCategory];
          categoryAttributesList.map(
            (attr: ShopeeProductCategoryAttributeList) => {
              if (attr.attributeId === attribute.attributeId) {
                return {
                  ...attr,
                  categoryId: newCategoryId,
                  inputValues: formatInputValues ? formatInputValues : null,
                  attributeValues:
                    attributeValueToSet && attributeValueToSet.length > 0
                      ? attributeValueToSet
                      : undefined,
                };
              }
            }
          );
        }
      }
    }
    if (categoryAttributesList.length > 0) {
      categoryAttributesList = categoryAttributesList.sort((a: any, b: any) => {
        if (a.isMandatory && !b.isMandatory) {
          return -1;
        } else if (!a.isMandatory && b.isMandatory) {
          return 1;
        } else {
          return 0;
        }
      });
      const attributes: categoryAttributes = { shopee: categoryAttributesList };
      console.log(`attributes in summary : ${JSON.stringify(attributes)}`);
      dispatch(ReduxProduct.setCategoryAttributes(attributes));
    } else {
      dispatch(ReduxProduct.setCategoryAttributes({ shopee: [] }));
    }
  };

  return handleEdit;
};

export { ProductActionsCell };
