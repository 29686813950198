import MarketplaceProductCategory from "../../../models/MarketplaceProductCategory";

interface InitialState {
  marketplaceShopee: MarketplaceProductCategory[],
}

export interface marketplaceProductCategoryList {
    marketplaceName: string;
    marketplaceProductCategoryData: MarketplaceProductCategory[]
}


const UpdateMarketplaceProductCategory: string = "UpdateMarketplaceProductCategory";

export { UpdateMarketplaceProductCategory };

export default InitialState;