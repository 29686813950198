import firebase from "firebase/compat/app";

interface InitialState {
  userfullname: string;
  email: string;
  password: string;
  phoneNumber: number;
  clientName: string;
  clientLogoURL: string;
  clientCompanyName: string;
  clientWebsite: string;
  clientBillingType: string;
  clientProvince: string;
  clientCity: string;
  clientDistrict: string;
  clientStreet: string;
  clientZipcode: string;
  userrole: string;
  countryCode: string;
  uid?: string;
  verifyExpiredAt?: string;
}
const UpdateRegisterAction: string = "Register";

export default InitialState;
export { UpdateRegisterAction };
