import { useListView } from "../../../../../layout/core/list/ListViewProvider";
import { TemplateToolbar } from "./TemplateToolbar";
import { TemplateGrouping } from "./TemplateGrouping";
import { TemplateSearchComponent } from "./TemplateSearchComponent";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { KTSVG } from "../../../../../../resources/helpers";
import { useTranslation } from "react-i18next";

const TemplateHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { selected } = useListView();
  const { t } = useTranslation();
  if (isTabletOrMobile) {
    return (
      <>
      <div className="menu menu-column menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary pt-6">
          <div className="menu-item here show menu-accordion">
            <span className="menu-link px-5">
              <div className="symbol symbol-50px">
                <span className="menu-icon">
                  <KTSVG
                    path="/media/icons/customsvg/megaphone.svg"
                    className="svg-icon-2hx"
                  />
                </span>
              </div>
              <span className="menu-title">{`${t(
                "Campaign.Title.Template"
              )}`}</span>
            </span>
          </div>
        </div>
      <div className="border-0 d-flex flex-column">
        <div className="card-header border-0 px-5">
          <TemplateSearchComponent />
          {/* begin::Card toolbar */}
          <div className={clsx(`card-toolbar`, isTabletOrMobile ? "w-100" : "")}>
            {selected.length > 0 ? <TemplateGrouping /> : <TemplateToolbar />} :
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
      </div>
      </>
    );
  } else {
    return (
      <div className="card-header border-0 pt-6">
        <TemplateSearchComponent />
        {/* begin::Card toolbar */}
        <div className="card-toolbar">
          <TemplateGrouping />

          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    );
  }
};

export { TemplateHeader };
