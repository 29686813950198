import {FC} from 'react'

type Props = {
  customerInfo?: string
}

const CampaignCustomerInfoCell: FC<Props> = ({customerInfo}) => (
  <div className='text-gray-650 mb-1' data-testid="customerName">{customerInfo}</div> //text-hover-primary
)

export {CampaignCustomerInfoCell}
