import axios from "axios";
import moment from "moment";
import Crypto from "crypto-js";
import * as LC from "../../app/modules/localstorage/index";
import { ApiSettingPush } from "../../app/models/ApiSetting";

const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;

export const generateAccessToken = async (secretKey: string) => {
  const clientId = LC.getClientRef()?.id ?? "";
  const uid = LC.getItemLC("UID");
  const timestamp = moment().unix();
  const sign = generateSign(clientId, uid, timestamp, secretKey);
  const url = `${BASE_URL}/token/${clientId}/generate`;
  const data = {
    uid,
    timestamp,
    sign,
  };
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("generateAccessToken response :", response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err) {
    console.log("Error Request server : " + err);
  }
};

const generateSign = (
  clientId: string,
  uid: string,
  timestamp: number,
  secretKey: string
) => {
  const message = `${clientId}${uid}${timestamp}`;
  const hmac = Crypto.HmacSHA256(message, secretKey);
  return hmac.toString();
};

export const updatePushSetting = async (
  data: Omit<ApiSettingPush, "createdAt" | "updatedAt">
) => {
  const clientId = LC.getClientRef()?.id ?? "";
  const url = `${BASE_URL}/setting/${clientId}/push_notif`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("updatePushSetting response :", response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err) {
    return undefined;
  }
};

export const verifyCallbackUrlSetting = async (url: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/setting/verify_url`,
      {
        url: url,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("verifyCallbackUrlSetting response :", response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err) {
    return undefined;
  }
};
