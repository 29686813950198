/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { toAbsoluteUrl } from "../../resources/helpers/AssetHelpers";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../setup/theme/useTheme";
import { useMediaQuery } from "react-responsive";

export const AuthLayout = () => {
  const { t } = useTranslation();
  const currentTheme = useTheme();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 900px)",
  });

  // function changeLanguage(lng: string) {
  //   i18n.changeLanguage(lng);
  // }
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column -column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/sketchy-1/14.png"
        )})`,
        height: "100vh",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        overflowY: "hidden",
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-5 align-items-center justify-content-evenly">
        {/* begin::Logo */}
        <a href="#" className="m-0 p-0 my-7">
          {/* swith case by theme */}
          <img
            alt="Logo"
            src={toAbsoluteUrl(currentTheme.urlLogoImageWithText)}
            className="h-90px animated-logo"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {isDesktopOrLaptop ? (
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Outlet />
          </div>
        ) : (
          <Outlet />
        )}
        {/* end::Wrapper */}
        {/* begin::Footer */}
        <div className="d-flex flex-center flex-column-auto p-10 m-0">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="#" className="text-muted text-hover-primary px-2">
              {t("Layout.Link.About")}
            </a>
            <a href="#" className="text-muted text-hover-primary px-2">
              {t("Layout.Link.Products")}
            </a>
            <a href="#" className="text-muted text-hover-primary px-2">
              {t("Layout.Link.ContactUs")}
            </a>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Content */}
    </div>
  );
};
