import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

type ContactActionProps = {
  addToExisting?: () => void;
  createNewContact?: () => void;
};

const ContactAction: FC<ContactActionProps> = ({
  addToExisting,
  createNewContact,
}) => {
  const { t } = useTranslation();

  const render = () => {
    return (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        id="ContactActionModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content bg-transparent">
            <div className="modal-body btn-group-vertical ">
              <button
                data-bs-dismiss="modal"
                onClick={createNewContact}
                className="btn btn-secondary"
              >
                {t("HC.Button.AddToContacts.New")}
              </button>
              <button
                type="button"
                className="btn btn-secondary mt-0 mb-0 pt-1 pb-0"
                disabled
              ></button>
              <button
                onClick={addToExisting}
                data-bs-dismiss="modal"
                className="btn btn-secondary"
              >
                {t("HC.Button.AddToContacts.Existing")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default ContactAction;
