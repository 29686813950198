export type ResponseRTS = {
  message: string;
  processId: string;
  data: {
    accountName: string;
    count: number;
  }[];
};

export type ModalExportOrderState = {
  isModalOpen: boolean;
  responseExportOrder?: ResponseRTS;
};
export const initialState: ModalExportOrderState = {
  isModalOpen: false,
  responseExportOrder: undefined,
};
