import { storage } from "../../../db";
import { v4 as uuidv4 } from "uuid";
export const errorType = {
  InvalidURL: "InvalidURL",
  MaxSize: "MaxSize",
  InvalidFiletype: "InvalidFiletype",
  InvalidFormat: "InvalidFormat",
  InvalidResolution: "InvalidResolution",
  UnsupportedFormat: "UnsupportedFormat"
};

export const ImageProductMediaType: string[] = [
  "jpg",
  "jpeg",
  "png"
];

export const VideoMProductediaType: string[] = [
  "mp4",
];

export const productMediaUpload = {
  image: "image",
  video: "video"
}

export const productImageResolution = {
  offsetHeight: 360,
  offsetWidth: 360
}


export const formatSize = (bytes:number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export interface IOption {
  maxfilesize?: number,
  resolution?: {
    width: number,
    height: number
  }
  imageRatio?: 1 | 0
}

/***
 * Validate Product Media File
 */
export const productMediaCheckFile = (
  FileType:string|undefined,
  FileExtensionType:string|undefined, 
  FileSize:number, imgOffsetHeight: number, imgOffsetWidth:number, options?:IOption ) => {

  let result: string|undefined = undefined;
  let error = undefined;
  let maxSize = 0;

  if(FileType === productMediaUpload.image || FileType === productMediaUpload.video){
    result = FileType;
  }else{
    error =errorType.InvalidFormat;
  }

  // Check File Size
  if (result !== undefined) {
    const sizeInMB = (FileSize / (1024*1024));
    if (options?.maxfilesize !== undefined)  {
      if (result === "image" && sizeInMB >= options.maxfilesize) {
        maxSize = options.maxfilesize;
        error = errorType.MaxSize;
      }
    }else {
      if (result === "image" && sizeInMB >= 3) {
        maxSize = 3;
        error =errorType.MaxSize;
      }
    }
    if (result === "video" && sizeInMB >= 20) {
      maxSize = 20;
      error =errorType.MaxSize;
    }
  }

  // Check File Extension
  if (result !== undefined) {
    if (result === "image" && (FileExtensionType !== undefined) && (ImageProductMediaType.includes(FileExtensionType) === false)) {
      error=errorType.InvalidFiletype
    }
    if (result === "video" && (FileExtensionType !== undefined) && (VideoMProductediaType.includes(FileExtensionType) === false)) {
      error=errorType.InvalidFiletype
    }
  }

  //Check Resolution
  if (result !== undefined) {
    if(result === "image" && (imgOffsetHeight !== imgOffsetWidth)){
      error=`${errorType.InvalidResolution} - ${imgOffsetHeight} ${imgOffsetWidth} 1`
    }

    if(result === "image" && (imgOffsetHeight < productImageResolution.offsetHeight || imgOffsetWidth < productImageResolution.offsetWidth)){
      error=`${errorType.InvalidResolution} - ${imgOffsetHeight} ${imgOffsetWidth} 2`
    }
  }

  return [result,error,maxSize]
};

/***
* Upload File to Firebase Storage
 
* file -> file upload
* inputcontentType -> filetype and extenstion from input file (audio/mp3)
* inputFileName -> abc.jpg
* storagePath -> companyID/video/chat/sent/uuid

*/
export const uploadFile = async (file: URL, inputContentType: string, inputFileName: string, storagePath: string) => {
  let fileURL = "";
  let error = ""
  let newfile = await fetch(file).then(r => r.blob()).then(blobFile => new File([blobFile], inputFileName, { type:inputContentType }));
  if (file) {
    const metadata = {
      contentType: inputContentType,
      fileName: inputFileName,
    };
    const uuid = uuidv4();
    const task = storage
      // .ref(companyID + "/" + messageType + "s/chat/sent/" + uuid)
      .ref(storagePath)
      .child(inputFileName)
      .put(newfile, metadata);
    await task
      .then(async (snapshot) => {
        return storage
          // .ref(companyID + "/" + messageType + "s/chat/sent/" + uuid)
          .ref(storagePath)
          .child(inputFileName)
          .getDownloadURL()
          .then((url) => {
            fileURL = url;
          });
      })
      .catch((e) => {
        console.log("error : " + e.message);
        error = e;
      });
  }
  return fileURL;
};
