import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../resources/helpers";
import "../../../styles/css/margin.scss";
import clsx from "clsx";
import { DropdownMenu } from "./DropdownMenu";
import { AsideMenuItemWithSub } from "../aside/AsideMenuItemWithSub";
import { AsideMenuItem } from "../aside/AsideMenuItem";
import AsideMenuLogoutButton from "../aside/AsideLogout";
import { DropdownMenuItem } from "./DropdownMenuItem";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../setup/redux/store";
import { useSelector } from "react-redux";
import { UserRoles } from "../../models/User";
import { getItemLC } from "../../modules/localstorage";

export function DropdownDefault() {
  const { t } = useTranslation();
  const userData = getItemLC("UserData");
  const countUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUnreadMessages
  );
  const countCustomerInQueue: number = useSelector(
    (state: RootState) => state.CIQ.countCIQ
  );
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [customerInQueue, setCustomerInQueue] = useState<number>(0);

  useEffect(() => {
    setUnreadMessages(countUnreadMessages);
    setCustomerInQueue(countCustomerInQueue);
  });

  return (
    <div>
      <button
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasTop"
        // data-bs-dismiss="#offcanvas"
        aria-controls="offcanvasTop"
        id="offcanvas1"
        style={{
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          marginRight: 0,
          paddingRight: 0,
        }}
      >
        <KTSVG
          path="/media/icons/duotune/abstract/abs015.svg"
          className="svg-icon-muted svg-icon-2hx"
        />
      </button>

      <div
        className="offcanvas offcanvas-top w-100 overflow-scroll"
        tabIndex={-1}
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
        style={{
          height: "87vh",
          top: "5vh",
          paddingBottom: "10vh",
          border: 0,
          outline: 0
        }}
        data-bs-backdrop="false"
      >
        {/* <div className="offcanvas-header w-100 align-items-end justify-content-end">
          <button
            type="button"
            className="btn-close m-2 mb-0 pb-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div> */}
        <div className="offcanvas-body w-100 h-auto overflow-scroll">
          <div className="overflow-scroll d-flex flex-column">
            <p
              className="px-9 mt-0 pt-0"
              style={{
                fontWeight: "bolder",
                fontSize: "17px",
                color: "black",
              }}
            >
              {t("StorefrontList.Column.Menu")}
            </p>

            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="#"
                title={t("Storefront.Column.Product")}
                bootstrapIcon="fas fa-box"
              >
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/product/create-product/basic-information"
                    title={t("AddNewProduct.Menu.Title.AddNewProduct")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/product/list"
                    title={t("ProductList.Menu.Title")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/storefront/list"
                    title={t("Storefront.Info.Storefront")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
              </AsideMenuItemWithSub>
            </div>
            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="#"
                title={t("Menu.Order")}
                bootstrapIcon="fas fa-clipboard"
              >
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/order/export"
                    title={t("ExportOrder.Title")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/order/list"
                    title={t("OrderList.Title")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/order/process"
                    title={t("ExportOrderProcess.Title")}
                    id="storefront"
                    hasBullet={true}
                  />
                </div>
              </AsideMenuItemWithSub>
            </div>
            {/* <div data-bs-dismiss="offcanvas">
              <DropdownMenuItem
                to="/list-component"
                title="new list component"
                bbcount={customerInQueue}
                bbcolor="danger"
                id="DD_NLC"
                duotone="/media/icons/duotune/communication/com002.svg"
              />
            </div> */}
            <div data-bs-dismiss="offcanvas">
              <DropdownMenuItem
                to="/ciq/list"
                title={t("SideBar.MenuItem.CIQ")}
                bbcount={customerInQueue}
                bbcolor="danger"
                id="DD_CIQ"
                duotone="/media/icons/duotune/communication/com002.svg"
              />
            </div>
            <div data-bs-dismiss="offcanvas">
              <DropdownMenuItem
                to="/handled-customer"
                title={t("SideBar.MenuItem.HC")}
                bbcount={countUnreadMessages}
                bbcolor="success"
                id="DD_HC"
                duotone="/media/icons/duotune/communication/com010.svg"
              />
            </div>

            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="/contact/contacts"
                title={t("SideBar.MenuItem.Contacts")}
                icon="/media/icons/duotune/communication/com005.svg"
              >
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/contact/list"
                    subMenu="/contact/contact-detail"
                    title={t("SideBar.MenuItem.Customer")}
                    id="AM_contact"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/contact/friendlist"
                    title={t("SideBar.MenuItem.FriendList")}
                    id="AM_friendlist"
                    hasBullet={true}
                  />
                </div>
              </AsideMenuItemWithSub>
            </div>

            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="/campaign/campaigns"
                title={t("Campaign.Title.Campaign")}
                icon="/media/icons/customsvg/megaphone.svg"
                // bootstrapIcon="bi bi-megaphone-fill"
              >
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/campaign/list"
                    subMenu="/campaign/create"
                    title={t("Campaign.Button.CreateCampaign")}
                    id="AM_CreateCampaign"
                    hasBullet={true}
                  />
                </div>
                <div data-bs-dismiss="offcanvas" className="ps-4">
                  <AsideMenuItem
                    to="/template/list"
                    subMenu="/template/create"
                    title={t("Campaign.Info.CreateTemplate")}
                    id="AM_CreateTemplate"
                    hasBullet={true}
                  />
                </div>
              </AsideMenuItemWithSub>
            </div>

            <div
              style={{
                width: "90%",
                alignSelf: "center",
                marginBottom: "10px",
                borderBottom: "0.5px solid rgba(0,0,0,.15)",
              }}
            ></div>

            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="/language"
                title={t("BS.Info.Languages")}
                bootstrapIcon="bi bi-globe"
                type="lang"
              >
                <div className="ps-4">
                  <AsideMenuItem
                    to="/language/selection"
                    type="lang"
                    subMenu="/selection/en"
                    title={t("Global.Languages.en")}
                    id="AM_SelectionEn"
                    hasBullet={true}
                  />
                </div>
                <div className="ps-4">
                  <AsideMenuItem
                    to="/language/selection"
                    type="lang"
                    subMenu="/selection/id"
                    title={t("Global.Languages.id")}
                    id="AM_SelectionId"
                    hasBullet={true}
                  />
                </div>
              </AsideMenuItemWithSub>
            </div>

            <div data-bs-dismiss="offcanvas">
              <DropdownMenuItem
                to="/about"
                title={t("HeaderBar.Button.About")}
                id="DD_about"
                bootstrapIcon="bi bi-info-circle-fill"
              />
            </div>

            <div data-bs-dismiss="offcanvas">
              <DropdownMenuItem
                to="/faq"
                title={t("HeaderBar.Button.FAQ")}
                id="DD_faq"
                bootstrapIcon="bi bi-question-circle-fill"
              />
            </div>

            <div
              style={{
                width: "90%",
                alignSelf: "center",
                marginBottom: "10px",
                borderBottom: "0.5px solid rgba(0,0,0,.15)",
              }}
            ></div>
            <div className="px-5 py-1">
              <AsideMenuItemWithSub
                to="/setting"
                icon="/media/icons/duotune/coding/cod001.svg"
                title={t("HeaderBar.Button.Settings")}
              >
                <AsideMenuItemWithSub
                  to="#"
                  title={t("WABAAccount.Menu.WhatsAppBusines")}
                  hasBullet={true}
                >
                  <div data-bs-dismiss="offcanvas" className="ps-10">
                    <AsideMenuItem
                      to="/setting/waba-account/create/sign-up"
                      title={t("WABAAccount.Submenu.AddWhatsAppBusinesAccount")}
                      id="WABA_ADD"
                      hasBullet={true}
                      bbcolor="dark"
                    />
                  </div>
                  <div data-bs-dismiss="offcanvas" className="ps-10">
                    <AsideMenuItem
                      to="/setting/waba-account/list"
                      title={t(
                        "WABAAccount.Submenu.WhatsAppBusinesAccountList"
                      )}
                      id="WABA_LIST"
                      hasBullet={true}
                      bbcolor="dark"
                    />
                  </div>
                </AsideMenuItemWithSub>

                <AsideMenuItemWithSub
                  to="#"
                  title={t("Marketplace.Menu.Marketplace")}
                  hasBullet={true}
                >
                  <div data-bs-dismiss="offcanvas" className="ps-10">
                    <AsideMenuItem
                      to="/setting/marketplace/marketplace-list"
                      title={t("Marketplace.List")}
                      id="MARKETPLACE_LIST"
                      hasBullet={true}
                      bbcolor="dark"
                    />
                  </div>
                  <div data-bs-dismiss="offcanvas" className="ps-10">
                    <AsideMenuItem
                      to="#"
                      title={t("Marketplace.Shipment")}
                      id="MARKETPLACE_SHIPMENT"
                      hasBullet={true}
                      bbcolor="dark"
                    />
                  </div>
                </AsideMenuItemWithSub>

                <div data-bs-dismiss="offcanvas" className="ps-10">
                  <AsideMenuItem
                    to="/setting/api"
                    title={t("API.Setting.Menu")}
                    id="API_SETTING"
                    bbcolor="dark"
                  />
                </div>
                {userData && userData.role === UserRoles.admin && (
                  <div data-bs-dismiss="offcanvas" className="ps-10">
                    <AsideMenuItem
                      to="/setting/user-management"
                      title={t("UserManagement.Title")}
                      id="USER_MANAGEMENT"
                      bbcolor="dark"
                    />
                  </div>
                )}
              </AsideMenuItemWithSub>
            </div>
            <div className="px-5 py-1" data-bs-dismiss="offcanvas">
              <AsideMenuLogoutButton />
            </div>

            <div
              style={{
                width: "90%",
                alignSelf: "center",
                marginBottom: "10px",
                borderBottom: "0.5px solid rgba(0,0,0,.15)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
