import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  KTSVG,
  checkIsActive,
  toAbsoluteUrl,
} from "../../../resources/helpers";
import { useLocation } from "react-router-dom";
import "../../../styles/css/color.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../setup/translations/i18next";
import i18next from "../../../setup/translations/i18next";
import Modal from "../../components/Modal";
import * as lc from "../../modules/localstorage/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUser, setAuth } from "../../modules/auth/redux/AuthSlice";
import { logout } from "../../../api";
import { setUserOffline } from "../../../api/server/connection";
import { deleteMessage } from "../../modules/chat/redux/ChatSlice";
import { cleanReduxCIQ } from "../../modules/ciq/redux/CIQSlice";
import { cleanReduxContact } from "../../modules/contact/redux/ContactSlice";
import * as Log from "../../../util/SDayslogger";
import { cleanReduxShipment } from "../../modules/product/shipment/redux/ShipmentsSlice";
import { cleanReduxProduct } from "../../modules/product/redux/ProductSlice";
import { cleanReduxMarketplaceProductCategory } from "../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { persistor } from "../../../setup/redux/store";
import { cleanReduxTemplate } from "../../modules/template/list/TemplateListSlice";
import { cleanReduxCampaign as cleanReduxCampaignList } from "../../modules/campaign/list/CampaignListSlice";
import { cleanReduxCampaign } from "../../modules/campaign/redux/CampaignSlice";
import { cleanReduxProductList } from "../../modules/product/productList/ProductListSlice";
import { cleanReduxStorefrontList } from "../../modules/product/storefront/storefrontList/StorefrontListSlice";

type Props = {
  to: string;
  title: string;
  duotone?: string;
  bootstrapIcon?: string;
  fontIcon?: string;
  type?: string;
  bbcount?: number;
  bbcolor?:
    | "white"
    | "primary"
    | "light"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "dark";
  id?: string;
};

const DropdownMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  duotone,
  bootstrapIcon,
  fontIcon,
  bbcount = 0,
  bbcolor,
  id,
  type = "link",
}) => {
  const location = useLocation();
  const isActive = checkIsActive(location.pathname, to);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [activeLang, setActiveLang] = useState(i18next.language);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const logoutHandler = () => {
    const currentUser = lc.getItemLC(lc.LCName.User);
    const sessionid = lc.getItemLC(lc.LCName.SessionID);

    if (currentUser === null || sessionid === null) {
      dispatch(setAuth(false));
      nav("/auth");
    } else {
      logout()
        .then(() => {
          setUserOffline(currentUser.uid, sessionid);
          dispatch(deleteUser());
          lc.removeSession();
          dispatch(setAuth(false));
          dispatch(deleteMessage);
          dispatch(cleanReduxCIQ(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxShipment(true));
          dispatch(cleanReduxProduct(true));
          dispatch(cleanReduxMarketplaceProductCategory(true));
          dispatch(deleteUser());
          dispatch(deleteMessage(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxTemplate(true));
          dispatch(cleanReduxCampaign(true));
          dispatch(cleanReduxProductList(true));
          dispatch(cleanReduxStorefrontList(true));
          dispatch(cleanReduxCampaignList(true));
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          nav("/auth");
        })
        .catch((error) => {
          // Log.SDayslogger(
          //     nav,
          //     "Testing Error Message",
          //     Log.SDLOGGER_INFO,
          //     false,
          //     true
          // );
          console.log("failed logout");
        });
    }
  };

  const changeLanguageHandler = (lang: string) => {
    if (lang === "id") {
      i18n.changeLanguage("id");
      setActiveLang("id");
    } else {
      i18n.changeLanguage("en");
      setActiveLang("en");
    }
  };

  return (
    <div
      className="menu-item w-100 px-5 py-1 text-black"
      id={id}
      // style={{
      //   borderBottom: "1px solid rgba(0,0,0,.50)",
      // }}
      // data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      {type === "link" ? (
        <Link
          className={clsx("menu-link without-sub text-black", { active: isActive })}
          to={to}
        >
          <div className="w-100 d-flex flex-row align-items-center text-black">
            <div className="align-items-center justify-content-center">
              {duotone && (
                <span className="menu-icon">
                  <KTSVG
                    path={duotone}
                    className="svg-icon-2hx svg-icon-dark"
                  />
                </span>
              )}
              {bootstrapIcon && (
                <div className="symbol symbol-50px">
                  <span className="menu-icon justify-content-center">
                    <i className={clsx(bootstrapIcon, "fs-1")} />
                  </span>
                </div>
              )}
            </div>
            <span
              className="menu-text py-0"
              style={{
                marginLeft: "10px",
              }}
            >
              {title}
            </span>
            <span className="menu-badge">
              {bbcount > 0 && (
                <span className="menu-title menu-badge">
                  <span
                    className={clsx(
                      "badge badge-circle fw-bolder fs-7",
                      `bg-${bbcolor}`
                    )}
                  >
                    {bbcount}
                  </span>
                </span>
              )}
            </span>
          </div>
        </Link>
      ) : type === "language" ? (
        <div
          className={clsx("dropdown menu-link without-sub", {
            active: false,
          })}
        >
          {duotone && (
            <span className="menu-icon p-0 m-0">
              <KTSVG
                path={duotone}
                className="svg-icon-2x svg-icon-dark p-0 m-0"
              />
            </span>
          )}
          {bootstrapIcon && (
            <span className="menu-icon justify-content-center p-0 m-0">
              <i className={clsx(bootstrapIcon, "fs-1 p-0 m-0")} />
            </span>
          )}
          <p
            className="w-100 dropdown-toggle py-0 px-3 m-0"
            id="menuDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "1.1rem", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {activeLang === "en"
              ? t("Global.Languages.en")
              : t("Global.Languages.id")}
          </p>
          <ul className="dropdown-menu w-100" aria-labelledby="menuDropdown">
            <li>
              <p
                className="dropdown-item m-0 py-0 px-2"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  changeLanguageHandler("id");
                }}
              >
                {t("Global.Languages.id")}
              </p>
            </li>
            <li>
              <hr className="dropdown-divider"></hr>
            </li>
            <li>
              <p
                className="dropdown-item m-0 py-0 px-2"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  changeLanguageHandler("en");
                }}
              >
                {t("Global.Languages.en")}
              </p>
            </li>
          </ul>
        </div>
      ) : type === "logout" ? (
        <div
          className="menu-link without-sub"
          onClick={() => setIsModalOpen(true)}
        >
          <div className="d-flex flex-row w-100">
            {duotone && (
              <span className="menu-icon p-0 m-0">
                <KTSVG
                  path={duotone}
                  className="svg-icon-2x svg-icon-dark p-0 m-0"
                />
              </span>
            )}
            {bootstrapIcon && (
              <span className="menu-icon justify-content-center p-0 m-0">
                <i className={clsx(bootstrapIcon, "fs-1 p-0 m-0")} />
              </span>
            )}
            <span className="menu-text py-0 px-3 m-0">{title}</span>
            <span className="menu-badge">
              {bbcount > 0 && (
                <span className="menu-title menu-badge">
                  <span
                    className={clsx(
                      "badge badge-circle fw-bolder fs-7",
                      `bg-${bbcolor}`
                    )}
                  >
                    {bbcount}
                  </span>
                </span>
              )}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Modal
        id="ConfirmLogoutModal"
        title={t("Logout.Modal.Title")}
        confirmOption={t("Common.Yes")}
        cancelOption={t("Common.No")}
        isModalOpen={isModalOpen}
        onConfirm={() => logoutHandler()}
        onCancel={() => setIsModalOpen(false)}
        centered={true}
      >
        <span>{t("Logout.Modal.Info")}</span>
      </Modal>
    </div>
  );
};

export { DropdownMenuItem };
