import React from "react";
import { KTSVG } from "../../../resources/helpers";

type ModalProps = {
  id: string;
  title: string;
  body: string | HTMLElement | JSX.Element;
  image?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onOther?: () => void;
  otherOption?: string;
  confirmOption: string;
  cancelOption?: string;
  isOther?: boolean;
};

const ModalConfirmation: React.FC<ModalProps> = ({
  id,
  title,
  body,
  onConfirm,
  onCancel,
  isOther,
  onOther,
  otherOption,
  confirmOption,
  cancelOption,
}) => {
  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {/* <p>{body}</p> */}
            {body}
          </div>
          <div className="modal-footer">
            {cancelOption && (
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={onOther ? onOther : onCancel}
              >
                {cancelOption}
              </button>
            )}
            {confirmOption && (
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={onConfirm ? onConfirm : onCancel}
              >
                {confirmOption}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;