import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../resources/helpers/components/KTCardBody";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { Order, OrderStatus7Days } from "../../../../../models/Order";
import { Process } from "../../../../../models/Process";
import { isSameStatusValue } from "../../ProcessPage";
import { useFirestoreTable } from "../context/TableContext";
import processColumns from "./columns/_columns";

const statusWithAction: string[] = [OrderStatus7Days.NewOrder];
const statusWithLimit: string[] = [
  OrderStatus7Days.NewOrder,
  OrderStatus7Days.ReadyToShip,
];

export default function ProcessTable() {
  const { t } = useTranslation();

  const columns = useMemo(() => processColumns, []);

  const {
    data: orderData,
    isLoading,
    isFetching,
    tableState,
  } = useFirestoreTable();

  // const statusFilter = tableState.filters?.find(
  //   (fil) => fil.field === ""
  // )?.value;

  // const displayColumnID = columns
  //   .filter((col) => {
  //     if (
  //       !(
  //         col.id === "action" ||
  //         col.id === "selection" ||
  //         col.id === "shippingLimit"
  //       )
  //     ) {
  //       return true;
  //     }
  //     if (
  //       (col.id === "action" || col.id === "selection") &&
  //       statusFilter &&
  //       isSameStatusValue(statusFilter, statusWithAction)
  //     ) {
  //       return true;
  //     }
  //     if (
  //       col.id === "shippingLimit" &&
  //       statusFilter &&
  //       isSameStatusValue(statusFilter, statusWithLimit)
  //     ) {
  //       return true;
  //     }

  //     return false;
  //   })
  //   .map((col) => col.id);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data: orderData?.items || [],
    });

  return (
    <>
      {/* <div className="card-body"> */}
      {isLoading && <Loading />}
      <div
        className="table-responsive px-2"
        style={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 10 }}
          >
            <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Process>, headerIndex) => {
                return column.render("Header", {
                  ...column.getHeaderProps(),
                  key: headerIndex,
                });
              })}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold">
            {rows.length > 0
              ? rows.map((row: Row<Process>, rowIndex) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  rowProps.key = rowIndex;
                  return (
                    <tr {...rowProps}>
                      {row.cells.map((cell, cellIndex) => {
                        return cell.render("Cell", {
                          ...cell.getCellProps(),
                          key: cellIndex,
                        });
                      })}
                    </tr>
                  );
                })
              : !(isLoading || isFetching) && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("OrderList.NoData")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      {/* </div> */}
    </>
  );
}
