import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import '../../../../../../styles/css/radiobuttons.css'
import { InteractiveMessage, InteractiveButton } from "../../../../../modules/template/redux/TemplateRedux";
import * as ReduxTemplate from "../../../../../modules/template/redux/TemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../setup/redux/store";
import { t } from "i18next";


const InteractiveSelection = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const selectedTemplateCategory = useSelector((state: RootState) => state.Template.templateCategory);
  const selectedInteractiveMessage = useSelector((state: RootState) => state.Template.selectedInteractiveMessage);

  useEffect(() => {
    if(selectedInteractiveMessage === InteractiveMessage.Button) {
      dispatch(ReduxTemplate.setSelectedInteractiveButton(InteractiveButton.QuickReply));
    }
  }, [selectedInteractiveMessage])

  const radioButtonHandler = (type: any) => {
    document.getElementById("danger-interactive")?.classList.add("d-none");
    if (type === InteractiveMessage.List) {
      dispatch(ReduxTemplate.setSelectedInteractiveMessage(InteractiveMessage.List));
    } else if (type === InteractiveMessage.Button) {
      dispatch(ReduxTemplate.setSelectedInteractiveMessage(InteractiveMessage.Button));
    }
  }

  return (              
        <>
        <div className="step-sub-title step-sub-title mt-5 mb-5 fs-2" data-testid="header-interactive-selection">
          <label>{t('Campaign.TemplateCategory.InteractiveMessageSelection')}</label>
        </div>
        <p data-testid="danger-interactive" id="danger-interactive" className={clsx(`text-danger fs-1x`)}>{t('Campaign.TemplateCategory.InteractiveMessage.ErrorAlert')}</p>
        <div className={clsx(`radio-buttons justify-content-center`, isTabletOrMobile ? "flex-column" : "")}>
          <div className="m-3 radio-button-wrapper">
            <label><input className={clsx(isTabletOrMobile ? `mb-2` : ``)} id="radio_list_message" disabled type="radio" name="optradio" data-testid="radio_list_message" onClick={() => radioButtonHandler(InteractiveMessage.List)} /> <b>{t('Campaign.TemplateCategory.IMS.ListMessage.Header')}</b> </label>
            <div>
              {t('Campaign.TemplateCategory.IMS.ListMessage.Body')}
            </div>
            {!isTabletOrMobile && <br />}
            <div className="mt-4">
              <img src="/media/images/interactive.png" />
            </div>
          </div>
          {isTabletOrMobile ? <p>&nbsp;</p> : null}
          <div className="m-3 radio-button-wrapper">
            <label><input className={clsx(isTabletOrMobile ? `mb-2` : ``)} id="radio_button_message" type="radio" name="optradio" data-testid="radio_button_message" onClick={() => radioButtonHandler(InteractiveMessage.Button)} /> <b>{t('Campaign.TemplateCategory.IMS.ReplyButton.Header')}</b></label>
            <div>
              {t('Campaign.TemplateCategory.IMS.ReplyButton.Body')} 
            </div>
            <div className="mt-4">
              <img src="/media/images/button.png" />
            </div>
          </div>
        </div>
      </>
  );
};

export default InteractiveSelection;