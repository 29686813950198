import "@popperjs/core";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import clsx from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../api/index";
import { setUserOffline } from "../../api/server/connection";
import { MenuComponent } from "../../resources/assets/ts/components";
import { toAbsoluteUrl } from "../../resources/helpers/AssetHelpers";
import Avatar from "../../styles/components/Avatar";
import * as Log from "../../util/SDayslogger";
import { Toolbar } from "../layout/toolbar/Toolbar";
import { deleteUser, setAuth } from "../modules/auth/redux/AuthSlice";
import { cleanReduxCampaign } from "../modules/campaign/list/CampaignListSlice";
import {
  deleteAllChatMessage,
  deleteMessage,
  setAllChatSelectedChat,
  setAllChatSelectedRoom,
  setSelectedChat,
  setSelectedRoom,
} from "../modules/chat/redux/ChatSlice";
import { cleanReduxCIQ } from "../modules/ciq/redux/CIQSlice";
import { cleanReduxContact } from "../modules/contact/redux/ContactSlice";
import * as lc from "../modules/localstorage/index";
import { cleanReduxMarketplaceProductCategory } from "../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { cleanReduxProduct } from "../modules/product/redux/ProductSlice";
import { cleanReduxShipment } from "../modules/product/shipment/redux/ShipmentsSlice";
import { cleanReduxStorefront } from "../modules/product/storefront/StorefrontSlice";
import { cleanReduxStorefrontList } from "../modules/product/storefront/storefrontList/StorefrontListSlice";
import { cleanReduxTemplate } from "../modules/template/list/TemplateListSlice";
import { Content } from "./Content";
import { ShortcutBar } from "./Shorcut";
import { AsideDefault } from "./aside/AsideDefault";
import { PageDataProvider } from "./core";
import { DropdownDefault } from "./dropdown/DropdownDefault";
import { CustomHeader } from "./header/CustomHeader";
import { HeaderUserMenu } from "./header/header-menus/HeaderUserMenu";
import { useTheme } from "../../setup/theme/useTheme";
import ContentTitle from "./header/ContentTitle";
import i18n from "../../setup/translations/i18next";
import i18next from "../../setup/translations/i18next";
import { KTSVG } from "../../resources/helpers";
import Draggable from "react-draggable";
import { throttle } from "lodash";

const MasterLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentTheme = useTheme();
  const [isSidebarMinimized, setIsSidebarMinimized] = useState<boolean>(false);
  const nav = useNavigate();

  useEffect(() => {
    document.body.classList.add("bg-white");
    const user = lc.getItemLC(lc.LCName.User);
    if (!user) {
      nav("/auth");
    }
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, [nav]);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    const element = document.getElementById("kt_aside");

    if (!element) return;

    const handleResize = throttle((entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        const componentWidth = entry.contentRect.width;
        if (componentWidth < 100 && !isSidebarMinimized) {
          requestAnimationFrame(() => {
            setIsSidebarMinimized(true);
          });
        } else if (componentWidth >= 100 && isSidebarMinimized) {
          requestAnimationFrame(() => {
            setIsSidebarMinimized(false);
          });
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      handleResize.cancel();
    };
  }, [isSidebarMinimized]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 900px)",
  });

  const client = lc.getItemLC(lc.LCName.Client);
  const user = lc.getItemLC(lc.LCName.UserData);

  let userName = user && user.name ? user.name : "7days User";
  if (userName.length > 20) {
    userName = userName.substring(0, 17) + "...";
    console.log("userName after replace : ", userName);
  }
  let clientName =
    client && client.clientName ? client.clientName : "Unnamed Client";
  if (clientName.length > 20) {
    clientName = clientName.substring(0, 17) + "...";
  }

  const draggableRef = useRef<HTMLDivElement | null>(null);

  const LayoutWeb = () => (
    <PageDataProvider>
      <div
        className="page d-flex flex-column bg-light"
        style={{ overflow: "hidden", width: "100%", height: "100%" }}
      >
        <div
          className="d-flex flex-row w-100"
          id="kt_wrapper"
          style={{ height: "100%" }}
        >
          <AsideDefault />
          <div className="position-relative top-0">
            <Draggable nodeRef={draggableRef}>
              <div
                id="kt_aside_toggle"
                ref={draggableRef}
                className="p-1 rounded d-flex align-items-center justify-content-center aside-toggle bg-white p-0 shadow"
                data-kt-toggle="true"
                data-kt-toggle-state="active"
                data-kt-toggle-target="body"
                data-kt-toggle-name="aside-minimize"
                style={{
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  top: "10px",
                  right: -10,
                  cursor: "pointer",
                  zIndex: 99900,
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr080.svg"}
                  className="svg-icon-1 rotate-0 flipX justify-content-end m-0 p-0 fs-1"
                />
              </div>
            </Draggable>
          </div>
          <div id="kt_content" className="d-flex flex-column w-100">
            <CustomHeader />
            <Toolbar />
            <div className="post d-flex flex-column-fluid me-n5 pe-5 h-450px">
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  );

  const LayoutMobile = () => (
    <Fragment>
      <div
        className="container page d-flex flex-column flex-column-fluid bg-light"
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingBottom: "10px",
          marginBottom: "50px",
        }}
      >
        <div className={clsx("page d-flex flex-row flex-column-fluid overflow-hidden")}>
          <div className="w-100 d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <nav className="navbar navbar-expand-lg navbar-light px-2" style={{
              zIndex: 99999,
              height: '5vh'
            }}>
              <div className="w-100 d-flex flex-row list-inline align-items-center justify-content-center">
                <div className="flex-fill w-75">
                  <Link to="/dashboard" className="navbar-brand">
                    <img
                      alt="7days"
                      src={toAbsoluteUrl(currentTheme.urlLogoImage)}
                      className="img-fluid w-50"
                    ></img>
                  </Link>
                </div>
                <div className="d-flex flex-row w-auto h-100 align-items-center justify-content-center">
                  <DropdownDefault />
                  {/* <div className="px-5">
                    <Avatar
                      id="active_user_avatar_mobile"
                      data-testid="avatar"
                      height="30"
                      width="30"
                      imgRadius="50%"
                      imgSrc={
                        client.avatar
                          ? client.avatar
                          : "/media/icons/avatar/client-icon.png"
                      }
                    />
                  </div> */}
                </div>
              </div>
            </nav>
            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid pt-0"
            >
              <div
                className="post d-flex flex-column-fluid h-100"
                id="kt_post"
              >
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
        <ShortcutBar />
      </div>
    </Fragment>
  );

  const layout = isDesktopOrLaptop ? LayoutWeb() : LayoutMobile();

  return layout;
};

export { MasterLayout };
