import clsx from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { getAccountByCompanyAndMarketplace } from "../../../../../../actions/account";
import * as actions from "../../../../../../actions/actions";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import * as ServProduct from "../../../../../../db/serviceProduct";
import { isMobileDevice } from "../../../../../../resources/assets/ts/_utils";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import "../../../../../../styles/css/select.css";
import {
  ShipmentsType,
  attributeList,
  attributeValueList,
  productStatus,
  productStep,
} from "../../../../../models/Product";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import ModalConfirmationWithStateHandler from "../../../../../modules/modal/modalConfirmationWithStateHandler";
import ModalDangerousProductInformation from "../../../../../modules/modal/modalDangerousProductInformation";
import { ProductHeaderTitle } from "../../../../../modules/product/productHeader/ProductHeaderTitle";
import { ProductStep } from "../../../../../modules/product/productHeader/productStep";
import * as ReduxProductList from "../../../../../modules/product/productList/ProductListSlice";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import ShipmentCard from "../../../../../modules/product/shipment/ShipmentCard";
import * as ReduxShipments from "../../../../../modules/product/shipment/redux/ShipmentsSlice";
import { Loading } from "../../../../../modules/util/Loading";
import { uploadFile } from "../../../../../modules/util/Utils";
import { PageLink, PageTitle } from "../../../../core";
import ProductMobileIcon from "./ProductMobileIcon";

interface ShipmentOption {
  isSummary?: boolean;
  isFromSummary?: boolean;
  onClickUpload?: boolean;
}

const Shipments: React.FC<ShipmentOption> = ({
  isSummary = false,
  onClickUpload = false,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();

  const rd_isDangerousProduct = useSelector(
    (state: RootState) => state.Product.isDangerousProduct
  );

  const rd_Shipments = useSelector(
    (state: RootState) => state.Product.shipments
  );

  const rd_tempShipments = useSelector(
    (state: RootState) => state.Product.tempShipments
  );

  const rd_isFromSummary = useSelector(
    (state: RootState) => state.Product.isFromSummary
  );

  const shipmentsList = useSelector(
    (state: RootState) => state.Shipments.listShipments
  );

  const summaryShipmentsList = useSelector(
    (state: RootState) => state.Shipments.summaryListShipments
  );

  const rd_SelectedCompanies = useSelector(
    (state: RootState) => state.Product.companies
  );

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const useruid = lc.getItemLC(lc.LCName.UserID);
  const refUser = createRef("users", useruid);
  const rd_VideoYoutubeURL = useSelector(
    (state: RootState) => state.Product.videoYoutubeURL
  );
  const rd_PhotosCoverIndex = useSelector(
    (state: RootState) => state.Product.coverIndex
  );
  const rd_ProductPhotos = useSelector(
    (state: RootState) => state.Product.productPhotos
  );
  const rd_ProductVideo = useSelector(
    (state: RootState) => state.Product.productVideo
  );

  const reduxNewProduct = useSelector(
    (state: RootState) => state.Product.newProduct
  );
  const reduxProductWeight = useSelector(
    (state: RootState) => state.Product.productWeight
  );
  const reduxProductDimensionLength = useSelector(
    (state: RootState) => state.Product.productDimensionLength
  );
  const reduxProductDimensionWidth = useSelector(
    (state: RootState) => state.Product.productDimensionWidth
  );
  const reduxProductDimensionHeight = useSelector(
    (state: RootState) => state.Product.productDimensionHeight
  );

  const rd_isProductHaveVariants = useSelector(
    (state: RootState) => state.Product.isProductHaveVariants
  );

  const rd_Variants = useSelector((state: RootState) => state.Product.variants);

  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const rd_productID = useSelector(
    (state: RootState) => state.Product.productID
  );
  const rd_isCreatedProduct: any = useSelector(
    (state: RootState) => state.Product.isCreateProduct
  );
  const rd_tempSelectedStorefront: string = useSelector(
    (state: RootState) => state.Product.tempSelectedStorefront
  );
  const rd_selectedBrandData = useSelector(
    (state: RootState) => state.Product.selectedBrandData
  );
  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const isUpdate = rd_productID && rd_isCreatedProduct;
  const URI = isUpdate ? "/product/update" : "/product/create-product";

  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "/product/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.BPI"),
      path: `${URI}/basic-information`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Title.Media"),
      path: `${URI}/media-upload`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("AddNewProduct.Info.Weight"),
      path: `${URI}/package-weight`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const UsersBreadcrumbsForSummary: Array<PageLink> = [
    {
      title: t("AddNewProduct.Title.Summary"),
      path: `${URI}/summary`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isModalFromSummary, setModalFromSummary] = useState(false);
  const [dangerousProductValue, setDangerousProductValue] = useState("");
  const [isValidDangerousProduct, setIsValidDangerousProduct] =
    useState<boolean>(true);
  const [isValidSelectedShipment, setIsValidSelectedShipment] =
    useState<boolean>(true);

  const dataDangerousProductInformation: any = [
    {
      type: "AddNewProduct.Modal.DG.Column.Flammableliquid",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow1",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Productcontainsabattery",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow2",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Liquidproduct",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow3",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Productcontainschemical",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow4",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Productinaerosolform",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow5",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Narcoticsorpsychotropics",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow6",
    },
    {
      type: "AddNewProduct.Modal.DG.Column.Productscontainsamagnet",
      example: "AddNewProduct.Modal.DG.Column.ExampleRow7",
    },
  ];

  const DangerousProductInfo = () => {
    return (
      <div data-testid="data-dangerousproductinfo">
        <span>{t("AddNewProduct.Modal.Info.DangerousGoods")}</span>
        <div className="mt-3">{t("AddNewProduct.Modal.Info.CDG")} : </div>
        <div className="px-5 w-100 bg-white rounded">
          <table className="table table-row-dashed table-row-gray-300">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800 text-center">
                <th>{t("AddNewProduct.Modal.DG.Column.TypeofProducts")}</th>
                <th>{t("AddNewProduct.Modal.DG.Column.ExampleofProducts")}</th>
              </tr>
            </thead>
            <tbody>
              {dataDangerousProductInformation.map((data: any) => {
                return (
                  <tr className="w-100 p-0" key={data.type}>
                    <td className="w-50 px-5">{t(data.type)}</td>
                    <td className="w-50">{t(data.example)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const isAllShipmentNotActive = (shipments: any[]) => {
    if (shipments.length === 0) {
      return true;
    }
    return shipments.every((shipment) => shipment.isActive === false);
  };

  const [isTouched, setIsTouched] = useState(false);

  const adjustChildScrollPosition = () => {
    setTimeout(() => {
      const itemsActive: any =
        document.getElementsByClassName("error-shipmenttype");
      if (itemsActive.length > 0) {
        for (let i = 0; i < itemsActive.length; i++) {
          const childPos = itemsActive[0];
          childPos.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 200);
  };

  const titleRef: any = useRef(null);

  const handleBack = () => {
    dispatch(ReduxProduct.setFromSummary(false));
    setIsTouched(false);
    nav(`${URI}/package-weight`);
  };

  const shipmentValidation = () => {
    let validation: any = [];
    for (let index = 0; index < rd_Shipments.length; index++) {
      const element = rd_Shipments[index];
      const getShipment: any = shipmentsList?.find(
        (shipment: any) => shipment.id === element.id
      );
      const shipment = rd_Shipments.find(
        (shipment: any) => shipment.id === element.id
      );
      if (
        shipment &&
        shipment.isActive &&
        getShipment?.children.length > 0 &&
        shipment?.types.length <= 0
      ) {
        validation = [...validation, false];
      } else {
        validation = [...validation, true];
      }
    }
    console.log("validation : ", validation);
    if (validation.includes(false)) {
      return;
    }
    if (rd_isFromSummary) {
      nav(`${URI}/summary/`);
    } else if (!isSummary) {
      nav(`${URI}/select-company-and-marketplace`);
    } else {
      return;
    }
  };

  const handleNext = () => {
    //get all classname error
    setIsTouched(true);
    let validationDangerousProduct: boolean = true;
    if (!dangerousProductValue) {
      if (typeof window !== "undefined") {
        // mobile
        window.scrollTo({ top: 0, behavior: "smooth" });
        // desktop
        titleRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsValidDangerousProduct(false);
      validationDangerousProduct = false;
    } else {
      // Scroll Shipping Error
      adjustChildScrollPosition();
    }
    if (isAllShipmentNotActive(rd_Shipments)) {
      if (typeof window !== "undefined") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        titleRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsValidSelectedShipment(false);
      return;
    } else {
      setIsValidSelectedShipment(true);
    }
    if (validationDangerousProduct === false) return;
    if (rd_Shipments.length > 0) {
      shipmentValidation();
    }
    dispatch(ReduxProduct.setFromSummary(false));
  };

  useEffect(() => {
    console.log("masuk di useeffect onClickUpload, isSummary");
    if (onClickUpload && isSummary) {
      console.log("masuk di useeffect onClickUpload, isSummary, handleNext");
      handleNext();
    }
  }, [onClickUpload, isSummary]);

  useEffect(() => {
    if (isTouched && isAllShipmentNotActive(rd_Shipments)) {
      setIsValidSelectedShipment(false);
    } else {
      setIsValidSelectedShipment(true);
    }
  }, [rd_Shipments]); // eslint-disable-line

  useEffect(() => {
    console.log("red danger, is dangerous have vars", rd_isDangerousProduct);
    if (rd_isDangerousProduct !== "") {
      const productDangerous =
        rd_isDangerousProduct === "true" ? "true" : "false";
      setDangerousProductValue(productDangerous);
      setIsValidDangerousProduct(true);
    }
  }, [rd_isDangerousProduct]);

  const onDangerousProductValueChange = (e: any) => {
    const dangerousProductvalue = e.target.value;
    const dangerousProduct = dangerousProductvalue === "true" ? true : false;
    setDangerousProductValue(dangerousProductvalue);
    setIsValidDangerousProduct(true);
    let newProduct = { ...reduxNewProduct };
    newProduct.isDangerousProduct = dangerousProduct;
    dispatch(ReduxProduct.setIsDangerousProduct(dangerousProductvalue));
    dispatch(ReduxProduct.setNewProduct(newProduct));
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxProduct.cleanReduxProduct(true));
    nav("/product/list");
  };

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const handleSavedChanges = async () => {
    setLoading(true);
    console.log("isdangerous product :", rd_isDangerousProduct);
    let resultFirestorageURL: string[] = [];
    let resultPhotosMediaID: string[] = [];
    let resultVideoMediaID: string = "";
    let isSuccess = true;
    let productID = "";
    let resultShipmentsId: string[] = [];
    let resultMarketplaceId: string[] = [];
    let resultVariantId: string[] = [];
    let resultProductVariantId: string[] = [];
    let resultProductVariantPhoto: string[] = [];

    let variantCategoriesData: any[] = [];
    // Save Data
    let newProduct = { ...reduxNewProduct };
    newProduct.status = productStatus.drafted;
    newProduct.lastStep = productStep.onShipment;
    let clientRef: any;
    const lcClient = lc.getItemLC(lc.LCName.Client);
    if (lcClient) {
      clientRef = createRef("clients", lcClient.id);
      newProduct.client = clientRef;
    }
    if (!newProduct || !newProduct.id || newProduct.id === "") {
      //Create Product
      productID = await actions.createDataProduct(newProduct);
      console.log("Saved Product : " + productID);
      newProduct.id = productID;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    } else {
      //Update Product
      productID = newProduct.id;
      newProduct.updatedAt = Timestamp.now();
      newProduct.updatedBy = refUser;
      actions.updateDataProduct(productID, newProduct);
      actions.deleteMediaDocData(productID);
      actions.deleteShipmentsData(productID);
      actions.deleteCompanyAndMarketplacesData(productID);
      actions.removeDataVariants(productID);
      actions.removeDataProductVariants(productID);
      actions.removeDataCategoryAttributes(productID);
    }
    //update numberofproducts storefront realtime db
    if (
      reduxNewProduct &&
      reduxNewProduct.storeFront &&
      reduxNewProduct.storeFront.id
    ) {
      const storefrontId = reduxNewProduct.storeFront.id.toString();
      //jika punya tempStorefront dan id selected storefront sama tidak perlu update
      //jika temp dan id storefront tidak sama update increment dan decrement
      if (
        rd_tempSelectedStorefront &&
        rd_tempSelectedStorefront !== reduxNewProduct.storeFront.id
      ) {
        const tempStorefrontId = rd_tempSelectedStorefront;
        const updateDecrementNumberOfProductStorefront =
          await actions.decrementNumberOfProductStorefront(tempStorefrontId);
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update decrementNumberOfProductStorefront for storefront id : ",
          tempStorefrontId
        );
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
      // tidak punya tempStorefront maka increment
      if (!rd_tempSelectedStorefront || rd_tempSelectedStorefront === "") {
        const updateIncrementNumberOfProductStorefront =
          await actions.incrementNumberOfProductStorefront(storefrontId);
        console.log(
          "update incrementNumberOfProductStorefront for storefront id : ",
          storefrontId
        );
      }
    }
    /***
     * Media Page - Process Save Photos
     */
    if (rd_ProductPhotos && rd_ProductPhotos.length > 0) {
      for (let index = 0; index < rd_ProductPhotos.length; index++) {
        const element = rd_ProductPhotos[index];
        //Upload File to Storage
        const result_FS_URL = await uploadToFirestorage(
          element.url,
          element.type,
          element.name,
          "product/photos/" + newProduct.id
        );
        console.log("Result URL Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultFirestorageURL.push(result_FS_URL);
        } else {
          isSuccess = false;
        }

        //Create Media Collection
        const dataMedia = await actions.formatMediaCollection(
          element.url,
          element.name,
          element.extension,
          element.type,
          element.size,
          result_FS_URL,
          rd_PhotosCoverIndex === index ? true : false,
          "",
          refUser
        );
        const mediaID = await actions.createDataMedia(
          newProduct.id,
          "photos",
          dataMedia
        );
        if (mediaID && mediaID !== "") {
          resultPhotosMediaID.push(mediaID);
        } else {
          isSuccess = false;
        }
      }
    }

    /***
     * Media Page - Process Save Video
     */
    if (rd_ProductVideo && rd_ProductVideo !== "") {
      // if (rd_VideoYoutubeURL && rd_VideoYoutubeURL !== "") {
      //Upload File to Storage
      const result_FS_URL = await uploadToFirestorage(
        rd_ProductVideo.url,
        rd_ProductVideo.type,
        rd_ProductVideo.name,
        "product/videos/" + newProduct.id
      );
      if (result_FS_URL && result_FS_URL !== "") {
        resultFirestorageURL.push(result_FS_URL);
      } else {
        isSuccess = false;
      }

      //Create Media Collection
      const dataMedia = await actions.formatMediaCollection(
        rd_ProductVideo.url,
        rd_ProductVideo.name,
        rd_ProductVideo.extension,
        rd_ProductVideo.type,
        rd_ProductVideo.size,
        result_FS_URL,
        false,
        rd_VideoYoutubeURL,
        refUser
      );
      const mediaID = await actions.createDataMedia(
        newProduct.id,
        "video",
        dataMedia
      );
      if (mediaID && mediaID !== "") {
        resultVideoMediaID = mediaID;
      } else {
        isSuccess = false;
      }
      // }
    }

    //save shipments data
    if (rd_Shipments && rd_Shipments.length > 0) {
      console.log("check redux rd_Shipments", rd_Shipments);
      for (let index = 0; index < rd_Shipments.length; index++) {
        const element = rd_Shipments[index];
        // check isActive and have types
        // save if isActive or not active but have types
        if (
          element.isActive ||
          (!element.isActive && element.types.length > 0)
        ) {
          const shipmentParentRef = createRef("shipments", element.id);
          console.log("parent shipments id : ", shipmentParentRef);
          console.log("element types : ", element.types);
          let typesData: any = [];
          for (let index = 0; index < element.types.length; index++) {
            const type = element.types[index];
            const shipmentChildrenRef = createRef("shipments", type.id);
            console.log("children shipments id : ", shipmentParentRef);
            const dataShipmentsType = await actions.formatShipmentsType(
              shipmentChildrenRef,
              type.name
            );
            typesData = [...typesData, dataShipmentsType];
          }
          console.log("check typesData : ", typesData);
          const dataShipments = await actions.formatShipmentsCollection(
            shipmentParentRef,
            element.name,
            element.isActive,
            typesData,
            refUser
          );
          const shipmentId = await actions.createDataShipments(
            productID,
            dataShipments
          );
          if (shipmentId && shipmentId !== "") {
            resultShipmentsId.push(shipmentId);
          } else {
            isSuccess = false;
          }
        }
      }
    }
    // save company data
    if (rd_SelectedCompanies && rd_SelectedCompanies.length > 0) {
      const companies = rd_SelectedCompanies;
      const marketplaces = rd_marketplaces;

      // create companies
      let newCompanies: any = [];
      console.log("companies : ", companies);
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const companyRef = createRef("company", company.company);
        newCompanies.push({ ...company, company: companyRef });
      }
      const dataCompanies = await actions.createDataCompany(
        productID,
        newCompanies
      );

      // create marketplace collection
      for await (const market of marketplaces) {
        // destructure
        let { company, category, isActive, marketplace } = market;

        // set company ref
        const companyRef = createRef("company", company!);

        // set category to empty array rather than undefined
        const categoryData = { ...(category[0] || []) };
        let arrayData = [];
        arrayData.push({ ...categoryData });
        // const categoryData = category || [];

        const findAccount = await getAccountByCompanyAndMarketplace(
          company,
          marketplace
        );
        const account: any =
          findAccount && findAccount[0] && findAccount[0].id
            ? findAccount[0].id
            : "";
        const accountRef = createRef("account", account ? account : "");

        //find attributes list by last category id
        let attributesList: attributeList[] = [];
        const cat: any[] = Object.values(category[0]);
        console.log(`category : ${cat}`);
        const lastCategory = cat.find(
          (category: any) => category.hasChildren === false
        );
        console.log(`find lastCategory : ${JSON.stringify(lastCategory)}`);
        if (
          rd_categoryAttributes &&
          rd_categoryAttributes.shopee &&
          lastCategory &&
          marketplace &&
          marketplace.toLowerCase() === "shopee"
        ) {
          const attributes = rd_categoryAttributes.shopee;
          const findAttributes = attributes.filter((att: any) => {
            if (att.inputValues && att.inputValues.length > 0) {
              const findCategoryById = att.categoryId?.find((cat: string) => {
                if (cat === lastCategory?.id) return cat;
              });
              if (findCategoryById) return att;
            }
          });
          console.log(`findAttributes : ${findAttributes}`);
          if (findAttributes && findAttributes.length > 0) {
            for (let index = 0; index < findAttributes.length; index++) {
              const attr = findAttributes[index];
              const inputValues = attr.inputValues!;
              const valueList: attributeValueList[] = [];
              console.log(`inputValues : ${JSON.stringify(inputValues)}`);
              if (
                attr.inputType === "MULTIPLE_SELECT" ||
                attr.inputType === "MULTIPLE_SELECT_COMBO_BOX"
              ) {
                const multipleValues = inputValues[0];
                if (multipleValues && multipleValues.length > 0) {
                  for (let index = 0; index < multipleValues.length; index++) {
                    const values = multipleValues[index];
                    console.log(`values for index ${index} : ${values}`);
                    let originalValueName: string = "";
                    if (values && values.value && values?.label) {
                      const getValueByInputType =
                        values?.value === values?.label ? 0 : values?.value;
                      console.log(
                        `getValueByInputType : ${getValueByInputType}`
                      );
                      if (getValueByInputType === 0)
                        originalValueName = values?.label;
                      const createValueList: attributeValueList =
                        await actions.formatAttributeValueList(
                          getValueByInputType,
                          "",
                          originalValueName
                        );
                      valueList.push({ ...createValueList });
                    }
                  }
                  console.log(`multiple : ${JSON.stringify(valueList)}`);
                }
              } else {
                const values = inputValues[0];
                console.log(`values for index ${0} : ${values}`);
                let getValueByInputType: number = 0;
                let originalValueName: string = "";
                if (values !== undefined && values !== null) {
                  getValueByInputType =
                    (attr.inputType === "COMBO_BOX" ||
                      attr.inputType === "DROP_DOWN") &&
                    values
                      ? values.value === values.label
                        ? 0
                        : values.value
                      : 0;
                  if (getValueByInputType === 0) {
                    originalValueName =
                      values && values.label
                        ? values.label
                        : values
                        ? values
                        : "";
                    if (attr.inputValidation === "DATE_TYPE")
                      originalValueName = values.replaceAll("-", "_");
                  }
                }
                const valueUnit =
                  attr.formatType === "QUANTITATIVE" &&
                  inputValues[1] &&
                  inputValues[1].value
                    ? inputValues[1].value
                    : "";
                console.log(`getValueByInputType : ${getValueByInputType}`);
                console.log(`originalValueName : ${originalValueName}`);
                console.log(`value unit : ${valueUnit}`);
                const createValueList: attributeValueList =
                  await actions.formatAttributeValueList(
                    getValueByInputType,
                    valueUnit,
                    originalValueName
                  );
                if (
                  (createValueList &&
                    createValueList.valueId === 0 &&
                    createValueList.originalValueName !== "" &&
                    createValueList.originalValueName &&
                    createValueList.originalValueName !== null) ||
                  (createValueList &&
                    createValueList.valueId &&
                    createValueList.valueId > 0)
                ) {
                  console.log(`valueList : ${createValueList}`);
                  valueList.push({ ...createValueList });
                }
              }
              console.log(`result valueList : ${valueList}`);
              if (valueList && valueList.length > 0) {
                const createAttributeModel: attributeList =
                  await actions.formatAttributeList(
                    attr.attributeId,
                    valueList
                  );
                console.log(`attributeModel : ${createAttributeModel}`);
                attributesList.push({ ...createAttributeModel });
              }
            }
          }
          console.log(
            `result attributesList: ${JSON.stringify(attributesList)}`
          );
        }
        console.log(`attributesList: ${JSON.stringify(attributesList)}`);
        const dataMarketplaces = await actions.formatMarketplacesCollection(
          companyRef,
          isActive,
          marketplace,
          arrayData,
          refUser,
          accountRef,
          attributesList && attributesList.length > 0 ? attributesList : []
        );

        const marketplaceId = await actions.createDataMarketplace(
          productID,
          dataMarketplaces
        );
        if (marketplaceId && marketplaceId !== "") {
          resultMarketplaceId.push(marketplaceId);
        } else {
          isSuccess = false;
        }
      }
    }

    //if have photo from redux upload then set image in option list
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      //upload to firestore
      console.log("rd_VariantPhotos : ", rd_VariantPhotos);
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      console.log("sortVariantPhoto : ", sortVariantPhoto);

      for (let index = 0; index < sortVariantPhoto.length; index++) {
        const element = sortVariantPhoto[index];
        let elem = {
          ...element,
          name: element.name || "variant-photo",
          type: element.type || "jpeg",
        };
        const result_FS_URL = await uploadToFirestorage(
          elem.url,
          elem.type,
          elem.name,
          "product/variant-photos/" + newProduct.id
        );
        console.log("Result URL Variant Photos : " + result_FS_URL);
        if (result_FS_URL && result_FS_URL !== "") {
          resultProductVariantPhoto.push(result_FS_URL);
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant category
    if (
      rd_isProductHaveVariants === "true" &&
      rd_Variants &&
      rd_Variants.length > 0
    ) {
      for (let i = 0; i < rd_Variants.length; i++) {
        const variant: any = rd_Variants[i];
        console.log("VAR, variant", variant);
        const variantName = variant.name ? variant.name.trim() : "";
        const optionList = variant.optionList ? variant.optionList : [];
        let optionListData: any = [];
        if (i === 0) {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const image = resultProductVariantPhoto[i];
            const optionData = await actions.formatVariantOptionList(
              opt ? opt : "",
              image ? image : ""
            );
            optionListData.push(optionData);
          }
        } else {
          for (let i = 0; i < optionList.length; i++) {
            const opt = optionList[i].option;
            const optionData = await actions.formatVariantOptionListOptionOnly(
              opt ? opt : ""
            );
            optionListData.push(optionData);
          }
        }
        console.log("optionListData : ", optionListData);

        let variantId: any;
        if (variantName !== "" || optionList.length > 0) {
          const dataVariant = await actions.formatVariantCollection(
            variantName,
            variant.index,
            optionListData && optionListData.length > 0
              ? optionListData
              : optionList,
            refUser
          );

          variantId = actions.createDataVariant(productID, dataVariant);
        }

        if (variantId && variantId !== "") {
          console.log("success create data variant");
        } else {
          isSuccess = false;
        }
      }
    }
    //save variant list/matrix
    console.log("RD VAR MATRIX", rd_VariantMatrix);
    if (rd_VariantMatrix && rd_VariantMatrix.length > 0) {
      console.log("VARLIST enter save matrix");
      let productVariantId: any;
      let variantId: any;
      for await (const variantMatrix of rd_VariantMatrix) {
        const productVariant = variantMatrix;
        console.log("productVariant : ", productVariant);
        const dataProductVariant = await actions.formatProductVariantCollection(
          productVariant.tierIndex,
          productVariant.sku ? productVariant.sku : "",
          productVariant.weight ? productVariant.weight : 0,
          productVariant.isActive === true ? true : false,
          productVariant.isMainVariant === true ? true : false,
          productVariant.stock ? productVariant.stock : 0,
          refUser
        );

        variantId = await actions.createDataProductVariant(
          productID,
          dataProductVariant
        );
      }
      if (variantId && variantId !== "") {
        resultVariantId.push(variantId);
        console.log("success create data variant");
      } else {
        isSuccess = false;
      }
    }
    //save category attributes to subCollection categoryAttributes
    console.log(`start process category attributes`);
    if (rd_categoryAttributes && rd_categoryAttributes.shopee) {
      const categoryAttributes = rd_categoryAttributes.shopee;
      for (let index = 0; index < categoryAttributes.length; index++) {
        const attribute = categoryAttributes[index];
        console.log(`attributes id : ${attribute.attributeId}`);
        const categoryId =
          attribute.categoryId && attribute.categoryId.length > 0
            ? attribute.categoryId
            : [];
        //reformat input values to get id value or string
        let inputValues: any[] = [];
        if (attribute.inputValues && (attribute.inputValues[0] || attribute.inputValues[1])) {
          if (
            attribute.inputType === "MULTIPLE_SELECT" ||
            attribute.inputType === "MULTIPLE_SELECT_COMBO_BOX"
          ) {
            //check if have value and save in subCollection categoryAttributes
            const multipleValues = attribute.inputValues[0];
            let multiple: any[] = [];
            for (let index = 0; index < multipleValues.length; index++) {
              const value = multipleValues[index];
              multiple.push({ ...value });
            }
            inputValues.push({ ...multiple });
            console.log(
              `input values multiple : ${JSON.stringify(inputValues)}`
            );
          } else {
            if(attribute.inputValues[0] || attribute.inputValues[1]){
              inputValues.push(attribute.inputValues[0] || null);
            }
            attribute.inputValues[1] &&
              inputValues.push(attribute.inputValues[1]);
            console.log(`input values single : ${JSON.stringify(inputValues)}`);
          }
          const categoryAttributeModel =
            await actions.formatProductCategoryAttributes(
              attribute.attributeId.toString(),
              inputValues,
              categoryId,
              "shopee",
              refUser
            );
          console.log(
            `categoryAttributeModel : ${JSON.stringify(categoryAttributeModel)}`
          );
          await actions.createDataProductCategoryAttributes(
            productID,
            attribute && attribute.attributeId ? attribute.attributeId.toString() : "",
            categoryAttributeModel
          );
        }
      }
    }
  };

  useEffect(() => {
    dispatch(ReduxProduct.setLastStep(productStep.onShipment));
    if (!isSummary) {
      if (
        !reduxNewProduct ||
        !reduxNewProduct.brand ||
        !reduxNewProduct.name ||
        reduxNewProduct.name === "" ||
        !reduxNewProduct.storeFront ||
        !reduxNewProduct.description ||
        reduxNewProduct.description === ""
      ) {
        if (rd_productID !== "") {
          return;
        }
        nav(`${URI}/basic-information`);
      } else if (
        !reduxProductWeight ||
        !reduxProductDimensionLength ||
        !reduxProductDimensionWidth ||
        !reduxProductDimensionHeight
      ) {
        nav(`${URI}/package-weight`);
      }
    }
    if (shipmentsList.length <= 0) {
      setLoading(true);
      const fetchData = async () => {
        const shipments: any = await ServProduct.getShipmentParent();
        let array: any = [];
        const copyParent = [...shipments];
        for await (const shipment of copyParent) {
          const data = await ServProduct.getShipmentChildren(shipment.id);
          const shipmentObj = { ...shipment, children: data };
          array.push(shipmentObj);
        }
        console.log("array children : ", array);
        let sortArray: any[] = array.sort((a: any, b: any) =>
          a.children.length! > b.children.length! ? -1 : 1
        );
        // console.log("hasil sorting array : ", sortArray);
        if (isSummary) {
          let arrayShipment: any = [];
          if (rd_Shipments.length > 0) {
            for (const rdshipment of rd_Shipments) {
              if (rdshipment.isActive === true) {
                arrayShipment.push(
                  sortArray.find(
                    (shipment: any) => shipment.id === rdshipment.id
                  )
                );
              }
            }
            dispatch(ReduxShipments.setSummaryShipmentList(arrayShipment));
          }
        } else {
          dispatch(ReduxShipments.setShipmentList(sortArray));
        }
      };
      fetchData().then(() => {
        setLoading(false);
      });
    } else {
      if (isSummary) {
        let arrayShipment: any = [];
        if (rd_Shipments.length > 0) {
          for (const rdshipment of rd_Shipments) {
            if (rdshipment.isActive === true) {
              arrayShipment.push(
                shipmentsList.find(
                  (shipment: any) => shipment.id === rdshipment.id
                )
              );
            }
          }
          dispatch(ReduxShipments.setSummaryShipmentList(arrayShipment));
        }
      }
    }

    if (rd_isDangerousProduct) {
      const dangerousProduct =
        rd_isDangerousProduct === "true" ? "true" : "false";
      console.log("ps dangerous prod", dangerousProduct);
      setDangerousProductValue(dangerousProduct);
    }
  }, []);

  useEffect(() => {
    if (rd_productID !== "") {
      const dangerousProduct =
        rd_isDangerousProduct === "true" ? "true" : "false";
      console.log("ps dangerous prod", dangerousProduct);
      setDangerousProductValue(dangerousProduct);
    }
  }, [rd_productID, rd_isDangerousProduct]);

  const handleUnsavedChangesToSummary = () => {
    dispatch(ReduxProduct.setShipments(rd_tempShipments));
    dispatch(ReduxProduct.setTempShipments([]));
    setModalFromSummary(false);
    nav(`${URI}/summary`);
  };

  const handleSavedChangesToSummary = () => {
    dispatch(ReduxProduct.setTempShipments([]));
    setModalFromSummary(false);
    nav(`${URI}/summary`);
  };

  useEffect(() => {
    let newProduct = { ...reduxNewProduct };
    if (
      rd_selectedBrandData &&
      rd_selectedBrandData !== null &&
      rd_selectedBrandData.value &&
      rd_selectedBrandData.value !== null
    ) {
      const brandRef = createRef("brands", rd_selectedBrandData.value);
      newProduct.brand = brandRef;
      dispatch(ReduxProduct.setNewProduct(newProduct));
    }
  }, [rd_selectedBrandData]);

  return (
    <>
      {(rd_isFromSummary || !isSummary) && (
        <PageTitle
          breadcrumbs={
            rd_isFromSummary ? UsersBreadcrumbsForSummary : UsersBreadcrumbs
          }
        >{`${t("AddNewProduct.Title.Shipment")}`}</PageTitle>
      )}

      <ModalDangerousProductInformation
        id={"dangerousProductInformation"}
        title={t("AddNewProduct.Modal.Title.DangerousGoods")}
        body={<DangerousProductInfo />}
      />

      {!isSummary && (
        <div>
          <ModalConfirmationMoveToOtherPage
            isDirty={true}
            path={`${URI}`}
            onUnsave={() => handleUnsavedChanges()}
            onSave={() =>
              handleSavedChanges().then(() => {
                setLoading(false);
                dispatch(ReduxProductList.cleanReduxProductList(true));
                dispatch(ReduxProduct.cleanReduxProduct(true));
              })
            }
            isEmpty={false}
          />

          {rd_isFromSummary && (
            <ModalConfirmationWithStateHandler
              id="ModalShipmentSummary"
              isModalOpen={isModalFromSummary}
              title={t("Alert.Info")}
              body={t("Alert.UnsavedChanges")}
              saveText={t("Alert.Button.SaveChanges")}
              unSaveText={t("Alert.Button.LeaveWithoutSave")}
              cancelText={t("Alert.Button.Cancel")}
              setIsModalOpen={setModalFromSummary}
              onClose={() => setModalFromSummary(false)}
              onUnsave={() => handleUnsavedChangesToSummary()} //Revert To Old Shipment and return to Summary
              onSave={() => handleSavedChangesToSummary()} //Save To Old Shipment and return to Summary
            />
          )}
        </div>
      )}

      <div
        className={clsx(
          "d-flex flex-column flex-lg-column",
          isSummary ? "" : "h-100"
        )}
      >
        {!isTabletOrMobile &&
          !isSummary &&
          ProductHeaderTitle("AddNewProduct.Title.Shipment")}
        <div
          className="d-flex flex-column flex-lg-column"
          style={{ minHeight: "92.5%" }}
        >
          <div
            // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
            className={clsx(
              isSummary ? "" : "overflow-hidden card",
              `h-100 ${isTabletOrMobile ? "" : isSummary ? "" : "p-7"}`
            )}
          >
            {isTabletOrMobile && !isSummary && <ProductMobileIcon />}
            <div
              style={{
                height: `${isTabletOrMobile ? "100%" : "90%"}`,
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile && !isSummary ? "p-5" : "p-5"
                }`
              )}
            >
              <div
                className={clsx(
                  "flex-lg-column-fluid tab-content h-100",
                  isTabletOrMobile ? "" : "overflow-auto "
                )}
              >
                {!isSummary && isTabletOrMobile && (
                  <div ref={titleRef} className="d-flex flex-column">
                    {ProductStep(4, "AddNewProduct.Title.Shipment")}
                  </div>
                )}
                <div>
                  <div ref={titleRef} className="step-sub-title mb-2 fs-4">
                    <label
                      className="form-check-label"
                      data-testid="dangerousGoodsTitle"
                    >
                      {t("AddNewProduct.Info.DangerousGoods")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  {/** checkbox is dangerous product */}
                  <div
                    className={clsx(
                      `d-flex ${isTabletOrMobile ? "flex-column" : "flex-row"}`
                    )}
                  >
                    <div
                      className={clsx(
                        `form-check form-check-custom form-check-solid ${
                          isTabletOrMobile ? "w-100 mb-5" : ""
                        }`
                      )}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="false"
                        name="dangerousProduct-false"
                        data-testid="dangerousProduct-false"
                        checked={
                          dangerousProductValue !== "" &&
                          dangerousProductValue === "false"
                        }
                        onChange={onDangerousProductValueChange}
                      />
                      <label className="form-check-label">
                        {t("AddNewProduct.Info.DangerousGoods.No")}
                      </label>
                    </div>
                    <div
                      className={clsx(
                        `form-check form-check-custom form-check-solid ${
                          isTabletOrMobile ? "w-100" : "ms-20"
                        }`
                      )}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="true"
                        name="dangerousProduct-true"
                        data-testid="dangerousProduct-true"
                        checked={
                          dangerousProductValue !== "" &&
                          dangerousProductValue === "true"
                        }
                        onChange={onDangerousProductValueChange}
                      />
                      <label className="form-check-label">
                        {t(
                          "AddNewProduct.Info.DangerousGoods.BatteryFlammableLiquidEtc"
                        )}
                      </label>
                      <span
                        className="bi bi-question-circle ms-3"
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#dangerousProductInformation"
                        data-testid="shipment-modaldangerousproduct"
                      ></span>
                    </div>
                  </div>
                  <div
                    className={clsx("mt-2 mb-2")}
                    style={{ height: isTabletOrMobile ? "auto" : "18px" }}
                    data-testid="alert-dangerousgoods"
                  >
                    {!isValidDangerousProduct && (
                      <span
                        className="text-danger"
                        id="shipping-error-dangerous-prod-not-selected"
                      >
                        {t("AddNewProduct.Alert.DangerousGoods")}
                      </span>
                    )}
                  </div>
                </div>

                {/* Shipment Section */}
                <div
                  className={clsx(
                    isSummary ? (isTabletOrMobile ? "pb-5" : "") : "mb-4"
                  )}
                >
                  <div className="step-sub-title mt-4 fs-4">
                    <label
                      className="form-check-label"
                      data-testid="shipmentTitle"
                    >
                      {t("AddNewProduct.Info.ShippingServiceSelection")}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  {/* Shipment Error Message */}
                  <div
                    className={clsx("mb-4")}
                    style={{ height: isTabletOrMobile ? "auto" : "18px" }}
                    data-testid="alert-shippingselection"
                  >
                    {!isValidSelectedShipment && (
                      <span
                        id="shipping-error-selection"
                        className="text-danger"
                      >
                        {t("AddNewProduct.Alert.ShippingServiceSelection")}
                      </span>
                    )}
                  </div>

                  {/* Card Select Shipment Section */}
                  {!isSummary && (
                    <div
                      className={clsx("row", {
                        "w-75": !isTabletOrMobile,
                      })}
                    >
                      {shipmentsList?.map((shipment: any) => {
                        if (shipment.children.length > 0) {
                          return (
                            <Fragment key={shipment.id.toString()}>
                              <ShipmentCard
                                shipment={shipment}
                                id={shipment.id.toString()}
                                onClickNext={isTouched}
                              />
                            </Fragment>
                          );
                        }
                      })}
                    </div>
                  )}
                  {isSummary && (
                    <div
                      className={clsx("row", {
                        "w-75": !isTabletOrMobile,
                      })}
                    >
                      {/* { console.log("Isi Summary List :"+JSON.stringify(summaryShipmentsList))} */}
                      {summaryShipmentsList.length > 0 &&
                        summaryShipmentsList?.map((shipment: any) => (
                          <Fragment key={shipment.id.toString()}>
                            <ShipmentCard
                              shipment={shipment}
                              id={shipment?.id?.toString()}
                              onClickNext={isTouched}
                            />
                          </Fragment>
                        ))}
                    </div>
                  )}
                </div>
                {isSummary && (
                  <Button
                    data-testid="btnNext-onShipments"
                    type="button"
                    btnbs="primary"
                    cName={clsx(isTabletOrMobile ? "w-100" : "")}
                    onClick={() => {
                      dispatch(ReduxProduct.setFromSummary(true));
                      dispatch(ReduxProduct.setTempShipments(rd_Shipments));
                      nav(`${URI}/shipments`);
                    }}
                  >
                    + {t("AddNewProduct.Button.Summary.AddShipmentService")}
                  </Button>
                )}
                <Button
                  id="btnValidateDataShipment"
                  data-testid="btnValidateDataShipment"
                  type="button"
                  btnbs="primary"
                  cName="mx-5 "
                  style={{
                    visibility: "hidden",
                    pointerEvents: "none",
                    height: "0px",
                    padding: "0.1px",
                  }}
                  onClick={() => handleNext()}
                >
                  {`${t("AddNewProduct.Button.BPI.Next")}`}
                </Button>
              </div>
            </div>
            {!isSummary && (
              <div
                className={clsx(
                  `d-flex bg-white mt-auto  ${isTabletOrMobile ? "mb-5" : ""}`,
                  rd_isFromSummary
                    ? "justify-content-end"
                    : "justify-content-between"
                )}
              >
                {/* <Link to=`${URI}/package-weight"> */}
                {!rd_isFromSummary && (
                  <Button
                    btnbs="primary"
                    cName={isTabletOrMobile ? "mx-5" : ""}
                    data-testid="btnBack"
                    onClick={() => handleBack()}
                  >
                    {`${t("Storefront.Button.Back")}`}
                  </Button>
                )}
                {/* </Link> */}

                <div className="d-flex">
                  {!rd_isFromSummary && (
                    <Link to="/product/list">
                      <Button
                        data-testid="btnCancel"
                        btnbs="primary"
                        type="button"
                      >
                        {t("AddNewProduct.Button.BPI.Cancel")}
                      </Button>
                    </Link>
                  )}
                  {rd_isFromSummary && (
                    <Button
                      data-testid="btnCancel"
                      btnbs="primary"
                      type="button"
                      onClick={() => setModalFromSummary(true)}
                    >
                      {t("AddNewProduct.Button.BPI.Cancel")}
                    </Button>
                  )}
                  <Button
                    data-testid="btnNext-onShipments"
                    type="button"
                    btnbs="primary"
                    cName="mx-5"
                    onClick={() => handleNext()}
                  >
                    {!rd_isFromSummary
                      ? `${t("AddNewProduct.Button.BPI.Next")}`
                      : `${t("Storefront.Button.Confirm")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
export default Shipments;
