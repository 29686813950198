import { FC } from "react";
import { ID, toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  id: ID;
  imgURL?: string;
};

const ProductNameCell: FC<Props> = ({ name, id, imgURL }) => {

  const dummyImg = "/media/images/dummy-image-square.jpg"

  const handleClick = () => {
    const editButton = document.getElementById(`editOnProduct-${id}`)
    editButton?.click()
  }

  let src = imgURL !== "" ? imgURL : dummyImg
  return (
    <div
      className="text-gray-600 mb-1 cursor-pointer"
      data-testid={"nameproduct-"+id}
      onClick={() => handleClick()}
    >
      <img className="me-4 rounded" src={src} style={{height: "60px"}} />
      {name}
    </div>
  )
};

export { ProductNameCell };