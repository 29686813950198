export const MediaType = ["Text", "Image", "Video", "Document", "Audio"];

export const MapMediaSupport: any = new Map<
  string,
  ("text" | "image" | "video" | "document")[]
>([
  ["whatsapp", ["text", "image", "video", "document"]],
  ["shopee", ["text", "image", "video"]],
  ["tokopedia", []],
  ["lazada", []],
  ["bukalapak", []],
  ["blibli", []],
  ["tiktok", []],
  ["jdid", []],
  ["bhinneka", []],
  ["orami", []],
]);
