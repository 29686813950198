/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  PaginationState,
  QUERIES,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import * as WABAAccountRedux from "../../../../../modules/waba-accounts/redux/WABAAccountSlice";
import * as WABAAccountSignUpRedux from "../../../../../modules/waba-accounts/redux/data/WABAAccountSignUpSlice";
import { useQueryRequest } from "./QueryRequestProvider";
import WABAccount, { WABAAccountDetail } from "../../../../../models/WABAccount"
import { getWABAAccounts } from "./_request";
import { wabaAccountSorting } from "./_sort";

import { Response } from "../../../../../../resources/helpers";

export type WABAAccountsQueryResponse = Response<WABAAccountDetail[]>;

const QueryResponseContext =
  createResponseContext<WABAAccountDetail>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listWABAAccountdata = useSelector(
    (state: RootState) => state.WABAAccountsList.listWABAAccount
  ); //list waba account from redux
  let WABAAccountsQueryResponse: WABAAccountsQueryResponse;

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    console.log("Masuk use effect update list WABA Account >>>>");
    dispatch(WABAAccountSignUpRedux.cleanReduxWABAAccountSignUp(true));
    setCounter(listWABAAccountdata.length);
  }, [listWABAAccountdata]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${counter}`,
    async () => {
      let data: WABAccount[] = [];

      //cek redux
      if (!listWABAAccountdata || listWABAAccountdata.length <= 0) {
        data = await getWABAAccounts(
          state.sort,
          state.order,
          state.search,
          state.action,
          state.lastId,
          state.items_per_page
        );
        if (data && data.length > 0) {
          dispatch(WABAAccountRedux.setListWABAAccount(data));
        }
      } else {
        data = listWABAAccountdata;
      }

      const dataToSort = data.reduce((acc, curr) => {
        return [
          ...acc,
          ...(curr.profiles?.map(profile => ({
            ...curr,
            ...profile
          })) ?? [ curr ])
        ]
      }, [] as WABAccount[])

      let resultData = wabaAccountSorting(
        dataToSort,
        state.sort,
        state.items_per_page,
        state.action,
        state.order,
        state.search,
        state.page,
        (lengthData: number) => {
          if (state.search && state.search !== "") {
            dispatch(WABAAccountRedux.setCountWABAAccount(lengthData));
          }
        }
      );
      if (state.search && state.search !== "") {
      } else {
        console.log("listWABAAccountdata", data);
        dispatch(WABAAccountRedux.setCountWABAAccount(data.length));
      }
      // console.log("Result Data : "+JSON.stringify(resultData));

      WABAAccountsQueryResponse = {
        data: resultData,
      };
      return Promise.resolve(WABAAccountsQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  //console.log("response "+JSON.stringify(response));

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
