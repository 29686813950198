import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../resources/helpers";
import Customer from "../../../../models/Customer"
import { fetchCustomers, deleteCustomer } from "../../../../../actions";
//import { Contact } from '../../../../pages/Contact'
import firebase from "firebase/compat/app";
import {
  fetchCustomersNext,
  createCustomer,
  updateCustomer,
  fetchCustomersByPhoneNumber, fetchCustomersByClient
} from "../../../../../db";
import * as customerServ from "../../../../../db/serviceCustomer"
import { format } from "date-fns";
import { getItemLC, LCName } from "../../../../modules/localstorage";
import * as lc from "../../../../modules/localstorage"
import { createRef } from "../../../../../db/connection";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`


export type ContactsQueryResponse = Response<Customer[]>

const getContacts = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<Customer>> => {
  let contactQueryResponse: ContactsQueryResponse;
  // console.log("query : " +query)
  // console.log("Sort : " + sort);
  // console.log("Order : " + order);
  // console.log("search : " + search)
  console.log("item : " + limit);
  console.log("action : " + action);

  let sortBy: string = "firstNameInsensitive";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";
  let isByPhoneNo = false;

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
    if(searchBy.startsWith("62")){
      isByPhoneNo=true;
    }
  }
  console.log("order =====>>" + orderBy);

  //Create Ref Data Company
  const companyID = getItemLC("CID");
  let companyRef = createRef("company", companyID);

  // create client ref
  const client = getItemLC(lc.LCName.Client);
  const clientRef = createRef("clients", client.id);

  console.log("Client Ref is, ", clientRef)

  // const arrCompany = getItemLC(LCName.CompanyList);
  // let companyIN = undefined;
  // let arrQueryIn = [];

  // if(arrCompany && arrCompany.length > 0){
  //   for (let index = 0; index < arrCompany.length; index++) {
  //     const element = arrCompany[index];
  //     const companyRef = createRef("company", arrCompany[index].id);
  //     console.log("------>>>> ini adalah company query in : "+index+" - " + companyRef.id);
  //     arrQueryIn.push(companyRef);
  //   }
  //   console.log("ArrComapny query in : " + arrQueryIn);
  // }else{
  //   return [];
  // }

  //TODO proses get data
  let dataContacts = await fetchCustomersByClient(clientRef);

  console.log("Contact data list:", dataContacts)

  dataContacts = dataContacts.map(contact => {
    const contactFirstName = contact.firstName ?? ""
    return ({...contact, firstName: contactFirstName})
  })

      // console.log("Result Data Customer Channel: "+JSON.stringify(data.lastInteractionChannel));
      // console.log("Result Data CompanyID: "+JSON.stringify(data.company.id));
    
      // // Use Customer Model
      // if (data.customer !== undefined){
      // const customer = (await getCustomerByIDV2(customerID)) as Customer;
      //   // console.log("Result Data Customer : "+JSON.stringify(customer));
      //   if(customer){
      //     data.customerModel = customer;
      //     data.phoneNumber = customer.phoneNumber;
      //   }
      // }

      //set Company Name
      // if(data.company){
      //   let company = arrCompany.find((cmp: ICompany) => cmp.id === data.company.id);
      //   if(company){
      //     data.companyName = company.companyName;
      //     data.companyAvatar = company.avatar;
      //   }  
      // }

      //Use Customer Info
      // if (data.customerInfo !== undefined){
      //   const customer = data.customerInfo as Customer;
      //   // const customer = (await getCustomerByIDV2(customerID)) as Customer;
      //   console.log("Result Data Customer : "+JSON.stringify(customer));
      //   if(customer){
      //     data.customerModel = customer;
      //   }
      // }

     

  console.log("Contact data from dataContact", dataContacts)
  
  if(dataContacts.length>0){
    console.log("Contact customer data exist...");
  }

  return Promise.resolve(dataContacts);
  // return Promise.resolve(CIQQueryResponse);


  // const companyID = getItemLC("CID");
  // console.log("CID =====>>>" + companyID);
  // let companyRef = createRef("company", companyID);

  // if (action === "prev") {
  //   return fetchCustomersPrev(searchBy, limit, companyRef).then((customers) => {
  //     var customersLength = customers.length;
  //     let dataUser = [];
  //     for (var i = 0; i < customersLength; i++) {
  //       let data = customers[i].data;
  //       data.id = customers[i].id;
  //       if (data.lastInteractionAt !== undefined) {
  //         data.lastInteractionAt = format(
  //           new Date(customers[i].data.lastInteractionAt.seconds * 1000),
  //           "d MMM yyyy, h:mm aaa"
  //         );
  //       }

  //       dataUser.push(data);
  //     }

  //     if (dataUser.length > 0) {
  //       if (sort === "firstName") {
  //         dataUser = dataUser?.sort((a, b) =>
  //           a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
  //         );
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>
  //             a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
  //           );
  //         }
  //       }
  //       if (sort === "email") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.email === undefined || a.email === "") return 1;
  //           if (b.email === undefined || b.email === "") return -1;
  //           return a.email?.toLowerCase() > b.email?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.email === undefined || a.email === "") return -1;
  //             if (b.email === undefined || b.email === "") return 1;
  //             return a.email?.toLowerCase() > b.email?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "phoneNumber") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.phoneNumber === undefined || a.phoneNumber === "") return 1;
  //           if (b.phoneNumber === undefined || b.phoneNumber === "") return -1;
  //           return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.phoneNumber === undefined || a.phoneNumber === "") return -1;
  //             if (b.phoneNumber === undefined || b.phoneNumber === "") return 1;
  //             return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "lastInteractionAt") {
  //         dataUser = dataUser?.sort((a, b) =>
  //         {
  //           if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return 1;
  //           if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return -1;
  //           return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //             ? 1
  //             : -1
  //         });
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>{
  //             if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return -1;
  //             if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return 1;
  //             return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //               ? -1
  //               : 1
  //         });
  //         }
  //       }
  //     }

  //     let contactsItem = {
  //       data: dataUser,
  //     };
  //     contactQueryResponse = contactsItem;

  //     return contactQueryResponse;
  //   });
  // } else if (action === "next") {
  //   return fetchCustomersNext(searchBy, limit, companyRef).then((customers) => {
  //     var customersLength = customers.length;
  //     let dataUser = [];
  //     for (var i = 0; i < customersLength; i++) {
  //       let data = customers[i].data;
  //       data.id = customers[i].id;
  //       if (data.lastInteractionAt !== undefined) {
  //         data.lastInteractionAt = format(
  //           new Date(customers[i].data.lastInteractionAt.seconds * 1000),
  //           "d MMM yyyy, h:mm aaa"
  //         );
  //       }

  //       dataUser.push(data);
  //     }

  //     if (dataUser.length > 0) {
  //       if (sort === "firstName") {
  //         dataUser = dataUser?.sort((a, b) =>
  //           a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
  //         );
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>
  //             a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
  //           );
  //         }
  //       }
  //       if (sort === "email") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.email === undefined || a.email === "") return 1;
  //           if (b.email === undefined || b.email === "") return -1;
  //           return a.email?.toLowerCase() > b.email?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.email === undefined || a.email === "") return -1;
  //             if (b.email === undefined || b.email === "") return 1;
  //             return a.email?.toLowerCase() > b.email?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "phoneNumber") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.phoneNumber === undefined || a.phoneNumber === "") return 1;
  //           if (b.phoneNumber === undefined || b.phoneNumber === "") return -1;
  //           return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.phoneNumber === undefined || a.phoneNumber === "") return -1;
  //             if (b.phoneNumber === undefined || b.phoneNumber === "") return 1;
  //             return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "lastInteractionAt") {
  //         dataUser = dataUser?.sort((a, b) =>
  //         {
  //           if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return 1;
  //           if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return -1;
  //           return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //             ? 1
  //             : -1
  //         });
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>{
  //             if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return -1;
  //             if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return 1;
  //             return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //               ? -1
  //               : 1
  //         });
  //         }
  //       }
  //     }

  //     let contactsItem = {
  //       data: dataUser,
  //     };
  //     contactQueryResponse = contactsItem;

  //     return contactQueryResponse;
  //   });
  // } else {
  //   return isByPhoneNo? fetchCustomersByPhoneNumber(searchBy, limit, companyRef).then((customers) => {
  //     var customersLength = customers.length;
  //     let dataUser = [];
  //     for (var i = 0; i < customersLength; i++) {
  //       let data = customers[i].data;
  //       data.id = customers[i].id;
  //       if (data.lastInteractionAt !== undefined) {
  //         data.lastInteractionAt = format(
  //           new Date(customers[i].data.lastInteractionAt.seconds * 1000),
  //           "d MMM yyyy, h:mm aaa"
  //         );
  //         // console.log("Test : " + new Date (data.lastInteractionAt))
  //       }

  //       dataUser.push(data);
  //     }

  //     if (dataUser.length > 0) {
  //       if (sort === "firstName") {
  //         dataUser = dataUser?.sort((a, b) =>
  //           a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
  //         );
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>
  //             a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
  //           );
  //         }
  //       }
  //       if (sort === "email") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.email === undefined || a.email === "") return 1;
  //           if (b.email === undefined || b.email === "") return -1;
  //           return a.email?.toLowerCase() > b.email?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.email === undefined || a.email === "") return -1;
  //             if (b.email === undefined || b.email === "") return 1;
  //             return a.email?.toLowerCase() > b.email?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "phoneNumber") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.phoneNumber === undefined || a.phoneNumber === "") return 1;
  //           if (b.phoneNumber === undefined || b.phoneNumber === "") return -1;
  //           return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.phoneNumber === undefined || a.phoneNumber === "") return -1;
  //             if (b.phoneNumber === undefined || b.phoneNumber === "") return 1;
  //             return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "lastInteractionAt") {
  //         dataUser = dataUser?.sort((a, b) =>
  //         {
  //           if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return 1;
  //           if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return -1;
  //           return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //             ? 1
  //             : -1
  //         });
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>{
  //             if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return -1;
  //             if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return 1;
  //             return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //               ? -1
  //               : 1
  //         });
  //         }
  //       }
  //     }

  //     let contactsItem = {
  //       data: dataUser,
  //     };
  //     contactQueryResponse = contactsItem;

  //     return contactQueryResponse;
  //   }) : fetchCustomers(searchBy, limit, companyRef).then((customers) => {
  //     var customersLength = customers.length;
  //     let dataUser = [];
  //     for (var i = 0; i < customersLength; i++) {
  //       let data = customers[i].data;
  //       data.id = customers[i].id;
  //       if (data.lastInteractionAt !== undefined) {
  //         data.lastInteractionAt = format(
  //           new Date(customers[i].data.lastInteractionAt.seconds * 1000),
  //           "d MMM yyyy, h:mm aaa"
  //         );
  //         // console.log("Test : " + new Date (data.lastInteractionAt))
  //       }

  //       dataUser.push(data);
  //     }

  //     if (dataUser.length > 0) {
  //       if (sort === "firstName") {
  //         dataUser = dataUser?.sort((a, b) =>
  //           a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
  //         );
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>
  //             a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
  //           );
  //         }
  //       }
  //       if (sort === "email") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.email === undefined || a.email === "") return 1;
  //           if (b.email === undefined || b.email === "") return -1;
  //           return a.email?.toLowerCase() > b.email?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.email === undefined || a.email === "") return -1;
  //             if (b.email === undefined || b.email === "") return 1;
  //             return a.email?.toLowerCase() > b.email?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "phoneNumber") {
  //         dataUser = dataUser?.sort((a, b) => {
  //           if (a.phoneNumber === undefined || a.phoneNumber === "") return 1;
  //           if (b.phoneNumber === undefined || b.phoneNumber === "") return -1;
  //           return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? 1 : -1;
  //         });

  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) => {
  //             if (a.phoneNumber === undefined || a.phoneNumber === "") return -1;
  //             if (b.phoneNumber === undefined || b.phoneNumber === "") return 1;
  //             return a.phoneNumber?.toLowerCase() > b.phoneNumber?.toLowerCase() ? -1 : 1;
  //           });
  //         }
  //       }
  //       if (sort === "lastInteractionAt") {
  //         dataUser = dataUser?.sort((a, b) =>
  //         {
  //           if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return 1;
  //           if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return -1;
  //           return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //             ? 1
  //             : -1
  //         });
  //         if (orderBy === "desc") {
  //           dataUser = dataUser?.sort((a, b) =>{
  //             if (a.lastInteractionAt === undefined || a.lastInteractionAt === "") return -1;
  //             if (b.lastInteractionAt === undefined || b.lastInteractionAt === "") return 1;
  //             return new Date(a.lastInteractionAt) > new Date(b.lastInteractionAt)
  //               ? -1
  //               : 1
  //         });
  //         }
  //       }
  //     }

  //     let contactsItem = {
  //       data: dataUser,
  //     };
  //     contactQueryResponse = contactsItem;

  //     return contactQueryResponse;
  //   });
  // }
};

const getContactById = (id: ID): Promise<Customer | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data);
};

const createContact = (
  contact: any, callback:any
): Promise<Customer | undefined | string | void> => {
  return createCustomer(contact, async (id: string | null)=> {
    if(id!== null){
      callback(await customerServ.get(id));
    }else{
      callback(undefined);
    }
  });
};

const updateContact = (
  contact: any
): Promise<Customer | undefined | string | void> => {
  return updateCustomer(contact).then(() => {
    return contact.id;
  }).catch(()=>{
    return undefined;
  })
  
  // return axios
  //   .post(`${USER_URL}/${contact.id}`, contact)
  //   .then((response: AxiosResponse<Response<Contact>>) => response.data)
  //   .then((response: Response<Contact>) => response.data);
};

const deleteContact = (contactId: ID): Promise<void> => {
  if (contactId === undefined || contactId === null) {
  } else {
    console.log("Delete id : " + contactId);
    deleteCustomer(contactId.toString());
  }

  return Promise.resolve();
};

const deleteSelectedContacts = (contactIds: Array<ID>): Promise<void> => {
  // const requests = contactIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  const requests = contactIds.map((id) => {
    if (id === undefined || id === null) {
    } else {
      console.log("Delete id : " + id);
      deleteCustomer(id.toString());
    }
  });

  return axios.all(requests).then(() => {});
};

export {
  getContacts,
  deleteContact,
  deleteSelectedContacts,
  getContactById,
  createContact,
  updateContact,
};
