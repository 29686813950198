import {
  getSandboxApiSetting,
  updateWhitelistPhoneNumbers,
} from "../api/sandbox/setting";
import { updatePushSetting } from "../api/server/api-setting";
import { ApiSettingPush } from "../app/models/ApiSetting";
import { ApiSettingPushState } from "../app/modules/api-setting/redux/ApiSettingRedux";
import { getAccess, getPush, getSms } from "../db/serviceApiSetting";

const IS_SANDBOX =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "stagging-sandbox";

export async function updateClientPushSetting(data: ApiSettingPushState) {
  const formattedData: Omit<ApiSettingPush, "createdAt" | "updatedAt"> = {};

  if (data.chat && data.chat.isActive && data.chat.isUrlVerified) {
    formattedData.chat = {
      url: data.chat.url,
      isDeliveryReport: data.chat.isDeliveryReport,
      isRecieveMessage: data.chat.isRecieveMessage,
    };
  }

  if (data.order && data.order.isActive && data.order.isUrlVerified) {
    formattedData.order = {
      url: data.order.url,
      selectedStatuses: data.order.selectedStatuses ?? [],
    };
  }

  if (data.product && data.product.isActive && data.product.isUrlVerified) {
    formattedData.product = {
      url: data.product.url,
    };
  }

  return await updatePushSetting(formattedData);
}

export async function updateClientWhitelistPhoneNumbers(
  phoneNumbers: string[],
  accessToken: string
) {
  return await updateWhitelistPhoneNumbers({ phoneNumbers }, accessToken);
}

export const getApiSetting = async () => {
  try {
    const [access, push, sms, sandboxSetting] = await Promise.all([
      getAccess(),
      getPush(),
      getSms(),
      IS_SANDBOX ? undefined : getSandboxApiSetting(),
    ]);
    return {
      access: access,
      push: push,
      sms: sms,
      sandboxAccess: sandboxSetting?.access,
    };
  } catch (error) {
    console.log(error);
  }
};
