import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle, PageLink } from "../layout/core/PageData";
import FriendChatWrapper from "../layout/chat/components/FriendChatWrapper";

const UserChat: FC = () => {
  const { t } = useTranslation();

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t('UC.Title.UserConversation'),
      path: '/user-conversation',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
    {/* <div style={{backgroundColor: "white"}}> */}
      <PageTitle breadcrumbs={usersBreadcrumbs}>{t('UC.Title.UserConversation')}</PageTitle>
      {/* <div className="fw-bold fs-3 text-gray-400 mb-15" data-testid="handledMessage-page"></div> */}
      <FriendChatWrapper />
    {/* </div> */}
    </>
  );
};

export { UserChat };

// 
