import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../resources/helpers";
import Loading from "../../../../../../resources/helpers/components/Loading";
import CardItem from "../../../../../components/table/list/cardList/CardItem";
import { StorefrontListPagination } from "../components/pagination/StorefrontListPagination";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import Storefront from "../../../../../models/Storefront"
import { CustomHeaderColumn } from "./columns/CustomHeaderColumns";
import { CustomRow } from "./columns/CustomRow";
import { storefrontColumns } from "./columns/_columns";

export const StorefrontViewDesktop = () => {
  const { t } = useTranslation();
  const storefronts = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => storefronts, [storefronts]);
  const columns = useMemo(() => storefrontColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Storefront>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Storefront>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {t("Contacts.Info.Empty")}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <StorefrontListPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  );
};

const StorefrontViewMobile = () => {
  const storefronts = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => storefronts, [storefronts]);
  const { t } = useTranslation();
  return (
    <>
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7">
        {data.map((storefront) => {
          let status = storefront.status;
          if (
            status !== "created" &&
            status !== "drafted"
          ) {
            status = status?.replace(
              "Common.Modal.Column.Contents.Status.Success",
              t("Common.Modal.Column.Contents.Status.Success")
            );
            status = status?.replace(
              "Common.Modal.Column.Contents.Status.Failed",
              t("Common.Modal.Column.Contents.Status.Failed")
            );
            console.log("status : ", status);
          }
          return (
          <Fragment key={storefront.id?.toString()}>
            <CardItem
              id={storefront.id}
              name={storefront.name}
              numOfProduct={storefront.numberOfProducts}
              marketplaces={storefront.marketplaceCategory}
              lastStep={storefront.lastStep}
              isStorefrontList={true}
              isProductList={false}
              status={status}
            />
          </Fragment>
        )})}
      </div>
      <StorefrontListPagination />
      {isLoading && <Loading />}
    </>
  );
};

const StorefrontTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? (
    <StorefrontViewMobile />
  ) : (
    <StorefrontViewDesktop />
  );
};

export { StorefrontTable };
