import React from "react";

type ModalDetailVariantProps = {
  id: string;
  title?: string;
  subtitle?: string;
  body?: string | JSX.Element;
  image?: string;
  onCancel?: any;
  onConfirm?: any;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen?: boolean;
};

const ModalDetailVariant: React.FC<ModalDetailVariantProps> = ({
  id,
  title,
  subtitle,
  body,
  image,
  onCancel,
  cancelOption,
  onConfirm,
  confirmOption,
  isModalOpen = false,
  ...props
}) => {
  return (
    <div
      {...props}
      hidden={!isModalOpen}
      className="h-100"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.15)",
      }}
    >
      <div id={id} className="h-100" style={{ overflow: "hidden" }}>
        <div
          className="modal-dialog"
          style={{
            position: "fixed",
            top: "auto",
            right: "auto",
            left: "auto",
            bottom: "0",
            width: "-webkit-fill-available",
            margin: "0px",
          }}
        >
          <div
            className="modal-content w-100"
            style={{ borderRadius: "1rem 1rem 0rem 0rem" }}
          >
            <div className="modal-header d-flex flex-row justify-content-start gap-2">
              <div className="d-flex">
                <div>
                  {image && image !== "" ? (
                    <img
                      src={image}
                      alt={`${title}-${subtitle}`}
                      style={{
                        height: "100px",
                      }}
                    />
                  ) : (
                    <div className="position-relative w-25 align-items-start">
                      <div
                        className="col-sm"
                        style={{
                          position: "sticky",
                          marginLeft: "1%",
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <div>
                          <label
                            className="button-dashed-mobile"
                            style={{
                              borderColor: "black",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            data-testid="button-upload"
                          >
                            <i
                              className="bi bi-card-image display-4"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "black",
                              }}
                            ></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ms-6 d-flex flex-column justify-content-between">
                  <h1 className="modal-title align-items-start">{title}</h1>
                  <h4 className="align-items-end">{subtitle}</h4>
                </div>
              </div>
            </div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">
              <div className="d-flex flex-row w-100">
                {cancelOption ? (
                  <button
                    type="button"
                    className="btn btn-primary w-50 mx-2"
                    onClick={onCancel}
                  >
                    {cancelOption}
                  </button>
                ) : null}
                {confirmOption ? (
                  <button
                    type="button"
                    className="btn btn-primary w-50 mx-2"
                    onClick={onConfirm ? onConfirm : onCancel}
                  >
                    {confirmOption}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailVariant;
