export function isValidPhoneNumber(phoneNumber: string) {
  const regexNumber = /^[0-9\b]+$/;

  return regexNumber.test(phoneNumber) && phoneNumber.length > 9;
}

export function isListHaveDuplicates(phoneNumbers: string[]) {
  return (
    phoneNumbers.length > 0 &&
    phoneNumbers.length !== [...new Set(phoneNumbers)].length
  );
}
