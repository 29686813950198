import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, { ProductListAction } from "./ProductListRedux";
import Product from "../../../models/Product"


const initialState: InitialState = {
  listProduct: [],
  countProduct: 0,
  currentPageProduct: 0,
  maxPageProduct: 0,
  arrayProductPagination: [],
};

export const ProductListSlice = createSlice({
  name: ProductListAction,
  initialState: initialState,
  reducers: {
    setListProduct: (state, action: PayloadAction<Product[]>) => {
      state.listProduct = action.payload;
      state.countProduct = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayProductPagination = arr;
      } else {
        state.arrayProductPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayProductPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayProductPagination = action.payload;
    },

    setCountProduct: (state, action: PayloadAction<number>) => {
      state.countProduct = action.payload;
    },

    setCurrentPageProduct: (state, action: PayloadAction<number>) => {
      state.currentPageProduct = action.payload;
    },

    setMaxPageProduct: (state, action: PayloadAction<number>) => {
      state.maxPageProduct = action.payload;
    },

    updateListProduct: (state, action: PayloadAction<Product>) => {
      let newListProduct: Product[] = [];
      const newProduct = action.payload;
      newListProduct.push(newProduct);

      for (let index = 0; index < state.listProduct.length; index++) {
        if (state.listProduct[index].id === newProduct.id) {
          console.log("Collab ID from Notif: " + state.listProduct[index].id);
        } else {
          newListProduct.push(state.listProduct[index]);
        }
      }
      state.listProduct = newListProduct;
      state.countProduct = newListProduct.length;
    },

    deleteItemListProduct: (state, action: PayloadAction<string>) => {
      let newDataCollab = state.listProduct.filter(
        (data) => data.id !== action.payload
      );
      state.listProduct = newDataCollab;
      state.countProduct = newDataCollab.length;
    },

    cleanReduxProductList: (state, action: PayloadAction<boolean>) => {
      state.listProduct = [];
      state.countProduct = 0;
      state.currentPageProduct = 0;
      state.maxPageProduct = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListProduct,
  deleteItemListProduct,
  updateListProduct,
  setCurrentPageProduct,
  setArrayProductPagination,
  setMaxPageProduct,
  setCountProduct,
  cleanReduxProductList,
} = ProductListSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default ProductListSlice.reducer;