import { Fragment, useEffect, useState } from "react";
import FilterComponent from "../layout/home/components/filter/Filter";
import SummaryComponent from "../layout/home/components/summary/Summary";
import TableComponent from "../layout/home/components/table/Table";
import HeaderComponent from "../layout/home/components/header/Header";
import ConversationAnalythicChart, { ChartProps } from '../layout/analytic/analytic-chart/ConversationAnalyticChart'
import { getConversationAnalyticByFilter } from "../../db/serviceAnalytic";
import { PageLink, PageTitle } from "../layout/core";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BR_COLOR, BG_COLOR } from "../layout/home/components/constant";
import PaginationComponent from "../layout/home/components/pagination/Pagination";

interface ITimeList {
    label: string;
    startTimestamp: number;
    endTimestamp: number;
}

const HomePage: React.FC = () => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
    const { t } = useTranslation();
    const [startDateValue, setStartDateValue] = useState<any>();
    const [endDateValue, setEndDateValue] = useState<any>();
    const [selectedGranularity, setSelectedGranularity] = useState<string>();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string[]>();
    const [selectedCategories, setSelectedCategories] = useState<string[]>();
    const [conversationData, setConversationData] = useState<any>();
    const [conversationTableDataSet, setConversationTableDataSet] = useState<any>();
    const [conversationTableDataPaginationSet, setConversationTableDataPaginationSet] = useState<any>();

    const [timeListTemplate, setTimeListTemplate] = useState<ITimeList[]>([]);
    const [dataLabels, setDataLabels] = useState<string[]>([]);
    const [dataSets, setDataSets] = useState<ChartProps["datasets"]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const handleStartDate = (startDate: any) => {
        setStartDateValue(startDate);
    }

    const handleEndDate = (endDate: any) => {
        setEndDateValue(endDate);
    }

    const handleGranularity = (granularity: string) => {
        setSelectedGranularity(granularity);
    }

    const handlePhoneNumber = (selectedPhoneNumbers: string[]) => {
        setSelectedPhoneNumber(selectedPhoneNumbers);
    }

    const handleCategory = (categories: any) => {
        setSelectedCategories(categories);
    }

    const createTimeListTemplate = (startDate: Date, endDate: Date): ITimeList[] => {
        let timeListTemplate = [];
        if (selectedGranularity === 'HOURLY') {
            let currentDate = moment(startDate);
            while (currentDate.unix() <= moment(endDate).unix()) {
                const datetimeDetail = {
                    label: currentDate.format('DD MMM YYYY HH:mm'),
                    startTimestamp: currentDate.unix(),
                    endTimestamp: currentDate.add(60, 'minutes').unix(),
                }
                timeListTemplate.push(datetimeDetail);
            }
        }

        else if (selectedGranularity === 'DAILY') {
            let currentDate = moment(startDate);
            while (currentDate.unix() <= moment(endDate).unix()) {
                const datetimeDetail = {
                    label: currentDate.format('DD MMM YYYY'),
                    startTimestamp: currentDate.unix(),
                    endTimestamp: currentDate.add(1, 'days').unix(),
                }
                timeListTemplate.push(datetimeDetail);
                // currentDate = currentDate.add(1, 'days');
            }
        }

        else if (selectedGranularity === 'MONTHLY') {
            let currentDate = moment(startDate);
            while (currentDate.unix() <= moment(endDate).unix()) {
                const startTimestamp = currentDate.unix();
                const startTimeLabel = currentDate.format('DD MMM YYYY');
                                
                // check if end date is last month of iteration
                const isLastIteration = moment(currentDate).add(1, 'months').unix() > moment(endDate).unix();
                const endTimeLabel = isLastIteration ? moment(endDate).format('DD MMM YYYY') : moment(currentDate).endOf('month').format('DD MMM YYYY');
                const endTimestamp = isLastIteration ? moment(endDate).unix() : moment(currentDate).endOf('month').unix();

                const datetimeDetail = {
                    label: `${startTimeLabel} - ${endTimeLabel}`,
                    startTimestamp: startTimestamp,
                    endTimestamp: endTimestamp,
                }
                timeListTemplate.push(datetimeDetail);
                
                // reset current date to first day of the month
                currentDate = moment(currentDate).startOf('month');

                // add 1 month to current date
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return timeListTemplate;
    }
    const getCategoryName = (category: string) => {
        if (category === 'authentication') return t('Home.ConversationCategories.Authenticating');
        else if (category === 'marketing') return t('Home.ConversationCategories.Marketing');
        else if (category === 'service') return t('Home.ConversationCategories.Service');
        else if (category === 'utility') return t('Home.ConversationCategories.Utility');
        else return category;
    }
    const createAnalyticChartDataset = (timeList: ITimeList[], conversationData: any, categories: string[]) => {
        if (!categories || !conversationData) return;
        let datasets = [];


        for (const category of categories) {
            const ctg: keyof BR_COLOR = category.toUpperCase() as keyof BR_COLOR;
            const datas = {
                label: getCategoryName(category),
                data: timeList.map((time: any) => {
                    const filteredData = conversationData.filter((item: any) => {
                        return (
                            item.conversationCategory === category &&
                            item.mutasiStartDate.seconds >= time.startTimestamp && item.mutasiStartDate.seconds < time.endTimestamp
                        );
                    });

                    const total = filteredData.reduce((acc: number, item: any) => {
                        return acc + (item.total_conv ?? item.waba_total_conv)
                    }, 0);
                    return total;
                }),
                borderColor: BR_COLOR[ctg],
                backgroundColor: BG_COLOR[ctg],
            }

            datasets.push(datas);
        }

        setDataSets(datasets);
        return datasets;
    }

    const createTableComponentDataSet = (timeList: ITimeList[], conversationData: any) => {
        const baseConversationDetail = {
            authentication: 0,
            marketing: 0,
            utility: 0,
            service: 0,
            total: 0
        }

        const tableDataset = timeList.map(time => ({ ...time, ...baseConversationDetail }));

        if (conversationData && conversationData.length > 0) {
            for (const data of conversationData) {
                const conversationCategory = data.conversationCategory;
                const totalConversation = data.total_conv ?? data.waba_total_conv;
                const conversationDetail = tableDataset.find(
                    conversation => data.mutasiStartDate.seconds >= conversation.startTimestamp && data.mutasiStartDate.seconds < conversation.endTimestamp
                )

                if (conversationDetail) {
                    if (conversationCategory === 'authentication') {
                        conversationDetail.authentication += totalConversation
                    }

                    else if (conversationCategory === 'marketing') {
                        conversationDetail.marketing += totalConversation
                    }

                    else if (conversationCategory === 'service') {
                        conversationDetail.service += totalConversation
                    }

                    else if (conversationCategory === 'utility') {
                        conversationDetail.utility += totalConversation
                    }
                    conversationDetail.total += totalConversation
                }
            }
        }
        setConversationTableDataSet(tableDataset);
    }

    const createConversationTableDataPaginationSet = (currentPage: number , pageSize: number , conversationTableDataSet:any ) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const conversationTableDataPaginationSet = conversationTableDataSet.slice(startIndex, endIndex);
        return conversationTableDataPaginationSet
    }

    // Side Effect For Fetching Conversation Data
    useEffect(() => {
        if (!startDateValue || !endDateValue || !selectedGranularity || !selectedPhoneNumber || !selectedCategories) return;

        const startDateFormatted = moment(moment(startDateValue).format('YYYY-MM-DD 00:00:00')).toDate();
        const endDateFormatted = moment(moment(endDateValue).format('YYYY-MM-DD 23:59:59')).toDate();

        const fetchConversationAnalyticData = async () => {
            const conversationData = await getConversationAnalyticByFilter(
                startDateFormatted,
                endDateFormatted,
                selectedGranularity,
                selectedCategories,
                selectedPhoneNumber
            )
            const newConversationData = conversationData?.filter((item:any) => item.conversationCategory &&
                selectedCategories.includes(item.conversationCategory)
            );
            setConversationData(newConversationData);
        }
        fetchConversationAnalyticData();
        setCurrentPage(1) // Reset Pagination
    }, [startDateValue, endDateValue, selectedPhoneNumber])
    // }, [startDateValue, endDateValue, selectedGranularity, selectedPhoneNumber, selectedCategories])

    // Side Effect For Handling Time List Template When Granularity Or Date Is Changed
    useEffect(() => {        
        const startDateFormatted = moment(moment(startDateValue).format('YYYY-MM-DD 00:00:00')).toDate();
        const endDateFormatted = moment(moment(endDateValue).format('YYYY-MM-DD 23:59:59')).toDate();
        if (!selectedGranularity || !startDateValue || !endDateValue) return;
        const timeList = createTimeListTemplate(startDateFormatted, endDateFormatted);
        setTimeListTemplate(timeList)
    }, [selectedGranularity, startDateValue, endDateValue])

    // Side Effect For Creating Component Dataset When TimeList , Category Data or Conversation Data Is Changed
    useEffect(() => {
        if (!timeListTemplate || timeListTemplate.length === 0) return;
        const timeList = [...timeListTemplate];
        setDataLabels(timeList.map(time => time.label));

        if (!selectedCategories || !conversationData) return;
        createTableComponentDataSet(timeList, conversationData);
        createAnalyticChartDataset(timeList, conversationData, selectedCategories);

    }, [timeListTemplate, selectedCategories, conversationData])

    // Side Effect For Pagination
    useEffect(() => {
        if (!conversationTableDataSet) return; // prevent side effect from running when conversationTableDataSet is not ready
        const conversationTableDataPagination = createConversationTableDataPaginationSet(currentPage, pageSize, conversationTableDataSet); 
        setConversationTableDataPaginationSet(conversationTableDataPagination);
    }, [currentPage, conversationTableDataSet])

    // Side Effect For Page Size
    useEffect(() => {
        // reset page to 1 when pageSize changes 
        // this is to prevent user from seeing an empty page
        // this is also triggering Side Effect For Pagination to update the data
        if (!conversationTableDataSet) return; // prevent side effect from running when conversationTableDataSet is not ready
        if (currentPage !== 1) setCurrentPage(1)
        else{
            const conversationTableDataPagination = createConversationTableDataPaginationSet(currentPage, pageSize, conversationTableDataSet);
            setConversationTableDataPaginationSet(conversationTableDataPagination);
        }   
    }, [pageSize])

    return (
        <Fragment>
            <PageTitle>
                {t("HeaderBar.Button.Dashboard")}
            </PageTitle>
            <HeaderComponent />
            <div className="d-flex flex-column flex-lg-column h-100">
                <div className="card mb-3">
                    <FilterComponent
                        onChangeStartDate={handleStartDate}
                        onChangeEndDate={handleEndDate}
                        onChangeGranularity={handleGranularity}
                        onChangePhoneNumber={handlePhoneNumber}
                        onChangeCategories={handleCategory}
                    />
                </div>
                <div className={clsx("overflow-hidden mb-1 mb-xl-2 h-100")}>
                    <div
                        className={clsx(isTabletOrMobile ? "w-100" : "")}
                        style={{
                            height: "100%",
                            overflowY: isTabletOrMobile ? "unset" : "scroll",
                        }}
                    >
                        <div className="mb-3">
                            <div className="card">
                                <SummaryComponent selectedCategories={selectedCategories ?? []} data={conversationData} />
                                <ConversationAnalythicChart labels={dataLabels} datasets={dataSets} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="card">
                                <TableComponent selectedCategories={selectedCategories ?? []} data={conversationTableDataPaginationSet}/>
                                <PaginationComponent 
                                    defaultActivePage={currentPage} 
                                    pageSize={pageSize} 
                                    totalData={conversationTableDataSet?.length ?? 0} 
                                    onPageChange={setCurrentPage} 
                                    onPageSizeChange={(pageSize) => {
                                        setPageSize(pageSize)
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HomePage;