import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import { RootState } from "../../../../../../../setup/redux/store";
import ButtonUploadMedia from "../../../../../../../styles/components/ButtonUploadMedia";
import * as ReduxProduct from "../../../../../../modules/product/redux/ProductSlice";
import { useTranslation } from "react-i18next";
import {
  productMediaCheckFile,
  productMediaUpload,
  uploadFile,
  formatSize,
} from "../../../../../../modules/util/Utils";
import { getImageSize } from "react-image-size";
import UploadImageThumbnail from "../../../../../../../styles/components/UploadImageThumbnail";


const updateObject = (obj: object, key: string, value: any) => {
  const currentObj: any = { ...obj };
  if (currentObj.hasOwnProperty(key)) {
    currentObj[key] = value;
    return currentObj;
  } else {
    return { ...obj, [key]: value };
  }
};

const clearObject = (obj: any) => {
  Object.keys(obj).forEach((key: any) => {
    delete obj[key];
  });
};

function checkIsAllEmpty(obj: any) {
  return Object.values(obj).every((val) => val === "");
}

interface Props {
  variantData?: any;
  variantFields?: any;
  onClickNext?: boolean;
  setIsHasError?: any;
  clickCounter?: any
  validateVariantsData?: any
}

const VariantListDesktop: FC<Props> = ({
  variantData,
  variantFields,
  onClickNext,
  setIsHasError,
  clickCounter,
  
}) => {
  const rd_Variants = useSelector((state: RootState) => state.Product.variants);
  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png

  const [photoValidation, setPhotoValidation] = useState<any>({});
  const [photoErrors, setPhotoErrors] = useState<any>({});

  // const [isPhotoError, setIsPhotoError] = useState<any>([]);

  const [errors, setErrors] = useState<any>({});

  console.log("Photos Error", photoErrors);
  console.log("Photo validation", photoValidation);

  const handleSKUChange = (e: any, data: any) => {
    const newSKU = e.target.value.trim();
    const newMatrixData = {
      ...data,
      sku: newSKU,
    };
    //filter
    const variantMatrixData = [...rd_VariantMatrix];
    const newMatrix = variantMatrixData.map((matrix) => {
      if (matrix.tierIndex === data.tierIndex) {
        return newMatrixData;
      }
      return matrix;
    });
    dispatch(ReduxProduct.setVariantMatrix(newMatrix));
  };

  const handleWeightChange = (e: any, data: any) => {
    let newWeight: any = e.target.value.replace(/^0+/, "");
    const newMatrixData = {
      ...data,
      weight: newWeight,
    };
    //filter
    const variantMatrixData = [...rd_VariantMatrix];
    const newMatrix = variantMatrixData.map((matrix) => {
      if (matrix.tierIndex === data.tierIndex) {
        return newMatrixData;
      }
      return matrix;
    });
    dispatch(ReduxProduct.setVariantMatrix(newMatrix));
  };

  const handleStatusChange = (e: any, data: any) => {
    let newStatus = e.target.checked;
    const newMatrixData = {
      ...data,
      isActive: newStatus,
    };
    //filter
    const variantMatrixData = [...rd_VariantMatrix];
    const newMatrix = variantMatrixData.map((matrix) => {
      if (matrix.tierIndex === data.tierIndex) {
        return newMatrixData;
      }
      return matrix;
    });
    dispatch(ReduxProduct.setVariantMatrix(newMatrix));
  };

  const handleMainVariantChange = (e: any, data: any) => {
    let newIsMainVariant: any = e.target.checked;
    const newMatrixData = {
      ...data,
      isActive: newIsMainVariant === true ? true : data.isActive,
      isMainVariant: newIsMainVariant ? newIsMainVariant : false,
    };
    //filter
    const variantMatrixData = [...rd_VariantMatrix];
    const newMatrix = variantMatrixData.map((matrix) => {
      if (matrix.tierIndex === data.tierIndex) {
        return newMatrixData;
      } else {
        if (newIsMainVariant === true) {
          const newMatrix = {
            tierIndex: matrix?.tierIndex,
            variant1: matrix?.variant1,
            variant2: matrix?.variant2,
            sku: matrix?.sku,
            weight: matrix?.weight,
            isActive: matrix.isActive ? matrix.isActive : false,
            isMainVariant: false,
          };
          return newMatrix;
        }
      }
      return matrix;
    });
    dispatch(ReduxProduct.setVariantMatrix(newMatrix));
  };

  const INVALID_PHOTO_EMPTY = t("AddNewProduct.VariantList.Alert.Photo");
  const INVALID_RESOLUTION = "please enter a valid resolution";
  const INVALID_PHOTO_SIZE = t(
    "AddNewProduct.SubTitle.PhotoFileRequirements.FileSize"
  );
  const INVALID_PHOTO_FORMAT = t("AddNewProduct.Alert.Photo.Invalidformatfile");

  const uploadProductMedia = async (
    event: any,
    uploadType: string,
    optionListIndex: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      // if (onClickNext) validateVariantsData();

      if (uploadType === productMediaUpload.image) {
        setPhotoValidation(clearObject(photoValidation));
      }

      const variant = rd_Variants;
      const optionListLength: any =
        variant[0] && variant[0].optionList ? variant[0].optionList.length : 0;
      let productVariantPhotoLength = rd_VariantPhotos.length;

      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        productVariantPhotoLength++;

        if (element) {
          const size: number = element.size;
          const tempArrFileType: string = element.type;
          const arryFileType = tempArrFileType.split("/");
          const fileName = element.name;

          if (
            arryFileType === undefined ||
            arryFileType.length < 1 ||
            arryFileType[0] === undefined
          ) {
            setPhotoValidation(
              updateObject(
                photoValidation,
                `photos-${optionListIndex},0`,
                INVALID_PHOTO_FORMAT
              )
            );
            //return error
            console.log("Media - Error File Invalid Format");
            return;
          } else {
            const URLObj = URL.createObjectURL(element);
            let photosWidth: number = 0;
            let photosHeight: number = 0;
            if (uploadType === productMediaUpload.image) {
              const { width, height } = await getImageSize(URLObj);
              photosWidth = width;
              photosHeight = height;
            }
            const [result, error, maxSize] = productMediaCheckFile(
              arryFileType[0],
              arryFileType[1],
              size,
              photosWidth,
              photosHeight
            );

            if (error !== undefined) {
              if (maxSize !== 0) {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photos-${optionListIndex},0`,
                      INVALID_PHOTO_SIZE
                    )
                  );
                }
                return;
              } else {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photos-${optionListIndex},0`,
                      INVALID_PHOTO_FORMAT
                    )
                  );
                }
              }
              return;
            }

            if (!result || result === "") {
              if (result === productMediaUpload.image) {
                console.log("INVALID FORMAT 3");
                setPhotoValidation(
                  updateObject(
                    photoValidation,
                    `photos-${optionListIndex},0`,
                    INVALID_PHOTO_FORMAT
                  )
                );
              }
              return;
            } else {
              let newFile = {
                index: optionListIndex,
                url: URLObj,
                size: formatSize(size),
                type: tempArrFileType,
                extension: arryFileType[1],
                name: fileName,
              };
              if (result === productMediaUpload.image) {
                let newVariantPhotos: any = [];
                newVariantPhotos = [...rd_VariantPhotos, newFile];
                setPhotoValidation(
                  updateObject(
                    photoValidation,
                    `photos-${optionListIndex},0`,
                    ""
                  )
                );
                dispatch(
                  ReduxProduct.setProductVariantPhotos(newVariantPhotos)
                );
              } else {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photos-${optionListIndex},0`,
                      INVALID_PHOTO_FORMAT
                    )
                  );
                }
              }
            }
          }
        }
      }
    }
  };

  const removeProductVariantPhotos = (photoIndex: any) => {
    console.log("VAR removed Photos : " + photoIndex);
    let newProductVariantPhotos: any[] = [];
    for (let index = 0; index < rd_VariantPhotos.length; index++) {
      const photo = rd_VariantPhotos[index];

      console.log("VAR photo", photo);
      if (photoIndex !== photo.index) {
        newProductVariantPhotos.push(photo);
      }
    }

    console.log("Hasil Redux Variant Photos : " + newProductVariantPhotos);

    dispatch(ReduxProduct.setProductVariantPhotos(newProductVariantPhotos));

    setPhotoValidation(
      updateObject({}, `photos-${photoIndex},0`, INVALID_PHOTO_EMPTY)
    );
  };

  /*
    === Validation Begins Here ===
  */
  const validateVariantsData = () => {
    const newErrors: any = {};

    for (const variant of rd_VariantMatrix) {
      console.log("VAR Weight", variant.weight);
      // weight
      if (variant.weight <= 0) {
        newErrors[`weight-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.Weight"
        );
      } else if (variant.weight == null) {
        newErrors[`weight-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.Weight"
        );
      }
      // sku
      if (variant.sku === "" || variant.sku === undefined) {
        newErrors[`sku-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.SKU"
        );
      }
    }
    // console.log("VAL Photos", rd_VariantPhotos)
    const variants = rd_Variants;
    const getVariantOptionList: any = variants[0]?.optionList;
    const sortData = [...rd_VariantPhotos];
    const sortVariantPhoto = sortData.sort((a: any, b: any) =>
      a.index > b.index! ? 1 : -1
    );
    if (sortVariantPhoto !== getVariantOptionList?.length) {
      for (let index = 0; index < getVariantOptionList?.length; index++) {
        const photo = sortVariantPhoto.find((photo) => photo.index === index);
        console.log("index : ", photo);
        console.log("check photo by index : ", photo);
        if (!photo || photo === undefined) {
          newErrors[`photos-${[index,0]}`] = t(
            "AddNewProduct.VariantList.Alert.Photo"
          );
        }
        console.log("photos : ", photo);
      }
    }

    let mergedErrors = Object.assign({}, newErrors, photoErrors);

    setErrors(mergedErrors);
  };

  useEffect(() => {
    setErrors({ ...errors, ...photoErrors });
  }, [photoErrors]);

  useEffect(() => {
    if (photoValidation !== null || photoValidation !== undefined) {
      if (photoValidation?.length !== 0) {
        setPhotoErrors({ ...photoErrors, ...photoValidation });
      }
    }
  }, [photoValidation]); //eslint-disable-line

  useEffect(() => {
    if (onClickNext) {
      validateVariantsData();
    } 
  }, [
    onClickNext,
    rd_VariantMatrix,
    rd_VariantPhotos,
    photoValidation,
    photoErrors,
  ]);

  const nav = useNavigate()

  const arrayHasPhotos = rd_VariantPhotos?.map((item: any) => item.index) || [];

  return (
    <div className="h-80 overlfow-y">
      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
        <thead className="table-light align-middle fw-bolder">
          <tr className="text-center">
            <th scope="col">{variantFields?.variant1}</th>
            <th scope="col">{variantFields?.variant2}</th>
            <th scope="col">
              SKU<span className="text-danger">*</span>
            </th>
            <th scope="col">
              {t("AddNewProduct.Column.Weight")}
              <span className="text-danger">*</span>
            </th>
            <th scope="col">{t("AddNewProduct.Column.Status")}</th>
            <th scope="col">{t("AddNewProduct.Column.MainVariant")}</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {rd_VariantMatrix &&
            rd_VariantMatrix?.map((item: any, index: any) => (
              <tr key={index}>
                {index === 0 ||
                rd_VariantMatrix[index - 1].variant1 !== item.variant1 ? (
                  <td
                    className="w-25"
                    rowSpan={
                      rd_VariantMatrix.filter(
                        (d: any) => d.variant1 === item.variant1
                      ).length
                    }
                  >
                    <div
                      className="mb-4 mx-8 text-center"
                      data-testid={`var-variant1-${index}`}
                    >
                      {item.variant1}
                      <span className="text-danger">*</span>
                    </div>
                    <div className="ms-14 d-flex justify-content-start">
                      {!arrayHasPhotos.includes(item.tierIndex[0]) && (
                        <ButtonUploadMedia
                          idRadioButton={"up_image-"}
                          fixWidth={true}
                          cName="bi bi-card-image display-3"
                          datatestid={"BtnUploadImage-"}
                          style={{ color: "black" }}
                          accept={imageExtention}
                          noMargin={true}
                          isVideoButton={true} // multiple file = false (name of props)
                          isInvalidUpload={
                            `photos-${item.tierIndex[0]}` in errors
                          }
                          onChangeFn={(e: any) => {
                            uploadProductMedia(
                              e,
                              productMediaUpload.image,
                              item.tierIndex[0]
                            );
                          }}
                        />
                      )}
                      {rd_VariantPhotos &&
                        rd_VariantPhotos
                          .filter(
                            (photos: any) => photos.index === item.tierIndex[0]
                          )
                          .map((photos: any, index: number) => {
                            return (
                              photos.url && (
                                <div
                                  className="col-sm"
                                  style={{
                                    position: "sticky",
                                    left: "0%",
                                    height: "100px",
                                  }}
                                >
                                  <UploadImageThumbnail
                                    idRadioButton={"image-" + index}
                                    isMobile={false}
                                    fixWidth={true}
                                    cName="bi bi-card-image display-3"
                                    datatestid={"image-" + index}
                                    imgSrc={photos.url}
                                    isVideoButton={false}
                                    isHasCover={false}
                                    onClickFn={() => {
                                      removeProductVariantPhotos(
                                        item.tierIndex[0]
                                      );
                                    }}
                                    onChecked={false}
                                  />
                                </div>
                              )
                            );
                          })}
                    </div>

                    <div
                      className="mt-2"
                      style={{ height: "18px" }}
                      data-testid="alert-photos"
                    >
                      {errors[`photos-${item?.tierIndex}`] ? (
                        <span className="text-danger">
                          {errors[`photos-${item?.tierIndex}`]}
                        </span>
                      ) : null}
                    </div>
                  </td>
                ) : null}
                <td className="p-0">{item.variant2}</td>
                <td className="p-0 pt-5">
                  <input
                    type="text"
                    data-testid={`var-sku-${index}`}
                    className="form-control form-control-sm"
                    defaultValue={item.sku}
                    onChange={(e) => handleSKUChange(e, item)}
                  />

                  {/* errors each index */}
                  <div
                    className="mt-2"
                    style={{ height: "15px" }}
                    data-testid="alert-sku"
                  >
                    {errors[`sku-${item.tierIndex}`] ? (
                      <span className="text-danger">
                        {errors[`sku-${item.tierIndex}`]}
                      </span>
                    ) : null}
                  </div>
                </td>
                <td className="p-0 pt-5 w-25">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center form-control border-1 px-0 py-0 w-75">
                      <input
                        data-testid={`var-weight-${index}`}
                        type="number"
                        min={0}
                        max={999}
                        id="bpi-input-variant-weight"
                        name="bpi-input-variant-weight"
                        className="form-control border-0"
                        defaultValue={item.weight}
                        onChange={(e) => handleWeightChange(e, item)}
                      />
                      <p
                        className="border-0 bg-white my-0"
                        data-testid="ProductWeight_PackageWeightInputUnit"
                        style={{
                          paddingRight: "10px",
                          color: "gray",
                          textAlign: "center",
                        }}
                      >
                        gram
                      </p>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    style={{ height: "18px" }}
                    data-testid="alert-weight"
                  >
                    {errors[`weight-${item.tierIndex}`] ? (
                      <span className="text-danger">
                        {errors[`weight-${item.tierIndex}`]}
                      </span>
                    ) : null}
                  </div>
                </td>
                <td className="p-0">
                  <div className="d-flex justify-content-center form-check form-switch">
                    <input
                      data-testid={`ismainvariant-${index}`}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      disabled={item.isMainVariant === true}
                      checked={item.isActive === true}
                      onChange={(e) => handleStatusChange(e, item)}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </td>
                <td className="p-0">
                  <input
                    type="radio"
                    checked={item.isMainVariant === true}
                    onChange={(e) => handleMainVariantChange(e, item)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default VariantListDesktop;
