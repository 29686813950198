import db from "../../db";
import Client from "../../app/models/Client";
import { converter } from "../../db/converter";

//get all clients in clients collection
export const getAllClients = () =>
  db
    .collection("clients")
    .withConverter(converter<Client>())
    .get()
    .then((snapshot) => {
      const clients: Client[] = [];
      snapshot.docs.forEach((doc) => {
        const client = doc.data();
        clients.push(client);
      });
      return clients;
    });


//get client by id in clients collection
export const getClientByID = (id: string) =>
  db
    .collection("clients")
    .withConverter(converter<Client>())
    .doc(id)
    .get()
    .then((snapshot) => {
      if (!snapshot.exists) return undefined
      const client = snapshot.data();
      return client;
    })
    .catch(error => {
      console.error(`clients.tsx getClientByID error ${error}`)
      return undefined
    });