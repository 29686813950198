import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../styles/components/Icon";
import { RootState } from "../../setup/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { checkIsActive, toAbsoluteUrl } from "../../resources/helpers";
import { useTranslation } from "react-i18next";
import * as lc from "../modules/localstorage/index";
import { HeaderUserMenu } from "./header/header-menus/HeaderUserMenu";
import { Dropdown } from "react-bootstrap";
import Avatar from "../../styles/components/Avatar";
import { cleanReduxStorefront } from "../modules/product/storefront/StorefrontSlice";
import { cleanReduxStorefrontList } from "../modules/product/storefront/storefrontList/StorefrontListSlice";
import { cleanReduxProduct } from "../modules/product/redux/ProductSlice";
import { cleanReduxShipment } from "../modules/product/shipment/redux/ShipmentsSlice";
import { cleanReduxMarketplaceProductCategory } from "../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { cleanReduxCIQ } from "../modules/ciq/redux/CIQSlice";
import { cleanReduxContact } from "../modules/contact/redux/ContactSlice";
import { cleanReduxCampaign } from "../modules/campaign/list/CampaignListSlice";
import { cleanReduxTemplate } from "../modules/template/list/TemplateListSlice";
import { deleteUser, setAuth } from "../modules/auth/redux/AuthSlice";
import { cleanReduxAddMembers } from "../modules/auth/redux/AddMemberSlice"
import {
  deleteMessage,
  setSelectedChat,
  setSelectedRoom,
  setAllChatSelectedChat,
  deleteAllChatMessage,
  setAllChatSelectedRoom,
} from "../modules/chat/redux/ChatSlice";
import { setUserOffline } from "../../api/server/connection";
import * as Log from "../../util/SDayslogger";
import { logout } from "../../api/index";
import IconImageRounded from "../../styles/components/IconImageRounded";

export function ShortcutBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const nav = useNavigate();
  const countUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUnreadMessages
  );
  const countCustomerInQueue: number = useSelector(
    (state: RootState) => state.CIQ.countCIQ
  );
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [customerInQueue, setCustomerInQueue] = useState<number>(0);
  const { t } = useTranslation();
  function getLocation(): string {
    return location.pathname;
  }

  useEffect(() => {
    setUnreadMessages(countUnreadMessages);
    setCustomerInQueue(countCustomerInQueue);
  }, [countUnreadMessages, countCustomerInQueue]);

  const client = lc.getItemLC(lc.LCName.Client);
  const user = lc.getItemLC(lc.LCName.UserData);

  let userName = user && user.name ? user.name : "7days User";
  if (userName.length > 20) {
    //   const splitName = userName.split(" ");
    //   console.log("splitName : ", splitName.length);
    //   let name: any = [];
    //   if(splitName.length === 1 || splitName[0].length > 20){
    //     userName = splitName[0].substring(0, 17) + "...";
    //   }else{
    //     let tempName = "";
    //     for (let index = 0; index < splitName.length; index++) {
    //       let element = "";
    //       if(index===0){
    //         element = tempName+""+splitName[index];
    //       }else{
    //         element = tempName+" "+splitName[index];
    //       }
    //       if(element.length > 20){
    //         tempName = tempName+" "+splitName[index].substring(0,1) +". ";
    //         break;
    //       }else{
    //         tempName = element;
    //       }
    //     }
    //     userName = tempName;
    //   }
    userName = userName.substring(0, 17) + "...";
    console.log("userName after replace : ", userName);
  }

  const toolbarButtonMarginClass = "ms-1 ms-lg-3",
    toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
    toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
    toolbarButtonIconSizeClass = "svg-icon-1";
  let clientName =
    client && client.clientName ? client.clientName : "Unnamed Client";
  if (clientName.length > 20) {
    clientName = clientName.substring(0, 17) + "...";
  }
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, location.pathname);

  function handleLogout() {
    const currentUser = lc.getItemLC(lc.LCName.User);
    const sessionid = lc.getItemLC(lc.LCName.SessionID);
    if (currentUser === null || sessionid === null) {
      dispatch(setAuth(false));
      nav("/auth");
    } else {
      logout()
        .then(() => {
          setUserOffline(currentUser.uid, sessionid);
          dispatch(deleteUser());
          lc.removeSession();
          dispatch(setAuth(false));
          dispatch(setSelectedChat(""));
          dispatch(setSelectedRoom(""));
          dispatch(setAllChatSelectedChat(""));
          dispatch(setAllChatSelectedRoom(""));
          dispatch(deleteMessage(true));
          dispatch(deleteAllChatMessage(true));
          dispatch(cleanReduxCIQ(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxCampaign(true));
          dispatch(cleanReduxTemplate(true));
          dispatch(cleanReduxStorefront(true));
          dispatch(cleanReduxMarketplaceProductCategory(true));
          dispatch(cleanReduxShipment(true));
          dispatch(cleanReduxProduct(true));
          dispatch(cleanReduxAddMembers(true));
        })
        .catch((error) => {
          Log.SDayslogger(
            nav,
            "Testing Error Message",
            Log.SDLOGGER_INFO,
            false,
            true
          );
          console.log("failed logout");
        })
        .finally(() => {
          nav("/auth");
        });
    }
  }
  return (
    <div className="d-flex align-items-center justify-content-center position-fixed fixed-bottom bg-white py-2 w-100 border top-3 px-3 shadow" style={{
      zIndex: 10000,
      height: '8vh'
    }}>
      <div className={clsx(`d-flex symbol align-items-center`)} data-testid="icon-product">
        <div className="btn-group dropup">
          <button
            type="button"
            className="btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px" }}
          >
            <img
              style={{ borderRadius: "80%" }}
              id="active_user_avatar"
              data-testid="AsideUserItemAvatarImage"
              src={toAbsoluteUrl(
                isActive && (pathname.includes("/storefront/") || pathname.includes("/product/")) ? "/media/icons/shortcut/Product_Active.png" : "/media/icons/shortcut/Product_Inactive.png"
              )}
              alt="7daysUser"
              className="mh-50px mw-50px"
            />
            {/* <i
                className={clsx(
                  `fas fa-box fs-3hx ${
                    isActive && (pathname.includes("/storefront/") || pathname.includes("/product/"))
                      ? "text-primary"
                      : ""
                  }`
                )}
              /> */}
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                to="/product/create-product/basic-information"
                className="dropdown-item"
              >
                {`${t("AddNewProduct.Menu.Title.AddNewProduct")}`}
              </Link>
            </li>
            <li>
              <Link to="/product/list" className="dropdown-item">
                Product List
              </Link>
            </li>
            <li>
              <Link to="/storefront/list" className="dropdown-item">
                {`${t("Storefront.Info.Storefront")}`}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <IconImageRounded
        data-testid="icon-ciq"
        id="icon-customer-in-queue"
        imgSrc="/media/icons/shortcut/CIQ_Inactive.png"
        imgActiveSrc="/media/icons/shortcut/CIQ_Active.png"
        currentLocation={`${getLocation()}`}
        size="4hx"
        badgeStyle="bg-danger"
        number={countCustomerInQueue}
        nav="/ciq/list"
      ></IconImageRounded>
      <IconImageRounded
        data-testid="icon-handle-customer"
        id="icon-handled-customer"
        currentLocation={`${getLocation()}`}
        imgSrc="/media/icons/shortcut/Handled_Customer_Inactive.png"
        imgActiveSrc="/media/icons/shortcut/Handled_Customer_Active.png"
        badgeStyle="bg-success"
        number={countUnreadMessages}
        nav="/handled-customer"
      ></IconImageRounded>
      <IconImageRounded
        data-testid="icon-contacts"
        id="icon-contact"
        currentLocation={`${getLocation()}`}
        imgSrc="/media/icons/shortcut/Contact_Inactive.png"
        imgActiveSrc="/media/icons/shortcut/Contact_Active.png"
        badgeStyle="bg-danger"
        nav="/contact/list"
      />
      <div className={clsx(`d-flex symbol align-items-center`)} data-testid="icon-product">
        <div className="btn-group dropup">
          <button
            type="button"
            className="btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px" }}
          >
            <img
              style={{ borderRadius: "80%" }}
              id="active_user_avatar"
              data-testid="AsideUserItemAvatarImage"
              src={toAbsoluteUrl(
                isActive && (pathname.includes("/campaign/") || pathname.includes("/template/")) ? "/media/icons/shortcut/Campaign_Active.png" : "/media/icons/shortcut/Campaign_Inactive.png"
              )}
              alt="7daysUser"
              className="mh-50px mw-50px"
            />
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                to="/campaign/list"
                className="dropdown-item"
              >
                {t("Campaign.Title.Campaign")}
              </Link>
            </li>
            <li>
              <Link to="/template/list" className="dropdown-item">
                {t("Campaign.Title.Template")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex symbol align-items-center">
        <div className="btn-group dropup">
          <button
            type="button"
            className="btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px" }}
          >
            <img
              style={{ borderRadius: "80%" }}
              id="active_user_avatar"
              data-testid="icon-user-account"
              src={toAbsoluteUrl(
                (user && user.avatar) ? user.avatar : (isActive && (pathname.includes("/storefront/") || pathname.includes("/product/")) ? "/media/icons/shortcut/Profile_Active.png" : "/media/icons/shortcut/Profile_Inactive.png")
              )}
              alt="7daysUser"
              className="mh-50px mw-50px"
            />
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                to="#"
                className="dropdown-item"
              >
                {t("HeaderBar.Button.Profile")}
              </Link>
            </li>
            <li>
              <Link to="/setting/" className="dropdown-item">
                {t("HeaderBar.Button.Settings")}
              </Link>
            </li>
            <li>
              <span onClick={handleLogout} className='menu-link px-5'>
                {t("HeaderBar.Button.Logout")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
