import { async } from "@firebase/util";
import firebase from "firebase/compat/app";
import * as server from "../api/server/products";
import Account from "../app/models/Account";
import MarketplaceProductCategory from "../app/models/MarketplaceProductCategory";
import Product, {
  Companies,
  ImportProduct,
  Marketplace,
  Media,
  ProductVariants,
  Shipments,
  ShipmentsType,
  Variant,
  VariantOption,
  attributeList,
  attributeValueList,
  categoryAttribute,
  productStatus,
} from "../app/models/Product";
import Shipment from "../app/models/Shipment";
import * as lc from "../app/modules/localstorage/index";
import { uploadFile } from "../app/modules/util/Utils";
import db, { Timestamp, createRef } from "../db";
import {
  createCompany,
  createCompanyAndMarketplace,
  createMarketplace,
  createMedia,
  createOrUpdateProductCategoryAttribute,
  createProduct,
  createProductVariant,
  createProductVariants,
  createShipments,
  createVariant,
  createVariants,
  deleteCompanyAndMarketplaces,
  deleteFileFromStorage,
  deleteMedia,
  deleteMediaDoc,
  deleteProductById,
  deleteShipments,
  getProductByStorefront,
  getShipmentChildrenByInsName,
  getShipmentParentByInsName,
  removeProductCategoryAttributes,
  removeProductVariants,
  removeVariants,
  transactionProduct,
  updateDecrementNumberOfProductsToStorefront,
  updateIncrementNumberOfProductsStorefront,
  updateProduct,
} from "../db/serviceProduct";
import { getAllChildParentCategoryById } from "../db/serviceStorefront";
import { IProductMediaUpload } from "../styles/components/ButtonUploadMediaRefV3";
import * as fileUtil from "../util/FileUtil";
import { createBrand } from "./brand";

type ProdCategoryMap = {
  id: string;
  hasChildren: boolean;
  level: number;
  name: {
    en?: string;
    id?: string;
  };
};

export const createDataProduct = async (dataProduct: Product) => {
  return await createProduct(dataProduct);
};

export const updateDataProduct = async (
  productID: string,
  dataProduct: Product
) => {
  return await updateProduct(productID, dataProduct);
};

async function convertToProductModel(
  importProduct: ImportProduct
): Promise<Product> {
  const clientRef = createRef("clients", lc.getItemLC(lc.LCName.Client).id);
  let product: Product; // Declare the product variable here

  if (!importProduct.brand && importProduct.brandName) {
    const newBrand = await createBrand(importProduct.brandName, undefined);
    if (!newBrand || !newBrand.id) {
      console.log(
        `Failed to create a new brand with brandName ${importProduct.brandName}`
      );
      // throw new Error(`Failed to create a new brand with brandName ${importProduct.brandName}`);
    } else {
      console.log(`CREATE BRAND ${importProduct.brandName}`);
      importProduct.brand = createRef("brands", newBrand.id);
      console.log(`brand created`);
    }
  }

  product = {
    client: clientRef,
    createdAt: firebase.firestore.Timestamp.now(),
    updatedAt: firebase.firestore.Timestamp.now(),
    sku: importProduct.parentSKU,
    status: productStatus.imported,
    parentSKU: importProduct.parentSKU,
    name: importProduct.productName,
    nameInsensitive: importProduct.productName.toLowerCase(),
    description: importProduct.productDescription,
    weight: importProduct.packageWeight,
    ...(importProduct.isDangerousGoods !== undefined && {
      isDangerousProduct: importProduct.isDangerousGoods,
    }),
    ...(importProduct.packageLength !== undefined && {
      length: importProduct.packageLength,
    }),
    ...(importProduct.packageWidth !== undefined && {
      width: importProduct.packageWidth,
    }),
    ...(importProduct.packageHeight !== undefined && {
      height: importProduct.packageHeight,
    }),
    ...(importProduct.hasVariant !== undefined && {
      isProductHaveVariants: importProduct.hasVariant,
    }),
    ...(importProduct.brand !== undefined && {
      brand: importProduct.brand,
    }),
    // Add other properties as needed
  };

  return product;
}

const importToVariant = async (
  importProduct: ImportProduct,
  productID: string,
  refUser: any
) => {
  let variants: Variant[] = [];
  let productVariants: ProductVariants[] = [];
  console.log(
    `importToVariant masuk || param ${importProduct}, || product id ${productID}} `
  );
  if (importProduct.hasVariant === true) {
    if (
      importProduct.variantData &&
      importProduct.variantData.variants &&
      importProduct.variantData.productVariants
    ) {
      const dataVariantToSave: Variant[] = await Promise.all(
        importProduct.variantData.variants.map(async (variant) => {
          let variantOptionts: VariantOption[] = [];
          if (variant.optionList) {
            const imgArr: string[] = [];
            variant.optionList.forEach((option) => {
              if (option.image) {
                imgArr.push(option.image);
              }
            });
            const savedImgs = await importVariantMedia(productID, imgArr);

            variantOptionts = variant.optionList.map((dataOption, index) => {
              if (!dataOption.image) {
                return dataOption;
              }
              return {
                ...dataOption,
                image: savedImgs[index],
              };
            });
          }

          return {
            ...variant,
            optionList: variantOptionts,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
            createdBy: refUser, // Set the createdBy reference if applicable
            updatedBy: refUser,
          };
        })
      );
      variants = dataVariantToSave;

      productVariants = importProduct.variantData.productVariants.map(
        (data) => {
          return {
            ...data,
            weight: importProduct.packageWeight,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
            createdBy: refUser, // Set the createdBy reference if applicable
            updatedBy: refUser,
          };
        }
      );
    }

    console.log(`importToVariant masuk has variant true `);
    // if (
    //   importProduct.variantName1 &&
    //   importProduct.variantOption1 &&
    //   importProduct.variantImage1
    // ) {
    //   const variant1: Variant = {
    //     index: 0, // Set the variant index if applicable
    //     optionList: [],
    //     name: importProduct.variantName1, // Set the variant name if available
    //     createdAt: firebase.firestore.Timestamp.now(),
    //     updatedAt: firebase.firestore.Timestamp.now(),
    //     createdBy: refUser, // Set the createdBy reference if applicable
    //     updatedBy: refUser, // Set the updatedBy reference if applicable
    //   };
    //   const options = importProduct.variantOption1.split(",");
    //   const image = importProduct.variantImage1.split(",");
    //   console.log(
    //     `importToVariant masuk has variant true , before import media `
    //   );
    //   const imageArr = await importVariantMedia(productID, image);
    //   console.log(
    //     `importToVariant masuk has variant true , after import media ${JSON.stringify(
    //       imageArr
    //     )} `
    //   );

    //   variant1.optionList = options.map((option, index) => ({
    //     option,
    //     image: imageArr[index],
    //   }));
    //   console.log(
    //     `importToVariant masuk has variant true , after import option list  ${JSON.stringify(
    //       variant1
    //     )} `
    //   );
    //   variants.push(variant1);

    //   if (importProduct.variantName2 && importProduct.variantOption2) {
    //     const variant2: Variant = {
    //       index: 1, // Set the variant index if applicable
    //       optionList: [],
    //       name: importProduct.variantName2, // Set the variant name if available
    //       createdAt: firebase.firestore.Timestamp.now(),
    //       updatedAt: firebase.firestore.Timestamp.now(),
    //       createdBy: refUser, // Set the createdBy reference if applicable
    //       updatedBy: refUser, // Set the updatedBy reference if applicable
    //     };
    //     const options = importProduct.variantOption2.split(",");
    //     variant2.optionList = options.map((option) => ({ option }));
    //     variants.push(variant2);
    //   }
    // }
  }

  console.log(
    `importToVariant after push variant 1 & 2  ${JSON.stringify(variants)} `
  );
  const importVariant = await createDataVariants(productID, variants);
  console.log(`importToVariant importVariant  with no data log `);
  console.log(
    `importToVariant importVariant  ${JSON.stringify(importVariant)} `
  );

  const importProductVariant = await createDataProductVariants(
    productID,
    productVariants
  );
  console.log(
    `importToVariant importProductVariant  ${JSON.stringify(
      importProductVariant
    )} `
  );

  return {
    variants,
    importVariant,
    importProductVariant,
  };
};

const importToShipment = async (
  expeditionName: (Shipment | undefined)[],
  productID: string,
  refUser: any
) => {
  // const expeditionNameArray = expeditionName.split(',');
  console.log(
    `importToShipment expeditionName ${expeditionName.length} ${JSON.stringify(
      expeditionName
    )}`
  );
  // loop and make it insensitive
  let array: Shipments[] = [];

  try {
    expeditionName.forEach((exp) => {
      // cek exist
      // const shipmentParentExist: Shipment | undefined = await getDataChildShipmentsByInsName(exp);
      console.log(`importToShipment data shipment ${JSON.stringify(exp)}`);
      console.log(
        `importToShipment data shipment ${exp} bef ${exp?.id} || ${exp?.name} || ${exp?.parentId} || ${exp?.parentName}`
      );

      if (exp && exp.id && exp.name && exp.parentId && exp.parentName) {
        const shipmentChildrenRef = createRef("shipments", exp.id);
        const shipmentChildrenName = exp.name;
        const shipmentParentRef = exp.parentId;
        const shipmentParentName = exp.parentName;
        console.log(
          `importToShipment data shipment in modify ${shipmentChildrenRef} || ${shipmentChildrenName} || ${shipmentParentRef} || ${shipmentParentName}`
        );

        const existingParent = array.find(
          (expArr) => expArr.name === shipmentParentName
        );
        console.log(
          `importToShipment data existingParent  ${JSON.stringify(
            existingParent
          )}`
        );

        if (existingParent) {
          const pushType: ShipmentsType = formatShipmentsType(
            shipmentChildrenRef,
            shipmentChildrenName
          );
          existingParent.types?.push(pushType);
          console.log(
            `importToShipment data existingParent  ${pushType} || array ${JSON.stringify(
              array
            )}`
          );
        } else {
          const pushType: ShipmentsType = formatShipmentsType(
            shipmentChildrenRef,
            shipmentChildrenName
          );
          const pushShipment: Shipments = formatShipmentsCollection(
            shipmentParentRef,
            shipmentParentName,
            true,
            [pushType],
            refUser
          );
          array.push(pushShipment);
          console.log(
            `importToShipment data !existingParent  ${pushShipment} || array ${JSON.stringify(
              array
            )}`
          );
        }
      }
    });

    await Promise.all(
      array.map(async (item) => {
        if (item) await createDataShipments(productID, item);
      })
    );

    console.log(
      `importToShipment data shipment after modify ${JSON.stringify(array)}`
    );
  } catch (error) {
    console.error("Error in importToShipment: ", error);
  }
};

const importToCategory = async (
  productId: string,
  categoryId: string,
  company: firebase.firestore.DocumentReference,
  marketplace: string,
  refUser: firebase.firestore.DocumentReference,
  account: firebase.firestore.DocumentReference
) => {
  const categories = await getAllChildParentCategoryById(
    marketplace,
    `${categoryId}`
  );
  console.log("Data founded categories", categories);

  if (!categories || categories.length <= 0) {
    return undefined;
  }

  const formatedCategories = formatCategories(categories);
  console.log("Data formated categories", formatedCategories);

  const dataMarketplace = {
    account: account,
    categories: [formatedCategories],
    company: company,
    isActive: true,
    marketplace: marketplace,
    createdBy: refUser,
    updatedBy: refUser,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };

  console.log("Data marketplace to save", dataMarketplace);

  await createMarketplace(productId, dataMarketplace);
};

const formatCategories = (categories: MarketplaceProductCategory[]) => {
  if (categories.length <= 0) {
    return [];
  }
  const categoryMap: { [id: string]: MarketplaceProductCategory } = {};
  const data: ProdCategoryMap[] = [];

  categories.forEach((category) => {
    if (category.categoryId) {
      categoryMap[category.categoryId] = category;
    }
  });

  console.log("category Map", categoryMap);

  categories.forEach((category) => {
    let level = 0;
    let parentId = category.parentCategoryId;

    while (
      parentId !== undefined &&
      parentId.toString() !== "" &&
      parentId.toString() !== "0"
    ) {
      level++;
      parentId = categoryMap[parentId].parentCategoryId;
    }
    const { categoryId, hasChildren, categoryName } = category;
    if (categoryId && hasChildren !== undefined && categoryName) {
      data.push({
        id: `${categoryId}`,
        hasChildren: hasChildren,
        level: level,
        name: categoryName,
      });
    }
  });

  return data
    .sort((a, b) => a.level - b.level)
    .reduce(
      (
        acc: {
          [key: number]: ProdCategoryMap;
        },
        category: ProdCategoryMap,
        index
      ) => {
        acc[index] = category;
        return acc;
      },
      {}
    );
};

// ImportProduct
export const importDataProduct = async (
  dataProduct: ImportProduct[],
  refUser: firebase.firestore.DocumentReference,
  accounts: Account[]
) => {
  const products: Product[] = await Promise.all(
    dataProduct.map((importProduct) => convertToProductModel(importProduct))
  );

  console.log(`action import product ${JSON.stringify(products)}`);
  const createProduct = await transactionProduct(products);

  if (createProduct.errorArray.length !== 0) {
    return createProduct;
  }

  try {
    await Promise.all(
      dataProduct.map(async (item, index) => {
        const productId = createProduct.idArray[index];

        await Promise.all(
          Object.keys(item).map(async (key) => {
            if (key.startsWith("imageURL_")) {
              const imageURL = item[key as keyof typeof item];
              const imageNumber = parseInt(key.split("_")[1], 10);

              console.log(
                `imageURL ${imageNumber} : ${imageURL} || productId : ${productId}`
              );

              if (typeof imageURL === "string") {
                const convertImageUrl = await fileUtil.generateMedia(imageURL);
                console.log(
                  `imageURL ${imageNumber} : ${imageURL} || converttoMedia() : ${convertImageUrl} || productId : ${productId}`
                );

                const isCover = imageNumber === 1;

                try {
                  await importProductMedia(
                    productId,
                    convertImageUrl,
                    isCover,
                    refUser
                  );
                } catch (error) {
                  console.log(
                    `Error importing media for product ${productId}: ${
                      (error as Error).message
                    }`
                  );
                  createProduct.errorArray[index] = (error as Error).message;
                }
              }
            }
          })
        );

        console.log(`action import before shipment`);
        if (item.expeditions) {
          const shipment = await importToShipment(
            item.expeditions,
            productId,
            refUser
          );
          console.log(`after import shipment ${JSON.stringify(shipment)}`);
        }

        console.log(`action import before variants`);
        const variants = await importToVariant(item, productId, refUser);
        console.log(
          `action import variants ${JSON.stringify(variants.variants)}`
        );
        console.log(
          `action import variantsreturn ${JSON.stringify(
            variants.importVariant
          )}`
        );

        await Promise.all(
          accounts.map(async (account) => {
            return await importToCategory(
              productId,
              item.categoryId,
              createRef("company", account.company.id),
              account.type,
              refUser,
              createRef("account", account.id)
            );
          })
        );
      })
    );
  } catch (error) {
    console.log(
      "Error occurred during image import. Rolling back transaction."
    );
    throw error;
  }

  return createProduct;
};

const uploadToFirestorage = async (
  file: any,
  filetype: string,
  fileName: string,
  path: string
) => {
  const inputcontentType = filetype;
  const inputFileName = fileName;
  return await uploadFile(file, inputcontentType, inputFileName, path);
};

export const importVariantMedia = async (
  productID: string,
  imageArr: string[]
) => {
  let imageFirebase: string[] = [];
  for (let index = 0; index < imageArr.length; index++) {
    const element = await fileUtil.generateMedia(imageArr[index]);
    let elem = {
      ...element,
      name: element.name || "variant-photo",
      type: element.type || "jpeg",
    };
    const result_FS_URL = await uploadToFirestorage(
      elem.url,
      elem.type,
      elem.name,
      "product/variant-photos/" + productID
    );
    console.log("Result URL Variant Photos : " + result_FS_URL);
    if (result_FS_URL && result_FS_URL !== "") {
      imageFirebase.push(result_FS_URL);
    }
  }

  return imageFirebase;
};

export const importProductMedia = async (
  productID: string,
  imageMedia: IProductMediaUpload,
  coverImage: boolean,
  refUser: any
) => {
  try {
    const result_FS_URL = await uploadToFirestorage(
      imageMedia.url,
      imageMedia.type,
      imageMedia.name,
      "product/photos/" + productID
    );
    console.log("Result URL Photos: " + result_FS_URL);

    if (result_FS_URL && result_FS_URL !== "") {
      // Create Media Collection
      const dataMedia = await formatMediaCollection(
        imageMedia.url,
        imageMedia.name,
        imageMedia.extension,
        imageMedia.type,
        imageMedia.size,
        result_FS_URL,
        coverImage,
        "",
        refUser
      );

      const mediaID = await createDataMedia(productID, "photos", dataMedia);

      if (mediaID && mediaID !== "") {
        // Handle the successful creation of media
        console.log("New media created successfully");
      } else {
        console.log("Error creating new media");
        throw new Error("Failed to create media");
      }
    } else {
      console.log("Error uploading media to FireStorage");
      throw new Error("Failed to upload media");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error importing product media");
  }
};

// Process Revert Data
export const revertUploadAndSavedMedia = async (
  fireStorageID: string[], //File Firebase Storage
  mediaIDPhotos: string[], //Collection Media ID Photos
  mediaIDVideo: string, //Collection Media ID Video
  productID: string //Collection Media ID Video
) => {
  //Delete collection from firestorage
  for (let index = 0; index < mediaIDPhotos.length; index++) {
    const element = mediaIDPhotos[index];
    if (element && element !== "") {
      deleteMedia(productID, element, "photos");
    }
  }

  if (mediaIDVideo && mediaIDVideo !== "") {
    deleteMedia(productID, mediaIDVideo, "photos");
  }

  //Delete file from storage
  for (let index = 0; index < fireStorageID.length; index++) {
    const element = fireStorageID[index];
    deleteFileFromStorage(element);
  }
};

/***
 * File Media
 */

export const deleteFileMedia = (params: any) => {
  return deleteFileFromStorage(params);
};

export const deleteMediaData = (
  productID: string,
  mediaID: string,
  mediaType: string
) => {
  return deleteMedia(productID, mediaID, mediaType);
};

export const deleteMediaDocData = (productID: string) => {
  return deleteMediaDoc(productID);
};

export const deleteShipmentsData = (productID: string) => {
  return deleteShipments(productID);
};

export const deleteCompanyAndMarketplacesData = (productID: string) => {
  return deleteCompanyAndMarketplaces(productID);
};

export const createDataMedia = async (
  productID: string,
  mediaType: string,
  dataMedia: Media
) => {
  return await createMedia(productID, mediaType, dataMedia);
};

export const formatMediaCollection = async (
  file: any,
  fileName: string,
  extension: string,
  filetype: string,
  filesize: string,
  fileURL: string,
  isCover: boolean,
  youtubeURL: string,
  refUser: firebase.firestore.DocumentReference
) => {
  let mediaData = {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    isActive: true,
    extension: extension,
    fileName: fileName,
    fileSize: filesize,
    fileURL: fileURL,
    fileType: filetype,
    isCover: isCover,
  } as Media;
  if (youtubeURL && youtubeURL !== "") {
    mediaData.youtbeURL = youtubeURL;
  }
  mediaData.createdBy = refUser;
  mediaData.updatedBy = refUser;
  return mediaData;
};

export const formatShipmentsCollection = (
  shipmentID: firebase.firestore.DocumentReference,
  name: string,
  isActive: boolean,
  types: ShipmentsType[],
  refUser: firebase.firestore.DocumentReference
) => {
  let shipmentsData = {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    shipmentID: shipmentID,
    isActive: isActive,
    name: name,
    types: types,
  } as Shipments;
  shipmentsData.createdBy = refUser;
  shipmentsData.updatedBy = refUser;
  return shipmentsData;
};

export const formatShipmentsType = (
  shipmentId: firebase.firestore.DocumentReference,
  name: string
) => {
  let shipmentsData = {
    shipmentID: shipmentId,
    name: name,
  } as ShipmentsType;
  return shipmentsData;
};

export const createDataShipments = async (
  productID: string,
  dataShipments: Shipments
) => {
  return await createShipments(productID, dataShipments);
};

export const getDataParentShipmentsByInsName = async (shipmentName: string) => {
  const insShipmentName = shipmentName.replaceAll(" ", "").toLowerCase();

  return await getShipmentParentByInsName(insShipmentName);
};

export const getDataChildShipmentsByInsName = async (shipmentName: string) => {
  const insShipmentName = shipmentName.replaceAll(" ", "").toLowerCase();

  return await getShipmentChildrenByInsName(insShipmentName);
};

/*
  select company and marketplace
*/
export const formatMarketplacesCollection = async (
  company: firebase.firestore.DocumentReference,
  isActive: boolean,
  marketplace: string,
  categories: any,
  refUser: firebase.firestore.DocumentReference,
  account?: firebase.firestore.DocumentReference,
  attributeList?: attributeList[]
) => {
  let marketplacesData = {
    company,
    isActive,
    marketplace,
    categories,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    account,
    attributeList,
  } as Marketplace;
  marketplacesData.createdBy = refUser;
  marketplacesData.updatedBy = refUser;
  return marketplacesData;
};

export const createDataCompanyAndMarketplace = async (
  productID: string,
  companies: Companies[],
  marketplaces: Marketplace
) => {
  return await createCompanyAndMarketplace(productID, companies, marketplaces);
};

export const formatAttributeList = async (
  attributeId: number,
  attributeValueList: attributeValueList[]
) => {
  const attribute = {
    attributeId,
    attributeValueList: attributeValueList,
  };
  return attribute as attributeList;
};

export const formatAttributeValueList = async (
  valueId: number,
  valueUnit?: string,
  originalValueName?: string
) => {
  const attributeValue = {
    valueId,
    valueUnit,
    originalValueName,
  };
  return attributeValue as attributeValueList;
};

export const createDataCompany = async (
  productID: string,
  companies: Companies[]
) => {
  return await createCompany(productID, companies);
};

export const createDataMarketplace = async (
  productID: string,
  marketplaces: Marketplace
) => {
  return await createMarketplace(productID, marketplaces);
};

export const formatVariantOptionList = async (
  option?: string,
  image?: string
) => {
  let variantOptionData = {
    option,
    image,
  } as VariantOption;
  return variantOptionData;
};

export const formatVariantOptionListOptionOnly = async (option?: string) => {
  let variantOptionData = {
    option,
  } as VariantOption;
  return variantOptionData;
};

export const formatVariantCollection = async (
  name: string,
  index: number,
  optionList: VariantOption,
  refUser: firebase.firestore.DocumentReference
) => {
  let variantData = {
    name,
    index,
    optionList,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  } as Variant;
  variantData.createdBy = refUser;
  variantData.updatedBy = refUser;
  return variantData;
};

export const createDataVariants = async (
  productID: string,
  variants: Variant[]
) => {
  console.log(
    `createDataVariants param productID ${productID}|| variants ${JSON.stringify(
      variants
    )}`
  );
  return await createVariants(productID, variants);
};

export const createDataVariant = async (
  productID: string,
  variant: Variant
) => {
  return await createVariant(productID, variant);
};

export const removeDataVariants = async (productID: string) => {
  return await removeVariants(productID);
};

export const createDataProductVariants = async (
  productID: string,
  variants: ProductVariants[]
) => {
  return await createProductVariants(productID, variants);
};

export const removeDataProductVariants = async (productID: string) => {
  return await removeProductVariants(productID);
};

export const formatProductVariantCollection = async (
  tierIndex: [],
  sku: string,
  weight: number,
  isActive: boolean,
  isMainVariant: boolean,
  stock: number,
  refUser: firebase.firestore.DocumentReference
) => {
  let variantProductData = {
    tierIndex,
    sku,
    weight,
    isActive,
    isMainVariant,
    stock,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  } as ProductVariants;
  variantProductData.createdBy = refUser;
  variantProductData.updatedBy = refUser;
  return variantProductData;
};

export const createDataProductVariant = async (
  productID: string,
  productvariant: ProductVariants
) => {
  return await createProductVariant(productID, productvariant);
};

export const formatProductCategoryAttributes = async (
  attributeId: string,
  inputValues: any[],
  categoryId: string[],
  marketplace: string,
  refUser: firebase.firestore.DocumentReference
) => {
  let categoryAttributeData = {
    attributeId: attributeId,
    inputValues: inputValues,
    categoryId: categoryId,
    marketplace: marketplace,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  } as categoryAttribute;
  categoryAttributeData.createdBy = refUser;
  categoryAttributeData.updatedBy = refUser;
  return categoryAttributeData;
};

export const createDataProductCategoryAttributes = async (
  productID: string,
  attributeId: string,
  categoryAttributeData: categoryAttribute
) => {
  return await createOrUpdateProductCategoryAttribute(
    productID,
    attributeId,
    categoryAttributeData
  );
};

export const removeDataCategoryAttributes = async (productID: string) => {
  return await removeProductCategoryAttributes(productID);
};

export const requestCreateProduct = async (
  accountID: string,
  productID: string,
  userID: string
  // callback: any
) => {
  console.log("masuk ke requestCreateProduct", accountID, productID, userID);
  return await server.sendRequestProduct(
    accountID,
    productID,
    userID
    // , callback
  );
};
export const deleteProductData = (id: string) => deleteProductById(id);

export const getProductByStorefrontID = async (storefrontId: string) => {
  return await getProductByStorefront(storefrontId);
};

export const incrementNumberOfProductStorefront = async (
  storefrontId: string
) => {
  return await updateIncrementNumberOfProductsStorefront(storefrontId);
};

export const decrementNumberOfProductStorefront = async (
  storefrontId: string
) => {
  return await updateDecrementNumberOfProductsToStorefront(storefrontId);
};
