import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, { ShipmentDeadlines, UpdateShipments } from "./ShipmentsRedux";

const initialState: InitialState = {
  listShipments: [],
  summaryListShipments: [],
  shipmentDeadlines: [],
};

export const ShipmentSlice = createSlice({
  name: UpdateShipments,
  initialState: initialState,
  reducers: {
    setShipmentList: (state, action: PayloadAction<any>) => {
      state.listShipments = action.payload;
    },
    setSummaryShipmentList: (state, action: PayloadAction<any>) => {
      state.summaryListShipments = action.payload;
    },
    cleanReduxShipment: (state, action: PayloadAction<boolean>) => {
      // state.listShipments = [];
      state.summaryListShipments = [];
    },
    setShipmentDeadlines: (state, action: PayloadAction<ShipmentDeadlines[]>) => {
      state.shipmentDeadlines = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShipmentList, cleanReduxShipment, setSummaryShipmentList, setShipmentDeadlines } = ShipmentSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default ShipmentSlice.reducer;
