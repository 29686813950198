import { Link, useNavigate } from "react-router-dom";
import { updateMarketplaceInformation } from "../../../../../../../../api/server/marketplace";
import Account from "../../../../../../../models/Account";
import { useFirestoreTable } from "../../context/TableContext";
import { useTranslation } from "react-i18next";

type MarketplaceFormEditFooterType = { dataAccount?: Partial<Account> };
const MarketplaceFormEditFooter: React.FC<MarketplaceFormEditFooterType> = (props) => {
  const { accountFormData: data, setAccountFormData } = useFirestoreTable();
  const { t } = useTranslation();
  const nav = useNavigate();
  const submitMarketplaceEdit = async (data?: Partial<Account>) => {
    console.log(data, "ini data dari client input edid");
    if (!data?.id) {
      return;
    }
    const fromServer = await updateMarketplaceInformation(data.id, data);
    console.log(fromServer, "ini data dari server edid");
    nav("/setting/marketplace/marketplace-list");
  };
  return (
    <div className="border-top d-flex justify-content-between py-3">
      <div className="d-flex align-items-center ps-10">
        <Link className="btn btn-primary mr-2" to={"/setting/marketplace/marketplace-list"}>
          <i className="fas fa-arrow-left"></i> {t("Marketplace.Edit.Back")}
        </Link>
      </div>
      <div className="pe-10">
        <button className="btn btn-primary" onClick={() => submitMarketplaceEdit(data)}>
          <i className="fas fa-check"></i> {t("Marketplace.Edit.Submit")}
        </button>
      </div>
    </div>
  );
};

export default MarketplaceFormEditFooter;
