import clsx from "clsx";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/toggle.scss";
import "../../../../../styles/css/color.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { RootState } from "../../../../../setup/redux/store";
import { useMediaQuery } from "react-responsive";
import FailOverTextArea from "../../../../components/textarea/FailOverTextArea";
import { KeyValues } from "../../../../../util/Formatter";
import TextAreaFailover from "../../../../components/textarea/TextAreaFailover";
import Select from "react-select";
import { BodyParamLanguage, failover } from "../../../../models/Language";
import { convertTextStyle } from "../../../../../util/TextFormatter";

interface TemplateProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  validateInputHandler: (isValid: boolean) => void;
}
type MessageParameter = {
  item: string;
  label: string;
};

const CampaignFailover: FC<TemplateProps> = (props) => {
  type Param = {
    text: string;
    paramNo: number;
    paramName: string;
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { i18n, t } = useTranslation();
  const [failoverToggle, setFailoverToggle] = useState<boolean>(false);
  const [failoverMessage, setFailoverMessage] = useState<string>("");
  const [listParameter, setListParameter] = useState<MessageParameter[]>([
    {
      item: "Campaign's Name",
      label: "Campaign's Name",
    },
    {
      item: "Customize text/URL",
      label: "Custom text/URL",
    },
  ]);
  const [selectedParameter, setSelectedParameter] =
    useState<MessageParameter>();
  const [sendWhenUndelivered, setSendWhenUndelivered] =
    useState<boolean>(false);
  const [undeliveredTime, setUndeliveredTime] = useState<number>(1);
  const [undeliveredUnit, setUndeliveredUnit] = useState<string>("Hour");

  const [previewMessage, setPreviewMessage] = useState<string>("");
  const [failoverBodyParam, setFailoverBodyParam] = useState<any[] | []>([]);
  const [inputParamName, setInputParamName] = useState<string>("");
  const [alertEmptyParamName, setAlertEmptyParamName] =
    useState<boolean>(false);

  const customStyles = {
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "white"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedParameter?.label
            ? "#0275d8" // Background color on hover or when option is selected
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
      color: "#1e2022", // Color of the selected value
    }),
  };

  const myRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  const reduxLoading = useSelector(
    (state: RootState) => state.Campaign.isLoading
  );
  const reduxCampaignData = useSelector(
    (state: RootState) => state.Campaign.campaignData
  );
  console.log(reduxCampaignData, "rcmpdata wix");
  console.log(listParameter, "listpar wix");
  const reduxTemplateFailover = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage?.failover
  );
  const reduxSelectedTemplate = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage
  );
  const campaign = useSelector((state: RootState) => state.Campaign);
  const templateCategory = useSelector(
    (state: RootState) => state.Template.templateCategory
  );

  const templateData = useSelector((state: RootState) => state.Template);

  const campaignData = useSelector((state: RootState) => state.Campaign);

  console.log(templateData, "tempadat kio");
  console.log(campaignData, "campadat kio");

  let option: any =
    templateCategory !== "authentication"
      ? ({
          "campaigns.campaignName": "Campaign's Name",
          CustomizeText: "Customize text/URL",
        } as any)
      : ({
          "template.otp": "OTP Code",
          "campaigns.campaignName": "Campaign's Name",
          CustomizeText: "Customize text/URL",
        } as any);

  const reduxTemplateLanguage = useSelector(
    (state: RootState) => state.Template
  );

  const duplicateParamName = useSelector(
    (state: RootState) => state.Template.alertDuplicateParamName
  );
  const reduxEmptyCustomTextlert = useSelector(
    (state: RootState) => state.Template.templateEmptyCustomTextAlert
  );

  useEffect(() => {
    if (reduxTemplateFailover) {
      if (
        reduxTemplateFailover?.message !== null &&
        reduxTemplateFailover?.message !== undefined
      ) {
        setFailoverToggle(true);
        let arrBodyParam: any[] = [];
        let convertMesssage = reduxTemplateFailover?.message;
        for (
          let index = 0;
          index < reduxTemplateFailover?.bodyParam?.length;
          index++
        ) {
          const element = reduxTemplateFailover?.bodyParam[index];
          convertMesssage = convertMesssage.replace(
            `{{${index + 1}}}`,
            `{{${
              element.paramName !== "" ? element.paramName : element.paramValue
            }}}`
          );
        }

        // console.log(convertMesssage, "conmess noel")

        let bodyParam = convertMesssage
          .split("{{")
          .filter((val) => val.includes("}}"))
          .map((val) => val.substring(0, val.indexOf("}}")));
        if (bodyParam.length > 0) {
          for (let index = 0; index < bodyParam.length; index++) {
            const element = bodyParam[index];
            let opsi = option;
            reduxTemplateFailover?.bodyParam?.forEach((item: any) => {
              const { paramKey, paramValue, paramName } = item;
              opsi = {
                ...opsi,
                [paramName !== "" ? paramName : paramKey]: paramValue,
              };
            });
            const keyParam = Object.keys(opsi).find((value, index, array) => {
              return t("Campaign.Input." + array[index]) === element;
            });

            let tempBodyParams = bodyParam
              ? bodyParam
              : reduxTemplateFailover?.bodyParam;
            let newParam = tempBodyParams?.find((param: any) => {
              return param.paramId === index + 1;
            });
            let paramNameValue = "";

            if (keyParam && keyParam !== "") {
              let text = "";
              let type = `${
                keyParam === "CustomizeText" ? "customText" : keyParam
              }`;
              console.log(" PFM ini type > " + type);
              if (
                newParam &&
                newParam.paramType &&
                newParam.paramType === type
              ) {
                console.log(
                  "PFM ini masuk ubah text content >>>" + newParam.textContent
                );
                if (newParam.textContent) {
                  text = newParam.textContent;
                }
                if (newParam.paramName) {
                  console.log(`masuk ambil dari redux paramName PFM`);
                  paramNameValue = newParam.paramName;
                }
              }
              console.log("PFM ini text > " + text);

              let header = {
                paramId: index + 1,
                paramKey: `${
                  keyParam === "CustomizeText"
                    ? "customText" + (index + 1)
                    : keyParam
                }`,
                paramType: `${
                  keyParam === "CustomizeText" ? "customText" : keyParam
                }`,
                paramValue: element,
                textContent: text,
                paramName:
                  keyParam === "CustomizeText"
                    ? newParam
                      ? paramNameValue
                      : ""
                    : "",
                // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
              };

              console.log(header, "masuk sini uye");

              // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
              console.log(
                "PFM New param added object >>> " + JSON.stringify(header)
              );
              arrBodyParam.push(header);
            } else {
              console.log(`masuk else PFM`);
              console.log(`PFM element >>> ${element}`);
              console.log(`PFM newParam >>> ${JSON.stringify(newParam)}`);
              let opsi2 = option;
              reduxTemplateFailover?.bodyParam?.forEach((item: any) => {
                const { paramKey, paramValue, paramName, textContent } = item;
                opsi2 = {
                  ...opsi2,
                  [paramName !== "" ? paramName : paramKey]: textContent,
                };
              });
              console.log(opsi2, "opsi2 uye");
              let newText = opsi2[element];
              console.log(newText, "apa ini uye");
              if (element !== "") {
                //find if duplicate
                // const findParamByParamName = checkParamName(element)
                // console.log(`findParamByParamName : ${findParamByParamName}`)
                // if(findParamByParamName && findParamByParamName.paramId !== index+1){
                //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
                // }else{
                //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
                let text: string =
                  newParam && newParam.textContent
                    ? newParam.textContent
                    : newText
                    ? newText
                    : "";
                let header = {
                  paramId: index + 1,
                  paramKey: `customText${index + 1}`,
                  paramType: `customText`,
                  paramValue: t("Campaign.Input.CustomizeText"),
                  textContent: text,
                  paramName: element,
                };
                console.log(header, "masuk situ uye");
                arrBodyParam.push(header);
                // }
              }
            }
          }
        }
        let templateLanguange = convertMesssage;
        // for (let index = 0; index < bodyParam.length; index++) {
        //   const element = bodyParam[index];
        //   templateLanguange = templateLanguange.replace(
        //     `{{${element}}}`,
        //     `{{${index + 1}}}`
        //   );
        // }
        console.log(arrBodyParam, "abp");
        setFailoverMessage(templateLanguange);
        setPreviewMessage(convertMesssage);
        setFailoverBodyParam(arrBodyParam);
      }
      if (reduxTemplateFailover.sendWhenUndelivered) {
        setSendWhenUndelivered(reduxTemplateFailover.sendWhenUndelivered);
      }
      if (reduxTemplateFailover.undeliveredTime) {
        setUndeliveredTime(reduxTemplateFailover.undeliveredTime);
      }
      if (reduxTemplateFailover.undeliveredTimeUnit) {
        setUndeliveredUnit(reduxTemplateFailover.undeliveredTimeUnit);
      }
    } else {
      setPreviewMessage("");
      setFailoverMessage("");
    }
    console.log(
      "--MASUK USEEFFECT REDUXTEMPLATEFAILOVER REDUXSELECTEDTEMPLATE CPFO--"
    );
  }, [reduxTemplateFailover, reduxSelectedTemplate]);

  useEffect(() => {
    if (templateCategory === "authentication") {
      setFailoverMessage("{{OTP CODE}}");
      if (failoverToggle === true) {
        let temp_data = Object.assign({}, reduxCampaignData, {
          failover: {
            message: "{{OTP CODE}}",
            bodyParam: [],
            bodyParamCount: 0,
            sendWhenUndelivered: sendWhenUndelivered,
            undeliveredTime: 0,
            undeliveredTimeUnit: "",
          },
        });
        console.log(reduxCampaignData, "reduxCampaignData before 337 RCD");
        console.log(temp_data, "temp_data before 337 RCD");
        dispatch(ReduxCampaign.setCampaignData(temp_data));
        console.log(reduxCampaignData, "reduxCampaignData 337 RCD");
      }
    } else {
      setFailoverMessage("");
      if (failoverToggle === true) {
        let temp_data = Object.assign({}, reduxCampaignData, {
          failover: {
            message: failoverMessage,
            bodyParam: reduxCampaignData?.bodyParam,
            bodyParamCount: reduxCampaignData?.bodyParamCount,
            sendWhenUndelivered: sendWhenUndelivered,
            undeliveredTime: 0,
            undeliveredTimeUnit: "",
          },
        });
        console.log(reduxCampaignData, "reduxCampaignData before 354 RCD");
        console.log(temp_data, "temp_data before 354 RCD");
        dispatch(ReduxCampaign.setCampaignData(temp_data));
        console.log(reduxCampaignData, "reduxCampaignData 354 RCD");
      }
    }

    if (templateCategory === "interactive") {
      const NewlistDataParameter = listParameter.filter(
        (item) => item.item !== "Campaign's Name"
      );
      console.log(NewlistDataParameter, "1 nlp");
      setListParameter(NewlistDataParameter);
    } else {
      let opsi = option;
      reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
        const { paramKey, paramName, paramValue } = item;
        opsi = {
          ...opsi,
          [paramName !== "" ? paramName : paramKey]: paramValue,
        };
      });
      const listDataParameter: MessageParameter[] = Object.keys(opsi).map(
        (value, index, array) => {
          console.log(value, index, array, "itempar peq");
          console.log(opsi[value], "itemopt peq");
          return {
            item: opsi[value],
            label:
              opsi[value] !== "Custom text/URL" &&
              opsi[value] !== "Teks/URL yang disesuaikan"
                ? t("Campaign.Input." + array[index])
                : array[index],
          };
        }
      );
      console.log(listDataParameter, "2 nlp");
      setListParameter(listDataParameter);
    }
  }, [templateCategory]);

  useEffect(() => {
    console.log(
      "CHECK USE EFFECT UNTUK TEMPLATE UPDATE  ==================================================="
    );
    //set setListParameter
    // if (listParameter.length <= 0) {
    let opsi = option;
    reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
      const { paramKey, paramName, paramValue } = item;
      opsi = {
        ...opsi,
        [paramName !== "" ? paramName : paramKey]: paramValue,
      };
    });
    const listDataParameter: MessageParameter[] = Object.keys(opsi).map(
      (value, index, array) => {
        console.log(value, index, array, "itempar peq");
        console.log(opsi[value], "itemopt peq");
        return {
          item: opsi[value],
          label:
            opsi[value] !== "Custom text/URL" &&
            opsi[value] !== "Teks/URL yang disesuaikan"
              ? t("Campaign.Input." + array[index])
              : array[index],
        };
      }
    );
    if (listDataParameter) {
      console.log(listDataParameter, "3 nlp");
      setListParameter(listDataParameter);
    }
    // }
  }, [reduxCampaignData, reduxSelectedTemplate]);

  const onChangeMessage = (e: any) => {
    let valuetarget = e.currentTarget.value;
    console.log("---MASUK onChangeMessage OCM CPFO---");
    let arrBodyParam: any[] = [];
    // let option: any = KeyValues;
    let bodyParam = valuetarget
      .split("{{")
      .filter((val: any) => val.includes("}}"))
      .map((val: any) => val.substring(0, val.indexOf("}}")));
    console.log(bodyParam, "bodyParam OCM CPFO");
    if (bodyParam.length > 0) {
      for (let index = 0; index < bodyParam.length; index++) {
        const element = bodyParam[index];
        console.log(element, "element OCM CPFO");
        let opsi = option;
        console.log(opsi, "opsi OCM CPFO");
        reduxSelectedTemplate?.failover?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue, paramName } = item;
          opsi = {
            ...opsi,
            [paramName !== "" ? paramName : paramKey]: paramValue,
          };
        });
        reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue, paramName } = item;
          opsi = {
            ...opsi,
            [paramName !== "" ? paramName : paramKey]: paramValue,
          };
        });
        const keyParam = Object.keys(opsi).find((value, index, array) => {
          return t("Campaign.Input." + array[index]) === element;
        });
        console.log(keyParam, "keyParam OCM CPFO");

        let tempBodyParams = bodyParam
          ? bodyParam
          : reduxTemplateFailover?.bodyParam;
        console.log(tempBodyParams, "tempBodyParams OCM CPFO");
        let newParam = tempBodyParams?.find((param: any) => {
          return param.paramId === index + 1;
        });
        console.log(newParam, "newParam OCM CPFO");
        let paramNameValue = "" || inputParamName;
        console.log(paramNameValue, "paramNameValue OCM CPFO");
        if (keyParam && keyParam !== "") {
          console.log("MASUK IF KEYPARAM !== OCM");

          let text = "";
          let type = `${
            keyParam === "CustomizeText" ? "customText" : keyParam
          }`;
          console.log(type, "type OCM CPFO");
          if (newParam && newParam.paramType && newParam.paramType === type) {
            console.log(
              "PFM ini masuk ubah text content >>>" + newParam.textContent
            );
            if (newParam.textContent) {
              text = newParam.textContent;
            }
            if (newParam.paramName) {
              console.log(`masuk ambil dari redux paramName PFM`);
              paramNameValue = newParam.paramName;
            }
          }
          console.log("PFM ini text > " + text);
          console.log(text, "text OCM CPFO");

          let header = {
            paramId: index + 1,
            paramKey: `${
              keyParam === "CustomizeText"
                ? "customText" + (index + 1)
                : keyParam
            }`,
            paramType: `${
              keyParam === "CustomizeText" ? "customText" : keyParam
            }`,
            paramValue: element,
            textContent: text,
            paramName:
              keyParam === "CustomizeText"
                ? newParam
                  ? paramNameValue
                  : ""
                : "",
            // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
          };
          console.log(header, "header OCM CPFO");

          // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
          console.log(
            "PFM New param added object >>> " + JSON.stringify(header)
          );
          arrBodyParam.push(header);
          console.log(arrBodyParam, "arrBodyParam OCM CPFO");
          console.log("KELUAR IF KEYPARAM !== OCM");
        } else {
          console.log(`masuk else OCM PFM`);
          console.log(`PFM OCM element >>> ${element}`);
          console.log(`PFM OCM newParam >>> ${JSON.stringify(newParam)}`);
          let opsi2 = option;
          console.log(opsi2, "opsi2 OCM CPFO");

          reduxSelectedTemplate?.failover?.bodyParam?.forEach((item: any) => {
            const { paramKey, paramValue, paramName, textContent } = item;
            opsi2 = {
              ...opsi2,
              [paramName !== "" ? paramName : paramKey]: textContent,
            };
          });
          reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
            const { paramKey, paramValue, paramName, textContent } = item;
            opsi2 = {
              ...opsi2,
              [paramName !== "" ? paramName : paramKey]: textContent,
            };
          });
          let newText = opsi2[element];
          console.log(newText, "newText OCM CPFO");
          if (element !== "") {
            console.log("MASUK IF ELEMENT !== OCM");

            //find if duplicate
            // const findParamByParamName = checkParamName(element)
            // console.log(`findParamByParamName : ${findParamByParamName}`)
            // if(findParamByParamName && findParamByParamName.paramId !== index+1){
            //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
            // }else{
            //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
            let text: string =
              newParam && newParam.textContent
                ? newParam.textContent
                : newText
                ? newText
                : "";
            console.log(text, "ini text OCM kox");
            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: text,
              paramName: element,
            };
            console.log(header, "header OCM CPFO");
            arrBodyParam.push(header);
            console.log(arrBodyParam, "arrBodyParam OCM CPFO");
            // }
            console.log("KELUAR IF ELEMENT !== OCM");
          }
          console.log(`keluar else OCM PFM`);
        }
      }
    }

    // let templateLanguange = valuetarget;
    console.log(valuetarget, "templang ocm kox");
    console.log(arrBodyParam, "arrbodpar ocm kox");
    console.log(bodyParam, "bod ocm kox");
    setFailoverMessage(valuetarget);
    setPreviewMessage(valuetarget);
    setFailoverBodyParam(arrBodyParam);
    let dbMessage = e.currentTarget.value;
    for (let index = 0; index < arrBodyParam.length; index++) {
      const element = arrBodyParam[index];
      dbMessage = dbMessage.replace(
        new RegExp(`{{${element.paramName || element.paramValue}}}`, "g"),
        `{{${index + 1}}}`
      );
    }
    // setFailoverBodyParam((prevFailoverBodyParam) => {
    //   return [...prevFailoverBodyParam, arrBodyParam];
    // });
    console.log(dbMessage, "dbmess kox");
    console.log(reduxCampaignData, "reduxCampaignData before 567 RCD");
    let temp_data = Object.assign({}, reduxCampaignData, {
      failover:
        sendWhenUndelivered === true
          ? {
              message: dbMessage,
              sendWhenUndelivered: sendWhenUndelivered,
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              undeliveredTime: undeliveredTime,
              undeliveredTimeUnit: undeliveredUnit,
            }
          : {
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              message: dbMessage,
              sendWhenUndelivered: sendWhenUndelivered,
            },
    });
    console.log(reduxCampaignData, "reduxCampaignData before 567 RCD");
    dispatch(ReduxCampaign.setCampaignData(temp_data));

    console.log(temp_data, "temp_data 567 RCD");
    console.log(reduxCampaignData, "reduxCampaignData 567 RCD");
    console.log("---KELUAR onChangeMessage OCM CPFO---");
  };

  const onChangeValidTextStatus = (isValid: boolean) => {
    console.log(failoverToggle, "DIS IS FAILOVERTOGGLE FT");

    if (failoverToggle !== true) {
      console.log("failover pas false FT");
      props.validateInputHandler(!isValid);
    } else {
      console.log("failover pas true FT");
      props.validateInputHandler(isValid);
    }
  };

  const addParameter = () => {
    console.log("---MASUK ADDPARAMETER CPFO---");

    const e: any = selectedParameter;
    let newMessage =
      failoverMessage +
      "{{" +
      (e?.label !== "Custom text/URL" &&
      e?.label !== "Teks/URL yang disesuaikan"
        ? e?.label
        : inputParamName) +
      "}}";
    let arrBodyParam: any[] = [];
    let bodyParam = newMessage
      .split("{{")
      .filter((val: any) => val.includes("}}"))
      .map((val: any) => val.substring(0, val.indexOf("}}")));
    console.log(bodyParam, "bodyParam CPFO");
    if (bodyParam.length > 0) {
      console.log("masuk if bodyparam length");

      for (let index = 0; index < bodyParam.length; index++) {
        const element = bodyParam[index];
        console.log(element, "element CPFO");
        let opsi = option;
        reduxSelectedTemplate?.failover?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue, paramName } = item;
          opsi = {
            ...opsi,
            [paramName !== "" ? paramName : paramKey]: paramValue,
          };
        });
        reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
          const { paramKey, paramValue, paramName } = item;
          opsi = {
            ...opsi,
            [paramName !== "" ? paramName : paramKey]: paramValue,
          };
        });
        console.log(opsi, "opsi CPFO");
        const keyParam = Object.keys(opsi).find((value, index, array) => {
          console.log(array[index], "arr index cpfo");
          return t("Campaign.Input." + array[index]) === element;
        });
        console.log(keyParam, "keyParam CPFO");

        let tempBodyParams = bodyParam
          ? bodyParam
          : reduxTemplateFailover?.bodyParam;
        console.log(tempBodyParams, "tempBodyParams CPFO");

        let newParam = tempBodyParams?.find((param: any) => {
          return param.paramId === index + 1;
        });
        console.log(newParam, "newParam CPFO");
        let paramNameValue = "" || inputParamName;
        console.log(paramNameValue, "paramname uyoo CPFO");
        if (keyParam && keyParam !== "") {
          console.log("MASUK IF KEYPARAM !==");

          let text = "";
          let type = `${
            keyParam === "CustomizeText" ? "customText" : keyParam
          }`;
          console.log(type, "type CPFO");

          console.log(" PFM CPFO ini type > " + type);
          if (newParam && newParam.paramType && newParam.paramType === type) {
            console.log(
              "PFM CPFO ini masuk ubah text content >>>" + newParam.textContent
            );
            if (newParam.textContent) {
              text = newParam.textContent;
            }
            if (newParam.paramName) {
              console.log(`masuk ambil dari redux paramName PFM CPFO`);
              paramNameValue = newParam.paramName;
            }
          }
          console.log("PFM CPFO ini text > " + text);

          let header = {
            paramId: index + 1,
            paramKey: `${
              keyParam === "CustomizeText"
                ? "customText" + (index + 1)
                : keyParam
            }`,
            paramType: `${
              keyParam === "CustomizeText" ? "customText" : keyParam
            }`,
            paramValue: element,
            textContent: text,
            paramName:
              keyParam === "CustomizeText"
                ? newParam
                  ? paramNameValue
                  : ""
                : "",
            // textContent: `${newParam && newParam.textContent !== "" ? newParam.textContent : ""}`
          };

          // console.log("New param check type  >>" + newParam.paramType + " - " + header.paramType);
          console.log(
            "PFM CPFO New param added object >>> " + JSON.stringify(header)
          );
          arrBodyParam.push(header);
        } else {
          console.log(`masuk else PFM CPFO`);
          console.log(`PFM CPFO element >>> ${element}`);
          console.log(`PFM CPFO newParam >>> ${JSON.stringify(newParam)}`);
          let opsi2 = option;
          console.log(opsi2, "opsi2 CPFO");

          reduxSelectedTemplate?.failover?.bodyParam?.forEach((item: any) => {
            const { paramKey, paramValue, paramName, textContent } = item;
            opsi2 = {
              ...opsi2,
              [paramName !== "" ? paramName : paramKey]: textContent,
            };
          });
          reduxSelectedTemplate?.bodyParam?.forEach((item: any) => {
            const { paramKey, paramValue, paramName, textContent } = item;
            opsi2 = {
              ...opsi2,
              [paramName !== "" ? paramName : paramKey]: textContent,
            };
          });
          let newText = opsi2[element];
          console.log(newText, "newText CPFO");
          if (element !== "") {
            //find if duplicate
            // const findParamByParamName = checkParamName(element)
            // console.log(`findParamByParamName : ${findParamByParamName}`)
            // if(findParamByParamName && findParamByParamName.paramId !== index+1){
            //   dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(true));
            // }else{
            //  dispatch(ReduxTemplate.setAlertDuplicateParamNameInBody(false))
            let text: string =
              newParam && newParam.textContent
                ? newParam.textContent
                : newText
                ? newText
                : "";
            console.log(text, "TEXT ADDPARAMETER CPFO");

            let header = {
              paramId: index + 1,
              paramKey: `customText${index + 1}`,
              paramType: `customText`,
              paramValue: t("Campaign.Input.CustomizeText"),
              textContent: text,
              paramName: element,
            };
            console.log(header, "HEDAER ADDPARAMETER CPFO");

            arrBodyParam.push(header);
            // }
          }
        }
      }
    }
    let dbMessage = newMessage;
    for (let index = 0; index < arrBodyParam.length; index++) {
      const element = arrBodyParam[index];
      dbMessage = dbMessage.replace(
        new RegExp(`{{${element.paramName || element.paramValue}}}`, "g"),
        `{{${index + 1}}}`
      );
    }
    console.log(dbMessage, "telang exa CPFO");
    setFailoverMessage(newMessage);
    setPreviewMessage(
      previewMessage +
        "{{" +
        (e?.label !== "Custom text/URL" &&
        e?.label !== "Teks/URL yang disesuaikan"
          ? e?.label
          : inputParamName) +
        "}}"
    );
    setFailoverBodyParam(arrBodyParam);
    let temp_data = Object.assign({}, reduxCampaignData, {
      failover:
        sendWhenUndelivered === true
          ? {
              message: dbMessage,
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              sendWhenUndelivered: sendWhenUndelivered,
              undeliveredTime: undeliveredTime,
              undeliveredTimeUnit: undeliveredUnit,
            }
          : {
              bodyParam: arrBodyParam,
              bodyParamCount: arrBodyParam.length,
              message: dbMessage,
              sendWhenUndelivered: sendWhenUndelivered,
            },
    });
    console.log(reduxCampaignData, "reduxCampaignData before 770 RCD");
    dispatch(ReduxCampaign.setCampaignData(temp_data));
    console.log(reduxCampaignData, "reduxCampaignData 770 RCD");
    console.log(temp_data, "temp_data CPFO");
    console.log("---KELUAR ADDPARAMETER CPFO---");
  };

  return (
    <>
      <div
        className={clsx("tab-pane fade")}
        id="kt_tab_pane_4"
        role="tabpanel"
        data-testid="tab-pane-templateFailover"
      >
        <div className="d-flex row w-100">
          {/* 1 */}
          <div className="col-15">
            <div className={clsx("mb-5 mt-5")}>
              <div className="p-5 rounded bg-secondary mb-2 col-8">
                <div className="d-flex">
                  <p className="fs-4 fw-bolder">{t("Failover.Title")}</p>
                  <div className="form-check form-switch ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      data-testid="campaign-failover-toggle"
                      id="flexSwitchCheckChecked"
                      checked={failoverToggle}
                      onClick={(e) => {
                        setFailoverToggle(e.currentTarget.checked);
                        if (e.currentTarget.checked === true) {
                          let temp_data = Object.assign({}, reduxCampaignData, {
                            failover:
                              sendWhenUndelivered === true
                                ? {
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                    bodyParam: failoverBodyParam,
                                    bodyParamCount: failoverBodyParam.length,
                                    undeliveredTime: undeliveredTime,
                                    undeliveredTimeUnit: undeliveredUnit,
                                  }
                                : {
                                    bodyParam: failoverBodyParam,
                                    bodyParamCount: failoverBodyParam.length,
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                  },
                          });
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData before 820 RCD"
                          );
                          console.log(temp_data, "temp_data before 820 RCD");
                          dispatch(ReduxCampaign.setCampaignData(temp_data));
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData 820 RCD"
                          );
                        } else {
                          // let temp_data = Object.assign({}, reduxCampaignData, {
                          //   failover: null,
                          // });
                          // dispatch(ReduxCampaign.setCampaignData(temp_data));
                          let temp_data = { ...reduxCampaignData };
                          delete temp_data.failover;
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData before 831 RCD"
                          );
                          console.log(temp_data, "temp_data before 831 RCD");
                          dispatch(ReduxCampaign.setCampaignData(temp_data));
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData 831 RCD"
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="fs-5">{t("Failover.Info")}</p>
                </div>
              </div>
            </div>
            {failoverToggle && (
              <div className={isTabletOrMobile ? "col-12" : "col-8"}>
                <h5 className="fw-bolder">
                  {t("Failover.Message.Title")}
                  <span className="text-danger">*</span>
                </h5>
                <TextAreaFailover
                  templateCategory={templateCategory}
                  onChanges={onChangeMessage}
                  key="message"
                  validateInputHandler={onChangeValidTextStatus}
                  parentValue={previewMessage}
                  // onRefChange={function (
                  //   ref: RefObject<HTMLTextAreaElement>
                  // ): void {
                  //   throw new Error("Function not implemented.");
                  // }}
                ></TextAreaFailover>
                <div className="w-full pt-0 mt-0 d-flex flex-row align-items-end justify-content-end">
                  <button
                    // className="bi bi-plus-lg btn btn-primary m-2"
                    className={clsx(
                      reduxLoading ? "disabled" : "",
                      "bi bi-plus-lg btn btn-primary m-2 align-self-end",
                      isTabletOrMobile ? "fs-8" : ""
                    )}
                    data-bs-toggle="modal"
                    id="addBodyParams"
                    data-bs-target="#addDataModal"
                    data-testid="button-add-data-body-message"
                  >
                    {t("Campaign.Button.AddData")}
                  </button>
                </div>

                {failoverMessage !== "" &&
                  previewMessage !== "" &&
                  failoverBodyParam &&
                  failoverBodyParam.length > 0 && (
                    <div>
                      {failoverBodyParam.map((bodyParam: any, i: any) => {
                        return (
                          <div
                            className="d-flex flex-row m-1"
                            key={`bodyParamDiv_${i + 1}`}
                          >
                            <span className="badge badge-circle fw-bolder fs-7 badge-primary mt-3">
                              {i + 1}
                            </span>
                            <div className="d-flex flex-column w-100 m-3">
                              <input
                                type="text"
                                disabled
                                value={
                                  bodyParam.paramType === "customText" &&
                                  bodyParam.paramName
                                    ? bodyParam.paramName
                                    : bodyParam.paramValue
                                  // bodyParam.paramValue
                                }
                              />
                              {bodyParam.paramType &&
                                bodyParam.paramType === "customText" && (
                                  <div
                                    className={clsx("mt-3 mb-3")}
                                    id={`customParam${i + 1}`}
                                  >
                                    <textarea
                                      className="form-control w-100 mt-3"
                                      placeholder={t(
                                        "Campaign.Input.CustomizeText"
                                      )}
                                      rows={3}
                                      id={`textAreaTemplateBodyParam${i + 1}`}
                                      onClick={(e) => {
                                        console.log(
                                          "PFM ini value dari id: " +
                                            e.currentTarget.id
                                        );
                                        // setCustoParamId(e.currentTarget.id);
                                      }}
                                      onChange={(e) => {
                                        // setParameter({
                                        //   text: e.target.value,
                                        //   paramNo: +e.target.id.charAt(
                                        //     e.target.id.length - 1
                                        //   ),
                                        //   paramName: bodyParam.paramName
                                        //     ? bodyParam.paramName
                                        //     : "",
                                        // });
                                        let updatedFailover = (
                                          reduxCampaignData?.failover as any
                                        )?.bodyParam?.map((param: any) => {
                                          console.log(param, "param lox");
                                          console.log(
                                            param.paramId,
                                            "param paramId lox"
                                          );
                                          // console.log(e.target, "e target lox")
                                          console.log(
                                            +e.target.id.charAt(
                                              e.target.id.length - 1
                                            ),
                                            "e.trget lox"
                                          );
                                          console.log(
                                            param.paramName,
                                            "param paramName lox"
                                          );
                                          console.log(
                                            bodyParam.paramName,
                                            "bodyParam paramName lox"
                                          );
                                          if (
                                            param.paramId ===
                                              +e.target.id.charAt(
                                                e.target.id.length - 1
                                              ) &&
                                            param.paramName ===
                                              bodyParam.paramName
                                          ) {
                                            return {
                                              ...param,
                                              textContent: e.target.value,
                                            };
                                          }
                                          return param;
                                        });
                                        console.log(
                                          reduxCampaignData,
                                          "reduxCampaignData before 998 RCD"
                                        );
                                        let dbMessage = (
                                          reduxCampaignData?.failover as any
                                        )?.message;
                                        for (
                                          let index = 0;
                                          index < updatedFailover?.length;
                                          index++
                                        ) {
                                          const element =
                                            updatedFailover[index];
                                          dbMessage = dbMessage.replace(
                                            new RegExp(
                                              `{{${
                                                element.paramName ||
                                                element.paramValue
                                              }}}`,
                                              "g"
                                            ),
                                            `{{${index + 1}}}`
                                          );
                                        }
                                        const temp_data = Object.assign(
                                          {},
                                          reduxCampaignData,
                                          {
                                            failover: {
                                              ...reduxCampaignData?.failover,
                                              bodyParam: updatedFailover,
                                              message: dbMessage,
                                            },
                                          }
                                        );
                                        console.log(
                                          temp_data,
                                          "temp_data before 998 RCD"
                                        );
                                        dispatch(
                                          ReduxCampaign.setCampaignData(
                                            temp_data
                                          )
                                        );
                                        console.log(
                                          reduxCampaignData,
                                          "reduxCampaignData 998 RCD"
                                        );

                                        // const updatedFailover2 = (
                                        //   reduxSelectedTemplate?.failover as any
                                        // ).bodyParam?.map((param: any) => {
                                        //   if (
                                        //     param.paramId ===
                                        //       +e.target.id.charAt(
                                        //         e.target.id.length - 1
                                        //       ) &&
                                        //     param.paramName ===
                                        //       bodyParam.paramName
                                        //   ) {
                                        //     return {
                                        //       ...param,
                                        //       textContent: e.target.value,
                                        //     };
                                        //   }
                                        //   return param;
                                        // });
                                        // const temp_data2 = Object.assign(
                                        //   {},
                                        //   reduxSelectedTemplate,
                                        //   {
                                        //     failover: {
                                        //       ...reduxSelectedTemplate?.failover,
                                        //       bodyParam: updatedFailover2,
                                        //     },
                                        //   }
                                        // );

                                        // dispatch(
                                        //   ReduxCampaign.setSelectedTemplateLanguage(
                                        //     temp_data2
                                        //   )
                                        // );
                                      }}
                                      // onBlur={({ target }) => target.focus()}
                                      defaultValue={bodyParam.textContent}
                                    ></textarea>
                                    {reduxEmptyCustomTextlert &&
                                      reduxEmptyCustomTextlert.map(
                                        (alert, index) => {
                                          if (index === i && alert === true) {
                                            return (
                                              <div
                                                className="text-danger"
                                                data-testid="errorEmptyCustomtext"
                                              >
                                                {t(
                                                  "CreateTemplate.TemplateContent.Alert.EmptyCustomTextorURL"
                                                )}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                <div
                  className={
                    isTabletOrMobile
                      ? "flex-column mt-13 justify-content-between align-items-center"
                      : "d-flex mt-13 flex-row w-full flex-wrap justify-content-between align-items-center"
                  }
                >
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sendWhenUndelivered}
                      onChange={(e) => {
                        setSendWhenUndelivered(e.currentTarget.checked);
                        if (e.currentTarget.checked === true) {
                          let temp_data = Object.assign({}, reduxCampaignData, {
                            failover: {
                              message: failoverMessage,
                              bodyParam: failoverBodyParam,
                              bodyParamCount: failoverBodyParam.length,
                              sendWhenUndelivered: e.currentTarget.checked,
                              undeliveredTime: undeliveredTime,
                              undeliveredTimeUnit: undeliveredUnit,
                            },
                          });
                          let temp_data2 = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                message: failoverMessage,
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                sendWhenUndelivered: e.currentTarget.checked,
                                undeliveredTime: undeliveredTime,
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          let temp_data3 = Object.assign(
                            {},
                            campaign.selectedTemplateLanguage,
                            {
                              failover: {
                                message: failoverMessage,
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                sendWhenUndelivered: e.currentTarget.checked,
                                undeliveredTime: undeliveredTime,
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          dispatch(
                            ReduxCampaign.setSelectedTemplateLanguage(
                              temp_data3
                            )
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data2)
                          );
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData before 1023 RCD"
                          );
                          console.log(temp_data, "temp_data before 1023 RCD");
                          dispatch(ReduxCampaign.setCampaignData(temp_data));
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData 1023 RCD"
                          );
                        } else {
                          let temp_data = Object.assign({}, reduxCampaignData, {
                            failover: {
                              bodyParam: failoverBodyParam,
                              bodyParamCount: failoverBodyParam.length,
                              message: failoverMessage,
                              sendWhenUndelivered: e.currentTarget.checked,
                            },
                          });
                          let temp_data2 = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                message: failoverMessage,
                                sendWhenUndelivered: e.currentTarget.checked,
                              },
                            }
                          );
                          let temp_data3 = Object.assign(
                            {},
                            campaign.selectedTemplateLanguage,
                            {
                              failover: {
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                message: failoverMessage,
                                sendWhenUndelivered: e.currentTarget.checked,
                              },
                            }
                          );
                          dispatch(
                            ReduxCampaign.setSelectedTemplateLanguage(
                              temp_data3
                            )
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data2)
                          );
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData before 1066 RCD"
                          );
                          console.log(temp_data, "temp_data before 1066 RCD");
                          dispatch(ReduxCampaign.setCampaignData(temp_data));
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData 1066 RCD"
                          );
                        }
                      }}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label fs-5">
                      {t("Failover.TimePeriod.Info")}
                    </label>
                  </div>
                  <div className={isTabletOrMobile ? "pt-3" : ""}>
                    <div className="d-flex flex-row input-group">
                      <input
                        type="number"
                        min={undeliveredUnit === "Minute" ? 5 : 1}
                        max={
                          undeliveredUnit === "Day"
                            ? undefined
                            : undeliveredUnit === "Hour"
                            ? 24
                            : 60
                        }
                        disabled={sendWhenUndelivered === true ? false : true}
                        value={undeliveredTime}
                        onChange={(e) => {
                          let value = Number(e.currentTarget.value);
                          if (isNaN(value) || value === 0) {
                            if (undeliveredUnit === "Minute") {
                              value = 5;
                            } else {
                              value = 1;
                            }
                            if (undeliveredUnit === "Hour" && value >= 24) {
                              value = 24;
                            }
                            if (
                              (undeliveredUnit === "Second" ||
                                undeliveredUnit === "Minute") &&
                              value >= 60
                            ) {
                              value = 60;
                            }
                          }
                          setUndeliveredTime(Number(value));
                          let temp_data = Object.assign({}, reduxCampaignData, {
                            failover: {
                              bodyParam: failoverBodyParam,
                              bodyParamCount: failoverBodyParam.length,
                              message: failoverMessage,
                              sendWhenUndelivered: sendWhenUndelivered,
                              undeliveredTime: Number(value),
                              undeliveredTimeUnit: undeliveredUnit,
                            },
                          });
                          let temp_data2 = Object.assign(
                            {},
                            reduxTemplateLanguage,
                            {
                              failover: {
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                message: failoverMessage,
                                sendWhenUndelivered: sendWhenUndelivered,
                                undeliveredTime: Number(value),
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          let temp_data3 = Object.assign(
                            {},
                            campaign.selectedTemplateLanguage,
                            {
                              failover: {
                                bodyParam: failoverBodyParam,
                                bodyParamCount: failoverBodyParam.length,
                                message: failoverMessage,
                                sendWhenUndelivered: sendWhenUndelivered,
                                undeliveredTime: Number(value),
                                undeliveredTimeUnit: undeliveredUnit,
                              },
                            }
                          );
                          dispatch(
                            ReduxCampaign.setSelectedTemplateLanguage(
                              temp_data3
                            )
                          );
                          dispatch(
                            ReduxTemplate.setGeneratedLanguage(temp_data2)
                          );
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData before 1156 RCD"
                          );
                          console.log(temp_data, "temp_data before 1156 RCD");
                          dispatch(ReduxCampaign.setCampaignData(temp_data));
                          console.log(
                            reduxCampaignData,
                            "reduxCampaignData 1156 RCD"
                          );
                        }}
                        className="form-control"
                        style={{
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          borderBottomRightRadius: "0rem",
                          borderTopRightRadius: "0rem",
                          position: "relative",
                          width: "100px",
                        }}
                      />
                      <div className="input-group-append position-relative d-flex">
                        <div className="position-relative d-inline-block">
                          <select
                            className="form-select border"
                            disabled={
                              sendWhenUndelivered === true ? false : true
                            }
                            value={undeliveredUnit}
                            onChange={(e) => {
                              const selectedUnit = e.currentTarget.value;
                              setUndeliveredUnit(selectedUnit);
                              setUndeliveredTime(
                                selectedUnit === "Minute" ? 5 : 1
                              );
                              let temp_data = Object.assign(
                                {},
                                reduxCampaignData,
                                {
                                  failover: {
                                    bodyParam: failoverBodyParam,
                                    bodyParamCount: failoverBodyParam.length,
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                    undeliveredTime:
                                      selectedUnit === "Minute" ? 5 : 1,
                                    undeliveredTimeUnit: selectedUnit,
                                  },
                                }
                              );
                              let temp_data2 = Object.assign(
                                {},
                                reduxTemplateLanguage,
                                {
                                  failover: {
                                    bodyParam: failoverBodyParam,
                                    bodyParamCount: failoverBodyParam.length,
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                    undeliveredTime:
                                      selectedUnit === "Minute" ? 5 : 1,
                                    undeliveredTimeUnit: selectedUnit,
                                  },
                                }
                              );
                              let temp_data3 = Object.assign(
                                {},
                                campaign.selectedTemplateLanguage,
                                {
                                  failover: {
                                    bodyParam: failoverBodyParam,
                                    bodyParamCount: failoverBodyParam.length,
                                    message: failoverMessage,
                                    sendWhenUndelivered: sendWhenUndelivered,
                                    undeliveredTime:
                                      selectedUnit === "Minute" ? 5 : 1,
                                    undeliveredTimeUnit: selectedUnit,
                                  },
                                }
                              );
                              dispatch(
                                ReduxCampaign.setSelectedTemplateLanguage(
                                  temp_data3
                                )
                              );
                              dispatch(
                                ReduxTemplate.setGeneratedLanguage(temp_data2)
                              );
                              console.log(
                                reduxCampaignData,
                                "reduxCampaignData before 1238 RCD"
                              );
                              console.log(
                                temp_data,
                                "temp_data before 1238 RCD"
                              );
                              dispatch(
                                ReduxCampaign.setCampaignData(temp_data)
                              );
                              console.log(
                                reduxCampaignData,
                                "reduxCampaignData 1238 RCD"
                              );
                            }}
                            style={{
                              borderTopLeftRadius: "0rem",
                              borderTopRightRadius: "0.5rem",
                              borderBottomRightRadius: "0.5rem",
                              borderBottomLeftRadius: "0rem",
                            }}
                          >
                            <option
                              label={t("Failover.TimePeriod.Minute")}
                              value="Minute"
                            />
                            <option
                              label={t("Failover.TimePeriod.Hour")}
                              value="Hour"
                            />
                            <option
                              label={t("Failover.TimePeriod.Day")}
                              value="Day"
                            />
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* start modal  */}
      <div
        className="modal fade"
        id="addDataModal"
        // tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-testid="template-process-addDataModal"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Campaign.Button.AddData")}
              </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                id="close-addData"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span
                  className="svg-icon svg-icon svg-icon-2x"
                  data-bs-dismiss="modal"
                >
                  <i className="bi bi-x-lg fs-2"></i>
                </span>
              </div>
            </div>
            <div className="modal-body" data-testid="modal-body">
              <Select
                data-testid="select-parameter-input"
                name="add-message-param"
                styles={customStyles}
                defaultValue={selectedParameter}
                options={listParameter.length > 0 ? listParameter : []}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={t("Campaign.Input.AddData") + "..."}
                onChange={(e) => {
                  const selectedParameterData: any = e;
                  setSelectedParameter(selectedParameterData);
                }}
              />
              {selectedParameter &&
                selectedParameter.item === "Customize text/URL" && (
                  <div>
                    <div className="mt-5 w-100">
                      {t("Campaign.IM.Body.Modal.CustomText.TextFieldName")}
                      <span className="text-danger">*</span>
                    </div>
                    <div className="flex-grow-1 d-flex flex-row border border-secondary rounded">
                      <input
                        className="form-control form-control-solid border-0 bg-white"
                        type="text"
                        placeholder={t("Campaign.IM.Body.Modal.CustomText")}
                        value={inputParamName}
                        onChange={(e) => {
                          const paramName: string = e.target.value;
                          setInputParamName(paramName);
                        }}
                        maxLength={30}
                      />
                      <button
                        className="border-0 align-middle bg-transparent"
                        style={{ color: "gray" }}
                      >
                        {inputParamName.length}/30
                      </button>
                    </div>
                    {alertEmptyParamName && (
                      <span id="empty-paramName" className="text-danger">
                        {t(
                          "Campaign.TemplateCategory.IM.Body.CustomTextModal.ErrorAlert"
                        )}
                      </span>
                    )}
                    {duplicateParamName && (
                      <span id="duplicate-paramName" className="text-danger">
                        {t(
                          "Campaign.TemplateCategory.IM.Body.ExistFieldName.ErrorAlert"
                        )}
                      </span>
                    )}
                  </div>
                )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={clsx(
                  "btn btn-primary",
                  reduxLoading === true ? "disabled" : ""
                )}
                onClick={() => {
                  addParameter();
                  setTimeout(() => {
                    myRef.current?.setSelectionRange(-1, -1);
                    myRef.current?.focus();
                  }, 500);
                }}
                data-bs-dismiss={
                  selectedParameter &&
                  selectedParameter.item === "Customize text/URL" &&
                  (inputParamName === "" ||
                    (inputParamName !== "" && duplicateParamName))
                    ? "#"
                    : "modal"
                }
                disabled={!selectedParameter}
                data-testid="button-modal-submit"
              >
                {t("Campaign.Button.AddData")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end modal  */}
    </>
  );
};

export default CampaignFailover;
