import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AutosizeInput from "react-input-autosize";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useParams } from "react-router-dom";
import { requestUpdateMarketplaceStorefront } from "../../../../../../api/server/storefront";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import { getAccessibleAccount } from "../../../../../../db/serviceAccount";
import * as ServStorefront from "../../../../../../db/serviceStorefront";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import { ListMarketplace } from "../../../../../../util/Marketplace";
import { productStep } from "../../../../../models/Product";
import Storefront, {
  MarketplaceCategoryList,
  storefrontStatus,
  storefrontStep,
} from "../../../../../models/Storefront";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import MarketplaceCategoryModalDesktop from "../../../../../modules/modal/storefront-category/MarketplaceModalDesktop";
import MarketplaceCategoryModalMobile from "../../../../../modules/modal/storefront-category/MarketplaceModalMobile";
import * as ReduxProduct from "../../../../../modules/product/redux/ProductSlice";
import * as ReduxStorefront from "../../../../../modules/product/storefront/StorefrontSlice";
import * as StorefrontList from "../../../../../modules/product/storefront/storefrontList/StorefrontListSlice";
import { PageLink, PageTitle } from "../../../../core";
import StorefrontMobileIcon from "./StorefrontMobileIcon";

function convertArray(array: any) {
  return [Object.values(array[0])];
}

const StorefrontSummary = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const { id } = useParams();
  const listMarketplace: any = ListMarketplace;
  const usersBreadcrumbsCreate: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Name.Storefront"),
      path: "/storefront/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Info.BreadCrumb.AddStorefront"),
      path: "/storefront/create-storefront/storefront-name",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Info.BreadCrumb.SelectMarketplace"),
      path: "/storefront/create-storefront/select-marketplace",
      isSeparator: false,
      isActive: true,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const usersBreadcrumbsEdit: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Name.Storefront"),
      path: "/storefront/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const dispatch = useDispatch();
  const reduxStorefrontName = useSelector(
    (state: RootState) => state.Storefront.storefrontName
  );

  const reduxStorefrontId = useSelector(
    (state: RootState) => state.Storefront.storefrontID
  );

  const storefrontList = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );

  const reduxStorefrontStatus = useSelector(
    (state: RootState) => state.Storefront.status
  );

  const reduxStorefrontAndSelectedMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  const isAddStorefrontFromProduct = useSelector(
    (state: RootState) => state.Product.isAddStorefront
  );

  const productLastStep = useSelector(
    (state: RootState) => state.Product.lastStep
  );

  const createModelStorefront = (
    user: string,
    storefrontName: string,
    status: string,
    lastStep: string
  ) => {
    const client = lc.getItemLC(lc.LCName.Client);
    const clientRef = createRef("clients", client.id);
    const productCategoriesData: Storefront = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      status: status,
      lastStep: status === storefrontStatus.drafted ? lastStep : "",
      client: clientRef,
    };
    return productCategoriesData;
  };

  const createModelStorefrontForUpdate = (
    user: string,
    storefrontName: string,
    status: string,
    lastStep: string
  ) => {
    const storefrontData: Storefront = {
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      updatedBy: createRef("users", user),
      status: status,
      lastStep: status === storefrontStatus.drafted ? lastStep : "",
    };
    return storefrontData;
  };

  const createModelMarketplaceCategory = (
    user: string,
    categoriesData: any,
    type: string,
    isChecked: boolean
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      type: type,
      isChecked: isChecked,
    };
    return marketplaceCategoriesData;
  };

  const createModelMarketplaceCategoryForUpdate = (
    user: string,
    categoriesData: any,
    isChecked: boolean,
    type: string
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      updatedAt: Timestamp.now(),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      isChecked,
      type: type,
    };
    return marketplaceCategoriesData;
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const nav = useNavigate();
  let resultid: any;
  let modelStorefront: any;
  const submitHandler = async () => {
    if (
      reduxStorefrontName === "" ||
      reduxStorefrontName.trim() === "" ||
      reduxStorefrontName.length <= 0
    ) {
      setIsValidTitle(false);
      return;
    }
    const client = lc.getItemLC(lc.LCName.Client);
    //check if name is exist
    let title = reduxStorefrontName.trim();
    let getCategories: any = [];
    let getStorefrontByName = await ServStorefront.getStorefrontByName(
      title,
      client.id
    ).then(async (productCategories: any) => {
      if (productCategories) {
        return productCategories;
      }
    });
    let sameId = false;
    if (reduxStorefrontId) {
      sameId = getStorefrontByName[0]?.id === reduxStorefrontId;
    }
    if (getStorefrontByName && !sameId) {
      getCategories = [...getStorefrontByName];
    }
    if (getCategories.length > 0) {
      setIsTitleHasExist(true);
      return;
    }
    //check selected marketplace
    console.log(reduxStorefrontAndSelectedMarketplace);
    let validCategories: any;

    reduxStorefrontAndSelectedMarketplace.forEach((m: any) => {
      // get checked value state & length of categories
      let isCheckValid = m.isChecked;
      let isCategoriesValid = m.category.length > 0;
      console.log(
        "isvalid " + isCheckValid + ", category valid " + isCategoriesValid
      );
      if (isCheckValid && isCategoriesValid) {
        validCategories = {
          ...validCategories,
          [m.name]: true,
        };
      } else if (isCheckValid && !isCategoriesValid) {
        validCategories = {
          ...validCategories,
          [m.name]: false,
        };
      } else if (!isCheckValid && isCategoriesValid) {
        setIsSubmitted(true);
      }
    });

    if (validCategories !== undefined) {
      const isAllValid = Object.values(validCategories).every(
        (value) => value === true
      );
      if (!isAllValid) {
        // if marketplace is checked, but not selecting a category yet.
        const marketplaceNotValid = Object.keys(validCategories).filter(
          function (key) {
            return validCategories[key] === false;
          }
        )[0];
        console.log(`storefront marketplace not valid ${marketplaceNotValid}`);
        return;
      }
      //
      console.log("all valid");
      let user = lc.getItemLC("UID");
      if (!reduxStorefrontId) {
        modelStorefront = createModelStorefront(
          user,
          reduxStorefrontName.trim(),
          storefrontStatus.created,
          storefrontStep.onSummary
        );
        resultid = await ServStorefront.createStorefront(modelStorefront)
          .then(async (id) => {
            console.log(
              `success create product categories ${reduxStorefrontName}}`
            );
            console.log("id", id);
            if (id) {
              //looping for selected marketplace
              for (
                let index = 0;
                index < reduxStorefrontAndSelectedMarketplace.length;
                index++
              ) {
                let data: any = undefined;
                let market: any = reduxStorefrontAndSelectedMarketplace[index];
                let type = market.name.toLowerCase();
                let isChecked = market.isChecked;
                let selectedCategory = market?.category;
                for (let i = 0; i < selectedCategory.length; i++) {
                  let arrayCategories = selectedCategory[i];
                  data = { ...arrayCategories };
                }
                let arrayData = [];
                arrayData.push({ ...data });
                let marketplaceData = createModelMarketplaceCategory(
                  user,
                  arrayData,
                  type,
                  isChecked
                );
                if (marketplaceData && data !== undefined) {
                  //create sub collection
                  await ServStorefront.createSelectedMarketplaceProductCategory(
                    id,
                    marketplaceData
                  )
                    .then(() => {
                      console.log(
                        `success create subcollection product categories ${reduxStorefrontName}`
                      );
                    })
                    .catch((error) => {
                      console.log("error add subcolection " + error);
                    });
                }
              }
              return id;
            } else {
              return "";
            }
          })
          .catch((error) => {
            console.log(error);
            return "";
          });
      } else {
        console.log("STL Perform update");
        const newStatus =
          reduxStorefrontStatus === storefrontStatus.drafted ||
          reduxStorefrontStatus === storefrontStatus.created
            ? storefrontStatus.created
            : storefrontStatus.requested;
        modelStorefront = createModelStorefrontForUpdate(
          user,
          reduxStorefrontName.trim(),
          newStatus,
          ""
        );
        await ServStorefront.updateStorefront(
          reduxStorefrontId,
          modelStorefront
        ).then(async () => {
          for (
            let index = 0;
            index < reduxStorefrontAndSelectedMarketplace.length;
            index++
          ) {
            let data: any = undefined;
            let market: any = reduxStorefrontAndSelectedMarketplace[index];
            let type = market.name.toLowerCase();
            let isChecked = market.isChecked;
            let selectedCategory = market?.category;
            for (let i = 0; i < selectedCategory.length; i++) {
              let arrayCategories = selectedCategory[i];
              data = { ...arrayCategories };
            }
            let arrayData = [];
            arrayData.push({ ...data });
            let marketplaceData = createModelMarketplaceCategoryForUpdate(
              user,
              arrayData,
              isChecked,
              type
            );

            if (marketplaceData) {
              const user = lc.getItemLC(lc.LCName.User);
              const client = lc.getItemLC(lc.LCName.Client);
              const companyAccess = lc.getItemLC(lc.LCName.CompanyList)
              const accountList = await getAccessibleAccount(client.id, companyAccess, type);

              console.log("STL marketplace data", marketplaceData);
              //get subcollection by type == Shopee
              const getSubCollectionId =
                await ServStorefront.getStorefrontMarketplaceCategoryById(
                  reduxStorefrontId
                );
              console.log("getSubCollectionId : ", getSubCollectionId);

              if (user && accountList && accountList.length > 0) {
                accountList.forEach(async (account) => {
                  const selectedMarket = getSubCollectionId.find(
                    (marketplace: any) => {
                      return (
                        marketplace.type === type &&
                        marketplace.account.id === account.id
                      );
                    }
                  );
                  await ServStorefront.updateSelectedMarketplaceProductCategory(
                    reduxStorefrontId,
                    selectedMarket.id,
                    marketplaceData
                  );
                  if (
                    type === "shopee" &&
                    selectedMarket.marketplaceShopCategoryId
                  ) {
                    await requestUpdateMarketplaceStorefront(
                      account.id,
                      user.uid,
                      reduxStorefrontId
                    );
                  }
                });
              }
            }
          }
        });
      }
      setIsCreated(true);
      dispatch(StorefrontList.cleanReduxStorefrontList(true));

      if (isAddStorefrontFromProduct) {
        //Add selected Storefont on Product
        if (resultid && resultid !== "") {
          const selectedStorefont = {
            label: modelStorefront.name,
            value: resultid,
          };
          dispatch(ReduxProduct.setSelectedStoreFrontData(selectedStorefont));
        }
        dispatch(ReduxStorefront.cleanReduxStorefront(true));
        if (productLastStep === productStep.onSummary) {
          console.log();
          nav("/product/create-product/summary");
        } else {
          nav("/product/create-product/basic-information");
        }
      } else {
        dispatch(ReduxStorefront.cleanReduxStorefront(true));
        nav("/storefront/");
      }
    }
  };

  const [isValidStorefrontName, setIsValidTitle] = useState<boolean>(true);
  const [isStorefrontNameHasExist, setIsTitleHasExist] =
    useState<boolean>(false);

  const onChangeStorefrontName = async (event: any) => {
    setIsValidTitle(true);
    setIsTitleHasExist(false);
    let storefrontNameValue = event.target.value;
    if (storefrontNameValue === "" || storefrontNameValue.trim() === "") {
      setIsValidTitle(false);
    }
    dispatch(ReduxStorefront.setStorefrontName(storefrontNameValue));
    dispatch(
      ReduxStorefront.setStorefrontNameLength(storefrontNameValue.length)
    );
  };

  const toggleIsCheck = (marketplace: any) => {
    let res = { ...marketplace, isChecked: !marketplace.isChecked };
    return res;
  };

  const handleOnChange = (marketplace: string) => {
    console.log("check or uncheck marketplace : " + marketplace);
    console.log("SUM, Handle Current Marketplace", marketplace);
    const isCurrentMarketplaceInList =
      reduxStorefrontAndSelectedMarketplace.find(
        (m: any) => m.name === marketplace
      );
    // add to redux if marketplace is not in the list & set checked state to: true
    if (!isCurrentMarketplaceInList) {
      const data = { name: marketplace, isChecked: true, category: [] };
      dispatch(
        ReduxStorefront.setStorefrontListMarketplace([
          ...reduxStorefrontAndSelectedMarketplace,
          data,
        ])
      );
    } else {
      let findMarketplace = reduxStorefrontAndSelectedMarketplace.find(
        (m: any) => m.name === marketplace
      );
      // if (findMarketplace){
      const data = toggleIsCheck(findMarketplace);
      // }
      const currentListMarketplace: any = [
        ...reduxStorefrontAndSelectedMarketplace,
      ];
      const categoryIndexToReplace = currentListMarketplace.findIndex(
        (m: any) => m.name === marketplace
      );
      if (categoryIndexToReplace !== -1) {
        currentListMarketplace[categoryIndexToReplace] = data;
      } else {
        currentListMarketplace.push(data);
      }
      dispatch(
        ReduxStorefront.setStorefrontListMarketplace(currentListMarketplace)
      );
    }
  };

  const handleSavedChanges = async () => {
    if (
      reduxStorefrontName === "" ||
      reduxStorefrontName.trim() === "" ||
      reduxStorefrontName.length <= 0
    ) {
      setIsValidTitle(false);
      return;
    }
    const client = lc.getItemLC(lc.LCName.Client);
    //check if name is exist
    let title = reduxStorefrontName.trim();
    let getCategories: any = [];
    let getStorefront = await ServStorefront.getStorefrontByName(
      title,
      client.id
    ).then(async (productCategories: any) => {
      if (productCategories) {
        return productCategories;
      }
    });
    let sameId = false;
    if (reduxStorefrontId) {
      sameId = getStorefront[0]?.id === reduxStorefrontId;
    }
    if (getStorefront && !sameId) {
      getCategories = [...getStorefront];
    }
    if (getCategories.length > 0) {
      setIsTitleHasExist(true);
      return;
    }
    let user = lc.getItemLC("UID");
    let modelStorefront: any;
    if (!reduxStorefrontId) {
      modelStorefront = createModelStorefront(
        user,
        reduxStorefrontName.trim(),
        storefrontStatus.drafted,
        storefrontStep.onSummary
      );
      await ServStorefront.createStorefront(modelStorefront)
        .then(async (id: any) => {
          console.log(
            `success create product categories ${reduxStorefrontName}}`
          );
          console.log("id", id);
          if (id) {
            //looping for selected marketplace
            for (
              let index = 0;
              index < reduxStorefrontAndSelectedMarketplace.length;
              index++
            ) {
              let data: any = undefined;
              let market: any = reduxStorefrontAndSelectedMarketplace[index];
              let type = market.name;
              let isChecked = market.isChecked;
              let selectedCategory = market?.category;
              for (let i = 0; i < selectedCategory.length; i++) {
                let arrayCategories = selectedCategory[i];
                data = { ...arrayCategories };
              }
              let arrayData = [];
              arrayData.push({ ...data });
              let marketplaceData = createModelMarketplaceCategory(
                user,
                arrayData,
                type,
                isChecked
              );
              if (marketplaceData) {
                //create sub collection
                await ServStorefront.createSelectedMarketplaceProductCategory(
                  id,
                  marketplaceData
                )
                  .then(() => {
                    console.log(
                      `success create subcollection product categories ${reduxStorefrontName}`
                    );
                  })
                  .catch((error) => {
                    console.log("error add subcolection " + error);
                  });
              }
            }
          }
          setIsCreated(true);
          dispatch(ReduxStorefront.cleanReduxStorefront(true));
          if (isAddStorefrontFromProduct) {
            if (productLastStep === productStep.onSummary) {
              console.log();
              nav("/product/create-product/summary");
            } else {
              nav("/product/create-product/basic-information");
            }
          } else {
            nav("/storefront/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      modelStorefront = createModelStorefrontForUpdate(
        user,
        reduxStorefrontName.trim(),
        storefrontStatus.drafted,
        storefrontStep.onSummary
      );
      await ServStorefront.updateStorefront(reduxStorefrontId, modelStorefront)
        .then(async () => {
          for (
            let index = 0;
            index < reduxStorefrontAndSelectedMarketplace.length;
            index++
          ) {
            let data: any = undefined;
            let market: any = reduxStorefrontAndSelectedMarketplace[index];
            let type = market.name;
            let isChecked = market.isChecked;
            let selectedCategory = market?.category;
            for (let i = 0; i < selectedCategory.length; i++) {
              let arrayCategories = selectedCategory[i];
              data = { ...arrayCategories };
            }
            let arrayData = [];
            arrayData.push({ ...data });
            let marketplaceData = createModelMarketplaceCategoryForUpdate(
              user,
              arrayData,
              isChecked,
              type
            );
            if (marketplaceData) {
              //get subcollection by type == Shopee
              const getSubCollectionId =
                await ServStorefront.getStorefrontMarketplaceCategoryById(
                  reduxStorefrontId
                );
              console.log("getSubCollectionId : ", getSubCollectionId);
              const findByType = getSubCollectionId.find((marketplace: any) => {
                return marketplace.type === type;
              });
              await ServStorefront.updateSelectedMarketplaceProductCategory(
                reduxStorefrontId,
                findByType.id,
                marketplaceData
              )
                .then(() => {
                  console.log(
                    `success create subcollection product categories ${reduxStorefrontName}`
                  );
                })
                .catch((error) => {
                  console.log("error add subcolection " + error);
                });
            }
          }
          setIsCreated(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxStorefront.cleanReduxStorefront(true));
  };

  // If Edit Page or Has Id
  useEffect(() => {
    if (id || reduxStorefrontId) {
      const currentStorefront: any = storefrontList.find(
        (storefront) =>
          storefront.id === id || storefront.id === reduxStorefrontId
      );
      let data = currentStorefront?.marketplaceCategory?.map(
        (marketplace: any) => {
          let objectMarketplace = {
            name: marketplace.type,
            isChecked: marketplace.isChecked,
            category:
              marketplace.categories && marketplace.categories.length > 0
                ? convertArray(marketplace.categories)
                : [],
          };
          // data.push(objectMarketplace);
          return objectMarketplace;
        }
      );
      console.log("currentStorefront?.name ", currentStorefront?.name);
      dispatch(ReduxStorefront.setStorefrontName(currentStorefront?.name));
      dispatch(ReduxStorefront.setLastStep(currentStorefront?.lastStep));
      if (
        currentStorefront?.lastStep === storefrontStep.onSummary ||
        currentStorefront?.lastStep === ""
      ) {
        dispatch(ReduxStorefront.setStorefrontListMarketplace(data));
      }
      dispatch(ReduxStorefront.setStatus(currentStorefront?.status));
    } else {
      if (!id && !isAddStorefrontFromProduct) {
        if (!reduxStorefrontName || reduxStorefrontName === "") {
          dispatch(ReduxStorefront.cleanReduxStorefront(true));
          nav("/storefront/create-storefront/storefront-name");
          return;
        } else if (
          reduxStorefrontName &&
          !reduxStorefrontAndSelectedMarketplace
        ) {
          nav("/storefront/create-storefront/select-marketplace");
          return;
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isCreated, setIsCreated] = useState(false);

  console.log("reduxStorefrontStatus", reduxStorefrontStatus);

  return (
    <>
      {(!id || (id && reduxStorefrontStatus === storefrontStatus.drafted)) && (
        <PageTitle breadcrumbs={usersBreadcrumbsCreate}>
          {t("Storefront.Info.BreadCrumb.Summary")}
        </PageTitle>
      )}

      {id && reduxStorefrontStatus !== storefrontStatus.drafted && (
        <PageTitle breadcrumbs={usersBreadcrumbsEdit}>
          {t("Edit Etalase")}
        </PageTitle>
      )}

      {!isCreated &&
        (!id || (id && reduxStorefrontStatus === storefrontStatus.drafted)) && (
          <ModalConfirmationMoveToOtherPage
            isDirty={reduxStorefrontName?.length > 0}
            path="/storefront/create-storefront"
            onSave={() => handleSavedChanges()}
            onUnsave={() => handleUnsavedChanges()}
          />
        )}
      {!isCreated &&
        id &&
        reduxStorefrontStatus !== storefrontStatus.drafted && (
          <ModalConfirmationMoveToOtherPage
            isDirty={false}
            path="/storefront/create-storefront"
            title={`${t("Storefront.Alert.LeavePage.Title")}`}
            body={`${t("Storefront.Alert.LeavePage.Info")}`}
            unSaveText={`${t("Alert.Button.LeavePage")}`}
            cancelText={t("Alert.Button.Cancel")}
            onUnsave={() => handleUnsavedChanges()}
          />
        )}

      <div className="d-flex flex-column flex-lg-column h-100">
        <div className="overflow-hidden card mb-1 mb-xl-2 h-100">
          {isTabletOrMobile && <StorefrontMobileIcon />}
          <div
            style={{ height: "90%" }}
            className={clsx(
              `d-flex flex-column ${
                isTabletOrMobile ? "p-10" : "px-7 pt-7 pb-0"
              }`
            )}
          >
            <div className="d-flex flex-column w-100">
              {(!id ||
                (id && reduxStorefrontStatus === storefrontStatus.drafted)) && (
                <h1>{`${t("Storefront.Name.Summary")}`}</h1>
              )}
              <div
                className="h5 text-gray-500 mt-5"
                style={{
                  borderInlineColor: `${isValidStorefrontName ? "red" : ""}`,
                }}
              >
                {`${t("Storefront.Info.StorefrontName")}`}
              </div>
              <div className="d-flex p-0">
                <div className="w-50 d-flex align-items-center form-control px-0 py-0">
                  <input
                    data-testid="storefrontInput"
                    id="categoryTitle"
                    name="categoryTitle"
                    maxLength={40}
                    className="form-control border-0"
                    onChange={onChangeStorefrontName}
                    value={reduxStorefrontName}
                  />
                  <p
                    className="border-0 bg-white my-0"
                    style={{
                      paddingRight: "10px",
                      color: "gray",
                      textAlign: "center",
                    }}
                  >
                    {reduxStorefrontName.length}/40
                  </p>
                </div>
              </div>
              <div className="d-flex">
                {!isValidStorefrontName && (
                  <div style={{ color: "red" }}>
                    {`${t("Storefront.Alert.StorefrontName")}`}
                  </div>
                )}
                {isStorefrontNameHasExist && (
                  <span style={{ color: "red" }}>{`${t(
                    "Storefront.Alert.Input.StorefrontName"
                  )}`}</span>
                )}
              </div>
              {!isTabletOrMobile && (
                <div className="d-flex flew-column border-gray-300 border-bottom border-bottom-dashed">
                  <div className="d-flex flex-row w-100 py-2">
                    <div className="w-25 text-muted text-center fs-2">
                      {t("Storefront.Column.Summary.MarketPlace")}
                    </div>
                    <div className="w-50 text-muted text-center fs-2">
                      {t("Storefront.Column.Summary.MarketplaceCategory")}
                    </div>
                  </div>
                </div>
              )}
              <div
                className="overflow-scroll "
                style={{ maxHeight: `${isTabletOrMobile ? "45vh" : "29vh"}` }}
              >
                <div
                  className="d-flex flex-column"
                  style={{ overflowX: "hidden" }}
                >
                  {listMarketplace?.map(({ name, isDisabled }: any) => {
                    console.log("marketplace check name : " + name);
                    return (
                      <div
                        className={clsx(
                          `d-flex ${
                            isTabletOrMobile ? "flex-column" : "flex-row"
                          } w-100 p-2 border-gray-300 border-bottom border-bottom-dashed`
                        )}
                        key={name}
                      >
                        <div
                          className={clsx(
                            `d-flex flex-row align-items-center gap-2 cursor-pointer  ${
                              isTabletOrMobile ? "w-100" : "w-25 ms-10"
                            }`
                          )}
                        >
                          <input
                            data-testid={"checkbox-" + name?.toLowerCase()}
                            type="checkbox"
                            key={name}
                            checked={
                              (reduxStorefrontAndSelectedMarketplace &&
                                reduxStorefrontAndSelectedMarketplace.find(
                                  (market: any) => {
                                    if (
                                      market.name?.toLowerCase() ===
                                      name?.toLowerCase()
                                    )
                                      return market.isChecked;
                                    return false;
                                  }
                                )) ||
                              false
                            }
                            disabled={isDisabled}
                            onChange={() => handleOnChange(name?.toLowerCase())}
                          />
                          <img
                            className="h-20px"
                            src={toAbsoluteUrl(
                              `/media/logos/marketplace/${name.toLowerCase()}.png`
                            )}
                            alt={name + ".png"}
                          />
                          <span className="fs-3 fw-bold d-block">{name}</span>
                        </div>
                        <div
                          className={clsx(
                            `d-flex flex-column ${
                              isTabletOrMobile ? "w-100" : "w-50"
                            }`
                          )}
                        >
                          {!isTabletOrMobile && (
                            <MarketplaceCategoryModalDesktop
                              title={name?.toLowerCase()}
                              step={isAddStorefrontFromProduct ? "" : "summary"}
                              isSubmitted={isSubmitted}
                              id={name?.toLowerCase()}
                            />
                          )}
                          {isTabletOrMobile && (
                            <MarketplaceCategoryModalMobile
                              title={name?.toLowerCase()}
                              step={isAddStorefrontFromProduct ? "" : "summary"}
                              isSubmitted={isSubmitted}
                              id={name?.toLowerCase()}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              !isAddStorefrontFromProduct
                ? `d-flex space justify-content-between w-100 ${
                    isTabletOrMobile ? "mb-5" : "pt-0 px-7 pb-0"
                  }`
                : `d-flex bg-white justify-content-end ${
                    isTabletOrMobile ? "mb-5" : "py-5"
                  }`
            )}
          >
            {!isAddStorefrontFromProduct &&
            (!id ||
              (id && reduxStorefrontStatus === storefrontStatus.drafted)) ? (
              <Link to="/storefront/create-storefront/select-marketplace">
                <Button btnbs="primary" cName="mx-5">{`${t(
                  "Storefront.Button.Back"
                )}`}</Button>
              </Link>
            ) : (
              <div />
            )}
            <div className="d-flex">
              <Link
                to={
                  !isAddStorefrontFromProduct
                    ? "/storefront/list"
                    : productLastStep === productStep.onSummary
                    ? "/product/create-product/summary"
                    : "/product/create-product/basic-information"
                }
              >
                <Button data-testid="btnCancel" btnbs="primary" type="button">
                  {t("Alert.Button.Cancel")}
                </Button>
              </Link>
              <Button
                data-testid="btnCreate"
                btnbs="primary"
                cName="mx-5"
                onClick={() => {
                  submitHandler();
                }}
              >
                {id && reduxStorefrontStatus !== storefrontStatus.drafted
                  ? t("Storefront.Button.Update")
                  : t("Storefront.Button.Create")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StorefrontSummary;
