import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KTSVG, useDebounce } from "../../../../../../resources/helpers";
import { OrderStatus7Days } from "../../../../../models/Order";
import { isSameStatusValue } from "../../OrderPage";
import { useFirestoreTable } from "../context/TableContext";
import { Link } from "react-router-dom";
import SyncModal from "./modal/SyncModal";
import SyncResultModal from "./modal/SyncResultModal";
import * as lc from "../../../../../../app/modules/localstorage";
import { createRef } from "../../../../../../db";
import * as userServ from "../../../../../../db/serviceUser";
import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import {
  setIsModalOpen,
  setResponseExportOrder,
} from "../../../../../components/modal/ModalExportOrder/redux/ModalExportOrderSlice";

const statusWithAction: string[] = [OrderStatus7Days.NewOrder];
const statusProcessing: string[] = [OrderStatus7Days.Processing];

export default function TableHeader() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    refreshData,
    selectedItems,
    tableState,
    updateTableState,
    setReadyToShipOrder,
    setExportedOrder,
    responseExportOrder,
  } = useFirestoreTable();

  const [searchTerm, setSearchTerm] = useState(tableState.searchKey?.value || "");
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  console.log(debouncedSearchTerm, "INII DEBOUNCEDSEARCHTERM");

  const activeOrderStatus = tableState.filters?.find(
    (filter) => filter.field === "exportStatus"
  )?.value;

  console.log("activeOrderStatus", { activeOrderStatus, statusWithAction });
  const isOrderStatusNew = isSameStatusValue(activeOrderStatus, statusWithAction);
  const isOrderStatusProcessing = isSameStatusValue(activeOrderStatus, statusProcessing);
  console.log("isOrderStatusNew", isOrderStatusNew);
  // const isOrderStatusNew = activeOrderStatus?.every(value => {
  //   return activeOrderStatus?.includes(value);
  // });
  useEffect(() => {
    updateTableState({
      searchKey: {
        field: "ordersn",
        value: debouncedSearchTerm,
        caseSensitive: true,
      },
    });
  }, [debouncedSearchTerm]);

  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => setModalOpen(false);

  const [modalSyncresOpen, setModalSyncresOpen] = useState(false);
  const modalSyncresClose = () => {
    setModalSyncresOpen(false);
    lc.removeLC("isSync");
    lc.removeLC("isSyncInProgress");
    lc.removeLC("syncDate");
  };
  const [syncResult, setSyncResult] = useState<any>();
  const [isSync, setIsSync] = useState<boolean>(
    lc.getItemLC("isSync") === "false" || false
    // false
  );
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const syncing = (state: boolean) => {
    setIsSync(state);
    lc.setItemLC("isSync", JSON.stringify(state));
    setIsLoadingSync(state);
    const storedSyncDate = lc.getItemLC("syncDate");
    if (storedSyncDate) {
      try {
        const parsedSyncDate = storedSyncDate as Array<string>;
        const reformattedSyncDate = parsedSyncDate?.map((date: string) => {
          return date.replace(/\//g, "/");
        });
        console.log("Data from SyncDate state di useeffect:", reformattedSyncDate);
        // Set syncDate to the retrieved value
        setSyncDate(reformattedSyncDate);
      } catch (error) {
        console.error("Error parsing or processing storedSyncDate:", error);
      }
    }
  };

  const [syncDate, setSyncDate] = useState<Array<string>>([]);

  const openSyncModal = (syncRes: any, syncDate: Array<string>) => {
    setSyncResult(syncRes);
    const order: Array<any> = [];
    var totalOrdersAffc: number = 0;
    syncRes?.response?.map((resp: any) => {
      const objData = {
        account: createRef("account", resp.account),
        error: resp.error,
        totalSyncOrder: resp.totalNewOrder,
        totalUpdatedOrder: resp.totalUpdatedOrder,
      };
      order.push(objData);
      totalOrdersAffc += resp.totalNewOrder + resp.totalUpdatedOrder;
    });

    const toSendToNotif = {
      startTime: syncDate[0],
      endTime: syncDate[1],
      orders: order,
    };

    const reformattedProp: Array<string> = syncDate.map((date: string) => {
      return date.replace(/\//g, "/");
    });
    console.log("Data to be set to SyncDate State from SyncModal:", reformattedProp);
    setSyncDate(reformattedProp);

    userServ.insertSyncResultNotification(lc.getItemLC(lc.LCName.UserID), toSendToNotif);

    lc.setItemLC("syncResult", JSON.stringify(syncRes));
  };


  useEffect(() => {
    // Retrieve syncDate from local storage
    const storedSyncDate = lc.getItemLC("syncDate");
    if (storedSyncDate) {
      try {
        const parsedSyncDate = storedSyncDate as Array<string>;
        const reformattedSyncDate = parsedSyncDate?.map((date: string) => {
          return date.replace(/\//g, "/");
        });
        console.log("Data from SyncDate state di useeffect:", reformattedSyncDate);
        // Set syncDate to the retrieved value
        setSyncDate(reformattedSyncDate);
      } catch (error) {
        console.error("Error parsing or processing storedSyncDate:", error);
      }
    }

    // Retrieve syncResult from local storage
    const storedSyncResult = lc.getItemLC("syncResult");
    if (storedSyncResult) {
      const parsedSyncResult = JSON.parse(storedSyncResult);
      console.log("Data from SyncResult state:", parsedSyncResult);
      // Set syncResult to the retrieved value
      setSyncResult(parsedSyncResult);
      setIsLoadingSync(false);
    }

    // Check if sync is in progress and open the modal
    const isSyncInProgress = lc.getItemLC("isSyncInProgress") === "true";
    if (isSyncInProgress) {
      setModalSyncresOpen(true);
    }
  }, []);

  console.log("Data from SyncDate state di table header:", syncDate);
  console.log("Data from SyncResult state di table header:", syncResult);

  const buttonExportOrderHandler = async () => {
    dispatch(setIsModalOpen(true));
    setExportedOrder(selectedItems);
  };

  useEffect(() => {
    if (responseExportOrder) {
      console.log("responseExportOrder", { responseExportOrder });
      dispatch(setResponseExportOrder(responseExportOrder));
    }
  }, [responseExportOrder, dispatch]);
  return (
    <div className="row align-items-center pb-2 justify-content-between">
      <div className={`col-12 col-lg-4 d-flex align-items-center position-relative`}>
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className={clsx("form-control form-control-solid ps-14", {
            "w-100": false,
          })}
          placeholder={t("OrderList.Search.Placeholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-testid="search-order"
        />
      </div>

      {(isOrderStatusNew || isOrderStatusProcessing) && (
        <div className="col-12 col-lg-2 py-2">
          <button disabled type="button" className="btn btn-light-dark w-100">
            {`${selectedItems.length} ${t("OrderList.Info.Selected")}`}
          </button>
        </div>
      )}

      <div className="col-1 py-2 d-none d-lg-block">
        <button type="button" className="btn btn-light-primary w-100" onClick={() => refreshData()}>
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      </div>

      {!isSync ? (
        <div className="col-12 col-lg-3 py-2">
          <button
            type="button"
            className="btn btn-light-primary w-100"
            onClick={() => setModalOpen(true)}
          >
            {t("OrderList.Button.Sync")}
          </button>
        </div>
      ) : (
        <div className="col-12 col-lg-3 py-2">
          <button type="button" className="btn btn-light-primary w-100" disabled={true}>
            <span className="spinner-border spinner-border-sm"></span>
            {t("OrderList.Sync.IsSync")}
          </button>
        </div>
      )}
      {modalOpen && (
        <SyncModal
          isOpen={modalOpen} //buka SyncModal
          onClose={modalClose} //Tutup SyncModal
          onSync={syncing} //Proses Sync
          syncResult={openSyncModal} //Data Response
          onResultFinished={() => setModalSyncresOpen(true)}
          // syncDate={setSyncDateTime} //Data DateTime
        />
      )}
      {modalSyncresOpen && (
        <SyncResultModal
          isResultOpen={modalSyncresOpen} //Buka SyncResultModal
          isResultClose={modalSyncresClose} //Tutup SyncResultModal
          syncRes={syncResult} //Data Response
          syncDate={syncDate} //Data DateTime
        />
      )}

      {isOrderStatusNew && (
        <div className="col-12 col-lg-2 py-2">
          <button
            type="button"
            className="btn btn-primary w-100"
            disabled={selectedItems.length <= 0}
            onClick={() => selectedItems.length > 0 && setReadyToShipOrder(selectedItems)}
          >
            {t("OrderList.Button.ArrangeShipment")}
          </button>
        </div>
      )}

      {isOrderStatusProcessing && (
        <div className="col-12 col-lg-2 py-2">
          <button
            type="button"
            className="btn btn-primary w-100"
            disabled={selectedItems.length <= 0}
            onClick={() => selectedItems.length > 0 && buttonExportOrderHandler()}
          >
            {t("OrderList.Button.ExportOrder")}
          </button>
        </div>
      )}
    </div>
  );
}
