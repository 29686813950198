import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import * as actAccount from "../../../../../actions/account";
import * as servAccount from "../../../../../db/serviceAccount";
import { KTSVG } from "../../../../../resources/helpers";
import { RootState } from "../../../../../setup/redux/store";
import "../../../../../styles/css/margin.scss";
import { Account } from "../../../../models/Account";
import { Campaign, campaignState } from "../../../../models/Campaign";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as lStorage from "../../../../modules/localstorage";

interface ChannelProps {
  onChanges: (params: { key: string; value: any }[]) => void;
  onContinue?: () => void;
  onSaveChanges?: () => void;
  resetChanges?: (resetAll: boolean, field: string, value: any) => void;
  campaign?: Campaign;
}

type TypeProps = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  onTemplateChanges: (state: string) => void;
  isDisabled?: boolean;
};

const CampaignChannel: FC<ChannelProps> = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  let accounts: Account[] = [];
  const [userAccounts, setUserAccounts] = useState<Account[]>([]);
  const [hasSender, setHasSender] = useState<boolean>(false);
  const [hasChannel, setHasChannel] = useState<boolean>(false);
  const [hasTemplate, setHasTemplate] = useState<boolean>(false);
  const [newTemplate, setNewTemplate] = useState<boolean>(false);
  const [stepComplete, setStepComplete] = useState<boolean>(false);
  const [channel, setChannel] = useState<string>("");
  const [sender, setSender] = useState<Account | undefined>(undefined);
  const [defaultAccount, setDefaultAccount] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState("");
  const ReduxCampaignTemplateType: string = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateType
  );
  const ReduxCampaignClientID = useSelector(
    (state: RootState) => state.Campaign.clientID
  );
  const channelList: string[] = lStorage.getItemLC(lStorage.LCName.ChannelList);
  // Edit Campaign
  let campaignID = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignID
  );
  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );
  const reduxCampaignState = useSelector(
    (state: RootState) => state.Campaign.campaignState
  );
  const reduxCampaignChannel = useSelector(
    (state: RootState) => state.Campaign.campaignChannel
  );
  const reduxSelectedAccount = useSelector(
    (state: RootState) => state.Campaign.selectedAccount
  );
  const { campaign, onChanges } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  // Edit Campaign

  useEffect(() => {
    console.log("use effect first run");
    if (reduxCampaignState === campaignState.New) {
      if (ReduxCampaignTemplateType !== "") {
        console.log(`Check disabled campaign channel`);

        document.getElementById(`nextStep`)?.classList.add("disabled");
      }

      setSelectedType(campaignState.New);
      if (reduxCampaignChannel) {
        actAccount.fetchByChannel(reduxCampaignChannel).then((accounts) => {
          if (!accounts) {
            setUserAccounts([]);
            return;
          }

          setUserAccounts(accounts as Account[]);

          if (reduxSelectedAccount !== "") {
            // react method
            const selectedAccount = document.getElementById(
              "senderCampaignSelect"
            ) as HTMLSelectElement;
            const options = Array.from(selectedAccount?.options);
            console.log("ini isi dari options: " + options);

            if (options) {
              // const optionToSelect = options.find(item => item.text === reduxSelectedAccount);
              options.find((item) => {
                if (item.text === reduxSelectedAccount) {
                  item.selected = true;
                  setHasSender(true);
                  document
                    .getElementById(`nextStep`)
                    ?.classList.remove("disabled");
                }
              });
            }
          }
          // if(reduxTemplateAccountID && reduxTemplateAccountID !== ""){
          //   accounts.find((account) => {
          //     if(reduxTemplateAccountID === account.id){
          //       console.log(`Check onCampaign Changes >> useEffect[] - CampaignChannel`)
          //       props.onChanges([{key:"accountId",value:account.id}
          //       ,{key:"companyId",value:account.companyModel?.id}
          //       ,{key:"whatsappBusinessAccount_ID",value:account.whatsappBusinessAccount_ID}
          //       ,{key:"access_token",value:account.access_token}])
          //     }
          //   })
          // }
        });

        document
          .getElementById("senderCampaignSelect")
          ?.setAttribute("value", "1");
        setHasChannel(true);
        setHasTemplate(false);
        setNewTemplate(true);
        setChannel(reduxCampaignChannel);
        setDefaultAccount(true);
      }
    }
  }, []);

  useEffect(() => {
    setChannel(reduxCampaignChannel);
  }, [reduxCampaignChannel]);

  useEffect(() => {
    const loadOptions = async () => {
      if (campaign?.accountModel) {
        const listCompanyAccount = await actAccount.fetchByChannel(
          campaign?.accountModel?.type
        );
        if (listCompanyAccount) {
          accounts = listCompanyAccount as Account[];
          setUserAccounts(accounts);
        }
        setHasChannel(true);
        setHasSender(true);
        if (campaign.templateState && campaign.templateState !== "") {
          setSelectedType(campaign?.templateState);
          setHasTemplate(true);
        }
        setStepComplete(true);
        setChannel(campaign?.accountModel.type);
        setSender(campaign?.accountModel);
      }
    };

    if (campaignID !== "") {
      loadOptions()
        .then(() => {
          // Set Property to Redux
        })
        .catch(console.error);
    }
  }, [campaignID, selectedCampaign]);

  useEffect(() => {
    console.log(
      `${hasTemplate} || ${newTemplate} && ${hasSender} && ${hasChannel}`
    );
    if ((hasTemplate || newTemplate) && hasSender && hasChannel) {
      setStepComplete(true);
    } else if (!hasTemplate || !newTemplate || !hasSender || !hasChannel) {
      setStepComplete(false);
    }

    if (hasChannel && hasSender) {
      document
        .getElementById(`saveChangeNewCampaign`)
        ?.classList.remove("disabled");
    }
  }, [accounts, newTemplate, hasTemplate, hasSender, hasChannel]);

  useEffect(() => {
    if (stepComplete) {
      document.getElementById("nextStep")?.classList.remove("disabled");
    } else {
      document.getElementById("nextStep")?.classList.add("disabled");
    }
  }, [stepComplete]);

  useEffect(() => {
    if (ReduxCampaignTemplateType === "") {
      setSelectedType("");
      setHasTemplate(false);
      setNewTemplate(false);
    }
  }, [ReduxCampaignTemplateType]);

  const Type: FC<TypeProps> = (props) => {
    const { id, title, description, icon, onTemplateChanges, isDisabled } =
      props;

    return (
      <div
        id={id}
        className={clsx(
          "btn btn-light btn-active-primary m-3 template-div",
          selectedType === id ? "active" : "",
          isDisabled ? "disabled" : "",
          isTabletOrMobile ? "w-100" : ""
        )}
        onClick={() => onTemplateChanges(id)}
        data-testid="type-template"
      >
        <div
          className={clsx(icon, "fs-3x")}
          //"bi bi-file-earmark-medical-fill "
        ></div>
        <div className="step-sub-title">{title}</div>
        <div className="desc">{description}</div>
      </div>
    );
  };

  const onUpdateTemplateState = (state: string) => {
    setHasTemplate(state === campaignState.Save);
    setNewTemplate(state === campaignState.New);
    setSelectedType(state);

    console.log(`Check onCampaign Changes >> onUpdateState - CampaignChannel`);
    onChanges([{ key: "templateState", value: state }]);
    dispatch(ReduxCampaign.setCampaignState(state));
    dispatch(ReduxCampaign.setCampaignTemplateType(state));

    console.log(`Masuk selected state >> ${state}`);
  };

  // Select Company and Channel (Account)
  const onSenderChanges = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (+event.target.value === 1) {
      dispatch(ReduxCampaign.setCampaignChanel(""));
      dispatch(ReduxCampaign.setAccountID(""));
      setHasTemplate(false);
      return setHasSender(false);
    }

    // Set Campaign Account (ID)
    const accountId = event.target.value;

    // Set Campaign Company (ID)
    const companyOption = event.target.options.selectedIndex;
    const companyId =
      event.target.options[companyOption].getAttribute("data-key");
    console.log(
      `Check onCampaign Changes >> onCompanySelectionChanges - CampaignChannel`
    );
    props.onChanges([
      { key: "accountId", value: accountId },
      { key: "companyId", value: companyId },
      { key: "clientId", value: ReduxCampaignClientID },
    ]);

    setHasSender(true);
    setSender(
      accounts.find((acc) => {
        return acc.id === accountId;
      })
    );
    setHasTemplate(false);
    // document.getElementById("nextStep")?.classList.add("disabled");

    console.log("Masuk Set Campaign Template Type Reset");
    dispatch(ReduxCampaign.setCampaignTemplateType(""));
    const selectedaccount = userAccounts.find((account) => {
      return accountId === account.id;
    });
    if (selectedaccount) {
      const companyName = selectedaccount.companyModel?.companyName;
      const type = selectedaccount.type;

      const whatsappNumber = selectedaccount.whatsappNumber;
      const defaultAccount =
        companyName +
        " - " +
        type.charAt(0).toUpperCase() +
        type.substring(1, type.length) +
        " - " +
        whatsappNumber;
      dispatch(ReduxCampaign.setAccountID(accountId));
      dispatch(ReduxCampaign.setSelectedAccount(defaultAccount));
      console.log(
        `Check onCampaign Changes >> onCompanySelectionChanges 2 - CampaignChannel`
      );
      props.onChanges([
        { key: "clientId", value: ReduxCampaignClientID },
        { key: "accountId", value: accountId },
        { key: "companyId", value: companyId },
        {
          key: "whatsappBusinessAccount_ID",
          value: selectedaccount.whatsappBusinessAccount_ID,
        },
        { key: "access_token", value: selectedaccount.access_token },
      ]);

      // Set to Redux
      console.log(selectedaccount.id, "tested xll")
      dispatch(ReduxCampaign.setAccountID(selectedaccount.id));
    }
  };

  const onChannelSelectionChanges = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log("Channel select: " + event.target.value);
    dispatch(ReduxCampaign.setIsLoading(true));
    const campaignChannel = event.target.value;
    const listCompanyAccount = await actAccount.fetchByChannel(campaignChannel);

    // console.log("Selected Channel ID: "+campaignChannel)

    if (campaignChannel === "1") {
      dispatch(ReduxCampaign.setCampaignChanel(""));
      dispatch(ReduxCampaign.setAccountID(""));
      setUserAccounts([]);
      setHasSender(false);
      setHasTemplate(false);
      setChannel("");
      setSender(undefined);
      return setHasChannel(false);
    }

    if (listCompanyAccount) {
      accounts = listCompanyAccount as Account[];
      setUserAccounts(accounts);
      console.log("accounts data showed");
    }

    document.getElementById("senderCampaignSelect")?.setAttribute("value", "1");

    setHasChannel(true);
    setHasSender(false);
    setHasTemplate(false);
    setChannel(campaignChannel);
    setSender(undefined);

    console.log(
      `Check onCampaign Changes >> onchannelSelectionChanges - CampaignChannel`
    );
    props.onChanges([{ key: "channel", value: campaignChannel }]);
    // Reset Company and Account Data
    dispatch(ReduxCampaign.setCampaignChanel(campaignChannel));
    dispatch(ReduxCampaign.setAccountID(""));
    dispatch(ReduxCampaign.setIsLoading(false));
  };

  useEffect(() => {
    console.log(`Set Channel and Sender ${channel} ${JSON.stringify(sender)}`);
  }, [channel, sender]);

  return (
    <div
      className="tab-pane fade active show"
      id="kt_tab_pane_1"
      role="tabpanel"
    >
      {/* Alert already processed campaign */}
      {campaignID !== undefined &&
        campaignID !== "" &&
        selectedCampaign.status?.toUpperCase() !== "CREATED" && (
          <div
            className={clsx(
              "alert alert-danger d-flex align-items-center p-5 mb-10"
              // (campaignID !== undefined && campaignID !== "" && selectedCampaign.status?.toUpperCase() !== "CREATED") ? "" : "display-none"
            )}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen050.svg"
              className="svg-icon-danger svg-icon-2hx pe-5"
            />
            <div className="d-flex flex-column">
              <span>{t("Campaign.Alert.ReadOnly")}</span>
            </div>
          </div>
        )}
      <div className="mb-5">
        {isTabletOrMobile && (
          <h1 className="mb-3">{t("Campaign.Tab.SetupCampaign")}</h1>
        )}
        <div className="step-sub-title">
          {t("Campaign.Title.Channel")}
          {hasChannel && (
            <span className="bi bi-check-circle-fill text-success ps-3"></span>
          )}
        </div>
        <div>{t("Campaign.Info.Channel")}</div>
        <select
          disabled={
            selectedCampaign.status !== undefined &&
            selectedCampaign.status !== "CREATED"
          }
          className={clsx("form-select mw-300px me-10")}
          aria-label="Select channel"
          id="channelCampaignSelect"
          onChange={onChannelSelectionChanges}
          value={channel}
          // defaultValue = {reduxCampaignChannel}
          data-testid="channel-selection"
          role="channel-selection"
        >
          <option value="1">{t("Campaign.Input.Channel")}</option>
          {channelList?.length > 0 &&
            channelList?.map((element, index) => {
              return (
                <option
                  value={element}
                  key={element + index}
                  data-testid="channel-selection-option"
                  // selected={campaign?.accountModel?.type === element}
                >{`${element.charAt(0).toUpperCase()}${element.substring(
                  1,
                  element.length
                )}`}</option>
              );
            })}
        </select>
      </div>
      <div className="mb-5">
        <div className="step-sub-title">
          {t("Campaign.Title.Sender")}
          {hasSender && (
            <span className="bi bi-check-circle-fill text-success ps-3"></span>
          )}
        </div>
        <div>{t("Campaign.Info.Sender")}</div>
        <select
          className="form-select mw-300px me-10"
          aria-label="Select channel"
          id="senderCampaignSelect"
          onChange={onSenderChanges}
          value={sender?.id}
          disabled={
            selectedCampaign.status !== undefined &&
            selectedCampaign.status !== "CREATED"
          }
          defaultChecked={reduxSelectedAccount}
          data-testid="sender-selection"
        >
          <option value={1}>{t("Campaign.Input.Sender")}</option>
          {userAccounts?.length > 0 &&
            userAccounts?.map((element, index) => {
              return (
                <option
                  value={element.id}
                  key={element?.id + index}
                  data-key={element?.id}
                  data-testid="sender-selection-option"
                >{`${element.companyModel?.companyName || ""} - ${element.type
                  .charAt(0)
                  .toUpperCase()}${element.type.substring(
                  1,
                  element.type.length
                )} - ${element.whatsappNumber}`}</option>
              );
            })}
        </select>
      </div>
      <div
        className={clsx("mb-5", hasSender ? "" : "display-none")}
        id="message-template"
      >
        <div className="step-sub-title">
          {t("Campaign.Title.MessageTemplate")}
          {(hasTemplate || newTemplate) && (
            <span className="bi bi-check-circle-fill text-success ps-3"></span>
          )}
        </div>
        <div>{t("Campaign.Info.MessageTemplate")}</div>
        <div className="d-flex align-content-start flex-wrap">
          <Type
            isDisabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
                ? true
                : false
            }
            id="Save"
            title={t("Campaign.Button.SavedTemplate")}
            description={t("Campaign.Info.SavedTemplate")}
            onTemplateChanges={onUpdateTemplateState}
            icon="bi bi-journal-bookmark-fill"
          />
          <Type
            isDisabled={
              selectedCampaign.status !== undefined &&
              selectedCampaign.status !== "CREATED"
                ? true
                : false
            }
            id="New"
            title={t("Campaign.Button.NewTemplate")}
            description={t("Campaign.Info.NewTemplate")}
            onTemplateChanges={onUpdateTemplateState}
            icon="bi bi-journal-plus"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CampaignChannel);
