import { current } from "@reduxjs/toolkit";
import React from "react";

type TabsProps = {
  tabList: {
    title: string;
    component: React.FC;
  }[];
};
const Tabs: React.FC<TabsProps> = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const ActiveTab = props.tabList[activeTab].component;
  return (
    <div>
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
        {props.tabList.map((tab, index) => {
          return (
            <li className="nav-item" key={index}>
              <a className={`nav-link ${index === activeTab ? "active text-primary" : "text-dark"}`} data-bs-toggle="tab" onClick={() => setActiveTab(index)}>
                {tab.title}
              </a>
            </li>
          );
        })}
      </ul>

      <div>
        <ActiveTab />
      </div>
    </div>
  );
};

export default Tabs;
