import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Product from "../../../models/Product";
import { Timestamp } from "../../../../db";
import InitialState, { UpdateProduct } from "./ProductRedux";

const initialState: InitialState = {
  productID: "",
  productName: "",
  productNameLength: 0,
  productSKU: "",
  productSKULength: 0,
  selectedStoreFrontData: "",
  selectedBrandData: "",
  descriptionProduct: "",
  descriptionProductLength: 0,
  storeFrontList: [],
  brandList: [],
  isAddStorefront: false,
  newProduct: {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  newBrand: "",
  productPhotos: [],
  productVideo: "",
  coverIndex: 0,
  videoYoutubeURL: "",
  productWeight: 0,
  productDimensionLength: 0,
  productDimensionWidth: 0,
  productDimensionHeight: 0,
  isDangerousProduct: "",
  shipments: [],
  tempShipments: [],
  companies: [],
  marketplaces: [],
  marketplaceCategoryList: [],
  isProductHaveVariants: "",
  variants: [],
  variantsType: [],
  selectedVariantsType: [],
  variantsMatrix: [],
  productVariantPhotos: [],
  isFromSummary: false,
  tempVariants: [],
  tempVariantsMatrix: [],
  tempProductVariantPhotos: [],
  lastStep: "",
  tempSelectedStorefront: "",
  isCreateProduct: false,
  categoryAttributes: {
    shopee: [],
  },
  isGenerateCategoryAttributes: false,
};

export const ProductSlice = createSlice({
  name: UpdateProduct,
  initialState: initialState,
  reducers: {
    setProductID: (state, action: PayloadAction<string>) => {
      state.productID = action.payload;
    },
    setProductName: (state, action: PayloadAction<string>) => {
      state.productName = action.payload;
    },
    setProductNameLength: (state, action: PayloadAction<number>) => {
      state.productNameLength = action.payload;
    },
    setProductSKU: (state, action: PayloadAction<string>) => {
      state.productSKU = action.payload;
    },
    setProductSKULength: (state, action: PayloadAction<number>) => {
      state.productSKULength = action.payload;
    },
    setSelectedStoreFrontData: (state, action: PayloadAction<any>) => {
      state.selectedStoreFrontData = action.payload;
    },
    setSelectedBrandData: (state, action: PayloadAction<any>) => {
      state.selectedBrandData = action.payload;
    },
    setDescriptionProduct: (state, action: PayloadAction<string>) => {
      state.descriptionProduct = action.payload;
    },
    setDescriptionProductLength: (state, action: PayloadAction<number>) => {
      state.descriptionProductLength = action.payload;
    },
    setStoreFrontList: (state, action: PayloadAction<any[]>) => {
      state.storeFrontList = action.payload;
    },
    setBrandList: (state, action: PayloadAction<any[]>) => {
      state.brandList = action.payload;
    },
    setIsAddStorefront: (state, action: PayloadAction<boolean>) => {
      state.isAddStorefront = action.payload;
    },
    setNewProduct: (state, action: PayloadAction<Product>) => {
      state.newProduct = action.payload;
    },
    setNewBrand: (state, action: PayloadAction<string>) => {
      state.newBrand = action.payload;
    },
    addProductPhotos: (state, action: PayloadAction<any>) => {
      const photos = state.productPhotos;
      photos.push(action.payload);
      state.productPhotos = photos;
    },

    setProductPhotos: (state, action: PayloadAction<any>) => {
      state.productPhotos = action.payload;
    },

    setProductVideo: (state, action: PayloadAction<any>) => {
      state.productVideo = action.payload;
    },

    setCoverIndex: (state, action: PayloadAction<number>) => {
      state.coverIndex = action.payload;
    },

    setVideoYoutubeURL: (state, action: PayloadAction<string>) => {
      state.videoYoutubeURL = action.payload;
    },

    setProductWeight: (state, action: PayloadAction<number>) => {
      state.productWeight = action.payload;
    },

    setProductDimensionLength: (state, action: PayloadAction<number>) => {
      state.productDimensionLength = action.payload;
    },

    setProductDimensionWidth: (state, action: PayloadAction<number>) => {
      state.productDimensionWidth = action.payload;
    },

    setProductDimensionHeight: (state, action: PayloadAction<number>) => {
      state.productDimensionHeight = action.payload;
    },
    setIsDangerousProduct: (state, action: PayloadAction<any>) => {
      state.isDangerousProduct = action.payload;
    },

    setShipments: (state, action: PayloadAction<any>) => {
      state.shipments = action.payload;
    },

    setTempShipments: (state, action: PayloadAction<any>) => {
      state.tempShipments = action.payload;
    },

    setCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload;
    },

    setMarketplaces: (state, action: PayloadAction<any>) => {
      state.marketplaces = action.payload;
      console.log("set marketplaces");
    },

    setMarketplacesCategoryList: (state, action: PayloadAction<any>) => {
      state.marketplaceCategoryList = action.payload;
    },

    setIsProductHaveVariants: (state, action: PayloadAction<any>) => {
      state.isProductHaveVariants = action.payload;
    },

    setVariants: (state, action: PayloadAction<any>) => {
      state.variants = action.payload;
    },

    setVariantsType: (state, action: PayloadAction<any>) => {
      state.variantsType = action.payload;
    },

    setSelectedVariantsType: (state, action: PayloadAction<any>) => {
      state.selectedVariantsType = action.payload;
    },

    setVariantMatrix: (state, action: PayloadAction<any>) => {
      state.variantsMatrix = action.payload;
    },

    setProductVariantPhotos: (state, action: PayloadAction<any>) => {
      state.productVariantPhotos = action.payload;
    },

    setFromSummary: (state, action: PayloadAction<boolean>) => {
      state.isFromSummary = action.payload;
    },

    setTempVariants: (state, action: PayloadAction<any>) => {
      state.tempVariants = action.payload;
    },
    setTempVariantsMatrix: (state, action: PayloadAction<any>) => {
      state.tempVariantsMatrix = action.payload;
    },

    setTempProductVariantPhotos: (state, action: PayloadAction<any>) => {
      state.tempProductVariantPhotos = action.payload;
    },

    setLastStep: (state, action: PayloadAction<string>) => {
      state.lastStep = action.payload;
    },

    setTempSelectedStorefront: (state, action: PayloadAction<string>) => {
      state.tempSelectedStorefront = action.payload;
    },

    setIsCreatedProduct: (state, action: PayloadAction<boolean>) => {
      state.isCreateProduct = action.payload;
    },

    setCategoryAttributes: (state, action: PayloadAction<any>) => {
      state.categoryAttributes = action.payload;
    },

    setIsGeneratedCategoryAttributes: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isGenerateCategoryAttributes = action.payload;
    },

    cleanReduxProduct: (state, action: PayloadAction<boolean>) => {
      state.productID = "";
      state.productName = "";
      state.productNameLength = 0;
      state.productSKU = "";
      state.productSKULength = 0;
      state.selectedStoreFrontData = "";
      state.selectedBrandData = "";
      state.descriptionProduct = "";
      state.descriptionProductLength = 0;
      state.storeFrontList = [];
      state.brandList = [];
      state.isAddStorefront = false;
      state.newProduct = {
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };
      state.productPhotos = [];
      state.productVideo = "";
      state.coverIndex = 0;
      state.newBrand = "";
      state.videoYoutubeURL = "";
      state.productWeight = 0;
      state.productDimensionLength = 0;
      state.productDimensionWidth = 0;
      state.productDimensionHeight = 0;
      state.videoYoutubeURL = "";
      state.isDangerousProduct = "";
      state.shipments = [];
      state.companies = [];
      state.marketplaces = [];
      state.marketplaceCategoryList = [];
      state.isProductHaveVariants = "";
      state.variants = [];
      state.variantsType = [];
      state.selectedVariantsType = [];
      state.variantsMatrix = [];
      state.productVariantPhotos = [];
      state.tempVariants = [];
      state.tempVariantsMatrix = [];
      state.tempProductVariantPhotos = [];
      state.isFromSummary = false;
      state.isCreateProduct = false;
      state.lastStep = "";
      state.tempSelectedStorefront = "";
      state.categoryAttributes = { shopee: [] };
      state.isGenerateCategoryAttributes = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProductID,
  setProductName,
  setProductNameLength,
  setProductSKU,
  setProductSKULength,
  setSelectedStoreFrontData,
  setSelectedBrandData,
  setDescriptionProduct,
  setDescriptionProductLength,
  setStoreFrontList,
  setBrandList,
  setIsAddStorefront,
  cleanReduxProduct,
  setNewProduct,
  setNewBrand,
  setProductPhotos,
  addProductPhotos,
  setProductVideo,
  setCoverIndex,
  setVideoYoutubeURL,
  setProductWeight,
  setProductDimensionLength,
  setProductDimensionWidth,
  setProductDimensionHeight,
  setIsDangerousProduct,
  setShipments,
  setCompanies,
  setMarketplaces,
  setMarketplacesCategoryList,
  setCategoryAttributes,
  setIsProductHaveVariants,
  setVariants,
  setVariantsType,
  setTempShipments,
  setSelectedVariantsType,
  setVariantMatrix,
  setProductVariantPhotos,
  setFromSummary,
  setTempVariants,
  setTempVariantsMatrix,
  setTempProductVariantPhotos,
  setLastStep,
  setIsCreatedProduct,
  setTempSelectedStorefront,
  setIsGeneratedCategoryAttributes,
} = ProductSlice.actions;

export default ProductSlice.reducer;
