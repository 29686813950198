/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  PaginationState,
  QUERIES,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import * as CampaignRedux from "../../../../../modules/campaign/list/CampaignListSlice";
import * as CampaignReportRedux from "../../../../../modules/campaign/report/CampaignReportSlice";
import { useQueryRequest } from "./QueryRequestProvider";
import { getCampaigns } from "./_requests";
import { campaignSorting } from "./_sort";

import {Response} from '../../../../../../resources/helpers';
import Campaign from '../../../../../models/Campaign';


export type CampaignsQueryResponse = Response<Array<Campaign>>


const QueryResponseContext =
  createResponseContext<Campaign>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state, updateState } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let CampaignQueryResponse: CampaignsQueryResponse;
  // Selected Campaign
  let campaignID = useSelector(
    (state: RootState) => state.CampaignReport.selectedCampaignID
  );
  // Campaign Report List
  let listCampaigndata: any = [];
  listCampaigndata = useSelector(
    (state: RootState) => state.CampaignReport.listCampaignReport
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    setCounter(listCampaigndata.length);
  }, [listCampaigndata]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [QUERIES.CAMPAIGN_REPORT_INFO, { ...state, campaignID }],
    async () => {
      let data: any = [];

      //cek redux
      // if (!listCampaigndata || listCampaigndata.length <= 0) {
      data = await getCampaigns(
        state.sort,
        state.order,
        state.search,
        state.action,
        state.lastId,
        state.items_per_page,
        campaignID
      );

      if (data && data.length > 0) {
        if (data[0]?.campaignMessagesCount?.repetitions) {
          const repeatCount = data[0].campaignMessagesCount.repetitions;
          const listReport = Object.keys(repeatCount).map((date) => {
            return {
              ...data[0],
              campaignRepeatCount: repeatCount[date],
              dateSent: date,
            };
          });
          data = listReport;
        }
        console.log(`Campaign report list from DB : ${JSON.stringify(data)}`);
        dispatch(CampaignReportRedux.setListCampaignReport(data));
        dispatch(CampaignReportRedux.setSelectedCampaign(data[0]));
      }
      // }
      // else{
      //   data = listCampaigndata;
      //   console.log(`Campaign report list from Redux : ${JSON.stringify(data)}`);
      // }

      let resultData = campaignSorting(
        data,
        state.sort,
        state.items_per_page,
        state.action,
        state.order,
        state.search,
        state.page
      );

      CampaignQueryResponse = {
        data: resultData,
      };

      return Promise.resolve(CampaignQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};
//tanya apa?

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
