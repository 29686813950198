import React, { ReactChild } from "react";
import { KTSVG } from "../../../resources/helpers";
import { t } from "i18next";

type ModalProps = {
  id: string;
  title: string;
  body: string | ReactChild;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmOption: string;
  cancelOption?: string;
};

const ModalViewStatusMarketplace: React.FC<ModalProps> = ({
  id,
  title,
  body,
  onConfirm,
  onCancel,
  confirmOption,
  cancelOption,
}) => {
  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{minWidth: "fit-content"}}>
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body" style={{ overflowX: "scroll", maxHeight: "700px", minWidth: "700px"}}>
            {body}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              {cancelOption}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={onConfirm ? onConfirm : onCancel}
            >
              {confirmOption}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalViewStatusMarketplace;
