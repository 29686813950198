import { uploadFileToStorage } from "../api/firebase/storage";
import Mime from "mime";
import { chatFiles } from "../app/models/Files";
import { Timestamp, addChatFiles, createRef } from "../db";
import { calculateFileSHA256 } from "./FileUtil";

export const getVideoCover = (selectedFile: File): Promise<File> => {
  console.log("Create Video Cover");
  const seekTo = 0.0;
  const maxFileSizeMB = 1; // Maximum file size in MB
  return new Promise((resolve, reject) => {
    try {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      const urlOfFIle = URL.createObjectURL(selectedFile); // Create object URL for the selected file
      // videoPlayer.setAttribute('src', URL.createObjectURL(urlOfFIle));
      videoPlayer.setAttribute("src", urlOfFIle);
      videoPlayer.crossOrigin = "Anonymous";
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject(`error when loading video file ${ex}`);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          if (videoPlayer.videoWidth === 0 || videoPlayer.videoHeight === 0) {
            alert("Unsupported Video. Please use another video file.");
            reject("Unsupported Video. Please use another video file.");
            return;
          }
          console.log("Vitax video player", videoPlayer.videoWidth, videoPlayer.videoHeight, videoPlayer);
          // console.log('video is now paused at %ss.', seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          // then convert it to base 64
          console.log("Vita ctx", ctx?.canvas);
          ctx!.canvas.toBlob(
            async (blob) => {
              console.log("Vita blob", blob);
              try {
                let thumbnailFile: File;
                const fileName = `thumbnail_${selectedFile.name.slice(0, selectedFile.name.lastIndexOf("."))}`;
                if (blob) {
                  // Check if the size of the thumbnail exceeds the maximum allowed size
                  if (blob.size > maxFileSizeMB * 1024 * 1024) {
                    // Resize the thumbnail to the maximum allowed size
                    const resizedBlob = await resizeBlob(blob, maxFileSizeMB * 1024 * 1024 * 0.8);
                    thumbnailFile = new File([resizedBlob], fileName, {
                      type: resizedBlob.type,
                      lastModified: Date.now(),
                    });
                  } else {
                    thumbnailFile = new File([blob], fileName, {
                      type: blob.type,
                      lastModified: Date.now(),
                    });
                  }
                  console.log("Success creating video cover file");
                  resolve(thumbnailFile);
                } else {
                  reject("Blob is null or undefined");
                }
              } catch (error) {
                reject(error);
              }
            },
            "image/jpeg",
            1 /* quality */
          );
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};

// Function to resize a blob
const resizeBlob = (blob: Blob, maxSize: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleFactor = Math.sqrt(maxSize / blob.size);
      canvas.width = image.width * scaleFactor;
      canvas.height = image.height * scaleFactor;
      const ctx = canvas.getContext("2d");
      ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((resizedBlob) => {
        console.log("Vitax resizing blob", {
          resizedBlob,
          scaleFactor,
          width: canvas.width,
          height: canvas.height,
          canvas,
          maxSize,
          imageWidth: image.width,
          imageHeight: image.height,
        });
        if (resizedBlob) {
          resolve(resizedBlob);
        } else {
          reject("Failed to resize blob");
        }
      }, blob.type);
    };
    image.onerror = () => {
      reject("Failed to load image");
    };
    image.src = URL.createObjectURL(blob);
  });
};

export const createFileDataByUploadResult = async (thumbnail: File, credential: { clientId: string; channel: string }) => {
  const file = await getImageDetails(thumbnail);
  console.log("Vita file", file);
  const fileName = `${thumbnail.name.split(".")[0]}_${Date.now()}`;
  const fileMimeType = thumbnail.type;
  const fileExtension = Mime.getExtension(fileMimeType);
  const fileSHA256 = await calculateFileSHA256(thumbnail);
  const fileType = "image";
  const fileMetaData = {
    contentType: fileMimeType,
    fileName: fileName,
  };
  const storagePath = `${credential.clientId}/${fileType}s/sent/${fileName}${fileExtension ? `.${fileExtension}` : ""}`;
  const uploadResult = await uploadFileToStorage(storagePath, thumbnail, fileMetaData);
  if (!uploadResult) {
    console.log("Error uploadFileToStorage. Upload Result Failed");
    return;
  }

  const thumbnailFile: chatFiles = {
    client: createRef("clients", credential.clientId),
    name: uploadResult.metaData.name,
    url: uploadResult.mediaUrl,
    storage_path: uploadResult.metaData.fullPath,
    size: uploadResult.metaData.size,
    type: fileType,
    media_channel: credential.channel,
    media_sha256: fileSHA256,
    media_width: file.width,
    media_height: file.height,
    mime_type: uploadResult.metaData.contentType ?? fileMimeType ?? "",
    extension: !!fileExtension ? fileExtension : "",
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };
  const addFileResult = await addChatFiles(thumbnailFile);
  return { ...thumbnailFile, id: addFileResult?.id };
};

export const getVideoDetails = (file: File) => {
  return new Promise<{ name: string; width: number; height: number }>((resolve, reject) => {
    console.log("SMFC getVideoDetails file ", file);
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      console.log("SMFC onloaded meta data", video, video.videoHeight, video.videoWidth);
      const name = file.name;
      const width = video.videoWidth;
      const height = video.videoHeight;
      resolve({ ...video, name, width, height });
    };
    video.onerror = () => {
      reject(new Error("Error loading video metadata"));
    };
    video.src = url;
    video.load();
    console.log("SMFC check video ", video);
  });
};

export const getImageDetails = (file: File) => {
  return new Promise<{ name: string; width: number; height: number }>((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = document.createElement("img");
    image.onload = () => {
      URL.revokeObjectURL(url);
      const name = file.name;
      const width = image.width;
      const height = image.height;
      resolve({ ...image, name, width, height });
    };
    image.onerror = () => {
      reject(new Error("Error loading image metadata"));
    };
    image.src = url;
  });
};
