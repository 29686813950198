import { Column } from "react-table";
import Storefront from "../../../../../../models/Storefront";
import { StorefrontCustomHeader } from "./StorefrontCustomHeader";
import { StorefrontNameCell } from "./StorefrontNameCell";
import { StorefrontSelectionHeader } from "./StorefrontSelectionHeader";
import { StorefrontSelectionCell } from "./StorefrontSelectionCell";
import { StorefrontActionsCell } from "./StorefrontActionCell";
import { StorefrontNumberOfProductCell } from "./StorefrontNumberOfProductCell";
import { StorefrontStatusCell } from "./StorefrontStatusCell";
const storefrontColumns: ReadonlyArray<Column<Storefront>> = [
  {
    Header: (props) => <StorefrontSelectionHeader tableProps={props} />,
    id: "selection",
    Cell: ({ ...props }) => (
      <StorefrontSelectionCell id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => (
      <StorefrontCustomHeader
        tableProps={props}
        title="StorefrontList.Column.StorefrontName"
        className="min-w-125px"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <StorefrontNameCell name={props.data[props.row.index].name ?? ""} id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => (
      <StorefrontCustomHeader
        tableProps={props}
        title="StorefrontList.Column.Product"
        className="min-w-125px"
      />
    ),
    id: "numberOfProducts",
    Cell: ({ ...props }) => (
      <StorefrontNumberOfProductCell
        numberOfProducts={props.data[props.row.index].numberOfProducts ?? 0}
      />
    ),
  },
  {
    Header: (props) => (
      <StorefrontCustomHeader
        tableProps={props}
        title="StorefrontList.Column.Status"
        className="min-w-125px"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <StorefrontStatusCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index].status}
      />
    ),
  },
  {
    Header: (props) => (
      <StorefrontCustomHeader
        tableProps={props}
        title="Common.Actions"
        className="text-center min-w-125px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <StorefrontActionsCell
        id={props.data[props.row.index].id}
        indexId={props.row.index}
        numberOfProduct={props.data[props.row.index].numberOfProducts??0}
        lastStep={props.data[props.row.index].lastStep}
      />
    ),
  },
];

export { storefrontColumns };