import {Campaign} from "../../../../../models/Campaign"


const sliceData = (data: Campaign[],startIndex: number, endIndex: number) => {
  let newDataCampaign: Campaign [] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const datacampaign = data[index];
    newDataCampaign.push(datacampaign); 
  }
  return newDataCampaign;
}

export const campaignSorting = (data : Campaign[], 
  sort: string | undefined, 
  limit: number | undefined, 
  action: string | undefined , 
  orderBy: string | undefined,
  searchBy: string | undefined, 
  curretPage: number | undefined) => {
  // console.log("Masuk Campaign sorting >>>>> ");

  // console.log("item : " + limit);
  // console.log("action : " + action);
  // console.log("sort : " + sort);
  // console.log("order : " + orderBy);
  // console.log("search : " + searchBy);
  // console.log("current page : " + curretPage);

  if(curretPage === undefined)curretPage = 1;
  if(limit === undefined)limit = 10;
  if(orderBy === undefined) orderBy = "desc";
  if(searchBy === undefined) searchBy = ""; 
  if(sort === undefined || sort === 'deleted') sort = "notifiedAt";

  let dataCampaigns:Campaign [] = [];

  if(data.length == 0){
    return dataCampaigns;
  }

  for (let index = 0; index < data.length; index++) {
    dataCampaigns.push(data[index]); 
  }
  
  //Search Data
  if(searchBy && searchBy !=null && searchBy!="" && dataCampaigns.length>0){
    dataCampaigns = dataCampaigns.filter((data) => data.campaignName?.toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
  }
  
  //Order Data
  if(dataCampaigns.length>0){
    if (sort === "campaignName") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
        a.campaignName!.toLowerCase() > b.campaignName!.toLowerCase() ? 1 : -1
      );
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>
          a.campaignName!.toLowerCase() > b.campaignName!.toLowerCase() ? -1 : 1
        );
      }
    }

    if (sort === "category") {
      dataCampaigns = dataCampaigns?.sort((a, b) => {
        if (a.template === undefined || a.template!.templateCategory === undefined || a.template!.templateCategory === "") return 1;
        if (b.template === undefined || b.template!.templateCategory === undefined || b.template!.templateCategory === "") return -1;
        return a.template!.templateCategory?.toString().toLowerCase() > b.template!.templateCategory?.toString().toLowerCase() ? 1 : -1;
      });

      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) => {
          if (a.template === undefined || a.template!.templateCategory === undefined || a.template!.templateCategory === "") return -1;
          if (b.template === undefined || b.template!.templateCategory=== undefined || b.template!.templateCategory === "") return 1;
          return a.template!.templateCategory?.toString().toLowerCase() > b.template!.templateCategory?.toString().toLowerCase() ? -1 : 1;
        });
      }
    }

    if (sort === "templateName") {
      dataCampaigns = dataCampaigns?.sort((a, b) => {
        if (a.template === undefined || a.template!.templateName === undefined || a.template!.templateName === "") return 1;
        if (b.template === undefined || b.template!.templateName === undefined || b.template!.templateName === "") return -1;
        return a.template!.templateName?.toString().toLowerCase() > b.template!.templateName?.toString().toLowerCase() ? 1 : -1;
      });

      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) => {
          if (a.template === undefined || a.template!.templateName === undefined || a.template!.templateName === "") return -1;
          if (b.template === undefined || b.template!.templateName=== undefined || b.template!.templateName === "") return 1;
          return a.template!.templateName?.toString().toLowerCase() > b.template!.templateName?.toString().toLowerCase() ? -1 : 1;
        });
      }
    }

    // if (sort === "phoneNumber") {
    //   dataCampaigns = dataCampaigns?.sort((a, b) => {
    //     if (a.customerModel!.phoneNumber === undefined || a.customerModel!.phoneNumber === "") return 1;
    //     if (b.customerModel!.phoneNumber === undefined || b.customerModel!.phoneNumber === "") return -1;
    //     return a.customerModel!.phoneNumber?.toString().toLowerCase() > b.customerModel!.phoneNumber?.toString().toLowerCase() ? 1 : -1;
    //   });

    //   if (orderBy === "desc") {
    //     dataCampaigns = dataCampaigns?.sort((a, b) => {
    //       if (a.customerModel!.phoneNumber === undefined || a.customerModel!.phoneNumber === "") return -1;
    //       if (b.customerModel!.phoneNumber === undefined || b.customerModel!.phoneNumber === "") return 1;
    //       return a.customerModel!.phoneNumber?.toString().toLowerCase() > b.customerModel!.phoneNumber?.toString().toLowerCase() ? -1 : 1;
    //     });
    //   }
    // }

    
    if (sort === "lastUpdatedAt") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
      {
        if (!a.updatedAt || a.updatedAt === undefined) return 1;
        if (!b.updatedAt || b.updatedAt === undefined) return -1;
        // return new Date(a.updatedAt) > new Date(b.updatedAt)
        return a.updatedAt > b.updatedAt
          ? 1
          : -1
      });
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>{
          if (!a.updatedAt || a.updatedAt === undefined) return -1;
          if (!b.updatedAt || b.updatedAt === undefined) return 1;
          // return new Date(a.updatedAt) > new Date(b.updatedAt)
          return a.updatedAt > b.updatedAt
            ? -1
            : 1
      });
      }
    }

    if (sort === "companyName") {
      dataCampaigns = dataCampaigns?.sort((a, b) =>
      {
        if (!a.companyName || a.companyName === undefined) return 1;
        if (!b.companyName || b.companyName === undefined) return -1;
        return a.companyName.toLowerCase() > b.companyName.toLowerCase()
          ? 1
          : -1
      });
      if (orderBy === "desc") {
        dataCampaigns = dataCampaigns?.sort((a, b) =>{
          if (!a.companyName || a.companyName === undefined) return -1;
          if (!b.companyName || b.companyName === undefined) return 1;
          return a.companyName.toLowerCase() > b.companyName.toLowerCase()
            ? -1
            : 1
      });
      }
    }
  }

  if(curretPage == 1){
    let endIndex = curretPage * limit;
    // let startIndex = (endIndex - limit);
    // if(startIndex>0){
    //   startIndex =startIndex-1;
    // } 
    // // console.log("Hasil slice data 1 : "+startIndex+" - "+endIndex);
    dataCampaigns = Array.from(dataCampaigns).slice(0,limit);
  }else{
    let endIndex = curretPage * limit;
    let startIndex = (endIndex - limit); 
    // if(startIndex>0){
    //   startIndex =startIndex+1;
    // } 
    // console.log("Hasil slice data 2 : "+startIndex+" - "+endIndex);
    if(startIndex > dataCampaigns.length){
      return [];
    }else{
      dataCampaigns = Array.from(dataCampaigns).slice(startIndex,endIndex);
    }
  }
  // console.log("Hasil Length Data : "+dataCampaigns.length);
  // // console.log("Hasil Data : "+JSON.stringify(dataCollabs));
  // console.log("Hasil Current Page : "+curretPage);
  return dataCampaigns;
}