import {useListView} from '../../core/ListViewProvider'
import {WABAAccountsListToolbar} from './WABAAccountListToolbar';
import {WABAAccountsListGrouping} from './WABAAccountListGrouping';
import {WABAAccountsListSearchComponent} from './WABAAccountListSearchComponent';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import { KTSVG } from '../../../../../../../resources/helpers';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const WABAAccountsListHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const {selected} = useListView()
  const { t } = useTranslation();
  if(isTabletOrMobile){
    return (
      <>
        <div className="d-flex flex-row menu menu-column menu-state-title-primary 
          menu-state-icon-primary menu-state-bullet-primary 
          pt-3 align-items-center border-gray-300 border-bottom">
          <Link to="/setting/" className="symbol p-0 mx-0">
            <KTSVG
              path="/media/icons/duotune/arrows/arr074.svg"
              className="svg-icon-primary svg-icon-2hx"
            />
          </Link>
          <div className="menu-item here show menu-accordion">
            <span className="menu-link px-5 gap-2">
              <div className="bg-light-primary">
                <span className="menu-icon bullet bg-light-primary h-30px w-30px mx-0">
                  <i className="bi bi-layout-text-sidebar-reverse"></i>
                </span>
              </div>
              <span className="menu-title">{`${t(
                "WABAAccountList.Menu"
              )}`}</span>
            </span>
          </div>
        </div>
        <div className="border-0 pt-6 px-5 d-flex flex-column">
          <WABAAccountsListSearchComponent />
          {/* begin::Card toolbar */}
          <div className="card-toolbar w-100 pb-7 mt-3">
            <WABAAccountsListGrouping />
            <WABAAccountsListToolbar />
          </div>
          {/* end::Card toolbar */}
        </div>
      </>
    );
  }else{
    return (
      <div className="card-header border-0 pt-6">
        <div className="w-50">
          <WABAAccountsListSearchComponent />
        </div>
        {/* <div className="w-25"> */}
          {/* begin::Card toolbar */}
          <div className="card-toolbar py-1">
            <WABAAccountsListGrouping />
            <WABAAccountsListToolbar />

            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        {/* </div> */}
      </div>
    );
  }
}

export {WABAAccountsListHeader}