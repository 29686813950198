import Storefront from "../../../../models/Storefront"

interface InitialState {
  listStorefront: Storefront[];
  countStorefront: number;
  currentPageStorefront: number;
  maxPageStorefront: number;
  arrayStorefrontPagination: string[];
}
const StorefrontListAction: string = "StorefrontListAction";

export type unsubFN = () => void;

export default InitialState;
export { StorefrontListAction };
