import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, {
  UpdateMarketplaceProductCategory,
} from "./marketplaceProductCategoryRedux";

const initialState: InitialState = 
  {
    marketplaceShopee: []
  };

export const MarketplaceProductCategorySlice = createSlice({
  name: UpdateMarketplaceProductCategory,
  initialState: initialState,
  reducers: {
    setShopeeMarketplaceProductCategory: (state, action: PayloadAction<any>) => {
      state.marketplaceShopee = action.payload;
    },
    cleanReduxMarketplaceProductCategory: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.marketplaceShopee = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShopeeMarketplaceProductCategory,
  cleanReduxMarketplaceProductCategory,
} = MarketplaceProductCategorySlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default MarketplaceProductCategorySlice.reducer;
