import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  }
}

interface IProps {
  labels: string[];
  datasets: ChartProps["datasets"];
}

export interface ChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
  options?: typeof defaultOptions;
}

export function ReusableLineChart({ labels, datasets, options = defaultOptions }: ChartProps) {
  const data = {
    labels,
    datasets,
  };

  return <Line options={options} data={data} />;
}

const ConversationAnalythicChart: React.FC<IProps> = (props) => {
  return (
        <div className="row mx-0 px-5 pb-5" style={{ height: '400px' }}>
          <ReusableLineChart labels={props.labels} datasets={props.datasets} />
        </div>
  );
}

export default ConversationAnalythicChart;
