import { Link, useNavigate } from "react-router-dom";
// import ReactRouterPrompt from "react-router-prompt";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Timestamp } from "../../../../../../db";
import { createRef } from "../../../../../../db/connection";
import * as ServStorefront from "../../../../../../db/serviceStorefront";
import { RootState } from "../../../../../../setup/redux/store";
import Button from "../../../../../../styles/components/Button";
import Storefront, {
  MarketplaceCategoryList,
  storefrontStatus,
  storefrontStep,
} from "../../../../../models/Storefront";
import * as lc from "../../../../../modules/localstorage";
import ModalConfirmationMoveToOtherPage from "../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import * as ReduxStorefront from "../../../../../modules/product/storefront/StorefrontSlice";
import * as ReduxStorefrontList from "../../../../../modules/product/storefront/storefrontList/StorefrontListSlice";
import { PageLink, PageTitle } from "../../../../core";
import StorefrontCarouselImage from "./StorefrontCarouselImage";
import StorefrontMobileIcon from "./StorefrontMobileIcon";
import { Loading } from "../../../../../modules/util/Loading";

let pathImg = "/media/images";
const SlideshowImages = [
  {
    name: "Shopee",
    data: {
      mobile: `${pathImg}/etalase-shopee-mobile.jpeg`,
      desktop: `${pathImg}/etalase-shopee.jpeg`,
    },
  },
  {
    name: "Tokopedia",
    data: {
      mobile: `${pathImg}/etalase-tokopedia-mobile.jpeg`,
      desktop: `${pathImg}/etalase-tokopedia.jpeg`,
    },
  },
  {
    name: "Bukalapak",
    data: {
      mobile: `${pathImg}/etalase-bukalapak-mobile.jpeg`,
      desktop: `${pathImg}/etalase-bukalapak.jpeg`,
    },
  },
  {
    name: "JDID",
    data: {
      mobile: `${pathImg}/etalase-jdid-mobile.jpeg`,
      desktop: `${pathImg}/etalase-jdid.jpeg`,
    },
  },
];

const StorefrontName = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Column.Product"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("Storefront.Info.Storefront"),
      path: "/storefront",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true);
  const [isStorefrontNameHasExist, setIsStorefrontNameHasExistHasExist] =
    useState<boolean>(false);
  const reduxStorefrontName = useSelector(
    (state: RootState) => state.Storefront.storefrontName
  );
  const reduxStorefrontNameLength = useSelector(
    (state: RootState) => state.Storefront.storefrontNameLength
  );

  const reduxStorefrontId = useSelector(
    (state: RootState) => state.Storefront.storefrontID
  );

  const storefrontList = useSelector(
    (state: RootState) => state.StorefrontList.listStorefront
  );

  const reduxStorefrontAndSelectedMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  const onChangeStorefrontName = async (event: any) => {
    setIsValidTitle(true);
    setIsStorefrontNameHasExistHasExist(false);
    let storefrontNameValue = event.target.value;
    if (storefrontNameValue === "" || storefrontNameValue.trim() === "") {
      setIsValidTitle(false);
    }
    dispatch(ReduxStorefront.setStorefrontName(storefrontNameValue));
    dispatch(
      ReduxStorefront.setStorefrontNameLength(storefrontNameValue.length)
    );
  };

  const handleNext = async () => {
    if (
      reduxStorefrontName === "" ||
      reduxStorefrontName.trim() === "" ||
      reduxStorefrontName.length <= 0
    ) {
      setIsValidTitle(false);
      return;
    }
    const client = lc.getItemLC(lc.LCName.Client);
    //check if name is exist
    let title = reduxStorefrontName.trim();
    let getCategories: any = [];
    let getStorefrontName = await ServStorefront.getStorefrontByName(
      title,
      client.id
    ).then(async (storefront: any) => {
      if (storefront) {
        return storefront;
      }
    });
    let sameId = false;
    if (reduxStorefrontId) {
      sameId = getStorefrontName[0]?.id === reduxStorefrontId;
    }
    if (getStorefrontName && !sameId) {
      getCategories = [...getStorefrontName];
    }
    if (getCategories.length > 0) {
      setIsStorefrontNameHasExistHasExist(true);
      return;
    }
    dispatch(ReduxStorefront.setStorefrontName(reduxStorefrontName));
    dispatch(
      ReduxStorefront.setStorefrontNameLength(reduxStorefrontName.length)
    );
    nav("/storefront/create-storefront/select-marketplace");
  };

  const createModelStorefront = (user: string, storefrontName: string) => {
    const client = lc.getItemLC(lc.LCName.Client);
    const clientRef = createRef("clients", client.id);
    const storefrontData: Storefront = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      status: storefrontStatus.drafted,
      lastStep: storefrontStep.onStorefrontName,
      client: clientRef,
    };
    return storefrontData;
  };

  const createModelStorefrontForUpdate = (
    user: string,
    storefrontName: string
  ) => {
    const storefrontData: Storefront = {
      updatedAt: Timestamp.now(),
      isActive: true,
      name: storefrontName,
      updatedBy: createRef("users", user),
      status: storefrontStatus.drafted,
      lastStep: storefrontStep.onStorefrontName,
    };
    return storefrontData;
  };

  const createModelMarketplaceCategory = (
    user: string,
    categoriesData: any,
    isChecked: boolean,
    type: string
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      createdBy: createRef("users", user),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      isChecked,
      type: type,
    };
    return marketplaceCategoriesData;
  };

  const createModelMarketplaceCategoryForUpdate = (
    user: string,
    categoriesData: any,
    isChecked: boolean,
    type: string
  ) => {
    const marketplaceCategoriesData: MarketplaceCategoryList = {
      updatedAt: Timestamp.now(),
      updatedBy: createRef("users", user),
      categories: categoriesData,
      isChecked,
      type: type,
    };
    return marketplaceCategoriesData;
  };

  const handleSavedChanges = async () => {
    if (
      reduxStorefrontName === "" ||
      reduxStorefrontName.trim() === "" ||
      reduxStorefrontName.length <= 0
    ) {
      setIsValidTitle(false);
      return;
    }
    const client = lc.getItemLC(lc.LCName.Client);
    //check if name is exist
    let name = reduxStorefrontName.trim();
    let getStorefront: any = [];
    console.log("name : ", name);
    let getStorefrontByName = await ServStorefront.getStorefrontByName(
      name,
      client.id
    ).then(async (storefront: any) => {
      if (storefront) {
        return storefront;
      }
    });
    console.log("getStorefrontByName : ", getStorefrontByName);
    let sameId = false;
    if (reduxStorefrontId && getStorefrontByName) {
      sameId = getStorefrontByName[0]?.id === reduxStorefrontId;
    }
    if (getStorefrontByName !== "" && !sameId) {
      getStorefront = [...getStorefrontByName];
    }
    if (getStorefront.length > 0) {
      setIsStorefrontNameHasExistHasExist(true);
      return;
    }
    let user = lc.getItemLC("UID");
    let modelStorefront: any;
    if (!reduxStorefrontId) {
      modelStorefront = createModelStorefront(user, reduxStorefrontName.trim());
      await ServStorefront.createStorefront(modelStorefront)
        .then(async (id) => {
          console.log(`success create storefront ${reduxStorefrontName}}`);
          if (id && reduxStorefrontAndSelectedMarketplace.length > 0) {
            //looping for selected marketplace
            for (
              let index = 0;
              index < reduxStorefrontAndSelectedMarketplace.length;
              index++
            ) {
              let data: any = undefined;
              let market: any = reduxStorefrontAndSelectedMarketplace[index];
              let type = market.name.toLowerCase();
              let isChecked = market.isChecked;
              let selectedCategory = market?.category;
              for (let i = 0; i < selectedCategory.length; i++) {
                let arrayCategories = selectedCategory[i];
                data = { ...arrayCategories };
              }
              let arrayData = [];
              arrayData.push({ ...data });
              let marketplaceData = createModelMarketplaceCategory(
                user,
                arrayData,
                isChecked,
                type
              );

              if (marketplaceData) {
                //create sub collection
                await ServStorefront.createSelectedMarketplaceProductCategory(
                  id,
                  marketplaceData
                )
                  .then(() => {
                    console.log(
                      `success create subcollection product categories ${reduxStorefrontName}`
                    );
                  })
                  .catch((error) => {
                    console.log("error add subcolection " + error);
                  });
              }
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      modelStorefront = createModelStorefrontForUpdate(
        user,
        reduxStorefrontName.trim()
      );
      await ServStorefront.updateStorefront(reduxStorefrontId, modelStorefront)
        .then(async () => {
          console.log(`success update storefront ${reduxStorefrontName}}`);
          if (reduxStorefrontAndSelectedMarketplace.length > 0) {
            for (
              let index = 0;
              index < reduxStorefrontAndSelectedMarketplace.length;
              index++
            ) {
              let data: any = undefined;
              let market: any = reduxStorefrontAndSelectedMarketplace[index];
              let type = market.name.toLowerCase();
              let isChecked = market.isChecked;
              let selectedCategory = market?.category;
              for (let i = 0; i < selectedCategory.length; i++) {
                let arrayCategories = selectedCategory[i];
                data = { ...arrayCategories };
              }
              let arrayData = [];
              arrayData.push({ ...data });
              let marketplaceData = createModelMarketplaceCategoryForUpdate(
                user,
                arrayData,
                isChecked,
                type
              );
              if (marketplaceData) {
                //get subcollection by type == Shopee
                const getSubCollectionId =
                  await ServStorefront.getStorefrontMarketplaceCategoryById(
                    reduxStorefrontId
                  );
                console.log("getSubCollectionId : ", getSubCollectionId);
                const findByType = getSubCollectionId.find(
                  (marketplace: any) => {
                    return marketplace.type === type;
                  }
                );
                await ServStorefront.updateSelectedMarketplaceProductCategory(
                  reduxStorefrontId,
                  findByType.id,
                  marketplaceData
                )
                  .then(() => {
                    console.log(
                      `success create subcollection product categories ${reduxStorefrontName}`
                    );
                  })
                  .catch((error) => {
                    console.log("error add subcolection " + error);
                  });
              }
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    dispatch(ReduxStorefrontList.cleanReduxStorefrontList(true));
  };

  const handleUnsavedChanges = () => {
    dispatch(ReduxStorefront.cleanReduxStorefront(true));
  };

  useEffect(() => {
    if (reduxStorefrontId) {
      const getStorefront: any = storefrontList.find(
        (storefront) => storefront.id === reduxStorefrontId
      );
      dispatch(ReduxStorefront.setStorefrontName(getStorefront?.name));
    } else {
      dispatch(ReduxStorefront.setStorefrontName(reduxStorefrontName));
    }
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={UsersBreadcrumbs}>{`${t(
        "Storefront.Info.BreadCrumb.AddStorefront"
      )}`}</PageTitle>

      <ModalConfirmationMoveToOtherPage
        isDirty={reduxStorefrontName.length > 0}
        path="/storefront/create-storefront"
        onSave={() => handleSavedChanges()}
        onUnsave={() => handleUnsavedChanges()}
      />

      <div className="d-flex flex-column flex-lg-column h-100">
        <div className="d-flex flex-column flex-lg-column h-100">
          <div className="overflow-hidden card mb-1 mb-xl-2 h-100">
            {isTabletOrMobile && <StorefrontMobileIcon />}
            <div
              style={{
                height: "90%",
              }}
              className={clsx(
                `d-flex flex-column ${
                  isTabletOrMobile ? "p-10" : "px-7 pt-7 pb-0"
                }`
              )}
            >
              <div className="flex-lg-column-fluid tab-content h-100">
                <div className="d-flex flex-column">
                  {isTabletOrMobile && (
                    <div className="h1">
                      {t("Storefront.Name.AddStorefront")}
                    </div>
                  )}
                  {!isTabletOrMobile && (
                    <div className="h1">
                      <h1 className={isTabletOrMobile ? "w-100" : "w-75"}>
                        {t("Storefront.Name.AddStorefront")}
                      </h1>
                    </div>
                  )}
                  <div className="step-sub-title mt-5 mb-5 fs-2">
                    <label className="form-check-label">
                      {`${t("Storefront.Info.StorefrontName")}`}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className={clsx(
                      `"d-flex flex-row mb-2 ${
                        isTabletOrMobile ? "w-100" : "w-50"
                      }`
                    )}
                  >
                    <div className="w-100">
                      <div className="d-flex align-items-center form-control border px-0 py-0">
                        <input
                          data-testid="storefrontName"
                          id="storefrontName"
                          name="storefrontName"
                          maxLength={40}
                          className="form-control border-0"
                          onChange={onChangeStorefrontName}
                          value={reduxStorefrontName}
                        />
                        <p
                          className="border-0 bg-white my-0"
                          style={{
                            paddingRight: "10px",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          {reduxStorefrontNameLength}/40
                        </p>
                      </div>
                      <div
                        className="d-flex"
                        style={{ height: "20px" }}
                        data-testid="storefrontTitleErrValid"
                      >
                        {!isValidTitle && (
                          <div style={{ color: "red" }}>
                            {`${t("Storefront.Alert.StorefrontName")}`}
                          </div>
                        )}
                        {isStorefrontNameHasExist && (
                          <span style={{ color: "red" }}>{`${t(
                            "Storefront.Alert.Input.StorefrontName"
                          )}`}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <StorefrontCarouselImage
                  id="storefrontcarousel"
                  dataImage={SlideshowImages}
                  title={`${t("Storefront.Name.Carousel")}`}
                />
              </div>
            </div>
            <div
              className={clsx(
                `d-flex space bg-white justify-content-end w-100 ${
                  isTabletOrMobile ? "mb-5" : "pt-0 px-7 pb-0"
                }`
              )}
              style={{ zIndex: 99 }}
            >
              <Link to="/storefront/list">
                <Button data-testid="btnCancel" btnbs="primary" type="button">
                  {t("Alert.Button.Cancel")}
                </Button>
              </Link>
              <Button
                data-testid="btnNext"
                type="button"
                btnbs="primary"
                cName="mx-5"
                onClick={() => handleNext()}
              >
                {`${t("Storefront.Button.Next")}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
export default StorefrontName;
