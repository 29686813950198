import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import MarketplaceList from "./MarketplaceList";
import { PageLink, PageTitle } from "../../../core";
import { useTranslation } from "react-i18next";

//  
const MarketplaceListPage = () => {
const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Marketplace.Menu.Marketplace"),
      path: '/setting/marketplace/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("Marketplace.List")} 
      </PageTitle>
      <MarketplaceList />
    </>
  );
}

export default MarketplaceListPage;