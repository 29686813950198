import { FC } from "react";
import { Link } from "react-router-dom";
import { Collaboration as HandledMessageListItem } from "../../../models/Chat";
import { Timestamp } from "../../../../db";
import { ChatLastMessage } from "./chat-message/ChatLastMessage";
import { format } from "timeago.js";
import ChatTime2 from "./chat-message/ChatTime";
import * as lc from "../../../modules/localstorage/index"
import * as chatUtil from "./chat-util/ChatUtil"

interface ChatItemProps {
  item: HandledMessageListItem;
  onOpenChat: (id: string) => void;
}

const lastActivity = (time: number) => {
  let timeMillis = Timestamp.now().toMillis();

  timeMillis = timeMillis - time * 1000;

  return Timestamp.fromMillis(time).toDate();
};

const FriendChatItem: FC<ChatItemProps> = (props) => {
  const { item } = props;
  const channelIcon = "/media/icons/channel/";
  const avatarIcon = "/media/icons/avatar/";
  const activeCompany = lc.getItemLC("CID");

  let fullName = "Friend";
  if (
    (item.userModel?.name === undefined ||
      item.userModel?.name === "") &&
    item.userModel?.phoneNumber !== undefined
  ) {
    fullName = `+${item.userModel?.phoneNumber}`;
  } else if (item.userModel?.name) {
    fullName = item.userModel?.name;

    if (fullName.length > 15) {
      fullName = fullName.substring(0, 11) + "...";
    }
  } 

  const sameCompany = () => {
    let isSameCompany = false
    if (activeCompany !== null) {
      if (item.userModel?.user_companies) {
        item.userModel?.user_companies.forEach(company => {
          if (company.company.id === activeCompany) {
            isSameCompany = true
          }
        })
      }
    }
    return isSameCompany
  }

  const avatarCompany = () => {
    const avatarCompany = document.getElementById(`avatarCompany_${activeCompany}`) as HTMLImageElement
    if (avatarCompany) {
      return <img
        className="badge symbol-badge badge-sm badge-circle bg-success start-75 top-75"
        alt={item.userModel?.name}
        src={avatarCompany.src}
        style={{ backgroundColor: "#FFFFFF" }}
      />
    }
    else{
      return <i className="start-75 top-75 far fa-building fs-5 cl-gray" 
      style={{position:"absolute", color: "#404255"}}
      ></i>
    }
  }

  return (
    <div
      className="d-flex flex-stack py-4 bg-active-secondary bg-hover-secondary cl-graywhite"
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
        // paddingBottom: "10px",
        // paddingTop: "10px",
      }}
      key={`chatUserItem_${item.id}`}
      onClick={props.onOpenChat.bind(null, item.id)}
    >
      <div className="d-flex align-items-center">
        <span
                  className="symbol symbol-45px symbol-circle"
                  style={{position: "relative"}}
                >
                  <img
                    className="symbol-label"
                    alt={item.userModel?.name}
                    src={chatUtil.avatarUrl(item.userModel)}
                    style={{ backgroundColor: "#FFFFFF" }}
                  />
                  {/* Company Badge */}
                  {/* {sameCompany() &&
                    avatarCompany()
                  } */}
                </span>

        <div className="ms-5">
          <Link
            to="#"
            className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
            onClick={props.onOpenChat.bind(null, item.id)}
          >
            {chatUtil.userName(item.userModel,15)}
          </Link>
          <div className="fw-bold text-gray-400">
            <ChatLastMessage
              lastmessage={item.lastInteractionMessage??""}
              lastmessagetype={item.lastInteractionType??""}
              id={item.id}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-end ms-2">
        {item.lastInteractionAt && (
          <span className="text-muted fs-7 mb-1">
            {/* {ChatTime( new Date (item.lastInteractionAt.seconds * 1000))} */}
            {ChatTime2(item.lastInteractionAt.seconds)}
          </span>
        )}
        {item.unreadMessages?.map(
          (unreadMessages) =>
            unreadMessages.unreadCount > 0 && (
              <span className="badge badge-circle fw-bolder bg-success start-75 badge-sm">
                {unreadMessages.unreadCount}
              </span>
            )
        )}
      </div>

      <div className="separator separator-dashed d-none"></div>
    </div>
  );
};

export default FriendChatItem;
