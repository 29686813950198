import React from "react";
import { useTranslation } from "react-i18next";
import { convertTextStyle } from "../chat-util/ChatUtil";

type Props = {
  lastmessage: string;
  lastmessagetype: string,
  id?: string;
};

const ChatLastMessage: React.FC<Props> = ({
  lastmessage,
  lastmessagetype,
  id,
}) => {
  const { t } = useTranslation();

  let unsupportedMessage = t("HC.Error.Unsupportedmsg");
  let message = convertTextStyle(lastmessage);
  // console.log(message, "messaga pox")

  if(lastmessage.length>20){
    message = message.substring(0,15)+" ...";
  }
  if (lastmessagetype === "text"){
    return (
      <div id={(id)} style={{
        color: message.includes('Draft : ') === true ? 'red' : 'grey'
      }}
      dangerouslySetInnerHTML={{ __html: convertTextStyle(message)}}>
      </div>
      );
  }else if (lastmessagetype === "image"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-camera-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: convertTextStyle(message)}}/>
      </div>
      );
  }else if (lastmessagetype === "video"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-camera-video-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      </div>
      );
  }else if (lastmessagetype === "audio"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-mic-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      </div>
      );
  }else if (lastmessagetype === "document"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-file-earmark-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      </div>
      );
  }else if (lastmessagetype === "location"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-geo-alt-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      </div>
      );
  }else if (lastmessagetype === "unsupported"){
    return (
      <div>
          <span id={(id)} data-testid={id} className="bi bi-clock-fill pe-1"></span>
          <span dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      </div>
      );
  }else{
    return (
      <div
      dangerouslySetInnerHTML={{ __html: (convertTextStyle(message))}}/>
      );
  }
};

export { ChatLastMessage };
