import { useTranslation } from "react-i18next";
import { useFirestoreTable } from "../context/TableContext";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import MarketplaceTargetModal from "../components/MarketplaceTargetModalSelect";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import { KTSVG } from "../../../../../../../resources/helpers";
import Offcanvas from "bootstrap/js/dist/offcanvas";

function MarketplaceListHeader() {
  const { t } = useTranslation();
  const [targetModalOpen, setTargetModalOpen] = useState(false);
  const [modalMobileOpen, setModalMobileOpen] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const onTargetModalClose = () => setTargetModalOpen(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  console.log(selectedField, "field pkl");
  console.log(selectedSort, "sort pkl");

  const { refreshData, isFetching, updateTableState } = useFirestoreTable();

  // useEffect(() => {
  //   if (isMobileView) {
  //   const offcanvasElement = document.getElementById("offcanvasBottom");
  //   if (offcanvasElement) {
  //     new Offcanvas(offcanvasElement);
  //   }}
  // }, [isMobileView]); 

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    updateTableState({
      searchKey: {
        field: "name",
        value: event.target.value,
      },
    });
  };

  const handleCloseOffcanvas = () => {
    let buttonmobile = document.getElementById("mobilefilter");
    buttonmobile?.click();
  };

  const applyFilter = () => {
    updateTableState({
      sorting: {
        field: selectedField as any,
        order: selectedSort as any,
      },
    });
    // refreshData();
    handleCloseOffcanvas();
  };

  const clearFilter = () => {
    updateTableState({
      sorting: {
        field: "type",
        order: "asc",
      },
    });
    setSelectedField("");
    setSelectedSort("");
    refreshData();
    handleCloseOffcanvas();
  };

  return (
    <>
      {!isMobileView ? (
        <div className="card-header border-0 pt-6 pb-5">
          {/* Search input */}
          <div
            className={clsx("card-title")}
            style={{
              flex: 1,
            }}
          >
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1 w-100">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-testid="search-marketplace"
                data-kt-user-table-filter="search"
                className={clsx("form-control form-control-solid ps-14")}
                placeholder={t("Marketplace.Button.Search")}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {/* end::Search */}
          </div>

          <div className="card-toolbar">
            {/* Fetch Button */}
            <button
              type="button"
              className="btn btn-light-primary me-2"
              // disabled
              data-testid="refetch-marketplace"
              onClick={() => refreshData()}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
            {/* Add Marketplace Button */}
            <div className="">
              <Link
                type="button"
                className="btn btn-primary"
                to="#"
                data-testid="add-marketplace"
                onClick={() => setTargetModalOpen(true)}
              >
                <span>
                  <i className="bi bi-plus-lg fs-4 me-2"></i>
                  {t("Marketplace.Button.AddMarketplace")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="border-0 pt-4 pb-3 position-relative">
          {/* Search input */}
          <div className="d-flex flex-row pb-3">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative w-100 pe-2 ms-2">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-4"
              />
              <input
                type="text"
                data-testid="search-marketplace"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid ps-12"
                placeholder={t("Marketplace.Button.Search")}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {/* end::Search */}
            {/* Filter Button */}
            <button
              type="button"
              id="mobilefilter"
              className="btn btn-light-primary me-2"
              // id="offcanvas2"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
              onClick={() => {
                setModalMobileOpen(!modalMobileOpen);
              }}
            >
              <i className="bi bi-funnel-fill fs-1"></i>
            </button>
          </div>

          <div className="d-flex justify-content-between align-items-center w-100">
            {/* Add Marketplace Button */}
            <div className="d-flex align-items-center w-100">
              <Link
                type="button"
                className="btn btn-primary w-100 mx-2"
                to="#"
                onClick={() => setTargetModalOpen(true)}
              >
                <span className="d-none d-md-inline">
                  <i className="bi bi-plus-lg fs-4 me-2"></i>
                  {t("Marketplace.Button.AddMarketplace")}
                </span>
                <span className="d-md-none">
                  <i className="bi bi-plus-lg fs-4 me-2"></i>
                  {t("Marketplace.Button.AddMarketplace")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* Render the target modal when needed */}
      {targetModalOpen && (
        <MarketplaceTargetModal
          isOpen={targetModalOpen}
          onClose={onTargetModalClose}
          onAction={onTargetModalClose}
        />
      )}

      {isMobileView && (
        <div
          className="offcanvas offcanvas-bottom w-100 h-auto"
          tabIndex={-1}
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
          style={{
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
            zIndex: 50000,
          }}
        >
          <div
            className="offcanvas-header p-6"
            style={{
              borderBottom: "1px solid #D6D6D6",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              {t("Marketplace.Button.Filter")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div
            className="offcanvas-body w-100 h-auto px-0 d-flex flex-column pb-0"
            style={{
              flex: 1,
            }}
          >
            <div
              className="px-5 d-flex flex-row col-gap-2 row-gap-2 flex-wrap"
              style={{
                flex: 1,
                marginBottom: "15px",
                paddingBottom: "15px",
                borderBottom: "1px solid #D6D6D6",
              }}
            >
              <button
                type="button"
                className={
                  selectedField === "type"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "type") {
                    setSelectedField("type");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "type" ? "none" : "auto",
                }}
              >
                {t("Marketplace.Menu.Marketplace")}
              </button>
              <button
                type="button"
                className={
                  selectedField === "name"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "name") {
                    setSelectedField("name");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "name" ? "none" : "auto",
                }}
              >
                {t("Marketplace.Table.Header.URL")}
              </button>
              <button
                type="button"
                className={
                  selectedField === "connectedAt"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "connectedAt") {
                    setSelectedField("connectedAt");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents:
                    selectedField === "connectedAt" ? "none" : "auto",
                }}
              >
                {t("Marketplace.Table.Header.Date")}
              </button>
              <button
                type="button"
                className={
                  selectedField === "status"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "status") {
                    setSelectedField("status");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "status" ? "none" : "auto",
                }}
              >
                {t("Marketplace.Table.Header.SetupProgress")}
              </button>
              <button
                type="button"
                className={
                  selectedField === "isActive"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "isActive") {
                    setSelectedField("isActive");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "isActive" ? "none" : "auto",
                }}
              >
                {t("Marketplace.Table.Header.Status")}
              </button>
            </div>
          </div>
          <div
            className="p-6 pt-0"
            style={{
              borderBottom: "1px solid #D6D6D6",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              {t("Marketplace.Button.SortBy")}
            </h5>
          </div>
          <div
            className="offcanvas-body w-100 h-auto px-0 d-flex flex-column"
            style={{
              flex: 1,
            }}
          >
            <div
              className="px-5 d-flex flex-row col-gap-2 row-gap-2 flex-wrap"
              style={{
                flex: 1,
                marginBottom: "15px",
                paddingBottom: "15px",
                borderBottom: "1px solid #D6D6D6",
              }}
            >
              <button
                type="button"
                className={
                  selectedSort === "asc"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  setSelectedSort("asc");
                }}
                disabled={selectedField === "" ? true : false}
                style={{
                  pointerEvents: selectedSort === "asc" ? "none" : "auto",
                }}
              >
                {selectedField === "connectedAt"
                  ? t("Marketplace.Button.Oldest")
                  : selectedField === "isActive"
                  ? t("Marketplace.Progress.On")
                  : selectedField === "status"
                  ? t("Marketplace.Status.Disconnected")
                  : t("Marketplace.Button.AZ")}
              </button>
              <button
                type="button"
                className={
                  selectedSort === "desc"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  setSelectedSort("desc");
                }}
                disabled={selectedField === "" ? true : false}
                style={{
                  pointerEvents: selectedSort === "desc" ? "none" : "auto",
                }}
              >
                {selectedField === "connectedAt"
                  ? t("Marketplace.Button.Newest")
                  : selectedField === "isActive"
                  ? t("Marketplace.Progress.Completed")
                  : selectedField === "status"
                  ? t("Marketplace.Status.Connected")
                  : t("Marketplace.Button.ZA")}
              </button>
            </div>
            <div className="px-5 d-flex w-100 flex-row row-gap-2 col-gap-2">
              <button
                type="button"
                className="btn btn-danger w-100 d-flex flex-column align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  height: "30px !important",
                }}
                onClick={() => {
                  clearFilter();
                }}
              >
                {t("Marketplace.Button.Reset")}
              </button>
              <button
                type="button"
                className="btn btn-primary w-100 d-flex flex-column align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  height: "30px !important",
                }}
                onClick={() => {
                  applyFilter();
                }}
                disabled={selectedField === "" || selectedSort === ""}
              >
                {t("Marketplace.Button.Apply")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MarketplaceListHeader;
