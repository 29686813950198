import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  QueryConstraint,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
  collection,
  doc,
  documentId,
  endAt,
  getCountFromServer,
  getDocs,
  getDocsFromServer,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
  and,
  or,
  collectionGroup,
  QueryFilterConstraint,
} from "firebase/firestore";
import * as lc from "src/app/modules/localstorage/index";
import { useQuery } from "react-query";
import db, { newDB } from "src/db";
import { Filter, FirestoreQueryState, Sorting } from "../models/Models";

export type MapperFn<T> = (
  docs: QueryDocumentSnapshot<T, DocumentData>[]
) => T[] | Promise<T[]>;

export function createDocRef(collection: string, id: string) {
  return doc(newDB, `${collection}/${id}`);
}

export function useFirestoreData<T>(
  props: FirestoreQueryState<T>,
  mapper?: MapperFn<T>
) {
  const { collection } = props;
  const queryResult = useQuery(
    [`firestore-data-${collection}`, props],
    () => getDataFromDB(props, mapper),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 30 * 1000, // Data is fresh for 30 seconds
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return queryResult;
}

async function getDataFromDB<T>(
  state: FirestoreQueryState<T>,
  mapper?: MapperFn<T>
) {
  const {
    clientID,
    collection: collName,
    sorting = [],
    limit: max,
    filters = [],
    currentPage,
    searchKey,
    collectionGroup: collGroup,
  } = state;

  if (!clientID) {
    throw new Error("Client ID is missing");
  }

  try {
    const id_client = lc.getItemLC(lc.LCName.Client)?.id;
    const clientRef = createDocRef("clients", id_client);
    const baseConstraint = where("client", "==", clientRef);
    const filterConstraints: QueryFilterConstraint[] = [];

    const processFilter = (
      filter: Filter<T>
    ): QueryFilterConstraint | QueryFilterConstraint[] | undefined => {
      if (filter?.type) {
        switch (filter.type) {
          case "text":
          case "number":
          case "option":
            return Array.isArray(filter.value) && filter.value.length > 0
              ? where(filter.field.toString(), "in", filter.value)
              : where(filter.field.toString(), "==", filter.value);

          case "array":
            return where(filter.field.toString(), "==", filter.value);

          case "exception":
            return Array.isArray(filter.value)
              ? where(filter.field.toString(), "not-in", filter.value)
              : where(filter.field.toString(), "!=", filter.value);

          case "date-range":
            return [
              where(filter.field.toString(), ">=", filter.value.startDate),
              where(filter.field.toString(), "<=", filter.value.endDate),
            ];

          case "boolean":
            return where(filter.field.toString(), "==", filter.value);

          case "greater":
            return where(filter.field.toString(), ">", filter.value);

          case "less":
            return where(filter.field.toString(), "<", filter.value);

          case "greater-than":
            return where(filter.field.toString(), ">=", filter.value);

          case "less-than":
            return where(filter.field.toString(), "<=", filter.value);

          case "array-contains-any":
            return where(
              filter.field.toString(),
              "array-contains-any",
              filter.value
            );

          case "array-in":
            return filter.field.toString() === "id"
              ? where(documentId(), "in", filter.value)
              : where(filter.field.toString(), "in", filter.value);

          case "or":
            const orFilters = filter.filters
              .map(processFilter)
              .flat()
              .filter(Boolean) as QueryFilterConstraint[];
            if (orFilters.length > 0) {
              return or(...orFilters);
            }
            return undefined;

          case "and":
            const andFilters = filter.filters
              .map(processFilter)
              .flat()
              .filter(Boolean) as QueryFilterConstraint[];
            if (andFilters.length > 0) {
              return and(...andFilters);
            }
            return undefined;

          default:
            return undefined;
        }
      }
      return undefined;
    };

    for (const filter of filters) {
      const constraint = processFilter(filter);
      if (constraint) {
        if (Array.isArray(constraint)) {
          filterConstraints.push(...constraint);
        } else {
          filterConstraints.push(constraint);
        }
      }
    }

    const sortingConstraints: QueryConstraint[] = [];
    const sortingFields: string[] = [];
    sorting.forEach((item: any) => {
      if (
        item.field &&
        item.direction &&
        !sortingFields.includes(item.field.toString())
      ) {
        sortingFields.push(item.field.toString());
        sortingConstraints.push(
          orderBy(item.field.toString(), item.direction.toString())
        );
      } else if (item.field && !sortingFields.includes(item.field.toString())) {
        sortingFields.push(item.field.toString());
        sortingConstraints.push(orderBy(item.field.toString()));
      }
    });

    const queryConstraints = and(baseConstraint, ...filterConstraints) as any;

    const countQuery =
      collGroup === true
        ? query(collectionGroup(newDB, collName), queryConstraints)
        : query(collection(newDB, collName), queryConstraints);
    const countSnapshot = await getCountFromServer(countQuery);
    const count = countSnapshot.data().count;

    const dataConstraints: QueryConstraint[] = [
      queryConstraints,
      ...sortingConstraints,
    ];
    if (currentPage > 1) {
      const tempConstraints: QueryConstraint[] = [
        queryConstraints,
        ...sortingConstraints,
      ];
      tempConstraints.push(limit((currentPage - 1) * max));
      const tempDocs =
        collGroup === true
          ? (
              await getDocs(
                query(collectionGroup(newDB, collName), ...tempConstraints)
              )
            ).docs
          : (
              await getDocs(
                query(collection(newDB, collName), ...tempConstraints)
              )
            ).docs;
      if (tempDocs.length > 0) {
        dataConstraints.push(startAfter(tempDocs[tempDocs.length - 1]));
      }
    }
    dataConstraints.push(limit(max));

    const dataQuery =
      collGroup === true
        ? query(collectionGroup(newDB, collName), ...dataConstraints)
        : query(collection(newDB, collName), ...dataConstraints);
    const dataSnapshot = await getDocs(dataQuery);

    let data: T[] = [];
    if (mapper) {
      const tempData = await mapper(
        dataSnapshot.docs as QueryDocumentSnapshot<T, DocumentData>[]
      );
      await Promise.all(
        tempData.map(async (x: any) => {
          await Promise.all(
            dataSnapshot.docs.map(async (item: any) => {
              if (collGroup === true && item.id === x.id) {
                let data_parent = await db
                  .collection(item?.ref?.parent?.parent?.parent?.id)
                  .doc(item?.ref?.parent?.parent?.id)
                  .get();
                x["parent_id"] = item?.ref?.parent?.parent?.id;
                x["parent_document"] = data_parent.data();
              }
            })
          );
        })
      );
      data = [...tempData];
    } else {
      const getFromDb = async (collection: any, doc: any) => {
        const res = await db.collection(collection).doc(doc).get();
        return res.data();
      };
      data = await Promise.all(
        dataSnapshot.docs.map(async (doc: any) => {
          if (collGroup === true) {
            const data_parent = await getFromDb(
              doc?.ref?.parent?.parent?.parent?.id,
              doc?.ref?.parent?.parent?.id
            );
            return Object.assign(
              {
                id: doc.id,
                parent_id: doc?.ref?.parent?.parent?.id,
                parent_document: data_parent,
              },
              doc.data() as T
            );
          } else {
            return Object.assign(
              {
                id: doc.id,
              },
              doc.data() as T
            );
          }
        })
      );
    }
    return { allCount: count, items: data };
  } catch (error) {
    console.error("Error fetching data from Firestore:", error);
    throw error;
  }
}

export default useFirestoreData;
