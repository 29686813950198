import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../setup/theme/useTheme";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../styles/components/TextInput";
import Button from "../../../../styles/components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Avatar from "../../../../styles/components/Avatar";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  formatSize,
  productMediaCheckFile,
  uploadFile,
} from "../../util/Utils";
import { useMediaQuery } from "react-responsive";
import { getImageSize } from "react-image-size";
import * as apiRegistration from "../../../../api/server/registration";
import { RootState } from "../../../../setup/redux/store";
import * as RegisterRedux from "../redux/RegisterSlice";
import { IProductMediaUpload } from "../../../../styles/components/ButtonUploadMediaRefV3";
import { Timestamp, getUserByID, updateUser } from "../../../../db";
import * as lc from "../../../../app/modules/localstorage";
import User from "../../../models/User";

export const ClientInfo = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const currentTheme = useTheme();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const [urlLogo, setUrlLogo] = useState("/media/icons/avatar/client-icon.png");
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [invalidSize, setInvalidSize] = useState(false);
  const [duplicateClientName, setDuplicateClientName] = useState(false);
  const [listBillingType, setListBillingTypes] = useState<[]>([]);
  const [selectedBillingType, setSelectedBillingType] = useState<any>();
  const registerState = useSelector((state: RootState) => state.Registration);
  const [clientLogoData, setClientLogoData] = useState<IProductMediaUpload>();

  const uploadToFirestorage = async (
    file: any,
    filetype: string,
    fileName: string,
    path: string
  ) => {
    const inputcontentType = filetype;
    const inputFileName = fileName;
    return await uploadFile(file, inputcontentType, inputFileName, path);
  };

  const clientInfoSchema = Yup.object().shape({
    clientName: Yup.string().required("Register.Error.ClientName"),
    companyName: Yup.string().required("Register.Error.CompanyName"),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "API.Setting.Push.Callback.URL.Message.Invalid"
    ),
  });

  const initialValues = {
    clientName: "",
    companyName: "",
    website: "",
    billingType: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: clientInfoSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(async () => {
        console.log("Start Validate");
        await apiRegistration.clientInfoValidation(
          values.clientName,
          async (responseCode: number, response: any) => {
            console.log(JSON.stringify(response));
            if (!responseCode || response === undefined) {
              setLoading(false);
              return;
            }
            if (!response.success) {
              if (response.message) {
                alert(response.message);
              }
              const findClientNameFieldError = response?.errorField.find(
                (field: string) => field === "clientName"
              );
              console.log(`check client ${findClientNameFieldError}`);
              if (findClientNameFieldError) {
                setDuplicateClientName(true);
                setLoading(false);
              }
              return;
            }
            //upload image
            let uploadLogoURL = "" as any;
            if (urlLogo !== "/media/icons/avatar/client-icon.png") {
              let storagePath = `client/${values.clientName.replace(" ", "")}`;
              let downloadUrl = await uploadToFirestorage(
                clientLogoData?.url,
                String(clientLogoData?.type),
                `profile_picture_${values.clientName.replace(" ", "")}`,
                storagePath
              );
              uploadLogoURL = downloadUrl;
            }
            setLoading(false);
            dispatch(RegisterRedux.setClientName(values.clientName));
            dispatch(RegisterRedux.setClientLogo(uploadLogoURL));
            dispatch(RegisterRedux.setClientCompanyName(values.companyName));
            dispatch(RegisterRedux.setClientWebsite(values.website));
            dispatch(
              RegisterRedux.setClientBillingType(selectedBillingType?.value)
            );
            nav("/auth/client-address");
          }
        );
      }, 1000);
    },
  });

  useEffect(() => {
    const SetUid = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      let getUid = urlParams.get("uid") || registerState.uid
      if (!getUid || getUid === "") {
        nav("/auth");
        return;
      }
      //check user and expired
      const getUser = await getUserByID(String(getUid));
      console.log(`getUser data : `, getUser);
      //get user if login, return to auth
      if (
        lc.getItemLC(lc.LCName.UserID) &&
        lc.getItemLC(lc.LCName.UserID) !== "" &&
        getUser?.id !== lc.getItemLC(lc.LCName.UserID)
      ) {
        console.log(`Already Login, back to dashboard`);
        nav("/auth");
        return;
      }
      if (
        !getUser ||
        (getUser &&
          getUser.verifyExpiredAt &&
          getUser.verifyExpiredAt < Timestamp.now())
      ) {
        //back to auth
        console.log(
          `getUser ${getUid} : ${getUser?.id} - expired ${getUser?.verifyExpiredAt}`
        );
        nav("/auth");
        return;
      }
      //set verify at
      if (!getUser.verifyAt)
        await updateUser({ ...getUser, verifyAt: Timestamp.now() } as User);
      dispatch(RegisterRedux.setUserID(String(getUid)));
    };
    SetUid();
  }, []);

  const customStyles = {
    option: (provided: any, state: any) => {
      console.log(state, "isi state test");
      return {
        ...provided,
        color:
          state.isFocused || state.data?.label === selectedBillingType?.label
            ? state.data?.disable === true
              ? "gray"
              : "white"
            : state.data?.disable === true
            ? "gray"
            : "#1e2022",
        backgroundColor:
          state.isFocused || state.data?.label === selectedBillingType?.label
            ? "#0275d8"
            : state.data?.disable === true
            ? "lightgrey"
            : "white",
        padding: ".5rem 3rem .5rem .5rem",
        cursor: "pointer",
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "white", // Background color when the menu is open
      color: "#1e2022",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      color: "#1e2022", // Color of the selected value
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5f8fa",
      border: "none",
      outline: "none",
    }),
  };

  const billingType = [
    {
      id: 0,
      label: `${t("Register.ClientInformation.BillingType.Prepaid")}`,
      value: `prepaid`,
    },
    {
      id: 1,
      label: `${t("Register.ClientInformation.BillingType.Postpaid")}`,
      value: `postpaid`,
      disable: true,
    },
  ] as any;

  useEffect(() => {
    setListBillingTypes(billingType);
    setSelectedBillingType(billingType[0]);
  }, []);

  const handleClientLogo = async (event: any) => {
    console.log(`Target files: ${event?.target?.files}`);
    if (event.target.files && event.target.files[0]) {
      const element = event.target.files[0] as File;
      console.log(
        "Size: " +
          element?.size +
          " - Name : " +
          element?.name +
          " - type : " +
          element?.type
      );
      if (element) {
        //Check File Type
        const size: number = element.size;
        const tempArrFileType: string = element.type;
        const arryFileType = tempArrFileType.split("/");
        const fileName = element.name;
        if (
          arryFileType === undefined ||
          arryFileType.length < 1 ||
          arryFileType[0] === undefined
        ) {
          console.log("Client Logo - Error File Invalid Format");
          setInvalidFormat(true);
          return;
        }

        const URLObj = URL.createObjectURL(element);
        let photosWidth: number = 0;
        let photosHeight: number = 0;
        const { width, height } = await getImageSize(URLObj);
        photosWidth = width;
        photosHeight = height;
        const [result, error, maxSize] = productMediaCheckFile(
          arryFileType[0],
          arryFileType[1],
          size,
          photosWidth,
          photosHeight
        );

        if (error !== undefined) {
          if (maxSize !== 0) {
            console.log(
              "Client Logo - Error Process Validate Maxsize: " + maxSize
            );
            setInvalidSize(true);
          } else {
            console.log("Client Logo -  Process Validate File : " + error);
            setInvalidFormat(true);
          }
          return;
        }
        if (!result || result === "") {
          console.log("Client Logo - Error File Invalid Format");
          setInvalidFormat(true);
          return;
        }

        let newFile = {
          url: URLObj,
          size: formatSize(size),
          type: tempArrFileType,
          extension: arryFileType[1],
          name: fileName,
        };
        console.log(`URLObj ${URLObj}`);
        console.log(newFile, "newfile");
        setUrlLogo(URLObj);
        setInvalidFormat(false);
        setInvalidSize(false);
        setClientLogoData(newFile);
      }
    }
  };

  const logoRef: any = useRef(null);

  return (
    <form
      className="form w-100"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
      id="kt_login_signin_form"
      data-testid="form-cinfo"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10" data-testid="form-cinfo-title">
        <h1 className="text-dark mb-3">
          {t("Register.ClientInformation.Title")}
        </h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger" data-testid="errorLogin">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div
        className="fv-column mb-5"
        data-testid="form-cinfo-input-clientlogos"
      >
        <label
          htmlFor="client-logo-input"
          className="form-label d-flex flex-row fs-6 fw-bolder text-dark"
        >
          {t("Register.ClientInformation.ClientLogo")}
        </label>
        <div className="d-flex flex-row fw-bolder text-dark">
          <div className="form-label d-flex flex-row fs-6 fw-bolder text-dark position-relative align-items-center justify-content-center">
            <Avatar
              id="client-logo"
              data-testid="client-logo"
              height="125"
              width="125"
              imgRadius="50%"
              imgSrc={urlLogo}
              onClick={() => {
                if (logoRef) logoRef.current.click();
              }}
            />
            <input
              ref={logoRef}
              accept={imageExtention}
              id="client-logo-input"
              src={urlLogo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleClientLogo(e);
              }}
              type="file"
              name="attachment"
              style={{
                display: "none",
                borderRadius: "20%",
                cursor: "pointer",
              }}
            />
            <div
              className="btn-group bg-primary rounded d-flex flex-row justify-content-center"
              style={{
                width: "70px",
                height: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-evenly",
                position: "absolute",
                bottom: -20,
                cursor: "pointer",
              }}
            >
              <div
                id="btn-input-logo"
                className="d-flex w-50 h-100 align-items-center justify-content-center border-end border-light border-1"
                onClick={() => {
                  if (logoRef) logoRef.current.click();
                }}
              >
                <i className="bi bi-pencil-fill text-white"></i>
              </div>
              <div
                id="delete-logo"
                className="border-0 z-3 d-flex w-50 h-100 align-items-center justify-content-center"
                onClick={(e) => {
                  // e.preventDefault();
                  if (urlLogo !== "/media/icons/avatar/client-icon.png") {
                    setUrlLogo("/media/icons/avatar/client-icon.png");
                  }
                }}
              >
                <i className="bi bi-trash text-white fs-4"></i>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center m-5 fw-bolder text-dark">
            <div>&bull; {t("Register.ClientInformation.ImageFormat")}</div>
            <div>&bull; {t("Register.ClientInformation.Ratio")}</div>
            <div>&bull; {t("Register.ClientInformation.MaxFile")}</div>
          </div>
        </div>
        <div className="mt-5">
          {invalidFormat && (
            <div
              data-testid="logoerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">{t("IC.Error.InvalidFormat")}</span>
            </div>
          )}
          {invalidSize && (
            <div
              data-testid="logoerror"
              className="fv-plugins-message-container text-danger"
            >
              <span role="alert">
                {t("Register.ClientInformation.ClientLogo.FileSizeExceeds")}
              </span>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10" data-testid="form-cinfo-input-clientname">
        <label className="required form-label fs-6 fw-bolder text-dark">
          {t("Register.ClientInformation.ClientName")}
        </label>
        <TextInput
          data-testid="clientName"
          placeholder=""
          {...formik.getFieldProps("clientName")}
          formcontrol={"solid"}
          name="clientName"
          autoComplete="off"
          id="login-clientName"
          onInput={(e: any) => {
            e.preventDefault();
            if (duplicateClientName) setDuplicateClientName(false);
          }}
        />
        {duplicateClientName === true && (
          <div
            data-testid="clientNameDuplicate"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">
              {t("Register.ClientInformation.ClientName.AlreadyRegistered")}
            </span>
          </div>
        )}
        {formik.touched.clientName && formik.errors.clientName && (
          <div
            data-testid="clientNameerror"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">{t(`${formik.errors.clientName}`)}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10" data-testid="form-cinfo-input-companyname">
        <label className="required form-label fs-6 fw-bolder text-dark">
          {t("Register.ClientInformation.CompanyName")}
        </label>
        <TextInput
          data-testid="companyName"
          placeholder=""
          {...formik.getFieldProps("companyName")}
          formcontrol={"solid"}
          name="companyName"
          autoComplete="off"
          id="login-companyName"
        />
        {formik.touched.companyName && formik.errors.companyName && (
          <div
            data-testid="companyNameerror"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">{t(`${formik.errors.companyName}`)}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10" data-testid="form-cinfo-input-website">
        <label className="form-label fs-6 fw-bolder text-dark">
          {t("Website")}
        </label>
        <TextInput
          data-testid="website"
          placeholder=""
          {...formik.getFieldProps("website")}
          formcontrol={"solid"}
          name="website"
          autoComplete="off"
          id="login-website"
        />
        {formik.touched.website && formik.errors.website && (
          <div
            data-testid="websiteerror"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">{t(`${formik.errors.website}`)}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10" data-testid="form-cinfo-input-billingtype">
        <label className="form-label fs-6 fw-bolder text-dark">
          {t("Register.ClientInformation.BillingType")}
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip {...props} id={"1"} className="fs-5">
                {t("Register.ClientInformation.BillingType.Information")}
              </Tooltip>
            )}
          >
            <i className="bi bi-info-circle-fill text-muted fs-3 m-3"></i>
          </OverlayTrigger>
        </label>
        <Select
          defaultValue={selectedBillingType}
          options={listBillingType.length > 0 ? listBillingType : []}
          className="basic-select"
          classNamePrefix="select"
          id="billing-type"
          name="billing-type"
          styles={customStyles}
          value={selectedBillingType}
          isDisabled={true}
        />

        {selectedBillingType == null && (
          // formik.touched.billingType && formik.errors.billingType
          <div
            data-testid="billingTypeerror"
            className="fv-plugins-message-container text-danger"
          >
            <span role="alert">{t(`${formik.errors.billingType}`)}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <Button
          data-testid="button-cinfo"
          id="cinfo-button"
          btnlg="primary"
          type="submit"
          cName="w-100 mb-5"
        >
          {!loading && (
            <span className="indicator-label">{t("Contacts.Pages.Next")}</span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {t("Login.Button.Loading")}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </div>
      {/* end::Action */}
    </form>
  );
};
