/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from '../../../../../resources/helpers'
import {getCampaigns} from './_requests'
import {Campaign, CampaignsQueryResponse} from '../../../../models/Campaign'
import {useQueryRequest} from './QueryRequestProvider'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup/redux/store'
import * as CampaignRedux from '../../../../modules/campaign/list/CampaignListSlice'
import { campaignSorting } from './_sort'

const QueryResponseContext = createResponseContext<Campaign>(initialQueryResponse)
const QueryResponseProvider: FC = ({children}) => {
  const {state, updateState} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listCampaigndata:any = [];
  listCampaigndata = useSelector((state: RootState) => state.CampaignList.listCampaign); 
  let CampaignQueryResponse: CampaignsQueryResponse;

  useEffect(() => {
    // console.log("Masuk use effect update query Campaign >>>>>");
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    console.log("Masuk use effect update list Campaign >>>>");
      setCounter(listCampaigndata.length)
  }, [listCampaigndata])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${counter}`,
    async () => {
      console.log("Fetching: "+isFetching);
      console.log("Campaign data on redux lengt : " + listCampaigndata.length);
      let data:any = [];

      //cek redux
      if (!listCampaigndata || listCampaigndata.length <= 0) {
        console.log(`List Campaign from DB`);
        
        data = await getCampaigns(state.sort , state.order, state.search, state.action, state.lastId, state.items_per_page)
        if(data && data.length>0){
          dispatch(CampaignRedux.setListCampaign(data));
        }
        // setCampaignData(listCampaigndata);
      }else{
        console.log(`List Campaign from Redux`);
        data = listCampaigndata;
      } 

      let resultData  = campaignSorting(data , state.sort , state.items_per_page, state.action, state.order, state.search,  state.page )
      
      CampaignQueryResponse = {
          data: resultData,
      };

      return Promise.resolve(CampaignQueryResponse);

    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: true}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }
  
  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
