import Customer from '../../../../../../src/app/models/Customer'

interface InitialState {
  targetList: Customer[];
  targetCount: number;
  selectedTarget: Customer[];
  selectedTargetCount: number;
  previewData: any[],
  previewDataCount: number
  targetData: string,
  columns: any[],

}

const CampaginTargetListAction: string = "CampaginTargetListAction";

export default InitialState;
export { CampaginTargetListAction };
