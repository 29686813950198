import firebase from "firebase/compat/app";

interface Language {
  id?: string;
  language?: string;
  body?: string;
  bodyParamCount?: number;
  status?: string;
  bodyParam?: BodyParamLanguage[];
  footer?: string;
  responseTemplateID?: string;
  responseJSON?: string;
  responseJSONUploadSession?: string;
  responseUploadSessionID?: string;
  responseUploadMediaID?: string;
  responseJSONUploadMedia?: string;
  errorMessage?: string;
  header?: {
    paramCount?: number;
    type: "text" | "media";
    mediaType?: "image" | "video" | "audio" | "document";
    headerURL?: string;
    fileName?: string;
    filename?: string;
    text: string;
    headerParam?: BodyParamLanguage[];
  };
  buttons?: button[];
  countMonthlyUpdate?: number;
  countTodaysUpdates?: number;
  updatedAt?: Date;
  createdAt?: Date;
  accountsData?: accountData[];
  addSecurityRecommendation?: boolean;
  code_expiration_minutes?: number;
  failover?: failover;
}

export default Language;

export interface failover {
  message: string;
  sendWhenUndelivered?: boolean;
  undeliveredTime?: number;
  undeliveredTimeUnit?: string;
  bodyParam: any[];
}

export interface button {
  type: "URL" | "PHONE_NUMBER" | "QUICK_REPLY" | "OTP";
  text: string;
  phoneNumber?: string;
  url?: string;
  index?: number; //start from 0
  urlType?: "STATIC" | "DYNAMIC";
  urlDynamic?: string;
  dynamicValue?: string;
  otpType?: "COPY_CODE" | "ONE_TAP" | "ZERO_TAP";
  signaturehash?: string;
  termsAccepted?: boolean;
  packageName?: string;
}

export interface BodyParamLanguage {
  paramId: number;
  paramValue?: string;
  paramKey?: string;
  paramType?: string;
  textContent?: string;
  paramName?: string;
}

export interface HeaderParamLanguage extends BodyParamLanguage {}

export const templateButtonType = {
  url: "URL",
  phoneNumber: "PHONE_NUMBER",
  quickReply: "QUICK_REPLY",
};

export const templateStatus = {
  approved: "APPROVED",
  in_appeal: "IN_APPEAL",
  pending: "PENDING",
  rejected: "REJECTED",
  disable_update: "DISABLED",
  paused: "PAUSED",
  flagged: "FLAGGED",
  reinstated: "REINSTATED",
};

export interface accountData {
  id?: string;
  account?: firebase.firestore.DocumentReference;
  type?: string;
  responseTemplateID?: string;
  responseJSON?: string;
  status?: string;
  errorMessage?: string;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
}

export const accountType = {
  whatsapp: "whatsapp",
  shopee: "shopee",
};
