import React from "react"
import * as action from "../../../actions/playground";
import Button from "../../../styles/components/Button";

const UserTab:React.FC = () => {


  const [email, setEmail] = React.useState<string>("");
  const [pass, setPass] = React.useState<string>("");
  const [uid, setUid] = React.useState<string>("");


  const createUserData = () => {
    action.createUserData(email, pass, uid);
  };

    return (<div>

        <div>
            <input
                name="uid"
                type="input"
                placeholder="uid"
                onChange={(e) => {
                    console.log(e.target.value);
                    setUid(e.target.value);
                }}
                value={uid}
                />

        </div>

        <div>
            <input
            name="email"
            type="input"
            placeholder="email"
            onChange={(e) => {
                console.log(e.target.value);
                setEmail(e.target.value);
            }}
            value={email}
            />
        </div>

        <div>
            <input
            name="password"
            type="password"
            placeholder="password"
            onChange={(e) => {
                console.log(e.target.value);
                setPass(e.target.value);
            }}
            value={pass}
            />

        </div>

                  
        <div>
            <Button
            className="btn btn-lg btn-primary fw-bolder"
            onClick={createUserData}
            >
            create User
            </Button>
        </div>

    </div>)
}

export default UserTab