import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { KTCard } from "../../../../../resources/helpers";
import { deleteAllNotifications } from "../../../../../db/serviceUser";
import * as lc from "../../../../modules/localstorage/index";

type HeaderNotificationMenuModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onDeleteComplete?: () => void;
};

export const HeaderNotificationMenuModal: FC<HeaderNotificationMenuModalProps> = ({
  isOpen,
  onClose,
  onDeleteComplete,
}) => {
  const { t } = useTranslation();
  const handleDeleteNotifications = async () => {
    const userID = lc.getItemLC(lc.LCName.UserID);

    try {
      await deleteAllNotifications(userID); //trigger query di serviceUser
      onDeleteComplete && onDeleteComplete(); //ngirim ke HeadernotificationMenu
      onClose && onClose(); //tutup modal ini
      console.log("All notifications for the user have been deleted.");
    } catch (error) {
      console.error("Failed to delete notifications:", error);
    }
  };

  return (
    <>
    {/* {isOpen && ( */}
      <Modal
        animation
        centered
        show={isOpen}
        size="sm"
        scrollable={false}
        onHide={() => onClose && onClose()}
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title data-testid="modal-title">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div>
            <h3>{t("Notif.Button.Assure")}</h3>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <button
            className="btn btn-danger w-25"
            onClick={() => handleDeleteNotifications()} 
          >
            {t("Notif.Button.Yes")}
          </button>
          <button
            className="btn btn-primary w-25 text-center"
            onClick={() => onClose && onClose()}
          >
            {t("Notif.Button.No")}
          </button>
        </Modal.Footer>
      </Modal>
    {/* )} */}
    </>
  );
};

export default HeaderNotificationMenuModal;
