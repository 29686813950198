import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ColumnInstance, Row, useTable } from "react-table";
import Loading from "../../../../../../resources/helpers/components/Loading";
import { RootState } from "../../../../../../setup/redux/store";
import CardItem from "../../../../../components/table/list/cardList/CardItem";

import { CampaignPagination } from "../components/pagination/CampaignPagination";
import { useQueryRequest } from "../core/QueryRequestProvider";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { campaignMessageSorting } from "../core/_sort";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { campaignDetailsColumns } from "./columns/_columns";
import {Response} from '../../../../../../resources/helpers';
import {CampaignMessage} from '../../../../../models/Campaign';


export type CampaignsQueryResponse = Response<Array<CampaignMessage>>

const CampaignDetailsDesktop = () => {
  const { t } = useTranslation();
  const campaignMessage = useSelector(
    (state: RootState) => state.CampaignMessage.listCampaignMessage
  );
  const totalToShowInPage = useSelector(
    (state: RootState) => state.CampaignMessage.currentPageCampaignMessage
  );
  const messages = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => messages, [messages]);
  const data = useMemo(() => {
    return campaignMessage;
  }, [campaignMessage]);
  // const [data, setData] = useState(messages);
  console.log(`CHECK data `, data);
  console.log(`message ${messages.length}`);
  console.log(`campaignMessage ${campaignMessage.length}`);
  const columns = useMemo(() => campaignDetailsColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  // const queryClient = useQueryClient();
  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   console.log(`on change data cause ReduxCampaignReport`);
  // setData(messages);
  // queryClient.refetchQueries();
  // let resultData  =  campaignMessageSorting(campaignMessage , state.sort , state.items_per_page, state.action, state.order, state.search,  state.page )
  // setData(resultData);
  // const CampaignQueryResponse: CampaignsQueryResponse = {
  //   data: resultData,
  // };
  // const a:any = Promise.resolve(CampaignQueryResponse);
  // setData(a);
  //   setRefresh(true);
  // }, [campaignMessage]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);

  return (
    <>
      {refresh && <Loading />}
      {!refresh && (
        <div className="card-body py-0 overflow-hidden">
          <div
            className="table-responsive"
            // style={{
            //   height: "75%",
            //   overflowY: "scroll",
            // }}
          >
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              {...getTableProps()}
            >
              <thead className="position-sticky top-0 bg-white">
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
                  {headers.map((column: ColumnInstance<CampaignMessage>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {rows.length > 0
                  ? rows.map((row: Row<CampaignMessage>, i) => {
                      prepareRow(row);
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                    })
                  : !isLoading && (
                      <tr>
                        <td colSpan={7}>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            {t("Contacts.Info.Empty")}
                          </div>
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
          <CampaignPagination />
          {isLoading && <Loading />}
        </div>
      )}
    </>
  );
};

const CampaignDetailsMobile = () => {
  const campaignMessage = useSelector(
    (state: RootState) => state.CampaignMessage.listCampaignMessage
  );
  const messages = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data: any = useMemo(() => campaignMessage, [campaignMessage]);
  // const [data, setData] = useState(messages);
  const [refresh, setRefresh] = useState(false);
  // const queryClient = useQueryClient();

  // useEffect(() => {
  //   console.log(`on change data cause ReduxCampaignReport`);
  // setData(campaignMessage);
  // queryClient.refetchQueries();
  //   setRefresh(true);
  // }, [campaignMessage]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [refresh === true]);
  return (
    <>
      {refresh && <Loading />}
      {!refresh && (
        <>
          <div className="mb-5 mx-5 pb-5">
            {data.map((contact: any, index: number) => {
              console.log(`data : ${data}`);
              return (
                <Fragment key={contact.id?.toString()}>
                  <div className="mb-5">
                    <CardItem
                      id={contact.id}
                      name={contact?.customerName ? contact?.customerName : "-"}
                      contactData={contact}
                      isCampaignReportList={true}
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
          <CampaignPagination />
          {isLoading && <Loading />}
        </>
      )}
    </>
  );
};

const CampaignDetails = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? (
    <CampaignDetailsMobile />
  ) : (
    <CampaignDetailsDesktop />
  );
};

export { CampaignDetails };
