import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageSize } from "react-image-size";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup/redux/store";
import Button from "../../../../../../../styles/components/Button";
import ButtonUploadMedia from "../../../../../../../styles/components/ButtonUploadMediav2";
import UploadImageThumbnail from "../../../../../../../styles/components/UploadImageThumbnail";
import ModalDetailVariant from "../../../../../../modules/modal/modalDetailVariant";
import * as ReduxProduct from "../../../../../../modules/product/redux/ProductSlice";
import {
  productMediaCheckFile,
  productMediaUpload,
  formatSize,
} from "../../../../../../modules/util/Utils";

interface VariantListMobileProps {
  onClickNext?: boolean;
  setIsHasError?: any;
}

const VariantListMobile: FC<VariantListMobileProps> = ({
  onClickNext,
  setIsHasError,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState(false);
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png
  const [modalData, setModalData] = useState<any>();
  const [isSKUError, setIsSKUError] = useState(false);
  const [isWeightError, setIsWeightError] = useState(false);
  const [onClickConfirm, setOnClickConfirm] = useState(false);
  const rd_Variants = useSelector((state: RootState) => state.Product.variants);
  const rd_VariantMatrix: any = useSelector(
    (state: RootState) => state.Product.variantsMatrix
  );
  const rd_VariantPhotos: any = useSelector(
    (state: RootState) => state.Product.productVariantPhotos
  );
  const [photoURL, setPhotoURL] = useState("");
  const [photoValidation, setPhotoValidation] = useState<any>({});

  // const INVALID_PHOTO_EMPTY = t("AddNewProduct.VariantList.Alert.Photo");
  // const INVALID_RESOLUTION = t("AddNewProduct.VariantList.Alert.PhotoInvalidRes");
  const INVALID_PHOTO_SIZE = t(
    "AddNewProduct.SubTitle.PhotoFileRequirements.FileSize"
  );
  const INVALID_PHOTO_FORMAT = t("AddNewProduct.Alert.Photo.Invalidformatfile");

  const updateObject = (obj: object, key: string, value: any) => {
    const currentObj: any = { ...obj };
    if (currentObj.hasOwnProperty(key)) {
      currentObj[key] = value;
      return currentObj;
    } else {
      return { ...obj, [key]: value };
    }
  };

  const clearObject = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
      delete obj[key];
    });
  };

  const uploadProductMedia = async (
    event: any,
    uploadType: string,
    optionListIndex: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      if (uploadType === productMediaUpload.image && photoValidation) {
        setPhotoValidation(clearObject(photoValidation));
      }
      // const variant = rd_Variants;
      // const optionListLength: any =
      //   variant[0] && variant[0].optionList ? variant[0].optionList.length : 0;
      // let productVariantPhotoLength = rd_VariantPhotos.length;

      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        // productVariantPhotoLength++;
        if (element) {
          const size: number = element.size;
          const tempArrFileType: string = element.type;
          const arryFileType = tempArrFileType.split("/");
          const fileName = element.name;

          if (
            arryFileType === undefined ||
            arryFileType.length < 1 ||
            arryFileType[0] === undefined
          ) {
            setPhotoValidation(
              updateObject(
                photoValidation,
                `photo-${optionListIndex}`,
                INVALID_PHOTO_FORMAT
              )
            );
            //return error
            console.log("Media - Error File Invalid Format");
            return;
          } else {
            const URLObj = URL.createObjectURL(element);
            let photosWidth: number = 0;
            let photosHeight: number = 0;
            if (uploadType === productMediaUpload.image) {
              const { width, height } = await getImageSize(URLObj);
              photosWidth = width;
              photosHeight = height;
            }
            const [result, error, maxSize] = productMediaCheckFile(
              arryFileType[0],
              arryFileType[1],
              size,
              photosWidth,
              photosHeight
            );

            if (error !== undefined) {
              if (maxSize !== 0) {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photo-${optionListIndex}`,
                      INVALID_PHOTO_SIZE
                    )
                  );
                }
                return;
              } else {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photo-${optionListIndex}`,
                      INVALID_PHOTO_FORMAT
                    )
                  );
                }
              }
              return;
            }

            if (!result || result === "") {
              if (result === productMediaUpload.image) {
                setPhotoValidation(
                  updateObject(
                    photoValidation,
                    `photo-${optionListIndex}`,
                    INVALID_PHOTO_FORMAT
                  )
                );
              }
              return;
            } else {
              let newFile = {
                index: optionListIndex,
                url: URLObj,
                size: formatSize(size),
                type: tempArrFileType,
                extension: arryFileType[1],
                name: fileName,
              };
              if (result === productMediaUpload.image) {
                let newVariantPhotos: any = [];
                newVariantPhotos = [...rd_VariantPhotos, newFile];
                setPhotoValidation(
                  updateObject(photoValidation, `photo-${optionListIndex}`, "")
                );
                dispatch(
                  ReduxProduct.setProductVariantPhotos(newVariantPhotos)
                );
              } else {
                if (result === productMediaUpload.image) {
                  setPhotoValidation(
                    updateObject(
                      photoValidation,
                      `photo-${optionListIndex}`,
                      INVALID_PHOTO_FORMAT
                    )
                  );
                }
              }
            }
          }
        }
      }
    }
  };

  const variantDetail = (variantData: any) => {
    const newData = {
      tierIndex: variantData.tierIndex,
      variant1: variantData.variant1,
      variant2: variantData.variant2 ? variantData.variant2 : "",
      sku: variantData.sku,
      weight: variantData.weight,
      isActive: variantData.isActive,
      isMainVariant: variantData.isMainVariant,
    };
    return (
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-row align-items-center">
          <div className="w-25">
            <span>SKU</span>
            <span className="text-danger">*</span>
          </div>
          <div className="d-flex align-items-center form-control border-2 px-0 py-0 w-75">
            <input
              type="string"
              id="bpi-input-variant-sku"
              name="bpi-input-variant-sku"
              className="form-control border-0"
              defaultValue={variantData.sku}
              onChange={(e) => {
                setOnClickConfirm(false);
                newData.sku = e.target.value.trim();
                setModalData(newData);
              }}
            />
          </div>
        </div>
        <div className="d-flex w-100">
          <div className="w-25"></div>
          <div className="d-flex w-75" style={{ height: "20px" }}>
            {isSKUError && onClickConfirm && (
              <span className="text-danger">
                {t("AddNewProduct.VariantList.Alert.SKU")}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="w-25">
            <span>{t("AddNewProduct.Column.Weight")}</span>
            <span className="text-danger">*</span>
          </div>
          <div className="d-flex align-items-center form-control border-2 px-0 py-0 w-75">
            <input
              type="number"
              min="1"
              max="999"
              id="bpi-input-variant-weight"
              name="bpi-input-variant-weight"
              className="form-control border-0"
              onChange={(e) => {
                setOnClickConfirm(false);
                newData.weight = e.target.value.replace(/^0+/, "");
                setModalData(newData);
              }}
              defaultValue={variantData.weight}
            />
            <p
              className="border-0 bg-white my-0"
              data-testid="ProductWeight_PackageWeightInputUnit"
              style={{
                paddingRight: "10px",
                color: "gray",
                textAlign: "center",
              }}
            >
              gram
            </p>
          </div>
        </div>
        <div className="d-flex w-100">
          <div className="w-25"></div>
          <div className="d-flex w-75" style={{ height: "20px" }}>
            {isWeightError && onClickConfirm && (
              <span className="text-danger">
                {t("AddNewProduct.VariantList.Alert.Weight")}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-row my-3">
          <div className="w-25">
            <span>{t("AddNewProduct.Column.MainVariant")}</span>
          </div>
          <input
            type="radio"
            checked={newData.isMainVariant === true}
            onChange={(e) => {
              newData.isMainVariant = e.target.checked;
              setModalData(newData);
            }}
          />
        </div>
        <div className="d-flex flex-row my-3">
          <div className="w-25">
            <span>{t("AddNewProduct.Column.Status")}</span>
          </div>
          <div className="d-flex justify-content-center form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              disabled={newData.isMainVariant === true}
              checked={
                newData.isMainVariant === true
                  ? true
                  : newData.isActive === true
                  ? true
                  : false
              }
              onChange={(e) => {
                newData.isActive = e.target.checked;
                setModalData(newData);
              }}
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
      </div>
    );
  };

  const handleOnConfirm = (newModalData: any) => {
    console.log("newModalData : ", newModalData);
    setOnClickConfirm(true);
    let isHaveError = false;
    if (newModalData.sku === "" || !newModalData.sku) {
      setIsSKUError(true);
      isHaveError = true;
    } else {
      setIsSKUError(false);
    }
    if (newModalData.weight < 1) {
      setIsWeightError(true);
      isHaveError = true;
    } else {
      setIsWeightError(false);
    }
    if (isHaveError) return;
    const variantMatrixData = [...rd_VariantMatrix];
    const newMatrix = variantMatrixData.map((matrix) => {
      if (matrix.tierIndex === newModalData.tierIndex) {
        const newData = {
          tierIndex: matrix.tierIndex,
          variant1: matrix.variant1,
          variant2: matrix.variant2,
          sku: newModalData.sku.trim(),
          weight: newModalData.weight,
          isActive: newModalData.isActive,
          isMainVariant: newModalData.isMainVariant,
        };
        return newData;
      } else {
        if (newModalData.isMainVariant === true) {
          const newMatrix = {
            tierIndex: matrix?.tierIndex,
            variant1: matrix?.variant1,
            variant2: matrix?.variant2,
            sku: matrix?.sku,
            weight: matrix?.weight,
            isActive: matrix.isActive ? matrix.isActive : false,
            isMainVariant: false,
          };
          return newMatrix;
        }
      }
      return matrix;
    });
    dispatch(ReduxProduct.setVariantMatrix(newMatrix));
    setIsShowModal(false);
  };

  const removeProductVariantPhotos = (photoIndex: any) => {
    let newProductVariantPhotos: any[] = [];
    for (let index = 0; index < rd_VariantPhotos.length; index++) {
      const photo = rd_VariantPhotos[index];
      console.log("VAR photo", photo);
      if (photoIndex !== photo.index) {
        newProductVariantPhotos.push(photo);
      }
    }
    console.log("Hasil Redux Variant Photos : " + newProductVariantPhotos);
    dispatch(ReduxProduct.setProductVariantPhotos(newProductVariantPhotos));
  };

  const arrayHasPhotos = rd_VariantPhotos?.map((item: any) => item.index) || [];
  const [photoErrors, setPhotoErrors] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const validateVariantsData = () => {
    const newErrors: any = {};

    for (const variant of rd_VariantMatrix) {
      console.log("VAR Weight", variant.weight);
      // weight
      if (variant.weight <= 0) {
        newErrors[`weight-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.Weight"
        );
      } else if (variant.weight == null) {
        newErrors[`weight-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.Weight"
        );
      }
      // sku
      if (variant.sku === "" || variant.sku === undefined) {
        newErrors[`sku-${variant.tierIndex}`] = t(
          "AddNewProduct.VariantList.Alert.SKU"
        );
      }
    }

    const variants = rd_Variants;
    const getVariantOptionList: any = variants[0].optionList;
    const sortData = [...rd_VariantPhotos];
    const sortVariantPhoto = sortData.sort((a: any, b: any) =>
      a.index > b.index! ? 1 : -1
    );
    if (sortVariantPhoto !== getVariantOptionList.length) {
      for (let index = 0; index < getVariantOptionList.length; index++) {
        const photo = sortVariantPhoto.find((photo) => photo.index === index);
        console.log("index : ", photo);
        console.log("check photo by index : ", photo);
        if (!photo || photo === undefined) {
          newErrors[`photo-${[index]}`] = t(
            "AddNewProduct.VariantList.Alert.Photo"
          );
        }
        console.log("photos : ", photo);
      }
    }

    //error sku and weigth show in card, group by tierIndex[0]
    const findErrorSKU = getKeyByValue(
      newErrors,
      t("AddNewProduct.VariantList.Alert.SKU")
    );
    console.log("findErrorSKU : ", findErrorSKU);
    let skuError: string[] = [];
    if (findErrorSKU && findErrorSKU.length > 0) {
      for (let index = 0; index < findErrorSKU.length; index++) {
        const error: string = findErrorSKU[index].toString();
        let newError = error.split(",");
        const errorToPush = newError[0];
        const find = skuError.find((err: any) => err === errorToPush);
        if (!find) skuError.push(errorToPush);
      }
    }
    console.log("skuError : ", skuError);
    if (skuError && skuError.length > 0) {
      for (let index = 0; index < skuError.length; index++) {
        newErrors[`sku-${[index]}`] = t("AddNewProduct.VariantList.Alert.SKU");
      }
    }

    const findErrorWeight = getKeyByValue(
      newErrors,
      t("AddNewProduct.VariantList.Alert.Weight")
    );
    console.log("findErrorSKU : ", findErrorSKU);
    
    if (findErrorWeight && findErrorWeight.length > 0) {
      let weightError: any = [];
      for (let index = 0; index < findErrorWeight.length; index++) {
        const error: string = findErrorWeight[index].toString();
        let newError = error.split(",");
        const errorToPush = newError[0];
        const find = weightError.find((err: any) => err === errorToPush);
        if (!find) weightError.push(errorToPush);
      }

      if (weightError && weightError.length > 0) {
        for (let index = 0; index < weightError.length; index++) {
          newErrors[`weight-${[index]}`] = t(
            "AddNewProduct.VariantList.Alert.Weight"
          );
        }
      }

    }
    

    let mergedErrors = Object.assign({}, newErrors, photoErrors);
    console.log("mergedErrors : ", mergedErrors);
    setErrors(mergedErrors);
    checkError(mergedErrors);
  };

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  function checkIsAllEmpty(obj: any) {
    return Object.values(obj).every((val) => val === "");
  }

  useEffect(() => {
    if (onClickNext) validateVariantsData();
  }, [
    onClickNext,
    rd_VariantMatrix,
    rd_VariantPhotos,
    photoValidation,
    photoErrors,
  ]);

  useEffect(() => {
    console.log("onClickNext :", onClickNext);
    if (onClickNext) {
      validateVariantsData();
    }
    if (rd_VariantPhotos && rd_VariantPhotos.length > 0) {
      const sortData = [...rd_VariantPhotos];
      const sortVariantPhoto = sortData.sort((a: any, b: any) =>
        a.index > b.index! ? 1 : -1
      );
      dispatch(ReduxProduct.setProductVariantPhotos(sortVariantPhoto));
    }
  }, []);

  useEffect(() => {
    // setErrors({ ...errors, ...photoErrors });
    setErrors((errs: any) => ({ ...errs, ...photoErrors }));
  }, [photoErrors]);

  useEffect(() => {
    if (photoValidation && photoValidation !== null) {
      if (photoValidation?.length !== 0) {
        
        setPhotoErrors((errsPhoto:any) => ({ ...errsPhoto, ...photoValidation }));
      }
    }
  }, [photoValidation]);

  const checkError = (errors: any) => {
    setIsHasError(Object.entries(errors).length > 0 && !checkIsAllEmpty(errors));
  };

  return (
    <div>
      <div className="mt-6 w-100">
        {isShowModal && modalData !== undefined && (
          <ModalDetailVariant
            id={`variant-${modalData.tierIndex[0]}-${modalData.tierIndex[1]}`}
            isModalOpen={isShowModal}
            title={modalData.variant1}
            subtitle={modalData.variant2}
            body={variantDetail(modalData)}
            cancelOption={t("AddNewProduct.Button.BPI.Cancel")}
            confirmOption={t("AddNewProduct.Modal.AddShipment.Button.Confirm")}
            onConfirm={() => handleOnConfirm(modalData)}
            onCancel={() => setIsShowModal(false)}
            image={photoURL ? photoURL : ""}
          />
        )}
        {rd_Variants &&
          rd_Variants[0]?.optionList &&
          rd_Variants[0].optionList?.map((opt, optIndex) => {
            return (
              <div
                className="bg-primary rounded p-5 bg-opacity-20 w-100 mt-5 h-auto"
                style={{ minHeight: "150px" }}
              >
                <div className="d-flex flex-row w-100 gap-5">
                  <div
                    className="position-relative align-items-start"
                    style={{ width: "100px" }}
                  >
                    {!arrayHasPhotos.includes(optIndex) && (
                      <div
                        className="col-sm"
                        style={{
                          position: "sticky",
                          marginLeft: "1%",
                          height: "100px",
                        }}
                      >
                        <ButtonUploadMedia
                          idRadioButton={"up_image-" + optIndex}
                          cName="bi bi-card-image display-4"
                          datatestid={"BtnUploadImage-"}
                          style={{ color: "black" }}
                          accept={imageExtention}
                          isVideoButton={false}
                          isInvalidUpload={`photo-${optIndex}` in errors}
                          onChangeFn={(e: any) => {
                            uploadProductMedia(
                              e,
                              productMediaUpload.image,
                              optIndex
                            );
                          }}
                        />
                      </div>
                    )}
                    {rd_VariantPhotos &&
                      rd_VariantPhotos
                        .filter((photos: any) => photos.index === optIndex)
                        .map((photos: any, index: number) => {
                          return (
                            photos.url && (
                              <div
                                className="col-sm"
                                style={{
                                  position: "sticky",
                                  marginLeft: "1%",
                                  height: "90px",
                                  width: "90px",
                                }}
                              >
                                <UploadImageThumbnail
                                  idRadioButton={"image-" + optIndex}
                                  isMobile={false}
                                  cName="bi bi-card-image display-3"
                                  datatestid={"image-" + optIndex}
                                  imgSrc={photos.url}
                                  isVideoButton={false}
                                  isHasCover={false}
                                  onClickFn={() => {
                                    removeProductVariantPhotos(optIndex);
                                  }}
                                  onChecked={false}
                                />
                              </div>
                            )
                          );
                        })}
                  </div>
                  <div className="d-flex flex-column w-75 justify-content-between">
                    <div className="fw-bolder fs-1 mb-2 align-items-start">
                      {opt.option}
                    </div>
                    <div className="d-flex align-items-end flex-wrap gap-2">
                      {rd_VariantMatrix &&
                        rd_VariantMatrix?.map((item: any, index: any) => {
                          if (item.tierIndex[0] === optIndex) {
                            return (
                              <Button
                                type="button"
                                btnbs="primary"
                                cName="btn-sm"
                                onClick={() => {
                                  setIsShowModal(true);
                                  setOnClickConfirm(false);
                                  setModalData(item);
                                  setPhotoURL(
                                    rd_VariantPhotos[optIndex] &&
                                      rd_VariantPhotos[optIndex].url
                                      ? rd_VariantPhotos[optIndex].url
                                      : ""
                                  );
                                }}
                              >
                                {`${
                                  item.variant2 ? item.variant2 : item.variant1
                                }`}
                              </Button>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
                {errors[`photo-${optIndex}`] ? (
                  <div className="mt-10" style={{ height: "15px" }}>
                    <span className="text-danger">
                      {errors[`photo-${optIndex}`]}
                    </span>
                  </div>
                ) : null}
                {errors[`sku-${optIndex}`] ? (
                  <div className="" style={{ height: "15px" }}>
                    <span className="text-danger">
                      {errors[`sku-${optIndex}`]}
                    </span>
                  </div>
                ) : null}
                {errors[`weight-${optIndex}`] ? (
                  <div className="" style={{ height: "15px" }}>
                    <span className="text-danger">
                      {errors[`weight-${optIndex}`]}
                    </span>
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default VariantListMobile;
