/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ID, KTSVG } from "../../../../../../resources/helpers";
import { templateStatus } from "../../../../../models/Language";
import { templateState } from "../../../../../models/Template";
import * as ReduxTemplate from "../../../../../modules/template/redux/TemplateSlice";
import * as ReduxTemplateList from "../../../../../modules/template/list/TemplateListSlice";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { deleteTemplate } from "../../core/_requests";
import AlertModal from "../../../../broadcast/AlertModal";
//import all from CellAction.tsx
import {
  Menu,
  print,
} from "../../../../../components/table/columns/CellAction";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { useMediaQuery } from "react-responsive";

type Props = {
  id: ID;
  indexId: number;
  languages?: any;
};

const TemplateActionsCell: FC<Props> = ({ id, indexId, languages }) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const { state, updateState } = useQueryRequest();
  const dataLanguage = languages;
  let status = "";
  if (dataLanguage !== undefined && dataLanguage.length > 0) {
    for (let index = 0; index < languages.length; index++) {
      const element = languages[index];
      if (
        element.templateLanguage !== undefined &&
        element.templateLanguage !== "" &&
        element.templateStatus !== undefined &&
        element.templateStatus !== ""
      ) {
        status = element.templateStatus;
      }
    }
  }

  const menus: Menu[] = [
    {
      label: t("Common.Edit"),
      type: "link",
      to: `/template/update/${id}`,
      state: { id, name: "name" },
      onClick: () => {
        console.log("id dari template id: " + id);
        dispatch(ReduxTemplate.cleanReduxTemplate(true));
        dispatch(ReduxTemplate.setTemplateID(id));
        dispatch(ReduxTemplate.setTemplateState(templateState.update));
      },
    },
    {
      label: t("Common.Delete"),
      type: "link",
      to: `/template/list`,
      state: { id, name: "name" },
      onClick: () => {
        let c = confirm("Are you sure you want to delete?");
        if (c === true) {
          deleteItem.mutate();
        }
      },
    },
  ];

  const deleteItem = useMutation(() => deleteTemplate(id), {
    onSuccess: () => {
      dispatch(ReduxTemplateList.deleteItemListTemplate(id + ""));
      updateState({
        sort: state.sort === "delete" ? "asc" : "delete",
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
    },
    onError(error, variables, context) {
      dispatch(ReduxTemplateList.deleteItemListTemplate(id + ""));
    },
  });

  if (status !== templateStatus.rejected) menus.splice(0, 1);

  return (
    <>
      {/* invisible button*/}
      <button
        id="triggerAlert"
        data-bs-toggle="modal"
        data-bs-target="#modal_broadcast_alert"
        hidden
      ></button>
      <AlertModal />

      <div className="dropdown">
        <a
          className={
            isTabletOrMobile
              ? "w-auto mx-0 px-0"
              : "btn btn-light btn-active-light-primary btn-sm"
          }
          href="/template/list"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {!isTabletOrMobile && t("Common.Actions")}
          {isTabletOrMobile ? (
            <KTSVG
              path="#"
              className="bi bi-three-dots-vertical fs-1 p-0 m-0"
            />
          ) : (
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className="svg-icon-5 m-0"
            />
          )}
        </a>

        <div
          className="dropdown-menu"
          style={{
            zIndex: 2000,
          }}
        >
          <h3 className="dropdown-header">{t("Common.Actions")}</h3>
          {menus.map((action, index) => print(action, `${index}`))}
        </div>
      </div>
    </>
  );
};

export { TemplateActionsCell };
function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
