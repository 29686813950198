import WABAAccount from "../../../../../models/WABAccount";

const sliceData = (data: WABAAccount[],startIndex: number, endIndex: number) => {
  let newDataContac: WABAAccount [] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const datacontact = data[index];
    newDataContac.push(datacontact); 
  }
  return newDataContac;
}

export const wabaAccountSorting = (data : WABAAccount[], 
  sort: string | undefined, 
  limit: number | undefined, 
  action: string | undefined , 
  orderBy: string | undefined,
  searchBy: string | undefined, 
  curretPage: number | undefined,
  callback: any) => {
  console.log("Masuk Contact sorting >>>>> ");

  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort : " + sort);
  console.log("order : " + orderBy);
  console.log("search : " + searchBy);
  console.log("current page : " + curretPage);

  if(curretPage === undefined)curretPage = 1;
  if(limit === undefined)limit = 10;
  if(orderBy === undefined) orderBy = "asc";
  if(searchBy === undefined) searchBy = ""; 
  if(sort === undefined || sort === 'deleted') sort = "firstName";

  let dataWABAAccount:WABAAccount [] = [];

  if(data.length == 0){
    return dataWABAAccount;
  }

  for (let index = 0; index < data.length; index++) {
    dataWABAAccount.push(data[index]); 
  }
  
  //Search Data
  if(searchBy && searchBy !=null && searchBy!="" && dataWABAAccount.length>0){
    //search by waba account name, and phone number in registered phone number
    dataWABAAccount = dataWABAAccount.filter((data) =>
      data.businessName?.toLowerCase().match(new RegExp(searchBy!.toLowerCase(), "g"))
    );
  }
  
  //Order Data
  if(dataWABAAccount.length>0){
    //sorting by waba account name, registered phone number, status
    if (sort === "businessName") {
      dataWABAAccount = dataWABAAccount?.sort((a, b) =>
        a.businessName!.toLowerCase() > b.businessName!.toLowerCase() ? 1 : -1
      );
      if (orderBy === "desc") {
        dataWABAAccount = dataWABAAccount?.sort((a, b) =>
          a.businessName!.toLowerCase() > b.businessName!.toLowerCase() ? -1 : 1
        );
      }
    }

    if(sort === "progress"){
      dataWABAAccount = dataWABAAccount?.sort((a, b) => {
        return (a.progress || 0) - (b.progress || 0)
      });
      
      if (orderBy === "desc") {
        dataWABAAccount = dataWABAAccount?.sort((a, b) =>{
          return (b.progress || 0) - (a.progress || 0)
        })
      }
    }

    if (sort === "phoneNumber") {
      
      dataWABAAccount = dataWABAAccount?.sort((a, b) =>
        (a.whatsappNumbers && a.whatsappNumbers[0] &&  a.whatsappNumbers[0].phoneNumber) &&
        (b.whatsappNumbers && b.whatsappNumbers[0] &&  b.whatsappNumbers[0].phoneNumber) ? 
        a.whatsappNumbers[0].phoneNumber?.toLowerCase() > b.whatsappNumbers![0].phoneNumber?.toLowerCase() ? 1 : -1 : 1
      );
      if (orderBy === "desc") {
        dataWABAAccount = dataWABAAccount?.sort((a, b) =>
        (a.whatsappNumbers && a.whatsappNumbers[0] &&  a.whatsappNumbers[0].phoneNumber) && 
        (b.whatsappNumbers && b.whatsappNumbers[0] &&  b.whatsappNumbers[0].phoneNumber) ?
        a.whatsappNumbers[0].phoneNumber?.toLowerCase() > b.whatsappNumbers![0].phoneNumber?.toLowerCase() ? -1 : 1
        : 0
        );
      }
    }
  }

  callback(dataWABAAccount.length);

  if(curretPage == 1){
    let endIndex = curretPage * limit;
    // let startIndex = (endIndex - limit);
    // if(startIndex>0){
    //   startIndex =startIndex-1;
    // } 
    // console.log("Hasil slice data 1 : "+startIndex+" - "+endIndex);
    dataWABAAccount = Array.from(dataWABAAccount).slice(0,limit);
  }else{
    let endIndex = curretPage * limit;
    let startIndex = (endIndex - limit); 
    // if(startIndex>0){
    //   startIndex =startIndex+1;
    // } 
    console.log("Hasil slice data 2 : "+startIndex+" - "+endIndex);
    if(startIndex > dataWABAAccount.length){
      return [];
    }else{
      dataWABAAccount = Array.from(dataWABAAccount).slice(startIndex,endIndex);
    }
  }
  console.log("Hasil Length Data : "+dataWABAAccount.length);
  // console.log("Hasil Data : "+JSON.stringify(dataContact));
  console.log("Hasil Current Page : "+curretPage);
  return dataWABAAccount;
}