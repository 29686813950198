import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as ReduxCampaign from "../../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxCampaignTarget from "../../../../../modules/campaign/target/redux/CampaignTargetSlice";
import { useFirestoreTable } from "../../context/TableContext";
import { useListView } from "../../core/ListViewProvider";
import { TargetListGrouping } from "./TargetListGrouping";
import { TargetListSearchComponent } from "./TargetListSearchComponent";
import { TargetListToolbar } from "./TargetListToolbar";

const TargetListHeader = ({ onModalClose }: { onModalClose?: () => void }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const { data, selectedItems, setSelectedItems } = useFirestoreTable();
  console.log("Target Data Items", data?.items);
  console.log("Target Data Items Selected", selectedItems);

  const addSelectedToRedux = () => {
    dispatch(ReduxCampaign.setIsLoading(true));
    // if (data?.items) {
    //   const selectedCustomers = data?.items.filter(
    //     (customer) => customer.id && selectedItems.includes(customer.id)
    //   );
    // }
    dispatch(ReduxCampaignTarget.setSelectedTarget(selectedItems));

    // setSelectedItems([]);
    dispatch(ReduxCampaign.setIsLoading(false));
    onModalClose && onModalClose();
  };

  if (isTabletOrMobile) {
    return (
      <div className="border-0 d-flex flex-column">
        <div className="card-header border-0 pt-6">
          <TargetListSearchComponent />
          {/* begin::Card toolbar */}
          <div className="card-toolbar w-100">
            {/* {selected.length > 0 ? <TargetListGrouping /> : <TargetListToolbar />} */}
            <TargetListGrouping onSubmitTarget={addSelectedToRedux} />
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="card-header border-0 pt-6">
        <TargetListSearchComponent />
        {/* begin::Card toolbar */}
        <div className="card-toolbar">
          <TargetListGrouping onSubmitTarget={addSelectedToRedux} />
          <TargetListToolbar />

          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    );
  }
};

export { TargetListHeader };
