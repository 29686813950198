/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as lc from "../../../modules/localstorage/index";
import * as chat from "../../../../app/modules/chat/redux/ChatSlice";
import * as CIQ from "../../../../app/modules/ciq/redux/CIQSlice";
import * as collabsService from "../../../../db/serviceCollaborations";
import * as Chat from "../../../../actions/chat";
import * as userServ from "../../../../db/serviceUser";
import HeaderNotificationMenuModal from "./modal/HeaderNotificationMenuModal";
import Notification from "../../../models/Notification";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  listSubsToFriendListMessages,
  listSubsToHanldeCollabsByAnotherUser,
  listSubsToNewMessageCollaboration,
  listSubsToNotificationMessageCollabByAnotherUser,
  listSubsToNotificationSyncOrderMarketplace,
  listSubsToUnhandledCollabs,
} from "src/api/firebase";
import { getIconChannelUrl, toAbsoluteUrl } from "src/resources/helpers";
import Avatar from "src/styles/components/Avatar";
import {
  createNotificationAnotherUser,
  deleteNotificationsByID,
} from "../../../../db/serviceUser";
import db from "src/db";
import moment from "moment";
import { CollabsStatus } from "src/app/models/Chat";
import { removeFromNotificationsByCollabID } from "src/app/modules/notify/Notification/helper";
import { Loading } from "src/app/modules/util/Loading";

const HeaderNotificationsMenu: FC = () => {
  const extend_limit = process.env.REACT_APP_EXTEND_LIMIT_INTERVAL_NOTIFICATION;
  const userID = lc.getItemLC("UID");
  const companyID = lc.getItemLC("CID");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const [newLimit, setNewLimit] = useState<number>(0);
  const [deleteNotifList, setDeleteNotifList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => setModalOpen(false);
  const [totalNotif, setTotalNotif] = useState<number>(0);

  useEffect(() => {
    const onNewData = (notif: any) => {
      setAllNotifications((prevNotifications) => {
        const isDuplicate = prevNotifications.some(
          (existingNotif) => existingNotif.id === notif.id
        );
        if (!isDuplicate) {
          const updatedNotifications = [...prevNotifications, notif];
          lc.setItemLC("Notify", updatedNotifications);
          setTotalNotif(updatedNotifications.length);
          return updatedNotifications;
        }
        return prevNotifications;
      });
    };

    const unsubscribes: any[] = [];

    const fetchData = async () => {
      setIsLoading(true);

      try {
        unsubscribes.push(
          listSubsToUnhandledCollabs(userID, companyID, onNewData, newLimit)
        );
        unsubscribes.push(
          listSubsToHanldeCollabsByAnotherUser(userID, onNewData, newLimit)
        );
        unsubscribes.push(
          listSubsToNotificationMessageCollabByAnotherUser(
            userID,
            onNewData,
            newLimit
          )
        );
        unsubscribes.push(
          listSubsToNewMessageCollaboration(userID, onNewData, newLimit)
        );
        unsubscribes.push(
          listSubsToFriendListMessages(userID, onNewData, newLimit)
        );
        unsubscribes.push(
          listSubsToNotificationSyncOrderMarketplace(
            userID,
            onNewData,
            newLimit
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [userID, companyID, newLimit]);

  const clickedNotif = (alert: Notification, idx: number) => {
    const newArray = allNotifications.map((notif) => {
      if (notif.id === alert.id) {
        const notifComponent = document
          .getElementById(`alert${idx}`)
          ?.getElementsByClassName("fw-bolder")
          ?.item(0);
        notifComponent?.classList.remove("fw-bolder");
        notifComponent?.classList.add("fw-bold");
        userServ.markNotifRead(lc.getItemLC("UID"), alert);
        return { ...notif, read: true };
      }
      return notif;
    });
    lc.setItemLC("Notify", newArray);
    setAllNotifications(newArray);
    setTotalNotif(newArray.length);
    window.dispatchEvent(new Event("storageNotif"));
  };

  useEffect(() => {
    if (deleteNotifList === true) {
      lc.removeLC("Notify");
      allNotifications.map(async (item: any) => {
        await db
          .collection("users")
          .doc(userID)
          .collection("notifications")
          .doc(item?.id)
          .delete();
      });
      setDeleteNotifList(false);
      setAllNotifications([]);
      setTotalNotif(0);
      window.dispatchEvent(new Event("storageNotif"));
    }
  }, [deleteNotifList]);

  useEffect(() => {
    lc.setItemLC("Notify", allNotifications);
  }, [allNotifications]);

  const handleDeleteNotificationsByID = async (
    notificationID: string | undefined
  ) => {
    const userID = lc.getItemLC("UID");
    await deleteNotificationsByID(userID, notificationID)
      .then(async () => {
        let arrNotif = allNotifications.filter(
          (notf) => notf.id !== notificationID
        );
        lc.setItemLC("Notify", arrNotif);
        setAllNotifications(arrNotif); // Update state
        setTotalNotif(arrNotif.length);
        await db
          .collection("users")
          .doc(userID)
          .collection("notifications")
          .doc(notificationID)
          .delete();

        window.dispatchEvent(new Event("storageNotif"));
      })
      .catch((error) => {
        console.error("Failed to delete notifications:", error);
      });
  };

  const renderNotificationContent = (
    title: string,
    info: string,
    notification: Notification
  ) => {
    return (
      <div className="d-flex">
        <div className="col-6">
          <div
            className={clsx("mb-2", {
              "fw-bolder": !notification.read,
            })}
          >
            {title} {notification.companyName}
          </div>
          <div className="text-gray-600 fs-7">
            {info}{" "}
            {notification.name ||
              notification.profileName ||
              notification.phoneNumber}
          </div>
        </div>
        <div className="col-6 text-end">
          <div className="ps-0 d-flex flex-column">
            <span className="text-muted fs-7">
              {notification.createdAt
                ? moment(
                    new Date(notification?.createdAt?.seconds * 1000)
                  ).fromNow()
                : moment(new Date()).fromNow()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  console.log(allNotifications, "ca upix")

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column w-425px w-lg-450px"
        style={{ borderRadius: "8px 4px 4px 8px" }}
        data-kt-menu="true"
      >
        <div
          className="d-flex flex-column bgi-no-repeat"
          style={{
            backgroundColor: "#DDDDDD",
            borderRadius: "8px 4px 4px 8px",
          }}
        >
          <div className="row d-flex">
            <div className="col-6">
              <h3 className="text-dark fw-bold px-9 mt-5 mb-5">
                {t("Notif.Info.Title")} ({allNotifications.length})
              </h3>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end pe-5">
              <button
                className="btn btn-transparent btn-sm fs-5 pe-3 text-primary pe-auto"
                onClick={() => setModalOpen(true)} // buka HeaderNotificationMenuModal
              >
                {t("Notif.Button.SyncOrderAll")}
              </button>
            </div>
          </div>
          <div className="bg-white" style={{ borderRadius: "0px 0px 0px 8px" }}>
            <div className="scroll-y mh-325px px-2">
              {allNotifications != null && allNotifications.length > 0 ? (
                allNotifications
                  .sort((a, b) => {
                    return b.createdAt.seconds - a.createdAt.seconds;
                  })
                  .map((alert, index) => (
                    <div className="border-bottom w-100" key={index}>
                      <div key={`alert${index}`} className="d-flex py-2 w-100">
                        <div className="d-flex align-items-center flex-row w-100">
                          <div className="symbol symbol-35px me-4">
                            <span
                              className={clsx(
                                "symbol-label",
                                `bg-light-${alert.state}`,
                                "rounded-circle"
                              )}
                            >
                              {" "}
                              {alert.notifType === "CIQMessage" &&
                                alert.companyAvatar && (
                                  <Avatar
                                    height="50"
                                    width="50"
                                    imgRadius="50%"
                                    imgSrc={
                                      alert.companyAvatar
                                        ? alert.companyAvatar
                                        : ""
                                    }
                                  />
                                )}
                              {alert.notifType === "CIQMessage" &&
                                (alert.companyAvatar === undefined ||
                                  alert.companyAvatar === "") && (
                                  <i className="far fa-building fs-2x cl-gray"></i>
                                )}
                              {(alert.notifType === "newUserMessage" ||
                                alert.notifType === "newMessage") &&
                                alert.gender &&
                                alert.gender === "female" && (
                                  <div className="symbol-label fs-3 fw-bold">
                                    <img
                                      src={
                                        alert.avatar
                                          ? `${alert.avatar}`
                                          : toAbsoluteUrl(
                                              "/media/icons/avatar/f-avatar.png"
                                            )
                                      }
                                      alt={alert.name}
                                      className="w-75"
                                    ></img>
                                  </div>
                                )}
                              {(alert.notifType === "newUserMessage" ||
                                alert.notifType === "newMessage") &&
                                alert.gender &&
                                alert.gender === "male" && (
                                  <div className="symbol-label fs-3 fw-bold">
                                    <img
                                      src={
                                        alert.avatar
                                          ? `${alert.avatar}`
                                          : toAbsoluteUrl(
                                              "/media/icons/avatar/m-avatar.png"
                                            )
                                      }
                                      alt={alert.name}
                                      className="w-75"
                                    ></img>
                                  </div>
                                )}
                              {(alert.notifType === "newUserMessage" ||
                                alert.notifType === "newMessage") &&
                                (alert.gender === undefined ||
                                  alert.gender === "") && (
                                  <div className="symbol-label fs-3 fw-bold">
                                    <img
                                      src={
                                        alert.avatar
                                          ? `${alert.avatar}`
                                          : toAbsoluteUrl(
                                              "/media/icons/avatar/def-avatar.png"
                                            )
                                      }
                                      alt={alert.name}
                                      className="w-75"
                                    ></img>
                                  </div>
                                )}
                              {alert.notifType === "SyncOrder" &&
                                (alert.companyAvatar === undefined ||
                                  alert.companyAvatar === "") && (
                                  <i className="bi bi-check-lg fs-2"></i>
                                )}
                            </span>
                            {alert.channel && (
                              <span className="symbol-badge badge badge-circle top-100 start-100 bg-light">
                                <Avatar
                                  height="18"
                                  width="18"
                                  imgRadius="0%"
                                  imgSrc={getIconChannelUrl(alert.channel!)}
                                />
                              </span>
                            )}
                          </div>

                          <div className="mb-2 me-2 ms-2 flex-row w-100">
                            <a
                              href="#"
                              className={clsx(
                                "text-hover-primary fs-6 text-dark text-decoration-none d-block",
                                {
                                  "fw-bolder": !alert.read,
                                }
                              )}
                              id={`alert${index}`}
                              onClick={() => clickedNotif(alert, index)}
                            >
                              {alert.notifType === "newMessage" &&
                                renderNotificationContent(
                                  t("Notif.Title.Message"),
                                  t("Notif.Info.Message"),
                                  alert
                                )}

                              {alert.notifType === "CIQMessage" &&
                                renderNotificationContent(
                                  t("Notif.Title.Message"),
                                  t("Notif.Info.Message"),
                                  alert
                                )}

                              {alert.notifType === "newUserMessage" &&
                                renderNotificationContent(
                                  t("Notif.Title.UserChat"),
                                  t("Notif.Info.UserChat"),
                                  alert
                                )}

                              {alert.notifType === "SyncOrder" &&
                                renderNotificationContent(
                                  t("Notif.Title.SyncOrder"),
                                  "Synchronize Completed",
                                  alert
                                )}
                            </a>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div style={{ textAlign: "left" }} className="ps-16 pb-3">
                        {alert.notifType === "newMessage" && (
                          <div className="d-flex">
                            <div className="pe-3">
                              <Link
                                to="/handled-customer"
                                onClick={() => {
                                  dispatch(
                                    chat.setSelectedChat(alert.collaborationID!)
                                  );
                                }}
                                className="btn btn-primary btn-sm align-text-bottom p-13 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("Notif.Button.Reply")}
                              </Link>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  handleDeleteNotificationsByID(alert.id)
                                }
                                className="btn btn-outline-primary border border-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("UFL.Button.Delete")}
                              </button>
                            </div>
                          </div>
                        )}
                        {alert.notifType === "CIQMessage" && (
                          <div className="d-flex">
                            <div className="pe-3">
                              <Link
                                to=""
                                onClick={async () => {
                                  try {
                                    const id =
                                      await collabsService.updateStatus(
                                        alert.collaborationID!,
                                        CollabsStatus.handled,
                                        lc.getItemLC(lc.LCName.UserID),
                                        t("CIQ.Error.DoubleHandled")
                                      );
                                    if (id && id !== null) {
                                      Chat.fetchCollaborationByID(
                                        alert.collaborationID!
                                      ).then((collab) => {
                                        if (collab) {
                                          dispatch(chat.updateChatList(collab));
                                          dispatch(
                                            chat.setSelectedChat(
                                              alert.collaborationID!
                                            )
                                          );
                                          dispatch(CIQ.deleteItemListCIQ(id));
                                          if (
                                            collab.company &&
                                            collab.company?.id &&
                                            collab.customer
                                          ) {
                                            createNotificationAnotherUser(
                                              collab
                                            );
                                          }
                                        }
                                      });
                                    }
                                  } catch (error) {
                                    console.log("error : " + error);
                                  }
                                  removeFromNotificationsByCollabID(
                                    alert.collaborationID!
                                  );
                                }}
                                className="btn btn-primary btn-sm align-text-bottom p-13 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("CIQ.Button.Handle")}
                              </Link>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  handleDeleteNotificationsByID(alert.id)
                                }
                                className="btn btn-outline-primary border border-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("UFL.Button.Delete")}
                              </button>
                            </div>
                          </div>
                        )}
                        {alert.notifType === "newUserMessage" && (
                          <div className="d-flex">
                            <div className="pe-3">
                              <Link
                                to="/user-conversation"
                                onClick={() => {
                                  dispatch(
                                    chat.setSelectedUserChat(
                                      alert.collaborationID!
                                    )
                                  );
                                  dispatch(chat.updateSortUserChatList());
                                  removeFromNotificationsByCollabID(
                                    alert.collaborationID!
                                  );
                                }}
                                className="btn btn-primary btn-sm align-text-bottom p-13 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("Notif.Button.Reply")}
                              </Link>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  handleDeleteNotificationsByID(alert.id)
                                }
                                className="btn btn-outline-primary border border-primary btn-sm align-text-bottom p-7 pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("UFL.Button.Delete")}
                              </button>
                            </div>
                          </div>
                        )}
                        {alert.notifType === "SyncOrder" && (
                          <div className="d-flex">
                            <div className="pe-3">
                              <Link
                                to="/order/list"
                                className="btn btn-primary btn-sm align-text-bottom pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("Notif.Button.SyncOrder")}
                              </Link>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  handleDeleteNotificationsByID(alert.id)
                                }
                                className="btn btn-outline-primary border border-primary btn-sm align-text-bottom  pt-1 pb-1 mt-0 justify-content-end"
                              >
                                {t("UFL.Button.Delete")}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="text-center text-muted mt-5 fs-6 my-6">
                  <p>{t("Notif.Info.Empty")}</p>
                </div>
              )}
              {isLoading === true && <Loading />}
              <p className="d-flex align-items-center justify-content-center w-100 p-0 m-0 bg-warning">
                {t("Notification.Info.Loadmore")}
                {moment(
                  new Date().setHours(
                    new Date().getHours() - (newLimit > 0 ? newLimit + 24 : 24)
                  )
                ).format("DD-MM-YYYY, HH:mm")}
              </p>
              <p
                className="cursor-pointer text-primary d-flex flex-row align-items-center justify-content-center p-2 m-0"
                onClick={() => {
                  setIsLoading(true);
                  setNewLimit(
                    newLimit + Number(extend_limit ? extend_limit : 12)
                  );
                }}
              >
                {t("Notification.Info.LoadmoreButton")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <HeaderNotificationMenuModal
          isOpen={modalOpen} //buka Modal
          onClose={modalClose} //Tutup Modal
          onDeleteComplete={() => {
            setDeleteNotifList(true);
          }} //state true untuk menghapus semua notif list
        />
      )}
    </>
  );
};

export { HeaderNotificationsMenu };
