import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { OrderStatus7Days } from "../../../../../models/Order";
import { isSameStatusValue } from "../../OrderPage";
import { useFirestoreTable } from "../context/TableContext";
import { useMediaQuery } from "react-responsive";

interface IOptions {
  value: string | string[];
  label: string;
}

const StatusFilter: React.FC = () => {
  const { t } = useTranslation();
  const isLaptopView = useMediaQuery({ query: "(max-width: 1440px)" });
  const statusOptions: IOptions[] = [
    { value: "ALL", label: t("OrderStatus7Days.All") },
    { value: OrderStatus7Days.Unpaid, label: t("OrderStatus7Days.Unpaid") },
    { value: OrderStatus7Days.NewOrder, label: t("OrderStatus7Days.NewOrder") },
    {
      value: OrderStatus7Days.Processing,
      label: t("OrderStatus7Days.Processing"),
    },
    {
      value: OrderStatus7Days.ReadyToShip,
      label: t("OrderStatus7Days.ReadyToShip"),
    },
    { value: OrderStatus7Days.Shipping, label: t("OrderStatus7Days.Shipping") },
    // {
    //   value: OrderStatus7Days.Delivered,
    //   label: t("OrderStatus7Days.Delivered"),
    // },
    {
      value: OrderStatus7Days.Completed,
      label: t("OrderStatus7Days.Completed"),
    },
    {
      value: OrderStatus7Days.Cancelled,
      label: t("OrderStatus7Days.Cancelled"),
    },
    { value: OrderStatus7Days.Return, label: t("OrderStatus7Days.Return") },
  ];

  const { tableState, updateTableState } = useFirestoreTable();

  const selectedStatus =
    tableState.filters?.find((fil) => fil.field === "exportStatus")?.value ||
    "ALL";

  const updateStatusFilter = (value: string | string[]) => {
    const filtered = tableState.filters?.filter((fil) => fil.field !== "exportStatus") || [];

    if (value === "ALL") {
      console.log("Filter value all", filtered);

      return updateTableState({
        filters: [...filtered],
      });
    }

    updateTableState({
      filters: [
        ...filtered,
        {
          field: "exportStatus",
          value: value,
        },
      ],
    });

    console.log("Filter value status liya", filtered);
  };

  return (
    <div
      id="tab-status"
      className="btn-group btn-group-custom d-flex align-items-center justify-content-between flex-nowrap w-100"
      role="group"
      style={
        isLaptopView
          ? {
              maxWidth: "1176px",
              overflowX: "auto",
            }
          : {
              maxWidth: "100%",
            }
      }
      data-testid="order-status-filter"
    >
      {statusOptions.map((status, index) => (
        <button
          key={index}
          type="button"
          className={`btn btn-white text-nowrap`}
          onClick={() => updateStatusFilter(status.value)}
        >
          <span
            className={clsx("fw-bolder", {
              "text-primary border-3 border-bottom border-primary py-3":
                isSameStatusValue(selectedStatus, status.value),
            })}
          >
            {status.label}
          </span>
        </button>
      ))}
    </div>
  );
};

export default StatusFilter;
