import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as ProcessesService from "../../../../../../db/serviceProcesses";
import { KTSVG, useDebounce } from "../../../../../../resources/helpers";
import { OrderStatus7Days } from "../../../../../models/Order";
import * as lc from "../../../../../modules/localstorage";
import { isSameStatusValue } from "../../ProcessPage";
import { useFirestoreTable } from "../context/TableContext";

const statusWithAction: string[] = [OrderStatus7Days.NewOrder];

export default function TableHeader() {
  const { t } = useTranslation();

  const {
    refreshData,
    selectedItems,
    tableState,
    updateTableState,
    data,
    isLoading,
    isFetching,
  } = useFirestoreTable();

  const [searchTerm, setSearchTerm] = useState(
    tableState.searchKey?.value || ""
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  useEffect(() => {
    updateTableState({
      searchKey: {
        field: "id",
        value: debouncedSearchTerm,
      },
    });
  }, [debouncedSearchTerm]);

  const onDownloadSelected = async () => {
    const userID = lc.getItemLC(lc.LCName.UserID);

    console.log(data?.items);
    if (!data?.items || !userID) {
      return;
    }
    const selected = data.items.filter(
      (item) => item.id && selectedItems.includes(item.id)
    );

    if (selected.length <= 0) {
      return;
    }

    for (const item of selected) {
      if (!item.fileURL || !item.id) {
        return undefined;
      }
      const link = document.createElement("a");
      link.href = item.fileURL || "";
      link.download = "";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ProcessesService.incrementDownloadByIDAndAccID(item.id, userID);
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    refreshData();
  };
  
  return (
    <div className="row align-items-center pb-2 justify-content-between">
      <div
        className={`col-12 col-lg-6 d-flex align-items-center position-relative`}
      >
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className={clsx("form-control form-control-solid ps-14", {
            "w-100": false,
          })}
          placeholder={t("ExportOrderProcess.Search.Placeholder")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-testid="search-order"
        />
      </div>

      <div className="col-12 col-lg-2 py-2">
        <button disabled type="button" className="btn btn-light-dark w-100">
          {`${selectedItems.length} ${t("OrderList.Info.Selected")}`}
        </button>
      </div>

      <div className="col-1 py-2 d-none d-lg-block">
        <button
          type="button"
          className="btn btn-light-primary w-100"
          disabled={isLoading || isFetching}
          onClick={() => !(isLoading || isFetching) && refreshData()}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      </div>

      <div className="col-12 col-lg-3 py-2">
        <button
          type="button"
          className="btn btn-primary w-100"
          disabled={selectedItems.length <= 0}
          // onClick={() =>
          //   selectedItems.length > 0 && setReadyToShipOrder(selectedItems)
          // }
          onClick={() => onDownloadSelected()}
        >
          {t("ExportOrderProcess.Button.DownloadSelected")}
        </button>
      </div>
    </div>
  );
}
