import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export const ProductStep = (step: number, title: string) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      {isTabletOrMobile ? (
        <div className="h1">
          <span
            className="badge badge-circle fw-bolder fs-7 bg-primary"
            style={{ marginRight: "10px" }}
          >
            {step}{" "}
          </span>
          {t(title)}
        </div>
      ) : (
        <div className="h1">
          <h1 className="w-50">
            <span
              className="badge badge-circle fw-bolder fs-1 bg-primary"
              style={{ marginRight: "10px" }}
              data-testid="shipmentsTitle"
            >
              {step}{" "}
            </span>
            {t(title)}
          </h1>
        </div>
      )}
    </>
  );
};
