import { current } from "@reduxjs/toolkit";
import clsx from "clsx";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KTSVG } from "../../../../resources/helpers";
import { RootState } from "../../../../setup/redux/store";
import ModalSelectProductCategory from "../modalSelectProductCategory";
import * as ReduxMarketplaceProductCategory from "../../product/marketplaceProductCategory/marketplaceProductCategorySlice";
import * as ReduxStorefront from "../../product/storefront/StorefrontSlice";
import { getMarketplaceProductCategory } from "../../../../actions/storefront";

interface MarketplaceCategoryProps {
  title: string;
  step: string;
  isSubmitted?: any;
  id: string;
  onConfirmCategory?: (categories: any[]) => void;
}

interface MarketplaceBodyModalProps {
  id: string;
  finalCategory: [];
  setFinalCategory: any;
  searchTerm: string
}

function filterArrayWithParent(array: any, name: string) {
  const filteredArray: any = [];
  
  const findAllParent: any = (category: any) =>  {
    if(!filteredArray.find((item:any) => item.categoryId === category.categoryId)){
      filteredArray.push(category)
    }

    if(category.parentCategoryId === "0"){
      return
    }
    const parent = array.find((item:any) => item.categoryId === category.parentCategoryId)
    if(!parent){
      return
    }
    return findAllParent(parent)
  }

  const matchedItems = array.filter((item: any) => item.categoryName.id.toLowerCase().includes(name.toLowerCase()))

  if (matchedItems.length <= 0) {
    return []
  }
  
  matchedItems.forEach((category: any) => {
    findAllParent(category)
  })

  return filteredArray;
}

function hasItemWithKeyAndValue(arr: [], key: string, value: any) {
  return arr.some((item) => {
    return item[key] === value;
  });
}

const findCategoryIndex = (arrays: [], key: string, value: any) => {
  let current = arrays.findIndex((items: any) => {
    return hasItemWithKeyAndValue(items, key, value);
  });
  return current;
};

const MarketplaceBodyModal: FC<MarketplaceBodyModalProps> = ({
  id,
  finalCategory,
  setFinalCategory,
  searchTerm
}: any) => {
  let [arrayData, setArrayData] = useState<any>([[]]);
  const reduxCategories: any = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );
  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const currentSelectedCategories = reduxCategories.find(
    (cat: any) => cat.name === id.toString()
  );

  // const [filteredCategories, setFilteredCategories] = useState(reduxShopeeProductCategoryData)
  

  // useEffect(() => {
  //   if (!searchTerm) {
  //     setFilteredCategories(reduxShopeeProductCategoryData)
  //     return
  //   }

  //   const filtered = filterArrayWithParent(reduxShopeeProductCategoryData, searchTerm)
  //   if (filtered.length <= 0) {
  //     setFilteredCategories([])
  //     return
  //   }

  //   setFilteredCategories(filtered)
  // }, [searchTerm])
  

  useEffect(() => {
    if (
      id?.toLowerCase() == "shopee" &&
      reduxShopeeProductCategoryData?.length > 0
    ) {
      let parent: any = reduxShopeeProductCategoryData?.filter(
        (category: any) => {
          return category.parentCategoryId === "0";
        }
      );
      let newArray = [...arrayData];
      newArray[0] = parent;
      console.log("new Array : ", newArray);

      setArrayData(newArray);
    } else {
      
      setArrayData([]);
    }
  }, [reduxShopeeProductCategoryData]); // eslint-disable-line react-hooks/exhaustive-deps

  // If has already selected category and has category data in redux
  useEffect(() => {
    // if category data in redux exist

    if (
      currentSelectedCategories !== undefined &&
      currentSelectedCategories.category.length > 0
    ) {
      // get a copy categories
      let listCategories = [...currentSelectedCategories.category];
      console.log("LIST CAT STOREFRONT", listCategories);

      // get initial parent data
      let parent: any = reduxShopeeProductCategoryData?.filter(
        (category: any) => {
          return category.parentCategoryId === "0";
        }
      );

      let newArray: any = [];
      let newFinal: any = [];

      // set column 0 category data to parent category
      newArray[0] = parent;

      // map each copy categories from redux
      listCategories &&
        listCategories.length > 0 &&
        listCategories?.forEach((list: any) => {
          // each marketplace list
          list &&
            list.length > 0 &&
            list?.forEach((cat: any, i: number) => {
              let children: any = reduxShopeeProductCategoryData.filter(
                (category: any) => {
                  return category?.parentCategoryId === cat?.id?.toString();
                }
              );

              const currentPiece = {
                id: cat?.id,
                level: cat?.level,
                hasChildren: cat?.hasChildren,
                name: {
                  id: cat?.name.id,
                  en: cat?.name.en,
                },
              };

              newFinal[i] = currentPiece;
              setFinalCategory(newFinal);

              if (children !== undefined && children.length > 0) {
                let idx = i + 1;

                // generate array data children
                newArray[idx] = children;
                setArrayData(newArray);
              }
            });
        });
    }
  }, [reduxCategories]);

  const handleSelectedCategory = (selectedCategory: any) => {
    let children: any = reduxShopeeProductCategoryData?.filter(
      (category: any) => {
        return (
          category.parentCategoryId.toString() ===
          selectedCategory.categoryId.toString()
        );
      }
    );

    // get current array index
    const currentIndex = findCategoryIndex(
      arrayData,
      "categoryId",
      selectedCategory.categoryId
    );
    console.log("current index : " + currentIndex);

    // remove any data from 0 to current index + 1
    const newArrayCategories = arrayData.slice(0, currentIndex + 1);
    console.log("current array", newArrayCategories);

    // --- perform get final categories array
    const currentPiece = {
      id: selectedCategory.categoryId,
      level: currentIndex,
      hasChildren: selectedCategory.hasChildren,
      name: {
        id: selectedCategory.categoryName.id,
        en: selectedCategory.categoryName.en,
      },
    };

    // get a copy from state
    const piece = [...finalCategory];

    // remove any data from current index slice
    let sliced = piece.slice(0, currentIndex);
    let newPiece = [...sliced, currentPiece];

    // overriede piece that has the same level
    newPiece.find((p) => p.level === currentPiece.level) !== undefined
      ? (newPiece.find((p) => p.level === currentPiece.level).id =
          currentPiece.id.toString())
      : newPiece.push(currentPiece);

    setFinalCategory(newPiece);

    let push;
    if (children.length > 0) {
      push = [...newArrayCategories, children];
    } else {
      push = [...newArrayCategories];
    }
    setArrayData(push);
  };

  const { i18n } = useTranslation();

  console.log("check marketplace id : " + id);
  return (
    <>
      {id?.toLowerCase() === "shopee" && (
        <div className="d-flex flex-row" key={id}>
          {arrayData.map((category: any, i: any) => {
            return (
              <div
                className="d-flex flex-column"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                }}
                key={id + "-" + i}
                id={"scrollColumn-" + i}
              >
                {category?.map((cat: any, i: number) => {
                  const isCategoryActive = finalCategory.some(
                    (u: any) => u.id.toString() === cat.categoryId.toString()
                  );
                  return (
                    <button
                      id={cat.id}
                      key={cat.id}
                      className={clsx(
                        "d-flex align-items-center justify-content-between btn p-3",
                        {
                          "bg-primary text-white active-items":
                            isCategoryActive,
                        }
                      )}
                      style={{ textAlign: "left", width: "260px" }}
                      onClick={() => handleSelectedCategory(cat)}
                    >
                      <span>
                        {i18n.language === "id"
                          ? cat?.categoryName?.id
                          : cat?.categoryName?.en}
                      </span>
                      {cat && cat?.hasChildren && (
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr071.svg"
                          className={clsx(
                            `svg-icon ${
                              isCategoryActive
                                ? "svg-icon-white"
                                : "svg-icon-dark"
                            }`
                          )}
                        />
                      )}
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const MarketplaceCategoryModalDesktop: React.FC<MarketplaceCategoryProps> = ({
  title,
  step,
  isSubmitted,
  id,
  onConfirmCategory,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();
  const [finalCategory, setFinalCategory] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("")

  console.log("final category", finalCategory);

  const listMarketplace = useSelector(
    (state: RootState) => state.Storefront.listMarketplace
  );

  // language check

  const isCurrentMarketplaceHasSelectedCategory: any = listMarketplace.some(
    (marketplace: any) => marketplace.name?.toLowerCase() === id?.toLowerCase()
  );

  const currentMarketplaceSelected: any = listMarketplace.find(
    (marketplace: any) => marketplace.name?.toLowerCase() === id?.toLowerCase()
  );

  const currentCategory = currentMarketplaceSelected?.category;
  console.log("Storefront Current Category", currentCategory);

  const dispatch = useDispatch();

  const isChecked = currentMarketplaceSelected?.isChecked || false;

  const handleOnConfirm = (title: string) => {
    const marketplaceName = title.split(" ").pop();

    const data: any = {
      name: marketplaceName,
      isChecked,
      category: [],
    };

    const categoryToAdd = finalCategory;
    data.category.push(categoryToAdd);

    const currentListMarketplace: any = [...listMarketplace];
    const categoryIndexToReplace = currentListMarketplace.findIndex(
      (marketplace: any) => marketplace.name === marketplaceName
    );

    if (categoryIndexToReplace !== -1) {
      currentListMarketplace[categoryIndexToReplace] = data;
    } else {
      currentListMarketplace.push(data);
    }

    dispatch(
      ReduxStorefront.setStorefrontListMarketplace(currentListMarketplace)
    );
    console.log(`handle on confirm ${JSON.stringify(currentListMarketplace)}`)
    onConfirmCategory && onConfirmCategory(categoryToAdd);
  };

  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );
  useEffect(() => {
    if (id?.toLowerCase() === "shopee") {
      if (
        !reduxShopeeProductCategoryData ||
        reduxShopeeProductCategoryData.length <= 0
      ) {
        getMarketplaceProductCategory(id.toLowerCase()).then(
          async (response) => {
            if (response) {
              dispatch(
                ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
                  response
                )
              );
            }
          }
        );
      }
    }
  }, []);

  const adjustChildScrollPosition = () => {
    setTimeout(() => {
      const itemsActive: any = document.getElementsByClassName("active-items");
      if (itemsActive.length > 0) {
        for (let i = 0; i < itemsActive.length; i++) {
          const childPos = itemsActive[i];
          childPos.scrollIntoView();
        }
      }
    }, 200);
  };

  // checked tanpa category
  const checkedWithNoCategory: any = listMarketplace.find(
    (marketplace: any) =>
      marketplace.isChecked === true && marketplace.category.length === 0
  );

  const isAllUnchecked = listMarketplace.every(
    (marketplace: any) => marketplace.isChecked === false
  );

  return (
    <>
      <div
        className={clsx(
          "d-flex flex-column", {
            "p-2 w-100" : step === "summary",
          }
        )}
        key={title}
      >
        <div id="marketplaceCategory" className="form-group">
          <div
            data-testid="form-selectmarketplace"
            className={clsx(`form-control h-5 d-flex justify-content-between`, {
              "pointer-none": !isChecked,
              "w-100": step === "summary" || isTabletOrMobile,
              // "w-50": step !== "summary",
              "border-gray-900": isChecked,
              "border-gray-300": !isChecked,
            })}
            style={{
              alignItems: "center",
            }}
            role="button"
            data-bs-toggle="modal"
            data-bs-target={
              isChecked
                ? `#modalSelectProductCategory-${title.toLowerCase()}`
                : ""  
            }
            onClick={() => adjustChildScrollPosition()}
          >
            <div
              className={clsx("text-gray-500", {
                "text-gray-900": isChecked,
              })}
            >
              {/* <div> current marketplace selected <br />
                {` has selected ${isCurrentMarketplaceHasSelectedCategory}
                current category ${currentCategory && JSON.stringify(currentCategory)}
                final category ${finalCategory && JSON.stringify(finalCategory)}

                `}
              </div> */}

              {/* {isCurrentMarketplaceHasSelectedCategory &&
              currentCategory &&
              currentCategory.length > 0 && (
                <span>
                  {currentCategory?.map((categories: any, i: number) => (
                    <Fragment key={i.toString()}>
                      {categories &&
                        categories?.map((category: any) => {
                          return (
                            <span>
                              {i18n.language === "id"
                                ? category?.name?.id
                                : category?.name?.en}
                              {category?.hasChildren ? " > " : ""}
                            </span>
                          );
                        })}
                    </Fragment>
                  ))}
                </span>
              )}  */}
              
              {finalCategory && finalCategory.length > 0 && (
                finalCategory?.map((category: any, i: number) => {
                  return (
                    <span>
                      {i18n.language === "id"
                        ? category?.name?.id
                        : category?.name?.en}
                      {category?.hasChildren ? " > " : ""}
                    </span>
                  );
                }))
              } 

              {finalCategory && finalCategory.length <= 0 && 
              (
                <span>{`${
                  i18n.language === "id"
                    ? `Pilih Kategori ${title}`
                    : `Select ${title} Category`
                }`}</span>
              )}
            </div>
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className={clsx("svg-icon", { "svg-icon-dark": isChecked })}
            />
          </div>
          {step !== "summary" &&
            isSubmitted &&
            isChecked &&
            currentCategory.length === 0 && (
              <p className="mt-2 text-danger">
                {t("Storefront.Alert.SelectMarketplace")}
              </p>
            )}
        </div>

        <ModalSelectProductCategory
          id={"modalSelectProductCategory-" + title.toLowerCase()}
          title={`${
            i18n.language === "id" ? "Kategori " + title : title + " Category"
          }`}
          body={
            <MarketplaceBodyModal
              id={title?.toLowerCase()}
              finalCategory={finalCategory}
              setFinalCategory={setFinalCategory}
              searchTerm={searchTerm}
            />
          }
          displayCategory={finalCategory}
          confirmOption={`${t("Storefront.Button.Confirm")}`}
          cancelOption={`${t("Alert.Button.Cancel")}`}
          onConfirm={() => handleOnConfirm(title.toLowerCase())}
          searchTerm={searchTerm}
          onChangeSearch={(value) => setSearchTerm(value)}
        />
      </div>

      {step === "summary" &&
        id?.toLowerCase() === title?.toLowerCase() &&
        isSubmitted &&
        isAllUnchecked && (
          // checkedWithNoCategory &&
          <p className="text-danger">
            {t("Storefront.Alert.SelectMarketplace")}
          </p>
        )}

      {step === "summary" &&
        id?.toLowerCase() === title?.toLowerCase() &&
        // isSubmitted &&
        checkedWithNoCategory &&
        id === checkedWithNoCategory.name && (
          <p className="text-danger p-2">
            {t("Storefront.Alert.SelectMarketplace")}
          </p>
        )}
    </>
  );
};

export default MarketplaceCategoryModalDesktop;
