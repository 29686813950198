import {
  createNewStorefront,
  getMarketplaceStorefront,
  updateMarketplaceStorefront,
} from "./connection";

export const sendRequestStorefront = async (
  storefrontID: string,
  accountID: string,
  userID: string
  //   callback: any
) => {
  console.log(
    "masuk di function sendRequestProduct to marketplace",
    storefrontID,
    accountID,
    userID
  );
  const jsonAddStorefront = `
  {
    "storefront" : "${storefrontID}",
    "account" : "${accountID}",
    "user" : "${userID}"
  }`;

  console.log("jsonAddProduct : ", jsonAddStorefront);

  await createNewStorefront(
    jsonAddStorefront
    // , (rsError:string, rsMessage:string, rsJson:JSON) => {
    // callback(rsError, rsMessage, rsJson);
    //   }
  );
};

export const requestGetMarketplaceStorefront = async (
  accountID: string,
  userID: string
) => {
  console.log(
    "masuk di function sendRequestProduct to marketplace",
    accountID,
    userID
  );
  const data = {
    account: accountID,
    user: userID,
  };

  return await getMarketplaceStorefront(data);
};

export const requestUpdateMarketplaceStorefront = async (
  accountID: string,
  userID: string,
  storefontID: string
) => {
  console.log(
    "masuk di function sendRequestProduct to marketplace",
    accountID,
    userID
  );
  const data = {
    account: accountID,
    user: userID,
    storefront: storefontID,
  };

  return await updateMarketplaceStorefront(data);
};
