import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../styles/components/Button";
import TextInput from "../../../../styles/components/TextInput";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Timestamp, getToken, updateIsUsedToken, updateUser } from "../../../../db";
import { resetPassword } from "../../../../api/server/users"
import User from "../../../models/User";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "PasswordEntry.Error.Under8Char")
    .required("PasswordEntry.Error.NewPasswordEmpty")
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "PasswordEntry.Error.ExcludeNumber"
    ),
  confirmpassword: Yup.string()
    .min(8, "PasswordEntry.Error.Under8Char")
    .required("PasswordEntry.Error.ConfirmPasswordEmpty"),
});

const initialValues = {
  password: "",
  confirmpassword: "",
}

function getNewPasswordFromInput(input: string) {
  const newpassword = input;
  return newpassword;
}

export function ResetPassword() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [token, setToken] = useState<any>()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tokenRequest = searchParams.get("token");
    //check token to firebase
    if(tokenRequest){
      console.log("masuk")
      getToken(tokenRequest, (token: any) => {
        console.log(token)
        if(token && !token.isUsed){
          setToken(token)
        }else{
          nav("/error/404")
        }
      })
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        const newpassword = getNewPasswordFromInput(values.password);
        const confirmpassword = getNewPasswordFromInput(values.confirmpassword);

        if (newpassword !== confirmpassword) {
          setHasErrors(true);
              setLoading(false);
              setSubmitting(false);
              setStatus(`{t("PasswordEntry.Notif.WrongPassword")}`);
        } else {
          //get user ID
          resetPassword(token.uid, newpassword, (responseCode: number, responseJson: JSON)=> {
            console.log(responseCode)
            console.log(responseJson)
            if(responseCode === 200){
              //update isUsed token to true
              console.log("Token ID : " + token.uid)
              updateIsUsedToken(token.uid);
              //update lastPasswordChangeAt user to now
              updateUser({id: token.uid, lastPasswordChangeAt: Timestamp.now()} as User);

              setHasErrors(false);
              setLoading(false);
              console.log("success change password");
              nav("/auth/reset-password-success");
            } else {
              console.log("gagal")
              setLoading(false);
              setSubmitting(false);
              // setStatus(`{t("PasswordEntry.Notif.WrongPassword")}`);
              nav("/error/500")
            }
          }).catch(() => {
            console.log("error")
            setLoading(false);
            setSubmitting(false);
            nav("/error/500");
          });
        }
        
      }, 1000);
    },
  });

    return (
      <div>
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          // noValidate
          id="reset-password-form"
        >
          {/* begin::Heading */}
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">
              {t("PasswordEntry.Info.ChangePassword")}
            </h1>
          </div>
          {hasErrors === true && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                {t("PasswordEntry.Notif.WrongPassword")}
              </div>
            </div>
          )}
          {/* begin::Heading */}
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">
              {t("PasswordEntry.Input.NewPassword")}
            </label>
            <TextInput
              id="rpassword-new"
              type="password"
              formcontrol={"solid"}
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password && (
              <div
                className="fv-plugins-message-container"
                id="rpassword-error"
              >
                <span role="alert">{t(`${formik.errors.password}`)}</span>
              </div>
            )}
          </div>
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">
              {t("PasswordEntry.Input.ConfirmPassword")}
            </label>
            <TextInput
              id="rpassword-confirm"
              type="password"
              formcontrol={"solid"}
              autoComplete="off"
              {...formik.getFieldProps("confirmpassword")}
              onPaste={(e: Event) => {
                e.preventDefault();
                return false;
              }}
            />
            {formik.touched.confirmpassword && formik.errors.confirmpassword && (
              <div
                className="fv-plugins-message-container"
                id="rpassword-confirmerror"
              >
                <span role="alert">
                  {t(`${formik.errors.confirmpassword}`)}
                </span>
              </div>
            )}
          </div>
          <div className="text-center">
            <Button
              id="rpassword-submit"
              btnlg="primary"
              type="submit"
              cName="w-75"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className="indicator-label">
                {t("ForgotPassword.Button.Submit")}
              </span>
              {loading && (
                <span className="indicator-progress">
                  {t("Login.Button.Loading")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </div>
        </form>
      </div>
    );
}
