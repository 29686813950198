import { FC } from "react";
import format from "date-fns/format";
import firebase from "firebase/compat/app";

type Props = {
  value?: firebase.firestore.Timestamp;
  className?: string;
};

const CellDate: FC<Props> = ({ value, className }) => {
  let valueStr = " - ";
  if (value && "seconds" in value) {
    valueStr = format(new Date(value.seconds * 1000), "d MMM yyyy, HH:mm");
  }
  console.log(valueStr, "data value str");
  return <div className={className ?? ""}>{valueStr}</div>;
};

export default CellDate;
