import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { Campaign } from "../../../../../models/Campaign";
import { CampaignActionsCell } from "./CampaignActionsCell";
import { CampaignCompanyCell } from "./CampaignCompanyCell";
import { CampaignCustomHeader } from "./CampaignCustomHeader";
import { CampaignInfoCell } from "./CampaignInfoCell";
import CellDate from "../../../../../components/table/columns/CellDate";
import { CampaignNonCustomHeader } from "./CampaignNonCustomHeader";
import { CampaignTemplateInfoCell } from "./CampaignTemplateInfoCell";
import { CampaignTemplateStatusCell } from "./CampaignTemplateStatusCell";
import { CampaignTwoStepsCell } from "./CampaignTwoStepsCell";

//props.data[props.row.index].updatedAt

const campaignsColumns: ReadonlyArray<Column<Campaign>> = [
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Campaign.Input.CampaignName"
      />
    ),
    id: "campaignName",
    Cell: ({ ...props }) => (
      <CampaignInfoCell campaign={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CampaignNonCustomHeader
        tableProps={props}
        title="Campaign.Column.TemplateLanguage"
      />
    ),
    // Header: (props) => (<th className='min-w-125px'>"Campaign.Column.TemplateLanguage"</th>),
    id: "templateLanguage",
    Cell: ({ ...props }) => (
      <CampaignTemplateStatusCell
        template={props.data[props.row.index].template}
        statusCode={props.data[props.row.index].statusCode}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Campaign.Column.TemplateName"
      />
    ),
    id: "templateName",
    Cell: ({ ...props }) => (
      <CampaignTemplateInfoCell
        template={props.data[props.row.index].template}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        // title="Campaign.Column.LastSync"
        title="Common.Column.CompanyName"
      />
    ),
    id: "CompanyName",
    Cell: ({ ...props }) => (
      <CampaignCompanyCell campaign={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Campaign.Column.LastUpdated"
      />
    ),
    id: "lastUpdatedAt",
    Cell: ({ ...props }) => (
      <CellDate
        value={props.data[props.row.index].updatedAt}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader
        tableProps={props}
        title="Common.Actions"
        className="text-end"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <CampaignActionsCell
        id={props.data[props.row.index].id}
        indexId={props.row.index}
        campaign={props.data[props.row.index]}
      />
    ),
  },
];

export { campaignsColumns };
