import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import Company from "./Company";

export interface ClientData {
  id: string;
  avatar?: string;
  clientName?: string;
}
export interface User {
  avatar?: string;
  gender?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  uid?: string;
  address?: string;
  email: string;
  isActive?: boolean;
  companyID?: string;
  last_changed?: Date;
  name: string;
  phoneNumber: number;
  session_id?: string;
  sessiontoken?: string;
  state?: string;
  clientData: ClientData;
  lastPasswordChangeAt?: firebase.firestore.Timestamp;
  friendList?: DocumentReference[];
  user_companies?: Array<UserCompanies>;
  countryCode?: string;
  role?: UserRoles;
  registeredAt?: firebase.firestore.Timestamp;
  invitedAt?: firebase.firestore.Timestamp;
  inviteExpiredAt?: firebase.firestore.Timestamp;
  referralCode?: string;
  createdBy?: firebase.firestore.DocumentReference;
  verifyExpiredAt?: firebase.firestore.Timestamp;
  verifyAt?: firebase.firestore.Timestamp;
  status?: string;
  notificationToken?: string;
}

export enum UserRoles {
  admin = "admin",
  user = "user",
}

export interface UserCompanies {
  client: DocumentReference;
  id: string;
  addedAt: firebase.firestore.Timestamp;
  company: DocumentReference;
  companyModel?: Company;
  companyName: string;
  isActive: boolean;
}

export enum UserStatus {
  invited = "invited",
  registered = "registered",
}

export default User;
