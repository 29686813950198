import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../core/PageData";
import { useTranslation } from "react-i18next";
import * as ReduxProduct from "../../../modules/product/redux/ProductSlice";
import { useDispatch } from "react-redux";
import { ProductListWrapper } from "./product-list/ProductList";
import * as ReduxShipment from "../../../modules/product/shipment/redux/ShipmentsSlice";
import { useEffect } from "react";
import { cleanReduxProductList } from "../../../modules/product/productList/ProductListSlice";

const ProductListPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("StorefrontList.Column.Product"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(cleanReduxProductList(true));
  // }, [])

  if (ReduxProduct) {
    dispatch(ReduxProduct.cleanReduxProduct(true));
    dispatch(ReduxShipment.cleanReduxShipment(true));
  }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                {`${t("StorefrontList.Column.Product")}`}
              </PageTitle>
              <ProductListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/product/list" />} />
    </Routes>
  );
};

export default ProductListPage;
