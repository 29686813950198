import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, { StorefrontListAction } from "./StorefrontListRedux";
import Storefront from "../../../../models/Storefront"


const initialState: InitialState = {
  listStorefront: [],
  countStorefront: 0,
  currentPageStorefront: 0,
  maxPageStorefront: 0,
  arrayStorefrontPagination: [],
};

export const StorefrontListSlice = createSlice({
  name: StorefrontListAction,
  initialState: initialState,
  reducers: {
    setListStorefront: (state, action: PayloadAction<Storefront[]>) => {
      state.listStorefront = action.payload;
      state.countStorefront = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayStorefrontPagination = arr;
      } else {
        state.arrayStorefrontPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayStorefrontPagination: (state, action: PayloadAction<string[]>) => {
      state.arrayStorefrontPagination = action.payload;
    },

    setCountStorefront: (state, action: PayloadAction<number>) => {
      state.countStorefront = action.payload;
    },

    setCurrentPageStorefront: (state, action: PayloadAction<number>) => {
      state.currentPageStorefront = action.payload;
    },

    setMaxPageStorefront: (state, action: PayloadAction<number>) => {
      state.maxPageStorefront = action.payload;
    },

    updateListStorefront: (state, action: PayloadAction<Storefront>) => {
      let newListStorefront: Storefront[] = [];
      const newStorefront = action.payload;
      newListStorefront.push(newStorefront);

      for (let index = 0; index < state.listStorefront.length; index++) {
        if (state.listStorefront[index].id === newStorefront.id) {
          console.log("Collab ID from Notif: " + state.listStorefront[index].id);
        } else {
          newListStorefront.push(state.listStorefront[index]);
        }
      }
      state.listStorefront = newListStorefront;
      state.countStorefront = newListStorefront.length;
    },

    deleteItemListStorefront: (state, action: PayloadAction<string>) => {
      let newDataCollab = state.listStorefront.filter(
        (data) => data.id !== action.payload
      );
      state.listStorefront = newDataCollab;
      state.countStorefront = newDataCollab.length;
    },

    cleanReduxStorefrontList: (state, action: PayloadAction<boolean>) => {
      state.listStorefront = [];
      state.countStorefront = 0;
      state.currentPageStorefront = 0;
      state.maxPageStorefront = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setListStorefront,
  deleteItemListStorefront,
  updateListStorefront,
  setCurrentPageStorefront,
  setArrayStorefrontPagination,
  setMaxPageStorefront,
  setCountStorefront,
  cleanReduxStorefrontList,
} = StorefrontListSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default StorefrontListSlice.reducer;