import InitialState from "./CompanyRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
  countCustomerInQueue: 0,
};

export const CompanySlice = createSlice({
  name: "CompanySlice",
  initialState: initialState,
  reducers: {
    setCountCIQ: (state, action: PayloadAction<number>) => {
      state.countCustomerInQueue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCountCIQ
} = CompanySlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default CompanySlice.reducer;
