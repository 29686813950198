import firebase from "firebase/compat/app";
import db from ".";
import Campaign, { Language as CampaignLanguage } from "../app/models/Campaign";
import * as conn from "./connection";
import { createRef } from "./connection";
import { UserCompanies } from "../app/models/User";

export const getAccessibleCampaign = async (clientId: string, companyAccesses: UserCompanies[]) => {
  let query = db.collection("campaigns").where("client", "==", createRef("clients", clientId)).where("isActive", "==", true);

  const hasAllCompanyAccess = companyAccesses.find((accessCompany) => accessCompany.id === "*");
  //if user does not have access to all company, get by company
  if (!hasAllCompanyAccess) {
    const companyAccessQuery = companyAccesses.map((company) => createRef("company", company.id));
    query = query.where("company", "in", companyAccessQuery);
  }

  return query
    .orderBy("updatedAt", "desc")
    .get()
    .then((snaps) => {
      const campaignList = snaps.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Campaign)
      );
      return campaignList;
    })
    .catch((error) => {
      Promise.reject(error);
      console.log("Erorr getAccessibleCampaign : " + error);
      return [] as Campaign[];
    });
};

// export const fetchAccountTypeListByClient = async (client: string) => {

//   return await db
//     .collection("accounts")
//     .where("client", "==", client)
//     .get()
//     .then((snaps) => {
//       const accountTypeList = snaps.docs.map((doc) => ({
//         type: doc.data().type,
//       }));
//       return accountTypeList;
//     })
//     .catch((error) => {
//       //TODO
//       console.log("Erorr fetchAccountTypeListByClient : " + error);
//     });
// }

export const createCampaign = async (campaignData: any) => {
  let campaignId;
  console.log(campaignData, "____ini data yg kesave");

  await db
    .collection("campaigns")
    .add(campaignData)
    .then((ref) => {
      console.log("New Campaign ID : " + ref.id);
      campaignId = ref.id;
      return ref.id;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  return campaignId;
};

export const setCampaignSubCollection = async (campaignID: string, languageID: string, data: any) => {
  console.log("Set Campaign Sub Collection. . ");

  let newData = { ...data };

  // check undefined
  if (newData.bodyParam === undefined) newData = { ...newData, bodyParam: [] };
  if (newData.header === undefined) newData = { ...newData, header: { ...newData.header, headerParam: [] } };
  if (newData.buttons === undefined) newData = { ...newData, buttons: { ...newData.buttons, buttons: [] } };

  console.log(`Hasil check undefined 
  >> data >> ${JSON.stringify(data)}
  >> newData >> ${JSON.stringify(newData)}
  `);
  return await db
    .collection("campaigns")
    .doc(campaignID)
    .collection("languages")
    .doc(languageID)
    .set(
      {
        ...newData,
      },
      {
        merge: true,
      }
    )
    .then((doc) => {
      console.log("Added Campaign Subcollection language: " + JSON.stringify(doc));
    })
    .catch((err) => {
      console.log("Error set campaign sub collection : ", err);
    });
};

export const getCampaignById = async (campaignId: string) => {
  return await db
    .collection("campaigns")
    .doc(campaignId)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.error("No such document >> campaign!");
      } else {
        const campaign = { id: doc.id, data: doc.data() }; //;
        // console.log("Campaign in Firebase: "+JSON.stringify(campaign));
        return campaign; //{...campaign, id: doc.id}
      }
    });
};

export const updateCampaignById = async (campaignId: string, campaignData: any) => {
  console.log("Update Campaign... ");

  return await db
    .collection("campaigns")
    .doc(campaignId)
    .set(
      {
        ...campaignData,
      },
      {
        merge: true,
      }
    )
    .then((doc) => {
      console.log("Updated: " + doc);
      return campaignId;
    })
    .catch((err) => {
      console.log("Error update campaign data : ", err);
    });
};

export const updateScheduleByCampaign = async (campaignId: string, scheduleData: any) => {
  console.log("update Schedule... by ", campaignId);
  const campaignRef = conn.createRef("campaigns", campaignId);
  return await db
    .collection("schedules")
    .where("campaign", "==", campaignRef)
    .get()
    .then(async (snap) => {
      if (snap.docs.length <= 0) {
        console.error("Error updateScheduleByCampaign, schedule not found");
        return undefined;
      }
      const doc = snap.docs[0];

      return await doc.ref
        .set(scheduleData, { merge: true })
        .then(() => {
          return doc.id;
        })
        .catch((error) => {
          console.error("Error updateScheduleByCampaign", error);
          return undefined;
        });
    })
    .catch((error) => {
      console.error("Error updateScheduleByCampaign", error);
      return undefined;
    });
};

export const createCampaignSchedule = async (campaignId: string, schedule: any) => {
  let scheduleId;
  const campaignRef = conn.createRef("campaigns", campaignId);
  // console.log("Create Schedule Campaign... "+campaignId);

  return await db
    .collection("schedules")
    // .add(schedule)
    .add({
      ...schedule,
      campaign: campaignRef,
    })
    .then((ref) => {
      console.log("New Schedule ID : " + ref.id);
      scheduleId = ref.id;
      return scheduleId;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export const updateCountTarget = async (campaignId: string, previewsDataLength: number) => {
  return firebase
    .database()
    .ref(`campaigns/${campaignId}`)
    .set(
      {
        countCreated: previewsDataLength,
        updatedAt: new Date().getTime(),
      },
      (error) => {
        if (error) {
          console.log(`Error Update Count Messages ${error}`);
        } else {
          console.log(`Success Update Count Messages`);
        }
      }
    );
};

export const addInitialMessageOnCampaign = async (campaignId: string, previewsData: any[], body: string | undefined, status: string) => {
  const batch = db.batch();

  let initialMessage = {
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    message: body,
    status: status ?? "SCHEDULED",
  };

  console.log("PREVIEWS DATA");

  previewsData.forEach(({ "customers.phoneNumber": phoneNumber }) => {
    let message = {
      ...initialMessage,
      phoneNumber,
    };
    let docRef = db.collection("campaigns").doc(campaignId).collection("messages").doc();
    batch.set(docRef, message);
  });
  await batch.commit();
};

export const fetchMessagesByCampaignID = async (campaignId: string) => {
  return await db
    .collection("campaigns")
    .doc(campaignId)
    .collection("messages")
    // .withConverter(converter2<CampaignMessage>())
    .get()
    .then((snaps) => {
      const messages = snaps.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      return messages;
    })
    .catch((error) => {
      //TODO
      console.log("Erorr fetch Campaign Messages : " + error);
    });
};

export const countCampaignMessagesByCampaignId = async (campaignId: string) => {
  // console.log(`Check masuk countReport >> ${JSON.stringify(campaignId)}`);

  return await firebase
    .database()
    .ref("/campaigns/" + campaignId)
    .once("value")
    .then((snapshot) => {
      // console.log(`Check result query countReport >> ${JSON.stringify(snapshot.val)}`);

      const data = snapshot.val();
      return data;
    });
};

export const fetchCampaignLanguageByCampaignIDAndLanguage = async (campaignId: string, language: string) => {
  return await db
    .collection("campaigns")
    .doc(campaignId)
    .collection("languages")
    .doc(language)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.error("No such document! >> campaignLanguage");
      } else {
        const campaignParam = { ...doc.data(), id: doc.id };
        return campaignParam as CampaignLanguage;
      }
    });
};

export const getCampaignByName = async (
  campaignName: string,
  campaignId: string,
  clientId: string
  // company: firebase.firestore.DocumentReference
) => {
  let query = db
    .collection("campaigns")
    .where("isActive", "==", true)
    .where("campaignName", "==", campaignName)
    .where("client", "==", createRef("clients", clientId));
  if (campaignId || campaignId !== "") query = query.where(firebase.firestore.FieldPath.documentId(), "!=", campaignId);

  return await query
    .get()
    .then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        console.log("Document Exist");
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.error("Erorr getCampaignByName : ", err);
      return false;
    });
};
