import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import * as Chat from "../../../../actions/chat";
import * as userAct from "../../../../actions/user";
import { RootState } from "../../../../setup/redux/store";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import * as lc from "../../../modules/localstorage/index";
import ChatItem from "./ChatItem";
import db, { createRef, functions, newDB, realtimedb } from "src/db";
import { DocumentSnapshot } from "firebase-admin/firestore";
import { debounce } from "lodash";
import { Collaboration, Room } from "src/app/models/Chat";
import { collection, doc, documentId, getCountFromServer, query, Timestamp, where } from "firebase/firestore";
import { error } from "console";
import { ChatType } from "src/app/models/Message";
import { el } from "date-fns/locale";
import { useTranslation } from "react-i18next";
type ComponentProps = {
  selected?: string;
  type?: string;
  filter?: string;
  onChatOpen?: (collabs: Collaboration) => void;
};

type ChatData = {
  id: string;
  lastInteractionAt: {
    seconds: number;
    nanoseconds: number;
  };
  toUser: Array<any>;
  roomsModel: Array<any>;
  toUserModel: Array<any>;
  [key: string]: any;
};

type FetchChatDataResult = {
  data: Collaboration[];
  preliminaryData?: Collaboration[];
  filteredDocs?: FirebaseFirestore.QueryDocumentSnapshot[];
};

// helper untuk mencegah ada duplikat by id 
export const uniqueById = (items: any) => {
  const set = new Set();
  return items.filter((item: any) => {
    const isDuplicate = set.has(item.id);
    set.add(item.id);
    return !isDuplicate;
  });
}

// helper untuk sorting desc by last interaction at 
export const sortByLastInteractionAt = (data: any) => {
  return data.sort((a: any, b: any) => {
    const aTime = a?.lastInteractionAt?.seconds * 1000 + a?.lastInteractionAt?.nanoseconds / 1000000;
    const bTime = b?.lastInteractionAt?.seconds * 1000 + b?.lastInteractionAt?.nanoseconds / 1000000;
    return bTime - aTime;
  });
};

// function dijalanan untuk fetch ke subcolection rooms 
export const fetchRooms = async (collabDoc: Collaboration, user: any, type: any) => {
  let arr_room: any[] = [];
  if (collabDoc) {
    let query;

    if (type === ChatType.mychat) {
      query = db
        .collection("collaborations")
        .doc(collabDoc.id)
        .collection("rooms")
        .where("status", "==", "handled")
        .where("toUser", "array-contains", createRef("users", user.uid))
    } else {
      query = db
        .collection("collaborations")
        .doc(collabDoc.id)
        .collection("rooms")
        .where("status", "==", "handled")
    }

    let roomData = await query.get()
    if (!roomData.empty) {
      for (const roomDoc of roomData.docs) {
        let datasRoom = {
          id: roomDoc?.id,
          ...roomDoc.data(),
        };
        arr_room.push(datasRoom);
      }
    }
  }
  return arr_room;
};

// function digunakan untuk update status isMessageRead menjadi true
export const markAsReadMessage = async (selectedChat: string, selectedRoom: any[]) => {
  const clientId = lc.getItemLC(lc.LCName.Client)?.id;
  const userId = lc.getItemLC(lc.LCName.User)?.uid;
  if (selectedChat !== "") {
    try {
      await Promise.all(selectedRoom.map(async (x: any) => {
        let fieldName = `${x?.accountData?.type}/unreadMessages`
        const updates = {
          unreadMessages: 0,
          [fieldName]: 0,
        }
        const path = `/collaborations/${selectedChat}`;
        const dataRef = realtimedb.ref(path);
        await dataRef.update(updates);
        const querySnapshot = await db
          .collection("messages")
          .where("client", "==", createRef("clients", clientId))
          .where('room', '==', db.collection("collaborations").doc(selectedChat).collection("rooms").doc(x?.id))
          .where("destination", "==", "inbound")
          .where("isActive", "==", true)
          .where("isMessageRead", "==", false)
          .get();

        const batch = db.batch();
        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          batch.update(docRef, { isMessageRead: true });
        });
        await batch.commit();

        let accountData = await (await db.collection("account").doc(x?.account?.id).get()).data()
        let waba_phoneNumber = accountData?.whatsappNumber
        if (x?.phoneNumber) {
          let arr_message_ids: string[] = querySnapshot.docs.map(doc => doc.id);
          let arr_company_ids: string[] = querySnapshot.docs.map(doc => doc.data().company)
          let company: any = arr_company_ids[0]
          let companyId = company?.id
          const json_request: any = {
            uid: userId,
            type: "whatsapp",
            client: clientId,
            company: companyId,
            phoneNumber: waba_phoneNumber || "",
            messages: arr_message_ids?.length > 0 ? arr_message_ids : [],
          };
          console.log(json_request, "<<< json_request markasread dani")
          if (json_request.messages.length > 0) {
            console.log(
              `json_request to markAsReadMessages : ${JSON.stringify(json_request)}`
            )
            await Chat.requestMarkAsReadMessage(json_request);
          }
        }
      }));
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  }
};

const ChatList: FC<ComponentProps> = ({ type, filter, selected, onChatOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [newListDraft, setNewListDraft] = useState<any[]>([]);
  const [lastDataFilteredAllChat, setlastDataFilteredAllChat] = useState<any[]>([]);
  let {
    selectedChat,
    selectedRoom,
    selectedCollaboration,
    allChatSelectedChat,
    allChatSelectedRoom,
    allChatSelectedCollaboration,
    chatState
  } = useSelector(
    (state: RootState) => state.Chat
  );

  let collaboration = chatState === ChatType.mychat ? selectedCollaboration : allChatSelectedCollaboration
  let chatSelected = chatState === ChatType.mychat ? selectedChat : allChatSelectedChat
  let roomSelected = chatState === ChatType.mychat ? selectedRoom : allChatSelectedRoom
  let roomsModel = collaboration?.roomsModel?.filter((room: any) => room?.status === "handled" &&
    room.id === roomSelected) as Room[];
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadingFirstLoad, setLoadingFirstLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchNext, setFetchNext] = useState<boolean>(false);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [handledCIQ, setHandledCIQ] = useState<string>("")
  const [notifications, setNotifications] = useState([]);
  const [notifTrigger, setNotifTrigger] = useState(0);
  const currentUser = lc.getItemLC(lc.LCName.User);
  const currentClient = lc.getItemLC(lc.LCName.Client);
  const user = {
    company: lc.getItemLC(lc.LCName.CompanyID),
    uid: currentUser?.uid,
    clientid: currentClient?.id,
  };

  const isMountedRef = useRef<boolean>(true);

  // function dijalankan untuk fetch collaboration by id bagi chat yg sudah lama dihandle di ciq 
  const fetchChatDataById = useCallback(async (CollabId: string) => {
    let query = db.collection("collaborations").doc(CollabId)
    const querySnapshot = await query.get();

    if (querySnapshot) {
      let data = { id: querySnapshot.id, ...querySnapshot.data() } as Collaboration | any
      const path = `collaborations/${querySnapshot.id}`;
      const dataRef = realtimedb.ref(path);
      const snapshot = await dataRef.once('value');
      const updatedData = snapshot.val();
      let notifTotal = 0;
      if (updatedData) {
        notifTotal += updatedData.unreadMessages || 0;
        notifTotal += updatedData.tokopedia?.unreadMessages || 0;
        notifTotal += updatedData.whatsapp?.unreadMessages || 0;
        notifTotal += updatedData.lazada?.unreadMessages || 0;
        notifTotal += updatedData.shopee?.unreadMessages || 0;
      }
      data = {
        id: querySnapshot.id,
        unreadMessagesCount: notifTotal,
        ...querySnapshot.data(),
      };
      setNewListDraft([data, ...newListDraft])
    }
  }, [type, filter, user.uid]);

  // function dijalanan untuk melakukan fetch ke collaboration 
  const fetchChatData = useCallback(async (lastInteractionAt: Timestamp | Date | null): Promise<FetchChatDataResult> => {
    let query;

    if (type === ChatType.mychat) {
      query =
        filter && filter !== ""
          ? db.collection("collaborations")
            .where("status", "==", "handled")
            .where("toUser", "array-contains", createRef("users", user.uid))
            .where("profileName", ">=", filter)
            .where("profileName", "<=", filter + "\uf8ff")
            .orderBy("profileName")
            .orderBy("lastInteractionAt", "desc")
            .startAfter(lastInteractionAt)
          // .limit(15)
          : db.collection("collaborations")
            .where("status", "==", "handled")
            .where("toUser", "array-contains", createRef("users", user.uid))
            .orderBy("lastInteractionAt", "desc")
            .startAfter(lastInteractionAt)
            .limit(17);
    } else if (type === ChatType.allchat) {
      query =
        filter && filter !== ""
          ? db.collection("collaborations")
            .where("status", "==", "handled")
            .where("profileName", ">=", filter)
            .where("profileName", "<=", filter + "\uf8ff")
            .orderBy("profileName")
            .orderBy("lastInteractionAt", "desc")
            .startAfter(lastInteractionAt)
          // .limit(15)
          : db.collection("collaborations")
            .where("status", "==", "handled")
            .orderBy("lastInteractionAt", "desc")
            .startAfter(lastInteractionAt)
            .limit(17)
    }

    if (query) {
      const querySnapshot = await query.get();
      const docs = querySnapshot.docs;

      if (type === ChatType.allchat) {
        const preliminaryData = docs.map((doc) => ({ id: doc.id, ...doc.data() })) as ChatData[];
        const filteredDocs = docs.filter((doc) => {
          const toUserArray = doc.data().toUser?.map((x: any) => x.id) || [];
          return !toUserArray.includes(user.uid);
        });

        let processData = filteredDocs.map(async (doc, index) => {
          const path = `collaborations/${doc.id}`;
          const dataRef = realtimedb.ref(path);
          const snapshot = await dataRef.once('value');
          const updatedData = snapshot.val();
          let notifTotal = 0;
          if (updatedData) {
            notifTotal += updatedData.unreadMessages || 0;
            notifTotal += updatedData.tokopedia?.unreadMessages || 0;
            notifTotal += updatedData.whatsapp?.unreadMessages || 0;
            notifTotal += updatedData.lazada?.unreadMessages || 0;
            notifTotal += updatedData.shopee?.unreadMessages || 0;
          }
          return {
            id: doc.id,
            unreadMessagesCount: notifTotal,
            ...doc.data(),
          };
        })
        let data = await Promise.all(processData);

        if (data.length < 17 && preliminaryData.length > 0 && filter === "") {
          const remainingLimit = 17 - data.length;
          const additionalData = await fetchChatData(new Date(preliminaryData[preliminaryData.length - 1].lastInteractionAt.seconds * 1000));
          return {
            data: data.concat(additionalData?.data?.slice(0, remainingLimit) as any),
          } as any;
        }
        return { data: sortByLastInteractionAt(data) };
      } else {
        let processData = docs.map(async (doc, index) => {
          const path = `collaborations/${doc.id}`;
          const dataRef = realtimedb.ref(path);
          const snapshot = await dataRef.once('value');
          const updatedData = snapshot.val();
          let notifTotal = 0;
          if (updatedData) {
            notifTotal += updatedData.unreadMessages || 0;
            notifTotal += updatedData.tokopedia?.unreadMessages || 0;
            notifTotal += updatedData.whatsapp?.unreadMessages || 0;
            notifTotal += updatedData.lazada?.unreadMessages || 0;
            notifTotal += updatedData.shopee?.unreadMessages || 0;
          }
          return {
            id: doc.id,
            unreadMessagesCount: notifTotal,
            ...doc.data(),
          };
        })
        let data = await Promise.all(processData);

        return { data: sortByLastInteractionAt(data) };
      }
    }

    if (filter && filter !== "") { setHasMore(false) } else { setHasMore(true) }

    return { data: [] };
  }, [type, filter, user.uid]);

  // function dijalankan untuk memuat list chat yang lebih lama 
  const fetchNextPage = useCallback(
    debounce(async (lastInteractionAt: Timestamp | Date | null) => {
      if (!hasMore) return;
      try {
        setLoading(true);
        setFetchNext(true);
        const { data }: any = await fetchChatData(lastInteractionAt);
        if (type === ChatType.allchat) {
          if (data.length < 17) {
            setlastDataFilteredAllChat(data);
          } else {
            setlastDataFilteredAllChat([]);
          }
          setNewListDraft((prev) => {
            return sortByLastInteractionAt(uniqueById([...prev, ...data]));
          });
        } else {
          setNewListDraft((prev) => {
            return sortByLastInteractionAt(uniqueById([...prev, ...data]));
          });
        }

        if (data.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
        setFetchNext(false);
      }
    }, 300),
    [loading, hasMore, type, filter, newListDraft, user.uid, lastDataFilteredAllChat]
  );

  // function berjalan ketika klik chat untuk membuka pesan/message 
  const chatOpenHandler = useCallback(async (collabs: Collaboration) => {
    try {
      const arr_room = await fetchRooms(collabs, user, type);
      const collabs_rooms = {
        ...collabs,
        roomsModel: arr_room,
      };
      if (onChatOpen) {
        dispatch(chat.setReplyToMessage(""));
        if (type !== ChatType.allchat) {
          await markAsReadMessage(collabs?.id, arr_room);
          dispatch(chat.setSelectedChat(collabs?.id));
          dispatch(chat.setSelectedRoom(arr_room[0]?.id));
          dispatch(chat.setSelectedCollaboration(collabs));
        } else {
          dispatch(chat.setAllChatSelectedChat(collabs?.id));
          dispatch(chat.setAllChatSelectedRoom(arr_room[0]?.id));
          dispatch(chat.setAllChatSelectedCollaboration(collabs));
        }
        await onChatOpen(collabs_rooms);
      }
    } catch (error) {
      console.error("Error in chatOpenHandler:", error);
    }
  }, [type, chatSelected, handledCIQ]);

  // function dijalankan ketika list chat di scroll 
  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    setScrollHeight(scrollTop);
    if (scrollTop + clientHeight >= scrollHeight - 17 && filter === "") {
      fetchNextPage(new Date(newListDraft[newListDraft?.length - 1].lastInteractionAt?.seconds * 1000));
    }
  }, [fetchNextPage, newListDraft]);

  // function dijalankan ketika klik button panah atas 
  const handleTop = useCallback(() => {
    document.getElementById("chatlist")?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const textArea = document.getElementById("chatTextMessage");
    textArea?.focus();
  }, []);

  // useeffect untuk mounting
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // useeffect untuk trigger load ketika ada notif chat masuk 
  useEffect(() => {
    const array = lc.getItemLC("Notify");
    if (array && array.length > 1) {
      const sortedArray = array?.reverse();
      setNotifications(sortedArray);
    }

    function storageEventHandler(event: any) {
      const array = lc.getItemLC("Notify");
      if (array) {
        const sortedArray = array.reverse();
        setNotifications((prevNotifications) => {
          if (
            JSON.stringify(prevNotifications) !== JSON.stringify(sortedArray)
          ) {
            setNotifTrigger((prev) => prev + 1);
            return sortedArray;
          }
          return prevNotifications;
        });
      }
    }

    window.addEventListener("storageNotif", storageEventHandler);

    return () => {
      window.removeEventListener("storageNotif", storageEventHandler);
    };
  }, [notifTrigger]);

  // useeffect untuk fetch data list chat
  useEffect(() => {
    if (fetchNext) return;
    isMountedRef.current = true

    const fetchData = async () => {
      try {
        setLoading(true);
        setLoadingFirstLoad(true);
        setHasMore(true);
        const { data }: any = await fetchChatData(new Date());
        if (isMountedRef.current) {
          setNewListDraft(sortByLastInteractionAt(data));
          setLoadingFirstLoad(false);
          setLoading(false);
          setHandledCIQ("")
        }
      } catch (error) {
        console.error("Error getting documents: ", error);
      } finally {
        if (isMountedRef.current) {
          setLoadingFirstLoad(false);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMountedRef.current = false;
    };
  }, [type, filter, user.uid, handledCIQ, notifTrigger]);

  // useeffect menangani handle by ciq 
  useEffect(() => {
    const handleChatByCIQ = async () => {
      if (selectedChat && selectedChat !== "") {
        setHandledCIQ(selectedChat)
        const matchingChat = newListDraft?.find(chat => chat?.id === selectedChat);
        if (matchingChat) {
          chatOpenHandler(matchingChat);
        } else {
          await fetchChatDataById(selectedChat)
        }
      }
    }
    handleChatByCIQ()
  }, []);

  // useeffect update counter notif bubble 
  useEffect(() => {
    if (!newListDraft || newListDraft.length === 0) return;
    const listeners = newListDraft?.map((item: any, index: number) => {
      const path = `/collaborations/${item.id}`;
      const dataRef = realtimedb.ref(path);

      const handleData = (snapshot: any) => {
        let data = snapshot.val()
        let notifTotal: number = 0
        if (data) {
          notifTotal += data?.unreadMessages || 0
          notifTotal += data?.tokopedia?.unreadMessages || 0
          notifTotal += data?.whatsapp?.unreadMessages || 0
          notifTotal += data?.lazada?.unreadMessages || 0
          notifTotal += data?.shopee?.unreadMessages || 0
        }
        if (item?.profileName === "Rico_53") {
          console.log(data, notifTotal, item, "check data ujq")
        }
        // item = {
        //   ...item,
        //   unreadMessagesCount: notifTotal,
        // }
        item.unreadMessagesCount = notifTotal
      };

      // Set up the listener for real-time updates
      dataRef.on('value', handleData);

      // Return a function to remove this listener
      return () => dataRef.off('value', handleData);
    });

    // Cleanup function to remove all listeners
    return () => {
      listeners.forEach(removeListener => removeListener());
    };
  }, [newListDraft, chatSelected]);

  return (
    <div
      id="kt_chat_contacts_body"
      style={{ overflow: "hidden" }}
      data-testid="chat-contacts-body"
      key={"chatlist"}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          border: "1px",
          overflowY: "scroll",
        }}
        id="chatlist"
        onScroll={handleScroll}
      >
        {loadingFirstLoad && (
          <div className="d-flex w-100 flex-row align-items-center justify-content-center" style={{
            height: '50px'
          }}>
            <span
              className="indicator-progress bg-white"
              style={{ display: "block" }}
            >
              <span className="spinner-border spinner-border-md align-middle m-2"></span>
            </span>
          </div>
        )}
        {newListDraft?.map((chatListItem: any) => {
          return (
            <div
              onClick={() => chatOpenHandler(chatListItem)}
              key={chatListItem?.id}
              id={`chatitem-${chatListItem?.id}`}
            >
              <ChatItem
                item={chatListItem}
                key={chatListItem?.id}
                selected={chatListItem?.id === chatSelected ? true : false}
              />
            </div>
          );
        })}
        {newListDraft.length === 0 &&
          !loading && !loadingFirstLoad &&
          <div className="w-100 d-flex align-items-center justify-content-center" style={{
            height: '70vh'
          }}>
            <h4 className="text-muted">{t("Common.NoData")}</h4>
          </div>
        }
        {loading && !loadingFirstLoad && (
          <div className="d-flex w-100 flex-row align-items-center justify-content-center" style={{
            height: '50px'
          }}>
            <span
              className="indicator-progress bg-white"
              style={{ display: "block" }}
            >
              <span className="spinner-border spinner-border-md align-middle m-2"></span>
            </span>
          </div>
        )}
        {hasMore === false && (
          <>
            <div className="d-flex w-100 flex-row align-items-center justify-content-center p-0 m-0">
              <p className="px-0 pt-0 pb-4 m-0 text-muted">
                you've reached end of data
              </p>
            </div>
          </>
        )}
        {scrollHeight > 100 && (
          <div
            className="d-flex position-absolute rounded-circle p-3 bg-primary cursor-pointer"
            style={{
              right: 10,
              bottom: 10,
            }}
            onClick={handleTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              className="text-light-primary"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                d="M9 19v-7H5.483a.2.2 0 0 1-.142-.341L12 5l6.659 6.659a.2.2 0 0 1-.142.341H15v7"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatList;