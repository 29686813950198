import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "../../../../db";
import { Campaign } from "../../../models/Campaign";
import InitialState, { CampaignReportAction } from "./CampaignReportRedux";

const initialState: InitialState = {
  // Campaign Report
  listCampaignReport: [],
  countCampaignReport: 0,
  currentPageCampaignReport: 0,
  maxPageCampaignReport: 0,
  arrayCampaignReportPagination: [],
  selectedCampaign: {
    id: "",
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    isActive: true,
    company: undefined,
    account: undefined,
    campaignName: "",
    template: undefined,
    language: [],
    targetFileURL: "",
  },
  selectedCampaignID: "",
};

export const CampaignReportSlice = createSlice({
  name: CampaignReportAction,
  initialState: initialState,
  reducers: {
    // Campaign Report
    setSelectedCampaign: (state, action: PayloadAction<Campaign>) => {
      state.selectedCampaign = action.payload;
    },

    setSelectedCampaignID: (state, action: PayloadAction<string>) => {
      state.selectedCampaignID = action.payload;
    },

    setSelectedRepTime: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRepTime = action.payload;
    },

    setListCampaignReport: (state, action: PayloadAction<Campaign[]>) => {
      state.listCampaignReport = action.payload;
      state.countCampaignReport = action.payload.length;
      if (action.payload.length / 10 <= 6) {
        let arr: string[] = [];
        for (
          let index = 0;
          index < Math.round(action.payload.length / 10);
          index++
        ) {
          arr.push(index + 1 + "");
        }
        state.arrayCampaignReportPagination = arr;
      } else {
        state.arrayCampaignReportPagination = [
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.round(action.payload.length / 10) + "",
        ];
      }
    },

    setArrayCampaignReportPagination: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.arrayCampaignReportPagination = action.payload;
    },

    setCountCampaignReport: (state, action: PayloadAction<number>) => {
      state.countCampaignReport = action.payload;
    },

    setCurrentPageReport: (state, action: PayloadAction<number>) => {
      state.currentPageCampaignReport = action.payload;
    },

    setMaxPageCampaignReport: (state, action: PayloadAction<number>) => {
      state.maxPageCampaignReport = action.payload;
    },

    updateListCampaignReport: (state, action: PayloadAction<Campaign>) => {
      let newListCampaign: Campaign[] = [];
      const newCampaign = action.payload;
      newListCampaign.push(newCampaign);

      for (let index = 0; index < state.listCampaignReport.length; index++) {
        if (state.listCampaignReport[index].id === newCampaign.id) {
          console.log(
            "Collab ID from Notif: " + state.listCampaignReport[index].id
          );
        } else {
          newListCampaign.push(state.listCampaignReport[index]);
        }
      }
      state.listCampaignReport = newListCampaign;
      state.countCampaignReport = newListCampaign.length;
    },

    deleteItemListCampaignReport: (state, action: PayloadAction<string>) => {
      // dataCollabs = dataCollabs.filter((data) => data.customerModel?.phoneNumber ?.toString().toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
      let newDataCollab = state.listCampaignReport.filter(
        (data) => data.id !== action.payload
      );
      state.listCampaignReport = newDataCollab;
      state.countCampaignReport = newDataCollab.length;
    },

    cleanReduxCampaignReport: (state, action: PayloadAction<boolean>) => {
      state.listCampaignReport = [];
      state.countCampaignReport = 0;
      state.currentPageCampaignReport = 0;
      state.maxPageCampaignReport = 0;
    },

    cleanReduxCampaignReportData: (state, action: PayloadAction<boolean>) => {
      state.selectedCampaign = {
        id: "",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        isActive: true,
        company: undefined,
        account: undefined,
        campaignName: "",
        template: undefined,
        language: [],
        targetFileURL: "",
      };
      state.selectedCampaignID = "";
      state.selectedRepTime = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  // Campaign Report
  setSelectedCampaign,
  setSelectedCampaignID,
  setListCampaignReport,
  deleteItemListCampaignReport,
  updateListCampaignReport,
  setCurrentPageReport,
  setArrayCampaignReportPagination,
  setMaxPageCampaignReport,
  setCountCampaignReport,
  setSelectedRepTime,
  cleanReduxCampaignReport,
  cleanReduxCampaignReportData,
} = CampaignReportSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignReportSlice.reducer;
