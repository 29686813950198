interface MaritalType {
  value: string,
  label: string
}

const MaritalStatus: MaritalType[] = [
  {
    value: "single",
    label: "CD.Option.Single",
  },
  {
    value: "married",
    label: "CD.Option.Married",
  },
];

// const MaritalStatus1 = () : any[] => {
//   const {t} = useTranslation();
//   const maritalStatusData = [{
//     value: "single", label: t("CD.Option.Single") 
//   }, {
//     value: "married", label: t("CD.Option.Married")
//   }]
//   return maritalStatusData;
// }

export default MaritalStatus;