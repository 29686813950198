import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../core/PageData'
import { useTranslation } from "react-i18next";
import * as ReduxStorefront from '../../modules/product/storefront/StorefrontSlice';
import { useDispatch } from 'react-redux';
import { StorefrontListWrapper } from './storefront/storefront-list/StorefrontList';

const StorefrontListPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Storefront.Info.Storefront"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const dispatch = useDispatch();
  if (ReduxStorefront) {
    dispatch(ReduxStorefront.cleanReduxStorefront(true));
  }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                {`${t("Storefront.Info.Storefront")}`}
              </PageTitle>
              <StorefrontListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/storefront/list" />} />
    </Routes>
  );
};

export default StorefrontListPage;
