
interface InitialState {
  status: string;
  isEdit: boolean;
  lastStep: string;
  listenerResponse: any;
  phoneNumbers: string[];
  dataWABA: any;
}
const WABAAccountSignUpAction: string = "WABAAccountSignUpAction";

export type unsubFN = () => void;

export default InitialState;
export { WABAAccountSignUpAction };
