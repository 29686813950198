/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from "../../../../../resources/helpers";
import { getTemplates } from "./_requests";
import Template from "../../../../models/Template";
import { useQueryRequest } from "./QueryRequestProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../setup/redux/store";
import * as TemplateReduxlist from "../../../../modules/template/list/TemplateListSlice";
import * as ReduxTemplate from "../../../../modules/template/redux/TemplateSlice";
import { templateSorting } from "./_sort";
import { Response } from "../../../../../resources/helpers";

export type TemplatesQueryResponse = Response<Template[]>;

const QueryResponseContext =
  createResponseContext<Template>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state, updateState } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listTemplatedata: any = [];
  listTemplatedata = useSelector(
    (state: RootState) => state.TemplateList.listTemplate
  );
  console.log("atasnya template wxx", listTemplatedata);
  let TemplateQueryResponse: TemplatesQueryResponse;

  useEffect(() => {
    console.log("Masuk use effect update query Template >>>>>");
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    setCounter(listTemplatedata.length);
  }, [listTemplatedata]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${counter}`,
    async () => {
      let data: any = [];
      dispatch(ReduxTemplate.cleanReduxTemplate(true));

      //cek redux
      if (!listTemplatedata || listTemplatedata.length <= 0) {
        data = await getTemplates(
          state.sort,
          // "lastUpdatedAt",
          state.order,
          // "desc",
          state.search,
          state.action,
          state.lastId,
          state.items_per_page
        );
        if (data && data.length > 0) {
          dispatch(TemplateReduxlist.setListTemplate(data));
        }
      } else {
        data = listTemplatedata;
      }

      let resultData = templateSorting(
        data,
        state.sort,
        // "lastUpdatedAt",
        state.items_per_page,
        state.action,
        state.order,
        // "desc",
        state.search,
        state.page,
        (lenthData: number) => {
          if (state.search && state.search !== "") {
            dispatch(TemplateReduxlist.setCountTemplate(lenthData));
          }
        }
      );
      if (state.search && state.search !== "") {
      } else {
        dispatch(TemplateReduxlist.setCountTemplate(listTemplatedata.length));
      }
      TemplateQueryResponse = {
        data: resultData,
      };

      return Promise.resolve(TemplateQueryResponse);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
