import db from ".";
import * as conn from "./connection";
import { converter2 } from "./converter";
import firebase from "firebase/compat/app";
import Company from "../app/models/Company";
import WABAccount from "../app/models/WABAccount";
import * as lc from "../app/modules/localstorage/index";
import { createRef } from "./connection";

export const getListByClient = async (
  client: firebase.firestore.DocumentReference
) => {
  return await db
    .collection("whatsappBusinessAccounts")
    .where("client", "==", client)
    .where("isActive", "==", true)
    .orderBy("updatedAt", "desc")
    .withConverter(converter2<WABAccount>())
    .get()
    .then((snaps) => {
      const wabaList = snaps.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      } as WABAccount));
      console.log(`hasil wabalist : ${JSON.stringify(wabaList)}`);
      return wabaList;
    })
    .catch((error) => {
      //
      console.log("Erorr getListWABAccountByClient : " + error);
      return [] as WABAccount[]
    });
};

export const getByID = async (wabaID: string) =>
  await db
    .collection("whatsappBusinessAccounts")
    .doc(wabaID)
    .withConverter(converter2<WABAccount>())
    .get()
    .then((doc) => {
      console.log(`whatsappBusinessAccounts found`);
      return doc.data();
    });

export const updateByID = async (wabaID: string, data: WABAccount) => {
  await db
    .collection("whatsappBusinessAccounts")
    .doc(wabaID)
    .withConverter(converter2<WABAccount>())
    .set(data, { merge: true })
    .then(() => {
      console.log(`whatsappBusinessAccounts updated`);
    });
};

export {
  getListByClient as getListWABAccountByClient,
  getByID as getWABAccountByID,
  updateByID as updateWABAccountByID,
};
