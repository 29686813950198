export default function isPublicIP(ipAddress: string): boolean {
  const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/; // Basic IPv4 regex

  if (ipRegex.test(ipAddress)) {
    const octets = ipAddress.split(".").map(Number);

    // Validate if first Octet out of range
    if (!octets.every((octet) => octet >= 0 && octet <= 255)) {
      return false;
    }

    // Validate if first Octet starts with 10
    if (octets[0] === 10) {
      return false; // Link-local IP address
    }

    // Validate if second Octet starts with 172
    if (octets[0] === 172 && octets[1] >= 16 && octets[1] <= 31) {
      return false; // Link-local IP address
    }

    // Validate if first Octet starts with 192
    if (octets[0] === 192 && octets[1] === 168) {
      return false; // Link-local IP address
    }

    return true; // Public IP address
  }

  return false; // Not a valid IPv4 address
}
