import { sendRequestWABASignUp } from "../../api/server/wabaSignUp";

// Facebook Login with JavaScript SDK
export const launchEmbeddedSignUp = (
  client: string,
  callback?: (code: number, res: any) => void
) => {
  // Launch Facebook login
  FB.login(
    function (response) {
      if (response) {
        console.log(`masuk di dapet response embed sign up`);
        if (response.authResponse !== null) {
          sendRequestWABASignUp(response, client, (code, res) => {
            callback && callback(code, res);
          });
        } else {
          callback && callback(400, response);
        }
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      scope:
        "whatsapp_business_management, whatsapp_business_messaging, business_management",
      extras: {
        feature: "whatsapp_embedded_signup",
        version: 2,
        sessionInfoVersion: 2,
      },
    }
  );
};
