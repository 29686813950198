import clsx from "clsx";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getMarketplaceProductCategory } from "../../../../actions/storefront";
import { KTSVG } from "../../../../resources/helpers";
import { RootState } from "../../../../setup/redux/store";
import { CapitalizedLetter } from "../../../../util/Formatter";
import { productStep } from "../../../models/Product";
import { ShopeeProductCategoryAttributeList } from "../../../models/ShopeeProductCategoryAttribute";
import * as ReduxProduct from "../../../modules/product/redux/ProductSlice";
import * as ReduxMarketplaceProductCategory from "../../product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { categoryAttributes } from "../../product/redux/ProductRedux";
import ModalSelectProductCategory from "../modalSelectProductCategory";

interface MarketplaceCategoryProps {
  title: string;
  step?: string;
  isSubmitted?: any;
  id?: string;
  isChecked?: boolean;
  arrayData?: [[]];
  currentMarketplaceSelected?: any;
  isCurrentMarketplaceHasSelectedCategory: any;
  company?: any;
  reduxMarketplace?: any;
  marketplace?: any;
  setMarketplaces?: any;
  isAllUnchecked?: boolean;
  checkedWithNoCategory?: boolean;
  onConfirmCategory?: (categories: any[]) => void;
}

interface MarketplaceBodyModalProps {
  id?: string;
  finalCategory?: [];
  setFinalCategory?: any;
  arrayData?: [[]];
  company?: string;
  marketplace?: any;
  reduxMarketplace?: any;
  currentSelectedCategories?: any;
}

function hasItemWithKeyAndValue(arr: [], key: string, value: any) {
  return arr.some((item) => {
    return item[key] === value;
  });
}

const findCategoryIndex = (arrays: [], key: string, value: any) => {
  let current = arrays.findIndex((items: any) => {
    return hasItemWithKeyAndValue(items, key, value);
  });
  return current;
};

const adjustChildScrollPosition = () => {
  setTimeout(() => {
    const itemsActive: any = document.getElementsByClassName("active-items");
    if (itemsActive.length > 0) {
      for (let i = 0; i < itemsActive.length; i++) {
        const childPos = itemsActive[i];
        childPos.scrollIntoView();
      }
    }
  }, 200);
};

const MarketplaceBodyModal: FC<MarketplaceBodyModalProps> = ({
  id,
  finalCategory,
  setFinalCategory,
  arrayData,
  marketplace,
  reduxMarketplace,
  currentSelectedCategories,
  onConfirmCategory,
}: any) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>([[]]);
  const rd_selectedStorefront = useSelector(
    (state: RootState) => state.Product.selectedStoreFrontData
  );
  const reduxShopeeProductCategoryData = useSelector(
    (state: RootState) => state.MarketplaceProductCategory.marketplaceShopee
  );

  const handleSelectedCategory = (selectedCategory: any) => {
    let children: any = reduxMarketplace.filter((category: any) => {
      return (
        category.parentCategoryId === selectedCategory.categoryId.toString()
      );
    });

    // get current array index
    const currentIndex = findCategoryIndex(
      data,
      "categoryId",
      selectedCategory.categoryId
    );
    console.log("current index : " + currentIndex);

    // remove any data from 0 to current index + 1
    const newArrayCategories = data.slice(0, currentIndex + 1);
    console.log("current array", newArrayCategories);

    // --- perform get final categories array
    const currentPiece = {
      id: selectedCategory.categoryId,
      level: currentIndex,
      hasChildren: selectedCategory.hasChildren,
      name: {
        id: selectedCategory.categoryName.id,
        en: selectedCategory.categoryName.en,
      },
    };

    // get a copy from state
    const piece = [...finalCategory];

    // remove any data from current index slice
    let sliced = piece.slice(0, currentIndex);
    let newPiece = [...sliced, currentPiece];

    // overriede piece that has the same level
    newPiece.find((p) => p.level === currentPiece.level) !== undefined
      ? (newPiece.find((p) => p.level === currentPiece.level).id =
          currentPiece.id)
      : newPiece.push(currentPiece);

    setFinalCategory(newPiece);

    let push;
    if (children.length > 0) {
      push = [...newArrayCategories, children];
    } else {
      push = [...newArrayCategories];
    }
    setData(push);

    onConfirmCategory && onConfirmCategory(newPiece);
  };

  useEffect(() => {
    console.log("arrayData measure: ", arrayData);
    if (arrayData && arrayData.length > 0 && arrayData[0].length > 0) {
      console.log("FINALE arrayData : ", arrayData);
      setData(arrayData);
    } else {
      let parent: any = reduxShopeeProductCategoryData?.filter(
        (category: any) => {
          return category.parentCategoryId === "0";
        }
      );
      let newArray = [...arrayData];
      newArray[0] = parent;
      console.log("new Array : ", newArray);

      setData(newArray);
    }

    if (finalCategory && finalCategory.length > 0) {
      let listCategories = [...finalCategory];

      // get initial parent data
      let parent: any = reduxMarketplace?.filter((category: any) => {
        return category.parentCategoryId === "0";
      });

      let newArray: any = [];
      let newFinal: any = [];

      // set column 0 category data to parent category
      newArray[0] = parent;

      // map each copy categories from redux
      listCategories &&
        listCategories.length > 0 &&
        listCategories?.forEach((cat: any, i: number) => {
          // console.log("FINALE CAT", cat);
          // each marketplace list
          // list && list.length > 0 && list?.forEach((cat: any, i: number) => {
          let children: any = reduxMarketplace.filter((category: any) => {
            return category?.parentCategoryId === cat?.id.toString();
          });

          const currentPiece = {
            id: cat?.id,
            level: cat?.level,
            hasChildren: cat?.hasChildren,
            name: {
              id: cat?.name.id,
              en: cat?.name.en,
            },
          };

          newFinal[i] = currentPiece;

          setFinalCategory(newFinal);

          if (children !== undefined && children.length > 0) {
            let idx = i + 1;

            newArray[idx] = children;
            console.log("newArray : ", newArray);
            setData(newArray);
          }
        });
    }
  }, [rd_selectedStorefront, finalCategory?.length]);

  return (
    <>
      {id.toLowerCase() == "shopee" && (
        <div className="d-flex flex-row" key={id}>
          {data?.map((category: any, i: any) => {
            return (
              <div
                className="d-flex flex-column"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                }}
                key={`${id}-${i}`}
                id={`scrollColumn-${i}`}
              >
                {category?.map((cat: any, i: number) => {
                  const isCategoryActive = finalCategory.some(
                    (u: any) => u.id.toString() === cat.categoryId.toString()
                  );
                  return (
                    <button
                      id={cat.id}
                      key={cat.id}
                      className={clsx(
                        "d-flex align-items-center justify-content-between btn p-3",
                        {
                          "bg-primary text-white active-items":
                            isCategoryActive,
                        }
                      )}
                      style={{ textAlign: "left", width: "260px" }}
                      onClick={() => handleSelectedCategory(cat)}
                    >
                      <span>
                        {i18n.language === "id"
                          ? cat?.categoryName?.id
                          : cat?.categoryName?.en}
                      </span>
                      {cat && cat?.hasChildren && (
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr071.svg"
                          className={clsx("svg-icon", {
                            "svg-icon-white" : isCategoryActive,
                            "svg-icon-dark" : !isCategoryActive
                          })}
                        />
                      )}
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

function convertArray(array: any) {
  if (array !== undefined || array !== null || array.length !== 0) {
    return [Object.values(array[0])];
  }
}

const MarketplaceCategoryModalDesktop: React.FC<MarketplaceCategoryProps> = ({
  title,
  step,
  isSubmitted,
  id,
  isChecked,
  arrayData,
  currentMarketplaceSelected,
  isCurrentMarketplaceHasSelectedCategory,
  company,
  marketplace,
  reduxMarketplace,
  setMarketplaces,
  isAllUnchecked,
  checkedWithNoCategory,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();

  const [currentCategory, setCurrentCategory] = useState<any>(
    currentMarketplaceSelected
  );

  console.log("FINAL CURRENT", currentMarketplaceSelected);

  const [finalCategory, setFinalCategory] = useState<any>(currentCategory[0]);

  const dispatch = useDispatch();

  console.log("FINAL CATEGORY", finalCategory);

  useEffect(() => {
    console.log("FINAL MARKET", marketplace?.category);
    if (marketplace?.category) {
      const category: any = convertArray(marketplace.category);
      setFinalCategory(category[0]);
      setCurrentCategory(category);
    }
  }, [marketplace]);

  useEffect(() => {
    if (id?.toLowerCase() === "shopee") {
      console.log("shopee marketplace");
      if (!reduxMarketplace || reduxMarketplace.length <= 0) {
        getMarketplaceProductCategory(id?.toLowerCase()).then(
          async (response) => {
            if (response) {
              console.log("set to redux");

              console.log("query get MarketPlcae", reduxMarketplace);
              dispatch(
                ReduxMarketplaceProductCategory.setShopeeMarketplaceProductCategory(
                  response
                )
              );
            }
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    console.log("STR Redux Marketplace", reduxMarketplace);
    console.log("STR Final Category is", finalCategory);
    if (finalCategory) {
      setCurrentCategory(convertArray([finalCategory]));
    }
  }, [finalCategory]);

  const rd_marketplaces = useSelector(
    (state: RootState) => state.Product.marketplaces
  );

  const handleOnConfirm = (marketplace: any) => {
    console.log("marketplace: ", marketplace);
    console.log("finalCategory : ", finalCategory);
    const convertFinalCategory = convertArray([finalCategory]);
    console.log("convertFinalCategory = ", convertFinalCategory);
    const marketplaceUpdate = {
      ...marketplace,
      category: convertFinalCategory,
    };
    let tempMarketplace =
      rd_marketplaces &&
      rd_marketplaces?.filter((market: any) => market !== marketplace);
    console.log("tempMarketplace before : ", tempMarketplace);
    tempMarketplace.push(marketplaceUpdate);
    console.log("tempMarketplace after: ", tempMarketplace);
    dispatch(ReduxProduct.setMarketplaces(tempMarketplace));
    dispatch(ReduxProduct.setIsGeneratedCategoryAttributes(false));
  };

  return (
    <>
      <div
        className={clsx(`d-flex flex-column`, {
          "w-100 p-2": step === "summary",
          "w-100": step === "companyandmarketplace",
        })}
        key={`${title}_${company ?? ""}`}
      >
        <div id="marketplaceCategory" className="form-group">
          <div
            data-testid="form-selectmarketplace"
            className={clsx(`form-control h-5 d-flex justify-content-between`, {
              "pointer-none": !isChecked,
              "w-100":
                step === "summary" ||
                isTabletOrMobile ||
                step === "companyandmarketplace",
              "w-50": step !== "summary" && step !== "companyandmarketplace",
              "border-gray-900": isChecked,
              "border-gray-300": !isChecked,
            })}
            style={{
              alignItems: "center",
            }}
            role="button"
            data-bs-toggle="modal"
            data-bs-target={
              isChecked
                ? `#modalSelectProductCategory_${title}_${(company?.companyName ?? "")}`
                : ""
            }
            onClick={() => adjustChildScrollPosition()}
          >
            <div
              className={clsx("text-gray-500", {
                "text-gray-900": isChecked,
              })}
            >
              {isCurrentMarketplaceHasSelectedCategory &&
              currentCategory &&
              currentCategory.length > 0 ? (
                <span key="category">
                  {currentCategory?.map((categories: any, i: number) => (
                    <Fragment key={i.toString()}>
                      {categories &&
                        categories?.map((category: any, index: number) => {
                          return (
                            <span key={`cat-${index}-${category?.name?.id}`} >
                              {i18n.language === "id"
                                ? category?.name?.id
                                : category?.name?.en}
                              {category?.hasChildren ? " > " : ""} 
                            </span>
                          );
                        })}
                    </Fragment>
                  ))}
                </span>
              ) : (
                <span key="input">{`${t("Storefront.Input.SelectMarketPlace").replace(
                  "<<marketplace>>",
                  CapitalizedLetter(title)
                )}`}</span>
              )}
            </div>
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className={clsx("svg-icon", { "svg-icon-dark": isChecked })}
            />
          </div>
          <div
            className={clsx("mt-2 mb-2")}
            style={{ height: isTabletOrMobile ? "auto" : "18px" }}
            data-testid="alert-company"
          >
            {step === "companyandmarketplace" &&
              id?.toLowerCase() === title?.toLowerCase() &&
              finalCategory.length <= 0 &&
              isChecked && (
                <span className="text-danger p-2 alert-marketplace">
                  {t("Storefront.Alert.SelectMarketplace")}
                </span>
              )}
          </div>
        </div>

        <ModalSelectProductCategory
          id={`modalSelectProductCategory_${title}_${(company?.companyName ?? "")}`}
          title={`${t("Storefront.Input.SelectMarketPlace").replace(
            "<<marketplace>>",
            title
          )}`}
          body={
            <MarketplaceBodyModal
              id={title}
              finalCategory={finalCategory}
              setFinalCategory={setFinalCategory}
              arrayData={arrayData}
              company={company?.companyName?.toLowerCase() ?? ""}
              reduxMarketplace={reduxMarketplace}
              currentSelectedCategories={currentCategory[0]}
            />
          }
          displayCategory={finalCategory}
          confirmOption={`${t("Storefront.Button.Confirm")}`}
          cancelOption={`${t("Alert.Button.Cancel")}`}
          onConfirm={() => handleOnConfirm(marketplace)}
        />
      </div>

      {step === "summary" &&
        id?.toLowerCase() === title?.toLowerCase() &&
        isSubmitted &&
        isAllUnchecked && (
          <p className="text-danger">
            {t("Storefront.Alert.SelectMarketplace")}
          </p>
        )}
    </>
  );
};

export default MarketplaceCategoryModalDesktop;
