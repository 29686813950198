import { CampaignMessage } from "../../../models/Campaign";

interface InitialState {
  listCampaignMessage: CampaignMessage[];
  countCampaignMessage: number;
  currentPageCampaignMessage: number;
  maxPageCampaignMessage: number;
  arrayCampaignMessagePagination: string[];
  listReportData: any[];
}
const CampaignMessageListAction: string = "CampaignMessageListAction";

export type unsubFN = () => void;

export default InitialState;
export { CampaignMessageListAction };
