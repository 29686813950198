import firebase from "firebase/compat/app";
import { DocumentReference } from "@firebase/firestore-types";
import Customer from "./Customer";
import Company from "./Company";
import Account from "./Account";
import { BodyParamLanguage, button } from "./Language";
import { Response } from "../../resources/helpers";

export interface CampaignData {
  [prop: string]: [value: any];
}

export interface Campaign {
  id?: string;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  isActive?: boolean;
  client?: DocumentReference;
  company?: DocumentReference;
  account?: DocumentReference;
  companyModel?: Company;
  accountModel?: Account;
  campaignName?: string;
  // template?: DocumentReference,
  language?: Language[];
  targetFileURL?: string | firebase.firestore.FieldValue;
  template?: any;
  companyName?: string;
  companyAvatar?: string;
  campaignSchedule?: string;
  scheduledAt?: firebase.firestore.Timestamp;
  templateName?: string;
  campaignMessages?: CampaignMessage[];
  campaignMessagesCount?: CampaignMessageCount;
  templateState?: string;
  status?: string;
  isCampaignDynamicURLValid?: boolean;
  scheduleConfig?: ScheduleConfig;
  dateSent?: string;
  campaignRepeatCount?: CampaignMessageCount;
  statusCode?: string;
}

export interface CampaignMessageCount {
  countCreated?: number;
  countSubmitted?: number;
  countSent?: number;
  countDelivered?: number;
  countRead?: number;
  countFailed?: number;
  updatedAt?: firebase.firestore.Timestamp;
  repetitions?: {
    [key: string]: CampaignMessageCount;
  };
}

export interface Language {
  id?: string;
  bodyParam?: {
    paramNo: number;
    paramKey: string;
    paramType?: string;
    textContent: string;
    paramId?: string;
    paramValue?: string;
  }[];
  header?: {
    type?: string;
    mediaType?: string;
    headerValue?: string;
    headerParam?: BodyParamLanguage[];
    headerURL?: string;
    fileName?: string;
  };
  buttons?: button[];
  addSecurityRecommendation?: boolean;
  code_expiration_minutes?: number;
  failover?: failover;
}

export interface failover {
  message: string;
  sendWhenUndelivered?: boolean;
  undeliveredTime?: number;
  undeliveredTimeUnit?: string;
  bodyParam: any[];
}

export interface CampaignMessage {
  customerName: any;
  id?: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  isActive: boolean;
  status: string;
  phoneNumber: string;
  resultMessageId: string;
  message?: string;
  type?: string;
  customerModel?: Customer;
}

export interface ScheduleConfig {
  end?: firebase.firestore.Timestamp;
  isRepeat: boolean;
  isRepActive?: boolean;
  repeatConfig?: RepetitionConfig;
}

export interface RepetitionConfig {
  type: string;
  interval: number;
  selectedByUnit?: number[];
  byDayConfig?: {
    key: string;
    value: string;
  };
}

export type CampaignsQueryResponse = Response<Array<Campaign>>;

export const campaignState = {
  Save: "Save",
  New: "New",
};

export default Campaign;
