import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../resources/helpers";

const CampaignMobileIcon = () => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-row menu menu-column menu-state-title-primary 
                menu-state-icon-primary menu-state-bullet-primary
                border-gray-300 border-bottom align-items-center"
    >
      <Link to="/campaign/list" className="symbol p-0 mx-0">
        <KTSVG
          path="/media/icons/duotune/arrows/arr074.svg"
          className="svg-icon-primary svg-icon-2hx"
        />
      </Link>
      <div className="menu-item here show menu-accordion ms-2">
        <div className="menu-link px-0">
          <span className="menu-icon justify-content-end">
            <KTSVG
              path="/media/icons/customsvg/megaphone.svg"
              className="svg-icon-2hx"
            ></KTSVG>
          </span>
          <span className="menu-title">{`${t(
            "Campaign.Title.Campaign"
          )}`}</span>
        </div>
      </div>
    </div>
  );
};
export default CampaignMobileIcon;
