import firebase from "firebase/compat/app";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import Customer from "../../../../models/Customer";
import { User } from "../../../../models/User";

type person = User | Customer | undefined;

export const DocumentMediaType: string[] = [
  "plain",
  "pdf",
  "rtf",
  "vnd.ms-powerpoint",
  "msword",
  "vnd.ms-excel",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
  "vnd.openxmlformats-officedocument.presentationml.presentation",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "vnd.oasis.opendocument.spreadsheet",
  "vnd.oasis.opendocument.presentation",
  "comma-separated-values",
  "csv",
];

export const ImageMediaType: string[] = ["jpeg", "png"];
export const LazadaImageMediaType: string[] = ["jpeg", "png", "gif"];
export const VideoMediaType: string[] = [
  "mp4",
  "3gp",
  // "mpeg"
];

export const StickerMediaType: string[] = ["webp"];

export const AudioMediaType: string[] = ["aac", "mp4", "mpeg", "amr", "ogg"];

export const checkFile = (FileType: string | undefined, FileExtensionType: string | undefined, FileSize: number, FileChannel?: string) => {
  let result: string | undefined = undefined;
  let error = undefined;
  let maxSize = 0;

  console.log("SMFC FileType : " + FileType + " FileExtensionType : " + FileExtensionType + " FileSize : " + FileSize + " FileChannel : " + FileChannel);
  // Check File Type
  if (FileType === undefined || FileType === undefined) {
    error = "InvalidURL";
  } else if (FileType.toLocaleLowerCase() === "application" || FileType.toLocaleLowerCase() === "text") {
    result = "document";
  } else if (FileType.toLocaleLowerCase() === "image") {
    result = "image";
  } else if (FileType.toLocaleLowerCase() === "video") {
    result = "video";
  } else if (FileType.toLocaleLowerCase() === "audio") {
    result = "audio";
  } else {
    error = "InvalidURL";
  }

  // Check File Size
  if (result !== undefined) {
    const sizeInMB = FileSize / (1024 * 1024);
    if (result === "document" && sizeInMB >= 100) {
      maxSize = 100;
      error = "MaxSize";
    }
    if (result === "image" && sizeInMB >= 5) {
      maxSize = 5;
      error = "MaxSize";
    }
    if (result === "video" && sizeInMB >= 16) {
      maxSize = 16;
      error = "MaxSize";
    }
    if (result === "audio" && sizeInMB >= 16) {
      maxSize = 16;
      error = "MaxSize";
    }
  }

  // Check File Extension
  if (result !== undefined) {
    if (result === "document" && FileExtensionType !== undefined && DocumentMediaType.includes(FileExtensionType) === false) {
      error = "InvalidFiletype";
    }
    if (result === "image" && FileExtensionType !== undefined) {
      if (FileChannel === "lazada" && LazadaImageMediaType.includes(FileExtensionType) === false) {
        error = "InvalidFiletype";
      } else if (FileChannel != "lazada" && ImageMediaType.includes(FileExtensionType) === false) {
        error = "InvalidFiletype";
      }
    }
    if (result === "video" && FileExtensionType !== undefined && VideoMediaType.includes(FileExtensionType) === false) {
      error = "InvalidFiletype";
    }
    if (result === "audio" && FileExtensionType !== undefined && AudioMediaType.includes(FileExtensionType) === false) {
      error = "InvalidFiletype";
    }
  }

  return [result, error, maxSize];
};

export const formatSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// Text Styling
// All Style
export const convertTextStyle = (textMessage: string) => {
  const messageBold: string = textBold(textMessage);
  const messageItalic: string = textItalic(messageBold);
  const messageStrike: string = textStrikeThrough(messageItalic);
  const styledMessage: string = textMonospace(messageStrike);
  return styledMessage;
};

// Bold
export const textBold = (text: string) => {
  let replaceBold = true;
  let boldmark: number = text?.lastIndexOf("*");

  if (boldmark <= 0) {
    replaceBold = false;
  }
  let startAt = 0;
  let countMark = 0;

  while (replaceBold) {
    boldmark = text?.lastIndexOf("*");
    countMark += 1;
    let indexBold: number = text.indexOf("*");

    if (indexBold < boldmark) {
      if (indexBold !== -1) {
        if (countMark % 2 === 1) {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "<strong>" + boldtemp2;
        } else {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "</strong>" + boldtemp2;
        }
      } else {
        replaceBold = false;
      }
    } else {
      if (indexBold !== -1) {
        if (countMark % 2 === 0) {
          let boldtemp = text.substring(0, indexBold);
          let boldtemp2 = text.substring(indexBold + 1, text.length);
          text = boldtemp + "</strong>" + boldtemp2;
        }
      }
      replaceBold = false;
    }
  }
  return text;
};

// Italic
export const textItalic = (text: string) => {
  let replaceItalic = true;
  let italicmark: number = text?.lastIndexOf("_");

  if (italicmark <= 0) {
    replaceItalic = false;
  }
  let startAt = 0;
  let countMark = 0;

  while (replaceItalic) {
    italicmark = text?.lastIndexOf("_");
    countMark += 1;
    let indexItalic: number = text.indexOf("_");

    if (indexItalic < italicmark) {
      if (indexItalic !== -1) {
        if (countMark % 2 === 1) {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "<em>" + italictemp2;
        } else {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "</em>" + italictemp2;
        }
      } else {
        replaceItalic = false;
      }
    } else {
      if (indexItalic !== -1) {
        if (countMark % 2 === 0) {
          let italictemp = text.substring(0, indexItalic);
          let italictemp2 = text.substring(indexItalic + 1, text.length);
          text = italictemp + "</em>" + italictemp2;
        }
      }
      replaceItalic = false;
    }
  }
  return text;
};

// Strikethrough
export const textStrikeThrough = (text: string) => {
  let replaceStrike = true;
  let strikemark: number = text?.lastIndexOf("~");

  if (strikemark <= 0) {
    replaceStrike = false;
  }
  let countMark = 0;

  while (replaceStrike) {
    strikemark = text?.lastIndexOf("~");
    countMark += 1;
    let indexStrike: number = text.indexOf("~");

    if (indexStrike < strikemark) {
      if (indexStrike !== -1) {
        if (countMark % 2 === 1) {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "<s>" + striketemp2;
        } else {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "</s>" + striketemp2;
        }
      } else {
        replaceStrike = false;
      }
    } else {
      if (indexStrike !== -1) {
        if (countMark % 2 === 0) {
          let striketemp = text.substring(0, indexStrike);
          let striketemp2 = text.substring(indexStrike + 1, text.length);
          text = striketemp + "</s>" + striketemp2;
        }
      }
      replaceStrike = false;
    }
  }
  return text;
};

// Monospace
export const textMonospace = (text: string) => {
  let replaceMono = true;
  let monomark: number = text?.lastIndexOf("```");

  if (monomark <= 0) {
    replaceMono = false;
  }
  let countMark = 0;

  while (replaceMono) {
    monomark = text?.lastIndexOf("```");
    countMark += 1;
    let indexMono: number = text.indexOf("```");

    if (indexMono < monomark) {
      if (indexMono !== -1) {
        if (countMark % 2 === 1) {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "<tt>" + monotemp2;
        } else {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "</tt>" + monotemp2;
        }
      } else {
        replaceMono = false;
      }
    } else {
      if (indexMono !== -1) {
        if (countMark % 2 === 0) {
          let monotemp = text.substring(0, indexMono);
          let monotemp2 = text.substring(indexMono + 3, text.length);
          text = monotemp + "</tt>" + monotemp2;
        }
      }
      replaceMono = false;
    }
  }
  return text;
};

export const formatDate = (timestamp: firebase.firestore.Timestamp, language: string, prefomattedDate = false, hideYear = false) => {
  // const date = timestamp.toDate();
  const date = new Date(timestamp.seconds * 1000);
  const day = date.getDate() < 10 ? "0" + date.getDate() : "" + date.getDate();
  const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : "" + (date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours();
  let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes();

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return ` ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    // return `${ day }/${ month } ${ hours }:${ minutes }`;
    return `${day}/${month}/${year}`;
  }

  // 10. January 2017. at 10:20
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const avatarUrl = (person: person) => {
  const avatarIcon = "/media/icons/avatar/";
  let personAvatar = toAbsoluteUrl(`${avatarIcon}def-avatar.png`);

  if (person?.avatar && person?.avatar !== "") {
    personAvatar = person?.avatar;
  } else if (person?.gender === "male") {
    personAvatar = toAbsoluteUrl(`${avatarIcon}m-avatar.png`);
  } else if (person?.gender === "female") {
    personAvatar = toAbsoluteUrl(`${avatarIcon}f-avatar.png`);
  }
  return personAvatar;
};

export const userName = (person: User | undefined, limitString: number) => {
  let fullName = "User Name";

  if (person?.name && person?.name !== "") {
    fullName = person?.name;
  } else if (person?.firstName && person?.firstName !== "") {
    fullName = person?.firstName;
    if (person?.lastName && person?.lastName !== "") {
      fullName += " " + person?.lastName;
    }
  } else {
    fullName = String(person?.phoneNumber) as string;
  }

  if (limitString > 0 && fullName.length > limitString) {
    fullName = fullName.substring(0, limitString - 4) + "...";
  }

  return fullName;
};
