import React, { FC, useState, useEffect } from "react";
import Select, { components } from "react-select";
import Option from "./Option";

interface MultiSelectProps {
  options: any[];
  defaultValue?: any;
  value?: any;
  allOptionValue: string;
  className: string;
  components: any;
  hideSelectedOptions: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  onChange: (selected: any[], actionMeta: any) => void;
}

const MultiSelect: FC<MultiSelectProps> = ({
  options,
  defaultValue = [],
  allOptionValue,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(defaultValue);
  /**
   * @all
   * if true, 
   * if false, 
   */
  const [all,setAll] = useState(false);
  // const { components, closeMenuOnSelect, isClearable, hideSelectedOptions } = props;
  const MultiValue = (props: any) => {
    let labelToBeDisplayed = `${props.data.label} `;

    if (props.data.value === allOptionValue) {
      labelToBeDisplayed = "All Option Selected";
      return (
        <components.MultiValue {...props}  >
          <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
      );
    }

    if (!all) {
      return (
        <components.MultiValue {...props}  >
          <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
      );
    }

    return null;
  };

  const handleSelectChange = (selected: readonly any[], actionMeta: any) => {
    const selectedArray = Array.from(selected)
    let selectAll = false

    const deselectOption: boolean = 
      actionMeta.action === "deselect-option" || actionMeta.action === "remove-value"
    
    if (actionMeta.action === "select-option" && actionMeta.option?.value === allOptionValue) {
      console.log(`all selected ${selectAll}`)
      setSelectedOptions(options);
      selectAll = true;
    } else if (deselectOption && 
        (actionMeta.removedValue?.value ?? actionMeta.option?.value) === allOptionValue) {
      console.log(`all deselected or removed ${selectAll}`)
      setSelectedOptions([]);
    } else if (deselectOption) {
      console.log(`selected deselect or remove other option ${selectAll}`)
      setSelectedOptions(selectedArray.filter((option) => option.value !== allOptionValue));
    } else if (selectedArray.length === options.length - 1) {
      console.log(`other option selected ${selectAll}`)
      setSelectedOptions(options);
      selectAll = true;
    } else {
      //not all selected
      setSelectedOptions(selectedArray);
    }

    setAll(selectAll);
  };

  useEffect(() => {
    const actionMeta: any = {
      action: selectedOptions.length === 0 ? "clear" : "deselect-option",
    };
    onChange(selectedOptions, actionMeta);
  }, [selectedOptions, onChange]);

  return (
    <Select
      // {...props}
      options={options}
      isMulti
      value={selectedOptions}
      onChange={handleSelectChange}
      components={{Option,MultiValue}}
      hideSelectedOptions={false}
      isClearable={true}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelect;