import clsx from 'clsx';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../styles/components/Button';
import { OrderExportHeaderTitle } from '../../../modules/order/orderHeader/OrderExportHeaderTitle';
import { PageTitle, PageLink } from '../../core';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IExportOrderResponse } from '../../../../api/server/order';

const OrderExportDownload: FC<any> = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const location = useLocation();
  const nav = useNavigate();

  // console.log()
  console.log(`location.state `, JSON.stringify(location.state));
   
  const state: IExportOrderResponse = location.state as IExportOrderResponse ?? {}
  const urlResponse = state.response
  console.log(`response `, JSON.stringify(urlResponse));

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Order",
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const handleDownloadResultExportOrder = () => {
    if(!urlResponse) return alert(`Error no URL Response`);

    const link = document.createElement("a")
    link.setAttribute("href", urlResponse)
    link.setAttribute("download", "Test.csv")
    link.click()
  }

  useEffect(() => {
    if(!urlResponse) nav("/order/export");
    
  }, [urlResponse]);

  return (
    <>
    <PageTitle breadcrumbs={usersBreadcrumbs}>{`Export Order`}</PageTitle>

    <div
      className="d-flex flex-column flex-lg-column h-100"        
    >
      {/* {!isTabletOrMobile &&
          OrderExportHeaderTitle("Export Order")} */}
      <div
        className="d-flex flex-column flex-lg-column"
        style={{ minHeight: "92.5%" }}
      >
        <div
          // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
          className={clsx("overflow-hidden card h-100", {"p-7": !isTabletOrMobile}
          )}
        >
          <div
            style={{
              height: "90%",
              // isSummary ? "" : "90%",
            }}
            className={clsx(
              `d-flex flex-column ${
                isTabletOrMobile && "p-5"
              }`
            )}
          >
            <div
              className={clsx(
                "flex-lg-column-fluid tab-content d-flex align-items-center justify-content-center p-5 h-100",
                {
                  "overflow-auto": !isTabletOrMobile
                }
              )}
            >
              <div className="text-center">
                <div>
                  <p style={{ fontSize: '14px' }}>Your Order data has been successfully exported</p>
                  <span style={{ fontSize: '14px' }}>Please download the order data that has been exported</span>
                </div>
                <div>
                  <button
                    id="downloadTargetFormat"
                    className={clsx(
                      "btn btn-primary",
                      { "mt-3 w-50": isTabletOrMobile, "m-5": !isTabletOrMobile }
                    )}
                    onClick={handleDownloadResultExportOrder}
                    data-testid="download-target-csv"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              `d-flex bg-white justify-content-start mt-auto ${
                isTabletOrMobile ? "mb-5" : ""
              }`
            )}
          >
            <Link to="/order/export">
              <Button data-testid="btnCancel" btnbs="primary" type="button">
                Back
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default OrderExportDownload;