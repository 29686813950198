import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirestoreTable } from "../context/TableContext";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { KTSVG, useDebounce } from "../../../../../../../resources/helpers";
import { useMediaQuery } from "react-responsive";

export default function TableHeader() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [selectedField, setSelectedField] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const {
    refreshData,
    selectedItems,
    memberTableState,
    updateTableState,
    data,
    isLoading,
    isFetching,
  } = useFirestoreTable();
  const [searchTerm, setSearchTerm] = useState(
    memberTableState?.searchKey?.value || ""
  );
  const [modalMobileOpen, setModalMobileOpen] = useState(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  useEffect(() => {
    updateTableState({
      searchKey: {
        field: "email",
        value: debouncedSearchTerm,
      },
    });
  }, [debouncedSearchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    updateTableState({
      searchKey: {
        field: "email",
        value: event.target.value,
      },
    });
  };

  const handleCloseOffcanvas = () => {
    let buttonmobile = document.getElementById("mobilefilter");
    buttonmobile?.click();
  };

  const applyFilter = () => {
    updateTableState({
      sorting: {
        field: selectedField as any,
        order: selectedSort as any,
      },
    });
    // refreshData();
    handleCloseOffcanvas();
  };

  const clearFilter = () => {
    updateTableState({
      sorting: {
        field: "email",
        order: "asc",
      },
    });
    setSelectedField("");
    setSelectedSort("");
    refreshData();
    handleCloseOffcanvas();
  };

  return (
    <>
      {!isMobileView ? (
        <div className="row align-items-center pb-2 justify-content-between">
          <div
            data-testid="member-search"
            className={`col-12 col-lg-8 d-flex align-items-center position-relative`}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className={clsx("form-control form-control-solid ps-14", {
                "w-100": false,
              })}
              placeholder={t("Search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              data-testid="search-order"
            />
          </div>

          <div
            data-testid="member-refetch"
            className="col-1 py-2 d-none d-lg-block"
          >
            <button
              type="button"
              className="btn btn-light-primary w-100"
              onClick={() => refreshData()}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>

          <div data-testid="add-member" className="col-12 col-lg-3 py-2">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={() => {
                console.log(`Click on Add Member Page`);
                nav("/setting/user-management/add-member");
              }}
            >
              + {t("UserManagement.Button.AddMember")}
            </button>
          </div>
        </div>
      ) : (
        <div className="row align-items-center pb-2 justify-content-between">
          <div className="d-flex flex-row pb-3">
            {/* begin::Search */}
            <div
              data-testid="member-search"
              className="d-flex align-items-center position-relative w-100 pe-2 ms-2"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-4"
              />
              <input
                type="text"
                data-testid="search-marketplace"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid ps-12"
                placeholder={t("Search")}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {/* end::Search */}
            {/* Filter Button */}
            <button
              type="button"
              id="mobilefilter"
              className="btn btn-light-primary me-2"
              // id="offcanvas2"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
              onClick={() => {
                setModalMobileOpen(!modalMobileOpen);
              }}
            >
              <i className="bi bi-funnel-fill fs-1"></i>
            </button>
          </div>

          <div className="d-flex justify-content-between align-items-center w-100">
            {/* Add Marketplace Button */}
            <div
              data-testid="add-member"
              className="d-flex align-items-center w-100"
            >
              <Link
                type="button"
                className="btn btn-primary w-100 mx-2"
                to="/setting/user-management/add-member"
              >
                <span className="d-md-none">
                  <i className="bi bi-plus-lg fs-4 me-2"></i>
                  {t("UserManagement.Button.AddMember")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}

      {isMobileView && (
        <div
          className="offcanvas offcanvas-bottom w-100 h-auto"
          tabIndex={-1}
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
          style={{
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
            zIndex: 50000,
          }}
        >
          <div
            className="offcanvas-header p-6"
            style={{
              borderBottom: "1px solid #D6D6D6",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              {t("Marketplace.Button.Filter")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div
            className="offcanvas-body w-100 h-auto px-0 d-flex flex-column pb-0"
            style={{
              flex: 1,
            }}
          >
            <div
              className="px-5 d-flex flex-row col-gap-2 row-gap-2 flex-wrap"
              style={{
                flex: 1,
                marginBottom: "15px",
                paddingBottom: "15px",
                borderBottom: "1px solid #D6D6D6",
              }}
            >
              <button
                type="button"
                className={
                  selectedField === "email"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "email") {
                    setSelectedField("email");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "email" ? "none" : "auto",
                }}
              >
                Email
              </button>
              <button
                type="button"
                className={
                  selectedField === "name"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "name") {
                    setSelectedField("name");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "name" ? "none" : "auto",
                }}
              >
                Name
              </button>
              <button
                type="button"
                className={
                  selectedField === "role"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "role") {
                    setSelectedField("role");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents: selectedField === "role" ? "none" : "auto",
                }}
              >
                Access Type
              </button>
              <button
                type="button"
                className={
                  selectedField === "inviteExpiredAt"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  if (selectedField !== "inviteExpiredAt") {
                    setSelectedField("inviteExpiredAt");
                  } else {
                    setSelectedField("");
                  }
                }}
                style={{
                  pointerEvents:
                    selectedField === "inviteExpiredAt" ? "none" : "auto",
                }}
              >
                Invitation Expired
              </button>
            </div>
          </div>
          <div
            className="p-6 pt-0"
            style={{
              borderBottom: "1px solid #D6D6D6",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              {t("Marketplace.Button.SortBy")}
            </h5>
          </div>
          <div
            className="offcanvas-body w-100 h-auto px-0 d-flex flex-column"
            style={{
              flex: 1,
            }}
          >
            <div
              className="px-5 d-flex flex-row col-gap-2 row-gap-2 flex-wrap"
              style={{
                flex: 1,
                marginBottom: "15px",
                paddingBottom: "15px",
                borderBottom: "1px solid #D6D6D6",
              }}
            >
              <button
                type="button"
                className={
                  selectedSort === "asc"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  setSelectedSort("asc");
                }}
                disabled={selectedField === "" ? true : false}
                style={{
                  pointerEvents: selectedSort === "asc" ? "none" : "auto",
                }}
              >
                {selectedField === "inviteExpiredAt"
                  ? t("Marketplace.Button.Oldest")
                  : selectedField === "role"
                  ? "Admin"
                  : t("Marketplace.Button.AZ")}
              </button>
              <button
                type="button"
                className={
                  selectedSort === "desc"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary border border-primary"
                }
                onClick={() => {
                  setSelectedSort("desc");
                }}
                disabled={selectedField === "" ? true : false}
                style={{
                  pointerEvents: selectedSort === "desc" ? "none" : "auto",
                }}
              >
                {selectedField === "inviteExpiredAt"
                  ? t("Marketplace.Button.Newest")
                  : selectedField === "role"
                  ? "User"
                  : t("Marketplace.Button.ZA")}
              </button>
            </div>
            <div className="px-5 d-flex w-100 flex-row row-gap-2 col-gap-2">
              <button
                type="button"
                className="btn btn-danger w-100 d-flex flex-column align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  height: "30px !important",
                }}
                onClick={() => {
                  clearFilter();
                }}
              >
                {t("Marketplace.Button.Reset")}
              </button>
              <button
                type="button"
                className="btn btn-primary w-100 d-flex flex-column align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  height: "30px !important",
                }}
                onClick={() => {
                  applyFilter();
                }}
                disabled={selectedField === "" || selectedSort === ""}
              >
                {t("Marketplace.Button.Apply")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
