import React, { ReactElement, ReactFragment, useState } from "react";
import { Modal as BSModal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Button from "./button/Button";
interface ModalProps {
  id: string;
  title?: string;
  subtitle?: string;
  body?: string | JSX.Element;
  onCancel?: () => void;
  onHide?: () => void;
  onConfirm: (responseSubmit: any) => void;
  confirmOption?: string;
  cancelOption?: string;
  isModalOpen?: boolean;
  centered?: boolean;
  size?: "sm" | "lg" | "xl";
  closeBtn?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const handleModalSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await props.onConfirm(e);
    setLoading(false);
  };

  return (
    <BSModal id={props.id} show={props.isModalOpen || false} centered={props.centered || false} size={props.size} onHide={props.onHide}>
      <BSModal.Header closeButton={!!props.closeBtn}>
        <BSModal.Title>{props.title ?? "No Title"}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>{props.children}</BSModal.Body>
      <BSModal.Footer>
        {props.cancelOption && (
          <Button type="button" className="btn btn-outline-primary mx-2" onClick={props.onCancel} disabled={false}>
            {props.cancelOption}
          </Button>
        )}
        {props.confirmOption && (
          <Button type="submit" className="btn btn-primary mx-2" isLoading={isLoading} onClick={handleModalSubmit}>
            {props.confirmOption}
          </Button>
        )}
      </BSModal.Footer>
    </BSModal>
  );
};

export default Modal;
