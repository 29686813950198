import clsx from "clsx";
import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "react-table";
import Customer from "../../../../../models/Customer";
import {
  defaultTableState,
  useFirestoreTable,
} from "../../context/TableContext";
import { useQueryRequest } from "../../core/QueryRequestProvider";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<Customer>>;
};
const TargetCustomHeader: FC<Props> = ({ className, title, tableProps }) => {
  const { t } = useTranslation();
  const id = tableProps.column.id;
  // const {state, updateState} = useQueryRequest()
  const { tableState, updateTableState } = useFirestoreTable();

  // const isSelectedForSorting = useMemo(() => {
  //   // console.log("is selected "+state.sort + " - " + id);
  //   return state.sort && state.sort === id
  // }, [state, id])
  // const order: 'asc' | 'desc' | 'delete' | undefined = useMemo(() => state.order, [state])

  const isSelectedSort =
    tableState.sorting.field === id ? tableState.sorting : undefined;

  const onClickHandler = () => {
    if (!id || id === "action") {
      return;
    }

    let newSort = defaultTableState.sorting;

    if (!isSelectedSort) {
      newSort = {
        field: id as keyof Customer,
        order: "asc",
      };
    }
    if (isSelectedSort && isSelectedSort.order === "asc") {
      newSort = {
        field: id as keyof Customer,
        order: "desc",
      };
    }

    updateTableState({
      sorting: newSort,
      priorityOrder: false,
    });
  };

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        tableState.sorting.field === id &&
          `table-sort-${isSelectedSort ? isSelectedSort.order : ""}`
      )}
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
    >
      {t(title!)}
    </th>
  );
};

export { TargetCustomHeader };
