import axios from "axios";
import * as bcrypt from "bcryptjs";
import * as db from "../../db";
import * as Log from "../../util/SDayslogger";

export const checkUserConnection = (uid: any, sessionid: string) => {
  // const userStatusDatabaseRef = connect.createFirebaseRef("status", uid);
  // const userProfileRef = connect.createRef("users", uid);

  const userStatusDatabaseRef = db.createFirebaseRef("status", uid);
  const userProfileRef = db.createRef("users", uid);

  db.onConnectionChanged((isConnected) => {
    if (!isConnected) {
      userStatusDatabaseRef.set(db.isOfflineForDatabase(sessionid));
      return null;
    }

    userStatusDatabaseRef
      .onDisconnect()
      .set(db.isOfflineForDatabase(sessionid))
      .then((_) => {
        userStatusDatabaseRef.set(db.isOnlineForDatabase(sessionid));
        userProfileRef.update(db.isOnlineForFirestore(sessionid));
      });
  });
};

export const setUserOffline = (uid: any, sessionid: string) => {
  const userStatusDatabaseRef = db.createFirebaseRef("status", uid);
  return userStatusDatabaseRef.set(db.isOfflineForDatabase(sessionid));
};

export const sendMessage = async (jsonMessage: string, callback: any) => {
  const url: string =
    process.env.REACT_APP_SERVER_URL! + "/messages/sendMessage";
  // "http://localhost:3001/messages/sendMessage";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  try {
    await axios
      .post(url, JSON.parse(jsonMessage), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        console.log("Check response.status : " + response.status);

        const responseJson = response.data;
        const responseCode = response.status;
        return callback(responseCode, responseJson);
      })
      .catch((error) => {
        //return error callback
        console.log(
          `error response status : ${
            error.response.status
          } - error response data : ${JSON.stringify(error.response.data)}`
        );
        return callback(error.response.status, error.response.data);
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return callback("503", {
      message: `Service Unavailable`,
    });
  }
};

// export const sendInitiateChat = async (marketplace: string, jsonMessage: string, callback: any) => {
//   console.log("==> Masuk sendInitiateChat INCHA");
//   const url: string = process.env.REACT_APP_SERVER_URL! + `/${marketplace}/chat/initiate-chat`;
//   // const url = 'http://localhost:3001';
//   const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);
//   if (!token) {
//     console.error(`Could not generate token`);
//   }
//   try {
//     await axios
//       .post(url, JSON.parse(jsonMessage), {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((response) => {
//         console.log(response, "ini response INCHA");
//         console.log(response.status, "ini response status INCHA");

//         const responseJson = response.data;
//         const responseCode = response.status;
//         return callback(responseCode, responseJson);
//       })
//       .catch((error) => {
//         //return error callback
//         console.log(`error response status : ${error.response.status} - error response data : ${JSON.stringify(error.response.data)} INCHA`);
//         return callback(error.response.status, error.response.data);
//       });
//   } catch (err) {
//     console.error("INCHA Error Request server : " + err);
//     return callback("503", {
//       message: `Service Unavailable`,
//     });
//   }
// };

export const sendWABASignUp = async (
  jsonWaba: any,
  callback: (code: number, data: any) => void
) => {
  console.log(
    `masuk di sendWABASignUp to server side ${JSON.stringify(jsonWaba)}`
  );
  const url: string =
    process.env.REACT_APP_SERVER_URL! + "/waba/embeddedSignup";
  // "http://localhost:3001/waba/embeddedSignup";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  try {
    await axios
      .post(url, jsonWaba, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        timeout: 10000
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        console.log("Check response.status : " + response?.status);

        const responseJson = response?.data;
        const responseCode = response?.status;
        return callback(responseCode, responseJson);
      })
      .catch((error) => {
        //return error callback
        console.log("error sendWABASignUp", error)
        return callback(error?.response?.status ?? 400, error?.response?.data ?? {
          message: `Service Unavailable`,
        });
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return callback(503, {
      message: `Service Unavailable`,
    });
  }
};

export const sendWABAVerifyPhoneNumber = async (
  jsonWaba: any,
  callback: (code: number, data: any) => void
) => {
  console.log(
    `masuk di sendWABAVerifyPhoneNumber to server side ${JSON.stringify(
      jsonWaba
    )}`
  );
  const url: string = process.env.REACT_APP_SERVER_URL! + "/waba/verifyPhone";
  // "http://localhost:3001/waba/createAndSendTemplateMessage";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  try {
    await axios
      .post(url, jsonWaba, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        console.log("Check response.status : " + response.status);

        const responseJson = response.data;
        const responseCode = response.status;
        return callback(responseCode, responseJson);
      })
      .catch((error) => {
        //return error callback
        return callback(error.response.status, error.response.data);
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return callback(503, {
      message: `Service Unavailable`,
    });
  }
};

export const sendBlastMessage = async (jsonMessage: Object, callback: any) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + "/messages/blast";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  console.log("URL: " + url);

  try {
    await axios
      .post(url, jsonMessage, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseJson = response.data;
        const responseCode = response.status;
        return callback(responseCode, responseJson);
      })
      .catch((error) => {
        //return error callback
        return callback(error.response.status, error.response.data);
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return callback("503", {
      message: `Service Unavailable`,
    });
  }
};

export const sendCreateTemplate = async (
  jsonMessage: Object,
  whatsappBusinessID: string,
  token: string,
  callback: any
) => {
  console.log("Running Send Create Template - SCT");
  console.log(jsonMessage, "json message - SCT");
  console.log(JSON.parse(jsonMessage as any), "json object - SCT");

  let url: string =
    process.env.REACT_APP_SERVER_URL! + "/whatsapp/template/create";
  const serverAccessToken = await bcrypt.hash(
    process.env.REACT_APP_SERVER_TOKEN!,
    10
  );
  try {
    await axios
      .post(
        url,
        { jsonMessage, whatsappBusinessID, token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${serverAccessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(
          "komen sendCreateTemplate - Check response : " +
            JSON.stringify(response)
        );
        console.log(response, "komen sendCreateTemplate - Check response");

        let responseTemplateID = "";
        if (response.data && response.data.responseTemplateID) {
          responseTemplateID = response.data.responseTemplateID;
        }
        return callback(
          responseTemplateID,
          response.data.responseJson ? response.data.responseJson : "",
          response.data.errorMessage ? response.data.errorMessage : "",
          response.data.errorJSON ? response.data.errorJSON : ""
        );
      })
      .catch((error) => {
        //return error callback
        console.log(
          "komen sendCreateTemplate - Check response Error : " +
            JSON.stringify(error) +
            ", Error Message : " +
            error.message +
            ", Error Code : " +
            error.code
        );
        return callback("", "", error.message, JSON.stringify(error));
      });
  } catch (err) {
    console.log("komen sendCreateTemplate - Error Request server : " + err);
    Log.SDayslogWithoutRouting(
      "Error Create Template : sendCreateTemplate " + err,
      Log.SDLOGGER_ERROR,
      false,
      true
    );
    return callback("", "", "503", {
      message: `Service Unavailable`,
    });
  }
};

export const sendUpdateTemplate = async (
  jsonMessage: Object,
  whatsappBusinessID: string,
  token: string,
  templateID: string,
  callback: any
) => {
  console.log("MASUK sendUpdateTemplate komen");

  let url: string =
    process.env.REACT_APP_SERVER_URL! + "/whatsapp/template/update";
  const serverAccessToken = await bcrypt.hash(
    process.env.REACT_APP_SERVER_TOKEN!,
    10
  );
  console.log(
    `komen sendUpdateTemplate
     - URL : ${url}
     - TOKEN : ${token} 
     - BUSINESS ID : ${whatsappBusinessID}
     - Template ID : ${templateID}`
  );
  try {
    await axios
      .post(
        url,
        { jsonMessage, whatsappBusinessID, token, templateID },
        {
          headers: {
            "Content-Type": "application/json",
            // "access_token" : token,
            Authorization: `Bearer ${serverAccessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(
          "komen sendUpdateTemplate - Check response : " +
            JSON.stringify(response)
        );
        let isSuccess = false;
        if (response.data && response.data.responseStatus) {
          isSuccess = response.data.responseStatus;
        }
        return callback(
          isSuccess,
          response.data.responseJson,
          response.data.errorMessage ? response.data.errorMessage : "",
          response.data.errorJSON ? response.data.errorJSON : ""
        );
      })
      .catch((error) => {
        //return error callback
        console.log(
          "komen sendUpdateTemplate - Check response Error : " +
            JSON.stringify(error) +
            ", Error Message : " +
            error.message +
            ", Error Code : " +
            error.code
        );
        return callback(false, "", error.message, JSON.stringify(error));
      });
  } catch (err) {
    console.log("komen sendUpdateTemplate - Error Request server : " + err);
    Log.SDayslogWithoutRouting(
      "Error Edit Template : sendUpdateTemplate " + err,
      Log.SDLOGGER_ERROR,
      false,
      true
    );
    return callback("", "", "503", {
      message: `Service Unavailable`,
    });
  }
};

export const createSessionUploadFileWABA = async (
  file_length: number,
  file_type: string,
  file_name: string,
  token: string
) => {
  let url: string =
    process.env.REACT_APP_FACEBOOK_WA_CREATE_SESSION_UPLOADFILE!;
  console.log(
    "createSessionUploadFileWABA - URL : " +
      url +
      " - TOKEN : " +
      token +
      " - file_length : " +
      file_length +
      " - file_type : " +
      file_type +
      " - file_name : " +
      file_name
  );

  // url = (url.replace("<<templateid>>",templateID));
  // console.log("sendUpdateTemplate - URL: "+url+" - JSON MESSAGE : "+jsonMessage+" - TOKEN : "+token);
  try {
    return await axios
      .post(url, "", {
        headers: {
          Authorization: `Bearer ` + token,
        },
        params: {
          file_length: file_length,
          file_type: file_type,
          file_name: file_name,
        },
      })
      .then((response) => {
        console.log(
          "createSessionUploadFileWABA - Check response : " +
            JSON.stringify(response)
        );
        return response;
        // if(response && response.data && response.data.id){
        //   console.log("return session id"+response.data.id);
        //   return callback(response.data.id,"","",response);
        // }else{
        //   console.log("return session id undefined");
        //   return callback(undefined,"","",response);
        // }
      })
      .catch((error) => {
        console.log("return session id undefined error");
        //return error callback
        Log.SDayslogWithoutRouting(
          "Error Edit Template : createSessionUploadFileWABA " + error,
          Log.SDLOGGER_ERROR,
          false,
          true
        );
        return error;
        // return callback(undefined,error.message, error.code, JSON.stringify(error));
      });
  } catch (err) {
    console.log("createSessionUploadFileWABA - Error Request server : " + err);
    Log.SDayslogWithoutRouting(
      "Error Edit Template : createSessionUploadFileWABA " + err,
      Log.SDLOGGER_ERROR,
      false,
      true
    );
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
    // return callback("","error create session","503",{
    //       message: `Service Unavailable`,
    //     });
  }
};

export const updloadFileToWaba = async (
  ContentType: string,
  file_offset: string,
  token: string,
  binaryFile: any,
  sessionid: string
) => {
  let url: string = process.env.REACT_APP_FACEBOOK_WA_UPLOADFILE_DATA!;
  console.log(
    "updloadFileToWaba - URL : " +
      url +
      " - ContentType : " +
      ContentType +
      " - file_offset : " +
      file_offset +
      " - token : " +
      token +
      " - binaryFile : " +
      binaryFile +
      " - sessionid : " +
      sessionid
  );

  url = url.replace("<<sessionid>>", sessionid);
  console.log("updloadFileToWaba - URL: " + url);
  try {
    // if(ContentType.indexOf("image")>0){
    //   //Return Image
    //   return {data: {
    //     "h": "4:bXlwcm9maWxlLmpwZw==:aW1hZ2UvanBlZw==:ARaBJZKLp_wB1eBhJvSmYF5tYtGBLMwwyPEaZgqe6qLcQQ1j2BhPHUXMKstcl5BaiqKvno3uvs2Ik7CPFrbqBywmEaK9rEkmPVVGTWMmKpwEuA:e:1664361313:1003384303873668:100081884901552:ARZt1LDLzQWAk1_kjb4"}
    //   };
    // }else if(ContentType.indexOf("video")>0){
    //   //Return Vidio
    //   return {data: {
    //     "h": "4:ZmlsZV9leGFtcGxlX01QNF80ODBfMV81TUcubXA0:dmlkZW8vbXA0:ARa5YSosL_lcdb2LEzMTLz4DsdAIT5oiNPfBliuVse3swqBa64x779RxsSW3SmOYb8zsYQ9pJn6erbusvsDCLPkLPsgyafwrPaVKTv9kijHzQw:e:1664690499:1003384303873668:100081884901552:ARYde7xByP6TC_zuI9U\n4:ZmlsZV9leGFtcGxlX01QNF80ODBfMV81TUcubXA0:dmlkZW8vbXA0:ARa5wFguhPGCC5l_H42PEtSFK_onFrP1qkbxgN01zmsXsNFkpyJ8MTR8o2klUIwRM5dIE1S5riVM9raY5OWEsmV6IKq8JeIvt-bsQUfdptPFYQ:e:1664690504:1003384303873668:100081884901552:ARbFH0Sfi_1XBuXa8ng"
    //   }};
    // }else{
    //   //Return Document
    //   return {data: {
    //     "h": "4:ZmlsZS1zYW1wbGVfMTUwa0IucGRm:YXBwbGljYXRpb24vcGRm:ARZUZ-5IbVdRSxq4N7W3Wx1J2KxGT4YAIBWPOxBdByFrxHHZnXgWID2j8WQ7c9Kp_8Iv7Pw4_S-Kw_Qdm1C2ek6pa6Nq19sTqjy_Ci_E_nixfQ:e:1664690684:1003384303873668:100081884901552:ARajOlg8gfI7hrM5_aU"
    //   }}
    // }

    return await axios.request({
      method: "POST",
      url,
      data: binaryFile,
      headers: {
        "User-Agent":
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36",
        "Content-Type": ContentType,
        file_offset: file_offset,
        Authorization: `OAuth ` + token,
        withCredentials: false,
        // "httpAgent": new axios.Agent({ keepAlive: true }),
        // "httpsAgent" : new https.Agent({ keepAlive: true }),
      },
      transformRequest: (data: any, headers?: any) => {
        console.log(` transform request header ${JSON.stringify(headers)}`);
        return data;
      },
    });
  } catch (err) {
    console.log("updloadFileToWaba - Error Request server : " + err);
    Log.SDayslogWithoutRouting(
      "Error Edit Template : updloadFileToWaba " + err,
      Log.SDLOGGER_ERROR,
      false,
      true
    );
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
    // return callback("","error create session","503",{
    //   message: `Service Unavailable`,
    // });
  }
};

export const sendUploadFile = async (jsonMessage: string) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + "/facebook";
  try {
    return await axios
      .post(url, JSON.parse(jsonMessage), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        return {
          error: {
            message: err.toString(),
            code: `400`,
          },
        };
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
  }
};

export const createNewProduct = async (jsonAddProduct: string) => {
  console.log("masuk request product ke serverside", jsonAddProduct);
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    "/7days/product/management/ShopProduct/add";
  try {
    return await axios
      .post(url, JSON.parse(jsonAddProduct), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        return {
          error: {
            message: err.toString(),
            code: `400`,
          },
        };
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
  }
};

export const createNewStorefront = async (jsonAddStorefront: string) => {
  console.log("masuk request storefront ke serverside", jsonAddStorefront);
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    "/7days/storefront/management/ShopStorefront/add";
  try {
    return await axios
      .post(url, JSON.parse(jsonAddStorefront), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        return {
          error: {
            message: err.toString(),
            code: `400`,
          },
        };
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
  }
};

export const getMarketplaceStorefront = async (dataParams: {
  account: string;
  user: string;
}) => {
  console.log("masuk request storefront ke serverside", dataParams);
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    "/7days/storefront/management/ShopStorefront/list";
  try {
    return await axios
      .get(url, {
        params: dataParams,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        return {
          error: {
            message: err.toString(),
            code: `400`,
          },
        };
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
  }
};

export const updateMarketplaceStorefront = async (dataRequest: {
  account: string;
  user: string;
  storefront: string;
}) => {
  console.log("masuk request storefront ke serverside", dataRequest);
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    "/7days/storefront/management/ShopStorefront/update";
  try {
    return await axios
      .post(url, dataRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Check response : " + JSON.stringify(response));
        return response;
      })
      .catch((err) => {
        return {
          error: {
            message: err.toString(),
            code: `400`,
          },
        };
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return {
      error: {
        message: `Service Unavailable`,
        code: `503`,
      },
    };
  }
};
