import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import * as chat from "../../../../modules/chat/redux/ChatSlice";
import { RootState } from "../../../../../setup/redux/store";
import { convertTextStyle } from "../chat-util/ChatUtil";
import { toAbsoluteUrl } from "../../../../../resources/helpers";
import { MessageLocation } from "../chat-message/ChatLocation";
import * as lc from "../../../../modules/localstorage/index"
import Message from "../../../../models/Message";
import { Collaboration } from "../../../../models/Chat";
import Customer from "../../../../models/Customer";

interface MessageProps {
  messageToReply: Message;
  customer?: Customer;
  phoneNumber?: string;
}

const ChatMessageToReply: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { messageToReply, customer, phoneNumber } = props;
  const audioDuration = useSelector((state: RootState) => state.Chat.replyToMessageAudioDuration);
  const dispatch = useDispatch();
  const user = lc.getItemLC(lc.LCName.UserData)
  const idAudio:HTMLAudioElement = document.getElementById(messageToReply.id!)! as HTMLAudioElement;

  /**
   * 
   * @param seconds 
   * @returns string
   * @description convert seconds to minutes:seconds
   */
  const secondsToMinutes = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
  };

  /**
   * @description useEffect for handling NaN:NaN bug on get audio duration
   */
  useEffect(() => {
    if (messageToReply.messageType === "audio") {
      if(secondsToMinutes(Math.round(idAudio.duration)) !== "NaN:NaN" || secondsToMinutes(Math.round(idAudio.duration)) !== audioDuration){
        console.log("duration changed")
        dispatch(chat.setReplyToMessageAudioDuration(secondsToMinutes(Math.round(idAudio.duration))))
      }else{
        return
      }
      
    }
  }, [messageToReply])

  return (
    <div className="d-flex flex-row justify-content-between">
      <div
        id="replied-content"
        data-testid="reply-message"
        className="d-flex flex-column m-2"
      >
        <div style={{ color: "#034CA3" }}>
          {messageToReply.destination === "outbound" && <b>{t("HandledCustomer.RoomChat.ReplyMessage.You")}</b>}
          {messageToReply.destination === "inbound" && (
            <b>
              {customer ? 
              (customer?.firstName +
                " " + (customer?.lastName ? customer?.lastName : "")) 
                : phoneNumber
                }
            </b>
          )}
        </div>
        <br />
        <div className="d-flex flex-row align-items-center">
          {messageToReply.messageType === "image" && (
            <i
              data-testid="reply-message-image-on-typing"
              className="bi bi-image me-3"
            ></i>
          )}
          {messageToReply.messageType === "video" && (
            <i
              data-testid="reply-message-video-on-typing"
              className="bi bi-play-btn me-3"
            ></i>
          )}
          {messageToReply.messageType === "document" && (
            <i
              data-testid="reply-message-document-on-typing"
              className="bi bi-file-earmark-text me-3"
            ></i>
          )}
          {messageToReply.messageType === "document" &&
            messageToReply.textContent.length === 0 && (
              <div>{messageToReply.filename?.substring(0, 25) + "..."}</div>
            )}
          {messageToReply.messageType === "location" && (
            <div>
              <img
                src={toAbsoluteUrl("/media/icons/duotune/general/gen018.svg")}
              />
            </div>
          )}
          {messageToReply.textContent && (
            <div
              data-testid="reply-message-text-on-typing"
              dangerouslySetInnerHTML={{
                __html: convertTextStyle(
                  messageToReply.textContent &&
                    messageToReply.textContent.length > 200
                    ? isTabletOrMobile
                      ? messageToReply.textContent.substring(0, 50) + "..."
                      : messageToReply.textContent.substring(0, 150) + "..."
                    : messageToReply.textContent
                ).replaceAll("\n", "<br/>"),
              }}
            ></div>
          )}
          {!messageToReply.textContent &&
            messageToReply.messageType === "image" && (
              <div>{t("AddNewProduct.Title.Photo")}</div>
            )}
          {!messageToReply.textContent &&
            messageToReply.messageType === "video" && (
              <div>{t("AddNewProduct.Title.Video")}</div>
            )}
          {!messageToReply.textContent &&
            messageToReply.messageType === "location" && (
              <div>{t("HandledCustomer.Attachment.Location")}</div>
            )}
        </div>
        {messageToReply.messageType === "audio" && (
          <div>
            <i
              data-testid="reply-message-audio-on-typing"
              className="bi bi-mic-fill me-3"
            ></i>
            {t("HandledCustomer.Attachment.VoiceMessage")}
            {idAudio.duration === undefined
              ? "loading..."
              : ` (${audioDuration})`}
          </div>
        )}
      </div>
      {messageToReply.messageType === "image" && (
        <div className="d-flex">
          <img
            src={messageToReply.mediaUrl}
            className="h-100 w-100"
            height={"75px"}
            width={"75px"}
            style={{ maxHeight: "75px", maxWidth: "75px" }}
          />
        </div>
      )}
      {messageToReply.messageType === "video" && (
        <div className="d-flex">
          <video
            src={messageToReply.mediaUrl}
            className="h-100 w-100"
            height={"75px"}
            width={"75px"}
            style={{ maxHeight: "75px", maxWidth: "75px" }}
          ></video>
        </div>
      )}
      {messageToReply.messageType === "location" && (
        <div className="d-flex">
          <a
            href={`https://maps.google.com/maps?q=${messageToReply.location?.latitude},${messageToReply.location?.longitude}&z=17`}
          >
            <div>
              <MessageLocation
                item={messageToReply}
                width="75px"
                height="75px"
              />
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default ChatMessageToReply;
