import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { KTSVG } from "../../../resources/helpers";
import { toAbsoluteUrl } from "../../../resources/helpers/AssetHelpers";
import "../../../styles/css/margin.scss";
import {
  deleteAllChatMessage,
  deleteMessage,
  setSelectedChat,
} from "../../modules/chat/redux/ChatSlice";
import { LCName, getItemLC, setItemLC } from "../../modules/localstorage";
import { useTheme } from "../../../setup/theme/useTheme";
import Notification from "../../models/Notification";
import i18n from "../../../setup/translations/i18next";
import { HeaderNotificationsMenu } from "./header-menus/HeaderNotificationsMenu";
import RealtimeInfo from "src/app/components/speedtest";

const CustomHeader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isNotif, setIsNotif] = useState<Array<Notification>>([]);
  const [client, setClient] = useState<any>(undefined);
  const [activeLang, setActiveLang] = useState(i18n.language);
  const currentTheme = useTheme();

  const updateIsNotifiedState = (notifications: Notification[]) => {
    const unreadNotifications = notifications?.filter(
      (notif) => notif.read === false || notif.read === undefined
    );
    const hasNotifications = unreadNotifications?.length > 0;
    setIsNotified(hasNotifications);
    setIsNotif(unreadNotifications);
    setItemLC(LCName.isHaveNotif, hasNotifications);
  };

  useEffect(() => {
    const notifs: Array<Notification> = getItemLC(LCName.Notification);
    updateIsNotifiedState(notifs);

    function storageEventHandler(event: any) {
      const updatedNotifs: Array<Notification> = getItemLC(LCName.Notification);
      updateIsNotifiedState(updatedNotifs);
    }

    window.addEventListener("storageNotif", storageEventHandler);
    setClient(getItemLC(LCName.Client));

    return () => {
      dispatch(deleteMessage(true));
      dispatch(deleteAllChatMessage(true));
      window.removeEventListener("storageNotif", storageEventHandler);
    };
  }, [dispatch]);

  const changeLanguageHandler = (lang: string) => {
    if (lang === "id") {
      i18n.changeLanguage("id");
      setActiveLang("id");
    } else {
      i18n.changeLanguage("en");
      setActiveLang("en");
    }
  };

  const toolbarButtonMarginClass = "ms-1 ms-lg-3",
    toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
    toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
    toolbarButtonIconSizeClass = "svg-icon-1";

  return (
    <nav
      className="w-100 align-self-end navbar navbar-expand-lg py-0 app_header sv-header sv-font-18"
      id="kt_app_header"
    >
      <div className="container-fluid justify-content-end py-0 bg-white ps-5 pe-10">
        <div className="d-flex align-items-stretch flex-shrink-0">
          <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <div className="d-flex flex-row flex-fill">
              <div className="navbar-nav text-right list-inline d-flex flex-row justify-content-end w-fit align-items-center gap-3">
                <RealtimeInfo />
                <div className="nav-item dropdown" data-testid="CustomHeaderAbout">
                  <p
                    className="nav-link dropdown-toggle py-0 my-0 px-2 text-black-50"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ fontSize: "1.05rem", cursor: "pointer" }}
                  >
                    {t("BS.Info.Languages")}
                  </p>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <p
                        className="dropdown-item m-0 py-0 px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          changeLanguageHandler("id");
                        }}
                      >
                        {t("Global.Languages.id")}
                      </p>
                    </li>
                    <li><hr className="dropdown-divider"></hr></li>
                    <li>
                      <p
                        className="dropdown-item m-0 py-0 px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          changeLanguageHandler("en");
                        }}
                      >
                        {t("Global.Languages.en")}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="nav-item w-auto" data-testid="CustomHeaderAbout">
                  <Link
                    to="/about"
                    className="nav-link text-black-50"
                    id="link-about"
                    style={{ fontSize: "1.05rem" }}
                  >
                    {t("HeaderBar.Button.About")}
                  </Link>
                </div>
                <div className="nav-item w-auto" data-testid="CustomHeaderFAQ">
                  <Link
                    to="/faq"
                    className="nav-link nav-toolbar text-black-50"
                    id="link-faq"
                    style={{ fontSize: "1.05rem" }}
                  >
                    {t("HeaderBar.Button.FAQ")}
                  </Link>
                </div>
                <div className={"d-flex align-items-center p-0 m-0 w-auto"}>
                  <div
                    className={clsx("btn btn-icon btn-custom me-2 p-0", toolbarButtonHeightClass)}
                    data-testid="CustomHeaderNotification"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-start"
                    data-kt-menu-flip="bottom"
                  >
                    <div className="symbol symbol-30px">
                      <div className="bg-white symbol-label fs-1 fw-bold position-relative">
                        <KTSVG
                          path="/media/icons/duotune/general/gen007.svg"
                          className={toolbarButtonIconSizeClass}
                        />
                      </div>
                      {isNotified && isNotif !== undefined && (
                        <span className="position-absolute top-25 start-75 translate-middle p-2 bg-success rounded-circle"></span>
                      )}
                    </div>
                  </div>
                  <HeaderNotificationsMenu />
                </div>
                <div className="d-flex flex-row border search-toolbar w-25 h-75" id="kt_chat_contacts_header">
                  <input
                    className="form-control border-0"
                    type="search"
                    placeholder={t("HeaderBar.Input.Search")}
                    id="example-search-input"
                    style={{ backgroundColor: "transparent" }}
                  />
                  <button className="btn border-0 search-toolbar-icon p-0 pe-2" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div
                  className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    data-testid="CustomHeaderClientAvatar"
                    className={clsx("cursor-pointer symbol", toolbarUserAvatarHeightClass)}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                  >
                    <img
                      id="active_user_avatar"
                      style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                      data-testid="CustomHeaderClientAvatarImage"
                      src={toAbsoluteUrl(
                        client !== undefined &&
                          (client.avatar !== undefined && client.avatar) !== ""
                          ? client.avatar
                          : "/media/icons/avatar/client-icon.png"
                      )}
                      alt="avatar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export { CustomHeader };
