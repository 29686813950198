import React, { useEffect, useState } from "react";
import { KTCard } from "../../../../../resources/helpers/components/KTCard";
import MarketplaceListHeader from "./marketplace-list/header/MarketplaceListHeader";
import { useMediaQuery } from "react-responsive";
import MarketplaceTable from "./marketplace-list/components/table/MarketplaceTable";
import MarketplaceCard from "./marketplace-list/card/MarketplaceCard";
import MarketplacePagination from "./marketplace-list/components/pagination/MarketplaceListPagination";
import { TableProvider } from "./marketplace-list/context/TableContext";
import Account from "../../../../models/Account";
import ModalFailRecon from "./marketplace-list/components/MarketplaceModalFailRecon";
import { getAccountByID } from "../../../../../db/serviceAccount";

const MarketplaceList = () => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [accountName, setAccountName] = useState<any>("");
  const onModalClose = () => setModalOpen(false);


  useEffect(() => {
    const EventHandler = async (event: any) => {
      if (event?.data) {
        console.log(event?.data, "event data reconnn");
        if (typeof event?.data === 'string' && event?.data.includes("reconnect")) {
          console.log("Process Reconnect Muncul Modal reconnn");
          setModalOpen(true);
          let message = event?.data;
          let id = message.replace("reconnectID : ", "");
          let account = await getAccountByID(id);
          console.log(account, "test event data account");
          if (account) {
            setAccountName(account?.name);
            setData(account);
          }
        }
        if (event?.data === "ServerClosed") {
          window.location.reload();
        }
      }
    };
    window.addEventListener("message", EventHandler);
    return () => {
      window.removeEventListener("message", EventHandler);
    };
  }, []);

  return (
    <TableProvider<Account> collection="account">
      <KTCard>
        <MarketplaceListHeader />

        {isMobileView ? <MarketplaceCard /> : <MarketplaceTable />}

        <MarketplacePagination />

        {modalOpen && (
          <ModalFailRecon
            isOpen={modalOpen}
            onClose={onModalClose}
            data={data}
          />
        )}
      </KTCard>
    </TableProvider>
  );
};

export default MarketplaceList;
