import { Timestamp } from "firebase/firestore";

export type ShipmentDeadlines = {
  id: string;
  logisticName: string;
  logisticSearchKey: string;
  marketplaceName: string;
  maxShipTime: Timestamp;
  minShipTime: Timestamp;
};

interface InitialState {
  listShipments: [];
  summaryListShipments: [];
  shipmentDeadlines: ShipmentDeadlines[];
}

const UpdateShipments: string = "UpdateShipments";

export default InitialState;

export { UpdateShipments };
