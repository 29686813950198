import clsx from "clsx";
import { t } from "i18next";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { getWABAccountByID } from "../../../../../../../db/serviceWABA";
import { templateStatus } from "../../../../../../models/Language";
import { templateLanguages } from "../../../../../../models/Template";
import * as ReduxWABASignup from "../../../../../../modules/waba-accounts/redux/data/WABAAccountSignUpSlice";

type Props = {
  id?: string;
  progress?: number;
  lastStep?: string;
  templateVerification?: any;
  errorMessage: string;
};

type wabaData = {
  waba_id?: string;
  phone_number_id?: string;
  business_name?: string;
};

const WABAAccountProgressCell: FC<Props> = ({
  id,
  progress,
  lastStep,
  templateVerification,
  errorMessage,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const nav = useNavigate();
  const supportEmail = process.env.REACT_APP_7DAYS_SUPPORT_EMAIL || "";

  const onClickLastStepHandler = async () => {
    if (lastStep === "PhoneNumberVerification") {
      console.log(`editing on id waba: ${id}`);
      //get waba by id
      const wabaData = await getWABAccountByID(id!);
      //set data wabaid and phoneNumberID
      console.log(`wabaData: ${JSON.stringify(wabaData)}`);
      let data: wabaData = {
        waba_id: id,
        phone_number_id: wabaData?.whatsappNumbers
          ? wabaData.whatsappNumbers[0].id!
          : "",
        business_name: wabaData?.businessName,
      };
      console.log(`json for wabaData edit:`);
      dispatch(ReduxWABASignup.setDataWABA(data));
      dispatch(ReduxWABASignup.setIsEdit(true));
      nav("/setting/waba-account/create/verify");
    }
  };

  const hasError =
    (templateVerification &&
      templateVerification?.status === templateStatus.rejected) ||
    (errorMessage && errorMessage !== "");

  return (
    <div>
      <div
        className={clsx(
          `progress`,
          isTabletOrMobile ? "w-100 h-10px mb-1" : "w-100 h-15px mb-2",
          !progress || progress < 1
            ? "justify-content-center align-items-center"
            : ""
        )}
      >
        {progress! > 0 && (
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              width: `${progress}%`,
              backgroundColor: hasError ? "#EC4747" : "#2DC56A",
            }}
          >
            {progress}%
          </div>
        )}
        {(!progress || progress < 1) && <div className="text-center">0%</div>}
      </div>
      <div
        className={clsx(isTabletOrMobile ? "text-start px-1" : "text-center")}
      >
        {lastStep === "PhoneNumberVerification" ? (
          <div
            id={`toresume-${id}`}
            onClick={() => {
              onClickLastStepHandler();
            }}
            style={{ cursor: "pointer", color: "#2C9FF7" }}
          >
            {lastStep
              ? t(`WABAAccountList.Column.SetupProgress.${lastStep}`)
              : ""}
          </div>
        ) : lastStep === "CreateTemplate" ? (
          hasError ? (
            <div className="d-flex text-center justify-content-center">
              <div>{t("WABAAccountList.Column.SetupProgress.Status")}</div>
              <div
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="top-end"
                data-kt-menu-flip="top"
              >
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-5">
                    {t(
                      "WABAAccountList.Column.SetupProgress.ErrorAlert"
                    ).replace("{{contact 7days}}", supportEmail)}
                  </div>
                </div>
                <span className="bi bi-info-circle ms-3" />
              </div>
            </div>
          ) : (
            t("WABAAccountList.Column.SetupProgress.Preparationsendingmessage")
          )
        ) : lastStep ? (
          t(`WABAAccountList.Column.SetupProgress.${lastStep}`)
        ) : (
          ""
        )}
        {/* {lastStep ? t(`WABAAccountList.Column.SetupProgress.${lastStep}`) : ''} */}
      </div>
    </div>
  );
};

export { WABAAccountProgressCell };
