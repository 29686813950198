import { Column } from "react-table";
import Customer from "../../../../../models/Customer";
import { TargetCustomHeader } from "./TargetCustomHeader";
import { TargetEmailCell } from "./TargetEmailCell";
import { TargetInfoCell } from "./TargetInfoCell";
import { TargetPhoneNumberCell } from "./TargetPhoneNumberCell";
import { TargetSelectionCell } from "./TargetSelectionCell";
import { TargetSelectionHeader } from "./TargetSelectionHeader";
import { TargetTwoStepsCell } from "./TargetTwoStepsCell";

import { useTranslation } from "react-i18next";
import CellDate from "../../../../../components/table/columns/CellDate";

const targetColumns: ReadonlyArray<Column<Customer>> = [
  {
    Header: (props) => <TargetSelectionHeader tableProps={props} />,
    id: "selection",
    Cell: ({ ...props }) => (
      <TargetSelectionCell customer={props.row.original} />
    ),
  },
  {
    Header: (props) => (
      <TargetCustomHeader
        tableProps={props}
        title="Common.Column.Name"
        className="min-w-125px"
      />
    ),
    id: "firstName",
    Cell: ({ ...props }) => (
      <TargetInfoCell contact={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <TargetCustomHeader
        tableProps={props}
        title="Common.Column.PhoneNumber"
        className="min-w-125px"
      />
    ),
    id: "phoneNumber",
    Cell: ({ ...props }) => (
      <TargetPhoneNumberCell
        phoneNumber={props.data[props.row.index].phoneNumber}
      />
    ),
  },
  {
    Header: (props) => (
      <TargetCustomHeader
        tableProps={props}
        title="Common.Column.CreatedAt"
        className="min-w-125px"
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <CellDate value={props.data[props.row.index].createdAt} />
    ),
  },
  {
    Header: (props) => (
      <TargetCustomHeader
        tableProps={props}
        title="Common.Column.LastInteractions"
        className="min-w-125px"
      />
    ),
    id: "lastInteractionAt",
    Cell: ({ ...props }) => {
      console.warn(`query campaign target`);
      return <CellDate value={props.data[props.row.index].lastInteractionAt} />;
    },
  },
];

export { targetColumns };
