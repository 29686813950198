/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../../resources/helpers";
import { Campaign } from "../../../../../../models/Campaign";

type Props = {
  campaign: Campaign;
};

const CampaignInfoCell: FC<Props> = ({ campaign }) => {
  let campaignName =  campaign.campaignName;
  
  return (
  <div className="d-flex align-items-center">
    <div className="d-flex flex-column">
    {campaignName}
      {/* <Link
        to={
          "/campaign/campaign-detail/overview/" +
          campaignName
        }
        state={{
          id: campaign.id,
          campaignName,
        }}
        className="text-gray-800 text-hover-primary mb-1"
      >
        {campaignName}
      </Link> */}
      
      {/* <span>{campaign.email}</span> */}
    </div>
  </div>
)};

export { CampaignInfoCell };
