import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as ApiSettingService from "../../../../../../db/serviceApiSetting";
import * as ApiSettingApi from "../../../../../../api/server/api-setting";
import * as SandboxSettingServer from "../../../../../../api/sandbox/setting";
import * as ApiSettingSlice from "../../../../../modules/api-setting/redux/ApiSettingSlice";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";
import Button from "../../../../../components/button/Button";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const IS_SANDBOX =
  process.env.REACT_APP_ENVIRONMENT === "sandbox" ||
  process.env.REACT_APP_ENVIRONMENT === "stagging-sandbox";

const LandingPage: React.FC = (props) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const handleGenerateAccessButton = async () => {
    console.log("handleGenerateAccessButton");
    // Create access API for client
    const access = await ApiSettingService.createAccess();
    const push = await ApiSettingService.createPush();
    const sms = await ApiSettingService.createSms();
    if (!access || !push || !sms) return;

    // Generate access token
    const token = await ApiSettingApi.generateAccessToken(access.secretKey);
    if (!token) return;

    if (!IS_SANDBOX) {
      // initialize sandbox environment
      const sandboxResult = await SandboxSettingServer.initializeSandbox();
      if (!sandboxResult?.response.data) {
        toast(sandboxResult?.response.error ?? "Failed intialize sandbox", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      const sandboxCredential = sandboxResult?.response?.data;

      // Save access token to redux
      dispatch(
        ApiSettingSlice.setApiSettingAccess({
          ...access,
          accessToken: token.data,
        })
      );

      // Save sandbox credential to redux
      if (!!sandboxCredential) {
        dispatch(
          ApiSettingSlice.setApiSandboxSettingAcces({
            secretKey: sandboxCredential.secret_key,
            accessToken: sandboxCredential.access_token,
          })
        );
      }
    }
  };

  const textSize = isMobileView ? "" : " display-5";
  const centerTextVertical = isMobileView ? " my-20" : " justify-content-center";
  const imgHeight = isMobileView ? " h-50" : "";

  return (
    <div
      className="card h-100"
      style={{ marginTop: isMobileView ? "50px" : "" }}
      data-testid="landing-page"
    >
      <img
        className={`card-img ${imgHeight} bg-image image-fluid`}
        src={toAbsoluteUrl("/media/images/bg_api.png")}
        alt="7Days"
      />
      <div
        className={`card-img-overlay d-flex ${centerTextVertical} align-items-center flex-column`}
      >
        <h1 className={`card-title text-white text-center ${textSize} p-2`}>
          Empower Innovation, Connect Easily
        </h1>
        <h1 className={`card-title text-white text-center ${textSize} p-0`}>
          Your Ideas, Our API Open Platform
        </h1>
        <div className="p-2 my-10">
          <Button
            onClick={async () => {
              await handleGenerateAccessButton();

              queryClient.invalidateQueries("apiSetting");
            }}
            className="btn btn-primary"
            type="button"
            data-testid="generate-access-button"
          >
            {t("API.Setting.Overview.Landing")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
