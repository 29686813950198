import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Account from "src/app/models/Account";
import db from "src/db";
import { getAccountByID } from "src/db/serviceAccount";
import { toAbsoluteUrl } from "src/resources/helpers";
import { CapitalizedLetter } from "src/util/Formatter";

type ModalInvoicePreviewProps = {
  open: boolean;
  close?: () => void;
  marketplaceType: string;
  downloadInv?: () => void;
  data?: any;
};

export const ModalInvoicePreview: FC<ModalInvoicePreviewProps> = ({
  open,
  close,
  marketplaceType,
  downloadInv,
  data,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [accountData, setAccountData] = useState<any>();
  const { t } = useTranslation();

  const orderStatus: { [key: string]: string } = {
    NEW_ORDER: "OrderStatus7Days.NewOrder",
    PROCESSED: "OrderStatus7Days.Processing",
    COMPLETED: "OrderStatus7Days.Completed",
    CANCELLED: "OrderStatus7Days.Cancelled",
    CANCELED: "OrderStatus7Days.Cancelled",
    READY_TO_SHIP: "OrderStatus7Days.ReadyToShip",
    UNPAID: "OrderStatus7Days.Unpaid",
    SHIPPING: "OrderStatus7Days.Shipping",
    RETURN: "OrderStatus7Days.Return",
    DELIVER: "OrderStatus7Days.Delivered",
    DELIVERED: "OrderStatus7Days.Delivered",
  };

  const statusColor: { [key: string]: string } = {
    NEW_ORDER: "primary",
    PROCESSED: "warning",
    COMPLETED: "success",
    CANCELLED: "danger",
    CANCELED: "danger",
    READY_TO_SHIP: "success",
    UNPAID: "secondary",
    SHIPPING: "warning",
    RETURN: "secondary",
    DELIVER: "success",
    DELIVERED: "success",
  };

  let orderedItems: Array<any> = data?.itemList;

  useEffect(() => {
    const getAccountData = async () => {
      let idAccount = data?.account?.id;
      if (idAccount) {
        let acc = await db
          .collection("account")
          .doc(idAccount)
          .get()
          .then((doc) => doc.exists && doc.data());
        setAccountData(acc);
      }
    };
    getAccountData();
  }, []);

  const formatNumber = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  let totalAmount = 0;
  orderedItems.forEach((item) => {
    let price: number;
    if (item.discountedPrice) {
      price = item.discountedPrice;
    } else {
      if (item.originalPrice) {
        price = item.originalPrice;
      } else {
        price = item.priceActual;
      }
    }
    totalAmount += price * item.qtyOrderItem;
  });

  return (
    <Modal
      animation
      centered
      show={open}
      onHide={() => close && close()}
      scrollable={false}
      size="lg"
      dialogClassName={!isTabletOrMobile ? "w-550px" : ""}
      style={{
        zIndex: "99999",
      }}
    >
      <Modal.Header closeButton className="bg-light p-5">
        <Modal.Title data-testid="modal-title">Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light p-3 pt-0">
        <div
          className="bg-white p-0"
          style={{
            borderTopWidth: "5px",
            borderTopStyle: "solid",
            borderColor:
              marketplaceType === "tokopedia"
                ? "#4F9D4E"
                : marketplaceType === "shopee"
                ? "#ee4d2d"
                : marketplaceType === "lazada"
                ? "#000083"
                : "gray",
          }}
        >
          <div className="d-flex flex-wrap-reverse flex-row w-100 align-items-end justify-content-between px-5 py-5">
            <div className="d-flex flex-column m-0 p-0">
              <div className="d-flex flex-wrap flex-row col-gap-1 align-items-center justify-content-start mb-3">
                <p className="fw-bolder fs-5 m-0 p-0">{data.ordersn}</p>
                <span
                  className={`m-0 p-0 bg-${
                    statusColor[
                      marketplaceType === "lazada"
                        ? data.marketplace_status
                        : data.status
                    ]
                  } px-2 py-1 rounded text-white fs-9`}
                >
                  {t(
                    orderStatus[
                      marketplaceType === "lazada"
                        ? data.marketplace_status
                        : data.status
                    ]
                  )}
                </span>
              </div>
              <p className="mb-0 pb-0">
                <span style={{ width: "120px", display: "inline-block" }}>
                  {t("HandledCustomer.Modal.Invoice.OrderCreated")}
                </span>{" "}
                :{" "}
                {moment(
                  new Date(data?.marketplaceOrderCreatedAt?.seconds * 1000)
                ).format("DD/MM/YYYY")}
              </p>
              {data?.shippingTrackingNumber && (
                <p className="pt-0 mt-0 pb-0 mb-0">
                  <span style={{ width: "120px", display: "inline-block" }}>
                    {t("HandledCustomer.Modal.Invoice.TrackingNumber")}
                  </span>{" "}
                  : {data?.shippingTrackingNumber}
                </p>
              )}
            </div>
            <div className="w-auto d-flex align-items-start justify-content-end m-0 p-0">
              <img
                className="w-50px"
                src={toAbsoluteUrl(
                  `/media/logos/marketplace/${marketplaceType.toLowerCase()}.png`
                )}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  padding: 0,
                }}
              />
            </div>
          </div>

          <div className="border-bottom border-1 border-secondary"></div>

          <div className="d-flex flex-wrap flex-row w-100 align-items-start justify-content-between px-5 py-5">
            <div className="d-flex flex-column w-50">
              <p className="mb-0 pb-2 fw-bolder" style={{ color: "#979797" }}>
                {t("Notif.info.From")}
              </p>
              <p className="mb-0 pb-0" style={{ wordBreak: "break-word" }}>
                {CapitalizedLetter(marketplaceType)}
              </p>
              {accountData && (
                <p className="mb-0 pb-0" style={{ wordBreak: "break-word" }}>
                  {CapitalizedLetter(accountData.name)}
                </p>
              )}
            </div>
            <div className="d-flex flex-column w-50">
              <p className="mb-0 pb-2 fw-bolder" style={{ color: "#979797" }}>
                {t("Notif.info.To")}
              </p>
              <p
                className="mb-0 pb-0 flex-wrap"
                style={{ wordBreak: "break-word" }}
              >
                {CapitalizedLetter(data?.buyerName)}
              </p>
              <p className="mb-0 pb-0" style={{ wordBreak: "break-word" }}>
                {data?.shippingAddress?.detailAddress},{" "}
                {data?.shippingAddress?.district}
              </p>
              <p
                className="mb-0 pb-0 flex-wrap"
                style={{ wordBreak: "break-word" }}
              >
                {data?.shippingAddress?.city}, {data?.shippingAddress?.state},{" "}
                {data?.shippingAddress?.postalCode}
              </p>
              <p className="mb-0 pb-0" style={{ wordBreak: "break-word" }}>
                {data?.receiverPhone}
              </p>
            </div>
          </div>

          <div className="border-bottom border-1 border-secondary"></div>

          <div className="d-flex flex-row w-100 align-items-start justify-content-between px-5 py-5">
            <div className="d-flex flex-column w-50">
              <p className="fw-bolder fs-5 mb-2 p-0">
                {t("HandledCustomer.Modal.Invoice.Shipper")}
              </p>
              <p className="mb-0 pb-0">
                {data?.marketplaceDeliveryChannelParent ||
                  data?.marketplaceLogisticChannelParent ||
                  data?.marketplaceDeliveryChannelName}{" "}
                - {data?.shippingLabel}
              </p>
            </div>
          </div>

          <div className="border-bottom border-1 border-secondary"></div>

          <div className="d-flex flex-row w-100 align-items-start justify-content-between px-5 py-5">
            <div className="d-flex flex-column w-100">
              <p className="fw-bolder fs-5 mb-2 p-0">
                {t("HandledCustomer.Modal.Invoice.ItemsOrder")}
              </p>
              <div className="d-flex w-100 col-gap-1 flex-row mb-1 pb-0">
                <p
                  className="mb-0 pb-0 fw-bolder"
                  style={{ color: "#979797", width: "60%" }}
                >
                  {t("HandledCustomer.Modal.Invoice.Product")}
                </p>
                <p
                  className="mb-0 pb-0 text-center fw-bolder"
                  style={{ color: "#979797", width: "10%" }}
                >
                  {t("HandledCustomer.Modal.Invoice.Quantity")}
                </p>
                <p
                  className="mb-0 pb-0 text-center fw-bolder"
                  style={{ color: "#979797", width: "20%" }}
                >
                  {t("HandledCustomer.Modal.Invoice.UnitPrice")}
                </p>
                <p
                  className="mb-0 pb-0 text-end fw-bolder"
                  style={{ color: "#979797", width: "20%" }}
                >
                  {t("HandledCustomer.Modal.Invoice.SubTotal")}
                </p>
              </div>
              {orderedItems.length &&
                orderedItems?.map((item: any, index: number) => {
                  let price: number;
                  if (item.discountedPrice) {
                    price = item.discountedPrice;
                  } else {
                    if (item.originalPrice) {
                      price = item.originalPrice;
                    } else {
                      price = item.priceActual;
                    }
                  }
                  return (
                    <div
                      className={`d-flex w-100 col-gap-1 flex-row py-1 ${
                        // orderedItems?.length > 1 &&
                        "border-bottom border-1 border-secondary"
                      }`}
                      key={index}
                    >
                      <p className="mb-0 pb-0" style={{ width: "60%" }}>
                        {item?.itemName}
                      </p>
                      <p
                        className="mb-0 pb-0 text-center"
                        style={{ width: "10%" }}
                      >
                        {item?.qtyOrderItem}
                      </p>
                      <p
                        className="mb-0 pb-0 text-center"
                        style={{ width: "20%" }}
                      >
                        Rp {formatNumber(price)}
                      </p>
                      <p
                        className="mb-0 pb-0 text-end"
                        style={{ width: "20%" }}
                      >
                        Rp {formatNumber(price * item?.qtyOrderItem)}
                      </p>
                    </div>
                  );
                })}

              <div className="d-flex w-100 col-gap-1 flex-row mt-1">
                <p className="mb-0 pb-0" style={{ width: "60%" }}></p>
                <p className="mb-0 pb-0" style={{ width: "10%" }}></p>
                <p
                  className="mb-0 pb-0 text-center fw-bolder"
                  style={{ width: "20%" }}
                >
                  {t("HandledCustomer.RoomChat.GranTot")}
                </p>
                <p
                  className="mb-0 pb-0 text-end fw-bolder"
                  style={{ width: "20%" }}
                >
                  Rp {formatNumber(totalAmount)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-2 m-0">
        {downloadInv && marketplaceType === "tokopedia" && (
          <button className="btn btn-primary" onClick={downloadInv}>
            {t("ExportOrderProcess.Button.Download")} Invoice
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
