import clsx from "clsx";
import { t } from "i18next";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { saveMessageMedia } from "src/actions/chat";
import Message from "src/app/models/Message";
import { Order } from "src/app/models/Order";
import db from "src/db";

import { ModalInvoicePreview } from "../modals/InvoicePreview";

interface MessageProps {
  message: Message;
  bgChat?: string;
  txChat?: string;
  children?: any;
}

export const MessageInvoice: FC<MessageProps> = (props) => {
  const [orderData, setOrderData] = useState<any>();
  const [itemList, setItemList] = useState<any>([]);
  const [isOpenInvoice, setIsOpenInvoice] = useState<boolean>(false);
  let { message, bgChat, txChat, children } = props;

  const onClose = () => {
    setIsOpenInvoice(false);
  };

  const saveMedia = () => {
    if (message.mediaUrl) {
      if (message.filename !== undefined && message.filename !== "") {
        return saveMessageMedia(message.mediaUrl, message.filename);
      } else {
        const filename = t("HC.File.Untitled");
        return saveMessageMedia(message.mediaUrl, message.filename);
      }
    } else {
      return alert(t("HC.Error.FailedUpload"));
    }
  };

  let mediaFileName = message.filename;
  if (mediaFileName && mediaFileName?.length > 20) {
    mediaFileName = mediaFileName?.substring(0, 20) + "...";
  }

  useEffect(() => {
    const getOrderData = async () => {
      let mediaUrl = message.mediaUrl;
      let order_sn;
      const urlParts = mediaUrl?.split("?");
      let orderDatas: any;
      if (message.channel === "tokopedia" && urlParts && urlParts.length >= 2) {
        const queryParams = urlParts[1].split("&");
        for (const param of queryParams) {
          if (param.startsWith("id=")) {
            const idValue = param.split("=")[1];
            const decodedId = decodeURIComponent(idValue);
            order_sn = decodedId;
            break;
          }
        }

        try {
          const querySnapshot = await db
            .collection("orders")
            .where("ordersn", "==", order_sn)
            .get();

          orderDatas = querySnapshot.docs.map((doc) => doc.data());
        } catch (error) {
          orderDatas = undefined;
          console.error("Error fetching order data:", error);
        }
      }

      if (message.channel === "lazada" && message?.order) {
        try {
          const orderRef = db.collection("orders").doc(message.order.id);
          const getOrderById = await orderRef.get();

          if (getOrderById.exists) {
            let data_order = getOrderById.data();
            orderDatas = [data_order];
          }
        } catch (error) {
          orderDatas = undefined;
          console.error("Error fetching order:", error);
        }
      }

      if (orderDatas && orderDatas?.length > 0) {
        setOrderData(orderDatas[0]);
        setItemList(orderDatas[0]?.itemList);
      }
    };
    getOrderData();
  }, []);

  const capitalizeWords = (str: String) => {
    return str
      .replaceAll("_", " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const formatNumber = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  let totalAmount = 0;
  orderData?.itemList?.forEach((item: any) => {
    let price: number;
    if (item.discountedPrice) {
      price = item.discountedPrice;
    } else {
      if (item.originalPrice) {
        price = item.originalPrice;
      } else {
        price = item.priceActual;
      }
    }
    totalAmount += price * item.qtyOrderItem;
  });

  return (
    <>
      {orderData === undefined ? (
        <div
          className={clsx(
            "p-2 rounded",
            `${bgChat}`,
            "d-flex flex-row align-items-center bg-opacity-25"
          )}
          title={message.filename}
        >
          <div
            className="bi bi-file-earmark-text fs-3x"
            style={{ color: txChat }}
          ></div>
          <div
            className="container-sm"
            style={{ paddingLeft: "5px", color: txChat }}
          >
            {mediaFileName}
          </div>
          <div
            className="d-flex flex-column justify-content-end"
            style={{ width: "70px" }}
          >
            <div
              className="btn bi bi-download fs-3"
              onClick={saveMedia}
              style={{
                color: txChat,
                paddingTop: "5px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
              }}
            ></div>
            <div
              className="text-center"
              style={{ fontSize: "10px", color: txChat }}
            >
              {message.filesize}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            "p-2 rounded",
            `${bgChat}`,
            "d-flex flex-row align-items-start bg-opacity-25 col-gap-2"
          )}
          style={{
            width: "fit-content",
            cursor: "pointer",
          }}
          title={message.filename}
          onClick={() => {
            setIsOpenInvoice(true);
          }}
        >
          <img
            src={itemList[0]?.imageUrl}
            className="rounded"
            style={{
              width: "50px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color:
                orderData?.marketplaceType === "lazada" ? "white" : "black",
            }}
          >
            <p className="pb-0 mb-0">
              <span style={{ width: "80px", display: "inline-block" }}>
                {t("HandledCustomer.RoomChat.SerialNum")}
              </span>
              : {orderData?.ordersn}
            </p>
            <p className="pt-0 mt-0 pb-0 mb-0">
              <span style={{ width: "80px", display: "inline-block" }}>
                {t("Common.Modal.Column.Header.Status")}
              </span>
              : {orderData && capitalizeWords(orderData.status)}
            </p>
            <p className="pt-0 mt-0 pb-2 mb-0">
              <span style={{ width: "80px", display: "inline-block" }}>
                {t("HandledCustomer.RoomChat.GranTot")}
              </span>
              : Rp {formatNumber(totalAmount)}
            </p>
            <p
              className="fs-9 mb-0 pb-0"
              style={{
                textDecoration: "underline",
              }}
            >
              {t("HandledCustomer.RoomChat.SeeDetail")}
            </p>
          </div>
        </div>
      )}

      {isOpenInvoice && (
        <ModalInvoicePreview
          open={isOpenInvoice}
          close={onClose}
          marketplaceType={orderData?.marketplaceType}
          downloadInv={saveMedia}
          data={orderData}
        />
      )}
    </>
  );
};
