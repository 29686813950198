import db, { Timestamp } from ".";
import * as conn from "./connection";
import { converter2 } from "./converter";
import { createRef } from "./connection";
import Reconsile from "../app/models/Reconsile";
import moment from "moment";

export const getConversationAnalyticByFilter = async (
  start: Date,
  end: Date,
  granularity: string,
  category: string[],
  phoneNumber: string[],
) => {
  if(!start || !end || !granularity || !category || category.length === 0 || !phoneNumber || phoneNumber.length === 0 ) return undefined;
  console.log(`getConversationAnalyticByFilter with param :` , {
    start,end,granularity,category,phoneNumber
  });

  // const startFormatted = Timestamp.fromDate(new Date(+start * 1000));
  // const endFormatted = Timestamp.fromDate(new Date(+end * 1000));

  const startFormatted = Timestamp.fromDate(start);
  const endFormatted = Timestamp.fromDate(end);
  console.log(`range start : ${startFormatted} end : ${endFormatted}`);
  console.log('FETCHIING SERVICE ANALYTICS')
  return await db
    .collection("reconsile")
    .withConverter(converter2<Reconsile>())
    .where("mutasiStartDate", ">=", startFormatted)
    .where("mutasiStartDate", "<=", endFormatted)
    .where("granularity", "==", "HALF_HOUR")
    // .where("conversationCategory", "in", category)
    .where("wabaPhoneNumber", "in", phoneNumber)
    .get()
    .then((snapshot) => {
      console.log({snapshot})
      const conversationAnalytics = snapshot.docs.map((doc) => {
        console.log(`snapshot ${snapshot}`);
        return { ...doc.data(), id: doc.id };
      });
      console.log("getAnalytic: ", conversationAnalytics);
      return conversationAnalytics;
    });
};