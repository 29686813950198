import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import firebase from "firebase/compat/app";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerByPhoneNumber } from "../../../../../../db";
import * as campaignServices from "../../../../../../db/serviceCampaign";
import { ID, Response } from "../../../../../../resources/helpers";
import { RootState } from "../../../../../../setup/redux/store";
import { Campaign, CampaignMessage } from "../../../../../models/Campaign";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

const getCampaigns = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number,
  campaignId: string
): Promise<Array<Campaign>> => {
  let sortBy: string = "id";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }

  //TODO proses get data
  let arrCampaign: Array<Campaign> = [];
  // let dataCampaign = undefined;
  let getCampaignData; //: Campaign;

  // console.log(`Campaign ID ${campaignId}`);

  getCampaignData = await campaignServices.getCampaignById(campaignId); // as Campaign;
  // dataCampaign = (await getCampaignData);
  if (getCampaignData && getCampaignData.id && getCampaignData.data) {
    getCampaignData.data.id = getCampaignData.id;

    // Campaign Report Data (from Realtime DB by CampaignId)
    let messagesCount =
      await campaignServices.countCampaignMessagesByCampaignId(
        getCampaignData.id
      );
      console.log(messagesCount, "message count")
    if (messagesCount) {
      getCampaignData.data.campaignMessagesCount = messagesCount;
    } else {
      getCampaignData.data.campaignMessagesCount = {
        countCreated: 0,
        countSubmitted: 0,
        countSent: 0,
        countDelivered: 0,
        countRead: 0,
        countFailed: 0,
      };
    }

    // Campaign Messages
    let campaignMessages = await campaignServices.fetchMessagesByCampaignID(
      getCampaignData.id
    );
    if (campaignMessages) {
      // Get Customer Data
      // campaignMessages.forEach(async (message) => {
      //   let customerData = await getCustomerByPhoneNumber(message.data.phoneNumber);
      //   if (customerData) {
      //     message.data.customerModel = customerData.find(cust => {
      //       return cust.phoneNumber === message.data.phoneNumber;
      //     });
      //   }
      // })

      const campMsgWithCust = await Promise.all(
        campaignMessages.map(async (message) => {
          let customerData = await getCustomerByPhoneNumber(
            message.data.phoneNumber
          );
          if (customerData) {
            const foundedCust = customerData.find((cust) => {
              return cust.phoneNumber === message.data.phoneNumber;
            });
            if (foundedCust) {
              return {
                data: {
                  ...message.data,
                  customerModel: foundedCust,
                },
              };
            }
          }
          return message;
        })
      );

      getCampaignData.data.campaignMessages = campMsgWithCust;
    }

    console.log(
      "Fetched Campaign Data: " + JSON.stringify(getCampaignData.data)
    );
    arrCampaign.push(getCampaignData.data as Campaign);
  }

  if (arrCampaign.length > 0) {
    console.log("Campaign data report exist...");
  }

  return Promise.resolve(arrCampaign);
};

const getCampaignById = (id: ID): Promise<Campaign | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Campaign>>) => response.data)
    .then((response: Response<Campaign>) => response.data);
};

export { getCampaignById, getCampaigns };
