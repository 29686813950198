import * as fireStorage from "../api/firebase/storage";
import Brand, { MarketplacesBrand } from "../app/models/Brand";
import { Media } from "../app/models/Product";
import * as lc from "../app/modules/localstorage";
import db, { Timestamp, createRef } from "../db";
import * as serviceBrand from "../db/serviceBrand";

export const createDataBrand = async (dataBrand: Brand) => {
  let brandId = "";
  if (dataBrand && dataBrand.brandName) {
    const brand = await serviceBrand.getBrandByName(dataBrand.brandName);
    if (brand && brand.length > 0) {
      console.log(
        "Result Brand : " + brand.length + " (" + dataBrand.brandName + ")"
      );
      brandId = brand[0].id;
      return brandId;
    }
  }
  return await serviceBrand.createBrand(dataBrand);
};

export const getListBrands = async (callback: any) => {
  const listBrands = await serviceBrand.getAllBrands();
  console.log("Result Get List Brand 2 : " + listBrands);
  if (listBrands && listBrands.length > 0) {
    let brands: any[] = [];
    let resultBrand: any[] = [];
    listBrands.map((brandResult) => {
      resultBrand.push(brandResult as Brand);
      brands.push({
        label: brandResult.data.brandName,
        value: brandResult.id,
      });
    });
    callback(brands, resultBrand);
  } else {
    callback([], []);
  }
};

export const createBrand = async (name: string, categories: any) => {
  const refUser = createRef("users", lc.getItemLC(lc.LCName.UserID));

  const model: Brand = {
    brandName: name,
    isActive: true,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    createdBy: refUser,
    updatedBy: refUser,
    brandNameInsensitive: name.toLowerCase(),
  };

  const brandId = await createDataBrand(model);
  console.log(`ini ada brand id ${brandId}`);
  if (!brandId) {
    return undefined;
  }

  if (categories && categories.length > 0) {
    const nestedObject = categories.reduce(
      (acc: any, category: any, index: any) => {
        acc[index] = category;
        return acc;
      },
      {}
    );

    const modelCategory: MarketplacesBrand = {
      categories: [nestedObject],
      marketplaceName: "shopee",
    };

    const categoryBrand = await serviceBrand.getBrandCategory(
      brandId,
      "shopee"
    );

    console.log(
      `ini adalah category brand yang baru ${JSON.stringify(modelCategory)}`
    );
    console.log(
      `ini adalah category brand yang sudah ada ${JSON.stringify(
        categoryBrand
      )}}`
    );

    if (!categoryBrand) {
      //error when querying marketplaceBrands
      console.log(`query error`);
      return undefined;
    } else if (categoryBrand.length === 0) {
      //categoryBrand not exist => create subcollection baru
      console.log(
        `categoryBrand not exist => create subcollection baru ${JSON.stringify(
          categoryBrand
        )}`
      );
      await serviceBrand.createBrandCategory(brandId, modelCategory);
    } else if (categoryBrand[0] && categoryBrand[0].brandID) {
      //TODO : categoryBrand exist & categoryBrand brandId exist => return error
      console.error(`category already exists`);
      return undefined;
    } else {
      //TODO : categoryBrand exist & categoryBrand brandId not exist => update collection
      console.log(
        `categoryBrand exist & categoryBrand brandId not exist => update collection  ${JSON.stringify(
          categoryBrand
        )}`
      );
      await serviceBrand.createBrandCategory(
        brandId,
        modelCategory,
        categoryBrand[0].id
      );
    }
  }
  return { ...model, id: brandId } as Brand;
};

export const createBrandDataMedia = async (
  brandID: string,
  mediaType: string,
  dataMedia: Media
) => {
  // check collection photo brand exist

  // console.log(`create ol media | before cek media exist ${brandID} `);
  // const existMedia = await serviceBrand.getMedia(brandID);

  // console.log(`create ol media | after cek media exist ${JSON.stringify(existMedia)}`);

  // // if exist, delete all media
  // if(existMedia){
  //   // map existMedia to array of id
  //   existMedia.map(async (media) => {
  //     // delete media from firebase storage
  //     if(!media.id) return undefined;
  //       console.log(`delete media req ${media.id}`)
  //       await serviceBrand.deleteMedia(brandID, mediaType, media?.id);

  //   });
  // }

  return await serviceBrand.createMedia(brandID, mediaType, dataMedia);
};

/*
//return true / false
//if false show popup error

const deletePhotos = (brandId) => {
  //get all photos

  //delete photos from storage & collections
} 
*/
export const deleteMedia = async (brandId: string, mediaType: string) => {
  console.log(`delete ol media | before cek media exist ${brandId} `);
  const existMedia = await serviceBrand.getMedia(brandId);

  console.log(
    `delete ol media | after cek media exist ${JSON.stringify(existMedia)}`
  );
  // if exist, delete all media
  if (existMedia) {
    // map existMedia to array of id
    existMedia.map(async (media) => {
      // delete media from firestore
      console.log("delete ol media");
      if (media.fileURL && media.id) {
        await serviceBrand
          .deleteMedia(brandId, mediaType, media.id)
          .then((result) => {
            if (result || !media.id || !media.fileURL) {
              return false;
            }
            fireStorage.deleteFile(media.fileURL, media.id);
          });
      }
    });
  }
  return true;
};
