import db from ".";
import { ShopeeProductCategoryAttribute } from "../app/models/ShopeeProductCategoryAttribute";
import { converter2 } from "./converter";

export const getListAttributesListByMarketplaceAndCategoryId = async (
  marketplace: string,
  categoryId: string,
) => {
  return await db
    .collection("marketplaceProductCategory")
    .doc(marketplace)
    .collection("categoryList")
    .doc(categoryId)
    .collection("attributeList")
    .withConverter(converter2<ShopeeProductCategoryAttribute>())
    .get()
    .then(async(snapshot: any) => {
        const attribute = snapshot.docs.map((doc:any) => doc.data())
        return attribute
    });
};
