/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useQueryClient } from "react-query";
import { ID, KTSVG, QUERIES } from "../../../../../../resources/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
// import { deleteCampaign } from "../../core/_requests";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import * as CampaignSlice from "../../../../../../app/modules/campaign/list/CampaignListSlice";
import {
  updateCampaignById,
  updateScheduleByCampaign,
} from "../../../../../../db/serviceCampaign";
import { RootState } from "../../../../../../setup/redux/store";
import { Campaign } from "../../../../../models/Campaign";
import * as ReduxCampaignReport from "../../../../../modules/campaign/report/CampaignReportSlice";
import ModalConfirmation from "../../../../../modules/modal/modalConfirmation";
import AlertModal from "../../../../broadcast/AlertModal";
import { useQueryRequest } from "../../core/QueryRequestProvider";

type Props = {
  id: ID;
  indexId: number;
  campaign: Campaign;
};

const CampaignActionsCell: FC<Props> = ({ id, indexId, campaign }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { setItemIdForUpdate } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { state, updateState } = useQueryRequest();
  const campaignList = useSelector(
    (state: RootState) => state.CampaignList.listCampaign
  );

  const onReportClickHandler = (event: any) => {
    const campaignId = event.target.id;
    const campaign = campaignList.find(
      (campaign) => campaign.id === campaignId
    );

    // console.log(`Clicked Campaign ID: ${campaignId} Data: ${JSON.stringify(campaign)}`);
    // Set Selected Campaign ID and Campaign in Redux
    if (campaignId && campaign) {
      // Set Campaign Data
      dispatch(ReduxCampaignReport.setSelectedCampaignID(campaignId));
      dispatch(ReduxCampaignReport.setSelectedCampaign(campaign));
      // Set Campaign Report List Data
      let campaignReportList: Campaign[] = [];

      campaignReportList.push(campaign);
      console.log('action cell Campaign report list from DB :', campaignReportList)
      dispatch(ReduxCampaignReport.setListCampaignReport(campaignReportList));

      nav(`/campaign/${campaignId}/report`);
    }
  };

  const onEditCampaignHandler = (event: any) => {
    const campaignId = event.target.id;
    const campaign = campaignList.find(
      (campaign) => campaign.id === campaignId
    );

    if (campaignId && campaign) {
      // Check if campaign status >> created, processing, failed, finished
      if (campaign.status && campaign.status.toUpperCase() !== "CREATED") {
        // already finished >> processing, failed, finished
        console.log(`Campaign are running, sorry gais`);
        // alert
      } else {
        console.log(`Campaign are not running, edit`);
        // Set Campaign Data
        // dispatch(ReduxCampaignReport.setSelectedCampaignID(campaignId));
        // dispatch(ReduxCampaignReport.setSelectedCampaign(campaign));
        // Set Campaign Report List Data
        let campaignReportList: Campaign[] = [];

        campaignReportList.push(campaign);
        dispatch(ReduxCampaignReport.setListCampaignReport(campaignReportList));

        nav(`/campaign/${campaign.id}/edit`);
      }
    }
  };

  const onSuccessSubmit = () => {
    //create emelent in list message
    // console.log("onSuccessSubmit");
    let triggerDummyButton = document.getElementById(
      "triggerAlert"
    ) as HTMLButtonElement;
    triggerDummyButton.click();
  };

  const onPause = (id?: string) => {
    if (!id) {
      return;
    }
    const campaignId = id;
    // const selectedCampaign = campaignList.find(
    //   (campaign) => campaign.id === campaignId
    // );

    console.log(
      `Clicked Campaign ID: ${campaignId} Data: ${JSON.stringify(campaign)}`
    );

    console.log(
      "campaign.scheduleConfig?.isRepeat",
      campaign?.scheduleConfig?.isRepeat
    );
    console.log(
      "campaign.scheduleConfig.isRepActive",
      campaign?.scheduleConfig?.isRepActive
    );

    // Set Selected Campaign ID and Campaign in Redux
    if (
      campaignId &&
      campaign &&
      campaign.scheduleConfig?.isRepeat &&
      campaign.scheduleConfig.isRepActive
    ) {
      // Set Campaign Data
      // setIsActiveRep to false
      const updateCampaign = updateCampaignById(campaignId, {
        scheduleConfig: {
          isRepActive: false,
        },
      });

      // get schedule set IsRepActive to false
      const updateSchedule = updateScheduleByCampaign(campaignId, {
        isRepActive: false,
      });

      if (updateCampaign !== undefined && updateSchedule !== undefined) {
        dispatch(CampaignSlice.cleanReduxCampaign(true));
      }
    }
  };

  // const deleteItem = useMutation(() => deleteContact(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     dispatch(Contact.deleteItemListContact(id+""));
  //     // ✅ update detail view directly
  //     // queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
  //     updateState({sort:(state.sort === 'delete' ? 'asc' : 'delete' ), items_per_page: state.items_per_page, page: 1, action: "noAction"})
  //     // updateState({sort: 'delete', items_per_page: state.items_per_page, page: 1, action: "noAction"})
  //   },
  //   onError(error, variables, context) {
  //     dispatch(Contact.deleteItemListContact(id+""));
  //   }
  // });

  return (
    <div className="dropdown">
      {!isTabletOrMobile && (
        <a
          className="btn btn-light btn-active-light-primary btn-sm"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-testid="action-dropdown"
        >
          {t("Common.Actions")}
          <KTSVG
            path="/media/icons/duotune/arrows/arr072.svg"
            className="svg-icon-5 m-0"
          />
        </a>
      )}

      {isTabletOrMobile && (
        <a
          href="#"
          data-testid="dropdownMenuAction"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen053.svg"
            className="svg-icon-1 m-0"
          />
        </a>
      )}

      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <a
            // className="menu-link px-3"
            className="dropdown-item btn btn-light btn-active-light-primary btn-sm disabled"
            data-kt-users-table-filter="delete_row"
            data-testid="action-delete"
            // onClick={async () => await deleteItem.mutateAsync()}
          >
            {t("Common.Delete")}
          </a>
        </li>
        {campaign.campaignSchedule === "scheduled" &&
          campaign.scheduleConfig?.isRepeat && (
            <li>
              <button
                className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
                id={campaign?.id}
                data-testid="action-report"
                disabled={
                  campaign?.status === "CREATED" ||
                  campaign.scheduleConfig.isRepActive === false
                }
                data-bs-toggle="modal"
                data-bs-target={`#stopCampaignModal-${campaign.id}`}
              >
                {/* {t("DLR.Title.Report")} */}
                Stop
              </button>
            </li>
          )}
        <li>
          {/* {
              <button 
              id={campaign.id}
              className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
              onClick={onEditCampaignHandler}
              data-bs-toggle={campaign?.status && campaign?.status.toUpperCase() !== "CREATED" ? "modal" : ""}
              data-bs-target={campaign?.status && campaign?.status.toUpperCase() !== "CREATED" ? "#campaignEditModal" : ""}
              // state={{
              //   id: campaign.id,
              //   name: campaign.campaignName,
              // }}
            >
              {t("Common.Edit")}
            </button>
            } */}
          <Link
            to={`/campaign/${campaign?.id}/edit`}
            data-testid="action-edit"
            // to={
            //   campaign.status?.toUpperCase() !== "CREATED" ? "#" : `/campaign/${campaign.id}/edit`
            // }
            onClick={onEditCampaignHandler}
            className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
            // data-bs-toggle={campaign?.status && campaign?.status.toUpperCase() !== "CREATED" ? "modal" : ""}
            // data-bs-target={campaign?.status && campaign?.status.toUpperCase() !== "CREATED" ? "#campaignEditModal" : ""}
          >
            {t("Common.Edit")}
          </Link>
        </li>
        <li>
          <button
            className="dropdown-item btn btn-light btn-active-light-primary btn-sm"
            onClick={onReportClickHandler}
            id={campaign?.id}
            data-testid="action-report"
            disabled={campaign?.status === "CREATED"}
          >
            {t("DLR.Title.Report")}
          </button>
        </li>
      </ul>
      <ModalConfirmation
        id="campaignEditModal"
        body={t("Template.Button.DiscardChanges")}
        title={t("Template.Button.Confirmno")}
        confirmOption={`OK`}
      />
      <ModalConfirmation
        id={`stopCampaignModal-${campaign.id}`}
        body={t("Campaign.ScheduleCampaign.Modal.Info")}
        title={t("Campaign.ScheduleCampaign.Modal.Title")}
        onConfirm={() => {
          onPause(campaign.id);
        }}
        confirmOption={t("Campaign.ScheduleCampaign.Modal.Button.Yes")}
        cancelOption={t("Campaign.ScheduleCampaign.Modal.Button.No")}
      />
    </div>
  );
};

export { CampaignActionsCell };
function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
