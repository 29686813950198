import { Column } from "react-table";
import { TemplateInfoCell } from "./TemplateInfoCell";
import { TemplateTwoStepsCell } from "./TemplateTwoStepsCell";
import { TemplateActionsCell } from "./TemplateActionsCell";
import { TemplateCategoryCell } from "./TemplateCategoryCell";
import { TemplateStatusCell } from "./TemplateStatusCell";
import { TemplateCustomHeader } from "./TemplateCustomHeader";
import Template from "../../../../../models/Template";
import CellDate from "../../../../../components/table/columns/CellDate";
import { TemplateNonCustomHeader } from "./TemplateNonCustomHeader";
import { useTranslation } from "react-i18next";
import PreviewTemplateModal from "../../components/modal/PreviewTemplateModal";
import { useState } from "react";
import TemplateLastSync from "./TemplateLastSync";

const templateColumns: ReadonlyArray<Column<Template>> = [
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title="Campaign.Column.TemplateName"
      />
    ),
    id: "templateName",
    Cell: ({ ...props }) => (
      <TemplateInfoCell
        template={props.data[props.row.index]}
        languages={props.data[props.row.index].languages}
      />
    ),
  },
  {
    Header: (props) => (
      <TemplateNonCustomHeader
        tableProps={props}
        title="Campaign.Column.TemplateLanguage"
      />
    ),
    // Header: (props) => (<th>"Campaign.Column.TemplateLanguage"</th>),
    id: "templateStatus",
    Cell: ({ ...props }) => {
      const [modalOpen, setModalOpen] = useState(false);
      const modalClose = () => setModalOpen(false);

      return (
        <div
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <TemplateStatusCell
            languages={props.data[props.row.index].languages}
          />
          {modalOpen && (
            <PreviewTemplateModal
              isOpen={modalOpen}
              onClose={modalClose}
              idTemplate={props.data[props.row.index].id}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <TemplateCustomHeader
      tableProps={props}//
      title="Campaign.Column.TemplateCategory"
      />
      ),
      id: "templateCategory",
      Cell: ({ ...props }) => {
        const [modalOpen, setModalOpen] = useState(false);
        const modalClose = () => setModalOpen(false);
        return (
          <div
          className="w-100 h-100"
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
          >
          <TemplateCategoryCell template={props.data[props.row.index]} />
          {modalOpen && (
            <PreviewTemplateModal
            isOpen={modalOpen}
            onClose={modalClose}
            idTemplate={props.data[props.row.index].id}
            />
            )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title="Campaign.Column.LastSync"
      />
    ),
    id: "lastSyncAt",
    Cell: ({ ...props }) => {
      const [modalOpen, setModalOpen] = useState(false);
      const modalClose = () => setModalOpen(false);
      return (
        <div
          className="w-100 h-100"
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <TemplateLastSync value={props.data[props.row.index].lastSyncAt} />
          {modalOpen && (
            <PreviewTemplateModal
              isOpen={modalOpen}
              onClose={modalClose}
              idTemplate={props.data[props.row.index].id}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title="Campaign.Column.LastUpdated"
      />
    ),
    id: "lastUpdatedAt",
    Cell: ({ ...props }) => {
      const [modalOpen, setModalOpen] = useState(false);
      const modalClose = () => setModalOpen(false);
      return (
        <div
          className="w-100 h-100"
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <CellDate value={props.data[props.row.index].updatedAt} />
          {modalOpen && (
            <PreviewTemplateModal
              isOpen={modalOpen}
              onClose={modalClose}
              idTemplate={props.data[props.row.index].id}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title="Common.Actions"
        className="text-end min-w-125px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      return (
        <TemplateActionsCell
          id={props.data[props.row.index].id}
          indexId={props.row.index}
          languages={props.data[props.row.index].languages}
        />
      );
    },
  },
];

export { templateColumns };
