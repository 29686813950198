import firebase from "firebase/compat/app";
import "firebase/compat/database";
import db from ".";
import * as lc from "../app/modules/localstorage/index";

export const status = (uid: string) => {
  let status = "offline";
  firebase
    .database()
    .ref("/status/" + uid + "/state")
    .on("value", (snapshot) => {
      status = snapshot.val();
    });
  return status;
};

export const createRef = (collection: any, docId: string) =>
  db.doc(`${collection}/` + docId);

export const isOfflineForDatabase = (sessionid: string) => {
  return {
    state: "offline",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
    session_id: lc.getItemLC(lc.LCName.SessionID),
  };
};

export const isOnlineForDatabase = (sessionid: string) => {
  return {
    state: "online",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
    session_id: lc.getItemLC(lc.LCName.SessionID),
  };
};

export const isOnlineForFirestore = (sessionid: string) => {
  return {
    state: "online",
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    session_id: lc.getItemLC(lc.LCName.SessionID),
  };
};

export const onConnectionChanged = (callback: (arg0: any) => void) => {
  firebase
    .database()
    .ref(".info/connected")
    .on("value", (snapshot) => {
      callback(snapshot.val());
    });
};
