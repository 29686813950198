import {FC} from 'react'

type Props = {
  message?: any
}

const CampaignMessageCell: FC<Props> = ({message}) => {
  const messageContentView = message?.split(/\\n/g).map((item:any) => item.replace(/\\n/g, " "));
  return (
    <div className="text-gray-650 mb-1 text-break">
      {messageContentView?.map((item: any, index: number) => {
        return (
          <div key={index}>
            {item}
            <br />
          </div>
        );
      })}
    </div>
  ); //text-hover-primary
}

export {CampaignMessageCell}
