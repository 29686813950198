import { useMemo, Fragment } from "react";
import {
  useTable,
  ColumnInstance,
  Row,
} from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from "../core/QueryResponseProvider";
import { campaignsColumns } from "./columns/_columns";
import { Campaign } from "../../../../models/Campaign";
import Loading from "../../../../../resources/helpers/components/Loading";
import { CampaignPagination } from "../components/pagination/CampaignPagination";
import { KTCardBody } from "../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import CardItem from "../../../../components/table/list/cardList/CardItem";

export const CampaignViewDesktop = () => {
  const { t } = useTranslation();
  const campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => campaigns, [campaigns]);
  const columns = useMemo(() => campaignsColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  console.log("into campaigns table");

  return (
    <KTCardBody className="py-4 h-100 overflow-hidden">
      <div
        className="table-responsive"
        style={{
          height: "90%",
          overflowY: "scroll",
        }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead
            className="position-sticky top-0 bg-white"
            style={{ zIndex: 100 }}
          >
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 position-sticky top-0 bg-white">
              {headers.map((column: ColumnInstance<Campaign>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0
              ? rows.map((row: Row<Campaign>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              : !isLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {t("Contacts.Info.Empty")}
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      <CampaignPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  );
};

export const CampaignViewMobile = () => {
  const { t } = useTranslation();
  const campaigns = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => campaigns, [campaigns]);

  return (
    <>
      <div className="px-5 h-100 d-flex flex-column gap-5 pb-7 mt-5">
        {data.length === 0 && (
          <div className="d-flex text-center w-100 align-content-center justify-content-center">
            {t("Contacts.Info.Empty")}
          </div>
        )}
        {data.map((campaign, index) => (
          <Fragment key={campaign.id?.toString()}>
            <CardItem
              id={campaign.id}
              name={campaign.campaignName}
              isContactList={false}
              isStorefrontList={false}
              isProductList={false}
              isCampaignList={true}
              index={index}
              campaignData={campaign}
            />
          </Fragment>
        ))}
      </div>
      <CampaignPagination />
      {isLoading && <Loading />}
    </>
  );
};

const CampaignsTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return isTabletOrMobile ? <CampaignViewMobile /> : <CampaignViewDesktop />;
};

export { CampaignsTable };