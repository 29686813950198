/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../resources/helpers";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Customer from "../../models/Customer";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup/redux/store";
import * as ReduxContact from "../../modules/contact/redux/ContactSlice";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email: string) => {
  return email.match(emailRegExp);
};

const validatePhone = (phone: any) => {
  return phone.match(phoneRegExp);
};

const ContactHeader = ({ customer }: { customer: Customer }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  
  const rd_navigateTimes = useSelector(
    (state: RootState) => state.Contact.navCount
  );
  const [validationState, setValidationState] = useState({
    email: true,
    phone: true,
  });

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handlePhoneNumberChange = (event: any) => {
    const value = event.target.value;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setPhone(value);

      let isValid = validatePhone(value);

      if (!isValid && phone.length < 8) {
        setValidationState((prev) => ({ ...prev, phone: false }));
      } else {
        setValidationState((prev) => ({ ...prev, phone: true }));
      }
    }
  };

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    let isValid = validateEmail(value);

    setEmail(value);
    if (!isValid) {
      setValidationState((prev) => ({ ...prev, email: false }));
    } else {
      setValidationState((prev) => ({ ...prev, email: true }));
    }
  };

  const isProfileDetailPage = location.pathname.startsWith(
    "/contact/contact-detail/overview/"
  );
  const isProfileEditPage = location.pathname.startsWith(
    "/contact/contact-detail/settings/"
  );

  const nav = useNavigate();

  const handleLinkClick = (event: React.MouseEvent<HTMLElement>) => {
    const navCount = rd_navigateTimes+1;
    console.log(`navigate handle link click. count ${navCount}`)
    dispatch(ReduxContact.setNavCount(navCount))
  }

  

  const handleBack = () => {
    
      console.log(`navigate count ${rd_navigateTimes}`);
      const navcount = rd_navigateTimes;
      dispatch(ReduxContact.setNavCount(1));
      nav(navcount * -1);
    // }
  };

  return (
    <div className="card mb-5 mb-xl-10">
      {isTabletOrMobile && (
        <>
          <div className="d-flex flex-row menu menu-column menu-state-title-primary 
                menu-state-icon-primary menu-state-bullet-primary py-3
                border-gray-300 border-bottom align-items-center">
            <div className="menu-item here show menu-accordion">
              <span className="menu-link px-0">
                <button onClick={()=>handleBack()} className="btn btn-link">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr074.svg"
                    className="svg-icon-primary svg-icon-2hx mr-2"
                  />
                </button>
                <div className="symbol symbol-50px">
                  <span className="menu-icon">
                    <KTSVG
                      path="/media/icons/duotune/communication/com005.svg"
                      className="svg-icon-2hx svg-icon-dark"
                    />
                  </span>
                </div>
                <span className="menu-title">{`${t(
                  "Contact.BC.ContactList"
                )}`}</span>
              </span>
            </div>
          </div>
        </>
      )}
      <div className="card-body pt-9 pb-0">
        <div className="d-flex mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                className="rounded-circle"
                src={
                  customer.avatar
                    ? customer.avatar
                    : toAbsoluteUrl("/media/icons/avatar/def-avatar.png")
                }
                alt={customer.firstName}
              />
              {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-4">
                  <a className="d-flex flex-wrap bg-blue-300 text-gray-800 text-hover-primary fs-1 fw-bolder me-1">
                    {`${customer.firstName} ${
                      customer.lastName ? customer.lastName : ""
                    }`}
                  </a>
                </div>
                <div
                  className={clsx(
                    "align-items-start flex-wrap fw-bold fs-6 gap-6",
                    { "d-flex h-45px": !isTabletOrMobile, "h-0 mb-4": isTabletOrMobile, }
                  )}
                >
                  {!customer.phoneNumber && isProfileEditPage && (
                    <div className={clsx("d-flex gap-4 align-items-start", {"mb-4": isTabletOrMobile})}>
                      {/* <div className="w-25px mt-3"> */}
                        <i className="bi bi-telephone-fill mt-5" />
                      {/* </div> */}
                      <div
                        className={clsx("d-inline", {
                          "w-100": isTabletOrMobile,
                          "w-250px": !isTabletOrMobile,
                        })}
                      >
                        <div className="d-flex flex-column">
                          <input
                            id="input-phone"
                            data-testid="input-phoneNumber"
                            placeholder={t(
                              "Contact.EditContact.InputPhoneNumber"
                            )}
                            className={clsx(
                              "d-inline-block form-control form-control-solid"
                            )}
                            type="text"
                            name="phoneNumber"
                            value={phone}
                            onChange={(e) => handlePhoneNumberChange(e)}
                            autoComplete="off"
                          />

                          {phone !== "" &&
                            phone.length > 3 &&
                            validationState.phone === false && (
                              <span id="error-phone" className="text-danger">
                                {t("Contact.Edit.Alert.InvalidPhoneNumber")}
                              </span>
                            )}

                          <span
                            id="error-phone-exist"
                            className="d-none text-danger"
                          >
                            {t("CD.Error.ExistPhoneNumber")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {customer.phoneNumber &&
                    (isProfileDetailPage || isProfileEditPage) && (
                      <div className="d-flex gap-4 align-items-center h-45px">
                        <i className={clsx("bi bi-telephone-fill", isTabletOrMobile ? "me-2" : "")} />
                        <span className="text-gray-600">
                          {customer.phoneNumber}
                        </span>
                      </div>
                    )}

                  {!customer.email && isProfileEditPage && (
                    <div className="d-flex gap-4 align-items-start">
                      {/* <div className="w-25px mt-3"> */}
                        <KTSVG
                          path="/media/icons/duotune/communication/com011.svg"
                          className={clsx("svg-icon-4 mt-5")}
                        />
                      {/* </div> */}
                      <div
                        className={clsx("d-inline", {
                          "w-100": isTabletOrMobile,
                          "w-250px": !isTabletOrMobile,
                        })}
                      >
                        <div className="d-flex flex-column">
                          <input
                            id="input-email"
                            data-testid="input-email"
                            className="d-inline-block form-control form-control-solid"
                            type="text"
                            name="email"
                            value={email}
                            onChange={(event: any) => handleEmailChange(event)}
                            placeholder={t(
                              "Contact.EditContact.InputEmailAddress"
                            )}
                          />

                          {email !== "" && validationState.email === false && (
                            <span id="error-email" className="text-danger">
                              {t("Contact.Edit.Alert.InvalidEmailAddress")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {customer.email &&
                    (isProfileDetailPage || isProfileEditPage) && (
                      <div className="d-flex gap-4 align-items-center h-45px">
                        <KTSVG
                          path="/media/icons/duotune/communication/com011.svg"
                          className={clsx("svg-icon-4", isTabletOrMobile && customer.email ? "me-1" : "")}
                        />
                        <span className="text-gray-600">{customer.email}</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx("d-flex overflow-auto h-55px")}>
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              
              <Link
                className={clsx("nav-link text-active-primary me-6", {
                  active: isProfileDetailPage,
                })}
                onClick={handleLinkClick}
              
                to={
                  "/contact/contact-detail/overview/" + customer.id
                  // customer.firstName +
                  // (customer.lastName ? "-" + customer.lastName : "")
                }
                state={{
                  id: customer.id,
                  name:
                    customer.firstName +
                    (customer.lastName ? " " + customer.lastName : ""),
                }}
              >
                {t("CD.Info.Overview")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                data-testid="edit-customer"
                className={clsx("nav-link text-active-primary me-6", {
                  active: isProfileEditPage,
                })}
                onClick={handleLinkClick}
                to={
                  "/contact/contact-detail/settings/" + customer.id
                }
                state={{
                  id: customer.id,
                  name:
                    customer.firstName +
                    (customer.lastName ? " " + customer.lastName : ""),
                }}
              >
                {t("CD.Info.Settings")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ContactHeader };
