import React from "react";
import { Link } from "react-router-dom";

export type Menu = {
  label: string;
  type: "link" | "button";
  state? : { [key: string]: any};
  to?: string;
  onClick?: () => void;
}

const print = (action: Menu, key: string) => {
  if (action.type === "link") {
    return (
      <Link to={`${action.to}`} 
            state = {action.state}
            className="dropdown-item btn btn-light btn-active-light-primary btn-sm" 
            key={key}
            onClick={action.onClick}>
        {action.label}
      </Link>
    );
  } else {
    return (
      <button className="dropdown-item btn btn-light btn-active-light-primary btn-sm" 
              onClick={action.onClick} key={key}>
        {action.label}
      </button>
    );
  }
}
 
export {print}