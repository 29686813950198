import { KTCard } from "../../../../../resources/helpers/components/KTCard";
import { StorefrontListHeader } from "./components/header/StorefrontListHeader";
import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { StorefrontTable } from "./table/StorefrontTable";

const StorefrontList = () => {
  return (
    <>
      <KTCard pStatic={true}>
        <StorefrontListHeader />
        <StorefrontTable />
      </KTCard>
    </>
  );
};

const StorefrontListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StorefrontList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { StorefrontListWrapper };
