import {
  ListViewProvider,
  useListView,
} from "../../core/list/ListViewProvider";
import { CampaignHeader } from "./components/header/CampaignHeader";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
// import {CIQsTable} from './table/CampaignTable'
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { KTCard } from "../../../../resources/helpers/components/KTCard";
import * as ReduxCampaignMessage from "../../../modules/campaign/message/CampaignMessageSlice";
import * as ReduxCampaign from "../../../modules/campaign/redux/CampaignSlice";
import * as ReduxCampaignReport from "../../../modules/campaign/report/CampaignReportSlice";
import { CampaignsTable } from "./table/CampaignsTable";

const CampaignList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ReduxCampaign.cleanReduxCampaign(true));
    dispatch(ReduxCampaignReport.cleanReduxCampaignReport(true));
    dispatch(ReduxCampaignReport.cleanReduxCampaignReportData(true));
    dispatch(ReduxCampaignMessage.cleanReduxCampaignMessage(true));
  }, []);

  return (
    <>
      <KTCard>
        <CampaignHeader />
        <CampaignsTable />
        {/* <CIQsTable /> */}
      </KTCard>
    </>
  );
};

const CampaignWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CampaignList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { CampaignWrapper };
