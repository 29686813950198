import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../core/PageData';
import { WABAAccountsListWrapper } from './WABAAccountsList'
import { useTranslation } from "react-i18next";

const WABAAccountsListPage = () => {
  const { t } = useTranslation();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("HeaderBar.Button.Settings"),
      path: '/setting/waba-account/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  console.log("Campaign Tools Page");
  
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                {t("WABAAccountList.Menu")}
              </PageTitle>
              <WABAAccountsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/setting/waba-account/list" />} />
    </Routes>
  );
}

export default WABAAccountsListPage