import axios from "axios";
import * as bcrypt from "bcryptjs";
import Message from "../../app/models/Message";
import db, { Timestamp } from "../../db";
import { sendWABASignUp } from "./connection";

export const sendRequestWABASignUp = async (
  json: any,
  client: string,
  callback: (code: number, data: any) => void
) => {
  let jsonRequest = json;
  jsonRequest.client = client;

  console.log(`jsonRequest for WABA SignUP: ${JSON.stringify(jsonRequest)}`);

  let defaultResponse = `
  {
    "responseCode" : <<responseCode>>,
    "response" : <<response>>
  }`;

  await sendWABASignUp(jsonRequest, callback);
};
