import { FirestoreQueryState, TableContextValue } from "../models/Models";

export const minimalDefaultValue: TableContextValue<any> = {
    TableState: {
      collection: "",
      clientID: null,
      limit: 10,
      currentPage: 1,
      tabActive: 0,
      priorityOrder: true,
    } as unknown as FirestoreQueryState<any>,
    selectedItems: [],
    setSelectedItems: () => {},
    updateTableState: () => {},
    updatePage: () => {},
    refreshData: () => new Promise((resolve) => resolve()),
    setReadyToShipOrder: () => {},
    setExportedOrder: () => {},
    resetResponseRTS: () => {},
    checkifAvailableToProcessOrder: () => true,
    loadingRTS: false,
    responseRTS: undefined,
    responseExportOrder: undefined,
    isLoading: true,
    isFetching: true,
    data: undefined,
  };