import { t } from "i18next";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { sendRequestWABAVerify } from "../../../../../../../api/server/wabaVerifyPhoneNumber";
import {
  getWABAccountByID,
  updateWABAccountByID,
} from "../../../../../../../db/serviceWABA";
import { RootState } from "../../../../../../../setup/redux/store";
import Language from "../../../../../../models/Language";
import * as lc from "../../../../../../modules/localstorage/index";
import ModalConfirmationMoveToOtherPage from "../../../../../../modules/modal/modalConfirmationMoveToOtherPage";
import * as ReduxWABASignUp from "../../../../../../modules/waba-accounts/redux/data/WABAAccountSignUpSlice";
import { PageLink, PageTitle } from "../../../../../core";
import PreviewMessage from "../../../../../core/preview/PreviewMessage";

const dummyTemplate: Language = {
  body: "Hello *<<companyName>>* is now available on WhatsApp. Now it's easier for you to contact us. Please tap the confirm button if you receive this message \n \n *Thank You* ",
  buttons: [
    {
      index: 0,
      type: "QUICK_REPLY",
      text: "Confirmation",
    },
  ],
  status: "APPROVED",
  language: "id",
  id: "id",
};

const WABAVerify: FC = () => {
  const currentClient = lc.getItemLC(lc.LCName.Client);
  const clientid = currentClient?.id;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const UsersBreadcrumbs: Array<PageLink> = [
    {
      title: "Setting",
      path: "",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const phoneNumbers = useSelector(
    (state: RootState) => state.WABAAccountSignUp.phoneNumbers
  );
  const dataWABA = useSelector(
    (state: RootState) => state.WABAAccountSignUp.dataWABA
  );

  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setisLoading] = useState(false);

  const setWABABusinessName = async (id?: string, phoneId?: string) => {
    if (!id || !phoneId) {
      return
    }
    getWABAccountByID(id).then((res) => {
      console.log(`response from getWABAccountByID`, res);
      const selectedProfile = res?.profiles?.find(profile => profile.whatsappNumberID === phoneId)
      dispatch(
        ReduxWABASignUp.setDataWABA({
          ...dataWABA,
          business_name: selectedProfile?.businessName,
        })
      );
    });
  };

  useEffect(() => {
    if (dataWABA.waba_id, dataWABA?.phone_number_id) {
      setWABABusinessName(dataWABA.waba_id);
    }
  }, [dataWABA.waba_id]);

  useEffect(() => {
    if (
      !Object.keys(dataWABA).length ||
      !dataWABA?.waba_id ||
      !dataWABA?.phone_number_id
    ) {
      console.log("dataWABA", dataWABA);
      window.location.replace("/setting/waba-account/list#");
    }
  }, [dataWABA, navigate]);

  const handleClickNext = async () => {
    if (!phoneNumbers || phoneNumbers.length < 1) {
      if (errors.includes(t("WABAAccount.VerifyingAccount.ErrorRequire"))) {
        return;
      }
      setErrors([t("WABAAccount.VerifyingAccount.ErrorRequire"), ...errors]);
      return;
    }
    if (errors && errors.length) {
      return;
    }
    if (!clientid || !dataWABA?.waba_id || !dataWABA?.phone_number_id) {
      return;
    }

    setisLoading(true);

    const reqData = {
      phoneNumbers,
      wabaID: dataWABA.waba_id,
      wabaPhoneNumberID: dataWABA.phone_number_id,
      // currentStep: currentStepPage,
    };

    // console.log(response);
    // await updateWABAccountByID(dataWABA.waba_id, {
    //   whitelistPhoneNumbers: phoneNumbers,
    // });
    await updatePhoneWhitelist(dataWABA.waba_id, dataWABA.phone_number_id, phoneNumbers)

    const response = await sendRequestWABAVerify(clientid, reqData);

    setisLoading(false);
    if (response.code === 200) {
      navigate("/setting/waba-account/create/summary");
    } else {
      if (response?.data?.error) {
        setErrors((prev) => [...prev, response.data?.error]);
        return;
      }
    }
  };

  if (
    !Object.keys(dataWABA).length ||
    !dataWABA?.waba_id ||
    !dataWABA?.phone_number_id
  ) {
    return null;
  }

  return (
    <>
      <ModalConfirmationMoveToOtherPage
        paths={[
          "/setting/waba-account/create/summary",
          "/setting/waba-account/list#0",
        ]}
        path="/setting/waba-account/create/verify"
        onUnsave={() => undefined}
      />
      <PageTitle breadcrumbs={UsersBreadcrumbs}>
        {t("WABAAccountList.Button")}
      </PageTitle>

      <div className="d-flex flex-column h-100 pb-5">
        <div className="card mb-1 mb-xl-2 py-4 px-7 d-flex flex-row space">
          <h2 className="w-100 m-0 fw-semibold h1">
            {t("WABAAccount.VerifyingAccount.Title")}
          </h2>
        </div>

        <div
          style={{ height: !isTabletOrMobile ? "90%" : "auto" }}
          className="d-flex flex-column card py-7"
        >
          <div style={{ flexGrow: 1 }} className="overflow-auto px-7">
            <div
              className="fs-4 text-gray-800 mb-8"
              data-testid="explain-paraghraf"
            >
              <p>
                {t("WABAAccount.VerifyingAccount.EnterPhoneNumber.Explanation")}
              </p>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-lg-5">
                <PhoneForms errors={errors} setErrors={setErrors} />
              </div>

              <div className="col-12 col-lg-4">
                <PreviewMessage
                  template={{
                    ...dummyTemplate,
                    body: dummyTemplate.body?.replace(
                      "<<companyName>>",
                      dataWABA?.business_name ?? "Account Name"
                    ),
                  }}
                  className="donotremove"
                  chatHeader={{
                    background: "#098069",
                    color: "#FFFFFF",
                    avatar: dataWABA?.business_name
                      ? `https://api.dicebear.com/6.x/initials/svg?backgroundColor=ffffff&textColor=222222&seed=${dataWABA.business_name}`
                      : "/media/icons/avatar/user-icon.png",
                    accountName: dataWABA?.business_name ?? "Account Name",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center pt-5 px-7">
            <button
              onClick={handleClickNext}
              type="button"
              className="btn btn-primary"
              disabled={isLoading}
              data-testid="next-button"
            >
              {isLoading ? t("Common.Pages.Loading") : t("UFL.Pages.Next")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const PhoneForms: FC<{
  errors: string[];
  setErrors: Dispatch<SetStateAction<string[]>>;
}> = ({ errors, setErrors }) => {
  const dispatch = useDispatch();

  const phoneNumbers = useSelector(
    (state: RootState) => state.WABAAccountSignUp.phoneNumbers
  );

  const [phoneInputs, setPhoneInputs] = useState<string[]>(
    phoneNumbers && phoneNumbers.length ? phoneNumbers : ["62"]
  );

  useEffect(() => {
    if (phoneInputs.length) {
      const filteredList = phoneInputs.filter(
        (text) => text !== "" && text.length >= 5
      );
      dispatch(ReduxWABASignUp.setPhoneNumbers(filteredList));
    }
  }, [phoneInputs, dispatch]);

  const handleTextChange = (phone: string, code: string, index: number) => {
    setErrors([]);

    const newData = [...phoneInputs];

    newData[index] = phone;

    setPhoneInputs(newData);
    const filteredList = newData.filter(
      (text) =>
        text !== "" && `${text}` !== `${code}` && text.length - code.length >= 3
    );
    dispatch(ReduxWABASignUp.setPhoneNumbers(filteredList));

    if (phone.length - code.length < 3) {
      setErrors((prev) => [...prev, t("IB.Error.PNInvalid")]);
      return;
    }

    if (phoneInputs.length > 1 && phoneInputs.includes(phone)) {
      setErrors((prev) => [
        ...prev,
        t("WABAAccount.VerifyingAccount.ErrorExist"),
      ]);
      return;
    }
  };

  const handleAddField = () => {
    const currentIndex = phoneInputs.length - 1;
    if (phoneInputs.length <= 3 && phoneInputs[currentIndex]) {
      setPhoneInputs((prev) => [...prev, "62"]);
    }
  };

  const handleRemoveField = (index: number) => {
    const data = [...phoneInputs];
    if (data.length > 1) {
      data.splice(index, 1);
    }
    setPhoneInputs(data);

    const invalid = data.filter((text) => text === "" || text.length < 5);
    if (invalid.length < 1) {
      setErrors([]);
    }
  };

  const disableAddField =
    errors.length > 0 ||
    phoneInputs.length >= 4 ||
    !phoneInputs[phoneInputs.length - 1];

  return (
    <>
      <div className="form-group">
        {/* <label htmlFor="phone" className="fw-bolder mb-2">
          Phone Number 1*
        </label>
        <input type="text" id="phone" className="form-control" placeholder="Enter phone number" /> */}
        {phoneInputs.map((phone, index) => {
          return (
            <div className="col mb-3" key={index}>
              <label style={{ fontWeight: 600 }} className="mb-2">
                {t(`IB.Title.PhoneNumber`) + ` ` + (index + 1)}
              </label>
              <br />
              <div
                data-testid={`input-phone-number-${index}`}
                className="d-flex gap-4 w-100"
              >
                <PhoneInput
                  data-testid={`input-phone-${index + 1}`}
                  // containerClass="form-control"
                  containerStyle={{
                    width: "100%",
                  }}
                  inputStyle={{ border: "1px solid #CCCCCC", width: "100%" }}
                  dropdownStyle={{ borderColor: "black", zIndex: 999 }}
                  country="id"
                  masks={{ id: "... .... .... ...." }}
                  enableSearch={true}
                  countryCodeEditable={false}
                  value={phone}
                  onChange={(phone, data: CountryData) => {
                    handleTextChange(phone, data.dialCode, index);
                  }}
                />
                <button
                  type="button"
                  className="btn p-0"
                  onClick={() => handleRemoveField(index)}
                  disabled={phoneInputs.length <= 1}
                >
                  <i className="bi bi-x-lg fs-2"></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="d-flex flex-column gap-2 mb-4">
        {errors.length
          ? errors.map((error, index) => (
              <span
                key={index}
                className="text-danger"
                data-testid={`error-button-${index}`}
              >
                {error}
              </span>
            ))
          : ""}
      </div>

      <button
        data-testid="addButton"
        type="button"
        className="btn btn-primary"
        onClick={handleAddField}
        disabled={disableAddField}
      >
        {`+ ${t("WABAAccount.VerifyingAccount.ButtonAdd")}`}
      </button>
    </>
  );
};

async function updatePhoneWhitelist(wabaID: string, wabaPhoneID: string, phoneNumbers: string[]){
  const dataWaba = await getWABAccountByID(wabaID)
  const selectedProfile = dataWaba?.profiles?.find(profile => profile.whatsappNumberID === wabaPhoneID)
  if (!selectedProfile) {
    console.log("Error updatePhoneWhitelist selected profile not found")
    console.log("Profiles: ", dataWaba?.profiles)
    return
  }
  const otherProfiles = (dataWaba?.profiles?.filter(profile => profile.whatsappNumberID !== wabaPhoneID)) ?? []

  await updateWABAccountByID(wabaID, {
    profiles: [
      {...selectedProfile, whitelistPhoneNumbers: phoneNumbers},
      ...otherProfiles
    ]
  });
}

export default WABAVerify;
