import { Timestamp } from "firebase/firestore";
import moment from "moment";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { CellProps, Column, HeaderProps } from "react-table";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ActionCell from "./actionCell";
import CustomCell from "./customCell";
import SelectionHeader from "./selectionHeader";
import SelectionCell from "./selectionCell";
import User from "../../../../../../../../models/User";
import {
  defaultMemberTableState,
  useFirestoreTable,
} from "../../../context/TableContext";
import db from "../../../../../../../../../db";

export type HeaderColoumnsProp = PropsWithChildren<HeaderProps<User>> & {
  field: keyof User | "action";
};
export type CellColoumnsProp = PropsWithChildren<CellProps<User>> & {
  value: string;
  cName: string;
  style: any;
};

const MemberColumns: ReadonlyArray<Column<User>> = [
  // Email
  {
    Header: (props: HeaderColoumnsProp) => {
      return (
        <CustomHeader {...props} title="Common.Column.Email" field="email" />
      );
    },
    id: "email",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      return <CustomCell {...props} value={data.email} cName="text-center" />;
    },
  },

  // Phone number
  {
    Header: (props: HeaderColoumnsProp) => {
      return (
        <CustomHeader
          {...props}
          title="Common.Column.PhoneNumber"
          field="phoneNumber"
        />
      );
    },
    id: "phoneNumber",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      let phonumber =
        data?.phoneNumber !== 0 &&
        data?.phoneNumber &&
        data?.phoneNumber !== undefined
          ? String(data.phoneNumber)
          : "";
      return <CustomCell {...props} value={phonumber} cName={"text-center"} />;
    },
  },

  // Name
  {
    Header: (props: HeaderColoumnsProp) => {
      return (
        <CustomHeader {...props} title="Common.Column.Name" field="name" />
      );
    },
    id: "name",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      return <CustomCell {...props} value={data.name} cName={"text-center"} />;
    },
  },

  // Access Type
  {
    Header: (props: HeaderColoumnsProp) => {
      return (
        <CustomHeader
          {...props}
          title="UserManagement.Table.Header.AccessType"
          field="role"
        />
      );
    },
    id: "role",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      return (
        <CustomCell
          {...props}
          value={
            data.role
              ? data.role.charAt(0).toUpperCase() + data.role.slice(1)
              : ""
          }
          cName={"text-center"}
        />
      );
    },
  },

  // Invitation Expired
  {
    Header: (props: HeaderColoumnsProp) => {
      const data = props.data[0];
      return (
        <CustomHeader
          {...props}
          title={"UserManagement.Table.Header.InvitationExpired"}
          field="inviteExpiredAt"
        />
      );
    },
    id: "inviteExpiredAt",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      const timestamp = data.inviteExpiredAt as Timestamp;
      const dateFormatted = moment(
        new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000)
      ).format("DD MMMM YYYY, HH:mm");
      console.log(data, dateFormatted, "testiong");
      return (
        <CustomCell
          {...props}
          value={dateFormatted !== "Invalid date" ? dateFormatted : ""}
          cName={"text-center"}
        />
      );
    },
  },

  // status
  {
    Header: (props: HeaderColoumnsProp) => {
      const data = props.data[0];
      return (
        <CustomHeader {...props} title={"Common.Modal.Column.Header.Status"} />
      );
    },
    id: "status",
    // Cell: async (props: CellColoumnsProp) => {
    //   const data = props?.row?.original;
    //   let state = null;
    //   await db
    //     .collection("users")
    //     .doc(data?.id)
    //     .onSnapshot((doc) => {
    //       console.log("Current data phd", doc.data());
    //       state = doc.data()?.state;
    //     });
    //   console.log(state, "test phd");
    //   return (
    //     <p
    //       style={{
    //         backgroundColor: data?.state === "online" ? "#E8FFF3" : "#FFE8E8",
    //         color: data?.state === "online" ? "#50CD89" : "#CD5050",
    //         margin: "auto",
    //         verticalAlign: "center",
    //         padding: "10px",
    //         marginTop: "12%",
    //         textAlign: "center",
    //         width: "fit-content",
    //         borderRadius: "10px",
    //         alignSelf: "center",
    //       }}
    //     >
    //       {data?.state}
    //     </p>
    //   );
    // },
    Cell: (props: CellColoumnsProp) => {
      const data = props?.row?.original;
      const [state, setState] = useState(null);
      useEffect(() => {
        const unsubscribe = db.collection("users").doc(data?.id).onSnapshot((doc) => {
          setState(doc.data()?.state);
        });
    
        return () => unsubscribe();
      }, [data?.id]);
      if (!state) {
        return null; 
      }
      return (
        <p
          style={{
            backgroundColor: state === "online" ? "#E8FFF3" : "#FFE8E8",
            color: state === "online" ? "#50CD89" : "#CD5050",
            margin: "auto",
            verticalAlign: "center",
            padding: "10px",
            marginTop: "12%",
            textAlign: "center",
            width: "fit-content",
            borderRadius: "10px",
            alignSelf: "center",
          }}
        >
          {state ? state : "offline"}
        </p>
      );
    },
  },

  // Action
  {
    Header: (props: HeaderColoumnsProp) => {
      return (
        <CustomHeader {...props} title={"Common.Actions"} field="action" />
      );
    },
    id: "action",
    Cell: (props: CellColoumnsProp) => {
      const data = props.row.original;
      return (
        <td {...props.cell.getCellProps()} className="text-center min-w-100px">
          <ActionCell data={data} />
        </td>
      );
    },
  },
];

type CustomHeaderProps = HeaderColoumnsProp & {
  className?: string;
  title?: string;
};

const CustomHeader: FC<CustomHeaderProps> = ({
  className,
  title,
  field,
  column,
}) => {
  const { t } = useTranslation();
  const { memberTableState, updateTableState } = useFirestoreTable();

  const isSelectedSort =
    memberTableState.sorting.field === field
      ? memberTableState.sorting
      : undefined;

  const onClickHandler = () => {
    if (!field || field === "action") {
      return;
    }

    let newSort = defaultMemberTableState.sorting;

    if (!isSelectedSort || (isSelectedSort && isSelectedSort.order !== "asc")) {
      newSort = {
        field: field,
        order: "asc",
      };
    }
    if (isSelectedSort && isSelectedSort.order !== "desc") {
      newSort = {
        field: field,
        order: "desc",
      };
    }

    updateTableState({
      sorting: newSort,
    });
  };

  return (
    <th
      {...column.getHeaderProps()}
      key={column.id}
      data-testid={column.id}
      className={clsx(
        className,
        memberTableState.sorting.field === field &&
          `table-sort-${isSelectedSort ? isSelectedSort.order : ""}`
      )}
      style={{
        cursor: "pointer",
        width: "16%",
      }}
      onClick={onClickHandler}
    >
      {t(title!)}
    </th>
  );
};

export default MemberColumns;
