import { useContext } from "react";
import { TableContext } from "./TableProvider";
import { TableContextValue } from "../models/Models";

export function useFirestoreTable<T>() {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useFirestoreTable must be used within a TableProvider");
  }
  return context as TableContextValue<T>;
}
