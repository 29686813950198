import clsx from "clsx";
import Picker from "emoji-picker-react";
import React, { FC, useEffect, useState } from "react";
import { toAbsoluteUrl, useDebounce } from "../../../../../resources/helpers";
import "../../../../../styles/css/campaign.scss";
import "../../../../../styles/css/color.css";
import "../../../../../styles/css/margin.scss";
import "../../../../../styles/css/toggle.scss";
// import { useNav }
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as servCampaign from "../../../../../db/serviceCampaign";
import * as servTemplate from "../../../../../db/serviceTemplate";
import { RootState } from "../../../../../setup/redux/store";
import { KeyValues } from "../../../../../util/Formatter";
import { convertTextStyle } from "../../../../../util/TextFormatter";
import { excerptFirstAndLast } from "../../../../../util/excerpt";
import { validateURL } from "../../../../../util/validation";
import {
  Campaign,
  Language as CampaignLanguage,
} from "../../../../models/Campaign";
import Language, {
  BodyParamLanguage,
  button,
} from "../../../../models/Language";
import Template from "../../../../models/Template";
import * as ReduxCampaign from "../../../../modules/campaign/redux/CampaignSlice";
import * as ReduxCampaignTarget from "../../../../modules/campaign/target/redux/CampaignTargetSlice";
import * as lc from "../../../../modules/localstorage/index";
import {
  checkFile,
  formatSize,
} from "../../../chat/components/chat-util/ChatUtil";

interface TemplateProps {
  templateState: any;
  onChanges: (params: { key: string; value: any }[]) => void;
  campaign?: Campaign;
}

const ButtonCtaPhoneNum = (props: any) => {
  return (
    <div
      role="button"
      id="phoneNumberPreview"
      className="d-flex text-center mt-1 search-toolbar w-75 text-center p-3 cl-chat-whatsapp w-full"
      style={{ alignContent: "center", justifyContent: "center" }}
    >
      <div className="d-flex" style={{ alignItems: "center" }}>
        <i
          className="bi bi-telephone-fill mr-4"
          style={{ color: "rgb(0, 165, 244" }}
        ></i>
        <strong style={{ marginLeft: "10px", color: "rgb(0, 165, 244" }}>
          {props.titleButtonPhoneNumber}
        </strong>
      </div>
    </div>
  );
};

const ButtonCtaWebsiteLink = (props: any) => {
  return (
    <div
      role="button"
      id="phoneNumberPreview"
      className="d-flex text-center mt-1 search-toolbar w-75 text-center p-3 cl-chat-whatsapp w-full"
      style={{ alignContent: "center", justifyContent: "center" }}
    >
      <div className="d-flex" style={{ alignItems: "center" }}>
        <i
          className="fas fa-external-link-alt mr-4"
          style={{ color: "rgb(0, 165, 244" }}
        ></i>
        <strong style={{ marginLeft: "10px", color: "rgb(0, 165, 244" }}>
          {props.titleButtonVisitWebsite}
        </strong>
      </div>
    </div>
  );
};

const CampaignTemplate: FC<TemplateProps> = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { templateState, onChanges, campaign } = props;
  const campaignTemplateType = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateType
  );
  const campaignName = useSelector(
    (state: RootState) => state.Campaign.newCampaignName
  );
  const selectedAccountId = useSelector(
    (state: RootState) => state.Campaign.accountID
  );
  const selectedTemplateIDRedux = useSelector(
    (state: RootState) => state.Campaign.templateID
  );
  const selectedTemplateType = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateType
  );
  const selectedTemplateCta = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage?.buttons
  );

  const isCampaignDynamicURLValid = useSelector(
    (state: RootState) => state.Campaign.isCampaignDynamicURLValid
  );

  const selectedTemplateLanguange = useSelector(
    (state: RootState) => state.Campaign.selectedTemplateLanguage
  );
  const [isLoadData, setLoadData] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template, setTemplate] = useState<Language>();
  const [previewBody, setPreviewBody] = useState<string>("");
  const [bodyParams, setBodyParams] = useState<any[]>([]);
  const [buttons, setButtons] = useState<button[]>();
  const [headerParams, setHeaderParams] = useState<any[]>([]);
  const [customHeaderParam, setCustomHeaderParam] = useState<string>("");
  const [hasCompleteParams, setHasCompleteParams] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const option: any = KeyValues;
  const imageExtention: string = "image/png, image/jpg, image/jpeg"; //jpg, jpeg, png
  const fileExtention: string = "application/pdf"; //pdf
  const videoExtention: string = "video/mp4"; //mp4
  const [file, setFile] = useState<File>();
  const [headerAttach, setHeaderAttach] = useState<File>();
  const [headerAttachObj, setHeaderAttachObj] = useState<any>({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [activeEmojiPickerBodyParam, setActiveEmojiPickerBodyParam] =
    useState(0);
  const [headerText, setHeaderText] = useState<string>("");
  const [previewHeaderText, setPreviewHeaderText] = useState<string>("");
  // Emoji, Bold, Italic Custom Text Saved Template
  const [activeTextArea, setActiveTextArea] = useState<string>("");
  const [activeBoldArea, setActiveBoldArea] = useState<string>("");
  const [activeItalicArea, setActiveItalicArea] = useState<string>("");
  const [isClickRemoveFile, setIsClickRemoveFile] = useState<boolean>(false);
  const [utmURLDynamic, setUtmURLDynamic] = useState<string>("");
  const [URLDynamicValues, setURLDynamicValues] = useState<any>("");
  const [fileName, setFileName] = useState<string>("");
  const [oldSelectedAccountId, setOldSelectedAccountId] = useState<string>();

  // Edit Campaign
  let campaignID = useSelector(
    (state: RootState) => state.Campaign.selectedCampaignID
  );
  let selectedCampaign = useSelector(
    (state: RootState) => state.Campaign.selectedCampaign
  );
  let campaignButtons = useSelector(
    (state: RootState) => state.Campaign.templateButtons
  );
  const isLoading = useSelector((state: RootState) => state.Campaign.isLoading);

  //Redux Template
  let isCreateTemplateFinish = useSelector(
    (state: RootState) => state.Campaign.campaignTemplateFinish
  );
  let RTTemplateID = useSelector(
    (state: RootState) => state.Template.templateID
  );
  let RTBodyParams = useSelector(
    (state: RootState) => state.Template.templateBodyParams
  );
  let RTHeaderParams = useSelector(
    (state: RootState) => state.Template.templateHeaderParams
  );

  //Redux Template List
  let RTTemplateList = useSelector(
    (state: RootState) => state.TemplateList.listTemplate
  );

  //redux campaign template list
  const RCTemplateList = useSelector(
    (state: RootState) => state.Campaign.templateList
  );

  let state_redux = useSelector((state: RootState) => state);

  console.log(state_redux, "state_redux xvz");
  console.log(selectedTemplateId, "selected xvz");

  //get client from local storage
  const client = lc.getItemLC(lc.LCName.Client);

  //Get Uploaded File
  const setPreviewFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setHeaderAttach(file);
      setIsClickRemoveFile(false);
      document.getElementById("nextStep")?.removeAttribute("disabled");
    }
  };

  // Get Attachment
  useEffect(() => {
    // Reset File
    props.onChanges([
      { key: "header", value: "" },
      { key: "headerStatus", value: "" },
      { key: "headerMediaURL", value: "" },
    ]);

    if (headerAttach) {
      let newFileObj = {};
      newFileObj = {
        ...template?.header,
      };

      //Set File Attribut
      let headerAttachSize: number = headerAttach.size;
      let headerAttachURL = URL.createObjectURL(headerAttach);
      let headerAttachName = headerAttach.name;
      let headerAttachExt = headerAttach.type;
      let headerAttachType = headerAttachExt.split(`/`)[0];
      let headerAttachExtType = headerAttachExt.split(`/`)[1];
      // Set URL
      newFileObj = {
        ...newFileObj,
        fileName: headerAttachName,
        fileType: headerAttachExt,
        headerURL: headerAttachURL,
        mediaType: headerAttachType,
      };

      // setHeaderURL(headerAttachURL);

      console.log(`use effect upload header 
        >> expected >> ${template?.header?.mediaType}
        >> size ${headerAttachSize} 
        >> url >> ${headerAttachURL}
        >> name >> ${headerAttachName}
        >> ext >> ${headerAttachExt}
        >> type >> ${headerAttachType}
        >> ext type >> ${headerAttachExtType}
      `);

      //Check File Type
      if (headerAttachExt === undefined || headerAttachType === undefined) {
        //return error
        alert(t("HC.Error.InvalidFiletype"));
        // setInputFileName(t("HC.Error.InvalidFiletype"))
        setHeaderAttach(undefined);
        return;
      } else {
        const [result, error, maxSize] = checkFile(
          headerAttachType,
          headerAttachExtType,
          headerAttachSize
        );
        if (error !== undefined) {
          if (maxSize !== 0) {
            alert(t("HC.File." + error).replace("<<size>>", maxSize + " MB"));
            // setInputFileName((t("HC.File."+error)).replace("<<size>>",maxSize+" MB"))
            setHeaderAttach(undefined);
          } else {
            alert(t("HC.Error." + error));
            // setInputFileName(t("HC.Error."+error))
            setHeaderAttach(undefined);
          }
          return;
        }

        if (result !== undefined) {
          newFileObj = {
            ...newFileObj,
            fileSizeFormatted: formatSize(headerAttachSize),
            fileSize: headerAttachSize,
            mediaType: result.toString(),
          };
        }
      }
      console.log(`use effect upload header 
        >> File Attribute 
        >> ${JSON.stringify(newFileObj)}`);
      setHeaderAttachObj(newFileObj);

      // Set to Campaign Data
      props.onChanges([
        { key: "headerMedia", value: headerAttach },
        { key: "header", value: newFileObj },
      ]);

      // Set to Redux
      let arrHeaderParam: any[] = [];
      arrHeaderParam.push(newFileObj);
      dispatch(ReduxCampaign.setTemplateHeaderParams(arrHeaderParam));
    }
  }, [headerAttach]);

  const removeHeaderAttach = () => {
    setHeaderAttach(undefined);
    setHeaderAttachObj(template?.header);
    setIsClickRemoveFile(true);
    document.getElementById("nextStep")?.setAttribute("disabled", "disabled");
  };

  const removeNoHeaderAttach = () => {
    setIsClickRemoveFile(true);
    setFile(undefined);
    document.getElementById("nextStep")?.setAttribute("disabled", "disabled");
  };

  // ---------------------------- Saved Template ---------------------------- //

  // Edit Campaign
  useEffect(() => {
    console.log(`Check Data di Campaign Template
    Campaign ID >> ${JSON.stringify(campaignID)}
    Campaign Data >> ${JSON.stringify(selectedCampaign)}
    Template Data >> ${JSON.stringify(campaign?.template)}
    Selected Template >> ${JSON.stringify(selectedCampaign.template)}`);
    console.log(campaignID, "isi campaign id uxa");
    if (campaignID !== "") {
      if (campaign?.template) {
        console.log(campaign?.template?.templateID.toString(), "1 any");
        setSelectedTemplateId(campaign?.template?.templateID.toString());
        onLoadCampaignContent(campaignID, selectedCampaign)
          .catch((error) => {
            console.error(`load campaign content error ${error}`);
          })
          .finally(() => {
            console.log(`load campaign content finished`);
          });
      }
    } else {
      setSelectedTemplateId(state_redux?.Template?.generatedTemplate?.id);
      onLoadCampaignbyID(state_redux?.Template?.generatedTemplate?.id)
        .catch((error) => {
          console.error(`load campaign content error ${error}`);
        })
        .finally(() => {
          console.log(`load campaign content finished`);
        });
    }
  }, [
    campaignID,
    selectedCampaign,
    state_redux?.Template?.generatedTemplate?.id,
  ]);

  const onLoadCampaignbyID = async (id: string) => {
    setLoadData(true);

    const templateWithLanguage = await servTemplate
      .getTemplateLanguages(id)
      .then((listLanguage) => {
        // Choose Template with current Language
        const templateWithLanguage = listLanguage.find((template) => {
          return template.language === i18n.language;
        });
        return templateWithLanguage as Language;
      });

    if (templateWithLanguage) {
      // Set State
      setTemplate(templateWithLanguage);
      setSelectedTemplateId(id);
      // Set to Redux
      dispatch(ReduxCampaign.setTemplateID(id));
      dispatch(ReduxCampaign.setSelectedCampaignTemplateID(id));
      dispatch(ReduxCampaign.setTemplateLanguageID(templateWithLanguage?.id));
      dispatch(ReduxCampaign.setSelectedTemplateLanguage(templateWithLanguage));
    }
    let campaignLanguage = state_redux?.Campaign?.campaignData?.language as any;
    if (campaignLanguage) {
      const campaignLanguageBodyParam = campaignLanguage.bodyParam;
      const campaignLanguageHeader = campaignLanguage.header;
      const campaignButtons =
        campaignLanguage.buttons && campaignLanguage.buttons.length > 0
          ? campaignLanguage.buttons
          : [];

      if (campaignLanguageBodyParam) {
        let newCampaignLanguageBodyParam: any[] = []; // = campaignLanguageBodyParam;
        campaignLanguageBodyParam.forEach((bodyParam: any) => {
          const param = {
            ...bodyParam,
            // paramValue: bodyParam.paramType?.toLowerCase() === "customtext" ?
            // `${bodyParam.paramValue} ${bodyParam.paramId}`  : bodyParam.paramValue
          };
          newCampaignLanguageBodyParam.push(param);
        });

        // props.onChanges([
        //   {key:"bodyParam", value:newCampaignLanguageBodyParam}
        // ])
        setBodyParams(newCampaignLanguageBodyParam);
        setHasCompleteParams(true);
        dispatch(
          ReduxCampaign.setTemplateBodyParams(newCampaignLanguageBodyParam)
        );
        // props.onChanges([
        //   {key:"bodyParam", value:campaignLanguageBodyParam}
        // ])
        // setBodyParams(campaignLanguageBodyParam);
        // setHasCompleteParams(true);
        // dispatch(ReduxCampaign.setTemplateBodyParams(campaignLanguageBodyParam));
      }
      // Header Text and Have Param
      if (campaignLanguageHeader) {
        // if (campaignLanguageHeader && campaignLanguageHeader.headerParam) {
        // Set Media
        if (!campaignLanguageHeader.headerParam) {
          if (campaignLanguageHeader.type?.toLowerCase() === "media")
            setHeaderAttachObj(campaignLanguageHeader);

          props.onChanges([{ key: "header", value: campaignLanguageHeader }]);

          let newArrHeader = [];
          newArrHeader.push(campaignLanguageHeader);

          setHeaderParams(newArrHeader);
          dispatch(ReduxCampaign.setTemplateHeaderParams(newArrHeader));

          console.log(
            `load campaign >> arr header param 1 >> ${JSON.stringify(
              newArrHeader
            )}`
          );
        }

        if (campaignLanguageHeader.headerParam) {
          setHeaderParams(campaignLanguageHeader.headerParam);
          if (
            campaignLanguageHeader.headerParam[0] &&
            campaignLanguageHeader.headerParam[0].textContent &&
            campaignLanguageHeader.headerParam[0].textContent !== ""
          )
            setCustomHeaderParam(
              campaignLanguageHeader.headerParam[0].textContent
            );
          // let newArrHeader = []
          // newArrHeader.push(campaignLanguageHeader?.headerParam)

          props.onChanges([{ key: "header", value: campaignLanguageHeader }]);
          dispatch(
            ReduxCampaign.setTemplateHeaderParams(
              campaignLanguageHeader.headerParam
            )
          );
          console.log(
            `load campaign >> arr header param 2 >> ${JSON.stringify(
              campaignLanguageHeader.headerParam
            )}`
          );
        }
      }
      if (campaignButtons && campaignButtons.length > 0) {
        const findDynamicValues = campaignButtons.find(
          (btn: any) => btn.type === "URL" && btn.urlType === "DYNAMIC"
        );
        if (findDynamicValues) {
          setUtmURLDynamic(findDynamicValues.urlDynamic!.replace("{{1}}", ""));
          setURLDynamicValues(
            findDynamicValues.urlDynamic!.replace(
              "{{1}}",
              findDynamicValues.dynamicValue!
            )
          );
        }
        setButtons(campaignButtons);
        props.onChanges([{ key: "buttons", value: campaignButtons }]);
        dispatch(ReduxCampaign.setCampaignButtons(campaignButtons));
      }
    } else {
      // Set Header and Body Parameter
      // from Template
      await setTemplateParameters(templateWithLanguage);
    }

    onChanges([
      { key: "languages", value: i18n.language },
      { key: "templateId", value: id },
      {
        key: "templateState",
        value: state_redux?.Campaign?.campaignData?.templateState,
      },
      { key: "templateName", value: state_redux?.Template?.templateName },
      { key: "template", value: state_redux?.Template },
    ]);
  };

  const onLoadCampaignContent = async (
    campaignId: string,
    campaign: Campaign
  ) => {
    setLoadData(true);
    console.log(
      `set body param check id >> ${campaignId} >> ${JSON.stringify(campaign)}`
    );

    const templateWithLanguage = await servTemplate
      .getTemplateLanguages(campaign?.template?.templateID)
      .then((listLanguage) => {
        // Choose Template with current Language
        const templateWithLanguage = listLanguage.find((template) => {
          return template.language === i18n.language;
        });
        return templateWithLanguage as Language;
      });

    if (templateWithLanguage) {
      // Set State
      setTemplate(templateWithLanguage);
      console.log(campaign?.template?.templateID, "2 any");
      setSelectedTemplateId(campaign?.template?.templateID);
      // console.log(`templateWithLanguage : `, templateWithLanguage);
      // if(templateWithLanguage.buttons && templateWithLanguage.buttons.length > 0 ){
      //   const findDynamicValues = templateWithLanguage.buttons.find((val) => val.type === "URL" && val.urlType === "DYNAMIC");
      //   if (findDynamicValues){
      //     console.log(`setURLDynamicValues with ${findDynamicValues.dynamicValue}`);
      //     setURLDynamicValues(findDynamicValues.dynamicValue);
      //   }
      // }
      // Set to Redux
      dispatch(ReduxCampaign.setTemplateID(campaign?.template?.templateID));
      dispatch(
        ReduxCampaign.setSelectedCampaignTemplateID(
          campaign?.template?.templateID
        )
      );
      dispatch(ReduxCampaign.setTemplateLanguageID(templateWithLanguage?.id));
      dispatch(ReduxCampaign.setSelectedTemplateLanguage(templateWithLanguage));
    }

    // from Language Sub Collection >> Get Language Parameter (already select parameter in template)
    const campaignLanguage: CampaignLanguage = await servCampaign
      .fetchCampaignLanguageByCampaignIDAndLanguage(campaignId, i18n.language)
      .then((languageParam) => {
        console.log(
          `set body param after push 1 >> ${JSON.stringify(languageParam)}`
        );
        return languageParam as CampaignLanguage;
      });

    if (campaignLanguage) {
      const campaignLanguageBodyParam = campaignLanguage.bodyParam;
      const campaignLanguageHeader = campaignLanguage.header;
      const campaignButtons =
        campaignLanguage.buttons && campaignLanguage.buttons.length > 0
          ? campaignLanguage.buttons
          : [];

      console.log(`load campaign check for testing bug
      >> body >> ${JSON.stringify(campaignLanguageBodyParam)}
      >> header >> ${JSON.stringify(campaignLanguageHeader)}
      >> header param >> ${JSON.stringify(
        campaignLanguageHeader?.headerParam
      )}`);

      if (campaignLanguageBodyParam) {
        let newCampaignLanguageBodyParam: any[] = []; // = campaignLanguageBodyParam;
        campaignLanguageBodyParam.forEach((bodyParam) => {
          const param = {
            ...bodyParam,
            // paramValue: bodyParam.paramType?.toLowerCase() === "customtext" ?
            // `${bodyParam.paramValue} ${bodyParam.paramId}`  : bodyParam.paramValue
          };
          newCampaignLanguageBodyParam.push(param);
        });

        console.log(`Template Selection Body Set Check 2
        >> body parameter >> ${JSON.stringify(campaignLanguageBodyParam)}
        >> body param modified >> ${JSON.stringify(
          newCampaignLanguageBodyParam
        )}
        `);

        // props.onChanges([
        //   {key:"bodyParam", value:newCampaignLanguageBodyParam}
        // ])
        setBodyParams(newCampaignLanguageBodyParam);
        setHasCompleteParams(true);
        dispatch(
          ReduxCampaign.setTemplateBodyParams(newCampaignLanguageBodyParam)
        );
        // props.onChanges([
        //   {key:"bodyParam", value:campaignLanguageBodyParam}
        // ])
        // setBodyParams(campaignLanguageBodyParam);
        // setHasCompleteParams(true);
        // dispatch(ReduxCampaign.setTemplateBodyParams(campaignLanguageBodyParam));
      }
      // Header Text and Have Param
      if (campaignLanguageHeader) {
        // if (campaignLanguageHeader && campaignLanguageHeader.headerParam) {
        // Set Media
        if (!campaignLanguageHeader.headerParam) {
          if (campaignLanguageHeader.type?.toLowerCase() === "media")
            setHeaderAttachObj(campaignLanguageHeader);

          props.onChanges([{ key: "header", value: campaignLanguageHeader }]);

          let newArrHeader = [];
          newArrHeader.push(campaignLanguageHeader);

          setHeaderParams(newArrHeader);
          dispatch(ReduxCampaign.setTemplateHeaderParams(newArrHeader));

          console.log(
            `load campaign >> arr header param 1 >> ${JSON.stringify(
              newArrHeader
            )}`
          );
        }

        if (campaignLanguageHeader.headerParam) {
          setHeaderParams(campaignLanguageHeader.headerParam);
          if (
            campaignLanguageHeader.headerParam[0] &&
            campaignLanguageHeader.headerParam[0].textContent &&
            campaignLanguageHeader.headerParam[0].textContent !== ""
          )
            setCustomHeaderParam(
              campaignLanguageHeader.headerParam[0].textContent
            );
          // let newArrHeader = []
          // newArrHeader.push(campaignLanguageHeader?.headerParam)

          props.onChanges([{ key: "header", value: campaignLanguageHeader }]);
          dispatch(
            ReduxCampaign.setTemplateHeaderParams(
              campaignLanguageHeader.headerParam
            )
          );
          console.log(
            `load campaign >> arr header param 2 >> ${JSON.stringify(
              campaignLanguageHeader.headerParam
            )}`
          );
        }
      }
      if (campaignButtons && campaignButtons.length > 0) {
        const findDynamicValues = campaignButtons.find(
          (btn) => btn.type === "URL" && btn.urlType === "DYNAMIC"
        );
        if (findDynamicValues) {
          setUtmURLDynamic(findDynamicValues.urlDynamic!.replace("{{1}}", ""));
          setURLDynamicValues(
            findDynamicValues.urlDynamic!.replace(
              "{{1}}",
              findDynamicValues.dynamicValue!
            )
          );
        }
        setButtons(campaignButtons);
        props.onChanges([{ key: "buttons", value: campaignButtons }]);
        dispatch(ReduxCampaign.setCampaignButtons(campaignButtons));
      }
    } else {
      // Set Header and Body Parameter
      // from Template
      await setTemplateParameters(templateWithLanguage);
    }

    onChanges([
      { key: "languages", value: i18n.language },
      { key: "templateId", value: campaign?.template?.templateID },
      { key: "templateState", value: campaign?.templateState },
      { key: "templateName", value: campaign?.template?.templateName },
      { key: "template", value: campaign?.template },
    ]);
  };

  useEffect(() => {
    console.log("Use effect load data >> CHECK LOAD DATA >>> " + isLoadData);
    if (isLoadData) {
      bodyParams.forEach((param) => {
        const div = document.getElementById(
          `customParam${param.paramId}`
        ) as HTMLDivElement;
        if (param.paramType === "customText") {
          div?.classList?.remove("display-none");
          const textArea = document.getElementById(
            `textAreaParam${param.paramId}`
          ) as HTMLTextAreaElement;
          if (textArea) {
            if (textArea.value) {
              textArea.value = param?.textContent ? param?.textContent : "";
              console.log(textArea.value, "textArea.Value");
              
            }
          } else {
            console.error(`Element with ID textAreaParam${param.paramId} not found.`);
          }
        }
      });
      console.log(
        "Use effect load data >> Header Param >>> " +
          JSON.stringify(headerParams)
      );
    }
  }, [isLoadData]);

  useEffect(() => {
    console.log(`masuk useEffect Disabled 
    >> ${JSON.stringify(template)} 
    >> selectedTemplateType ${JSON.stringify(selectedTemplateType)}
    `);

    if (selectedTemplateType === "Save" && hasCompleteParams) {
      console.log("masuk useEffect Disabled 1");
      // document.getElementById("nextStep")?.classList.remove("disabled");
    }
    // Add disabled when template not selected
    // Edit
    else if (
      (campaignID && template) ||
      (campaignID && selectedCampaign.templateName) ||
      (campaignID && template && !template?.bodyParamCount)
    ) {
      console.log("masuk useEffect Disabled 2");
      document.getElementById("nextStep")?.classList.add("disabled");
    }
    //
    else {
      console.log("jalan 1 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    }
  }, [hasCompleteParams]);

  // Select Template
  useEffect(() => {
    console.log(`Use Effect get template: getByAccount
    Account >> ${selectedAccountId} 
    Length >> ${RCTemplateList.length}`);

    if (!selectedAccountId) return;

    if (
      oldSelectedAccountId &&
      oldSelectedAccountId === selectedAccountId &&
      RCTemplateList.length > 0
    )
      return;

    setOldSelectedAccountId(selectedAccountId);

    servTemplate
      .getByAccount(client.id, selectedAccountId)
      .then(async (templates: any) => {
        // Filter Non Rejected && Based on Language
        console.log(templates, "ini templates sor");
        const sortedTemplates = templates
          .filter((template: any) => {
            if (!template.languages) return false;

            return (
              template.languages.filter((lang: any) => {
                // console.log(
                //   `lang.templateStatus.toString().toUpperCase() : ${lang.templateStatus
                //     .toString()
                //     .toUpperCase()}`
                // );

                return (
                  lang.templateLanguage === i18n.language &&
                  lang.templateStatus.toString().toUpperCase() !== "REJECTED" &&
                  template.category !== "interactive"
                );
              }).length > 0
            );
          })
          .sort((a: any, b: any) => {
            if (!a.templateName || a.templateName === undefined) return 1;
            if (!b.templateName || b.templateName === undefined) return -1;
            return a.templateName.toLowerCase() > b.templateName.toLowerCase()
              ? 1
              : -1;
          });

        console.log(sortedTemplates, "sortedtemo sor");

      dispatch(ReduxCampaign.setTemplateList(sortedTemplates));
      });
  }, [selectedAccountId, templateState]);

  //TODO
  useEffect(() => {
    const textArea = document.getElementById(
      `dynamicValue`
    ) as HTMLTextAreaElement;
    if (campaignButtons && campaignButtons.length > 0) {
      const findDynamicValues: any = campaignButtons.map((cta: any) => {
        if (cta.type === "URL" && cta.urlType === "DYNAMIC") {
          let dynamicValue = URLDynamicValues;
          if (
            dynamicValue &&
            dynamicValue !== "" &&
            validateURL(URLDynamicValues)
          ) {
            try {
              let newValue = URLDynamicValues;
              if (
                !URLDynamicValues.startsWith("http://") &&
                !URLDynamicValues.startsWith("https://")
              ) {
                newValue = "https://" + URLDynamicValues; // Prepend "https://" if not present
              }
              const parsedUrl = new URL(newValue);
              const origin =
                !dynamicValue.startsWith("http://") &&
                !dynamicValue.startsWith("https://")
                  ? parsedUrl.hostname
                  : parsedUrl.origin;
              console.log(`origin : `, origin);
              dynamicValue = URLDynamicValues.replace(origin, "");
              dynamicValue = dynamicValue.substring(1);
            } catch (error) {
              console.log(`error parsedURL, error `, error);
            }
          }
          console.log(`textArea Dynamic Value from state : ${dynamicValue}`);
          return { ...cta, dynamicValue };
        } else {
          return cta;
        }
      });
      console.log(`findDynamicValues `, findDynamicValues);
      setButtons(findDynamicValues);
      dispatch(ReduxCampaign.setCampaignButtons(findDynamicValues));
      onChanges([{ key: "buttons", value: findDynamicValues }]);

      //if redux false set to true
      if (
        !isCampaignDynamicURLValid &&
        validateURL(URLDynamicValues) &&
        URLDynamicValues !== "" &&
        URLDynamicValues !== utmURLDynamic
      ) {
        console.log(`remove error`);
        dispatch(ReduxCampaign.setIsCampaignDynamicURLValid(true));
      }
      document.getElementById("nextStep")?.classList.remove("disabled");

      //for testing purposes only
      const dynamicValuesTesting = "https://shopee.com/testing";
      console.log(
        `textArea Dynamic Value from state : ${dynamicValuesTesting}`
      );
    }
  }, [URLDynamicValues]);

  useEffect(() => {
    document.getElementById("nextStep")?.classList.remove("disabled");
    document.getElementById("nextStep")?.removeAttribute("disabled");
    //reset selected template
    if (
      (!selectedTemplateIDRedux || selectedTemplateIDRedux === "") &&
      state_redux?.Template?.generatedTemplate?.id !== ""
    ) {
      console.log("masuk 1 rfx");
      // document.getElementById("nextStep")?.classList.add("disabled");
      // document.getElementById("nextStep")?.setAttribute("disabled", "");
      ResetTemplate();
    }
  }, [selectedTemplateIDRedux, state_redux?.Template?.generatedTemplate?.id]);

  useEffect(() => {
    if (isCreateTemplateFinish) {
      dispatch(ReduxCampaign.setTemplateID(RTTemplateID));
      setBodyParams(RTBodyParams);
      setHeaderParams(RTHeaderParams);
    }
  }, [isCreateTemplateFinish]);

  //use effect template
  useEffect(() => {
    console.log(`Use effect template >> ${selectedTemplateId}`);

    if (template) {
      // Set Body
      if (template.body) {
        // let replacedBody = replacePreviewBody(template.body.replaceAll("\n","<br/>"));
        let replacedBody = replacePreviewBody(template.body);
        // if (replacedBody)
        setPreviewBody(replacedBody);

        // Check if all parameter are inserted for Step Completion
        if (bodyParams.length === template.bodyParamCount) {
          setHasCompleteParams(true);
        }
      }
      if (template && template.header) {
        console.log(`CHECK template header disini `, template.header);
        const header = template.header;
        const filename = header.filename
          ? header.filename
          : header.fileName
          ? header.fileName
          : "";
        console.log(`disini filename `, filename);
        setFileName(filename);
      }
      document.getElementById(`nextStep`)?.classList.remove("disabled");
    }
  }, [template]);

  // Body Param Saved Template Changes
  useEffect(() => {
    console.log(`useeffect body params
    >> has complete params >> ${hasCompleteParams}
    >> headerParam >> ${JSON.stringify(headerParams)} 
    >> bodyParams >> ${JSON.stringify(bodyParams)} 
    `);

    if (template) {
      if (template.body) {
        let replacedBody = replacePreviewBody(template.body);
        setPreviewBody(replacedBody);
        // Check if all parameter are inserted for Step Completion
        if (bodyParams.length === template.bodyParamCount) {
          setHasCompleteParams(true);
        }
        //check if body param text content is empty
        if (bodyParams.length > 0) {
          bodyParams.forEach((param) => {
            if (
              param.paramType === "customText" &&
              (param.textContent === "" || param.paramName === "")
            ) {
              console.log("jalan 2 poc");
              setHasCompleteParams(false);
              document.getElementById("nextStep")?.classList.add("disabled");
            }
            // else {
            //   setHasCompleteParams(true);
            //   document.getElementById("nextStep")?.classList.remove("disabled");
            // }
          });
        }
      }

      let newCampaignData = {
        languages: i18n.language,
        templateState: templateState,
        bodyParam: bodyParams,
        targetFileURL: "",
      };
      props.onChanges([
        { key: "languages", value: i18n.language },
        { key: "templateState", value: templateState },
        { key: "bodyParam", value: bodyParams },
        { key: "targetFileURL", value: "" },
      ]);
    }

    dispatch(ReduxCampaign.setTemplateBodyParams(bodyParams));
    dispatch(ReduxCampaignTarget.cleanReduxSelectedCampaignTarget(true));

    console.log(`Template Selection Body Set Check - useeffect body params
    >> body parameter >> ${JSON.stringify(bodyParams)}
    `);
  }, [bodyParams]);

  // Header Param Saved Template Changes
  useEffect(() => {
    console.log(`useeffect header params
    >> has complete params >> ${hasCompleteParams}
    >> headerParam >> ${JSON.stringify(headerParams)} 
    >> bodyParams >> ${JSON.stringify(bodyParams)} 
    `);

    replacePreviewHeader(template, headerParams);

    // updateCampaignData();
    dispatch(ReduxCampaign.setTemplateHeaderParams(headerParams));
    dispatch(ReduxCampaignTarget.cleanReduxSelectedCampaignTarget(true));
    console.log("useeffect header params : " + JSON.stringify(headerParams));
  }, [headerParams]);

  useEffect(() => {
    if (activeBoldArea !== "") {
      onCustomBold();
    } else if (activeItalicArea !== "") {
      onCustomItalic();
    }
  }, [activeBoldArea, activeItalicArea]);

  const onTemplateSelected = async (templateId: string) => {
    ResetTemplate();
    if (templateId && templateId !== "") {
      console.log("masuk 2 rfx");
      // ResetTemplate();

      const languageTemplate = await servTemplate
        .getTemplateLanguages(templateId)
        .then(async (listLanguage) => {
          // Choose Template with current Language
          const templateWithLanguage = listLanguage.find((template) => {
            return template.language === i18n.language;
          }) as Language;

          await setTemplateParameters(templateWithLanguage);

          // Set State
          console.log(templateId, "3 any");
          setSelectedTemplateId(templateId);
          setTemplate(templateWithLanguage);

          //check have dynamic url
          console.log(`templateWithLanguage : `, templateWithLanguage);
          console.log(`ini isi state template : ${JSON.stringify(template)}`);
          if (
            templateWithLanguage?.buttons &&
            templateWithLanguage?.buttons?.length > 0
          ) {
            const findDynamicValues = templateWithLanguage.buttons.find(
              (val) => val.type === "URL" && val.urlType === "DYNAMIC"
            );
            if (findDynamicValues) {
              const dynamicURL = findDynamicValues.urlDynamic!.replace(
                "{{1}}",
                findDynamicValues.dynamicValue
                  ? findDynamicValues.dynamicValue
                  : ""
              );
              console.log(
                `setURLDynamicValues with ${findDynamicValues.dynamicValue}`
              );
              setUtmURLDynamic(
                findDynamicValues.urlDynamic!.replace("{{1}}", "")
              );
              setURLDynamicValues(dynamicURL);
            }
          }

          // Set to Redux
          dispatch(ReduxCampaign.setTemplateID(templateId));
          dispatch(
            ReduxCampaign.setTemplateLanguageID(templateWithLanguage?.id)
          );
          dispatch(
            ReduxCampaign.setSelectedTemplateLanguage(templateWithLanguage)
          );

          onChanges([
            { key: "languages", value: i18n.language },
            { key: "templateId", value: templateId },
            { key: "templateState", value: templateState },
            // {key:"bodyParam",value:templateWithLanguage?.bodyParam},
            // {key:"header",value:templateWithLanguage?.header},
            { key: "targetFileURL", value: "" },
            // {key: "saveTemplate", value:selectedTemplate}
          ]);
        });
    }

    // updateCampaignData();
  };

  const setTemplateParameters = async (templateLang: Language) => {
    if (templateLang) {
      // Header Set
      let tempHeaderObj: any = {};
      let arryHeaderObj: any[] = [];

      if (templateLang && templateLang.header) {
        // Text
        console.log(`Template Selection Header Set before
        >> headerObj >> ${JSON.stringify(templateLang.header)} 
      `);
        const headerObj = replacePreviewHeader(templateLang);
        if (headerObj) {
          tempHeaderObj = headerObj;

          console.log(`Template Selection Header Set
            >> headerObj >> ${JSON.stringify(templateLang.header)}
            >> headerObj >> ${JSON.stringify(tempHeaderObj)} 
          `);

          if (
            templateLang.header.headerParam &&
            templateLang.header.headerParam[0] &&
            templateLang.header.headerParam[0].textContent &&
            templateLang.header.headerParam[0].textContent !== ""
          ) {
            setCustomHeaderParam(
              templateLang.header.headerParam[0].textContent
            );
          }
        }

        // Media : Document, Image, and Video
        if (
          templateLang &&
          templateLang.header &&
          templateLang.header?.type === "media"
        ) {
          tempHeaderObj = templateLang.header;
          setHeaderAttachObj(tempHeaderObj);
        }
        // Set Header Param
        arryHeaderObj.push(tempHeaderObj);
        setHeaderParams(arryHeaderObj);
        console.log(`template selection header set array ${arryHeaderObj}`);
        dispatch(ReduxCampaign.setTemplateHeaderParams(arryHeaderObj));
      }

      console.log(`Template Selection Body Set
        >> header parameter >> ${JSON.stringify(tempHeaderObj)}
        `);

      let newTemplateLanguageBodyParam: BodyParamLanguage[] = [];
      // Body Parameter Set
      if (templateLang && templateLang.bodyParam) {
        templateLang.bodyParam.forEach((bodyParam) => {
          const param = {
            ...bodyParam,
            paramValue:
              bodyParam.paramType?.toLowerCase() === "customtext"
                ? `${bodyParam.paramValue} ${bodyParam.paramId}`
                : bodyParam.paramValue,
          };
          newTemplateLanguageBodyParam.push(param);
        });
        // let newTemplateLanguageBodyParam = templateLang.bodyParam;
        // newTemplateLanguageBodyParam.forEach(bodyParam => {
        //   if (bodyParam?.paramType && bodyParam?.paramType.toLowerCase() === "customtext") {
        //     bodyParam = {
        //       ...bodyParam,
        //       paramValue: `${bodyParam.paramValue} ${bodyParam.paramId}`
        //     }
        //   }
        // })

        console.log(`Template Selection Body Set Check
        >> body text >> ${JSON.stringify(templateLang.body)}
        >> body parameter >> ${JSON.stringify(templateLang.bodyParam)}
        >> body param modified >> ${JSON.stringify(
          newTemplateLanguageBodyParam
        )}
        `);

        setBodyParams(newTemplateLanguageBodyParam);
        dispatch(
          ReduxCampaign.setTemplateBodyParams(newTemplateLanguageBodyParam)
        );

        // setBodyParams(templateLang.bodyParam)
        // dispatch(ReduxCampaign.setTemplateBodyParams(templateLang?.bodyParam));
      }

      const bodyParamExist: boolean = templateLang.bodyParam ? true : false;
      const bodyParamCountExist: boolean =
        !templateLang.bodyParamCount || templateLang.bodyParamCount > 0
          ? true
          : false;

      setHasCompleteParams(
        templateLang && (bodyParamExist || bodyParamCountExist)
      );

      //dynamic value
      if (
        templateLang &&
        templateLang.buttons &&
        templateLang.buttons.length > 0
      ) {
        setButtons(templateLang.buttons);
        dispatch(ReduxCampaign.setCampaignButtons(templateLang.buttons));
      }

      onChanges([
        { key: "bodyParam", value: newTemplateLanguageBodyParam },
        { key: "header", value: templateLang?.header },
        { key: "buttons", value: templateLang?.buttons },
        // {key: "saveTemplate", value:selectedTemplate}
      ]);
    }
  };

  const onTemplateSelectionChanges = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log(
      "Masuk template selection changes  >>> value = " + event.target.value
    );
    dispatch(ReduxCampaign.setIsLoading(true));
    // Reset Header, Body, and Button Param
    if (event.target.value === "") {
      // console.log("Masuk template selection changes default >>>");
      console.log("masuk 3 rfx");
      ResetTemplate();
      return;
    }
    // console.log("Template select: "+ event.target.value);
    const templateId = event.target.value;
    // Get Template by ID and Language
    await onTemplateSelected(templateId);
    dispatch(ReduxCampaign.setIsLoading(false));
  };

  const ResetTemplate = async () => {
    setBodyParams([]);
    setHeaderParams([]);
    setHasCompleteParams(false);
    setTemplate(undefined);
    setSelectedTemplateId("");
    setPreviewBody("");
    setHeaderAttach(undefined);
    setHeaderAttachObj({});
    setPreviewHeaderText("");
    setButtons([]);
    setURLDynamicValues("");
    dispatch(ReduxCampaign.setCampaignButtons([]));
    dispatch(ReduxCampaign.setTemplateID(""));
    dispatch(ReduxCampaign.setTemplateLanguageID(""));
    dispatch(ReduxCampaign.setSelectedTemplateLanguage({}));
    dispatch(ReduxCampaign.setTemplateBodyParams([]));
    dispatch(ReduxCampaignTarget.cleanReduxSelectedCampaignTarget(true));
    setIsClickRemoveFile(false);
    // console.log("Disabled 6");
    console.log("jalan 3 poc");
    document.getElementById("nextStep")?.classList.remove("disabled");
  };

  const onBodyParamChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("masuk disini poc");
    const bodyParamNo: number = +event.target.id.charAt(
      event.target.id.length - 1
    );
    let bodyParamValue: string = event.target.value;
    let bodyParamText = event.target.selectedOptions.item(0)?.text;
    let newBodyParams: any[] = [...bodyParams];

    if (
      !document
        .getElementById(`customParam${bodyParamNo}`)
        ?.classList.contains("display-none")
    ) {
      document
        .getElementById(`customParam${bodyParamNo}`)
        ?.classList.add("display-none");
    }

    // Reset Parameter (Select Option 1)
    if (+bodyParamValue === 1) {
      const filteredBodyParams = newBodyParams.filter((bodyParam) => {
        return bodyParam.paramId !== bodyParamNo;
      });
      newBodyParams = filteredBodyParams;
      // setHasCompleteParams(false);
      return setBodyParams(newBodyParams);
    }

    // Customized Param (Select Option 2)
    if (bodyParamValue === "CustomizeText") {
      document
        .getElementById(`customParam${bodyParamNo}`)
        ?.classList.remove("display-none");
      // setHasCompleteParams(false);

      bodyParamText = `${bodyParamText} ${bodyParamNo}`;
    }

    // Else
    let newParam: any = {
      paramId: bodyParamNo,
      paramKey:
        bodyParamValue === "CustomizeText"
          ? "customText" + bodyParamNo
          : bodyParamValue,
      paramType:
        bodyParamValue === "CustomizeText" ? "customText" : bodyParamValue,
      paramValue:
        bodyParamValue === "CustomizeText"
          ? `${bodyParamText} ${bodyParamNo}`
          : bodyParamText,
      // paramValue: bodyParamText,
      textContent: "",
    };

    if (bodyParamValue === "CustomizeText") {
      newParam.paramName = "";
    }

    let tempBodyParams = newBodyParams.filter((bodyParam) => {
      return bodyParam.paramId !== bodyParamNo;
    });
    tempBodyParams.push(newParam);

    let hasEmptyTextContent: Boolean = false;
    tempBodyParams.forEach((param) => {
      if (
        param.paramType === "customText" &&
        (param.textContent === "" || param.paramName === "")
      ) {
        hasEmptyTextContent = true;
      }
    });
    console.log(hasEmptyTextContent, "loh poc");
    if (hasEmptyTextContent === true) {
      console.log("jalan 4 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }

    return setBodyParams(tempBodyParams);
  };

  //only for custom param
  const onHeaderParamChanges = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let headerParamValue: string = event.target?.value; // customers.firstName, CustomizeText >> jadi paramType tapi CustomizeText
    let headerParamText = event.target?.selectedOptions?.item(0)?.text; // Bahasa Indonesia >> jadi paramValue

    console.log(`Check select header Param 
    headerParamValue >> ${headerParamValue} 
    headerParamText ${headerParamText}`);

    // Reset Param (Select Option 1)
    if (+headerParamValue === 1) {
      // Pilih Parametermu >> Reset All
      setHeaderParams([]);
      console.log("jalan 5 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
      return;
    }

    // Customized Param (Select Option Customize Text/URL)
    if (headerParamValue === "CustomizeText") {
      document
        .getElementById(`customHeaderParam`)
        ?.classList.remove("display-none");
      // setHasCompleteParams(false);
      // bodyParamText = `${bodyParamText} ${bodyParamNo}`;
    } else {
      document
        .getElementById(`customHeaderParam`)
        ?.classList.add("display-none");
    }

    // Else
    // Select value >> value={headerParams[0].paramType}
    let newHeaderParam: any = {
      paramId: 1,
      paramKey:
        headerParamValue === "CustomizeText" ? "customText" : headerParamValue,
      paramType:
        headerParamValue === "CustomizeText" ? "customText" : headerParamValue,
      paramValue: headerParamText,
      textContent: "",
    };

    if (headerParamValue === "CustomizeText") {
      newHeaderParam.paramName = "";
    }

    let newArrHeaderParam = [newHeaderParam];
    // newArrHeaderParam.push(newHeaderParam)
    console.log(`Check select header Param 2
    header param >> ${JSON.stringify(newHeaderParam)}
    new arr >> ${JSON.stringify(newArrHeaderParam)}
    `);

    let hasEmptyTextContent = false;
    newArrHeaderParam.forEach((param) => {
      if (
        param.paramType === "customText" &&
        (param.textContent === "" || param.paramName === "")
      ) {
        hasEmptyTextContent = true;
      }
    });
    if (hasEmptyTextContent) {
      console.log("jalan 6 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }

    setHeaderParams(newArrHeaderParam);

    props.onChanges([
      {
        key: "header",
        value: {
          ...template?.header,
          headerParam: newArrHeaderParam,
        },
      },
    ]);

    // dispatch(ReduxCampaign.setTemplateHeaderParams(newArrHeaderParam));
  };

  const onCustomHeaderParamChanges = async (customHeader: string) => {
    let text = customHeader;
    console.log(`Check select header Param 3
    customheaderparam >> ${text}`);

    let customHeaderParam = {
      paramId: 1,
      paramKey: "customText",
      paramValue: `${t("Campaign.Input.CustomizeText")}`,
      paramType: "customText",
      paramName: headerParams[0]?.paramName ?? "",
      textContent: text ? text : "",
    };

    let newArrHeaderParam = [];
    newArrHeaderParam.push(customHeaderParam);

    let hasEmptyTextContent = false;
    newArrHeaderParam.forEach((param) => {
      if (param.textContent === "" || param.paramName === "") {
        hasEmptyTextContent = true;
      }
    });
    if (hasEmptyTextContent) {
      console.log("jalan 7 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }

    setHeaderParams(newArrHeaderParam);

    props.onChanges([
      {
        key: "header",
        value: {
          ...template?.header,
          headerParam: newArrHeaderParam,
        },
      },
    ]);
  };

  //when custom text
  const onCustomParamChanges = async (text: string, paramNo: number) => {
    // console.log("Inserted value: "+text);

    const filteredBodyParams = bodyParams.filter((bodyParam) => {
      return bodyParam.paramId !== paramNo;
    });
    const selectedBodyParams = bodyParams.find((bodyParam) => {
      return bodyParam.paramId === paramNo;
    });

    const tempParams = [...filteredBodyParams];

    let customTextValue = text;

    let customParam = {
      paramId: paramNo,
      paramKey: "customText" + paramNo, //"customText",
      paramValue: `${t("Campaign.Input.CustomizeText")} ${paramNo}`,
      paramType: "customText",
      paramName: selectedBodyParams?.paramName ?? "",
      textContent: customTextValue ? customTextValue : "",
    };

    tempParams.push(customParam);

    //check if tempParams has textContent not empty
    let hasEmptyTextContent = false;
    tempParams.forEach((param) => {
      if (param.textContent === "" || param.paramName === "") {
        hasEmptyTextContent = true;
      }
    });
    if (hasEmptyTextContent) {
      console.log("jalan 8 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }

    return setBodyParams(tempParams);
  };

  const onParamNameChanges = async (text: string, paramNo: number) => {
    // console.log("Inserted value: "+text);

    const filteredBodyParams = bodyParams.filter((bodyParam) => {
      return bodyParam.paramId !== paramNo;
    });
    console.log(filteredBodyParams, "filteredBodyParams 9 poc");
    
    const selectedBodyParams = bodyParams.find((bodyParam) => {
      return bodyParam.paramId === paramNo;
    });
    console.log(selectedBodyParams, "selectedBodyParams 9 poc");


    const tempParams = [...filteredBodyParams];
    console.log(tempParams, "tempParams 9 poc");


    let nameValue = text;
    console.log(nameValue, "nameValue 9 poc");


    let customParam = {
      paramId: paramNo,
      paramKey: "customText" + paramNo, //"customText",
      paramValue: `${t("Campaign.Input.CustomizeText")} ${paramNo}`,
      paramType: "customText",
      paramName: nameValue ? nameValue : "",
      textContent: selectedBodyParams?.textContent ?? "",
    };
    console.log(customParam, "customParam 9 poc");


    tempParams.push(customParam);
    console.log(tempParams, "tempParams after push 9 poc");


    //check if tempParams has textContent not empty
    let hasEmptyTextContent = false;
    tempParams.forEach((param) => {
      if (param.textContent === "" || param.paramName === "") {
        hasEmptyTextContent = true;
      }
    });
    if (hasEmptyTextContent) {
      console.log("jalan 9 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }
    console.log(tempParams, "tempParams before setBodyParams 9 poc");

    // dispatch(ReduxCampaign.set)
    return setBodyParams(tempParams);
  };

  const onHeaderParamNameChanges = async (customHeader: string) => {
    let text = customHeader;
    console.log(`Check select header Param 3
    customheaderparam >> ${text}`);

    let customHeaderParam = {
      paramId: 1,
      paramKey: "customText",
      paramValue: `${t("Campaign.Input.CustomizeText")}`,
      paramType: "customText",
      paramName: text ? text : "",
      textContent: headerParams[0]?.textContent ?? "",
    };

    let newArrHeaderParam = [];
    newArrHeaderParam.push(customHeaderParam);

    let hasEmptyTextContent = false;
    newArrHeaderParam.forEach((param) => {
      if (param.textContent === "" || param.paramName === "") {
        hasEmptyTextContent = true;
      }
    });
    if (hasEmptyTextContent) {
      console.log("jalan 10 poc");
      document.getElementById("nextStep")?.classList.add("disabled");
    } else {
      document.getElementById("nextStep")?.classList.remove("disabled");
    }

    setHeaderParams(newArrHeaderParam);

    props.onChanges([
      {
        key: "header",
        value: {
          ...template?.header,
          headerParam: newArrHeaderParam,
        },
      },
    ]);
  };

  const onCustomEmoji = (event: any, emojiObject: any) => {
    const textAreaId = activeTextArea;
    let currentTextArea = document.getElementById(
      activeTextArea
    ) as HTMLTextAreaElement;
    let tempText = "";
    if (currentTextArea.value) {
      tempText = currentTextArea.value;
    }

    // Selection
    let selectStart = currentTextArea.selectionStart;
    let selectEnd = currentTextArea.selectionEnd;

    if (selectStart !== undefined && selectEnd !== undefined) {
      let selectedText = tempText.substring(selectStart, selectEnd);
      let selectedStart = tempText.substring(0, selectStart);
      let selectedEnd = tempText.substring(selectEnd, tempText.length);
      let resultText =
        selectedStart + "" + selectedText + emojiObject.emoji + selectedEnd;

      currentTextArea.value = resultText;
      onCustomParamChanges(
        resultText,
        +textAreaId.charAt(textAreaId.length - 1)
      );

      // console.log("Emoji added");
      setShowEmojiPicker(false);
      setActiveEmojiPickerBodyParam(0);
      setActiveTextArea("");
    }
  };

  const onCustomBold = () => {
    // Get Active Text Area and Attributes
    const textAreaId = activeBoldArea;
    let currentTextArea = document.getElementById(
      textAreaId
    ) as HTMLTextAreaElement;
    let tempText = "";
    if (currentTextArea.value) {
      tempText = currentTextArea.value;
    }

    // Selection
    let selectStart = currentTextArea.selectionStart;
    let selectEnd = currentTextArea.selectionEnd;

    if (selectStart !== undefined && selectEnd !== undefined) {
      let selectedText = tempText.substring(selectStart, selectEnd);
      let selectedStart = tempText.substring(0, selectStart);
      let selectedEnd = tempText.substring(selectEnd, tempText.length);
      let resultText = selectedStart + "*" + selectedText + "*" + selectedEnd;

      currentTextArea.value = resultText;
      onCustomParamChanges(
        resultText,
        +textAreaId.charAt(textAreaId.length - 1)
      );

      setActiveBoldArea("");
    }
  };

  const onCustomItalic = () => {
    // Get Active Text Area and Attributes
    const textAreaId = activeItalicArea;
    let currentTextArea = document.getElementById(
      textAreaId
    ) as HTMLTextAreaElement;
    let tempText = currentTextArea.value;

    // Selection
    let selectStart = currentTextArea.selectionStart;
    let selectEnd = currentTextArea.selectionEnd;

    if (selectStart !== undefined && selectEnd !== undefined) {
      let selectedText = tempText.substring(selectStart, selectEnd);
      let selectedStart = tempText.substring(0, selectStart);
      let selectedEnd = tempText.substring(selectEnd, tempText.length);
      let resultText = selectedStart + "_" + selectedText + "_" + selectedEnd;

      currentTextArea.value = resultText;
      onCustomParamChanges(
        resultText,
        +textAreaId.charAt(textAreaId.length - 1)
      );

      setActiveItalicArea("");
    }
  };

  // Preview Saved Template
  const replacePreviewBody = (bodyText: string) => {
    let tempBody = "";

    if (template) {
      if (template.body) {
        tempBody = template.body.replaceAll("\n", "<br/>");
        // tempBody = template.body;
      }

      if (template.bodyParamCount) {
        for (let idx = 0; idx < template.bodyParamCount; idx++) {
          const replaceIdx: string = `{{${idx + 1}}}`;
          let changedParam = bodyParams.find((bodyParam) => {
            return bodyParam.paramId === idx + 1;
          });
          if (changedParam) {
            // paramKey !== customTExt+paramNo
            // if (Object.keys(option).includes(changedParam.paramKey) && changedParam.paramKey !== "customText") {
            if (
              Object.keys(option).includes(changedParam.paramKey) &&
              changedParam.paramKey !== `customText${idx + 1}`
            ) {
              // console.log("Ini yang harus direplace: "+JSON.stringify(changedParam));
              tempBody = tempBody.replace(
                replaceIdx,
                `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                  "Campaign.Input." + changedParam.paramKey
                )}</span>`
              );
            } else {
              if (
                changedParam.paramKey === `customText${idx + 1}` &&
                (!changedParam.textContent || changedParam.textContent === "")
              ) {
                // if (changedParam.paramKey === "customText" && changedParam.paramId === idx+1 && ( !changedParam.textContent ||changedParam.textContent === "")) {
                // console.log("Ini custom check 1 >> "+changedParam.textContent);
                tempBody = tempBody.replace(
                  replaceIdx,
                  `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                    "Campaign.Input.CustomizeText"
                  )}</span>`
                );
              } else {
                // console.log("Ini custom check 2 >> "+changedParam.textContent);
                const newLine = changedParam.textContent.includes("\n")
                  ? "<br/>"
                  : "";
                const newTextContent = changedParam.textContent.replaceAll(
                  "\n",
                  "<br/>"
                );
                tempBody = tempBody.replace(
                  replaceIdx,
                  `${newLine}<span class="badge badge-primary fw-bolder fs-7 m-1 text-wrap mw-100" style="text-align: start;"><i class="bi bi-server pe-2 text-white w-100"></i>${newTextContent}</span>`
                );
              }
            }
          } else {
            tempBody = tempBody.replace(
              replaceIdx,
              `<span class="badge badge-circle fw-bolder fs-7 badge-primary m-1">${
                idx + 1
              }</span>`
            );
          }
        }
      }
    }
    setPreviewBody(tempBody);

    return tempBody;
  };

  /**
   * only for text header
   * returning header obj
   */
  const replacePreviewHeader = (
    template: Language | undefined,
    headerParams?: BodyParamLanguage[] | undefined
  ) => {
    if (template) {
      if (template.header) {
        console.log(`Template Selection Header Set replace preview
      >> template language >> ${JSON.stringify(template)}
    `);

        if (template.header?.type !== "text") {
          return;
        }

        let tempHeaderText = template.header.text;
        let initHeaderPreview = tempHeaderText;
        let headerParam: BodyParamLanguage | undefined = undefined;
        if (template.header.headerParam) {
          if (headerParams) headerParam = headerParams[0];
          else
            headerParam = template.header.headerParam[0] as BodyParamLanguage;

          if (headerParam.paramValue) {
            // Check By Type
            let replacementKey = `{{${headerParam.paramId}}}`;
            let replacementContent = `{{${headerParam.paramValue}}}`;
            if (
              headerParam.paramType === "customText" &&
              headerParam.textContent &&
              headerParam.textContent !== ""
            ) {
              replacementContent = `${headerParam.textContent}`;
              // replacementKey = `{{${headerParam.paramId}}}`
            }
            // debugger
            // tempHeaderText >> Hi {{Nama Pelanggan}}
            console.log(
              `replacePreviewHeader ${tempHeaderText} ${replacementKey} ${replacementContent}`
            );
            tempHeaderText = tempHeaderText.replace(
              replacementKey,
              replacementContent
            );

            // // Replace Preview Header
            const keyParam = Object.keys(option).map((value) => {
              //, index, array) => {
              console.log(`replacePreviewHeader key param ${value}`);
              return value; //array[index];
            });

            initHeaderPreview = tempHeaderText;
            for (let index = 0; index < keyParam.length; index++) {
              const key = keyParam[index];
              console.log(
                "replacePreviewHeader testing : " +
                  key +
                  " - " +
                  t("Campaign.Input." + key)
              );
              console.log(
                `replacementContent ${replacementContent} headerParam.paramType ${headerParam.paramType}`
              );
              if (headerParam.paramType === "customText") {
                if (headerParam.textContent === "") {
                  initHeaderPreview = initHeaderPreview.replaceAll(
                    "{{" + t("Campaign.Input." + key) + "}}",
                    `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                      "Campaign.Input.CustomizeText"
                    )}</span>`
                  );
                }
                if (headerParam.textContent !== "" && index === 0) {
                  initHeaderPreview = initHeaderPreview.replaceAll(
                    replacementContent,
                    `<span class="badge badge-primary fw-bolder fs-7 m-1 text-wrap mw-100" style="text-align: start;"><i class="bi bi-server pe-2 text-white w-100"></i>${replacementContent}</span>`
                  );
                }
              } else {
                // not a custom parameter
                initHeaderPreview = initHeaderPreview.replaceAll(
                  "{{" + t("Campaign.Input." + key) + "}}",
                  `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                    "Campaign.Input." + key
                  )}</span>`
                );
              }
            }
          }
        }

        setHeaderText(tempHeaderText);
        setPreviewHeaderText(initHeaderPreview);

        // >> template header param >> ${JSON.stringify(template.header.headerParam)}
        console.log(`replacePreviewHeader Template Selection Header Set
            >> template header >> ${JSON.stringify(template.header)}  
            >> headerParam >> ${JSON.stringify(
              template.header.headerParam
            )}    // param2 and textcontent
            >> headerParam >> ${JSON.stringify(
              headerParam
            )}    // param2 and textcontent
            `);

        return {
          ...template.header,
          ...headerParam,
          textContent: tempHeaderText,
        };
      }
    }
  };

  type Param = {
    text: string;
    paramNo?: number;
    isURLDynamic?: boolean;
    isHeaderParam?: boolean;
    isBodyParam?: boolean;
  };
  const [parameter, setParameter] = useState<Param>();
  const debounceParam = useDebounce(parameter, 500);
  const [urlParameter, setURLParameter] = useState<Param>();
  const debounceParamURLDynamic = useDebounce(urlParameter, 10);
  useEffect(() => {
    console.log(`debounce value ${JSON.stringify(debounceParam)}`);
    if (
      debounceParam &&
      debounceParam.text !== undefined &&
      debounceParam.paramNo &&
      debounceParam.isBodyParam
    )
      onCustomParamChanges(debounceParam.text, debounceParam.paramNo);
    else if (
      debounceParam &&
      debounceParam.text !== undefined &&
      !debounceParam.paramNo &&
      debounceParam.isHeaderParam
    )
      onCustomHeaderParamChanges(debounceParam.text);
  }, [debounceParam]);

  useEffect(() => {
    if (debounceParamURLDynamic && debounceParamURLDynamic.isURLDynamic)
      if (
        !debounceParamURLDynamic?.text ||
        (debounceParamURLDynamic.text && debounceParamURLDynamic.text === "")
      ) {
        setURLDynamicValues(utmURLDynamic);
      } else {
        console.log(`debounceParam?.text : `, debounceParamURLDynamic?.text);
        //check jika blocked text
        if (!debounceParamURLDynamic.text.startsWith(utmURLDynamic)) {
          setURLDynamicValues(utmURLDynamic);
          console.log(
            `debounce urlDynamicValues blocked text : `,
            URLDynamicValues
          );
          return;
        }
        setURLDynamicValues(debounceParamURLDynamic?.text);
        console.log(`debounce urlDynamicValues : `, URLDynamicValues);
      }
  }, [debounceParamURLDynamic]);

  const [paramName, setParamName] = useState<Param>();
  const debounceParamName = useDebounce(paramName, 500);
  useEffect(() => {
    console.log(`debounce value ${JSON.stringify(debounceParamName)}`);
    if (
      debounceParamName &&
      debounceParamName.text !== undefined &&
      debounceParamName.paramNo
    )
      onParamNameChanges(debounceParamName.text, debounceParamName.paramNo);
    else if (
      debounceParamName &&
      debounceParamName.text !== undefined &&
      !debounceParamName.paramNo
    )
      onHeaderParamNameChanges(debounceParamName.text);
  }, [debounceParamName]);

  // ---------------------------- Saved Template ---------------------------- //

  // --------------------------------------- UI ---------------------------------------  //

  return (
    <>
      <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
        {/* Saved Template */}

        {/* Saved Template Pane */}
        {/* {templateState === "Save" && ( */}
        <div className={clsx(`d-flex`, isTabletOrMobile ? "flex-column" : "")}>
          {/* 1 */}
          <div className={clsx(`col-${isTabletOrMobile ? "12" : "4"}`)}>
            {isTabletOrMobile && (
              <h1 className="mb-3">{t("Campaign.Tab.Content")}</h1>
            )}
            {/* Select Template */}
            <div className="mb-5">
              <div className="step-sub-title">
                {t("Campaign.Title.Template")}
                {template?.id && template.id !== "" && (
                  <span
                    id="templateChoosed"
                    className="bi bi-check-circle-fill text-success ps-3"
                  ></span>
                )}
              </div>
              <select
                disabled={
                  selectedCampaign.status !== undefined &&
                  selectedCampaign.status !== "CREATED"
                }
                className={clsx(
                  "form-select",
                  isTabletOrMobile ? "w-100" : "mw-300px me-10"
                )}
                aria-label="Select Saved Template"
                id="templateSelect"
                onChange={onTemplateSelectionChanges}
                value={selectedTemplateId}
                data-testid="select-template"
              >
                {selectedTemplateId === "" && (
                  <option value={""}>{t("Campaign.Input.Template")}</option>
                )}
                {RCTemplateList.map((element) => {
                  // if (campaignID)
                  return (
                    <option value={element.id} key={element.id}>
                      {element.templateName}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Select Header Parameter */}
            {template && template.header && (
              <div
                className={clsx(
                  "mb-5",
                  template.id !== "" ? "" : "display-none"
                )}
              >
                <div className="step-sub-title">
                  {t("Campaign.Title.Header")}
                  {file && (
                    <span className="bi bi-check-circle-fill text-success ps-3"></span>
                  )}
                </div>
                {/* Header Image, Video, Document */}
                {template.header.type === "media" && (
                  <div className="d-flex flex-row align-items-center">
                    <label
                      className="btn btn-icon-gray-900 btn-text-gray-900 p-0"
                      // type="button"
                      data-bs-toggle="tooltip"
                      data-kt-image-input-action="change"
                      title={t("HC.Info.Upload")}
                    >
                      {(template.header.mediaType === "image" && (
                        <i className="bi bi-card-image fs-5tx"></i>
                      )) ||
                        (template.header.mediaType === "video" && (
                          <i className="bi bi-play-btn fs-5tx"></i>
                        )) ||
                        (template.header.mediaType === "document" && (
                          <i className="bi bi-file-earmark fs-5tx"></i>
                        ))}
                      <span
                        className="badge badge-square upload-badge bi bi-pencil-fill text-gray-500 fs-1"
                        style={{
                          top: "30px",
                          textShadow: "2px 2px rgba(0,0,0,0.20)",
                        }}
                      />
                      <input
                        disabled={
                          selectedCampaign.status !== undefined &&
                          selectedCampaign.status !== "CREATED"
                        }
                        id="chat-media"
                        onChange={setPreviewFile}
                        type="file"
                        name="attachment"
                        multiple={false}
                        style={{ display: "none" }}
                        accept={
                          template?.header?.mediaType === "image"
                            ? imageExtention
                            : template?.header?.mediaType === "video"
                            ? videoExtention
                            : template?.header?.mediaType === "document"
                            ? fileExtention
                            : ""
                        }
                      />
                    </label>
                    {/* Uploaded Header */}
                    {/* Not Upload (Sample) */}

                    {!headerAttach && (
                      <div>
                        <label
                          className={clsx(
                            "text-gray-600",
                            isClickRemoveFile === true ? "d-none" : ""
                          )}
                        >
                          {`${fileName.substring(0, 20)}...`}
                        </label>
                        <label
                          className={clsx(
                            "btn btn-sm btn-icon-danger btn-text-danger p-0",
                            isClickRemoveFile === true ? "d-none" : ""
                          )}
                        >
                          <div
                            className="btn bi bi-x-lg text-danger"
                            onClick={removeNoHeaderAttach}
                          ></div>
                        </label>
                      </div>
                    )}

                    {/* Upload (Header) */}
                    {headerAttach && (
                      <div>
                        <label className="text-gray-600">{`${
                          headerAttachObj.fileName
                            ? headerAttachObj.fileName
                                .toString()
                                .substring(0, 20) + "..."
                            : ""
                        }`}</label>
                        <label className="btn btn-sm btn-icon-danger btn-text-danger p-0">
                          <div
                            className="btn bi bi-x-lg text-danger"
                            onClick={removeHeaderAttach}
                          ></div>
                        </label>
                      </div>
                    )}
                  </div>
                )}

                {/* Header Text */}
                {template.header.type === "text" && (
                  <div>
                    <div
                      className={clsx(
                        "d-flex flex-row border mb-5",
                        isTabletOrMobile ? "w-100" : "mw-300px"
                      )}
                    >
                      <input
                        disabled
                        className="form-control form-control-solid border-0 bg-white"
                        placeholder={t("Campaign.Input.ContentField")}
                        id="templateHeaderText"
                        value={headerText} //savedHeaderText
                        // value={template && template.header?.type === "text" ? template.header?.text : ""}
                      />
                    </div>
                    {/* Header Select */}
                    {template?.header !== undefined &&
                      template?.header?.paramCount !== undefined &&
                      template?.header?.paramCount > 0 && (
                        <div>
                          <select
                            className={clsx(
                              "form-select",
                              isTabletOrMobile ? "w-100" : "mw-300px me-10"
                            )}
                            aria-label="Select Saved Template"
                            id={`selectHeaderParam`}
                            // value={headerParams[0].paramType === "customText" ?
                            //   "CustomizeText" : headerParams[0].paramType}
                            value={
                              headerParams[0] && headerParams[0].paramType
                                ? headerParams[0].paramType === "customText"
                                  ? "CustomizeText"
                                  : headerParams[0].paramType
                                : ""
                            }
                            onChange={onHeaderParamChanges}
                          >
                            <option value={1}>
                              {t("Campaign.Input.Param")}
                            </option>
                            {Object.keys(option).map((value, index, array) => {
                              return (
                                <option
                                  value={array[index]}
                                  key={value + index}
                                >
                                  {t("Campaign.Input." + array[index])}
                                </option>
                              );
                            })}
                          </select>

                          {headerParams[0]?.paramType === "customText" &&
                          headerParams[0]?.paramName ? (
                            <div
                              className={clsx(
                                "mt-2",
                                isTabletOrMobile ? "w-100" : "mw-300px"
                              )}
                            >
                              <input
                                // disabled={!!headerParams[0]?.paramName}
                                className="form-control"
                                placeholder={t(
                                  "Campaign.TemplateCategory.IM.Body.Modal.CustomText"
                                )}
                                value={headerParams[0]?.paramName}
                                onChange={(e) =>
                                  setParamName({
                                    text: e.target.value,
                                    isURLDynamic: false,
                                  })
                                }
                              />
                            </div>
                          ) : null}

                          {headerParams[0] &&
                          headerParams[0].paramType === "customText" ? (
                            <div
                              // className="mt-3 mb-3 display-none"
                              className="mt-3 mb-3"
                              id={`customHeaderParam`}
                            >
                              <textarea
                                // ref={headerParamRef}
                                className={clsx(
                                  "form-control",
                                  isTabletOrMobile ? "w-100" : "mw-300px"
                                )}
                                placeholder={t("Campaign.Input.CustomizeText")}
                                rows={2}
                                id={`textAreaHeaderParam`}
                                // onChange={onCustomHeaderParamChanges}
                                onChange={(e) =>
                                  setParameter({
                                    text: e.target.value,
                                    isURLDynamic: false,
                                    isBodyParam: false,
                                    isHeaderParam: true,
                                  })
                                }
                                defaultValue={
                                  customHeaderParam && customHeaderParam !== ""
                                    ? customHeaderParam
                                    : ""
                                  // headerParams[0].textContent ? headerParams[0].textContent : (

                                  // template?.header?.headerParam &&
                                  // template?.header?.headerParam[0]?.textContent
                                  // ? template?.header?.headerParam[0]?.textContent: ""
                                  // )
                                }
                                // {(e) =>
                                //   onCustomHeaderParamChanges(
                                //     e.target.value)//,+(e.target.id.charAt(e.target.id.length - 1))
                                // }
                                data-testid="custom-header-param"
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}

            {/* Select Body Parameter */}
            {template && (template.bodyParamCount || 0) > 0 && (
              <div
                className={clsx(
                  "mb-5",
                  template.id && template.id !== "" ? "" : "display-none"
                )}
              >
                <div className="step-sub-title">
                  {t("Campaign.Title.BodyMsg")}
                  {hasCompleteParams && (
                    <span className="bi bi-check-circle-fill text-success ps-3"></span>
                  )}
                </div>
                <div>
                  {[
                    ...Array(
                      Math.ceil(
                        template?.bodyParamCount ? template?.bodyParamCount : 0
                      )
                    ),
                  ].map((e, i) => {
                    const currentParam = bodyParams.find(
                      (param) => param.paramId === i + 1
                    );
                    console.log("currentParam", currentParam);
                    return (
                      <div
                        className="d-flex flex-row m-1"
                        key={`bodyParamDiv_${i + 1}`}
                      >
                        <span className="badge badge-circle fw-bolder fs-7 badge-primary m-3">
                          {i + 1}
                        </span>
                        <div
                          className={clsx(
                            "d-flex flex-column",
                            isTabletOrMobile ? "w-100" : ""
                          )}
                        >
                          <select
                            disabled={
                              selectedCampaign.status !== undefined &&
                              selectedCampaign.status !== "CREATED"
                            }
                            className={clsx(
                              "form-select",
                              isTabletOrMobile ? "w-100" : "mw-300px me-10"
                            )}
                            aria-label="Select Saved Template"
                            id={`selectBodyParam_${i + 1}`}
                            value={
                              currentParam?.paramType === "customText"
                                ? "CustomizeText"
                                : currentParam?.paramType
                            }
                            onChange={onBodyParamChanges}
                          >
                            <option value={1}>
                              {t("Campaign.Input.Param")}
                            </option>
                            {/* <option value="customText" key="1" >
                            {t("Campaign.Input.CustomizeText")}
                          </option> */}
                            {Object.keys(option).map((value, index, array) => {
                              return (
                                <option
                                  value={array[index]}
                                  key={value + index}
                                >
                                  {t("Campaign.Input." + array[index])}
                                </option>
                              );
                            })}
                          </select>

                          {showEmojiPicker &&
                            activeEmojiPickerBodyParam === i + 1 && (
                              <Picker
                                pickerStyle={{ width: "100%" }}
                                onEmojiClick={onCustomEmoji}
                                key={i + 1}
                                data-key={i + 1}
                              />
                            )}

                          {currentParam?.paramType === "customText" &&
                          currentParam?.paramName !== undefined ? (
                            <div
                              className={clsx(
                                "mt-1",
                                isTabletOrMobile ? "w-100" : "mw-300px"
                              )}
                            >
                              <input
                                // disabled={!!currentParam?.paramName}
                                className="form-control"
                                placeholder={t(
                                  "Campaign.TemplateCategory.IM.Body.Modal.CustomText"
                                )}
                                defaultValue={currentParam?.paramName}
                                onChange={(e) =>
                                  setParamName({
                                    text: e.target.value,
                                    paramNo: currentParam?.paramId,
                                    isURLDynamic: false,
                                  })
                                }
                              />
                            </div>
                          ) : null}

                          {currentParam?.paramType === "customText" ? (
                            <div
                              className={clsx("mt-3 mb-3")}
                              id={`customParam${i + 1}`}
                            >
                              <textarea
                                className="form-control mw-300px"
                                placeholder={t("Campaign.Input.CustomizeText")}
                                rows={2}
                                id={`textAreaParam${i + 1}`}
                                onChange={(e) =>
                                  setParameter({
                                    text: e.target.value,
                                    paramNo: +e.target.id.charAt(
                                      e.target.id.length - 1
                                    ),
                                    isURLDynamic: false,
                                    isBodyParam: true,
                                    isHeaderParam: false,
                                  })
                                }
                                defaultValue={
                                  currentParam?.paramType === "customText"
                                    ? currentParam?.textContent
                                      ? currentParam?.textContent
                                      : ""
                                    : ""
                                }
                              />
                              <div className="d-flex justify-content-end">
                                {/* Emoji */}
                                <i
                                  id={`emoji_${i + 1}`}
                                  className="btn bi bi-emoji-smile p-1"
                                  onClick={() => {
                                    setActiveTextArea(`textAreaParam${i + 1}`);
                                    setShowEmojiPicker(true);
                                    setActiveEmojiPickerBodyParam(i + 1);
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={t("Common.Emoji")}
                                ></i>
                                <i
                                  id={`customBold${i + 1}`}
                                  className="btn bi bi-type-bold p-1"
                                  onClick={() =>
                                    setActiveBoldArea(`textAreaParam${i + 1}`)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={t("Common.Bold")}
                                ></i>
                                <i
                                  id={`customItalic${i + 1}`}
                                  className="btn bi bi-type-italic p-1"
                                  onClick={() =>
                                    setActiveItalicArea(`textAreaParam${i + 1}`)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={t("Common.Italic")}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Footer UI */}
            {/* {console.log("Check Footernya : " + JSON.stringify(template))} */}
            {template !== undefined &&
              template.footer &&
              template.footer != "" && (
                <div>
                  <div className="step-sub-title">
                    {t("Campaign.Title.Footer")}
                    <span className="bi bi-check-circle-fill text-success ps-3"></span>
                  </div>
                  <div>
                    {template.footer && template.footer != "" && (
                      <div
                        className={clsx(
                          // useFooter ? "" : "display-none",
                          "d-flex flex-row border",
                          isTabletOrMobile ? "w-100" : "mw-300px me-10"
                        )}
                        // className="d-flex flex-row border  mw-50"
                        // style={{background:"#F5F8FA"}}
                      >
                        <input
                          className="form-control form-control-solid border-0 bg-white"
                          // type="search"
                          // placeholder={t("Campaign.Input.ContentField")}
                          value={template.footer}
                          id="footer"
                          readOnly
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>

          {/* 2 */}
          <div className={clsx(`col-${isTabletOrMobile ? "12" : "4"}`)}>
            {campaignButtons !== undefined && campaignButtons.length > 0 && (
              <div
                className={clsx(
                  "mb-5",
                  template?.id && template?.id !== "" ? "" : "display-none"
                )}
              >
                <div className="step-sub-title text-grey">
                  {t("IB.Title.InteractiveButtons")}
                </div>

                {campaignButtons.length > 0 &&
                  campaignButtons.map((cta) => (
                    <React.Fragment key={cta.index?.toString()}>
                      {cta.type === "PHONE_NUMBER" && (
                        <div className="mt-4">
                          <label className="step-sub-title text-gray">
                            {t("Common.Column.PhoneNumber")}
                          </label>
                          <input
                            type="text"
                            className={clsx(
                              "form-control border-gray-300",
                              isTabletOrMobile ? "w-100" : "mw-300px"
                            )}
                            id="phoneNumber"
                            value={cta.phoneNumber}
                            disabled
                          />
                        </div>
                      )}
                      {cta.type === "URL" &&
                        (!cta.urlType || cta.urlType === "STATIC") && (
                          <div className="mt-4">
                            <label className="step-sub-title text-gray">
                              {t("Campaign.Title.WebURL")}
                            </label>
                            <input
                              type="text"
                              className={clsx(
                                "form-control border-gray-300",
                                isTabletOrMobile ? "w-100" : "mw-300px"
                              )}
                              id="phoneNumber"
                              // value={cta.urlType === "DYNAMIC" ? cta.urlDynamic?.replace("{{1}}", "") : cta.url}
                              value={cta.url}
                              disabled
                            />
                          </div>
                        )}
                      {/* check if url and urltype is dynamic */}
                      {cta.type === "URL" && cta.urlType === "DYNAMIC" && (
                        <div className="mt-4">
                          <label className="step-sub-title text-gray">
                            {/* {t("Campaign.Title.DynamicURL")} */}
                            {t("Campaign.Title.WebURL")}
                          </label>
                          <textarea
                            data-testid="dynamicValue"
                            className={clsx(
                              "form-control border-gray-300",
                              isTabletOrMobile ? "w-100" : "mw-300px"
                            )}
                            id={`dynamicValue`}
                            onChange={
                              (event) =>
                                setURLParameter({
                                  text: event.target.value,
                                  isURLDynamic: true,
                                  isBodyParam: false,
                                  isHeaderParam: false,
                                })
                              // setParameter({
                              //   text: event.target.value,
                              //   isURLDynamic: true,
                              //   isBodyParam: false,
                              //   isHeaderParam: false,
                              // })
                            }
                            value={URLDynamicValues}
                            maxLength={2000}
                          />
                          <div
                            className={clsx(
                              "d-flex flex-row-reverse",
                              isTabletOrMobile ? "w-100" : "mw-300px"
                            )}
                            style={{
                              justifySelf: "end",
                              textAlign: "end",
                              // paddingRight: "100px"
                            }}
                          >
                            {URLDynamicValues.length}/2000
                          </div>
                          {!isCampaignDynamicURLValid && (
                            <div className="text-danger">
                              {t("IB.Error.URLInvalid")}
                            </div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>

          {/* 3 */}
          <div className={clsx(`col-${isTabletOrMobile ? "12" : "4"}`)}>
            <div className="mb-5 h-100" data-testid="preview-wrapper">
              <div className="step-sub-title mb-4">
                {t("Campaign.Title.Preview")}
              </div>
              <div
                // className="h-100 p-5 ms-3 me-3"
                // className="h-100 bgi-size-contain bgi-size-lg-auto bgi-no-repeat bgi-position-center p-3"
                className="h-100 p-5"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/misc/wa-bg-review.png"
                  )})`,
                  // backgroundSize : '100%',
                  minHeight: isTabletOrMobile ? "300px" : "",
                }}
              >
                <div
                  className={clsx(
                    "d-flex flex-column cl-chat-whatsapp w-75 search-toolbar mw-80"
                  )}
                >
                  {/* Preview Header */}
                  {/* Header Text */}
                  {previewHeaderText !== undefined && previewHeaderText !== "" && (
                    // (template !== undefined &&
                    //     template.header?.paramCount !== undefined &&
                    //     template.header?.paramCount > 0 &&
                    //     template.header.type === "text" &&
                    <div
                      className="m-3 step-sub-title"
                      dangerouslySetInnerHTML={{
                        __html: previewHeaderText,
                      }}
                    ></div>
                    // >{headerText}</div>
                  )}
                  {/* Header Document, Video, Image */}
                  {/* <div>${JSON.stringify(headerAttachObj)}</div> */}
                  {template &&
                    template.header?.type === "media" &&
                    template.header.mediaType === "document" &&
                    isClickRemoveFile === false && (
                      <div className="d-flex justify-content-between bg-secondary m-3 search-toolbar mw-80 h-auto align-items-start image-input">
                        <div className="w-30 ms-2 align-self-center">
                          <i className="bi bi-file-earmark-text fs-3x text-dark"></i>
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          {headerAttachObj &&
                          headerAttachObj !== undefined &&
                          headerAttachObj.fileName !== undefined
                            ? excerptFirstAndLast(
                                headerAttachObj.fileName.toString()
                              )
                            : excerptFirstAndLast(template.header.fileName)}
                          {/* {!headerAttach ? (
                          headerAttachObj === undefined ? 
                            template.header.fileName : headerAttachObj.fileName.toString()) 
                        : headerAttachObj.fileName.toString()} */}
                        </div>
                        {/* <div className="flex-grow-1 m-2 align-self-center">{!headerAttach ? template.header.fileName : headerAttachObj.fileName.toString()}</div> */}
                        {/* <div className="flex-grow-1 m-2 align-self-center">{headerAttachObj.fileName.toString()}</div> */}
                        {/* <div className="flex-grow-1 m-2 align-self-center">{template.header.fileName ? template.header.fileName : "Document"}</div> */}
                        <div className="w-30 my-5 me-2 m align-self-center">
                          <i className="bi bi-download fs-1 text-dark"></i>
                        </div>
                      </div>
                    )}
                  {template &&
                    template.header?.type === "media" &&
                    template.header.mediaType === "image" &&
                    template.header.headerURL !== undefined &&
                    isClickRemoveFile === false && (
                      <div
                        className="wrapper mw-100 search-toolbar m-3"
                        style={{
                          paddingLeft: "0px",
                          backgroundImage: `url(${
                            !headerAttach
                              ? headerAttachObj.headerURL
                              : toAbsoluteUrl(
                                  headerAttachObj.headerURL
                                  // headerAttachObj.headerURL.toString()
                                )
                            // !headerAttach ? template.header.headerURL : toAbsoluteUrl(headerAttachObj.headerURL.toString())
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                    )}
                  {template &&
                    template.header?.type === "media" &&
                    template.header.mediaType === "video" &&
                    template.header.headerURL !== undefined &&
                    isClickRemoveFile === false && (
                      <div>
                        <video
                          controls
                          className={clsx("p-3")}
                          style={{ width: "100%", borderRadius: "8px" }}
                          src={headerAttachObj.headerURL}
                        ></video>
                      </div>
                    )}
                  {template &&
                    template.header?.type === "media" &&
                    template.header.mediaType !== "document" &&
                    template.header.headerURL === undefined &&
                    isClickRemoveFile === false && (
                      <div className="d-flex flex-column bg-secondary m-3 search-toolbar mw-80 h-auto align-items-center image-input">
                        <i
                          className={clsx(
                            "fs-5x text-dark",
                            template.header.mediaType === "image"
                              ? "bi bi-card-image"
                              : "bi-play-btn-fill"
                          )}
                        ></i>
                      </div>
                    )}

                  {/* Preview Body Text Message */}
                  <div
                    className={clsx(
                      "m-3",
                      template?.id && template.id !== "" ? "" : "display-none"
                    )}
                    dangerouslySetInnerHTML={{
                      // __html: replacePreviewBody(previewBody),
                      __html: convertTextStyle(previewBody),
                    }}
                  ></div>
                  {/* Preview Body Text Message */}

                  {/* Footer Preview */}
                  {template &&
                    template.id !== "" &&
                    template.footer &&
                    template.footer !== "" && (
                      <div
                        className={clsx("m-3", "textBold")}
                        style={{ color: "gray" }}
                        dangerouslySetInnerHTML={{
                          __html: template.footer,
                        }}
                      ></div>
                    )}
                </div>

                {/* Cta Campaign Preview */}
                {campaignButtons !== undefined &&
                  campaignButtons.length > 0 &&
                  campaignButtons.map((cta) => (
                    <React.Fragment key={cta.index}>
                      {cta.type === "PHONE_NUMBER" && (
                        <ButtonCtaPhoneNum titleButtonPhoneNumber={cta.text} />
                      )}
                      {cta.type === "URL" && (
                        <ButtonCtaWebsiteLink
                          titleButtonVisitWebsite={cta.text}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default React.memo(CampaignTemplate);
