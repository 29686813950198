import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import firebase from "firebase/compat/app";
import { createRef } from "../../../../../db/connection";
import * as templateServ from "../../../../../db/serviceTemplate";
import { ID, Response } from "../../../../../resources/helpers";
import Template from "../../../../models/Template";
import { UserCompanies } from "../../../../models/User";

import { LCName, getItemLC } from "../../../../modules/localstorage";
import * as lc from "../../../../modules/localstorage/index";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`

/***
 * Get Data Customer In Queue
 */
const getTemplates = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<Template>> => {
  console.log("Template - item : " + limit);
  console.log("Template - action : " + action);
  console.log("Template - sort : " + sort);
  console.log("Template - order : " + order);
  console.log("Template - search : " + search);

  let sortBy: string = "id";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
  }

  const client = getItemLC(lc.LCName.Client);
  const clientref = createRef("clients", client?.id);
  console.log("Template - Client ref : " + clientref);
  console.log("Template - Client id : " + client.id);

  const companyAccesses: UserCompanies[] =
    lc.getItemLC(lc.LCName.CompanyList) ?? [];
  if (companyAccesses.length <= 0) return [];

  let templates = (
    await templateServ.getAccessibleTemplate(client.id, companyAccesses)
  ).filter((template) => {
    // if (template.updatedAt !== undefined) {
    //   template.updatedAt = format(
    //     new Date(template.updatedAt?.seconds! * 1000),
    //     "d MMM yyyy, h:mm aaa"
    //   );
    // }
    console.log(`filter template`);
    return !template.isTemplateRegistrationConfirmation;
  });
  console.log(templates, "data template wxx")

  return Promise.resolve(templates);
};

/***
 * End Of Get Data Customer In Queue
 */

const getTemplateById = (id: ID): Promise<Template | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Template>>) => response.data)
    .then((response: Response<Template>) => response.data);
};

const deleteTemplate = (templateId: ID): Promise<void> => {
  if (templateId === undefined || templateId === null) {
  } else {
    console.log("Delete id : " + templateId);
    templateServ.deleteTemplatebyId(templateId.toString());
  }

  return Promise.resolve();
};

export { getTemplateById, getTemplates, deleteTemplate };
