import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../../../../styles/components/MultiSelectWithCheckbox";
import Select from "../../../../../styles/components/Select";
import styles from './Filter.module.css';
import * as lc from '../../../../../app/modules/localstorage/index';
import * as Account from "../../../../../actions/account";
import moment from "moment-timezone";

interface IOptions {
    label: string;
    value: string;
}

interface IProps {
    onChangeStartDate: (selectedStartDate: string) => void;
    onChangeEndDate: (selectedEndDate: string) => void;
    onChangeGranularity: (selectedGranularity: string) => void;
    onChangePhoneNumber: (selectedPhoneNumber: string[]) => void;
    onChangeCategories: (selectedCategories: string[]) => void;
}

const FilterCard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [phoneNumberOptions, setPhoneNumberOptions] = useState<IOptions[]>([]);

    const AllOptionPhoneNumbers: IOptions[] = [{ label: "All Phone Numbers", value: "*" }, ...phoneNumberOptions];

    const timeOptions: IOptions[] = [
        { label: t('Home.TimeSelections.Monthly'), value: "MONTHLY" },
        { label: t('Home.TimeSelections.Daily'), value: "DAILY" },
        { label: t('Home.TimeSelections.Hourly'), value: "HOURLY" },
    ];

    const conversationCategories: IOptions[] = [
        { label: t('Home.ConversationCategories.AllCategories'), value: "*" },
        { label: t('Home.ConversationCategories.Authenticating'), value: "authentication" },
        { label: t('Home.ConversationCategories.Marketing'), value: "marketing" },
        { label: t('Home.ConversationCategories.Utility'), value: "utility" },
        { label: t('Home.ConversationCategories.Service'), value: "service" },
    ]

    const conversationTypes: IOptions[] = [
        { label: t('Home.ConversationType.ConversationAnalytic'), value: "*" },
    ]

    const defaultStartDateValue = moment().format('YYYY-MM-DD');
    const defaultEndDateValue = moment().format('YYYY-MM-DD');
    const [selectedConversationCategory, setSelectedConversationCategory] = useState<IOptions[]>([]);
    const [selectedConversationPhoneNumbers, setSelectedConversationPhoneNumbers] = useState<IOptions[]>([]);
    const [selectedConversationGranularity, setSelectedConversationGranularity] = useState<string>("HOURLY");
    const [selectedConversationStartDate, setSelectedConversationStartDate] = useState<string>(defaultStartDateValue)
    const [selectedConversationEndDate, setSelectedConversationEndDate] = useState<string>(defaultEndDateValue);
    const [errorMessageInputDateRange, setErrorMessageInputDateRange] = useState<string>('');
    const [errorMessageInputPhoneNumber, setErrorMessageInputPhoneNumber] = useState<string>('');
    const [errorMessageInputCategory, setErrorMessageInputCategory] = useState<string>('');

    const startDateChangeHandler = (startDate: string) => {
        // const parsedStartDate = moment(startDate).format('YYYY-MM-DD 00:00:00');
        setSelectedConversationStartDate(startDate);
    }
    const endDateChangeHandler = (endDate: string) => {
        // const parsedEndDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
        setSelectedConversationEndDate(endDate)
    }
    const granularityChangeHandler = (selectedGranularity: string) => {
        setSelectedConversationGranularity(selectedGranularity)
    }
    const phoneNumberChangeHandler = (selectedPhoneNumbers: IOptions[]) => {
        setSelectedConversationPhoneNumbers(selectedPhoneNumbers)
    };
    const categoryChangeHandler = (selectedCategories: IOptions[]) => {
        setSelectedConversationCategory(selectedCategories);
    }
    const granularityDateRangeValidateHandler = (granularity: string, startDate: string, endDate: string): boolean => {
        const startDateObj = moment(startDate);
        const endDateObj = moment(endDate).add('23' , 'hours');

        // Check if the date is valid
        if (!startDateObj.isValid() || !endDateObj.isValid()) {
            setErrorMessageInputDateRange(t('Home.Validation.InvalidDateFormat'));
            return false;
        }

        // Check if the start date is not higher than end date
        if (startDateObj.unix() > endDateObj.unix()) {
            setErrorMessageInputDateRange(t('Home.Validation.StartDateMustNotHigherThanEndDate'));
            return false;
        }

        if (granularity === 'DAILY') {
            // Check if the start date is not same with end date
            // if (startDateObj.format('YYYYMMDD') === endDateObj.format('YYYYMMDD')) {
                // setErrorMessageInputDateRange(t('Home.Validation.StartDateMustBeDifferentWhenUsingDailyGranularity'));
                // return false
            // }

            // Check if the start date is not more than 60 days from end date
            if (startDateObj.unix() < endDateObj.subtract(60, 'days').unix()) {
                setErrorMessageInputDateRange(t('Home.Validation.DifferentDateMustNotMoreThan60Days'));
                return false
            }
        }

        else if (granularity === 'MONTHLY') {
            // Check if the start date is the first day of a month
            // if (!(startDateObj.format('DD') === '01')) {
            //     setErrorMessageInputDateRange(t('Home.Validation.StartDateMustBeTheFirstDayOfAMonth'));
            //     return false;
            // }

            // // Check if the end date is on or after the first day of the next month
            // if (!(endDateObj.format('DD') === '01')) {
            //     setErrorMessageInputDateRange(t('Home.Validation.EndDateMustBeTheFirstDayOfAMonth'));
            //     return false;
            // }

            // Check if the start date is not more than 24 months from end date
            if (startDateObj.unix() < endDateObj.subtract(24, 'months').unix()) {
                setErrorMessageInputDateRange(t('Home.Validation.DifferentDateMustNotMoreThan24Months'));
                return false;
            }
        }

        else if (granularity === 'HOURLY') {
            // Check if the start date is not more than 7 days from end date
            if (startDateObj.unix() < endDateObj.subtract(7, 'days').unix()) {
                setErrorMessageInputDateRange(t('Home.Validation.DifferentDateMustNotMoreThan7Days'));
                return false;
            }
        }

        // If No conditions are met, the date range is valid
        setErrorMessageInputDateRange('');
        return true
    }

    const phoneNumberValidateHandler = (selectedPhoneNumbers: IOptions[]): boolean => {
        if (selectedPhoneNumbers.length === 0) {
            setErrorMessageInputPhoneNumber(t('Home.Validation.PhoneNumberMustBeSelected'));
            return false;
        }
        setErrorMessageInputPhoneNumber('');
        return true;
    }
    
    const categoryValidateHandler = (selectedCategories: IOptions[]): boolean => {
        if (selectedCategories.length === 0) {
            setErrorMessageInputCategory(t('Home.Validation.CategoryMustBeSelected'));
            return false;
        }
        setErrorMessageInputCategory('');
        return true;
    }

    // Side Effect For Initializing Default Value
    useEffect(() => {
        setSelectedConversationCategory(conversationCategories);
        setSelectedConversationPhoneNumbers(AllOptionPhoneNumbers);
    }, []);
    
    // Side Effect For Fetching Phone Number Options
    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientRef = lc.getClientRef();

                if (!clientRef) {
                    console.warn('No client reference found.');
                    return;
                }

                const account = await Account.fetchWAByClientChannel(clientRef);

                if (!account) {
                    console.warn('No account data found.');
                    return;
                }

                const phoneNumbers = account
                    .filter((acc:any) => acc.whatsappNumber)
                    .map((acc:any) => ({
                        label: acc.whatsappNumber,
                        value: acc.whatsappNumber,
                    }));

                setPhoneNumberOptions(phoneNumbers);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    // Side Effect For Handling Input Change By User
    useEffect(() => {
        const isDateValid = granularityDateRangeValidateHandler(
            selectedConversationGranularity, 
            selectedConversationStartDate, 
            selectedConversationEndDate
        );
        const isPhoneNumberValid = phoneNumberValidateHandler(selectedConversationPhoneNumbers);
        const isCategoryValid = categoryValidateHandler(selectedConversationCategory);

        if (!isDateValid || !isPhoneNumberValid || !isCategoryValid) return;

        props.onChangeGranularity(selectedConversationGranularity);
        props.onChangeStartDate(selectedConversationStartDate);
        props.onChangeEndDate(selectedConversationEndDate);

        // Handling Submit Phone Number to Home Page To Prevent Phone Number With Options All Submitted When All Phone Number Is Selected
        const selectedPhoneNumbers = selectedConversationPhoneNumbers.filter(ctg => ctg.value != '*').map((ctg) => ctg.value);
        if (selectedPhoneNumbers.length === 1 && selectedPhoneNumbers[0] === '*') {
            const allPhoneNumbers = phoneNumberOptions.filter(ctg => ctg.value != '*').map((ctg) => ctg.value);
            props.onChangePhoneNumber(allPhoneNumbers);
        } else {
            props.onChangePhoneNumber(selectedConversationPhoneNumbers.filter(ctg => ctg.value != '*').map((ctg) => ctg.value));
        }
    
        // Handling Submit Categories to Home Page To Prevent Categories With Options All Submitted When All Category Is Selected
        if (selectedConversationCategory.length === 1 && selectedConversationCategory[0].value === '*') {
            const allCategories = conversationCategories.filter(ctg => ctg.value != '*').map((ctg) => ctg.value)
            props.onChangeCategories(allCategories);
        } else {
            const selectedCategories = selectedConversationCategory.filter(ctg => ctg.value != '*').map((ctg) => ctg.value)
            props.onChangeCategories(selectedCategories);
        }
    }, [selectedConversationStartDate, selectedConversationEndDate, selectedConversationGranularity, selectedConversationCategory, selectedConversationPhoneNumbers])


    return (
        <Fragment>
            <div className="container p-5">
                <div className="row py-4">
                    <div className="col-lg-4 col-6 p-lg-3 p-1">
                        <div className="form-group" data-testid="select-time-selection">
                            <label className={styles['form-label']}>{t('Home.TimeSelections')}<span className="text-danger">*</span></label>
                            <Select
                                data-testid="inputTimeSelections"
                                name="time"
                                options={timeOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={'Time Selections'}
                                onChange={(event: any) => granularityChangeHandler(event.value)}
                                value={
                                    timeOptions.filter(option =>
                                        option.value === selectedConversationGranularity)
                                }
                            />
                        </div>
                    </div>

                    {AllOptionPhoneNumbers.length > 1 && (
                        <div className="col-lg-4 col-6 p-lg-3 p-1">
                            <div className="form-group" data-testid="multiselect-phone-numbers">
                                <label className={styles['form-label']}>{t('Common.Column.PhoneNumber')}</label>
                                <MultiSelect
                                    data-testid="inputPhoneNumber"
                                    className="d-flex align-items-center form-control border-2 px-0 py-0"
                                    options={AllOptionPhoneNumbers}
                                    defaultValue={AllOptionPhoneNumbers}
                                    allOptionValue={AllOptionPhoneNumbers[0].value}
                                    onChange={(event) => phoneNumberChangeHandler(event)}
                                    components={{ Option }}
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    data-role="status-select"
                                />
                                {errorMessageInputPhoneNumber != '' && (<span className="text-danger">{errorMessageInputPhoneNumber}</span>)}
                            </div>
                        </div>
                    )}

                    <div className="col-lg-4 col-6 p-lg-3 p-1 d-none d-lg-block">
                        <div className="form-group">
                            <label className={styles['form-label']}></label>
                            <Select
                                data-testid="inputConversationType"
                                name="type"
                                options={conversationTypes}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={'Conversation Analytics'}
                                onChange={(event: any) => {
                                }}
                                value={
                                    conversationTypes.filter(option =>
                                        option.value === conversationTypes[0].value)
                                }
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 col-6 p-lg-3 p-1">
                        <div className="row px-3">
                            <div className="col-6 p-0">
                                <div className="form-group">
                                    <label className={styles['form-label']}>{t('Home.Start')}<span className="text-danger">*</span></label>
                                    <input
                                        onChange={(event) => startDateChangeHandler(event.target.value)}
                                        id="inputStartDate"
                                        type="date"
                                        className="form-control form-control-lg"
                                        name="startDate"
                                        defaultValue={selectedConversationStartDate}
                                        data-testid="start-date"
                                        role="start-date"
                                    />
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <div className="form-group">
                                    <label className={styles['form-label']}>{t('Home.End')}<span className="text-danger">*</span></label>
                                    <input
                                        onChange={(event) => endDateChangeHandler(event.target.value)}
                                        id="inputEndDate"
                                        type="date"
                                        className="form-control form-control-lg"
                                        name="endDate"
                                        defaultValue={selectedConversationEndDate}
                                        data-testid="end-date"
                                        role="end-date"
                                    />
                                </div>
                            </div>
                            {errorMessageInputDateRange != '' && (<div data-testid="invalid-date"><span className="text-danger">{errorMessageInputDateRange}</span></div>)}
                        </div>
                    </div>

                    <div className="col-lg-4 col-6 p-lg-3 p-1">
                        <div className="form-group" data-testid="multiselect-categories">
                            <label className={styles['form-label']}>{t('Home.ConversationCategories')}</label>

                            <MultiSelect
                                data-testid="status-selections"
                                className="d-flex align-items-center form-control border-2 px-0 py-0"
                                options={conversationCategories}
                                defaultValue={conversationCategories}
                                allOptionValue={conversationCategories[0].value}
                                onChange={(value) => categoryChangeHandler(value)}
                                components={{ Option }}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                data-role="category-select"
                            />
                            {errorMessageInputCategory != '' && (<span className="text-danger">{errorMessageInputCategory}</span>)}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >)
}

export default FilterCard;
