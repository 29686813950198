import clsx from "clsx";
import "../css/borderdashed.css"
import {IOption, productMediaUpload as MEDIA_TYPE, formatSize, productMediaCheckFile } from "../../app/modules/util/Utils"

import UploadImageThumbnail from "../../styles/components/UploadImageThumbnail";
import { ChangeEvent, useState } from "react";
import { getImageSize } from "react-image-size";

export interface IError {
  isValidPhotoFileFormat?:boolean, isValidPhotoFileSize?:boolean, isMaximumPhotos?:boolean , isValidPhoto?:boolean
}

// {"url":"blob:http://localhost:3000/b0e8c59c-107f-4389-99ba-ecf0340927a8","size":"2.99 KB","type":"image/png","extension":"png","name":"600px_X-trans.png"}
export interface IProductMediaUpload { 
  url: string,
  size: string,
  type: string,
  extension: string,
  name: string,  
}

interface ButtonProps {
  /**
   * base color of the button with hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbs?: 'white' | 'primary' | 'light' | 'secondary' | 'success' | 'info' | 'warning' |  'danger' | 'dark';
  /**
   * light style color of the button with hover, defined with $theme-light-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnlg?: 'primary' | 'success' | 'info' | 'warning' |  'danger' | 'dark' | 'light-primary' | 'light-success' | 'light-info' | 'light-warning' | 'light-danger' | 'light-dark';
  /**
   * background style color of the button without hover, defined with $theme-colors mapped in src/assets/sass/core/components/_variables.scss
   */
  btnbg?: 'white' | 'primary' | 'light' | 'secondary' | 'success' | 'info' | 'warning' |  'danger' | 'dark';
  /**
   * className to be added to the button
   */
  cName?: string;
  /**
   * className to be added to the button
   */
  biIcon?: string;
  /**
   * props for the button
   */
  isMobile?: boolean;
  onChangeFn?: any;
  onFinishFn: (media?: IProductMediaUpload, error?: IError) => void;
  onRemoveFn: (index?:number, error?: IError, url?:string) => void;
  index?: string | number;
  isHidden?: boolean;
  isVideoButton?: boolean;
  isMultipleUpload?: boolean;
  idRadioButton?:string;
  checked?: boolean;
  isInvalidUpload?: boolean;
  refId?: string;
  mediaType? : string;
  accept?: string;
  options? : IOption;
  /**
   * props for the button
   */
  [propName: string]: any;
  
}

/**
 * Primary UI component for user interaction
 */
function ButtonUploadMedia({ 
  btnbs,
  btnlg,
  btnbg,
  cName,
  biIcon,
  isMobile,
  onChangeFn,
  index,
  mediaType="image",
  onFinishFn,
  onRemoveFn,
  isHidden,
  isVideoButton,
  isMultipleUpload,
  idRadioButton,
  checked,
  isInvalidUpload,
  refId,
  accept,
  options,
  ...props
}: ButtonProps) {

  const [ media, setMedia] = useState<IProductMediaUpload>()
  // const [isInvalidPhotoFileFormat, setInvalidPhotoFileFormat] = useState<boolean>(false);
  // const [isInvalidPhotoFileSize, setInvalidPhotoFileSize] =  useState<boolean>(false);
  const [isMaximumPhotos, setMaximumPhotos] = useState<boolean>(false);

  const onAttachmentChange = async (event: ChangeEvent<HTMLInputElement> ) => {

    if (event.target.files && event.target.files.length > 0) {
      

      let productPhotoLength = event.target.files.length;
      console.log("Check 1 : " + event.target.files.length);
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];

        console.log(
          `Size: ${element.size} 
           Name : ${element.name}
           type : ${element.type}
          `   
         );

        productPhotoLength++;
        if (mediaType === MEDIA_TYPE.image && productPhotoLength > 5) {
          console.log("Masuk Maximmum");
          setMaximumPhotos(true);
          continue;
        }

        if (!element) continue;
        
        //Check File Type
        const size: number = element.size;
        const tempArrFileType: string = element.type;
        const arryFileType = tempArrFileType.split("/");
        const fileName = element.name;

        if (
          arryFileType === undefined ||
          arryFileType.length < 1 ||
          arryFileType[0] === undefined
        ) {
          //return error
          console.log("Media - Error File Invalid Format");
          // setInvalidPhotoFileFormat(true);
          onFinishFn(undefined, {isValidPhotoFileFormat: true})
          return;
        } 

        // const [result, error] = checkFile2("image","jpg",5);
        const URLObj = URL.createObjectURL(element);
        let photosWidth: number = 0;
        let photosHeight: number = 0;
        
        console.log(`. w ${mediaType} ${MEDIA_TYPE.image} `)
        if (mediaType === MEDIA_TYPE.image) {
          
          console.log(`.5 w`)
          const { width, height } = await getImageSize(URLObj);
          photosWidth = width;
          photosHeight = height;

          console.log(`w ${width} ${height}`)
        }
          console.log(`2 w ${photosWidth} h ${photosHeight}`)

        const [result, error, maxSize] = productMediaCheckFile(
          arryFileType[0],
          arryFileType[1],
          size,
          photosHeight,
          photosWidth,
          options,
        );

        if (error !== undefined) {
          if (maxSize !== 0) {
            console.log(
              "Media - Error Process Validate Maxsize: " + maxSize
            );
            if (result === MEDIA_TYPE.image) {
              // setInvalidPhotoFileSize(true);
              onFinishFn(undefined, {isValidPhotoFileSize: true})
            }
            return;
          } else {
            console.log("Media -  Process Validate File : " + error);
            if (result === MEDIA_TYPE.image) {
              // setInvalidPhotoFileFormat(true);
              onFinishFn(undefined, {isValidPhotoFileFormat: true})
            }
          }
          return;
        }

        if (!result || result === "") {
          console.log("Media - Error File Invalid Format");
          if (result === MEDIA_TYPE.image) {
            // setInvalidPhotoFileFormat(true);
            onFinishFn(undefined, {isValidPhotoFileFormat: true})
          }
          return;
        } 

        let newFile:IProductMediaUpload = {
          url: URLObj,
          size: formatSize(size),
          type: tempArrFileType,
          extension: arryFileType[1],
          name: fileName,
        };
        if (result === MEDIA_TYPE.image) {
          // dispatch(ReduxProduct.addProductPhotos(newFile));
          setMedia(newFile);
          onFinishFn(newFile);
          // saveFileToRef(index, newFile);
          // console.log(`url dari REF ${arrInputRefImage[index].current.url}`);
          // console.log(`berhasil ${JSON.stringify(newFile)}`)
        } else {
          console.log("Media - Error File Invalid Format");
          if (result === MEDIA_TYPE.image) {
            onFinishFn(undefined, {isValidPhotoFileFormat: true})
            // setInvalidPhotoFileFormat(true);
            console.log(`gagal ${result}`)
          }
        }
        
      }
    }
  }

  const removeProductPhotos = (index:any) => { 
    console.log(`removeProductPhotos index ${index}`);
    onRemoveFn(index);
    setMedia(undefined);
  }

  const removePhotos = (index:any ,url:string|undefined) => {
    console.log(`removePhotos url ${url}`);
    onRemoveFn(index, undefined, url)
  }

  const render = () => {
    return (
      <div
        className={clsx(isMobile ? "col" : "col-sm")}
        style={{
          position: "sticky",
          marginLeft: "1%",
          height: isMobile ? "70px" : "100px",
        }}
        key={`photos-${index ?? "0"}`}
      >
        <UploadImageThumbnail
          idRadioButton={`image-${index}`}
          isMobile={isMobile}
          cName={
            "bi bi-card-image " +
            (isMobile ? "display-2" : "display-3")
          }
          datatestid={`image-${index ?? 0}`}
          imgSrc={media?.url}
          isVideoButton={false}
          onClickFn={() => {
            removeProductPhotos(index);
            removePhotos(index,media?.url)
            // resetUploadProductMedia(
            //   productMediaUpload.image,
            //   0
            // );
          }}
          // onChecked={() => onSelectedImageAsCover(index)}
          // checked={
          //   rd_PhotosCoverIndex === index ? true : false
          // }
          isHasCover={false}
        />
      </div> 
    )
  }

  return (<>
    { media && render() }
    { !media && <div>
      <label
        className={clsx("d-flex cursor-pointer",isMobile ? "button-dashed-mobile" : "button-dashed-desktop ", isHidden ? "d-none" : "", isVideoButton && !isMobile ? "ms-7" : "")}
        style={{ borderColor: isInvalidUpload ? "red" : "#787889", justifyContent: "center", alignItems: "center" }}
        data-testid="button-upload"
      >
        <i className={clsx(cName)}
        style={{ color: "#787889" }}/>
        <input
          accept={accept}
          ref={refId}
          id="chat-media"
          onChange={onAttachmentChange}
          type="file"
          name="attachment"
          multiple={isVideoButton ? false : true}
          className="d-none"
        />
        {props.children}
      </label>
    </div>
    }
  </>);
}

export default ButtonUploadMedia;
