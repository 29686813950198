import React from "react";
import { useMediaQuery } from "react-responsive";
import { KTCard } from "../../../../../../resources/helpers";
import User from "../../../../../models/User";
import MemberStatusFilter from "./components/MemberStatusFilter";
import TableHeader from "./components/TableHeader";
import { TableProvider } from "./context/TableContext";
import MemberPagination from "./components/pagination/MemberPagination";
import MemberTable from "./components/table/MemberTable";
import MemberCard from "./components/card/MemberCard";

const MemberList = () => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  console.log("test workspace");
  return (
    <>
      <TableProvider<User> collection="users">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
          }}
        >
          <div className="card py-2">
            <MemberStatusFilter />
          </div>
          <div className="card py-2 my-2 bg-transparent" />
          <div
            style={{
              height: "100%",
              overflow: "hidden",
            }}
            className="card p-3"
          >
            <TableHeader />

            {isMobileView && <MemberCard />}
            {!isMobileView && <MemberTable />}

            <MemberPagination />
          </div>
        </div>

        {/* <OrderModal />
        <ModalExportOrder /> */}
      </TableProvider>
    </>
  );
}

export default MemberList;