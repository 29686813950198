import { FC } from "react";

type Props = {
  accountName?: string;
};

const CIQCAccountCell: FC<Props> = ({ accountName }) => {
  return <p className="p-0 m-0">{accountName}</p>;
};

export { CIQCAccountCell };
