import { FC } from "react";
import {
  getIconChannelUrl,
  toAbsoluteUrl,
} from "../../../../../../resources/helpers";

type Props = {
  channel?: string;
};

const CIQChannelCell: FC<Props> = ({ channel }) => {
  if (channel) {
    return (
      <span className="symbol symbol-30px text-align-center d-flex align-items-center justify-content-center">
        <img
          className="symbol-label"
          alt={channel}
          src={toAbsoluteUrl(getIconChannelUrl(channel!.toLowerCase()))}
          style={{ backgroundColor: "#FFFFFF", objectFit: "cover" }}
        />
        <p className="px-3 p-0 m-0 text-center h-auto">{channel}</p>
      </span>
    );
  } else {
    return (
      <div className="text-gray-650">{channel}</div> //text-hover-primary
    );
  }
};

export { CIQChannelCell };

// import {FC} from 'react'

// type Props = {
//   email?: string
// }

// const CIQEmailCell: FC<Props> = ({email}) => (
//   // <div className='badge badge-light fw-bolder'>{email}</div>
//   <div className='text-gray-650 mb-1'>{email}</div>
// )

// export {CIQEmailCell}
