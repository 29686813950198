import {KTSVG} from '../../../../../../resources/helpers/components/KTSVG'
import {useListView} from '../../../../core/list/ListViewProvider'
import {TemplateFilter} from './TemplateFilter'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { templateState } from '../../../../../models/Template';
import * as ReduxTemplate from "../../../../../modules/template/redux/TemplateSlice";
import { useDispatch } from 'react-redux';
import * as ReduxCampaign from "../../../../../modules/campaign/redux/CampaignSlice";
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

const TemplateToolbar = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div
      className={clsx(
        `d-flex justify-content-end`,
        isTabletOrMobile ? "flex-column gap-5 w-100" : ""
      )}
      data-kt-user-table-toolbar="base"
    >
      {/* <TemplateFilter /> */}

      {/* begin::Filter */}
      <button type="button" className="btn btn-light-primary me-3" disabled>
        <span className="bi bi-funnel-fill" />
        {t("Common.Filter")}
      </button>
      {/* end::Filter */}

      {/* begin::Handle Selected */}
      <Link
        to="/template/create"
        type="button"
        className="btn btn-primary"
        // onClick={async () => await deleteSelectedItems.mutateAsync()}
        onClick={() => {
          dispatch(ReduxTemplate.cleanReduxTemplate(true));
          dispatch(ReduxCampaign.cleanReduxCampaign(true));
          dispatch(ReduxTemplate.setTemplateID(""));
          dispatch(ReduxTemplate.setTemplateState(templateState.created));
          dispatch(ReduxCampaign.setCampaignTemplateFinish(false));
        }}
      >
        <span>
          <i className="bi bi-plus-lg fs-4 me-2"></i>{t("Campaign.Info.CreateTemplate")}</span>
      </Link>
      {/* end::Handle */}

      {/* {selected.length > 0 ? 
      <button
        type='button'
        className='btn btn-primary'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {t("CIQ.Button.DeleteSelected")}
      </button> : <button
        type='button'
        className='btn btn-danger' disabled
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {t("CIQ.Button.HandleSelected")}
      </button>} */}
    </div>
  );
}

export {TemplateToolbar}
