import clsx from 'clsx';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../styles/components/Button';
import { OrderExportHeaderTitle } from '../../../modules/order/orderHeader/OrderExportHeaderTitle';
import { PageTitle, PageLink } from '../../core';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IExportOrderResponse } from '../../../../api/server/order';

const OrderExportInfo: FC<any> = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const nav = useNavigate();

  // console.log()
  console.log(`location.state `, JSON.stringify(location.state));
   
  const state: IExportOrderResponse = location.state as IExportOrderResponse ?? {}
  const urlResponse = state.response
  console.log(`response `, JSON.stringify(urlResponse));

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Menu.Order"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const handleDownloadResultExportOrder = () => {
    if(!urlResponse) return alert(`Error no URL Response`);

    const link = document.createElement("a")
    link.setAttribute("href", urlResponse)
    link.setAttribute("download", "Test.csv")
    link.click()
  }

  return (
    <>
    <PageTitle breadcrumbs={usersBreadcrumbs}>{t("ExportOrder.Title")}</PageTitle>

    <div
      data-testid="export-order-info"
      className="d-flex flex-column flex-lg-column h-100"        
    >
      {/* {!isTabletOrMobile &&
          OrderExportHeaderTitle("Parameter for Export Order")} */}
      <div
        className="d-flex flex-column flex-lg-column"
        style={{ minHeight: "92.5%" }}
      >
        <div
          // className="overflow-hidden card mb-1 mb-xl-2 p-7 h-100"
          className={clsx("overflow-hidden card h-100", {"p-7": !isTabletOrMobile}
          )}
        >
          <div
            style={{
              height: "90%",
              // isSummary ? "" : "90%",
            }}
            className={clsx(
              `d-flex flex-column ${
                isTabletOrMobile && "p-5"
              }`
            )}
          >
            <div
                className={clsx(
                // "flex-lg-column-fluid tab-content h-100",
                "flex-lg-column-fluid tab-content p-5 h-100",
                {
                  "overflow-auto": !isTabletOrMobile
                }
                )}
              >
              <p style={{ fontSize: '14px' }}>{t("ExportOrder.ParameterExportOrder.Info")}</p>
              <table className="table">
                <thead className="thead-dark text-center">
                  <tr>
                    <th>{t("ExportOrder.Table.Header.Parameter")}</th>
                    <th>{t("ExportOrder.Table.Header.Description")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{`{{ordersn}}`}</td>
                    <td>{t("ExportOrder.Table.Description.OrderSN")}</td>
                  </tr>
                  <tr>
                    <td>{`{{buyerUsername}}`}</td>
                    <td>{t("ExportOrder.Table.Description.BuyerUserName")}</td>
                  </tr>
                  <tr>
                    <td>{`{{buyerName}}`}</td>
                    <td>{t("ExportOrder.Table.Description.BuyerName")}</td>
                  </tr>
                  <tr>
                    <td>{`{{fullAddress}}`}</td>
                    <td>{t("ExportOrder.Table.Description.FullAddress")}</td>
                  </tr>
                  <tr>
                    <td>{`{{district}}`}</td>
                    <td>{t("ExportOrder.Table.Description.District")}</td>
                  </tr>
                  <tr>
                    <td>{`{{city}}`}</td>
                    <td>{t("ExportOrder.Table.Description.City")}</td>
                  </tr>
                  <tr>
                    <td>{`{{postalCode}}`}</td>
                    <td>{t("ExportOrder.Table.Description.PostalCode")}</td>
                  </tr>
                  <tr>
                    <td>{`{{state}}`}</td>
                    <td>{t("ExportOrder.Table.Description.State")}</td>
                  </tr>
                  <tr>
                    <td>{`{{country}}`}</td>
                    <td>{t("ExportOrder.Table.Description.Country")}</td>
                  </tr>
                  <tr>
                    <td>{`{{marketplaceLogisticChannelName}}`}</td>
                    <td>{t("ExportOrder.Table.Description.MarketplaceLogisticChannelName")}</td>
                  </tr>
                  <tr>
                    <td>{`{{marketplaceLogisticChannelParent}}`}</td>
                    <td>{t("ExportOrder.Table.Description.marketplaceLogisticChannelParent")}</td>
                  </tr>
                  <tr>
                    <td>{`{{shippingTrackingNumber}}`}</td>
                    <td>{t("ExportOrder.Table.Description.ShippingTrackingNumber")}</td>
                  </tr>
                  <tr>
                    <td>{`{{shippingLabel}}`}</td>
                    <td>{t("ExportOrder.Table.Description.ShippingLabel")}</td>
                  </tr>
                  <tr>
                    <td>{`{{shippingWHIndex0}} & {{shippingWHIndex1}}`}</td>
                    <td>{t("ExportOrder.Table.Description.shippingWHIndex")}</td>
                  </tr>
                  <tr>
                    <td>{`{{SKU}}`}</td>
                    <td>{t("ExportOrder.Table.Description.SKU")}</td>
                  </tr>
                  <tr>
                    <td>{`{{parentSKU}}`}</td>
                    <td>{t("ExportOrder.Table.Description.ParentSKU")}</td>
                  </tr>
                  <tr>
                    <td>{`{{variantSKU}}`}</td>
                    <td>{t("ExportOrder.Table.Description.VariantSKU")}</td>
                  </tr>
                  <tr>
                    <td>{`{{qtyOrderItem}}`}</td>
                    <td>{t("ExportOrder.Table.Description.qtyOrderItem")}</td>
                  </tr>
                  <tr>
                    <td>{`{{discountedPrice}}`}</td>
                    <td>{t("ExportOrder.Table.Description.discountedPrice")}</td>
                  </tr>
                  <tr>
                    <td>{`{{originalPrice}}`}</td>
                    <td>{t("ExportOrder.Table.Description.OriginalPrice")}</td>
                  </tr>
                  <tr>
                    <td>{`{{cod}}`}</td>
                    <td>{t("ExportOrder.Table.Description.COD")}</td>
                  </tr>
                  <tr>
                    <td>{`{{codAmount}}`}</td>
                    <td>{t("ExportOrder.Table.Description.CODAmount")}</td>
                  </tr>
                  <tr>
                    <td>{`{{shipByDate<dd-MM-yyyy>}}`}</td>
                    <td>{t("ExportOrder.Table.Description.ShipByDate")}</td>
                  </tr>
                  <tr>
                    <td>{`{{marketplaceOrderCreatedAt<dd-MM-yyyy>}}`}</td>
                    <td>{t("ExportOrder.Table.Description.MarketplaceOrderCreated")}</td>
                  </tr>
                </tbody>
              </table>
              <p style={{ fontSize: '14px' }}><strong>{t("ExportOrder.Example")}</strong></p>
              <p style={{ fontSize: '14px' }}>{t("ExportOrder.Example.Info")}</p>
              <div style={{ overflowX: 'auto' }}>
                <img src={i18n.language === "id" ? "/media/images/export_order_format_info_id.png" : "/media/images/export_order_format_info_en.png"} alt="" height={clsx({"150px": !isTabletOrMobile, "140px": isTabletOrMobile})} />
              </div>
            </div>  
          </div>
          <div
            className={clsx(
              `d-flex bg-white justify-content-start mt-auto ${
                isTabletOrMobile ? "mb-5" : ""
              }`
            )}
          >
            <Link to="/order/export">
              <Button data-testid="btnBack" btnbs="primary" type="button">
                Back
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default OrderExportInfo;