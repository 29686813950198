import { Modal, Offcanvas } from "react-bootstrap";
import { KTCard } from "../../../../../../../resources/helpers";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import db, { createRef } from "../../../../../../../db";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import {
  requestConnectShopeeURL,
  requestLazadaChatURL,
  requestLazadaURL,
} from "../../../../../../../api/server/marketplace";
import { getAccountByID } from "../../../../../../../db/serviceAccount";

const ModalFailRecon: FC<{
  isOpen?: boolean;
  onClose?: () => void;
  data?: any;
}> = ({ isOpen, onClose, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const closeUpdate = async () => {
    onClose && onClose();
  };

  let connected_erp = false;
  let connected_chat = false;
  let expired_accesstoken_erp = false;
  let expired_accesstoken_chat = false;
  if (data !== null) {
    if (data.type === "shopee") {
      const shopee_exp = data[`${data.type}_expiredIn`];
      const shopee_expMilliseconds =
        shopee_exp?.seconds * 1000 + shopee_exp?.nanoseconds / 1000000;
      const shopee_expDate = new Date(shopee_expMilliseconds);
      const currentDate = new Date();
      connected_erp = true;
      connected_chat = false;
      if (shopee_expDate < currentDate) {
        expired_accesstoken_erp = true;
        expired_accesstoken_chat = true;
      }
    }
    if (data.type === "tokopedia") {
      const tokopedia_exp = data[`${data.type}_accessTokenExpired`];
      const tokopedia_expMilliseconds =
        tokopedia_exp?.seconds * 1000 + tokopedia_exp?.nanoseconds / 1000000;
      const tokopedia_expDate = new Date(tokopedia_expMilliseconds);
      const currentDate = new Date();
      connected_erp = true;
      connected_chat = true;
      if (tokopedia_expDate < currentDate) {
        expired_accesstoken_erp = true;
        expired_accesstoken_chat = true;
      }
    }
    if (data.type === "lazada") {
      const lazerp_exp = data?.credentials?.erp?.accessTokenExpired;
      const lazerp_expMilliseconds =
        lazerp_exp?.seconds * 1000 + lazerp_exp?.nanoseconds / 1000000;
      const lazerp_expDate = new Date(lazerp_expMilliseconds);
      const lazchat_exp = data?.credentials?.chat?.accessTokenExpired;
      const lazchat_expMilliseconds =
        lazchat_exp?.seconds * 1000 + lazchat_exp?.nanoseconds / 1000000;
      const lazchat_expDate = new Date(lazchat_expMilliseconds);
      const currentDate = new Date();
      if (!!lazchat_expDate) {
        connected_chat = true;
      }
      if (!!lazerp_expDate) {
        connected_erp = true;
      }
      if (lazerp_expDate < currentDate) {
        expired_accesstoken_erp = true;
      }
      if (lazchat_expDate < currentDate) {
        expired_accesstoken_chat = true;
      }
    }
  }

  const connectHandler = async (data: any) => {
    if (data !== null) {
      let reconnect = "";
      if (connected_chat === true && expired_accesstoken_chat === true) {
        reconnect = "chat";
      }
      if (connected_erp === true && expired_accesstoken_erp === true) {
        reconnect = "erp";
      }

      let settingsArray: firebase.firestore.DocumentData[] = [];
      await db
        .collection("settings")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let datadoc = doc.data();
            datadoc["id"] = doc.id;
            settingsArray.push(datadoc);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      if (settingsArray?.length > 0) {
        if (data?.type === "shopee") {
          if (reconnect === "chat") {
            const shopee_chat_parnerID = settingsArray?.find(
              (item) => item.id === "Shopee_Chat"
            )?.partnerId;
            const shopee_chat_parnerKey = settingsArray?.find(
              (item) => item.id === "Shopee_Chat"
            )?.partnerKey;
            const urlFromServer = await requestConnectShopeeURL(
              shopee_chat_parnerID,
              shopee_chat_parnerKey,
              data?.id
            );
            const callbackURL = urlFromServer?.url;
            if (callbackURL) {
              window.open(callbackURL, "_blank");
            }
          } else {
            const shopee_erp_parnerID = settingsArray?.find(
              (item) => item.id === "Shopee"
            )?.partnerId;
            const shopee_erp_parnerKey = settingsArray?.find(
              (item) => item.id === "Shopee"
            )?.partnerKey;
            const urlFromServer = await requestConnectShopeeURL(
              shopee_erp_parnerID,
              shopee_erp_parnerKey,
              data?.id
            );
            const callbackURL = urlFromServer?.url;
            if (callbackURL) {
              window.open(callbackURL, "_blank");
            }
          }
        }
        if (data?.type === "lazada") {
          if (reconnect === "chat") {
            const urlFromServer = await requestLazadaChatURL("Lazada");
            const callbackURL = urlFromServer?.login_url;
            if (callbackURL) {
              window.open(callbackURL, "_blank");
            }
          } else {
            const urlFromServer = await requestLazadaURL("Lazada");
            const callbackURL = urlFromServer?.login_url;
            if (callbackURL) {
              window.open(callbackURL, "_blank");
            }
          }
        }
        if (data?.type === "tokopedia") {
          alert("Tokopedia ERP & Chat Service not available now");
        }
      }
    }
  };
  return (
    <>
      {isOpen && (
        <>
          {isTabletOrMobile ? (
            <Offcanvas
              show={isOpen}
              onHide={async () => {
                closeUpdate();
              }}
              placement="bottom"
              style={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
              className="h-auto pb-20"
            >
              <Offcanvas.Header closeButton className="border-bottom ms-5 me-5">
                <Offcanvas.Title data-testid="fs-2 pt-2">
                  {t("Marketplace.Modal.HowToAuth.Title")}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="h-auto">
                <KTCard>
                  <div className="pt-5 pb-20 pe-5">
                    <h5 className="ps-4 mb-5">
                      {t("Marketplace.Modal.HowToAuth.Body.Follow")}
                    </h5>
                    <ol className="fs-6">
                      <li>
                        {t("Marketplace.Modal.HowToAuth.Body.One.One")}
                        {/* {idAccount} */}
                        {t("Marketplace.Modal.HowToAuth.Body.One.Two")}
                      </li>
                      <li>{t("Marketplace.Modal.HowToAuth.Body.Two")}</li>
                      <li>
                        {t("Marketplace.Modal.HowToAuth.Body.Three")}
                        {/* {idAccount} */}
                      </li>
                      <li>{t("Marketplace.Modal.HowToAuth.Body.Four")}</li>
                    </ol>
                  </div>
                </KTCard>
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <>
              <Modal
                animation
                centered
                show={isOpen}
                // onHide={() => onClose && onClose()}
                onHide={async () => {
                  closeUpdate();
                }}
                className="md"
                scrollable={false}
              >
                <Modal.Header closeButton className="bg-light">
                  <Modal.Title data-testid="modal-title">
                    Failed to Reconnect
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                  <KTCard className="bg-white pt-10 pb-10 ps-5 pe-5">
                    <p>
                      Reconnecting has failed because you are logged in using
                      the wrong account. Please use the account {data?.name} to
                      continue reconnecting
                    </p>
                  </KTCard>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                  <button
                    type="button"
                    className="btn btn-primary w-25 text-center"
                    onClick={async () => {
                      // closeUpdate();
                      connectHandler(data);
                    }}
                  >
                    Reconnect
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ModalFailRecon;
