import clsx from "clsx";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KTCard, toAbsoluteUrl } from "../../../../resources/helpers";
import { RootState } from "../../../../setup/redux/store";
import { KeyValues } from "../../../../util/Formatter";
import { convertTextStyle } from "../../../../util/TextFormatter";
import { excerpt } from "../../../../util/excerpt";
import TemplateLanguage from "../../../models/Language";
import { InteractiveButton } from "../../../modules/template/redux/TemplateRedux";
import { Offcanvas } from "react-bootstrap";

interface Props {
  template?: TemplateLanguage;
  previewHeaderText?: any;
  previewBody?: any;
  chatHeader?: {
    background: string;
    color: string;
    avatar?: string;
    accountName: string;
  };
}

interface ButtonCTAProps {
  usedFor: string;
  title: string;
  width?: string;
}

const ButtonCTA: React.FC<ButtonCTAProps> = ({ usedFor, title, width }) => {
  return (
    <div
      role="button"
      className="d-flex text-center mt-1 search-toolbar w-75 text-center p-3 cl-chat-whatsapp w-full"
      style={{
        alignContent: "center",
        justifyContent: "center",
        maxWidth: width,
      }}
    >
      <div className="d-flex" style={{ alignItems: "center" }}>
        {usedFor === "PHONE_NUMBER" && (
          <i
            data-testid="preview-cta-pn"
            className="bi bi-telephone-fill mr-4"
            style={{ color: "#00a5f4" }}
          ></i>
        )}
        {usedFor === "URL" && (
          <i
            data-testid="preview-cta-url"
            className="fas fa-external-link-alt mr-4"
            style={{ color: "#00a5f4" }}
          ></i>
        )}
        {usedFor === "COPY_CODE" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#00a5f4"
            className="bi bi-copy mr-4"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
            />
          </svg>
        )}
        {usedFor === "SEE_ALL" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 56 56"
          >
            <path
              fill="#00a5f4"
              fill-rule="evenodd"
              d="M10 36a3 3 0 1 1 0 6a3 3 0 0 1 0-6m35.998 1c1.106 0 2.002.888 2.002 2c0 1.105-.89 2-2.002 2H18.002A1.996 1.996 0 0 1 16 39c0-1.105.89-2 2.002-2zM10 26a3 3 0 1 1 0 6a3 3 0 0 1 0-6m35.998 1c1.106 0 2.002.888 2.002 2c0 1.105-.89 2-2.002 2H18.002A1.996 1.996 0 0 1 16 29c0-1.105.89-2 2.002-2zM10 16a3 3 0 1 1 0 6a3 3 0 0 1 0-6m35.998 1c1.106 0 2.002.888 2.002 2c0 1.105-.89 2-2.002 2H18.002A1.996 1.996 0 0 1 16 19c0-1.105.89-2 2.002-2z"
            />
          </svg>
        )}
        <strong
          data-testid="preview-cta"
          style={{ marginLeft: "10px", color: "#00a5f4" }}
        >
          {title}
        </strong>
      </div>
    </div>
  );
};

const ButtonQuickReply: React.FC<{
  reduxQuickReply: string[];
  className?: string;
  width?: string;
}> = ({ reduxQuickReply, className, width }) => {
  const isAllFull = getIsAllFull(reduxQuickReply);
  const firstFullElementIndex = reduxQuickReply.findIndex(
    (text) => text.length > 10
  );

  return (
    <div
      className={`mt-1 ${className || ""}`}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "4px",
        maxWidth: width,
      }}
    >
      {reduxQuickReply.map((text, i) => {
        let isFull = text.length > 10;
        if (
          reduxQuickReply.length > 2 &&
          i === reduxQuickReply.length - 1 &&
          firstFullElementIndex !== 0
        ) {
          isFull = true;
        }
        if (text) {
          return (
            <div
              key={i}
              role="button"
              className="d-flex align-items-center justify-content-center search-toolbar text-center p-3 cl-chat-whatsapp"
              style={{
                gridColumn:
                  isFull || isAllFull ? "span 2 / span 2" : "span 1 / span 1",
              }}
            >
              <strong
                data-testid="preview-cta-qr"
                className="px-2"
                style={{ color: "#00a5f4" }}
              >
                {text}
              </strong>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const PreviewMessage: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  // previewBody: campaignPreviewBody,
  previewHeaderText,
  template,
  className,
  chatHeader,
}) => {
  const testingTemplate = useSelector((state: RootState) => state.Template);
  const testingstate = useSelector((state: RootState) => state.Template);

  // Redux
  const reduxPreviewHeaderText = useSelector(
    (state: RootState) => state?.Template.templatePreviewHeaderText
  );
  const reduxHeaderFile = useSelector(
    (state: RootState) => state?.Template.templateHeaderFile
  );
  const reduxHeaderURL = useSelector(
    (state: RootState) => state?.Template.templateHeaderURL
  );
  const reduxFileType = useSelector(
    (state: RootState) => state?.Template.templateFileType
  );
  const reduxFooter = useSelector(
    (state: RootState) => state?.Template.templateFooter
  );
  const reduxButtonCta = useSelector(
    (state: RootState) => state?.Template.templateButton
  );
  const reduxQuickReply = useSelector(
    (state: RootState) => state?.Template.templateQuickReplies
  );
  const previewBody = useSelector(
    (state: RootState) => state?.Template.templatePreviewBody
  );
  const selectedButton = useSelector(
    (state: RootState) => state?.Template.selectedInteractiveButton
  );


  //check isTabletOrMobile
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  // State
  const [readMore, setReadMore] = useState(false);
  const [displayedBody, setDisplayedBody] = useState("");

  // 768 is default text lenght determined by whatsapp to show ...Read more
  const READMORE_LEN = 768;

  // Use Effect for Re-assign Body Message Value & track Read More status
  useEffect(() => {
    const previewBodyString = //campaignPreviewBody ? campaignPreviewBody :
      previewBody;
    if (previewBodyString.length > READMORE_LEN) {
      const body = excerpt(previewBodyString, READMORE_LEN);
      setDisplayedBody(body);
    } else {
      setDisplayedBody(previewBodyString);
    }
    setReadMore(previewBodyString.length > READMORE_LEN);
  }, [previewBody]); //, campaignPreviewBody]);

  const ReadMore = () => {
    const showReadMore = () => {
      setReadMore(false);
      // if (campaignPreviewBody) {
      //   setDisplayedBody(campaignPreviewBody);
      // } else {
      setDisplayedBody(previewBody);
      // }
    };

    if (!readMore) return <></>;

    return (
      <div
        role="button"
        onClick={showReadMore}
        style={{
          color: "#00a5f4",
          textDecoration: "underline",
          display: "inline",
          cursor: "pointer",
        }}
      >
        {t("Common.ReadMore")}
      </div>
    );
  };

  let combinedButtons = [
    ...reduxButtonCta.map((cta) => ({ ...cta, source: "cta" })),
  ];
  const renderButtons = () => {
    let combinedButtons = [
      ...reduxButtonCta.map((cta) => ({ ...cta, source: "cta" })),
    ];

    let displayedButtons = combinedButtons?.slice(0, 2);

    return (
      <>
        {displayedButtons.map((button, i) => (
          <React.Fragment key={i.toString()}>
            {button.source === "cta" && button.type === "PHONE_NUMBER" && (
              <ButtonCTA
                key={`cta-phone-${i}`}
                usedFor="PHONE_NUMBER"
                title={button.text}
              />
            )}
            {button.source === "cta" && button.type === "URL" && (
              <ButtonCTA
                key={`cta-url-${i}`}
                usedFor="URL"
                title={button.text}
              />
            )}
            {button.source === "cta" && button.type === "OTP" && (
              <ButtonCTA
                key={`cta-otp-${i}`}
                usedFor="COPY_CODE"
                title={button.text}
              />
            )}
            {button.source === "cta" && button.type === "QUICK_REPLY" && (
              <ButtonQuickReply
                key={`quickreply-${i}`}
                reduxQuickReply={[button.text]} // Pass single button as array for consistent API
                className="w-75"
                // width="75%"
              />
            )}
          </React.Fragment>
        ))}
        {combinedButtons.length > 2 && (
          <ButtonCTA
            key="see-all-options"
            usedFor="SEE_ALL"
            title="See All Options"
          />
        )}
      </>
    );
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const onClose = () => setModalOpen(false);
  const closeUpdate = async () => {
    onClose && onClose();
  };

  return (
    <div
      className={clsx(`${className}`, {
        "col-12 mt-10": isTabletOrMobile,
        "col-4": !isTabletOrMobile && !className, //todo remove
      })}
    >
      <div className="mb-10 h-100">
        <div className="d-flex flex-row align-items-center justify-content-start mb-5">
          <div className="step-sub-title me-3 p-0 m-0">
            {t("Campaign.Title.Preview")}
          </div>
          {combinedButtons?.length > 2 && (
            <div className="px-2 py-0 btn btn-light-primary border border-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 448 512"
                className="cursor-pointer"
                onClick={() => {
                  setModalOpen(!modalOpen);
                }}
              >
                <path
                  fill="currentColor"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                />
              </svg>
            </div>
          )}
        </div>
        {chatHeader ? (
          <div
            style={{
              width: "100%",
              height: "3.5rem",
              backgroundColor: chatHeader.background,
              color: chatHeader.color,
            }}
            className="d-flex align-items-center justify-content-between"
            data-testid="header-whatsapp-chat"
          >
            <div className="d-flex align-items-center gap-1">
              <i
                style={{ color: chatHeader.color, fontSize: "2rem" }}
                className="bi bi-arrow-left-short"
              ></i>
              <div className="symbol symbol-30px me-1">
                <img
                  id="active_user_avatar"
                  style={{ borderRadius: "50%" }}
                  data-testid="CustomHeaderClientAvatarImage"
                  src={toAbsoluteUrl(
                    chatHeader?.avatar || "/media/icons/avatar/client-icon.png"
                  )}
                  alt="avatar"
                />
              </div>
              <span style={{ fontSize: "1rem", fontWeight: 600 }}>
                {chatHeader.accountName}
              </span>
            </div>

            <div className="d-flex align-items-center gap-1">
              <i
                style={{ color: chatHeader.color, fontSize: "1.25rem" }}
                className="bi bi-camera-video-fill me-3"
              ></i>
              <i
                style={{ color: chatHeader.color, fontSize: "1.25rem" }}
                className="bi bi-telephone-fill me-2"
              ></i>
              <i
                style={{ color: chatHeader.color, fontSize: "1.25rem" }}
                className="bi bi-three-dots-vertical me-2"
              ></i>
            </div>
          </div>
        ) : null}
        <div
          className="p-5 overflow-hidden position-relative w-100"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/misc/wa-bg-review.png"
            )})`,
            maxWidth: "500px",
            minHeight: isTabletOrMobile ? "300px" : "400px",
          }}
          data-testid="preview-header-document"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          {!template ? (
            <div>
              <div
                data-testid="preview-message"
                className={clsx(
                  "d-flex flex-column flex-column-fluid cl-chat-whatsapp p-2 search-toolbar",
                  {
                    "d-none":
                      !reduxHeaderURL &&
                      !reduxHeaderFile &&
                      !reduxPreviewHeaderText &&
                      !displayedBody &&
                      !reduxFooter,
                  }
                )}
                style={{ maxWidth: "75%" }}
              >
                {(reduxHeaderURL !== "" || !reduxHeaderFile) &&
                  reduxFileType === "image" && (
                    <div
                      className={clsx("mw-70 search-toolbar", {
                        "wrapper mb-3": reduxHeaderURL !== "",
                        "d-none": !reduxFileType,
                      })}
                      style={{
                        backgroundImage: `url(${reduxHeaderURL})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        paddingLeft: 100,
                      }}
                      data-testid="preview-header-image"
                    ></div>
                  )}

                {(reduxHeaderURL !== "" || !reduxHeaderFile) &&
                  reduxFileType === "video" && (
                    <video
                      className={clsx("mb-3", {
                        "d-none": reduxHeaderURL === "",
                      })}
                      src={reduxHeaderURL}
                      style={{ borderRadius: "8px" }}
                      data-testid="preview-header-video"
                    ></video>
                  )}

                {reduxHeaderFile && (
                  <div
                    className={clsx(
                      "wrapper search-toolbar mb-3 bi bi-file-earmark fs-5x cl-graysilver",
                      { "d-none": reduxHeaderURL === "" }
                    )}
                    style={{
                      paddingTop: "0%",
                      paddingLeft: "40%",
                    }}
                    data-testid="preview-header-document"
                  ></div>
                )}

                {reduxPreviewHeaderText !== "" && (
                  <div
                    className="textBold"
                    style={{ fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{
                      __html: reduxPreviewHeaderText,
                    }}
                  ></div>
                )}

                <div
                  data-testid="preview-body"
                  className={clsx({ "d-none": !displayedBody })}
                  dangerouslySetInnerHTML={{
                    __html: displayedBody,
                  }}
                ></div>
                <ReadMore />

                {reduxFooter !== "" && (
                  <div
                    data-testid="preview-footer"
                    className={clsx("mt-3 textBold")}
                    style={{ color: "gray" }}
                    dangerouslySetInnerHTML={{
                      __html: reduxFooter,
                    }}
                  ></div>
                )}
              </div>

              {selectedButton === InteractiveButton.CallToAction &&
                renderButtons()}
              {(selectedButton === InteractiveButton.QuickReply ||
                selectedButton === InteractiveButton.OTP) &&
                renderButtons()}
              {selectedButton === InteractiveButton.Multiple && renderButtons()}
            </div>
          ) : null}

          {template && (
            <div>
              <div
                className={clsx(
                  "d-flex flex-column cl-chat-whatsapp m-1 search-toolbar mw-75"
                )}
              >
                {/* Preview Header */}
                {/* Header Text */}
                {previewHeaderText && previewHeaderText !== "" && (
                  <div
                    className="m-3 step-sub-title"
                    dangerouslySetInnerHTML={{
                      __html: previewHeaderText,
                    }}
                  ></div>
                  // >{headerText}</div>
                )}

                {!previewHeaderText &&
                  template.header?.type === "text" &&
                  template.header?.text && (
                    <div
                      className={clsx(
                        "m-3 fw-bolder",
                        template?.id && template.id !== "" ? "" : "d-none"
                      )}
                      dangerouslySetInnerHTML={{
                        __html: convertTextStyle(
                          replacePreviewHeader(template)
                        ),
                      }}
                    ></div>
                  )}

                {/* Header Document, Video, Image */}
                {/* <div>${JSON.stringify(headerAttachObj)}</div> */}
                {template !== undefined &&
                  template.header?.type === "media" &&
                  template.header.mediaType === "document" && (
                    <div className="d-flex flex-fill bg-secondary m-3 search-toolbar mw-80 h-auto align-items-start image-input">
                      <div className="w-30 m-2 align-self-center">
                        <i className="bi bi-file-earmark-text fs-3x text-dark"></i>
                      </div>
                      <div className="flex-grow-1 m-2 align-self-center">
                        {template.header.fileName ||
                          template.header.filename ||
                          ""}
                      </div>
                      <div className="w-30 m-5 align-self-center">
                        <i className="bi bi-download fs-1 text-dark"></i>
                      </div>
                    </div>
                  )}

                {template &&
                  template.header?.type === "media" &&
                  template.header.mediaType === "image" &&
                  template.header.headerURL !== undefined && (
                    <div
                      className="wrapper mw-100 search-toolbar m-3"
                      style={{
                        backgroundImage: `url(${template.header.headerURL})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      data-testid="preview-header-image"
                    ></div>
                  )}
                {template &&
                  template.header?.type === "media" &&
                  template.header.mediaType === "video" &&
                  template.header.headerURL !== undefined && (
                    <div className="p-3">
                      <video
                        controls
                        className={clsx("mw-100")}
                        src={template.header.headerURL}
                        style={{ borderRadius: "8px" }}
                        data-testid="preview-header-video"
                      ></video>
                    </div>
                  )}
                {template &&
                  template.header?.type === "media" &&
                  template.header.mediaType !== "document" &&
                  template.header.headerURL === undefined && (
                    <div className="d-flex flex-column bg-secondary m-3 search-toolbar mw-80 h-auto align-items-center image-input">
                      <i
                        className={clsx(
                          "fs-5x text-dark",
                          template.header.mediaType === "image"
                            ? "bi bi-card-image"
                            : "bi-play-btn-fill"
                        )}
                      ></i>
                    </div>
                  )}

                {/* Preview Body Text Message */}
                <div
                  className={clsx(
                    "m-3",
                    template?.id && template.id !== "" ? "" : "d-none"
                  )}
                  dangerouslySetInnerHTML={{
                    // __html: replacePreviewBody(previewBody),
                    __html: convertTextStyle(replacePreviewBody(template)),
                  }}
                ></div>
                {/* Preview Body Text Message */}

                {/* Footer Preview */}
                {template &&
                  template.id !== "" &&
                  template.footer &&
                  template.footer !== "" && (
                    <div
                      className={clsx("m-3", "textBold")}
                      style={{ color: "gray" }}
                      dangerouslySetInnerHTML={{
                        __html: template.footer,
                      }}
                    ></div>
                  )}
              </div>

              {/* {template.buttons &&
              template.buttons.length > 0 &&
              (template.buttons[0].type === "QUICK_REPLY" ||
                template.buttons[0].type === "OTP") ? (
                <ButtonQuickReply
                  reduxQuickReply={template.buttons.map((btn) => btn.text)}
                />
              ) : null}

              {template.buttons &&
              template.buttons.length > 0 &&
              (template.buttons[0].type === "QUICK_REPLY" ||
                template.buttons[0].type === "OTP")
                ? template.buttons.map((btn, i) => (
                    <React.Fragment key={i.toString()}>
                      {btn.type === "PHONE_NUMBER" && (
                        <ButtonCTA
                          usedFor="PHONE_NUMBER"
                          title={btn.text}
                          width="70%"
                        />
                      )}
                      {btn.type === "URL" && (
                        <ButtonCTA usedFor="URL" title={btn.text} width="70%" />
                      )}
                      {btn.type === "OTP" && (
                        <ButtonCTA
                          usedFor="COPY_CODE"
                          title={btn.text}
                          width="70%"
                        />
                      )}
                    </React.Fragment>
                  ))
                : null} */}

              {selectedButton === InteractiveButton.CallToAction &&
                renderButtons()}
              {(selectedButton === InteractiveButton.QuickReply ||
                selectedButton === InteractiveButton.OTP) &&
                renderButtons()}
              {selectedButton === InteractiveButton.Multiple && renderButtons()}
            </div>
          )}

          {modalOpen && (
            <div
              className="position-absolute w-100 h-auto bg-white shadow-md border border-gray"
              style={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                bottom: 0,
                right: 0,
              }}
            >
              <div className="d-flex flex-row-reverse m-0 p-0">
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  <div className="w-60px border-5 border-bottom border-secondary mb-2 mt-5 rounded"></div>
                </div>
              </div>
              <div className=" d-flex flex-row m-0 p-0 py-2 mb-2 mx-3 position-relative">
                <div className=" w-100 p-0 m-0 d-flex flex-row align-items-center justify-content-center">
                  <p className="fs-5 p-0 m-0 fw-bolder">All Options</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 1216 1312"
                  className="position-absolute my-1 cursor-pointer"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  <path
                    fill="currentColor"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68z"
                  />
                </svg>
              </div>
              <div className="pt-0 pb-20 px-4">
                {combinedButtons &&
                  combinedButtons.map((item) => {
                    return (
                      <div className="d-flex flex-row align-items-center justfy-content-center m-0 p-0 mb-4">
                        {item.type !== "QUICK_REPLY" && (
                          <div className="p-0 m-0 pe-2">
                            {item.type === "PHONE_NUMBER" && (
                              <i
                                data-testid="preview-cta-pn"
                                className="bi bi-telephone-fill mr-4"
                                style={{ fontSize: "16px" }}
                              ></i>
                            )}
                            {item.type === "URL" && (
                              <i
                                data-testid="preview-cta-url"
                                className="fas fa-external-link-alt mr-4"
                                style={{ fontSize: "16px" }}
                              ></i>
                            )}
                            {item.type === "OTP" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                style={{ height: "16px" }}
                                // fill="#00a5f4"
                                className="bi bi-copy mr-4"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                />
                              </svg>
                            )}
                          </div>
                        )}
                        <p className="p-0 m-0">{item.text}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Preview Saved Template
const replacePreviewBody = (template: TemplateLanguage) => {
  let tempBody = "";

  if (template) {
    if (template.body) {
      tempBody = template.body.replaceAll("\n", "<br/>");
      // tempBody = template.body;
    }

    if (template.bodyParamCount) {
      template.bodyParam?.forEach((param, idx) => {
        const replaceIdx: string = `{{${idx + 1}}}`;

        const changedParam = { ...param };

        if (
          Object.keys(KeyValues).includes(changedParam.paramKey!) &&
          changedParam.paramKey !== `customText${idx + 1}`
        ) {
          tempBody = tempBody.replace(
            replaceIdx,
            `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>
              ${
                // changedParam.textContent && changedParam.textContent !== ""
                //   ? changedParam.textContent
                //   : t("Campaign.Input." + changedParam.paramKey)
                t("Campaign.Input." + changedParam.paramKey)
              }
            </span>`
          );
        } else {
          if (
            changedParam.paramKey === `customText${idx + 1}` &&
            (!changedParam.textContent || changedParam.textContent === "")
          ) {
            tempBody = tempBody.replace(
              replaceIdx,
              `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                "Campaign.Input.CustomizeText"
              )}</span>`
            );
          } else {
            // console.log("Ini custom check 2 >> "+changedParam.textContent);
            const newLine = changedParam.textContent?.includes("\n")
              ? "<br/>"
              : "";
            const newTextContent = changedParam.textContent?.replaceAll(
              "\n",
              "<br/>"
            );
            tempBody = tempBody.replace(
              replaceIdx,
              `${newLine}<span class="badge badge-primary fw-bolder fs-7 m-1 text-wrap mw-100" style="text-align: start;"><i class="bi bi-server pe-2 text-white w-100"></i>${newTextContent}</span>`
            );
          }
        }
      });
    }
  }

  return tempBody;
};

function replacePreviewHeader(template: TemplateLanguage) {
  let result = "";

  if (template.header && template.header.type === "text") {
    if (template.header.text) {
      result = template.header.text.replaceAll("\n", "<br/>");
    }

    if (template.header.paramCount && template.header.headerParam) {
      template.header.headerParam.forEach((param, idx) => {
        const replaceIdx: string = `{{${idx + 1}}}`;

        const changedParam = { ...param };

        if (
          Object.keys(KeyValues).includes(changedParam.paramKey!) &&
          changedParam.paramKey !== `customText${idx + 1}`
        ) {
          result = result.replace(
            replaceIdx,
            `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
              "Campaign.Input." + changedParam.paramKey
            )}</span>`
          );
        } else {
          if (
            changedParam.paramKey === `customText${idx + 1}` &&
            (!changedParam.textContent || changedParam.textContent === "")
          ) {
            result = result.replace(
              replaceIdx,
              `<span class="badge badge-primary fw-bolder fs-7 m-1"><i class="bi bi-server pe-2 text-white"></i>${t(
                "Campaign.Input.CustomizeText"
              )}</span>`
            );
          } else {
            // console.log("Ini custom check 2 >> "+changedParam.textContent);
            const newLine = changedParam.textContent?.includes("\n")
              ? "<br/>"
              : "";
            const newTextContent = changedParam.textContent?.replaceAll(
              "\n",
              "<br/>"
            );
            result = result.replace(
              replaceIdx,
              `${newLine}<span class="badge badge-primary fw-bolder fs-7 m-1 text-wrap mw-100" style="text-align: start;"><i class="bi bi-server pe-2 text-white w-100"></i>${newTextContent}</span>`
            );
          }
        }
      });
    }
  }

  return result;
}

const getIsAllFull = (reduxQuickReply: string[]) => {
  let allFull = false;
  const lastIndex = reduxQuickReply.length - 1;
  if (reduxQuickReply.length < 2) {
    allFull = true;
  }
  if (reduxQuickReply.length <= 2) {
    if (
      reduxQuickReply[0].length > 10 ||
      reduxQuickReply[lastIndex].length > 10
    ) {
      allFull = true;
    }
  }

  if (
    reduxQuickReply[0].length > 10 &&
    reduxQuickReply[lastIndex].length > 10
  ) {
    allFull = true;
  }

  const firstFullElementIndex = reduxQuickReply.findIndex(
    (text) => text.length > 10
  );
  const allFullElement = reduxQuickReply.filter((text) => text.length > 10);
  if (
    firstFullElementIndex > 0 &&
    firstFullElementIndex !== 0 &&
    firstFullElementIndex !== lastIndex
  ) {
    allFull = true;
  }
  if (
    firstFullElementIndex === 0 &&
    reduxQuickReply[lastIndex].length <= 10 &&
    allFullElement.length > 1
  ) {
    allFull = true;
  }

  return allFull;
};

export default PreviewMessage;
