
import Storefront from "../../../../../models/Storefront";

const sliceData = (
  data: Storefront[],
  startIndex: number,
  endIndex: number
) => {
  let newDataStorefront: Storefront[] = [];
  for (let index = startIndex; index < endIndex; index++) {
    const datastorefront = data[index];
    newDataStorefront.push(datastorefront);
  }
  return newDataStorefront;
};

export const storefrontSorting = (data : Storefront[], 
  sort: string | undefined, 
  limit: number | undefined, 
  action: string | undefined , 
  orderBy: string | undefined,
  searchBy: string | undefined, 
  currentPage: number | undefined,
  callback: any) => {
  console.log("Masuk Storefront sorting >>>>> ");

  console.log("item : " + limit);
  console.log("action : " + action);
  console.log("sort : " + sort);
  console.log("order : " + orderBy);
  console.log("search : " + searchBy);
  console.log("current page : " + currentPage);

  if(currentPage === undefined)currentPage = 1;
  if(limit === undefined)limit = 10;
  if(orderBy === undefined) orderBy = "asc";
  if(searchBy === undefined) searchBy = ""; 
  if(sort === undefined || sort === 'deleted') sort = "createdAt";

  let dataStorefront: Storefront[] = [];

  if(data.length == 0){
    return dataStorefront;
  }

  for (let index = 0; index < data.length; index++) {
    dataStorefront.push(data[index]); 
  }

  //Search Data
  if(searchBy && searchBy !=null && searchBy!="" && dataStorefront.length>0){
    dataStorefront = dataStorefront.filter((data) => data.name?.toLowerCase().match(new RegExp(searchBy!.toLowerCase(), 'g')));
  }

  //sorting
  if(dataStorefront.length>0){
    if (sort === "name") {
      dataStorefront = dataStorefront?.sort((a, b) =>
        a.name!.toLowerCase() > b.name!.toLowerCase() ? 1 : -1
      );
      if (orderBy === "desc") {
        dataStorefront = dataStorefront?.sort((a, b) =>
          a.name!.toLowerCase() > b.name!.toLowerCase() ? -1 : 1
        );
      }
    }
    if (sort === "numberOfProducts") {
      dataStorefront = dataStorefront?.sort((a, b) =>
        a.numberOfProducts! > b.numberOfProducts! ? 1 : -1
      );
      if (orderBy === "desc") {
        dataStorefront = dataStorefront?.sort((a, b) =>
           a.numberOfProducts! > b.numberOfProducts! ? -1 : 1
        );
      }
    }
    if (sort === "status") {
      dataStorefront = dataStorefront?.sort((a, b) =>
      {
        if (!a.status || a.status === undefined) return 1;
        if (!b.status || b.status === undefined) return -1;
        return a.status.toLowerCase() > b.status.toLowerCase()? 1 : -1
      });
      if (orderBy === "desc") {
        dataStorefront = dataStorefront?.sort((a, b) =>{
          if (!a.status || a.status === undefined) return -1;
          if (!b.status || b.status === undefined) return 1;
          return a.status.toLowerCase() > b.status.toLowerCase() ? -1 : 1
      });
      }
    }
  }

  callback(dataStorefront.length);

  if(currentPage == 1){
    let endIndex = currentPage * limit;
    dataStorefront = Array.from(dataStorefront).slice(0,limit);
  }else{
    let endIndex = currentPage * limit;
    let startIndex = (endIndex - limit);
    console.log("Hasil slice data 2 : "+startIndex+" - "+endIndex);
    if(startIndex > dataStorefront.length){
      return [];
    }else{
      dataStorefront = Array.from(dataStorefront).slice(startIndex,endIndex);
    }
  }
  console.log("Hasil Length Data : "+dataStorefront.length);
  // console.log("Hasil Data : "+JSON.stringify(dataContact));
  console.log("Hasil Current Page : "+currentPage);
  return dataStorefront;
}