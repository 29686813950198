import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, { UpdateStorefront } from "./StorefrontRedux";

const initialState: InitialState = {
  accountID: "",
  isloading: false,
  storefrontID: "",
  storefrontName: "",
  storefrontNameLength: 0,
  lastStep: "",
  status: "",
  listMarketplace: [], //{marketplace: shopee, isChecked: true, selectedCategory: [[{id: 100000, level : 1, hasChildren : true}]]}
};

export const StorefrontSlice = createSlice({
  name: UpdateStorefront,
  initialState: initialState,
  reducers: {
    setStorefrontId: (state, action: PayloadAction<string>) => {
      state.storefrontID = action.payload;
    },
    setStorefrontName: (state, action: PayloadAction<string>) => {
      state.storefrontName = action.payload;
    },
    setStorefrontNameLength: (state, action: PayloadAction<any>) => {
      state.storefrontNameLength = action.payload;
    },
    setStorefrontListMarketplace: (state, action: PayloadAction<any>) => {
      state.listMarketplace = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setLastStep: (state, action: PayloadAction<string>) => {
      state.lastStep = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isloading = action.payload;
    },
    cleanReduxStorefront: (state, action: PayloadAction<boolean>) => {
      state.accountID = "";
      state.storefrontID = "";
      state.listMarketplace = [];
      state.storefrontName = "";
      state.storefrontNameLength = 0;
      state.isloading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setStorefrontId,
  setStorefrontName,
  setStorefrontNameLength,
  setStorefrontListMarketplace,
  setLastStep,
  setStatus,
  cleanReduxStorefront
} = StorefrontSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default StorefrontSlice.reducer;