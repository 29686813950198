import Product, {
  ProductVariants,
  Variant,
} from "../../../../../src/app/models/Product";
import {
  ShopeeProductCategoryAttributeList,
} from "../../../models/ShopeeProductCategoryAttribute";

interface InitialState {
  productID: string;
  productName: string;
  productNameLength: number;
  productSKU: string;
  productSKULength: number;
  selectedStoreFrontData: any;
  selectedBrandData: any;
  descriptionProduct: string;
  descriptionProductLength: number;
  storeFrontList?: any[];
  brandList?: any[];
  isAddStorefront: boolean;
  newProduct: Product;
  newBrand: string;
  productPhotos: any[];
  productVideo: any;
  coverIndex: number;
  videoYoutubeURL: string;
  productWeight: number;
  productDimensionLength: number;
  productDimensionWidth: number;
  productDimensionHeight: number;
  isDangerousProduct: string;
  shipments: any[];
  tempShipments: any[];
  companies: any[];
  marketplaces: any[];
  marketplaceCategoryList: any[];
  isProductHaveVariants: string;
  variants: Variant[];
  variantsType: [];
  selectedVariantsType: [];
  variantsMatrix: ProductVariants[];
  productVariantPhotos: any[];
  isFromSummary: boolean;
  tempVariants: Variant[];
  tempVariantsMatrix: ProductVariants[];
  tempProductVariantPhotos: any[];
  lastStep: string;
  tempSelectedStorefront: string;
  isCreateProduct: boolean;
  categoryAttributes: categoryAttributes;
  isGenerateCategoryAttributes: boolean;
}

export interface categoryAttributes {
  shopee: ShopeeProductCategoryAttributeList[];
}
const UpdateProduct: string = "UpdateProduct";

export default InitialState;

export { UpdateProduct };
