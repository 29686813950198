/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useMutation } from "react-query";
import { ID, KTSVG } from "../../../../../../resources/helpers";
import { deleteContact } from "../../core/_requests";
import { useTranslation } from "react-i18next";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Contact from "../../../../../../app/modules/contact/redux/ContactSlice";
import { useMediaQuery } from "react-responsive";

type ActionMenu = {
  label: string;
  type: "link" | "button";
  state? : { [key: string]: any};
  to?: string;
  onClick?: () => void;
}

const print = (action: ActionMenu, key: string) => {
  if (action.type === "link") {
    return (
      <Link to={`${action.to}`} 
            state = {action.state}
            className="dropdown-item btn btn-light btn-active-light-primary btn-sm" key={key}>
        {action.label}
      </Link>
    );
  } else {
    return (
      <button className="dropdown-item btn btn-light btn-active-light-primary btn-sm" 
              onClick={action.onClick} key={key}>
        {action.label}
      </button>
    );
  }
}

type Props = {
  id: ID;
  indexId: number;
  name?: string;
};

const ContactActionsCell: FC<Props> = ({ id, indexId, name }) => {
  const dispatch = useDispatch();
  
  const { t } = useTranslation();
  const { state, updateState } = useQueryRequest();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:  900px)" });
  
  const actions: ActionMenu[] = [
    {
      label: t("Common.Edit"),
      type: "link",
      to: `/contact/contact-detail/settings/${id}`,
      state: { id, name }
    }, 
    // {
    //   label: t("Common.Delete"),
    //   type: "button",
    //   onClick: () => {deleteItem.mutate()},
    // }
  ];

  const deleteItem = useMutation(() => deleteContact(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      dispatch(Contact.deleteItemListContact(id + ""));
      // ✅ update detail view directly
      // queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
      updateState({
        sort: state.sort === "delete" ? "asc" : "delete",
        items_per_page: state.items_per_page,
        page: 1,
        action: "noAction",
      });
      // updateState({sort: 'delete', items_per_page: state.items_per_page, page: 1, action: "noAction"})
    },
    onError(error, variables, context) {
      dispatch(Contact.deleteItemListContact(id + ""));
    },
  });

  return (
    <>
      <div className="dropdown">
        {isTabletOrMobile && (
          <button
            data-testid="dropdownMenuAction"
            type="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="btn btn-icon"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen053.svg"
              className="svg-icon-1 m-0"
            />
          </button>
        )}
        {!isTabletOrMobile && (
          <a
            className="btn btn-light btn-active-light-primary btn-sm p-2 m-1"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t("Common.Actions")}
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className="svg-icon-5 m-0"
            />
          </a>
        )}
        <div className="dropdown-menu">
          <h3 className="dropdown-header">{t("Common.Actions")}</h3>
          { actions.map((action, index) => print(action, `${index}`))} 
        </div>
        {/* not used
        <BroadcastModal
          customerId={id}
          indexId={indexId}
          onSuccessSubmit={onSuccessSubmit}
        /> */}
      </div>
    </>
  );
};

export { ContactActionsCell };

function updateState(arg0: {
  sort: string;
  items_per_page: any;
  page: number;
  action: string;
}) {
  throw new Error("Function not implemented.");
}
