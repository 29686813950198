/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useQueryResponseLoading } from "../../core/QueryResponseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useDispatch, useSelector } from "react-redux";
import { count } from "console";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { RootState } from "../../../../../../setup/redux/store";
import * as TemplateRedux from "../../../../../modules/template/list/TemplateListSlice";

const TemplatePagination = () => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  const isLoading = useQueryResponseLoading();
  const { state, updateState } = useQueryRequest();
  // let Contactcount: number = 0;
  // Contactcount = useSelector((state: RootState) => state.Contact.countContact);
  const count = useSelector(
    (state: RootState) => state.TemplateList.countTemplate
  );
  const arrayContactPagination = useSelector(
    (state: RootState) => state.TemplateList.arrayTemplatePagination
  );
  const [value, setValue] = useState(10);
  const [page, setPage] = useState(1);
  // const [arrPagination, setArrPagination] = useState<number[]>([]);
  const [totalItems, setTotalItems] = useState(10);
  const [defItemView, setDefItemView] = useState(10);
  const [minimumPage, setMinimumPage] = useState(1);
  const [pagination, setPagination] = useState<number[]>([1, 2, 3, 4, 5, 6, 7]);
  const [currentButton, setCurrentButton] = useState<string>("1");
  // const [maxPage, setMaxPage] = useState(10)

  useEffect(() => {
    setPage(1);
  }, [totalItems]);

  useEffect(() => {
    setPage(1);
  }, [state.sort, state.search]);

  let maxPage = Math.ceil(count / state.items_per_page);
  // console.log("max page ==>> " + maxPage);

  useEffect(() => {
    maxPage = Math.ceil(count / state.items_per_page);
    const numberOfPages: any = [];
    for (let i = 1; i <= maxPage; i++) {
      numberOfPages.push(i + "");
    }
    // console.log("Clicked Masuk Use Effect ");

    let tempNumberOfPages: string[] = [...arrayContactPagination];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numberOfPages.length < 6) {
      // [1,2,3,4,5,6]
      tempNumberOfPages = numberOfPages;
      MovePage(parseInt(currentButton));
    } else if (currentButton === dotsInitial) {
      //Action Selected Dot Initial
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      const value =
        parseInt(arrayContactPagination[arrayContactPagination.length - 3]) + 1;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (currentButton === dotsRight) {
      //Action Selected Dot Right
      const value = parseInt(arrayContactPagination[3]) + 2;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (currentButton === dotsLeft) {
      //Action Selected Dot Left
      const value = parseInt(arrayContactPagination[3]) - 2;
      setCurrentButton(value + "");
      MovePage(value);
    } else if (parseInt(currentButton) >= 1 && parseInt(currentButton) <= 3) {
      //[1, 2, 3, 4, "...", 10]
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
      MovePage(parseInt(currentButton));
    }
    // else if (parseInt(currentButton)  === 4) {//[1, 2, 3, 4, 5"...", 10]
    //   const sliced = numberOfPages.slice(0, 5);
    //   tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    //   MovePage(parseInt(currentButton));
    // }
    else if (
      parseInt(currentButton) >= 4 &&
      parseInt(currentButton) < numberOfPages.length - 2
    ) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numberOfPages.slice(
        parseInt(currentButton) - 2,
        parseInt(currentButton)
      ); // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numberOfPages.slice(
        parseInt(currentButton),
        parseInt(currentButton) + 1
      ); // sliced1 (5, 5+1) -> [6]
      tempNumberOfPages = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ]; // [1, '...', 4, 5, 6, '...', 10]
      MovePage(parseInt(currentButton));
    } else if (parseInt(currentButton) > numberOfPages.length - 3) {
      // > 7  // [1, '...', 6, 7, 8, 9, 10]
      const sliced = numberOfPages.slice(numberOfPages.length - 4); // slice(10-4)
      tempNumberOfPages = [1, dotsLeft, ...sliced];
      MovePage(parseInt(currentButton));
    }
    dispatch(TemplateRedux.setArrayTemplatePagination(tempNumberOfPages));
  }, [currentButton, count]);

  const updateTotalItem = (totalItem: number) => {
    setTotalItems(totalItem);
    if (count / totalItem <= 6) {
      let arr: string[] = [];
      for (let index = 1; index <= Math.ceil(count / totalItem); index++) {
        arr.push(index + "");
      }
      dispatch(TemplateRedux.setArrayTemplatePagination(arr));
    } else {
      dispatch(
        TemplateRedux.setArrayTemplatePagination([
          "1",
          "2",
          "3",
          "4",
          "...",
          Math.ceil(count / totalItem) + "",
        ])
      );
    }
    updateState({ items_per_page: totalItem, action: "noAction", page: 1 });
  };

  const PrevItemPage = () => {
    if (page <= 1) {
      return;
    }
    let pages = page - 1;
    setCurrentButton(pages + "");
    setPage(pages);
    updateState({ action: "prev", page: page, items_per_page: totalItems });
  };

  const NextItemPage = () => {
    if (page >= maxPage) {
      return;
    } else {
      let pages = page + 1;
      setCurrentButton(pages + "");
      setPage(pages);
      updateState({ action: "next", page: page, items_per_page: totalItems });
    }
  };

  const MovePage = (pageTo: number) => {
    setPage(pageTo);
    updateState({ page: pageTo, items_per_page: totalItems });
  };

  const updateCurrentButton = (index: string) => {
    console.log("Clicked Button :" + index);
    setCurrentButton(index);
  };

  return (
    <div
      className="row align-items-center justify-content-center py-5"
    >
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div
          v-if="enableItemsPerPageDropdown"
          className="dataTables_length flex-left"
          id="kt_customers_table_length"
        >
          {!isTabletOrMobile && (
            <label>
              <select
                name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                // value={totalItems}
                defaultValue={defItemView}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  // setTotalItems(parseInt(selectedValue));
                  updateTotalItem(parseInt(selectedValue));
                  setCurrentButton("1");
                }}
              >
                {/* {arrPagination.map((page) => {
                return (<option value={page}>{page}</option>);
              })} */}
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
                <option value={60}>60</option>
                <option value={70}>70</option>
                <option value={80}>80</option>
                <option value={90}>90</option>
                <option value={100}>100</option>
              </select>
            </label>
          )}
        </div>
      </div>
      <div className="py-0 my-0 col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {/* {pagination.links?.map((link) => (
              <li
                key={link.label}
                className={clsx("page-item", {
                  active: pagination.page === link.page,
                  disabled: isLoading,
                  previous: link.label === "&laquo; Previous",
                  next: link.label === "Next &raquo;",
                })}
              >
                <a
                  className="page-link"
                  onClick={() => updatePage(link.page)}
                  dangerouslySetInnerHTML={{ __html: link.label }}
                  style={{ cursor: "pointer" }}
                />
              </li>
            ))} */}

            {/* Button Previous */}
            {page <= 1 ? (
              <li className="page-item disabled py-0 my-0">
                <a className="page-link py-0 my-0" style={{ cursor: "pointer" }}>
                  « {t("Contacts.Pages.Previous")}
                </a>
              </li>
            ) : (
              <li className="page-item py-0 my-0">
                <a
                  className="page-link py-0 my-0"
                  style={{ cursor: "pointer" }}
                  onClick={PrevItemPage}
                >
                  « {t("Contacts.Pages.Previous")}
                </a>
              </li>
            )}
            {/* End Of Button Previous */}

            {/* Number Button */}
            {arrayContactPagination.map((i) => {
              if (i == page + "") {
                return (
                  <li key={i} className="page-item active py-0 my-0 ">
                    <p
                      className="page-link me-3 py-0 my-0"
                      key={i}
                      onClick={() => setCurrentButton(i)}
                    >
                      {i}
                    </p>
                  </li>
                );
              }
              return (
                <li key={i} className="page-item p- m-0 ">
                  <a
                    className="page-link me-3 py-0 my-0 "
                    style={{ cursor: "pointer" }}
                    onClick={() => updateCurrentButton(i)}
                  >
                    {i}
                  </a>
                  {/* <p className="page-link me-3 " key={i + 1}>
                    {i + 1}
                  </p> */}
                </li>
              );
            })}
            {/* End of Number Button */}

            {/* Next Button */}
            {page === Math.ceil(maxPage) ? (
              <li className="page-item disabled py-0 my-0 ">
                <a className="page-link py-0 my-0" style={{ cursor: "pointer" }}>
                  {t("Contacts.Pages.Next")} »
                </a>
              </li>
            ) : (
              <li className="page-item py-0 my-0">
                <a
                  className="page-link py-0 my-0"
                  style={{ cursor: "pointer" }}
                  onClick={NextItemPage}
                >
                  {t("Contacts.Pages.Next")} »
                </a>
              </li>
            )}
            {/* End Of Next Button */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { TemplatePagination };
