import React from "react";
import { KTSVG } from "../../../resources/helpers";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

interface ModalProps {
  id: string;
  isModalOpen?: boolean;
  setIsModalOpen?: any;
  title?: string;
  body?: any;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: any;
  onClose?: any;
}

const ModalDeleteSelected: React.FC<ModalProps> = ({
  id,
  isModalOpen = false,
  setIsModalOpen,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  ...props
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div
      {...props}
      hidden={!isModalOpen}
      className="h-100"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.15)",
      }}
    >
      <div id={id} className="h-100" style={{ overflow: "hidden" }}>
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ minWidth: isTabletOrMobile ? "" : "700px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                aria-label="Close"
                onClick={onClose}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body" style={{textAlign: "start"}}>
              {/* <p>{body}</p> */}
              {body}
            </div>

            {isTabletOrMobile ? (
              <div className={clsx("modal-footer")}>
                {cancelText ? (
                  <button
                    type="button"
                    className="btn btn-light w-100"
                    onClick={onClose}
                  >
                    {cancelText}
                  </button>
                ) : null}

                {confirmText ? (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={onConfirm}
                  >
                    {confirmText}
                  </button>
                ) : null}
              </div>
            ) : (
              <div className="modal-footer justify-content-end">
                <div>
                  {cancelText ? (
                    <button
                      type="button"
                      className={clsx(
                        `btn ${confirmText && confirmText !== "" ? "btn-light-primary" : "btn-primary"}`
                      )}
                      onClick={onClose}
                    >
                      {cancelText}
                    </button>
                  ) : null}
                </div>

                <div className="justify-content-right">
                  <>
                    {confirmText ? (
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                        onClick={onConfirm}
                      >
                        {confirmText}
                      </button>
                    ) : null}
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteSelected;