import {Order as SyncedOrders} from "../../models/Order";
import {Account as SyncedAccounts} from "../../models/Account";

interface InitialState {
    clientID: string,
    AccountList: string[];
    StartTime: Date;
    EndTime: Date;
    IsSync: boolean;
    SyncRes: any;
}

const UpdateSyncAction: string = "UpdateSyncAction";

// const newNotification = (type: string, autoClose: boolean, notif: any) => {
//     // Implement your notification creation logic here
//     // Return the created notification
//     return {
//       type,
//       autoClose,
//       notif,
//     };
//   };

export default InitialState;

export type unsubFN = () => void;
export {UpdateSyncAction};