// import InitialState, { UpdateChatListAction } from "./ChatRedux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import db, { firebases, Timestamp } from "../../../../db";
import Campaign from "../../../models/Campaign";
import Language, { button } from "../../../models/Language";
import Template from "../../../models/Template";
import * as lc from "../../localstorage/index";
import InitialState, { UpdateCampaignAction } from "./CampaignRedux";

const initialState: InitialState = {
  campaignData: {},
  clientID: "",
  accountID: "",
  campaignTemplateType: "",
  newCampaignName: "",
  templateID: "",
  templateLanguageID: "",
  templateBodyParams: [],
  templateHeaderParams: [],
  newTemplate: {
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    templateName: "",
    category: "",
    isActive: true,
    //have sub collection template
    languages: [],
  },
  selectedCampaign: {
    id: "",
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    isActive: true,
    company: undefined,
    account: undefined,
    campaignName: "",
    template: undefined,
    language: [],
    targetFileURL: "",
  },
  selectedCampaignID: "",
  selectedCampaignTemplateID: "",
  newCampaignID: "",
  campaignTemplateFinish: false,
  campaignState: "",
  campaignChannel: "",
  selectedAccount: undefined,
  selectedTemplateLanguage: {
    id: "",
  },
  backFromTemplate: false,
  fileURL: "",
  templateList: [],
  isLoading: false,
  templateButtons: [],
  isCampaignDynamicURLValid: true,
};

export const CampaignSlice = createSlice({
  name: UpdateCampaignAction,
  initialState: initialState,
  reducers: {
    addCampaignData: (state, action: PayloadAction<any>) => {
      state.campaignData = { ...state.campaignData, ...action.payload };
    },

    setCampaignData: (state, action: PayloadAction<any>) => {
      state.campaignData = action.payload;
      console.log("Set campaign data >> " + action.payload);
    },

    setClientID: (state, action: PayloadAction<any>) => {
      state.clientID = action.payload;
      console.log("Set campaign client id " + action.payload);
    },

    setAccountID: (state, action: PayloadAction<any>) => {
      state.accountID = action.payload;
      console.log("Set campaign account id " + action.payload);
    },

    setCampaignTemplateType: (state, action: PayloadAction<string>) => {
      state.campaignTemplateType = action.payload;
      console.log("Set campaign template type " + action.payload);
    },

    setNewCampaignName: (state, action: PayloadAction<any>) => {
      state.newCampaignName = action.payload;
      console.log("Set new campaign name " + action.payload);
    },

    setTemplateID: (state, action: PayloadAction<any>) => {
      state.templateID = action.payload;
      console.log("Set campaign template id " + action.payload);
    },

    setTemplateLanguageID: (state, action: PayloadAction<any>) => {
      state.templateLanguageID = action.payload;
      console.log("Set campaign template with language id " + action.payload);
    },

    setTemplateBodyParams: (state, action: PayloadAction<any[]>) => {
      state.templateBodyParams = action.payload;
      state.campaignData = {
        ...state.campaignData,
        bodyParam: action.payload as any,
      };
      console.log(
        "Set campaign body parameters " + JSON.stringify(action.payload)
      );
    },

    setTemplateHeaderParams: (state, action: PayloadAction<any[]>) => {
      state.templateHeaderParams = action.payload;
      console.log(
        "Set campaign Header parameters " + JSON.stringify(action.payload)
      );
    },

    setCampaignButtons: (state, action: PayloadAction<button[]>) => {
      state.templateButtons = action.payload;
      console.log(
        "Set campaign Buttons parameters " + JSON.stringify(action.payload)
      );
    },

    setNewTemplate: (state, action: PayloadAction<Template>) => {
      state.newTemplate = action.payload;
      // state.newCampaignName =  action.payload.templateName;
      console.log("Set Template " + JSON.stringify(action.payload));
    },

    setSelectedCampaign: (state, action: PayloadAction<Campaign>) => {
      state.selectedCampaign = action.payload;
      console.log("Set Template " + JSON.stringify(action.payload));
    },

    setSelectedCampaignID: (state, action: PayloadAction<string>) => {
      state.selectedCampaignID = action.payload;
      console.log("Set Template ID " + JSON.stringify(action.payload));
    },

    setFileURL: (state, action: PayloadAction<string>) => {
      state.fileURL = action.payload;
      console.log("Set setFileURL " + JSON.stringify(action.payload));
    },

    setNewCampaignID: (state, action: PayloadAction<string>) => {
      state.newCampaignID = action.payload;
      console.log("Set New Campaign ID " + JSON.stringify(action.payload));
    },

    setCampaignTemplateFinish: (state, action: PayloadAction<boolean>) => {
      state.campaignTemplateFinish = action.payload;
      console.log("Redux template - Set campaignTemplateFinish ");
    },

    setBackFromTemplate: (state, action: PayloadAction<boolean>) => {
      state.backFromTemplate = action.payload;
      console.log("Redux template - Set backFromFinish ");
    },

    setCampaignState: (state, action: PayloadAction<string>) => {
      state.campaignState = action.payload;
      console.log("Set Campaign State " + JSON.stringify(action.payload));
    },

    setCampaignChanel: (state, action: PayloadAction<string>) => {
      state.campaignChannel = action.payload;
      console.log("Set Campaign Channel " + JSON.stringify(action.payload));
    },

    setSelectedAccount: (state, action: PayloadAction<any>) => {
      state.selectedAccount = action.payload;
      console.log("Set selected account" + action.payload);
    },

    setSelectedTemplateLanguage: (state, action: PayloadAction<Language>) => {
      state.selectedTemplateLanguage = action.payload;
    },

    setSelectedCampaignTemplateID: (state, action: PayloadAction<any>) => {
      state.selectedCampaignTemplateID = action.payload;
      console.log("Set selected campaign template id " + action.payload);
    },

    setTemplateList: (state, action: PayloadAction<any>) => {
      state.templateList = action.payload;
      console.log("Set template list " + action.payload);
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setIsCampaignDynamicURLValid: (state, action: PayloadAction<boolean>) => {
      state.isCampaignDynamicURLValid = action.payload;
    },

    cleanReduxCampaign: (state, action: PayloadAction<boolean>) => {
      console.log(`Clear Redux Campaign`);
      state.campaignData = {};
      state.clientID = "";
      state.accountID = "";
      state.campaignTemplateType = "";
      state.campaignChannel = "";
      state.newCampaignName = "";
      state.templateID = "";
      state.templateLanguageID = "";
      state.templateBodyParams = [];
      state.templateHeaderParams = [];
      state.newTemplate = {
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        templateName: "",
        category: "",
        isActive: true,
        //have sub collection template
        languages: [],
      };
      state.newCampaignID = "";
      state.selectedCampaignID = "";
      state.selectedCampaign = {
        id: "",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        isActive: true,
        company: undefined,
        account: undefined,
        campaignName: "",
        template: undefined,
        language: [],
        targetFileURL: "",
      };
      state.campaignState = "";
      state.campaignTemplateFinish = false;
      state.selectedAccount = undefined;
      state.selectedTemplateLanguage = {
        id: "",
      };
      state.backFromTemplate = false;
      state.fileURL = "";
      state.selectedCampaignTemplateID = "";
      state.isLoading = false;
      console.log(`Check after clear >> ${JSON.stringify(state)}`);
      state.templateList = [];
      state.templateButtons = [];
      state.isCampaignDynamicURLValid = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCampaignData,
  setCampaignData,
  setClientID,
  setAccountID,
  setCampaignTemplateType,
  setNewCampaignName,
  setTemplateID,
  setTemplateLanguageID,
  setTemplateBodyParams,
  cleanReduxCampaign,
  setTemplateHeaderParams,
  setNewTemplate,
  setSelectedCampaign,
  setSelectedCampaignID,
  setNewCampaignID,
  setCampaignTemplateFinish,
  setCampaignState,
  setCampaignChanel,
  setSelectedAccount,
  setSelectedTemplateLanguage,
  setBackFromTemplate,
  setFileURL,
  setSelectedCampaignTemplateID,
  setTemplateList,
  setIsLoading,
  setCampaignButtons,
  setIsCampaignDynamicURLValid,
} = CampaignSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default CampaignSlice.reducer;
