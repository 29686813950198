import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../resources/helpers";
import firebase from "firebase/compat/app";
import { format } from "date-fns";
import { getItemLC, LCName } from "../../../../../modules/localstorage";
import * as lc from "../../../../../modules/localstorage";
import { createRef } from "../../../../../../db/connection";
import WABAccount from "../../../../../models/WABAccount";
import { getListWABAccountByClient } from "../../../../../../db/serviceWABA";

const API_URL = process.env.REACT_APP_THEME_API_URL;
const USER_URL = `${API_URL}/user`;
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`; //`${API_URL}/users/query`


export type WABAAccountsQueryResponse = Response<WABAccount[]>;

export const getWABAAccounts = async (
  sort: string | undefined,
  order: string | undefined,
  search: string | undefined,
  action: string | undefined,
  lastId: string | undefined,
  limit: number
): Promise<Array<WABAccount>> => {
  let wabaAccountsQueryResponse: WABAAccountsQueryResponse;
  console.log("item : " + limit);
  console.log("action : " + action);

  let sortBy: string = "firstNameInsensitive";
  let orderBy: firebase.firestore.OrderByDirection = "asc";
  let searchBy: string = "";
  let isByPhoneNo = false;

  if (sort !== undefined && sort !== null) {
    sortBy = sort;
  }

  if (order !== undefined && order !== null) {
    if (order === "desc") orderBy = "desc";
  }

  if (search !== undefined && search !== null) {
    searchBy = search;
    if(searchBy.startsWith("62")){
      isByPhoneNo=true;
    }
  }
  console.log("order =====>>" + orderBy);

  //Create Ref Data Company
  const companyID = getItemLC("CID");
  let companyRef = createRef("company", companyID);

  // create client ref
  const client = getItemLC(lc.LCName.Client);
  const clientRef = createRef("clients", client.id);

  console.log("Client Ref is, ", clientRef)

  //TODO proses get data
  let dataWABAAccounts = await getListWABAccountByClient(clientRef)

  console.log("waba data", dataWABAAccounts.length);

  if(dataWABAAccounts.length>0){
    console.log("WABAAccount data  exist...");
  }

  return Promise.resolve(dataWABAAccounts);
};