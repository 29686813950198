import { FC } from "react";
import { Order } from "../../../../models/Order";
import { useTranslation } from "react-i18next";

interface OrderInitiateChatProps {
  order: Order;
}

export const ChatMessageOrderInitiateView: FC<OrderInitiateChatProps> = ({ order }) => {
  const { t } = useTranslation();
  const orderStatus = order?.status?.replaceAll("_", " ") ?? "";
  const orderImageDisplay = order?.itemList?.[0]?.imageUrl ?? "/public/media/icons/channel/7days.png";
  const statusBadgeColor = order?.status?.toUpperCase() === "CANCELLED" ? "danger" : "success";
  return (
    <div className="d-flex flex-row justify-content-between">
      <div id="replied-content" data-testid="reply-initiate-message" className="d-flex flex-row">
        <img src={orderImageDisplay} className={`rounded`} style={{ height: "50px", width: "50px" }} />
        <div className="px-3 py-1">
          <div className={`fw-bolder badge badge-${statusBadgeColor} text-center`}>{orderStatus}</div>
          <div className="d-flex flex-row align-items-center mt-2">{order.ordersn}</div>
        </div>
      </div>
    </div>
  );
};
