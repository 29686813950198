import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import {
  ShopeeProductCategoryAttributeList,
  ShopeeProductCategoryAttributeValue,
} from "../../../../../../models/ShopeeProductCategoryAttribute";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../setup/redux/store";
import * as ReduxProduct from "../../../../../../modules/product/redux/ProductSlice";
import { t } from "i18next";
import { useMediaQuery } from "react-responsive";
import CreatableSelect from "react-select/creatable";
import clsx from "clsx";

interface CategoryAttributesProps {
  attributeData: ShopeeProductCategoryAttributeList;
  isClickNext?: boolean;
  children?: any;
}

const CategoryAttributes: FC<CategoryAttributesProps> = ({
  attributeData,
  isClickNext,
}) => {
  console.log(`isClickNext ${isClickNext}`);
  const { i18n } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const today: any = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const rd_categoryAttributes = useSelector(
    (state: RootState) => state.Product.categoryAttributes
  );

  const handleOnChangeInput = async (
    event: any,
    attribute_id: any,
    index: number
  ) => {
    setIsFirst(false);
    let targetValue = event.target.value;
    if (attribute_id === 100963)
      targetValue = targetValue.replace(/[^\w\s]/gi, "");
    console.log(`input Value : ${targetValue}`);
    const attributes = rd_categoryAttributes.shopee;
    const newAttributes = attributes.map(
      (att: ShopeeProductCategoryAttributeList) => {
        if (att?.attributeId !== attribute_id) return att;
        let newInputValues: any = [];
        if (!att.inputValues || att.inputValues.length < 1) {
          if (index === 0) newInputValues.push(targetValue);
        } else {
          console.log(`masuk sini`);
          newInputValues = [...att.inputValues];
          newInputValues[0] = index === 0 ? targetValue : newInputValues[0];
        }
        console.log(`newInputValues : ${JSON.stringify(newInputValues)}`);
        return { ...att, inputValues: newInputValues, isValid: true };
      }
    );
    dispatch(ReduxProduct.setCategoryAttributes({ shopee: newAttributes }));
  };

  const handleOnChangeSelection = async (
    selected: any,
    attribute_id: any,
    index: number
  ) => {
    setIsFirst(false);
    console.log(`Ini single select`);
    console.log(`attribute_id : ${attribute_id}`);
    // console.log( `index : ${index}`);
    console.log(`selected Option : ${JSON.stringify(selected)}`);
    if (selected && selected.__isNew__) delete selected.__isNew__;
    const attributes: any = rd_categoryAttributes.shopee; //list attributes shopee
    //find if new, by value
    let newCategoryAttributes: ShopeeProductCategoryAttributeValue[] =
      attributeData.attributeValues ? [...attributeData.attributeValues] : [];
    const findAttribute = newCategoryAttributes.find((values: any) => {
      console.log(`values : `, values);
      return values.valueId.toString() === selected?.value.toString();
    });
    console.log(`findAttribute : ${findAttribute}`);
    if (!findAttribute && selected) {
      //add new attribute to list
      const newAttribute: ShopeeProductCategoryAttributeValue = {
        valueId: selected?.label,
        displayValueName: selected?.label,
        originalValueName: selected?.label,
      };
      newCategoryAttributes.push(newAttribute);
      console.log(
        `newCategoryAttributes with input values : ${JSON.stringify(
          newCategoryAttributes
        )}`
      );
    }
    //find attributes by attribute_id
    //change inputValues
    const newAttributes = attributes.map(
      (att: ShopeeProductCategoryAttributeList) => {
        if (att?.attributeId !== attribute_id) return att;
        let newInputValues: any = [];
        // if (selected && (selected?.value === selected?.label)) selected = {value: 0, label: selected.label}
        if (!att.inputValues || att.inputValues.length < 1) {
          if (index !== 0) newInputValues.push(null);
          newInputValues.push(selected);
        } else {
          newInputValues = [...att.inputValues];
          newInputValues[0] = newInputValues[0] ? newInputValues[0] : null;
          newInputValues[index] = selected;
        }
        console.log(`newInputValues : ${JSON.stringify(newInputValues)}`);
        return {
          ...att,
          inputValues: newInputValues,
          isValid: true,
          attributeValues: newCategoryAttributes ? newCategoryAttributes : [],
        };
      }
    );
    //dispatch set inputValues to redux attributesList
    dispatch(ReduxProduct.setCategoryAttributes({ shopee: newAttributes }));
  };

  const handleOnChangeMultipleSelection = async (
    selected: any,
    attribute_id: any,
    index: number
  ) => {
    setIsFirst(false);
    console.log(`Ini multi select`);
    console.log(`attribute_id : ${attribute_id}`);
    // console.log( `index : ${index}`);
    console.log(`selected Length : ${JSON.stringify(selected.length)}`);
    console.log(`selected Options : ${JSON.stringify(selected)}`);
    const attributes = rd_categoryAttributes.shopee; //list attributes shopee
    let newCategoryAttributes: ShopeeProductCategoryAttributeValue[] =
      attributeData.attributeValues ? [...attributeData.attributeValues] : [];
    let newAttribute: ShopeeProductCategoryAttributeValue;
    const indexSelected = selected.length - 1;
    console.log(`indexSelected: ${indexSelected}`);
    const findAttribute = newCategoryAttributes?.find((values: any) => {
      console.log(`values : `, values);
      console.log(
        `selected[indexSelected] : ${selected[indexSelected]}`
      );
      if(values && values.valueId && selected[indexSelected] && selected[indexSelected].value) 
        return (values?.valueId.toString() === selected[indexSelected]?.value?.toString());
    });
    console.log(`findAttribute : ${findAttribute}`);
    if (!findAttribute && selected && selected.length > 0 && 
      selected[indexSelected] && selected[indexSelected].label) {
      //add new attribute to list
      newAttribute = {
        valueId: selected[indexSelected]?.label,
        displayValueName: selected[indexSelected]?.label,
        originalValueName: selected[indexSelected]?.label,
      };
      newCategoryAttributes.push(newAttribute);
      console.log(
        `newCategoryAttributes with input values : ${JSON.stringify(
          newCategoryAttributes
        )}`
      );
    }
    const newAttributes = attributes.map(
      (att: ShopeeProductCategoryAttributeList) => {
        if (att?.attributeId !== attribute_id) return att;
        let newInputValues: any[] = [];
        if (!att.inputValues || att.inputValues.length < 1) {
          if (index !== 0) newInputValues.push(null);
          newInputValues.push(selected);
          console.log(`new Input Values: ${JSON.stringify(newInputValues)}`);
        } else {
          console.log(`masuk sini`);
          newInputValues = [...att.inputValues]; //[[], []]
          console.log(`newInput Values: ${JSON.stringify(newInputValues)}`);
          if (selected.length > 0 || newAttribute) {
            let newAttributeValueList = selected.map((item: any) => {
              // if (item.label === item.value) {
              //   return { label: item.label, value: 0 }
              // }
              return { label: item.label, value: item.value };
            });
            console.log(`newAttributeValueList : `, newAttributeValueList);
            newInputValues[index] = newAttributeValueList;
            console.log(`index : `, index);
            console.log(`newInputValues[0] : `, newInputValues[index]);
            // newInputValues= newInputValues.splice(index, 1, newAttributeValueList)
            console.log(`newInputValues after : `, newInputValues);
          } else {
            newInputValues[index] = [];
          }
        }
        newInputValues = newInputValues.map((val)=>{
          if(val.__isNew__){
            return {label: val.label, value: val.value}
          }else if(val){
            return val
          }
        })
        console.log(`newInputValues : ${JSON.stringify(newInputValues)}`);
        return {
          ...att,
          inputValues: newInputValues,
          isValid: true,
          attributeValues: newCategoryAttributes,
        };
      }
    );
    //dispatch set inputValues to redux attributesList
    dispatch(ReduxProduct.setCategoryAttributes({ shopee: newAttributes }));
  };

  useEffect(() => {
    const attributes = rd_categoryAttributes.shopee;
    const newAttributes = attributes.map(
      (att: ShopeeProductCategoryAttributeList) => {
        if (att.inputValues && att.inputValues.length > 0) {
          return { ...att };
        }
      }
    );
    if (isFirst) {
      setIsErrorVisible(false);
    } else {
      setIsErrorVisible(attributeData.isMandatory && !isValid);
    }
  }, [rd_categoryAttributes, isClickNext]);

  return (
    <div
      className={clsx("row col mt-5", {
        "error-attributes": !attributeData.isValid || isErrorVisible,
      })}
      key={attributeData.attributeId}
      data-testid={`col-${attributeData.attributeId}`}
    >
      <div className="fs-5">
        {i18n.language === "id"
          ? `${attributeData?.displayAttributeName}`
          : `${attributeData?.originalAttributeName}`}
        {attributeData.isMandatory && attributeData.isMandatory === true && (
          <span className="text-danger">*</span>
        )}
      </div>

      {/* drop down */}
      {attributeData?.inputType === "DROP_DOWN" &&
      attributeData?.inputValidation !== "DATE_TYPE" ? (
        attributeData.attributeUnit &&
        attributeData.attributeUnit.length > 0 ? (
          <>
            <div
              className={clsx(
                `row-start `,
                isTabletOrMobile ? "col-12" : "col-8"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map(
                    (attr: ShopeeProductCategoryAttributeValue) => ({
                      value: attr.valueId,
                      label:
                        i18n.language === "id"
                          ? attr.displayValueName
                          : attr.originalValueName,
                    })
                  )
                }
                onChange={(e: any) => {
                  console.log(`e : ${JSON.stringify(e)}`);
                  handleOnChangeSelection(e, attributeData.attributeId, 0);
                  setIsValid(e);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
            <div
              className={clsx(
                `row-end`,
                isTabletOrMobile ? "col-12 mt-5" : "col-4"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={
                  attributeData.attributeUnit &&
                  attributeData.attributeUnit.map((attr: any) => ({
                    value: attr,
                    label: attr,
                  }))
                }
                onChange={(e: any) => {
                  handleOnChangeSelection(e, attributeData.attributeId, 1);
                  setIsValid(e);
                }}
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[1]
                    : undefined
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className="row-start col-12">
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={
                  attributeData?.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                onChange={(e: any) => {
                  handleOnChangeSelection(e, attributeData.attributeId, 0);
                  setIsValid(e);
                }}
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        )
      ) : undefined}

      {/* combo box */}
      {attributeData?.inputType === "COMBO_BOX" &&
      attributeData?.inputValidation !== "DATE_TYPE" ? (
        attributeData.attributeUnit &&
        attributeData.attributeUnit.length > 0 ? (
          <>
            <div
              className={clsx(
                `row-start `,
                isTabletOrMobile ? "col-12" : "col-8"
              )}
            >
              <CreatableSelect
                id={attributeData.attributeId.toString()}
                className="w-100 border-0"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                onChange={(e: any) => {
                  console.log(`e : ${JSON.stringify(e)}`);
                  handleOnChangeSelection(e, attributeData.attributeId, 0);
                  setIsValid(e);
                }}
                value={
                  attributeData.inputValues
                    ? attributeData.inputValues[0]
                    : undefined
                }
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
            <div
              className={clsx(
                `row-end`,
                isTabletOrMobile ? "col-12 mt-5" : "col-4"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={
                  attributeData.attributeUnit &&
                  attributeData.attributeUnit.map((attr: any) => ({
                    value: attr,
                    label: attr,
                  }))
                }
                onChange={(e: any) => {
                  handleOnChangeSelection(e, attributeData.attributeId, 1);
                  setIsValid(e);
                }}
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[1]
                    : undefined
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className="row-start col-12">
              <CreatableSelect
                id={attributeData.attributeId.toString()}
                className="w-100 border-0"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                onChange={(e: any) => {
                  handleOnChangeSelection(e, attributeData.attributeId, 0);
                  setIsValid(e);
                }}
                isClearable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        )
      ) : undefined}

      {/* input */}
      {attributeData?.inputType === "TEXT_FILED" &&
      attributeData?.inputValidation === "DATE_TYPE" ? (
        <>
          <div className="row-start col-12">
            <input
              data-testid={`input-${attributeData.attributeId}`}
              className="w-100 form-control"
              min={attributeData.attributeId === 100061 ? today : undefined}
              type="date"
              onChange={(e: any) => {
                handleOnChangeInput(e, attributeData.attributeId, 0);
                setIsValid(e.target.value !== "" && e.target.value >= today);
              }}
              value={attributeData.inputValues
                ? attributeData.inputValues[0]
                : ""}
            />
            {attributeData.isValid === false || isErrorVisible ? (
              attributeData.attributeId === 100061 &&
              attributeData.inputValues &&
              attributeData.inputValues[0] &&
              attributeData.inputValues[0] < today ? (
                <span className="text-danger">
                  {t("AddNewProduct.Alert.InvalidDateExpired")}
                </span>
              ) : (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              )
            ) : null}
          </div>
        </>
      ) : attributeData?.inputType === "TEXT_FILED" &&
        attributeData.inputValidation !== "DATE_TYPE" &&
        attributeData.attributeUnit &&
        attributeData.attributeUnit.length > 0 ? (
        <>
          <div
            className={clsx(
              `row-start `,
              isTabletOrMobile ? "col-12" : "col-8"
            )}
          >
            <input
              id={attributeData.attributeId.toString()}
              className="w-100 form-control"
              maxLength={attributeData.attributeId === 100963 ? 15 : 25}
              onChange={(e: any) => {
                handleOnChangeInput(e, attributeData.attributeId, 0);
                setIsValid(e.target.value !== "");
              }}
              value={
                attributeData.inputValues
                  ? Object.values(attributeData.inputValues)[0]
                  : ""
              }
            />
            {attributeData.isValid === false || isErrorVisible ? (
              <span className="text-danger">
                {t("AddNewProduct.Title.Specification.Error")}
              </span>
            ) : null}
          </div>
          <div
            className={clsx(
              `row-start `,
              isTabletOrMobile ? "col-12 mt-5" : "col-4"
            )}
          >
            <Select
              id={attributeData.attributeId.toString()}
              className="w-100 border-0 basic-single"
              classNamePrefix="select"
              options={attributeData.attributeUnit.map((attr: any) => ({
                value: attr,
                label: attr,
              }))}
              onChange={(e: any) => {
                handleOnChangeSelection(e, attributeData.attributeId, 1);
                setIsValid(e);
              }}
              value={
                attributeData.inputValues
                  ? Object.values(attributeData.inputValues)[1]
                  : undefined
              }
              // options={categoryAttributesResponse[0].attribute_value_list}
              isClearable={true}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
            {attributeData.isValid === false || isErrorVisible ? (
              <span className="text-danger">
                {t("AddNewProduct.Title.Specification.Error")}
              </span>
            ) : null}
          </div>
        </>
      ) : attributeData?.inputType === "TEXT_FILED" ? (
        <>
          <div className="row-start col-12">
            <input
              id={attributeData.attributeId.toString()}
              data-testid={`input-${attributeData.attributeId}`}
              className="w-100 form-control"
              onChange={(e: any) => {
                handleOnChangeInput(e, attributeData.attributeId, 0);
                setIsValid(e.target.value !== "");
              }}
              maxLength={attributeData.attributeId === 100963 ? 25 : 25}
              value={
                attributeData.inputValues
                  ? Object.values(attributeData.inputValues)[0]
                  : ""
              }
            />
            {attributeData.isValid === false || isErrorVisible ? (
              <span className="text-danger">
                {t("AddNewProduct.Title.Specification.Error")}
              </span>
            ) : null}
          </div>
        </>
      ) : null}

      {/* multiple select */}
      {attributeData?.inputType === "MULTIPLE_SELECT" &&
      attributeData.inputValidation !== "DATE_TYPE" ? (
        attributeData.attributeUnit &&
        attributeData.attributeUnit.length > 0 ? (
          <>
            <div
              className={clsx(
                `row-start `,
                isTabletOrMobile ? "col-12" : "col-8"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-multi-select"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                onChange={(e: any) => {
                  // if(attributeData.inputValues &&
                  //   attributeData.inputValues[0] &&
                  //   attributeData.inputValues[0].length <= attributeData.maxInputValueNumber) setIsValid(true);
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    0
                  );
                  if (e.length > 0) setIsValid(true);
                  else setIsValid(false);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                isMulti
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isOptionDisabled={(option: any, selectValue: any) =>
                  selectValue.length === attributeData.maxInputValueNumber &&
                  !selectValue.includes(option)
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
            <div
              className={clsx(
                `row-end `,
                isTabletOrMobile ? "col-12 mt-5" : "col-4"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={attributeData.attributeUnit.map((attr: any) => ({
                  value: attr,
                  label: attr,
                }))}
                onChange={(e: any) => {
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    1
                  );
                  setIsValid(e);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[1]
                    : undefined
                }
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className="row-start col-12">
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-multi-select"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                onChange={(e: any) => {
                  // if(attributeData.inputValues &&
                  //   attributeData.inputValues[0] &&
                  //   attributeData.inputValues[0].length <= attributeData.maxInputValueNumber) setIsValid(true);
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    0
                  );
                  if (e.length > 0) setIsValid(true);
                  else setIsValid(false);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                isMulti
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isOptionDisabled={(option: any, selectValue: any) =>
                  selectValue.length === attributeData.maxInputValueNumber &&
                  !selectValue.includes(option)
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        )
      ) : undefined}

      {/* multiple select combo box */}
      {attributeData?.inputType === "MULTIPLE_SELECT_COMBO_BOX" &&
      attributeData.inputValidation !== "DATE_TYPE" ? (
        attributeData.attributeUnit &&
        attributeData.attributeUnit.length > 0 ? (
          <>
            <div
              className={clsx(
                `row-start `,
                isTabletOrMobile ? "col-12" : "col-8"
              )}
            >
              <CreatableSelect
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-multi-select"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                onChange={(e: any) => {
                  // if(attributeData.inputValues &&
                  //   attributeData.inputValues[0] &&
                  //   attributeData.inputValues[0].length <= attributeData.maxInputValueNumber) setIsValid(true);
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    0
                  );
                  if (e.length > 0) setIsValid(true);
                  else setIsValid(false);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                isMulti
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isOptionDisabled={(option: any, selectValue: any) =>
                  selectValue.length === attributeData.maxInputValueNumber &&
                  !selectValue.includes(option)
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
            <div
              className={clsx(
                `row-end `,
                isTabletOrMobile ? "col-12 mt-5" : "col-4"
              )}
            >
              <Select
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-single"
                classNamePrefix="select"
                options={attributeData.attributeUnit.map((attr: any) => ({
                  value: attr,
                  label: attr,
                }))}
                onChange={(e: any) => {
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    1
                  );
                  setIsValid(e);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[1]
                    : undefined
                }
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className="row-start col-12">
              <CreatableSelect
                id={attributeData.attributeId.toString()}
                className="w-100 border-0 basic-multi-select"
                classNamePrefix="select"
                options={
                  attributeData.attributeValues &&
                  attributeData.attributeValues.map((attr: any) => ({
                    value: attr.valueId,
                    label:
                      i18n.language === "id"
                        ? attr.displayValueName
                        : attr.originalValueName,
                  }))
                }
                onChange={(e: any) => {
                  // if(attributeData.inputValues &&
                  //   attributeData.inputValues[0] &&
                  //   attributeData.inputValues[0].length <= attributeData.maxInputValueNumber) setIsValid(true);
                  handleOnChangeMultipleSelection(
                    e,
                    attributeData.attributeId,
                    0
                  );
                  if (e.length > 0) setIsValid(true);
                  else setIsValid(false);
                }}
                value={
                  attributeData.inputValues
                    ? Object.values(attributeData.inputValues)[0]
                    : undefined
                }
                isMulti
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isOptionDisabled={(option: any, selectValue: any) =>
                  selectValue.length === attributeData.maxInputValueNumber &&
                  !selectValue.includes(option)
                }
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
              {attributeData.isValid === false || isErrorVisible ? (
                <span className="text-danger">
                  {t("AddNewProduct.Title.Specification.Error")}
                </span>
              ) : null}
            </div>
          </>
        )
      ) : undefined}
    </div>
  );
};

export default CategoryAttributes;