import React, { Fragment, useEffect, useState } from "react";
import * as lc from "../../modules/localstorage/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUser, setAuth } from "../../modules/auth/redux/AuthSlice";
import { logout } from "../../../api";
import { setUserOffline } from "../../../api/server/connection";
import { deleteMessage } from "../../modules/chat/redux/ChatSlice";
import { cleanReduxCIQ } from "../../modules/ciq/redux/CIQSlice";
import { cleanReduxContact } from "../../modules/contact/redux/ContactSlice";
import * as Log from "../../../util/SDayslogger";
import { cleanReduxShipment } from "../../modules/product/shipment/redux/ShipmentsSlice";
import { cleanReduxProduct } from "../../modules/product/redux/ProductSlice";
import { cleanReduxMarketplaceProductCategory } from "../../modules/product/marketplaceProductCategory/marketplaceProductCategorySlice";
import { persistor } from "../../../setup/redux/store";
import { cleanReduxTemplate } from "../../modules/template/list/TemplateListSlice";
import { cleanReduxCampaign as cleanReduxCampaignList } from "../../modules/campaign/list/CampaignListSlice";
import { cleanReduxCampaign } from "../../modules/campaign/redux/CampaignSlice";
import { cleanReduxProductList } from "../../modules/product/productList/ProductListSlice";
import { cleanReduxStorefrontList } from "../../modules/product/storefront/storefrontList/StorefrontListSlice";
import { cleanReduxAddMembers } from "../../modules/auth/redux/AddMemberSlice";
import { AsideMenuItem } from "./AsideMenuItem";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import { AsideMenuButton } from "./AsideMenuButton";
import db, { createRef } from "src/db";

const AsideMenuLogoutButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const logoutHandler = async () => {
    const currentUser = lc.getItemLC(lc.LCName.User);
    const sessionid = lc.getItemLC(lc.LCName.SessionID);
    const handleUserRef = createRef("users", currentUser.uid);
    const querySnapshot = await db
      .collectionGroup("rooms")
      .where("toUserOnline", "array-contains", handleUserRef)
      .get();

    if (currentUser === null || sessionid === null) {
      dispatch(setAuth(false));
      nav("/auth");
    } else {
      logout()
        .then(() => {
          setUserOffline(currentUser.uid, sessionid);
          dispatch(deleteUser());
          lc.removeSession();
          dispatch(setAuth(false));
          dispatch(deleteMessage);
          dispatch(cleanReduxCIQ(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxShipment(true));
          dispatch(cleanReduxProduct(true));
          dispatch(cleanReduxMarketplaceProductCategory(true));
          dispatch(deleteUser());
          dispatch(deleteMessage(true));
          dispatch(cleanReduxContact(true));
          dispatch(cleanReduxTemplate(true));
          dispatch(cleanReduxCampaign(true));
          dispatch(cleanReduxProductList(true));
          dispatch(cleanReduxStorefrontList(true));
          dispatch(cleanReduxCampaignList(true));
          dispatch(cleanReduxAddMembers(true));
          lc.removeLC("Notify");
          querySnapshot.forEach(async (doc) => {
            const roomData = doc.data();
            if (roomData && roomData.toUserOnline) {
              const updatedToUserOnline = roomData.toUserOnline.filter(
                (userRef: any) => userRef.id !== handleUserRef.id
              );
              await doc.ref.update({
                toUserOnline: updatedToUserOnline,
              });
            }
          });
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          nav("/auth");
        })
        .catch((error) => {
          // Log.SDayslogger(
          //     nav,
          //     "Testing Error Message",
          //     Log.SDLOGGER_INFO,
          //     false,
          //     true
          // );
          console.log("failed logout");
        });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <AsideMenuButton
        duotoneIcon="/media/icons/duotune/arrows/arr077.svg"
        title={t("HeaderBar.Button.Logout")}
        onClick={() => setIsModalOpen(true)}
        id="AM_Logout"
        // data-bs-dismiss="offcanvas"
        aria-label="Close"
      />

      <Modal
        id="ConfirmLogoutModal"
        title={t("Logout.Modal.Title")}
        confirmOption={t("Common.Yes")}
        cancelOption={t("Common.No")}
        isModalOpen={isModalOpen}
        onConfirm={() => logoutHandler()}
        onCancel={() => setIsModalOpen(false)}
        centered={true}
      >
        <span>{t("Logout.Modal.Info")}</span>
      </Modal>
    </Fragment>
  );
};

export default AsideMenuLogoutButton;
