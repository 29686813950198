/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React from "react";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Template } from "webpack";
import { toAbsoluteUrl } from "../../../../../../resources/helpers";

type Props = {
  template: any;
};

const CampaignTemplateInfoCell: FC<Props> = ({ template }) => {
  if(template !== undefined && template.templateName !== undefined){
    let templateName = template.templateName;
    if (templateName && templateName.length > 17) {
      templateName = templateName.slice(0, 16).concat("...");
    }
    return (
      <div className='text-gray-650 mb-1'>{templateName}</div>
    )
  }else{
    return <></>
  }
  };

export { CampaignTemplateInfoCell };
