import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialState, {
  WABAAccountSignUpAction,
} from "./WABAAccountSignUpRedux";

const initialState: InitialState = {
  status: "",
  isEdit: false,
  lastStep: "",
  listenerResponse: "",
  phoneNumbers: [],
  dataWABA: {},
};

export const WABAAccountSignupSlice = createSlice({
  name: WABAAccountSignUpAction,
  initialState: initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },

    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },

    setLastStep: (state, action: PayloadAction<string>) => {
      state.lastStep = action.payload;
    },

    setListenerResponse: (state, action: PayloadAction<any>) => {
      state.listenerResponse = action.payload;
    },

    setPhoneNumbers: (state, action: PayloadAction<string[]>) => {
      state.phoneNumbers = action.payload;
    },
    setDataWABA: (state, action: PayloadAction<any>) => {
      state.dataWABA = action.payload;
    },
    cleanReduxWABAAccountSignUp: (state, action: PayloadAction<boolean>) => {
      state.status = "";
      state.isEdit = false;
      state.lastStep = "";
      state.listenerResponse = "";
      state.phoneNumbers = [];
      state.dataWABA = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setStatus,
  setIsEdit,
  setLastStep,
  setListenerResponse,
  setPhoneNumbers,
  setDataWABA,
  cleanReduxWABAAccountSignUp,
} = WABAAccountSignupSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default WABAAccountSignupSlice.reducer;
